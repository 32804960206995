@if (blend) {
<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>
<ng-template #coffeeString let-coffee="coffee" let-size="size">
    @if (coffee?.label) {
    <span>&nbsp;<a [routerLink]="['/coffees', {id: coffee.hr_id}]">{{coffee.hr_id}}</a>
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="size || 10"
            [leftMargin]="4"
            [spacer]="haveOrganicAndNonOrganic">
        </app-organicicon>
        @if (coffee.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
        @if (coffee.origin || coffee.yearLabel) {
        <span>,</span>
        }
        @if (coffee.label) {
        <span> {{coffee.label}}</span>
        }
        @if (coffee.totalStockStr) {
        (<span [ngClass]="{'negative-amount': coffee.totalStock <= 0}">{{coffee.totalStockStr}}</span>)
        }
    </span>
    }
</ng-template>
<ng-template #coffeeStringIng let-ing="ing">
    @if (ing?.coffee?.label) {
    <span><a [routerLink]="['/coffees', {id: ing.coffee.hr_id}]" class="littlespaceleft">{{ing.coffee.hr_id}}</a>
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(ing.coffee)"
            [size]="11"
            [leftMargin]="5"
            [spacer]="haveOrganicAndNonOrganic">
        </app-organicicon>
        @if (ing.ratio) {
        <span> {{ing.ratio * 100 | number:'1.0-0'}}%</span>
        }
        @if (ing.coffee.origin) {
        <span> {{tr.anslate(ing.coffee.origin)}}</span>
        }
        @if (ing.coffee && ing.coffee.yearLabel) {
        <span> {{ing.coffee.yearLabel}}</span>
        }
        @if (ing.coffee && (ing.coffee.origin || ing.coffee.yearLabel)) {
        <span>,</span>
        }
        @if (ing.coffee.label) {
        <span> {{ing.coffee.label}}</span>
        }
    </span>
    }
</ng-template>
<ng-template #nostock><span class="negative-amount">-</span></ng-template>

<span>
    <mat-expansion-panel [disabled]="editMode === index" hideToggle="true"
        [expanded]="isNew === index || isExpanded"
        (closed)="panel(false)"
        (opened)="panel(true)">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto">
            <!-- <mat-expansion-panel-header> -->
            <mat-panel-title class="paneltitle">
                <div class="panel-titel-amount-wrapper">
                    @if (showStockPlaceholder || sav?.replAmount > 0) {
                    <span class="head-stock-wrapper">
                        <!-- <span *ngIf="sav.replAmount > 0 && sav.replAmountstr !== sav.amountstr; else noreplAmount" class="head-stock-line-unitamount">({{sav.replAmountstr}})</span>
            <ng-template #noreplAmount>
              <span class="head-stock-line-unitamount">{{sav.replAmountstr}}</span>
            </ng-template> -->
                        <!-- <span [ngClass]="{'negative-amount': sav.replAmount <= 0 && sav.replAmountstr !== sav.amountstr}" class="head-stock-line-unitamount">{{sav.replAmountstr}}</span> -->
                        @if (sav.replAmount > 0) {
                        <span class="head-stock-line-unitamount"
                            [ngClass]="{'warn-amount': !(sav.amount > 0)}">{{sav.replAmountstr}}</span>
                        }
                    </span>
                    }
                    <span class="head-label-wrapper">
                        <span class="head-hrid">{{blend.hr_id}}</span>
                        <app-organicicon [isOrganic]="isOrganic" [size]="11"></app-organicicon>
                        @if (sav.replAmount > 0 && !(sav.amount > 0)) {
                        <span class="head-label-inclreplings" i18n="blends$$incl. replacement ingredients@@incl. replacement ingredients">incl. replacement ingredients</span>
                        }
                        <div class="head-label">{{blend.label}}</div>
                    </span>
                </div>
            </mat-panel-title>
            <mat-panel-description>
                @if (!isExpanded) {
                <div class="full-width ellipsed">
                    @if (blend.ingredients?.length) {
                    <div class="full-width ellipsed">
                        <ng-container *ngTemplateOutlet="coffeeStringIng; context: {ing: blend.ingredients[0]}"></ng-container>
                    </div>
                    } @else {
                    <span i18n="transaction$$no beans to transfer / sell@@no beans">Please add beans first!</span>
                    }
                    @if (blend.ingredients?.length > 1) {
                    <div class="full-width ellipsed slightup">
                        <ng-container *ngTemplateOutlet="coffeeStringIng; context: {ing: blend.ingredients[1]}"></ng-container>
                        @if (blend.ingredients.length > 2) {
                        <span class="littlespaceleft">...</span>
                        }
                    </div>
                    }
                </div>
                }
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            @if (!readOnly && editMode === index) {
            <div>
                <form id="form" name="form" (ngSubmit)="f.form.valid && save()" #f="ngForm">
                    <div class="outerdiv">
                        <div class="nottable width-for-edit">
                            <mat-form-field class="form-group">
                                <input matInput required autofocus i18n-placeholder="generic$$Name@@Name" type="text" placeholder="Name" name="label" [(ngModel)]="blendcopy.label">
                            </mat-form-field><br />
                            <!-- (ensure blend input before coffees input) -->
                            <app-blend-edit
                                [currentUser]="currentUser"
                                [showstockfrom]="showstockfrom"
                                [readOnly]="readOnly"
                                [blend]="blendcopy"
                                [coffees]="coffees"
                                [coffeesLoaded]="coffeesLoaded">
                            </app-blend-edit>
                        </div>
                    </div>
                </form>
            </div>
            } @else {
            <div class="outerdiv">
                <div class="nottable">
                    <app-modification-date [element]="blend" [user]="currentUser"></app-modification-date>
                    @if (blend.ingredients?.length) {
                    @for (ing of blend.ingredients; track $index) {
                    <div class="text-indent">
                        @if (ing.ratio) {
                        @if (blend.ingredients?.length === 1) {
                        <span>{{ing.ratio * 100 | number:'1.0-0'}}%</span>
                        } @else {
                        <span>{{ing.ratio * 100 | number:'1.1-1'}}%</span>
                        }
                        }
                        <ng-container *ngTemplateOutlet="coffeeString; context: {coffee: ing.coffee}"></ng-container>
                        @if (replaceIngredientsOpen) {
                        <div class="replace-coffee-dropdown">
                            @if (!coffees) {
                            <mat-spinner diameter="15" style="display: inline-block; margin-right: 10px;"></mat-spinner>
                            }
                            <mat-form-field class="coffeeinput"
                                [style.width]="!coffees ? '90%' : '100%'">
                                <mat-select name="replcoffee-{{$index}}"
                                    panelClass="twoLineOption" panelWidth="null"
                                    placeholder="Beans" i18n-placeholder="generic$Beans@@Beans"
                                    [(ngModel)]="ing.replace_coffee" [compareWith]="utils.compareObjectsFn"
                                    (selectionChange)="replaceIngChanged()">
                                    <mat-select-trigger class="twoLineOption ellipsed">
                                        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: ing.replace_coffee}"></ng-container>
                                    </mat-select-trigger>
                                    @if (coffees?.length >= 5) {
                                    <mat-option>
                                        <ngx-mat-select-search ngModel name="coffeeSelect"
                                            (ngModelChange)="filteredCoffees[$index] = utils.filterObjects($event, replCoffees[$index])"
                                            placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                            noEntriesFoundLabel="">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    }
                                    <mat-option [value]="null"></mat-option>
                                    @for (coffee of filteredCoffees[$index]; track coffee?._id) {
                                    <mat-option [value]="coffee">
                                        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coffee}"></ng-container>
                                    </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        }
                        @if (!replaceIngredientsOpen && ing.replace_coffee) {
                        <div class="replace-coffee">
                            ->
                            <ng-container *ngTemplateOutlet="coffeeString; context: {coffee: ing.replace_coffee, size: 8}">
                            </ng-container>
                            @if (!readOnly) {
                            <mat-icon (click)="useReplaceCoffee($index)" matTooltip="Replace by" i18n-matTooltip="blends$$Replace by: beans X; allows selecting replacement beans if roasting a blend and one ingredient is out of stock@@Replace by" class="tertiary-element replace-icon">swap_horiz</mat-icon>
                            }
                        </div>
                        }
                    </div>
                    }
                    @if (replaceIngredientsOpen) {
                    <div style="text-align: right; max-width: 400px;">
                        <button mat-button type="button" class="cancel-button" (click)="closeReplaceIngredients(false)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <button mat-raised-button type="submit" class="action-button" (click)="closeReplaceIngredients(true)">
                            <mat-icon>done</mat-icon>
                        </button>
                    </div>
                    }
                    } @else {
                    <span i18n="transaction$$no beans to transfer / sell@@no beans">Please add beans first!</span>
                    }
                    @if (!replaceIngredientsOpen && !readOnly) {
                    <div class="replace-coffee-link">
                        <a (click)="openReplaceIngredients()" (keydown.enter)="openReplaceIngredients(); $event.preventDefault()" role="button" [tabindex]="0" i18n="blends$$let user add or change replacement ingredients in blends@@Add/Change replacement ingredients">Add/Change replacement ingredients</a>
                        <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/updates/replace-blend-ingredients/'" rel="noopener" style="padding-right: 0;margin: 0 0 0 5px;" target="_blank">
                            <mat-icon class="helpicon">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
                        </a>
                        @if (undoBlend) {
                        <mat-icon (click)="undoReplacements()" matTooltip="Undo replace" i18n-matTooltip="blends$$Undo replace@@Undo replace" class="tertiary-element replace-icon">undo</mat-icon>
                        }
                    </div>
                    }
                    @if (commonCerts?.length) {
                    <div style="margin-top: 10px;">
                        @for (cert of commonCerts; track cert?.abbrev; let c = $index) {
                        <span>
                            {{cert.abbrev ? cert.abbrev : cert.label}}&nbsp;@if (cert.web) {
                            <a href="{{cert.web}}" target="_blank" rel="noopener">
                                <mat-icon class="small">launch</mat-icon>
                            </a>
                            }
                            @if (commonCerts.length - 1 > c) {
                            <span>, </span>
                            }
                        </span>
                        }
                    </div>
                    }
                    <div style="margin-top: 10px;">
                        <span i18n="generic$$Stock@@Stock">Stock</span>: @if (sav?.replAmount > 0) {
                        <span>{{sav.replAmountstr}}@if (sav.replValue) {
                            <span>, {{sav.replValue | currency:currency:'symbol-narrow':'1.0-0'}} ({{(sav.replValue / sav.replAmount) | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}})</span>
                            }</span>
                        } @else {
                        <span class="negative-amount">-</span>
                        }
                        @if (sav?.replAmount > 0 && sav.replAmountstr !== sav.amountstr) {
                        &nbsp;<span class="replace-comment" i18n="blends$$incl. replacement ingredients@@incl. replacement ingredients">incl. replacement ingredients</span>
                        }
                        <!-- <span i18n="generic$$Stock@@Stock">Stock</span>: <span *ngIf="maxstock > 0; else nostock">{{amountstr}}<span *ngIf="value">, {{value | currency:currency:'symbol-narrow':'1.0-0'}} ({{(value / maxstock) | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}})</span></span> -->
                    </div>
                </div>
                <div>
                    @if (blend.image) {
                    <img [src]="blend.image" class="blend-image" (click)="addImage()" (keydown.enter)="addImage(); $event.preventDefault()" role="button" [tabindex]="0" alt="a picture of the blend">
                    }
                </div>
                <div style="width: 100%; margin-bottom: 10px;">
                    <app-ap-file [files]="blend.files" [belongsTo]="blend.internal_hr_id" belongsToType="BLEND"
                        (filesChanged)="filesChanged($event)" [readOnly]="readOnly"></app-ap-file>
                </div>
                <mat-form-field class="notesdiv no-height-change">
                    <mat-label i18n="generic$$arbitrary notes@@Notes">Notes</mat-label>
                    <textarea matInput [disabled]="readOnly" name="notes"
                        [(ngModel)]="notes"
                        (blur)="saveNotes()"
                        (ngModelChange)='notesChanged.next($event)'
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5">
                    </textarea>
                </mat-form-field>
            </div>
            }
        </ng-template>

        @if (!readOnly && (0 > editMode || editMode !== index)) {
        <mat-action-row>
            <button mat-raised-button type="button" [disabled]="editMode >= 0" (click)="delete()" class="edit-button">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-raised-button type="button" [disabled]="editMode >= 0 || replaceIngredientsOpen" (click)="edit()" class="action-button">
                <mat-icon>edit</mat-icon>
            </button>
        </mat-action-row>
        }
        @if (!readOnly && editMode === index) {
        <mat-action-row [ngClass]="{'separate-buttons': blendcopy?.internal_hr_id || blendcopy?._id}">
            @if (blendcopy?.internal_hr_id || blendcopy?._id) {
            <button mat-raised-button type="button" class="action-button otherside-button" (click)="addImage()">
                <mat-icon>{{blendcopy?.image ? 'insert_photo' : 'add_photo_alternate'}}</mat-icon>
            </button>
            }
            <div>
                <button mat-button type="button" class="cancel-button" (click)="cancel()">
                    <mat-icon>close</mat-icon>
                </button>
                @if (!isSaving) {
                <button [disabled]="isSaving" mat-raised-button type="submit" class="action-button" form="form" data-test="bttn_done">
                    <mat-icon>done</mat-icon>
                </button>
                } @else {
                <button disabled mat-raised-button class="action-button spinner-button">
                    <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                </button>
                }
            </div>
        </mat-action-row>
        }
    </mat-expansion-panel>
</span>
}