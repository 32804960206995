<mat-form-field class="form-group">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [required]="required" [disabled]="disabled" type="text"
        [placeholder]="placeholder" name="bleinput"
        [value]="value"
        (change)="this.valueChanged.emit($event.target['value'])"
        (input)="this.inputChanged.emit($event.target['value'])">
</mat-form-field>
@if (bleenabled && !disabled) {
@if (!connected) {
<img (click)="connect()" (keydown.enter)="connect(); $event.preventDefault()" role="button" [tabindex]="0"
    [src]="'assets/images/acaia' + (isDarkmode ? '_dark' : '') + '.png'" width="20px" height="20px"
    class="acaia-logo" [ngClass]="{'spinning': connecting}" alt="the acaia logo">
} @else {
<mat-icon (click)="close(false)" class="ble-icon ble-close-icon">close</mat-icon>
<mat-icon (click)="close(true)" class="ble-icon ble-done-icon">done</mat-icon>
}
}