import { ReportService } from 'src/app/modules/report/report.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { UserType, UserService } from 'src/app/modules/frame/services/user.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Roast } from 'src/app/models/Roast';
import { Subject } from 'rxjs';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { RoastReport } from 'src/app/models/RoastReport';
import { NGXLogger } from 'ngx-logger';
import { RoastreportTableComponent } from './roastreport-table.component';
import { Router } from '@angular/router';
import { RoastReportOpen } from 'src/app/models/RoastReportOpen';
import merge from 'deepmerge';
import cloneDeep from 'lodash-es/cloneDeep';
import { RoastReportOverview } from './RoastReportOverview';
import { YesNoConfirmDialogComponent } from '../../ui/dialog/yesnoconfirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GetPageOptions } from 'src/app/util/services/standard.service';
import { Utils2 } from 'src/app/util/utils2';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-newroastreport',
    templateUrl: './newroastreport.component.html',
    styleUrls: ['./newroastreport.component.scss']
})
export class NewroastreportComponent implements OnInit, OnDestroy {

    constructor(
        private reportService: ReportService,
        public utils: Utils,
        public utils2: Utils2,
        private alertService: AlertService,
        public tr: TranslatorService,
        private dialog: MatDialog,
        private logger: NGXLogger,
        private userService: UserService,
        private router: Router,
    ) { }

    @Input() currentUser: UserType;
    @Input() isDarkmode: boolean;
    @Input() readOnly: boolean;
    @Input() isOpenReport = false;
    @Input() lastEndDate: DateTime;
    // format: 2019-002
    @Input() lastReportNumber: string;
    @Input() reportNames: string[];
    private _stores: { _id: string, hr_id?: string, label?: string }[]
    get stores() { return this._stores; }
    @Input() set stores(newStores: { _id: string, hr_id?: string, label?: string }[]) {
        this._stores = newStores;
        const ssf = this.utils.readShowStockFrom(this._stores, this.currentUser);
        this.currentFilter.ssf = ssf;
    }

    @Output() newReportModeChanged = new EventEmitter<boolean>();
    @Output() newReport = new EventEmitter<{ newReport: RoastReport, newReportOverview: RoastReportOverview }>();
    report: RoastReport;
    reportOverview: RoastReportOverview;

    /** the "internal" name, e.g. 2019-001 */
    reportName: string;
    /** the user defined label */
    label: string;
    /** true if the user at least once changed that name */
    labelWasTouched = false;

    // allOrigins: { translated: string, english: string }[] = [];
    // filteredOrigins: { translated: string, english: string }[] = [];
    // origins: string[] = [];
    // translatedOrigins: string[] = [];
    // curOriginsFilter: string;
    // showStockFrom: { _id: string, hr_id ?: string, label ?: string } [] | 'all' = 'all';

    // startDate: DateTime;
    // endDate: DateTime;

    // roasts: Roast[];
    invalidRoasts: Roast[];
    notInRangeRoasts: Roast[];
    saving = false;
    waiting = false;
    waiting2 = false;
    waiting3 = false;
    waiting4 = false;

    energyUnit = 'BTU';
    currentFilter: GetPageOptions = {};

    columnsToDisplay = ['Number', 'Date', 'Roast-ID', 'Coffees', 'In', 'Out', 'Loss', 'References'];
    columnsToDisplayInvalids = ['InvalidReason', 'Date', 'Roast-ID', 'Coffees', 'In', 'Out', 'Loss'];
    invalidsReport: RoastReport;
    notInRangeReport: RoastReport;
    // dataSourceInvalids: MatTableDataSource<Roast>;
    // dataSourceNotInRange: MatTableDataSource<Roast>;

    // showOrganic: 'on' | 'off' | '' = '';
    // organicButtonInactive = false;

    private ngUnsubscribe = new Subject();

    @ViewChild('table') table: RoastreportTableComponent;
    @ViewChild('invalidsTable') invalidsTable: RoastreportTableComponent;
    @ViewChild('notinrangeTable') notInRangeTable: RoastreportTableComponent;
    @ViewChild('hiddenElems') hiddenElems: ElementRef;
    @ViewChild('reportTableElem') reportTableElem: ElementRef;


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        this.energyUnit = this.currentUser.energy_unit || 'BTU';

        this.report = this.isOpenReport ? new RoastReportOpen() : new RoastReport();

        if (this.lastEndDate?.isValid) {
            // have a last end date, use as "from" date (also means it it _not_ the first report)
            this.currentFilter.from = this.lastEndDate.plus({ milliseconds: 1 });
            // this.currentFilter.to = DateTime.now().minus({ month: 1 }).endOf('month');
            this.currentFilter.to = this.currentFilter.from.endOf('month');
            if (this.isOpenReport && this.currentFilter.to.diff(this.currentFilter.from, 'years').years > 1) {
                this.currentFilter.from = DateTime.now().minus({ month: 1 }).startOf('month');
                this.currentFilter.to = this.currentFilter.from.endOf('month');
            }
            // if (this.currentFilter.to.isBefore(this.currentFilter.from)) {
            //     this.currentFilter.to = DateTime.now().endOf('day');
            // } else if (this.isOpenReport && this.currentFilter.to.diff(this.currentFilter.from, 'years') > 1) {
            //     this.currentFilter.from = this.currentFilter.to.startOf('month');
            // }
            this.reportName = this.reportService.createReportName(this.lastReportNumber, this.currentFilter.from.year);
            this.label = this.reportService.suggestLabel(this.currentFilter, this.reportName, this.reportNames);

            this.getRoastsWithin();
            // don't get missing roasts before that date; user already decided not to include them #383
            // this.getAllBefore(this.currentFilter.from);

        } else {
            // first report
            this.currentFilter.from = DateTime.now().minus({ month: 1 }).startOf('month');
            if (!this.isOpenReport) {
                this.waiting = true;
                this.reportService.getFirstTransactionDate(['roast'])
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            this.waiting = false;
                            if (response.success === true) {
                                if (response.result) {
                                    this.currentFilter.from = DateTime.fromMillis(response.result).startOf('month');
                                    this.currentFilter.to = this.currentFilter.from.endOf('month');
                                    this.reportName = this.reportService.createReportName(this.lastReportNumber, this.currentFilter.from.year);
                                    this.label = this.reportService.suggestLabel(this.currentFilter, this.reportName, this.reportNames);
                                    this.getRoastsWithin();
                                    this.getAllBefore();
                                }
                            } else {
                                this.logger.warn('error getting first roast date');
                                // this.utils.handleError('error retrieving reports', response.error);
                            }
                        },
                        error: error => {
                            this.waiting = false;
                            this.logger.warn('error getting first roast date', error);
                            // this.utils.handleError('error retrieving reports', error);
                        }
                    });
            } else { // this.isOpenReport
                this.currentFilter.from = DateTime.now().startOf('month');
                this.currentFilter.to = this.currentFilter.from.endOf('month');
                this.reportName = this.reportService.createReportName(this.lastReportNumber, this.currentFilter.from.year);
                this.label = this.reportService.suggestLabel(this.currentFilter, this.reportName, this.reportNames);
                this.getRoastsWithin();
            }
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    filterChanged($event: { options: GetPageOptions }): void {
        this.currentFilter = $event.options;
        if (this.currentFilter.from?.isValid) {
            this.reportName = this.reportService.createReportName(this.lastReportNumber, this.currentFilter.from.year);
            if (this.currentFilter.to?.isValid && !this.labelWasTouched) {
                // suggest label based on dates etc. - if user has not changed it before
                const oneStore = (this.currentFilter.ssf !== 'all' && this.currentFilter.ssf?.length === 1) ? this.currentFilter.ssf[0]?.label : undefined;
                this.label = this.reportService.suggestLabel(this.currentFilter, this.reportName, this.reportNames, oneStore);
            }
        }
        this.getRoastsWithin();
    }

    datesChanged(startDateChanged = false): void {
        if (!this.currentFilter.from || !this.currentFilter.to) {
            this.alertService.error(this.tr.anslate('Invalid date'));
            return;
        }
        if (startDateChanged && !this.lastReportNumber) {
            this.reportName = this.reportService.createReportName(this.lastReportNumber, this.currentFilter.from.year);
        }
        
        this.currentFilter.from = this.currentFilter.from.startOf('day');
        if (this.currentFilter.to < this.currentFilter.from) {
            this.currentFilter.to = this.currentFilter.from.endOf('month');
        }
        this.currentFilter.to = this.currentFilter.to.endOf('day');
        if (!this.labelWasTouched) {
            // suggest label based on dates etc. - if user has not changed it before
            const oneStore = (this.currentFilter.ssf !== 'all' && this.currentFilter.ssf?.length === 1) ? this.currentFilter.ssf[0]?.label : undefined;
            this.label = this.reportService.suggestLabel(this.currentFilter, this.reportName, this.reportNames, oneStore);
        }
        this.getRoastsWithin();
        if (!this.isOpenReport) {
            this.getAllBefore();
        }
    }

    allowablesChanged(): void {
        // could optimise if this.fullyLoaded && this.fullyLoadedInvalids
        // but this is a lot of work for a specific case
        this.getRoastsWithin();
        if (!this.lastEndDate) {
            this.getAllBefore();
        }

        // this.reloadOverviewReport();

        // const allRoasts = (this.report.roasts || []).concat(this.invalidRoasts);
        // allRoasts.sort((r1, r2) => new Date(r1.date).valueOf() - new Date(r2.date).valueOf());
        // this.updateTables(allRoasts);
    }

    // originFilterChanged(changeEvent: MatSelectChange): void {
    //     this.translatedOrigins = changeEvent.value.map((entry: string) => this.tr.anslate(entry));
        
    //     // could optimise if this.fullyLoaded && this.fullyLoadedInvalids
    //     // but this is a lot of work for a specific case
    //     this.getRoastsWithin();

    //     if (!this.labelWasTouched) {
    //         // suggest label based on dates etc. - if user has not changed it before
    //         const oneStore = (this.currentFilter.ssf !== 'all' && this.currentFilter.ssf?.length === 1) ? this.currentFilter.ssf[0]?.label : undefined;
    //         this.label = this.reportService.suggestLabel(this.currentFilter, this.reportName, this.reportNames, oneStore);
    //     }
    // }

    // // called from the template when the dropdown value changed
    // showstockfromChanged($event: { value: { _id: string, hr_id?: string, label?: string }[] | 'all' }): void {
    //     // if ($event.value === 'all' || $event.value.length === this.stores.length) {
    //     //     // $event.value = 'all';
    //     //     $event.value = undefined;
    //     // }
    //     if (!$event.value?.length || $event.value.length === this.stores.length) {
    //         $event.value = 'all';
    //     }
    //     this.currentFilter.ssf = $event.value;
    //     this.utils.storeShowStockFrom($event.value, this.currentUser);

    //     this.getRoastsWithin();

    //     if (!this.labelWasTouched) {
    //         // suggest label based on dates etc. - if user has not changed it before
    //         const oneStore = (this.currentFilter.ssf !== 'all' && this.currentFilter.ssf?.length === 1) ? this.currentFilter.ssf[0]?.label : undefined;
    //         this.label = this.reportService.suggestLabel(this.currentFilter, this.reportName, this.reportNames, oneStore);
    //     }
    // }

    // showstockfromOpened(opened: boolean): void {
    //     if (!opened && !this.currentFilter.ssf?.length) {
    //         // nothing selected, change to 'all' for dropdown
    //         this.currentFilter.ssf = 'all';

    //         this.getRoastsWithin();

    //         if (!this.labelWasTouched) {
    //             this.label = this.reportService.suggestLabel(this.currentFilter, this.reportName, this.reportNames);
    //         }
    //     }
    // }

    // called on loadMoreClick
    loadAllRoastsForReport(toLoad: 'normal' | 'invalids' | 'both' = 'both', reason: 'forSort') {
        if (!toLoad && reason && this.report?.roastsCount > this.reportService.MAX_LIST_ROASTS) {
            const dialogRef = this.dialog.open(YesNoConfirmDialogComponent, {
                closeOnNavigation: true,
                data: { text: this.tr.anslate('This will load all {{nrItems}} items. This can slow down your browser. Proceed?', { nrItems: this.report.roastsCount }) }
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                if (result === true) {
                    this.getRoastsWithin(true, toLoad, true);
                }
            });
        } else {
            this.getRoastsWithin(true, toLoad, false, false);
        }
    }

    updateTables(roastList: Roast[], invalids = false, count = 0): void {
        if (!invalids) {
            // make a copy to have the table update itself
            const rep = Object.assign({}, this.report);
            rep.startDate = this.currentFilter.from;
            rep.endDate = this.currentFilter.to;
            rep.roasts = roastList?.filter(r => r) ?? [];
            rep.roastsCount = count ?? rep.roasts?.length ?? 0;
            this.report = rep;
        } else {
            // have a list of invalid roasts, just need to get the reason
            this.invalidRoasts = [];
            for (const roast of roastList) {
                if (!roast) {
                    continue;
                }
                const reason = this.reportService.isInvalidRoast(roast, { anpa: this.currentFilter.anpa });
                // if (!reason || (this.anpa && reason === this.tr.anslate('weight or yield not positive'))) {
                //     report.roasts.push(roast);
                // } else {
                roast.invalidReason = reason;
                this.invalidRoasts.push(roast);
                // }
            }
            this.invalidsReport = {
                roasts: this.invalidRoasts,
                _id: undefined,
                startDate: this.report.startDate,
                endDate: this.report.endDate,
                certInfo: this.report.certInfo,
            };
        }

        // setTimeout(() => {
        //     if (this.table) {
        //         this.table.updateDataSource(this.dataSource);
        //     }
        // });
        // invalidsTable is updated automatically through the invalids @Input of the table component 
        // setTimeout(() => {
        //     if (this.invalidsTable) {
        //         this.invalidsTable.recalc();
        //     }
        // });
    }

    // // called via template from RoastreportTableComponent on loadMore
    // onReportChanged(changedReport: RoastReport): void {
    //     // if the change means the report has 0 roasts now, the empty roast property will
    //     // have been deleted (i.e. not present) => re-set it to empty array
    //     if (!changedReport.roasts) {
    //         changedReport.roasts = [];
    //     }
    //     // TODO check whether / which ones are necessary
    //     this.utils.updateObject(this.report, changedReport);
    //     // this.recalcInfo(/*true*/);
    //     this.updateTables(changedReport.roasts);
    // }

    // updateOriginSet() {
    //     // get all origins used in this timeframe (if it is not restricted by origin)
    //     if (!this.currentFilter.origins?.length) {
    //         const originSet = new Set<string>();
    //         const originregionSet = new Set<string>();
    //         for (let c = 0; c < this.reportOverview?.coffees?.length; c++) {
    //             const entry = this.reportOverview.coffees[c];
    //             if (entry?.coffee?.origin_region) {
    //                 originregionSet.add(entry.coffee.origin_region);
    //             }
    //             if (entry?.coffee?.origin) {
    //                 originSet.add(entry.coffee.origin);
    //             }
    //         }
    //         const oregions = Array.from(originregionSet).sort().map(oreg => ({ english: oreg, translated: this.tr.anslate(oreg).toLocaleUpperCase() }));
    //         this.allOrigins = oregions.concat(Array.from(originSet).sort().map(origin => ({ english: origin, translated: this.tr.anslate(origin) })));
    //         this.filteredOrigins = this.allOrigins;
    //     }
    // }

    // called (maybe from children) when the report needs to be reloaded
    reloadOverviewReport() {
        this.waiting2 = true;
        this.reportService.getRoastReportOverview(undefined, this.isOpenReport, this.currentFilter)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.reportOverview = response.result;
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                    this.waiting2 = false;
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                    this.waiting2 = false;
                }
            });
    }

    getRoastsWithin(loadAll = false, toLoad: 'normal' | 'invalids' | 'both' = 'both', unlimited = false, reloadOverview = true): void {
        let limit = unlimited ? undefined : (loadAll ? this.reportService.MAX_LIST_ROASTS : this.reportService.STD_LIMIT);
        if (toLoad === 'normal' || toLoad === 'both') {
            this.waiting = true;
            this.report.fullyLoaded = false;

            if (reloadOverview) {
                this.reloadOverviewReport();
            }

            if (unlimited) {
                // user has been warned
                limit = 0;
            } else {
                // we allow NaN as a result here
                const toLoad = this.report.roastsCount - this.report.roasts?.length;
                if (loadAll && toLoad > 150) {
                    // need to load at least 150 items; limit to 100 more each time
                    // up to MAX_LIST_ROASTS
                    limit = Math.min(100 + (this.report.roasts?.length || 0), this.reportService.MAX_LIST_ROASTS);
                }
            }
            const filterCopy = { ...this.currentFilter};
            filterCopy.limit = limit;
            // this does not return roasts with missing information
            this.reportService.getRoastsWithin(this.isOpenReport, filterCopy)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.updateTables(this.utils.dateifyRoasts(response.result), false, response.count);
                        } else {
                            this.utils.handleError('error retrieving reports', response.error);
                        }
                        this.report.fullyLoaded = response.count === response.result.length;
                        // save last response.count so the next loadMore call can
                        // decide how many to load
                        this.report.roastsCount = response.count;
                        this.report.filter = this.currentFilter;
                        // this.organicButtonInactive = false;
                        this.waiting = false;
                    },
                    error: error => {
                        this.utils.handleError('error retrieving reports', error);
                        this.report.fullyLoaded = false;
                        this.waiting = false;
                    }
                });
        }

        if (toLoad === 'invalids' || toLoad === 'both') {
            this.waiting3 = true;
            if (this.invalidsReport) {
                this.invalidsReport.fullyLoaded = false;
            }
             
            this.reportService.getRoastsWithMissingInfo(this.isOpenReport, this.currentFilter)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.updateTables(this.utils.dateifyRoasts(response.result), true);
                            // we don't have response.count here
                            // this.fullyLoadedInvalids = response.count === response.result.length;
                            // cannot be sure if response.result.length === limit:
                            this.invalidsReport.fullyLoaded = !limit || response.result.length < limit;
                        } else {
                            this.utils.handleError('error retrieving reports', response.error);
                        }
                        this.waiting3 = false;
                    },
                    error: error => {
                        this.utils.handleError('error retrieving reports', error);
                        this.waiting3 = false;
                    }
                });
        }
    }

    getAllBefore(date = this.currentFilter.from): void {
        this.waiting4 = true;
        // TODO need to filter r => !r.report on the server!
        const filterCopy = {...this.currentFilter};
        filterCopy.from = DateTime.fromMillis(0);
        filterCopy.to = date;
        this.reportService.getRoastsWithin(this.isOpenReport, filterCopy)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.notInRangeRoasts = this.utils.dateifyRoasts(response.result.filter(r => !r.report));
                        for (const nirr of this.notInRangeRoasts) {
                            nirr.invalidReason = this.tr.anslate('before selected period');
                        }
                        this.notInRangeReport = {
                            roasts: this.notInRangeRoasts,
                            _id: undefined,
                            startDate: this.report.startDate,
                            endDate: this.report.endDate,
                            certInfo: this.report.certInfo,
                        };
                        setTimeout(() => {
                            if (this.notInRangeTable) {
                                this.notInRangeTable.recalc();
                            }
                            this.waiting4 = false;
                        });
                        if (!this.notInRangeRoasts?.length) {
                            this.waiting4 = false;
                        }
                    } else {
                        this.utils.handleError('error retrieving early reports', response.error);
                        this.waiting4 = false;
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving early reports', error);
                    this.waiting4 = false;
                }
            });
    }

    // /**
    //  * Returns the number of not reconciled roasts.
    //  */
    // getNrOfOpenRoasts(): number {
    //     if (!this.report.roasts) {
    //         return 0;
    //     }
    //     return this.report.roasts.reduce((cnt, roast) => cnt += roast.reconciled ? 0 : 1, 0);
    // }

    createReport(): void {
        if (!this.report.roasts) {
            return;
        }
        this.saving = true;

        // generate a new report object
        const reportToSend = this.isOpenReport ? new RoastReportOpen() : new RoastReport();
        reportToSend.created = DateTime.now();
        reportToSend.startDate = this.currentFilter.from;
        reportToSend.endDate = this.currentFilter.to;
        reportToSend.label = this.label;
        // server doesn't return the list of included roasts, save them
        const cachedroasts = this.report.roasts;
        reportToSend.number = this.reportName;
        reportToSend.certInfoOnCreation = this.reportService.getCertInfo(this.currentFilter.showOrganic);
        const filterCopy = this.utils2.cleanResult(cloneDeep(this.currentFilter));
        this.utils2.cleanLoadedFilter(filterCopy);
        // those are stored directly at the report (startDate, endDate)
        delete filterCopy.from;
        delete filterCopy.to;
        reportToSend.filter = filterCopy;

        // TODO filter is sent twice (reportCopy.filter and filterCopy as HTTP params)
        this.reportService.addRoastReport(reportToSend, this.isOpenReport, filterCopy)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success) {
                        this.alertService.success(this.tr.anslate('Successfully added'));
                        // the returned report doesn't contain the roasts
                        this.report = this.utils.dateifyRoastReports([response.result])?.[0];
                        this.report.roasts = cachedroasts;
                        // this.report.roastsCount = this.report.roasts?.length || 0;
                        this.report.fullyLoaded = this.report.roastsCount === this.report.roasts?.length;
                        this.newReport.emit({ newReport: this.report, newReportOverview: this.reportOverview });
                        this.newReportModeChanged.emit(false);

                        this.currentUser = merge(this.currentUser, { account: { settings: { pref_roastreport_type: this.isOpenReport ? 'open' : 'fixed' } } });
                        this.userService.storeCurrentUser(this.currentUser);
                    } else {
                        this.utils.handleError('error adding report', response.error);
                    }
                    this.saving = false;
                },
                error: error => {
                    this.utils.handleError('error adding report', error);
                    this.saving = false;
                }
            });
    }

    cancel(): void {
        this.newReportModeChanged.emit(false);
    }

    // // called from template
    // showOrganicChanged(): void {
    //     if (this.organicButtonInactive) {
    //         return;
    //     }
    //     this.organicButtonInactive = true;
    //     this.currentFilter.showOrganic = this.utils.getNextShowOrganicState(this.currentFilter.showOrganic);
    //     if (!this.labelWasTouched) {
    //         const oneStore = (this.currentFilter.ssf !== 'all' && this.currentFilter.ssf?.length === 1) ? this.currentFilter.ssf[0]?.label : undefined;
    //         this.label = this.reportService.suggestLabel(this.currentFilter, this.reportName, this.reportNames, oneStore);
    //     }
    //     this.getRoastsWithin();
    //     if (!this.lastEndDate && !this.isOpenReport) {
    //         this.getAllBefore();
    //     }
    // }

    scrollToMissing(): void {
        this.hiddenElems?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }

    scrollToListOfRoasts(): void {
        this.reportTableElem?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }

    // filterOrigins(value: string): { translated: string, english: string }[] {
    //     this.curOriginsFilter = value;
    //     if (!value) {
    //         // return [];
    //         return this.allOrigins;
    //         // return this.allOrigins.map(entry => entry.english);
    //     }
    //     const filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    //     return this.allOrigins.filter(region => region.translated.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0);
    // }
}
