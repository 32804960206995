<a [routerLink]="['/welcome']">
    <div class="backdrop" [ngClass]="{ 'animated': doblur }">
        <div class="background-mask"></div>
    </div>
</a>
@if (!isNewlyCreated) {
<div class="ap-popuplike">
    <div style="margin-bottom: 10px;">
        @if (!readonly_user) {
        <span i18n="multi users$$explain that this login will add the user to someone else's account@@you have been invited">You have been invited to join the account of {{byEmail}}</span>
        } @else {
        <span i18n="multi users$$explain that this login will let the user view someone else's data (readonly)@@you have been invited, readonly">You have been invited to see the data of the account of {{byEmail}}</span>
        }
        @if (!byEmail) {
        <mat-spinner class="in-text-spinner white-spinner" diameter="15"></mat-spinner>
        }
    </div>
    <form name="form">
        <mat-form-field class="form-group" hideRequiredMarker>
            <mat-label i18n="users$$email address@@email">Email</mat-label>
            <input matInput required disabled email
                autocomplete="username email" type="email" name="email"
                placeholder="Email" i18n-placeholder="users$$email address@@email"
                [ngModel]="email">
        </mat-form-field>
        <mat-form-field class="form-group" hideRequiredMarker>
            <mat-label i18n="users$$password@@password">Password</mat-label>
            <input matInput required autofocus
                autocomplete="current-password" type="password" name="password"
                placeholder="Password" i18n-placeholder="users$$password@@password"
                [(ngModel)]="password">
        </mat-form-field>
        <a class="reset-password" [routerLink]="['/resetPassword']" i18n="users$$link to reset password page, button@@reset_password">RESET PASSWORD</a>
        <div class="form-group mainbutton">
            <button [disabled]="loading || !email || !password" mat-raised-button
                type="submit" class="primary-element mainbutton"
                (click)="login(false)"
                i18n="multi users$$join invitation to other account, button@@add account">ADD ACCOUNT</button>
        </div>
    </form>
    @if (loading) {
    <mat-spinner></mat-spinner>
    }
</div>
}

@if (isNewlyCreated) {
<div class="ap-popuplike">
    <div class="explanation-text">
        @if (!readonly_user) {
        <span i18n="multi users$$explain that this login will add the user to someone else's account@@you have been invited">You have been invited to join the account of {{byEmail}}</span>
        } @else {
        <span i18n="multi users$$explain that this login will let the user view someone else's data (readonly)@@you have been invited, readonly">You have been invited to see the data of the account of {{byEmail}}</span>
        }
        @if (!byEmail) {
        <mat-spinner class="in-text-spinner white-spinner" diameter="15"></mat-spinner>
        }
    </div>
    <form name="form">
        <mat-form-field class="form-group" hideRequiredMarker>
            <mat-label i18n="users$$email address@@email">Email</mat-label>
            <input matInput required disabled email
                autocomplete="username email" type="email" name="email"
                placeholder="Email" i18n-placeholder="users$$email address@@email"
                [ngModel]="email">
        </mat-form-field>
        <mat-form-field class="form-group" hideRequiredMarker>
            <input autofocus i18n-placeholder="users$$new password@@register_new_password"
                type="password" matInput autocomplete="new-password" placeholder="New password"
                name="password" [(ngModel)]="password"
                required>
        </mat-form-field>
        <div class="form-group mainbutton">
            <button [disabled]="loading || !email || !password" mat-raised-button
                type="submit" class="primary-element mainbutton"
                (click)="login(true)"
                i18n="multi users$$join invitation to other account, button@@add account">ADD ACCOUNT</button>
        </div>
    </form>
    @if (loading) {
    <mat-spinner></mat-spinner>
    }
</div>
}