<ng-template #missingInfo>---</ng-template>

<table mat-table [dataSource]="dataSource" class="wide-table mat-elevation-z8" #table
    matSort matSortActive="Date" matSortDirection="desc">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let transaction; columns: columnsToDisplay" class="report-transaction-row"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplay" class="sum-cell"></tr>

    <ng-container matColumnDef="InvalidReason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="reports$$Reason@@Reason">Reason</th>
        <td mat-cell *matCellDef="let transaction">
            <span class='error-reason'>{{transaction.invalidReason}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef colspan="3"></td>
    </ng-container>

    <ng-container matColumnDef="Number">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let transaction; let row=index" [ngClass]="{'deleted-transaction': transaction.deleted === true}">
            {{(dataSource.data.length - row).toString().padStart(3, '0')}}
            @if (!readOnly && editMode) {
            <a (click)="editTransaction(transaction)" (keydown.enter)="editTransaction(transaction); $event.preventDefault()" role="button" [tabindex]="0">
                <mat-icon class="smallicon">edit</mat-icon>
            </a>
            <a (click)="deleteTransaction(transaction, row)" (keydown.enter)="deleteTransaction(transaction, row); $event.preventDefault()" role="button" [tabindex]="0">
                <mat-icon class="smallicon smallicon-second">delete</mat-icon>
            </a>
            }
            @if (!readOnly && editMode && checkingIfDeletable === row) {
            <a>
                <mat-spinner class="button-spinner" diameter="13" style="display: inline;"></mat-spinner>
            </a>
            }
            <br />
            {{transaction.name}}
            @if (transaction.deleted === true) {
            <br />
            (<span class="deleted-transaction" i18n="generic$$tag used to indicate that an item has been deleted@@deleted">deleted</span>)
            }
        </td>
        <td mat-footer-cell *matFooterCellDef colspan="2"><span i18n="generic$$total number of some items@@Total">Total</span>: {{(dataSource.data || []).length}}</td>
    </ng-container>

    <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" arrowPosition="after" i18n="generic$$date placeholder or header@@Date">Date</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction.date) {
            <span>{{transaction.date.toLocaleString(DateTime.DATE_SHORT)}}</span>
            } @else {
            ---
            }
            <br />
            @if (transaction.date) {
            <span>{{transaction.date.toLocaleString(DateTime.TIME_SIMPLE)}}</span>
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>

    <ng-container matColumnDef="Cost">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="stores$$cost, table header@@Cost">Cost</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction.price) {
            <span>{{(transaction.__t === 'Sale' ? -transaction.price : transaction.price) | currency:currency:'symbol-narrow':'1.0-0'}}</span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef>
            @if (showTotals) {
            {{sumPrice | currency:currency:'symbol-narrow':'1.0-0'}}
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="Transaction-ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="generic$$ID@@ID">ID</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction.order_number) {
            <span>{{transaction.order_number}}</span>
            }
            @if (transaction.sales_number) {
            <span>{{transaction.sales_number}}</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="Supplier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="contacts$$supplier@@Supplier">Supplier</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction.supplier?.label) {
            <span><a [routerLink]="['/contacts', {id: transaction.supplier?._id}]" class="clipped-string">{{transaction.supplier.label}}</a></span>
            } @else if (transaction.__t === 'Purchase') {
            <span><ng-container *ngTemplateOutlet="missingInfo"></ng-container></span>
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>

    <ng-container matColumnDef="Customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="contacts$$customer@@Customer">Customer</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction.customer?.label) {
            <span><a [routerLink]="['/contacts', {id: transaction.customer?._id}]" class="clipped-string">{{transaction.customer.label}}</a></span>
            } @else if (transaction.__t === 'Sale') {
            <span><ng-container *ngTemplateOutlet="missingInfo"></ng-container></span>
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>

    <ng-container matColumnDef="Store">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="generic$$storage location@@Store">Store</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction.location?.label) {
            <span>@if (transaction.location?.hr_id) {
                <a [routerLink]="['/stores', {id: transaction.location.hr_id}]">{{transaction.location.hr_id}}</a>
                } {{transaction.location.label}}</span>
            } @else {
            ---
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>

    <ng-container matColumnDef="Target Store">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="transaction$$Target store placeholder@@Target store">Target store</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction.target?.label) {
            <span>@if (transaction.target?.hr_id) {
                <a [routerLink]="['/stores', {id: transaction.target.hr_id}]">{{transaction.target.hr_id}}</a>
                } {{transaction.target.label}}</span>
            } @else if (transaction.__t === 'Transfer') {
            <span><ng-container *ngTemplateOutlet="missingInfo"></ng-container></span>
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>

    <ng-container matColumnDef="Coffees">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="generic$$Beans@@Beans">Beans</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction?.coffee) {
            <span>
                @if (transaction.coffee.hr_id) {
                <a [routerLink]="['/coffees', {id: transaction.coffee.hr_id}]">{{transaction.coffee.hr_id}}</a>
                }
                @if (transaction.coffee.origin) {
                <span> {{tr.anslate(transaction.coffee.origin)}}</span>
                }
                @if (transaction.coffee.yearLabel) {
                <span> {{transaction.coffee.yearLabel}}</span>
                }
                @if (transaction.coffee.label && (transaction.coffee.hr_id || transaction.coffee.origin || transaction.coffee.yearLabel)) {
                <br />
                }
                @if (transaction.coffee.label) {
                <span> {{transaction.coffee.label}}</span>
                }
                <app-organicicon [isOrganic]="utils.isOrganicCoffee(transaction.coffee)" [size]="11" [leftMargin]="5"></app-organicicon>
            </span>
            }
            <!-- cannot be a blend -->
            <!-- <span *ngIf="transaction?.blend as blend">
                <span *ngIf="blend.label">{{blend.label}}:</span>
                <ul class="ingredients-list">
                    <li *ngFor="let ing of blend.ingredients">
                    <span *ngIf="ing.ratio != null">{{ing.ratio * 100 | number:'1.1-1'}}%
                        <a *ngIf="ing.coffee?.hr_id" [routerLink]="['/coffees', {id: ing.coffee.hr_id}]"> {{ing.coffee.hr_id}}</a>
                        <span *ngIf="ing.coffee?.origin"> {{tr.anslate(ing.coffee.origin)}}</span>
                        <span *ngIf="ing.coffee?.yearLabel"> {{ing.coffee.yearLabel}}</span>
                        <span *ngIf="ing.coffee?.label && (ing.coffee.origin || ing.coffee.yearLabel)">,</span>
                        <span *ngIf="ing.coffee.label"> {{ing.coffee.label}}</span>
                    </span>
                    </li>
                </ul>
                </span> -->
            @if (!transaction.coffee && !transaction.blend) {
            <ng-container *ngTemplateOutlet="missingInfo"></ng-container>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
        <!-- <td mat-footer-cell *matFooterCellDef class="right-align"><ng-container *ngIf="showTotals"><span i18n="generic$$indicating the sum of some numbers@@Sum">Sum</span>:</ng-container></td> -->
    </ng-container>

    <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" arrowPosition="after" i18n="generic$$Amount (of beans, e.g. in kg or bags)@@Amount">Amount</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction.amount != null) {
            <span><span>{{((transaction.__t === 'Sale' ? -1 : 1) * utils.convertToUserUnit(transaction.amount, currentUser?.unit_system)) | number:'1.0-3'}}{{mainUnit}}</span></span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef>
            @if (showTotals) {
            @if (utils.convertToUserUnit(sum, currentUser?.unit_system); as thesum) {
            <span>{{thesum | number:'1.0-3'}}{{mainUnit}}</span>
            } @else {
            ---
            }
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="Reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="reports$$Reason@@Reason">Reason</th>
        <td mat-cell *matCellDef="let transaction">
            @if (transaction.reason) {
            <span>{{transaction.reason}}</span>
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>

    <ng-container matColumnDef="References">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" class="centered" class="text-centered" i18n="reports$$References@@References">References</th>
        <td mat-cell *matCellDef="let transaction; let r=index" class="text-centered">
            @if (!additionalIsEditable) {
            <span class="additional-field">{{transaction.reportNote}}</span>
            }
            @if (additionalIsEditable) {
            <mat-form-field class="additional-field form-group">
                <input matInput type="text" name="additional" [(ngModel)]="transaction.reportNote" [@animateField]="animateFieldState"
                    (blur)="additionalNoteChanged[transaction._id].next(transaction)" (ngModelChange)='additionalNoteChanged[transaction._id].next(transaction)'
                    [disabled]="readOnly">
            </mat-form-field>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
</table>