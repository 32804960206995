@if (!readOnly) {
<button [disabled]="newReportMode || readOnly" type="button" mat-raised-button
    class="add-button action-button shifted-add-button" data-test="bttn_add"
    (click)="add()">
    <mat-icon>add</mat-icon>
</button>

@if (showHint || isOpenReport) {
<div class="actionbar" [ngStyle]="{'display': (isSmall$ | async) ? 'block' : 'flex'}" style="margin-top: 0;">
    @if (showHint && isOpenReport) {
    <mat-hint data-test="hnt_free" class="hint-left" [ngStyle]="{'width': (isSmall$ | async) ? '100%' : '50%'}">
        <span i18n="reports$$hint explaining what 'open' roast reports are in contrast to strict, 'sequential' ones@@You can freely choose your report intervals here.">
            You can freely choose your report intervals here.
        </span>
        <a class="close-icon" (click)="removeHint()" (keydown.enter)="removeHint(); $event.preventDefault()" role="button" [tabindex]="0">&times;</a>
    </mat-hint>
    } @else if (showHint) {
    <mat-hint data-test="hnt_fixed" class="hint-left" [ngStyle]="{'width': (isSmall$ | async) ? '100%' : '50%'}">
        <span i18n="reports$$hint explaining what strict, 'sequential' roast reports are in contrast to open ones@@Reports created here must be consecutive (no overlaps or gaps). They can be finalized for official purposes.">
            Reports created here must be consecutive (no overlaps or gaps). They can be finalized for official purposes.
        </span>
        <a class="close-icon" (click)="removeHint()" (keydown.enter)="removeHint(); $event.preventDefault()" role="button" [tabindex]="0">&times;</a>
    </mat-hint>
    }
</div>
}
}

@if (!newReportMode) {
<div>
    @if (!displayCache) {
    <div class="main-note">
        <span i18n="reports$$currently loading reports@@loading reports">Loading reports ...</span>
        <mat-spinner class="main-note" diameter="20"></mat-spinner>
    </div>
    } @else if (!displayCache.length) {
    <div data-test="dv_noReports"
        class="main-note" i18n="reports$$no reports@@no reports">No reports available yet.</div>
    } @else if (suggestCopyFromFixed) {
    <div class="top-space report-container">
        <div i18n="roastreports$$move reports to open section@@You can COPY or MOVE all your roast reports from the 'Sequential' section here:"
            data-test="dv_copyFromSequential" style="margin-bottom: 15px;">
            You can COPY or MOVE all your roast reports from the 'Sequential' section here:
        </div>
        <button [disabled]="copying || readOnly" data-test="bttn_cfsCopy" type="button"
            (click)="moveFromFixed(true)" class="add-button action-button" mat-raised-button>
            <mat-icon>content_copy</mat-icon>
        </button>
        <button [disabled]="copying || readOnly" data-test="bttn_cfsMove" mat-raised-button
            (click)="moveFromFixed()" type="button" class="add-button action-button">
            <mat-icon>content_cut</mat-icon>
        </button>
        @if (copying) {
        <mat-spinner class="main-note" diameter="20"></mat-spinner>
        }
    </div>
    }
    @if (isOpenReport && displayCache?.length) {
    <div data-test="dv_sortbar" class="sortbar">
        <div class="sortbar-inner">
            <mat-button-toggle-group [disabled]="editReportMode >= 0"
                [(ngModel)]="currentSort.active"
                [ngClass]="{'inverse': currentSort.direction === 'asc'}"
                class="sortButtonGroup" [hideSingleSelectionIndicator]="true">
                <mat-button-toggle (click)="sortOrderChanged()" value="date"
                    i18n="generic$$date placeholder or header@@Date"
                    data-test="tggl_date" class="sortButton">Date</mat-button-toggle>
                <mat-button-toggle (click)="sortOrderChanged()" value="lastmodified"
                    i18n="generic$$sort according to when the items have been modified@@Last modified"
                    data-test="tggl_lastmodified" class="sortButton">Last modified</mat-button-toggle>
                <mat-button-toggle (click)="sortOrderChanged()" value="label"
                    i18n="generic$$Name@@Name"
                    data-test="tggl_name" class="sortButton">Name</mat-button-toggle>
                <mat-button-toggle (click)="sortOrderChanged()" value="organic"
                    i18n="certificationproperties$$CertificationProperties@@organic"
                    data-test="tggl_organic" class="sortButton">Organic</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    }
    @if (objectCount >= 5) {
    <mat-paginator [length]="objectCount" [pageSize]="pageSize" [disabled]="editReportMode >= 0"
        [pageSizeOptions]="[5, 10, 25, 50]" [pageIndex]="pageIndex" (page)="pagingChanged($event)">
    </mat-paginator>
    }
    @if (roastreports?.length) {
    <mat-accordion displayMode="default" multi="true">
        @for (roastreport of roastreports; track roastreport?.label) {
        <app-roastreport
            [report]="roastreport"
            [reportOverview]="roastreportOverviews?.[$index]"
            [currentUser]="currentUser"
            [isOpenReport]="isOpenReport"
            [missingRoasts]="isOpenReport ? undefined : missingRoasts[$index]"
            [isNewest]="$index === 0"
            [isOnly]="roastreports.length === 1"
            [canBeReconciled]="!isOpenReport && (($index === roastreports.length - 1) || (!!roastreports[$index + 1]['reconciled']))"
            [stores]="stores"
            [readOnly]="readOnly"
            [idToHighlight]="idToHighlight"
            [index]="$index"
            [editmode]="editReportMode"
            (editmodeChanged)="editReportMode=$event"
            (reportReconciled)="onReportReconciled($index, $event)"
            (reportDeleted)="onReportDeleted($event)">
        </app-roastreport>
        }
    </mat-accordion>
    }
    @if (objectCount >= 5 && pageSize >= 10) {
    <mat-paginator [length]="objectCount" [pageSize]="pageSize" [disabled]="editReportMode >= 0"
        [pageSizeOptions]="[5, 10, 25, 50]" [pageIndex]="pageIndex" (page)="pagingChanged($event)" style="margin-top: 4px;">
    </mat-paginator>
    }
</div>
}

@if (!readOnly && newReportMode && displayCache) {
<div class="top-space">
    <app-newroastreport
        [currentUser]="currentUser"
        [isOpenReport]="isOpenReport"
        [stores]="stores"
        [lastEndDate]="lastEndDate"
        [lastReportNumber]="lastNumber"
        [reportNames]="reportNames"
        (newReportModeChanged)="onNewReportModeChanged($event)"
        (newReport)="onNewReport($event)">
    </app-newroastreport>
</div>
}