import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/modules/frame/services/auth-guard.service';
import { ContactsComponent } from './contacts.component';

const routes: Routes = [
  {
      path: '',
      component: ContactsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactRoutingModule { }
