import { FormsModule } from '@angular/forms';
import { TestComponent } from './test.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestRoutingModule } from './test-routing.module';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';

@NgModule({
    declarations: [
        TestComponent
    ],
    imports: [
        CommonModule,
        TestRoutingModule,
        FormsModule,
        MyMaterialModule,
    ]
})
export class TestModule { }
