/* eslint-disable @typescript-eslint/no-explicit-any */
 
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';

import 'chartjs-adapter-luxon';
import 'chartjs-plugin-stacked100';
import { Chart, LineController, LineElement, LinearScale, PointElement, Title, Legend, Tooltip, TooltipItem, ChartConfiguration } from 'chart.js';
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, Legend, Tooltip);

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-roastmachine-graph',
    templateUrl: './roastmachine-graph.component.html',
    providers: [DecimalPipe]
})

export class RoastmachineGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private userService: UserService,
        private decimalPipe: DecimalPipe,
        private utils: Utils,
        private router: Router,
    ) { }

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currentUser: UserType;
    @Input() isDarkmode = false;
    @Input() latestYear = new Date().getFullYear();

    haveData = false;

    data: ChartConfiguration<'doughnut'>['data'];

    options: ChartConfiguration<'doughnut'>['options'] & { plugins: { roughness?: any } } = {
        plugins: {
            roughness: {
                disabled: true,
            },
            title: {
                display: true,
                text: this.tr.anslate('Machines'),
                position: 'top' as const,
            },
            legend: {
                display: true,
                position: 'bottom' as const,
                labels: {
                    color: Chart.defaults.color.toString(),
                },
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                mode: 'dataset' as const,
                intersect: true,
                displayColors: false,
                backgroundColor: 'rgba(66,66,66,0.8)', // '#424242',
                titleColor: 'rgba(255, 255, 255, 0)',
                bodyColor: '#212121DD',
                // bodyColor: 'rgba(0, 0, 0, 0)',
                callbacks: {
                    title: (tooltipItems: TooltipItem<'doughnut'>[]) => {
                        const datasetIndex = tooltipItems[0].datasetIndex;
                        return ((this.latestYear ?? new Date().getFullYear()) - datasetIndex).toString();
                    },
                    label: (tooltipItem: TooltipItem<'doughnut'>) => {
                        const datasetIndex = tooltipItem.datasetIndex;
                        const index = tooltipItem.dataIndex;
                        const roasts = this.data.datasets[datasetIndex]['roasts'][index];
                        if (roasts === 0) {
                            return null;
                        } else {
                            const name = this.data.labels[index];
                            const percent = Math.round(this.data.datasets[datasetIndex]['percentages'][index]);
                            const roasted = this.data.datasets[datasetIndex].data[index];
                            // roasted is in kg (no need to convert it for the graph)
                            const roastedFormated = this.utils.formatAmountForPipe(roasted, undefined, this.currentUser.unit_system);
                            const roastedFormatedFull = this.decimalPipe.transform(roastedFormated.value, '1.0-1') + roastedFormated.post;
                            const roastsString = roasts === 1 ? this.tr.anslate('one batch') : `${roasts} ${this.tr.anslate('batches')}`;
                            return `${name}: ${roastedFormatedFull} (${percent}%, ${roastsString})`;
                        }
                    }
                }
            },
        },
        hover: {
            mode: 'dataset',
            intersect: true,
        },
        rotation: 270,
        cutout: '30%',
        circumference: 180,
        // circumference: Math.PI,
        color: '#FFFF',
        // scaleFontColor: '#CCCCCC',
        animation: {
            animateRotate: false,
            animateScale: true
        },
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20
            }
        },
        aspectRatio: 1.4,
        maintainAspectRatio: false
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
    }

    setNewData(nd: any) {
        this.data = { labels: [], datasets: [] };
        this.haveData = false;

        if (nd?.data) {
            if (nd.labels?.length && nd.coffees &&
                nd.labels.length === nd.data.length &&
                nd.labels.length === nd.coffees.length &&
                nd.additionalData?.length > 1 &&
                nd.additionalData[0]?.data?.length === nd.labels.length &&
                nd.additionalData[1]?.data?.length === nd.labels.length
            ) {
                if (nd.labels.length > 1) {
                    // there are at least two machines
                    const total = nd.data.reduce((a: number, b: number) => a + b, 0);

                    // we remove the element where the name is null (however, we counted its values for the total sum!)
                    const nullIdx = nd.labels.indexOf(null);
                    if (nullIdx > -1) {
                        nd.labels.splice(nullIdx, 1);
                        nd.coffees.splice(nullIdx, 1);
                        nd.data.splice(nullIdx, 1);
                        nd.additionalData[0].data.splice(nullIdx, 1);
                        nd.additionalData[1].data.splice(nullIdx, 1);
                    }

                    if (total > 0) {
                        this.haveData = true;

                        this.data = { labels: nd.labels, datasets: [] };

                        const percentages = [];
                        for (let i = 0; i < nd.data.length; i++) {
                            percentages[i] = nd.data[i] * 100 / total;
                        }

                        // maximal 3 entries per dataset, thus we reduce to the 3 primary colors

                        this.data.datasets.push({
                            data: nd.data,
                            backgroundColor: [
                                '#db5785A0', // secondary-300
                                '#43a7cfA0', // primary-400
                                '#616161A0', // grey-700
                                '#9e9e9eA0', // grey-500
                            ],
                            hoverBackgroundColor: [
                                '#db5785DD', // secondary-300
                                '#43a7cfEE', // primary-400
                                '#616161EE', // grey-700
                                '#9e9e9eFF', // grey-500
                            ],
                            borderColor: this.isDarkmode ? '#303030' : '#fafafa',
                            hoverBorderColor: this.isDarkmode ? '#303030' : '#fafafa',
                            borderWidth: 4
                        });
                        this.data.datasets[this.data.datasets.length - 1]['roasts'] = nd.coffees;
                        this.data.datasets[this.data.datasets.length - 1]['percentages'] = percentages;

                        const lastyearTotal = nd.additionalData[1].data.reduce((a: number, b: number) => a + b, 0);
                        if (lastyearTotal > 0) {
                            const lastyearPercentages = [];
                            for (let i = 0; i < nd.additionalData[1].data.length; i++) {
                                lastyearPercentages[i] = nd.additionalData[1].data[i] * 100 / lastyearTotal;
                            }

                            this.data.datasets.push({
                                data: nd.additionalData[1].data,
                                backgroundColor: [
                                    '#db578550', // secondary-300
                                    '#43a7cf50', // primary-400
                                    '#61616150', // grey-700
                                    '#9e9e9e50', // grey-500
                                ],
                                hoverBackgroundColor: [
                                    '#db5785CC', // secondary-300
                                    '#43a7cfCC', // primary-400
                                    '#616161CC', // grey-700
                                    '#9e9e9eCC', // grey-500
                                ],
                                borderColor: this.isDarkmode ? '#303030' : '#fafafa',
                                hoverBorderColor: this.isDarkmode ? '#303030' : '#fafafa',
                                borderWidth: 4
                            });
                            this.data.datasets[this.data.datasets.length - 1]['roasts'] = nd.additionalData[0].data;
                            this.data.datasets[this.data.datasets.length - 1]['percentages'] = lastyearPercentages;
                        }
                    }
                }
            }
        }
        if (this.isDarkmode) {
            this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
            this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
            this.options.plugins.tooltip.backgroundColor = '#eeeeeeDD'; // G200/DD
            // this.options.plugins.legend.labels.color = '#eeeeeeDD'; // G200/DD
        } else {
            this.options.plugins.tooltip.titleColor = '#fff';
            this.options.plugins.tooltip.bodyColor = '#fff';
            this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
            // this.options.plugins.legend.labels.color = '#bdbdbd';
        }

        this.chart?.render();
    }
}
