import { StockType } from 'src/app/modules/stock/store-stock.component';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/util/utils';
import { PurchaseDialogComponent } from './dialogs/purchase-dialog.component';
import { CorrectDialogComponent, CorrectDialogResultType } from './dialogs/correct-dialog.component';
import { SellDialogComponent } from './dialogs/sell-dialog.component';
import { TransferDialogComponent } from './dialogs/transfer-dialog.component';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { StandardService } from 'src/app/util/services/standard.service';
import { ObjectChangedService } from 'src/app/util/services/objectchanged.service';
import { Location } from 'src/app/models/Location';
import { StockChange } from 'src/app/models/StockChange';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Transfer } from 'src/app/models/Transfer';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Purchase } from 'src/app/models/Purchase';
import { Sale } from 'src/app/models/Sale';
import { Correction } from 'src/app/models/Correction';
import { Coffee } from 'src/app/models/Coffee';
import { TransactionDialogComponent } from './dialogs/transaction-dialog.component';
import { YesNoWaitDialogComponent } from '../ui/dialog/yesno-wait-dialog.component';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class TransService {

    constructor(
        private utils: Utils,
        private dialog: MatDialog,
        private alertService: AlertService,
        private tr: TranslatorService,
        private standardService: StandardService,
        private objectChangedService: ObjectChangedService,
        private logger: NGXLogger,
        private http: HttpClient,
    ) { }

    getTransactions(storeId: string, coffeeId: string): Observable<{ success: boolean, result: StockChange[], error: string }> {
        storeId = storeId || 'undefined';
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/transactions/' + storeId + (coffeeId ? '/' + coffeeId : '');
        return this.http.get<{ success: boolean, result: StockChange[], error: string }>(url);
    }

    getPurchases(coffeeId: string, maxAmount?: number, maxDate?: DateTime): Observable<{ success: boolean, result: Purchase[], error: string }> {
        coffeeId = coffeeId || 'undefined';
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/transactions/purchases/' + coffeeId;
        let params = new HttpParams();
        if (maxAmount) params = params.set('maxAmount', maxAmount);
        if (maxDate) params = params.set('maxDate', maxDate?.toMillis());
        return this.http.get<{ success: boolean, result: Purchase[], error: string }>(url, { params });
    }

    editTransaction(transaction: StockChange, ngUnsubscribe: Subject<unknown>, callbackFn: (t: StockChange) => void): void {
        let dialogRef: MatDialogRef<TransactionDialogComponent, StockChange>;

        if (transaction.type === 'Purchase') {
            dialogRef = this.dialog.open(PurchaseDialogComponent, {
                closeOnNavigation: true,
                data: { transaction }
            });
        } else if (transaction.type === 'Correction') {
            dialogRef = this.dialog.open(CorrectDialogComponent, {
                closeOnNavigation: true,
                data: { stores: undefined, stocks: undefined, transaction }
            });
        } else if (transaction.type === 'Sale') {
            dialogRef = this.dialog.open(SellDialogComponent, {
                closeOnNavigation: true,
                data: { stores: undefined, stocks: undefined, transaction }
            });
        } else if (transaction.type === 'Transfer') {
            dialogRef = this.dialog.open(TransferDialogComponent, {
                closeOnNavigation: true,
                data: { stores: undefined, stocks: undefined, transaction }
            });
        }

        if (!dialogRef) {
            this.logger.fatal('edittransaction type "unknown": ' + transaction.type);
            return;
        }
        dialogRef.componentInstance.finished.subscribe(changeInfo => {
            if (!changeInfo) {
                dialogRef.close();
                return;
            }
            const changedTransaction = changeInfo['trans'] ?? changeInfo;
            this.doEditTransaction(changedTransaction, transaction, ngUnsubscribe, dialogRef, callbackFn);
        });
    }

    /**
     * Stores the given Location with its ID.
     * Uses _id, internal_hr_id, hr_id or the loc (string) itself as ID
     * @param map maps from ID to Location
     * @param loc the location to add
     */
    private tempSave(map: Map<string, Location>, loc: Location) {
        let id = loc._id;
        if (!id && typeof loc === 'string') {
            id = loc;
        }
        if (!id && loc.internal_hr_id) {
            id = loc.internal_hr_id + '';
        }
        if (!id && loc.hr_id) {
            id = loc.hr_id.slice(1);
        }
        if (id) {
            id = id.toString();
            map.set(id, loc);
        }
    }

    doEditTransaction(changedTransaction: StockChange, origTransaction: StockChange, ngUnsubscribe: Subject<unknown>, dialogRef: MatDialogRef<TransactionDialogComponent>, callbackFn: (t: StockChange) => void): void {
        if (changedTransaction) {
            changedTransaction._id = origTransaction._id;
            changedTransaction.type = origTransaction.type;
            const transModelName = (changedTransaction.type as string).toLowerCase();

            if (changedTransaction.type === 'Correction') {
                // indicate that this is a new (absolute) Correction (June 2019)
                changedTransaction['relative'] = false;
            }
            if (changedTransaction.coffee) {
                changedTransaction.coffee = changedTransaction.coffee._id as unknown as Coffee || changedTransaction.coffee;
            }

            dialogRef.componentInstance.waiting = true;
            this.standardService.update(transModelName + 's', changedTransaction)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (!response) {
                            this.alertService.success(this.tr.anslate('Nothing to change'));
                        } else if (response.success === true) {
                            this.alertService.success(this.tr.anslate('Successfully updated'));
                            if (response.result) {
                                changedTransaction = this.utils.dateifyStockChanges([response.result])?.[0];
                            }

                            callbackFn(changedTransaction);
                            // // make change locally
                            // origTransaction = changedTransaction;

                            // collect all stores that might be affected (esp. location and target)
                            // TODO this could be a normal Set<string> of location IDs
                            const relevantStores = new Map<string, Location>();

                            // notify parent and siblings
                            this.tempSave(relevantStores, origTransaction.location);
                            // this.objectChangedService.objectChanged({ model: 'stores', info: { store: origTransaction.location, type: 'transaction' }, reload: false });
                            this.objectChangedService.objectChanged({ model: 'coffees', info: { coffee: origTransaction.coffee, type: 'transaction' }, reload: true });

                            // if the (source) store has been changed, need to update this as well
                            if (!this.utils.compareObjectsFn(changedTransaction.location, origTransaction.location)) {
                                this.tempSave(relevantStores, changedTransaction.location);
                                // this.objectChangedService.objectChanged({ model: 'stores', info: { store: changedTransaction.location, type: 'transaction' }, reload: false });
                            }
                            // if it is a transfer, the target needs also to be notified
                            const origTransfer = origTransaction as Transfer;
                            if (origTransfer.target) {
                                this.tempSave(relevantStores, origTransfer.target);
                                // this.objectChangedService.objectChanged({ model: 'stores', info: { store: origTransfer.target, type: 'transaction' }, reload: false });

                                // if the (target) store has been changed, need to update this as well
                                const changedTransfer = changedTransaction as Transfer;
                                if (changedTransfer && !this.utils.compareObjectsFn(changedTransfer.target, origTransfer.target)) {
                                    this.tempSave(relevantStores, changedTransfer.target);
                                    // this.objectChangedService.objectChanged({ model: 'stores', info: { store: changedTransfer.target, type: 'transaction' }, reload: false });
                                }
                            }

                            // it might also have impact on stores in which a relevant coffee has some stock
                            for (let s = 0; s < changedTransaction.coffee?.stock?.length; s++) {
                                const stock = changedTransaction.coffee?.stock[s];
                                if (stock?.location) {
                                    this.tempSave(relevantStores, stock.location);
                                }
                            }
                            for (let s = 0; s < origTransaction.coffee?.stock?.length; s++) {
                                const stock = origTransaction.coffee?.stock[s];
                                if (stock?.location) {
                                    this.tempSave(relevantStores, stock.location);
                                }
                            }

                            relevantStores.forEach(store => {
                                this.objectChangedService.objectChanged({ model: 'stores', info: { store: store, type: 'transaction' }, reload: false });
                            })

                        } else {
                            this.utils.handleError('Error editing ' + transModelName, response.error);
                        }
                        dialogRef?.close();
                    },
                    error: error => {
                        this.utils.handleError('Error editing ' + transModelName, error);
                        dialogRef?.close();
                    }
                });
        } else {
            this.logger.fatal('doEditTransaction: undefined changedTransaction');
            dialogRef?.close();
        }
    }

    deleteTransaction(transaction: StockChange, ngUnsubscribe: Subject<unknown>, callbackFn: (ts: StockChange) => void): void {
        const dialogRef = this.dialog.open(YesNoWaitDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to delete this {{transaction_type}}?', { transaction_type: this.tr.anslate(transaction.type) }) }
        });

        dialogRef.componentInstance.finished.subscribe(result => {
            if (result === true) {
                dialogRef.componentInstance.waiting = true;
                const transModelName = (transaction.type as string).toLowerCase();
                this.standardService.remove(transModelName + 's', transaction._id)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe), takeUntil(dialogRef.componentInstance.cancelled))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully removed'));

                                // notify parent and siblings
                                this.objectChangedService.objectChanged({ model: 'stores', info: { store: transaction.location._id ? transaction.location._id as unknown as Location : transaction.location, type: 'transaction' }, reload: false });
                                this.objectChangedService.objectChanged({ model: 'coffees', info: { coffee: transaction.coffee, type: 'transaction' }, reload: true });
                                // if there was a target store (e.g. Transfer), need to update this as well
                                const transfer = transaction as Transfer;
                                if (transfer.target) {
                                    this.objectChangedService.objectChanged({ model: 'stores', info: { store: transfer.target._id ? transfer.target._id as unknown as Location : transfer.target, type: 'transaction' }, reload: false });
                                }

                                callbackFn(transaction);

                            } else {
                                this.utils.handleError('error deleting ' + transModelName, response.error);
                            }
                            dialogRef.close();
                        },
                        error: error => {
                            this.utils.handleError('error deleting ' + transModelName, error);
                            dialogRef.close();
                        }
                    });
            } else {
                dialogRef.close();
            }
        });
    }

    handleCorrectionDialogClosed(dialogResult: CorrectDialogResultType, dialogRef: MatDialogRef<CorrectDialogComponent>, 
        stock: StockType, stocks: StockType[], sIdx: number, stores: Location[], coffee: Coffee,
        callbackFn: (trans: StockChange) => unknown, ngUnsubscribe: Subject<unknown>): void {
        if (!dialogResult?.openDialog) {
            return;
        }
        if (dialogResult.openDialog === 'purchase') {
            dialogRef.close();
            const newDialogRef = this.dialog.open(PurchaseDialogComponent, {
                closeOnNavigation: true,
                data: {
                    stores: stores,
                    selectedCoffee: coffee,
                }
            });

            newDialogRef.componentInstance.finished.subscribe(p => {
                const purchase = p as Purchase;
                if (!purchase) {
                    return;
                }
                if (!purchase.amount) {
                    this.alertService.success(this.tr.anslate('Ignored since amount was zero'));
                    return;
                }
                // long running op; set waiting true and takeUntil(cancelled)
                newDialogRef.componentInstance.waiting = true;
                this.standardService.add<Purchase>('purchases', purchase)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe), takeUntil(newDialogRef.componentInstance.cancelled))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully added'));
                                if (typeof callbackFn === 'function') {
                                    callbackFn(purchase);
                                } else {
                                    // make the change locally
                                    if (stock) {
                                        stock.amount += response.result.amount;

                                        // notify of the change (why is the local change not enough to update the stock?
                                        // because the coffees component will re-render everything and will use its (old) value)
                                        if (typeof sIdx !== 'undefined') {
                                            this.objectChangedService.objectChanged({ model: 'stocks', info: { storeIdx: sIdx, coffeeId: stock.coffeeId, amount: stock.amount }, reload: false });
                                        }
                                    }
                                    this.objectChangedService.objectChanged({ model: 'coffees', info: { coffee: purchase.coffee, type: 'transaction' }, reload: true });
                                    this.objectChangedService.objectChanged({ model: 'stores', info: { store: purchase.location, type: 'transaction' }, reload: false });
                                }
                            } else {
                                this.utils.handleError('error adding purchase', response.error);
                            }
                            newDialogRef.close();
                        },
                        error: error => {
                            this.utils.handleError('error adding purchase', error);
                            newDialogRef.close();
                        }
                    });
            });
        } else if (dialogResult.openDialog === 'sale') {
            dialogRef.close();
            const newDialogRef = this.dialog.open(SellDialogComponent, {
                closeOnNavigation: true,
                data: {
                    stores: stores,
                    stocks: stocks ? [stocks] : undefined,
                    selectedStore: stores ? stores[0] : undefined,
                    selectedCoffee: coffee,
                }
            });

            newDialogRef.componentInstance.finished.subscribe(s => {
                const sale = s as Sale;
                if (!sale) {
                    return;
                }
                if (!sale.amount) {
                    this.alertService.success(this.tr.anslate('Ignored since amount was zero'));
                    dialogRef.close();
                    return;
                }

                // long running op; set waiting true and takeUntil(cancelled)
                newDialogRef.componentInstance.waiting = true;
                this.standardService.add<Sale>('sales', sale)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe), takeUntil(newDialogRef.componentInstance.cancelled))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully added'));
                                if (typeof callbackFn === 'function') {
                                    callbackFn(sale);
                                } else {
                                    // make the change locally
                                    if (stock) {
                                        stock.amount -= response.result.amount;
                                        // notify of the change (why is the local change not enough to update the stock?
                                        // because the coffees component will re-render everything and will use its (old) value)
                                        if (typeof sIdx !== 'undefined') {
                                            this.objectChangedService.objectChanged({ model: 'stocks', info: { storeIdx: sIdx, coffeeId: stock.coffeeId, amount: stock.amount }, reload: false });
                                        }
                                    }
                                    this.objectChangedService.objectChanged({ model: 'coffees', info: { coffee: sale.coffee, type: 'transaction' }, reload: true });
                                    this.objectChangedService.objectChanged({ model: 'stores', info: { store: sale.location, type: 'transaction' }, reload: false });
                                }
                            } else {
                                this.utils.handleError('error adding sale', response.error);
                            }
                            newDialogRef.close();
                        },
                        error: error => {
                            this.utils.handleError('error adding sale', error);
                            newDialogRef.close();
                        }
                    });
            });
        } else if (dialogResult.openDialog === 'transfer') {
            dialogRef.close();
            const newDialogRef = this.dialog.open(TransferDialogComponent, {
                closeOnNavigation: true,
                data: {
                    stores: stores,
                    stocks: [stocks],
                    selectedSourceStore: stores ? stores[0] : undefined,
                    selectedCoffee: coffee,
                }
            });

            newDialogRef.componentInstance.finished.subscribe(t => {
                const transfer = t as Transfer;
                if (!transfer) {
                    return;
                }
                if (!transfer.amount) {
                    this.alertService.success(this.tr.anslate('Ignored since amount was zero'));
                    dialogRef.close();
                    return;
                }

                // long running op; set waiting true and takeUntil(cancelled)
                newDialogRef.componentInstance.waiting = true;
                this.standardService.add<Transfer>('transfers', transfer)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe), takeUntil(newDialogRef.componentInstance.cancelled))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully added'));
                                if (typeof callbackFn === 'function') {
                                    callbackFn(transfer);
                                } else {
                                    let foundOtherStore = false;
                                    // make the change locally
                                    if (stock) {
                                        let otherStockDelta = response.result.amount;
                                        if (stock.location?.hr_id === response.result.location.hr_id || `L${stock.location_internal_hr_id}` === response.result.location.hr_id) {
                                            // transfer away from current store
                                            stock.amount -= response.result.amount;
                                        } else if (stock.location?.hr_id === response.result.target.hr_id || `L${stock.location_internal_hr_id}` === response.result.target.hr_id) {
                                            // transfer to current store
                                            stock.amount += response.result.amount;
                                            otherStockDelta = -response.result.amount
                                        } // else transfer outside current store, ignore

                                        // update other store
                                        for (let s = 0; s < stocks.length; s++) {
                                            if (stocks[s].location_id === response.result.target._id.toString()) {
                                                let newStock = stocks[s].amount ?? 0;
                                                newStock += otherStockDelta;
                                                stocks[s].amount = newStock;
                                                this.objectChangedService.objectChanged({ model: 'stocks', info: { storeIdx: s, coffeeId: stock.coffeeId, amount: newStock }, reload: false });
                                                foundOtherStore = true;
                                                break;
                                            }
                                        }
                                        if (!foundOtherStore) {
                                            this.objectChangedService.objectChanged({ model: 'stocks', info: { coffeeId: `C${stock.coffee_internal_hr_id}` }, reload: true });
                                        }

                                        // notify of the change (why is the local change not enough to update the stock?
                                        // because the coffees component will re-render everything and will use its (old) value)
                                        if (typeof sIdx !== 'undefined' && foundOtherStore) {
                                            // sIdx is the current store
                                            this.objectChangedService.objectChanged({ model: 'stocks', info: { storeIdx: sIdx, coffeeId: stock.coffeeId, amount: stock.amount }, reload: false });
                                        }
                                    }
                                    if (foundOtherStore) {
                                        this.objectChangedService.objectChanged({ model: 'stores', info: { store: transfer.location, type: 'transaction' }, reload: false });
                                        this.objectChangedService.objectChanged({ model: 'stores', info: { store: transfer.target, type: 'transaction' }, reload: false });
                                    }
                                }
                            } else {
                                this.utils.handleError('error adding transfer', response.error);
                            }
                            newDialogRef.close();
                        },
                        error: error => {
                            this.utils.handleError('error adding transfer', error);
                            newDialogRef.close();
                        }
                    });
            });
        } else if (dialogResult.openDialog === 'correction') {
            // else have normal correction, no need to close and open new dialog
            const correction = dialogResult.trans as Correction;
            if (!correction) {
                return;
            }
            // indicate that this is a new (absolute) Correction (June 2019)
            correction['relative'] = false;

            // long running op; set waiting true and takeUntil(cancelled)
            dialogRef.componentInstance.waiting = true;
            this.standardService.add<Correction>('corrections', correction)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe), takeUntil(dialogRef.componentInstance.cancelled))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.alertService.success(this.tr.anslate('Successfully added'));
                            if (typeof callbackFn === 'function') {
                                callbackFn(this.utils.dateifyStockChanges([response.result])?.[0]);
                            } else {
                                // make the change locally
                                if (stock) {
                                    stock.amount = response.result.amount;
                                    // notify of the change (why is the local change not enough to update the stock?
                                    // because the coffees component will re-render everything and will use its (old) value)
                                    this.objectChangedService.objectChanged({ model: 'stocks', info: { storeIdx: sIdx, coffeeId: stock.coffeeId, amount: stock.amount }, reload: false });
                                }
                                this.objectChangedService.objectChanged({ model: 'coffees', info: { coffee: correction.coffee, type: 'transaction' }, reload: true });
                                this.objectChangedService.objectChanged({ model: 'stores', info: { store: correction.location, type: 'transaction' }, reload: false });
                            }
                        } else {
                            this.utils.handleError('error adding correction', response.error);
                        }
                        dialogRef.close();
                    },
                    error: error => {
                        this.utils.handleError('error adding correction', error);
                        dialogRef.close();
                    }
                });
        } else {
            this.logger.warn('unknwon CorrectionDialogComponent result: ' + dialogResult.openDialog);
        }
    }

}
