import { Component, OnDestroy, Inject, LOCALE_ID, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { finalize, Subject, takeUntil, throttleTime } from 'rxjs';
import { Enumerations } from 'src/app/models/Enumerations';
import { InvitedUserInfo, UserType } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { RoastScheduledItem } from 'src/app/models/RoastScheduledItem';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SchedulerService } from './scheduler.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Utils2 } from 'src/app/util/utils2';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DateTime } from 'luxon';
import { omit } from 'lodash-es';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-scheduler-planner-fav',
    templateUrl: './scheduler-planner-fav.component.html',
    styleUrls: ['./scheduler-planner-fav.component.scss'],
    animations: [
        trigger('showDelete', [
            state('start', style({
                'min-width': 0,
                width: 0,
            })),
            state('end', style({
                'min-width': '{{deleteWidth}}',
                width: '{{deleteWidth}}',
            }), { params: { deleteWidth: '25px' } }),
            transition('start => end', [
                animate(SchedulerPlannerFavComponent.ANIMATIONDURATION)
            ]),
            transition('end => start', [
                animate(SchedulerPlannerFavComponent.ANIMATIONDURATION)
            ]),
        ]),
    ],
})
export class SchedulerPlannerFavComponent implements OnInit, OnDestroy {

    constructor(
        protected schedulerService: SchedulerService,
        protected alertService: AlertService,
        protected utils: Utils,
        protected utils2: Utils2,
        protected tr: TranslatorService,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        // this.beansAndBlendsPlaceholder = `${this.tr.anslate('Beans')} / ${this.tr.anslate('Blends')}`;
    }

    static readonly ANIMATIONDURATION = '0.15s';
    deleteWidth = '25px';

    _currentUser: UserType;
    @Input() set currentUser(cu: UserType) {
        if (cu) {
            this._currentUser = cu;
            if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
                this.mainUnit = 'lb';
                this.deleteWidth = '22px';
            }
        }
    }
    get currentUser(): UserType {
        return this._currentUser;
    }

    @Input() readOnly: boolean;
    @Input() line = 0;
    @Input() users: InvitedUserInfo[];

    @Output() editItem = new EventEmitter<{ item: RoastScheduledItem, favoritesLine: number }>();

    favItems: RoastScheduledItem[] = [];
    lastEditItem: RoastScheduledItem;


    // showClone = false;
    dragIndex = -1;

    animateItem = [];

    mainUnit: UnitSystemType = 'kg';
    protected ngUnsubscribe = new Subject();

    isUpdatingNow = false;
    isAdding = false;
    addingTimer: ReturnType<typeof setTimeout>;

    // for the copy drag and drop cursor
    bodyElement: HTMLElement = document.body;

    DateTime = DateTime;


    ngOnInit(): void {
        // const favsstr = this.userService.getFromLocal(`fav_schedule_${this.line}`);
        // if (favsstr) {
        //     this.favItems = JSON.parse(favsstr) ?? [];
        // }
        this.loadFavorites();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    public deselect(): void {
        if (this.lastEditItem) {
            this.lastEditItem.selected = false;
            // don't bother if none show the delete (e.g. if readonly)
            if (this.animateItem.some(itm => itm)) {
                setTimeout(() => {
                    // need to find the item or just use all
                    for (let i = 0; i < this.animateItem.length; i++) {
                        this.animateItem[i] = false;
                    }
                }, 10);
            }
            this.lastEditItem = undefined;
            // this.editItem.emit({ item: undefined, favoritesLine: this.line });
        }
    }

    private edit(item: RoastScheduledItem | undefined): void {
        this.lastEditItem = item;
        this.editItem.emit({ item, favoritesLine: this.line });
    }

    /**
     * Saves the favorite items
     * @returns true if the update was successfully made
     */
    public updateEditedItem(): boolean {
        if (this.readOnly) { return; }

        this.saveFavorites(this.favItems, this.line);
        return true;
    }

    /**
     * Selects the given item or deselects if already selected. Deselects all others.
     * @param idx index of the item within filteredItems
     */
    protected selectItem(idx: number): void {
        for (let i = 0; i < this.favItems?.length; i++) {
            const item = this.favItems[i];
            if (item.selected) {
                // deselect in any case (either clicked on same item or another)
                item.selected = false;
                setTimeout(() => {
                    this.animateItem[i] = false;
                }, 10);
                if (i === idx) {
                    // clicked on selected item, unselect and finish
                    this.edit(undefined);
                    return;
                }
                continue;
            }
            if (i === idx) {
                item.selected = true;
                this.edit(item);
                setTimeout(() => {
                    this.animateItem[i] = true;
                }, 10);
            }
        }
    }

    dragStarted(data: { source: { data: { idx: number } } }) {
        this.deselect();
        this.dragIndex = data?.source?.data?.idx;
    }

    dragReleased() {
        // this.showClone = false;
        this.dragIndex = -1;
        this.bodyElement.classList.remove('inheritCursors');
        this.bodyElement.style.cursor = 'unset';
    }

    // private shortenOrigin(item: RoastScheduledItem): void {
    //     if (item.title) {
    //         const ctry3s = Object.keys(Enumerations.Country3);
    //         const ctries = Object.values(Enumerations.Country3);
    //         for (let c = 0; c < ctries.length; c++) {
    //             const ctry = ctries[c];
    //             if (ctry.length >= 5 && item.title.indexOf(`${ctry} `) === 0) {
    //                 item.title = item.title.replace(`${ctry} `, `${ctry3s[c]} `);
    //                 return;
    //             }
    //         }
    //     }
    // }

    private loadFavorites(): void {
        if (!this.addingTimer) {
            this.addingTimer = setTimeout(() => this.isAdding = true, 600);
        }
        this.schedulerService.loadFavorites(this.line)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { clearTimeout(this.addingTimer); this.addingTimer = undefined; this.isAdding = false; }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        try {
                            this.favItems = JSON.parse(response.result) ?? [];
                            for (const item of this.favItems) {
                                this.schedulerService.addInfo(item, this.mainUnit, this.users?.length);
                                // difficult since it changes the title string
                                // which is then shown in copies of the item
                                // but this is not persisted:
                                // this.shortenOrigin(item);
                            }
                            // this.schedulerService.calculateStock(this.favItems, this.mainUnit);
                        } catch (err) {
                            this.favItems = [];
                            this.utils.handleError('error retrieving infomation', err);
                        }
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                }
            });
    }

    private saveFavorites(items: RoastScheduledItem[], line: number): void {
        if (this.readOnly) { return; }

        if (!this.addingTimer) {
            this.addingTimer = setTimeout(() => this.isAdding = true, 600);
        }
        // need to remove computed items such as itemAmount since this contains
        // mainUnit as string which might have changed when loading the item
        // also coffee.stock
        // and need to update computed items since they might have changed (e.g. itemAmount)
        const clonedItems = [];
        for (const item of items) {
            this.schedulerService.addInfo(item, this.mainUnit, this.users?.length);
             
            clonedItems.push(omit(item, ['selected', 'coffee.stock', 'coffee.totalStock', 'coffee.totalStockStr', 'location.coffeeStock', 'coffee.coffeeStockStr', 'itemAmount', 'info1', 'info2', 'tooltip', 'blendInfo', 'subtitle', 'synced', 'roasts']));
        }
        const str = JSON.stringify(clonedItems);
        this.schedulerService.saveFavorites(str, line)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { clearTimeout(this.addingTimer); this.addingTimer = undefined; this.isAdding = false; }))
            .subscribe({
                next: response => {
                    // ignore if ok
                    if (response && !response.success) {
                        this.utils.handleError('error updating the schedule', response.error);
                        this.loadFavorites();
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the schedule', error);
                    this.loadFavorites();
                }
            });
    }

   protected drop(event: CdkDragDrop<unknown, { items?: RoastScheduledItem[], fromFavorites?: boolean, line?: number }, { item?: RoastScheduledItem }>) {
        if (this.readOnly) { return; }

        if (event.container === event.previousContainer) {
            // reorder within fav line
            moveItemInArray(this.favItems, event.previousIndex, event.currentIndex);
        } else if (event.previousContainer.data.fromFavorites) {
            // move from one fav line to another
            transferArrayItem(event.previousContainer.data.items, this.favItems, event.previousIndex, event.currentIndex);
            // store info from incoming fav line
            // this.userService.storeToLocal(`fav_schedule_${event.previousContainer.data.line}`, JSON.stringify(event.previousContainer.data.items));
            this.saveFavorites(event.previousContainer.data.items, event.previousContainer.data.line);
        } else {
            // add new item
            // TODO! remove last one if #items > 3
            const item = omit(event.item?.data?.item ?? event.previousContainer.data?.items[event.previousIndex],
                ['selected', 'synced', 'roasts', 'roasted', 'created_at', 'ceated_by', 'updated_at', 'updated_by']);
            item.synced = false;
            this.favItems.splice(event.currentIndex, 0, item);
        }
        this.deselect();
        this.editItem.emit({ item: undefined, favoritesLine: this.line });
        // this.userService.storeToLocal(`fav_schedule_${this.line}`, JSON.stringify(this.favItems));
        this.saveFavorites(this.favItems, this.line);
    }

    protected deleteItem(idx: number): void {
        if (this.readOnly) { return; }

        this.favItems.splice(idx, 1);
        // this.userService.storeToLocal(`fav_schedule_${this.line}`, JSON.stringify(this.favItems));
        this.saveFavorites(this.favItems, this.line);
        this.deselect();
    }
}
