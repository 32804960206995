import { AlertService } from 'src/app/util/alert/alert.service';
import { FileService } from 'src/app/util/services/file.service';
import { Component, ViewChild, Inject, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { environment } from 'src/environments/environment-uber';

// Adapted from https://malcoded.com/posts/angular-file-upload-component-with-express/

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements AfterViewInit {

    constructor(
        private dialogRef: MatDialogRef<FileUploadComponent>,
        private fileService: FileService,
        private logger: NGXLogger,
        private tr: TranslatorService,
        private alertService: AlertService,
        @Inject(MAT_DIALOG_DATA) public data: {
            belongsTo: string,
            belongsToType: string,
        },
    ) { }

    @ViewChild('file', { static: true }) file: ElementRef;
    public files = new Set<File>();
    uploadInfo: Record<string, { progress: Observable<number>, fileName: Observable<{ fileName: string, path: string }> }>;
    canBeClosed = true;
    showCancelButton = true;
    uploading = false;
    uploadSuccessful = false;

    ngAfterViewInit(): void {
        if (this.file?.nativeElement) {
            this.file.nativeElement.click();
        }
    }

    addFiles(): void {
        if (this.file?.nativeElement) {
            this.file.nativeElement.click();
        } else {
            this.logger.warn('could not access file.nativeElement for click: ' + this.file);
            this.alertService.error(this.tr.anslate('server error'));
        }
    }

    removeFile(fileToRemove: File): void {
        this.files.delete(fileToRemove);
    }

    onFilesAdded(): void {
        const files: Record<string, File> = this.file.nativeElement.files;
        for (const key in files) {
            if (!isNaN(parseInt(key, 10))) {
                this.files.add(files[key]);
            }
        }
    }

    closeDialog(): void {
        // if everything was uploaded already, just close the dialog
        if (this.uploadSuccessful) {
            return this.dialogRef.close();
        }

        // set the component state to "uploading"
        this.uploading = true;

        let sizeOk = true;
        this.files.forEach(file => {
            if (file?.size > environment.MAX_UPLOAD_SIZE_MB * 1024 * 1024) {
                this.logger.warn('file size too large: ' + file.name + ': ' + file.size);
                this.alertService.error(this.tr.anslate('File size too large (must be <2MB)'));
                this.uploadSuccessful = false;
                this.canBeClosed = true;
                this.dialogRef.disableClose = false;
                this.showCancelButton = true;
                this.uploading = false;
                // reset progress bars
                if (this.uploadInfo) {
                    for (const key of Object.keys(this.uploadInfo)) {
                        this.uploadInfo[key].progress = undefined;
                    }
                }
                sizeOk = false;
                return;
            }
        });
        if (!sizeOk) {
            // leave dialog open
            return;
        }

        // start the upload and save the progress map
        this.uploadInfo = this.fileService.uploadFiles(this.files, 'FILE', this.data && this.data.belongsToType ? this.data.belongsToType.toString() : '', this.data && this.data.belongsTo ? this.data.belongsTo.toString() : '');

        // convert the progress map into an array
        const allProgressObservables = [];
        for (const key of Object.keys(this.uploadInfo)) {
            allProgressObservables.push(this.uploadInfo[key].progress);
        }
        // same for filenames
        const allFilenameObservables = [];
        for (const key of Object.keys(this.uploadInfo)) {
            allFilenameObservables.push(this.uploadInfo[key].fileName);
        }

        // Adjust the state variables

        // The dialog should not be closed while uploading
        this.canBeClosed = false;
        this.dialogRef.disableClose = true;

        // Hide the cancel-button
        this.showCancelButton = false;

        // When all progress-observables are completed...
        forkJoin(allProgressObservables as Observable<{ fileName: string, path: string }>[])
            .subscribe({
                next: () => {
                    // ... the dialog can be closed again...
                    this.canBeClosed = true;
                    this.dialogRef.disableClose = false;

                    // ... the upload was successful...
                    this.uploadSuccessful = true;

                    // ... and the component is no longer uploading
                    this.uploading = false;

                    // // close with ok
                    // return this.dialogRef.close(true);
                },
                error: error => {
                    this.logger.warn('upload of files failed: ' + error);
                    if (error?.error && error.error.error && error.error.error.toString().substring(0, 19) === 'File size too large') {
                        this.alertService.error(this.tr.anslate('File size too large (must be <2MB)'));
                    } else {
                        this.alertService.error(this.tr.anslate('no documents were uploaded') + ', ' + this.tr.anslate('unknown reason'));
                    }
                    this.uploadSuccessful = false;
                    this.canBeClosed = true;
                    this.dialogRef.disableClose = false;
                    this.showCancelButton = true;
                    this.uploading = false;
                    // reset progress bars
                    if (this.uploadInfo) {
                        for (const key of Object.keys(this.uploadInfo)) {
                            this.uploadInfo[key].progress = undefined;
                        }
                    }
                    // leave dialog open
                }
            });

        // When all progress-observables are completed...
        forkJoin(allFilenameObservables as Observable<number>[])
            .subscribe({
                next: response => {
                    // close with filename array
                    return this.dialogRef.close(response);
                },
                error: error => {
                    this.alertService.error(this.tr.anslate('no documents were uploaded') + ', ' + error);
                }
            });
    }
}
