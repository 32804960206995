import { StandardService } from 'src/app/util/services/standard.service';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Roast } from 'src/app/models/Roast';
import { UserType } from 'src/app/modules/frame/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { throttleTime, debounceTime, distinctUntilChanged, startWith, skip, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { trigger, state, style, transition, sequence, animate } from '@angular/animations';
import { Enumerations } from 'src/app/models/Enumerations';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Router } from '@angular/router';
import { RoastReport } from 'src/app/models/RoastReport';
import { ReportService } from 'src/app/modules/report/report.service';
import { MatSort, Sort } from '@angular/material/sort';
import { ReportExportUiComponent } from './report-export-ui.component';
import { DateTime } from 'luxon';
// 
@Component({
    selector: 'app-roastreport-table',
    templateUrl: './roastreport-table.component.html',
    styleUrls: ['./roastreport-table.component.scss'],
    animations: [
        trigger('animateField', [
            state('idle', style({
                backgroundColor: '#00000000'
            })),
            state('saved', style({
                backgroundColor: '#00000000'
            })),
            transition('idle => saved', sequence([
                style({ color: 'black' }),
                animate(400, style({ backgroundColor: '#00FF0030' })),
                animate(400, style({ backgroundColor: '#00000000' })),
            ]))
        ])
    ],
})
export class RoastreportTableComponent implements OnInit, OnDestroy {

    constructor(
        public utils: Utils,
        private userService: UserService,
        private logger: NGXLogger,
        private standardService: StandardService,
        public tr: TranslatorService,
        private router: Router,
        private reportService: ReportService,
    ) {
        this.MAX_LIST_ROASTS = this.reportService.MAX_LIST_ROASTS;
    }

    @Input() currentUser: UserType;
    @Input() isOpenReport = false;
    @Input() columnsToDisplay: string[];
    @Input() reportName: string;
    @Input() additionalIsEditable = false;
    @Input() showTotals = true;
    @Input() showExportUI = true;
    @Input() readOnly = false;
    @Input() invalids = false;

    private _report: RoastReport;
    get report(): RoastReport { return this._report; }
    @Input() set report(rep: RoastReport) {
        this._report = rep;
        if (rep) {
            this.dataSource = new MatTableDataSource(rep.roasts);
            this.updateDataSource();
        }
    }
    private _totals: { nr: number, in: number, out: number };
    get totals(): { nr: number, in: number, out: number } { return this._totals; }
    @Input() set totals(tt: { nr: number, in: number, out: number }) {
        this._totals = tt;
        if (tt && !this.report?.fullyLoaded) {
            this.sumIn = this.sumInForAvg = tt.in;
            this.sumOut = this.sumOutForAvg = tt.out;
            this.avgLoss = (this.sumInForAvg - this.sumOutForAvg) / this.sumInForAvg;
        }
    }

    @Output() reportChange = new EventEmitter<RoastReport>();
    @Output() loadMoreClick = new EventEmitter<'forSort'>();

    MAX_LIST_ROASTS = 500;

    mainUnit: UnitSystemType = 'kg';

    dataSource: MatTableDataSource<Roast>;
    sumIn = 0;
    sumOut = 0;
    avgLoss = 0;
    sumInForAvg = 0;
    sumOutForAvg = 0;
    sumInMissing = false;
    sumOutMissing = false;

    waiting = false;
    animateFieldState = 'idle';
    additionalNoteChanged: Subject<string>[] = [];

    private ngUnsubscribe = new Subject();

    public currentSort: Sort = undefined;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('reportExport') reportExport: ReportExportUiComponent;

    DateTime = DateTime;


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    generatePDF(): void {
        this.reportExport?.generatePDF();
    }

    sortChanged(newSort: Sort) {
        this.currentSort = newSort;
        if (!this.report?.fullyLoaded) {    
            this.loadMore(undefined, 'forSort');
        }
    }

    updateDataSource(dataSource?: MatTableDataSource<Roast>): void {
        if (dataSource) {
            this.dataSource = dataSource;
        }
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'Number': return item['tempnumber'];
                case 'Date': return item.date;
                case 'Roast-ID': return item.hr_id;
                case 'Coffees': return item.blend ? ('__' + item.blend?.label) : (item.coffee ? item.coffee.hr_id : undefined);
                case 'In': return item.amount || 0;
                case 'Out': return item.end_weight || 0;
                case 'Loss': return (item.amount || 0) - (item.end_weight || 0);
                case 'InvalidReason': return item.invalidReason;
                case 'References': return item.reference;
                default: return item[property];
            }
        };
        if (this.sort) {
            this.dataSource.sort = this.sort;
        }
        this.recalc();
    }

    recalc(): void {
        this.logger.debug('roastreport-table recalc for ' + (this.columnsToDisplay || [''])[0]);
        const roasts = this.dataSource.data;
        this.sumIn = 0;
        this.sumOut = 0;
        this.sumInMissing = false;
        this.sumOutMissing = false;
        this.sumInForAvg = 0;
        this.sumOutForAvg = 0;
        this.additionalNoteChanged.length = 0;

        for (let r = 0; r < roasts.length; r++) {
            if (!this.readOnly) {
                // every field gets its own listener to catch only value changes
                this.additionalNoteChanged[r] = new Subject<string>();
                this.additionalNoteChanged[r].pipe(
                    debounceTime(2000),
                    startWith(roasts[r].reportNote),
                    distinctUntilChanged(),
                    skip(1))
                    .subscribe(
                        newNote => {
                            this.saveAdditionalNote(r, newNote);
                        }
                    );
            }

            const roast = roasts[r];
            if (roast.discarded) {
                roast.end_weight = 0;
            }
            if (roast.coffee && !roast.coffee.yearLabel) {
                roast.coffee.yearLabel = this.utils.createBeansYearLabel(roast.coffee);
            }
            if (roast.blend?.ingredients) {
                for (const ing of roast.blend.ingredients) {
                    const cof = ing?.coffee;
                    if (cof && !cof.yearLabel) {
                        cof.yearLabel = this.utils.createBeansYearLabel(cof);
                    }
                }
            }

            if (this.report?.fullyLoaded || roasts.length === this.totals?.nr) {
                this.sumIn += !Number.isFinite(roast.amount) ? 0 : roast.amount;
                this.sumOut += !Number.isFinite(roast.end_weight) ? 0 : roast.end_weight;
                // we potenitally allow roasts without amount or end_weight; ignore those for avg
                if (roast.amount && roast.end_weight) {
                    this.sumInForAvg += roast.amount;
                    this.sumOutForAvg += roast.end_weight;
                }
            }
            if (!roast.amount) {
                this.sumInMissing = true;
            }
            if (!roast.end_weight) {
                this.sumOutMissing = true;
            }
            // store seq. number; needs to be per roast for sorting
            roast['tempnumber'] = (r + 1).toString().padStart(3, '0');
        }

        if (this.report?.fullyLoaded || roasts.length === this.totals?.nr) {
            if (this.report) {
                this.report.fullyLoaded = true;
            }
        } else if (this.totals) {
            this.sumIn = this.sumInForAvg = this.totals.in;
            this.sumOut = this.sumOutForAvg = this.totals.out;
        }
        this.avgLoss = (this.sumInForAvg - this.sumOutForAvg) / this.sumInForAvg;
        this.waiting = false;
    }

    loadMore(cb?: (success: boolean) => void, forSort?: 'forSort'): void {
        // if (this.report?.roastsCount > this.report.roasts?.length) {
        // retrieve all roasts
        // this.recalc();
        if (this.report._id) {
            this.waiting = true;
            this.reportService.getRoastReport(this.report._id, this.isOpenReport)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            // avoid using setter so that recalc is not called multiple times
                            this._report = this.utils.dateifyRoastReports([response.result])?.[0];
                            this.report.roasts = this.utils.dateifyRoasts(this.report.roasts);
                            this.report.fullyLoaded = true;
                            this.report.roastsCount = this.report.roasts?.length;
                            // done through report setter via the reportChange.emit:
                            // this.recalc();
                            this.reportChange.emit(this.report);
                            if (typeof cb === 'function') cb(true);
                        } else {
                            this.utils.handleError('error retrieving reports', response.error);
                            if (typeof cb === 'function') cb(false);
                        }
                        this.waiting = false;
                    },
                    error: error => {
                        this.utils.handleError('error retrieving reports', error);
                        this.waiting = false;
                        if (typeof cb === 'function') cb(false);
                    }
                });
        } else { // new report is being created
            this.waiting = true;
            this.loadMoreClick.emit(forSort);
        }
        // } else {
        //     if (typeof cb === 'function') cb(true);
        // }
    }

    saveAdditionalNote(rIndex: number, newNote: string): void {
        if (this.readOnly) { return; }

        if (this.report.roasts[rIndex].reportNote === newNote) {
            this.logger.debug('ignoring note change');
            return;
        }

        const roast = this.report.roasts[rIndex];
        const lastNote = roast.reportNote;
        roast.reportNote = newNote;

        this.logger.debug('save notes:', newNote, 'over existing', roast.reportNote);
        this.standardService.update<Roast>('roasts', {
            _id: roast._id, reportNote: newNote,
            modified_at: DateTime.now(), label: undefined, amount: undefined, date: undefined
        })
            .pipe(throttleTime(environment.RELOADTHROTTLE))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        // unnecessary (set above already) and there was a case when
                        // this.report.roasts[rIndex] was an ID only
                        // if (response?.result) {
                        //     this.report.roasts[rIndex].reportNote = response.result.reportNote;
                        // }
                        this.animateFieldState = 'saved';
                        setTimeout(() => this.animateFieldState = 'idle', 3000);
                    } else {
                        if (typeof this.report.roasts[rIndex] === 'object') {
                            this.report.roasts[rIndex].reportNote = lastNote;
                        }
                        this.utils.handleError('error updating the additional report information', response.error);
                    }
                },
                error: error => {
                    this.report.roasts[rIndex].reportNote = lastNote;
                    this.utils.handleError('error updating the additional report information', error);
                }
            });
    }
}
