<table mat-table [dataSource]="stocks">
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef class="amount" i18n="stores$$amount, table header@@amount header">Amount</th>
        <td mat-cell class="amount" *matCellDef="let stock; let s=index">
            @if (stock.amount) {
            @if (formatAmount(stock.amount); as amount) {
            {{amount.pre}}
            @if (amount.pre) {(}
            <a (click)="changeAmount(s)" (keydown.enter)="changeAmount(s); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer" [ngClass]="{'negative-amount': amount.value < 0}">{{amount.value | number:'1.0-1'}}{{amount.post}}</a>
            @if (amount.pre) {)}
            }
            }
        </td>
        <td mat-footer-cell *matFooterCellDef [ngClass]="{'negative-amount': totalAmount < 0}">
            @if (formatAmount(totalAmount); as amount) {
            <span matTooltip="{{totalAmount * utils.getUnitFactor(mainUnit) | number:'1.0-3'}}{{mainUnit}}">{{amount.pre}}
                @if (amount.pre) {(}
                <span [ngClass]="{'negative-amount': amount.value < 0}">{{amount.value | number:'1.0-1'}}{{amount.post}}</span>
                @if (amount.pre) {)}
            </span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="low_limit">
        <th mat-header-cell *matHeaderCellDef i18n="stores$$limit, table header@@limit header">Limit for Stock Alert</th>
        <td mat-cell class="low_limit" *matCellDef="let stock">
            @if (stock.amount) {
            {{stock.low_limit}}
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef class="location" i18n="generic$$storage location@@Store">Store</th>
        <td mat-cell class="location" *matCellDef="let stock">
            @if (stock.amount) {
            <a [routerLink]="['/stores', {id: stock.location.hr_id}]">{{stock.location.hr_id}}</a> {{stock.location.label}}
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align"><span i18n="generic$$indicating the sum of some numbers@@Sum">Sum</span>:&nbsp;</td>
    </ng-container>
    <ng-container matColumnDef="cost">
        <th mat-header-cell *matHeaderCellDef i18n="stores$$cost, table header@@Cost">Cost</th>
        <td mat-cell *matCellDef="let stock">
            @if (stock.amount) {
            {{stock.fifo_cost | currency:currency:'symbol-narrow':'1.0-0'}}<br />
            <a (click)="changeCost(stock)" (keydown.enter)="changeCost(stock); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer small-value">{{stock.fifo_cost / (stock.amount * utils.getUnitFactor(mainUnit)) | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}}</a>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef>{{totalCost | currency:currency:'symbol-narrow':'1.0-0'}}<br />
            <a (click)="changeCost()" (keydown.enter)="changeCost(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer small-value">{{((avgCost / utils.getUnitFactor(mainUnit)) || 0) | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}}</a>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <!-- <th mat-header-cell *matHeaderCellDef i18n="beans$$actions, table header@@Actions">Actions</th> -->
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let stock; let s=index">
            @if (!readOnly && (stock.amount >= 0.001 || -0.001 >= stock.amount)) {
            <button mat-icon-button (click)="setAmountToZero(s)" matTooltip="Set stock to 0" i18n-matTooltip="beans$$button, set stock to zero tooltip@@Set stock to 0">
                <mat-icon class="cursor-pointer smallicon" style="vertical-align: baseline; margin-bottom: 2px;">vertical_align_bottom</mat-icon>
            </button>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    <tr [hidden]="stocks.length <= 1" mat-footer-row *matFooterRowDef="columnsToDisplay" class="sum-cell"></tr>
</table>