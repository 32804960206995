import { Account } from './Account';
import { User } from './User';

export class Property {
    _id: string;
    created_at ?: Date;
    updated_at ?: Date;
    owner ?: Account;
    categories ?: string[];
    property: string;
    label ?: string;
    value ?: string;
    donttranslate ? = true;
    created_by ?: User;
    updated_by ?: User;
}

