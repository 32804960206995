<ng-template #noInfo>---</ng-template>
<ng-template #noInfoError><span class="no-amount">---</span></ng-template>
<ng-template #roastsErrors><span class="no-amount" style="margin-top: 8px">Achtung:</span> Manche Einträge im Röstprotokoll sind unvollständig!</ng-template>


<div style="margin-right: 15px;">Diese Daten können direkt in das <a href="https://artisan.plus/assets/documents/customs/GER/roestprotokoll.xlsx" rel="noopener" target="_blank">Excel</a> (<a href="https://artisan.plus/assets/documents/customs/GER/roestprotokoll.xls" rel="noopener" target="_blank">Excel 2013</a>) / <a href="https://artisan.plus/assets/documents/customs/GER/roestprotokoll.numbers" rel="noopener" target="_blank">Numbers</a> Dokument kopiert werden. Bitte immer beim entsprechenden Hauptzollamt nachfragen, ob dieses Format akzeptiert wird.</div>

@if (data_roasts) {
<h2>Röstprotokoll
    @if (!justCopied_r && !copyFailed_r && data_roasts?.filteredData?.length) {
    <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyRoasts()">
        <mat-icon matTooltip="Copy to clipboard"
            i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
    </button>
    }
    @if (justCopied_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
    </button>
    }
    @if (copyFailed_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
    </button>
    }
    @if (data_roasts?.filteredData?.length) {
    <a (click)="generatePDF()" (keydown.enter)="generatePDF(); $event.preventDefault()" role="button" [tabindex]="0" [ngClass]="{'isDisabled': creatingPdf}" class="report-link">PDF</a>
    }
</h2>
}

@if (manualDiscarded && nrDiscarded) {
<mat-checkbox [disabled]="readOnly" name="manualDiscarded_checkbox"
    [ngModel]="onlyDiscardedNotDestroyed"
    (change)="onlyDiscardedNotDestroyedChanged($event)"
    class="manualDiscarded-checkbox wrap-checkbox-label"
    [ngStyle]="{'margin-bottom': roastsHaveErrors ? '10px' : 0}">
    Zeige nur alle Fehlröstungen, die noch nicht als vernichtet markiert sind.
</mat-checkbox>
}

@if (roastsHaveErrors) {
<div><ng-container *ngTemplateOutlet="roastsErrors"></ng-container></div>
}

@if (data_roasts?.filteredData?.length) {
<table mat-table [dataSource]="data_roasts" class="wide-table mat-elevation-z8" #roastTable>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay_roasts"></tr>
    <tr mat-row *matRowDef="let roasts; columns: columnsToDisplay_roasts" class="report-roasts-row"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplay_roasts" class="sum-cell"></tr>
    <!-- <ng-container matColumnDef="ID">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let roast">
      <span *ngIf="roast.batch_prefix || roast.batch_number || roast.hr_id; else noInfo">
        {{roast.batch_prefix}}{{roast.batch_number}}
        <br *ngIf="roast.batch_prefix || roast.batch_number"/>
        <a *ngIf="roast.hr_id; else noInfo" [routerLink]="['/roasts', {id: roast.hr_id}]">{{roast.hr_id}}</a>
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container> -->
    <ng-container matColumnDef="Destroyed">
        <th mat-header-cell *matHeaderCellDef>Fehlröstung<br />vernichtet</th>
        <td mat-cell *matCellDef="let roast; let i=index">
            @if (roast.discarded) {
            <mat-form-field class="amount-input-ff1 narrow-date-field">
                <input matInput [placeholder]="roast.destroyed ? '' : 'Leer / Datum'"
                    [(ngModel)]="roast.destroyed" (dateChange)="destroyedChanged(i)"
                    [disabled]="readOnly" data-test="npt_destroyedDate"
                    [matDatepicker]="myDatepicker" name="destroyedDate">
                <mat-datepicker-toggle matIconPrefix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
            }
            @if (roast.destroyed && !readOnly) {
            <button mat-icon-button class="tertiary-element" (click)="removeDestroyed(i)">
                <mat-icon class="tertiary-element removeDestroyed-icon">close</mat-icon>
            </button>
            }
            <!-- <ng-container *ngIf="i === 0"> -->
            @if (roast.discarded && nrDiscarded > 1) {
            <br />
            @if (!readOnly) {
            <a (click)="applyDestroyedToAll(i)" (keydown.enter)="applyDestroyedToAll(i); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">
                Auf alle anwenden
            </a>
            }
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Number">
        <th mat-header-cell *matHeaderCellDef>Lfd. Nr.</th>
        <td mat-cell *matCellDef="let roast; let i=index">
            @if (roast.hr_id) {
            <a [routerLink]="['/roasts', {id: roast.hr_id}]">{{i+1}}</a>
            } @else {
            {{i+1}}
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let roast">
            @if (roast.date) {
            <span>{{roast.date.toLocaleString(DateTime.DATE_SHORT)}}</span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Time">
        <th mat-header-cell *matHeaderCellDef>Uhrzeit</th>
        <td mat-cell *matCellDef="let roast">
            @if (roast.date) {
            <span>{{roast.date.toLocaleString(DateTime.TIME_SIMPLE)}}</span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="CoffeesOrBlend">
        <th mat-header-cell *matHeaderCellDef>Sorte</th>
        <td mat-cell *matCellDef="let roast">
            @if (roast?.coffee) {
            <span>
                @if (roast.coffee.hr_id) {
                <a [routerLink]="['/coffees', {id: roast.coffee.hr_id}]">{{roast.coffee.hr_id}}</a>
                }
                @if (roast.coffee.origin) {
                <span> {{tr.anslate(roast.coffee.origin)}}</span>
                }
                @if (roast.coffee.yearLabel) {
                <span> {{roast.coffee.yearLabel}}</span>
                }
                @if (roast.coffee.label && (roast.coffee.hr_id || roast.coffee.origin || roast.coffee.yearLabel)) {
                <br />
                }
                @if (roast.coffee.label) {
                <span> {{roast.coffee.label}}</span>
                }
            </span>
            }
            @if (roast?.blend; as blend) {
            <span>
                <ul class="ingredients-list">
                    @for (ing of blend.ingredients; track $index) {
                    <li>
                        @if (ing.ratio != null) {
                        <span>{{ing.ratio * 100 | number:'1.1-1'}}%
                            @if (ing.coffee?.hr_id) {
                            <a [routerLink]="['/coffees', {id: ing.coffee.hr_id}]"> {{ing.coffee.hr_id}}</a>
                            }
                            @if (ing.coffee?.origin) {
                            <span> {{tr.anslate(ing.coffee.origin)}}</span>
                            }
                            @if (ing.coffee?.yearLabel) {
                            <span> {{ing.coffee.yearLabel}}</span>
                            }
                            @if (ing.coffee?.label && (ing.coffee.origin || ing.coffee.yearLabel)) {
                            <span>,</span>
                            }
                            @if (ing.coffee?.label) {
                            <span> {{ing.coffee.label}}</span>
                            }
                        </span>
                        }
                    </li>
                    }
                </ul>
            </span>
            }
            @if (!roast?.coffee && !roast?.blend) {
            <ng-container *ngTemplateOutlet="noInfoError"></ng-container>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef colSpan="4" class="normalweight-footer right-align">
            @if (sum_discarded) {
            <br />ohne Fehlröstungen:
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef class="right-align">Rohkaffee</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.amount != null) {
            <span><span>{{roast.amount | number:'1.3-3'}}kg</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{sum_amount | number:'1.3-3'}}kg@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_amount - sum_discarded) | number:'1.3-3'}}kg</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="End Weight">
        <th mat-header-cell *matHeaderCellDef class="right-align">Röstkaffee</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.end_weight != null) {
            <span><span>{{roast.end_weight | number:'1.3-3'}}kg</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{sum_end_weight | number:'1.3-3'}}kg@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_end_weight - sum_end_weight_discarded) | number:'1.3-3'}}kg</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="Yield">
        <th mat-header-cell *matHeaderCellDef class="right-align">Ausbeute</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.amount && roast.end_weight != null) {
            <span><span>{{roast.end_weight / roast.amount * 100 | number:'1.1-1'}}%</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{sum_end_weight * 100 / sum_amount | number:'1.1-1'}}%@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_end_weight - sum_end_weight_discarded) / (sum_amount - sum_discarded) * 100 | number:'1.1-1'}}%</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="Loss">
        <th mat-header-cell *matHeaderCellDef class="right-align">Röstverlust</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.amount && roast.end_weight != null) {
            <span><span>{{(roast.amount - roast.end_weight) / roast.amount * 100 | number:'1.1-1'}}%</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{sum_amount - sum_end_weight | number:'1.3-3'}}kg@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_amount - sum_discarded - sum_end_weight + sum_end_weight_discarded) | number:'1.3-3'}}kg</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="Taxable">
        <th mat-header-cell *matHeaderCellDef class="right-align">Zu versteuern</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.end_weight != null && (!roast.discarded || (manualDiscarded && !roast.destroyed))) {
            <span><span>{{roast.end_weight | number:'1.3-3'}}kg</span></span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{((sum_end_weight - sum_end_weight_discarded) || 0) | number:'1.0-3'}}kg</td>
    </ng-container>
    <ng-container matColumnDef="Tax">
        <th mat-header-cell *matHeaderCellDef class="right-align">Steuer 2,19€/kg</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.end_weight != null && (!roast.discarded || (manualDiscarded && !roast.destroyed))) {
            <span><span>{{roast.end_weight * tax | number:'1.2-2'}}€</span></span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{(((sum_end_weight - sum_end_weight_discarded) * tax) || 0) | number:'1.2-2'}}€</td>
    </ng-container>
    <ng-container matColumnDef="Exclude Yield">
        <th mat-header-cell *matHeaderCellDef class="right-align">Fehlröstung</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.end_weight != null && roast.discarded && (!manualDiscarded || roast.destroyed)) {
            <span><span>{{roast.end_weight | number:'1.3-3'}}kg</span></span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">
            @if (sum_end_weight_discarded) {
            <span>{{sum_end_weight_discarded | number:'1.3-3'}}kg</span>
            } @else {
            ---
            }
        </td>
    </ng-container>
</table>
} @else {
Keine Röstungen in diesem Zeitraum vorhanden
}