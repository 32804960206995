import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslatorService } from 'src/app/util/services/translator.service';

export class PaginatorI18n {

    constructor(private readonly tr: TranslatorService) { }

    getPaginatorIntl(): MatPaginatorIntl {
        const paginatorIntl = new MatPaginatorIntl();
        paginatorIntl.itemsPerPageLabel = this.tr.anslate('Items per page');
        paginatorIntl.nextPageLabel = this.tr.anslate('Next page');
        paginatorIntl.previousPageLabel = this.tr.anslate('Previous page');
        paginatorIntl.firstPageLabel = this.tr.anslate('First page');
        paginatorIntl.lastPageLabel = this.tr.anslate('Last page');
        paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
        return paginatorIntl;
    }

    private getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return '0';
        }
        const nlength = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < nlength ? Math.min(startIndex + pageSize, nlength) : startIndex + pageSize;
        if (length === -1) {
            return this.tr.anslate('{{startIndex}} - {{endIndex}} of {{length}}', { startIndex: startIndex + 1, endIndex, length: '...' });
        }
        return this.tr.anslate('{{startIndex}} - {{endIndex}} of {{length}}', { startIndex: startIndex + 1, endIndex, length: nlength });
    }
}
