{
	"UNKNOWN": "DESCONHECIDO",
	"NEW": "NOVO",
	"Attention": "Atenção",
	"Please add at least one type of beans first.": "Por favor, adicione pelo menos um tipo de grãos primeiro.",
	"A blend needs at least one type of beans.": "Um blend precisa de pelo menos um tipo de grãos.",
	"Ratios must be positive and add up to 100%.": "As proporções devem ser positivas e ir até no máximo 100%.",
	"0% ratios are not allowed.": "Não são permitidas proporções de 0%.",
	"Please select beans or a blend.": "Por favor, selecione grãos ou um blend.",
	"Please enter an amount larger than 0.": "Digite um valor maior que 0.",
	"Do you really want to delete {{name}}?": "Deseja realmente excluir {$INTERPOLATION}?",
	"Do you really want to delete all your data?!": "Deseja mesmo excluir todos os seus dados ?!",
	"Do you really want to delete your account and all your data?!": "Deseja realmente excluir sua conta e todos os seus dados ?!",
	"Do you really want to delete this {{transaction_type}}?": "Deseja realmente excluir {$INTERPOLATION}?",
	"Do you really want to finalize this report and all contained roasts?": "Deseja realmente finalizar este relatório e todos as torras contidas nele?",
	"Do you really want to cancel the access to the account of {{nickname}}?": "Deseja realmente cancelar o acesso à conta de {$INTERPOLATION}?",
	"Set the stock of this coffee to 0 (consumed)?": "Definir o estoque deste café como 0 ('esgotado')?",
	"consumed": "esgotado",
	"Email sent. You can also forward the following link yourself": "Email enviado. Você também pode encaminhar o seguinte link",
	"Purchases": "Compras",
	"Sales": "Vendas",
	"Transfers": "Transferências",
	"Corrections": "Correções",
	"Correction": "Correção",
	"Purchase": "Compra",
	"Sale": "Venda",
	"Transfer": "Transferência",
	"Last correction": "Última correção",
	"Last purchase": "Última compra",
	"Last sale": "Última venda",
	"Last transfer": "Última transferência",
	"You can now switch accounts here": "Agora você pode mudar de conta aqui",
	"Check Online Status": "Verifique o status online",
	"Sun-grown": "Crescido pelo sol",
	"Plantation": "Plantação",
	"Garden": "Jardim",
	"Shade-grown": "Sombreado",
	"Forest": "Floresta",
	"Wild Forest": "Floresta nativa",
	"Organic Solvent": "Solvente orgânico",
	"Natural Decaffeination Process EA": "Processo natural de descafeinação AE",
	"Methylene Chloride Process": "Processo de cloreto de metileno",
	"Triglyceride Process": "Processo de triglicérides",
	"Water Filtered": "Água filtrada",
	"Swiss Water Process": "Processo Swiss Water",
	"Mountain Water Process": "Processo de água da montanha",
	"Supercritical Fluid": "Fluido supercrítico",
	"Carbon Dioxide Process": "Processo de dióxido de carbono",
	"Others": "Outros",
	"decaffeinated": "descafeínado",
	"European Preparation": "Preparação Européia",
	"American Preparation": "Preparação americana",
	"Abu Dhabi": "Abu Dhabi",
	"Ajman": "Ajman",
	"Azores and Madeira": "Açores e Madeira",
	"Bonaire": "Bonaire",
	"Caroline Islands": "Ilhas Carolinas",
	"Ceuta": "Ceuta",
	"Cocos Islands": "Ilhas Cocos",
	"Dubai": "Dubai",
	"E.C. unspecified": "EC não especificado",
	"Faeroe Islands": "Ilhas Faroé",
	"Falkland Islands": "Ilhas Malvinas",
	"Fujairah": "Fujairah",
	"Gaza Strip": "faixa de Gaza",
	"Holy See": "Santa Sé",
	"Leeward Islands": "Ilhas Sotavento",
	"Libyan Arab Jamahiriya": "Jamahiriya Árabe da Líbia",
	"Macau": "Macau",
	"Melilla": "Melilla",
	"Netherlands Antilles": "Antilhas Holandesas",
	"Northern Marianas": "Marianas do Norte",
	"Ras al Khaimah": "Ras al Khaimah",
	"Reunion": "Reunião",
	"Sabah": "Sabah",
	"Sao Tome and Principe": "São Tomé e Príncipe",
	"Sarawak": "Sarawak",
	"Sharjah": "Sharjah",
	"Svalbard and Jan Mayen Islands": "Ilhas Svalbard e Jan Mayen",
	"Swaziland": "Suazilândia",
	"Tahiti": "Taiti",
	"Umm al Qaiwain": "Umm al Qaiwain",
	"Windward Islands": "Ilhas de Barlavento",
	"Galápagos": "Galápagos",
	"Africa": "África",
	"Caribbean": "Caribe",
	"Central America": "América Central",
	"Europe": "Europa",
	"Canary Islands": "Ilhas Canárias",
	"South America": "América do Sul",
	"Asia": "Ásia",
	"ANI": "ANI",
	"Bali": "Bali",
	"Borneo": "Bornéu",
	"Flores": "Flores",
	"Java": "Java",
	"Papua": "Papua",
	"St. Lucia": "Santa Lúcia",
	"Sulawesi": "Sulawesi",
	"Sumatra": "Sumatra",
	"Sumbawa": "Sumbawa",
	"Oceania": "Oceania",
	"Hawaii": "Havaí",
	"Metric": "Métrica",
	"Imperial": "Imperial",
	"t": "m",
	"none": "Nenhum",
	"Weight of a bag in kg?": "Peso de uma saca em kg?",
	"Weight of a barrel in kg?": "Peso de um barril em kg?",
	"Weight of a box in kg?": "Peso de uma caixa em kg?",
	"Weight of a bag in lbs?": "Peso de uma saca em libras?",
	"Weight of a barrel in lbs?": "Peso de um barril em libras?",
	"Weight of a box in lbs?": "Peso de uma caixa em libras?",
	"yesterday": "ontem",
	"{{x}} days ago": "{$INTERPOLATION} dias atrás",
	"Stores": "Estoques",
	"Producers": "Produtores",
	"Suppliers": "Fornecedores",
	"Customers": "Clientes",
	"Fields": "Campos",
	"External Stores": "Estoques externos",
	"Color system": "Sistema de cores",
	"Week {{week number}}, {{year}}": "Semana {$INTERPOLATION}, {$INTERPOLATION_1}",
	"Quarter {{quarter number}}, {{year}}": "Quarto {$INTERPOLATION}, {$INTERPOLATION_1}",
	"error adding report": "erro ao adicionar relatório",
	"report dates are overlapping with report {{reportLabel}}": "as datas do relatório estão sobrepostas ao relatório {$INTERPOLATION}",
	"report with label {{label}} already exists": "o relatório com o nome {$INTERPOLATION} já existe",
	"report cannot be deleted: it is reconciled": "o relatório não pode ser excluído: ele é reconciliado",
	"no beans or blend": "sem feijão ou blend",
	"no amount": "nenhuma quantidade",
	"no roast date": "sem data de assado",
	"weight or yield not positive": "Peso ou produção não positivo",
	"Yield must be smaller than weight": "Produção deve ser menor que peso",
	"no roast location": "nenhum local assado",
	"before selected period": "antes do período selecionado",
	"unknown reason": "Razão desconhecida",
	"Roast Report": "Relatório de Assado",
	"Created by artisan.plus": "Criado por artisan.plus",
	"Fahrenheit": "Fahrenheit",
	"Celsius": "Celsius",
	"bag": "saca",
	"plural_bag": "sacas",
	"barrel": "barril",
	"plural_barrel": "barris",
	"box": "caixa",
	"plural_box": "caixas",
	"social": "social",
	"organic": "orgânico",
	"fair": "justo",
	"sustainable": "sustentável",
	"bird friendly": "pássaro amigável",
	"shade grown": "sombra crescida",
	"high quality": "alta qualidade",
	"Afghanistan": "Afeganistão",
	"Aland Islands": "Ilhas Aland",
	"Albania": "Albânia",
	"Algeria": "Argélia",
	"American Samoa": "Samoa Americana",
	"Andorra": "Andorra",
	"Angola": "Angola",
	"Anguilla": "Anguilla",
	"Antarctica": "Antártida",
	"Antigua and Barbuda": "Antígua e Barbuda",
	"Argentina": "Argentina",
	"Armenia": "Armênia",
	"Aruba": "Aruba",
	"Australia": "Austrália",
	"Austria": "Áustria",
	"Azerbaijan": "Azerbaijão",
	"Bahamas": "Bahamas",
	"Bahrain": "Barém",
	"Bangladesh": "Bangladesh",
	"Barbados": "Barbados",
	"Belarus": "Bielorrússia",
	"Belgium": "Bélgica",
	"Belize": "Belize",
	"Benin": "Benin",
	"Bermuda": "Bermudas",
	"Bhutan": "Butão",
	"Bolivia": "Bolívia",
	"Bonaire, Sint Eustatius and Saba": "Bonaire, Santo Eustáquio e Saba",
	"Bosnia and Herzegovina": "Bósnia e Herzegovina",
	"Botswana": "Botsuana",
	"Bouvet Island": "Ilha Bouvet",
	"Brazil": "Brasil",
	"British Indian Ocean Territory": "Território Britânico do Oceano Índico",
	"Brunei Darussalam": "Brunei Darussalam",
	"Bulgaria": "Bulgária",
	"Burkina Faso": "Burkina Faso",
	"Burundi": "Burundi",
	"Cape Verde": "Cabo Verde",
	"Cambodia": "Camboja",
	"Cameroon": "Camarões",
	"Canada": "Canadá",
	"Cayman Islands": "Ilhas Cayman",
	"Central African Republic": "República Centro-Africana",
	"Chad": "Chade",
	"Chile": "Chile",
	"China": "China",
	"Christmas Island": "Ilha do Natal",
	"Cocos (Keeling) Islands": "Ilhas Cocos (Keeling)",
	"Colombia": "Colômbia",
	"Comoros": "Comores",
	"Congo, DR": "Congo, RD",
	"Congo, Republic": "República do Congo",
	"Cook Islands": "Ilhas Cook",
	"Costa Rica": "Costa Rica",
	"Croatia": "Croácia",
	"Cuba": "Cuba",
	"Curaçao": "Curaçao",
	"Cyprus": "Chipre",
	"Czech Republic": "República Checa",
	"Denmark": "Dinamarca",
	"Djibouti": "Djibuti",
	"Dominica": "Dominica",
	"Dominican Republic": "República Dominicana",
	"Ecuador": "Equador",
	"Egypt": "Egito",
	"El Salvador": "El Salvador",
	"Equatorial Guinea": "Guiné Equatorial",
	"Eritrea": "Eritreia",
	"Estonia": "Estônia",
	"Eswatini": "Eswatini",
	"Ethiopia": "Etiópia",
	"Falkland Islands [Malvinas]": "Ilhas Falkland [Malvinas]",
	"Faroe Islands": "ilhas Faroe",
	"Fiji": "Fiji",
	"Finland": "Finlândia",
	"France": "França",
	"French Guiana": "Guiana Francesa",
	"French Polynesia": "Polinésia Francesa",
	"French Southern Territories": "Territórios Franceses do Sul",
	"Gabon": "Gabão",
	"Gambia": "Gâmbia",
	"Georgia": "Geórgia",
	"Germany": "Alemanha",
	"Ghana": "Gana",
	"Gibraltar": "Gibraltar",
	"Greece": "Grécia",
	"Greenland": "Groelândia",
	"Grenada": "Granada",
	"Guadeloupe": "Guadalupe",
	"Guam": "Guam",
	"Guatemala": "Guatemala",
	"Guernsey": "Guernsey",
	"Guinea": "Guiné",
	"Guinea-Bissau": "Guiné-Bissau",
	"Guyana": "Guiana",
	"Haiti": "Haiti",
	"Heard Island and McDonald Islands": "Ilha Heard e Ilhas McDonald",
	"Honduras": "Honduras",
	"Hong Kong": "Hong Kong",
	"Hungary": "Hungria",
	"Iceland": "Islândia",
	"India": "Índia",
	"Indonesia": "Indonésia",
	"Iran": "Irã",
	"Iraq": "Iraque",
	"Ireland": "Irlanda",
	"Isle of Man": "Ilha de Man",
	"Israel": "Israel",
	"Italy": "Itália",
	"Ivory Coast": "Costa do Marfim",
	"Jamaica": "Jamaica",
	"Japan": "Japão",
	"Jersey": "Jersey",
	"Jordan": "Jordânia",
	"Kazakhstan": "Cazaquistão",
	"Kenya": "Quênia",
	"Kiribati": "Kiribati",
	"North Korea": "Coréia do Norte",
	"South Korea": "Coréia do Sul",
	"Kuwait": "Kuwait",
	"Kyrgyzstan": "Quirguistão",
	"Laos": "Laos",
	"Latvia": "Letônia",
	"Lebanon": "Líbano",
	"Lesotho": "Lesoto",
	"Liberia": "Libéria",
	"Libya": "Líbia",
	"Liechtenstein": "Liechtenstein",
	"Lithuania": "Lituânia",
	"Luxembourg": "Luxemburgo",
	"Macao": "Macau",
	"North Macedonia": "Macedônia do Norte",
	"Madagascar": "Madagáscar",
	"Malawi": "Malawi",
	"Malaysia": "Malásia",
	"Maldives": "Maldivas",
	"Mali": "Mali",
	"Malta": "Malta",
	"Marshall Islands": "Ilhas Marshall",
	"Martinique": "Martinica",
	"Mauritania": "Mauritânia",
	"Mauritius": "Maurícia",
	"Mayotte": "Mayotte",
	"Mexico": "México",
	"Micronesia": "Micronésia",
	"Moldova": "Moldávia",
	"Monaco": "Mônaco",
	"Mongolia": "Mongólia",
	"Montenegro": "Montenegro",
	"Montserrat": "Montserrat",
	"Morocco": "Marrocos",
	"Mozambique": "Moçambique",
	"Myanmar": "Myanmar",
	"Namibia": "Namíbia",
	"Nauru": "Nauru",
	"Nepal": "Nepal",
	"Netherlands": "Países Baixos",
	"New Caledonia": "Nova Caledônia",
	"New Zealand": "Nova Zelândia",
	"Nicaragua": "Nicarágua",
	"Niger": "Níger",
	"Nigeria": "Nigéria",
	"Niue": "Niue",
	"Norfolk Island": "Ilha Norfolk",
	"Northern Mariana Islands": "Ilhas Marianas do Norte",
	"Norway": "Noruega",
	"Oman": "Omã",
	"Pakistan": "Paquistão",
	"Palau": "Palau",
	"Palestine": "Palestina",
	"Panama": "Panamá",
	"PNG": "PNG",
	"Paraguay": "Paraguai",
	"Peru": "Peru",
	"Philippines": "Filipinas",
	"Pitcairn": "Pitcairn",
	"Poland": "Polônia",
	"Portugal": "Portugal",
	"Puerto Rico": "Porto Rico",
	"Qatar": "Catar",
	"Réunion": "Reunião",
	"Romania": "Romênia",
	"Russian Federation": "Federação Russa",
	"Rwanda": "Ruanda",
	"Saint Barthélemy": "São Bartolomeu",
	"St. Helena": "Santa Helena",
	"Saint Kitts and Nevis": "São Cristóvão e Nevis",
	"Saint Lucia": "Santa Lúcia",
	"Saint Martin (French part)": "São Martinho (parte francesa)",
	"Saint Pierre and Miquelon": "São Pedro e Miquelon",
	"St. Vincent": "São Vicente",
	"Samoa": "Samoa",
	"San Marino": "San Marino",
	"São Tomé": "São Tomé",
	"Saudi Arabia": "Arábia Saudita",
	"Senegal": "Senegal",
	"Serbia": "Sérvia",
	"Seychelles": "Seychelles",
	"Sierra Leone": "Serra Leoa",
	"Singapore": "Cingapura",
	"Sint Maarten (Dutch part)": "São Martinho (parte holandesa)",
	"Slovakia": "Eslováquia",
	"Slovenia": "Eslovênia",
	"Solomon Islands": "Ilhas Salomão",
	"Somalia": "Somália",
	"South Africa": "África do Sul",
	"South Georgia and the South Sandwich Islands": "Ilhas Geórgia do Sul e Sandwich do Sul",
	"South Sudan": "Sudão do Sul",
	"Spain": "Espanha",
	"Sri Lanka": "Sri Lanka",
	"Sudan": "Sudão",
	"Suriname": "Suriname",
	"Svalbard and Jan Mayen": "Svalbard e Jan Mayen",
	"Sweden": "Suécia",
	"Switzerland": "Suíça",
	"Syrian Arab Republic": "República Árabe da Síria",
	"Taiwan": "Taiwan",
	"Tajikistan": "Tajiquistão",
	"Tanzania": "Tanzânia",
	"Thailand": "Tailândia",
	"Timor, East": "Timor Leste",
	"Timor": "Timor",
	"Togo": "Ir",
	"Tokelau": "Tokelau",
	"Tonga": "Tonga",
	"Trinidad and Tobago": "Trindade e Tobago",
	"Tunisia": "Tunísia",
	"Turkey": "Peru",
	"Turkmenistan": "Turquemenistão",
	"Turks and Caicos Islands": "Ilhas Turcas e Caicos",
	"Tuvalu": "Tuvalu",
	"Uganda": "Uganda",
	"Ukraine": "Ucrânia",
	"United Arab Emirates": "Emirados Árabes Unidos",
	"UK": "Reino Unido",
	"United States Minor Outlying Islands": "Ilhas Menores Distantes dos Estados Unidos",
	"USA": "EUA",
	"Uruguay": "Uruguai",
	"Uzbekistan": "Usbequistão",
	"Vanuatu": "Vanuatu",
	"Venezuela": "Venezuela",
	"Vietnam": "Vietnã",
	"Virgin Islands (British)": "Ilhas Virgens Britânicas",
	"Virgin Islands (U.S.)": "Ilhas Virgens (EUA)",
	"Wallis and Futuna": "Wallis e Futuna",
	"Western Sahara": "Saara Ocidental",
	"Yemen": "Iémen",
	"Zambia": "Zâmbia",
	"Zimbabwe": "Zimbábue",
	"Single farm": "Fazenda única",
	"Cooperation": "Cooperativa",
	"could not get report: {{error}}": "não foi possível obter o relatório: {$INTERPOLATION}",
	"could not save PDF file: {{error}}": "não foi possível salvar o arquivo PDF: {$INTERPOLATION}",
	"could not save the report: {{error}}": "não foi possível salvar o relatório: {$INTERPOLATION}",
	"could not delete the report: {{error}}": "não foi possível excluir o relatório: {$INTERPOLATION}",
	"could not update the report: {{error}}": "não foi possível atualizar o relatório: {$INTERPOLATION}",
	"report not found": "relatório não encontrado",
	"report already reconciled": "relatório já reconciliado",
	"could not reconcile the report: {{error}}": "não foi possível reconciliar o relatório: {$INTERPOLATION}",
	"could not find the report with id {{report_id}}": "não foi possível encontrar o relatório com o ID {$INTERPOLATION}",
	"error creating PDF": "erro ao criar PDF",
	"error creating CSV": "erro ao criar CSV",
	"error creating Sheet": "erro ao criar planilha",
	"error creating XML": "erro ao criar XML",
	"Type": "Tipo",
	"Items per page": "Itens por página",
	"Next page": "Próxima página",
	"Previous page": "Página anterior",
	"0 of {{length}}": "0 de {$INTERPOLATION}",
	"{{startIndex}} - {{endIndex}} of {{length}}": "{$INTERPOLATION} - {$INTERPOLATION_1} de {$INTERPOLATION_2}",
	"First page": "Primeira página",
	"Last page": "Última página",
	"Months": "Meses",
	"Regions": "Regiões",
	"Accumulated Production": "Produção Acumulada",
	"Yearly Production": "Produção Anual",
	"Ambient": "Ambiente",
	"Machines": "Máquinas",
	"Users": "Usuários",
	"User": "Usuário",
	"Weight": "Peso",
	"Value": "Valor",
	"Certification": "Certificação",
	"Water Activity": "Atividade de Água",
	"Origins": "Origens",
	"estimated yield": "rendimento estimado",
	"estimated amount": "quantidade estimada",
	"month": "mês",
	"batches": "lotes",
	"batch": "lote",
	"one batch": "um lote",
	"days": "dias",
	"{{count}} on one day": "{$INTERPOLATION} em um dia",
	"{{count}} on {{days}} days": "{$INTERPOLATION} em {$INTERPOLATION_1} dias",
	"still ~{{month_left}} months": "ainda ~{$INTERPOLATION} meses",
	"still ~{{weeks_left}} weeks": "ainda ~{$INTERPOLATION} semanas",
	"still about one week": "ainda cerca de uma semana",
	"still about one month": "ainda cerca de um mês",
	"more than": "mais que",
	"weekly": "semanal",
	"monthly": "mensal",
	"Monthly Production": "Produção Mensal",
	"This year": "Este ano",
	"Last year": "Ano passado",
	"{{nrCounts}} roasts": "{$INTERPOLATION} torras",
	"one roast": "uma torra",
	"one lot": "um lote",
	"{{nrCoffees}} lots": "{$INTERPOLATION} lotes",
	"1 month": "1 mês",
	"{{nrMonth}} months": "{$INTERPOLATION} meses",
	"1 year": "1 ano",
	"{{nrYears}} years": "{$INTERPOLATION} anos",
	"today": "agora",
	"stored": "armazenado",
	"with replacements": "com substituições",
	"roasted": "torrado",
	"estimated age": "idade estimada",
	"Age": "Idade",
	"age": "idade",
	"yield": "produção",
	"amount": "montante",
	"expected total age": "idade total esperada",
	"expected reach": "alcance esperado",
	"min reach": "alcance mínimo",
	"max reach": "alcance máximo",
	"harvested": "colhido",
	"Please click on the link in the email we sent to {{email}}": "Por favor, clique no link no e-mail que enviamos para {$INTERPOLATION}",
	"Your login expired, please login again.": "Seu login expirou, faça o login novamente.",
	"Successfully removed": "Removido com sucesso",
	"Successfully added": "Adicionado com sucesso",
	"Successfully updated": "Atualizado com sucesso",
	"Successfully finalized": "Finalizado com sucesso",
	"Finalized": "Finalizado",
	"Successfully sent email to {{target_email}}": "Email enviado com sucesso para {$INTERPOLATION}",
	"Nothing to change": "Nada para mudar",
	"Ignored since amount was zero": "Ignorado, peso era zero",
	"Are you connected to the Internet?": "Você está conectado à Internet?",
	"sum of ratios must be 100%, not {{sum}}": "a soma das proporções deve ser de 100%, não {$INTERPOLATION}",
	"Your browser sent too many requests too quickly. Please slow down.": "Seu navegador enviou muitas solicitações muito rapidamente. Por favor, diminua a velocidade.",
	"beans with label {{label}} already exist": "grãos com o rótulo {$INTERPOLATION} já existem",
	"Http failure response for https": "Resposta de falha de HTTP para https",
	"Not a valid file": "Não é um arquivo válido",
	"Could not set new image": "Não foi possível definir nova imagem",
	"Error loading image. Please choose a new one!": "Erro ao carregar a imagem. Por favor, escolha uma nova!",
	"Could not update documents": "Não foi possível atualizar os documentos",
	"Invalid date": "Data inválida",
	"{{imageName}} is not a valid image": "{$INTERPOLATION} não é uma imagem válida",
	"password must not be empty": "a senha não pode estar em branco",
	"repeated password does not match": "senha repetida não corresponde",
	"could not update the password": "não foi possível atualizar a senha",
	"could not delete the data": "não foi possível excluir os dados",
	"Invitation failed. Did you use the invitation link?": "Falha no convite. Você usou o link do convite?",
	"You will LOSE ACCESS to all of your OWN existing data - you have {{nrExistingObjects}} objects": "Você perderá acesso a todos os seus próprios dados existentes - você tem {$INTERPOLATION} objetos",
	"If you do not want this, cancel and ask the person who invited you to use another of your email addresses": "Se você não quiser, cancele e peça à pessoa que o convidou para usar outro endereço de e-mail",
	"Could not reach server.": "Não foi possível acessar o servidor.",
	"Please repeat the password reset process": "Repita o processo de redefinição de senha",
	"Could not send verification email": "Não foi possível enviar o email de verificação",
	"this does not belong to current user": "isso não pertence ao usuário atual",
	"cannot update since a related item is already finalized": "Não é possível atualizar, pois um item relacionado já foi finalizado",
	"error updating the beans information": "erro ao atualizar as informações dos grãos",
	"error updating the roast information": "erro ao atualizar as informações de torra",
	"error updating the blend information": "erro ao atualizar as informações da blend",
	"error updating the store information": "erro ao atualizar as informações do estoque",
	"error updating the stock information": "erro ao atualizar as informações do estoque",
	"error updating the information": "erro ao atualizar as informações",
	"error updating user information": "erro ao atualizar informações do usuário",
	"error retrieving all stores": "erro ao carregar todos os estoques",
	"error retrieving all locations": "erro ao carregar todos os locais",
	"error retrieving all beans": "erro ao carregar todos os grãos",
	"error retrieving all blends": "erro ao carregar todos os blends",
	"error retrieving all customers": "erro ao carregar todos os clientes",
	"error retrieving reports": "erro ao carregar relatórios",
	"error retrieving graph data": "erro ao carregar dados do gráfico",
	"error retrieving all roasts": "erro ao carregar todas as torras",
	"error adding store": "erro ao adicionar estoque",
	"error adding purchase": "erro ao adicionar compra",
	"error adding correction": "erro ao adicionar correção",
	"error adding transfer": "erro ao adicionar transferência",
	"error adding sale": "erro ao adicionar venda",
	"error editing store": "erro ao editar estoque",
	"error editing purchase": "erro ao editar a compra",
	"error editing correction": "erro ao editar a correção",
	"error editing transfer": "erro ao editar a transferência",
	"error editing sale": "erro ao editar venda",
	"error deleting purchase": "erro ao excluir a compra",
	"error deleting correction": "erro ao excluir a correção",
	"error deleting transfer": "erro ao excluir a transferência",
	"error deleting sale": "erro ao excluir a venda",
	"no documents were uploaded": "nenhum documento foi enviado",
	"server error": "erro no servidor",
	"error": "erro",
	"User with email {{email}} not found": "Usuário com e-mail {$INTERPOLATION} não encontrado",
	"Email not verified. Please check the email sent by admin@artisan.plus": "Email não verificado. Por favor, verifique o email enviado por admin@artisan.plus",
	"Your account does not have enough credit. Please recharge at {{link}}": "Sua conta não tem crédito suficiente. Recarregue em {$INTERPOLATION}",
	"wrong email or password": "e-mail ou senha incorretos",
	"wrong password given": "senha digitada está incorreta",
	"User with email {{email}} already exists": "O usuário com e-mail {$INTERPOLATION} já existe",
	"you are not allowed to access this data": "você não tem permissão para acessar esses dados",
	"user not found": "usuário não encontrado",
	"not found": "não encontrado",
	"producer with label {{label}} already exists": "produtor com rótulo {$INTERPOLATION} já existe",
	"could not add producer {{label}}": "não foi possível adicionar o produtor {$INTERPOLATION}",
	"could not update producer {{label}}": "não foi possível atualizar o produtor {$INTERPOLATION}",
	"could not update supplier {{label}}": "não foi possível atualizar o fornecedor {$INTERPOLATION}",
	"could not update customer {{label}}": "não foi possível atualizar o cliente {$INTERPOLATION}",
	"could not update roast {{label}}": "não foi possível atualizar a torra {$INTERPOLATION}",
	"could not update coffee {{label}}": "não foi possível atualizar o café {$INTERPOLATION}",
	"location with label {{label}} already exists": "o local com o marcador {$INTERPOLATION} já existe",
	"could not add beans {{label}}": "não foi possível adicionar grãos {$INTERPOLATION}",
	"supplier with label {{label}} already exists": "fornecedor com rótulo {$INTERPOLATION} já existe",
	"blend with label {{label}} already exists": "blend com o rótulo {$INTERPOLATION} já existe",
	"customer with label {{label}} already exists": "cliente com o rótulo {$INTERPOLATION} já existe",
	"stock update failed": "atualização de estoque falhou",
	"could not save image file {{error}}": "não foi possível salvar o arquivo de imagem {$INTERPOLATION}",
	"wrong email verification link": "link de verificação de e-mail incorreto",
	"Email verify failed": "Falha na verificação do e-mail",
	"Hidden beans with this name + picked year + origin already exist, {{info}}": "Grãos (escondidos) com nome + ano escolhida + origem já existe: {$INTERPOLATION}",
	"Beans with this name + picked year + origin already exist, {{info}}": "Grãos com nome + ano escolhida + origem já existe: {$INTERPOLATION}",
	"Failed updating blend templates": "Falha ao atualizar modelos de blend",
	"user data could not be downloaded": "não foi possível fazer o download dos dados do usuário",
	"Update available, please do a browser refresh (CMD-SHIFT-R or CTRL-SHIFT-F5)": "Atualização disponível, faça uma atualização do navegador (CMD-SHIFT-R ou CTRL-SHIFT-F5)",
	"location not found": "localização não encontrado",
	"could not find beans": "grãos não encontrados",
	"roast not found": "torra não encontrada",
	"store not found": "estoque não encontrado",
	"purchase not found": "compra não encontrada",
	"transfer not found": "transferência não encontrada",
	"sale not found": "venda não encontrada",
	"correction not found": "correção não encontrada",
	"erroneous altitude range": "faixa de altitude incorreta",
	"erroneous screen size range": "faixa de tamanho de peneira incorreta",
	"must specify target of transfer": "deve especificar o destino da transferência",
	"must specify location of change": "deve especificar o localização da mudança",
	"must specify the beans to be changed": "deve especificar os grãos a serem alterados",
	"roast must not have both beans and blend": "a torra não deve ter grãos e blend ao mesmo tempo",
	"invalid ID given for Location": "ID inválido fornecido para localização",
	"error retrieving a store": "erro ao carregar um estoque",
	"trying to get location": "tentando obter localização",
	"error retrieving stock of a store": "erro ao recuperar o estoque disponível de uma locação de estoque",
	"error retrieving all store stocks": "erro ao recuperar todos os estoques da locação",
	"error retrieving all purchases for the beans": "erro ao recuperar todas as compras para os grãos",
	"error adding the custom selection property": "erro ao adicionar a propriedade de seleção customizada",
	"error adding the region": "erro ao adicionar a região",
	"error updating the additional report information": "erro ao atualizar as informações adicionais do relatório",
	"could not identify the user": "não foi possível identificar o usuário",
	"could not find the user": "não foi possível encontrar o usuário",
	"could not update the user account": "não foi possível atualizar a conta do usuário",
	"could not update the user": "não foi possível atualizar o usuário",
	"user is not associated with an account. Please send an email to admin@artisan.plus": "o usuário não está associado a uma conta. Por favor, envie um email para admin@artisan.plus",
	"Your account is not activated. Please send an email to admin@artisan.plus": "Sua conta não está ativada. Por favor, envie um email para admin@artisan.plus",
	"missing or invalid start or end date": "data inicial ou final ausente ou inválida",
	"missing or invalid date": "data ausente ou inválida",
	"no user information given": "nenhuma informação do usuário fornecida",
	"no email given": "nenhum email fornecido",
	"no password given": "nenhuma senha fornecida",
	"no nickname given": "nenhum apelido fornecido",
	"could not send email": "não foi possível enviar e-mail",
	"wrong password reset link": "link de redefinição de senha incorreto",
	"not allowed to change someone else\\'s password": "não é permitido alterar a senha de outra pessoa",
	"could not create account with customer_code {{ccode}}": "não foi possível criar uma conta com customer_code {$INTERPOLATION}",
	"could not add user": "não foi possível adicionar usuário",
	"successfully uploaded data but could not set password": "dados enviados com sucesso, mas não foi possível definir a senha",
	"could not retrieve user from id {{userId}}": "não foi possível recuperar o usuário do id {$INTERPOLATION}",
	"object with id {{id}} not found": "objeto com o id {$INTERPOLATION} não encontrado",
	"{{model}} with id {{_id}} not found": "{$INTERPOLATION} com o id {$INTERPOLATION_1} não encontrado",
	"trying to get {{model}} by name {{label}}": "tentando obter {$INTERPOLATION} pelo nome {$INTERPOLATION_1}",
	"could not add producer location {{label}}": "não foi possível adicionar a localização do produtor {$INTERPOLATION}",
	"location with {{label and type info}} already exists": "a localização {$INTERPOLATION} já existe",
	"could not add location {{label}}": "não foi possível adicionar a localização {$INTERPOLATION}",
	"could not update location {{label}}": "não foi possível atualizar a localização {$INTERPOLATION}",
	"could not update location of {{producerLabel}}": "não foi possível atualizar a localização de {$INTERPOLATION}",
	"could not add supplier {{label}}": "não foi possível adicionar o fornecedor {$INTERPOLATION}",
	"could not add customer {{label}}": "não foi possível adicionar o cliente {$INTERPOLATION}",
	"could not find blend with ID {{blend}}": "não foi possível encontrar o blend com o ID {$INTERPOLATION}",
	"trying to get {{model}}": "tentando obter {$INTERPOLATION}",
	"invalid ID given for {{property}}": "ID inválido fornecido para {$INTERPOLATION}",
	"could not make related updates": "não foi possível fazer as atualizações relacionadas",
	"stock of beans at location {{location}} could not be updated": "o estoque de grãos na locação {$INTERPOLATION} não pôde ser atualizado",
	"value of beans at location {{location}} could not be calculated": "o valor dos grãos na locação {$INTERPOLATION} não pôde ser calculado",
	"could not update property {{error}}": "não foi possível atualizar a propriedade {$INTERPOLATION}",
	"roast with roast_id {{roast_id}} not found": "torra com roast_id {$INTERPOLATION} não encontrado",
	"rejected deleting all {{model}}": "rejeitou a exclusão de todos os {$INTERPOLATION}",
	"could not check if {{model}} exists in DB": "não foi possível verificar se {$INTERPOLATION} existe no banco de dados",
	"logged in user not the same as user to be deleted": "usuário conectado não é o mesmo usuário a ser excluído",
	"logged in user not the same as user to be updated": "usuário conectado não é o mesmo usuário a ser atualizado",
	"no data": "sem dados",
	"could not delete location": "não foi possível excluir a locação",
	"could not un-reconcile a transaction in the report": "não foi possível reconciliar uma transação no relatório",
	"undefined": "indefinido",
	"error retrieving information": "erro ao recuperar informações",
	"Company number": "Número da empresa",
	"Company name": "Nome da empresa",
	"over limit": "ACIMA DO LIMITE",
	"Reminders menu label": "Lembretes",
	"active": "ativo",
	"Rename machines": "Renomear máquinas",
	"{{mainUnit}} roasted": "{$INTERPOLATION} torrado",
	"hours of roasting": "horas de torra",
	"minutes of roasting": "minutos de torra",
	"min": "min",
	"time": "tempo",
	"h": "h",
	"hour of roasting": "hora de torra",
	"weeks": "semanas",
	"years": "anos",
	"day of the week": "dia da semana",
	"day of the month": "dia do mês",
	"propane consumed": " gás consumido",
	"On": "Sobre",
	"After": "Depois",
	"Repetition": "Repetição",
	"Repetitions": "Repetições",
	"Maximum number of repetitions restricted by": "Número máximo de repetições restrito por",
	"repetitions possible with the current stock": "repetições possíveis com o estoque atual",
	"error retrieving all reminders": "erro ao recuperar todos os lembretes",
	"daily": "diário",
	"yearly": "anual",
	"Condition": "Condição",
	"starting from a date": "de",
	"Day": "Dia",
	"Week": "Semana",
	"Completed Tasks": "Tarefas concluídas",
	"Here you can see, and manually document, completed actions such as the routine cleaning of machines.": "Aqui você pode ver e documentar manualmente as ações concluídas, como a limpeza contínua das máquinas.",
	"Reminder": "Lembrete",
	"Here you can let the system remind you of recurring tasks such as 'Clean thermocouple every week'.": "Aqui você pode deixar o sistema lembrá-lo de tarefas recorrentes, como 'Limpar termopar toda semana'.",
	"Every /n/ {{PLACEHOLDER}}": "A cada /n/ {$INTERPOLATION}",
	"Every /WEEKDAY/": "Todos os /WEEKDAY/",
	"Every /nthDAYOFMONTH/ day of the month": "Todo /nthDAYOFMONTH/ dia do mês",
	"(every n>=2) days": "dias",
	"(every n>=2) weeks": "semanas",
	"(every n>=2) months": "meses",
	"(every n>=2) years": "anos",
	"Propane bottle": "Garrafa de propano",
	"Once {{kg}} have been roasted": "Assim que {$INTERPOLATION} tiver sido assado",
	"Clean fans": "Ventiladores limpos",
	"Clean cooling tray": "Limpe a bandeja de resfriamento",
	"Submit tax report": "Enviar relatório fiscal",
	"Pay tax bill": "Pagar conta de imposto",
	"After each roast hour": "Após cada hora de assado",
	"expired since {{kg}}": "expirou desde {$INTERPOLATION}",
	"expired {{time}}": "expirou {$INTERPOLATION}",
	"next {{time}}": "próximo {$INTERPOLATION}",
	"Every {{interval}} {{intervalUnit}}": "A cada {$INTERPOLATION}{$INTERPOLATION_1}",
	"Every {{weekday}}": "A cada {$INTERPOLATION}",
	"Every {{dayOfMonth}}": "A cada {$INTERPOLATION}",
	"from {{date}}": "desde {$INTERPOLATION}",
	"After {{minutes}} roast minutes": "Após {$INTERPOLATION} minutos de torra",
	"After {{hours}} roast hours": "Após {$INTERPOLATION} horas de torra",
	"After {{n}} roasts": "Após {$INTERPOLATION} assados",
	"{{interval}} to go": "ainda faltam {$INTERPOLATION}",
	"Lubricate drum bearings": "Lubrifique os rolamentos do tambor",
	"Clean exhaust ducting": "Limpe o duto de exaustão",
	"Lubricate drum drive chain": "Lubrifique a corrente de acionamento do tambor",
	"Replace air filter": "Substitua o filtro de ar",
	"Generate monthly roast report": "Gerar relatório mensal de torra",
	"Replace gas bottle": "Substitua a garrafa de gás",
	"Prepare organic audit": "Preparar auditoria orgânica",
	"Empty chaff container": "Esvaziar o recipiente de palha",
	"Once {{kg}} {{gasUnit}} propane consumed ({{btu}} {{btuUnit}})": "Uma vez que {$INTERPOLATION}{$INTERPOLATION_1} propano consumido ({$INTERPOLATION_2}{$INTERPOLATION_3})",
	"Notify all": "Notificar todos",
	"Browser": "Navegador",
	"Notification": "Notificação",
	"Best add this email to your address book": "Melhor adicionar este e-mail isso ao seu catálogo de endereços",
	"OR": "OU",
	"After each roast": "Depois de cada torra",
	"average FIFO cost comes from": "A média de {$INTERPOLATION_1}/{$INTERPOLATION_2} vem do custo atual de {$INTERPOLATION_4} para seu estoque atual de {$INTERPOLATION_5}{$INTERPOLATION_6} grãos",
	"Corrections are not listed": "As correções não estão listadas",
	"Maximum for display reached": "Máximo para exibição atingido",
	"plural_discarded": "descartados",
	"from": "de",
	"Attribute": "Atributo",
	"Load / Save ...": "Carregar / Salvar ...",
	"Click here to add more filters": "Adicionar mais filtros",
	"No amount set": "Sem quantidade definido",
	"No yield set": "Sem produção definido",
	"No beans set": "Nenhum grão definido",
	"No blends": "Sem blends",
	"Specific bean in blends": "Grão específico em blends",
	"missing value": "valor faltante",
	"any value": "qualquer valor",
	"any": "todos",
	"or": "ou",
	"and": "e",
	"not": "não",
	"Summary": "Resumo",
	"List of roasts": "lista de torras",
	"BT": "BT",
	"FC": "PC",
	"Only one value found": "Apenas um valor encontrado",
	"Share beans info": "Compartilhe informações sobre feijão",
	"Copy to clipboard": "Copiar para área de transferência",
	"See what will be shared": "Veja o que será compartilhado",
	"Scheduler menu label": "Agendar",
	"5848348023107769657": "Peso",
	"Title": "Título",
	"This is a system wide change. All existing roasts, templates, scheduled items etc. will be affected. Cannot be undone.": "Esta é uma mudança em todo o sistema. Todos os assados, modelos, itens programados, etc. existentes serão afetados. Não pode ser desfeito.",
	"Caution! Chosen machine name already exists, you will not be able to separate roasts by machine after the change.": "Cuidado! O nome da máquina escolhida já existe, não será possível separar os assados por máquina após a alteração.",
	"Cupping": "Cupping",
	"Always available as template": " Sempre disponível como modelo",
	"Roasting Notes": "Notas de torrefação",
	"Cupping Notes": "Notas de degustação",
	"Search beans": "Pesquisar feijões",
	"Add as post blend": "Adicionar como pós-mistura",
	"Save": "Economizar",
	"Missing": "Ausente",
	"From history": "Da história",
	"Search templates": "Modelos de pesquisa",
	"Add machines": "Adicionar máquinas",
	"Favorites": "Favoritos",
	"Reload - check for changes": "Recarregar - verifique se há alterações",
	"not specified": "não especificado",
	"Reduce displayed stock by planned amounts": "Reduzir o estoque exposto em quantidades planejadas",
	"Search roast": "Pesquisar assado",
	"Batch": "Lote",
	"Hauptzollamt Aachen": "Hauptzollamt Aachen",
	"Hauptzollamt Augsburg": "Hauptzollamt Augsburgo",
	"Hauptzollamt Berlin": "Hauptzollamt Berlim",
	"Hauptzollamt Bielefeld": "Hauptzollamt Bielefeld",
	"Hauptzollamt Braunschweig": "Hauptzollamt Braunschweig",
	"Hauptzollamt Bremen": "Hauptzollamt Bremen",
	"Hauptzollamt Darmstadt": "Hauptzollamt Darmstadt",
	"Hauptzollamt Dortmund": "Hauptzollamt Dortmund",
	"Hauptzollamt Dresden": "Hauptzollamt Dresden",
	"Hauptzollamt Duisburg": "Hauptzollamt Duisburgo",
	"Hauptzollamt Düsseldorf": "Hauptzollamt Dusseldorf",
	"Hauptzollamt Erfurt": "Hauptzollamt Erfurt",
	"Hauptzollamt Frankfurt (Oder)": "Hauptzollamt Frankfurt (Oder)",
	"Hauptzollamt Frankfurt am Main": "Hauptzollamt Frankfurt am Main",
	"Hauptzollamt Gießen": "Hauptzollamt Gießen",
	"Hauptzollamt Hamburg": "Hauptzollamt Hamburgo",
	"Hauptzollamt Hannover": "Hauptzollamt Hanôver",
	"Hauptzollamt Heilbronn": "Hauptzollamt Heilbronn",
	"Hauptzollamt Itzehoe": "Hauptzollamt Itzehoe",
	"Hauptzollamt Karlsruhe": "Hauptzollamt Karlsruhe",
	"Hauptzollamt Kiel": "Hauptzollamt Kiel",
	"Hauptzollamt Koblenz": "Hauptzollamt Koblenz",
	"Hauptzollamt Köln": "Hauptzollamt Colônia",
	"Hauptzollamt Krefeld": "Hauptzollamt Krefeld",
	"Hauptzollamt Landshut": "Hauptzollamt-Landshut",
	"Hauptzollamt Lörrach": "Hauptzollamt Lörrach",
	"Hauptzollamt Magdeburg": "Hauptzollamt Magdeburgo",
	"Hauptzollamt München": "Hauptzollamt Munique",
	"Hauptzollamt Münster": "Hauptzollamt Munster",
	"Hauptzollamt Nürnberg": "Hauptzollamt Nuremberga",
	"Hauptzollamt Oldenburg": "Hauptzollamt Oldemburgo",
	"Hauptzollamt Osnabrück": "Hauptzollamt Osnabrück",
	"Hauptzollamt Potsdam": "Hauptzollamt Potsdam",
	"Hauptzollamt Regensburg": "Hauptzollamt Ratisbona",
	"Hauptzollamt Rosenheim": "Hauptzollamt Rosenheim",
	"Hauptzollamt Saarbrücken": "Hauptzollamt Saarbrücken",
	"Hauptzollamt Schweinfurt": "Hauptzollamt Schweinfurt",
	"Hauptzollamt Singen": "Hauptzollamt Singen",
	"Hauptzollamt Stralsund": "Hauptzollamt Stralsund",
	"Hauptzollamt Stuttgart": "Hauptzollamt Estugarda",
	"Hauptzollamt Ulm": "Hauptzollamt Ulm",
	"of": "de",
	"Item cannot be updated, probably already synchronized with Artisan": "O item não pode ser atualizado, provavelmente já sincronizado com o Artisan",
	"Item cannot be updated, roasting has already started": "O item não pode ser atualizado, a torra já começou",
	"Please enter a value for {{missingField}}": "Insira um valor para {$INTERPOLATION}",
	"Some items could not be deleted": "Não foi possível excluir alguns itens",
	"error updating the schedule information": "erro ao atualizar as informações do agendamento",
	"error updating the schedule": "erro ao atualizar o agendamento",
	"error retrieving the schedule": "erro ao recuperar a agendamento",
	"Beans used in blends": "Feijão usado em blends",
	"Batches": "Lotes",
	"Batch size": "Tamanho do batch",
	"are you sure": "Você tem certeza?",
	"Upload documents": "Enviar documentos",
	"to customer": "para",
	"list header": "Número de transações: {$INTERPOLATION}",
	"for price": "para",
	"from source": "de",
	"to target": "para",
	"on {{date}}": "em {$INTERPOLATION}",
	"Roasts": "Torras",
	"Sequential": "Completo",
	"Open": "Livre",
	"You can COPY or MOVE all your roast reports from the 'Sequential' section here:": "Você pode COPIAR ou MOVER todos os seus relatórios de torrefação da seção 'Completo' aqui:",
	"x% of a blend": "({$INTERPOLATION} de um blend)",
	"of a blend": "de um blend",
	"between dates": "entre {$INTERPOLATION} e {$INTERPOLATION_1}",
	"Roast": "Torra",
	"roast": "torra",
	"Report": "Relatório",
	"Beans": "Grãos",
	"Blend": "Blend",
	"cannot delete": "Não foi possível excluir!",
	"cannot delete because of references": "{$INTERPOLATION} não pode ser excluído porque está em uso.",
	"Customs": "Personalização",
	"Stocks": "Estoques",
	"no purchases made": "Nenhuma compra feita. Os custos são calculados usando apenas compras. Considere adicionar uma compra ou substituir uma correção por uma compra.",
	"amount header": "Quantidade",
	"Cost": "Custo",
	"limit header": "Limite para alerta de estoque",
	"add purchase": "Adicionar compra",
	"edit purchase": "Editar compra",
	"Filter ...": "Filtrar ...",
	"Find / Add ...": "Filtrar / Adicionar ...",
	"Add new beans": "Adicionar novos grãos",
	"rename": "Renomear",
	"pick beans from list": "Escolher grãos",
	"pick supplier from list": "Escolher fornecedor",
	"no beans": "Por favor, adicione grãos antes!",
	"select coffee": "Por favor, selecione os grãos antes!",
	"last purchase/correction price info": "{$INTERPOLATION}{$INTERPOLATION_1} por {$INTERPOLATION_2} em {$INTERPOLATION_3}",
	"no last purchase": "Não há preços de compra anteriores disponíveis.",
	"Loading last purchase price": "Carregando a última compra ...",
	"tracking_number": "Numero de rastreio",
	"product_id": "ID do produto",
	"reference": "Referência",
	"Target store": "Estoque de destino",
	"Weight in {{mainUnit}}": "Peso em {$INTERPOLATION}",
	"order_number": "Número do pedido",
	"Amount": "Quantidade",
	"total cost": "Custo total",
	"Cost per": "Custo por",
	"add a new supplier": "Adicionar novo Fornecedor",
	"Supplier": "Fornecedor",
	"done": "Concluído",
	"unit system": "Sistema de unidades",
	"temperature system": "Sistema de temperatura",
	"currency": "Moeda",
	"wizard heading": "Vamos começar!",
	"your locations": "Seus estoques",
	"location explanation": "Como você chama o local onde armazena seus grãos verdes: por exemplo, Torrefação Principal, Estoque 1, Cozinha, ...",
	"new location": "Nova locação",
	"select location": "Selecionar local existente",
	"new location label": "Adicionar nova locação",
	"next step": "Próximo",
	"Your Beans": "Seus grãos",
	"coffee explanation": "Que tipo de grãos você tem em {$INTERPOLATION}?",
	"New beans": "Novos grãos",
	"select existing beans": "Selecionar grãos existentes",
	"coffee amount explanation": "Quanto {$INTERPOLATION_1} você tem em {$INTERPOLATION_1}?",
	"new supplier": "Novo fornecedor",
	"select supplier": "Selecionar fornecedor existente",
	"question for supplier": "De quem você recebeu (fornecedor)?",
	"wizard finished": "Ótimo! Qual o proximo?",
	"Clipboard": "Buffer",
	"Export": "Exportação",
	"button add more": "Adicione mais",
	"add sale": "Adicionar venda",
	"edit sale": "Editar venda",
	"pick customer from list": "Escolha o cliente",
	"sales_number": "Número da Venda",
	"total price": "Preço total",
	"Price per": "preço por",
	"add a new customer": "Adicionar novo Cliente",
	"Customer": "Cliente",
	"no stores": "Adicione um estoque antes!",
	"report": "relatório",
	"grams per liter": "g/l",
	"discarded": "descartado",
	"use blend template": "Use {$INTERPOLATION}",
	"Batch Prefix / Number / Position": "Prefixo / Número / Posição do Lote",
	"Prefix": "Prefixo",
	"Number": "Número",
	"Position": "Posição",
	"Energy": "Energia",
	"Machine": "Máquina",
	"Ground color": "Cor moído",
	"Whole color": "Cor em grãos",
	"Open in Artisan as background template (Artisan >2.4.4)": "Abrir como modelo de fundo (Artisan >2.4.4)",
	"Open in Artisan": "Abrir em Artisan",
	"CHARGE": "CARREGAR",
	"DROP": "DESCARREGAR",
	"DEV": "DEV",
	"Temperature": "Temperatura",
	"Pressure": "Pressão",
	"Humidity": "Umidade ambiente",
	"CM ET/BT": "CM ET/BT",
	"AUC": "ASC",
	"Template": "Modelo",
	"roast yield": "produção",
	"loss": "perda",
	"reconciled roast info": "Este item foi colocado em um relatório final e a maioria dos conteúdos não pode mais ser alterada.",
	"new blend name": "Novo nome da blend",
	"Yield in {{mainUnit}}": "Rendimento em {$INTERPOLATION}",
	"not paid": "EXPIRADO",
	"0 paid days left": "Último dia",
	"1 paid days left": "1 dia restante",
	"2 paid days left": "2 dias restantes",
	"3 paid days left": "3 dias restantes",
	"paid until, extend here": "Pago até {$INTERPOLATION} (estenda aqui)",
	"No changes possible": "Não são possíveis alterações",
	"greeting": "Oi",
	"Your account": "Sua conta",
	"logout": "SAIR",
	"for": "para",
	"register link": "CADASTRO",
	"visit our store": "Visite nossa loja para estender sua assinatura",
	"login link": "ENTRAR",
	"You can always visit our shop to extend your subscription": "Você pode sempre visitar nossa loja para estender sua assinatura",
	"paid until": "Pago até {$INTERPOLATION}",
	"your config": "Sua configuração",
	"Light mode": "Modo claro",
	"Auto": "Auto",
	"Dark mode": "Modo escuro",
	"dot seperator": "Formato: 12.34 kg (ponto)",
	"comma seperator": "Formato: 12,34 kg (vírgula)",
	"Sharing options": "Opções de compartilhamento",
	"Users with full access to this account:": "Usuários com acesso total a esta conta:",
	"admin user": "(Admin)",
	"pending user": "(pendente)",
	"resend invitation": "enviar convite novamente",
	"Add user to account": "Adicionar usuário à sua conta",
	"Viewers with read-only access to this account:": "Espectadores com acesso somente leitura a esta conta:",
	"You can see the accounts of:": "Você pode ver as contas de:",
	"Data Export Settings (CSV, Excel)": "Configurações de exportação de dados (CSV, Excel)",
	"Tab": "Aba",
	"Comma": "Vírgula",
	"Semicolon": "Ponto e vírgula",
	"Space": "Espaço",
	"Separated by": "Separado por",
	"Windows": "Windows",
	"Unix / macOS": "Unix / macOS",
	"Line separator": "Separador de linhas",
	"Decimal Separator": "Separador decimal",
	"Variant": "Variante",
	"Change your password": "Mude sua senha",
	"download data heading": "Baixe seus dados",
	"download all data": "Exporte todos os seus dados para um arquivo.",
	"delete data heading": "Exclua seus dados",
	"reset": "Redefinir sua conta: Este comando mantém sua conta, mas exclui todos os seus dados para sempre. Não pode ser desfeito.",
	"delete account": "Excluir sua conta: Este comando exclu todos os seus dados, incluindo sua conta. Não pode ser desfeito.",
	"resend verification email": "Enviar novamente Email de verificação",
	"email": "E mail",
	"password": "Senha",
	"reset_password": "Redefinir senha",
	"login button": "CONECTAR",
	"register_new_password": "Nova senha",
	"Success! You can now log in with your new password.": "Sucesso! Agora você pode fazer login com sua nova senha.",
	"you have been invited": "Você foi convidado para ingressar na conta de {$INTERPOLATION}",
	"you have been invited, readonly": "Você foi convidado para ver os dados da conta de {$INTERPOLATION}",
	"add account": "ADICIONAR CONTA",
	"nickname": "Apelido",
	"register button": "REGISTRO",
	"Partner Coupons": "Cupons de Parceiro",
	"accept privacy": "Eu aceito a Política de Privacidade:",
	"read here": "ler",
	"add transfer": "Adicionar transferência",
	"edit transfer": "Editar transferência",
	"from source store": "De",
	"no stores with beans": "Você não tem grãos em estoque.",
	"Amount of beans available": "Disponível:",
	"Please select a source store first!": "Selecione um estoque de origem primeiro!",
	"Graphs of your data are shown here as soon as there is enough data": "Os gráficos dos seus dados são mostrados aqui assim que houver dados suficientes.",
	"correction title": "Corrija seu estoque",
	"edit correction title": "Editar Correção",
	"bought beans": "Você comprou grãos",
	"add a purchase": "adicionar uma compra",
	"sold beans": "Você vendeu grãos",
	"add a sale": "adicionar uma venda",
	"moved beans": "Você transferiu grãos",
	"add a transfer": "adicionar uma transferência",
	"other reason": "Outra razão",
	"new amount": "Nova quantidade",
	"new weight in": "Novo peso em {$INTERPOLATION}",
	"delta amount": "Diferença de quantidade",
	"delta weight in": "Diferença de peso em {$INTERPOLATION}",
	"show delta amounts": "Digite a diferença de peso",
	"purchase": "Comprar",
	"sell": "Vender",
	"transfer": "Transferir",
	"correct": "Corrigir",
	"store with xkg of beans": "com {$INTERPOLATION} de grãos",
	"Store": "Estoque",
	"Sum": "Soma",
	"Set stock to 0": "Definir estoque para 0",
	"page not found text": "Estamos trabalhando duro para adicionar o conteúdo que você deseja aqui!",
	"Update available": "Atualização disponível",
	"Label changelog page": "O que há de novo?",
	"UPDATE": "ATUALIZAR",
	"last changed": "Última alteração {$INTERPOLATION}",
	"updated by": "por {$INTERPOLATION}",
	"Added {{date}}": "Adicionado {$INTERPOLATION}",
	"created by": "por {$INTERPOLATION}",
	"edit": "Editar",
	"close edit mode": "Fechar",
	"Year": "Ano",
	"Notes": "Anotações",
	"clone": "Criar uma cópia",
	"Import Beans Data": "Dados de importação de feijão",
	"Name": "Nome",
	"Month": "Mês",
	"street": "Rua",
	"Address": "Endereço",
	"Phone": "Telefone",
	"Coupon": "Cupom",
	"Request code": "Obtenha o código",
	"Details": "Detalhes",
	"GET a COUPON": "Pegue um CUPOM",
	"Supplier Partners": "Parceiros Fornecedores",
	"Do you want to add the following beans?": "Você deseja adicionar os seguintes grãos?",
	"last seen at supplier on date": "Visto pela última vez em {$INTERPOLATION} em {$INTERPOLATION_1}",
	"beans already exist will merge attributes": "Esses grãos já existem na sua conta. Novos atributos serão mesclados.",
	"Postal Code": "CEP",
	"city": "Cidade",
	"region": "Região",
	"subregion": "Sub-região",
	"Country": "País",
	"code": "Código",
	"organizational_form": "Forma organizacional",
	"farmowner": "Proprietário da Fazenda",
	"Web": "Site",
	"loading fields": "Carregando campos ...",
	"add a new field": "Adicionar novo campo",
	"producer": "Produtor",
	"loading origins": "Carregando origens ...",
	"Origin differs from ICO origin": "Diferente da origem da OIC!",
	"certifications": "Certificações",
	"loading producers": "Carregando produtores ...",
	"loading suppliers": "Carregando fornecedores ...",
	"add a new producer": "Adicionar novo produtor",
	"ICO": "OIC",
	"ICO details": "OIC Origin - Exportador - Lote",
	"crop_date_picked": "Escolhido",
	"crop_date_landed": "Desembarcou",
	"grade": "Grau",
	"altitude": "Altitude",
	"altitude_minmax": "Altitude min / máx",
	"screen_size": "Peneira",
	"screen_size_minmax": "Peneira min / máx",
	"score": "Ponto",
	"Species": "Espécies",
	"Varietals": "Variedades",
	"cultivation": "Cultivo",
	"harvesting": "Colheita",
	"Processing": "Processamento",
	"fermentation": "Fermentação",
	"drying": "Secagem",
	"decaffeination": "Descafeinação",
	"ageing": "Envelhecimento",
	"packaging": "Embalagem",
	"Selection": "Seleção",
	"Density": "Densidade",
	"Moisture": "Umidade dos grãos verdes",
	"water_activity": "Atividade aquática",
	"defects": "Defeitos",
	"flavors": "Sabores",
	"crop_yield": "Rendimento da colheita",
	"lot": "Lote",
	"Origin": "Origem",
	"loading certifications": "Carregando certificações ...",
	"field": "Campo",
	"unit_specification": "Unidade de embalagem",
	"unit_specification_2": "1 {$INTERPOLATION} tem quantos {$INTERPOLATION_1}?",
	"add initial stock": "Adicionar estoque inicial:",
	"Last modified": "Última modificação",
	"Stock": "Estoque",
	"incl. replacement ingredients": "incl. ingredientes de substituição",
	"ID": "ID",
	"all": "todos",
	"nothing to show plus hint, add with button": "Atualmente, não há nada para mostrar. Adicione usando o botão na parte superior!",
	"Filter": "Filtro",
	"Show roasts from": "Mostrar torras de",
	"Loading roasts": "Carregando torras ...",
	"sort order user": "Usuário",
	"Repeat every": "Repita cada",
	"Repeat on": "Repita nas",
	"Ends": "Termina",
	"no match for filter": "Nenhuma equivalência encontrada",
	"roast date": "Data da Torra",
	"batch number": "Número do lote",
	"Blends": "Blends",
	"Change in all blend templates?": "Mudança em todos os modelos de mesclagem?",
	"Total": "Total",
	"Reason": "Razão",
	"Yield": "Produção",
	"Loss": "Perda",
	"References": "Referências",
	"Beans used in blends, header": "Feijão em misturas",
	"Date": "Data",
	"roasts": "Torra",
	"creating PDF": "Criando PDF ...",
	"Do not show this again": "Non mostrare più",
	"Can be edited in": "Pode ser editado em",
	"update PDF": "Atualizar PDF gerado",
	"download PDF": "PDF",
	"not included explanation": "Itens com datas que pertencem a este relatório, mas não estão incluídas:",
	"added or modified after the report has been created": "adicionado ou modificado após a criação do relatório",
	"finalize": "Finalizar",
	"deleted": "excluído",
	"You have no hidden items.": "Você não tem entradas escondidos.",
	"Click here to find all hidden entries": "Clique aqui para encontrar todas as entradas escondidos",
	"Show only items with organic certification": "Clique aqui para ver apenas itens com certificação orgânica",
	"Show only beans that are not hidden": "Clique aqui para ver apenas os grãos que não estão escondidos",
	"add a new place": "Adicionar novo local",
	"place": "Local",
	"close edit place mode": "Fechar",
	"Beans menu label": "Grãos",
	"Blends menu label": "Blends",
	"Roasts menu label": "Torras",
	"Contacts menu label": "Contatos",
	"Reports menu label": "Relatórios",
	"legal notice": "Notícia legal",
	"privacy statement": "Política de Privacidade",
	"Shop": "Comprar",
	"add user to account": "Adicionar usuário à sua conta",
	"invite for read-only access": "Convidar para acesso somente leitura",
	"User with email already invited": "Usuário com e-mail {$INTERPOLATION} já convidado",
	"invalid email": "e-mail inválido",
	"get full access": "A pessoa terá acesso total aos seus dados.",
	"get read-only access": "A pessoa pode ler todos os seus dados.",
	"register_password_repeat": "Repita a senha",
	"update password": "ATUALIZAR SENHA",
	"inventory management": "gestão de inventário",
	"How can artisan.plus support you?": "Como o artisan.plus pode ajudar você?",
	"every roast automatically reduces your stock": "cada assado atualiza automaticamente seu inventário",
	"simplifies planning": "simplifica o planejamento e a execução de seus assados",
	"roast even without an internet connection": "funciona com e sem conexão com a internet",
	"keeps your roast profiles private": "mantém seus perfis de assados privados",
	"includes unlimited users and machines": "inclui usuários e máquinas ilimitados",
	"blends and certifications": "mantém misturas e rastreia certificações",
	"charts and tax reports": "oferece gráficos, relatórios fiscais e previsões",
	"imports coffee descriptions": "suporta importação de dados de bean de importadores",
	"3 months free testing": "3 meses grátis - obtenha todos os recursos a partir de 42€ / mês",
	"includes unlimited warehouses and bean quantities": "inclui estoques e quantidades de grãos ilimitados",
	"description 1": "Quanto tempo meu estoque irá durar?",
	"description 2": "Qual valor estimado do meu estoque?",
	"description 3": "Quais tipos de  grãos tenho no momento?",
	"description 4": "Como capturar meus perfis com eficiência ?",
	"description 5": "Como eu estou indo?",
	"description 6": "Como criar relatórios?",
	"Ratio": "Proporção",
	"Check out your {{newPartners}} new LINKSTART_supplier partners_LINKEND!": "Confira seus {$INTERPOLATION} novos LINKSTART_fornecedores parceiros_LINKEND!",
	"Check out your new LINKSTART_supplier partner_LINKEND!": "Verifique seu novo LINKSTART_fornecedor parceiro_LINKEND!",
	"No coupon available, please email info@artisan.plus": "Nenhum cupom disponível, envie um e-mail para info@artisan.plus",
	"Fill with": "Preencher com",
	"Replace by": "Substituir por",
	"Add/Change replacement ingredients": "Adicionar / alterar ingredientes de substituição",
	"Undo replace": "Desfazer substituição",
	"Loading beans": "Carregando grãos ...",
	"start date": "De",
	"end date": "Para",
	"allow empty weights": "Permitir pesos vazios",
	"invalid roasts not included": "Os seguintes assados não serão incluídos no relatório, pois faltam informações importantes:",
	"Missing items": "Entradas ausentes",
	"roasts not in range are excluded": "Os seguintes assados não serão incluídos no relatório, pois a data do assado é anterior ao período selecionado:",
	"loading reports": "Carregando relatórios ...",
	"no reports": "Ainda não há relatórios disponíveis.",
	"You can freely choose your report intervals here.": "Aqui você pode criar relatórios de torrefação para qualquer momento.",
	"Reports created here must be consecutive (no overlaps or gaps). They can be finalized for official purposes.": "Os relatórios criados aqui devem ser consecutivos (sem sobreposições ou lacunas). Eles podem ser tornados imutáveis para fins oficiais.",
	"Soon you will see an overview of your beans here; and more, stay tuned!": "Em breve você verá uma visão geral de seus grãos aqui; e mais, fique atento!",
	"invalid transactions not included": "As seguintes transações não serão incluídas no relatório, pois faltam informações importantes:",
	"Show zero amounts": "Mostrar pesos zero",
	"Per bean": "Por feijão",
	"per": "por",
	"Per store": "Por loja",
	"Reports Settings": "Configurações de relatórios"
}