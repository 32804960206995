{
	"not paid": "SCADUTO",
	"visit our store": "Visita il nostro online-shop per prolungare l'abbonamento",
	"0 paid days left": "0 giorni residui",
	"1 paid days left": "Ultimo giorno",
	"2 paid days left": "Ultimi 2 giorni",
	"3 paid days left": "Ultimi 3 giorni",
	"No changes possible": "Nessuna modifica possibile",
	"for": "per",
	"greeting": "Ciao",
	"Your account": "Il tuo account",
	"logout": "ESCI",
	"register link": "ISCRIVITI",
	"login link": "ACCEDI",
	"You can always visit our shop to extend your subscription": "Visita il nostro online shop per prolungare la tua iscrizione",
	"over limit": "OLTRE IL LIMITE",
	"Reminders menu label": "Promemoria",
	"active": "attivo",
	"Rename machines": "Rinomina macchine",
	"{{mainUnit}} roasted": "{$INTERPOLATION} tostato",
	"hours of roasting": "ore di tostatura",
	"minutes of roasting": "minuti di tostatura",
	"min": "min",
	"time": "tempo",
	"h": "h",
	"hour of roasting": "ora di tostatura",
	"weeks": "settimane",
	"years": "anni",
	"day of the week": "giorno della settimana",
	"day of the month": "giorno del mese",
	"propane consumed": " gas propano consumato",
	"On": "Il",
	"After": "Dopo",
	"Repetition": "Ripetizione",
	"Repetitions": "Ripetizioni",
	"Maximum number of repetitions restricted by": "Numero massimo di ripetizioni limitato da",
	"repetitions possible with the current stock": "ripetizioni possibili con lo stock attuale",
	"error retrieving all reminders": "errore nel recupero di tutti i promemoria",
	"daily": "quotidiano",
	"yearly": "annuale",
	"Condition": "Condizione",
	"starting from a date": "da",
	"Day": "Giorno",
	"Week": "Settimana",
	"Completed Tasks": "Attività completate",
	"Here you can see, and manually document, completed actions such as the routine cleaning of machines.": "Qui puoi vedere e documentare manualmente le azioni completate come la pulizia regolare delle macchine.",
	"Reminder": "Promemoria",
	"Here you can let the system remind you of recurring tasks such as 'Clean thermocouple every week'.": "Qui puoi lasciare che il sistema ti ricordi attività ricorrenti come &quot;Pulisci la termocoppia ogni settimana&quot;.",
	"Every /n/ {{PLACEHOLDER}}": "Ogni /n/ {$INTERPOLATION}",
	"Every /WEEKDAY/": "Ogni /WEEKDAY/",
	"Every /nthDAYOFMONTH/ day of the month": "Ogni /nthDAYOFMONTH/ giorno del mese",
	"(every n>=2) days": "giorni",
	"(every n>=2) weeks": "settimane",
	"(every n>=2) months": "mesi",
	"(every n>=2) years": "anni",
	"Propane bottle": "Bombola di propano",
	"Once {{kg}} have been roasted": "Una volta che {$INTERPOLATION} sono stati tostati",
	"Clean fans": "Ventole pulite",
	"Clean cooling tray": "Pulire il vassoio di raffreddamento",
	"Submit tax report": "Inviare la dichiarazione dei redditi",
	"Pay tax bill": "Pagare le tasse",
	"After each roast hour": "Dopo ogni ora di tostatura",
	"expired since {{kg}}": "scaduto da {$INTERPOLATION}",
	"expired {{time}}": "scaduto {$INTERPOLATION}",
	"next {{time}}": "fra {$INTERPOLATION}",
	"Every {{interval}} {{intervalUnit}}": "Ogni {$INTERPOLATION}{$INTERPOLATION_1}",
	"Every {{weekday}}": "Ogni {$INTERPOLATION}",
	"Every {{dayOfMonth}}": "Ogni {$INTERPOLATION}",
	"from {{date}}": "dal {$INTERPOLATION}",
	"After {{minutes}} roast minutes": "Dopo {$INTERPOLATION} minuti di tostatura",
	"After {{hours}} roast hours": "Dopo {$INTERPOLATION} ore di tostatura",
	"After {{n}} roasts": "Dopo {$INTERPOLATION} tostature",
	"{{interval}} to go": "ancora {$INTERPOLATION}",
	"Lubricate drum bearings": "Lubrificare i cuscinetti del tamburo",
	"Clean exhaust ducting": "Pulire il condotto di estrazione",
	"Lubricate drum drive chain": "Lubrificare la catena di trasmissione del tamburo",
	"Replace air filter": "Sostituire il filtro dell'aria",
	"Generate monthly roast report": "Genera rapporto mensile sulla tostatura",
	"Replace gas bottle": "Sostituire la bombola del gas",
	"Prepare organic audit": "Preparare un audit biologico",
	"Empty chaff container": "Svuotare il contenitore della pula",
	"Once {{kg}} {{gasUnit}} propane consumed ({{btu}} {{btuUnit}})": "Una volta consumati {$INTERPOLATION}{$INTERPOLATION_1} propano ({$INTERPOLATION_2}{$INTERPOLATION_3})",
	"Notify all": "Avvisare a tutti",
	"Browser": "Browser",
	"Notification": "Notifica",
	"Best add this email to your address book": "Pregasi aggiungere questa email alla rubrica",
	"OR": "O",
	"After each roast": "Dopo ogni tostatura",
	"average FIFO cost comes from": "La media di {$INTERPOLATION_1}/{$INTERPOLATION_2} deriva dal costo attuale di {$INTERPOLATION_4} per il tuo stock attuale di {$INTERPOLATION_5}{$INTERPOLATION_6} chicchi",
	"Corrections are not listed": "Le correzioni non sono elencate",
	"Maximum for display reached": "Massimo per la visualizzazione raggiunto",
	"plural_discarded": "scartati",
	"from": "da",
	"Attribute": "Attributo",
	"Load / Save ...": "Caricare / Salvare ...",
	"Click here to add more filters": "Aggiungere altri filtri",
	"No amount set": "Nessun peso iniziale inserito",
	"No yield set": "Nessun peso finale inserito",
	"No beans set": "Nessun chiccho inserito",
	"No blends": "Niente miscele",
	"Specific bean in blends": "Chiccho specifico in miscele",
	"missing value": "valore mancante",
	"any value": "qualsiasi valore",
	"any": "qualunque",
	"or": "o",
	"and": "e",
	"not": "non",
	"Summary": "Riepilogo",
	"List of roasts": "Elenco degli tostature",
	"BT": "BT",
	"FC": "PC",
	"Only one value found": "Solo un valore trovato",
	"Share beans info": "Condividi informazioni sui fagioli",
	"Copy to clipboard": "Copia negli appunti",
	"See what will be shared": "Scopri cosa verrà condiviso",
	"Scheduler menu label": "Programma",
	"5848348023107769657": "Peso",
	"Title": "Titolo",
	"This is a system wide change. All existing roasts, templates, scheduled items etc. will be affected. Cannot be undone.": "Si tratta di un cambiamento a livello di sistema. Verranno interessati tutti gli arrosti, i modelli, gli elementi programmati ecc. esistenti. Non può essere non fatto.",
	"Caution! Chosen machine name already exists, you will not be able to separate roasts by machine after the change.": "Attenzione! Il nome della macchina scelta esiste già, non sarà possibile separare gli arrosti per macchina dopo la modifica.",
	"Cupping": "Cupping",
	"Always available as template": " Sempre disponibile come modello ",
	"Roasting Notes": "Note sulla tostatura",
	"Cupping Notes": "Note sulla coppettazione",
	"Search beans": "Cerca fagioli",
	"Add as post blend": "Aggiungi come post-fusione",
	"Save": "Salva",
	"Missing": "Mancante",
	"From history": "Dalla storia",
	"Search templates": "Cerca modelli",
	"Add machines": "Aggiungi macchine",
	"Favorites": "Preferiti",
	"Reload - check for changes": "Ricarica: controlla le modifiche",
	"not specified": "non specificato",
	"Reduce displayed stock by planned amounts": "Ridurre le scorte visualizzate in base agli importi pianificati",
	"Search roast": "Cerca arrosto",
	"Batch": "Lotto",
	"Hauptzollamt Aachen": "Hauptzollamt Aquisgrana",
	"Hauptzollamt Augsburg": "Hauptzollamt Augusta",
	"Hauptzollamt Berlin": "Hauptzollamt di Berlino",
	"Hauptzollamt Bielefeld": "Hauptzollamt Bielefeld",
	"Hauptzollamt Braunschweig": "Hauptzollamt Braunschweig",
	"Hauptzollamt Bremen": "Hauptzollamt Brema",
	"Hauptzollamt Darmstadt": "Hauptzollamt Darmstadt",
	"Hauptzollamt Dortmund": "Hauptzollamt Dortmund",
	"Hauptzollamt Dresden": "Hauptzollamt Dresda",
	"Hauptzollamt Duisburg": "Hauptzollamt Duisburg",
	"Hauptzollamt Düsseldorf": "Hauptzollamt Düsseldorf",
	"Hauptzollamt Erfurt": "Hauptzollamt Erfurt",
	"Hauptzollamt Frankfurt (Oder)": "Hauptzollamt Francoforte sull&#39;Oder",
	"Hauptzollamt Frankfurt am Main": "Hauptzollamt Francoforte sul Meno",
	"Hauptzollamt Gießen": "Hauptzollamt Gießen",
	"Hauptzollamt Hamburg": "Hauptzollamt Amburgo",
	"Hauptzollamt Hannover": "Hauptzollamt Hannover",
	"Hauptzollamt Heilbronn": "Hauptzollamt Heilbronn",
	"Hauptzollamt Itzehoe": "Hauptzollamt Itzehoe",
	"Hauptzollamt Karlsruhe": "Hauptzollamt Karlsruhe",
	"Hauptzollamt Kiel": "Hauptzollamt Kiel",
	"Hauptzollamt Koblenz": "Hauptzollamt Coblenza",
	"Hauptzollamt Köln": "Hauptzollamt Colonia",
	"Hauptzollamt Krefeld": "Hauptzollamt Krefeld",
	"Hauptzollamt Landshut": "Hauptzollamt Landshut",
	"Hauptzollamt Lörrach": "Hauptzollamt Lörrach",
	"Hauptzollamt Magdeburg": "Hauptzollamt Magdeburgo",
	"Hauptzollamt München": "Hauptzollamt Monaco",
	"Hauptzollamt Münster": "Hauptzollamt Münster",
	"Hauptzollamt Nürnberg": "Hauptzollamt Norimberga",
	"Hauptzollamt Oldenburg": "Hauptzollamt Oldenburg",
	"Hauptzollamt Osnabrück": "Hauptzollamt Osnabrück",
	"Hauptzollamt Potsdam": "Hauptzollamt Potsdam",
	"Hauptzollamt Regensburg": "Hauptzollamt Ratisbona",
	"Hauptzollamt Rosenheim": "Hauptzollamt Rosenheim",
	"Hauptzollamt Saarbrücken": "Hauptzollamt Saarbrücken",
	"Hauptzollamt Schweinfurt": "Hauptzollamt Schweinfurt",
	"Hauptzollamt Singen": "Hauptzollamt Singen",
	"Hauptzollamt Stralsund": "Hauptzollamt Stralsund",
	"Hauptzollamt Stuttgart": "Hauptzollamt Stoccarda",
	"Hauptzollamt Ulm": "Hauptzollamt Ulm",
	"of": "Di",
	"Item cannot be updated, probably already synchronized with Artisan": "Impossibile aggiornare l&#39;articolo, probabilmente è già sincronizzato con Artisan",
	"Item cannot be updated, roasting has already started": "Impossibile aggiornare l&#39;articolo, la tostatura è già iniziata",
	"Please enter a value for {{missingField}}": "Inserisci un valore per {$INTERPOLATION}",
	"Some items could not be deleted": "Impossibile eliminare alcuni elementi",
	"error updating the schedule information": "errore durante l&#39;aggiornamento delle informazioni sulla pianificazione",
	"error updating the schedule": "errore durante l&#39;aggiornamento della pianificazione",
	"error retrieving the schedule": "errore durante il recupero della pianificazione",
	"Beans used in blends": "Chicchi utilizzati nelle miscele",
	"Batches": "Lotti",
	"Batch size": "Dimensione del lotto",
	"paid until, extend here": "Pagato fino a {$INTERPOLATION} (prolunga qui)",
	"Blends menu label": "Miscele",
	"Roasts menu label": "Tostature",
	"Beans menu label": "Chicchi",
	"Contacts menu label": "Contatti",
	"Reports menu label": "Rapporti",
	"legal notice": "Nota legale",
	"privacy statement": "Informativa sulla privacy",
	"Shop": "Shop",
	"paid until": "Pagato fino a {$INTERPOLATION}",
	"your config": "La tua configurazione",
	"Light mode": "Aspetto chiaro",
	"Auto": "Auto",
	"Dark mode": "Aspetto scuro",
	"dot seperator": "Formato: 12.34 kg (punto)",
	"comma seperator": "Formato: 12,34 kg (virgola)",
	"Data Export Settings (CSV, Excel)": "Impostazioni esporto dati (CSV, Excel)",
	"Tab": "tab",
	"Comma": "virgola",
	"Semicolon": "punto e virgola",
	"Space": "spazio",
	"Separated by": "Separati da",
	"Windows": "Windows",
	"Unix / macOS": "Unix / macOS",
	"Line separator": "Interruzione di riga",
	"Change your password": "Cambia la tua password",
	"download data heading": "Scarica i tuoi dati",
	"download all data": "Esporta tutti i tuoi dati in un file.",
	"delete data heading": "Elimina i tuoi dati",
	"reset": "Ripristina il tuo account: conserva il tuo account ma elimina tutti i tuoi dati per sempre. Non è reversibile.",
	"delete account": "Elimina il tuo account: elimina tutti i tuoi dati incluso il tuo account. Non è reversibile.",
	"Decimal Separator": "Separatore decimale",
	"Variant": "Variante",
	"Reports Settings": "Impostazioni rapporti",
	"Sharing options": "Opzioni di condivisione",
	"Users with full access to this account:": "Utenti con accesso completo a questo account:",
	"admin user": "(Admin)",
	"pending user": "(non accettato)",
	"resend invitation": "reinvia l'invito",
	"Viewers with read-only access to this account:": "Utenti con accesso in sola lettura a questo account:",
	"You can see the accounts of:": "Puoi vedere i conti di:",
	"Add user to account": "Aggiungi utente al tuo account",
	"unit system": "Sistema di unità",
	"currency": "Moneta",
	"temperature system": "Sistema di temperatura",
	"register button": "ISCRIVITI",
	"Partner Coupons": "Buoni Partner",
	"nickname": "Nickname",
	"accept privacy": "Accetto l'informativa sulla privacy:",
	"read here": "leggi",
	"login button": "ACCEDI",
	"resend verification email": "Invia nuovamente email di verifica",
	"email": "E-mail",
	"password": "Password",
	"reset_password": "Reimposta password",
	"register_new_password": "Nuova password",
	"Success! You can now log in with your new password.": "Successo! Ora puoi accedere con la tua nuova password.",
	"you have been invited": "Sei stato invitato ad utilizzare l'account di {$INTERPOLATION}",
	"you have been invited, readonly": "Sei stato invitato a vedere i dati di {$INTERPOLATION}",
	"add account": "AGGIUNGI ACCOUNT",
	"Amount": "Quantità",
	"total cost": "Costo totale",
	"done": "Finito",
	"Add new beans": "Aggiungi nuovi chicchi",
	"unit_specification": "Unità di imballaggio",
	"wizard heading": "Iniziamo!",
	"your locations": "Le tue sedi",
	"location explanation": "Come si chiama il luogo in cui si conservano i chicchi: ad es. Torrefazione Principale, Magazzino 1, Cucina, ...",
	"new location": "Nuova sede",
	"select location": "Seleziona la sede esistente",
	"new location label": "Aggiungi nuova sede",
	"next step": "Avanti",
	"Your Beans": "I tuoi chicchi",
	"coffee explanation": "Che tipi di chicchi hai nella sede {$INTERPOLATION}?",
	"New beans": "Chicchi nuovi",
	"select existing beans": "Seleziona i chicchi esistente",
	"coffee amount explanation": "Quanto {$INTERPOLATION} hai nella sede {$INTERPOLATION_1}?",
	"new supplier": "Nuovo fornitore",
	"select supplier": "Seleziona il fornitore esistente",
	"question for supplier": "Da chi l'hai ricevuto (fornitore)?",
	"wizard finished": "Bene! E ora?",
	"Clipboard": "Gli appunti",
	"Export": "Esportare",
	"button add more": "Aggiungi altro",
	"all": "tutti",
	"Last modified": "Ultima modifica",
	"Loading beans": "Caricamento chicchi ...",
	"nothing to show plus hint, add with button": "Attualmente, non ci sono dati. Inserisci dati usando il pulsante in alto!",
	"You have no hidden items.": "Non hai oggetti nascosti.",
	"Click here to find all hidden entries": "Clicca qui per trovare tutte gli oggetti nascosti",
	"Show only items with organic certification": "Mostra solo articoli con certificazione biologica",
	"Show only beans that are not hidden": "Clicca qui per vedere solo i chicchi che non sono nascosti",
	"edit": "Modifica",
	"Name": "Nome",
	"Supplier": "Fornitore",
	"producer": "Produttore",
	"code": "Codice",
	"organizational_form": "Forma organizzativa",
	"farmowner": "Proprietario",
	"Web": "Web",
	"loading fields": "Caricamento piantagioni ...",
	"Country": "Nazione",
	"street": "Via",
	"Postal Code": "Codice postale",
	"region": "Regione",
	"subregion": "Provincia",
	"Address": "Indirizzo",
	"Phone": "Telefono",
	"Coupon": "Coupon",
	"Request code": "Richiedi il codice",
	"Details": "Particolari",
	"GET a COUPON": "Ottenere un COUPON",
	"Supplier Partners": "Partner fornitori",
	"Do you want to add the following beans?": "Vuoi aggiungere i seguenti fagioli?",
	"last seen at supplier on date": "Visto l'ultima volta a {$INTERPOLATION} il {$INTERPOLATION_1}",
	"beans already exist will merge attributes": "Questi chicchi esistono già nel tuo account. I nuovi attributi verranno uniti.",
	"city": "Città",
	"add a new place": "Aggiungi una nuova località",
	"place": "Località",
	"close edit place mode": "Chiudi",
	"Customer": "Cliente",
	"Stock": "Scorta",
	"incl. replacement ingredients": "incl. ingredienti sostitutivi",
	"Notes": "Appunti",
	"Add/Change replacement ingredients": "Aggiungi / Cambia ingredienti sostitutivi",
	"Undo replace": "Annulla sostituzione",
	"Replace by": "Sostituito da",
	"ID": "ID",
	"order_number": "Numero d'ordine",
	"sales_number": "Numero di vendita",
	"Yield in {{mainUnit}}": "Rendimento in {$INTERPOLATION}",
	"Blends": "Miscele",
	"Change in all blend templates?": "Cambiare in tutti i modelli miscele?",
	"Roast": "Tostatura",
	"roast": "tostatura",
	"Beans": "Chicchi",
	"Blend": "Miscela",
	"Report": "Rapporto",
	"for price": "costo",
	"from source": "da",
	"on {{date}}": "il {$INTERPOLATION}",
	"cannot delete": "Impossibile eliminare!",
	"cannot delete because of references": "{$INTERPOLATION} non può essere eliminato perché in uso.",
	"close edit mode": "Chiudi",
	"Year": "Anno",
	"clone": "Crea una copia",
	"Import Beans Data": "Importa dati sui fagioli",
	"add a new field": "Aggiungi una nuova piantagione",
	"reference": "Il tuo riferimento",
	"loading certifications": "Caricamento delle certificazioni ...",
	"loading origins": "Caricamento origini ...",
	"lot": "lotto",
	"certifications": "Certificazioni",
	"loading producers": "Caricamento dei produttori ...",
	"loading suppliers": "Caricamento dei rifornitore ...",
	"add a new producer": "Aggiungi nuovo produttore",
	"ICO": "ICO",
	"ICO details": "ICO Origine - Esportatore - Lotto",
	"crop_date_picked": "Raccolto",
	"crop_date_landed": "Arrivo",
	"grade": "Classificazione",
	"altitude": "Altitudine",
	"altitude_minmax": "Altitudine min / max",
	"screen_size": "Crivelli",
	"screen_size_minmax": "Crivelli min / max",
	"score": "Valutazione",
	"cultivation": "Coltivazione",
	"harvesting": "Metodo di raccolta",
	"fermentation": "Fermentazione",
	"drying": "Essicazione",
	"decaffeination": "Decaffeinizzazione",
	"ageing": "Invecchiamento",
	"packaging": "Imballaggo",
	"Selection": "Selezione",
	"water_activity": "Water Activity",
	"defects": "Difetti",
	"flavors": "Sapori",
	"crop_yield": "Raccolto",
	"Origin": "Origine",
	"Origin differs from ICO origin": "Diverso dall'origine ICO!",
	"Species": "Specie",
	"Varietals": "Varietà",
	"Processing": "Preparazione",
	"field": "Campo",
	"add initial stock": "Aggiungi scorte iniziali:",
	"invalid transactions not included": "Le seguenti transazioni non saranno incluse nel rapporto poiché mancano informazioni importanti:",
	"report": "rapporto",
	"Weight in {{mainUnit}}": "Peso in {$INTERPOLATION}",
	"no stores": "Nessun magazzino disponibile!",
	"no beans": "Per favore aggiungi prima i chicchi!",
	"grams per liter": "g / l",
	"Density": "Densità",
	"new blend name": "Nuovo nome blend",
	"use blend template": "Utilizzare {$INTERPOLATION}",
	"Batch Prefix / Number / Position": "Prefisso batch / Numero / Posizione",
	"Prefix": "Prefisso",
	"Ground color": "Colore del macinato",
	"Whole color": "Colore di chicchi",
	"Open in Artisan as background template (Artisan >2.4.4)": "Apri come modello di sfondo (Artisan >2.4.4)",
	"Open in Artisan": "Apri in Artisan",
	"CHARGE": "CARICO",
	"DROP": "SCARICO",
	"DEV": "DEV",
	"Temperature": "Temperatura",
	"Pressure": "Pressione",
	"Humidity": "Umidità ambiente",
	"Template": "Modello",
	"CM ET/BT": "CM ET/BT",
	"AUC": "AUC",
	"roast yield": "rendimento",
	"loss": "perdita",
	"reconciled roast info": "Questa voce è stata inserita in un rapporto finale e la maggior parte dei contenuti non può più essere modificata.",
	"Number": "Numero",
	"Position": "Posizione",
	"Energy": "Energia",
	"Moisture": "Umidità crudo",
	"discarded": "scartato",
	"Store": "Magazzino",
	"Sum": "Somma",
	"Cost": "Costo",
	"Show zero amounts": "Mostra valore zero",
	"Per bean": "Per chicchi",
	"per": "per",
	"Per store": "Per magazzino",
	"loading reports": "Caricamento dei rapporti ...",
	"roasts": "tostature",
	"creating PDF": "Creazione PDF in corso ...",
	"Do not show this again": "Non mostrare più",
	"Can be edited in": "Può essere modificato nel",
	"update PDF": "Aggiorna PDF generato",
	"download PDF": "PDF",
	"finalize": "Finalizza",
	"Total": "Totale",
	"deleted": "eliminato",
	"Target store": "Magazzino di destinazione",
	"Reason": "Motivo",
	"References": "Riferimenti",
	"Beans used in blends, header": "Fagioli nelle miscele",
	"Date": "Data",
	"page not found text": "Stiamo lavorando per aggiungere il contenuto che desideri qui!",
	"Update available": "Aggiornamento disponibile",
	"Label changelog page": "Cosa c'è di nuovo?",
	"UPDATE": "AGGIORNARE",
	"created by": "da {$INTERPOLATION}",
	"last changed": "Ultima modifica",
	"updated by": "di {$INTERPOLATION}",
	"Added {{date}}": "Aggiunto",
	"Loading roasts": "Caricamento tostature ...",
	"sort order user": "Utente",
	"Repeat every": "Ripeti ogni",
	"Repeat on": "Ripetere il",
	"Ends": "Termina",
	"Filter": "Seleziona",
	"Show roasts from": "Mostra tostature da",
	"Machine": "Macchina",
	"roast date": "Data",
	"batch number": "Numero del batch",
	"no match for filter": "Nessun risultato trovato",
	"Filter ...": "Filtrare ...",
	"Find / Add ...": "Filtrare / Aggiungere ...",
	"Amount of beans available": "Disponibilità:",
	"Ratio": "Percentuale",
	"Check out your {{newPartners}} new LINKSTART_supplier partners_LINKEND!": "Dai un'occhiata ai tuoi {$INTERPOLATION} nuovi LINKSTART_fornitori partner_LINKEND!",
	"Check out your new LINKSTART_supplier partner_LINKEND!": "Dai un'occhiata al tuo nuovo LINKSTART_fornitore partner_LINKEND!",
	"No coupon available, please email info@artisan.plus": "Nessun coupon disponibile, inviare un'e-mail a info@artisan.plus",
	"Customs": "Dogana",
	"Roasts": "Tostature",
	"Sequential": "Ininterrotto",
	"Open": "Libero",
	"You can COPY or MOVE all your roast reports from the 'Sequential' section here:": "Puoi COPIARE o SPOSTARE tutti i tuoi rapporti della sezione \"Ininterrotto\" qui:",
	"Stocks": "Scorte",
	"Check Online Status": "Controlla lo stato online",
	"You can now switch accounts here": "Qui ora puoi cambiare l'account",
	"Correction": "Correzione",
	"Purchase": "Acquisto",
	"Sale": "Vendita",
	"Transfer": "Trasferimento",
	"Last correction": "Ultima correzione",
	"Last purchase": "Ultimo acquisto",
	"Last sale": "Ultima vendita",
	"Last transfer": "Ultimo trasferimento",
	"Purchases": "Acquisti",
	"Sales": "Vendite",
	"Transfers": "Trasferimenti",
	"Corrections": "Correzioni",
	"Metric": "Metrico",
	"Imperial": "Imperiale",
	"t": "t",
	"none": "nessuna",
	"Weight of a bag in kg?": "Peso di un sacco in kg?",
	"Weight of a barrel in kg?": "Peso di un barile in kg?",
	"Weight of a box in kg?": "Peso di una scatola in kg?",
	"Weight of a bag in lbs?": "Peso di un sacco in libbre?",
	"Weight of a barrel in lbs?": "Peso di un barile in libbre?",
	"Weight of a box in lbs?": "Peso di una scatola in libbre?",
	"yesterday": "ieri",
	"{{x}} days ago": "{$INTERPOLATION} giorni fa",
	"Sun-grown": "Sole",
	"Plantation": "Piantagione",
	"Garden": "Giardino",
	"Shade-grown": "Ombra",
	"Forest": "Foresta",
	"Wild Forest": "Foresta selvaggia",
	"Organic Solvent": "Solvente organico",
	"Natural Decaffeination Process EA": "Decaffeinizzazione naturale (acetato di etile)",
	"Methylene Chloride Process": "Processo di cloruro di metilene",
	"Triglyceride Process": "Processo dei trigliceridi",
	"Water Filtered": "Acqua filtrata",
	"Swiss Water Process": "Processo Swiss Water",
	"Mountain Water Process": "Processo Mountain Water",
	"Supercritical Fluid": "Fluido supercritico",
	"Carbon Dioxide Process": "Processo con anidride carbonica",
	"Others": "Altri",
	"decaffeinated": "decaffeinato",
	"European Preparation": "Preparazione europea",
	"American Preparation": "Preparazione americana",
	"Abu Dhabi": "Abu Dhabi",
	"Ajman": "Ajman",
	"Azores and Madeira": "Azzorre e Madera",
	"Bonaire": "Bonaire",
	"Caroline Islands": "Isole Caroline",
	"Ceuta": "Ceuta",
	"Cocos Islands": "Isole Cocos",
	"Dubai": "Dubai",
	"E.C. unspecified": "CE non specificato",
	"Faeroe Islands": "Isole Fr Øer",
	"Falkland Islands": "Isole Falkland",
	"Fujairah": "Fujaira",
	"Gaza Strip": "Striscia di Gaza",
	"Holy See": "Santa Sede",
	"Leeward Islands": "Isole Sottovento",
	"Libyan Arab Jamahiriya": "Giamahiria Araba Libica",
	"Macau": "Macau",
	"Melilla": "Melilla",
	"Netherlands Antilles": "Antille Olandesi",
	"Northern Marianas": "Marianne settentrionali",
	"Ras al Khaimah": "Ras Al Khaimah",
	"Reunion": "Riunione",
	"Sabah": "Sabah",
	"Sao Tome and Principe": "Sao Tomé e Principe",
	"Sarawak": "Sarawak",
	"Sharjah": "Sharjah",
	"Svalbard and Jan Mayen Islands": "Isole Svalbard e Jan Mayen",
	"Swaziland": "Swaziland",
	"Tahiti": "Tahiti",
	"Umm al Qaiwain": "Umm al Qaiwain",
	"Windward Islands": "Isole Sopravvento",
	"Galápagos": "Galápagos",
	"Africa": "Africa",
	"Caribbean": "Caraibico",
	"Central America": "America Centrale",
	"Europe": "Europa",
	"Canary Islands": "Isole Canarie",
	"South America": "America del Sud",
	"Asia": "Asia",
	"ANI": "ANI",
	"Bali": "Bali",
	"Borneo": "Borneo",
	"Flores": "Flores",
	"Java": "Giava",
	"Papua": "Papua",
	"St. Lucia": "Santa Lucia",
	"Sulawesi": "Sulawesi",
	"Sumatra": "Sumatra",
	"Sumbawa": "Sumbawa",
	"Oceania": "Oceania",
	"Hawaii": "Hawaii",
	"Color system": "Scala di colore",
	"Week {{week number}}, {{year}}": "Settimana {$INTERPOLATION}, {$INTERPOLATION_1}",
	"Quarter {{quarter number}}, {{year}}": "Trimestre {$INTERPOLATION}, {$INTERPOLATION_1}",
	"error adding report": "errore durante l'aggiunta del report",
	"report dates are overlapping with report {{reportLabel}}": "le date del rapporto si sovrappongono al rapporto {$INTERPOLATION}",
	"report with label {{label}} already exists": "rapporto con questo nome {$INTERPOLATION} già esistente",
	"report cannot be deleted: it is reconciled": "il rapporto non può essere eliminato: è già finalizzato",
	"no beans or blend": "niente chicchi o miscela",
	"no amount": "nessuna quantità",
	"no roast date": "nessuna data di tostatura",
	"weight or yield not positive": "Importo in entrata o in uscita non positivo",
	"Yield must be smaller than weight": "Rendimento deve essere inferiore a peso iniziale",
	"no roast location": "nessuna posizione tostatura",
	"before selected period": "prima del periodo selezionato",
	"unknown reason": "ragione sconosciuta",
	"Roast Report": "Rapporto Torrefazione",
	"Created by artisan.plus": "Creato da artisan.plus",
	"Company name": "Nome della ditta",
	"Company number": "Numero aziendale",
	"UNKNOWN": "SCONOSCIUTO",
	"NEW": "NUOVO",
	"Attention": "Attenzione",
	"Please add at least one type of beans first.": "Per favore prima aggiungi almeno un tipo di chicchi.",
	"A blend needs at least one type of beans.": "Una miscela ha bisogno di almeno un tipo di chicchi.",
	"Ratios must be positive and add up to 100%.": "I rapporti devono essere positivi e raggiungere il 100%.",
	"0% ratios are not allowed.": "I rapporti allo 0% non sono ammessi.",
	"Please select beans or a blend.": "Si prega di selezionare un tipo di chicchi o una miscela.",
	"Please enter an amount larger than 0.": "Si prega di inserire una quantità superiore a 0.",
	"Do you really want to delete {{name}}?": "Vuoi davvero eliminare {$INTERPOLATION}?",
	"Do you really want to delete all your data?!": "Vuoi veramente cancellare tutti i tuoi dati ?!",
	"Do you really want to delete your account and all your data?!": "Vuoi veramente eliminare il tuo account e tutti i tuoi dati ?!",
	"Do you really want to delete this {{transaction_type}}?": "Vuoi davvero eliminare questo {$INTERPOLATION}?",
	"Do you really want to finalize this report and all contained roasts?": "Vuoi davvero finalizzare il rapporto e tutte le tostature contenute?",
	"Do you really want to cancel the access to the account of {{nickname}}?": "Vuoi veramente annullare l'accesso all'account di {$INTERPOLATION}?",
	"Set the stock of this coffee to 0 (consumed)?": "Impostare la quantità di questo caffè a 0 (finito)?",
	"consumed": "finito",
	"Email sent. You can also forward the following link yourself": "Email inviata. Puoi anche inoltrare tu stesso il seguente link",
	"Fahrenheit": "Fahrenheit",
	"Celsius": "Centigrado",
	"bag": "sacco",
	"plural_bag": "sacchi",
	"barrel": "barile",
	"plural_barrel": "botti",
	"box": "scatola",
	"plural_box": "scatole",
	"Stores": "Magazzini",
	"Producers": "Produttori",
	"Suppliers": "Rifornitore",
	"Customers": "Clienti",
	"Fields": "Campi",
	"External Stores": "Magazzini esterni",
	"social": "solidale",
	"organic": "biologico",
	"fair": "equo",
	"sustainable": "sostenibile",
	"bird friendly": "bird friendly",
	"shade grown": "shade grown",
	"high quality": "alta qualità",
	"Afghanistan": "Afghanistan",
	"Aland Islands": "Isole Aland",
	"Albania": "Albania",
	"Algeria": "Algeria",
	"American Samoa": "Samoa americane",
	"Andorra": "Andorra",
	"Angola": "Angola",
	"Anguilla": "Anguilla",
	"Antarctica": "Antartide",
	"Antigua and Barbuda": "Antigua e Barbuda",
	"Argentina": "Argentina",
	"Armenia": "Armenia",
	"Aruba": "Aruba",
	"Australia": "Australia",
	"Austria": "Austria",
	"Azerbaijan": "Azerbaijan",
	"Bahamas": "Bahamas",
	"Bahrain": "Bahrain",
	"Bangladesh": "Bangladesh",
	"Barbados": "Barbados",
	"Belarus": "Bielorussia",
	"Belgium": "Belgio",
	"Belize": "Belize",
	"Benin": "Benin",
	"Bermuda": "Bermuda",
	"Bhutan": "Bhutan",
	"Bolivia": "Bolivia",
	"Bonaire, Sint Eustatius and Saba": "Bonaire, Sint Eustatius e Saba",
	"Bosnia and Herzegovina": "Bosnia Erzegovina",
	"Botswana": "Botswana",
	"Bouvet Island": "Isola Bouvet",
	"Brazil": "Brasile",
	"British Indian Ocean Territory": "Territorio britannico dell'Oceano Indiano",
	"Brunei Darussalam": "Brunei Darussalam",
	"Bulgaria": "Bulgaria",
	"Burkina Faso": "Burkina Faso",
	"Burundi": "Burundi",
	"Cape Verde": "Capo Verde",
	"Cambodia": "Cambogia",
	"Cameroon": "Camerun",
	"Canada": "Canada",
	"Cayman Islands": "Isole Cayman",
	"Central African Republic": "Repubblica Centrafricana",
	"Chad": "Ciad",
	"Chile": "Cile",
	"China": "Cina",
	"Christmas Island": "Isola di Natale",
	"Cocos (Keeling) Islands": "Isole Cocos (Keeling)",
	"Colombia": "Colombia",
	"Comoros": "Comoros",
	"Congo, DR": "Congo, DR",
	"Congo, Republic": "Congo, Repubblica",
	"Cook Islands": "Isole Cook",
	"Costa Rica": "Costa Rica",
	"Croatia": "Croazia",
	"Cuba": "Cuba",
	"Curaçao": "Curaçao",
	"Cyprus": "Cipro",
	"Czech Republic": "Repubblica Ceca",
	"Denmark": "Danimarca",
	"Djibouti": "Gibuti",
	"Dominica": "Dominica",
	"Dominican Republic": "Repubblica Dominicana",
	"Ecuador": "Ecuador",
	"Egypt": "Egitto",
	"El Salvador": "El Salvador",
	"Equatorial Guinea": "Guinea Equatoriale",
	"Eritrea": "Eritrea",
	"Estonia": "Estonia",
	"Eswatini": "Swaziland",
	"Ethiopia": "Etiopia",
	"Falkland Islands [Malvinas]": "Isole Falkland [Malvinas]",
	"Faroe Islands": "Isole Faroe",
	"Fiji": "Fiji",
	"Finland": "Finlandia",
	"France": "Francia",
	"French Guiana": "Guiana francese",
	"French Polynesia": "Polinesia francese",
	"French Southern Territories": "Territori della Francia del sud",
	"Gabon": "Gabon",
	"Gambia": "Gambia",
	"Georgia": "Georgia",
	"Germany": "Germania",
	"Ghana": "Gana",
	"Gibraltar": "Gibilterra",
	"Greece": "Grecia",
	"Greenland": "Groenlandia",
	"Grenada": "Grenada",
	"Guadeloupe": "Guadalupa",
	"Guam": "Guam",
	"Guatemala": "Guatemala",
	"Guernsey": "Guernsey",
	"Guinea": "Guinea",
	"Guinea-Bissau": "Guinea-Bissau",
	"Guyana": "Guyana",
	"Haiti": "Haiti",
	"Heard Island and McDonald Islands": "Isole Heard e isole McDonald",
	"Honduras": "Honduras",
	"Hong Kong": "Hong Kong",
	"Hungary": "Ungheria",
	"Iceland": "Islanda",
	"India": "India",
	"Indonesia": "Indonesia",
	"Iran": "Iran",
	"Iraq": "Iraq",
	"Ireland": "Irlanda",
	"Isle of Man": "Isola di Man",
	"Israel": "Israele",
	"Italy": "Italia",
	"Ivory Coast": "Costa d'Avorio",
	"Jamaica": "Giamaica",
	"Japan": "Giappone",
	"Jersey": "Jersey",
	"Jordan": "Giordania",
	"Kazakhstan": "Kazakistan",
	"Kenya": "Kenia",
	"Kiribati": "Kiribati",
	"North Korea": "Corea del Nord",
	"South Korea": "Corea del Sud",
	"Kuwait": "Kuwait",
	"Kyrgyzstan": "Kirghizistan",
	"Laos": "Laos",
	"Latvia": "Lettonia",
	"Lebanon": "Libano",
	"Lesotho": "Lesoto",
	"Liberia": "Liberia",
	"Libya": "Libia",
	"Liechtenstein": "Liechtenstein",
	"Lithuania": "Lituania",
	"Luxembourg": "Lussemburgo",
	"Macao": "Macao",
	"North Macedonia": "Macedonia del Nord",
	"Madagascar": "Madagascar",
	"Malawi": "Malawi",
	"Malaysia": "Malaysia",
	"Maldives": "Maldive",
	"Mali": "Mali",
	"Malta": "Malta",
	"Marshall Islands": "Isole Marshall",
	"Martinique": "Martinique",
	"Mauritania": "Mauritania",
	"Mauritius": "Mauritius",
	"Mayotte": "Mayotte",
	"Mexico": "Messico",
	"Micronesia": "Micronesia",
	"Moldova": "Moldavia",
	"Monaco": "Monaco",
	"Mongolia": "Mongolia",
	"Montenegro": "Montenegro",
	"Montserrat": "Montserrat",
	"Morocco": "Marocco",
	"Mozambique": "Mozambico",
	"Myanmar": "Birmania",
	"Namibia": "Namibia",
	"Nauru": "Nauru",
	"Nepal": "Nepal",
	"Netherlands": "Olanda",
	"New Caledonia": "Nuova Caledonia",
	"New Zealand": "Nuova Zelanda",
	"Nicaragua": "Nicaragua",
	"Niger": "Niger",
	"Nigeria": "Nigeria",
	"Niue": "Niue",
	"Norfolk Island": "Isola Norfolk",
	"Northern Mariana Islands": "Isole Marianne settentrionali",
	"Norway": "Norvegia",
	"Oman": "Oman",
	"Pakistan": "Pakistan",
	"Palau": "Palau",
	"Palestine": "Palestina",
	"Panama": "Panama",
	"PNG": "PNG",
	"Paraguay": "Paraguay",
	"Peru": "Perù",
	"Philippines": "Filippine",
	"Pitcairn": "Pitcairn",
	"Poland": "Polonia",
	"Portugal": "Portogallo",
	"Puerto Rico": "Porto Rico",
	"Qatar": "Qatar",
	"Réunion": "L'isola della Riunione",
	"Romania": "Romania",
	"Russian Federation": "Federazione Russa",
	"Rwanda": "Ruanda",
	"Saint Barthélemy": "Saint Barthélemy",
	"St. Helena": "Sant'Elena",
	"Saint Kitts and Nevis": "Saint Kitts e Nevis",
	"Saint Lucia": "Santa Lucia",
	"Saint Martin (French part)": "Saint Martin (parte francese)",
	"Saint Pierre and Miquelon": "Saint Pierre e Miquelon",
	"St. Vincent": "San Vincenzo",
	"Samoa": "Samoa",
	"San Marino": "San Marino",
	"São Tomé": "São Tomé",
	"Saudi Arabia": "Arabia Saudita",
	"Senegal": "Senegal",
	"Serbia": "Serbia",
	"Seychelles": "Seychelles",
	"Sierra Leone": "Sierra Leone",
	"Singapore": "Singapore",
	"Sint Maarten (Dutch part)": "Sint Maarten (parte olandese)",
	"Slovakia": "Slovacchia",
	"Slovenia": "Slovenia",
	"Solomon Islands": "Isole Salomone",
	"Somalia": "Somalia",
	"South Africa": "Sud Africa",
	"South Georgia and the South Sandwich Islands": "Georgia del Sud e Isole Sandwich del Sud",
	"South Sudan": "Sudan del Sud",
	"Spain": "Spagna",
	"Sri Lanka": "Sri Lanka",
	"Sudan": "Sudan",
	"Suriname": "Suriname",
	"Svalbard and Jan Mayen": "Svalbard e Jan Mayen",
	"Sweden": "Svezia",
	"Switzerland": "Svizzera",
	"Syrian Arab Republic": "Repubblica Araba Siriana",
	"Taiwan": "Taiwan",
	"Tajikistan": "Tagikistan",
	"Tanzania": "Tanzania",
	"Thailand": "Tailandia",
	"Timor, East": "Timor, est",
	"Timor": "Timor",
	"Togo": "Togo",
	"Tokelau": "Tokelau",
	"Tonga": "Tonga",
	"Trinidad and Tobago": "Trinidad e Tobago",
	"Tunisia": "Tunisia",
	"Turkey": "Turchia",
	"Turkmenistan": "Turkmenistan",
	"Turks and Caicos Islands": "Isole Turks e Caicos",
	"Tuvalu": "Tuvalu",
	"Uganda": "Uganda",
	"Ukraine": "Ucraina",
	"United Arab Emirates": "Emirati Arabi Uniti",
	"UK": "Gran Bretagna e Irlanda del Nord",
	"United States Minor Outlying Islands": "Isole minori secondarie degli Stati Uniti",
	"USA": "USA",
	"Uruguay": "Uruguay",
	"Uzbekistan": "Uzbekistan",
	"Vanuatu": "Vanuatu",
	"Venezuela": "Venezuela",
	"Vietnam": "Vietnam",
	"Virgin Islands (British)": "Isole Vergini (britanniche)",
	"Virgin Islands (U.S.)": "Isole Vergini (USA)",
	"Wallis and Futuna": "Wallis e Futuna",
	"Western Sahara": "Sahara occidentale",
	"Yemen": "Yemen",
	"Zambia": "Zambia",
	"Zimbabwe": "Zimbabwe",
	"Single farm": "Azienda agricola singola",
	"Cooperation": "Cooperativa",
	"could not get report: {{error}}": "impossibile ottenere il rapporto: {$INTERPOLATION}",
	"could not save PDF file: {{error}}": "impossibile salvare il file PDF: {$INTERPOLATION}",
	"could not save the report: {{error}}": "impossibile salvare il rapporto: {$INTERPOLATION}",
	"could not delete the report: {{error}}": "impossibile eliminare il rapporto: {$INTERPOLATION}",
	"could not update the report: {{error}}": "impossibile aggiornare il rapporto: {$INTERPOLATION}",
	"report not found": "rapporto non trovato",
	"report already reconciled": "rapporto già finalizzato",
	"could not reconcile the report: {{error}}": "impossibile finalizzare il rapporto: {$INTERPOLATION}",
	"could not find the report with id {{report_id}}": "impossibile trovare il rapporto con ID {$INTERPOLATION}",
	"error creating PDF": "errore durante la creazione del PDF",
	"error creating CSV": "errore durante la creazione di CSV",
	"error creating Sheet": "errore durante la creazione del foglio",
	"error creating XML": "errore durante la creazione di XML",
	"Type": "Tipo",
	"Items per page": "Oggetti per pagina",
	"Next page": "Pagina successiva",
	"Previous page": "Pagina precedente",
	"0 of {{length}}": "0 di {$INTERPOLATION}",
	"{{startIndex}} - {{endIndex}} of {{length}}": "{$INTERPOLATION} - {$INTERPOLATION_1} di {$INTERPOLATION_2}",
	"First page": "Prima pagina",
	"Last page": "Ultima pagina",
	"Months": "Mesi",
	"Regions": "Regioni",
	"Accumulated Production": "Produzione accumulata",
	"Yearly Production": "Produzione annuale",
	"Ambient": "Ambiente",
	"Machines": "Macchine",
	"Users": "Utenti",
	"User": "Utente",
	"Value": "Valore",
	"Certification": "Certificazione",
	"Water Activity": "Water Activity",
	"Origins": "Origini",
	"estimated yield": "rendimento stimato",
	"estimated amount": "quantità stimato",
	"month": "mese",
	"batches": "tostature",
	"batch": "tostatura",
	"one batch": "una tostatura",
	"days": "giorni",
	"{{count}} on one day": "{$INTERPOLATION} al giorno",
	"{{count}} on {{days}} days": "{$INTERPOLATION} in {$INTERPOLATION_1} giorni",
	"still ~{{month_left}} months": "ancora ~{$INTERPOLATION} mesi",
	"still ~{{weeks_left}} weeks": "ancora ~{$INTERPOLATION} settimane",
	"still about one week": "per circa una settimana",
	"still about one month": "per circa un altro mese",
	"more than": "più di",
	"weekly": "settimanale",
	"monthly": "mensile",
	"Monthly Production": "Produzione mensile",
	"This year": "Quest'anno",
	"Last year": "L'anno scorso",
	"{{nrCounts}} roasts": "{$INTERPOLATION} tostature",
	"one roast": "una tostatura",
	"one lot": "un tipo di chicchi",
	"{{nrCoffees}} lots": "{$INTERPOLATION} tipi di chicchi",
	"1 month": "1 mese",
	"{{nrMonth}} months": "{$INTERPOLATION} mesi",
	"1 year": "1 anno",
	"{{nrYears}} years": "{$INTERPOLATION} anni",
	"Age": "Età",
	"today": "Oggi",
	"stored": "immagazzinato",
	"with replacements": "con sostitutivi",
	"roasted": "tostato",
	"estimated age": "età stimata",
	"age": "età",
	"yield": "rendimento",
	"expected total age": "età complessiva prevista",
	"expected reach": "fine scorta prevista",
	"min reach": "fine scorta minima",
	"max reach": "fine scorta massima",
	"harvested": "raccolto",
	"amount": "quantità",
	"Please click on the link in the email we sent to {{email}}": "Fai clic sul link nell'email che abbiamo inviato a {$INTERPOLATION}",
	"Your login expired, please login again.": "Il tuo login è scaduto, effettua nuovamente il login.",
	"Successfully removed": "Rimozione avvenuta",
	"Successfully added": "Aggiunta avvenuta",
	"Successfully updated": "Aggiornamento avvenuto",
	"Successfully finalized": "Finalizzazione avvenuta",
	"Finalized": "Finalizzato",
	"Are you connected to the Internet?": "Sei connesso a Internet?",
	"Successfully sent email to {{target_email}}": "Email inviata correttamente a {$INTERPOLATION}",
	"sum of ratios must be 100%, not {{sum}}": "la somma dei rapporti deve essere 100%, non {$INTERPOLATION}",
	"Nothing to change": "Niente da cambiare",
	"Ignored since amount was zero": "Ignorato poiché l'importo era zero",
	"Http failure response for https": "Risposta di errore Http per https",
	"Not a valid file": "File non valido",
	"Could not set new image": "Impossibile impostare una nuova immagine",
	"Error loading image. Please choose a new one!": "Errore durante il caricamento dell'immagine. Per favore scegline una nuova!",
	"Could not update documents": "Non è stato possibile aggiornare i documenti",
	"Your browser sent too many requests too quickly. Please slow down.": "Il tuo browser ha inviato troppe richieste troppo velocemente. Per favore rallenta.",
	"beans with label {{label}} already exist": "chicchi con etichetta {$INTERPOLATION} esistono già",
	"Invalid date": "Data non valida",
	"{{imageName}} is not a valid image": "{$INTERPOLATION} non è un'immagine valida",
	"password must not be empty": "la password non deve essere vuota",
	"repeated password does not match": "la password ripetuta non corrisponde",
	"could not update the password": "non è stato possibile aggiornare la password",
	"could not delete the data": "non è stato possibile eliminare i dati",
	"Invitation failed. Did you use the invitation link?": "Invito non riuscito. Hai usato il link di invito?",
	"You will LOSE ACCESS to all of your OWN existing data - you have {{nrExistingObjects}} objects": "Perderai l'accesso a TUTTI i tuoi dati esistenti: avrai {$INTERPOLATION} oggetti",
	"If you do not want this, cancel and ask the person who invited you to use another of your email addresses": "Se non vuoi, annulla e chiedi alla persona che ti ha invitato di utilizzare un altro dei tuoi indirizzi email",
	"Update available, please do a browser refresh (CMD-SHIFT-R or CTRL-SHIFT-F5)": "Update: Aggiorna la pagina con un aggiornamento del browser (CMD-SHIFT-R o CTRL-SHIFT-F5)",
	"location not found": "sede non trovata",
	"could not find beans": "chicchi non trovati",
	"roast not found": "tostatura non trovata",
	"store not found": "magazzino non trovato",
	"purchase not found": "acquisto non trovato",
	"transfer not found": "trasferimento non trovato",
	"sale not found": "vendita non trovata",
	"correction not found": "correzione non trovata",
	"erroneous altitude range": "altitudine errata",
	"erroneous screen size range": "intervallo di crivello errato",
	"must specify target of transfer": "devi specificare la destinatione del trasferimento",
	"must specify location of change": "deve specificare la sede del cambiamento",
	"must specify the beans to be changed": "deve specificare il tipo di chicchi da cambiare",
	"roast must not have both beans and blend": "la tostatura non deve contenere chicchi e miscela",
	"invalid ID given for Location": "ID fornito per la sede non valido",
	"error retrieving a store": "errore nel recupero di un magazzino",
	"trying to get location": "ricerca della sede",
	"error retrieving stock of a store": "errore nel recupero delle scorte di un magazzino",
	"error retrieving all store stocks": "errore nel recupero di tutte le scorte dei magazzini",
	"error retrieving all purchases for the beans": "errore nel recupero di tutti gli acquisti di chicchi",
	"error adding the custom selection property": "Errore nell' aggiunta della proprietà di selezione personalizzata",
	"error adding the region": "Errore nell' aggiunta della regione",
	"error updating the additional report information": "errore durante l'aggiornamento delle informazioni aggiuntive sul rapporto",
	"could not identify the user": "non è stato possibile identificare l'utente",
	"could not find the user": "utente non trovato",
	"could not update the user account": "non è stato possibile aggiornare l'account utente",
	"could not update the user": "non è stato possibile aggiornare l'utente",
	"user is not associated with an account. Please send an email to admin@artisan.plus": "l'utente non è associato a un account. Si prega di inviare una email a admin@artisan.plus",
	"Your account is not activated. Please send an email to admin@artisan.plus": "Il tuo account non è attivato. Si prega di inviare una email a admin@artisan.plus",
	"missing or invalid start or end date": "data di inizio o fine mancante o non valida",
	"missing or invalid date": "data mancante o non valida",
	"no user information given": "nessuna informazione utente fornita",
	"no email given": "nessuna email fornita",
	"no password given": "nessuna password fornita",
	"no nickname given": "nessun nickname fornito",
	"user data could not be downloaded": "i dati dell'utente non possono essere scaricati",
	"could not send email": "non è stato possibile inviare email",
	"wrong password reset link": "link errato per la reimpostazione della password",
	"not allowed to change someone else\\'s password": "non è permesso cambiare la password di altri",
	"could not create account with customer_code {{ccode}}": "non è stato possibile creare un account con codice cliente {$INTERPOLATION}",
	"could not add user": "non è stato possibile aggiungere utente",
	"successfully uploaded data but could not set password": "dati caricati correttamente ma non è stato possibile impostare la password",
	"could not retrieve user from id {{userId}}": "impossibile trovare l'utente dall'ID {$INTERPOLATION}",
	"object with id {{id}} not found": "oggetto con ID {$INTERPOLATION} non trovato",
	"{{model}} with id {{_id}} not found": "{$INTERPOLATION} con ID {$INTERPOLATION_1} non trovato",
	"trying to get {{model}} by name {{label}}": "cercando di ottenere {$INTERPOLATION} per nome {$INTERPOLATION_1}",
	"could not add producer location {{label}}": "non è stato possibile aggiungere la sede del produttore {$INTERPOLATION}",
	"location with {{label and type info}} already exists": "la sede {$INTERPOLATION} esiste già",
	"could not add location {{label}}": "non è stato possibile aggiungere la sede {$INTERPOLATION}",
	"could not update location {{label}}": "non è stato possibile aggiornare la sede {$INTERPOLATION}",
	"could not update location of {{producerLabel}}": "non è stato possibile aggiornare la sede di {$INTERPOLATION}",
	"could not add supplier {{label}}": "non è stato possibile aggiungere il fornitore {$INTERPOLATION}",
	"could not add customer {{label}}": "non è stato possibile aggiungere il cliente {$INTERPOLATION}",
	"could not find blend with ID {{blend}}": "non è stato possibile trovare la miscela con l'ID {$INTERPOLATION}",
	"trying to get {{model}}": "cercando di ottenere {$INTERPOLATION}",
	"invalid ID given for {{property}}": "ID non valido fornito per {$INTERPOLATION}",
	"could not make related updates": "non è stato possibile effettuare aggiornamenti correlati",
	"stock of beans at location {{location}} could not be updated": "la disponibilità di chicchi nella sede {$INTERPOLATION} non può essere aggiornata",
	"value of beans at location {{location}} could not be calculated": "il valore dei chicchi nella sede {$INTERPOLATION} non può essere calcolato",
	"could not update property {{error}}": "non è stato possibile aggiornare la caratteristica {$INTERPOLATION}",
	"roast with roast_id {{roast_id}} not found": "tostatura numero {$INTERPOLATION} non trovata",
	"rejected deleting all {{model}}": "impossibile eliminare completamente {$INTERPOLATION}",
	"could not check if {{model}} exists in DB": "non è stato possibile verificare se {$INTERPOLATION} esiste nel DB",
	"logged in user not the same as user to be deleted": "l'utente che ha effettuato l'accesso non è lo stesso utente da eliminare",
	"logged in user not the same as user to be updated": "l'utente connesso non è uguale all'utente da aggiornare",
	"no data": "nessun dato",
	"could not delete location": "non è stato possibile cancellare la sede",
	"could not un-reconcile a transaction in the report": "non è stato possibile annullare la finalizzazione di una transazione nel rapporto",
	"undefined": "non definito",
	"error retrieving information": "errore durante il recupero di informazioni",
	"Failed updating blend templates": "Aggiornamento dei modelli di miscela non riuscito",
	"error updating the beans information": "errore durante l'aggiornamento delle informazioni sui chicchi",
	"error updating the roast information": "errore nell'aggiornamento delle informazioni di tostatura",
	"error updating the blend information": "errore durante l'aggiornamento delle informazioni sulla miscela",
	"error updating the store information": "errore durante l'aggiornamento delle informazioni del magazzino",
	"error updating the stock information": "errore nell'aggiornamento delle informazioni sulle scorte",
	"error updating the information": "errore durante l'aggiornamento delle informazioni",
	"error updating user information": "errore durante l'aggiornamento delle informazioni dell'utente",
	"error retrieving all stores": "errore nel recupero di tutti i maggazini",
	"error retrieving all locations": "errore nel recupero di tutte le sedi",
	"error retrieving all beans": "errore nel recupero di tutti i tipi di chicchi",
	"error retrieving all blends": "errore durante il recupero delle miscele",
	"error retrieving all customers": "errore nel recupero di tutti i clienti",
	"error retrieving reports": "errore nel recupero dei rapporti",
	"error retrieving graph data": "errore nel recupero dei dati del grafico",
	"error retrieving all roasts": "errore nel recupero di tutti le tostature",
	"error adding store": "errore nell'aggiunta del maggazino",
	"error adding purchase": "errore nell'aggiunta dell' acquisto",
	"error adding correction": "errore nell' aggiunta della correzione",
	"error adding transfer": "Errore durante l'aggiunta del trasferimento",
	"error adding sale": "errore nell'aggiunta della vendita",
	"error editing store": "errore di modifica del magazzino",
	"error editing purchase": "errore nella modifica dell'acquisto",
	"error editing correction": "errore nella modifica nella correzione",
	"error editing transfer": "errore nella modifica del trasferimento",
	"error editing sale": "errore nella modifica della vendita",
	"error deleting purchase": "errore durante l'eliminazione dell'acquisto",
	"error deleting correction": "errore durante l'eliminazione della correzione",
	"error deleting transfer": "errore durante l'eliminazione del trasferimento",
	"error deleting sale": "errore durante l'eliminazione della vendita",
	"no documents were uploaded": "nessun file è stato caricato",
	"server error": "Errore del server",
	"error": "errore",
	"User with email {{email}} not found": "Utente con email {$INTERPOLATION} non trovato",
	"Email not verified. Please check the email sent by admin@artisan.plus": "Email non verificata. Si prega di controllare l'e-mail inviata da admin@artisan.plus",
	"Your account does not have enough credit. Please recharge at {{link}}": "Il tuo account non ha abbastanza credito. Si prega di fare la ricarica presso {$INTERPOLATION}",
	"wrong email or password": "email o password errate",
	"wrong password given": "password errata",
	"User with email {{email}} already exists": "Utente con l'email {$INTERPOLATION} già esistente",
	"you are not allowed to access this data": "Non sei autorizzato ad accedere a questi dati",
	"user not found": "Utente non trovato",
	"not found": "non trovato",
	"producer with label {{label}} already exists": "il produttore {$INTERPOLATION} esiste già",
	"could not add producer {{label}}": "non è stato possibile aggiungere il produttore {$INTERPOLATION}",
	"could not update producer {{label}}": "non è stato possibile aggiornare il produttore {$INTERPOLATION}",
	"could not update supplier {{label}}": "non è stato possibile aggiornare il fornitore {$INTERPOLATION}",
	"could not update customer {{label}}": "non è stato possibile aggiornare il cliente {$INTERPOLATION}",
	"could not update roast {{label}}": "non è stato possibile aggiornare la tostatura {$INTERPOLATION}",
	"could not update coffee {{label}}": "non è stato possibile aggiornare i chicchi {$INTERPOLATION}",
	"location with label {{label}} already exists": "la sede {$INTERPOLATION} esiste già",
	"could not add beans {{label}}": "non è stato possibile aggiungere i chicchi {$INTERPOLATION}",
	"supplier with label {{label}} already exists": "il fornitore {$INTERPOLATION} esiste già",
	"blend with label {{label}} already exists": "la miscela con l'etichetta {$INTERPOLATION} esiste già",
	"customer with label {{label}} already exists": "il cliente {$INTERPOLATION} esiste già",
	"stock update failed": "aggiornamento delle scorte fallito",
	"could not save image file {{error}}": "impossibile salvare il file di immagine {$INTERPOLATION}",
	"wrong email verification link": "link di verifica email errato",
	"Email verify failed": "Verifica email non riuscita",
	"Hidden beans with this name + picked year + origin already exist, {{info}}": "Esistono già chicchi (nascosti) con queste nome + anno di raccolto + origine: {$INTERPOLATION}",
	"Beans with this name + picked year + origin already exist, {{info}}": "Esistono già chicchi con queste nome + anno di raccolto + origine: {$INTERPOLATION}",
	"this does not belong to current user": "questo non appartiene all'utente attuale",
	"cannot update since a related item is already finalized": "impossibile aggiornare poiché un elemento correlato è già finalizzato",
	"Could not reach server.": "Impossibile raggiungere il server.",
	"Please repeat the password reset process": "Ripeti la procedura di reimpostazione della password",
	"Could not send verification email": "Impossibile inviare l'email di verifica",
	"add user to account": "Aggiungi utente al tuo account",
	"invite for read-only access": "Invito per accesso in sola lettura",
	"get full access": "La persona avrà pieno accesso ai tuoi dati.",
	"get read-only access": "La persona sarà in grado di leggere tutti i tuoi dati.",
	"User with email already invited": "Utente con l'email {$INTERPOLATION} già invitato",
	"invalid email": "email non valido",
	"register_password_repeat": "Ripeti la password",
	"update password": "AGGIORNA PASSWORD",
	"add a new supplier": "Aggiungi nuovo fornitore",
	"edit purchase": "Modifica acquisto",
	"add purchase": "Aggiungi acquisto",
	"pick beans from list": "Scegli chicchi",
	"pick supplier from list": "Scegli fornitore",
	"last purchase/correction price info": "{$INTERPOLATION}{$INTERPOLATION_1} costo {$INTERPOLATION_2} il {$INTERPOLATION_3}",
	"no last purchase": "Nessun prezzo d'acquisto precedente disponibile.",
	"Loading last purchase price": "Caricamento ultimo acquisto ...",
	"add a new customer": "Aggiungi nuovo cliente",
	"rename": "Cambia nome",
	"select coffee": "Si prega di selezionare prima i chicchi!",
	"tracking_number": "Numero di tracciabilità",
	"product_id": "Codice prodotto",
	"total price": "Prezzo cumulativo",
	"Price per": "Prezzo per",
	"add sale": "Aggiungi vendita",
	"edit sale": "Modifica vendita",
	"pick customer from list": "Scegli cliente",
	"no stores with beans": "Non hai magazzini con chicchi.",
	"from source store": "Provenienza",
	"to customer": "a",
	"list header": "Numero di transazioni: {$INTERPOLATION}",
	"between dates": "fra il {$INTERPOLATION} e il {$INTERPOLATION_1}",
	"x% of a blend": "({$INTERPOLATION} di una miscela)",
	"of a blend": "di una miscela",
	"no purchases made": "Nessun acquisto effettuato. I costi sono calcolati solo in base agli acquisti. Puoi aggiungere un acquisto o sostituire una correzione con un acquisto.",
	"to target": "a",
	"amount header": "Quantità",
	"limit header": "Limite per avviso dal scorta magazzino",
	"correction title": "Correggi magazzino",
	"edit correction title": "Modifica correzione",
	"bought beans": "Hai comprato chicchi",
	"add a purchase": "aggiungi acquisto",
	"sold beans": "Hai venduto chicchi",
	"add a sale": "aggiungi vendita",
	"moved beans": "Hai trasferito chicchi",
	"add a transfer": "aggiungi trasferimento",
	"other reason": "Altro motivo",
	"new amount": "Nuova quantità",
	"new weight in": "Nuovo peso in {$INTERPOLATION}",
	"delta amount": "Differenza della quantità",
	"delta weight in": "Differenza di peso in {$INTERPOLATION}",
	"show delta amounts": "Oppure inserisci la differenza di peso",
	"purchase": "Acquista",
	"sell": "Vendi",
	"transfer": "Trasferisci",
	"correct": "Correggi",
	"store with xkg of beans": "con {$INTERPOLATION} di chicchi",
	"add transfer": "Aggiungi trasferimento",
	"edit transfer": "Modifica trasferimento",
	"Please select a source store first!": "Seleziona prima un magazzino di origine!",
	"Fill with": "Riempire con",
	"Month": "Mese",
	"Set stock to 0": "Imposta la quantità a 0",
	"added or modified after the report has been created": "aggiunto o modificato dopo la creazione del rapporto",
	"not included explanation": "Elemento con date che appartengono a questo rapporto ma non sono incluse:",
	"Weight": "Peso iniziale",
	"Yield": "Peso finale",
	"Loss": "Perdita",
	"no reports": "Non c'è ancora un rapporto disponibile.",
	"You can freely choose your report intervals here.": "Qui puoi scegliere liberamente gli intervalli dei rapporti.",
	"Reports created here must be consecutive (no overlaps or gaps). They can be finalized for official purposes.": "Qui i rapporti di tostatura devono susseguirsi senza lacune o sovrapposizioni. Possono essere resi immutabili per scopi ufficiali.",
	"Soon you will see an overview of your beans here; and more, stay tuned!": "Presto qui potrai vedere rapporti suoi tuoi chicci; e altro ancora!",
	"start date": "Da",
	"end date": "A",
	"allow empty weights": "Consenti pesi a vuoto",
	"invalid roasts not included": "Le seguenti tostature non verranno incluse nel rapporto perche mancano informazioni importanti:",
	"Missing items": "Oggetti mancanti",
	"roasts not in range are excluded": "Le seguenti tostature non saranno incluse nel rapporto perche la loro data di tostatura è precedente al periodo selezionato:",
	"are you sure": "Sei sicuro?",
	"Upload documents": "Carica documenti",
	"Graphs of your data are shown here as soon as there is enough data": "I grafici dei tuoi dati vengono mostrati qui se ci sono abbastanza dati.",
	"inventory management": "gestione magazzino",
	"How can artisan.plus support you?": "Come può aiutarti artisan.plus?",
	"every roast automatically reduces your stock": "ogni tostatura aggiorna automaticamente il tuo inventario",
	"simplifies planning": "semplifica la pianificazione e l'esecuzione delle tostature",
	"roast even without an internet connection": "funziona anche senza connessione internet",
	"keeps your roast profiles private": "mantiene privati i tuoi profili di tostatura",
	"includes unlimited users and machines": "include utenti e macchine illimitati",
	"blends and certifications": "gestisce le miscele e tiene traccia delle certificazioni",
	"charts and tax reports": "offre grafici, rapporti fiscali e previsioni",
	"imports coffee descriptions": "importa le descrizioni del caffè dei rivenditori",
	"3 months free testing": "3 mesi di test gratuito - 48€ / al mese (sconti per periodi più lunghi)",
	"includes unlimited warehouses and bean quantities": "include magazzini e quantità di chicchi illimitati",
	"description 1": "Quanto dureranno i miei chicchi?",
	"description 2": "Quanto vale la mia scorta?",
	"description 3": "Che tipo di chicchi ho?",
	"description 4": "Come documentare le mie tostature in modo efficiente?",
	"description 5": "Come va?",
	"description 6": "Come fare i rapporti?"
}