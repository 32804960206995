import { MatDialog } from '@angular/material/dialog';
import { AccountChangedService } from 'src/app/util/services/accountchanged.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, throttleTime, takeUntil } from 'rxjs/operators';
import { UserType } from 'src/app/modules/frame/services/user.service';
import { Router } from '@angular/router';
import { Renderer2 } from '@angular/core';
import { User } from 'src/app/models/User';
import { AlertService } from 'src/app/util/alert/alert.service';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';
import { Enumerations } from 'src/app/models/Enumerations';
import { DateTime } from 'luxon';
import { MatSidenav } from '@angular/material/sidenav';
// import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-ap-toolbar',
    templateUrl: './ap-toolbar.component.html',
    styleUrls: ['./ap-toolbar.component.scss']
})
export class ApToolbarComponent implements OnInit, OnDestroy {

    constructor(
        private breakpointObserver: BreakpointObserver,
        public utils: Utils,
        private userService: UserService,
        @Inject(LOCALE_ID) public locale: string,
        private router: Router,
        private dialog: MatDialog,
        private tr: TranslatorService,
        private alertService: AlertService,
        private accountChangedService: AccountChangedService,
    ) { }

    private _loggedInUser: UserType;
    get loggedInUser(): UserType { return this._loggedInUser; }
    @Input() set loggedInUser(liu: UserType) {
        this._loggedInUser = liu;
        if (this._loggedInUser) {
            if (this._loggedInUser.readonly_account_idx >= 0 && this._loggedInUser.other_accounts[this._loggedInUser.readonly_account_idx]) {
                this.readonlyAccountIdx = this._loggedInUser.readonly_account_idx;
                this.accountLabel = this._loggedInUser.other_accounts[this.readonlyAccountIdx].nickname;
            } else {
                this.readonlyAccountIdx = -1;
                if (this._loggedInUser.readonly_account_idx >= 0) {
                    delete this._loggedInUser.readonly_account_idx;
                    this.userService.storeCurrentUser(this._loggedInUser);
                }
                this.accountLabel = this.tr.anslate('Your account');
            }
            this.paidDaysLeft = this.utils.paidDaysLeft(this._loggedInUser.account?.paidUntil);
            this.updateReadOnly();
        }
    }
    @Input() drawer: MatSidenav;
    @Input() relative: boolean;
    @Input() opaque: boolean;
    @Input() returnUrl: string;
    paidDaysLeft: number;
    private _paidUntil: DateTime;
    get paidUntil(): DateTime { return this._paidUntil; }
    @Input() set paidUntil(pu: DateTime) {
        this._paidUntil = pu;
        this.loggedInUser = this.userService.getCurrentUser();
        this.paidDaysLeft = this.utils.paidDaysLeft(this._paidUntil);
        this.updateReadOnly();
    }
    showPaid = true;
    overLimit = false;
    signedOutLanguage = 'en';
    latestWebVersion: string;
    accountLabel = '';
    removing = false;
    isDarkmode = false;

    readonlyAccountIdx = -1;

    localeShop = '/';

    isSmall$: Observable<boolean>;
    isSmallish$: Observable<boolean>;
    accountSelectorWidth = '100px';

    private ngUnsubscribe = new Subject();
    window = window;
    Enumerations = Enumerations;
    DateTime = DateTime;

    renderer2: Renderer2;
    mouse: string;
    mouseListener: () => void;

    ngOnInit(): void {
        this.locale = this.locale ?? 'en';
        this.localeShop = this.locale === 'de/' ? this.locale : '/';

        this.isSmall$ = this.breakpointObserver.observe('(max-width: 599px)')
            .pipe(map(result => result.matches));
        this.isSmallish$ = this.breakpointObserver.observe('(max-width: 799px)')
            .pipe(map(result => result.matches));

        setTimeout(() => this.showPaid = false, 20000);
        this.latestWebVersion = environment.latestWebVersion;

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        if (this.loggedInUser) {
            if (!this.loggedInUser.language) {
                this.loggedInUser.language = this.locale;
                this.updateUserLanguage();
            }
            if (this.loggedInUser.readonly_account_idx >= 0) {
                this.readonlyAccountIdx = this.loggedInUser.readonly_account_idx;
            } else {
                this.readonlyAccountIdx = -1;
            }
        }
        if (!this.loggedInUser?.nickname) {
            // not logged in, get current language from URL
            this.signedOutLanguage = window.location.pathname.substring(1, 3);
            if (this.signedOutLanguage === 'gb') {
                this.signedOutLanguage = 'en-GB';
            }
        }

        this.accountLabel = this.readonlyAccountIdx >= 0 ? this.loggedInUser.other_accounts[this.readonlyAccountIdx].nickname : this.tr.anslate('Your account');
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    updateReadOnly(): void {
        if (this.loggedInUser) {
            this.overLimit = !!(this.loggedInUser.account?.limit?.rlimit && (this.loggedInUser.account.limit.rused > this.loggedInUser.account.limit.rlimit));
            this.loggedInUser.readonly = this.loggedInUser.readonly_account_idx >= 0 || this.paidDaysLeft < 0 || this.overLimit;
        }
        if (this.paidDaysLeft > 30) {
            this.showPaid = false;
        }
    }

    languageChanged(): void {
        if (this.loggedInUser) {
            this.updateUserLanguage();
        }
        if (this.signedOutLanguage || this.loggedInUser?.language) {
            let path = window.location.pathname?.substring(3);
            if (path?.substring(0, 1) === '/') {
                path = path.substring(1);
                if (path.substring(0, 5) === 'login') {
                    path = 'stores';
                }
            }
            this.router.navigate(['/externalRedirect', { lang: this.loggedInUser?.language ?? this.signedOutLanguage, path, query: window.location.search }], {
                skipLocationChange: true,
            });
        }
    }

    updateUserLanguage(): void {
        this.userService.storeCurrentUser(this.loggedInUser);
        const user = {} as User;
        user.locale = this.loggedInUser.language;
        this.userService.updateUser(user)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response?.success !== true) {
                        this.utils.handleError('error updating the user langugae', response?.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the user langugae', error);
                }
            });
    }

    accountChanged(): void {
        if (this.removing) {
            return;
        }

        // "readonly_account_idx":0, "other_accounts":[{"_id":"5bb5d95688baa90b8a5c7f9a", "nickname":"Marko","email":"marko@email.de"}],
        this.loggedInUser.readonly_account_idx = this.readonlyAccountIdx === -1 ? undefined : this.readonlyAccountIdx;
        this.updateReadOnly();
        this.userService.storeCurrentUser(this.loggedInUser);
        // reload current page
        this.router.navigate(['/externalRedirect', { lang: this.loggedInUser.language }], { skipLocationChange: true });
    }

    removeViewerFromMyAccount(acc: UserType['other_accounts'][0]): void {
        if (this.removing) {
            return;
        }

        this.removing = true;
        const dialogRef = this.dialog.open(YesNoDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to cancel the access to the account of {{nickname}}?', { nickname: acc.nickname }) }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.userService.removeViewerFromMyAccount(acc._id)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (!response || response.success === true) {
                                // remove user locally
                                for (let i = 0; i < this.loggedInUser.other_accounts.length; i++) {
                                    const ouser = this.loggedInUser.other_accounts[i];
                                    if (ouser.nickname === acc.nickname && ouser.email === acc.email) {
                                        this.loggedInUser.other_accounts.splice(i, 1);
                                        if (i === this.readonlyAccountIdx) {
                                            // removed the user that is currently viewed, need to switch
                                            this.readonlyAccountIdx = -1;
                                            // this.removing = false;
                                            // // storeCurrentUser is called there
                                            // this.accountChanged();

                                            this.loggedInUser.readonly_account_idx = this.readonlyAccountIdx === -1 ? undefined : this.readonlyAccountIdx;
                                            this.updateReadOnly();
                                            this.userService.storeCurrentUser(this.loggedInUser);
                                            this.accountChangedService.accountChanged(this.loggedInUser.account);
                                            this.router.navigate(['/stores']);
                                        } else {
                                            this.userService.storeCurrentUser(this.loggedInUser);
                                        }
                                        break;
                                    }
                                }
                                this.alertService.success(this.tr.anslate('Successfully updated'));

                            } else {
                                this.utils.handleError('error', response.error);
                            }
                            this.removing = false;
                        },
                        error: error => {
                            this.utils.handleError('error', error);
                            this.removing = false;
                        }
                    });
            }
            this.removing = false;
        });
    }
}
