import { TranslatorService } from 'src/app/util/services/translator.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle } from '@angular/material/dialog';
import { OrganicIconComponent } from '../organicicon.component';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { StandardService } from 'src/app/util/services/standard.service';
import { finalize, Subject, takeUntil, throttleTime } from 'rxjs';
import { RoastReport } from 'src/app/models/RoastReport';
import { MatIcon } from '@angular/material/icon';
import { environment } from 'src/environments/environment';
import { Roast } from 'src/app/models/Roast';
import { DecimalPipe, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-discarded-dialog',
    templateUrl: './discarded-dialog.component.html',
    styleUrl: './discarded-dialog.component.scss',
    standalone: true,
    imports: [OrganicIconComponent, MatDialogContent, MatDialogActions, MatProgressSpinner, MatButton, MatDialogClose, MatIcon, DecimalPipe, RouterLink, NgTemplateOutlet, MatDialogTitle],
})
export class DiscardedDialogComponent implements OnInit, OnDestroy {

    constructor(
        private standardService: StandardService,
        public tr: TranslatorService,
        public utils: Utils,
        private dialogRef: MatDialogRef<DiscardedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            report: RoastReport,
            mainUnit: UnitSystemType,
        },
    ) { }

    roasts: Roast[];
    notIncludedRoasts: Roast[];
    discardedsTitle: string;
    amountSum: number;
    amountSumNotIncluded: number;

    loadSubscription = new Subject<string>();

    isLoading = false;
    isLoadingTimer: ReturnType<typeof setTimeout>;

    private ngUnsubscribe = new Subject();

    DateTime = DateTime;

    ngOnInit(): void {
        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.loadDiscarded());

        this.loadSubscription.next('init');

        this.discardedsTitle = this.tr.anslate('plural_discarded');
        this.discardedsTitle = `${this.discardedsTitle[0].toLocaleUpperCase()}${this.discardedsTitle.slice(1)}`;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.isLoadingTimer);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    loadDiscarded(): void {
        const filter = this.data.report.filter ?? {};
        const newFilter = Object.assign({}, filter, {
            discarded: true,
            from: this.data.report.startDate,
            to: this.data.report.endDate,
        });
        this.isLoadingTimer = setTimeout(() => {
            this.isLoading = true;
        }, 600);
        this.standardService.getAll<Roast>('roasts', newFilter)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { clearTimeout(this.isLoadingTimer); this.isLoading = false; }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.roasts = this.utils.dateifyRoasts(response.result);
                        this.notIncludedRoasts = [];
                        const reportRoastIds = this.data.report.roasts.map(r => r._id);
                        this.amountSum = 0;
                        this.amountSumNotIncluded = 0;
                        for (let r = 0; r < this.roasts.length; r++) {
                            const roast = this.roasts[r];
                            if (!reportRoastIds.includes(roast._id)) {
                                this.notIncludedRoasts.push(roast);
                                this.amountSumNotIncluded += roast.amount || 0;
                                this.roasts.splice(r, 1);
                                r -= 1;
                            } else {
                                this.amountSum += roast.amount || 0;
                            }
                        }
                    } else {
                        this.utils.handleError('error retrieving all roasts', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all roasts', error);
                }
            });
    }
}
