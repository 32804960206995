import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from './alert.service';
import { Alert, AlertType } from './alert';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit, OnDestroy {
    alerts: Alert[] = [];
    removingAlerts: Alert[] = [];
    subscription: Subscription;

    constructor(
        private alertService: AlertService
    ) { }

    ngOnInit(): void {
        this.subscription = this.alertService.getAlert().subscribe((alert: Alert) => {
            if (!alert || alert.type === AlertType.Clear) {
                // clear alerts
                // this.removingAlerts = cloneDeep(this.alerts);
                this.removingAlerts = this.alerts.filter(alert => alert.type !== AlertType.Error);
                this.alerts = this.alerts.filter(alert => alert.type === AlertType.Error);
            } else if (alert.type === AlertType.ClearAlsoErrors) {
                // clear alerts when an empty alert is received
                this.removingAlerts = this.alerts.slice();
                this.alerts = [];
            } else {
                // add alert to array
                this.alerts.push(alert);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    removeAlert(alertToRemove: Alert): void {
        this.removingAlerts = this.alerts.filter(alert => alert === alertToRemove);
        this.alerts = this.alerts.filter(alert => alert !== alertToRemove);
        this.alertService.removeAlert();
    }

    cssClass(alert: Alert): string {
        if (!alert) {
            return;
        }

        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                return 'alert alert-success';
            case AlertType.Error:
                return 'alert alert-danger';
            case AlertType.Info:
                return 'alert alert-info';
            case AlertType.Warning:
                return 'alert alert-warning';
        }
    }
}
