import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoginChangedService {
    private statusSubject = new BehaviorSubject<boolean>(true);
    loginStatus = this.statusSubject.asObservable();

    changeLoginStatus(status: boolean): void {
        // https://blog.angularindepth.com/
        // everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
        setTimeout(() => {
            this.statusSubject.next(status);
        });
    }
}
