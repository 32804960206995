<div class="chips-filter-main-div" [ngStyle]="{'margin-top': margin_top + 'px', 'max-width': mainWidth}">
    <mat-chip-listbox [disabled]="disabled" [hideSingleSelectionIndicator]="false">
        @for (option of allOptions; track option) {
        <mat-chip-option [disabled]="disabled"
            [style.--mdc-chip-container-height]="'40px'"
            class="chips-filter-option"
            [ngClass]="{'chips-filter-warn': noValueFound && atLeastOne}"
            [selected]="selected[$index]"
            (selectionChange)="optionChanged($index, $event)"
            [ngStyle]="{'width': chipWidth ? chipWidth : 'initial'}">
            <span class="option-container">
                <span class="option-label">{{option}} {{unit}}</span>
                <div class="chips-filter-with-value">
                    @if (additionalValues?.[$index]) {
                    <span class="chips-filter-filter-hints" [ngClass]="{'warn-amount': additionalValueWarns?.[$index]}">
                        {{additionalValues?.[$index]}}
                    </span>
                    }
                </div>
            </span>
        </mat-chip-option>
        }
    </mat-chip-listbox>
</div>