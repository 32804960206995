<table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true" multiTemplateDataRows class="no-pad-table">
    <ng-container matColumnDef="coffee_internal_hr_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc">
            @if (isSorting) {
            <mat-spinner diameter="15" style="margin-right: 20px;"></mat-spinner>
            }
            <ng-container i18n="generic$$Beans@@Beans">Beans</ng-container>
        </th>
        <td mat-cell *matCellDef="let stock">
            @if (stock?.coffee_internal_hr_id) {
            <a class="hr_id" [routerLink]="['/coffees', {id: 'C' + stock.coffee_internal_hr_id}]">C{{stock.coffee_internal_hr_id}}</a>
            }
            <app-organicicon [certInfo]="stock.certInfo" [size]="11"></app-organicicon>
            @if (stock?.origin) {
            <span> {{tr.anslate(stock.origin)}}</span>
            }
            @if (stock?.yearLabel) {
            <span> {{stock.yearLabel}}</span>
            }
            @if (stock?.coffeeLabel && (stock.coffee_internal_hr_id || stock.origin || stock.yearLabel)) {
            <br />
            }
            @if (stock?.coffeeLabel) {
            <span [matTooltip]="stock.coffeeLabel?.length > 20 ? stock.coffeeLabel : undefined"
                matTooltipShowDelay="500" class="clipped-label"> {{stock.coffeeLabel}}
            </span>
            }
        </td>
    </ng-container>

    <!-- most recent transaction -->
    <ng-container matColumnDef="transaction">
        <td mat-cell class="transaction" [attr.colspan]="columnsToDisplay.length" *matCellDef="let stock">
            @if (lastTransactions?.get('C' + stock.coffee_internal_hr_id + '_' + (this.store.hr_id ? this.store.hr_id : 'L' + this.store.internal_hr_id)); as transaction) {
            <div class="smallfont smallfont-indented">
                <span>{{tr.anslate('Last ' + transaction.type?.toLowerCase())}}</span>: {{transaction.amount * utils.getUnitFactor(mainUnit) | number:'1.0-0'}}{{mainUnit}}
                @if (transaction.price) {
                <span class="littlespaceright"><span i18n="transaction$$indicates the price of a transaction, e.g. 3kg _for_ 100€@@for price">for</span> {{transaction.price | currency:currency:'symbol-narrow':'1.0-0'}}</span>
                }
                @if (transaction.type === 'Purchase' && transaction['supplier']?.label) {
                <span class="littlespaceright"> <span i18n="transaction$$transaction, e.g. a transfer, _from_ a source location@@from source" class="littlespaceright">from</span> <a [routerLink]="['/contacts', {id: transaction['supplier']?._id}]" [matTooltip]="transaction['supplier'] && transaction['supplier'].label > 13 ? transaction['supplier'].label : undefined" matTooltipShowDelay="500" class="clipped-string">{{transaction['supplier'].label}}</a></span>
                }
                @if (transaction.type === 'Sale' && transaction['customer']?.label) {
                <span class="littlespaceright"> <span i18n="transaction$$sale _to_ a customer@@to customer" class="littlespaceright">to</span> @if (transaction['customer']?.label) {
                    <a [routerLink]="['/contacts', {id: transaction['customer']?._id}]" [matTooltip]="transaction['customer'] && transaction['customer'].label > 13 ? transaction['customer'].label : undefined" matTooltipShowDelay="500" class="clipped-string">{{transaction['customer'].label}}</a>
                    }</span>
                }
                @if (transaction.type === 'Correction' && transaction['reason']) {
                <span class="clipped-string littlespaceleft littlespaceright" [matTooltip]="transaction['reason'] && transaction['reason'].length > 13 ? transaction['reason'] : undefined" matTooltipShowDelay="500"> "{{transaction['reason']}}"</span>
                }
                @if (transaction.type === 'Transfer' && transaction['target']) {
                <span>
                    @if (transaction['target']._id === store._id) {
                    <span>
                        @if (transaction.location?.label) {
                        <span class="littlespaceright"><span i18n="transaction$$transaction, e.g. a transfer, _from_ a source location@@from source">from</span> {{transaction.location.label}}</span>
                        }
                    </span>
                    } @else if (transaction['target'].label) {
                    <span class="littlespaceright"><span i18n="transaction$$transaction, e.g. a transfer, _to_ a target location@@to target">to</span> {{transaction['target'].label}}</span>
                    }
                </span>
                }
                <span class="nowrap-icons">
                    @if (transaction.date) {
                    <span i18n="transaction$$e.g. on 09.12.1978@@on {{date}}" class="littlespaceright">on {{transaction.date.toLocaleString(DateTime.DATE_MED)}}</span>
                    }
                    @if (!readOnly && !transaction.reconciled) {
                    <a (click)="editTransaction(transaction)" (keydown.enter)="editTransaction(transaction); $event.preventDefault()" role="button" [tabindex]="0">
                        <mat-icon data-test="cn_edit" class="smallesticon">edit</mat-icon>
                    </a>
                    <a (click)="deleteTransaction(transaction)" (keydown.enter)="deleteTransaction(transaction); $event.preventDefault()" role="button" [tabindex]="0">
                        <mat-icon data-test="cn_delete" class="smallesticon smallicon-second">delete</mat-icon>
                    </a>
                    }
                </span>
            </div>
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" arrowPosition="before" i18n="stores$$amount, table header@@amount header">Amount</th>
        <td mat-cell *matCellDef="let stock">
            @if (getAmount(stock); as amount) {
            {{amount.pre}}
            @if (amount.pre) {
            <br />
            }
            <a (click)="changeAmount(stock)" (keydown.enter)="changeAmount(stock); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer" [ngClass]="{'negative-amount': amount.value < 0}">{{amount.value | number:'1.0-1'}}{{amount.post}}</a>
            <!-- <a (click)="changeAmount(stock)"><mat-icon class="smallicon">edit</mat-icon></a> -->
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="cost">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" arrowPosition="before" i18n="stores$$cost, table header@@Cost">Cost</th>
        <td mat-cell *matCellDef="let stock">{{stock.fifo_cost | currency:currency:'symbol-narrow':'1.0-0'}}<br />
            <a (click)="changeCost(stock)" (keydown.enter)="changeCost(stock); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer small-value">{{stock.fifo_cost / (stock.amount * utils.getUnitFactor(mainUnit)) | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}}</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="low_limit">
        <th mat-header-cell *matHeaderCellDef i18n="stores$$limit, table header@@limit header">Limit for Stock Alert</th>
        <td mat-cell *matCellDef="let stock">{{stock.low_limit}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: ['transaction'];"></tr>
</table>