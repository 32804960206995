import { DateTime } from 'luxon';
import { Blend } from './Blend';
import { StockChange } from './StockChange';
import { RoastScheduledItem } from './RoastScheduledItem';

export class RoastTemplate {
    ground_color?: number;
    DRY_time?: number;
    drop_time?: number;
    DRY_temp?: number;
    batch_pos?: number;
    FCe_temp?: number;
    drop_temp_ET?: number;
    TP_temp?: number;
    date?: DateTime;
    whole_color?: number;
    color_system?: string;
    id?: string;
    is_template?: boolean;
    TP_time?: number;
    DEV_ratio?: number;
    DEV_time?: number;
    FCs_temp?: number;
    end_weight?: number;
    FCe_time?: number;
    batch_number?: number;
    density_roasted?: number;
    label?: string;
    machine?: string;
    start_weight?: number;
    roasterheating?: number;
    roastersize?: number;
    drop_temp?: number;
    moisture?: number;
    charge_temp_ET?: number;
    hr_id?: string;
    FCs_time?: number;
    FCs_RoR?: number;
    charge_temp?: number;
    batch_prefix?: string;
    // only used in web client
    coffee?: string;
    blend?: string;
}

export class Roast extends StockChange {
    modified_at: DateTime;
    internal_hr_id?: number;
    hr_id?: string;
    roast_id?: string;
    s_item_id?: RoastScheduledItem;
    is_template?: boolean;
    batch_prefix?: string;
    batch_number?: number;
    batch_pos?: number;
    label?: string;
    blend?: Blend;
    files?: string[];
    image?: string;
    machine?: string;
    notes?: string;
    cupping_notes?: string;
    cupping_score?: number;
    discarded?: boolean;
    destroyed?: DateTime;
    end_weight?: number;
    whole_color?: number;
    ground_color?: number;
    color_system?: string;
    moisture?: number;
    roasterheating?: number;
    roastersize?: number;
    certInfo?: number;
    charge_temp?: number;
    charge_temp_ET?: number;
    drop_time?: number;
    drop_temp?: number;
    drop_temp_ET?: number;
    TP_time?: number;
    TP_temp?: number;
    DRY_time?: number;
    DRY_temp?: number;
    FCs_time?: number;
    FCs_temp?: number;
    FCs_RoR?: number;
    FCe_time?: number;
    FCe_temp?: number;
    DEV_time?: number;
    DEV_ratio?: number;
    AUC?: number;
    AUC_base?: number;
    setup?: string;
    density_roasted?: number;
    temperature?: number;
    pressure?: number;
    humidity?: number;
    GMT_offset?: number;
     
    template?: RoastTemplate;
    CM_ETD?: number; // temperature difference
    CM_BTD?: number; // temperature difference
    BTU_batch?: number;
    CO2_batch?: number;
    // only used in client
    dateOutside?: boolean;
    invalidReason?: string;
    lastPurchaseDate?: DateTime;
    lastPurchaseDates?: DateTime[];
    lastPurchasePrice?: number;
    lastPurchasePrices?: number[];
}

