import { AccountChangedService } from 'src/app/util/services/accountchanged.service';
import { DialogService } from 'src/app/modules/ui/dialog/dialog.service';
import { AdduserDialogComponent } from './adduser-dialog.component';
import { Component, OnInit, OnDestroy, Inject, LOCALE_ID, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UserType, UserService, InvitedUserInfo } from 'src/app/modules/frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { NGXLogger } from 'ngx-logger';
import { User } from 'src/app/models/User';
import { Account } from 'src/app/models/Account';
import { debounceTime, throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/util/utils';
import { Subject } from 'rxjs';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { LoginChangedService } from 'src/app/modules/frame/services/loginchanged.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/modules/report/report.service';

import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { StandardService } from 'src/app/util/services/standard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageSnackComponent } from 'src/app/modules/ui/snacks/message-snack.component';
import { ServerLogService } from 'src/app/util/services/server-log.service';
import { YesNoConfirmDialogComponent } from '../ui/dialog/yesnoconfirm-dialog.component';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private reportService: ReportService,
        private logger: NGXLogger,
        private utils: Utils,
        private alertService: AlertService,
        private standardService: StandardService,
        public tr: TranslatorService,
        private loginChangedService: LoginChangedService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private accountChangedService: AccountChangedService,
        private snackBar: MatSnackBar,
        private serverLogService: ServerLogService,
        @Inject(LOCALE_ID) public locale2: string,
    ) { }

    currentUser: UserType;
    readOnly = false;
    isAdmin = false;
    otherUsers: InvitedUserInfo[] = [];
    pendingUsers: InvitedUserInfo[] = [];
    otherViewers: InvitedUserInfo[] = [];
    pendingViewers: InvitedUserInfo[] = [];

    loading = false;
    updatingUser = false;
    updatingAccount = false;
    downloading = false;
    removing = false;
    resending = false;

    password: string;
    newPassword: string;
    repeatPassword: string;

    paidUntil: DateTime;
    paidDaysLeft: number;

    version: string;
    customsVariantsPerCountry = new Map</*country*/string, /*variants*/string[]>();
    customsCountries: string[] = [];
    customsVariants: string[] = [];

    allCountries: { val: string, tr: string, cur: string, cont: string }[] = [];
    countries: { val: string, tr: string, cur: string, cont: string }[] = [];
    userCountry: { val: string, tr: string, cur: string, cont: string };
    currencies: string[] = [];

    darkmode: 'auto' | 'lightmode' | 'darkmode' = 'auto';
    isDarkmode = false;

    Enumerations = Enumerations;
    DateTime = DateTime;

    saver = new Subject<{ prop: string, value: string }>();
    saverComp = new Subject<{ prop: string, value: string }>();

    @ViewChild('customsHeader') customsElement: ElementRef;
    loadSubscription = new Subject<string>();
    private ngUnsubscribe = new Subject();

    ngOnInit(): void {
        // need exactly de/en/it for link to shop
        this.locale2 = this.locale2 ? this.locale2.substring(0, 2) : 'en';
        this.version = environment.latestWebVersion + '-' + environment.buildNr;

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                dm => {
                    this.isDarkmode = this.userService.isDarkModeEnabled(dm);
                }
            );

        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => {
                    this.init();
                }
            );

        this.saver
            .pipe( // distinctUntilChanged(), // doesn't help since an object is passed
                debounceTime(2000), // no takeUntil since we want to save the changes even if the user browses away
                throttleTime(environment.RELOADTHROTTLE))
            .subscribe(
                newPropVal => {
                    if (newPropVal) {
                        this.saveUser({ [newPropVal.prop]: newPropVal.value });
                    }
                }
            );
        this.saverComp
            .pipe( // distinctUntilChanged(), // doesn't help since an object is passed
                debounceTime(2000), // no takeUntil since we want to save the changes even if the user browses away
                throttleTime(environment.RELOADTHROTTLE))
            .subscribe(
                newPropVal => {
                    if (newPropVal) {
                        this.saveCompany(newPropVal.prop, newPropVal.value);
                    }
                }
            );

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (e: unknown) => {
                    if (e instanceof NavigationEnd && e.url.indexOf('settings') >= 0) {
                        // only pass and debounce interesting events
                        this.loadSubscription.next('reload');
                    }
                }
            );

        this.allCountries = Utils.getAllCountriesInfos();
        const curs = [...new Set(this.allCountries.map(info => info.cur))];
        this.currencies = curs.filter(cur => cur !== 'EUR' && cur !== 'USD').sort();

        this.loadSubscription.next('init');
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    ngAfterViewInit(): void {
        const section = this.route.snapshot.params?.section;
        if (section === 'customs') {
            setTimeout(() => this.customsElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' }))
        }
    }

    init(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        this.getAdditionalInfo();
        this.getCustomsInfo();
        this.darkmode = this.userService.getDarkmode();
        this.userCountry = this.findUserCountry(this.currentUser.country);

        if (this.currentUser.account) {
            this.paidUntil = this.utils.getPaidUntil(this.currentUser.account);
        }
        this.paidDaysLeft = this.utils.paidDaysLeft(this.paidUntil);

        this.readOnly = this.currentUser.readonly;
    }

    findUserCountry(countryVal: string): { val: string, tr: string, cur: string, cont: string } {
        if (!countryVal || !this.allCountries?.length) {
            return undefined;
        }
        for (const exCountry of this.allCountries) {
            if (exCountry.val === countryVal || exCountry.tr === countryVal) {
                return exCountry;
            }
        }
        return undefined;
    }

    changeCountryFilter(): void {
        if (!this.userCountry) {
            this.countries = this.allCountries;
            return;
        }
        if (this.allCountries) {
            const filterValue = (this.userCountry.tr || this.userCountry as unknown as string)?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.countries = this.allCountries.filter(myCountry => myCountry && myCountry.tr.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0);
        }
    }

    /**
     * Sets currency, unit_system, and temperature_system according to the 
     * changed country. Saves the changes on the server and locally.
     * Will clone supplierpartners if country changed (calls getSuppliersWithPartners)
     * @see WizardComponent.countryChanged similar implementation; here, storing into this.currentUser is done later in the saveUser / saveAccount methods; therefore we need to block the UI using updatingUser/updatingAccount flags
     * @param event event.target.value contains the text typed by the user (on blur, if the user has not clicked on the autocomplete entry)
     */
    countryChanged(event?: FocusEvent): void {
        const changedUser: Partial<User> = {};
        let currencyChanged = false;
        if (event?.target && event.target['value']) {
            this.userCountry = this.findUserCountry(event.target['value']);
            if (!this.userCountry) {
                return;
            }
        }
        if (this.userCountry?.cur && this.currentUser.account.currency !== this.userCountry.cur) {
            currencyChanged = true;
        }
        if (this.userCountry?.val && this.currentUser.country !== this.userCountry.val) {
            changedUser.country = this.userCountry.val;
        }
        if (['USA', 'Myanmar', 'Liberia'].includes(changedUser.country)) {
            // according to https://worldpopulationreview.com/country-rankings/countries-that-use-imperial
            if (this.currentUser.unit_system !== Enumerations.UNIT_SYSTEM.IMPERIAL) {
                changedUser.unit_system = Enumerations.UNIT_SYSTEM.IMPERIAL;
            }
        } else if (changedUser.country && this.currentUser.unit_system !== Enumerations.UNIT_SYSTEM.METRIC) {
            changedUser.unit_system = Enumerations.UNIT_SYSTEM.METRIC;
        }
        if (['USA', 'Bahamas', 'Liberia', 'Palau', 'Micronesia', 'Marshall Islands'].includes(changedUser.country)) {
            // according to https://worldpopulationreview.com/country-rankings/countries-that-use-fahrenheit
            // Cayman Islands is missing here which we don't have as country
            if (this.currentUser.temperature_system !== Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT) {
                changedUser.temperature_system = Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT;
            }
        } else if (changedUser.country && this.currentUser.temperature_system !== Enumerations.TEMPERATURE_SYSTEM.CELSIUS) {
            changedUser.temperature_system = Enumerations.TEMPERATURE_SYSTEM.CELSIUS;
        }

        if (Object.keys(changedUser)?.length || currencyChanged) {
            this.updatingUser = true;
            if (currencyChanged) {
                changedUser.account = { currency: this.userCountry?.cur, _id: undefined, customer_code: undefined, active: undefined };
            }
            this.saveUser(changedUser, () => {
                if (typeof changedUser.country !== 'undefined') {
                    // update supplier partners if country changed
                    this.standardService.getSuppliersWithPartners(this.userCountry.val)
                        .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                        .subscribe({
                            next: response => {
                                if (response.success === true) {
                                    const newPartners = response.result?.filter(supp => supp.cloned).length;
                                    if (newPartners) {
                                        let message: string;
                                        if (newPartners === 1) {
                                            message = this.tr.anslate('Check out your new LINKSTART_supplier partner_LINKEND!')
                                                .replace('LINKSTART_', '<a href="contacts">')
                                                .replace('_LINKEND', '</a>');
                                        } else {
                                            message = this.tr.anslate('Check out your {{newPartners}} new LINKSTART_supplier partners_LINKEND!', { newPartners })
                                                .replace('LINKSTART_', '<a href="contacts">')
                                                .replace('_LINKEND', '</a>');
                                        }
                                        const snackBarRef = this.snackBar.openFromComponent(MessageSnackComponent, {
                                            data: {
                                                html: message,
                                                action: 'ok',
                                                notifcation: null,
                                            }
                                        });
                                        snackBarRef.onAction().subscribe(() => {
                                            snackBarRef.dismiss();
                                        });
                                    }
                                } else {
                                    // TODO should an error be shown to the user here?
                                    // this.utils.handleError('error getting suppliers', response.error);
                                }
                            },
                            error: error => {
                                this.serverLogService.errorOccurred(error, 'UserSettings.countryChanged')
                                    .pipe(throttleTime(environment.RELOADTHROTTLE))
                                    .subscribe({
                                        next: response => {
                                            if (response.success !== true) {
                                                this.logger.debug('could not send error, error: ' + response.error);
                                            }
                                        },
                                        error: error => {
                                            this.logger.debug('could not send error, error: ' + error);
                                        }
                                    })
                                // TODO should an error be shown to the user here?
                                // this.utils.handleError('error getting suppliers', error);
                            }
                        });
                }
            });
        }
    }
    
    displayCountryFn(country?: string | { tr: string }): string | undefined {
        if (typeof country === 'string') {
            return country;
        }
        return country?.tr;
    }

    darkmodeSettingChanged(): void {
        this.userService.storeDarkmode(this.darkmode);
    }

    /**
     * Retrieves info about other users and viewers.
     */
    getAdditionalInfo(): void {
        this.userService.getAdditionalInfo()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        this.isAdmin = response.result.admin;
                        this.otherUsers = response.result.otherUsers;
                        this.pendingUsers = response.result.pendingUsers;
                        this.otherViewers = response.result.otherViewers;
                        this.pendingViewers = response.result.pendingViewers;

                    } else {
                        // this.logger.debug('additional user data could not be downloaded');
                        this.utils.handleError('user data could not be downloaded', response.error);
                    }
                },
                error: error => {
                    // this.logger.debug('additional user data could not be downloaded: ' + error);
                    this.utils.handleError('user data could not be downloaded', error);
                }
            });
    }

    /**
     * Retrieves info about the customs settings.
     */
    getCustomsInfo(): void {
        this.reportService.getCustomsTypes()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        this.customsCountries = [];
                        this.customsVariantsPerCountry.clear();
                        for (const customsTypePerCountry of response.result) {
                            this.customsCountries.push(customsTypePerCountry.country);
                            this.customsVariantsPerCountry.set(customsTypePerCountry.country, customsTypePerCountry.variants);
                        }
                        if (this.currentUser?.account?.customs?.report_type?.country) {
                            const vars = this.customsVariantsPerCountry.get(this.currentUser.account.customs.report_type.country);
                            if (vars) {
                                this.customsVariants = vars;
                            } else {
                                this.customsVariants = [];
                                this.currentUser.account.customs.report_type.variant = '-';
                            }
                        }
                    } else {
                        // handleError also handles the case that the token has expired in the meantime
                        this.utils.handleError('user data could not be downloaded', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('user data could not be downloaded', error);
                }
            });
    }

    /**
     * Called from template when the country is changed. Loads the respective customs variants.
     */
    saveCustomsCountry(country: string): void {
        if (country === this.currentUser?.account?.customs?.report_type?.country) {
            return;
        }
        const vars = this.customsVariantsPerCountry.get(country);
        if (vars) {
            this.customsVariants = vars;
        } else {
            this.customsVariants = [];
        }
        const newObj = { account: { customs: { report_type: { country, variant: '-' } } } };
        this.save(
            newObj as unknown as Account,
            () => {
                this.currentUser = merge(this.currentUser, newObj, { isMergeableObject: isPlainObject });
                this.userService.storeCurrentUser(this.currentUser);
            }
        );
    }

    /**
     * Called from template when the customs variant is changed
     */
    saveCustomsVariant(variant: string): void {
        if (variant === this.currentUser?.account?.customs?.report_type?.variant) {
            return;
        }
        const newObj = { account: { customs: { report_type: { variant } } } };
        this.save(
            newObj as unknown as Account,
            () => {
                this.currentUser = merge(this.currentUser, newObj, { isMergeableObject: isPlainObject });
                this.userService.storeCurrentUser(this.currentUser);
            }
        );
    }

    /**
     * Called from template when one of the export options is changed
     */
    saveExport(prop: string, value: string): void {
        if (this.currentUser?.export && this.currentUser?.export[prop] === value) {
            return;
        }
        const newObj = { export: { [prop]: value } };
        this.save(
            newObj,
            () => {
                this.currentUser = merge(this.currentUser, newObj as UserType['export'], { isMergeableObject: isPlainObject });
                this.userService.storeCurrentUser(this.currentUser);
            }
        );
    }

    /**
     * Stores a changed user and calls the given cb function on success
     * @param userData the changed options, e.g. { nickname: 'Yo' }
     * @param cb function called on successful save
     * @param nicknameChanged true if the nickname changed - updates via changeLoginStatus
     */
    save(userData: Partial<User>, cb: () => void, nicknameChanged = false): void {
        if (!userData) {
            return;
        }

        if (!this.currentUser?.user_id) {
            this.logger.fatal('save() no currentUser');
            this.alertService.error(this.tr.anslate('Not logged in!'));
            return;
        }

        userData._id = this.currentUser.user_id;
        this.logger.debug(`saving user settings ${JSON.stringify(userData)}`);

        this.userService.updateUser(userData as User)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        cb();

                        if (nicknameChanged) {
                            // update nickname in app title bar
                            this.loginChangedService.changeLoginStatus(true);
                        }
                        this.alertService.success(this.tr.anslate('Successfully updated'));

                    } else {
                        this.utils.handleError('error updating user information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating user information', error);
                }
            });
    }

    saveUser(changedUser: Partial<User>, cb?: () => void): void {
        const props = Object.getOwnPropertyNames(changedUser);
        // ignore all unchanged or empty nickname
        for (const prop of props) {
            if (changedUser[prop] === this.currentUser[prop] || (prop === 'nickname' && !changedUser[prop])) {
                delete changedUser[prop];
            }
        }
        if (!changedUser || !Object.getOwnPropertyNames(changedUser).length) {
            this.updatingUser = false;
            return;
        }

        this.save(
            changedUser,
            () => {
                Object.assign(this.currentUser, changedUser);
                this.userService.storeCurrentUser(this.currentUser);
                this.updatingUser = false;
                if (typeof cb === 'function') {
                    cb();
                }
            },
            Object.getOwnPropertyNames(changedUser).includes('nickname'),
        );
    }

    // saveUser(prop: string, value: string): void {
    //     // this.logger.debug(`saveUser: ${prop} -> ${value}`);
    //     if (!prop || value === this.currentUser[prop] || (prop === 'nickname' && !value)) {
    //         // ignore unchanged or empty nickname
    //         return;
    //     }

    //     this.save(
    //         { [prop]: value },
    //         () => {
    //             this.currentUser[prop] = value;
    //             this.userService.storeCurrentUser(this.currentUser);
    //         },
    //         prop === 'nickname',
    //     );
    // }

    saveCompany(prop: string, value: string): void {
        if (!prop || !this.currentUser?.account?.company || value === this.currentUser.account.company[prop]) {
            // ignore unchanged
            return;
        }

        this.save(
            { account: { company: { [prop]: value }, _id: undefined, customer_code: undefined, active: undefined } },
            () => {
                this.currentUser = merge(this.currentUser, { account: { company: { [prop]: value } } }, { isMergeableObject: isPlainObject });
                this.userService.storeCurrentUser(this.currentUser);
            },
        );
    }

    /**
     * Sets the value to the account.prop and stores it in the DB; also updated currentUser locally
     * @param prop the property to update; can by "foo.bar" (max one .)
     * @param value the value to assign to the prop
    */
    saveAccount(prop: string, value: string | boolean): void {
        if (!prop) {
            this.updatingAccount = false;
            return;
        }
        if (prop.indexOf('.') < 0 && value === this.currentUser?.account[prop]) {
            this.updatingAccount = false;
            return;
        }

        this.updatingAccount = true;

        const account = { _id: undefined, customer_code: undefined, active: undefined };
        if (prop.indexOf('.') > 0) {
            // split prop, e.g. "settings.xyz"
            const spl = prop.split('.');
            if (value === this.currentUser?.account?.[spl[0]]?.[spl[1]]) {
                this.updatingAccount = false;
                return;
            }
            account[spl[0]] = this.currentUser?.account?.[spl[0]];
            if (!account[spl[0]]) {
                account[spl[0]] = {};
            }
            account[spl[0]][spl[1]] = value;
        } else {
            account[prop] = value;
        }

        this.save(
            { account },
            () => {
                // update locally
                if (!this.currentUser.account) {
                    this.currentUser.account = {};
                }
                if (prop.indexOf('.') > 0) {
                    // split prop, e.g. "settings.xyz"
                    const spl = prop.split('.');
                    if (!this.currentUser.account[spl[0]]) {
                        this.currentUser.account[spl[0]] = {};
                    }
                    this.currentUser.account[spl[0]][spl[1]] = value;
                } else {
                    this.currentUser.account[prop] = value;
                }
                this.userService.storeCurrentUser(this.currentUser);
                this.updatingAccount = false;
            }
        );
    }

    updatePassword(): void {
        if (!this.currentUser?.user_id) {
            this.logger.fatal('updatePassword() no currentUser');
            this.alertService.error(this.tr.anslate('Not logged in!'));
            return;
        }
        if (this.newPassword === '' || this.password === '') {
            this.logger.debug('password is empty', this.currentUser.user_id, this.newPassword, this.password);
            this.alertService.error(this.tr.anslate('password must not be empty'));
            return;
        }
        if (this.newPassword !== this.repeatPassword) {
            this.logger.debug('passwords don\'t match');
            this.alertService.error(this.tr.anslate('repeated password does not match'));
            return;
        }

        this.loading = true;
        this.userService.updatePassword({ _id: this.currentUser.user_id, password: this.newPassword, token: this.password }, true)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        this.logger.debug('successfully updated password:', response.result);
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                    } else {
                        this.logger.fatal('error in updatePassword', response.error);
                        this.alertService.error(this.tr.anslate('could not update the password'), this.tr.anslate(response.error.toString()));
                    }
                    this.loading = false;
                },
                error: error => {
                    this.logger.fatal('error in updatePassword', error);
                    const err = error ? error.error ? error.error.error : error.toString() : undefined;
                    this.alertService.error(this.tr.anslate('could not update the password'), err ? this.tr.anslate(err) : undefined);
                    this.loading = false;
                }
            });
    }

    deleteData(): void {
        if (this.readOnly) { return; }
        if (!this.currentUser?.user_id) {
            this.logger.fatal('deleteData() no currentUser');
            this.alertService.error(this.tr.anslate('Not logged in!'));
            return;
        }

        const dialogRef = this.dialog.open(YesNoConfirmDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to delete all your data?!') }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.userService.deleteAllData(this.currentUser.user_id)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true && response.result) {
                                this.logger.debug('successfully deleted all data', response.result);
                                this.alertService.success(this.tr.anslate('Successfully removed'));
                            } else {
                                this.logger.fatal('error in deleteData', response.error);
                                this.alertService.error(this.tr.anslate('could not delete the data'), response.error);
                            }
                            this.loading = false;
                        },
                        error: error => {
                            this.logger.fatal('error in deleteData', error);
                            const err = error ? error.error ? error.error.error : error.toString() : undefined;
                            this.alertService.error(this.tr.anslate('could not delete the data'), err ? this.tr.anslate(err) : undefined);
                            this.loading = false;
                        }
                    });
            }
        });
    }

    deleteAccount(): void {
        if (this.readOnly) { return; }
        if (!this.currentUser?.user_id) {
            this.logger.fatal('deleteAccount() no currentUser');
            this.alertService.error(this.tr.anslate('Not logged in!'));
            return;
        }

        const dialogRef = this.dialog.open(YesNoConfirmDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to delete your account and all your data?!') }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.userService.deleteAccount(this.currentUser.user_id)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true && response.result) {
                                this.logger.debug('successfully deleted all data', response.result);
                                this.alertService.success(this.tr.anslate('Successfully removed'));
                            } else {
                                this.logger.fatal('error in deleteAccount', response.error);
                                this.alertService.error(this.tr.anslate('could not delete the data'), response.error);
                            }
                            this.loading = false;
                        },
                        error: error => {
                            this.logger.fatal('error in deleteAccount', error);
                            const err = error ? error.error ? error.error.error : error.toString() : undefined;
                            this.alertService.error(this.tr.anslate('could not delete the data'), err ? this.tr.anslate(err) : undefined);
                            this.loading = false;
                        }
                    });
            }
        });
    }

    download(): void {
        if (this.currentUser?.user_id) {
            const userId = this.currentUser.user_id;
            this.downloading = true;
            this.userService.getAllData(userId)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.alertService.success(this.tr.anslate('Successfully downloaded'));
                            this.utils.saveToFileSystem(JSON.stringify(response.result), 'json');
                        } else {
                            this.utils.handleError('user data could not be downloaded', response.error);
                        }
                        this.downloading = false;
                    },
                    error: error => {
                        this.utils.handleError('user data could not be downloaded', error);
                        this.downloading = false;
                    }
                });
        } else {
            this.utils.handleError('user data could not be downloaded', undefined);
            this.downloading = false;
        }
    }

    addUser(readonly = false): void {
        if (!this.currentUser?.user_id) {
            this.logger.fatal('addUser() no currentUser');
            this.alertService.error(this.tr.anslate('Not logged in!'));
            return;
        }

        this.userService.getAdditionalInfo()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.isAdmin = response.result.admin;
                        this.otherUsers = response.result.otherUsers;
                        this.pendingUsers = response.result.pendingUsers;
                        this.otherViewers = response.result.otherViewers;
                        this.pendingViewers = response.result.pendingViewers;

                        const existingEmails =
                            this.otherUsers.map(u => u.email).concat(
                                this.pendingUsers.map(u => u.email)).concat(
                                    this.otherViewers.map(u => u.email)).concat(
                                        this.pendingViewers.map(u => u.email));
                        existingEmails.push(response.result.email);

                        const dialogRef = this.dialog.open(AdduserDialogComponent, {
                            closeOnNavigation: true,
                            data: { readonly: readonly, existingEmails: existingEmails },
                        });

                        dialogRef.afterClosed().subscribe(res => {
                            if (res) {
                                // update
                                this.getAdditionalInfo();
                            }
                        });
                    } else {
                        this.logger.debug('additional user data could not be downloaded');
                        this.utils.handleError('user data could not be downloaded', response.error);
                    }
                },
                error: error => {
                    this.logger.debug('additional user data could not be downloaded: ' + error);
                    this.utils.handleError('user data could not be downloaded', error);
                }
            });
    }

    removeUser(user: { _id?: string, nickname?: string, email?: string }, pending: boolean): void {
        if (this.removing) {
            return;
        }
        this.removing = true;
        this.userService.removeUserFromAccount(user._id, user.email, false, pending)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        // remove user locally
                        let idx = -1;
                        for (let i = 0; i < this.otherUsers.length; i++) {
                            const ouser = this.otherUsers[i];
                            if (ouser.nickname === user.nickname && ouser.email === user.email) {
                                idx = i;
                                break;
                            }
                        }
                        if (idx >= 0) {
                            this.otherUsers.splice(idx, 1);
                        }
                        idx = -1;
                        for (let i = 0; i < this.pendingUsers.length; i++) {
                            const puser = this.pendingUsers[i];
                            if (puser.nickname === user.nickname && puser.email === user.email) {
                                idx = i;
                                break;
                            }
                        }
                        if (idx >= 0) {
                            this.pendingUsers.splice(idx, 1);
                        }
                        // re-read from server to be sure
                        this.getAdditionalInfo();
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                    } else {
                        this.utils.handleError('error', response.error);
                    }
                    this.removing = false;
                },
                error: error => {
                    this.utils.handleError('error', error);
                    this.removing = false;
                }
            });
    }

    // same as removeUser but with otherViewers and pendingViewers arrays
    removeViewer(user: { _id?: string, nickname?: string, email?: string }, pending: boolean): void {
        if (this.removing) {
            return;
        }
        this.removing = true;
        this.userService.removeUserFromAccount(user._id, user.email, true, pending)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        // remove user locally
                        let idx = -1;
                        for (let i = 0; i < this.otherViewers.length; i++) {
                            const ouser = this.otherViewers[i];
                            if (ouser.nickname === user.nickname && ouser.email === user.email) {
                                idx = i;
                                break;
                            }
                        }
                        if (idx >= 0) {
                            this.otherViewers.splice(idx, 1);
                        }
                        idx = -1;
                        for (let i = 0; i < this.pendingViewers.length; i++) {
                            const puser = this.pendingViewers[i];
                            if (puser.nickname === user.nickname && puser.email === user.email) {
                                idx = i;
                                break;
                            }
                        }
                        if (idx >= 0) {
                            this.pendingViewers.splice(idx, 1);
                        }
                        // re-read from server to be sure
                        this.getAdditionalInfo();
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                    } else {
                        this.utils.handleError('error', response.error);
                    }
                    this.removing = false;
                },
                error: error => {
                    this.utils.handleError('error', error);
                    this.removing = false;
                }
            });
    }

    removeViewerFromMyAccount(acc: UserType['other_accounts'][0]): void {
        if (this.removing) {
            return;
        }
        this.removing = true;
        this.userService.removeViewerFromMyAccount(acc._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        if (this.currentUser) {
                            // remove user locally
                            for (let i = 0; i < this.currentUser.other_accounts.length; i++) {
                                const ouser = this.currentUser.other_accounts[i];
                                if (ouser.nickname === acc.nickname && ouser.email === acc.email) {
                                    this.currentUser.other_accounts.splice(i, 1);
                                    this.userService.storeCurrentUser(this.currentUser);
                                    this.accountChangedService.accountChanged(this.currentUser.account);
                                    break;
                                }
                            }
                        }
                        this.alertService.success(this.tr.anslate('Successfully updated'));

                    } else {
                        this.utils.handleError('error', response.error);
                    }
                    this.removing = false;
                },
                error: error => {
                    this.utils.handleError('error', error);
                    this.removing = false;
                }
            });
    }

    resendInvitationEmail(user: InvitedUserInfo): void {
        if (this.resending) {
            return;
        }
        this.resending = true;
        this.userService.resendInvitationEmail(user.email)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && (typeof response.result === 'string')) {
                        this.dialogService.showDialog(
                            'Successfully updated',
                            'Email sent. You can also forward the following link yourself',
                            response.result);
                    } else {
                        this.logger.warn('email could not be sent');
                        this.utils.handleError('could not send email', response.error);
                    }
                    this.resending = false;
                },
                error: error => {
                    this.logger.warn('email could not be sent');
                    this.utils.handleError('could not send email', error);
                    this.resending = false;
                }
            });
    }
}
