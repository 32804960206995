import { DialogModule } from 'src/app/modules/ui/dialog/dialog.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoastComponent } from './roast.component';
import { RoastsComponent } from './roasts.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { ApDatetimeModule } from 'src/app/modules/ui/ap-datetime.module';
import { AngularSplitModule } from 'angular-split';
import { RoastGraphModule } from 'src/app/modules/graph/roast-graph.module';
import { RoastRoutingModule } from './roast-routing.module';
import { FileUploadModule } from 'src/app/modules/ui/file-upload.module';
import { ModificationDateModule } from 'src/app/modules/ui/modification-date.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RoastBlendEditComponent } from 'src/app/modules/blend/roast-blend-edit.component';
import { UiModule } from 'src/app/modules/ui/ui.module';
import { BleinputComponent } from './bleinput.component';
import { RoastsFilterComponent } from './roasts-filter.component';
import { RangeFilterComponent } from './filters/range-filter.component';
import { SelectFilterComponent } from './filters/select-filter.component';
import { ClickValuesDirective } from './filters/range-filter-click.directive';
import { CheckboxFilterComponent } from './filters/checkbox-filter.component';
import { CheckboxandtextFilterComponent } from './filters/checkboxandtext-filter.component';

@NgModule({
    declarations: [
        RoastComponent,
        RoastsComponent,
        RoastBlendEditComponent,
        BleinputComponent,
        RoastsFilterComponent,
        RangeFilterComponent,
        CheckboxandtextFilterComponent,
        ClickValuesDirective,
    ],
    imports: [
        CommonModule,
        RouterModule,
        RoastRoutingModule,
        FormsModule,
        AngularSplitModule,
        MyMaterialModule,
        ApDatetimeModule,
        RoastGraphModule,
        DialogModule,
        FileUploadModule,
        ModificationDateModule,
        NgxMatSelectSearchModule,
        UiModule,
        NgxSliderModule,
        SelectFilterComponent,
        CheckboxFilterComponent,
    ],
    exports: [
        RoastsFilterComponent,
    ]
})
export class RoastModule { }
