import { FormsModule } from '@angular/forms';
import { WizardComponent } from './wizard.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WizardRoutingModule } from './wizard-routing.module';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';

@NgModule({
    declarations: [
        WizardComponent
    ],
    imports: [
        CommonModule,
        WizardRoutingModule,
        FormsModule,
        MyMaterialModule,
    ]
})
export class WizardModule { }
