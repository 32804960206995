import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoastsComponent } from './roasts.component';
import { AuthGuard } from 'src/app/modules/frame/services/auth-guard.service';

const routes: Routes = [
    {
        path: '',
        component: RoastsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RoastRoutingModule { }
