import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose, MatDialogActions, MatDialogContent } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { Roast } from 'src/app/models/Roast';
import { UnitSystemType, Utils } from 'src/app/util/utils';

@Component({
    selector: 'app-roasts-dialog',
    templateUrl: './roasts-dialog.component.html',
    styleUrls: ['./roasts-dialog.component.scss'],
    imports: [MatButton, DatePipe, MatDialogClose, MatDialogActions, MatDialogContent, MatIcon, MatList, MatListItem, DecimalPipe, RouterLink, NgClass],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoastsDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<RoastsDialogComponent>,
        protected utils: Utils,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(MAT_DIALOG_DATA) public data: { roasts: Roast[], mainUnit: UnitSystemType },
    ) { }

    Math = Math;
    Number = Number;

    closeDialog(): void {
        this.dialogRef.close();
    }
}
