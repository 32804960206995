import { Component, OnInit } from "@angular/core";
import { Coffee } from "src/app/models/Coffee";
import { Enumerations } from "src/app/models/Enumerations";
import { UserService, UserType } from "../../frame/services/user.service";
import { Location } from 'src/app/models/Location';
import { Subject } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { UnitSystemType, Utils } from "src/app/util/utils";
import { Router } from "@angular/router";
import { WaitDialogComponent } from "../../ui/dialog/wait-dialog.component";
import { DateTime } from "luxon";

@Component({
    selector: 'app-transaction-dialog',
    template: '',
})
export class TransactionDialogComponent extends WaitDialogComponent implements OnInit {
    
    constructor(
        public dialogRef: MatDialogRef<WaitDialogComponent>,
        protected utils: Utils,
        protected userService: UserService,
        protected router: Router,
    ) {
        super(dialogRef);
    }

    // e.g. 2 (69kg bags)
    amount = 0;
    // e.g. 138 (kg)
    unitamount = 0;
    // the source warehouse
    mainUnit: UnitSystemType = 'kg';
    mainUnitSingular = 'kg';
    currency = 'EUR';

    unit_system = Enumerations.UNIT_SYSTEM.METRIC;
    stores: Location[];
    coffees: Coffee[];
    filteredCoffees: Coffee[];
    date: DateTime;
    coffee: Coffee;
    reconciled: boolean;

    currentUser: UserType;
    Enumerations = Enumerations;

    submitPressed = false;
    waitingForChanges = false;

    // used to cancel all pending requests if user navigates away
    protected ngUnsubscribe = new Subject();


    ngOnInit(): void {
        super.ngOnInit();

        this.currentUser = this.userService.getCurrentUser();
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }

        this.unit_system = this.currentUser.unit_system;
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
            this.mainUnitSingular = 'lb';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        this.date = DateTime.now();
        // this.date = this.date.set({hour: 12, minute: 0, second: 0, millisecond: 0});

        this.dialogRef.backdropClick().subscribe(() => this.handleExit());
        this.dialogRef.keydownEvents().subscribe(ev => ev?.code === 'Escape' && this.handleExit(ev));
        this.dialogRef.afterClosed().subscribe(res => this.handleExit(undefined, res));
    }

    protected handleExit(ev?: KeyboardEvent, res?: unknown, force = false) {
        if (force || (!this.waiting && (!ev || ev.code === 'Escape'))) {
            this.cancelled.emit();
            this.dialogRef.close(res);
        }
    }

    protected finishDialog(sc: unknown) {
        this.finished.emit(sc);
    }

    protected add(): void {
        this.submitPressed = true;
        if (!this.waitingForChanges) {
            this.doAdd();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected doAdd(that?: TransactionDialogComponent, changedPrice?: string): void {
        throw new Error("Must be implemented in subclass");
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected calcAmount(toUnit: boolean, deltaChanged: boolean): void {
        throw new Error("Must be implemented in subclass");
    }

    protected checkChangesUnits(parent: unknown, variable: string, oldValue: number, newValueStr: string, toUnit: boolean, deltaChanged?: boolean, digits = 3): void {
        parent[variable] = undefined;
        this.waitingForChanges = true;
        setTimeout(() => {
            const { val, changed } = this.utils.checkChangedValue(oldValue * (!toUnit ? this.utils.getUnitFactor(this.mainUnit) : 1), newValueStr, digits);
            parent[variable] = val / (!toUnit ? this.utils.getUnitFactor(this.mainUnit) : 1);
            this.waitingForChanges = false;
            if (changed) {
                this.calcAmount(toUnit, deltaChanged);
                if (!this.waiting && this.submitPressed === true && parent[variable] !== oldValue) {
                    this.doAdd();
                }
            }
            this.submitPressed = false;
        });
    }
}