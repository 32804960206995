import { DateTime } from 'luxon';
import { Account } from './Account';

export class User {
    _id: string;
    created_at ?: Date;
    updated_at ?: Date;
    deleted ?: boolean;
    account ?: Account;
    nickname: string;
    country ?: string;
    name ?: string;
    email: string;
    hash ?: string;
    admin ? = false;
    locale ? = 'en';
    timezone ?: string;
    unit_system ? = 'Metric';
    temperature_system ? = 'Celsius';
    artisan ?: string;
    os ?: string;
    last_login ? = DateTime.now();
    email_verified ? = false;
    email_verification_token ?: string;
    invitation_token ?: string;
    invitation_userId ?: User;
    invitation_readonly_user ?: boolean;
    other_accounts ?: { _id?: Account, nickname?: string, email?: string }[];
    readonly_account_idx ?: number;
    last_own_account ?: Account;
    password_reset_token ?: string;
    active = false;
    privacy_accepted ? = false;
    privacy_accepted_date ?: DateTime;
    enabled_modules ? = [];
    export ?: { decsep?: string, linesep?: string, sep?: string };
    energy_unit ?: string;
    gas_unit ?: string;
    webVersion ?: string;
    created_by?: User;
    updated_by?: User;
    // helptips shown (which help indicators have already been shown)
    hts ?: number;
}

