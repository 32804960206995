import { Component, Input, Output, EventEmitter, OnInit, ViewChild, Inject, LOCALE_ID } from '@angular/core';
import { Utils } from 'src/app/util/utils';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { Options, SliderComponent, ChangeContext } from '@angular-slider/ngx-slider';
import { convertFromIndexToValue, convertFromValueToIndex } from './filter-utils';
import { NumberFilterType } from 'src/app/util/services/standard.service';
import { FilterComponent } from './filter.component';
import { EMPTYTAG } from './filter-utils';

@Component({
    selector: 'app-range-filter',
    templateUrl: './range-filter.component.html',
    styleUrls: ['./range-filter.component.scss'],
    providers: [
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'check' } as MatCheckboxDefaultOptions }
    ],
})
export class RangeFilterComponent implements OnInit, FilterComponent {

    constructor(
        public utils: Utils,
        public tr: TranslatorService,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    @ViewChild('slider') slider: SliderComponent;

    private _disabled = false;
    get disabled(): boolean { return this._disabled; }
    @Input() set disabled(disable: boolean) {
        this._disabled = disable;
        this.updateDisabled();
    }

    rangemin: number;
    rangemax: number;
    min: number;
    max: number;
    @Output() rangeChange = new EventEmitter<NumberFilterType>();

    // should not round; otherwise, DB entry of 0.0039 will not be found when
    // querying $gte: 0.004; also range will be shown as 0...0 for [0, 0.04] if step 0.1

    readonly NRDIGITS_DEFAULT = 3;

    private _step = 0.1;
    get step(): number { return this._step; }
    @Input() set step(s: number) {
        this._step = s;
        // this.toround = 1 / (this._step || 1);
        this.nrDigits = this.NRDIGITS_DEFAULT;
    }

    @Input() nrDigits: number;

    private _allowNullInit = true;
    get allowNullInit() { return this._allowNullInit; }
    @Input() set allowNullInit(an: boolean) {
        this._allowNullInit = an ?? true;
        this.allowNull = this._allowNullInit;
    }

    private _dontAllowNullInit = false;
    get dontAllowNullInit() { return this._dontAllowNullInit; }
    @Input() set dontAllowNullInit(dan: boolean) {
        this._dontAllowNullInit = dan ?? false;
        this.dontAllowNull = this.dontAllowNullInit;
    }

    @Input() showAllowNull = true;

    private _floor: number;
    get floor(): number { return this._floor; }
    @Input() set floor(m: number) {
        // new interval; keep value at min if it was min before
        if (this.min === this._floor) {
            this.min = m;
        }
        this._floor = Number.isFinite(m) ? m : 0;
        if (m != null) {
            this.update();
        }
    }
    private _ceil: number;
    get ceil(): number { return this._ceil; }
    @Input() set ceil(c: number) {
        // new interval; keep value at max if it was max before
        if (this.max === this._ceil) {
            this.max = c;
        }
        this._ceil = Number.isFinite(c) ? c : 0;
        if (c != null) {
            this.update();
        }
    }

    @Input() unit: string;
    @Input() margin_top = -7;

    // whether to show 0 instead of -- for the "null" checkbox
    private _emptyIsZero = false;
    get emptyIsZero(): boolean { return this._emptyIsZero; }
    @Input() set emptyIsZero(eiz: boolean) {
        this._emptyIsZero = eiz;
    }

    // manualRefresh: EventEmitter<void> = new EventEmitter<void>();

    // default values for reset
    allowNull = this.allowNullInit;
    dontAllowNull = this.dontAllowNullInit;

    onlyOneOption = false;
    showSwitch = false;
    show = true;

    isNot = false;
    rangeOptions: Options = {
        floor: 0,
        ceil: 100,
        tickStep: 10,
        step: 0.1,
        showTicks: true,
        enforceStep: false,
        enforceStepsArray: false,
        bindIndexForStepsArray: true,
        translate: (modelValue: number): string => {
            if (!this.step) this.step = 0.1;
            if (this.rangeOptions.bindIndexForStepsArray && this.rangeOptions.stepsArray) {
                const val = convertFromIndexToValue(modelValue, this.rangeOptions.stepsArray);
                if (this.unit === 's') {
                    return `${Math.floor(val / 60)}:${Math.round(val % 60).toString().padStart(2, '0')}`;
                }
                return val.toLocaleString(this.locale, { maximumFractionDigits: Math.round(-Math.log10(this.step)) });
                // return val.toLocaleString(this.locale, { maximumFractionDigits: this.nrDigits });
            }
            return modelValue.toLocaleString(this.locale, { maximumFractionDigits: Math.round(-Math.log10(this.step)) });
            // return modelValue.toLocaleString(this.locale, { maximumFractionDigits: this.nrDigits });
            // original implementation:
            // if (this.rangeOptions.bindIndexForStepsArray) {
            //     const step = this.rangeOptions.stepsArray[modelValue];
            //     return String(step == null ? step.value : NaN);
            // }
            // return String(modelValue);
        },
    };

    EMPTYTAG = EMPTYTAG;

    ngOnInit(): void {
        this.locale = this.locale ?? 'en';

        setTimeout(() => this.emitChanges(true), 0);
    }

    update(): void {
        if (this.floor == null || this.ceil == null || this.floor > this.ceil) {
            return;
        }
        this.rangeOptions.floor = this.floor;
        this.rangeOptions.ceil = this.ceil;
        this.rangeOptions.stepsArray = [{ value: this.floor }];
        const step = Math.max(this.step ?? 0, Math.pow(10, Math.round(Math.log10((this.ceil - this.floor) / 100.0))));
        const start = this.floor + (this.floor >= 0 ? step : 0) - ((this.floor + step) % step);
        for (let s = this.floor === start ? 1 : 0; start + s * step < this.ceil; s++) {
            this.rangeOptions.stepsArray.push({ value: start + s * step });
        }
        this.rangeOptions.stepsArray.push({ value: this.ceil });
        // this.rangeOptions.step = Math.pow(10, Math.round(Math.log10((this.rangeOptions.ceil - this.rangeOptions.floor) / 100.0)));
        this.rangeOptions.tickStep = Math.max(1, Math.min(10, Math.pow(10, Math.round(Math.log10((this.rangeOptions.stepsArray.length - 1) / 10.0)))));
        // if ((this.rangeOptions.ceil - this.rangeOptions.floor) / this.rangeOptions.tickStep > 30) {
        //     this.rangeOptions.tickStep *= 10;
        // }
        this.onlyOneOption = this.rangeOptions.floor === this.rangeOptions.ceil;

        if (this.min == null || (this.min < (this.rangeOptions.stepsArray[0]?.value ?? 0))) {
            this.min = this.rangeOptions.stepsArray[0]?.value ?? 0;
        }
        const maxdefault = this.rangeOptions.stepsArray[this.rangeOptions.stepsArray.length - 1]?.value ?? 100;
        if (this.max == null || this.max > maxdefault) {
            this.max = maxdefault;
        } else if (this.max < this.min) {
            this.max = this.min;
        }
        this.rangemin = convertFromValueToIndex(this.min, this.rangeOptions.stepsArray) ?? 0;
        this.rangemax = convertFromValueToIndex(this.max, this.rangeOptions.stepsArray) ?? this.rangeOptions.stepsArray.length - 1;

        this.show = false;
        setTimeout(() => {
            this.show = true;
            this.emitChanges(true);
        }, 10);
        this.emitChanges(true);
    }

    reset(redraw = true): void {
        if (!this.rangeOptions.stepsArray) {
            this.update();
        }
        this.allowNull = this.allowNullInit;
        this.dontAllowNull = this.dontAllowNullInit;
        this.isNot = false;
        this.rangemin = 0;
        if (!this.rangeOptions.stepsArray) {
            this.rangeOptions.stepsArray = [{ value: this.floor }];
        }
        this.rangemax = this.rangeOptions.stepsArray.length - 1;
        this.min = this.rangeOptions.stepsArray[0]?.value ?? 0;
        this.max = this.rangeOptions.stepsArray[this.rangeOptions.stepsArray.length - 1]?.value ?? 100;
        this.rangeOptions = Object.assign({}, this.rangeOptions, { disabled: false });
        if (redraw) {
            this.show = false;
            setTimeout(() => {
                this.show = true;
            }, 10);
        }

        // this.manualRefresh.emit();
    }

    getValues(): { min: number, max: number, inverse: boolean, allowNull: 'yes' | 'only' | 'no' } {
        return { min: this.min, max: this.max, inverse: this.isNot, allowNull: this.allowNull && this.dontAllowNull ? 'only' : (this.allowNull ? 'yes' : 'no') };
    }

    setValues(arg: { min: number, max: number, inverse: boolean, allowNull: 'yes' | 'only' | 'no' }) {
        this.reset(false);
        if (!Number.isFinite(arg.min)) {
            arg.min = this.floor;
        }
        if (arg.min < this.floor) {
            arg.min = this.floor;
        }
        this.rangemin = convertFromValueToIndex(arg.min, this.rangeOptions.stepsArray);
        // this.min = convertFromIndexToValue(this.rangemin, this.rangeOptions.stepsArray);
        this.min = arg.min;

        if (!Number.isFinite(arg.max)) {
            arg.max = this.ceil;
        }
        if (arg.max > this.ceil) {
            arg.max = this.ceil;
        }
        this.rangemax = convertFromValueToIndex(arg.max, this.rangeOptions.stepsArray);
        // this.max = convertFromIndexToValue(this.rangemax, this.rangeOptions.stepsArray);
        this.max = arg.max;

        this.isNot = !!arg.inverse;
        this.allowNull = (arg.allowNull == null || arg.allowNull === 'yes' || arg.allowNull === 'only');
        this.dontAllowNull = arg.allowNull === 'only';
        this.rangeOptions = Object.assign({}, this.rangeOptions, { disabled: this.dontAllowNull });
        // this.show = false;
        // setTimeout(() => {
        //     this.show = true;
        //     this.emitChanges(true);
        // }, 10);
        this.emitChanges(true);
    }

    emitChanges(initial = false): void {
        this.rangeChange.emit({
            min: this.dontAllowNull || this.min <= this.floor ? undefined : this.min ?? undefined,
            max: this.dontAllowNull || this.max >= this.ceil ? undefined : this.max ?? undefined,
            inverse: this.dontAllowNull ? undefined : (this.isNot || undefined),
            allowNull: this.dontAllowNull ? 'only' : (this.allowNull ? undefined : 'no'),
            firstInit: initial ? true : undefined,
        });
        this.updateShowSwitch();
    }

    updateDisabled() {
        if (!!this.disabled !== !!this.rangeOptions.disabled) {
            this.rangeOptions = Object.assign({}, this.rangeOptions, { disabled: this.disabled });
        }
        // this.emitChanges();
    }

    allowNullClicked(): void {
        if (this.allowNull && !this.dontAllowNull) {
            this.dontAllowNull = true;
            this.rangeOptions = Object.assign({}, this.rangeOptions, { disabled: true });
        } else if (this.dontAllowNull) {
            this.dontAllowNull = false;
            this.allowNull = false;
            this.rangeOptions = Object.assign({}, this.rangeOptions, { disabled: false });
        } else {
            this.allowNull = true;
        }
        this.emitChanges();
    }

    updateShowSwitch(): void {
        this.showSwitch = !this.dontAllowNull && (this.isNot || !(this.min === this.floor && this.max === this.ceil));
    }

    doFilter(change?: ChangeContext): void {
        if (this.rangeOptions.stepsArray) {
            if (change?.value != null && Number.isFinite(change.value)) {
                this.min = convertFromIndexToValue(change.value, this.rangeOptions.stepsArray);
            }
            if (change?.highValue != null && Number.isFinite(change.highValue)) {
                this.max = convertFromIndexToValue(change.highValue, this.rangeOptions.stepsArray);
            }
            this.emitChanges();
        }
    }
}
