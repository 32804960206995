import { ApDatetimeModule } from 'src/app/modules/ui/ap-datetime.module';
import { CoffeesComponent } from './coffees.component';
import { CoffeeComponent } from './coffee.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoffeeRoutingModule } from './coffee-routing.module';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { FormsModule } from '@angular/forms';
import { TransactionsModule } from 'src/app/modules/transaction/transactions.module';
import { RouterModule } from '@angular/router';
import { StockModule } from 'src/app/modules/stock/stock.module';
import { DialogModule } from 'src/app/modules/ui/dialog/dialog.module';
import { FileUploadModule } from 'src/app/modules/ui/file-upload.module';
import { ModificationDateModule } from 'src/app/modules/ui/modification-date.module';
import { ApHideModule } from 'src/app/modules/ui/ap-hide.module';
import { UiModule } from 'src/app/modules/ui/ui.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
    declarations: [
        CoffeeComponent,
        CoffeesComponent,
    ],
    imports: [
        CommonModule,
        CoffeeRoutingModule,
        RouterModule,
        StockModule,
        TransactionsModule,
        FormsModule,
        MyMaterialModule,
        DialogModule,
        FileUploadModule,
        ApDatetimeModule,
        ModificationDateModule,
        ApHideModule,
        NgxMatSelectSearchModule,
        UiModule,
    ],
    exports: [
        CoffeeComponent,
        CoffeesComponent
    ],
})
export class CoffeeModule { }
