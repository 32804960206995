<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>


@if (count > 1 || showAlways) {
<div class="roastsfilter-filter-wrapper">
    <!-- date filter -->
    <div class="filter-parts date-filter" style="align-self: flex-end;">
        <mat-form-field class="date-input-narrow left-margin-only">
            <mat-label i18n="reports$$start date@@start date">From</mat-label>
            <input [disabled]="editNewRoast >= 0" matInput
                name="startDate" data-test="npt_startDate"
                placeholder="From" i18n-placeholder="reports$$start date@@start date"
                [ngModel]="filters.from?.isValid ? filters.from : undefined"
                (dateChange)="datesChanged($event, true)"
                [matDatepicker]="myDatepickerStart">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepickerStart">
                <mat-icon matDatepickerToggleIcon>today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #myDatepickerStart></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="date-input-narrow left-margin-only">
            <mat-label i18n="reports$$end date@@end date">To</mat-label>
            <input [disabled]="editNewRoast >= 0" matInput [min]="filters.from"
                name="endDate" data-test="npt_endDate"
                placeholder="To" i18n-placeholder="reports$$end date@@end date"
                [ngModel]="filters.to?.isValid ? filters.to : undefined"
                (dateChange)="datesChanged($event, false)"
                [matDatepicker]="myDatepickerEnd">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepickerEnd">
                <mat-icon matDatepickerToggleIcon>event</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #myDatepickerEnd></mat-datepicker>
        </mat-form-field>
        @if (showCancelDates && (filters.from?.isValid || filters.to?.isValid)) {
        <button
            [disabled]="editNewRoast >= 0" (click)="cancelFilterDates()"
            mat-icon-button type="button" class="primary-element">
            <mat-icon class="overview-close-icon">close</mat-icon>
        </button>
        }
        @if (filterInfo && detailFilterOpen) {
        <div class="filter-hints roastsfilter-date-hints">
            <span class="date-hint-narrow">{{filterInfo.date?.min?.toLocaleString(DateTime.DATE_SHORT)}}</span>
            <span class="date-hint-narrow" style="padding-right: 5px;">{{filterInfo.date?.max?.toLocaleString(DateTime.DATE_SHORT)}}</span>
        </div>
        }
    </div>
    <div class="filter-parts roastsfilter-origin-etc-filter">
        <!-- origin filter -->
        @if (allOrigins?.length) {
        <mat-form-field class="origin-select left-margin-only"
            style="vertical-align: bottom">
            <mat-label i18n="graphs$$graph label@@Origins">Origins</mat-label>
            <mat-select [disabled]="editNewRoast >= 0" matInput multiple panelWidth="null"
                [compareWith]="utils.compareObjectsFn" name="filter.origins"
                placeholder="Origins" i18n-placeholder="graphs$$graph label@@Origins"
                [ngModel]="filters.origins?.vals"
                (selectionChange)="originsChanged($event)">
                @if (filters.origins?.vals?.length) {
                <mat-select-trigger>
                    {{translatedOrigins.join(', ')}}
                </mat-select-trigger>
                }
                <mat-option>
                    <ngx-mat-select-search ngModel name="originSelect"
                        (ngModelChange)="filteredOrigins = filterOrigins($event)"
                        placeholderLabel="Selection" i18n-placeholderLabel="beans$$beans property selection@@Selection"
                        [showToggleAllCheckbox]="true"
                        [toggleAllCheckboxChecked]="allChecked"
                        [toggleAllCheckboxIndeterminate]="indetChecked"
                        (toggleAll)="toggleAll($event)"
                        noEntriesFoundLabel="" i18n-noEntriesFoundLabel="generic$$no matches found using the given filter@@no match for filter">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="EMPTYTAG">
                    <mat-icon>unknown_med</mat-icon>
                </mat-option>
                @for (origin of filteredOrigins; track origin?.english) {
                <mat-option [value]="origin.english">
                    {{origin.translated}}
                </mat-option>
                }
            </mat-select>
        </mat-form-field>
        }
        <!-- organic filter -->
        <div class="origin-select organic-filter left-margin-only">
            <app-organicicon class="cursor-pointer"
                (click)="organicChanged()"
                [isOrganic]="true"
                [inactive]="!filters.showOrganic || editNewRoast >= 0"
                [strikethrough]="filters.showOrganic === 'off'"
                [matTooltip]="!filters.showOrganic ? tr.anslate('Show only items with organic certification') : ''"
                i18n-matTooltip="generic$$tooltip, filter for organic certifications@@Show only items with organic certification">
            </app-organicicon>
            @if (!helptipOrganicShown && showHelptipOrganic) {
            <div class="helptip helptip-organic" [ngClass]="{'showme': showHelptipOrganic}"><span i18n="generic$$tooltip, filter for organic certifications@@Show only items with organic certification">Show only items with organic certification</span>&nbsp;<mat-icon class="small-close-icon" (click)="removeHelptip('organic')">close</mat-icon></div>
            }
        </div>
        <!-- showstockfrom filter -->
        @if (stores?.length > 1) {
        <mat-form-field class="origin-select left-margin-only twoline-select"
            style="vertical-align: bottom">
            <mat-label i18n="stores$$placeholder: select the store(s) for which to show roasts@@Show roasts from">Show roasts from</mat-label>
            <mat-select multiple [disabled]="editNewRoast >= 0"
                name="showstockfrom" panelWidth="null"
                placeholder="Show roasts from" i18n-placeholder="stores$$placeholder: select the store(s) for which to show roasts@@Show roasts from"
                [value]="(!filters.ssf || filters.ssf === 'all') ? stores : filters.ssf"
                [compareWith]="utils.compareObjectsFn"
                (openedChange)="showstockfromOpened($event)"
                (selectionChange)="showstockfromChanged($event)">
                <mat-select-trigger class="twoLineOption ellipsed">
                    @if (filters.ssf?.length === 1) {
                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: filters.ssf[0]}"></ng-container>
                    } @else if (!filters.ssf?.length || filters.ssf === 'all' || filters.ssf?.length === stores.length) {
                    <span i18n="generic$$indicating everything is selected@@all">all</span>
                    } @else if (filters.ssf?.length > 1 && stores.length > filters.ssf.length) {
                    {{filters.ssf.length}} <span i18n="locationtypes$$LocationTypes@@Stores">Stores</span>
                    }
                </mat-select-trigger>
                @for (store of stores; track store?._id) {
                <mat-option [value]="store"><ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container></mat-option>
                }
            </mat-select>
        </mat-form-field>
        }
        @if (!hideSearchDetails || anyAdditionalFilterActive) {
        <div class="filter-parts" style="align-self: center;">
            <a>
                <mat-icon
                    (click)="detailFilterOpen = !detailFilterOpen"
                    class="detail-filter-icon primary-element"
                    [ngClass]="{'tertiary-element': anyAdditionalFilterActive}"
                    [ngStyle]="{'color': detailFilterOpen && !anyAdditionalFilterActive ? 'gray' : undefined}">manage_search
                </mat-icon>
            </a>
            <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/updates/filtering-roasts/'" rel="noopener" style="vertical-align: middle; padding-right: 0; margin: 0 0 0 15px;" target="_blank">
                <mat-icon class="helpicon">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
            </a>
        </div>
        }
    </div>
    <!-- [ngStyle]="{'margin-right': filterInfo?.blends?.vals?.length ? '20px' : 0}" -->
    <div class="optional-filter-container" [@openClose]="detailFilterOpen ? 'open' : 'closed'">
        <div class="filter-parts late-wrap-part">
            <!-- beans and blends filter -->
            @if (filterInfo?.coffees?.vals?.length) {
            <!-- [ngStyle]="{'padding-right': filterInfo?.blends?.vals?.length ? '24px' : 0}" -->
            <app-select-filter #beansSelectFilter class="wide-filter-input left-margin-only"
                [disabled]="editNewRoast >= 0"
                [allOptions]="filterInfo?.coffees?.vals"
                [initialValue]="filters?.coffees"
                objectType="coffees"
                placeholder="Beans"
                (selectChanged)="selectChanged('coffees', $event)">
            </app-select-filter>
            }
            @if (filterInfo?.blends?.vals?.length) {
            <app-select-filter #blendsSelectFilter class="wide-filter-input left-margin-only"
                [disabled]="editNewRoast >= 0"
                [allOptions]="filterInfo?.blends?.vals"
                [initialValue]="filters?.blends"
                objectType="blends"
                placeholder="Blends"
                (selectChanged)="selectChanged('blends', $event)">
            </app-select-filter>
            }
        </div>
        <div class="filter-parts left-margin-only" style="width: 258px;">
            <!-- label -->
            <mat-form-field>
                <mat-label i18n="generic$$Title@@Title">Title</mat-label>
                <input [(ngModel)]="filters.label" (ngModelChange)="labelChanged()"
                    [disabled]="editNewRoast >= 0"
                    type="text" name="label" matInput class="filter-input"
                    i18n-placeholder="generic$$Title@@Title" placeholder="Title">
            </mat-form-field>
        </div><br />
        <div class="filter-parts late-wrap-part">
            <!-- amount -->
            @if (filterInfo?.amount?.min || filterInfo?.amount?.max) {
            <div class="left-margin-only">
                <div class="readable-query"><span i18n="reports$$report heading volume in@@Weight">Weight</span> ({{mainUnit}})@if (readableQuery.get('amount'); as query) {: {{query}}}
                </div>
                <app-range-filter #amountRangeFilter
                    [disabled]="editNewRoast >= 0"
                    [emptyIsZero]="true"
                    [step]="filterInfo.amount.step"
                    [nrDigits]="3"
                    [floor]="filterInfo.amount.min"
                    [ceil]="filterInfo.amount.max"
                    (rangeChange)="rangeChanged('amount', $event)">
                </app-range-filter>
            </div>
            }
            <!-- end_weight -->
            @if (filterInfo?.end_weight?.min || filterInfo?.end_weight?.max) {
            <div class="left-margin-only">
                <div class="readable-query"><span i18n="reports$$report heading volume out@@Yield">Yield</span> ({{mainUnit}})@if (readableQuery.get('end_weight'); as query) {: {{query}}}
                </div>
                <app-range-filter #endweightRangeFilter
                    [disabled]="editNewRoast >= 0"
                    [emptyIsZero]="true"
                    [step]="filterInfo.end_weight.step"
                    [nrDigits]="3"
                    [floor]="filterInfo.end_weight.min"
                    [ceil]="filterInfo.end_weight.max"
                    (rangeChange)="rangeChanged('end_weight', $event)">
                </app-range-filter>
            </div>
            }
        </div>
        <br />
        <!-- other attributes -->
        @if (filterOptions?.length) {
        <div class="filter-parts">
            @for (fo of filterOptions; track fo?.label) {
            <div
                class="attribute-filter-container">
                <mat-form-field class="wide-filter-input select-attr left-margin-only wide-filter-input-with-button">
                    <mat-select [disabled]="editNewRoast >= 0" [ngModel]="fo.label"
                        #otherAttrSelect
                        (selectionChange)="filterOptionChanged($index, $event)"
                        [compareWith]="utils.compareObjectsFn"
                        matInput name="filterOption-{{$index}}"
                        placeholder="Attribute" i18n-placeholder="roasts$$filter, label for selecting an attribute such as 'drop temperature' for filtering@@Attribute">
                        @if (allFilterOptions?.length >= 3) {
                        <mat-option>
                            <ngx-mat-select-search ngModel name="attributeSelect"
                                (ngModelChange)="filteredFilterOptions = utils.filterObjects($event, allFilterOptions)"
                                [showToggleAllCheckbox]="false"
                                placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                noEntriesFoundLabel="">
                            </ngx-mat-select-search>
                        </mat-option>
                        }
                        @for (attr of filteredFilterOptions; track attr?.label) {
                        <mat-option [value]="attr.label">
                            {{attr.translatedLabel}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <button [disabled]="editNewRoast >= 0"
                    (click)="deleteFilter($index)" mat-icon-button type="button" class="primary-element delete-attribute-button">
                    <mat-icon class="smallish">delete</mat-icon>
                </button>
                @if (getAttrFromName(fo.label); as attr) {
                @if (isLoadingTemplates && attr === 'template') {
                <mat-spinner diameter="20"></mat-spinner>
                }
                @if (filterInfo) {
                @if (fo.type === 'select'&& show[$index]) {
                <div>
                    <app-select-filter #filterui class="wide-filter-input left-margin-only right-padding"
                        [disabled]="editNewRoast >= 0"
                        [allOptions]="filterInfo[attr]?.vals ?? []"
                        [objectType]="attr"
                        [placeholder]="fo.label"
                        (selectChanged)="selectChanged(attr, $event)">
                    </app-select-filter>
                </div>
                }
                @if (fo.type === 'checkbox'&& show[$index]) {
                <div>
                    <!-- [allOptions]="valsToString(filterInfo[attr]?.vals ?? [], attr)" -->
                    <app-checkbox-filter #filterui class="left-margin-only"
                        [disabled]="editNewRoast >= 0"
                        [margin_top]="-15"
                        [showAllowNull]="attr !== 'discarded' && attr !== 'reconciled'"
                        [allOptions]="filterInfo[attr]?.vals ?? []"
                        [unit]="filterInfo[attr]?.unit"
                        [placeholder]="fo.label"
                        (selectChanged)="checkboxChanged(attr, $event)">
                    </app-checkbox-filter>
                </div>
                }
                @if (fo.type === 'checkboxandtext'&& show[$index]) {
                <div>
                    <app-checkboxandtext-filter #filterui class="left-margin-only"
                        [disabled]="editNewRoast >= 0"
                        [placeholder]="fo.label"
                        (filterChanged)="checkboxAndTextChanged(attr, $event.checkbox, $event.text)">
                    </app-checkboxandtext-filter>
                </div>
                }
                @if (fo.type === 'range' && show[$index]) {
                <div class="left-margin-only">
                    <div class="readable-query">{{this.filterService.translateAttr(fo.label)}}@if (filterInfo[attr]?.unit && filterInfo[attr].unit !== 's') {
                        ({{filterInfo[attr]?.unit}})
                        }@if (readableQuery.get(attr); as query) {
                        : {{query}}
                        }</div>
                    <app-range-filter #filterui
                        [disabled]="editNewRoast >= 0"
                        [margin_top]="-15"
                        [step]="filterInfo[attr]?.step"
                        [floor]="filterInfo[attr]?.min"
                        [ceil]="filterInfo[attr]?.max"
                        [unit]="filterInfo[attr]?.unit"
                        [showAllowNull]="attr !== 'ID'"
                        [allowNullInit]="false"
                        (rangeChange)="rangeChanged(attr, $event)">
                    </app-range-filter>
                </div>
                }
                }
                }
            </div>
            }
        </div>
        }
        <br />
        <div class="add-and-filter-container">
            <button [disabled]="editNewRoast >= 0" (click)="addFilter()" type="button" class="add-filter-button" mat-icon-button>
                <mat-icon [ngClass]="{'primary-element': !(editNewRoast >= 0)}">add</mat-icon>
            </button>
            <button [disabled]="isLoading || editNewRoast >= 0"
                type="button" mat-raised-button
                class="dofilter-button"
                [ngClass]="{
                    'highlight-button': someFilterHasChanged,
                    'edit-button': someFilterHasChanged,
                    'action-button': !someFilterHasChanged,
                }"
                (click)="doFilter(true)">
                <mat-icon>search</mat-icon>
            </button>
            <!-- load and save filter -->
            <mat-form-field class="filter-field loadsave-field">
                <mat-label i18n="transaction$$Load or save new ...@@Load / Save ...">Load / Save ...</mat-label>
                <mat-select [disabled]="editNewRoast >= 0" matInput name="filterName"
                    [(ngModel)]="filterName" (selectionChange)="filterNameSelected()"
                    panelClass="twoLineOption" panelWidth="null" data-test="slct-filterName"
                    placeholder="Load / Save ..." i18n-placeholder="transaction$$Load or save new ...@@Load / Save ...">
                    <mat-select-trigger>{{filterName}}</mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search ngModel name="filterSelect"
                            (ngModelChange)="filterFilters($event)"
                            placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                            [hideClearSearchButton]="true"
                            [clearSearchInput]=""
                            noEntriesFoundLabel="">
                        </ngx-mat-select-search>
                    </mat-option>
                    @if (isNewFilterLabel) {
                    <mat-option [value]="newFilterLabel" class="twoline-select-with-icon">
                        <span class="select-first-line">{{newFilterLabel}}</span>
                        <mat-icon>file_download</mat-icon>
                    </mat-option>
                    }
                    @for (label of filteredLabels; track label) {
                    <mat-option [value]="label" class="twoline-select-with-icon with-delete-button">
                        <span class="filter-description-container">
                            <div class="filter-description-and-delete">
                                {{label}}
                                <a (click)="deleteExistingFilter($index); $event.stopPropagation()" (keydown.enter)="deleteExistingFilter($index); $event.stopPropagation(); $event.preventDefault()" role="button" [tabindex]="0">
                                    <mat-icon class="smallesticon delete-filter-icon">delete</mat-icon>
                                </a>
                            </div>
                            <div class="filter-description">({{getFilterDescription($index)}})</div>
                        </span>
                        <!-- <mat-icon>file_upload</mat-icon> -->
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        @if (!helptipAddFilterShown && showHelptipAddFilter) {
        <div class="helptip-container">
            <div class="helptip helptip-addfilter" [ngClass]="{'showme': showHelptipAddFilter}">
                <span i18n="generic$$tooltip, add specific filter@@Click here to add more filters">Click here to add more filters</span>&nbsp;<mat-icon class="small-close-icon" (click)="removeHelptip('addfilter')">close</mat-icon>
            </div>
        </div>
        }
    </div>
</div>
}