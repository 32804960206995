import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/util/alert/alert.service';
import { NGXLogger } from 'ngx-logger';
import { LoginChangedService } from 'src/app/modules/frame/services/loginchanged.service';
import { UserService, Role } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Utils } from 'src/app/util/utils';
import { Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';

@Component({
    selector: 'app-login',
    templateUrl: './invited.component.html',
    styleUrls: ['./invited.component.scss']
})
export class InvitedComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loginChangedService: LoginChangedService,
        private alertService: AlertService,
        private logger: NGXLogger,
        private userService: UserService,
        private utils: Utils,
        private dialog: MatDialog,
        private tr: TranslatorService,
    ) { }

    email: string;
    password: string;
    byEmail: string;
    readonly_user: boolean;
    nrExistingObjects: number;
    token: string;
    isNewlyCreated = false;
    loading = false;
    returnUrl: string;
    returnParam: unknown;
    returnQuery: unknown;

    doblur = false;

    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        // reset login status
        this.userService.logout();

        // get return url from route parameters or default to '/stores'
        this.returnUrl = '/stores';
        if (this.route.snapshot.queryParams['returnUrl']) {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
            if (this.returnUrl.indexOf('?') !== -1) {
                // e.g. "/add;d=5?data=ChBF"
                const spl = this.returnUrl.split('?');
                this.returnUrl = spl[0];
                this.returnQuery = {};
                const spl2 = spl[1].split('=');
                this.returnQuery[spl2[0]] = decodeURIComponent(spl2[1]);
            }
            if (this.returnUrl.indexOf(';') !== -1) {
                // e.g. "/...;id=5acc9b9421a1231adc9ac349"
                const spl = this.route.snapshot.queryParams['returnUrl'].split(';');
                const spl2 = spl[1].split('=');
                this.returnUrl = spl[0];
                this.returnParam = {};
                this.returnParam[spl2[0]] = spl2[1];
            }
        }
        this.logger.debug('init in LoginComponent ' + this.route.snapshot.queryParams['returnUrl']);

        this.token = this.route.snapshot.params.token;
        if (this.token) {
            // this is called from the link in the invitation email for the given user
            this.isNewlyCreated = this.route.snapshot.params.new && this.route.snapshot.params.new.toString() === '1';

            this.userService.getInvitationInfo(this.token)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true && response.result) {
                            this.email = response.result.email;
                            this.byEmail = response.result.by;
                            this.readonly_user = response.result.readonly_user;
                            this.nrExistingObjects = response.result.existingObjects;
                            // the "add" button is disabled bc no password is set
                            // this is still true even if a password manager added something
                            // this sets it arbitrary, the pass man will update it
                            // users without pass man will might be confused now, but prob
                            // still better than a disabled button
                            this.password = 'abc';

                        } else {
                            this.utils.handleError('Invitation failed. Did you use the invitation link?', response.error);
                            this.userService.logout();
                            this.router.navigate(['/login']);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Invitation failed. Did you use the invitation link?', error);
                        this.userService.logout();
                        this.router.navigate(['/login']);
                    }
                });
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.doblur = true;
        }, 100);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    doLogin(setPasswordWithToken: boolean): void {
        this.loading = true;
        this.userService.login(this.email, this.password, setPasswordWithToken ? this.token : undefined)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: resp => {
                    this.logger.trace('received login', resp);
                    // login successful if there's a jwt token in the response
                    // {"success": true, "result": {user: {user_id: string,nickname: string,
                    //      account: string,admin: boolean,token: string}}, "error": ""}
                    if (resp?.success === true && resp.result?.user?.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        let role: Role = 0;
                        if (resp.result.user.admin === true) {
                            role += Role.Admin;
                        }
                        delete resp.result.user.admin;
                        this.userService.setRole(role);

                        if (!resp.result.user.nickname) {
                            resp.result.user.nickname = '';
                        }

                        const user = resp.result.user;
                        this.userService.storeCurrentUser(user);

                        this.userService.switchAccountOfUser(this.token)
                            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                            .subscribe({
                                next: response => {
                                    if (response.success === true && response.result) {
                                        this.logger.debug('invitation successful');
                                        this.alertService.success(this.tr.anslate('Successfully updated'));
                                        if (response.result) {
                                            // update info locally
                                            user.readonly = response.result.readonly;
                                            user.other_accounts = response.result.other_accounts;
                                            if (response.result.readonly_account_idx >= 0) {
                                                user.readonly_account_idx = response.result.readonly_account_idx;
                                                // forward using account=<readonly account>
                                                this.returnParam = {};
                                                this.returnParam['account'] = response.result.readonly_account_idx;
                                                this.returnParam['showAccountHint'] = 1;
                                            } else {
                                                user.readonly_account_idx = undefined;
                                                // forward normally
                                                this.returnParam = {};
                                            }
                                            this.userService.storeCurrentUser(user);
                                        }
                                        this.logger.debug('forwarding to', this.returnUrl, 'with param', this.returnParam);
                                        this.loginChangedService.changeLoginStatus(true);
                                        this.logger.debug('login successful for', this.email);
                                        this.router.navigate([this.returnUrl, this.returnParam || {}], { queryParams: this.returnQuery });
                                    } else {
                                        this.utils.handleError('Invitation failed. Did you use the invitation link?', response.error);
                                        this.loading = false;
                                        this.userService.logout();
                                        this.router.navigate(['/login']);
                                    }
                                },
                                error: error => {
                                    this.utils.handleError('Invitation failed. Did you use the invitation link?', error);
                                    this.loading = false;
                                    this.userService.logout();
                                    this.router.navigate(['/login']);
                                }
                            });
                    } else {
                        this.utils.handleError('server error', resp && resp.error ? resp.error : '');
                        this.loading = false;
                        this.userService.logout();
                        this.router.navigate(['/login']);
                    }
                },
                error: error => {
                    this.utils.handleError('serer error', error);
                    this.loading = false;
                }
            });
    }

    login(setPasswordWithToken = false): void {
        this.logger.trace('loginSetPassword() called in invited.component');

        if (!this.token) {
            this.utils.handleError('Invitation failed. Did you use the invitation link?', 'no token');
            return;
        }

        // if it is not a new account (!setPasswordWithToken), not a readonly user, and there are some objects, ask for confirmation
        if (!setPasswordWithToken && !this.readonly_user && this.nrExistingObjects > 0) {
            // inform user that they will lose access to their own data / account
            const dialogRef = this.dialog.open(YesNoDialogComponent, {
                closeOnNavigation: true,
                data: {
                    text: this.tr.anslate('You will LOSE ACCESS to all of your OWN existing data - you have {{nrExistingObjects}} objects', { nrExistingObjects: this.nrExistingObjects }) + ' - '
                        + this.tr.anslate('If you do not want this, cancel and ask the person who invited you to use another of your email addresses.')
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result !== true) {
                    // user cancelled
                    return;
                }
                this.doLogin(setPasswordWithToken);
            });
        } else {
            this.doLogin(setPasswordWithToken);
        }
    }
}
