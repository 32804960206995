import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterOptions, StandardService } from 'src/app/util/services/standard.service';

@Injectable()
export class GraphService {

    constructor(
        private http: HttpClient,
        private standardService: StandardService,
    ) { }

    public energyUnitSource = new Subject<string>();
    energyUnitChanged$ = this.energyUnitSource.asObservable();
    energyUnitChanged(energyUnit: string): void {
        this.energyUnitSource.next(energyUnit);
    }

    /**
     * @param type type of data to get, e.g. 'store', 'coffee', roast'
     * @param params additional query parameters to send, e.g. {'maxNumberOfMonths': '12'}
     */
    getGraphData(type: string, filters: FilterOptions, options: { maxNumberOfMonths: number }): Observable<{ success: boolean, result: unknown[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/statistics/' + type;
        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.standardService.addFilterOptions(httpParams, filters);
        }
        if (options.maxNumberOfMonths) {
            httpParams = httpParams.set('maxNumberOfMonths', options.maxNumberOfMonths.toString());
        }
        return this.http.get<{ success: boolean, result: unknown[], error: string }>(url, { params: httpParams });
    }


}
