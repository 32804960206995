<mat-dialog-content>
    <mat-list role="list">
        @for (roast of data?.roasts; track roast?.hr_id) {
        <mat-list-item role="listitem" style="margin-bottom: 15px;">
            <div matListItemTitle style="margin-bottom: -5px;">
                <span class="roasts-dialog-hrid"><a [routerLink]="['/roasts', {id: roast.hr_id}]" (click)="closeDialog()">{{roast.hr_id}}</a></span>

                @if (roast.batch_prefix) {
                <span class="littlespaceleft">{{roast.batch_prefix}}</span>
                }
                @if (Number.isFinite(roast.batch_number)) {
                <span [ngClass]="{'littlespaceleft': !roast.batch_prefix}">{{Math.round(roast.batch_number)}}</span>
                }
                @if (Number.isFinite(roast.batch_pos)) {
                <span class="littlespaceleft">({{roast.batch_pos | number:'1.0-0'}})</span>
                }

                <span class="roasts-dialog-date">, {{roast.date.toString() | date:'shortDate'}} {{roast.date.toString() | date:'HH:mm'}}</span>
            </div>

            <div matListItemLine>
                <span class="roasts-dialog-amount littlespaceleft littlespaceright">{{roast.amount * utils.getUnitFactor(data.mainUnit) | number:'1.0-1'}}{{data.mainUnit}}</span>
                <span class="roasts-dialog-label">{{roast.label}}</span>

                @if (roast.roast_id) {
                <a [href]="this.utils.sanitize('artisan://roast/' + roast.roast_id)"
                    target="_blank" rel="noopener" class="roasts-dialog-artisanicons"
                    matTooltip="Open in Artisan" i18n-matTooltip="roasts$$tooltip load as template in Artisan app@@Open in Artisan"
                    (click)="closeDialog()">
                    <mat-icon class="helpicon" svgIcon="artisan-blue"></mat-icon>
                </a>
                <a [href]="this.utils.sanitize('artisan://template/' + roast.roast_id)"
                    target="_blank" rel="noopener" class="roasts-dialog-artisanicons"
                    matTooltip="Open in Artisan as background template (Artisan >2.4.4)" i18n-matTooltip="roasts$$tooltip load as background template in Artisan app@@Open in Artisan as background template (Artisan >2.4.4)"
                    (click)="closeDialog()">
                    <mat-icon class="helpicon" svgIcon="artisan-grey"></mat-icon>
                </a>
                }
            </div>

            <!-- @if (roast.coffee?.label && (roast.coffee.internal_hr_id || roast.coffee.hr_id)) {<a [routerLink]="['/coffees', {id: roast.coffee.hr_id ? roast.coffee.hr_id : 'C' + roast.coffee.internal_hr_id}]">{{roast.coffee?.label}}</a>}<span *ngIf="roast['blend']?.label" [ngClass]="{'littlespaceright': roast.coffee}">{{roast['blend']?.label}}</span> -->
        </mat-list-item>
        }
    </mat-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="action-button" autofocus cdkFocusInitial mat-raised-button type="submit" mat-dialog-close tabindex="0">
        <mat-icon>done</mat-icon>
    </button>
</mat-dialog-actions>