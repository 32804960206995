import { Component, OnInit, OnDestroy, ElementRef, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { InvitedUserInfo, UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { map, takeUntil, throttleTime } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { Reminder } from 'src/app/models/Reminder';
import { RemindersService } from './reminder.service';
import { environment } from 'src/environments/environment';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { StandardService } from 'src/app/util/services/standard.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ReminderlogtableComponent } from './reminderlogtable.component';
// import { ReminderComponent } from './reminder.component';
import throttle from 'lodash-es/throttle';
import { IdleService } from 'src/app/util/services/idle.service';
import { PageEvent } from '@angular/material/paginator';
import { DateTime } from 'luxon';
import { SplitGutterInteractionEvent } from 'angular-split';

@Component({
    selector: 'app-reminders',
    templateUrl: './reminders.component.html',
    styleUrls: ['./reminders.component.scss'],
    providers: [IdleService],
})
export class RemindersComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private standardService: StandardService,
        private logger: NGXLogger,
        private utils: Utils,
        private remindersService: RemindersService,
        private breakpointObserver: BreakpointObserver,
        private idleService: IdleService,
    ) {
    }
    filteredObjects: Reminder[];
    // objectCount = 0;
    // filteredObjectCount = 0;
    maxObjectCount = 0;
    pageSize = 5;
    pageIndex = 0;
    editMode = -1;
    isNew = -1;
    idToHighlight: string;

    machines: { label: string, cnt: number }[];

    currentUser: UserType;
    mainUnit: UnitSystemType = 'kg';
    energyUnit = 'BTU';
    gasUnit = 'kg';
    readOnly = false;
    isDarkmode = false;
    loading = false;
    loadingTimer: ReturnType<typeof setTimeout>;
    targetUsers: InvitedUserInfo[];

    // keeps track of expanded items for silentReload
    expandeds: boolean[] = [];
    idleServiceSub: Subscription;
    initialIdleServiceCall = false;

    defaultSplitSizes = [50, 50];
    splitSizes = this.defaultSplitSizes;
    readonly splitSizesLocalStorageName = 'reminders-split-size';

    loadSubscription = new Subject<string>();
    private ngUnsubscribe = new Subject();

    isLarge$: Observable<boolean>;
    isSmall$: Observable<boolean>;

    @ViewChildren('reminderElem', { read: ElementRef }) reminderElems: QueryList<ElementRef>;
    // @ViewChildren('reminderElem') reminderComps: QueryList<ReminderComponent>;
    @ViewChild('logTable') logTable: ReminderlogtableComponent;


    ngOnInit(): void {
        if (localStorage.getItem(this.splitSizesLocalStorageName)) {
            this.splitSizes = JSON.parse(localStorage.getItem(this.splitSizesLocalStorageName));
        } else {
            this.splitSizes = this.defaultSplitSizes;
        }

        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        if (this.currentUser?.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        this.energyUnit = this.currentUser.energy_unit || 'BTU';
        this.gasUnit = this.currentUser.gas_unit || 'kg';

        this.remindersService.allTasks.forEach(task => this.remindersService.allTaskLabels.add(task.label));

        this.pageSize = this.userService.getPageSize('reminders', this.pageSize);

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm)
            );

        this.loadSubscription
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((reason: string) => {
                this.filteredObjects = undefined;
                // this.filteredObjectCount = 0;
                // this.objectCount = 0;
                this.maxObjectCount = 0;
                this.editMode = -1;
                this.isNew = -1;
                this.pageIndex = 0;

                this.utils.resetShownNotifications();

                this.idToHighlight = this.route.snapshot.params.id;
                if (this.idToHighlight) {
                    // have a direct link; need to get the corresponding page
                    this.getPageForId(this.idToHighlight);
                } else {
                    this.getPage();
                }

                // needed for editing
                this.getAllMachines();
                this.getAllUsers();

                if (reason === 'reload') {
                    this.logTable?.reload();
                }
            });

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && (e as NavigationEnd).url.indexOf('reminders') >= 0) {
                    // only pass and debounce interesting events
                    this.loadSubscription.next('reload');
                }
            });

        this.isLarge$ = this.breakpointObserver.observe('(min-width: 900px)')
            .pipe(map(result => result.matches));

        if (this.currentUser) {
            this.loadSubscription.next('init');
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.loadingTimer);
    }

    /**
     * Reloads data from the server and tries not to visually change anything
     * (but the changed data). Does nothing in edit mode.
     */
    silentReload(): void {
        if (this.initialIdleServiceCall) {
            return;
        }
        if (this.editMode === -1) {
            // const rcs = this.reminderComps?.toArray();
            // this.expandeds = rcs.map(rc => rc.isExpanded);
            this.idToHighlight = this.route.snapshot.params.id;
            if (this.idToHighlight) {
                // have a direct link; need to get the corresponding page
                this.getPageForId(this.idToHighlight, false, true);
            } else {
                this.getPage(false, true);
            }
        }
    }

    getAllUsers(): void {
        this.userService.getAdditionalInfo('OTHERUSERS')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.targetUsers = response.result.otherUsers;
                        if (this.targetUsers?.length) {
                            this.targetUsers.unshift({ _id: this.currentUser.user_id?.toString(), nickname: this.currentUser.nickname });
                        }
                    } else {
                        this.utils.handleError('user data could not be downloaded', response.error);
                        if (!this.targetUsers?.length) {
                            this.targetUsers = [];
                        }
                    }
                },
                error: error => {
                    this.utils.handleError('user data could not be downloaded', error);
                    if (!this.targetUsers?.length) {
                        this.targetUsers = [];
                    }
                }
            });
    }

    getAllMachines(cb?: () => void): void {
        this.remindersService.getAllMachines()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.machines = response.result;
                    } else {
                        this.utils.handleError('error retrieving all machines', response.error);
                        if (!this.machines?.length) {
                            this.machines = [];
                        }
                    }
                    if (typeof cb === 'function') {
                        cb();
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all machines', error);
                    if (!this.machines?.length) {
                        this.machines = [];
                    }
                    if (typeof cb === 'function') {
                        cb();
                    }
                }
            });
    }

    // add known reminder labels to the list used for autocomplete
    addTasks(reminders: Reminder[]): void {
        for (let i = 0; i < reminders?.length; i++) {
            const reminder = reminders[i];
            // if (this.remindersService.allTaskLabels.has(reminder.label)) {
            //     // update list entry
            //     for (let t = 0; t < this.remindersService.allTasks.length; t++) {
            //         const task = this.remindersService.allTasks[t];
            //         if (task.label === reminder.label) {
            //             task.interval_unit = undefined;
            //             // task.example = reminder;
            //             break;
            //         }
            //     }
            // } else {
            if (!this.remindersService.allTaskLabels.has(reminder.label)) {
                // add to list
                this.remindersService.allTaskLabels.add(reminder.label);
                this.remindersService.allTasks.unshift({
                    label: reminder.label,
                    interval_unit: reminder.conditions?.[0]?.interval_unit,
                    interval: reminder.conditions?.[0]?.interval,
                    on_date: reminder.conditions?.[0]?.on_date,
                });
                if (reminder.conditions?.[1]) {
                    this.remindersService.allTasks[0].interval_unit2 = reminder.conditions[1].interval_unit;
                    this.remindersService.allTasks[0].interval2 = reminder.conditions[1].interval;
                    this.remindersService.allTasks[0].on_date2 = reminder.conditions[1].on_date;
                }
            }

            // calc current status for sorting and display
            this.utils.calcExpiredUnit(reminder, this.mainUnit, this.energyUnit, this.gasUnit);
        }

        // reminders.sort((r1, r2) => r1.expiredForSort - r2.expiredForSort);
    }

    pagingChanged($event: PageEvent): void {
        if (this.pageSize !== $event.pageSize) {
            this.pageSize = $event.pageSize;
            this.userService.setPageSize('reminders', this.pageSize);
        }
        this.pageIndex = $event.pageIndex;
        this.idToHighlight = undefined;
        this.isNew = -1;
        this.editMode = -1;
        this.getPage();
    }

    getPage(addNewEntry = false, silent = false): void {
        this.loadingTimer = setTimeout(() => {
            this.loading = true;
        }, 300);
        this.logger.debug('loading reminders');

        this.standardService.getPage<Reminder>('reminders', this.pageSize, this.pageIndex, 'lastmodified', false)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.readOnly = this.userService.isReadOnly();
                    if (response.success === true) {
                        // this.maxObjectCount = Math.max(response.count || 0, this.maxObjectCount);
                        this.maxObjectCount = response.count;
                        this.filteredObjects = this.utils.dateifyReminders(response.result);
                        this.addTasks(this.filteredObjects);
                        if (addNewEntry) {
                            this.add();
                        }
                        // auto update every few minutes if no user interaction
                        if (this.filteredObjects?.length) {
                            // short update interval if there is a reminder with a condition that is less than 1 hour
                            const haveShortTermReminder = (this.filteredObjects.map(r => r.conditions?.reduce((prev, cur) => Math.min(prev, cur.interval_done_forsort), Number.MAX_SAFE_INTEGER)).some(done => done < 1/24) ?? Number.MAX_SAFE_INTEGER);
                            const reloadIntervalSeconds = haveShortTermReminder ? 2 * 60 : 30 * 60;
                            this.idleService.setIdleAfterSeconds(reloadIntervalSeconds);
                            // idleService.setIdleAfterSeconds(10 * 60);
                            if (this.idleServiceSub) {
                                this.idleServiceSub.unsubscribe();
                            }
                            this.initialIdleServiceCall = true;
                            this.idleServiceSub = this.idleService.idle$
                                .pipe(takeUntil(this.ngUnsubscribe))
                                .subscribe(() => this.silentReload());
                            this.initialIdleServiceCall = false;
                            // setTimeout(() => {
                            //     const rcs = this.reminderComps?.toArray();
                            //     if (rcs) {
                            //         this.expandeds = rcs.map(rc => rc.isExpanded);
                            //     }
                            // }, 100);
                        } else {
                            this.idleService.setIdleAfterSeconds(undefined);
                        }
                    } else {
                        if (silent) {
                            // stop auto reloading on error (e.g. unauthorized)
                            this.idleServiceSub.unsubscribe();
                        } else {
                            this.utils.handleError('error retrieving all reminders', response.error);
                        }
                        if (!this.machines?.length) {
                            this.machines = [];
                        }
                    }
                    clearTimeout(this.loadingTimer);
                    this.loading = false;
                },
                error: error => {
                    if (silent) {
                        // stop auto reloading on error (e.g. unauthorized)
                        this.idleServiceSub.unsubscribe();
                    } else {
                        this.utils.handleError('error retrieving all reminders', error);
                    }
                    if (!this.filteredObjects?.length) {
                        this.filteredObjects = [];
                    }
                    clearTimeout(this.loadingTimer);
                    this.loading = false;
                }
            });
    }

    scrollToReminder(idx: number) {
        const rls = this.reminderElems?.toArray();
        if (rls?.[idx]) {
            rls[idx].nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }

    getPageForId(id: string, openForEdit = false, silent = false): void {
        this.loadingTimer = setTimeout(() => {
            this.loading = true;
        }, 300);
        this.logger.debug('loading reminders');

        // need to get the corresponding page from the server
        this.standardService.getPageForId<Reminder>('reminders', this.pageSize, id, 'lastmodified', false)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.readOnly = this.userService.isReadOnly();
                    if (response.success === true) {
                        // this.maxObjectCount = Math.max(response.count || 0, this.maxObjectCount);
                        this.maxObjectCount = response.count;
                        this.filteredObjects = this.utils.dateifyReminders(response.result.objects);
                        this.addTasks(this.filteredObjects);
                        if (!this.filteredObjects || this.filteredObjects.length === 0) {
                            // object with given id not found
                        } else {
                            // find entry with id to open it; do this after addTasks which sorts the reminders
                            let foundIdx: number;
                            for (let i = 0; i < this.filteredObjects?.length; i++) {
                                const obj = this.filteredObjects[i];
                                if (obj.hr_id === id || obj._id === id) {
                                    if (openForEdit) {
                                        this.editMode = i;
                                    }
                                    foundIdx = i;
                                    setTimeout(() => {
                                        this.scrollToReminder(foundIdx);
                                    }, 0);
                                    break;
                                }
                            }
                            this.isNew = foundIdx ?? -1;
                            this.pageIndex = response.result.pageIndex;
                        }
                    } else if (!silent) {
                        this.utils.handleError('error retrieving all reminders', response.error);
                    }
                    clearTimeout(this.loadingTimer);
                    this.loading = false;
                },
                error: error => {
                    if (!silent) {
                        this.utils.handleError('error retrieving all reminders', error);
                    }
                    clearTimeout(this.loadingTimer);
                    this.loading = false;
                }
            });
    }

    add(): void {
        if (this.readOnly) { return; }

        if (!this.filteredObjects) {
            this.filteredObjects = [];
        }
        // const label = this.tr.anslate('NEW');

        this.editMode = 0;
        this.isNew = 0;
        const reminder = new Reminder();
        // reminder.label = label;
        this.filteredObjects.unshift(reminder);
        this.expandeds.unshift(true);
        this.maxObjectCount += 1;
    }

    addCancelled(): void {
        this.filteredObjects.splice(0, 1);
        this.expandeds.splice(0, 1);
        this.isNew = -1;
        this.maxObjectCount -= 1;
    }

    /**
     * Called if a reminder has been deleted.
     * Updates list of ReminderLog entries since source links might have been removed.
     * @param _evnt ignored
     * @param idx index of deleted entry within filteredObjects
     */
    deleted(_evnt: unknown, idx: number): void {
        if (idx >= 0 && idx < this.filteredObjects.length) {
            this.filteredObjects.splice(idx, 1);
            this.expandeds.splice(idx, 1);
            this.maxObjectCount -= 1;
            this.isNew = -1;
            if (!this.filteredObjects.length && this.pageIndex > 0) {
                // if the last of this page was deleted, go back one page
                this.pageIndex -= 1;
                this.getPage();
            } else if (this.pageIndex === 0 && this.maxObjectCount === this.pageSize) {
                // if there are only pageSize elements left, we need to reload the first page
                // since otherwise the pager is not displayed and some elements are hidden
                this.getPage();
            }
            this.logChanged();
        }
    }

    updatedSnoozedReminder = throttle(this.updatedSnoozedReminderThrottled, 10000, { 'trailing': false });

    updatedSnoozedReminderThrottled(reminder: Reminder, idx: number): void {
        if (reminder.snooze_until && reminder.snooze_until < DateTime.now() && idx >= 0 && idx < this.filteredObjects.length) {
            // snooze time of reminder expired, reload to receive the notification
            this.standardService.getOne<Reminder>('reminders', reminder._id)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.filteredObjects.splice(idx, 1, this.utils.dateifyReminderLogs([response.result])?.[0] || reminder);
                            this.utils.calcExpiredUnit(this.filteredObjects[idx], this.mainUnit, this.energyUnit, this.gasUnit);
                        }
                    },
                    error: () => {
                        // fail silently
                    }
                });
            return;
        }
    }

    updated(reminder: Reminder, idx: number): void {
        if (idx >= 0 && idx < this.filteredObjects.length) {
            this.filteredObjects.splice(idx, 1, reminder);
            this.utils.calcExpiredUnit(this.filteredObjects[idx], this.mainUnit, this.energyUnit, this.gasUnit);

            // make sure the correct new one is still expanded
            const updatedReminderId = this.filteredObjects[idx].internal_hr_id;

            this.filteredObjects.sort((r1, r2) => r1.expiredForSort - r2.expiredForSort);

            let foundIdx: number;
            for (let r = 0; r < this.filteredObjects.length; r++) {
                if (updatedReminderId === this.filteredObjects[r].internal_hr_id) {
                    foundIdx = r;
                    break;
                }
            }
            if (foundIdx !== idx) {
                this.expandeds[foundIdx] = true;
                this.expandeds[idx] = false;
                this.scrollToReminder(foundIdx);
                this.isNew = -1;
            }

            this.updatedSnoozedReminder(reminder, idx);
        }
    }

    logChanged(waitTime?: number): void {
        this.logTable?.getReminderLog(undefined, waitTime);
    }

    // reload all machines and reminders and edit the one with the given index
    machinesChanged(idx: number): void {
        this.getAllMachines(() => {
            if (typeof idx !== 'undefined' && this.filteredObjects?.[idx]?._id) {
                this.getPageForId(this.filteredObjects[idx]._id, true);
            } else {
                this.getPage(this.isNew === 0);
            }
            this.logChanged();
        });
    }

    onDragEnd(e: SplitGutterInteractionEvent): void {
        this.splitSizes = e.sizes as number[];
        localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
    }

    onGutterClick(e: SplitGutterInteractionEvent): void {
        if (e.sizes[0] === '*' || e.sizes[0] < 50) {
            this.splitSizes = [100, 0];
        } else {
            this.splitSizes = [0, 100];
        }
        localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
    }
}
