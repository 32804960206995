import { ActivatedRoute, Router } from '@angular/router';
import { Certification } from 'src/app/models/Certification';
import { DialogService } from 'src/app/modules/ui/dialog/dialog.service';
import { Component, OnInit, Input, ViewChild, AfterViewInit, EventEmitter,  OnDestroy, Output } from '@angular/core';
import { Blend } from 'src/app/models/Blend';
import { StandardService } from 'src/app/util/services/standard.service';
import { Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ObjectChangedService } from 'src/app/util/services/objectchanged.service';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';
import { Coffee } from 'src/app/models/Coffee';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { ReplaceAllInBlendsComponent } from './replace-all-in-blends.component';
import { BlendService } from './blend.service';
import unionwith from 'lodash-es/unionWith';
import { Utils2 } from 'src/app/util/utils2';
import { cloneDeep } from 'lodash-es';

@Component({
    selector: 'app-blend',
    templateUrl: './blend.component.html',
    styleUrls: ['./blend.component.scss']
})
export class BlendComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private standardService: StandardService,
        private userService: UserService,
        private objectChangedService: ObjectChangedService,
        public tr: TranslatorService,
        public utils: Utils,
        private utils2: Utils2,
        private dialogService: DialogService,
        private alertService: AlertService,
        private dialog: MatDialog,
        private logger: NGXLogger,
        private route: ActivatedRoute,
        private blendService: BlendService,
        private router: Router,
    ) { }

    hiddenProperties = ['updated_at', 'updated_by', '__v', '__t', 'tags', 'refs'];
    fixedProperties = ['_id', 'hr_id', 'created_at', 'created_by', 'deleted', 'internal_hr_id', 'owner'];

    @Output() blendStockChanged = new EventEmitter<Blend>();

    @Input() currentUser: UserType;
    currency = 'EUR';
    @Input() readOnly = false;
    @Input() editMode = -1;
    @Input() isNew = -1;
    @Input() index: number;
    @Input() idToHighlight: string;

    showstockfrom: string[] | 'all' = 'all';
    // next ignore: getter and setter would be of slightly different type which is not possible
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('showstockfrom') set _showstockfrom(ssf: { _id: string }[] | 'all') {
        this.showstockfrom = ssf === 'all' ? 'all' : ssf.map(ssf => ssf._id);
        this.recalcStocks();
    }

    isOrganic = false;
    haveOrganicAndNonOrganic = false;

    private _blend: Blend;
    get blend(): Blend { return this._blend; }
    @Input() set blend(b: Blend) {
        this._blend = b;
        this.recalcStocks();
        this.haveOrganicAndNonOrganic = this.utils.haveOrganicAndNonOrganic(this._blend?.ingredients);
    }
    @Input() blends: Blend[];
    @Input() blendsCount: number;
    @Input() showStockPlaceholder = true;

    isExpanded = false;
    replaceIngredientsOpen = false;
    isSaving = false;

    isDarkmode = false;

    coffees: Coffee[];
    coffeesLoaded = false;
    // list of replacement coffees (all but the to-be-replaced coffee)
    replCoffees: Coffee[][] = [];
    replIngHasChanged = false;
    // list of coffees filtered by the dropdown filter
    filteredCoffees: Coffee[][] = [];

    // used to undo a replacement change by pressing the cancel button
    beforeReplIngBlend: Blend;
    // used to undo a replacement change using the shortcut icon
    undoBlend: Blend;

    // stock and value
    sav: { amountstr: string, value: number, amount: number, replAmountstr: string, replValue: number, replAmount: number };
    // amountstr: string;
    // maxstock: number;
    // value: number;
    mainUnitSingular = 'kg';

    blendcopy: Blend;

    // after an image changed, need to wait for the local server to reload; only for localhost setup
    waitForLocalServer = false;

    notes: string;
    notesChanged: Subject<string> = new Subject<string>();

    commonCerts: Certification[] = [];
    // num: number[] = [];
    // denom: number[] = [];

    Enumerations = Enumerations;

    @ViewChild(MatExpansionPanel) expPanel: MatExpansionPanel;

    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser) {
            this.isDarkmode = this.userService.isDarkModeEnabled();
            this.userService.darkmodeMode$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm)
                );
            if (this.currentUser.account) {
                this.currency = this.currentUser.account.currency || 'EUR';
            }
            if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
                this.mainUnitSingular = 'lb';
            }
        }

        if (this.editMode === this.index && typeof this.blendcopy === 'undefined' && this.blend) {
            // don't notify parent - command comes from parent anyway (and would reset isNew flag)
            this.edit(false);
        }

        this.notesChanged.pipe(
            debounceTime(4000),
            distinctUntilChanged(),
            throttleTime(environment.RELOADTHROTTLE),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.saveNotes();
            }
            );
    }

    ngAfterViewInit(): void {
        if (this.idToHighlight && this.blend &&
            (this.blend._id === this.idToHighlight || this.blend.hr_id === this.idToHighlight || (this.blend.label && this.blend.label === this.idToHighlight)) &&
            !this.expPanel.expanded) {

            setTimeout(() => {
                this.expPanel.open();
            });
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    recalcStocks(): void {
        if (this.blend) {
            this.notes = this.blend.notes;
            if (this.blend.ingredients) {
                for (const ing of this.blend.ingredients) {
                    if (ing?.coffee) {
                        // calculate current stock per ingredient
                        const stk = this.utils.getCoffeeStock(ing.coffee, this.showstockfrom);
                        ing.coffee.yearLabel = this.utils.createBeansYearLabel(ing.coffee);
                        ing.coffee['totalStock'] = stk;
                        ing.coffee['totalStockStr'] = this.utils.formatAmount(stk, undefined, this.currentUser?.unit_system, 1);
                    }
                    if (ing?.replace_coffee) {
                        // calculate stock for each replacement ingredient
                        const stk = this.utils.getCoffeeStock(ing.replace_coffee, this.showstockfrom);
                        ing.replace_coffee.yearLabel = this.utils.createBeansYearLabel(ing.replace_coffee);
                        ing.replace_coffee['totalStock'] = stk;
                        ing.replace_coffee['totalStockStr'] = this.utils.formatAmount(stk, undefined, this.currentUser?.unit_system, 1);
                    }
                }
            }

            // calculate total max stock of this blend
            this.sav = this.utils.getBlendStockAndValue(this.blend, this.showstockfrom, this.currentUser?.unit_system, undefined);
            this.blend.curStock = this.sav.replAmount;
            // this.amountstr = this.sav.amountstr;
            // this.value = this.sav.value;
            // this.maxstock = this.sav.stock;

            this.updateCertifications();
        }
    }

    updateCertifications(): void {
        this.commonCerts = this.utils.calcCommonCerts(this.blend);

        this.isOrganic = this.utils.isOrganicBlend(this.blend);
    }

    getAllCoffees(alsoForReplacementIngredients = false): void {
        this.coffeesLoaded = false;
        this.standardService.getAll<Coffee>('coffees')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.coffees = this.utils.dateifyCoffees(response.result);
                        for (let i = 0; i < this.blend.ingredients?.length; i++) {
                            if (this.blend.ingredients[i]?.coffee?.hidden) {
                                // need to add this current coffee since hidden coffees are not retrieved by getAll
                                this.coffees.unshift(this.blend.ingredients[i].coffee);
                            }
                        }
                        for (const coff of this.coffees) {
                            if (coff) {
                                coff.yearLabel = this.utils.createBeansYearLabel(coff);
                            }
                        }
                        if (alsoForReplacementIngredients) {
                            for (let i = 0; i < this.blend.ingredients?.length; i++) {
                                // all but the ingredient itself
                                this.replCoffees[i] = this.coffees.slice().filter(
                                    c => !this.utils.compareObjectsFn(this.blend.ingredients[i].coffee, c)
                                );
                                if (this.blend.ingredients[i]?.replace_coffee?.hidden) {
                                    // need to add this current coffee since hidden coffees are not retrieved by getAll
                                    this.replCoffees[i].unshift(this.blend.ingredients[i].replace_coffee);
                                }
                            }
                            this.filteredCoffees = this.replCoffees.slice();
                        }
                        this.coffeesLoaded = true;

                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                }
            });
    }

    saveNotes(): void {
        if (this.readOnly || this.blend.notes === this.notes) {
            return;
        }

        this.standardService.update<Blend>('blends', { _id: this.blend._id, notes: this.notes, label: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.blend.notes = this.notes;
                    } else {
                        this.utils.handleError('error updating the blend information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the blend information', error);
                }
            });
    }

    replaceIngChanged(): void {
        this.replIngHasChanged = true;
        this.recalcStocks();
    }

    openReplaceIngredients(): void {
        this.getAllCoffees(true);
        this.beforeReplIngBlend = cloneDeep(this.blend);
        this.replaceIngredientsOpen = true;
    }

    closeReplaceIngredients(store: boolean): void {
        if (store) {
            if (this.replIngHasChanged) {
                this.standardService.update<Blend>('blends', { _id: this.blend._id, label: this.blend.label, ingredients: this.blend.ingredients })
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (!response || response.success === true) {
                                this.replaceIngredientsOpen = false;
                                this.replIngHasChanged = false;
                                this.alertService.success(this.tr.anslate('Successfully updated'));
                            } else {
                                this.utils.handleError('error updating the blend information', response.error);
                            }
                        },
                        error: error => {
                            this.utils.handleError('error updating the blend information', error);
                        }
                    });
            } else {
                this.replaceIngredientsOpen = false;
            }
        } else {
            this.blend = this.beforeReplIngBlend;
            this.replaceIngredientsOpen = false;
            this.replIngHasChanged = false;
        }
    }

    delete(): void {
        if (this.readOnly) { return; }

        const dialogRef = this.dialog.open(YesNoDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: this.blend.hr_id + ' ' + this.blend.label }) }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.standardService.remove('blends', this.blend._id)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully removed'));
                                // notify dashboard of the change
                                this.blend.deleted = true;
                                this.editMode = -1;
                                this.isNew = -1;
                                this.objectChangedService.objectChanged({ model: 'blends', info: { object: this.blend }, reload: true });
                            } else {
                                this.utils.handleError('Error updating the blend information', response.error);
                            }
                        },
                        error: error => {
                            this.utils.handleError('Error updating the blend information', error);
                        }
                    });
            }
        });
    }

    edit(notifyParent = true): void {
        if (this.readOnly) { return; }

        if (notifyParent) {
            // give the edit component the known coffees to start with
            const ingCoffees = this.blend.ingredients?.map(ing => ing?.coffee) || [];
            if (!this.coffees) {
                this.coffees = [];
            }
            this.coffees = unionwith(this.coffees, ingCoffees, this.utils.compareObjectsFn) as Coffee[];
        }
        this.undoBlend = undefined;
        this.getAllCoffees();

        this.blendcopy = cloneDeep(this.blend);
        if (typeof this.blendcopy.ingredients === 'undefined') {
            this.blendcopy.ingredients = [];
        } else {
            for (const ing of this.blendcopy.ingredients) {
                if (typeof ing.ratio === 'undefined') {
                    ing.ratio = 0;
                }
                // ing.ratio *= 100;
            }
        }
        this.editMode = this.index;
        if (notifyParent) {
            this.objectChangedService.objectChanged({ model: 'blends', info: { editMode: this.index }, reload: false });
        }
    }

    cancel(): void {
        this.editMode = -1;
        this.blendcopy = undefined;
        if (this.isNew === this.index) {
            this.isNew = -1;
            this.blend.deleted = true;
        }
        this.objectChangedService.objectChanged({ model: 'blends', info: { object: this.blend }, reload: false });
        this.isNew = -1;
    }

    /**
     * Called when a replacement coffee has been used or the use undone
     */
    saveBlend(): void {
        // calculdate total max stock of this blend
        this.sav = this.utils.getBlendStockAndValue(this.blend, this.showstockfrom, this.currentUser?.unit_system, undefined);
        this.blend.curStock = this.sav.replAmount;

        const blendCopy = Object.assign({}, this.blend);
        delete blendCopy.hr_id;
        delete blendCopy['__v'];
        this.standardService.update<Blend>('blends', blendCopy)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response) {
                        this.alertService.success(this.tr.anslate('Nothing to change'));
                    } else if (response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        // this.blend = response && response.result ? response.result : cloneDeep(this.blendcopy);
                        this.blendStockChanged.emit(this.blend);
                    } else {
                        this.utils.handleError('error updating the blend information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the blend information', error);
                }
            });
    }

    useReplaceCoffee(idx: number): void {
        if (!this.blend?.ingredients || !this.blend?.ingredients[idx]?.replace_coffee) {
            this.utils.handleError('error updating the blend information', undefined);
            return;
        }

        if (!this.undoBlend) {
            this.undoBlend = cloneDeep(this.blend);
        }

        const ing = this.blend.ingredients[idx];
        ing.coffee = ing.replace_coffee;
        ing.replace_coffee = undefined;
        this.mergeSameIngs(idx);

        this.recalcStocks();

        this.saveBlend();
    }

    // check if any other ingredients needs to be merged with the one at index idx
    private mergeSameIngs(idx: number) {
        // check if need to merge with another ingredient
        const ing = this.blend.ingredients[idx];
        for (let i = 0; i < this.blend.ingredients.length; i++) {
            if (i === idx) {
                continue;
            }
            const iiing = this.blend.ingredients[i];
            if (this.utils.compareObjectsFn(iiing.coffee, ing.coffee)) {
                // sum, delete second, and update first occurrence
                ing.ratio += iiing.ratio;
                if (this.blend.ingredients[i].replace_coffee) {
                    // TODO cannot figure out why this would otherwise throw an ExpressionChanged error ...
                    const nidx = idx < i ? idx : idx - 1;
                    const replc = this.blend.ingredients[i].replace_coffee;
                    setTimeout(() => {
                        this.blend.ingredients[nidx].replace_coffee = replc;
                    });
                }
                this.blend.ingredients.splice(i, 1);
                const num = [];
                num.length = idx + 1;
                const denom = [];
                denom.length = idx + 1;
                this.utils.calculateFraction(num, denom, idx, ing.ratio);
                ing.ratio_num = num[idx];
                ing.ratio_denom = denom[idx];
                // assume that only 2 can have same coffee at any point in time
                break;
            }
        }
    }

    undoReplacements(): void {
        this.blend = this.undoBlend;
        this.undoBlend = undefined;
        this.recalcStocks();
        this.saveBlend();
    }

    sendCoffeeReplacement(from: Coffee, to: Coffee): void {
        this.blendService.sendCoffeeReplacement(from, to)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.logger.debug(`updated ${response.count} blend templates`);
                        // notify parent of the change
                        this.objectChangedService.objectChanged({ model: 'blends', info: undefined, reload: true });
                        // setTimeout(() => {
                        this.alertService.success(this.tr.anslate('Blends') + ' - ' + this.tr.anslate('Successfully updated'));
                        // }, 2500);
                    } else {
                        this.utils.handleError('Failed updating blend templates', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Failed updating blend templates', error);
                }
            });
    }

    save(): void {
        if (this.readOnly) { return; }

        if (!this.blendcopy.ingredients || this.blendcopy.ingredients.length === 0) {
            this.dialogService.showDialog('Attention', 'A blend needs at least one type of beans.');
            return;
        }
        if (!this.utils.percentCorrect(this.blendcopy)) {
            this.dialogService.showDialog('Attention', 'Ratios must be positive and add up to 100%.');
            return;
        }
        for (const ing of this.blendcopy.ingredients) {
            if (!ing.ratio) {
                this.dialogService.showDialog('Attention', '0% ratios are not allowed.');
                return;
            }
        }
        this.isSaving = true;

        // need a copy of the copy as we change that for sending to the server
        // and we would otherwise change the object that is currently displayed
        const myccopy = cloneDeep(this.blendcopy) as Blend;
        if (myccopy.hr_id && myccopy._id) {
            // make sure only one of _id and hr_id is present in the update object
            delete myccopy.hr_id;
        }

        // calculate potentially changed coffees to display in ReplaceAllInBlendsComponent dialog
        const changedCoffeesIdx = [];
        if (this.isNew !== this.index) {
            for (let i = 0; i < Math.min(myccopy.ingredients.length, this.blend.ingredients?.length || 0); i++) {
                const ing = myccopy.ingredients[i];
                if (this.blend.ingredients[i] && !this.utils.compareObjectsFn(ing.coffee, this.blend.ingredients[i].coffee)) {
                    // coffee changed
                    changedCoffeesIdx.push(i);
                }
            }
        }
        // calculate potentially affected blends to display in ReplaceAllInBlendsComponent dialog
        if (changedCoffeesIdx.length === 1 && this.blend.ingredients[changedCoffeesIdx[0]]) {
            let affectedBlends: Blend[];
            if (this.blends?.length && this.blends.length === this.blendsCount) {
                // we have all blends at hand; check whether some are affected
                affectedBlends = [];
                const changedCoff = this.blend.ingredients[changedCoffeesIdx[0]].coffee;
                for (const blend of this.blends) {
                    if (this.utils.compareObjectsFn(this.blend, blend)) {
                        // ignore currently changed blend
                        continue;
                    }
                    for (let i = 0; i < blend?.ingredients.length; i++) {
                        const coff = blend.ingredients[i].coffee;
                        if (this.utils.compareObjectsFn(coff, changedCoff)) {
                            affectedBlends.push(blend);
                            continue;
                        }
                    }
                }
            }
            if (typeof affectedBlends === 'undefined' || affectedBlends.length > 0) {
                // ask whether this coffee replacement should be done in all blend templates
                // TODO allow for several coffee replacements at once
                const idx = changedCoffeesIdx[0];
                // this.blend will be overwritten before the dialog even pops up
                const origCoffee = this.blend.ingredients[idx].coffee;
                const dialogRef = this.dialog.open(ReplaceAllInBlendsComponent, {
                    closeOnNavigation: true,
                    data: {
                        from: origCoffee,
                        to: myccopy.ingredients[idx].coffee,
                        currentBlend: this.blend,
                        affectedBlends,
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result === true) {
                        this.sendCoffeeReplacement(origCoffee, myccopy.ingredients[idx].coffee);
                    }
                });
            }
        }

        // de-populate
        if (myccopy.ingredients?.length > 0) {
            myccopy.ingredients.map(ing => {
                ing.coffee = (ing.coffee?._id ? ing.coffee._id : ing.coffee) as unknown as Coffee;
                ing.replace_coffee = (ing.replace_coffee?._id ? ing.replace_coffee._id : ing.replace_coffee) as unknown as Coffee;
            });
        }

        // note that isEqual removes all equal properties from myccopy
        if (this.isNew !== this.index) {
            if (this.isEqual(this.blend, myccopy)) {
                this.alertService.success(this.tr.anslate('Nothing to change'));
                this.objectChangedService.objectChanged({ model: 'blends', info: undefined, reload: false });
                this.editMode = -1;
                this.isSaving = false;
                return;
            }
        } else {
            this.utils2.cleanResult(myccopy);
        }
        if (myccopy.hr_id && myccopy._id) {
            // make sure only one of _id and hr_id is present in the update object
            delete myccopy.hr_id;
        }

        let obs: Observable<{ success: boolean, result: Blend, error: string }>;
        if (this.isNew === this.index) {
            obs = this.standardService.add('blends', myccopy);
        } else {
            // if an existing item is updated, isEqual deleted _id and hr_id; re-add one of them (prefer _id)
            if (this.blend._id) {
                myccopy._id = this.blend._id;
            } else {
                myccopy.hr_id = this.blend.hr_id;
            }
            // other operations could change the array of ingredients; then, the versionKey changes
            delete myccopy['__v'];
            obs = this.standardService.update<Blend>('blends', myccopy);
        }
        obs.pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response) {
                        this.alertService.success(this.tr.anslate('Nothing to change'));
                        this.editMode = -1;
                        this.objectChangedService.objectChanged({ model: 'blends', info: undefined, reload: false });
                    } else if (response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.blend = response?.result ? response.result : cloneDeep(this.blendcopy);
                        // notify parent of the change
                        this.objectChangedService.objectChanged({ model: 'blends', info: { object: this.blend }, reload: false });
                        this.editMode = -1;
                        this.recalcStocks();
                    } else {
                        this.utils.handleError('error updating the blend information', response.error);
                    }
                    this.isSaving = false;
                },
                error: error => {
                    this.utils.handleError('error updating the blend information', error);
                    this.isSaving = false;
                }
            });
    }

    /**
     * checks all main and tableProperties for equality
     * deletes all fixed and hidden properties (except _id, hr_id) from bUpdate as well as all others that are equal
     */
    isEqual(obj: Blend, updateObj: Blend): boolean {
        let equal = true;

        const props = Object.getOwnPropertyNames(updateObj);
        for (const prop of props) {

            if (this.fixedProperties.indexOf(prop) >= 0 || this.hiddenProperties.indexOf(prop) >= 0) {
                // these can be ignored
                if (prop !== 'hr_id' && prop !== '_id') {
                    delete updateObj[prop];
                }
                continue;

            } else {
                // it's a mainProperty

                if ((obj[prop] == null || obj[prop]?.length === 0 || (Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object))
                    && (updateObj[prop] == null || updateObj[prop]?.length === 0 || (Object.keys(updateObj[prop]).length === 0 && updateObj[prop].constructor === Object))) {
                    // both are empty (undefined and null and [] and {} are considered equal here)
                    delete updateObj[prop];
                    continue;
                }

                if (obj[prop] == null || obj[prop]?.length === 0 || (Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object)
                    || updateObj[prop] == null || updateObj[prop]?.length === 0 || (Object.keys(updateObj[prop]).length === 0 && updateObj[prop].constructor === Object)) {
                    // only one is set
                    equal = false;
                    continue;
                }

                if (prop === 'ingredients') {
                    if (obj.ingredients && !updateObj.ingredients || updateObj.ingredients && !obj.ingredients
                        || obj.ingredients && updateObj.ingredients && obj.ingredients.length !== updateObj.ingredients.length) {

                        equal = false;
                        continue;
                    }
                    let innerEqual = true;
                    for (let i = 0; i < obj.ingredients.length; i++) {
                        const ingA = obj.ingredients[i];
                        const ingE = updateObj.ingredients[i];
                        // ignore ratio_num and ration_denom here since they are different iff ratio is different
                        if (ingA && !ingE || ingE && !ingA || ingA.ratio !== ingE.ratio
                            || (ingA.coffee?._id || ingA.coffee)?.toString() !== (ingE.coffee?._id || ingE.coffee)?.toString()
                            || (ingA.replace_coffee?._id || ingA.replace_coffee)?.toString() !== (ingE.replace_coffee?._id || ingE.replace_coffee)?.toString()) {

                            equal = false;
                            innerEqual = false;
                            break;
                        }
                    }
                    if (innerEqual) {
                        delete updateObj.ingredients;
                    }
                    continue;
                }

                // this doesn't find equality if one is populated (blend is treated separately above)
                if (this.utils.compareObjectsFn(obj[prop], updateObj[prop]) || JSON.stringify(obj[prop]) === JSON.stringify(updateObj[prop])) {
                    delete updateObj[prop];
                } else {
                    equal = false;
                }
            }
        }
        return equal;
    }

    getUnits(): string[] {
        const keys = Object.values(Enumerations.CoffeeUnits);
        keys.shift();
        return keys;
    }

    panel(opened: boolean): void {
        this.isExpanded = opened;
    }

    addImage(): void {
        if (this.readOnly || !this.blend) {
            return;
        }

        this.utils.addImage(this.blend, 'blends', 'image', this.ngUnsubscribe,
            () => { this.isSaving = true; }, // dialog closed
            imagePath => { // saving done (or no changes)
                if (typeof imagePath !== 'undefined') {
                    if (imagePath && environment.BASE_API_URL.indexOf('localhost') >= 0) {
                        if (this.blendcopy) {
                            this.blendcopy.image = null;
                            this.waitForLocalServer = true;
                            setTimeout(() => {
                                this.waitForLocalServer = false;
                                this.blendcopy.image = imagePath;
                                // this.logger.debug('image reloaded for blendcopy');
                            }, 7250);
                        }
                        this.blend.image = null;
                        this.waitForLocalServer = true;
                        setTimeout(() => {
                            this.waitForLocalServer = false;
                            this.blend.image = imagePath;
                            // this.logger.debug('image reloaded for blend');
                        }, 6750);
                    } else {
                        if (this.blendcopy) {
                            this.blendcopy.image = imagePath;
                        }
                        this.blend.image = imagePath;
                    }
                }
                this.isSaving = false
            },
        );
    }

    filesChanged(newFiles: string[]): void {
        if (this.readOnly) { return; }

        this.blend.files = newFiles;
        this.standardService.update<Blend>('blends', { _id: this.blend._id, files: this.blend.files, label: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.logger.debug('update successful');
                    } else {
                        this.utils.handleError('Could not update documents', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Could not update documents', error);
                }
            });
    }
}
