import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { RoastGraphsComponent } from './roast/roast-graphs.component';
import { RoastamountaccumulatedGraphComponent } from './roast/roastamountaccumulated-graph.component';
import { RoastamountpertimeGraphComponent } from './roast/roastamountpertime-graph.component';
import { RoastamounttotalGraphComponent } from './roast/roastamounttotal-graph.component';
// import { RoastgroundcolorhistoGraphComponent } from './roast/roastgroundcolorhisto-graph.component';
// import { RoastwholecolorhistoGraphComponent } from './roast/roastwholecolorhisto-graph.component';
import { RoastconsumptionGraphComponent } from './roast/roastconsumption-graph.component';
import { RoastambientGraphComponent } from './roast/roastambient-graph.component';
import { RoastmachineGraphComponent } from './roast/roastmachine-graph.component';
import { RoastuserGraphComponent } from './roast/roastuser-graph.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UiModule } from 'src/app/modules/ui/ui.module';
import { EnergytotalWidgetComponent } from './roast/energytotal-widget.component';
import { MyMaterialModule } from '../ui/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// // @ts-expect-error not used here
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// import * as roughness from 'src/app/util/chartjs-plugin-roughness';
import { Legend, LineController, LineElement, LinearScale, Title, Tooltip, CategoryScale, Colors, PointElement, TimeSeriesScale, TimeScale } from 'chart.js';
import { RoastblendGraphComponent } from './roast/roastblend-graph.component';

@NgModule({
    declarations: [
        RoastGraphsComponent,
        // RoastgroundcolorhistoGraphComponent,
        // RoastwholecolorhistoGraphComponent,
    ],
    imports: [
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MyMaterialModule,
        FormsModule,
        CommonModule,
        NgxMatSelectSearchModule,
        UiModule,
        RoastamountaccumulatedGraphComponent,
        RoastamountpertimeGraphComponent,
        RoastamounttotalGraphComponent,
        RoastconsumptionGraphComponent,
        RoastblendGraphComponent,
        RoastambientGraphComponent,
        RoastmachineGraphComponent,
        RoastuserGraphComponent,
        EnergytotalWidgetComponent,
    ],
    exports: [
        RoastGraphsComponent
    ],
    providers: [
        BreakpointObserver,
        provideCharts(withDefaultRegisterables([
                LineController, LineElement, PointElement,
                Legend, Colors, Title, Tooltip,
                CategoryScale, LinearScale, TimeScale, TimeSeriesScale,
            ]),
        ),
    ],
})
export class RoastGraphModule { }
