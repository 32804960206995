import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { FilterOptions } from './services/standard.service';
import { DateTime } from 'luxon';
// import { DebouncedFunc } from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class Utils2 {

    constructor(
        private logger: NGXLogger,
        public tr: TranslatorService,
    ) { }

    /**
     * Normally not used directly, use cleanResult instead.
     * Removes all "empty" properties from the given array of objects using cleanResult recursively.
     * @param {T} obj the object to clean
     * @param {number} depth the current recursion depth just to avoid an "infinite loop"
     */
    private cleanOneObject<T>(obj: T, depth: number) {
        if (!obj || (typeof obj !== 'object' && typeof obj !== 'function')) {
            return;
        }
        const props = Object.getOwnPropertyNames(obj);
        for (const prop of props) {
            const propVal = obj[prop];
            if (propVal == null
                // empty array:
                || propVal.length === 0
                // empty object {}:
                || (Object.keys(propVal).length === 0 && propVal.constructor === Object)
                // array with only one empty object [{}]:
                || (propVal.length === 1 && (propVal[0] && Object.keys(propVal[0]).length === 0 && propVal[0].constructor === Object))
                || (DateTime.isDateTime(propVal) && !propVal.isValid)) {
                // logger.debug('remove property %s as its value is %s', props[p], JSON.stringify(obj[props[p]]));
                delete obj[prop];
            } else if (prop !== '_id' && typeof propVal === 'object' && !(DateTime.isDateTime(propVal))) {
                // recurse for all object properties
                obj[prop] = this.cleanResult(propVal, depth + 1);
                // remove null, undefined, [], {}
                // don't change the .length === 0 check with !.length since undefined must not match
                if (obj[prop] == null || obj[prop].length === 0 || (obj[prop] && !Object.keys(obj[prop]).length && obj[prop].constructor === Object)) {
                    delete obj[prop];
                }
            }
        }
    }

    // cleanOneObject<T>(obj: T, depth = 0): T {
    //     return this.traverseObjects(obj);
    // }
    // cleanArray<T>(objs: T[], depth = 0): T[] {
    //     return this.traverseObjects(objs);
    // }

    /**
     * Removes all "empty" properties from the given array of objects using cleanObject recursively.
     * Directly changes the objects and returns them.
     * @param {T} objs one object or an array of objects to clean
     * @param {number} depth the current recursion depth just to avoid an "infinite loop" (max 10 recursions)
     */
    cleanResult<T>(objs: T, depth = 0): T {
        try {
            if (depth > 10) {
                this.logger.warn('too many recursions in cleanResult');
                return objs;
            }

            if (!objs) {
                return undefined;
            }
            if (objs['length']) {
                for (let o = 0; o < objs['length']; o++) {
                    // never send refs; just used on the client, generated on the fly on the server
                    if (objs[o]) {
                        delete objs[o]['refs'];
                        if (!objs[o].default_unit?.name) {
                            // specifically remove default default_unit === { name: Enumerations.CoffeeUnits._NONE, size: 1 }
                            delete objs[o].default_unit;
                        }
                        this.cleanOneObject(objs[o], depth);
                    }
                }
            } else {
                // never send refs; just used on the client, generated on the fly on the server
                delete objs['refs'];
                if (!objs['default_unit']?.name) {
                    delete objs['default_unit'];
                }
                this.cleanOneObject(objs, depth);
            }
            return objs;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (err) {
            // ignore for now
        }
    }

    /**
     * 
     * @param filter filter to remove "non-filter" attributes
     */
    cleanLoadedFilter(filter: FilterOptions) {
        // not needed but potentially added through DB roundtrip
        delete filter.filtername;
        delete filter.filtertype;
        delete filter['owner'];
        delete filter['created_by'];
        delete filter['updated_by'];
        delete filter['created_at'];
        delete filter['updated_at'];
        this.cleanResult(filter);
    }
}
