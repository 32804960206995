<h1 mat-dialog-title i18n="generic$$link to dialog to quickly fix typos in machine names@@Rename machines">Rename machines</h1>
<mat-dialog-content style="max-width: 550px;">
    <div class="dialog-description" i18n="generic$$displayed as explanation in the machine rename dialog@@This is a system wide change. All existing roasts, templates, scheduled items etc. will be affected. Cannot be undone.">This is a system wide change. All existing roasts, templates, scheduled items etc. will be affected. Cannot be undone.</div>
    <table>
        <tr>
            <th colspan="3"></th>
            @if (data.useCnt) {
            <th i18n="generic$$roasts, plural@@roasts" style="text-align: center;">roasts</th>
            }
        </tr>
        @for (replacement of replacements; track replacement?.val) {
        <tr class="replace-table-row">
            <td [ngClass]="{'active-text': replacement.val !== replacement.replaceWith, 'warn-text': willMergeIdxs.includes($index)}">{{replacement.val}}</td>
            <td>
                <mat-icon style="margin: 0 5px; vertical-align: middle;">redo</mat-icon>
            </td>
            <td>
                <mat-form-field class="replace-right">
                    <input matInput type="text" name="replace_{{$index}}"
                        [(ngModel)]="replacement.replaceWith"
                        (ngModelChange)="inputChanged()"
                        [ngClass]="{'active-text': replacement.val !== replacement.replaceWith}">
                </mat-form-field>
            </td>
            @if (data.useCnt) {
            <td style="text-align: center;" [ngClass]="{'active-text': replacement.val !== replacement.replaceWith}">{{replacement.cnt}}</td>
            }
        </tr>
        }
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if (willMerge) {
    <div class="dialog-warning" i18n="generic$$warning displayed in the machine rename dialog if a machine will get the same name as an existing one@@Caution! Chosen machine name already exists, you will not be able to separate roasts by machine after the change.">Caution! Chosen machine name already exists, you will not be able to separate roasts by machine after the change.</div>
    }
    <button class="cancel-button" autofocus cdkFocusInitial mat-button type="submit" mat-dialog-close tabindex="0">
        <mat-icon>close</mat-icon>
    </button>
    <button [disabled]="!haveChanges" class="action-button" mat-raised-button type="button" (click)="closeDialog()">
        <mat-icon>done</mat-icon>
    </button>
</mat-dialog-actions>