import { Utils } from './util/utils';
import { Component, OnInit, OnDestroy, Inject, LOCALE_ID, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { map, delay, takeUntil } from 'rxjs/operators';
import { LoginChangedService } from 'src/app/modules/frame/services/loginchanged.service';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AccountChangedService } from './util/services/accountchanged.service';
import { Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { GraphService } from './modules/graph/graph.service';
import { DateTime, Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [GraphService],
})
export class AppComponent implements OnInit, OnDestroy {

    constructor(
        private breakpointObserver: BreakpointObserver,
        private loginChangedService: LoginChangedService,
        private userService: UserService,
        private logger: NGXLogger,
        private utils: Utils,
        private router: Router,
        private accountChangedService: AccountChangedService,
        private mediaMatcher: MediaMatcher,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private overlayContainer: OverlayContainer,
        private changeDetRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        // https://github.com/angular/components/issues/24845
        const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
        const outlinedFontSetClasses = defaultFontSetClasses
            .filter(fontSetClass => fontSetClass !== 'material-icons');
        outlinedFontSetClasses.unshift('material-symbols-outlined');
        iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);

        iconRegistry.addSvgIcon('artisan-blue', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/artisan-blue.svg'));
        iconRegistry.addSvgIcon('artisan-grey', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/artisan-grey.svg'));
        iconRegistry.addSvgIcon('store', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/store.svg'));
        iconRegistry.addSvgIcon('coffee', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/coffee.svg'));
        iconRegistry.addSvgIcon('blend', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/blend.svg'));
        iconRegistry.addSvgIcon('roast', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/roast.svg'));
        iconRegistry.addSvgIcon('contact', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/contact.svg'));
        iconRegistry.addSvgIcon('report', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/report.svg'));
        iconRegistry.addSvgIcon('reminder', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/reminder.svg'));
        iconRegistry.addSvgIcon('schedule', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/schedule.svg'));
        iconRegistry.addSvgIcon('store-dark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/store-dark.svg'));
        iconRegistry.addSvgIcon('coffee-dark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/coffee-dark.svg'));
        iconRegistry.addSvgIcon('blend-dark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/blend-dark.svg'));
        iconRegistry.addSvgIcon('roast-dark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/roast-dark.svg'));
        iconRegistry.addSvgIcon('contact-dark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/contact-dark.svg'));
        iconRegistry.addSvgIcon('report-dark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/report-dark.svg'));
        iconRegistry.addSvgIcon('reminder-dark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/main/reminder-dark.svg'));

        this.updateColorSchemeClasses();
    }

    isSmallDelayed$: Observable<boolean>;
    isSmall$: Observable<boolean>;
    isLarge$: Observable<boolean>;

    loggedInUser: UserType = null;
    private ngUnsubscribe = new Subject();

    darkmodeMode: 'auto' | 'lightmode' | 'darkmode' = 'auto';
    darkColorSchemeMatcher: MediaQueryList;
    darkColorSchemeChanged: () => void;
    isDarkmode = false;

    paidUntil: DateTime;
    locale2 = this.locale;

    nrExpiredReminders = 0;

    DateTime = DateTime;

    ngOnInit(): void {
        Settings.defaultLocale = this.locale;
        
        // need exactly de/en/it for link to shop
        this.locale2 = this.locale ? this.locale.substring(0, 2) : 'en';

        // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        //     .pipe(map(result => result.matches));
        this.isSmallDelayed$ = this.breakpointObserver.observe('(max-width: 599px)')
            .pipe(map(result => result.matches), delay(450));
        this.isSmall$ = this.breakpointObserver.observe('(max-width: 599px)')
            .pipe(map(result => result.matches));
        this.isLarge$ = this.breakpointObserver.observe('(min-width: 900px)')
            .pipe(map(result => result.matches));

        // !needs to be registered before the loginChangedService!
        // called when user changes dark mode in user settings
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => {
                this.darkmodeMode = dm;
                this.isDarkmode = this.userService.isDarkModeEnabled(dm);
                this.updateColorSchemeClasses();
            }
            );
        this.darkmodeMode = this.userService.getDarkmode();
        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.updateColorSchemeClasses();

        // !needs to be registered after the darkmodeMode subscription!
        this.darkColorSchemeChanged = () => {
            if (this.userService.getDarkmode() === 'auto') {
                // update all listeners
                this.userService.storeDarkmode('auto');
                this.darkmodeMode = 'auto';
                this.isDarkmode = this.userService.isDarkModeEnabled('auto');
                this.updateColorSchemeClasses();
                this.changeDetRef.detectChanges();
            }
        }
        // matches all changes to prefers-color-scheme
        this.darkColorSchemeMatcher = this.mediaMatcher.matchMedia('(prefers-color-scheme: dark)');
        try {
            // Safari (and Chrome, though deprecated)
            this.darkColorSchemeMatcher.addListener(this.darkColorSchemeChanged);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e1) {
            try {
                // Chrome (not for me?) & Firefox
                this.darkColorSchemeMatcher.addEventListener('change', () => this.darkColorSchemeChanged);
            } catch (e2) {
                // ignore
                this.logger.error('error: could not assign darkColorSchemeChange listener', e2);
            }
        }

        // !needs to be registered after the darkmodeMode subscription!
        this.loginChangedService.loginStatus
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(status => this.loginStateChanged(status)
            );

        this.accountChangedService.changed$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(account => this.accountChanged(account)
            );
    }

    ngOnDestroy(): void {
        this.darkColorSchemeMatcher.removeListener(this.darkColorSchemeChanged);
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    loginStateChanged(status: boolean): void {
        let currentUser: UserType;
        if (status) {
            currentUser = this.userService.getCurrentUser();
        }

        if (status && currentUser) {
            if (this.loggedInUser?.user_id === currentUser.user_id) {
                this.logger.trace('user still logged in');
            } else {
                this.logger.trace('new or different user logged in!');
            }
            this.loggedInUser = currentUser;
            if (this.loggedInUser.account) {
                this.paidUntil = this.utils.getPaidUntil(this.loggedInUser.account);
            }

            // redirect to currentUser.language if not already there
            const currentUserLanguage = currentUser.language === 'en-GB' ? 'gb' : currentUser.language;
            const hasLang = RegExp('/..($|/)');
            if (hasLang.test(window.location.pathname)) {
                const curLang = window.location.pathname.substring(1, 3); // /en/...
                if (curLang !== 'xy' && curLang !== 'zz' && currentUserLanguage && curLang !== currentUserLanguage) {
                    let path = window.location.pathname.substring(3);
                    if (path.substring(0, 1) === '/') {
                        path = path.substring(1);
                        if (path.substring(0, 5) === 'login') {
                            path = 'stores';
                        }
                    }
                    this.router.navigate(['/externalRedirect', { lang: currentUserLanguage, path, query: window.location.search }], {
                        skipLocationChange: true,
                    });
                }
            } else {
                let path = window.location.pathname;
                if (path.substring(0, 1) === '/') {
                    path = path.substring(1);
                }
                this.router.navigate(['/externalRedirect', { lang: currentUserLanguage, path: path, query: window.location.search }], {
                    skipLocationChange: true,
                });
            }

        } else {
            // not logged in
            this.logger.debug('no user logged in');
            this.paidUntil = undefined;
            this.loggedInUser = null;
        }
    }

    accountChanged(account: UserType['account']): void {
        this.loggedInUser = this.userService.getCurrentUser();
        this.paidUntil = this.utils.getPaidUntil(account);
        this.nrExpiredReminders = account?.reminders || 0;
        if (typeof account.notifications !== 'undefined') {
            // fetch and show notification, not more than once every 10 seconds
            this.utils.getNotifications();
        } else {
            this.utils.removeActiveNotificationSnackBar();
            this.utils.resetShownNotifications();
        }
    }

    updateColorSchemeClasses(): void {
        // in template for the app component:
        // <div style="height: 100%;" [ngClass]="{'force-dark-mode': darkmodeMode === 'darkmode', 'force-light-mode': darkmodeMode === 'lightmode', 'auto-mode': darkmodeMode === 'auto'}">
        // needs to be put on overlay separately (https://material.angular.io/guide/theming#multiple-themes, https://material.angular.io/cdk/overlay/overview)
        if (this.darkmodeMode === 'darkmode') {
            this.renderer.removeClass(this.document.body, 'auto-mode');
            this.renderer.removeClass(this.document.body, 'force-light-mode');
            this.renderer.addClass(this.document.body, 'force-dark-mode');
            this.overlayContainer.getContainerElement().classList.remove('auto-mode');
            this.overlayContainer.getContainerElement().classList.remove('force-light-mode');
            this.overlayContainer.getContainerElement().classList.add('force-dark-mode');
        } else if (this.darkmodeMode === 'lightmode') {
            this.renderer.removeClass(this.document.body, 'auto-mode');
            this.renderer.addClass(this.document.body, 'force-light-mode');
            this.renderer.removeClass(this.document.body, 'force-dark-mode');
            this.overlayContainer.getContainerElement().classList.remove('auto-mode');
            this.overlayContainer.getContainerElement().classList.remove('force-dark-mode');
            this.overlayContainer.getContainerElement().classList.add('force-light-mode');
        } else if (this.darkmodeMode === 'auto') {
            this.renderer.addClass(this.document.body, 'auto-mode');
            this.renderer.removeClass(this.document.body, 'force-light-mode');
            this.renderer.removeClass(this.document.body, 'force-dark-mode');
            this.overlayContainer.getContainerElement().classList.remove('force-light-mode');
            this.overlayContainer.getContainerElement().classList.remove('force-dark-mode');
            this.overlayContainer.getContainerElement().classList.add('auto-mode');
        }
    }
}
