<a [routerLink]="['/welcome']">
    <div class="backdrop" [ngClass]="{ 'animated': doblur }">
        <div class="background-mask"></div>
    </div>
</a>
<div class="ap-popuplike">
    <form name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm">
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !nickname.valid }">
            <mat-form-field class="form-group" hideRequiredMarker>
                <mat-label i18n="users$$nickname, only for the greeting@@nickname">Nickname</mat-label>
                <input matInput required #firstinput="matInput" #nickname="ngModel"
                    id="npt_nickname" name="nickname"
                    autofocus autocomplete="nickname" data-test="npt_nickname"
                    placeholder="Nickname" i18n-placeholder="users$$nickname, only for the greeting@@nickname"
                    [(ngModel)]="user.nickname">
            </mat-form-field>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
            <mat-form-field class="form-group" hideRequiredMarker>
                <mat-label i18n="users$$email address@@email">Email</mat-label>
                <input matInput required email type="email" #email="ngModel"
                    id="npt_email" name="email"
                    autocomplete="username email" data-test="npt_email"
                    placeholder="Email" i18n-placeholder="users$$email address@@email"
                    [(ngModel)]="user.email">
            </mat-form-field>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
            <mat-form-field class="form-group" hideRequiredMarker>
                <mat-label i18n="users$$password@@password">Password</mat-label>
                <input matInput required type="password" #password="ngModel"
                    id="npt_password" name="password"
                    autocomplete="new-password" pattern=".{4,}" data-test="npt_password"
                    placeholder="Password" i18n-placeholder="users$$password@@password"
                    [(ngModel)]="user.password">
            </mat-form-field>
        </div>

        <div data-test="div_chck_privacy" class="form-group" [ngClass]="{ 'has-error': f.submitted && !user.privacy_accepted }" style="margin-bottom: 10px;">
            <mat-checkbox required data-test="chck_privacy"
                class="primary-element wrap-checkbox-label"
                name="privacy_accepted"
                [(ngModel)]="user.privacy_accepted">
                <span i18n="register$$accept privacy policy checkbox@@accept privacy" class="littlespaceright">I accept the Privacy Policy:</span>
                <a data-test="lnk_privacy" href="privacy" i18n="register$$link to privacy policy@@read here" target="_blank" rel="noopener">read</a>
            </mat-checkbox>
        </div>

        <div class="form-group" mainbutton>
            <button [disabled]="loading || !user.privacy_accepted || !f.form.valid"
                data-test="bttn_register" mat-raised-button
                class="primary-element mainbutton action-button"
                style="margin: 0;"
                type="submit" i18n="users$$register button@@register button">REGISTER</button>
        </div>

        <div class="login-doc">
            <a class="login toolbartext" data-test="lnk_login" routerLink="/login" routerLinkActive="active" i18n="users$$link to login page@@login link">LOGIN</a>
            <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/docs'" target="_blank" rel="noopener" class="login" style="padding-right: 0">
                <mat-icon>{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
            </a>
        </div>

        @if (loading) {
        <mat-spinner data-test="spnnr"></mat-spinner>
        }
    </form>
</div>