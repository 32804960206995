import { DialogModule } from 'src/app/modules/ui/dialog/dialog.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerComponent } from './scheduler.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { ApDatetimeModule } from 'src/app/modules/ui/ap-datetime.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SchedulerPlannerComponent } from './scheduler-planner.component';
import { CdkDropList, CdkDrag, DragDropModule } from '@angular/cdk/drag-drop';
import { AngularSplitModule } from 'angular-split';
import { UiModule } from '../ui/ui.module';
import { CheckboxFilterComponent } from 'src/app/modules/roast/filters/checkbox-filter.component';
import { SchedulerPlannerPlaceholderComponent } from './scheduler-planner-placeholder.component';
import { ChipsFilterComponent } from './chips-filter.component';
import { SchedulerInputComponent } from './scheduler-input.component';
import { SchedulerPlannerFavComponent } from './scheduler-planner-fav.component';
import { RecurringDialogComponent } from './recurring-dialog.component';
import { RoastsDialogComponent } from './roasts-dialog.component';
import { SchedulerPlannerYearComponent } from "./scheduler-planner-year.component";

@NgModule({
    declarations: [
        SchedulerComponent,
        SchedulerPlannerComponent,
        SchedulerPlannerPlaceholderComponent,
        SchedulerPlannerFavComponent,
    ],
    imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MyMaterialModule,
    ApDatetimeModule,
    DialogModule,
    NgxMatSelectSearchModule,
    CdkDropList,
    CdkDrag,
    DragDropModule,
    AngularSplitModule,
    UiModule,
    SchedulerInputComponent,
    CheckboxFilterComponent,
    ChipsFilterComponent,
    RecurringDialogComponent,
    RoastsDialogComponent,
    SchedulerPlannerYearComponent
],
    exports: [
    ]
})
export class SchedulerModule { }
