import { Component, OnInit, Input } from '@angular/core';
import { Blend } from 'src/app/models/Blend';
import { Utils } from 'src/app/util/utils';
import { Coffee } from 'src/app/models/Coffee';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UserType, UserService } from 'src/app/modules/frame/services/user.service';
import { Router } from '@angular/router';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
    selector: 'app-blend-edit',
    templateUrl: './blend-edit.component.html',
    styleUrls: ['./blend-edit.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class BlendEditComponent implements OnInit {

    constructor(
        // used in template
        public utils: Utils,
        // used in template
        public tr: TranslatorService,
        private userService: UserService,
        private router: Router,
    ) { }

    @Input() currentUser: UserType;

    private _blend: Blend;
    get blend(): Blend { return this._blend; }
    @Input() set blend(b: Blend) {
        this._blend = b;
        this.blendChanged();
    }
    @Input() readOnly = false;
    filteredCoffees: Coffee[][] = [];
    private _coffees: Coffee[];
    get coffees(): Coffee[] { return this._coffees; }
    @Input() set coffees(cs: Coffee[]) {
        this._coffees = cs;
        // for (let c = 0; c < this._coffees?.length; c++) {
        //     const cof = this._coffees[c];
        //     if (cof) {
        //         cof.yearLabel = this.utils.createBeansYearLabel(cof);
        //     }
        // }
        if (this._coffees && this.blend) {
            for (let i = 0; i < this.blend.ingredients.length; i++) {
                this.filteredCoffees[i] = this.getCoffees(i);
            }
        } else {
            this.filteredCoffees = [];
        }
    }
    @Input() coffeesLoaded = false;

    @Input() showstockfrom: string[] | 'all' = 'all';

    sortedIngredients: Blend['ingredients'];
    num: number[] = [];
    denom: number[] = [];

    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
    }

    blendChanged(): void {
        if (this.blend) {
            if (this.blend.ingredients) {
                this.sortedIngredients = this.blend.ingredients.sort((i1, i2) => i2.ratio - i1.ratio);
                // read fraction ratios if present
                for (let i = 0; i < this.blend.ingredients.length; i++) {
                    const ing = this.blend.ingredients[i];
                    if (ing.ratio_num && ing.ratio_denom) {
                        this.num[i] = ing.ratio_num;
                        this.denom[i] = ing.ratio_denom;
                    } else if (ing.ratio) {
                        // check if fraction ratios can be displayed
                        this.utils.calculateFraction(this.num, this.denom, i, ing.ratio);
                        ing.ratio_num = this.num[i];
                        ing.ratio_denom = this.denom[i];
                        if (ing.ratio_denom) {
                            ing.ratio = ing.ratio_num / ing.ratio_denom;
                        }
                    }
                }
            }
        }
    }

    // update list for filters
    updateFilters(): void {
        for (let i = 0; i < this.blend?.ingredients?.length; i++) {
            this.filteredCoffees[i] = this.getCoffees(i);
        }
    }

    addIngredient(): void {
        if (this.readOnly) { return; }

        let rest = 1.0;
        for (const ing of this.blend.ingredients) {
            if (ing.ratio) {
                rest -= ing.ratio;
            }
        }
        // the given idx doesn't exist yet but that's ok
        const possibleCoffees = this.getCoffees(this.blend.ingredients.length);
        if (possibleCoffees.length === 1) {
            this.blend.ingredients.push({ coffee: possibleCoffees[0], ratio: rest });
        } else {
            this.blend.ingredients.push({ coffee: undefined, ratio: rest });
        }
        this.utils.checkFractions(this.blend, this.num, this.denom, this.blend.ingredients.length - 1, rest);
        const newing = this.blend.ingredients[this.blend.ingredients.length - 1];
        newing.ratio_num = this.num[this.blend.ingredients.length - 1];
        newing.ratio_denom = this.denom[this.blend.ingredients.length - 1];

        this.updateFilters();
    }

    deleteIngredient(idx: number): void {
        if (this.readOnly) { return; }

        this.blend.ingredients.splice(idx, 1);
        this.num.splice(idx, 1);
        this.denom.splice(idx, 1);
        this.updateFilters();
    }

    ingredientChanged(i: number): void {
        if (this.utils.compareObjectsFn(this.blend.ingredients[i].coffee, this.blend.ingredients[i].replace_coffee)) {
            this.blend.ingredients[i].replace_coffee = undefined;
        }
        this.updateFilters();
    }

    checkChangesRatio(variable: string, oldValue: number, newValueStr: string, digits: number, idx: number): void {
        this.blend.ingredients[idx][variable] = undefined;
        setTimeout(() => {
            const { val, changed } = this.utils.checkChangedValue(oldValue, newValueStr, digits, true);
            // round to max digits; https://stackoverflow.com/a/23560569
            let val2 = +(Math.round(+(val + 'e' + (digits + 2))) + 'e' + -(digits + 2));
            if (val2 < 0) {
                val2 = -val2;
            }
            this.blend.ingredients[idx][variable] = val2;
            this.utils.calculateFraction(this.num, this.denom, idx, val2);
            if (changed) {
                this.ratioChanged(idx);
            }
        });
    }

    /**
     * get all the coffees that are possible for this idx
     * (i.e. all that are not already taken by the other indexes)
     * @param idx index of the desired blend.ingredients
     * @returns list of coffees that are not already used as ingredient
     */
    getCoffees(idx: number): Coffee[] {
        if (!this.coffees) {
            return [];
        }
        return this.coffees.filter((c: Coffee) => {
            for (let i = 0; i < this.blend.ingredients.length; i++) {
                if (i !== idx && this.blend.ingredients[i]?.coffee?._id === c._id) {
                    return false;
                }
            }
            return true;
        });
    }

    ratioChanged(idx: number): void {
        const ing = this.blend.ingredients[idx];
        ing.ratio_num = this.num[idx];
        ing.ratio_denom = this.denom[idx];
        if (this.blend.ingredients.length === 1) {
            // don't fix to 100%, user probably just starting
        } else if (this.blend.ingredients.length === 2) {
            // fix the other value such that the sum is 100%
            this.blend.ingredients[1 - idx].ratio = 1 - this.blend.ingredients[idx].ratio;
            this.utils.checkFractions(this.blend, this.num, this.denom, 1 - idx);
            const newing = this.blend.ingredients[1 - idx];
            newing.ratio_num = this.num[1 - idx];
            newing.ratio_denom = this.denom[1 - idx];
        } else {
            // cannot decide which other value(s) to change, ignore
        }
    }

    fractionChanged(idx: number, isNum: boolean, value: string): void {
        let n = parseInt(value, 10);
        if (!Number.isNaN(n)) {
            if (n < 0) {
                n = -n;
            }
            if (isNum) {
                this.num[idx] = n;
            } else {
                this.denom[idx] = n;
            }
            if (this.num[idx] && this.denom[idx]) {
                // set ratio
                this.blend.ingredients[idx].ratio = this.num[idx] / this.denom[idx];
                this.ratioChanged(idx);
            }
        }
    }

    getAmountStr(idx: number): { pre: string, value: number, post: string } {
        if (!this.blend?.ingredients || !this.blend?.ingredients[idx]?.coffee) {
            return undefined;
        }
        let coffee = this.blend.ingredients[idx].coffee;
        if (!coffee.stock && this.coffees) {
            for (const cof of this.coffees) {
                if (this.utils.compareObjectsFn(cof, this.blend.ingredients[idx].coffee)) {
                    coffee = cof;
                }
            }
        }
        if (!coffee) {
            return undefined;
        }
        const totalStock = this.blend.ingredients[idx].coffee.stock?.reduce((prev, cur) => prev + 
            (this.showstockfrom === 'all' || this.showstockfrom.includes(cur.location?.toString()) ? cur.amount : 0)
        , 0);
        return this.utils.formatAmountForPipe(totalStock, undefined, this.currentUser.unit_system);
    }
}
