<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content>
    <div class="nobreak input-range-hint">
        @if (data.min || data.min === 0) {
        <span>{{data.min | number:'1.0-3':locale}}</span>
        }
        @if (data.min || data.max || data.min === 0 || data.max === 0) {
        <span>&nbsp;-&nbsp;</span>
        }
        @if (data.max || data.max === 0) {
        <span>{{data.max | number:'1.0-3':locale}}</span>
        }
    </div>
    <mat-form-field>
        <input required matInput #inputField type="text" [placeholder]="data.placeholder" name="numberinput" [(ngModel)]="value" (input)="validateInputLive()" (blur)="validateInput()" (keyup.enter)="onYesClick()">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-button" mat-button type="button" mat-dialog-close><mat-icon>close</mat-icon></button>
    <button [disabled]="okDisabled" class="action-button" mat-raised-button type="submit" (click)="onYesClick()"><mat-icon>done</mat-icon></button>
</mat-dialog-actions>