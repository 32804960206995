import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddExternalComponent } from './import/add-external.component';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { CoffeeModule } from 'src/app/modules/coffee/coffee.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        AddExternalComponent,
    ],
    imports: [
        CommonModule,
        MyMaterialModule,
        FormsModule,
        CoffeeModule,
    ],
})
export class ExternalModule { }
