<mat-toolbar class="main-toolbar toolbar-normal-color" [ngClass]="{'accent': overLimit || paidDaysLeft <= 3, 'opaque': opaque, 'position-relative': relative, 'readOnly': loggedInUser?.readonly === true, 'is-small': isSmall$ | async}">
    <!-- <mat-toolbar-row> -->
    @if (loggedInUser?.nickname) {
    <img (click)="drawer?.toggle()" (keydown.enter)="drawer?.toggle(); $event.preventDefault()" role="button" [tabindex]="0" [src]="'assets/images/artisan-plus' + (isDarkmode ? '-inverted' : '') + '.svg'" class="plusicon" [matTooltip]="latestWebVersion" alt="the artisan plus logo; opens and closes the menu">
    @if (!(loggedInUser.readonly_account_idx >= 0)) {
    <span class="toolbar-smaller-font">
        @if (loggedInUser.account?.subscription) {
        <span style="margin-right: 10px;">
            {{loggedInUser.account.subscription}}
        </span>
        }
        @if (overLimit || 0 > paidDaysLeft || (31 > paidDaysLeft && !loggedInUser.readonly)) {
        <span [ngClass]="{'toolbar-warning': paidDaysLeft <= 3}" style="line-height: 5px">
            @if (overLimit && 500 >= loggedInUser.account?.limit?.rlimit) {
            <span style="margin-right: 10px;"><a [href]="'https://buy.artisan.plus/' + localeShop + '1000kg-extra'" target="_blank" rel="noopener" i18n="toolbar$$indicate user is over the current limit@@over limit" matTooltip="Please visit our shop to extend your subscription" i18n-matTooltip="toolbar$$tooltip with hint to our store@@visit our store" class="expired">OVER LIMIT</a></span>
            } @else if (overLimit) {
            <span style="margin-right: 10px;"><a [href]="'https://buy.artisan.plus/' + localeShop + 'PRO'" target="_blank" rel="noopener" i18n="toolbar$$indicate user is over the current limit@@over limit" matTooltip="Please visit our shop to extend your subscription" i18n-matTooltip="toolbar$$tooltip with hint to our store@@visit our store" class="expired">OVER LIMIT</a></span>
            }
            @if (0 > paidDaysLeft) {
            <a [href]="'https://buy.artisan.plus/' + localeShop" target="_blank" rel="noopener" i18n="toolbar$$indicate user doesn't have a current paid account@@not paid" matTooltip="Please visit our shop to extend your subscription" i18n-matTooltip="toolbar$$tooltip with hint to our store@@visit our store" class="expired">EXPIRED</a>
            } @else if (paidDaysLeft === 0) {
            <a [href]="'https://buy.artisan.plus/' + localeShop" target="_blank" rel="noopener" i18n="toolbar$$last day of paid service@@0 paid days left" matTooltip="Please visit our shop to extend your subscription" i18n-matTooltip="toolbar$$tooltip with hint to our store@@visit our store" class="expired">0 days left</a>
            } @else if (paidDaysLeft === 1) {
            <a [href]="'https://buy.artisan.plus/' + localeShop" target="_blank" rel="noopener" i18n="toolbar$$one more day before the paid service expires@@1 paid days left" matTooltip="Please visit our shop to extend your subscription" i18n-matTooltip="toolbar$$tooltip with hint to our store@@visit our store" class="expired">1 day left</a>
            } @else if (paidDaysLeft === 2) {
            <a [href]="'https://buy.artisan.plus/' + localeShop" target="_blank" rel="noopener" i18n="toolbar$$two more days before the paid service expires@@2 paid days left" matTooltip="Please visit our shop to extend your subscription" i18n-matTooltip="toolbar$$tooltip with hint to our store@@visit our store" class="expired">2 days left</a>
            } @else if (paidDaysLeft === 3) {
            <a [href]="'https://buy.artisan.plus/' + localeShop" target="_blank" rel="noopener" i18n="toolbar$$three more days before the paid service expires@@3 paid days left" matTooltip="Please visit our shop to extend your subscription" i18n-matTooltip="toolbar$$tooltip with hint to our store@@visit our store" class="expired">3 days left</a>
            } @else if (paidDaysLeft > 3 && showPaid && 31 > paidDaysLeft) {
            <!-- <br *ngIf="isSmall$ | async" /> -->
            <a [href]="'https://buy.artisan.plus/' + localeShop" data-test="lnk_visitShop"
                style="font-size: 80%; vertical-align: middle;" target="_blank" rel="noopener" i18n="toolbar$$link showing until when user has paid and how to extend@@paid until, extend here"
                matTooltip="You can always visit our shop to extend your subscription" i18n-matTooltip="users$$link to our shop@@You can always visit our shop to extend your subscription">Paid until {{paidUntil.toLocaleString(DateTime.DATE_SHORT)}} (extend here)</a>
            }
        </span>
        }
        @if (loggedInUser.readonly && (0 > paidDaysLeft || loggedInUser.account?.subscription)) {
        <br />
        }
        @if (loggedInUser.account?.limit?.rlimit) {
        <span [ngClass]="{'expired': loggedInUser.account?.limit?.rused > 0.9 * loggedInUser.account?.limit?.rlimit}">
            @if (!(loggedInUser.readonly && (0 > paidDaysLeft || loggedInUser.account?.subscription))) {
            <br />
            }
            @if (loggedInUser.account.limit.rused) {
            {{(loggedInUser.account.limit.rused / loggedInUser.account.limit.rlimit * 100).toFixed(0)}}% <span i18n="graphs$$graph tooltips@@roasted">roasted</span> ({{utils.formatAmount(loggedInUser.account.limit.rused, undefined, loggedInUser.unit_system, loggedInUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL && loggedInUser.account.limit.rlimit > 500 ? 1 : 0)}} / {{utils.formatAmount(loggedInUser.account.limit.rlimit, undefined, loggedInUser.unit_system, loggedInUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL && loggedInUser.account.limit.rlimit > 500 ? 1 : (loggedInUser.account.limit.rlimit > 0 ? 1 : 0))}})
            }
        </span>
        }
        @if (loggedInUser.readonly && (isSmall$ | async) === false) {
        <ng-container style="margin-left: 0">
            <span i18n="toolbar$$comment for read-only mode@@No changes possible">No changes possible</span>
        </ng-container>
        }
    </span>
    }
    @if (loggedInUser.readonly_account_idx >= 0 && loggedInUser.readonly && (isSmall$ | async) === false) {
    <span style="line-height: 20px; margin-left: 0" i18n="toolbar$$comment for read-only mode@@No changes possible">No changes possible</span>
    }
    <span class="fill-remaining-space"></span>
    @if ((isSmall$ | async) === false) {
    <div class="login toolbartext greeting" style="margin: 0 5px; padding: 0 5px;">
        <span i18n="toolbar$$greeting, before user nickname@@greeting" class="littlespaceright">Hi</span>
        @if (!(loggedInUser.readonly_account_idx >= 0)) {
        <a routerLink="/settings" data-test="lnk_nickname"
            routerLinkActive="active">{{loggedInUser.nickname.indexOf('@') >= 0 ? loggedInUser.nickname.split('@')[0] : loggedInUser.nickname}}<mat-icon class="settings-icon-small" style="margin-left: 3px;">settings</mat-icon>
        </a>
        } @else {
        <span>{{loggedInUser.nickname.indexOf('@') >= 0 ? loggedInUser.nickname.split('@')[0] : loggedInUser.nickname}}</span>
        }
    </div>
    } @else {
    @if (!(loggedInUser.readonly_account_idx >= 0)) {
    <span class="login">
        <a routerLink="/settings" style="vertical-align: text-bottom;" routerLinkActive="active">
            <mat-icon>settings</mat-icon>
        </a>
    </span>
    }
    }
    @if (loggedInUser.other_accounts?.length) {
    <div class="account-div" style="height: 35px;">
        <mat-form-field class="account-selector" subscriptSizing="dynamic">
            <mat-select name="account" panelClass="account-selector"
                [(ngModel)]="readonlyAccountIdx" (selectionChange)="accountChanged()"
                panelWidth="null">
                <mat-select-trigger>{{accountLabel}}</mat-select-trigger>
                <mat-option [value]="-1" i18n="toolbar$$short for _your account_, e.g. You@@Your account">Your account</mat-option>
                @for (acc of loggedInUser.other_accounts; track acc?._id) {
                <mat-option [value]="$index" class="option-flex">
                    <span>{{acc?.nickname}} - {{acc?.email}}</span>
                    <mat-icon (click)="removeViewerFromMyAccount(acc)" style="color: var(--primary-color);">delete</mat-icon>
                </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    }
    <mat-form-field class="language-selector no-height-change" style="height: 22px">
        <mat-select name="language"
            panelClass="language-flag-select" panelWidth="null"
            [(ngModel)]="loggedInUser.language" (selectionChange)="languageChanged()">
            <mat-select-trigger><img [src]="'assets/icons/lang/' + loggedInUser.language + '.svg'" [alt]="loggedInUser.language" class="flag-size"></mat-select-trigger>
            @if (window.location.pathname.substring(0, 3) !== '/zz' && window.location.pathname.substring(0, 3) !== '/xy') {
            <mat-option value="en" class="language-selector-option"><img src="assets/icons/lang/en.svg" alt="English" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="en-GB" class="language-selector-option"><img src="assets/icons/lang/en-GB.svg" alt="English (British)" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="de" class="language-selector-option"><img src="assets/icons/lang/de.svg" alt="Deutsch" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="it" class="language-selector-option"><img src="assets/icons/lang/it.svg" alt="Italiano" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="fr" class="language-selector-option"><img src="assets/icons/lang/fr.svg" alt="Français" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="pt" class="language-selector-option"><img src="assets/icons/lang/pt.svg" alt="Português" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="zh" class="language-selector-option"><img src="assets/icons/lang/zh.svg" alt="中文" width="45px" height="30px" class="flag-img"></mat-option>
            }
            @if (window.location.pathname.substring(0, 3) === '/xy') {
            <mat-option value="xy"><img src="assets/icons/lang/xy.svg" alt="xy" width="45px" height="30px" class="flag-img"></mat-option>
            }
            @if (window.location.pathname.substring(0, 3) === '/zz') {
            <mat-option value="zz"><img src="assets/icons/lang/zz.svg" alt="zz" width="45px" height="30px" class="flag-img"></mat-option>
            }
        </mat-select>
    </mat-form-field>
    @if (isSmallish$ | async) {
    <a class="login" routerLink="/logout" routerLinkActive="active">
        <mat-icon>power_settings_new</mat-icon>
    </a>
    } @else {
    <a class="login toolbartext" routerLink="/logout" routerLinkActive="active" i18n="toolbar$$logout link@@logout">LOGOUT</a>
    }
    <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/docs'" target="_blank" rel="noopener" class="login" style="padding-right: 0">
        <mat-icon>{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
    </a>
    } @else { <!-- @if (loggedInUser?.nickname) -->
    <a class="imagehomelink" routerLink="/login" [queryParams]="{returnUrl: returnUrl}" routerLinkActive="active"><img
            [src]="'assets/images/artisan-plus' + (isDarkmode ? '-inverted' : '') + '.svg'" class="plusicon" alt="the artisan plus logo; opens and closes the menu"></a>
    <span class="fill-remaining-space"></span>
    <div class="for-artisan-info" [style.display]="(isSmall$ | async) ? 'none' : 'block'">
        <span class="spacearound" i18n="toolbar$$_for_ Artisan, linking to artisan-scope.org@@for">for</span> <a data-test="lnk_artisan" style="padding-right:20px"
            href="https://artisan-scope.org/" target="_blank" rel="noopener">Artisan</a>
    </div>
    <mat-form-field data-test="slct_language" class="language-selector" style="height: 22px;">
        <mat-select name="signedOutLanguage"
            panelClass="language-flag-select" panelWidth="null"
            [(ngModel)]="signedOutLanguage" (selectionChange)="languageChanged()">
            <mat-select-trigger><img [src]="'assets/icons/lang/' + signedOutLanguage + '.svg'" [alt]="signedOutLanguage" class="flag-size"></mat-select-trigger>
            @if (window.location.pathname.substring(0, 3) !== '/zz' && window.location.pathname.substring(0, 3) !== '/xy') {
            <mat-option value="en" class="language-selector-option"><img src="assets/icons/lang/en.svg" alt="English" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="en-GB" class="language-selector-option"><img src="assets/icons/lang/en-GB.svg" alt="English (British)" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="de" class="language-selector-option"><img src="assets/icons/lang/de.svg" alt="Deutsch" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="it" class="language-selector-option"><img src="assets/icons/lang/it.svg" alt="Italiano" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="fr" class="language-selector-option"><img src="assets/icons/lang/fr.svg" alt="Français" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="pt" class="language-selector-option"><img src="assets/icons/lang/pt.svg" alt="Português" width="45px" height="30px" class="flag-img"></mat-option>
            <mat-option value="zh" class="language-selector-option"><img src="assets/icons/lang/zh.svg" alt="中文" width="45px" height="30px" class="flag-img"></mat-option>
            }
            @if (window.location.pathname.substring(0, 3) === '/xy') {
            <mat-option value="xy"><img src="assets/icons/lang/xy.svg" alt="xy"></mat-option>
            }
            @if (window.location.pathname.substring(0, 3) === '/zz') {
            <mat-option value="zz"><img src="assets/icons/lang/zz.svg" alt="zz"></mat-option>
            }
        </mat-select>
    </mat-form-field>
    <a class="login toolbartext" data-test="lnk_register" routerLink="/register" routerLinkActive="active"
        i18n="toolbar$$link to registration page@@register link">REGISTER</a>&nbsp;|&nbsp;
    <a class="login toolbartext" data-test="lnk_login" routerLink="/login" [queryParams]="{returnUrl: returnUrl}" routerLinkActive="active" i18n="users$$link to login page@@login link">LOGIN</a>
    <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/docs'" target="_blank" rel="noopener" class="login" style="padding-right: 0">
        <mat-icon class="toolbar-help-icon" style="vertical-align: middle;">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
    </a>
    }
    <!-- </mat-toolbar-row> -->
</mat-toolbar>