@if (!data.readonly) {
<h1 mat-dialog-title i18n="multi users$$dialog header for invitation dialog@@add user to account">Add full user to your account</h1>
} @else {
<h1 mat-dialog-title i18n="multi users$$button for read-only invitation dialog@@invite for read-only access">Invite for read-only access</h1>
}
<mat-dialog-content>
    <mat-form-field class="form-group" hideRequiredMarker>
        <input autofocus i18n-placeholder="users$$email address@@email" matInput autocomplete="email"
            placeholder="Email" type="email" name="email" [(ngModel)]="email" required email
            #emailFormModel="ngModel" [appExistingEmail]="data.existingEmails">
    </mat-form-field>
    @if (email && emailFormModel.invalid && (emailFormModel.dirty || emailFormModel.touched)) {
    <div
        class="alert alert-danger" style="margin-bottom: 20px; margin-top: -15px; position: relative;">
        @if (emailFormModel.errors.existingEmail) {
        <div>
            <ng-container i18n="multi users$$prevent adding same user twice@@User with email already invited">User with email {{email}} already invited</ng-container>
        </div>
        }
        @if (emailFormModel.errors.email) {
        <div>
            <ng-container i18n="multi users$$prevent adding user with invalid email@@invalid email">invalid email</ng-container>
        </div>
        }
    </div>
    }
    @if (adding) {
    <mat-spinner class="lifted-spinner" diameter="20"></mat-spinner>
    }

    @if (!data.readonly) {
    <div style="margin-bottom: 10px" i18n="multi users$$explains what it means to invite another user@@get full access">The person will get full access to your data.</div>
    } @else {
    <div style="margin-bottom: 10px" i18n="multi users$$explains what it means to invite another read-only viewer@@get read-only access">The person can read all your data.</div>
    }

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button type="button" class="cancel-button" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <button mat-raised-button type="button" class="action-button" type="submit" (click)="add()" [disabled]="!email || adding || emailFormModel.invalid">
        <mat-icon>done</mat-icon>
    </button>
</mat-dialog-actions>