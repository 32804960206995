<div class="file-show">
    @if (files) {
    @for (file of files; track file) {
    <div class="flex-center">
        @if (!readOnly) {
        <button mat-icon-button type="button" class="tertiary-element"
            (click)="removeFile(file)">
            <mat-icon>delete</mat-icon>
        </button>
        }
        <a [href]="getFileLink(file)" target="_blank" rel="noopener">{{getFileName(file)}}</a>
        @if (getFileAlog(file); as alink) {
        &nbsp;<a style="vertical-align: text-top" [href]=alink target="_blank" rel="noopener" matTooltip="Open in Artisan" i18n-matTooltip="roasts$$tooltip load as template in Artisan app@@Open in Artisan">
            <mat-icon class="helpicon" svgIcon="artisan-blue"></mat-icon>
        </a>
        }
    </div>
    }
    }
    @if (!readOnly) {
    <div class="upload-link">
        <a (click)="openUploadDialog()" (keydown.enter)="openUploadDialog(); $event.preventDefault()" role="button" [tabindex]="0" i18n="dialogs$$dialog link to upload documents@@Upload documents" class="cursor-pointer">Upload documents</a>
    </div>
    }
</div>