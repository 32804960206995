import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatSnackBarRef, SimpleSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-update-snack',
    templateUrl: './update-snack.component.html',
    styleUrls: ['./update-snack.component.scss'],
})
export class UpdateSnackComponent extends SimpleSnackBar implements OnInit, OnDestroy {
    constructor(
        public snackBarRef: MatSnackBarRef<SimpleSnackBar>,
        @Inject(MAT_SNACK_BAR_DATA) data: { message: string, action: string },
        private userService: UserService,
    ) {
        // data.message and data.action will be empty; we use custom messages in the html
        super(snackBarRef, data);
    }

    private ngUnsubscribe = new Subject();
    isDarkmode = false;

    ngOnInit(): void {
        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm)
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    // should override accessor from SimpleSnackBar
    // eslint-disable-next-line @typescript-eslint/class-literal-property-style
    get hasAction(): boolean {
        return true;
    }
}
