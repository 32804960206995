import { Account } from './Account';
import { Tag } from './Tag';
import { Coffee } from './Coffee';
import { User } from './User';

export class Blend {
    _id: string;
    created_at?: Date;
    updated_at?: Date;
    deleted?: boolean;
    internal_hr_id?: number;
    hr_id?: string;
    label: string;
    image?: string;
    files?: string[];
    owner?: Account;
    ingredients?: { ratio_denom?: number, ratio: number, coffee: Coffee, replace_coffee?: Coffee, ratio_num?: number }[];
    notes?: string;
    tags?: Tag[];
    created_by?: User;
    updated_by?: User;
    // only in client
    organic?: boolean;
    curStock?: number;
    totalStock?: number;
    totalStockStr?: string;
    blendinfo?: string;
}

