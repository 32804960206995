import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { Location } from 'src/app/models/Location';
import { CorrectDialogComponent, CorrectDialogResultType } from 'src/app/modules/transaction/dialogs/correct-dialog.component';
import { Subject, takeUntil, throttleTime } from 'rxjs';
import { ShareMostRecentTransactionsService } from 'src/app/util/services/share-mostrecenttransactions.service';
import { StockChange } from 'src/app/models/StockChange';
import { TransService } from 'src/app/modules/transaction/trans.service';
import { ShowPurchasesDialogComponent } from 'src/app/modules/store/show-purchases-dialog.component';
import { Router } from '@angular/router';
import { Coffee } from 'src/app/models/Coffee';
import { environment } from 'src/environments/environment';
import { CantDeleteDialogComponent } from '../ui/dialog/cant-delete-dialog.component';
import { StandardService } from 'src/app/util/services/standard.service';
import { DateTime } from 'luxon';

export interface StockType {
    coffeeLabel: string;
    coffeeId: string; // MongoDB _id
    coffee_internal_hr_id: number; // of the coffee
    default_unit: { name: Enumerations.CoffeeUnits, size: number };
    amount: number;
    cost: number;
    fifo_cost: number;
    location: Location; // undefined when returned from server
    location_id: string;
    location_internal_hr_id ?: number;
    low_limit: number;
    origin ?: string; // of the coffee
    yearLabel ?: string; // crop year of the coffee (see utils.createBeansYearLabel)
    certInfo ?: Enumerations.CertificationTypes;
}

@Component({
    selector: 'app-store-stock',
    templateUrl: './store-stock.component.html',
    styleUrls: ['./store-stock.component.scss'],
    providers: []
})
export class StoreStockComponent implements OnInit, OnDestroy {
    checkingIfDeletable: string;

    constructor(
        private userService: UserService,
        public utils: Utils,
        private dialog: MatDialog,
        private shareMostRecentTransactions: ShareMostRecentTransactionsService,
        private transService: TransService,
        private standardService: StandardService,
        public tr: TranslatorService,
        private router: Router,
    ) { }

    @Input() currentUser: UserType;
    @Input() readOnly = false;
    mainUnit: UnitSystemType = 'kg';
    mainUnitSingular = 'kg';
    currency = 'EUR';

    isOrganic = false;
    isSorting = false;
    // isSortingTimer: ReturnType<typeof setTimeout>;
    isSortingTimer;

    @Input() store: Location;
    @Input() allStocks: StockType[][];

    private _stocks: StockType[];
    get stocks(): StockType[] { return this._stocks }
    @Input() set stocks(stks: StockType[]) {
        this._stocks = stks;
        this.sortAccordingToRecentTransaction();
        // make sure table is updated; MatTableDataSource does not update automatically
        this.dataSource.data = this._stocks;
    }
    lastTransactions: Map<string, StockChange>;

    dataSource = new MatTableDataSource();
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    columnsToDisplay = ['coffee_internal_hr_id', 'amount', 'cost'];

    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();

    Array = Array;
    Enumerations = Enumerations;
    DateTime = DateTime;

    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
            this.mainUnitSingular = 'lb';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        this.dataSource.sort = this.sort;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.isSortingTimer);
    }

    private sortAccordingToRecentTransaction(): void {
        this.isSortingTimer = setTimeout(() => {
            this.isSorting = true;
        }, 600);
        if (this.dataSource.sort) {
            this.dataSource.sort.sort(({ id: 'transaction', start: 'desc', disableClear: true }));
        }
        if (!this.lastTransactions) {
            this.lastTransactions = this.shareMostRecentTransactions.getTransactions();
        }
        if (this.lastTransactions?.size > 0) {
            this.stocks.sort((a, b) => {
                const transA = this.lastTransactions.get('C' + a.coffee_internal_hr_id + '_' + (this.store.hr_id ? this.store.hr_id : 'L' + this.store.internal_hr_id));
                const transB = this.lastTransactions.get('C' + b.coffee_internal_hr_id + '_' + (this.store.hr_id ? this.store.hr_id : 'L' + this.store.internal_hr_id));
                if (!transA && !transB) {
                    return 0;
                } else if (!transA) {
                    return 1;
                } else if (!transB) {
                    return -1;
                } else if (transA.date > transB.date) {
                    return -1;
                } else if (transA.date < transB.date) {
                    return 1;
                } else {
                    return 0;
                }
            });
            this.dataSource.data = this.stocks;
        }
        clearTimeout(this.isSortingTimer);
        this.isSorting = false;
    }

    /**
     * This gets called from the store's transaction component when it has
     * finished loading all tranactions - which means the
     * shareMostRecentTransactions.getTransactions() will be up to date
     * Sort the stock data (most recent transaction first)
     */
    allTransactionsLoaded(): void {
        this.lastTransactions = this.shareMostRecentTransactions.getTransactions();
        this.sortAccordingToRecentTransaction();
    }

    deleteTransaction(transaction: StockChange): void {
        if (this.readOnly) { return; }

        this.checkingIfDeletable = transaction._id;
        this.standardService.getRefs('stockchanges', transaction._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.checkingIfDeletable = undefined;
                    if (response.success === true) {
                        if (response.result.count > 0) {
                            const ondate = this.tr.anslate('on {{date}}', { date: transaction.date.toLocaleString(DateTime.DATE_MED) });
                            const dialogRef = this.dialog.open(CantDeleteDialogComponent, {
                                closeOnNavigation: true,
                                data: {
                                    label: `${transaction.translated_type}: ${(transaction.amount * this.utils.getUnitFactor(this.mainUnit)).toFixed(2)}${this.mainUnit} ${ondate}`,
                                    count: response.result.count,
                                    refs: this.utils.dateifyObjects(response.result.refs, ['date']),
                                    mainUnit: this.mainUnit,
                                    currency: this.currency
                                }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDeleteTransaction(transaction);
                                }
                            });

                        } else {
                            this.doDeleteTransaction(transaction);
                        }
                    } else {
                        this.utils.handleError('could not delete the data', response.error);
                    }
                },
                error: error => {
                    this.checkingIfDeletable = undefined;
                    this.utils.handleError('could not delete the data', error);
                }
            });
    }

    private doDeleteTransaction(transaction: StockChange): void {
        this.transService.deleteTransaction(transaction, this.ngUnsubscribe, () => {
            this.shareMostRecentTransactions.removeTransaction(
                transaction.coffee.hr_id ? transaction.coffee.hr_id : 'C' + transaction.coffee.internal_hr_id,
                this.store.hr_id ? this.store.hr_id : 'L' + this.store.internal_hr_id
            );
            if (transaction['target']) {
                this.shareMostRecentTransactions.removeTransaction(
                    transaction.coffee.hr_id ? transaction.coffee.hr_id : 'C' + transaction.coffee.internal_hr_id,
                    undefined, transaction['target']._id || transaction['target']
                );
            }
        });
    }

    getMostRecentPerCoffee() {
        throw new Error('Method not implemented.');
    }

    editTransaction(transaction: StockChange): void {
        if (this.readOnly) { return; }

        this.transService.editTransaction(transaction, this.ngUnsubscribe, trans => {
            transaction = trans;
            this.shareMostRecentTransactions.setTransaction(
                trans.coffee.hr_id ? trans.coffee.hr_id : 'C' + trans.coffee.internal_hr_id,
                this.store.hr_id ? this.store.hr_id : 'L' + this.store.internal_hr_id,
                trans);
        });
    }

    getAmount(stock: StockType): { pre: string, value: number, post: string } {
        return this.utils.formatAmountForPipe(stock?.amount, stock?.default_unit, this.currentUser.unit_system);
    }

    changeAmount(stock: StockType): void {
        if (this.readOnly) { return; }
        if (!stock) { return; }

        const dialogRef = this.dialog.open(CorrectDialogComponent, {
            closeOnNavigation: true,
            data: {
                stores: [this.store],
                stocks: [this.stocks],
                preSelected: 0,
                coffees: [{
                    label: stock.coffeeLabel, internal_hr_id: stock.coffee_internal_hr_id,
                    yearLabel: stock.yearLabel,
                    origin: stock.origin, _id: stock.coffeeId,
                    default_unit: stock.default_unit
                }]
            }
        });
        dialogRef.componentInstance.finished.subscribe(dialogResult => {
            if (dialogResult instanceof CorrectDialogResultType) {
                this.transService.handleCorrectionDialogClosed(dialogResult, dialogRef, stock, this.stocks, undefined, [this.store],
                    stock.coffeeId as unknown as Coffee, undefined, this.ngUnsubscribe);
                }
        });
    }

    changeCost(stock: StockType): void {
        if (!stock || !this.stocks) { return; }
        // calculate the total amount across all stocks (stores)
        const totalAmount = this.allStocks.reduce((prev, stocks) => prev + stocks.filter(s => s.coffeeId?.toString() === stock.coffeeId?.toString()).reduce((prev, stock) => prev + stock.amount, 0), 0);
        const dialogRef = this.dialog.open(ShowPurchasesDialogComponent, {
            closeOnNavigation: true,
            data: {
                readOnly: this.readOnly,
                coffee: stock.coffeeId,
                totalAmount,
            }
        });

        dialogRef.afterClosed().subscribe(trans => {
            if (!trans || this.readOnly) {
                return;
            }
            this.editTransaction(trans);
        });
    }
}
