import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { RemindersComponent } from './reminders.component';
import { ReminderComponent } from './reminder.component';
import { FormsModule } from '@angular/forms';
import { RemindersService } from './reminder.service';
import { RenameMachineNamesDialogComponent } from './renamemachinenames-dialog.component';
import { ModificationDateModule } from '../ui/modification-date.module';
import { ReminderlogtableComponent } from './reminderlogtable.component';
import { RouterModule } from '@angular/router';
import { AngularSplitModule } from 'angular-split';
// import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
    declarations: [
        RemindersComponent,
        ReminderComponent,
        ReminderlogtableComponent,
        RenameMachineNamesDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MyMaterialModule,
        AngularSplitModule,
        ModificationDateModule,
        RouterModule,
        // MatNativeDateModule,
    ],
    providers: [
        RemindersService,
    ]
})
export class ReminderModule { }
