export class AcaiaConstants {
    // Acaia Pearl, Lunar:
    static DEVICE_NAME_PEARL = 'PROCHBT';
    static DEVICE_NAME_LUNAR = 'ACAIA';

    // Acaia Pearl S:
    static DEVICE_NAME_PEARLS = 'PEARLS';

    // Acaia Lunar(2021):
    static DEVICE_NAME_LUNAR2021 = 'LUNAR-';

    // Acaia Pyxis:
    static DEVICE_NAME_PYXIS = 'PYXIS';

    // Acaia Pearl(2021):
    static DEVICE_NAME_PEARL2021 = 'PEARL-';

    // for the above older models
    static SERVICE_UUID_LEGACY = '00001820-0000-1000-8000-00805f9b34fb';
    static CHAR_UUID_LEGACY = '00002a80-0000-1000-8000-00805f9b34fb';

    // for the above newer models
    static SERVICE_UUID = '49535343-fe7d-4ae5-8fa9-9fafd205e455';
    static CHAR_UUID = '49535343-1e4d-4bd9-ba61-23c647249616';
    static CHAR_UUID_WRITE = '49535343-8841-43f4-a8d4-ecbe34729bb3';

    static HEADER1 = 0xef;
    static HEADER2 = 0xdd;

    static MSG_SYSTEM = 0;
    // static MSG_TARE = 4;
    static MSG_INFO = 7;
    static MSG_STATUS = 8;
    static MSG_IDENTIFY = 11;
    static MSG_EVENT = 12;
    // static MSG_TIMER = 13;
    static EVENT_WEIGHT = 5;
    // static EVENT_BATTERY = 6;
    // static EVENT_TIMER = 7;
    // static EVENT_KEY = 8;
    // static EVENT_ACK = 11;
    static EVENT_WEIGHT_LEN = 6;
    // static EVENT_BATTERY_LEN = 1;
    // static EVENT_TIMER_LEN = 3;
    // static EVENT_KEY_LEN = 1;
    // static EVENT_ACK_LEN = 2;
    // static TIMER_START = 0;
    // static TIMER_STOP = 1;
    // static TIMER_PAUSE = 2;
    // static TIMER_STATE_STOPPED = 0;
    // static TIMER_STATE_STARTED = 1;
    // static TIMER_STATE_PAUSED = 2;
    // Acaia Protocol Parser
    static E_PRS_CHECKHEADER1 = 0;
    static E_PRS_CHECKHEADER2 = 1;
    static E_PRS_CMDID = 2;
    static E_PRS_CMDDATA = 3;
    static E_PRS_CHECKSUM1 = 4;
    static E_PRS_CHECKSUM2 = 5;

    static NEW_CMD_SYSTEM_SA = 0;
    static NEW_CMD_INFO_A = 7;
    static NEW_CMD_STATUS_A = 8;
    static NEW_CMD_EVENT_SA = 12;
}
