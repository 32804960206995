import { UserType } from 'src/app/modules/frame/services/user.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { StandardService } from 'src/app/util/services/standard.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Location } from 'src/app/models/Location';
import { Coffee } from 'src/app/models/Coffee';
import { Enumerations } from 'src/app/models/Enumerations';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/User';
// import { NGXLogger } from 'ngx-logger';
import { Purchase } from 'src/app/models/Purchase';
import { Supplier } from 'src/app/models/Supplier';
import { MatStepper } from '@angular/material/stepper';
import { ServerLogService } from 'src/app/util/services/server-log.service';
import { SupplierPartner } from 'src/app/models/SupplierPartner';
import cloneDeep from 'lodash-es/cloneDeep';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

    constructor(
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private tr: TranslatorService,
        public utils: Utils,
        private alertService: AlertService,
        private standardService: StandardService,
        private serverLogService: ServerLogService,
        private cd: ChangeDetectorRef,
    ) { }

    currentUser: UserType;
    readOnly = false;

    location: Location = new Location();
    coffee: Coffee = new Coffee();
    supplier: Supplier;
    newLocationLabel: string;
    newCoffeeLabel: string;
    newSupplierLabel: string;
    locations: Location[] = [];
    coffees: Coffee[] = [];
    suppliers: Supplier[] = [];
    allCountries: { val: string, tr: string, cur: string, cont: string }[] = [];
    countries: { val: string, tr: string, cur: string, cont: string }[] = [];
    userCountry: { val: string, tr: string, cur: string, cont: string };
    currenciesWithoutEURUSD: string[] = [];

    amount = 1;
    unit: { name: Enumerations.CoffeeUnits, size: number } = { name: Enumerations.CoffeeUnits._NONE, size: undefined };
    mainUnit: UnitSystemType = 'kg';
    mainUnitSingular = 'kg';
    unitIdx = 0;
    price = 0;
    pricePerUnit = 0;

    canEdit = true;
    saving = false;
    saved = false;
    error = false;
    retrievingSuppliers = false;

    isDarkmode = false;
    waitingForChanges = false;

    Enumerations = Enumerations;

    private ngUnsubscribe = new Subject();

    @ViewChild('wizardStepper') stepper: MatStepper;

    findUserCountry(countryVal: string): { val: string, tr: string, cur: string, cont: string } {
        if (!countryVal || !this.allCountries?.length) {
            return undefined;
        }
        for (const exCountry of this.allCountries) {
            if (exCountry.val === countryVal || exCountry.tr === countryVal) {
                return exCountry;
            }
        }
        return undefined;
    }

    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (this.currentUser) {
            if (this.currentUser.readonly === true) {
                this.router.navigate(['/stores']);
            }

            if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
                this.mainUnit = 'lbs';
                this.mainUnitSingular = 'lb';
            }

            this.getExistingLocations();
            this.getExistingCoffees();
            this.allCountries = Utils.getAllCountriesInfos();
            const curs = [...new Set(this.allCountries.map(info => info.cur))];
            this.currenciesWithoutEURUSD = curs.filter(cur => cur !== 'EUR' && cur !== 'USD').sort();
            this.userCountry = this.findUserCountry(this.currentUser.country);
        } else {
            this.userService.navigateToLogin(this.router.url);
        }

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm)
            );
    }

    settingChanged(unitSystemChanged = false): void {
        this.userService.updateUser({ unit_system: this.currentUser.unit_system, temperature_system: this.currentUser.temperature_system, country: this.currentUser.country, account: { currency: this.currentUser.account.currency } } as User)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.userService.storeCurrentUser(this.currentUser);
                        if (unitSystemChanged) {
                            if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
                                this.mainUnit = 'lbs';
                                this.mainUnitSingular = 'lb';
                            } else {
                                this.mainUnit = 'kg';
                                this.mainUnitSingular = 'kg';
                            }
                            // avoid ExpressionChangedAfterCheck error for slct_unitIdx
                            this.cd.detectChanges();
                        }
                        this.alertService.success(this.tr.anslate('Successfully updated'));

                    } else {
                        this.utils.handleError('error updating user information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating user information', error);
                }
            });
    }

    changeCountryFilter(): void {
        if (!this.userCountry) {
            this.countries = this.allCountries;
            return;
        }
        if (this.allCountries) {
            const filterValue = (this.userCountry.tr || this.userCountry as unknown as string)?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.countries = this.allCountries.filter(myCountry => myCountry && myCountry.tr.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0);
        }
    }

    countryChanged(event?: FocusEvent): void {
        let changed = false;
        let unitSystemChanged = false;
        if (event?.target && event.target['value']) {
            this.userCountry = this.findUserCountry(event.target['value']);
        }
        if (this.userCountry?.cur && this.currentUser.account.currency !== this.userCountry.cur) {
            this.currentUser.account.currency = this.userCountry.cur;
            changed = true;
        }
        if (this.userCountry?.val && this.currentUser.country !== this.userCountry.val) {
            this.currentUser.country = this.userCountry.val;
            this.getExistingSuppliers();
            changed = true;
        }
        if (['USA', 'Myanmar', 'Liberia'].includes(this.currentUser.country)) {
            // according to https://worldpopulationreview.com/country-rankings/countries-that-use-imperial
            if (this.currentUser.unit_system !== Enumerations.UNIT_SYSTEM.IMPERIAL) {
                this.currentUser.unit_system = Enumerations.UNIT_SYSTEM.IMPERIAL;
                changed = true;
                unitSystemChanged = true;
            }
        } else if (this.currentUser.unit_system !== Enumerations.UNIT_SYSTEM.METRIC) {
            this.currentUser.unit_system = Enumerations.UNIT_SYSTEM.METRIC;
            changed = true;
            unitSystemChanged = true;
        }
        if (['USA', 'Bahamas', 'Liberia', 'Palau', 'Micronesia', 'Marshall Islands'].includes(this.currentUser.country)) {
            // according to https://worldpopulationreview.com/country-rankings/countries-that-use-fahrenheit
            // Cayman Islands is missing here which we don't have as country
            if (this.currentUser.temperature_system !== Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT) {
                this.currentUser.temperature_system = Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT;
                changed = true;
            }
        } else if (this.currentUser.temperature_system !== Enumerations.TEMPERATURE_SYSTEM.CELSIUS) {
            this.currentUser.temperature_system = Enumerations.TEMPERATURE_SYSTEM.CELSIUS;
            changed = true;
        }

        if (changed) {
            this.settingChanged(unitSystemChanged);
        }
    }

    displayCountryFn(country?: string | { tr: string }): string | undefined {
        if (typeof country === 'string') {
            return country;
        }
        return country?.tr;
    }

    priceChanged(ppuChanged: boolean): void {
        const unitamount = this.amount * (this.unitIdx !== 0 && this.unit && this.unit.size || 1);
        if (ppuChanged) {
            // pricePerUnit changed
            this.price = this.pricePerUnit * unitamount;
        } else {
            // price changed
            this.pricePerUnit = unitamount ? this.price / unitamount : 0;
        }
    }

    changePrice(that?: WizardComponent, changedPrice?: string): void {
        if (!that) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            that = this;
        }
        that.priceChanged(changedPrice === 'pricePerUnit' || changedPrice === 'amount' || changedPrice === 'size');
    }

    reset(): void {
        this.amount = 1;
        this.price = 0;
        this.pricePerUnit = 0;
        this.unitIdx = 0;
        this.unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 };
        this.saving = false;
        this.saved = false;
        this.error = false;
        this.newCoffeeLabel = undefined;
        this.newLocationLabel = undefined;
        this.newSupplierLabel = undefined;
        this.location = undefined;
        if (this.locations?.length > 0) {
            this.location = this.locations[0];
            this.stepper.steps.first.completed = true;
        }
        if (this.suppliers?.length > 0) {
            this.supplier = this.suppliers[0];
        }
        this.coffee.label = undefined;
    }

    handleError(): void {
        this.reset();
        this.error = true;
        this.forward();
    }

    compareLabelFn(l1: Location, l2: Location): boolean {
        return l1 && l2 ? l1.label === l2.label : l1 === l2;
    }

    getExistingLocations(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.locations = response.result;
                        if (this.locations?.length > 0) {
                            this.location = this.locations[0];
                        }
                    } else {
                        this.utils.handleError('error getting locations', response.error);
                        this.handleError();
                    }
                },
                error: error => {
                    this.utils.handleError('error getting locations', error);
                    this.handleError();
                }
            });
    }

    getExistingCoffees(): void {
        this.standardService.getAll<Coffee>('coffees')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.coffees = response.result;
                    } else {
                        this.utils.handleError('error getting coffees', response.error);
                        this.handleError();
                    }
                },
                error: error => {
                    this.utils.handleError('error getting coffees', error);
                    this.handleError();
                }
            });
    }

    imageLoadError(supplier: Supplier): void {
        supplier['image'] = undefined;
    }

    getExistingSuppliers(): void {
        this.retrievingSuppliers = true;
        const country = this.currentUser.country;
        this.standardService.getSuppliersWithPartners(country)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.suppliers = response.result;
                        if (this.suppliers?.length) {
                            for (const sup of this.suppliers) {
                                if ((sup as SupplierPartner)?.partner) {
                                    sup['image'] = (sup as SupplierPartner).partner;
                                }
                            }
                            this.supplier = this.suppliers[0];
                        } else {
                            this.newSupplierLabel = this.tr.anslate('NEW');
                        }
                    } else {
                        this.utils.handleError('error getting suppliers', response.error);
                        this.handleError();
                    }
                    this.retrievingSuppliers = false;
                },
                error: error => {
                    this.utils.handleError('error getting suppliers', error);
                    this.handleError();
                    this.retrievingSuppliers = false;
                    this.serverLogService.errorOccurred(error, 'WizardComponent.getExistingSuppliers')
                        .pipe(throttleTime(environment.RELOADTHROTTLE))
                        .subscribe()
                }
            });
    }

    getUnits(): string[] {
        return this.utils.getUnits(this.amount !== 1);
    }

    getCurrentUnit(): string {
        // return Enumerations.CoffeeUnits[Object.keys(Enumerations.CoffeeUnits)[this.unitIdx]];
        const arr = Object.keys(Enumerations.CoffeeUnits);
        const str = arr[this.unitIdx];
        const en = Enumerations.CoffeeUnits[str];
        return this.tr.anslate(en);
    }

    forward(): void {
        this.router.navigate(['/stores']);
    }

    coffeeSelected($event: { value: string }): void {
        // this.logger.debug('coffeeSelected: ' + $event);
        for (const coffee of this.coffees) {
            if (coffee.label === $event.value) {
                this.coffee = cloneDeep(coffee);
                this.newCoffeeLabel = undefined;

                // convert default_unit in unitIdx
                if (typeof this.coffee.default_unit === 'undefined') {
                    this.unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 };
                    this.unitIdx = 0;
                    break;
                }
                let i = 0;
                for (const unit in Enumerations.CoffeeUnits) {
                    if (Enumerations.CoffeeUnits[unit] === this.coffee.default_unit.name) {
                        this.unitIdx = i;
                        this.unit = this.coffee.default_unit;
                        break;
                    }
                    i++;
                }
                break;
            }
        }
    }

    save(): void {
        if (this.readOnly) {
            return;
        }

        // 1) check if location already exists (checkLocation), if yes GOTO 3)
        // 2) add location (addLocationAndCoffee)
        // 3) check if coffee already exists (checkCoffee), if yes GOTO 5)
        // 4) add coffee (addCoffee)
        // 5) check if supplier already exists (checkSupplier), if yes GOTO 7)
        // 6) add supplier (addSupplier)
        // 7) create a Purchase to add the coffee at the location by the supplier (addStock)

        this.saving = true;
        this.checkLocation();
    }

    checkLocation(): void {
        if (this.newLocationLabel) {
            this.standardService.getLabelled<Location>('locations', this.newLocationLabel, { i: 'wizard' })
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true && typeof response.result !== 'undefined') {
                            // location already exists - ok
                            this.location = response.result;
                            this.checkCoffee(response.result);
                        } else if (response.success === true && typeof response.result === 'undefined') {
                            // location does not exist
                            this.location = new Location;
                            this.location.label = this.newLocationLabel;
                            this.location.type = [Enumerations.LocationTypes.STORE];
                            this.addLocationAndCoffee();
                        } else {
                            this.utils.handleError('error checking location ' + this.location.label, response.error);
                        }
                    },
                    error: error => {
                        if (error.status === 404) {
                            // location does not exist
                            this.location = new Location;
                            this.location.label = this.newLocationLabel;
                            this.location.type = [Enumerations.LocationTypes.STORE];
                            this.addLocationAndCoffee();
                        } else {
                            this.utils.handleError('error checking location ' + this.location.label, error);
                        }
                    }
                });
        } else {
            // user chose an existing location
            for (const loc of this.locations) {
                if (loc.label === this.location.label) {
                    this.location = cloneDeep(loc);
                    break;
                }
            }
            this.checkCoffee(this.location);
        }
    }

    addLocationAndCoffee(): void {
        // add new location
        this.standardService.add<Location>('locations', this.location)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.getExistingLocations();
                        this.checkCoffee(response.result);
                    } else {
                        this.utils.handleError('error adding location ' + this.location.label, response.error);
                        this.handleError();
                    }
                },
                error: error => {
                    this.utils.handleError('error adding location ' + this.location.label, error);
                    this.handleError();
                }
            });
    }

    checkCoffee(loc: Location): void {
        if (this.newCoffeeLabel) {
            this.standardService.getLabelled<Coffee>('coffees', this.newCoffeeLabel, { i: 'wizard' })
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true && typeof response.result !== 'undefined') {
                            // coffee already exists - ok
                            this.unit = { name: Enumerations.CoffeeUnits[Object.keys(Enumerations.CoffeeUnits)[this.unitIdx]], size: this.unit.size };
                            if (!this.unit.name) {
                                this.unit.size = 1;
                            }
                            this.coffee.default_unit = this.unit;
                            this.coffee._id = response.result._id;
                            this.checkSupplier(loc, this.coffee);
                        } else if (response.success === true && typeof response.result === 'undefined') {
                            // coffee does not exist
                            this.unit = { name: Enumerations.CoffeeUnits[Object.keys(Enumerations.CoffeeUnits)[this.unitIdx]], size: this.unit.size };
                            if (!this.unit.name) {
                                this.unit.size = 1;
                            }
                            this.coffee = new Coffee;
                            this.coffee.label = this.newCoffeeLabel;
                            this.coffee.default_unit = this.unit;
                            this.addCoffee(loc);
                        } else {
                            this.utils.handleError('error checking coffee ' + this.coffee.label, response.error);
                            this.handleError();
                        }
                    },
                    error: error => {
                        if (error.status === 404) {
                            // coffee does not exist
                            this.unit = { name: Enumerations.CoffeeUnits[Object.keys(Enumerations.CoffeeUnits)[this.unitIdx]], size: this.unit.size };
                            if (!this.unit.name) {
                                this.unit.size = 1;
                            }
                            this.coffee = new Coffee;
                            this.coffee.label = this.newCoffeeLabel;
                            this.coffee.default_unit = this.unit;
                            this.addCoffee(loc);
                        } else {
                            this.utils.handleError('error checking coffee ' + this.coffee.label, error);
                            this.handleError();
                        }
                    }
                });
        } else {
            // user chose an existing coffee
            this.checkSupplier(loc, this.coffee);
        }
    }

    checkSupplier(loc: Location, cof: Coffee): void {
        if (this.newSupplierLabel) {
            this.standardService.getLabelled<Supplier>('suppliers', this.newSupplierLabel, { i: 'wizard' })
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true && typeof response.result !== 'undefined') {
                            // supplier already exists - ok
                            this.supplier = response.result;
                            this.addStock(loc, cof, this.supplier);
                        } else if (response.success === true && typeof response.result === 'undefined') {
                            // supplier does not exist
                            this.supplier = new Supplier;
                            this.supplier.label = this.newSupplierLabel;
                            this.addSupplier(loc, cof);
                        } else {
                            this.utils.handleError('error checking supplier ' + this.supplier.label, response.error);
                            this.handleError();
                        }
                    },
                    error: error => {
                        if (error.status === 404) {
                            // supplier does not exist
                            this.supplier = new Supplier;
                            this.supplier.label = this.newSupplierLabel;
                            this.addSupplier(loc, cof);
                        } else {
                            this.utils.handleError('error checking supplier ' + this.supplier.label, error);
                            this.handleError();
                        }
                    }
                });
        } else {
            // user chose an existing supplier
            this.addStock(loc, cof, this.supplier);
        }
    }

    addCoffee(loc: Location): void {
        // Add new beans
        this.standardService.add<Coffee>('coffees', this.coffee)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.getExistingCoffees();
                        this.checkSupplier(loc, response.result);
                    } else {
                        this.utils.handleError('error adding coffee ' + this.coffee.label, response.error);
                        this.handleError();
                    }
                },
                error: error => {
                    this.utils.handleError('error adding coffee ' + this.coffee.label, error);
                    this.handleError();
                }
            });
    }

    addSupplier(loc: Location, cof: Coffee): void {
        // add new supplier
        this.standardService.add('suppliers', this.supplier)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.getExistingSuppliers();
                        this.addStock(loc, cof, response.result);
                    } else {
                        this.utils.handleError('error adding supplier ' + this.coffee.label, response.error);
                        this.handleError();
                    }
                },
                error: error => {
                    this.utils.handleError('error adding supplier ' + this.coffee.label, error);
                    this.handleError();
                }
            });
    }

    addStock(loc: Location, cof: Coffee, sup: Supplier): void {
        const purchase = new Purchase();
        delete purchase.reconciled;
        purchase.location = loc._id as unknown as Location;
        purchase.coffee = cof._id as unknown as Coffee;
        purchase.price = this.price;
        purchase.supplier = sup?._id as unknown as Supplier;
        purchase.date = DateTime.now();
        purchase.date = purchase.date.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
        if (cof.default_unit && !cof.default_unit.size) {
            cof.default_unit.size = 1;
        }
        purchase.amount = this.utils.convertToKg(this.amount, this.currentUser.unit_system) * (cof.default_unit?.size || 1);

        this.standardService.add<Purchase>('purchases', purchase)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                    } else {
                        this.utils.handleError('error updating the stock information', response.error);
                        this.handleError();
                    }
                    this.newCoffeeLabel = undefined;
                    this.newLocationLabel = undefined;
                    this.saving = false;
                    this.saved = true;
                    this.error = false;
                },
                error: error => {
                    this.utils.handleError('error updating the stock information', error);
                    this.handleError();
                }
            });
    }

    getCurrentUnitPlaceholder(): string {
        return this.tr.anslate('1 {{unitname}} has how many {{mainUnit}}?', { unitname: this.getCurrentUnit(), mainUnit: this.mainUnit });
    }
}
