import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { UserType, UserService } from 'src/app/modules/frame/services/user.service';
import { ReportService } from 'src/app/modules/report/report.service';
import { Utils } from 'src/app/util/utils';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BeansReport } from 'src/app/models/BeansReport';
import { StockChange } from 'src/app/models/StockChange';
import { Location } from 'src/app/models/Location';
import { BeansreportTableComponent } from './beansreport-table.component';
import { Enumerations } from 'src/app/models/Enumerations';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import cloneDeep from 'lodash-es/cloneDeep';
import { StandardService } from 'src/app/util/services/standard.service';

@Component({
    selector: 'app-editbeansreport',
    templateUrl: './editbeansreport.component.html',
    styleUrls: ['./editbeansreport.component.scss']
})
export class EditbeansreportComponent implements OnInit, OnDestroy {

    constructor(
        private reportService: ReportService,
        // used in template
        public utils: Utils,
        private alertService: AlertService,
        public tr: TranslatorService,
        private userService: UserService,
        private logger: NGXLogger,
        private router: Router,
        private standardService: StandardService,
    ) { }

    @Input() currentUser: UserType;
    @Input() isNewest = false;
    @Input() isOnly = false;
    @Input() report: BeansReport;
    @Input() readOnly = false;

    @Output() editModeChanged = new EventEmitter<boolean>();
    @Output() reportChanged = new EventEmitter<BeansReport>();

    reportCopy: BeansReport;

    stores: Location[];

    invalidTransactions: StockChange[];

    columnsToDisplay = ['Number', 'Date', 'Transaction-ID', 'Coffees', 'Amount', 'Cost', 'References'];
    dataSource: MatTableDataSource<StockChange>;
    columnsToDisplayInvalids = ['Number', 'InvalidReason', 'Date', 'Transaction-ID', 'Coffees', 'Amount', 'Cost', 'References'];
    dataSourceInvalids: MatTableDataSource<StockChange>;

    showOrganic: 'on' | 'off' | '' = '';
    organicButtonInactive = false;

    selectedTypes: { Purchase: boolean, Sale: boolean, Correction: boolean, Transfer: boolean } = { Purchase: true, Sale: false, Correction: false, Transfer: false };

    isLoading = false;

    private ngUnsubscribe = new Subject();

    @ViewChild('table') table: BeansreportTableComponent;
    @ViewChild('invalidsTable') invalidsTable: BeansreportTableComponent;
    @ViewChild('hiddenElems') hiddenElems: ElementRef;


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
 
        if (this.report) {
            this.isLoading = true;
            this.reportCopy = cloneDeep(this.report);
            this.setState();
            this.getAllWithin();
            this.getAllStores();
        } else {
            this.logger.error('report not set while calling editbeansreport');
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores = response.result;
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                        if (!this.stores?.length) {
                            this.stores = [];
                        }
                    }
                    this.stores.sort((s1, s2) => s1.hr_id > s2.hr_id ? -1 : s1.hr_id > s2.hr_id ? 1 : 0);
                    // don't overwrite what has been stored within the report
                    // this.reportCopy.ssf = this.utils.readShowStockFrom(this.stores, this.currentUser);
                    if (this.reportCopy.ssf?.length) {
                        for (let s = 0; s < this.reportCopy.ssf.length; s++) {
                            const loc = this.reportCopy.ssf[s];
                            const store = this.stores.find(store => store?._id?.toString() === loc?._id?.toString());
                            if (store) {
                                this.reportCopy.ssf[s] = store;
                            } else {
                                this.reportCopy.ssf.splice(s, 1);
                                s -= 1;
                            }
                        }
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                    if (!this.stores?.length) {
                        this.stores = [];
                    }
                }
            });
    }

    // called from the template when the dropdown value changed
    showstockfromChanged($event: { value: Location[] }): void {
        if (!$event.value?.length || $event.value.length === this.stores.length) {
            $event.value = null;
        }
        this.reportCopy.ssf = $event.value;
        this.getAllWithin();
    }

    showstockfromOpened(opened: boolean): void {
        if (!opened && !this.reportCopy.ssf?.length) {
            // nothing selected, change to null for dropdown and save
            this.reportCopy.ssf = null;
        }
    }

    datesChanged(): void {
        if (!this.reportCopy.startDate || !this.reportCopy.endDate) {
            this.alertService.error(this.tr.anslate('Invalid date'));
            return;
        }
        this.reportCopy.startDate = this.reportCopy.startDate.startOf('day');
        this.reportCopy.endDate = this.reportCopy.endDate.endOf('day');
        this.getAllWithin();
    }

    updateTables(transactions: StockChange[]): void {
        this.reportCopy.transactions = [];
        this.invalidTransactions = [];
        for (const trans of transactions) {
            const reason = this.reportService.isInvalidTransaction(trans);
            if (!reason) {
                this.reportCopy.transactions.push(trans);
            } else {
                trans['invalidReason'] = reason;
                this.invalidTransactions.push(trans);
            }
        }
        this.columnsToDisplay = this.reportService.getBeansReportColumns(this.reportCopy.transactions, undefined);
        this.columnsToDisplayInvalids = this.columnsToDisplay;
        this.columnsToDisplayInvalids.splice(1, 0, 'InvalidReason');
        this.dataSource = new MatTableDataSource(this.reportCopy.transactions);
        this.dataSourceInvalids = new MatTableDataSource(this.invalidTransactions);
        if (this.table) {
            setTimeout(() => { if (this.table) { this.table.recalc(); } });
        }
        if (this.invalidsTable) {
            setTimeout(() => { if (this.invalidsTable) { this.invalidsTable.recalc(); } });
        }
    }

    getAllWithin(showOrganic = this.showOrganic, types = this.reportService.getTypes(this.selectedTypes), startDate = this.reportCopy.startDate, endDate = this.reportCopy.endDate, ssf = this.reportCopy.ssf): void {
        this.reportService.getAllNonRoastsWithin(types, { from: startDate, to: endDate, showOrganic, ssf: ssf?.length ? ssf : undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.updateTables(this.utils.dateifyStockChanges(response.result));
                    } else {
                        this.utils.handleError('error retrieving reports', response.error);
                    }
                    this.isLoading = false;
                    this.showOrganic = showOrganic;
                    this.organicButtonInactive = false;
                },
                error: error => {
                    this.utils.handleError('error retrieving reports', error);
                    this.isLoading = false;
                    this.showOrganic = showOrganic;
                    this.organicButtonInactive = false;
                }
            });
    }

    // called by beansreport-table component when a transaction was edited or deleted
    onReportChanged(transactions: StockChange[]): void {
        this.reportCopy.transactions = transactions;
        this.dataSource = new MatTableDataSource(this.reportCopy.transactions);
    }

    typesChanged(): void {
        this.reportCopy.types = this.reportService.getTypesEnum(this.selectedTypes);
        this.getAllWithin();
    }

    setState(): void {
        // set organic filter
        this.showOrganic = '';
        if (this.reportCopy.certInfoOnCreation === Enumerations.CertificationTypes.ORGANIC) {
            this.showOrganic = 'on';
        } else if (this.reportCopy.certInfoOnCreation === Enumerations.CertificationTypes.NOT_ORGANIC) {
            this.showOrganic = 'off';
        }
        
        this.reportCopy.ssf = this.reportCopy.ssf?.length ? this.reportCopy.ssf.map(l => ({ _id: l.toString() })) : undefined;

        // set selected types
        this.selectedTypes.Purchase = !!(this.reportCopy.types & Enumerations.StockchangeTypes.PURCHASE);
        this.selectedTypes.Sale = !!(this.reportCopy.types & Enumerations.StockchangeTypes.SALE);
        this.selectedTypes.Correction = !!(this.reportCopy.types & Enumerations.StockchangeTypes.CORRECTION);
        this.selectedTypes.Transfer = !!(this.reportCopy.types & Enumerations.StockchangeTypes.TRANSFER);
    }

    saveReport(): void {
        if (this.isLoading) {
            // TODO best would be to avoid letting the user press this button at this stage
            // however, the button is located outside this component
            return;
        }
        // replace full objects by their _id
        for (let r = 0; r < this.reportCopy.transactions.length; r++) {
            this.reportCopy.transactions[r] = this.reportCopy.transactions[r]?._id as unknown as StockChange;
        }
        this.reportCopy.certInfoOnCreation = this.reportService.getCertInfo(this.showOrganic);
        this.reportService.updateBeansReport(this.reportCopy._id, this.reportCopy)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.editModeChanged.emit(false);
                        this.reportChanged.emit(this.utils.dateifyBeansReports([response.result])?.[0]);

                    } else {
                        this.utils.handleError('error updating report', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating report', error);
                }
            });
    }

    // called from template
    showOrganicChanged(): void {
        if (this.organicButtonInactive) {
            return;
        }
        this.organicButtonInactive = true;
        const nextShowOrganic = this.utils.getNextShowOrganicState(this.showOrganic);
        this.getAllWithin(nextShowOrganic);
    }

    scrollToMissing(): void {
        this.hiddenElems?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
}
