import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Router } from '@angular/router';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { saveAs } from 'file-saver';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { SizeTooLargeError } from 'src/app/util/exceptions/SizeTooLargeException';
import { LoginChangedService } from 'src/app/modules/frame/services/loginchanged.service';
import { Blend } from 'src/app/models/Blend';
import { Coffee } from 'src/app/models/Coffee';
import { Roast } from 'src/app/models/Roast';
import { getLocaleNumberSymbol, NumberSymbol, PathLocationStrategy } from '@angular/common';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { CustomErrorType } from './CustomErrorType';
import { Certification } from 'src/app/models/Certification';
import { formatNumber } from '@angular/common';
import { Producer } from 'src/app/models/Producer';
import { RoastFilterInfo, StandardService } from './services/standard.service';
import { Subject } from 'rxjs';
// import { PropertiesService } from './services/properties.service';
import { Location } from 'src/app/models/Location';
import { GlobalErrorHandler } from './global-error-handler';
import { ServerLogService } from './services/server-log.service';
import { NotificationService } from 'src/app/modules/frame/services/notification.service';
import { MessageSnackComponent } from 'src/app/modules/ui/snacks/message-snack.component';
import throttle from 'lodash-es/throttle';
import { Condition, LastTriggered, Reminder } from 'src/app/models/Reminder';
import { MatDialog } from '@angular/material/dialog';
import { ImageUpload2Component } from 'src/app/modules/ui/image-upload/image-upload2.component';
import pick from 'lodash-es/pick';
import { FileService } from './services/file.service';
import { Constants } from './constants';
import { APBeans } from 'src/app/models/protobuf/generated/beans_pb';
import { PostalAddress } from 'src/app/models/protobuf/generated/google/type/postal_address_pb';
import { LatLng } from 'src/app/models/protobuf/generated/google/type/latlng_pb';
import { APLocation } from 'src/app/models/protobuf/generated/location_pb';
import { APProducer, OrganizationalForm, OrganizationalFormMap } from 'src/app/models/protobuf/generated/producer_pb';
import { Property } from 'src/app/models/Property';
import { DateTime, Info } from 'luxon';
import { ReminderLog } from '../models/ReminderLog';
import { BeansReport } from '../models/BeansReport';
import { RoastReport } from '../models/RoastReport';
import { StockChange } from '../models/StockChange';
import { Account } from '../models/Account';
import { Coupon } from '../models/Coupon';
import { CustomsReport } from '../models/CustomsReport';
import { Error } from '../models/Error';
import { Measurement } from '../models/Measurement';
import { Notification } from '../models/Notification';


export type UnitSystemType = Enumerations.UNIT_SYSTEM | 'kg' | 'g' | 'lb' | 'lbs' | 'oz';

export type PropertiesType = Record<string, { label: string, value: string } []>;


@Injectable({
    providedIn: 'root'
})
export class Utils {

    constructor(
        private alertService: AlertService,
        private logger: NGXLogger,
        public tr: TranslatorService,
        private userService: UserService,
        private router: Router,
        private snackBar: MatSnackBar,
        private loginChangedService: LoginChangedService,
        private standardService: StandardService,
        // private propertiesService: PropertiesService,
        private location: PathLocationStrategy,
        private serverLogService: ServerLogService,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        private fileService: FileService,
        private sanitizer: DomSanitizer,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        Utils.utr = tr;
        Utils.staticLocale = locale;
    }

    static allCountries = [];
    static utr: TranslatorService;
    static staticLocale: string;

    activeNotificationSnackBar: MatSnackBarRef<MessageSnackComponent>;

    callDepth = 0;
    shownNotifications = new Set<string>();

    getNotifications = throttle(this.getNotificationsThrottled, 10000, { 'trailing': false });

    // // convert DB properties (e.g. crop_yield) into readable format (e.g. Crop Yield)
    // static trProp(str: string): string {
    //     const res = str.split('_');
    //     for (let i = 0; i < res.length; i++) {
    //         res[i] = res[i].charAt(0).toUpperCase() + res[i].slice(1);
    //     }
    //     return res.join(' ');
    // }

    // // convert from readable format (e.g. Crop Yield) into DB properties (e.g. crop_yield)
    // static trPropBack(str: string): string {
    //     const res = str.split(' ');
    //     for (let i = 0; i < res.length; i++) {
    //         res[i] = res[i].charAt(0).toLowerCase() + res[i].slice(1);
    //     }
    //     return res.join('_');
    // }

    sanitize(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    getDateFromMaybeString(val: string | Date | DateTime): DateTime {
        if (!val) {
            return val as DateTime;
        }
        if (typeof val === 'string') {
            return DateTime.fromISO(val);
        }
        const ms = val.valueOf();
        return DateTime.fromMillis(ms);
    }

    /**
     * In each of the given objects, the properties given in dateProps
     * are converted using DateTime.fromISO (if typeof string)
     * @param objs the array of objects to traverse
     * @param dateProps list of property names that should be converted; a name can contain one '.' for a subproperty
     * @returns same list of the updated objects
     */
    dateifyObjects<T>(objs: T[], dateProps: string[]): T[] {
        if (!objs || !objs.length) {
            return objs;
        }
        for (const obj of objs) {
            if (!obj) {
                continue;
            }
            for (const prop of dateProps) {
                if (prop.includes('.')) {
                    const [prop1, prop2] = prop.split('.');
                    if (obj[prop1]) {
                        if (obj[prop1][prop2] && typeof obj[prop1][prop2] === 'string') {
                            obj[prop1][prop2] = DateTime.fromISO(obj[prop1][prop2]);
                        }
                    }
                } else if (obj[prop] && typeof obj[prop] === 'string') {
                    obj[prop] = DateTime.fromISO(obj[prop]);
                }
            }
        }
        return objs;
    }
    dateifyObject<T>(obj: T, dateProps: string[]): T {
        return this.dateifyObjects([obj], dateProps)?.[0];
    }

    dateifyRoastFilterInfo(obj: RoastFilterInfo): RoastFilterInfo {
        return this.dateifyObjects<RoastFilterInfo>([obj], ['date.min', 'date.max'])?.[0];
    }
    dateifyReminderLogs(objs: ReminderLog[]): ReminderLog[] {
        return this.dateifyObjects<ReminderLog>(objs, ['done_date']);
    }
    dateifyBeansReports(objs: BeansReport[]): BeansReport[] {
        return this.dateifyObjects<BeansReport>(objs, ['startDate', 'endDate', 'pdfLinkDate', 'created']);
    }
    dateifyRoastReports(objs: RoastReport[]): RoastReport[] {
        return this.dateifyObjects<RoastReport>(objs, ['reconciledDate', 'startDate', 'endDate', 'pdfLinkDate', 'pdfOvLinkDate', 'created']);
    }
    dateifyCustomsReports(objs: CustomsReport[]): CustomsReport[] {
        return this.dateifyObjects<CustomsReport>(objs, ['created', 'startDate', 'endDate']);
    }
    dateifyRoasts(objs: Roast[]): Roast[] {
        return this.dateifyObjects<Roast>(objs, ['date', 'modified_at', 'destroyed', 'template.date']);
    }
    dateifyStockChanges(objs: StockChange[]): StockChange[] {
        return this.dateifyObjects<StockChange>(objs, ['date']);
    }
    dateifyCoffees(objs: Coffee[]): Coffee[] {
        return this.dateifyObjects<Coffee>(objs, ['imported.date']);
    }
    dateifyAccounts(objs: Account[]): Account[] {
        return this.dateifyObjects<Account>(objs, ['paidUntil', 'company.foundation']);
    }
    dateifyCoupons(objs: Coupon[]): Coupon[] {
        return this.dateifyObjects<Coupon>(objs, ['start', 'end']);
    }
    dateifyErrors(objs: Error[]): Error[] {
        return this.dateifyObjects<Error>(objs, ['time']);
    }
    dateifyMeasurements(objs: Measurement[]): Measurement[] {
        return this.dateifyObjects<Measurement>(objs, ['date']);
    }
    dateifyNotifications(objs: Notification[]): Notification[] {
        return this.dateifyObjects<Notification>(objs, ['added_on', 'snooze_until', 'valid_until', 'seen_on']);
    }
    dateifyUsers(objs: User[]): User[] {
        return this.dateifyObjects<User>(objs, ['last_login', 'privacy_accepted_date']);
    }
    // TODO conditions array with sub element
    dateifyReminders(objs: Reminder[]): Reminder[] {
        for (const rem of objs) {
            this.dateifyObjects<Condition>(rem.conditions, ['on_date', 'start_date', 'snooze_until']);
            for (let c = 0; c < rem.conditions?.length; c++) {
                const cond = rem.conditions[c];
                this.dateifyObjects<LastTriggered>(cond?.last_triggered, ['date']);
            }
        }
        return this.dateifyObjects<Reminder>(objs, ['snooze_until']);
    }

    convertLoadedProps(allprops: Property[]): PropertiesType {
        const props: PropertiesType = {};
        for (const prop of allprops) {
            if (typeof props[prop.property] === 'undefined') {
                props[prop.property] = [];
            }
            if (!prop.value) {
                prop.value = prop.label;
            }
            let lbl = prop.label || '';
            if (prop.donttranslate !== true) {
                const idx = lbl.indexOf(Constants.LABEL_SEPARATOR);
                if (idx >= 0) {
                    const cat = lbl.substring(0, idx);
                    let realLabel = lbl.substring(idx + 1);
                    let country: string;
                    if (prop.property === 'Origin') {
                        const idx = realLabel.indexOf(' (');
                        if (idx > 0) {
                            country = realLabel.slice(idx + 2, realLabel.indexOf(')'));
                            realLabel = realLabel.slice(0, idx);
                        }
                    }
                    lbl = Utils.utr.anslate(cat) + Constants.LABEL_SEPARATOR + Utils.utr.anslate(realLabel);
                    if (country) {
                        lbl += ' (' + Utils.utr.anslate(country) + ')';
                    }
                } else {
                    lbl = Utils.utr.anslate(lbl);
                }
            }
            props[prop.property].push({ label: lbl, value: prop.value });
        }
        const sortLabelFn = (p1: { label: string }, p2: { label: string }) => (p1?.label ?? '').localeCompare(p2?.label ?? '');
        for (const prop of Object.keys(props)) {
            if (props[prop]?.length) {
                if (prop === 'Region') {
                    props[prop].sort((p1, p2) => (p1?.value ?? '').localeCompare(p2?.value ?? ''));
                } else if (prop === 'Selection') {
                    // may have custom entries in the front
                    const sepIdx = props[prop].findIndex(sel => sel.label === '' && sel.value === '');
                    if (sepIdx >= 0) {
                        // sort custom entries seperately from others
                        props[prop] = [...props[prop].slice(0, sepIdx).sort(sortLabelFn), props[prop][sepIdx], ...props[prop].slice(sepIdx + 1).sort(sortLabelFn)];
                    }
                } else {
                    props[prop].sort(sortLabelFn);
                }
            }
        }
        return props;
    }

    getCountryFromCode2(code: string): string {
        const countries = Object.keys(Enumerations.Country);
        for (const country of countries) {
            if (Enumerations.Country[country] === code) {
                return country;
            }
        }
        return undefined;
    }

    convertPBLocationToLocation(loc: APLocation): Location {
        if (!loc || !loc.getLabel()) {
            return undefined;
        }
        const location = new Location();
        location.label = loc.getLabel();
        const addr = loc.getAddress();
        if (addr) {
            location.country = this.tr.anslate(this.getCountryFromCode2(addr.getRegionCode()));
            location.zip_code = addr.getPostalCode();
            location.city = addr.getLocality();
            location.region = addr.getAdministrativeArea();
            const addrlist = addr.getAddressLinesList();
            if (addrlist?.length) {
                location.street = addrlist[0];
                location.address = addrlist[1];
            }
        }
        const latlng = loc.getCoordinates();
        if (latlng?.getLatitude() || latlng?.getLongitude()) {
            location.coordinates = { type: 'Point', coordinates: [latlng.getLatitude(), latlng.getLongitude()] };
        }
        return location;
    }

    convertLocationToPBLocation(loc: Location): APLocation {
        if (!loc || !loc.label) {
            return undefined;
        }
        const pbloc = new APLocation();
        pbloc.setLabel(loc.label);
        const addr = new PostalAddress();
        addr.setRevision(0);
        if (loc.country) {
            addr.setRegionCode(Enumerations.Country[loc.country]);
        }
        if (loc.zip_code) {
            addr.setPostalCode(loc.zip_code);
        }
        if (loc.city) {
            addr.setLocality(loc.city);
        }
        if (loc.region) {
            addr.setAdministrativeArea(loc.region);
        }
        addr.setAddressLinesList([
            loc.street,
            loc.address
        ]);
        pbloc.setAddress(addr);
        if (loc.coordinates?.coordinates?.length) {
            const latlng = new LatLng();
            latlng.setLatitude(loc.coordinates?.coordinates?.[0]);
            latlng.setLongitude(loc.coordinates?.coordinates?.[1]);
            pbloc.setCoordinates(latlng);
        }
        return pbloc;
    }

    /**
     * Converts a protobuf Beans object to the internal Coffee object.
     * Generated Certifications only have their "nr" set!
     * @param apBeans protobuf Beans object
     * @returns Coffee object
     */
    convertPBBeansToBeans(apBeans: APBeans, props: PropertiesType, certifications: Certification[]): Coffee {
        const coffee = new Coffee();
        // // strings
        coffee.label = apBeans.getLabel() || undefined;
        coffee.fermentation = apBeans.getFermentation() || undefined;
        coffee.grade = apBeans.getGrade() || undefined;
        coffee.ref_id = apBeans.getLot() || undefined;
        coffee.reference = apBeans.getReference() || undefined;
        coffee.selection = apBeans.getSelection() || undefined;

        // // integers
        coffee.crop_yield = apBeans.getCropYield() || undefined;

        // // floats
        coffee.defects = apBeans.getDefects() || undefined;
        coffee.density = apBeans.getDensity() || undefined;
        coffee.score = apBeans.getScore() || undefined;
        coffee.water_activity = apBeans.getWaterActivity() || undefined;
        coffee.moisture = apBeans.getMoisture() || undefined;

        // arrays
        const certs = apBeans.getCertificationsList();
        coffee.certifications = certs?.length ? certs.map(certNr => {
            return this.convertPBToObject<Certification>(APBeans.Certification, certNr, 'CERTIFICATION', certifications.map(c => ({ label: c.abbrev, value: c })), 'label');
        }) : undefined;
        coffee.flavors = apBeans.getFlavorsList()?.length ? apBeans.getFlavorsList() : undefined;
        coffee.regions = apBeans.getRegionsList()?.length ? apBeans.getRegionsList() : undefined;
        coffee.varietals = apBeans.getVarietalsList()?.length ? apBeans.getVarietalsList() : undefined;

        // objects
        const alt = apBeans.getAltitude();
        if (alt) {
            coffee.altitude = { min: alt.getMin() || undefined, max: alt.getMax() || undefined };
        }
        const ss = apBeans.getScreenSize();
        if (ss) {
            coffee.screen_size = { min: ss.getMin() || undefined, max: ss.getMax() || undefined };
        }
        const cd = apBeans.getCropDate();
        if (cd?.getLanded() || cd?.getPicked()) {
            if (cd?.getLanded()) {
                coffee.crop_date = {};
                coffee.crop_date.landed = [cd.getLanded().getYear() || null];
                if (cd.getLanded().getMonth()) {
                    coffee.crop_date.landed.push(cd.getLanded().getMonth() - 1);
                }
            }
            if (cd?.getPicked()) {
                if (!coffee.crop_date) {
                    coffee.crop_date = {};
                }
                coffee.crop_date.picked = [cd.getPicked().getYear() || null];
                if (cd.getPicked().getMonth()) {
                    coffee.crop_date.picked.push(cd.getPicked().getMonth() - 1);
                }
            }
        }

        const ico = apBeans.getIco();
        if (ico) {
            coffee.ICO = {
                exporter: ico.getExporter() || undefined,
                lot: ico.getLot() || undefined,
                origin: ico.getOrigin() || undefined,
            };
        }

        coffee.location = this.convertPBLocationToLocation(apBeans.getLocation());

        const prod = apBeans.getProducer();
        if (prod) {
            coffee.producer = new Producer();
            coffee.producer.code = prod.getCode();
            coffee.producer.email = prod.getEmail();
            coffee.producer.farmowner = prod.getFarmowner();
            coffee.producer.floId = prod.getFloid();
            coffee.producer.label = prod.getLabel();
            coffee.producer.phone = prod.getPhone();
            coffee.producer.web = prod.getWeb();
            coffee.producer.location = this.convertPBLocationToLocation(prod.getLocation());
            coffee.producer.organizational_form = this.convertPBToObject<string>(OrganizationalForm, prod.getOrganizationalForm(), 'ORGANIZATIONAL_FORM', props['Organizational form']);
            apBeans.setProducer(prod);
        }

        // enums
        const du = apBeans.getDefaultUnit();
        if (du?.getPackagingUnit()) {
            coffee.default_unit = { name: Object.values(Enumerations.CoffeeUnits)[du.getPackagingUnit() - 1] as Enumerations.CoffeeUnits, size: du.getWeightPerUnit() };
        } else {
            coffee.default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 };
        }
        switch (apBeans.getDefectsUnit()) {
            case APBeans.DefectsUnit.DEFECTS_UNIT_300G:
                coffee.defects_unit = 300;
                break;
            case APBeans.DefectsUnit.DEFECTS_UNIT_500G:
                coffee.defects_unit = 500;
                break;
            case APBeans.DefectsUnit.DEFECTS_UNIT_350G:
            default:
                coffee.defects_unit = 350;
        }

        coffee.cultivation = this.convertPBToObject<string>(APBeans.Cultivation, apBeans.getCultivation(), 'CULTIVATION', props['Cultivation']);
        coffee.ageing = this.convertPBToObject<string>(APBeans.Ageing, apBeans.getAgeing(), 'AGEING', props['Ageing']);
        coffee.decaffeination = this.convertPBToObject<string>(APBeans.Decaffeination, apBeans.getDecaffeination(), 'DECAFFEINATION', props['Decaffeination']);
        coffee.drying = this.convertPBToObject<string>(APBeans.Drying, apBeans.getDrying(), 'DRYING', props['Drying']);
        coffee.harvesting = this.convertPBToObject<string>(APBeans.Harvesting, apBeans.getHarvesting(), 'HARVESTING', props['Harvesting']);
        coffee.packaging = this.convertPBToObject<string>(APBeans.Packaging, apBeans.getPackaging(), 'PACKAGING', props['Packaging']);
        coffee.processing = this.convertPBToObject<string>(APBeans.Processing, apBeans.getProcessing(), 'PROCESSING', props['Processing']);
        coffee.species = this.convertPBToObject<string>(APBeans.Species, apBeans.getSpecies(), 'SPECIES', props['Species']);

        coffee.origin = this.convertPBToObject<string>(APBeans.Origin, apBeans.getOrigin(), 'ORIGIN', props['Origin']);

        return coffee;
    }

    convertPBToObject<T>(enumObj: unknown, pbVal: number, enumPrefix: string, props: { label?: string; value: T; }[], comparePropAttr: 'label' | 'value' = 'value'): T {
        const name = Object.getOwnPropertyNames(enumObj)[pbVal];
        return props?.find(prop => `${enumPrefix}_${this.convertObjNameToPBEnumString(prop?.[comparePropAttr]?.toString())}` === name)?.value as T ?? undefined;
    }

    setScreenSize(apBeans: APBeans, screen_size: Coffee['screen_size']): void {
        if (!screen_size) {
            return;
        }
        // must be integers
        screen_size.min = Math.round(screen_size.min);
        screen_size.max = Math.round(screen_size.max);
        const ss = new APBeans.ScreenSize();
        ss.setMin(screen_size.min || undefined);
        ss.setMax(screen_size.max || undefined);
        apBeans.setScreenSize(ss);
    }

    setAltitude(apBeans: APBeans, altitude: Coffee['altitude']): void {
        if (!altitude) {
            return;
        }
        // fix accidently entered values such as 1.75
        if (Math.round(altitude.min) !== altitude.min) {
            if (altitude.min < 8) {
                altitude.min = Math.round(altitude.min * 1000);
            } else {
                altitude.min = Math.round(altitude.min);
            }
        }
        if (Math.round(altitude.max) !== altitude.max) {
            if (altitude.max < 8) {
                altitude.max = Math.round(altitude.max * 1000);
            } else {
                altitude.max = Math.round(altitude.max);
            }
        }
        const alt = new APBeans.Altitude();
        alt.setMin(altitude.min || undefined);
        alt.setMax(altitude.max || undefined);
        apBeans.setAltitude(alt);
    }

    convertBeansToPBBeans(coffee: Coffee): APBeans {
        const apBeans = new APBeans();

        // simple string properties
        apBeans.setLabel(coffee.label);
        apBeans.setFermentation(coffee.fermentation || undefined);
        apBeans.setGrade(coffee.grade || undefined);
        // is set automatically in the backend:
        // apBeans.setOriginRegion(coffee.origin_region);
        apBeans.setLot(coffee.ref_id || undefined);
        apBeans.setReference(coffee.reference || undefined);
        apBeans.setSelection(coffee.selection);

        // simple number properties
        apBeans.setCropYield(coffee.crop_yield || undefined);
        apBeans.setDefects(coffee.defects || undefined);
        apBeans.setDensity(coffee.density || undefined);
        apBeans.setScore(coffee.score || undefined);
        apBeans.setWaterActivity(coffee.water_activity);
        apBeans.setMoisture(coffee.moisture);

        // arrays
        apBeans.setCertificationsList(coffee.certifications?.map(cert => {
            return this.convertObjectToPB<APBeans.CertificationMap[keyof APBeans.CertificationMap]>(cert.abbrev, APBeans.Certification, 'CERTIFICATION');
        }));
        apBeans.setFlavorsList(coffee.flavors);
        apBeans.setRegionsList(coffee.regions);
        apBeans.setVarietalsList(coffee.varietals);

        // objects
        this.setAltitude(apBeans, coffee.altitude);
        this.setScreenSize(apBeans, coffee.screen_size);
        if (coffee.crop_date?.landed || coffee.crop_date?.picked) {
            const cd = new APBeans.CropDate();
            if (coffee.crop_date.landed) {
                const ym = new APBeans.YearMonth();
                ym.setYear(coffee.crop_date.landed?.[0]);
                if (coffee.crop_date.landed?.[1] != null) {
                    // make sure month!=0 (would be removed as default)
                    ym.setMonth(coffee.crop_date.landed[1] + 1 as APBeans.MonthMap[keyof APBeans.MonthMap]);
                }
                cd.setLanded(ym);
            }
            if (coffee.crop_date.picked) {
                const ym = new APBeans.YearMonth();
                ym.setYear(coffee.crop_date.picked?.[0]);
                if (coffee.crop_date.picked?.[1] != null) {
                    // make sure month!=0 (would be removed as default)
                    ym.setMonth(coffee.crop_date.picked[1] + 1 as APBeans.MonthMap[keyof APBeans.MonthMap]);
                }
                cd.setPicked(ym);
            }
            apBeans.setCropDate(cd);
        }
        if (coffee.ICO) {
            const ico = new APBeans.ICO();
            ico.setExporter(coffee.ICO.exporter || undefined);
            ico.setLot(coffee.ICO.lot || undefined);
            ico.setOrigin(coffee.ICO.origin || undefined);
            apBeans.setIco(ico);
        }
        apBeans.setLocation(this.convertLocationToPBLocation(coffee.location) ?? undefined);

        if (coffee.producer) {
            const prod = new APProducer();
            prod.setCode(coffee.producer.code || undefined);
            prod.setEmail(coffee.producer.email || undefined);
            prod.setFarmowner(coffee.producer.farmowner || undefined);
            prod.setFloid(coffee.producer.floId || undefined);
            prod.setLabel(coffee.producer.label || undefined);

            prod.setOrganizationalForm(this.convertObjectToPB<OrganizationalFormMap[keyof OrganizationalFormMap]>(coffee.producer.organizational_form, OrganizationalForm, 'ORGANIZATIONAL_FORM'));

            prod.setPhone(coffee.producer.phone || undefined);
            prod.setWeb(coffee.producer.web || undefined);
            prod.setLocation(this.convertLocationToPBLocation(coffee.producer.location) ?? undefined);

            apBeans.setProducer(prod);
        }

        // enums
        if (coffee.default_unit) {
            const idx = Object.values(Enumerations.CoffeeUnits).indexOf(coffee.default_unit.name);
            const du = new APBeans.CoffeeUnit();
            du.setPackagingUnit(idx + 1 as APBeans.PackagingUnitMap[keyof APBeans.PackagingUnitMap]);
            du.setWeightPerUnit(coffee.default_unit.size);
            apBeans.setDefaultUnit(du);
        }
        if (coffee.defects_unit && coffee.defects_unit !== 350) {
            switch (coffee.defects_unit) {
                case 300:
                    apBeans.setDefectsUnit(APBeans.DefectsUnit.DEFECTS_UNIT_300G);
                    break;
                case 500:
                    apBeans.setDefectsUnit(APBeans.DefectsUnit.DEFECTS_UNIT_500G);
                    break;
                case 350:
                default:
                    break;
            }

        }

        apBeans.setCultivation(this.convertObjectToPB<APBeans.CultivationMap[keyof APBeans.CultivationMap]>(coffee.cultivation ?? '', APBeans.Cultivation, 'CULTIVATION'));
        apBeans.setAgeing(this.convertObjectToPB<APBeans.AgeingMap[keyof APBeans.AgeingMap]>(coffee.ageing ?? '', APBeans.Ageing, 'AGEING'));
        apBeans.setDecaffeination(this.convertObjectToPB<APBeans.DecaffeinationMap[keyof APBeans.DecaffeinationMap]>(coffee.decaffeination ?? '', APBeans.Decaffeination, 'DECAFFEINATION'));
        apBeans.setDrying(this.convertObjectToPB<APBeans.DryingMap[keyof APBeans.DryingMap]>(coffee.drying ?? '', APBeans.Drying, 'DRYING'));
        apBeans.setHarvesting(this.convertObjectToPB<APBeans.HarvestingMap[keyof APBeans.HarvestingMap]>(coffee.harvesting ?? '', APBeans.Harvesting, 'HARVESTING'));
        apBeans.setPackaging(this.convertObjectToPB<APBeans.PackagingMap[keyof APBeans.PackagingMap]>(coffee.packaging ?? '', APBeans.Packaging, 'PACKAGING'));
        apBeans.setProcessing(this.convertObjectToPB<APBeans.ProcessingMap[keyof APBeans.ProcessingMap]>(coffee.processing ?? '', APBeans.Processing, 'PROCESSING'));
        apBeans.setSpecies(this.convertObjectToPB<APBeans.SpeciesMap[keyof APBeans.SpeciesMap]>(coffee.species ?? '', APBeans.Species, 'SPECIES'));

        apBeans.setOrigin(this.convertObjectToPB<APBeans.OriginMap[keyof APBeans.OriginMap]>(coffee.origin?.['origin'] ?? coffee.origin ?? '', APBeans.Origin, 'ORIGIN'));

        return apBeans;
    }

    convertObjectToPB<T>(objStr: string, enumObj: unknown, enumPrefix: string): T {
        const name = `${enumPrefix}_${this.convertObjNameToPBEnumString(objStr || '')}`;
        const idx = Object.getOwnPropertyNames(enumObj).indexOf(name);
        return (idx < 0 ? 0 : idx) as T;
    }

    convertObjNameToPBEnumString(str: string): string {
        if (typeof str !== 'string') {
            return '';
        }
        return str
            .replace(/[ÃÁ]/g, 'A')
            .replace(/É/g, 'E')
            .replace(/,\.\(\)/g, '')
            .replace(/[ /#-]/g, '_')
            .replace('+', 'PLUS')
            .toUpperCase();
    }

    resetShownNotifications(): void {
        this.shownNotifications.clear();
    }

    removeActiveNotificationSnackBar(): void {
        this.activeNotificationSnackBar?.dismiss();
        this.activeNotificationSnackBar = undefined;
    }

    // getNotifications: DebouncedFunc<(callDepth?: number) => void>;
    getNotificationsThrottled(callDepth: number = undefined): void {
        if (!this.notificationService || this.activeNotificationSnackBar) {
            // don't retrieve notifications if one is still showing
            return;
        }
        if (!callDepth) {
            this.callDepth = 0;
        }
        this.notificationService.getNotifications(1)
            .pipe(throttleTime(environment.RELOADTHROTTLE))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (response.result?.[0]) {
                            const notification = this.dateifyNotifications([response.result[0]])?.[0];
                            if (this.shownNotifications.has(notification.hr_id)) {
                                // already shown, retrieve next
                                if (this.callDepth < 3) {
                                    this.callDepth += 1;
                                    this.getNotifications(this.callDepth);
                                }
                                return;
                            }
                            this.shownNotifications.add(notification.hr_id);
                            setTimeout(() => {
                                const snackBarRef = this.snackBar.openFromComponent(MessageSnackComponent, {
                                    data: {
                                        title: notification.title,
                                        html: notification.html,
                                        text: notification.text,
                                        link: notification.link,
                                        date: notification.added_on,
                                        action: 'ok',
                                        notification: notification,
                                    },
                                });
                                this.activeNotificationSnackBar = snackBarRef;
                                snackBarRef.onAction().subscribe(() => {
                                    snackBarRef.dismiss();
                                    this.activeNotificationSnackBar = undefined;
                                    // // notify server that the notification has been seen
                                    // this.notificationService.setNotificationSeen(notification)
                                    //     .pipe(throttleTime(environment.RELOADTHROTTLE))
                                    //     .subscribe({
                                    //         next: () => {
                                    //             this.logger.debug(`setNotificationSeen for ${notification.hr_id}`);
                                    //             if (this.callDepth < 3) {
                                    //                 this.callDepth += 1;
                                    //                 this.getNotifications(this.callDepth);
                                    //             }
                                    //         },
                                    //         error: error => {
                                    //             this.serverLogService.sendError(error, 'Utils.getNotificationsThrottled');
                                    //         }
                                    //     });
                                });
                            }, 750);
                        }
                    } else {
                        this.serverLogService.sendError({ message: 'getNotifications', details: JSON.stringify(response) }, 'Utils.getNotificationsThrottled');
                    }
                },
                error: error => {
                    this.serverLogService.sendError(error, 'Utils.getNotificationsThrottled');
                }
            });
    }

    getUnits(plural = false): string[] {
        const keys = Object.values(Enumerations.CoffeeUnits);
        keys.shift();

        return keys.map(unit => Utils.utr.anslate((plural ? 'plural_' : '') + unit));
    }

    getWeight(w: number, unit_system: Enumerations.UNIT_SYSTEM, showIfZero = true): { pre: string, value: number, post: string } {
        if (w == null || (!showIfZero && !w)) {
            return undefined;
        }
        return this.formatAmountForPipe(w, undefined, unit_system);
    }

    // returns the given energyUnit prefixed with a *1000 symbol
    getUnit1000(energyUnit: string): string {
        switch (energyUnit) {
            case 'BTU':
                return 'kBTU';
            case 'kJ':
                return 'MJ';
            case 'kCal':
                return 'MCal';
            case 'kWh':
                return 'MWh';
            case 'hph':
                return 'khph';
            default:
                return `M${energyUnit}`;
        }
    }

    // returns the given energyUnit prefixed with a /1000 symbol
    getUnitDividedBy1000(energyUnit: string): string {
        switch (energyUnit) {
            case 'BTU':
                return undefined;
            case 'kJ':
                return 'J';
            case 'kCal':
                return 'Cal';
            case 'kWh':
                return 'Wh';
            case 'hph':
                return undefined;
            default:
                return undefined;
        }
    }

    /**
     * Format the given amount to a readable format using the default_unit; used for piping into DecimalPipe (number)
     * @param amount number to format
     * @param default_unit e.g. 60kg bags
     * @param unit_system metric or imperial
     */
    formatAmountForPipe(amount: number, default_unit?: { name: string, size: number }, unit_system?: Enumerations.UNIT_SYSTEM): { pre: string, value: number, post: string } {
        // precision (50,00 -> 50) is done by fixDigits

        // TODO implement, take into account
        // - locale (t, ton, Tonne, ...)

        if (amount == null) {
            return { pre: '', value: 0, post: '' };
        }

        let pre = '';
        let post = '';
        let value = amount;

        if (!default_unit) {
            default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 };
        }
        if (!unit_system) {
            unit_system = Enumerations.UNIT_SYSTEM.METRIC;
        }

        if (Math.abs(amount) >= 0.01 && default_unit.name &&
            default_unit.size && Math.round(amount / default_unit.size) > 0) {
            const err = Math.round(amount / default_unit.size) - amount / default_unit.size;
            if (Math.abs(err) < 0.05) {
                if (Math.abs(err) > 0.01) {
                    pre = '~';
                }
            } else {
                pre = amount > Math.round(amount / default_unit.size) * default_unit.size ? '>' : '<';
            }
            const rval = Math.round(amount / default_unit.size);
            pre += rval.toString() + ' ';
            if (rval !== 1) {
                pre += Utils.utr.anslate('plural_' + default_unit.name);
            } else {
                pre += Utils.utr.anslate(default_unit.name);
            }
        }
        if (unit_system === Enumerations.UNIT_SYSTEM.METRIC) {
            if (Math.abs(amount) < 0.005) {
                post += 'kg';
            } else if (Math.abs(amount) >= 1000) {
                value = amount / 1000;
                post += 't';
            } else if (Math.abs(amount) < 1) {
                // no decimals for grams
                value = Math.round(amount * 1000);
                post += 'g';
            } else {
                post += 'kg';
            }

        } else {
            value = amount * 2.20462262185;
            if (Math.abs(value) < 0.005) {
                post += 'lb';
            } else if (Math.abs(value) < 1) {
                value *= 16.0;
                // max 1 decimal for ounces
                value = Math.round(value * 10) / 10.0;
                post += 'oz';
                // } else if (Math.abs(lbamount) >= 1120) { // or US short ton of 1000 pound ?!
                //     value = amount / 1120.0;
                //     post += 't';
            } else if (Math.abs(value) >= 2000) { // US short ton of 1000 pound ?!
                value /= 2000.0;
                post += 't';
            } else {
                if (Math.abs(value % 1) < 0.002) {
                    value = Math.round(value);
                }
                post += 'lb';
            }
        }
        return { pre: pre, value: value, post: post };
    }


    /**
     * Format the given amount to a readable format using the default_unit using toLocaleString and fixDigits
     * @param {number} amount number to format
     * @param {{name: string, size: number}} default_unit unit to convert to (default {name: Enumerations.CoffeeUnits._NONE, size: 1})
     * @param {UnitSystemType} unit_system one of UnitSystemType such as IMPERIAL or 'kg' or 'lb' (default METRIC)
     * @param {number} [fractionDigits=-1] number of digits after decimal points - used by fixDigits; if negative or not defined: at most 2 digits after the comma (default -1)
     * @param {boolean} [appendUnit=true] whether the unit should be added to the string (default true)
     * @param {boolean} [allowUnitConversion=true] whether it may be converted to, e.g., g or t (default true)
     * @param {number} [minFractDigits=2] min digits after the comma (if fractionDigits not given) (default 2)
     * @param {number} [maxFractDigits=2] max digits after the comma (if fractionDigits not given) (default 2)
     * @returns {string} formatted string; empty string if isNaN
     */
    formatAmount(amount: number, default_unit?: { name: string, size: number }, unit_system?: UnitSystemType, fractionDigits = -1, appendUnit = true, allowUnitConversion = true, minFractDigits = 2, maxFractDigits = 2): string {
        // precision (50,00 -> 50) is done by fixDigits

        // TODO implement, take into account
        // - locale (t, ton, Tonne, ...)
        // - locale (short ton, long ton)

        let retString = '';
        if (isNaN(amount)) {
            return retString;
        }
        let err: number;
        let addCloseParen = false;

        if (!default_unit) {
            default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 };
        }

        if (Math.abs(amount) >= 0.01 && default_unit?.name &&
            default_unit.size && Math.round(amount / default_unit.size) > 0) {
            err = Math.round(amount / default_unit.size) - amount / default_unit.size;
            if (Math.abs(err) < 0.05) {
                if (Math.abs(err) > 0.01) {
                    retString += '~';
                }
                retString += Math.round(amount / default_unit.size).toLocaleString(this.locale) + ' ';
                if (Math.round(amount / default_unit.size) !== 1) {
                    retString += Utils.utr.anslate('plural_' + default_unit.name);
                } else {
                    retString += Utils.utr.anslate(default_unit.name);
                }
                retString += ' (';
                addCloseParen = true;
            } else {
                retString += amount > Math.round(amount / default_unit.size) * default_unit.size ? '>' : '<';
                retString += Math.round(amount / default_unit.size).toLocaleString(this.locale) + ' ';
                if (Math.round(amount / default_unit.size) !== 1) {
                    retString += Utils.utr.anslate('plural_' + default_unit.name);
                } else {
                    retString += Utils.utr.anslate(default_unit.name);
                }
                retString += ' (';
                addCloseParen = true;
            }
        }
        if (!unit_system || unit_system === Enumerations.UNIT_SYSTEM.METRIC || unit_system === 'kg' || unit_system === 'g') {
            if (unit_system === 'g') {
                retString += this.fixDigits(amount * 1000, 'g', 0, appendUnit, minFractDigits, maxFractDigits);
            } else if (Math.abs(amount) < 0.01) {
                retString = `0${appendUnit ? 'kg' : ''}`;
            } else if (allowUnitConversion && Math.abs(amount) >= 1000) {
                retString += this.fixDigits(amount / 1000, 't', fractionDigits, appendUnit, minFractDigits, maxFractDigits);
            } else if (allowUnitConversion && Math.abs(amount) < 1) {
                retString += this.fixDigits(amount * 1000, 'g', 0, appendUnit, minFractDigits, maxFractDigits);
            } else {
                if (Math.abs(amount % 1) < 0.002) {
                    amount = Math.round(amount);
                    fractionDigits = 0;
                }
                retString += this.fixDigits(amount, 'kg', fractionDigits, appendUnit, minFractDigits, maxFractDigits);
            }

        } else {
            let lbamount = amount * 2.20462262185;
            if (unit_system === 'oz') {
                retString += this.fixDigits(lbamount * 16.0, 'oz', 0, appendUnit, minFractDigits, maxFractDigits);
            } else if (Math.abs(lbamount) < 0.01) {
                retString = `0${appendUnit ? 'lb' : ''}`;
            } else if (allowUnitConversion && Math.abs(lbamount) < 1) {
                retString += this.fixDigits(lbamount * 16.0, 'oz', 0, appendUnit, minFractDigits, maxFractDigits);
            } else if (allowUnitConversion && ((this.locale === 'en-GB' && Math.abs(lbamount) >= 2240) || (this.locale !== 'en-GB' && Math.abs(lbamount) >= 2000))) {
                // long ton (UK) of 2240 pound or short ton (US) of 2000 pound
                retString += this.fixDigits(lbamount / (this.locale === 'en-GB' ? 2240 : 2000), 't', fractionDigits, appendUnit, minFractDigits, maxFractDigits);
            } else {
                if (Math.abs(lbamount % 1) < 0.002) {
                    lbamount = Math.round(lbamount);
                    fractionDigits = 0;
                }
                retString += this.fixDigits(lbamount, 'lb', fractionDigits, appendUnit, minFractDigits, maxFractDigits);
            }
        }
        if (addCloseParen) {
            retString += ')';
        }
        return retString;
    }

    /**
     */

    /**
     * Returns a string using Number.toLocaleString
     * If the last digit(s) after the comma would be 0, they are omitted.
     * Amounts > 100 are always shown as integers (unless fractionDigits is given).
     * unit is appended (configure with appendUnit)
     * @param {number} amount number to format
     * @param {string} [unit] will be added if appendUnit is true
     * @param {number} [fractionDigits=-1] number of digits to use; if negative or not defined: at most 2 digits after the comma.
     * @param {boolean} [appendUnit=true] if the unit should be added to the string
     * @param {number} [minFractDigits=2] min digits after the comma (if fractionDigits not given)
     * @param {number} [maxFractDigits=2] max digits after the comma (if fractionDigits not given) 
     * @returns {string} formatted string
     */
    fixDigits(amount: number | '', unit = '', fractionDigits = -1, appendUnit = true, minFractDigits = 2, maxFractDigits = 2): string {
        if (amount === '' || !isFinite(amount)) {
            return '';
        }
        if (fractionDigits >= 0) {
            return new Intl.NumberFormat(this.locale, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format(amount) + (appendUnit ? unit : '');
            // return amount.toLocaleString(this.locale, {minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits}) + (appendUnit ? unit : '');
        }

        if (amount >= 100 || (Math.abs(Math.round(amount) - amount) < Constants.EPSILON)) {
            if (Math.round(amount) !== amount) {
                return (Math.abs(Math.round(amount) - amount) >= 0.5 ? '~' : '') + amount.toLocaleString(this.locale, { maximumFractionDigits: 0 }) + (appendUnit ? unit : '');
            }
            return amount.toLocaleString(this.locale, { maximumFractionDigits: 0 }) + (appendUnit ? unit : '');
        } else if ((amount < 100 && amount > 10) || Math.abs(Math.round(amount * 10) / 10 - amount) < Constants.EPSILON) {
            if (Math.round(amount * 10) / 10 !== amount) {
                return (Math.abs(Math.round(amount * 10) / 10 - amount) >= 0.1 ? '~' : '') + amount.toLocaleString(this.locale, { minimumFractionDigits: minFractDigits ?? 1, maximumFractionDigits: maxFractDigits ?? 1 }) + (appendUnit ? unit : '');
            }
            return amount.toLocaleString(this.locale, { minimumFractionDigits: minFractDigits ?? 1, maximumFractionDigits: maxFractDigits ?? 1 }) + (appendUnit ? unit : '');
        } else {
            if (Math.round(amount * 1000) / 1000 !== amount) {
                 
                return (Math.abs(Math.round(amount * 1000) / 1000 - amount) >= 0.01 ? '~' : '') + amount.toLocaleString(this.locale, { minimumFractionDigits: minFractDigits ?? 2, maximumFractionDigits: maxFractDigits ?? 2 }) + (appendUnit ? unit : '');
            }
            return amount.toLocaleString(this.locale, { minimumFractionDigits: minFractDigits ?? 2, maximumFractionDigits: maxFractDigits ?? 2 }) + (appendUnit ? unit : '');
        }
    }

    getSeparator(separatorType: 'decimal' | 'group', locale?: string): string {
        // const numberWithGroupAndDecimalSeparator = 1000.1;
        // return Intl.NumberFormat(locale)
        //     .formatToParts(numberWithGroupAndDecimalSeparator)
        //     .find(part => part.type === separatorType)
        //     .value;
        if (separatorType === 'group') {
            const grp = getLocaleNumberSymbol(locale ?? this.locale, NumberSymbol.Group);
            return grp;
        }
        const dec = getLocaleNumberSymbol(locale ?? this.locale, NumberSymbol.Decimal);
        return dec;
    }

    /**
     * Uses parseFloat but first removes the getSeparator('group') (thousand sep) 
     * character and replaces , (comma) with . (dot) and finally removes all non-digits.
     * Can thus work with $12.23 or 1.034,5kg or 10,003.51
     * @param sval the string to convert to a number
     */
    parseLocaleFloat(sval: string, locale?: string): number {
        const grp = this.getSeparator('group', locale ?? this.locale);
        let svall: string;
        if (grp === '.' && this.getSeparator('decimal', locale ?? this.locale) === ',' && sval.indexOf(',') < 0 && (sval.match(/\./g) || []).length === 1) {
            // don't replace the only . as the user probably meant ,
            svall = sval;
        } else if (grp !== '.' && sval.indexOf(grp) >= 0 && sval.indexOf(grp) >= sval.length - 3) {
            // there are less than 3 characters after the grp, use it as decimlar sep
            svall = sval.replace(new RegExp(grp, 'g'), '.');
        } else {
            // replace all
            const re = grp === '.' ? /\./g : new RegExp(grp, 'g');
            svall = sval.replace(re, '');
        }
        svall = svall.replace(/,/g, '.');
        svall = svall.replace(/[^-\d.]/g, '');
        return parseFloat(svall);
    }

    /**
     * Returns true if the numbers are the same when rounded to the given number of digits.
     */
    isWithinRounding(val1: number, val2: number, digits = 2): boolean {
        return Math.abs(val1 - val2) < (0.5 / Math.pow(10, digits));
    }

    /**
     * Converts the number to the user's unit system (e.g. lb) and rounds it to the given number of digits.
     * Returns a number | '', not a string.
     * 
     * @param num the number to format
     * @param nrDigits the number of digits to display
     * @param unit_system the user's unit system
     * @returns the number converted to the user's unit system and rounded to the number of digits (returns '' if no valid number given)
     */
    formatNumber(num: number, nrDigits = 3, unit_system?: UnitSystemType): number | '' {
        if (isNaN(num)) {
            return '';
        }
        const dig = Math.pow(10, nrDigits);
        return Math.round(this.convertToUserUnit(num, unit_system) * dig) / dig;
    }

    /**
     * Check whether the given values only differ because of rounding to <digits> digits
     * @param oldValue previous value
     * @param newValueStr new value (as string, probably coming from input text field)
     * @param digits accuracy to check
     * @param divideBy100 if true, newValueStr will be divided by 100 after conversion to float
     * @param allowEmptyValue if true, empty value is ok
     * @returns newValue (float) if different, oldValue if invalid or not different
     */
    checkChangedValue(oldValue: number, newValueStr: string, digits = 3, divideBy100 = false, allowEmptyValue = false, clamp?: ((n: number) => number)): { val: number, changed: boolean } {
        if (allowEmptyValue && !newValueStr) {
            return { val: null, changed: true };
        }
        let newValue = this.parseLocaleFloat(newValueStr) / (divideBy100 ? 100.0 : 1);
        if (Number.isNaN(Number(newValue))) {
            return { val: oldValue, changed: false };
        }
        if (typeof clamp === 'function') {
            newValue = clamp(newValue);
        }
        if (!this.isWithinRounding(oldValue, newValue, divideBy100 ? digits + 2 : digits)) {
            return { val: newValue, changed: true };
        }
        return { val: oldValue, changed: false };
    }

    clamp(min?: number, max?: number, maxDigits?: number): (n: number) => number {
        if (typeof min === 'undefined') {
            min = Number.MIN_SAFE_INTEGER;
        }
        if (typeof max === 'undefined') {
            max = Number.MAX_SAFE_INTEGER;
        }
        return (n: number) => {
            if (typeof n === 'string') {
                n = Number.parseFloat(n);
            }
            if (!isFinite(n)) {
                return n;
            }
            const val = Math.max(min, Math.min(n, max));
            if (typeof maxDigits !== 'undefined') {
                const digits = Math.pow(10, maxDigits);
                return Math.round(val * digits) / digits;
            }
            return val;
        }
    }

    /**
     * convert internal kg to user unit (e.g. * 2.2... for lb)
     * @param amount amount to convert
     * @param unit_system the user's unit system
     */
    convertToUserUnit(amount: number, unit_system: UnitSystemType): number {
        if (!Number.isFinite(amount)) {
            return undefined;
        }
        switch (unit_system) {
            case Enumerations.UNIT_SYSTEM.IMPERIAL:
            case 'lb':
            case 'lbs':
                return amount * 2.20462262185;
            case 'oz':
                return amount * 35.27396195;
            case 'g':
                return amount * 1000;
            default:
                return amount;
        }
    }

    // /**
    //  * Converts given energy value in BTU into kg of propane gas or v.v.
    //  * @param amount energy amount in BTU
    //  * @param reverse if true converts kg to BTU
    //  * @returns energy amount in kg (we assume 1kg has 12.87kWh energy; 1 kWh ~ 3412 BTU)
    //  */
    // convertPropaneBTUtoKG(amount: number, reverse = false): number {
    //     if (!amount) {
    //         return 0;
    //     }
    //     if (reverse) {
    //         return (amount / 0.0002931) * 12.87;
    //     }
    //     return (amount * 0.0002931) / 12.87;
    // }

    /**
     * convert user unit to internal kg (e.g. /2.2 for lbs)
     * @param amount the amount to convert
     * @param unit_system the user's unit system
     */
    convertToKg(amount: number, unit_system: UnitSystemType): number {
        if (!amount) {
            return 0;
        }
        switch (unit_system) {
            case Enumerations.UNIT_SYSTEM.IMPERIAL:
            case 'lb':
            case 'lbs':
                return amount / 2.20462262185;
            case 'oz':
                return amount / 35.27396195;
            case 'g':
                return amount / 1000;
            default:
                return amount;
        }
    }

    /**
     * Converts kg propane gas into an energy unit
     * @param amount amount in kg
     * @param energyUnit e.g. "BTU"
     * @returns amount in energyUnit
     */
    convertGasToEnergy(amount: number, energyUnit: string): number {
        const kwh = amount * 12.87;
        const btu = kwh / 0.0002931;
        switch (energyUnit) {
            case 'kWh':
                return kwh;
            case 'BTU':
                return btu;
            case 'kJ':
                return this.convertEnergy(btu, 'kJ');
            case 'kCal':
                return this.convertEnergy(btu, 'kCal');
            case 'Wh':
                return this.convertEnergy(btu, 'Wh');
            case 'hph':
                return this.convertEnergy(btu, 'hph');
            default:
                return amount;
        }
    }

    /**
     * Converts given amount between the given units (from => to).
     * Assumes US Gallons (4.23 lb)
     * @param amount amount in "fromunit"
     * @param fromUnit unit to convert the amount into kg from: "lb", "gal"
     * @param toUnit unit to convert the amount from ("lb", "gal") into kg
     * @returns amount in kg (if fromUnit given) or in "toUnit"
     */
    convertGasSize(amount: number, fromUnit = 'kg', toUnit = 'kg'): number {
        if (!amount) {
            return 0;
        }
        if (fromUnit !== 'kg') {
            // convert to kg
            switch (fromUnit) {
                case 'lbs':
                case 'lb':
                    if (toUnit === 'gal') {
                        // lb to gal
                        return Math.round(amount / 4.236 * 1000) / 1000;
                    }
                    // lb to kg
                    return Math.round(this.convertToKg(amount, Enumerations.UNIT_SYSTEM.IMPERIAL) * 1000) / 1000;
                case 'gal':
                    if (toUnit === 'lb' || toUnit === 'lb') {
                        // gal to lb
                        return amount * 4.236;
                    }
                    // gal to kg
                    return Math.round(this.convertToKg(amount * 4.236, Enumerations.UNIT_SYSTEM.IMPERIAL) * 1000) / 1000;

                default:
                    return amount;
            }
        }
        switch (toUnit) {
            case 'lbs':
            case 'lb':
                // kg to lb
                return Math.round(this.convertToUserUnit(amount, Enumerations.UNIT_SYSTEM.IMPERIAL) * 1000) / 1000;
            case 'gal':
                // kg to gal
                return Math.round(this.convertToUserUnit(amount, Enumerations.UNIT_SYSTEM.IMPERIAL) / 4.236 * 1000) / 1000;

            default:
                return amount;
        }
    }

    /**
     * Convert an amount.
     * toUnit: assume weight in coffee unit (e.g. 69kg bag, return amount*69)
     * !toUnit: assume weight in kg (return amount/69 for 69kg bag unit); rounds max 2 digits after comma
     */
    convertAmount(amount: number, toUnit: boolean, unit: { name: Enumerations.CoffeeUnits, size: number }): number {
        if (toUnit) {
            // weight in coffee unit to kg
            if (unit?.size) {
                return amount * unit.size;
            }
            return amount;

        } else {
            // kg amount to coffee unit
            if (unit?.size) {
                let toamount = Math.round(amount * 100 / unit.size) / 100.0;
                if (Math.abs(toamount - Math.round(toamount)) < Constants.EPSILON) {
                    toamount = Math.round(toamount);
                }
                return toamount;
            }
            return amount;
        }
    }

    /**
     * Assigns all own properties of the withObj to obj.
     * It doesn't delete properties from obj (even if not present in newObj)
     * Probably the same as Object.assign
     */
    updateObject(obj: unknown, withObj: unknown): void {
        if (!withObj) {
            return;
        }
        const props = Object.getOwnPropertyNames(withObj);
        for (const prop of props) {
            obj[prop] = withObj[prop];
        }
    }

    /**
     * Reloads the web app.
     * @param {boolean} [force] if false, checks localStorage to avoid multiple reloads; default true
     */
    forceReload(force = true): void {
        if (!force) {
            const lastReloadDate = localStorage.getItem('lastReload');
            if (lastReloadDate && lastReloadDate >= DateTime.now().toISODate()) {
                return;
            }
            localStorage.setItem('lastReload', DateTime.now().toISODate());
        }

        if (environment.production) {
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = location.href;
            document.body.appendChild(form);
            form.submit();
        } else {
            window.location.reload();
        }
    }

    // log error and show alert, esp. useful for .subscribe error handling
     
    handleError(description: string, error?: string | CustomErrorType): void {
        if (!description && !error) {
            this.logger.debug('error without description and error!');
            this.alertService.error(Utils.utr.anslate('server error'));
            return;
        }
        if (typeof error !== 'string') {
            if (typeof error?.status !== 'undefined' && error.status === 404 && error.error && typeof error.error !== 'string'
                && error.error.error?.toString().split('#')[0]?.toUpperCase() === 'OTHER ACCOUNT NOT FOUND') {
                // requesting data of another account but this has not been found, probably deleted
                const delAccount = error.error.error.toString().split('#')[1]?.toUpperCase();
                this.logger.debug(description, error);
                this.alertService.error(Utils.utr.anslate('user not found'));
                const currentUser = this.userService.getCurrentUser();
                const otherAccounts = currentUser ? (currentUser.other_accounts || []) : [];
                for (let a = 0; a < otherAccounts.length; a++) {
                    const otherAccount = otherAccounts[a];
                    if ((otherAccount._id || otherAccount)?.toString().toUpperCase() === delAccount) {
                        currentUser.other_accounts.splice(a, 1);
                        delete currentUser.readonly_account_idx;
                        currentUser.readonly = false;
                        this.userService.storeCurrentUser(currentUser);
                        // update on server
                        this.userService.removeAccountFromAccount(delAccount).subscribe();
                        // update toolbar
                        setTimeout(() => this.loginChangedService.changeLoginStatus(true), 500);
                        break;
                    }
                }
                return;
            }
            if (error?.status === 401 && error.error) {
                const errStrUp = (error.error['message'] || error.error['error'] || error.error)?.toString().toUpperCase();
                if (errStrUp.indexOf('JWT EXPIRED') > -1
                    || errStrUp === 'NO AUTHORIZATION TOKEN WAS FOUND'
                    || errStrUp === 'INVALID TOKEN'
                    || errStrUp === 'INVALID SIGNATURE') {
                    this.logger.debug(description, error);
                    this.alertService.error(Utils.utr.anslate('Your login expired, please login again.'));
                    const url = this.router.url;
                    if (url) {
                        this.router.navigate(['/login'], { queryParams: { returnUrl: encodeURIComponent(url) } });
                    } else {
                        this.router.navigate(['/login']);
                    }
                    return;
                }
            }
            if (error?.status === 401 && error.error &&
                error.error['error']?.toString().toUpperCase().indexOf('YOUR ACCOUNT DOES NOT HAVE ENOUGH BALANCE') > -1) {
                const shopLoc = this.locale.substring(0, 2) === 'de' ? 'de' : '';
                this.logger.debug(description, error);
                this.alertService.error(Utils.utr.anslate('Your account does not have enough credit. Please recharge at {{link}}', { link: 'https://buy.artisan.plus/' + shopLoc }));
                this.router.navigate(['/login']);
                return;
            }
            if (error?.status === 429 && error.error &&
                (error.error['message'] || error.error['error'] || error.error)?.toString().toUpperCase().indexOf('TOO MANY REQUESTS') >= 0) {
                this.logger.fatal(description, error);
                this.alertService.error(Utils.utr.anslate('Your browser sent too many requests too quickly. Please slow down.'));
                return;
            }
            if (error?.status === 405 && error.error && error.error['error']?.toString().toUpperCase().indexOf('UPDATE AVAILABLE') >= 0) {
                this.logger.warn(description, error);
                this.alertService.error(Utils.utr.anslate(error.error['error'].toString()));
                const snackBarRef = this.snackBar.open(Utils.utr.anslate('Update available'), Utils.utr.anslate('UPDATE'));
                snackBarRef.onAction().subscribe(() => {
                                       // window.location.reload(true);
                    this.forceReload();
                });
                return;
            }
            this.logger.debug(description, error);
            if (error && ((error.status === 502 && error.name === 'HttpErrorResponse') || (error.error instanceof ProgressEvent && error.statusText?.toUpperCase() === 'UNKNOWN ERROR'))) {
                if (description) {
                    this.alertService.error(Utils.utr.anslate(description), { message: Utils.utr.anslate('Are you connected to the Internet?'), link: 'https://stats.uptimerobot.com/N502RCNjM8', linkText: Utils.utr.anslate('Check Online Status') });
                } else {
                    this.alertService.error(Utils.utr.anslate('error'),
                        { message: Utils.utr.anslate('Are you connected to the Internet?'), link: 'https://stats.uptimerobot.com/N502RCNjM8', linkText: Utils.utr.anslate('Check Online Status') });
                }
                return;
            }
        }
        if (!error) {
            error = description;
        }
        const myerror: string = error && error['error'] && error['error']['error'] ? error['error']['error'] : (error['message'] || error['error'] || error);
        let idx = myerror.toString().indexOf(':');
        let trerror = myerror;
        let trerror2: string;
        if (idx > 0) {
            trerror = myerror.toString().substring(0, idx).trim();
            trerror2 = myerror.toString().substring(idx + 1).trim();
        }
        if (error === description || myerror === description) {
            description = undefined;
        }
        try {
            if (trerror.indexOf('#') >= 0) {
                const trerrorspl = trerror.split('#');
                const template = trerror.match(/{{([^}]*)}}/);
                trerror = Utils.utr.anslate(trerrorspl[0], { [template[1]]: trerrorspl[1] });
            } else {
                trerror = Utils.utr.anslate(trerror);
            }
            if (trerror2 && trerror2.indexOf('#') >= 0) {
                const trerrorspl = trerror2.split('#');
                const template = trerror2.match(/{{([^}]*)}}/);
                trerror2 = Utils.utr.anslate(trerrorspl[0], { [template[1]]: trerrorspl[1] });
            } else if (trerror2) {
                trerror2 = Utils.utr.anslate(trerror2);
            }
        } catch (err) {
            this.logger.warn('could not translate error string', err);
            // ignore
        }
        trerror = trerror + ((trerror2 && trerror !== trerror2) ? ': ' + trerror2 : '');
        if (trerror.toString().length > 200) {
            idx = 200; // heuristic
            trerror = trerror.substring(0, idx) + ' ...';
        }
        if (description) {
            this.alertService.error(Utils.utr.anslate(description), trerror);
        } else {
            this.alertService.error(trerror);
        }
    }

    isaProperty(prop: string | unknown[]): boolean {
        if (prop) {
            if (typeof prop === 'string') {
                if (prop !== '') {
                    // non-empty string
                    return true;
                }
                return false;
            }
            if (typeof prop.length !== 'undefined') {
                if (prop.length > 0) {
                    if (prop[0] !== '') {
                        // non-empty array
                        return true;
                    }
                }
                return false;
            }
            if (Object.keys(prop).length === 0 && prop.constructor === Object) {
                // empty object
                return false;
            }
            const propprops = Object.getOwnPropertyNames(prop);
            if (propprops.length > 0) {
                // non-empty object

                for (const propprop of propprops) {
                    if (this.isaProperty(prop[propprop])) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        }
        return false;
    }

    getCountries(translate = true): string[] {
        if (translate) {
            return Object.keys(Enumerations.Country).map(c => Utils.utr.anslate(c)).sort();
        } else {
            return Object.keys(Enumerations.Country).sort();
        }
    }

    /**
     * Finds the English (as stored in the DB) version of a translated country.
     * @param translatedCountry country in user's language, e.g. Elfenbeinküste
     * @returns country in DB English, e.g. Ivory Coast
     */
    getCountryValue(translatedCountry: string): string {
        if (!translatedCountry) {
            return undefined;
        }
        for (const country of Object.keys(Enumerations.Country)) {
            if (Utils.utr.anslate(country) === translatedCountry) {
                return country;
            }
        }
        return undefined;
    }

    /**
     * Returns true if the given objects are the same, esp. if they have the same
     * internal_hr_id or _id, if one of them is the same as the other's _id, or if o1 === o2
     * @param o1 first object to compare
     * @param o2 second object to compare
     */
    compareObjectsFn(o1: { internal_hr_id?: number, _id?: string }, o2: { internal_hr_id?: number, _id?: string }): boolean {
        return o1 && o2 ? (o1.internal_hr_id && o2.internal_hr_id ? o1.internal_hr_id === o2.internal_hr_id
            : (o1._id && o2._id ? o1._id.toString() === o2._id.toString()
                : (o1 === o2._id?.toString() || o1._id?.toString() === o2 || o1 === o2)))
            : o1 === o2;
    }

    /**
     * Returns true if the given objects are the same, esp. if they have the same
     * id, if one of them is the same as the other's _id, or if o1 === o2
     * @param o1 first object to compare
     * @param o2 second object to compare
     */
    compareObjectsIdFn(o1: { id?: number }, o2: { id?: number }): boolean {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }

    /**
     * Returns true if the given objects are the same, esp. if they have the same
     * hr_id, or label or string representation
     * @param o1 first object to compare
     * @param o2 second object to compare
     */
    compareIdLabelElseFn(o1: { hr_id?: string, label?: string } | string, o2: { hr_id?: string, label?: string } | string): boolean {
        if (o1 && o2) {
            if (typeof o1 === 'string') {
                if (typeof o2 === 'string') {
                    return o1 === o2;
                }
                return false;
            } else if (typeof o2 === 'string') {
                return false;
            }
            return o1.hr_id && o2.hr_id ? o1.hr_id === o2.hr_id
                : (o1.label && o2.label ? o1.label === o2.label
                    : o1 === o2);
        } else {
            return o1 === o2;
        }
    }

    compareIntStringFn(o1: string | number, o2: string | number): boolean {
        return o1 && o2 ? o1.toString() === o2.toString() : o1 === o2;
    }

    compareByLabelFn(o1: { label?: string }, o2: { label?: string }): boolean {
        return o1 && o2 ? o1.label === o2.label : o1 === o2;
    }

    // this version also returns true if _id exist and match
    // compareByLabelFn(o1: { label?: string, _id?: string | { toString: () => string } }, o2: { label?: string, _id?: string | { toString: () => string } }): boolean {
    //     return o1 && o2 ? (o1.label === o2.label || (o1._id && o2._id && o1._id.toString() === o2._id.toString())) : o1 === o2;
    // }

    saveToFileSystem(data: string, type: 'text' | 'xml' | 'json', filename?: string): void {
        let ttype = 'text/plain;charset=utf-8';
        if (type === 'xml') {
            ttype = 'application/xml;charset=utf-8';
        } else if (type === 'json') {
            ttype = 'application/json;charset=utf-8';
        }
        const blob = new Blob([data], { type: ttype });
        saveAs(blob, filename);
    }

    saveBlobToFileSystem(blob: Blob, filename?: string): void {
        saveAs(blob, filename);
    }

    /**
     * Returns 1 unless the given unit is
     * 'lbs' or 'lb' or Enumerations.UNIT_SYSTEM.IMPERIAL (2.20462262185)
     * 'oz' (35.27396195)
     * @param unit the unit_system of the user or the weight unit used (e.g. 'lb'); most often available as
     * this.currentUser.unit_system or mainUnit (retrieved from user settings:
     *   if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
     *       this.mainUnit = 'lb';
     *   })
     */
    getUnitFactor(unit: UnitSystemType): number {
        if (unit === 'lbs' || unit === 'lb' || unit === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            return 2.20462262185;
        }
        if (unit === 'oz') {
            return 35.27396195;
        }
        if (unit === 'g') {
            return 1000;
        }
        return 1;
    }

    checkChanges(parent: { waitingForChanges: boolean }, variable: string, oldValue: number, newValueStr: string, callback?: (parent: { waitingForChanges: boolean }, variable: string) => void, digits = 3, ownerOfVariable?: unknown): void {
        if (ownerOfVariable) {
            ownerOfVariable[variable] = undefined;
        } else {
            parent[variable] = undefined;
        }
        parent.waitingForChanges = true;
        setTimeout(() => {
            const { val, changed } = this.checkChangedValue(oldValue, newValueStr, digits);
            if (ownerOfVariable) {
                ownerOfVariable[variable] = val;
            } else {
                parent[variable] = val;
            }
            parent.waitingForChanges = false;
            if (changed && typeof callback === 'function') {
                callback(parent, variable);
            }
        });
    }

    capitalizeFirstLetter([first = '', ...rest]: string, locales?: string | string[]): string {
        return [first.toLocaleUpperCase(locales), ...rest].join('');
    }

    /**
     * Retrieves a list of capitalized, translated month names
     * @returns array of names
     */
    getAllMonthsLocalized(locale = this.locale): string[] {
        return Info.months('long', { locale }).map(m => this.capitalizeFirstLetter(m));
    }

    /**
     * Returns the name of the month with the given index
     * Attention: Months in Luxon are 1-indexed instead of 0-indexed like in Moment and the native Date type
     * @param m index of month, 0-11
     * @returns Translated, capitalized month
     */
    getMonthStr(m: string | number, locale = this.locale, len: 'short' | 'long' = 'long'): string {
        if (typeof m === 'string') {
            m = parseInt(m, 10);
        }
        if (m >= 0 && m < 12) {
            return this.capitalizeFirstLetter(Info.months(len, { locale })[m]);
        } else {
            return '';
        }
    }

    // isDefined(obj: unknown): boolean {
    //     return typeof obj !== 'undefined';
    // }

    getRelativeDate(date: DateTime): string | undefined {
        if (!date) {
            return undefined;
        }
        const mdate = date.startOf('day');
        const now = DateTime.now().startOf('day');
        // const daysAgo = Math.trunc(DateTime.now().diff(mdate, 'days', true));
        if (now.hasSame(mdate, 'day')) {
            // today; time only
            return undefined;
        }
        if (now.minus({ day: 1 }).hasSame(mdate, 'day')) {
            // yesterday
            return Utils.utr.anslate('yesterday');
        }
        // if (daysAgo > 5 || mdate.isAfter(now)) {
        return mdate.toFormat('DD');
        // }
        // return Utils.utr.anslate('{{x}} days ago', {x: daysAgo});
    }

    /**
     * Translates a DB model name to the URL route, e.g. /coffees
     * @param model the DB model, e.g. Coffee
     */
    getRoute(model: string): string {
        switch (model) {
            case 'Coffee':
                return '/coffees';
            case 'Roast':
                return '/roasts';
            case 'Blend':
                return '/blends';
            case 'Producer':
            case 'Supplier':
            case 'Customer':
                return '/contacts';
            default:
                return undefined;
        }
    }

    convertTemp(temp: number, temperature_system: Enumerations.TEMPERATURE_SYSTEM): number {
        if (typeof temp !== 'number') {
            return undefined;
        }
        if (temperature_system === Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT) {
            return temp * 9.0 / 5.0 + 32;
        }
        return temp;
    }

    convertTempDifference(temp: number, temperature_system: Enumerations.TEMPERATURE_SYSTEM): number {
        if (typeof temp !== 'number') {
            return undefined;
        }
        if (temperature_system === Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT) {
            return temp * 9.0 / 5.0;
        }
        return temp;
    }

    paidDaysLeft(paid: DateTime | string): number {
        let mpaid: DateTime;
        if (!paid) {
            mpaid = DateTime.now();
        } else {
            mpaid = typeof paid === 'string' ? DateTime.fromISO(paid) : paid;
        }
        if (!mpaid.plus) {
            // try to fix broken DateTime object
            if (mpaid['ts']) {
                mpaid = DateTime.fromMillis(mpaid['ts']);
            } else {
                mpaid = DateTime.now();
            }
        }
        return Math.floor(mpaid.plus({ days: 1 }).diffNow('days').days);
    }

    overLimit(ol: { rlimit: number, rused: number }): boolean {
        if (ol?.rlimit && ol.rused > ol.rlimit) {
            return true;
        }
        return false;
    }

    /**
     * Returns the Date of .paidUntil. Treats legacy .paid
     * @param account account part of (stored) user
     */
    getPaidUntil(account: UserType['account']): DateTime {
        if (!account) {
            return undefined;
        }
        if (account.paid) {
            // remove legacy 'paid'
            if (!account.paidUntil) {
                account.paidUntil = DateTime.now().plus({ days: 31 });
            }
            account.paid = undefined;
            const currentUser = this.userService.getCurrentUser();
            if (!currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return undefined;
            }
            // currentUser.account.paid = account.paid;
            currentUser.account.paidUntil = account.paidUntil;
            this.userService.storeCurrentUser(currentUser);
        }
        if (typeof account.paidUntil === 'string') {
            account.paidUntil = DateTime.fromISO(account.paidUntil);
        }
        return account.paidUntil ? account.paidUntil : undefined;
    }

    // adapted from https://stackoverflow.com/a/12300351
    dataURItoBlob(dataURI: string): Blob {
        if (!dataURI) {
            return undefined;
        }
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        const ia = new Uint8Array(ab);

        if (ia.byteLength > environment.MAX_UPLOAD_SIZE_MB * 1024 * 1024) {
            throw new SizeTooLargeError(ia.byteLength ? ia.byteLength.toString() : undefined);
        }

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        const blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    /* Takes two numbers and returns their greatest common factor. */
    // Adapted from Ratio.js
    gcf(a: number, b: number): number {
        if (arguments.length < 2) {
            return a;
        }
        let c;
        a = Math.abs(a);
        b = Math.abs(b);
        while (b) {
            c = a % b;
            a = b;
            b = c;
        }
        return a;
    }

    /**
    * Destructively normalize the fraction to its smallest representation.
    * e.g. 4/16 -> 1/4, 14/28 -> 1/2, etc.
    * This is called after all math ops.
    * Adapted from https://github.com/ekg/fraction.js
     * @param num numerator
     * @param denom denominator
     */
    fraction(num: number, denom = 1): { num: number, denom: number } {
        if (typeof num === 'undefined') {
            return { num: undefined, denom: undefined };
        }
        const isFloat = (n: number) => {
            return (typeof n === 'number' &&
                ((n > 0 && n % 1 > 0 && n % 1 < 1) ||
                    (n < 0 && n % -1 < 0 && n % -1 > -1))
            );
        };

        const roundToPlaces = (n: number, places: number) => {
            if (!places) {
                return Math.round(n);
            }
            const scalar = Math.pow(10, places);
            return Math.round(n * scalar) / scalar;
        };

        let numerator = num;
        let denominator = denom;

        // XXX hackish.  Is there a better way to address this issue?
        //
        /* first check if we have decimals, and if we do eliminate them
        * multiply by the 10 ^ number of decimal places in the number
        * round the number to nine decimal places
        * to avoid js floating point funnies
        */
        if (isFloat(denominator)) {
            const rounded = roundToPlaces(denominator, 9);
            if (rounded.toString().indexOf('.') >= 0) {
                const scaleup = Math.pow(10, rounded.toString().split('.')[1].length);
                denominator = Math.round(denominator * scaleup); // this !!! should be a whole number
                numerator *= scaleup;
            } else {
                // this happens if the denominator is 0.999999999999 which isFloat but rounded is 1 and has no '.'
                denominator = Math.round(denominator);
            }
        }
        if (isFloat(numerator)) {
            const rounded = roundToPlaces(numerator, 9);
            if (rounded.toString().indexOf('.') >= 0) {
                const scaleup = Math.pow(10, rounded.toString().split('.')[1].length);
                numerator = Math.round(numerator * scaleup); // this !!! should be a whole number
                denominator *= scaleup;
            } else {
                numerator = Math.round(numerator);
            }
        }
        const gcf = this.gcf(numerator, denominator);
        numerator /= gcf;
        denominator /= gcf;
        if (denominator < 0) {
            numerator *= -1;
            denominator *= -1;
        }
        return { num: numerator, denom: denominator };
    }

    /**
     * Converts a given val into a fraction and saves it into num[idx] / denom[idx]
     * @param num the array of numerators
     * @param denom the array of denominators
     * @param idx the index of the values that should be updated within the num / denom arrays
     * @param val the value to be converted
     */
    calculateFraction(num: number[], denom: number[], idx: number, val: number): void {
        if (val === 0) {
            num[idx] = undefined;
            denom[idx] = undefined;
            return;
        }
        // catch some common values
        if (Math.abs(val - 1 / 3) < Constants.EPSILON) {
            num[idx] = 1;
            denom[idx] = 3;
            return;
        } else if (Math.abs(val - 2 / 3) < Constants.EPSILON) {
            num[idx] = 2;
            denom[idx] = 3;
            return;
        } else if (Math.abs(val - 1 / 6) < Constants.EPSILON) {
            num[idx] = 1;
            denom[idx] = 6;
            return;
        } else if (Math.abs(val - 5 / 6) < Constants.EPSILON) {
            num[idx] = 5;
            denom[idx] = 6;
            return;
        } else {
            for (let i = 1; i < 7; i++) {
                if (Math.abs(val - i / 7) < Constants.EPSILON) {
                    num[idx] = i;
                    denom[idx] = 7;
                    return;
                }
            }
        }
        const frac = this.fraction(val);
        if (frac.num < 100 && frac.denom < 100) {
            num[idx] = frac.num;
            denom[idx] = frac.denom;
        } else {
            num[idx] = undefined;
            denom[idx] = undefined;
        }
    }

    /**
     * Check if all other ingredients (i.e. different from idx) have a fraction set and calculate the rest as fraction.
     * Will change (if possible) num[idx] and denom[idx]
     * @param blend the blend to work on
     * @param num the numerators of the ingredients
     * @param denom the denominators of the ingredients
     * @param idx the index of the ingredient to calculate (if possible)
     * @param rest calculated if not given explicitly
     */
    checkFractions(blend: Roast['blend'], num: number[], denom: number[], idx: number, rest?: number): void {
        let restFrac = this.fraction(1);
        for (let i = 0; i < blend.ingredients.length; i++) {
            if (i === idx) {
                continue;
            }
            if (num[i] && denom[i]) {
                restFrac = this.fractionSubtract(restFrac, num[i], denom[i]);
            } else {
                restFrac = undefined;
                break;
            }
        }
        if (!restFrac) {
            if (typeof rest === 'undefined') {
                rest = 1.0;
                for (const ing of blend.ingredients) {
                    if (ing.ratio) {
                        rest -= ing.ratio;
                    }
                }
            }
            this.calculateFraction(num, denom, idx, rest);
        } else {
            if (restFrac.num === 0) {
                num[idx] = undefined;
                denom[idx] = undefined;
            } else {
                num[idx] = restFrac.num;
                denom[idx] = restFrac.denom;
            }
        }
    }

    fractionSubtract(frac: { num: number, denom: number }, num: number, denom: number): { num: number, denom: number } {
        if (typeof frac.num === 'undefined' || typeof frac.denom === 'undefined' || typeof num === 'undefined' || typeof denom === 'undefined') {
            return { num: undefined, denom: undefined };
        }
        const td = frac.denom;
        frac.num *= denom;
        frac.denom *= denom;
        frac.num -= num * td;
        return this.fraction(frac.num, frac.denom);
    }

    /**
     * Returns formatted maximum amount that can be created of the given blend. Also returns the value of the ingredients.
     * Relies on blend.ingredients[].coffee.stock[] (with amount and value) and blend.ingredients[].ratio
     * @param blend the blend
     * @param showstockfrom list of store _ids from which (only) the stock should be calculated
     * @param unit_system Enumerations.UNIT_SYSTEM
     * @param coffeeUnit name/size; if not given, will use the one of the first blend ingredient
     * @returns amountstr amount for printing, value as number for currency, amount as number in kg; replXXX same but includes replacement coffees
     */
     
    getBlendStockAndValue(blend: Blend, showstockfrom: string[] | 'all', unit_system: Enumerations.UNIT_SYSTEM, coffeeUnit?: { name: string, size: number }): { amountstr: string, value: number, amount: number, replAmountstr: string, replValue: number, replAmount: number } {

        if (!showstockfrom) {
            showstockfrom = [];
        }
        let amount = 0;
        let replAmount = 0;
        let coffunit = coffeeUnit;
        const coffeeSet: Map<string, number> = new Map<string, number>();
        for (let i = 0; i < blend?.ingredients?.length; i++) {
            let ingamount = 0;
            let replIngamount = 0;
            const ing = blend.ingredients[i];
            if (!ing?.coffee) {
                continue;
            }
            // store coffee to be able to merge with potential same replace_coffee
            let ratio = ing.ratio;
            const exCoffeeRatio = coffeeSet.get((ing.coffee._id || ing.coffee).toString());
            if (exCoffeeRatio) {
                ratio += exCoffeeRatio;
            }
            coffeeSet.set((ing.coffee._id || ing.coffee).toString(), ratio);
            for (let s = 0; s < ing.coffee.stock?.length; s++) {
                const stock = ing.coffee.stock[s];
                if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                    // ingamount of the blend could be made with this ingredient's stock
                    // ingamount += Math.max(stock.amount, 0) / ing.ratio;
                    ingamount += stock.amount / ratio;
                }
            }
            if (ingamount <= 0 && ing.replace_coffee?.stock) {
                // use replace_coffee if defined for this coffee
                let ratio = ing.ratio;
                const exCoffeeRatio = coffeeSet.get((ing.replace_coffee._id || ing.replace_coffee).toString());
                if (exCoffeeRatio) {
                    ratio += exCoffeeRatio;
                }
                coffeeSet.set((ing.replace_coffee._id || ing.replace_coffee).toString(), ratio);
                for (let s = 0; s < ing.replace_coffee.stock?.length; s++) {
                    const stock = ing.replace_coffee.stock[s];
                    if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                        // ingamount of the blend could be made with this ingredient's stock
                        // replIngamount += Math.max(stock.amount, 0) / ing.ratio;
                        replIngamount += stock.amount / ratio;
                    }
                }
            }

            if (i === 0) {
                if (!coffunit) {
                    // use unit of first ingredient if not given from outside
                    coffunit = ing.coffee.default_unit;
                }
                amount = ingamount;
                if (ingamount <= 0 && ing.replace_coffee) {
                    replAmount = replIngamount;
                } else {
                    replAmount = ingamount;
                }
            } else {
                if (ingamount < amount) {
                    // use the smallest amount of all ingredients
                    amount = ingamount;
                }
                if (ingamount <= 0 && ing.replace_coffee) {
                    if (replIngamount < replAmount) {
                        replAmount = replIngamount;
                    }
                } else {
                    if (ingamount < replAmount) {
                        replAmount = ingamount;
                    }
                }
            }
        }
        // amount is now the maximum amount of the blend possible with the given stocks of ingredients
        // replAmount is the maximum amount using replace_coffee for out of stock ingredients

        let value = 0;
        let replValue = 0;
        for (let i = 0; i < blend.ingredients?.length; i++) {
            const ing = blend.ingredients[i];
            if (!ing || !ing.coffee) {
                continue;
            }
            // calculate average value of this ingredient
            let sumAmount = 0;
            let sumValue = 0;
            for (let s = 0; s < ing.coffee.stock?.length; s++) {
                const stock = ing.coffee.stock[s];
                if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                    // sumAmount += Math.max(stock.amount, 0);
                    sumAmount += stock.amount;
                    sumValue += stock.fifo_cost || 0;
                }
            }
            let replSumAmount = 0;
            let replSumValue = 0;
            if (sumAmount <= 0 && ing.replace_coffee && ing.replace_coffee.stock) {
                // calculate average value of this replacement ingredient
                for (let s = 0; s < ing.replace_coffee.stock?.length; s++) {
                    const stock = ing.replace_coffee.stock[s];
                    if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                        // replSumAmount += Math.max(stock.amount);
                        replSumAmount += stock.amount;
                        replSumValue += stock.fifo_cost || 0;
                    }
                }
            }
            const avgValue = sumValue / sumAmount;
            // const ingamount = sumAmount * ing.ratio;
            if (sumAmount <= 0 && ing.replace_coffee) {
                const replIngamount = replAmount * ing.ratio;
                const replAvgValue = replSumValue / replSumAmount;
                replValue += Math.max(replIngamount * replAvgValue, 0);
                value += Math.max(replIngamount * avgValue, 0);
            } else {
                const ingamount = (amount > 0 ? amount : replAmount) * ing.ratio;
                value += Math.max(ingamount * avgValue, 0);
                replValue += Math.max(ingamount * avgValue, 0);
            }
        }
        return {
            amountstr: this.formatAmount(amount, coffunit, unit_system), value, amount: this.convertToUserUnit(amount, unit_system),
            replAmountstr: this.formatAmount(replAmount, coffunit, unit_system), replValue, replAmount: this.convertToUserUnit(replAmount, unit_system),
        };
    }

    /**
     * Returns formatted maximum amount that can be created of the given blend.
     * Takes replacement coffees into account.
     * Relies on blend.ingredients[].coffee.stock[] (with amount and location) and blend.ingredients[].ratio
     * @param blend the blend
     * @param showstockfrom list of store _ids from which (only) the stock should be calculated
     * @param unit_system Enumerations.UNIT_SYSTEM
     * @param coffeeUnit name/size; if not given, will use the one of the first blend ingredient
     * @returns amountstr amount for printing, value as number for currency, amount as number in kg; replXXX same but includes replacement coffees
     */
     
    getBlendStockInclReplacements(blend: Blend, showstockfrom: string[] | 'all', unit_system: Enumerations.UNIT_SYSTEM, coffeeUnit?: { name: string, size: number }): { amountstr: string, value: number, amount: number, replAmountstr: string, replValue: number, replAmount: number } {

        if (!showstockfrom) {
            showstockfrom = [];
        }
        let amount = 0;
        let replAmount = 0;
        let coffunit = coffeeUnit;
        const coffeeSet: Map<string, number> = new Map<string, number>();
        for (let i = 0; i < blend?.ingredients?.length; i++) {
            let ingamount = 0;
            let replIngamount = 0;
            const ing = blend.ingredients[i];
            if (!ing || !ing.coffee) {
                continue;
            }
            // store coffee to be able to merge with potential same replace_coffee
            let ratio = ing.ratio;
            const exCoffeeRatio = coffeeSet.get((ing.coffee._id || ing.coffee).toString());
            if (exCoffeeRatio) {
                ratio += exCoffeeRatio;
            }
            coffeeSet.set((ing.coffee._id || ing.coffee).toString(), ratio);
            for (let s = 0; s < ing.coffee.stock?.length; s++) {
                const stock = ing.coffee.stock[s];
                if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                    // ingamount of the blend could be made with this ingredient's stock
                    // ingamount += Math.max(stock.amount, 0) / ing.ratio;
                    ingamount += stock.amount / ratio;
                }
            }
            if (ingamount <= 0 && ing.replace_coffee && ing.replace_coffee.stock) {
                // use replace_coffee if defined for this coffee
                let ratio = ing.ratio;
                const exCoffeeRatio = coffeeSet.get((ing.replace_coffee._id || ing.replace_coffee).toString());
                if (exCoffeeRatio) {
                    ratio += exCoffeeRatio;
                }
                coffeeSet.set((ing.replace_coffee._id || ing.replace_coffee).toString(), ratio);
                for (let s = 0; s < ing.replace_coffee.stock?.length; s++) {
                    const stock = ing.replace_coffee.stock[s];
                    if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                        // ingamount of the blend could be made with this ingredient's stock
                        // replIngamount += Math.max(stock.amount, 0) / ing.ratio;
                        replIngamount += stock.amount / ratio;
                    }
                }
            }

            if (i === 0) {
                if (!coffunit) {
                    // use unit of first ingredient if not given from outside
                    coffunit = ing.coffee.default_unit;
                }
                amount = ingamount;
                if (ingamount <= 0 && ing.replace_coffee) {
                    replAmount = replIngamount;
                } else {
                    replAmount = ingamount;
                }
            } else {
                if (ingamount < amount) {
                    // use the smallest amount of all ingredients
                    amount = ingamount;
                }
                if (ingamount <= 0 && ing.replace_coffee) {
                    if (replIngamount < replAmount) {
                        replAmount = replIngamount;
                    }
                } else {
                    if (ingamount < replAmount) {
                        replAmount = ingamount;
                    }
                }
            }
        }
        // amount is now the maximum amount of the blend possible with the given stocks of ingredients
        // replAmount is the maximum amount using replace_coffee for out of stock ingredients

        let value = 0;
        let replValue = 0;
        for (let i = 0; i < blend.ingredients?.length; i++) {
            const ing = blend.ingredients[i];
            if (!ing || !ing.coffee) {
                continue;
            }
            // calculate average value of this ingredient
            let sumAmount = 0;
            let sumValue = 0;
            for (let s = 0; s < ing.coffee.stock?.length; s++) {
                const stock = ing.coffee.stock[s];
                if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                    // sumAmount += Math.max(stock.amount, 0);
                    sumAmount += stock.amount;
                    sumValue += stock.fifo_cost || 0;
                }
            }
            let replSumAmount = 0;
            let replSumValue = 0;
            if (sumAmount <= 0 && ing.replace_coffee && ing.replace_coffee.stock) {
                // calculate average value of this replacement ingredient
                for (let s = 0; s < ing.replace_coffee.stock?.length; s++) {
                    const stock = ing.replace_coffee.stock[s];
                    if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                        // replSumAmount += Math.max(stock.amount);
                        replSumAmount += stock.amount;
                        replSumValue += stock.fifo_cost || 0;
                    }
                }
            }
            const avgValue = sumValue / sumAmount;
            // const ingamount = sumAmount * ing.ratio;
            if (sumAmount <= 0 && ing.replace_coffee) {
                const replIngamount = replAmount * ing.ratio;
                const replAvgValue = replSumValue / replSumAmount;
                replValue += Math.max(replIngamount * replAvgValue, 0);
                value += Math.max(replIngamount * avgValue, 0);
            } else {
                const ingamount = (amount > 0 ? amount : replAmount) * ing.ratio;
                value += Math.max(ingamount * avgValue, 0);
                replValue += Math.max(ingamount * avgValue, 0);
            }
        }
        return {
            amountstr: this.formatAmount(amount, coffunit, unit_system), value, amount: this.convertToUserUnit(amount, unit_system),
            replAmountstr: this.formatAmount(replAmount, coffunit, unit_system), replValue, replAmount: this.convertToUserUnit(replAmount, unit_system),
        };
    }

    /**
     * Returns maximum amount that can be created of the given blend.
     * Relies on blend.ingredients[].coffee.stock[] (with amount and value) and blend.ingredients[].ratio
     * @param {Blend} blend the blend
     * @param {string[] | 'all'} showstockfrom the stores to include
     * @param {boolean} [useReplCoffees=true] whether to include replacement coffees (default true)
     * @returns {number} maximum available amount in kg
     */
    getBlendStock(blend: Blend, showstockfrom: string[] | 'all', useReplCoffees = true): number {
        if (!showstockfrom) {
            showstockfrom = [];
        }
        let amount = 0;
        let replAmount = 0;
        const coffeeSet: Map<string, number> = new Map<string, number>();
        for (let i = 0; i < blend?.ingredients?.length; i++) {
            let ingamount = 0;
            let replIngamount = 0;
            const ing = blend.ingredients[i];
            if (!ing?.coffee) {
                continue;
            }
            // store coffee to be able to merge with potential same replace_coffee
            let ratio = ing.ratio;
            const exCoffeeRatio = coffeeSet.get((ing.coffee._id || ing.coffee).toString());
            if (exCoffeeRatio) {
                ratio += exCoffeeRatio;
            }
            coffeeSet.set((ing.coffee._id || ing.coffee).toString(), ratio);
            for (let s = 0; s < ing.coffee.stock?.length; s++) {
                const stock = ing.coffee.stock[s];
                if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                    // ingamount of the blend could be made with this ingredient's stock
                    ingamount += stock.amount / ing.ratio;
                }
            }

            if (useReplCoffees && ingamount <= 0 && ing.replace_coffee?.stock) {
                // use replace_coffee if defined for this coffee
                let ratio = ing.ratio;
                const exCoffeeRatio = coffeeSet.get((ing.replace_coffee._id || ing.replace_coffee).toString());
                if (exCoffeeRatio) {
                    ratio += exCoffeeRatio;
                }
                coffeeSet.set((ing.replace_coffee._id || ing.replace_coffee).toString(), ratio);
                for (let s = 0; s < ing.replace_coffee.stock?.length; s++) {
                    const stock = ing.replace_coffee.stock[s];
                    if (showstockfrom === 'all' || showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                        // ingamount of the blend could be made with this ingredient's stock
                        // replIngamount += Math.max(stock.amount, 0) / ing.ratio;
                        replIngamount += stock.amount / ratio;
                    }
                }
            }

            if (i === 0) {
                amount = ingamount;
                if (useReplCoffees) {
                    if (ingamount <= 0 && ing.replace_coffee) {
                        replAmount = replIngamount;
                    } else {
                        replAmount = ingamount;
                    }
                }
            } else {
                if (ingamount < amount) {
                    // use the smallest amount of all ingredients
                    amount = ingamount;
                }
                if (useReplCoffees) {
                    if (ingamount <= 0 && ing.replace_coffee) {
                        if (replIngamount < replAmount) {
                            replAmount = replIngamount;
                        }
                    } else {
                        if (ingamount < replAmount) {
                            replAmount = ingamount;
                        }
                    }
                }
            }
        }
        // amount is now the maximum amount of the blend possible with the given stocks of ingredients
        // replAmount is the maximum amount using replace_coffee for out of stock ingredients
        if (useReplCoffees) {
            return replAmount || 0;
        }
        return amount || 0;
    }

    isUnfinishedFilterString(filter: string): boolean {
        if (!filter || typeof filter !== 'string') {
            return false;
        }
        const tfilter = filter.trim();
        return tfilter === '>' || tfilter === '<' || tfilter === '-';
    }

    filterArray(search: string, strings: string[]): string[] {
        if (!strings) {
            return;
        }
        if (!search) {
            return strings;
        }

        search = search.toLowerCase();
        return strings.filter(str => str.toLowerCase().indexOf(search) > -1);
    }

    // filterCoffees(search: string, coffees: Coffee[]): Coffee[] {
    //     if (!coffees) {
    //         return;
    //     }
    //     if (!search) {
    //         return coffees;
    //     }

    //     search = search.toLowerCase();
    //     return coffees.filter(
    //         coff => coff.label?.toLowerCase().indexOf(search) > -1
    //             || coff.hr_id?.toLowerCase().indexOf(search) > -1
    //             || (coff.origin && Utils.utr.anslate(coff.origin).toLowerCase().indexOf(search) > -1)
    //     );
    // }

    // filterBlends(search: string, blends: Blend[]): Blend[] {
    //     if (!blends) {
    //         return;
    //     }
    //     if (!search) {
    //         return blends;
    //     }

    //     search = search.toLowerCase();
    //     return blends.filter(
    //         blend => blend.label?.toLowerCase().indexOf(search) > -1
    //         || blend.hr_id?.toLowerCase().indexOf(search) > -1);
    // }

    filterObjects<T extends { translatedLabel?: string, label?: string, hr_id?: string, origin?: string }>(search: string, objects: T[]): T[] {
        if (!objects) {
            return;
        }
        if (!search) {
            return objects;
        }
        search = search.toLocaleLowerCase(this.locale);
        return objects.filter(
            obj => obj &&
                ((obj.translatedLabel ? obj.translatedLabel?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1 : obj.label?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1)
                    || obj.hr_id?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1
                    || (obj.origin && Utils.utr.anslate(obj.origin).toLocaleLowerCase(this.locale)?.indexOf(search) > -1))
        );
    }

    /**
     * Reads 'stockfrom' setting and either returns [] or 'all' or part of the given stores array, see storeShowStockFrom.
     * If the setting is still undefined, returns 'all'.
     * @param {{ _id: string, hr_id?: string, label?: string }[]} stores list of possible stores (id and label)
     * @param {UserType} user the curent user; used to get a potential readonly_account_idx
     * @returns {{ _id: string, hr_id?: string, label?: string }[] | 'all'} 'all' or part of the given stores list
     */
    readShowStockFrom(stores: Location[], user?: UserType): 'all' | Location[] {
        if (!user) {
            user = this.userService.getCurrentUser();
        }
        if (!user) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        let storageName = 'stockfrom_' + user.user_id;
        if (user.readonly_account_idx >= 0 && user.other_accounts[user.readonly_account_idx]) {
            // currently looking at a readonly account
            storageName = 'stockfrom_' + user.other_accounts[user.readonly_account_idx]._id;
        }

        const storeids = this.userService.getFromLocal(storageName);
        if (storeids === 'all') {
            return 'all';
        }
        if (!stores || storeids === '') {
            return [];
        }
        let showstockfrom: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';
        if (storeids && storeids !== 'all') {
            const storeidids = storeids.split(Constants.STORES_SEPARATOR);
            showstockfrom = stores ? stores.filter(store => storeidids.indexOf(store._id) >= 0) : [];
        }
        return showstockfrom;
    }

    /**
     * Stores 'stockfrom' setting as a comma separated list of store _ids, see readShowStockFrom
     * @param {{ _id: string, hr_id?: string, label?: string }[] | 'all'} stores list of stores (_id is used) or 'all'
     * @param {UserType} user the curent user; used to get a potential readonly_account_idx
     */
    storeShowStockFrom(stores: { _id: string, hr_id?: string, label?: string }[] | 'all', user?: UserType): void {
        if (!stores) {
            stores = 'all';
        }
        let str = 'all';
        if (stores !== 'all' && stores.length) {
            str = stores.map(store => store._id).join(',');
        }

        if (!user) {
            user = this.userService.getCurrentUser();
        }
        if (!user) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        let storageName = 'stockfrom_' + user.user_id;
        if (user.readonly_account_idx >= 0 && user.other_accounts[user.readonly_account_idx]) {
            storageName = 'stockfrom_' + user.other_accounts[user.readonly_account_idx]._id;
        }

        this.userService.storeToLocal(storageName, str);
    }

    /**
     * Checks whether all blend ingredients are >0 and add up to 100%
     * @param blend the Blend to check
     */
    percentCorrect(blend: Roast['blend']): boolean {
        let sum = 0;
        for (let i = 0; i < blend?.ingredients?.length; i++) {
            if (blend.ingredients[i]?.ratio) {
                if (blend.ingredients[i].ratio < 0) {
                    return false;
                }
                sum += blend.ingredients[i].ratio;
            }
        }
        // 3*33.333=99.999; |0.99999 - 1| <= 0.0001
        // 6*16.667=100.002; |1.00002 - 1| <= 0.0001
        return Math.abs(sum - 1.0) < Constants.EPSILON;
    }

    /**
     * Returns the crop_date part for the coffee label, e.g. 2019/2020.
     * @param coffee the object that contains the crop_date property
     */
    createBeansYearLabel(coffee: { crop_date?: { landed?: number[], picked?: number[] } }): string {
        if (!coffee?.crop_date) {
            return '';
        }
        const cc = coffee.crop_date;
        if (cc.picked?.[0]) {
            if (cc.landed?.[0] && cc.landed[0] > cc.picked[0]) {
                return `${cc.picked[0]}/${cc.landed[0]}`;
            } else {
                return cc.picked[0].toString();
            }
        } else if (cc.landed && cc.landed[0]) {
            return cc.landed[0].toString();
        }
        return '';
    }

    /**
     * Stores, locally and on the server, that the given helptip should not be shown any more
     * @param tipNr the Enumerations.HELPTIP that has been shown and should not be shown any more
     */
    storeHelptipShown(tipNr: Enumerations.HELPTIP): void {
        const currentUser = this.userService.getCurrentUser();
        currentUser.hts = currentUser.hts | tipNr;
        this.userService.storeCurrentUser(currentUser);
        const storeUser = { _id: currentUser.user_id, hts: currentUser.hts };
        if (!currentUser.readonly) {
            this.userService.updateUser(storeUser as unknown as User)
                .pipe(throttleTime(environment.RELOADTHROTTLE)).subscribe();
        }
    }

    /**
     * Stores, locally and on the server, that the given dialog should not be shown any more
     * @param dialogNr the Enumerations.DIALOG that has been shown and should not be shown any more
     */
    storeDontShownAgain(dialogNr: Enumerations.DIALOG): void {
        const currentUser = this.userService.getCurrentUser();
        currentUser.dsa = currentUser.dsa | dialogNr;
        this.userService.storeCurrentUser(currentUser);
        const storeUser = { _id: currentUser.user_id, dsa: currentUser.dsa };
        if (!currentUser.readonly) {
            this.userService.updateUser(storeUser as unknown as User)
                .pipe(throttleTime(environment.RELOADTHROTTLE)).subscribe();
        }
    }

    /**
     * Returns true if the specified certification Enum contains "organic"
     * @param {number} certInfo combination of Enumerations.CertificationTypes
     */
    isOrganic(certInfo: number): boolean {
        if (!certInfo) {
            return false;
        }
        return !!(certInfo & Enumerations.CertificationTypes.ORGANIC);
        // return certInfo % (2 * Enumerations.CertificationTypes.ORGANIC) >= Enumerations.CertificationTypes.ORGANIC;
    }

    /**
     * Returns true if the coffee has at least one "organic" certification
     * @param coffee the object with certifications
     */
    isOrganicCoffee(coffee: { certifications?: { organic?: boolean }[] }): boolean {
        if (!coffee) {
            return false;
        }
        // if one cert.organic is found, return true
        return coffee.certifications?.reduce((prev, cur) => prev || cur.organic, false);
    }

    /**
     * Returns true if the blend ingredients _all_ have organic: true or 
     * _at least one_ "organic" certification
     * @param blend the object with ingredients that have certifications
     */
    isOrganicBlend(blend: { ingredients?: { coffee?: { organic?: boolean, certifications?: { organic?: boolean }[] } }[] }): boolean {
        // there must be at least one ingredient
        if (!blend.ingredients?.length) {
            return false;
        }
        // all ingredients must have organic: true or at least one cert.organic
        for (const ing of blend.ingredients) {
            if (!ing.coffee?.organic && !ing.coffee?.certifications?.reduce((prev, cur) => prev || cur.organic, false)) {
                return false;
            }
        }
        return true;
    }

    // isOrganicRoast(roast: { coffee?: { certifications?: { organic?: boolean }[] }, blend?: { ingredients?: { coffee?: { certifications?: { organic?: boolean }[] } }[] } }): boolean {
    //     if (!roast) {
    //         return false;
    //     }
    //     if (roast.coffee) {
    //         return this.isOrganicCoffee(roast.coffee);
    //     }
    //     if (roast.blend) {
    //         return this.isOrganicBlend(roast.blend);
    //     }
    // }

    calcCommonCerts(blend: Roast['blend']): Certification[] {
        let cCerts = [];
        if (!blend?.ingredients?.length) {
            return [];
        }
        const certs1 = blend.ingredients[0]?.coffee?.certifications;
        if (!certs1?.length) {
            return [];
        }
        cCerts = certs1.slice();
        // start at 1; filter out all that don't appear in the other cert arrays
        for (let i = 1; i < blend.ingredients.length; i++) {
            const certs = blend.ingredients[i].coffee?.certifications;
            cCerts = certs ? cCerts.filter(ccert => {
                for (const cert of certs) {
                    if (cert._id.toString() === ccert._id.toString()) {
                        return true;
                    }
                }
                return false;
            }) : [];
            if (cCerts.length === 0) {
                // can't get less than empty, don't need to check further
                break;
            }
        }
        return cCerts;
    }

    getNextShowOrganicState(showOrganic: 'on' | 'off' | ''): 'on' | 'off' | '' {
        if (!showOrganic) {
            // from unset to on
            return 'on';
        } else if (showOrganic === 'on') {
            // from on to off
            return 'off';
        } else {
            // from off to unset
            return '';
        }
    }

    // used in templates
    // TODO pre-calc only once
    haveOrganicAndNonOrganic(ings: { coffee: Coffee }[]): boolean {
        if (!ings) {
            return false;
        }
        let haveOrg = false;
        let haveNonOrg = false;
        for (const ing of ings) {
            if (this.isOrganicCoffee(ing?.coffee)) {
                haveOrg = true;
            } else {
                haveNonOrg = true;
            }
            if (haveOrg && haveNonOrg) {
                return true;
            }
        }
        return false;
    }

    convertEnergyStr(val: number, energyUnit: string, digits = -1): string {
        const convVal = this.convertEnergy(val, energyUnit);
        if (convVal == null) {
            return undefined;
        }
        if (digits < 0) {
            if (convVal < 1) {
                return formatNumber(convVal, this.locale, '1.0-2');
                // return convVal.toFixed(3).replace('.', this.currentUser?.export?.decsep || '.');
            }
            if (convVal >= 100) {
                return formatNumber(convVal, this.locale, '1.0-0');
            }
            return formatNumber(convVal, this.locale, '1.0-1');
        } else {
            return formatNumber(convVal, this.locale, `1.0-${digits}`);
        }
    }

    /**
     * Converts BTU energy into a given energy unit.
     * Supports kJ, kCal, Wh, hph, BTU.
     * Returns the same value if an unknown unit is given.
     * @param val energy in BTU
     * @param energyUnit unit to convert into, e.g. 'kJ'
     * @param inverse if true converts from the given unit into BTU
     * @returns the converted amount
     */
    convertEnergy(val: number, energyUnit: string, inverse = false): number {
        if (val == null) {
            return undefined;
        }
        if (!energyUnit) {
            energyUnit = 'BTU';
        }
        let factor: number;
        switch (energyUnit) {
            case 'kJ':
                factor = 1.0551;
                break;
            case 'kCal':
                factor = 0.2521644;
                break;
            case 'Wh':
                factor = 0.2931;
                break;
            case 'kWh':
                factor = 0.0002931;
                break;
            case 'hph':
                factor = 0.000393015;
                break;
            default:
                factor = 1;
        }
        return inverse ? val / factor : val * factor;
    }

    // loadSuppliers(ngUnsubscribe: Subject<unknown>, cb: (supp: Supplier[]) => void): void {
    //     this.standardService.getAll<Supplier>('suppliers')
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     cb(response.result);
    //                 } else {
    //                     cb(undefined);
    //                     this.handleError('Are you connected to the Internet?', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 cb(undefined);
    //                 this.handleError('Are you connected to the Internet?', error);
    //             }
    //         });
    // }

    // loadPlaces(ngUnsubscribe: Subject<unknown>, cb: (stores: Location[], fields: Location[]) => void): void {
    //     this.standardService.getAll<Location>('locations')
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     // TODO optimize: only retrieve places of types FIELD or STORE
    //                     const places = response.result as Location[];
    //                     const fields = places.filter(p => (p.type || p['__t'] || '').indexOf(Enumerations.LocationTypes.FIELD) >= 0);
    //                     fields.sort((p1, p2) => p1.internal_hr_id - p2.internal_hr_id);
    //                     const stores = places.filter(p => (p.type || p['__t'] || '').indexOf(Enumerations.LocationTypes.STORE) >= 0);
    //                     stores.sort((p1, p2) => p2.internal_hr_id - p1.internal_hr_id);
    //                     cb(stores, fields);
    //                 } else {
    //                     cb(undefined, undefined);
    //                     this.handleError('Are you connected to the Internet?', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 cb(undefined, undefined);
    //                 this.handleError('Are you connected to the Internet?', error);
    //             }
    //         });
    // }

    // loadCertifications(ngUnsubscribe: Subject<unknown>, cb: (certs: Certification[]) => void): void {
    //     this.propertiesService.getCertifications()
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     response.result.sort((c1, c2) => {
    //                         if (c1.label < c2.label) { return -1; }
    //                         if (c1.label > c2.label) { return 1; }
    //                         return 0;
    //                     });
    //                     cb(response.result);
    //                 } else {
    //                     cb(undefined);
    //                     this.handleError('Are you connected to the Internet?', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 cb(undefined);
    //                 this.handleError('Are you connected to the Internet?', error);
    //             }
    //         });
    // }

    // loadProperties(ngUnsubscribe: Subject<unknown>, cb: (props: { label: string, value: string }[][]) => void): void {
    //     this.propertiesService.getProperties(['coffee', 'producer'], undefined)
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     const allprops = response.result;
    //                     const props = [];
    //                     for (const prop of allprops) {
    //                         if (typeof props[prop.property] === 'undefined') {
    //                             props[prop.property] = [];
    //                         }
    //                         if (!prop.value) {
    //                             prop.value = prop.label;
    //                         }
    //                         let lbl = prop.label || '';
    //                         if (prop.donttranslate !== true) {
    //                             const idx = lbl.indexOf(Constants.LABEL_SEPARATOR);
    //                             if (idx >= 0) {
    //                                 const cat = lbl.substring(0, idx);
    //                                 let realLabel = lbl.substring(idx + 1);
    //                                 let country: string;
    //                                 if (prop.property === 'Origin') {
    //                                     const idx = realLabel.indexOf(' (');
    //                                     if (idx > 0) {
    //                                         country = realLabel.slice(idx + 2, realLabel.indexOf(')'));
    //                                         realLabel = realLabel.slice(0, idx);
    //                                     }
    //                                 }
    //                                 lbl = Utils.utr.anslate(cat) + Constants.LABEL_SEPARATOR + Utils.utr.anslate(realLabel);
    //                                 if (country) {
    //                                     lbl += ' (' + Utils.utr.anslate(country) + ')';
    //                                 }
    //                             } else {
    //                                 lbl = Utils.utr.anslate(lbl);
    //                             }
    //                         }
    //                         props[prop.property].push({ label: lbl, value: prop.value });
    //                     }
    //                     cb(props);
    //                 } else {
    //                     cb(undefined);
    //                     this.handleError('Are you connected to the Internet?', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 cb(undefined);
    //                 this.handleError('Are you connected to the Internet?', error);
    //             }
    //         });
    // }

    // loadVarietals(ngUnsubscribe: Subject<unknown>, cb: (cats: string[], vars: Variety[][]) => void): void {
    //     this.propertiesService.getVerietals()
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     const vars = response.result;
    //                     const allVarietalCategories = [];
    //                     const allVarietals = [];
    //                     for (const varietal of vars) {
    //                         let idx = allVarietalCategories.indexOf(varietal.category);
    //                         if (idx < 0) {
    //                             idx = allVarietalCategories.length;
    //                             allVarietalCategories.push(varietal.category);
    //                         }
    //                         if (typeof allVarietals[idx] === 'undefined') {
    //                             allVarietals[idx] = [];
    //                         }
    //                         allVarietals[idx].push(varietal);
    //                     }
    //                     // this._varietalFilter(this.varietalInput);
    //                     cb(allVarietalCategories, allVarietals);
    //                 } else {
    //                     cb(undefined, undefined);
    //                     this.handleError('Are you connected to the Internet?', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 cb(undefined, undefined);
    //                 this.handleError('Are you connected to the Internet?', error);
    //             }
    //         });
    // }

    // loadProducers(ngUnsubscribe: Subject<unknown>, cb: (prod: Producer[]) => void): void {
    //     this.standardService.getAll<Producer>('producers')
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     cb(response.result);
    //                 } else {
    //                     cb(undefined);
    //                     this.handleError('Are you connected to the Internet?', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 cb(undefined);
    //                 this.handleError('Are you connected to the Internet?', error);
    //             }
    //         });
    // }

    // loadCustomers(ngUnsubscribe: Subject<unknown>, cb: (cust: Customer[]) => void): void {
    //     this.standardService.getAll<Customer>('customers')
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     cb(response.result);
    //                 } else {
    //                     cb(undefined);
    //                     this.handleError('Are you connected to the Internet?', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 cb(undefined);
    //                 this.handleError('Are you connected to the Internet?', error);
    //             }
    //         });
    // }

    loadRegionsForOrigin(origin: string, ngUnsubscribe: Subject<unknown>, cb: (regions: string[]) => void): void {
        this.standardService.getRegionsForOrigin(origin)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        cb(response.result);
                    } else {
                        cb(undefined);
                        this.handleError(undefined, response.error);
                    }
                },
                error: error => {
                    cb(undefined);
                    this.handleError('error retrieving information', error);
                    // TODO remove when error is understood
                    const ge = new GlobalErrorHandler(this.location, this.serverLogService, this.logger, this.userService);
                    ge.handleError(error);
                }
            });
    }

    calcExpiredUnit(rem: Reminder, mainUnit: UnitSystemType, energyUnit: string, gasUnit: string): void {
        // calculate minimum value over all conditions
        let sortVal = Number.MAX_SAFE_INTEGER;
        let val: number;
        let unit: string;
        for (let r = 0; r < rem?.conditions?.length; r++) {
            const condition = rem.conditions[r];
            if (typeof condition.interval_done !== 'undefined') {
                if (condition.interval_done_forsort < sortVal) {
                    sortVal = condition.interval_done_forsort;
                    val = condition.interval_done;
                    unit = condition.interval_unit || 'days';
                }
            }
        }

        // calculate values for this minimum
        const exp = this.calcExpired(unit, val, sortVal, mainUnit, energyUnit, gasUnit);
        Object.assign(rem, exp);
    }

    // TODO check use
    humanizeInterval(val: number, unit: string): string {
        return DateTime.now().plus({ [unit]: val }).toRelative();
    }

     
    calcExpired(unit: string, val: number, sortVal: number, mainUnit: UnitSystemType, energyUnit: string, gasUnit: string): {
        expired2ForSort?: number, expired2Pre?: string, expired2Number?: string, expired2Unit?: string,
        expiredForSort?: number, expiredPre?: string, expiredNumber?: string, expiredUnit?: string
    } {
        let pre = '';
        let precision = 1; // max digits after decimal point
         
        let ret: {
            expired2ForSort?: number, expired2Pre?: string, expired2Number?: string, expired2Unit?: string,
            expiredForSort?: number, expiredPre?: string, expiredNumber?: string, expiredUnit?: string
        } = {};
        let durStr: string;
        switch (unit) {
            case 'roasted_amount':
                if (sortVal) {
                    ret = Math.abs(sortVal) > 10000 ? { expired2Unit: '' } : {
                        expired2ForSort: sortVal,
                        expired2Pre: '',
                        expired2Number: '',
                        // expired2Unit: moment.duration(sortVal, 'days').humanize(true),
                        expired2Unit: this.humanizeInterval(sortVal, 'days'),
                    }
                }
                pre = '';
                unit = mainUnit;
                val *= this.getUnitFactor(mainUnit);
                break;
            case 'roast_hours':
                if (sortVal) {
                    ret = Math.abs(sortVal) > 10000 ? { expired2Unit: '' } : {
                        expired2ForSort: sortVal,
                        expired2Pre: '',
                        expired2Number: '',
                        expired2Unit: this.humanizeInterval(sortVal, 'days'),
                    }
                }
                if (Math.abs(val) === 1) {
                    unit = this.tr.anslate('hour of roasting');
                } else if (Math.abs(val) < 2) {
                    unit = this.tr.anslate('minutes of roasting');
                    val *= 60;
                } else {
                    unit = this.tr.anslate('hours of roasting');
                }
                break;
            case 'batches':
                if (sortVal) {
                    ret = Math.abs(sortVal) > 10000 ? { expired2Unit: '' } : {
                        expired2ForSort: sortVal,
                        expired2Pre: '',
                        expired2Number: '',
                        expired2Unit: this.humanizeInterval(sortVal, 'days'),
                    }
                }
                pre = '';
                if (Math.abs(val) === 1) {
                    unit = this.tr.anslate('roast');
                } else {
                    unit = this.tr.anslate('batches');
                }
                break;
            case 'gas_consumed':
                precision = 2;
                if (sortVal) {
                    ret = Math.abs(sortVal) > 10000 ? { expired2Unit: '' } : {
                        expired2ForSort: sortVal,
                        expired2Pre: '',
                        expired2Number: '',
                        expired2Unit: this.humanizeInterval(sortVal, 'days'),
                    }
                }
                pre = '';
                unit = gasUnit;
                // unit = mainUnit;
                // val *= this.getUnitFactor(mainUnit);
                val = this.convertGasSize(val, 'kg', gasUnit);
                break;
            case 'date':
            case 'days':
            case 'weeks':
            case 'months':
            case 'years':
            case 'day_of_week':
            case 'day_of_month':
                durStr = this.humanizeInterval(sortVal, 'days');
                return {
                    expiredForSort: sortVal,
                    expiredPre: '',
                    expiredNumber: '',
                    expiredUnit: durStr,
                }
        }
        if (unit) {
            ret.expiredForSort = sortVal;
            ret.expiredPre = pre;
            ret.expiredNumber = formatNumber(val, this.locale, '1.0-' + precision);
            ret.expiredUnit = unit;
            return ret;
        }
        return undefined;
    }

    /**
     * Opens a dialog in which the user can choose a new or remove an existing image.
     * The change will be stored at the server.
     * Between the calls to cbClosed and cbDone, the user should not be able to store 
     * the object since this could overwrite changes. Probably show a spinner and disable
     * any save button.
     * @param targetObj the object on which the image should be set (or deleted)
     * @param dbCollectionName the collection in which the object is stored (e.g. 'reminders')
     * @param imagePropName the property name under which the image should be stored (e.g. 'image' or 'picture')
     * @param ngUnsubscribe an ngUnsubscribe subject used in takeUntil
     * @param cbClosed callback called when the image dialog is closed (independent on result)
     * @param cbDone calback called when all actions (upload/save, delete/save, nothing) have finished.
     * Possible return values of cbDone are undefined (no change (maybe error)), null (image removed) or a path as string
     */
    addImage<T extends { _id?: string, internal_hr_id?: number, modified_at?: DateTime, image?: string }>(
        targetObj: T, dbCollectionName: string, imagePropName: string, ngUnsubscribe: Subject<unknown>,
        cbClosed: () => void, cbDone: (imagePath: string) => void,
    ): void {
        const dialogRef = this.dialog.open(ImageUpload2Component, {
            closeOnNavigation: true, autoFocus: false,
            data: { url: targetObj[imagePropName], avatar: false, default: undefined }
        });

        dialogRef.afterClosed().subscribe(fileString => {
            if (typeof cbClosed === 'function') cbClosed();
            if (!fileString) {
                if (fileString === null) {
                    // removed image
                    // modified_at is necessary for roasts
                    const trimmedObject = Object.assign({}, pick(targetObj, ['_id', 'modified_at']));
                    trimmedObject[imagePropName] = null;
                    this.standardService.update<T>(dbCollectionName, trimmedObject)
                        .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
                        .subscribe({
                            next: response => {
                                if (!response || response.success === true) {
                                    this.logger.debug('update successful');
                                    targetObj[imagePropName] = null;
                                    this.alertService.success(this.tr.anslate('Successfully updated'));
                                    if (typeof cbDone === 'function') cbDone(null);
                                } else {
                                    this.handleError('Could not set new image', response.error);
                                    if (typeof cbDone === 'function') cbDone(undefined);
                                }
                            },
                            error: error => {
                                this.handleError('Could not set new image', error);
                                if (typeof cbDone === 'function') cbDone(undefined);
                            }
                        });
                }
                if (typeof cbDone === 'function') cbDone(undefined);
                return;
            }
            try {
                const file: Blob = this.dataURItoBlob(fileString);
                this.fileService.uploadFile(file, 'IMAGE', dbCollectionName.slice(0, -1).toUpperCase(), targetObj.internal_hr_id ? targetObj.internal_hr_id.toString() : targetObj._id.toString())
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                if (environment.BASE_API_URL.indexOf('localhost') >= 0) {
                                    targetObj[imagePropName] = '/' + (this.locale === 'en-GB' ? 'gb' : this.locale) + response.result;
                                } else {
                                    targetObj[imagePropName] = response.result;
                                }
                                this.logger.debug('using image at ' + targetObj[imagePropName]);
                                // modified_at is necessary for roasts
                                const trimmedObject = Object.assign({}, pick(targetObj, ['_id', 'modified_at']));
                                trimmedObject[imagePropName] = targetObj[imagePropName];
                                this.standardService.update<T>(dbCollectionName, trimmedObject)
                                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(ngUnsubscribe))
                                    .subscribe({
                                        next: response2 => {
                                            if (!response2 || response2.success === true) {
                                                this.logger.debug('update successful');
                                                this.alertService.success(this.tr.anslate('Successfully updated'));
                                                if (typeof cbDone === 'function') cbDone(response2?.result?.image);
                                            } else {
                                                this.handleError('Could not set new image', response2.error);
                                                if (typeof cbDone === 'function') cbDone(undefined);
                                            }
                                        },
                                        error: error => {
                                            this.handleError('Could not set new image', error);
                                            if (typeof cbDone === 'function') cbDone(undefined);
                                        }
                                    });
                            } else {
                                this.handleError('Could not set new image', response.error);
                                if (typeof cbDone === 'function') cbDone(undefined);
                            }
                        },
                        error: error => {
                            this.handleError('Could not set new image', error);
                            if (typeof cbDone === 'function') cbDone(undefined);
                        }
                    });
            } catch (err) {
                if (err instanceof SizeTooLargeError) {
                    this.handleError('File size too large (must be <2MB)', undefined);
                } else {
                    throw err;
                }
                if (typeof cbDone === 'function') cbDone(undefined);
            }
        });
    }

    storeRoastDestroyed(roasts: Roast[], idx: number, cb: () => void, changedIdxs?: number[]): void {
        if (typeof idx !== 'undefined') {
            if (!roasts?.[idx]) { return; }
            const roast = roasts[idx];
            this.logger.debug('save destroyed date:', roast.destroyed?.toString(), 'to roast', roast.hr_id);
            this.standardService.update<Roast>('roasts', {
                _id: roast._id, destroyed: roast.destroyed || null,
                modified_at: DateTime.now(), label: undefined, amount: undefined, date: undefined
            })
                .pipe(throttleTime(environment.RELOADTHROTTLE))
                .subscribe({
                    next: response => {
                        if (!response || response.success === true) {
                            this.alertService.success(this.tr.anslate('Successfully updated'));
                            if (typeof cb === 'function') {
                                cb();
                            }
                        } else {
                            this.handleError('error updating date', response.error);
                        }
                    },
                    error: error => {
                        this.handleError('error updating date', error);
                    }
                });
        } else if (changedIdxs?.length) {
            // called from applyDestroyedToAll
            // TODO change many at once
            let throttledCB: () => void;
            if (typeof cb === 'function') {
                throttledCB = throttle(cb, 2000, { 'trailing': false });
            }
            for (const index of changedIdxs) {
                this.storeRoastDestroyed(roasts, index, throttledCB, undefined);
            }
        }
    }

    destroyedChanged(updateRoastData: () => void, cb: () => void, readOnly: boolean, roasts: Roast[], i?: number): void {
        updateRoastData();
        if (typeof i !== 'undefined' && !readOnly) {
            this.storeRoastDestroyed(roasts, i, cb);
        }
    }

    removeDestroyed(i: number, updateRoastData: () => void, cb: () => void, readOnly: boolean, roasts: Roast[]): void {
        if (roasts[i]?.destroyed) {
            roasts[i].destroyed = undefined;
        }
        this.destroyedChanged(updateRoastData, cb, readOnly, roasts, i);
    }

    applyDestroyedToAll(i: number, updateRoastData: () => void, cb: () => void, readOnly: boolean, roasts: Roast[]): void {
        const changedIdxs = [];
        const date = roasts[i]?.destroyed || null;
        for (let r = 0; r < roasts?.length; r++) {
            const roast = roasts[r];
            // TODO check condition (getTime())
            if (r !== i && roast?.discarded && (
                (roast.destroyed && date && +date !== +roast.destroyed)
                || (!roast.destroyed && date) || (roast.destroyed && !date)
            )) {
                roast.destroyed = date;
                changedIdxs.push(r);
            }
        }
        this.destroyedChanged(updateRoastData, cb, readOnly, roasts);

        if (!readOnly) {
            this.storeRoastDestroyed(roasts, undefined, cb, changedIdxs);
        }
    }

    /**
     * Returns true if any of the given roasts is discarded.
     */
    hasAnyDiscardedRoasts(roasts: Roast[]): boolean {
        for (let r = 0; r < roasts?.length; r++) {
            if (roasts[r].discarded) {
                return true;
            }
        }
        return false;
    }

    /**
     * takes a crop_date object and
     * returns the crop year as a string extracted from the given crop_date object (eg. "2019" or "2017/2018")
     * in case the crop_date does not contain a year it returns null
     */
    cropDateToString(cropDate: Coffee['crop_date']): string {
        if (!cropDate) {
            return null;
        }
        const pickedYear = cropDate.picked ? cropDate.picked[0] : '';
        const landedYear = cropDate.landed ? cropDate.landed[0] : '';
        if (pickedYear && landedYear) {
            if (pickedYear >= landedYear) {
                return pickedYear.toString();
            }
            return `${pickedYear}/${landedYear}`;
        }
        if (pickedYear) {
            return pickedYear.toString();
        }
        if (landedYear) {
            return landedYear.toString();
        }
        return null;
    }

    /**
     * Takes an origin string and a crop_date object and returns the origin
     * combined with the crop year, if given, as a string
     * (eg. "Brazil 2019" or "Guatemala 2017/2018")
     * if none is given, returns ''
     */
    originCropDateToString(origin: string, cropDate: Coffee['crop_date']) {
        const cropYear = this.cropDateToString(cropDate);
        if (origin && cropYear) {
            return `${this.tr.anslate(origin)} ${cropYear}`;
        }
        if (origin) {
            return this.tr.anslate(origin);
        }
        if (cropYear) {
            return cropYear;
        }
        return '';
    }

    /**
     * Adds up the stock taking this.showstockfrom into account
     * @param coff coffee with stock
     * @param showstockfrom defines which stocks should be counted
     * @returns summed stock in given locations
     */
    getCoffeeStock(coff: Coffee, showstockfrom: string[] | 'all' = 'all'): number {
        if (!coff?.stock) {
            return 0;
        }
        return coff.stock.reduce((prev, cur) => {
            if (!cur || isNaN(cur.amount) || (showstockfrom !== 'all' && showstockfrom.indexOf((cur.location._id || cur.location).toString()) < 0)) {
                return prev;
            }
            return prev + cur.amount;
        }, 0);
    }

    /**
     * Adds up the stock for a particular location (store)
     * @param coff coffee with stock
     * @param store location at which stock is calculated
     * @returns summed stock in given location
     */
    getStoreStock(coffees: Coffee[], store: string): number {
        if (!store) {
            return 0;
        }
        let amount = 0;
        for (const coffee of coffees) {
            amount += coffee.stock.reduce((prev, cur) => {
                if (isNaN(cur?.amount) || (store !== (cur.location._id || cur.location).toString())) {
                    return prev;
                }
                return prev + cur.amount;
            }, 0);
        }
        return amount;
    }

    /**
     * Replaces ingredients[].coffee and ingredients[].repl_coffee ids with
     * objects with the same id in the given coffee list.
     * @param { Blend } blend the blend with string ids as ingredient.coffee
     * @param { Coffee[] } coffees list of available coffees
     * @returns { string[] | undefined } list of coffee _ids that have not been found in coffees
     */
    populateCoffees(blend: Blend, coffees: Coffee[]): string[] {
        if (!blend) {
            return;
        }
        const notFoundCoffeeIds = [];
        for (let i = 0; i < blend.ingredients?.length; i++) {
            const ing = blend.ingredients[i];
            if (typeof ing?.coffee === 'string') {
                const cofId = ing.coffee;
                let replCofId: string;
                if (typeof ing.replace_coffee === 'string') {
                    replCofId = ing.replace_coffee;
                }
                let found = false;
                for (const coffee of coffees) {
                    const id = coffee._id?.toString();
                    if (id === cofId) {
                        ing.coffee = coffee;
                        found = true;
                    }
                    if (id === replCofId) {
                        ing.replace_coffee = coffee;
                    }
                }
                if (!found) {
                    notFoundCoffeeIds.push(ing.coffee);
                }
            }
            if (typeof ing?.replace_coffee === 'string') {
                const cofId = ing.replace_coffee;
                let found = false;
                for (const coffee of coffees) {
                    if (coffee._id?.toString() === cofId) {
                        ing.replace_coffee = coffee;
                        found = true;
                    }
                }
                if (!found) {
                    notFoundCoffeeIds.push(ing.replace_coffee);
                }
            }
        }
        return notFoundCoffeeIds;
    }

    /**
     * Convert the ingredient list of the given blend in a human readable string.
     * Tries coffee.yearLabel if coffee.crop_date is not set.
     * @param blend the blend to stringify
     * @param separator ingredient strings are separated by this string
     * @param amount if given, the ingredients' amounts will be shown instead of %
     * @param unit_system the user's unit system (e.g. 'kg')
     * @returns string to display a blend's ingredients list
     */
    public getBlendStr(blend: Blend, separator = '\n', smallSize = false, amount?: number, unit_system?: UnitSystemType) {
        let str = '';
        if (blend) {
            // if (blend.label) {
            //     str += `${blend.label}: `;
            // }
            for (let b = 0; b < blend?.ingredients.length; b++) {
                const ing = blend.ingredients[b];
                if (ing.ratio != null) {
                    if (amount != null && unit_system) {
                        str += `${this.formatAmount(ing.ratio * amount, undefined, unit_system)}`;
                    } else {
                        str += `${(ing.ratio * 100).toFixed(0)}%`;
                    }
                    if (ing.coffee) {
                        if (ing.coffee.hr_id) {
                            str += ` ${ing.coffee.hr_id}`;
                        }
                        let oriYear = this.originCropDateToString(ing.coffee.origin, ing.coffee.crop_date);
                        if (oriYear) {
                            if (!ing.coffee.crop_date && ing.coffee.yearLabel) {
                                oriYear += `${oriYear ? ' ' : ''}${ing.coffee.yearLabel}`;
                            }
                            str += ` ${oriYear}`;
                        }
                        if (ing.coffee.label && (!smallSize || ing.coffee.label.length < 20)) {
                            str += `${str ? ', ' : ''}${ing.coffee.label}`;
                        }
                        if (b < blend.ingredients.length - 1) {
                            str += separator;
                        }
                    }
                }
            }
            return str;
        }
        return '';
    }

    public padNumber(num: number, len: number): string {
        return Math.round(num).toString().padStart(len, '0');
    }

    /**
     * Retrieves a list of all countries in the world.
     * Mostly from https://www.sport-histoire.fr/en/Geography/Currencies_countries_of_the_world.php, July 2021
     * Exceptions:
     * - Brunei => Brunei Darussalam
     * - Czech Republic => Czech Republic
     * - East Timor => Timor, East
     * - Kosovo, Vatican removed
     * - Syria => Syrian Arab Republic
     * - Russia => Russian Federation
     * @returns a list of countries (val) with its translation (tr) the currency (cur) and continent (cont)
     */
    static getAllCountriesInfos(): { val: string, tr: string, cur: string, cont: string }[] {
        if (!Utils.allCountries?.length) {
            Utils.allCountries.push({ val: 'Afghanistan', tr: Utils.utr.anslate('Afghanistan'), cur: 'AFN', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Albania', tr: Utils.utr.anslate('Albania'), cur: 'ALL', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Algeria', tr: Utils.utr.anslate('Algeria'), cur: 'DZD', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Andorra', tr: Utils.utr.anslate('Andorra'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Angola', tr: Utils.utr.anslate('Angola'), cur: 'AOA', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Antigua and Barbuda', tr: Utils.utr.anslate('Antigua and Barbuda'), cur: 'XCD', cont: 'America' })
            Utils.allCountries.push({ val: 'Argentina', tr: Utils.utr.anslate('Argentina'), cur: 'ARS', cont: 'America' })
            Utils.allCountries.push({ val: 'Armenia', tr: Utils.utr.anslate('Armenia'), cur: 'AMD', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Australia', tr: Utils.utr.anslate('Australia'), cur: 'AUD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Austria', tr: Utils.utr.anslate('Austria'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Azerbaijan', tr: Utils.utr.anslate('Azerbaijan'), cur: 'AZN', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Bahamas', tr: Utils.utr.anslate('Bahamas'), cur: 'BSD', cont: 'America' })
            Utils.allCountries.push({ val: 'Bahrain', tr: Utils.utr.anslate('Bahrain'), cur: 'BHD', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Bangladesh', tr: Utils.utr.anslate('Bangladesh'), cur: 'BDT', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Barbados', tr: Utils.utr.anslate('Barbados'), cur: 'BBD', cont: 'America' })
            Utils.allCountries.push({ val: 'Belarus', tr: Utils.utr.anslate('Belarus'), cur: 'BYN', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Belgium', tr: Utils.utr.anslate('Belgium'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Belize', tr: Utils.utr.anslate('Belize'), cur: 'BZD', cont: 'America' })
            Utils.allCountries.push({ val: 'Benin', tr: Utils.utr.anslate('Benin'), cur: 'XOF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Bhutan', tr: Utils.utr.anslate('Bhutan'), cur: 'BTN', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Bolivia', tr: Utils.utr.anslate('Bolivia'), cur: 'BOB', cont: 'America' })
            Utils.allCountries.push({ val: 'Bosnia and Herzegovina', tr: Utils.utr.anslate('Bosnia and Herzegovina'), cur: 'BAM', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Botswana', tr: Utils.utr.anslate('Botswana'), cur: 'BWP', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Brazil', tr: Utils.utr.anslate('Brazil'), cur: 'BRL', cont: 'America' })
            Utils.allCountries.push({ val: 'Brunei Darussalam', tr: Utils.utr.anslate('Brunei Darussalam'), cur: 'BND', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Bulgaria', tr: Utils.utr.anslate('Bulgaria'), cur: 'BGN', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Burkina Faso', tr: Utils.utr.anslate('Burkina Faso'), cur: 'XOF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Burundi', tr: Utils.utr.anslate('Burundi'), cur: 'BIF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Cambodia', tr: Utils.utr.anslate('Cambodia'), cur: 'KHR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Cameroon', tr: Utils.utr.anslate('Cameroon'), cur: 'XAF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Canada', tr: Utils.utr.anslate('Canada'), cur: 'CAD', cont: 'America' })
            Utils.allCountries.push({ val: 'Cape Verde', tr: Utils.utr.anslate('Cape Verde'), cur: 'CVE', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Central African Republic', tr: Utils.utr.anslate('Central African Republic'), cur: 'XAF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Chad', tr: Utils.utr.anslate('Chad'), cur: 'XAF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Chile', tr: Utils.utr.anslate('Chile'), cur: 'CLP', cont: 'America' })
            Utils.allCountries.push({ val: 'China', tr: Utils.utr.anslate('China'), cur: 'CNY', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Colombia', tr: Utils.utr.anslate('Colombia'), cur: 'COP', cont: 'America' })
            Utils.allCountries.push({ val: 'Comoros', tr: Utils.utr.anslate('Comoros'), cur: 'KMF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Costa Rica', tr: Utils.utr.anslate('Costa Rica'), cur: 'CRC', cont: 'America' })
            Utils.allCountries.push({ val: 'Croatia', tr: Utils.utr.anslate('Croatia'), cur: 'HRK', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Cuba', tr: Utils.utr.anslate('Cuba'), cur: 'CUP', cont: 'America' })
            Utils.allCountries.push({ val: 'Cyprus', tr: Utils.utr.anslate('Cyprus'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Czech Republic', tr: Utils.utr.anslate('Czech Republic'), cur: 'CZK', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Congo, DR', tr: Utils.utr.anslate('Congo, DR'), cur: 'CDF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Denmark', tr: Utils.utr.anslate('Denmark'), cur: 'DKK', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Djibouti', tr: Utils.utr.anslate('Djibouti'), cur: 'DJF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Dominica', tr: Utils.utr.anslate('Dominica'), cur: 'XCD', cont: 'America' })
            Utils.allCountries.push({ val: 'Dominican Republic', tr: Utils.utr.anslate('Dominican Republic'), cur: 'DOP', cont: 'America' })
            Utils.allCountries.push({ val: 'Timor, East', tr: Utils.utr.anslate('Timor, East'), cur: 'USD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Timor', tr: Utils.utr.anslate('Timor'), cur: 'USD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Ecuador', tr: Utils.utr.anslate('Ecuador'), cur: 'USD', cont: 'America' })
            Utils.allCountries.push({ val: 'Egypt', tr: Utils.utr.anslate('Egypt'), cur: 'EGP', cont: 'Africa' })
            Utils.allCountries.push({ val: 'El Salvador', tr: Utils.utr.anslate('El Salvador'), cur: 'USD', cont: 'America' })
            Utils.allCountries.push({ val: 'Equatorial Guinea', tr: Utils.utr.anslate('Equatorial Guinea'), cur: 'XAF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Eritrea', tr: Utils.utr.anslate('Eritrea'), cur: 'ERN', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Estonia', tr: Utils.utr.anslate('Estonia'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Eswatini', tr: Utils.utr.anslate('Eswatini'), cur: 'SZL', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Ethiopia', tr: Utils.utr.anslate('Ethiopia'), cur: 'ETB', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Fiji', tr: Utils.utr.anslate('Fiji'), cur: 'FJD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Finland', tr: Utils.utr.anslate('Finland'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'France', tr: Utils.utr.anslate('France'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Gabon', tr: Utils.utr.anslate('Gabon'), cur: 'XAF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Gambia', tr: Utils.utr.anslate('Gambia'), cur: 'GMD', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Georgia', tr: Utils.utr.anslate('Georgia'), cur: 'GEL', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Germany', tr: Utils.utr.anslate('Germany'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Ghana', tr: Utils.utr.anslate('Ghana'), cur: 'GHS', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Greece', tr: Utils.utr.anslate('Greece'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Grenada', tr: Utils.utr.anslate('Grenada'), cur: 'XCD', cont: 'America' })
            Utils.allCountries.push({ val: 'Guatemala', tr: Utils.utr.anslate('Guatemala'), cur: 'GTQ', cont: 'America' })
            Utils.allCountries.push({ val: 'Guinea', tr: Utils.utr.anslate('Guinea'), cur: 'GNF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Guinea-Bissau', tr: Utils.utr.anslate('Guinea-Bissau'), cur: 'XOF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Guyana', tr: Utils.utr.anslate('Guyana'), cur: 'GYD', cont: 'America' })
            Utils.allCountries.push({ val: 'Haiti', tr: Utils.utr.anslate('Haiti'), cur: 'HTG', cont: 'America' })
            Utils.allCountries.push({ val: 'Honduras', tr: Utils.utr.anslate('Honduras'), cur: 'HNL', cont: 'America' })
            Utils.allCountries.push({ val: 'Hungary', tr: Utils.utr.anslate('Hungary'), cur: 'HUF', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Iceland', tr: Utils.utr.anslate('Iceland'), cur: 'ISK', cont: 'Europe' })
            Utils.allCountries.push({ val: 'India', tr: Utils.utr.anslate('India'), cur: 'INR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Indonesia', tr: Utils.utr.anslate('Indonesia'), cur: 'IDR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Iran', tr: Utils.utr.anslate('Iran'), cur: 'IRR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Iraq', tr: Utils.utr.anslate('Iraq'), cur: 'IQD', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Ireland', tr: Utils.utr.anslate('Ireland'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Israel', tr: Utils.utr.anslate('Israel'), cur: 'ILS', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Italy', tr: Utils.utr.anslate('Italy'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Ivory Coast', tr: Utils.utr.anslate('Ivory Coast'), cur: 'XOF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Jamaica', tr: Utils.utr.anslate('Jamaica'), cur: 'JMD', cont: 'America' })
            Utils.allCountries.push({ val: 'Japan', tr: Utils.utr.anslate('Japan'), cur: 'JPY', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Jordan', tr: Utils.utr.anslate('Jordan'), cur: 'JOD', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Kazakhstan', tr: Utils.utr.anslate('Kazakhstan'), cur: 'KZT', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Kenya', tr: Utils.utr.anslate('Kenya'), cur: 'KES', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Kiribati', tr: Utils.utr.anslate('Kiribati'), cur: 'AUD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'North Korea', tr: Utils.utr.anslate('North Korea'), cur: 'KPW', cont: 'Asia' })
            Utils.allCountries.push({ val: 'South Korea', tr: Utils.utr.anslate('South Korea'), cur: 'KRW', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Kuwait', tr: Utils.utr.anslate('Kuwait'), cur: 'KWD', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Kyrgyzstan', tr: Utils.utr.anslate('Kyrgyzstan'), cur: 'KGS', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Laos', tr: Utils.utr.anslate('Laos'), cur: 'LAK', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Latvia', tr: Utils.utr.anslate('Latvia'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Lebanon', tr: Utils.utr.anslate('Lebanon'), cur: 'LBP', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Lesotho', tr: Utils.utr.anslate('Lesotho'), cur: 'LSL', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Liberia', tr: Utils.utr.anslate('Liberia'), cur: 'LRD', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Libya', tr: Utils.utr.anslate('Libya'), cur: 'LYD', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Liechtenstein', tr: Utils.utr.anslate('Liechtenstein'), cur: 'CHF', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Lithuania', tr: Utils.utr.anslate('Lithuania'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Luxembourg', tr: Utils.utr.anslate('Luxembourg'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Madagascar', tr: Utils.utr.anslate('Madagascar'), cur: 'MGA', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Malawi', tr: Utils.utr.anslate('Malawi'), cur: 'MWK', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Malaysia', tr: Utils.utr.anslate('Malaysia'), cur: 'MYR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Maldives', tr: Utils.utr.anslate('Maldives'), cur: 'MVR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Mali', tr: Utils.utr.anslate('Mali'), cur: 'XOF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Malta', tr: Utils.utr.anslate('Malta'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Marshall Islands', tr: Utils.utr.anslate('Marshall Islands'), cur: 'USD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Mauritania', tr: Utils.utr.anslate('Mauritania'), cur: 'MRO', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Mauritius', tr: Utils.utr.anslate('Mauritius'), cur: 'MUR', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Mexico', tr: Utils.utr.anslate('Mexico'), cur: 'MXN', cont: 'America' })
            Utils.allCountries.push({ val: 'Micronesia', tr: Utils.utr.anslate('Micronesia'), cur: 'USD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Moldova', tr: Utils.utr.anslate('Moldova'), cur: 'MDL', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Monaco', tr: Utils.utr.anslate('Monaco'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Mongolia', tr: Utils.utr.anslate('Mongolia'), cur: 'MNT', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Montenegro', tr: Utils.utr.anslate('Montenegro'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Morocco', tr: Utils.utr.anslate('Morocco'), cur: 'MAD', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Mozambique', tr: Utils.utr.anslate('Mozambique'), cur: 'MZN', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Myanmar', tr: Utils.utr.anslate('Myanmar'), cur: 'MMK', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Namibia', tr: Utils.utr.anslate('Namibia'), cur: 'NAD', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Nauru', tr: Utils.utr.anslate('Nauru'), cur: 'AUD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Nepal', tr: Utils.utr.anslate('Nepal'), cur: 'NPR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Netherlands', tr: Utils.utr.anslate('Netherlands'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'New Zealand', tr: Utils.utr.anslate('New Zealand'), cur: 'NZD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Nicaragua', tr: Utils.utr.anslate('Nicaragua'), cur: 'NIO', cont: 'America' })
            Utils.allCountries.push({ val: 'Niger', tr: Utils.utr.anslate('Niger'), cur: 'XOF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Nigeria', tr: Utils.utr.anslate('Nigeria'), cur: 'NGN', cont: 'Africa' })
            Utils.allCountries.push({ val: 'North Macedonia', tr: Utils.utr.anslate('North Macedonia'), cur: 'MKD', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Norway', tr: Utils.utr.anslate('Norway'), cur: 'NOK', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Oman', tr: Utils.utr.anslate('Oman'), cur: 'OMR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Pakistan', tr: Utils.utr.anslate('Pakistan'), cur: 'PKR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Palau', tr: Utils.utr.anslate('Palau'), cur: 'USD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Palestine', tr: Utils.utr.anslate('Palestine'), cur: 'ILS', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Panama', tr: Utils.utr.anslate('Panama'), cur: 'PAB', cont: 'America' })
            Utils.allCountries.push({ val: 'PNG', tr: Utils.utr.anslate('PNG'), cur: 'PGK', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Paraguay', tr: Utils.utr.anslate('Paraguay'), cur: 'PYG', cont: 'America' })
            Utils.allCountries.push({ val: 'Peru', tr: Utils.utr.anslate('Peru'), cur: 'PEN', cont: 'America' })
            Utils.allCountries.push({ val: 'Philippines', tr: Utils.utr.anslate('Philippines'), cur: 'PHP', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Poland', tr: Utils.utr.anslate('Poland'), cur: 'PLN', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Portugal', tr: Utils.utr.anslate('Portugal'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Qatar', tr: Utils.utr.anslate('Qatar'), cur: 'QAR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Congo, Republic', tr: Utils.utr.anslate('Congo, Republic'), cur: 'XAF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Romania', tr: Utils.utr.anslate('Romania'), cur: 'ROL', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Russian Federation', tr: Utils.utr.anslate('Russian Federation'), cur: 'RUB', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Rwanda', tr: Utils.utr.anslate('Rwanda'), cur: 'RWF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Saint Kitts and Nevis', tr: Utils.utr.anslate('Saint Kitts and Nevis'), cur: 'XCD', cont: 'America' })
            Utils.allCountries.push({ val: 'St. Lucia', tr: Utils.utr.anslate('St. Lucia'), cur: 'XCD', cont: 'America' })
            Utils.allCountries.push({ val: 'St. Vincent', tr: Utils.utr.anslate('St. Vincent'), cur: 'XCD', cont: 'America' })
            Utils.allCountries.push({ val: 'Samoa', tr: Utils.utr.anslate('Samoa'), cur: 'WST', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'San Marino', tr: Utils.utr.anslate('San Marino'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'São Tomé', tr: Utils.utr.anslate('São Tomé'), cur: 'STD', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Saudi Arabia', tr: Utils.utr.anslate('Saudi Arabia'), cur: 'SAR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Senegal', tr: Utils.utr.anslate('Senegal'), cur: 'XOF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Serbia', tr: Utils.utr.anslate('Serbia'), cur: 'RSD', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Seychelles', tr: Utils.utr.anslate('Seychelles'), cur: 'SCR', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Sierra Leone', tr: Utils.utr.anslate('Sierra Leone'), cur: 'SLL', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Singapore', tr: Utils.utr.anslate('Singapore'), cur: 'SGD', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Slovakia', tr: Utils.utr.anslate('Slovakia'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Slovenia', tr: Utils.utr.anslate('Slovenia'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Solomon Islands', tr: Utils.utr.anslate('Solomon Islands'), cur: 'SBD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Somalia', tr: Utils.utr.anslate('Somalia'), cur: 'SOS', cont: 'Africa' })
            Utils.allCountries.push({ val: 'South Africa', tr: Utils.utr.anslate('South Africa'), cur: 'ZAR', cont: 'Africa' })
            Utils.allCountries.push({ val: 'South Sudan', tr: Utils.utr.anslate('South Sudan'), cur: 'SSP', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Spain', tr: Utils.utr.anslate('Spain'), cur: 'EUR', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Sri Lanka', tr: Utils.utr.anslate('Sri Lanka'), cur: 'LKR', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Sudan', tr: Utils.utr.anslate('Sudan'), cur: 'SDG', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Suriname', tr: Utils.utr.anslate('Suriname'), cur: 'SRD', cont: 'America' })
            Utils.allCountries.push({ val: 'Sweden', tr: Utils.utr.anslate('Sweden'), cur: 'SEK', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Switzerland', tr: Utils.utr.anslate('Switzerland'), cur: 'CHF', cont: 'Europe' })
            Utils.allCountries.push({ val: 'Syrian Arab Republic', tr: Utils.utr.anslate('Syrian Arab Republic'), cur: 'SYP', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Taiwan', tr: Utils.utr.anslate('Taiwan'), cur: 'TWD', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Tajikistan', tr: Utils.utr.anslate('Tajikistan'), cur: 'TJS', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Tanzania', tr: Utils.utr.anslate('Tanzania'), cur: 'TZS', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Thailand', tr: Utils.utr.anslate('Thailand'), cur: 'THB', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Togo', tr: Utils.utr.anslate('Togo'), cur: 'XOF', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Tonga', tr: Utils.utr.anslate('Tonga'), cur: 'TOP', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Trinidad and Tobago', tr: Utils.utr.anslate('Trinidad and Tobago'), cur: 'TTD', cont: 'America' })
            Utils.allCountries.push({ val: 'Tunisia', tr: Utils.utr.anslate('Tunisia'), cur: 'TND', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Turkey', tr: Utils.utr.anslate('Turkey'), cur: 'TRY', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Turkmenistan', tr: Utils.utr.anslate('Turkmenistan'), cur: 'TMT', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Tuvalu', tr: Utils.utr.anslate('Tuvalu'), cur: 'AUD', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Uganda', tr: Utils.utr.anslate('Uganda'), cur: 'UGX', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Ukraine', tr: Utils.utr.anslate('Ukraine'), cur: 'UAH', cont: 'Europe' })
            Utils.allCountries.push({ val: 'United Arab Emirates', tr: Utils.utr.anslate('United Arab Emirates'), cur: 'AED', cont: 'Asia' })
            Utils.allCountries.push({ val: 'UK', tr: Utils.utr.anslate('UK'), cur: 'GBP', cont: 'Europe' })
            Utils.allCountries.push({ val: 'USA', tr: Utils.utr.anslate('USA'), cur: 'USD', cont: 'America' })
            Utils.allCountries.push({ val: 'Uruguay', tr: Utils.utr.anslate('Uruguay'), cur: 'UYU', cont: 'America' })
            Utils.allCountries.push({ val: 'Uzbekistan', tr: Utils.utr.anslate('Uzbekistan'), cur: 'UZS', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Vanuatu', tr: Utils.utr.anslate('Vanuatu'), cur: 'VUV', cont: 'Oceania' })
            Utils.allCountries.push({ val: 'Venezuela', tr: Utils.utr.anslate('Venezuela'), cur: 'VEF', cont: 'America' })
            Utils.allCountries.push({ val: 'Vietnam', tr: Utils.utr.anslate('Vietnam'), cur: 'VND', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Yemen', tr: Utils.utr.anslate('Yemen'), cur: 'YER', cont: 'Asia' })
            Utils.allCountries.push({ val: 'Zambia', tr: Utils.utr.anslate('Zambia'), cur: 'ZMW', cont: 'Africa' })
            Utils.allCountries.push({ val: 'Zimbabwe', tr: Utils.utr.anslate('Zimbabwe'), cur: 'USD', cont: 'Africa' })

            Utils.allCountries.sort((c1, c2) => c1.tr.localeCompare(c2.tr, Utils.staticLocale));
        }

        return Utils.allCountries;
    }
}
