<div class="sp-wrapper" [ngClass]="{'sp-wrapper-empty': wholeExpanded === 0}">
    <div style="max-width: 100%; overflow-x: scroll; overflow-y: hidden; white-space: nowrap;">
        <div class="sp-frame">
            @if (wholeExpanded > 0) {
            <mat-icon class="expand-icon-float" (click)="expandWhole(-1)">expand_less</mat-icon>
            }
            @if (wholeExpanded === 1) {
            <mat-icon class="expand-icon-float expand-icon-float-right" (click)="expandWhole(1)">expand_more</mat-icon>
            } @else if (wholeExpanded === 0) {
            <mat-icon class="expand-icon-float expand-icon-float-left" (click)="expandWhole(1)">chevron_right</mat-icon>
            <div (click)="expandWhole(1)" (keydown.enter)="expandWhole(1); $event.preventDefault()" role="button" [tabindex]="0"
                class="sp-placeholder" i18n="Coupons$$Supplier Partners@@Supplier Partners">Supplier Partners
            </div>
            }

            @if (wholeExpanded > 0) {
            @for (contact of supPartners; track contact?._id) {
            <mat-expansion-panel #expansionpanel [disabled]="editMode === $index" hideToggle="true"
                class="sp-panel" [ngClass]="{'wide-contact-card': editMode === $index}"
                (closed)="expanded[$index] = false" (opened)="expanded[$index] = true">
                <mat-expansion-panel-header class="sp-panel-header" [ngClass]="{'sp-panel-header-coupon': wholeExpanded === 1 && !expanded[$index] && contact.coupons?.length && contact.coupons[0].active}" [ngStyle]="{'padding-bottom': (wholeExpanded === 1 && !expanded[$index] && contact.coupons?.length && contact.coupons[0].active) ? 0 : '5px'}" expandedHeight="auto" collapsedHeight="auto">
                    <mat-panel-title class="sp-paneltitle" [ngClass]="{'sp-panel-title-coupon': wholeExpanded === 1 && contact.coupons?.length && contact.coupons[0].code}" [ngStyle]="{'min-height': wholeExpanded === 1 ? '62px' : '92px'}">
                        <div class="sp-paneltitle-content">
                            <span>
                                <div class="contact-title-label">
                                    @if (editMode !== $index) {
                                    <span #contactLabel style="white-space: normal;">{{contact.label}}</span>
                                    } @else {
                                    <span #contactLabel style="white-space: normal;">{{contactcopy.label}}</span>
                                    }
                                    @if (contact?.web) {
                                    <a data-test="lnk_contactWeb" href="{{contact.web}}"
                                        class="contact-title-link" target="_blank" rel="noopener"
                                        (click)="$event.stopPropagation()">
                                        <mat-icon class="small">launch</mat-icon>
                                    </a>
                                    }
                                </div>
                                @if ((expanded[$index] && wholeExpanded > 0) || wholeExpanded === 2) {
                                <div class="mat-card-subtitle sp-subtitle">
                                    @if (editMode !== $index && contact?.code) {
                                    <span>{{contact.code}}</span>
                                    }
                                    @if (editMode === $index && contactcopy?.code) {
                                    <span>{{contactcopy.code}}</span>
                                    }
                                    @if ((editMode !== $index && contact?.code || editMode === $index && contactcopy?.code) && (editMode === $index && contactcopy?.['floId'] || editMode !== $index && contact?.floId)) {
                                    <span>, </span>
                                    }
                                    @if (editMode === $index && contactcopy?.['floId']) {
                                    <span>FLO ID <a (click)="searchFloId(LocTypes.SUPPLIER, $index, '', contactcopy?.['floId']); $event.stopPropagation()" (keydown.enter)="searchFloId(LocTypes.SUPPLIER, $index, '', contactcopy?.['floId']); $event.stopPropagation(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">#{{contactcopy['floId']}}</a></span>
                                    }
                                    @if (editMode !== $index && contact?.floId) {
                                    <span>FLO ID <a (click)="searchFloId(LocTypes.SUPPLIER, $index, '', contact?.floId); $event.stopPropagation()" (keydown.enter)="searchFloId(LocTypes.SUPPLIER, $index, '', contact?.floId); $event.stopPropagation(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">#{{contact.floId}}</a></span>
                                    }
                                    @if (loadingFloId === $index) {
                                    <mat-spinner diameter="14" class="littlespaceleft" style="display: inline;"></mat-spinner>
                                    }
                                </div>
                                }
                            </span>
                            @if (contact?._id) {
                            <span class="sp-logo">
                                <img width="55px" height="35px" [src]="'assets/images/suppliers/' + contact.partner + (isDarkmode ? '_dark' : '') + '_70x110.' + (contact.useSVG ? 'svg' : 'png')"
                                    onerror="this.style.display='none'" alt="a picture of the supplier">
                            </span>
                            }
                        </div>
                        @if (((expanded[$index] && wholeExpanded > 0) || wholeExpanded === 2) && contact.coupons?.length) {
                        <app-supplier-coupon style="width: 100%;"
                            [coupons]="contact.coupons" [isDarkmode]="isDarkmode"
                            [supplierPartner]="contact?.['__t'] === 'SupplierPartner' ? contact : undefined">
                        </app-supplier-coupon>
                        }
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div class="sp-content-wrapper">
                        <!-- <div *ngIf="expanded[$index] && wholeExpanded > 0" class="sp-content-wrapper"> -->
                        @if (!readOnly && editMode === $index) {
                        <div class="sp-content">
                            <form id="form" name="form" (ngSubmit)="mainForm.form.valid && (!editPlaceMode || (editPlaceMode && editingLocation?.label)) && save(LocTypes.SUPPLIER, $index, true)" #mainForm="ngForm">
                                <mat-form-field class="form-group">
                                    <input required placeholder="Name" matInput data-test="npt_name"
                                        i18n-placeholder="generic$$Name@@Name" type="text" name="label" [(ngModel)]="contactcopy.label">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft">
                                    <input placeholder="Code" data-test="npt_code" matInput i18n-placeholder="contacts$$some user defined code for the contact@@code"
                                        [(ngModel)]="contactcopy.code" type="text" name="code">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright-smaller">
                                    <input placeholder="FLO ID" data-test="npt_floId" matInput
                                        [(ngModel)]="contactcopy['floId']" type="text" name="floId">
                                </mat-form-field>
                                @if (loadingFloId !== $index && (contactcopy['floId'] || contactcopy.label?.length >= 3)) {
                                <mat-icon data-test="cn_searchFloId"
                                    style="vertical-align: middle; margin-left: 5px;" class="primary-element cursor-pointer"
                                    (click)="searchFloId(LocTypes.SUPPLIER, $index, contactcopy.label, contactcopy['floId'])">search</mat-icon>
                                }
                                @if (loadingFloId === $index) {
                                <mat-spinner diameter="14" class="littlespaceleft" style="display: inline;"></mat-spinner>
                                }
                                @if (!getPlaces(LocTypes.SUPPLIER)) {
                                <div class="spinner-with-comment">
                                    <mat-spinner data-test="spnnr_loadPlaces" diameter="20"></mat-spinner> <span class="spinner-comment" i18n="contacts$$loading already defined locations@@loading fields">Loading places ...</span>
                                </div>
                                }
                                <div class="flex-wrap">
                                    @if (getPlaces(LocTypes.SUPPLIER) && !editPlaceMode) {
                                    <mat-form-field data-test="slct_place" class="form-group left-large">
                                        <mat-select placeholder="Place" matInput i18n-placeholder="contacts$$location, placeholder@@place" name="place"
                                            [(ngModel)]="contactcopy.location" [compareWith]="utils.compareObjectsFn" (selectionChange)="placeChanged(LocTypes.SUPPLIER)">
                                            <mat-option [value]="null"></mat-option>
                                            <mat-option value="######" class="highlight-add-option">
                                                @if (newLocation && editingLocation) {
                                                <span>{{editingLocation.label}} @if (editingLocation.country) {
                                                    <span>({{tr.anslate(editingLocation.country)}})</span>
                                                    }</span>
                                                } @else {
                                                <ng-container i18n="contacts$$add a new location, option@@add a new place">Add new place</ng-container>
                                                }
                                            </mat-option>
                                            @for (place of getPlaces(LocTypes.SUPPLIER); track place?.hr_id) {
                                            <mat-option [value]="place">
                                                @if (place.hr_id) {
                                                <span>{{place.hr_id}}</span>
                                                }
                                                @if (place.label) {
                                                <span> {{place.label}}</span>
                                                }
                                                @if (place.country) {
                                                <span> ({{tr.anslate(place.country)}})</span>
                                                }
                                            </mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                    }
                                    @if (!editPlaceMode && contactcopy.location) {
                                    <button data-test="bttn_editPlace" mat-raised-button type="button"
                                        (click)="editPlace()" class="discardButton right-button" i18n="generic$$edit some details, button@@edit">Edit</button>
                                    }
                                </div>
                                <!-- <br *ngIf="getPlaces() && !editPlaceMode"/> -->
                                <!-- <form *ngIf="editPlaceMode && editingLocation" id="pform" name="pform" #pf="ngForm" class="new-place-part"> -->
                                @if (editPlaceMode && editingLocation) {
                                <div>
                                    <mat-form-field class="form-group halfsizeleft">
                                        <input required placeholder="Name" data-test="npt_placeName" matInput i18n-placeholder="generic$$Name@@Name"
                                            [(ngModel)]="editingLocation.label" type="text" name="placelabel">
                                    </mat-form-field>
                                    <mat-form-field class="form-group halfsizeright">
                                        <input placeholder="Street" matInput data-test="npt_placeStreet" i18n-placeholder="contacts$$street@@street"
                                            [(ngModel)]="editingLocation.street" type="text" name="street">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group halfsizeleft">
                                        <input placeholder="Address" matInput data-test="npt_placeAddress" i18n-placeholder="contacts$$address@@Address"
                                            [(ngModel)]="editingLocation.address" type="text" name="address">
                                    </mat-form-field>
                                    <mat-form-field class="form-group halfsizeright">
                                        <input placeholder="Postal Code" matInput data-test="npt_placePostalCode" i18n-placeholder="contacts$$Postal Code@@Postal Code"
                                            [(ngModel)]="editingLocation.zip_code" type="text" name="zip_code">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group halfsizeleft">
                                        <input placeholder="City" matInput data-test="npt_placeCity" i18n-placeholder="contacts$$City@@city"
                                            [(ngModel)]="editingLocation.city" type="text" name="city">
                                    </mat-form-field>
                                    <mat-form-field class="form-group halfsizeright">
                                        <input placeholder="Region" matInput data-test="npt_placeRegion" i18n-placeholder="contacts$$region@@region"
                                            [(ngModel)]="editingLocation.region" type="text" name="region">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group halfsizeleft">
                                        <input placeholder="Subregion" matInput data-test="npt_placeSubregion" i18n-placeholder="contacts$$subregion@@subregion"
                                            [(ngModel)]="editingLocation.subregion" type="text" name="subregion">
                                    </mat-form-field>
                                    <mat-form-field class="form-group halfsizeright">
                                        <input placeholder="Country" data-test="npt_placeCountry" matInput type="text" i18n-placeholder="contacts$$country@@Country"
                                            (input)="changeCountryFilter($event.target['value'])" (focus)="changeCountryFilter($event.target['value'])" name="country" id="country"
                                            [ngModel]="editingLocation.country" [matAutocomplete]="autoCompleteCountryPlace"
                                            (blur)="checkCountry($event.target['value'])">
                                        <mat-autocomplete #autoCompleteCountryPlace="matAutocomplete">
                                            @for (country of filteredCountries; track country) {
                                            <mat-option [value]="country">{{country}}</mat-option>
                                            }
                                        </mat-autocomplete>
                                    </mat-form-field><br />
                                    <!-- coordinates -->
                                    <!-- <mat-form-field class="form-group">
                          <input matInput i18n-placeholder="contacts$$street@@street" type="text" placeholder="Street" name="street" [(ngModel)]="editingLocation.street">
                        </mat-form-field><br/> -->
                                    @if (!floIdEdit) {
                                    <button data-test="bttn_close"
                                        i18n="contacts$$go back to select-location mode, button@@close edit place mode"
                                        (click)="closePlace(LocTypes.SUPPLIER)" mat-raised-button type="button" class="discardButton">Close</button>
                                    }
                                </div>
                                }
                                <!-- </form> -->
                                <mat-form-field class="form-group wide-form-field">
                                    <input placeholder="Web" data-test="npt_web" matInput i18n-placeholder="contacts$$internet address@@Web"
                                        [(ngModel)]="contactcopy.web" (blur)="checkLink(contactcopy)" type="text" name="web">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft">
                                    <input placeholder="Phone" matInput data-test="npt_phone" i18n-placeholder="contacts$$Phone@@Phone"
                                        [(ngModel)]="contactcopy.phone" type="text" name="phone">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright">
                                    <input placeholder="Email" matInput data-test="npt_email" i18n-placeholder="users$$email address@@email"
                                        [(ngModel)]="contactcopy.email" type="text" name="email">
                                </mat-form-field><br />
                                <mat-form-field class="form-group wide-form-field">
                                    <input placeholder="Notes" data-test="npt_notes" matInput i18n-placeholder="generic$$arbitrary notes@@Notes"
                                        [(ngModel)]="contactcopy.notes" type="text" name="notes">
                                </mat-form-field><br />
                                <!-- <img *ngIf="contactcopy.image" data-test="img_contact" [src]="contactcopy.image" class="contact-image" (click)="addImage(c)">
                                <br /> -->
                                <div class="inner-action-row">
                                    <button (click)="cancel()" data-test="bttn_cancel" mat-button type="button" class="cancel-button">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    @if (!isSaving) {
                                    <button [disabled]="isSaving" data-test="bttn_done" mat-raised-button
                                        type="submit" class="action-button" form="form" data-test="bttn_done">
                                        <mat-icon>done</mat-icon>
                                    </button>
                                    } @else {
                                    <button disabled data-test="spnnr_whileSaving" mat-raised-button class="action-button spinner-button">
                                        <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                                    </button>
                                    }
                                </div>
                            </form>
                        </div>
                        } @else {
                        <div class="sp-content">
                            <!-- <app-modification-date [element]="contact" [user]="currentUser"></app-modification-date> -->
                            <div>
                                @if (contact?.location?.label && contact.location.label !== contact.label) {
                                {{contact.location.label}}<br />
                                }
                                @if (contact?.location?.street) {
                                {{contact.location.street}}<br />
                                }
                                @if (contact?.location?.address) {
                                {{contact.location.address}}<br />
                                }
                                @if (contact?.location?.zip_code) {
                                {{contact.location.zip_code}}
                                }
                                @if (contact?.location?.city) {
                                {{contact.location.city}}
                                }
                            </div>
                            <div>
                                @if (contact?.location?.subregion) {
                                <span>{{contact.location.subregion}}</span>
                                }
                                @if (contact?.location?.region) {
                                <span>@if (contact?.location?.subregion) {
                                    <span>, </span>
                                    }{{contact.location.region}}</span>
                                }
                                @if (contact?.location?.country) {
                                <span>@if (contact?.location?.subregion || contact?.location?.region) {
                                    <span>, </span>
                                    }{{tr.anslate(contact.location.country)}}</span>
                                }
                            </div>
                            @if (contact?.email?.indexOf) {
                            <div data-test="dv_email">
                                @if (contact.email.indexOf('@') > 0) {
                                <a href="mailto:{{contact.email}}">{{contact.email}}</a>
                                } @else {
                                <span i18n="users$$email address@@email">Email</span>: {{contact.email}}
                                }
                            </div>
                            }
                            @if (contact?.phone) {
                            <div><a [href]="'tel:' + formatPhone(contact.phone)">{{contact.phone}}</a></div>
                            }
                            @if (contact?.notes) {
                            <div><i>{{contact.notes}}</i></div>
                            }
                            <!-- <div>
                <img *ngIf="contact?.image" [src]="contact.image" class="contact-image" (click)="addImage(c)">
              </div> -->
                            <app-ap-file
                                [files]="contact?.files"
                                [belongsTo]="contact?._id"
                                belongsToType="CONTACTS"
                                (filesChanged)="filesChanged(LocTypes.SUPPLIER, $index, $event)"
                                [readOnly]="readOnly">
                            </app-ap-file>
                            @if (!readOnly && (0 > editMode || editMode !== $index)) {
                            <div style="text-align: end;">
                                <button [disabled]="editMode >= 0" data-test="bttn_delete"
                                    (click)="delete(LocTypes.SUPPLIER, $index)" mat-raised-button type="button" class="edit-button">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button [disabled]="editMode >= 0" data-test="bttn_edit"
                                    (click)="edit(LocTypes.SUPPLIER, $index)" mat-raised-button type="button" class="action-button" style="margin-right: 0;">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                            }
                        </div>
                        }
                    </div>
                </ng-template>
            </mat-expansion-panel>
            }
            }
        </div>
    </div>
    <!-- <button *ngIf="supPartners?.length" (click)="moveRight()" mat-icon-button>
<mat-icon>keyboard_arrow_right</mat-icon>
</button> -->
</div>