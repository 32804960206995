import { HttpRequest, HttpHandlerFn, HttpEvent, HttpResponse, HttpEventType } from "@angular/common/http";
import { inject } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, tap } from "rxjs";
import { UserService } from "src/app/modules/frame/services/user.service";
import { AccountChangedService } from "../services/accountchanged.service";
import { Utils } from "../utils";

let lastPu: string;
let lastOl: { rlimit: number, rused: number };

interface MyResponseType { pu: string, ol: { rlimit: number, rused: number }, rem: number, notifications: number }

export function accountInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const accountChangedService = inject(AccountChangedService);
    const userService = inject(UserService);
    const utils = inject(Utils);

    function olEqual(newOl: { rlimit?: number, rused?: number }, oldOl: { rlimit?: number, rused?: number }): boolean {
        return newOl === oldOl
            || (newOl?.rlimit === oldOl?.rlimit && newOl?.rused === oldOl?.rused);
    }

    return next(req).pipe(tap((event: HttpResponse<MyResponseType>) => {
        // ignore if pu not given
        // (call to public API; response does not inlude .pu since request does not include user)
        if (event.type === HttpEventType.Response && event.body) {
            const body = event.body;
            if (body.pu || body.ol || body.rem || body.notifications) {
                if (body.pu !== lastPu || (!olEqual(body.ol, lastOl))) {
                    lastPu = body.pu;
                    lastOl = body.ol;
                    // setTimeout(() => {
                    const user = userService.getCurrentUser();
                    if (!user) {
                        return event;
                    }
                    let needsUpdate = false;
                    if (utils.paidDaysLeft(body.pu) < 0 || utils.overLimit(body.ol)) {
                        // make sure readonly mode is set (if not already)
                        if (!user.readonly) {
                            user.readonly = true;
                            needsUpdate = true;
                        }
                    } else {
                        // disable readonly mode if not looking at a readonly_account
                        if (!(user.readonly_account_idx >= 0) && user.readonly) {
                            user.readonly = false;
                            needsUpdate = true;
                        }
                    }
                    if (body.pu.toString() !== user.account?.paidUntil?.toString()) {
                        user.account.paidUntil = body.pu ? DateTime.fromISO(body.pu.toString()) : undefined;
                        needsUpdate = true;
                    }
                    if (!olEqual(body.ol, user.account?.limit)) {
                        if (!user.account) {
                            user.account = {};
                        }
                        user.account.limit = body.ol;
                        needsUpdate = true;
                    }
                    if (needsUpdate) {
                        userService.storeCurrentUser(user);
                    }
                    // });
                }
                // always update since the reminder count could be changed in the DB
                accountChangedService.accountChanged({
                    paidUntil: body.pu ? DateTime.fromISO(body.pu) : undefined,
                    limit: body.ol,
                    reminders: body.rem,
                    notifications: body.notifications,
                });
            }
        }
        return event;
    }));
}
