import { FormsModule } from '@angular/forms';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextinputDialogComponent } from './textinput-dialog.component';
import { CantDeleteDialogComponent } from './cant-delete-dialog.component';
import { InfoDialogComponent } from './info-dialog.component';
import { YesNoDialogComponent } from './yesno-dialog.component';
import { YesNoConfirmDialogComponent } from './yesnoconfirm-dialog.component';
import { RouterModule } from '@angular/router';
import { NumberinputDialogComponent } from './numberinput-dialog.component';
import { YesNoWaitDialogComponent } from './yesno-wait-dialog.component';

@NgModule({
    declarations: [
        CantDeleteDialogComponent,
        InfoDialogComponent,
        TextinputDialogComponent,
        NumberinputDialogComponent,
        YesNoDialogComponent,
        YesNoWaitDialogComponent,
        YesNoConfirmDialogComponent,
    ],
    imports: [
        CommonModule,
        MyMaterialModule,
        FormsModule,
        RouterModule,
    ],
    exports: [
        CantDeleteDialogComponent,
        InfoDialogComponent,
        TextinputDialogComponent,
        YesNoDialogComponent,
        YesNoConfirmDialogComponent,
    ],
})
export class DialogModule { }
