<div>
    @if (readOnly) {
    <div class="readonly-spacer"></div>
    }

    <nav mat-tab-nav-bar mat-stretch-tabs [tabPanel]="tabPanel">
        <a mat-tab-link
            (click)="activeLink = 'roasts'"
            [routerLink]="'roasts'"
            [active]="activeLink === 'fixed' || activeLink === 'open'">
            <span i18n="reports$$Roasts@@Roasts">Roasts</span>
        </a>
        <a mat-tab-link
            (click)="activeLink = 'transactions'"
            [routerLink]="'transactions'"
            [active]="activeLink === 'transactions'">
            {{purchaseSaleLabel}}
        </a>
        <a mat-tab-link
            (click)="activeLink = 'stocks'"
            [routerLink]="'stocks'"
            [active]="activeLink === 'stocks'">
            <span i18n="transaction$$Stocks@@Stocks">Stocks</span>
        </a>
        <a mat-tab-link
            (click)="activeLink = 'customs'"
            [routerLink]="'customs'"
            [active]="activeLink === 'customs'">
            <span i18n="transaction$$Customs@@Customs">Customs</span>
        </a>
    </nav>

    <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
    </mat-tab-nav-panel>

</div>

<div class="enlargepage"></div>