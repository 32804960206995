<p>This is a test page. Here, you can see whether the artisan.plus service is currently working.</p>

<p></p>

@if (roastedCoffee) {
<p>Great, everything seems to be working fine.</p>
}
@if (roastedCoffee) {
<p>The amount of coffee roasted on artisan.plus is {{roastedCoffee | number:'1.0-0'}}kg!</p>
}

@if (sometest) {
<p> Sample object: {{sometest | json}}</p>
}

@if (!roastedCoffee) {
<p>
    Bummer, there seems to be a problem! Please contact <a href="mailto: admin@artisan.plus">admin&#64;artisan.plus</a>
</p>
}

<div class="enlargepage"></div>