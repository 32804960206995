import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';

interface ElementType {
    updated_by?: { _id: string, nickname: string };
    updated_at?: Date | string;
    created_by?: { _id: string, nickname: string };
    created_at?: Date | string;
}

@Component({
    selector: 'app-modification-date',
    templateUrl: './modification-date.component.html',
    styleUrls: ['./modification-date.component.scss']
})
export class ModificationDateComponent {

    cNickname: string;
    uNickname: string;

    _element: ElementType;
    @Input() set element(el: ElementType) {
        if (el) {
            // remove @... if inside a nickname
            if (el.updated_by?.nickname) {
                if (el.updated_by.nickname.indexOf('@') > 0) {
                    this.uNickname = el.updated_by.nickname.split('@')[0];
                } else {
                    this.uNickname = el.updated_by.nickname;
                }
            }
            if (el.created_by?.nickname) {
                if (el.created_by.nickname.indexOf('@') > 0) {
                    this.cNickname = el.created_by.nickname.split('@')[0];
                } else {
                    this.cNickname = el.created_by.nickname;
                }
            }
        }
        this._element = el;
    }

    @Input() user: { user_id: string };

    differentDay(date1: Date | string, date2: Date | string): boolean {
        const d1 = typeof date1 === 'string' ? DateTime.fromISO(date1) : DateTime.fromJSDate(date1);
        const d2 = typeof date2 === 'string' ? DateTime.fromISO(date2) : DateTime.fromJSDate(date2);
        return !d1.hasSame(d2, 'day');
    }
}
