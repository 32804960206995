import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { Coupon } from 'src/app/models/Coupon';
import { SupplierPartner } from 'src/app/models/SupplierPartner';
import { StandardService } from 'src/app/util/services/standard.service';
import { Utils } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-supplier-coupon',
    templateUrl: './supplier-coupon.component.html',
    styleUrls: ['./supplier-coupon.component.scss']
})
export class SupplierCouponComponent implements OnInit, OnDestroy {

    constructor(
        private clipboardService: ClipboardService,
        private standardService: StandardService,
        private utils: Utils,
    ) { }

    generalLinkPRO = 'https://doc.artisan.plus/partners?subscription=PRO';
    generalLinkHOME = 'https://doc.artisan.plus/partners?subscription=HOME';
    generalLink = this.generalLinkPRO;

    @Input() set isDarkmode(idm: boolean) {
        if (idm) {
            this.generalLink = this.generalLink.replace('//doc.', '//ddoc.');
        } else {
            this.generalLink = this.generalLink.replace('//ddoc.', '//doc.');
        }
    }

    @Input() coupons: Coupon[];

    @Input() supplierPartner: SupplierPartner;
    @Input() subscription: string;

    isSupported = false;
    justCopied = false;
    copyFailed = false;

    private ngUnsubscribe = new Subject();

    @ViewChild('copyElem') copyContainer: ElementRef;

    ngOnInit(): void {
        this.isSupported = this.clipboardService.isSupported;
        if (this.subscription?.indexOf('PRO') >= 0) {
            this.generalLink = this.generalLinkPRO;
        } else {
            this.generalLink = this.generalLinkHOME;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    requestCoupon(idx: number, event: { stopPropagation: () => void }): void {
        if (this.supplierPartner?.supplierid && this.supplierPartner.partner) {
            this.standardService.getCouponForSupplier(this.supplierPartner)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true && response.result) {
                            if (!this.coupons.length) {
                                this.coupons = [];
                            }
                            if (response.result[0]?.code) {
                                this.coupons[idx].code = response.result[0].code;
                            } else {
                                this.utils.handleError('No coupon available, please email info@artisan.plus');
                            }
                        } else {
                            this.utils.handleError('error retrieving information', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving information', error);
                    }
                });
        } else {
            this.utils.handleError('error retrieving information');
        }
        event.stopPropagation();
    }

    copy(event: { stopPropagation: () => void }): void {
        if (this.clipboardService.copyFromContent(this.coupons[0].code, this.copyContainer?.nativeElement)) {
            this.justCopied = true;
            setTimeout(() => {
                this.justCopied = false;
            }, 2000);
        } else {
            this.copyFailed = true;
            setTimeout(() => {
                this.copyFailed = false;
            }, 2000);
        }
        event.stopPropagation();
    }
}
