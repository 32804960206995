import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle, MatDatepickerToggleIcon } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { DateTime, Info } from 'luxon';
import { RRule, RRuleSet } from 'rrule';
import { Options } from 'rrule/dist/esm/types';
import { Coffee } from 'src/app/models/Coffee';
import { RoastScheduledItem } from 'src/app/models/RoastScheduledItem';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';

export interface RecurringDialogData {
    item: RoastScheduledItem,
    items?: RoastScheduledItem[],
    minRepsIdx?: number,
    minRepsCoffeeInfo: { coffee: Coffee, amount: number },
    batchSize: number,
    stock: number,
    mainUnit: UnitSystemType,
}

@Component({
    selector: 'app-recurring-dialog',
    templateUrl: './recurring-dialog.component.html',
    styleUrls: ['./recurring-dialog.component.scss'],
     
    imports: [MatFormField, MatInput, FormsModule, MatDialogContent, MatDialogActions, MatIcon, NgClass, MatOption, MatRadioGroup, MatRadioButton, MatDatepicker, MatDatepickerInput, MatDatepickerToggle, MatDatepickerToggleIcon, MatSelect, MatButton, MatButtonToggleModule, DatePipe, MatDialogClose, MatLuxonDateModule],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecurringDialogComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<RecurringDialogComponent, { add?: DateTime[], delete?: DateTime[] }>,
        private tr: TranslatorService,
        protected utils: Utils,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(MAT_DIALOG_DATA) public data: RecurringDialogData,
    ) { }

    FREQUENCIES = [RRule.DAILY, RRule.WEEKLY, RRule.MONTHLY, RRule.YEARLY];
    FREQUENCIES_STR = [this.tr.anslate('Year'), this.tr.anslate('Month'), this.tr.anslate('Week'), this.tr.anslate('Day')];
    years = this.tr.anslate('years');
    weeks = this.tr.anslate('weeks');
    days = this.tr.anslate('days');
    FREQUENCIES_STR_PLURAL = [
        `${this.years[0].toLocaleUpperCase()}${this.years.slice(1)}`,
        this.tr.anslate('Months'),
        `${this.weeks[0].toLocaleUpperCase()}${this.weeks.slice(1)}`,
        `${this.days[0].toLocaleUpperCase()}${this.days.slice(1)}`,
    ];

    end: 'date' | 'count' = 'count';
    until: DateTime = null;

    reps = 0;

    options: Options;
    dates: Date[] = [];
    exdates: Date[] = [];
    totalRoastsCount = 0;

    tomorrow: DateTime;;
    WEEKDAYS = Info.weekdays('short', { locale: this.locale });

    RRule = RRule;
    Math = Math;

    ngOnInit(): void {
        // normally, one clones an item of today => start tomorrow
        this.tomorrow = DateTime.now().plus({ day: 1 }).startOf('day');
        const itemDate = (this.data.item ?? this.data.items?.[0])?.date;
        if (itemDate) {
            // clone should start day after current item's date
            this.tomorrow = DateTime.fromISO(itemDate).plus({ day: 1 }).startOf('day');
            if (this.tomorrow < DateTime.now()) {
                // cannot create past items, start tomorrow
                this.tomorrow = DateTime.now().plus({ day: 1 }).startOf('day');
            }
        }
        this.reps = Math.floor(this.data.stock / this.data.batchSize);
        // normally 2 but at most reps; at least 0
        this.options = {
            freq: RRule.WEEKLY,
            interval: 1,
            byweekday: [DateTime.now().weekday - 1], // not localWeekday
            dtstart: this.tomorrow.toJSDate(),
            count: Math.max(0, Math.min(2, this.reps || 0)),
            until: null,
            byhour: 12,
            byminute: 42,
            bysecond: 42,
            wkst: RRule.MO,
            bysetpos: null,
            bymonth: null,
            bymonthday: null,
            bynmonthday: null,
            byyearday: null,
            byweekno: null,
            tzid: null,
            bynweekday: null,
            byeaster: null,
        };
        this.updateDates();

        if (this.data.items?.length > 1) {
            this.totalRoastsCount = this.data.items.reduce((prev, item) => (item.count ?? 0) + prev, 0);
        }
    }

    updateEndDate(date: DateTime) {
        this.end = 'date';
        if (!date) {
            date = DateTime.now().plus({ weeks: 2 }).endOf('day');
        }
        this.until = date.endOf('day');
        this.options.until = this.until.toJSDate();
        this.options.count = null;
        this.updateDates();
    }

    updateCount(count: number) {
        this.end = 'count';
        if (count == null) {
            count = 2;
        }
        this.until = null;
        this.options.until = null;
        this.options.count = count;
        this.updateDates();
    }

    updateFreq(): void {
        if (this.options.freq === RRule.WEEKLY) {
            this.options.byweekday = [DateTime.now().weekday];
        } else {
            this.options.byweekday = null;
        }
        this.updateDates();
    }

    endTypeChanged(): void {
        if (this.end === 'count') {
            if (this.dates?.length) {
                this.updateCount(this.dates.length);
            } else {
                this.updateCount(undefined);
            }
            // if (!this.options.count) {
            //     this.options.count = 2;
            // }
            // this.options.until = null;
        } else if (this.end === 'date') {
            if (this.dates?.length) {
                const lastDate = this.dates?.[this.dates.length - 1];
                this.until = DateTime.fromJSDate(lastDate);
                this.options.until = lastDate;
                this.updateEndDate(DateTime.fromJSDate(lastDate));
            } else {
                this.updateEndDate(undefined);
            }
            // if (!this.options.until) {
            //     this.options.until = this.until.toJSDate();
            // }
            // this.options.count = null;
        }
        // this.updateDates();
    }

    useCountSuggestion(reps: number): void {
        if (this.end === 'count') {
            this.updateCount(reps);
        } else { // end === 'date'
            // calculate dates
            this.options.until = null;
            this.options.count = reps;
            const rrule = new RRule(this.options);
            const dates = rrule.all();
            this.options.count = null;
            const lastDate = dates?.[dates.length - 1];
            if (lastDate) {
                this.until = DateTime.fromJSDate(lastDate);
                this.options.until = lastDate;
            } else {
                this.until = DateTime.now().plus({ weeks: 2 }).endOf('day');
                this.options.until = this.until.toJSDate();
            }
            this.updateDates();
        }
    }

    isRemovedDate(date: Date): boolean {
        for (const exdate of this.exdates) {
            if (exdate.valueOf() === date.valueOf()) {
                return true;
            }
        }
        return false;
    }

    removeDate(date: Date): void {
        this.exdates.push(date);
        this.updateDates();
    }

    addDate(date: Date): void {
        for (let d = 0; d < this.exdates.length; d++) {
            const exdate = this.exdates[d];
            if (exdate.valueOf() === date.valueOf()) {
                this.exdates.splice(d, 1);
                this.updateDates();
                return;
            }
        }
    }

    updateDates() {
        const rrule = new RRule(this.options);
        // if (this.exdates?.length) {
        //     const rruleset = new RRuleSet();
        //     rruleset.rrule(rrule);
        //     for (let d = 0; d < this.exdates.length; d++) {
        //         const exdate = this.exdates[d];
        //         rruleset.exdate(exdate);
        //     }
        //     this.dates = rruleset.all();
        // } else {
        this.dates = rrule.all();
        // }
    }

    addClones(): void {
        this.closeDialog(true);
    }

    deleteClones(): void {
        this.closeDialog(false);
    }

    closeDialog(add: boolean): void {
        let dates: Date[];
        const rrule = new RRule(this.options);
        if (this.exdates?.length) {
            const rruleset = new RRuleSet();
            rruleset.rrule(rrule);
            for (const exdate of this.exdates) {
                rruleset.exdate(exdate);
            }
            dates = rruleset.all();
        } else {
            dates = rrule.all();
        }
        if (add) {
            this.dialogRef.close({ add: dates.map(d => DateTime.fromJSDate(d)) });
        } else {
            this.dialogRef.close({ delete: dates.map(d => DateTime.fromJSDate(d)) });
        }
    }
}
