@if (report) {
<span class="report">
    <mat-expansion-panel hideToggle="true" [disabled]="editMode"
        [ngClass]="{'missing-transactions': anyDeleted || report.missingTransactions?.length}">
        <mat-expansion-panel-header
            expandedHeight="auto" collapsedHeight="auto"
            [ngClass]="{'missing-transactions': anyDeleted || report.missingTransactions?.length}">
            <mat-panel-title class="paneltitle">
                <span class="small-font">{{report.startDate.toLocaleString(DateTime.DATE_MED)}} - {{report.endDate.toLocaleString(DateTime.DATE_MED)}}
                    <app-organicicon [certInfo]="report.certInfo" [size]="9" [rightMargin]="8" [leftMargin]="4" [spacer]="true"></app-organicicon>
                    @if (nrTransactions?.get('Purchase'); as nr) {
                    <span i18n="transaction$$transaction title, plural@@Purchases">Purchases</span>:&nbsp;{{nr}}
                    }
                    @if (nrTransactions?.get('Sale'); as nr) {
                    <span class="littlespaceleft" i18n="transaction$$transaction title, plural@@Sales">Sales</span>:&nbsp;{{nr}}
                    }
                    @if (nrTransactions?.get('Transfer'); as nr) {
                    <span class="littlespaceleft" i18n="transaction$$transaction title, plural@@Transfers">Transfers</span>:&nbsp;{{nr}}
                    }
                    @if (nrTransactions?.get('Correction'); as nr) {
                    <span class="littlespaceleft" i18n="transaction$$transaction title, plural@@Corrections">Corrections</span>:&nbsp;{{nr}}
                    }
                </span>
                <div class="full-width ellipsed">
                    <span class="head-label">{{report.label}}</span>
                </div>
            </mat-panel-title>
            <mat-panel-description>
                @if (utils.getRelativeDate(report.created); as haveDate) {
                <div class="head-date-wrapper">
                    <span class="head-date-line head-date-line-time">
                        @if (report.updated_by && currentUser && report.updated_by._id !== currentUser.user_id) {
                        {{uNickname}} &#64;
                        }{{report.created.toLocaleString(DateTime.TIME_SIMPLE)}}
                    </span>
                    <span class="head-date-line">{{haveDate}}</span>
                </div>
                } @else {
                @if (report.created) {
                <span class="head-date-line">
                    @if (report.updated_by && currentUser && report.updated_by._id !== currentUser.user_id) {
                    {{uNickname}} &#64;
                    }{{report.created.toLocaleString(DateTime.TIME_SIMPLE)}}
                </span>
                }
                }
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <!-- <app-beansreport-overview [currentUser]="currentUser" [report]="report"></app-beansreport-overview> -->
            @if (!editMode && report.missingTransactions?.length) {
            <div class="missing-items-div">
                <a (click)="scrollToMissing()" (keydown.enter)="scrollToMissing(); $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_missingRoasts" class="missing-items-link cursor-pointer">
                    <span i18n="reports$$link to a list of items (such as roasts or purchases) that are not included in the curent report@@Missing items">Missing items</span>
                    <mat-icon style="vertical-align: bottom;">arrow_drop_down</mat-icon>
                </a>
            </div>
            }
            @if (!editMode) {
            <div>
                @if (this.dataSource?.data) {
                <div class="report-link-space-flex">
                    <span data-test="spn_reportPDF" class="report-pdf-space-flex">
                        <a (click)="generatePDF()" (keydown.enter)="generatePDF(); $event.preventDefault()" role="button" [tabindex]="0" [ngClass]="{'isDisabled': creatingPdf}" class="report-link">PDF</a>
                    </span>
                    <mat-form-field data-test="slct_export" style="width: 100px; margin-right: 10px; margin-top: 5px;">
                        <mat-select [disabled]="creatingSheet || creatingCsv"
                            placeholder="Export" matInput name="export" panelWidth="null"
                            i18n-placeholder="generic$$button, export@@Export"
                            [(value)]="exportFormat"
                            (selectionChange)="export($event.value)">
                            <!-- <mat-option></mat-option> -->
                            <mat-option value="csv">CSV</mat-option>
                            <mat-option value="clipboardCSV" i18n="generic$$Clipboard@@Clipboard">Clipboard</mat-option>
                            <mat-option value="sheet">Excel</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                }
                <app-beansreport-table
                    [currentUser]="currentUser"
                    [dataSource]="dataSource"
                    [columnsToDisplay]="columnsToDisplay"
                    [additionalIsEditable]="true"
                    [reportName]="report.number"
                    [editMode]="false"
                    [readOnly]="readOnly"
                    (totalsChanged)="totalsChanged($event)">
                </app-beansreport-table>
                <span name="hiddenElems" id="hiddenElems" #hiddenElems></span>
                <br />
                @if (report.missingTransactions?.length) {
                <div class="other-table-heading" i18n="reports$$introduces list of items not included in this report for some reason@@not included explanation">Items with dates that belong to this report but are not included:</div>
                }
                <ul>
                    @for (transaction of report.missingTransactions; track transaction?._id) {
                    <li>
                        {{tr.anslate(transaction.__t)}}: {{transaction.coffee?.label}} &#64; {{transaction.location?.label}} @if (transaction.reference) {
                        <span class="littlespaceright">({{transaction.reference}})</span>
                        } <span i18n="transaction$$e.g. on 09.12.1978@@on {{date}}">on {{transaction.date.toLocaleString(DateTime.DATETIME_SHORT)}}</span>:
                        @if (reportService.isInvalidTransaction(transaction); as reason) {
                        <span class="error-reason"> {{reason}}</span>
                        } @else {
                        <span class="littlespaceleft error-reason" i18n="reports$$hint why an item is not put into a report@@added or modified after the report has been created">added or modified after the report has been created</span>
                        }
                        @if (!readOnly && !transaction.reconciled) {
                        <a (click)="editTransaction($index)" (keydown.enter)="editTransaction($index); $event.preventDefault()" role="button" [tabindex]="0">
                            <mat-icon class="smallicon" style="margin-left: 5px;">edit</mat-icon>
                        </a>
                        }
                        @if (!readOnly && !transaction.reconciled) {
                        <a (click)="deleteTransaction($index)" (keydown.enter)="deleteTransaction($index); $event.preventDefault()" role="button" [tabindex]="0">
                            <mat-icon class="smallicon smallicon-second">delete</mat-icon>
                        </a>
                        }
                    </li>
                    }
                </ul>
            </div>
            }
            @else if (!readOnly) {
            <app-editbeansreport
                [currentUser]="currentUser"
                [report]="report"
                [readOnly]="readOnly"
                (reportChanged)="onReportChanged($event)"
                (editModeChanged)="onEditModeChanged($event)">
            </app-editbeansreport>
            }
        </ng-template>
        @if (!readOnly && !editMode) {
        <mat-action-row>
            <button mat-raised-button type="button" (click)="editReport()" class="action-button">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-raised-button type="button" (click)="deleteReport()" class="edit-button">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-action-row>
        } @else if (!readOnly) {
        <mat-action-row>
            <button mat-button type="button" class="cancel-button" (click)="cancelEdit()">
                <mat-icon>close</mat-icon>
            </button>
            <button [disabled]="!report.startDate || !report.endDate || !report.label" mat-raised-button type="submit"
                class="action-button" (click)="saveEditedReport()">
                <mat-icon>done</mat-icon>
            </button>
        </mat-action-row>
        }
    </mat-expansion-panel>
</span>
}