@if (coupons?.[0]?.active) {
<div class="coupon-box">
    <!-- <div i18n="coupons$$Coupon@@Coupon">Coupon</div> -->
    @if (coupons[0].conditions) {
    <div class="coupon-condition">{{coupons[0].conditions}}</div>
    }
    <div class="coupon-desc" [ngClass]="{'coupon-noconditions': !coupons[0].conditions}">
        {{coupons[0].description}}
        @if (coupons[0].link) {
        <a data-test="lnk_link" [href]="coupons[0].link" class="ng-star-inserted coupon-link"
            target="_blank" rel="noopener" (click)="$event.stopPropagation()">
            <!-- <span i18n="coupon$$Details@@Details">Details</span> -->
            <mat-icon class="coupon-linkicon">launch</mat-icon>
        </a>
        }
    </div>
    @if (coupons[0].code) {
    <div class="coupon-code">
        {{coupons[0].code}}
        @if (isSupported) {
        @if (!justCopied && !copyFailed) {
        <a (click)="copy($event)" (keydown.enter)="copy($event); $event.preventDefault()" role="button" [tabindex]="0" style="vertical-align: middle;">
            <mat-icon class="copy-icon">content_copy</mat-icon>
        </a>
        }
        @if (justCopied) {
        <mat-icon class="copied copy-icon done-icon">done</mat-icon>
        }
        @if (copyFailed) {
        <mat-icon class="copyFailed copy-icon">error_outline</mat-icon>
        }
        }
    </div>
    } @else {
    <button (click)="requestCoupon(0, $event)" data-test="bttn_requestCoupon"
        mat-flat-button type="button" class="smallButton action-button">
        <span i18n="coupons$$Request coupon code@@Request code">Request code</span>
    </button>
    <!-- <button (click)="requestCoupon(0)" data-test="bttn_requestCoupon"
      mat-raised-button type="button" class="action-button">
      <span i18n="coupons$$Coupon@@Coupon">Coupon</span>
    </button> -->
    }
    <!-- <div>
  <span *ngIf="coupons[0].end"><span i18n="coupons$$until@@until">until</span> {{coupons[0].end.toLocaleString(DateTime.DATE_SHORT)}}</span>
</div> -->
</div>
}

@if (coupons?.[0]?.active === false) {
<div class="coupon-box">
    <div class="coupon-code" i18n="coupons$$GET a COUPON@@GET a COUPON">GET a COUPON</div>
    <div>
        <a data-test="lnk_link" [href]="generalLink" class="ng-star-inserted coupon-link"
            target="_blank" rel="noopener" (click)="$event.stopPropagation()">
            Details <mat-icon class="verysmall">launch</mat-icon>
        </a>
    </div>
</div>
}

<div #copyElem></div>