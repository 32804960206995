<h1 mat-dialog-title>flocert.net:</h1>
<mat-dialog-content>
    <mat-radio-group [(ngModel)]="selected" name="select-group" class="primary-element">
        @for (item of data; track item?.name) {
        <mat-radio-button [value]="$index" style="display: block;">
            #{{item.floid}} {{item.name}} {{item.address}}
        </mat-radio-button>
        }
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-button" autofocus cdkFocusInitial mat-button type="submit" mat-dialog-close tabindex="0">
        <mat-icon>close</mat-icon>
    </button>
    @if (data) {
    <button [disabled]="selected < 0" class="action-button" mat-raised-button type="button" tabindex="1" (click)="applyInfo()">
        <mat-icon>archive</mat-icon>
        <!-- <mat-icon>file_download</mat-icon> -->
        <!-- <mat-icon>approval</mat-icon> -->
    </button>
    }
</mat-dialog-actions>