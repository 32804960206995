import { version } from './version';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    SUB_API_URL: '/api/v1',
    RELOADTHROTTLE: 1000,
    BASE_API_URL: 'https://artisan.plus',
    latestWebVersion: version.latestWebVersion,
    buildNr: version.buildNr,
    MAX_UPLOAD_SIZE_MB: 2,
};
