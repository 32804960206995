import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Property } from 'src/app/models/Property';
import { Variety } from 'src/app/models/Variety';
import { Certification } from 'src/app/models/Certification';
import { Floid } from 'src/app/models/Floid';
import { Constants } from 'src/app/util/constants';

@Injectable({
    providedIn: 'root'
})
export class PropertiesService {

    constructor(
        private http: HttpClient
    ) {
        this.baseUrl = environment.BASE_API_URL + environment.SUB_API_URL;
    }

    baseUrl: string;

    getProperties(categories: string[], propertyName: string): Observable<{ success: boolean, result: Property[], error: string }> {
        if (typeof propertyName === 'undefined' || propertyName === null) {
            propertyName = 'null';
        }
        const url = this.baseUrl + '/properties/' + encodeURI(categories.join('&')) + '/' + encodeURI(propertyName);
        return this.http.get<{ success: boolean, result: Property[], error: string }>(url);
    }

    addProperty(property: Property): Observable<{ success: boolean, result: Property, error: string }> {
        const url = this.baseUrl + '/properties';
        return this.http.post<{ success: boolean, result: Property, error: string }>(url, property);
    }

    removeProperty(property: Property): Observable<{ success: boolean, result: Property, error: string }> {
        const url = this.baseUrl + '/properties';
        let params = new HttpParams();
        params = params.set('categories', property.categories.join(Constants.PROPERTIES_SEPARATOR));
        params = params.set('property', property.property);
        params = params.set('label', property.label);
        params = params.set('value', property.value);
        return this.http.delete<{ success: boolean, result: Property, error: string }>(url, { params });
    }

    getVerietals(): Observable<{ success: boolean, result: Variety[], error: string }> {
        const url = this.baseUrl + '/varietals';
        return this.http.get<{ success: boolean, result: Variety[], error: string }>(url);
    }

    getCertifications(): Observable<{ success: boolean, result: Certification[], error: string }> {
        const url = this.baseUrl + '/certifications';
        return this.http.get<{ success: boolean, result: Certification[], error: string }>(url);
    }

    getFloIdInfo(floId: string, label: string, returnAllMatches = false): Observable<{ success: boolean,
        result: Floid[], error: string }> {
        const url = this.baseUrl + '/request/floid';
        let params = new HttpParams();
        if (label) {
            params = params.set('label', label);
        }
        if (floId) {
            params = params.set('floid', floId);
        }
        if (returnAllMatches) {
            params = params.set('all', '1');
        }
        return this.http.get<{ success: boolean, result: Floid[], error: string }>(url, { params });
    }

    // addRegion(region: Region): Observable<{ success: boolean, result: Region, error: string }> {
    //     const url = this.baseUrl + '/regions';
    //     return this.http.post<{ success: boolean, result: Region, error: string }>(url, region);
    // }
}
