@if (report) {
<span class="report">
    <mat-expansion-panel hideToggle="true" [disabled]="editmode === index" (opened)="openUp()"
        [ngClass]="{'reconciled': report.reconciled, 'missing-roasts': missingRoasts?.length}">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto"
            [ngClass]="{'reconciled': report.reconciled, 'missing-roasts': missingRoasts?.length}">
            <mat-panel-title data-test="pnlttl" class="paneltitle" #appRoastReport>
                <span class="small-font">
                    <span class="paneltitle-daterange">{{report.startDate.toLocaleString(DateTime.DATE_MED)}} - {{report.endDate.toLocaleString(DateTime.DATE_MED)}}</span>
                    <app-organicicon [certInfo]="report.certInfo ?? report.certInfoOnCreation" [size]="9" [rightMargin]="8" [leftMargin]="4" [spacer]="true"></app-organicicon>
                    <span class="roastreport-panel-roastcount"><span i18n="generic$$roasts, plural@@roasts">roasts</span>: {{report.roastsCount || (report.roasts || []).length}}</span>
                    <!-- <span *ngIf="utils.getWeight(sumIn, currentUser?.unit_system) as weight">({{weight.pre}}{{weight.value | number:'1.0-1'}}{{weight.post}})</span> -->
                </span>
                <div class="full-width ellipsed">
                    <span data-test="spn_label" class="head-label">{{report.label}}</span>
                    @if (report.discardedCount) {
                    <span class="small-font head-label-discarded"><span i18n="generic$$discarded, plural@@plural_discarded">discardeds</span>: {{report.discardedCount}}</span>
                    }
                </div>
            </mat-panel-title>
            <mat-panel-description>
                @if (utils.getRelativeDate(report.created); as haveDate) {
                <div data-test="dv_date" class="head-date-wrapper">
                    <div class="head-date-line head-date-line-time">
                        @if (report.updated_by && currentUser && (report.updated_by._id ?? report.updated_by) !== currentUser.user_id) {
                        {{uNickname}} &#64;
                        }{{report.created.toLocaleString(DateTime.TIME_SIMPLE)}}
                    </div>
                    <div class="head-date-line">{{haveDate}}</div>
                </div>
                } @else {
                @if (report.created) {
                <span data-test="spn_time" class="head-date-line">
                    @if (report.updated_by && currentUser && (report.updated_by._id ?? report.updated_by) !== currentUser.user_id) {
                    {{uNickname}} &#64;
                    }{{report.created.toLocaleString(DateTime.TIME_SIMPLE)}}
                </span>
                }
                }
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            @if (editmode !== index && report.discardedCount) {
            <span class="roastreport-discarded-info">
                @if (report.discardedCount === 1) {
                <span class="discarded" i18n="generic$$discarded@@discarded">discarded</span>:
                } @else {
                <span class="discarded" i18n="generic$$discarded, plural@@plural_discarded">discardeds</span>:
                }
                @if (utils.convertToUserUnit(report.discardedAmount, currentUser?.unit_system); as discarded) {
                <span>{{discarded | number:'1.0-3'}}{{mainUnit}}</span>
                } @else {
                0{{mainUnit}}
                }
                <mat-icon class="primary-element roastreport-discarded-icon" (click)="openDiscardedInfo(report)">visibility</mat-icon>
            </span>
            }
            @if (editmode !== index && missingRoasts?.length) {
            <div class="missing-items-link">
                <a (click)="scrollToMissing()" (keydown.enter)="scrollToMissing(); $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_missingRoasts" class="missing-items-link cursor-pointer">
                    <span i18n="reports$$link to a list of items (such as roasts or purchases) that are not included in the curent report@@Missing items">Missing items</span>
                    <mat-icon style="vertical-align: bottom;">arrow_drop_down</mat-icon>
                </a>
            </div>
            }
            @if (report.roastsCount && !report.roasts?.length) {
            <mat-spinner diameter="24"></mat-spinner>
            }
            @if (editmode !== index) {
            <div style="position: relative;">
                @if ((reportOverview?.blends?.length ?? 0) + (reportOverview?.coffees?.length ?? 0) > 2) {
                <span class="listofroasts-link">
                    <a (click)="scrollToListOfRoasts()" (keydown.enter)="scrollToListOfRoasts(); $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_listofroasts" class="listofroasts-items-link cursor-pointer">
                        <span i18n="roasts$$link or heading to a list of roasts@@List of roasts">List of roasts</span>
                        <mat-icon style="vertical-align: bottom;">arrow_drop_down</mat-icon>
                    </a>
                </span>
                }
                @if (report.roastsCount && report.roasts?.length) {
                <app-roastreport-overview
                    [currentUser]="currentUser"
                    [readOnly]="readOnly"
                    [isOpenReport]="isOpenReport"
                    [report]="report"
                    [reportOverview]="reportOverview">
                </app-roastreport-overview>
                }
                <span name="reportTableElem" id="reportTableElem" #reportTableElem></span>
                <!-- [fullyLoaded]="!(report.roastsCount > report.roasts?.length)" -->
                <app-roastreport-table #reportTable data-test="table"
                    [currentUser]="currentUser"
                    [readOnly]="readOnly"
                    [isOpenReport]="isOpenReport"
                    [(report)]="report"
                    [columnsToDisplay]="columnsToDisplay"
                    [additionalIsEditable]="!report.reconciled"
                    [showTotals]="true">
                </app-roastreport-table>
                <span name="hiddenElems" id="hiddenElems" #hiddenElems></span>
                <br />
                @if (missingRoasts?.length) {
                <div datat-test="dv_missingRoastsHeader" class="other-table-heading"
                    i18n="reports$$introduces list of items not included in this report for some reason@@not included explanation">Items with dates that belong to this report but are not included:</div>
                <ul>
                    @for (roast of missingRoasts; track roast?.hr_id) {
                    <li data-test="lst_missingRoast">
                        @if (roast.hr_id) {
                        <a [routerLink]="['/roasts', {id: roast.hr_id}]">{{roast.hr_id}}</a>
                        }
                        {{roast.label}}, {{roast.date.toLocaleString(DateTime.DATETIME_SHORT)}}:
                        @if (reportService.isInvalidRoast(roast); as reason) {
                        <span class="error-reason"> {{reason}}</span>
                        } @else {
                        <span class="littlespaceleft error-reason" i18n="reports$$hint why an item is not put into a report@@added or modified after the report has been created">added or modified after the report has been created</span>
                        }
                    </li>
                    }
                </ul>
                }
            </div>
            }
            @if (!readOnly && editmode === index) {
            <app-editroastreport
                [currentUser]="currentUser"
                [isNewest]="isNewest"
                [isOnly]="isOnly"
                [isOpenReport]="isOpenReport"
                [stores]="stores"
                [report]="report"
                [reportOverview]="reportOverview"
                (editModeChanged)="onEditModeChanged($event)"
                (reportChanged)="onReportChanged($event)"
                (reportOverviewChanged)="onReportOverviewChanged($event)">
            </app-editroastreport>
            }
        </ng-template>

        @if (!readOnly && editmode !== index && !report.reconciled) {
        <mat-action-row>
            @if (!deleting && (isOpenReport || (!report.reconciled && isNewest))) {
            <button [disabled]="loading || editmode >= 0" mat-raised-button type="button"
                (click)="deleteReport()" data-test="bttn_delete" class="edit-button">
                <mat-icon>delete</mat-icon>
            </button>
            } @else if (isOpenReport || (!report.reconciled && isNewest)) {
            <button disabled mat-raised-button class="action-button spinner-button">
                <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
            </button>
            }
            @if (!report.reconciled) {
            <button [disabled]="editmode >= 0"
                (click)="editReport()" data-test="bttn_edit"
                mat-raised-button type="button" class="action-button">
                @if (!loading) {
                <mat-icon>edit</mat-icon>
                }
                @if (loading) {
                <mat-spinner diameter="24"></mat-spinner>
                }
            </button>
            }
            @if (!isOpenReport && !report.reconciled && canBeReconciled) {
            <button mat-raised-button type="button"
                [disabled]="editmode >= 0" class="edit-button"
                (click)="reconcileReport()" data-test="bttn_finalize">
                <span i18n="reports$$finalize (i.e. it cannot be changed afterwards any more), button@@finalize">Finalize</span>
            </button>
            }
        </mat-action-row>
        }
        @if (!readOnly && editmode === index) {
        <mat-action-row>
            <button (click)="cancelEdit()" data-test="bttn_cancel" mat-button type="button" class="cancel-button">
                <mat-icon>close</mat-icon>
            </button>
            <button [disabled]="!report.startDate || !report.endDate || !report.label" mat-raised-button type="submit"
                (click)="saveEditedReport()" data-test="bttn_save" class="action-button">
                <mat-icon>done</mat-icon>
            </button>
        </mat-action-row>
        }
    </mat-expansion-panel>
</span>
}