@if (_element) {
@if (_element.updated_at && _element.updated_at !== _element.created_at) {
<div class="updatedat" [ngClass]="{'margin-below': !_element.created_at}">
    <span i18n="generic$$Last change 09.12.1978@@last changed">Last change {{(_element.updated_at | date:'mediumDate') + ', ' + (_element.updated_at | date:'shortTime')}}</span>
    @if (_element.updated_by && user && _element.updated_by._id !== user.user_id) {
    <span i18n="generic$$updated by user nickname@@updated by">by {{uNickname}}</span>
    }
</div>
}
@if (_element.created_at) {
<div class="createdat margin-below">
    <span i18n="generic$$Added 09.12.1978@@Added {{date}}">Added {{(_element.created_at | date:'mediumDate') + ', ' + (_element.created_at | date:'shortTime')}}</span>
    @if (_element.created_by && user && _element.created_by._id !== user.user_id) {
    <span i18n="generic$$created by user nickname@@created by">by {{cNickname}}</span>
    }
</div>
}
}