import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { DateTime } from 'luxon';

@Injectable()
export class RemindersService {

    constructor(
        private http: HttpClient,
        private tr: TranslatorService,
    ) {
        this.allTasks = [
            { label: this.tr.anslate('Empty chaff container'), interval_unit: 'roasted_amount', interval: 20, interval_unit2: 'batches', interval2: 4 },
            { label: this.tr.anslate('Lubricate drum bearings'), interval_unit: 'roast_hours', interval: 500 },
            { label: this.tr.anslate('Clean fans'), interval_unit: 'months', interval: 3 },
            { label: this.tr.anslate('Clean exhaust ducting'), interval_unit: 'months', interval: 3 },
            { label: this.tr.anslate('Lubricate drum drive chain'), interval_unit: 'months', interval: 6 },
            { label: this.tr.anslate('Replace air filter'), interval_unit: 'year', interval: 1 },
            { label: this.tr.anslate('Clean cooling tray'), interval_unit: 'day_of_week', interval: 1 },
            { label: this.tr.anslate('Generate monthly roast report'), interval_unit: 'day_of_month', interval: 1 },
            { label: this.tr.anslate('Submit tax report'), interval_unit: 'day_of_month', interval: 10 },
            { label: this.tr.anslate('Pay tax bill'), interval_unit: 'day_of_month', interval: 20 },
            { label: this.tr.anslate('Replace gas bottle'), interval_unit: 'gas_consumed', interval: 5 },
            { label: this.tr.anslate('Prepare organic audit'), interval_unit: 'date', on_date: DateTime.now().plus({ months: 1 }) },
            // { label: this.tr.anslate('Empty chaff bucket'), interval_unit: 'days', interval: 1 },
            // { label: this.tr.anslate('Check cooling tray'), interval_unit: 'days', interval: 1 },
            // { label: this.tr.anslate('Blow out motors'), interval_unit: 'weeks', interval: 1 },
            // { label: this.tr.anslate('Grease bearings'), interval_unit: 'weeks', interval: 1 },
            // { label: this.tr.anslate('Clean thermocouple'), interval_unit: 'weeks', interval: 1 },
            // { label: this.tr.anslate('Empty destoner'), interval_unit: 'batches', interval: 10 },
            // { label: this.tr.anslate('Scrub ductwork'), interval_unit: 'months', interval: 1 },
            // { label: this.tr.anslate('Clean fans'), interval_unit: 'months', interval: 3 },
            // { label: this.tr.anslate('Clean hopper'), interval_unit: 'months', interval: 1 },
            // { label: this.tr.anslate('Clean Sight Glass'), interval_unit: 'month', interval: 1 },
            // { label: this.tr.anslate('Clean chaff bucket'), interval_unit: 'months', interval: 1 },
            // { label: this.tr.anslate('Clean cooling tray'), interval_unit: 'months', interval: 1 },
            // { label: this.tr.anslate('Inspect afterburner'), interval_unit: 'years', interval: 1 },
            // { label: this.tr.anslate('Submit tax report'), interval_unit: 'years', interval: 1 },
            // { label: this.tr.anslate('Pay tax bill'), interval_unit: 'years', interval: 1 },
        ];
    }

    allTasks: {
        label: string,
        interval_unit?: string, interval?: number,
        interval_unit2?: string, interval2?: number,
        on_date?: DateTime, on_date2?: DateTime,
    }[];
    allTaskLabels = new Set<string>();

    /**
     * Gets a list of machines that have been used in any roast (or Reminder or ReminderLog)
     * in the last year (i.e previous ~365 days)
     * @returns result with a list of tuples (machine name and number of roasts)
     */
    getAllMachines(): Observable<{ success: boolean, result: { label: string, cnt: number }[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/machines';
        return this.http.get<{ success: boolean, result: { label: string, cnt: number }[], error: string }>(url);
    }

    renameMachineNames(replacements: { val?: string, _id?: string, replaceWith: string }[]): Observable<void> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/machines/rename';
        return this.http.put<void>(url, { replacements });
    }

}
