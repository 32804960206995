<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>

<ng-template #labelEntry let-obj="obj" let-isBlend="isBlend">
    <div class="smallerfont">
        @if (obj?.hr_id) {
        <span>{{obj.hr_id}}</span>
        }
        @if (isBlend) {
        <app-organicicon [isOrganic]="utils.isOrganicBlend(obj)" [size]="7" [leftMargin]="3"></app-organicicon>
        }
    </div>
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>

<ng-template #batchInfo>
    <span (click)="edit()" (keydown.enter)="edit(); $event.preventDefault()" role="button" [tabindex]="0">@if (roast.batch_prefix) {
        <span>{{roast.batch_prefix}}</span>
        }{{Number.isFinite(roast.batch_number) ? round(roast.batch_number) : ''}}@if (Number.isFinite(roast.batch_pos)) {
        <span> ({{roast.batch_pos | number:'1.0-0'}})</span>
        }
    </span>
</ng-template>


@if (roast) {
<span>
    <mat-expansion-panel hideToggle="true" [expanded]="(isNew === index) || isExpanded"
        (closed)="panel(false)" (opened)="panel(true)" [disabled]="editMode === index"
        [ngClass]="{'reconciled': roast.reconciled, 'in-report': roast.report}">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto"
            [ngClass]="{'reconciled': roast.reconciled, 'in-report': roast.report}">
            <!-- <mat-expansion-panel-header [matTooltip]="roast.tooltip" expandedHeight="auto" collapsedHeight="auto" [ngClass]="{'reconciled': roast.reconciled, 'in-report': roast.report}"> -->
            <!-- <mat-expansion-panel-header> -->
            <mat-panel-title class="paneltitle" #appRoast>
                <span style="display: inline-block" [ngStyle]="{'width': mainUnit === 'lbs' || mainUnit === 'lb' || mainUnit === 'oz' ? '70px' : '65px' }"></span>
                @if (roast.batch_prefix) {
                <span class="small-font"
                    [ngClass]="{'spaceright': !roast.batch_number}">{{roast.batch_prefix}}</span>
                }@if (Number.isFinite(roast.batch_number)) {
                <span class="small-font spaceright">{{round(roast.batch_number)}}</span>
                }
                @if (roast.machine) {
                <span class="small-font spaceright">{{roast.machine}}</span>
                }
                @if (roast.discarded) {
                <span class="small-font discarded spaceright"
                    i18n="roast$$discarded@@discarded">discarded</span>
                }
                <app-organicicon [certInfo]="roast.certInfo" [size]="10" [rightMargin]="7"></app-organicicon>
                <!-- make sure that the weight and label are moved to the second line -->
                @if (!roast.batch_prefix && !Number.isFinite(roast.batch_number) && !roast.machine) {
                <span class="small-font">&nbsp;</span>
                }
                <div class="full-width ellipsed flex-center" style="width: inherit; margin-top: -5px;">
                    @if (utils.getWeight(roast.end_weight, currentUser?.unit_system); as weight) {
                    <span
                        class="fixedWidth" [ngClass]="{'fixedWidth-lbs': mainUnit === 'lbs' || mainUnit === 'lb' || mainUnit === 'oz'}" style="text-align: right; margin-right: 15px">
                        {{weight.pre}}{{weight.value | number:'1.0-1'}}{{weight.post}}
                    </span>
                    } @else {
                    <span style="min-width: 50px; margin-right: 15px;"></span>
                    }
                    @if (roast.label) {
                    <span class="head-label ellipsed" style="margin-top: 0;">{{roast.label}}</span>
                    }
                </div>
            </mat-panel-title>
            @if (!isExpanded) {
            <mat-panel-description>
                @if (isLarge) {
                <div class="full-width">
                    @if (roast.coffee) {
                    <div class="flex-baseline">
                        @if (roast.coffee.hr_id) {
                        <span class="head-hrid small-font fixedWidth" [ngClass]="{'fixedWidth-lbs': mainUnit === 'lbs' || mainUnit === 'lb' || mainUnit === 'oz'}">
                            {{roast.coffee.hr_id}}
                            <app-organicicon [isOrganic]="utils.isOrganicCoffee(roast.coffee)" [size]="9" [leftMargin]="3" [spacer]="true"></app-organicicon>
                        </span>
                        }
                        @if (roast.coffee.origin) {
                        <span class="littlespaceright">{{tr.anslate(roast.coffee.origin)}}</span>
                        }
                        @if (roast.coffee.yearLabel) {
                        <span class="ellipsed"> {{roast.coffee.yearLabel}}</span>
                        }
                    </div>
                    }
                    @else if (roast.blend) {
                    <div class="head-hrid small-font">
                        @if (roast.blend.ingredients?.length && (isMiddleOrLarge$ | async)) {
                        <div class="ellipsed">
                            @for (ing of getSortedIngredients(); track $index) {
                            <span>
                                @if (ing.ratio) {
                                <span>{{ing.ratio * 100 | number:'1.0-0'}}%</span>
                                }
                                @if (ing.coffee?.hr_id) {
                                <span> {{ing.coffee.hr_id}}</span>
                                }
                                <app-organicicon [isOrganic]="utils.isOrganicCoffee(ing.coffee)" [size]="9" [leftMargin]="3"></app-organicicon>
                                @if (ing.coffee?.origin) {
                                <span> {{tr.anslate(ing.coffee.origin)}}</span>
                                }
                                @if (ing.coffee?.yearLabel) {
                                <span> {{ing.coffee.yearLabel}}</span>
                                }
                                @if (roast.blend.ingredients.length - 1 > $index) {
                                <span>, </span>
                                }
                            </span>
                            }
                        </div>
                        } @else {
                        <div i18n="generic$$Blend@@Blend">Blend</div>
                        }
                    </div>
                    }
                    <div class="full-width ellipsed flex-center" style="margin-top: -5px;">
                        @if (utils.getWeight(roast.amount, currentUser?.unit_system); as weight) {
                        <span class="spaceright-more fixedWidth" [ngClass]="{'fixedWidth-lbs': mainUnit === 'lbs' || mainUnit === 'lb' || mainUnit === 'oz'}">{{weight.pre}}{{weight.value | number:'1.0-1'}}{{weight.post}}</span>
                        }
                        @if (roast.coffee?.label) {
                        <span class="ellipsed">{{roast.coffee.label}}</span>
                        }
                        @if (roast.blend?.hr_id) {
                        <span class="littlespaceright">{{roast.blend.hr_id}}</span>
                        }
                        @if (roast.blend?.label) {
                        <span class="ellipsed">{{roast.blend.label}}</span>
                        }
                    </div>
                </div>
                }
                @if (roast.date) {
                <div class="head-date-wrapper">
                    @if (utils.getRelativeDate(roast.date); as haveDate) {
                    <span class="head-date-line head-date-line-time">{{roast.date.toLocaleString(DateTime.TIME_SIMPLE)}}</span>
                    <span class="head-date-line" style="margin-top: -5px;">{{haveDate}}</span>
                    } @else {
                    <span class="head-date-line head-date-line-time">{{roast.date.toLocaleString(DateTime.TIME_SIMPLE)}}</span>
                    }
                </div>
                }
            </mat-panel-description>
            }
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            @if (!readOnly && editMode === index && roastcopy) {
            <div>
                <form id="form" name="form" (ngSubmit)="(editForm.form.valid || !roastcopy.location) && save()" #editForm="ngForm">
                    <div class="outerdiv">
                        <div class="nottable width-for-edit">
                            <mat-form-field class="form-group">
                                <mat-label i18n="generic$$Title@@Title">Title</mat-label>
                                <input matInput required type="text" name="label"
                                    placeholder="Title" i18n-placeholder="generic$$Title@@Title"
                                    [(ngModel)]="roastcopy.label">
                            </mat-form-field>
                            <br />
                            @if (stores?.length) {
                            <mat-form-field class="form-group">
                                <mat-label i18n="generic$$storage location@@Store">Store</mat-label>
                                <mat-select [required]="!!(roastcopy.blend || roastcopy.coffee)"
                                    [disabled]="roastcopy.reconciled" hideSingleSelectionIndicator="true"
                                    name="store" panelClass="twoLineOption" panelWidth="null"
                                    placeholder="Store" i18n-placeholder="generic$$storage location@@Store"
                                    [(ngModel)]="roastcopy.location"
                                    [compareWith]="utils.compareObjectsFn">
                                    @if (roastcopy.location) {
                                    <mat-select-trigger class="twoLineOption ellipsed">
                                        <ng-container *ngTemplateOutlet="labelEntry; context: {obj: roastcopy.location}"></ng-container>
                                    </mat-select-trigger>
                                    }
                                    <mat-option [value]="null"></mat-option>
                                    @for (store of stores; track store?._id) {
                                    <mat-option [value]="store">
                                        <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                                    </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            }<br />
                            @if (!roastcopy.blend && (!coffees || !coffees.length)) {
                            <div>
                                @if (!coffees) {
                                <mat-spinner diameter="20"></mat-spinner>
                                } @else {
                                <p i18n="transaction$$no beans to transfer / sell@@no beans">Please add beans first!</p>
                                }
                            </div>
                            }
                            <mat-form-field class="form-group">
                                <mat-label i18n="generic$$Beans@@Beans">Beans</mat-label>
                                <mat-select [disabled]="roastcopy.reconciled" hideSingleSelectionIndicator="true"
                                    placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                                    name="coffee" panelClass="twoLineOption" panelWidth="null"
                                    [(ngModel)]="roastcopy.coffee"
                                    (selectionChange)="coffeeSelected()"
                                    [compareWith]="utils.compareObjectsFn">
                                    @if (roastcopy.coffee) {
                                    <mat-select-trigger class="twoLineOption ellipsed">
                                        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: roastcopy.coffee}"></ng-container>
                                    </mat-select-trigger>
                                    }
                                    @if (coffees?.length >= 5) {
                                    <mat-option>
                                        <ngx-mat-select-search ngModel (ngModelChange)="filteredCoffees = utils.filterObjects($event, coffees)" name="coffeeSelect"
                                            placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                            noEntriesFoundLabel="">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    }
                                    <mat-option [value]="null"></mat-option>
                                    <!-- <mat-option *ngIf="isNew !== -1 && isNew === index" [value]="null"></mat-option> -->
                                    @for (coffee of filteredCoffees; track coffee?._id) {
                                    <mat-option [value]="coffee">
                                        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coffee}"></ng-container>
                                    </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field><br />
                            @if (roastcopy?.coffee && roastcopy.location && (isNew === index) && getAmountStr(roastcopy.coffee); as available) {
                            <div class="comment">
                                <span [ngClass]="{'warn-amount': round(roastcopy.amount * utils.getUnitFactor(mainUnit) * 1000) > round(available.value * 1000)}" i18n="transaction$$label before showing how much of those beans are available in this store@@Amount of beans available">Available:</span>
                                <span class="spaceleft" [ngClass]="{'warn-amount': round(roastcopy.amount * utils.getUnitFactor(mainUnit) * 1000) > round(available.value * 1000)}">{{available.pre}}{{available.value | number:'1.0-3'}}{{available.post}}</span>
                            </div>
                            }
                            @if (roastcopy?.coffee && roastcopy.location && (isNew === index) && getAmountStr(roastcopy.coffee)) {
                            <br />
                            }
                            @if (!blends) {
                            <mat-spinner diameter="20"></mat-spinner>
                            }
                            @if (blends?.length) {
                            <mat-form-field class="form-group">
                                <mat-label i18n="generic$$Blend@@Blend">Blend</mat-label>
                                <mat-select [disabled]="roastcopy.reconciled" matInput name="blend"
                                    panelClass="twoLineOption" hideSingleSelectionIndicator="true" panelWidth="null"
                                    placeholder="Blend" i18n-placeholder="generic$$Blend@@Blend"
                                    [compareWith]="utils.compareObjectsFn"
                                    [(ngModel)]="roastcopy.blend"
                                    (selectionChange)="blendSelected()">
                                    @if (roastcopy.blend) {
                                    <mat-select-trigger class="twoLineOption ellipsed">
                                        <ng-container *ngTemplateOutlet="labelEntry; context: {obj: roastcopy.blend, isBlend: true}"></ng-container>
                                    </mat-select-trigger>
                                    }
                                    @if (filteredBlends?.length >= 5) {
                                    <mat-option>
                                        <ngx-mat-select-search ngModel (ngModelChange)="filteredBlends = utils.filterObjects($event, blends)"
                                            placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                            noEntriesFoundLabel="" name="blendSelect">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    }
                                    <mat-option [value]="null"></mat-option>
                                    @for (blend of filteredBlends; track blend?.label) {
                                    <mat-option [value]="blend">
                                        <ng-container *ngTemplateOutlet="labelEntry; context: {obj: blend, isBlend: true}"></ng-container>
                                    </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            }
                            @if (currentBlend) {
                            <div class="blends-div">
                                <app-roast-blend-edit
                                    [currentUser]="currentUser"
                                    [blend]="currentBlendCopy"
                                    [coffees]="coffees"
                                    [readOnly]="readOnly"
                                    [reconciled]="roastcopy.reconciled"
                                    (blendChanged)="blendChanged($event)"
                                    [showAmounts]="index === isNew"
                                    [amount]="roastcopy.amount * utils.getUnitFactor(mainUnit)"
                                    [store]="roastcopy.location">
                                </app-roast-blend-edit>
                            </div>
                            }
                            @if (!roastcopy.blend && currentBlend) {
                            <div class="blend-name-div">
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="roasts$$new blend name, placeholder@@new blend name">New blend name</mat-label>
                                    <input matInput required type="text" name="newblendname"
                                        placeholder="New blend name" i18n-placeholder="roasts$$new blend name, placeholder@@new blend name"
                                        [(ngModel)]="newBlendName">
                                </mat-form-field>
                                @if (!roastcopy.blend && couldBeBlendTemplate?.label) {
                                <button matButton type="button" class="smallButton halfsizeright" style="max-width: 170px;" (click)="useTemplate()"><span i18n="roasts$$use given blend template@@use blend template">Use {{couldBeBlendTemplate.label}}</span></button>
                                }
                            </div>
                            }
                            <!-- <div *ngIf="roastcopy.blend || !currentBlend" class="vert-spacer"></div> -->
                            <div class="vert-spacer"></div>
                            @if (!stores || stores.length === 0) {
                            <div>
                                @if (!stores) {
                                <mat-spinner diameter="20"></mat-spinner>
                                } @else {
                                <p i18n="transaction$$no stores defined yet@@no stores">Please add a store first!</p>
                                }
                            </div>
                            }
                            <div style="width: 100%; display: flex; justify-content: space-between;">
                                @if (roastcopy) {
                                <app-bleinput class="form-group halfsizeleft-withicon"
                                    [required]="true" [disabled]="roastcopy.reconciled"
                                    [placeholder]="tr.anslate('Weight in {{mainUnit}}', { mainUnit: mainUnit })"
                                    (valueChanged)="checkChangesUnits(roastcopy, 'amount', roastcopy.amount, $event.toString()); validateAmounts($event, roastcopy.end_weight)"
                                    (checkedValueChanged)="roastcopy.amount=$event; validateAmounts($event, roastcopy.end_weight)"
                                    [origValue]="origAmount"
                                    [value]="roastcopy.amount != null ? (roastcopy.amount * utils.getUnitFactor(mainUnit) | number:'1.0-3') : '0'"
                                    (inputChanged)="validateAmounts($event, roastcopy.end_weight)">
                                </app-bleinput>

                                <app-bleinput class="form-group halfsizeright-withicon" [ngClass]="{'warn-amount': notsmaller}"
                                    [required]="true" [disabled]="roastcopy.reconciled"
                                    [placeholder]="tr.anslate('Yield in {{mainUnit}}', { mainUnit: mainUnit })"
                                    (valueChanged)="checkChangesUnits(roastcopy, 'end_weight', roastcopy.end_weight, $event.toString()); validateAmounts(roastcopy.amount, $event)"
                                    (checkedValueChanged)="roastcopy.end_weight=$event; validateAmounts(roastcopy.amount, $event)"
                                    [origValue]="origEndWeight"
                                    [value]="roastcopy.end_weight != null ? (roastcopy.end_weight * utils.getUnitFactor(mainUnit) | number:'1.0-3') : '0'"
                                    (inputChanged)="validateAmounts(roastcopy.amount, $event)">
                                </app-bleinput>
                                }
                            </div>
                            <!-- <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                <mat-label i18n="transaction$$weight of beans in, e.g. kg@@Weight in {{mainUnit}}">Weight in {{mainUnit}}</mat-label>
                                <input matInput required [disabled]="roastcopy.reconciled" i18n-placeholder="transaction$$weight of beans in, e.g. kg@@Weight in {{mainUnit}}" type="text"
                                placeholder="Weight in {{mainUnit}}" name="amount"
                                (change)="checkChangesUnits(roastcopy, 'amount', roastcopy.amount, $event.target['value'])"
                                [value]="roastcopy.amount != null ? (roastcopy.amount * utils.getUnitFactor(mainUnit) | number:'1.0-3') : 0"
                                (input)="validateAmounts($event.target['value'], roastcopy?.end_weight)">
                            </mat-form-field> -->
                            <!-- <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                            <mat-label i18n="roasts$$end weight in e.g. kg@@Yield in {{mainUnit}}">Yield in {{mainUnit}}</mat-label>
                            <input matInput [disabled]="roastcopy.reconciled" i18n-placeholder="roasts$$end weight in e.g. kg@@Yield in {{mainUnit}}" type="text"
                                [ngClass]="{'warn-amount': notsmaller}"
                                placeholder="Yield in {{mainUnit}}" name="end_weight"
                                (change)="checkChangesUnits(roastcopy, 'end_weight', roastcopy.end_weight, $event.target['value'])"
                                [value]="roastcopy.end_weight != null ? (roastcopy.end_weight * utils.getUnitFactor(mainUnit) | number:'1.0-3') : null"
                                (input)="validateAmounts(roastcopy?.amount, $event.target['value'])">
                            </mat-form-field> -->
                            @if (notsmaller) {
                            <div class="comment comment-ble warn-amount" i18n="roasts$$warning displayed if roasted weight is larger or equal to green beans weight@@Yield must be smaller than weight">
                                Yield must be smaller than weight
                            </div>
                            } @else {
                            <div class="comment comment-ble warn-amount"></div>
                            }
                            <mat-checkbox [disabled]="roastcopy.reconciled"
                                [(ngModel)]="roastcopy.discarded"
                                class="tertiary-element discarded-checkbox"
                                name="discarded_checkbox"
                                i18n="roast$$discarded@@discarded">discarded</mat-checkbox>
                            <br />
                            @if (!roast.roast_id) {
                            <div>
                                <app-ap-datetime [disabled]="roastcopy.reconciled" [dateRequired]="true"
                                    [value]="roastcopy.date" (valueChange)="roastcopy.date=$event">
                                </app-ap-datetime>
                            </div>
                            }
                        </div>
                        <div class="tablediv">
                            <table class="proptable mat-elevation-z8">
                                <tbody>
                                    <tr class="inputrow">
                                        <td class="labelname"
                                            matTooltip="Batch Prefix / Number / Position"
                                            i18n-matTooltip="roasts$$Batch Prefix / Number / Position tooltip@@Batch Prefix / Number / Position">
                                            <span i18n="roasts$$Batch@@Batch">Batch</span>
                                        </td>
                                        <td>
                                            <input type="text" [disabled]="roastcopy.reconciled"
                                                name="batch_prefix" class="thirdwidth" style="text-align: right;"
                                                [(ngModel)]="roastcopy.batch_prefix"
                                                placeholder="Prefix" i18n-placeholder="roasts$$Batch Prefix (short placeholder)@@Prefix">
                                            <input [disabled]="roastcopy.reconciled"
                                                type="number" pattern="[\-0-9,.]*" inputmode="numeric" min="0"
                                                name="batch_number" class="thirdwidth" style="text-align: right;"
                                                [value]="Number.isFinite(roastcopy.batch_number) ? round(roastcopy.batch_number) : ''"
                                                (change)="checkChanges('batch_number', roastcopy.batch_number, $event.target['value'], 3, utils.clamp(0))"
                                                placeholder="Number" i18n-placeholder="reports$$report heading number@@Number">
                                            <input type="number" inputmode="numeric" min="0"
                                                name="batch_pos" class="thirdwidth" style="text-align: right;"
                                                [value]="Number.isFinite(roastcopy.batch_pos) ? round(roastcopy.batch_pos) : ''"
                                                (change)="checkChanges('batch_pos', roastcopy.batch_pos, $event.target['value'], 3, utils.clamp(0))"
                                                placeholder="Position" i18n-placeholder="roasts$$Batch Position (short placeholder)@@Position">
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="roasts$$machine@@Machine">Machine</span></td>
                                        <td>
                                            <input [disabled]="roastcopy.reconciled" style="width: 192px"
                                                [(ngModel)]="roastcopy.machine"
                                                (input)="changeMachineFilter($event.target['value'])"
                                                (focus)="changeMachineFilter($event.target['value'])"
                                                matInput type="text" id="machine" name="machine"
                                                autocomplete="off" [matAutocomplete]="autoCompleteMachine">
                                            <mat-autocomplete #autoCompleteMachine="matAutocomplete">
                                                @for (machine of filteredMachines; track machine?.label) {
                                                <mat-option [value]="machine.label">{{machine.label}}</mat-option>
                                                }
                                            </mat-autocomplete>
                                            <!-- <input [disabled]="roastcopy.reconciled" type="text" name="machine" [(ngModel)]="roastcopy.machine"> -->
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="cupping$$Cupping@@Cupping">Cupping</span></td>
                                        <td><input type="text" inputmode="decimal" min="0" max="100"
                                                class="number-input" name="cupping_score"
                                                (change)="checkChanges('cupping_score', roastcopy.cupping_score, $event.target['value'], undefined, utils.clamp(1, 100))"
                                                [value]="roastcopy.cupping_score === null ? '' : roastcopy.cupping_score | number:'1.0-2'"></td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="roasts$$color of beans after grinding@@Ground color">Ground color</span></td>
                                        <td class="number-dropdown">
                                            <input type="text" inputmode="numeric" min="1" max="255"
                                                class="number-input" name="ground_color"
                                                (change)="checkChanges('ground_color', roastcopy.ground_color, $event.target['value'], 3, utils.clamp(1, 255))"
                                                [value]="roastcopy.ground_color === null ? '' : roastcopy.ground_color | number:'1.0-1'">
                                            <mat-select class="nexttonumber" panelWidth="null"
                                                name="color_system1" hideSingleSelectionIndicator="true"
                                                [(ngModel)]="roastcopy.color_system">
                                                <mat-option value=''></mat-option>
                                                <mat-option value='Tonino'>Tonino</mat-option>
                                                <mat-option value='Agtron'>Agtron</mat-option>
                                                <mat-option value='ColorTrack'>ColorTrack</mat-option>
                                                <mat-option value='ColorTest'>ColorTest</mat-option>
                                                <mat-option value='Colorette'>Colorette</mat-option>
                                            </mat-select>
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="roasts$$color of whole beans@@Whole color">Whole color</span></td>
                                        <td class="number-dropdown">
                                            <input type="text" inputmode="numeric" min="1" max="255"
                                                class="number-input" name="whole_color"
                                                (change)="checkChanges('whole_color', roastcopy.whole_color, $event.target['value'], 3, utils.clamp(1, 255))"
                                                [value]="roastcopy.whole_color | number:'1.0-1'">
                                            <mat-select class="nexttonumber" panelWidth="null"
                                                name="color_system2" hideSingleSelectionIndicator="true"
                                                [(ngModel)]="roastcopy.color_system">
                                                <mat-option value=''></mat-option>
                                                <mat-option value='Tonino'>Tonino</mat-option>
                                                <mat-option value='Agtron'>Agtron</mat-option>
                                                <mat-option value='ColorTrack'>ColorTrack</mat-option>
                                                <mat-option value='ColorTest'>ColorTest</mat-option>
                                                <mat-option value='Colorette'>Colorette</mat-option>
                                            </mat-select>
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$property Moisture@@Moisture">Moisture</span></td>
                                        <td><input type="text" inputmode="numeric" min="0" max="100"
                                                class="number-input" name="moisture"
                                                (change)="checkChanges('moisture', roastcopy.moisture, $event.target['value'], 1, utils.clamp(0.1, 100))"
                                                [value]="roastcopy.moisture === null ? '' : roastcopy.moisture | number:'1.0-1'"> %</td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="roasts$$roast property roasted density@@Density">Density</span></td>
                                        <td><input type="text" inputmode="numeric" min="0.1" max="999"
                                                class="number-input" name="density_roasted"
                                                (change)="checkChanges('density_roasted', roastcopy.density_roasted, $event.target['value'], 1, utils.clamp(0.1, 999))"
                                                [value]="roastcopy.density_roasted === null ? '' : roastcopy.density_roasted | number:'1.0-1'"> g/l</td>
                                    </tr>
                                    @if (roast.charge_temp || roast.drop_temp || roast.drop_time || roast.DEV_time || roast.DEV_ratio || roast.temperature || roast.pressure || roast.humidity || roast.CM_ETD || roast.CM_BTD || roast.AUC || roast.AUC_base || roast.template?.batch_prefix || Number.isFinite(roast.template?.batch_number) || roast.template?.label || roast.FCs_RoR || roast.FCs_temp || roast.FCs_time || (roast.FCe_time - roast.FCs_time > 0)) {
                                    <tr class="table-row-separator">
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    }
                                    @if (roast.charge_temp) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$roast property CHARGE@@CHARGE">CHARGE</td>
                                        <td>{{utils.convertTemp(roast.charge_temp, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}</td>
                                    </tr>
                                    }
                                    @if (roast.FCs_temp || roast.FCs_time) {
                                    <tr>
                                        <td class="labelname">FC</td>
                                        <td>
                                            @if (roast.FCs_temp) {
                                            <span>{{utils.convertTemp(roast.FCs_temp, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}</span>
                                            }
                                            @if (roast.FCs_time) {
                                            <span> &#64; {{floor(roast.FCs_time / 60.0) + ':' + utils.padNumber(roast.FCs_time % 60, 2)}}</span>
                                            }
                                            @if (roast.FCs_RoR || (roast.FCe_time - roast.FCs_time > 0)) {
                                            <span> [@if (roast.FCs_RoR) {{{utils.convertTemp(roast.FCs_RoR, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}/min}@if (roast.FCe_time - roast.FCs_time > 0){@if (roast.FCs_RoR) {;}{{floor((roast.FCe_time - roast.FCs_time) / 60.0) + ':' + utils.padNumber((roast.FCe_time - roast.FCs_time) % 60, 2)}}}]</span>
                                            }
                                        </td>
                                    </tr>
                                    }
                                    @if (roast.drop_temp || roast.drop_time) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$roast property DROP@@DROP">DROP</td>
                                        <td>
                                            @if (roast.drop_temp) {
                                            <span>{{utils.convertTemp(roast.drop_temp, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}</span>
                                            }
                                            @if (roast.drop_time) {
                                            <span> &#64; {{floor(roast.drop_time / 60.0) + ':' + utils.padNumber(roast.drop_time % 60, 2)}}</span>
                                            }
                                        </td>
                                    </tr>
                                    }
                                    @if (roast.DEV_time || roast.DEV_ratio) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$roast property DEV@@DEV">DEV</td>
                                        <td>{{floor(roast.DEV_time / 60.0) + ':' + utils.padNumber(roast.DEV_time % 60, 2)}}@if (roast.DEV_time && roast.DEV_ratio) {, }
                                            @if (roast.DEV_ratio) {
                                            <span>{{roast.DEV_ratio | number:'1.0-1'}}%</span>
                                            }
                                        </td>
                                    </tr>
                                    }
                                    @if (roast.temperature) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$roast property Temperature@@Temperature">Temperature</td>
                                        <td>{{utils.convertTemp(roast.temperature, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}</td>
                                    </tr>
                                    }
                                    @if (roast.pressure) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$roast property Pressure@@Pressure">Pressure</td>
                                        <td>{{roast.pressure | number:'1.0-1'}} hPa</td>
                                    </tr>
                                    }
                                    @if (roast.humidity) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$roast property humidity@@Humidity">Humidity</td>
                                        <td>{{roast.humidity | number:'1.0-1'}}%</td>
                                    </tr>
                                    }
                                    @if (roast.CM_ETD || roast.CM_BTD) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$roast property CM ET/BT@@CM ET/BT">CM ET/BT</td>
                                        <td>
                                            @if (roast.CM_ETD) {
                                            {{utils.convertTempDifference(roast.CM_ETD, currentUser?.temperature_system) | number:'1.0-1'}}
                                            } @else {
                                            --
                                            }
                                            @if (!roast.CM_BTD) {
                                            {{tempUnit}}
                                            }/@if (roast.CM_BTD) {
                                            {{utils.convertTempDifference(roast.CM_BTD, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}
                                            } @else {
                                            --
                                            }
                                        </td>
                                    </tr>
                                    }
                                    @if (roast.AUC || roast.AUC_base) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$roast property AUC@@AUC">AUC</td>
                                        <td>
                                            @if (roast.AUC) {
                                            {{roast.AUC | number:'1.0-0'}} °C&middot;min
                                            }
                                            @if (roast.AUC_base) {
                                            [{{utils.convertTemp(roast.AUC_base, currentUser?.temperature_system) | number:'1.0-0'}}{{tempUnit}}]
                                            }
                                        </td>
                                    </tr>
                                    }
                                    @if (roast.template?.batch_prefix || Number.isFinite(roast.template?.batch_number) || roast.template?.label) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$roast property Template@@Template">Template</td>
                                        <td>
                                            @if (roast.template.hr_id) {
                                            <a [routerLink]="['/roasts', {id: roast.template.hr_id} ]">{{roast.template.batch_prefix}}{{Number.isFinite(roast.template.batch_number) ? round(roast.template.batch_number) : ''}} {{roast.template.label}}</a>
                                            } @else {
                                            {{roast.template.batch_prefix}}{{Number.isFinite(roast.template.batch_number) ? round(roast.template.batch_number) : ''}} {{roast.template.label}}
                                            }
                                        </td>
                                    </tr>
                                    }
                                    @if (roast.BTU_batch || roast.CO2_batch) {
                                    <tr class="table-row-separator">
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    }
                                    @if (roast.BTU_batch && currentUser) {
                                    <tr>
                                        <td class="labelname" i18n="roasts$$energy (e.g. used by one or more roasts)@@Energy">Energy</td>
                                        <td class="number-dropdown">
                                            {{roast['BTU_batch_str']}}
                                            <mat-select class="nexttonumber"
                                                name="energy_unit" hideSingleSelectionIndicator="true"
                                                [(ngModel)]="currentUser.energy_unit"
                                                (selectionChange)="saveEnergyUnit($event.value)">
                                                <mat-option value="BTU">BTU</mat-option>
                                                <mat-option value="kJ">kJ</mat-option>
                                                <mat-option value="kCal">kCal</mat-option>
                                                <mat-option value="kWh">kWh</mat-option>
                                                <mat-option value="hph">hph</mat-option>
                                            </mat-select>
                                        </td>
                                    </tr>
                                    }
                                    @if (roast.CO2_batch) {
                                    <tr>
                                        <td class="labelname">CO2</td>
                                        <td class="number-dropdown" style="word-break: initial;">
                                            {{roast['CO2_batch_str']}}
                                        </td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            } @else { <!-- not edit mode -->
            <div class="outerdiv">
                <div class="nottable">
                    <app-modification-date [element]="roast" [user]="currentUser"></app-modification-date>
                    <span>{{roast.hr_id}}</span>@if (roast?.location?.hr_id) {
                    <span> &#64; <a [routerLink]="['/stores', {id: roast.location.hr_id}]">{{roast.location.label}}</a></span>
                    }<br />
                    @if (utils.getWeight(roast.amount, currentUser?.unit_system); as weight) {
                    <span class="boldtext">{{weight.pre}}{{weight.value | number:'1.0-3'}}{{weight.post}}</span>
                    }
                    @if (roast.amount) {
                    @if (utils.getWeight(roast.end_weight, currentUser?.unit_system); as weight) {
                    <span> ({{weight.pre}}{{weight.value | number:'1.0-3'}}{{weight.post}} <span i18n="roasts$$yield i.e. end weight@@roast yield">yield</span>, {{((roast.amount - roast.end_weight) / roast.amount) * 100 | number:'1.0-1'}}% <span i18n="roasts$$e.g. 15% _loss_@@loss">loss</span>)</span>
                    }
                    @else {
                    @if (utils.getWeight(roast.end_weight, currentUser?.unit_system); as weight) {
                    <span>{{weight.pre}}{{weight.value | number:'1.0-3'}}{{weight.post}} <span i18n="roasts$$yield i.e. end weight@@roast yield">yield</span></span>
                    }
                    }
                    }
                    <br />
                    @if (roast.coffee) {
                    <div>
                        @if (roast.coffee.hr_id) {
                        <a [routerLink]="['/coffees', {id: roast.coffee.hr_id}]">{{roast.coffee.hr_id}}</a>
                        }
                        <app-organicicon [isOrganic]="utils.isOrganicCoffee(roast.coffee)" [leftMargin]="5" [size]="10"></app-organicicon>
                        @if (roast.coffee.origin) {
                        <span> {{tr.anslate(roast.coffee.origin)}}</span>
                        }
                        @if (roast.coffee.yearLabel) {
                        <span> {{roast.coffee.yearLabel}}</span>
                        }
                        @if (roast.coffee.label && (roast.coffee.origin || roast.coffee.yearLabel)) {
                        <span>,</span>
                        }
                        @if (roast.coffee.label) {
                        <span> {{roast.coffee.label}}</span>
                        }
                    </div>
                    }
                    @else if (roast.blend) {
                    <div>
                        @if (roast.blend.label) {
                        <div class="blend-label">{{roast.blend.label}}:</div>
                        }
                        <ul class="blend-list">
                            @for (ing of roast.blend.ingredients; track $index) {
                            <li>
                                @if (ing.ratio != null) {
                                <span>{{ing.ratio * 100 | number:'1.1-1'}}% @if (ing.ratio && roast.amount && utils.getWeight(ing.ratio * roast.amount, currentUser?.unit_system); as weight) {
                                    <span>({{weight.pre}}{{weight.value | number:'1.0-3'}}{{weight.post}})</span>
                                    }
                                    @if (ing.coffee?.hr_id) {
                                    <a [routerLink]="['/coffees', {id: ing.coffee.hr_id}]" class="littlespaceleft">{{ing.coffee.hr_id}}</a>
                                    }
                                    <app-organicicon [isOrganic]="utils.isOrganicCoffee(ing.coffee)" [spacer]="utils.haveOrganicAndNonOrganic(roast.blend.ingredients)" [leftMargin]="5" [size]="10"></app-organicicon>
                                    @if (ing.coffee?.origin) {
                                    <span> {{tr.anslate(ing.coffee.origin)}}</span>
                                    }
                                    @if (ing.coffee?.yearLabel) {
                                    <span> {{ing.coffee.yearLabel}}</span>
                                    }
                                    @if (ing.coffee?.label && (ing.coffee.origin || ing.coffee.yearLabel)) {
                                    <span>,</span>
                                    }
                                    @if (ing.coffee?.label) {
                                    <span> {{ing.coffee.label}}</span>
                                    }
                                </span>
                                }
                            </li>
                            }
                        </ul>
                    </div>
                    }
                    @if (commonCerts?.length > 0) {
                    <div style="margin-top: 13px; margin-bottom: 10px;">
                        @for (cert of commonCerts; track cert?._id) {
                        <span>
                            {{cert.abbrev ? cert.abbrev : cert.label}}&nbsp;@if (cert.web) {
                            <a href="{{cert.web}}" target="_blank" rel="noopener">
                                <mat-icon class="small">launch</mat-icon>
                            </a>
                            }
                            @if (commonCerts.length -1 > $index) {, }
                        </span>
                        }
                    </div>
                    }
                    <div>
                        {{roast.date?.toLocaleString(DateTime.DATE_MED)}} {{roast.date?.toLocaleString(DateTime.TIME_SIMPLE)}}
                        @if (roast.report) {
                        <span>@if (roast.date) {<span>, </span>}
                            <span i18n="generic$$report@@report">report</span>&nbsp;<a [routerLink]="['/reports/roasts/fixed', { id: roast.report }]">{{roast.report}}</a>
                        </span>
                        }
                    </div>
                    @if (roast.roast_id) {
                    <div class="roast-template-switch">
                        <mat-checkbox labelPosition="before" class="primary-element" name="is_template_switch"
                            [(ngModel)]="roast.is_template"
                            (change)="isTemplateChanged()"
                            i18n="roasts$$should this roast be available as a template@@Always available as template">
                            Always available as template</mat-checkbox>
                    </div>
                    }
                </div>
                @if (hasAProperty() || hasAReadonlyProperty()) {
                <div class="tablediv">
                    <table class="proptable mat-elevation-z8">
                        <tbody>
                            @if (roast.batch_prefix || Number.isFinite(roast.batch_number) || Number.isFinite(roast.batch_pos)) {
                            <tr>
                                <td (click)="edit()" class="labelname" i18n="roasts$$Batch@@Batch">Batch</td>
                                <td>
                                    <ng-container *ngTemplateOutlet="batchInfo"></ng-container>
                                    @if (roast.roast_id) {
                                    <a [href]="this.utils.sanitize('artisan://roast/' + roast.roast_id)" target="_blank" rel="noopener" class="spaced-icon" matTooltip="Open in Artisan" i18n-matTooltip="roasts$$tooltip load as template in Artisan app@@Open in Artisan">
                                        <mat-icon class="helpicon" svgIcon="artisan-blue"></mat-icon>
                                    </a>
                                    <a [href]="this.utils.sanitize('artisan://template/' + roast.roast_id)" target="_blank" rel="noopener" matTooltip="Open in Artisan as background template (Artisan >2.4.4)" i18n-matTooltip="roasts$$tooltip load as background template in Artisan app@@Open in Artisan as background template (Artisan >2.4.4)">
                                        <mat-icon class="helpicon" svgIcon="artisan-grey"></mat-icon>
                                    </a>
                                    }
                                </td>
                            </tr>
                            }
                            @if (roast.machine) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$machine@@Machine">Machine</td>
                                <td>{{roast.machine}}</td>
                            </tr>
                            }
                            @if (roast.cupping_score) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="cupping$$Cupping@@Cupping">Cupping</span></td>
                                <td>{{roast.cupping_score | number:'1.0-2'}}</td>
                            </tr>
                            }
                            @if (roast.ground_color) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$color of beans after grinding@@Ground color">Ground color</td>
                                <td>{{roast.ground_color | number:'1.0-1'}} @if (roast.color_system) {
                                    <span>({{roast.color_system}})</span>
                                    }
                                </td>
                            </tr>
                            }
                            @if (roast.whole_color) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$color of whole beans@@Whole color">Whole color</td>
                                <td>{{roast.whole_color | number:'1.0-1'}} @if (roast.color_system) {
                                    <span>({{roast.color_system}})</span>
                                    }
                                </td>
                            </tr>
                            }
                            @if (roast.moisture) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$property Moisture@@Moisture">Moisture</span></td>
                                <td>{{roast.moisture | number:'1.0-1'}}%</td>
                            </tr>
                            }
                            @if (roast.density_roasted) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="roasts$$roast property roasted density@@Density">Density</span></td>
                                <td>{{roast.density_roasted | number:'1.0-1'}}<span i18n="unit_system$$density unit grams per liter@@grams per liter">g/l</span></td>
                            </tr>
                            }
                            @if (roast.charge_temp || roast.drop_temp || roast.drop_time || roast.DEV_time || roast.DEV_ratio || roast.temperature || roast.pressure || roast.humidity || roast.AUC || roast.AUC_base || roast.template?.batch_prefix || Number.isFinite(roast.template?.batch_number) || roast.template?.label || roast.FCs_temp || roast.FCs_time || roast.FCs_RoR || (roast.FCe_time - roast.FCs_time > 0) ) {
                            <tr (click)="edit()" class="table-row-separator">
                                <td></td>
                                <td></td>
                            </tr>
                            }
                            @if (roast.charge_temp) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$roast property CHARGE@@CHARGE">CHARGE</td>
                                <td>{{utils.convertTemp(roast.charge_temp, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}</td>
                            </tr>
                            }
                            @if (roast.FCs_temp || roast.FCs_time) {
                            <tr (click)="edit()">
                                <td class="labelname">FC</td>
                                <td>@if (roast.FCs_temp) {
                                    <span>{{utils.convertTemp(roast.FCs_temp, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}</span>
                                    } @if (roast.FCs_time) {
                                    <span> &#64; {{floor(roast.FCs_time / 60.0) + ':' + utils.padNumber(roast.FCs_time % 60, 2)}}</span>
                                    } @if (roast.FCs_RoR || (roast.FCe_time - roast.FCs_time > 0)) {
                                    <span> @if (roast.FCs_RoR) { [{{utils.convertTemp(roast.FCs_RoR, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}/min}@if (roast.FCe_time - roast.FCs_time > 0) {@if (roast.FCs_RoR) {;} @else {[}{{floor((roast.FCe_time - roast.FCs_time) / 60.0) + ':' + utils.padNumber((roast.FCe_time - roast.FCs_time) % 60, 2)}}}]</span>
                                    }
                                </td>
                            </tr>
                            }
                            @if (roast.drop_temp || roast.drop_time) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$roast property DROP@@DROP">DROP</td>
                                <td>@if (roast.drop_temp) {
                                    <span>{{utils.convertTemp(roast.drop_temp, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}</span>
                                    }@if (roast.drop_time) {
                                    <span> &#64; {{floor(roast.drop_time / 60.0) + ':' + utils.padNumber(roast.drop_time % 60, 2)}}</span>
                                    }
                                </td>
                            </tr>
                            }
                            @if (roast.DEV_time || roast.DEV_ratio) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$roast property DEV@@DEV">DEV</td>
                                <td>{{floor(roast.DEV_time / 60.0) + ':' + utils.padNumber(roast.DEV_time % 60, 2)}}@if (roast.DEV_time && roast.DEV_ratio) {
                                    <span>, </span>
                                    }@if (roast.DEV_ratio) {
                                    <span>{{roast.DEV_ratio | number:'1.0-1'}}%</span>
                                    }
                                </td>
                            </tr>
                            }
                            @if (roast.temperature) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$roast property Temperature@@Temperature">Temperature</td>
                                <td>{{utils.convertTemp(roast.temperature, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}</td>
                            </tr>
                            }
                            @if (roast.pressure) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$roast property Pressure@@Pressure">Pressure</td>
                                <td>{{roast.pressure | number:'1.0-1'}} hPa</td>
                            </tr>
                            }
                            @if (roast.humidity) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$roast property humidity@@Humidity">Humidity</td>
                                <td>{{roast.humidity | number:'1.0-1'}}%</td>
                            </tr>
                            }
                            @if (roast.CM_ETD || roast.CM_BTD) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$roast property CM ET/BT@@CM ET/BT">CM ET/BT</td>
                                <td>
                                    @if (roast.CM_ETD) {
                                    {{utils.convertTempDifference(roast.CM_ETD, currentUser?.temperature_system) | number:'1.0-1'}}
                                    } @else {
                                    --
                                    }
                                    @if (!roast.CM_BTD) {
                                    {{tempUnit}}
                                    }/@if (roast.CM_BTD) {
                                    {{utils.convertTempDifference(roast.CM_BTD, currentUser?.temperature_system) | number:'1.0-1'}}{{tempUnit}}
                                    } @else {
                                    --
                                    }
                                </td>
                            </tr>
                            }
                            @if (roast.AUC || roast.AUC_base) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n="roasts$$roast property AUC@@AUC">AUC</td>
                                <td>
                                    @if (roast.AUC) {
                                    {{roast.AUC | number:'1.0-0'}} °C&middot;min
                                    }
                                    @if (roast.AUC_base) {
                                    [{{utils.convertTemp(roast.AUC_base, currentUser?.temperature_system) | number:'1.0-0'}}{{tempUnit}}]
                                    }
                                </td>
                            </tr>
                            }
                            @if (roast.template?.batch_prefix || Number.isFinite(roast.template?.batch_number) || roast.template?.label) {
                            <tr>
                                <td class="labelname" i18n="roasts$$roast property Template@@Template">Template</td>
                                <td class="clipped-string">
                                    @if (roast.template.hr_id) {
                                    <a [routerLink]="['/roasts', {id: roast.template.hr_id} ]">{{roast.template.batch_prefix}}{{Number.isFinite(roast.template.batch_number) ? round(roast.template.batch_number) : ''}} {{roast.template.label}}</a>
                                    } @else {
                                    {{roast.template.batch_prefix}}{{Number.isFinite(roast.template.batch_number) ? round(roast.template.batch_number) : ''}} {{roast.template.label}}
                                    }
                                </td>
                            </tr>
                            }
                            @if (roast.BTU_batch || roast.CO2_batch) {
                            <tr class="table-row-separator">
                                <td></td>
                                <td></td>
                            </tr>
                            }
                            @if (roast.BTU_batch) {
                            <tr>
                                <td class="labelname" i18n="roasts$$energy (e.g. used by one or more roasts)@@Energy">Energy</td>
                                <td class="number-dropdown" style="word-break: initial;">
                                    <span (click)="edit()" (keydown.enter)="edit(); $event.preventDefault()" role="button" [tabindex]="0">{{roast['BTU_batch_str']}}</span>
                                    <mat-select class="nexttonumber" panelWidth="null"
                                        name="energy_unit" hideSingleSelectionIndicator="true"
                                        [(ngModel)]="energyUnit"
                                        (selectionChange)="saveEnergyUnit($event.value)">
                                        <mat-option value="BTU">BTU</mat-option>
                                        <mat-option value="kJ">kJ</mat-option>
                                        <mat-option value="kCal">kCal</mat-option>
                                        <mat-option value="kWh">kWh</mat-option>
                                        <mat-option value="hph">hph</mat-option>
                                    </mat-select>
                                </td>
                            </tr>
                            }
                            @if (roast.CO2_batch) {
                            <tr (click)="edit()">
                                <td class="labelname">CO2</td>
                                <td style="word-break: initial;">
                                    {{roast['CO2_batch_str']}}
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                <div>
                    @if (roast.image) {
                    <img [src]="roast.image" class="roast-image" (click)="addImage()" (keydown.enter)="addImage(); $event.preventDefault()" role="button" [tabindex]="0" alt="a icture related to the roast">
                    }
                </div>
                <div style="width: 100%;">
                    <app-ap-file [files]="roast.files" [belongsTo]="roast.internal_hr_id" belongsToType="ROAST"
                        (filesChanged)="filesChanged($event)" [readOnly]="readOnly"></app-ap-file>
                </div>
                @if (editable) {
                <div class="notes-container">
                    <mat-form-field class="notesdiv no-height-change"
                        style="margin-right: 20px;" subscriptSizing="dynamic">
                        <mat-label i18n="roast$$Roasting Notes@@Roasting Notes">Roasting Notes</mat-label>
                        <textarea matInput [disabled]="readOnly" name="roasting_notes"
                            [(ngModel)]="roasting_notes"
                            (blur)="roastingNotesChanged.next(roasting_notes)"
                            (ngModelChange)="roastingNotesChanged.next(roasting_notes)"
                            [ngStyle]="{'margin-top': roasting_notes?.includes('\n') ? 0 : '14px'}"
                            cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5">
                        </textarea>
                    </mat-form-field>
                    <mat-form-field class="notesdiv no-height-change" subscriptSizing="dynamic">
                        <mat-label i18n="cupping$$Cupping Notes@@Cupping Notes">Cupping Notes</mat-label>
                        <textarea matInput [disabled]="readOnly" name="cupping_notes"
                            [(ngModel)]="cupping_notes"
                            (blur)="cuppingNotesChanged.next(cupping_notes)"
                            (ngModelChange)="cuppingNotesChanged.next(cupping_notes)"
                            [ngStyle]="{'margin-top': cupping_notes?.includes('\n') ? 0 : '14px'}"
                            cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5">
                        </textarea>
                    </mat-form-field>
                </div>
                }
            </div>
            }
        </ng-template>
        @if (editable && (0 > editMode || editMode !== index)) {
        <mat-action-row>
            @if (!checkingIfDeletable && !readOnlyAccount) {
            <button mat-raised-button type="button" [disabled]="editMode >= 0 || roast.reconciled" (click)="delete()" class="edit-button">
                <mat-icon>delete</mat-icon>
            </button>
            } @else if (checkingIfDeletable) {
            <button disabled mat-raised-button class="action-button spinner-button">
                <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
            </button>
            }
            @if (!readOnly) {
            <button mat-raised-button type="button" [disabled]="editMode >= 0" (click)="edit()" class="action-button">
                <mat-icon>edit</mat-icon>
            </button>
            }
        </mat-action-row>
        }
        <!-- <mat-action-row *ngIf="isExpanded && roast.reconciled">
    <p>This item has been put into a final report and cannot be changed any more.</p>
  </mat-action-row> -->
        @if (!readOnly && editMode === index) {
        <mat-action-row [ngClass]="{'separate-buttons': roastcopy?.internal_hr_id || roastcopy?._id}">
            @if (roastcopy?.internal_hr_id || roastcopy?._id) {
            <button mat-raised-button type="button" class="action-button otherside-button" style="align-self: center;" (click)="addImage()">
                <mat-icon>{{roastcopy?.image ? 'insert_photo' : 'add_photo_alternate'}}</mat-icon>
            </button>
            }
            @if (roast.reconciled) {
            <span class="button-note" i18n="roasts$$most info cannot be changed message@@reconciled roast info">This item has been put into a final report and most content cannot be changed any more.</span>
            }
            <div style="min-width: 165px;">
                <button mat-button type="button" class="cancel-button"
                    (click)="cancel()">
                    <mat-icon>close</mat-icon>
                </button>
                @if (!isSaving) {
                <button [disabled]="!!isSavingTimer" mat-raised-button type="submit" class="action-button" form="form" data-test="bttn_done">
                    <mat-icon>done</mat-icon>
                </button>
                } @else {
                <button disabled mat-raised-button class="action-button spinner-button">
                    <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                </button>
                }
            </div>
        </mat-action-row>
        }
    </mat-expansion-panel>
</span>
}