/* eslint-disable @typescript-eslint/no-explicit-any */
 
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import 'chartjs-plugin-stacked100';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Observable } from 'rxjs';
import { BaseChartDirective } from 'ng2-charts';
import { map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CurrencyPipe } from '@angular/common';
import { getCurrencySymbol } from '@angular/common';
import { DateTime } from 'luxon';
import { ChartConfiguration, TooltipItem } from 'chart.js';

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-amountpertime-graph',
    templateUrl: './amountpertime-graph.component.html',
})
export class AmountpertimeGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private utils: Utils,
        private breakpointObserver: BreakpointObserver,
        private currencyPipe: CurrencyPipe,
        @Inject(LOCALE_ID) public locale: string
    ) { }

    MAX_NUMBER_OF_MONTHS = 12;

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    // needed to have the correct order of ngOnInit and newData setter
    inited = false;
    updData: any;
    haveData = false;

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currency = 'EUR';
    @Input() isDarkmode = false;

    _isRatherSmall = false;
    isRatherSmall$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 999px)')
        .pipe(map(result => result.matches));

    additionalData: any;

    type = 'line';

    totalCosts: number[] = [];
    data: ChartConfiguration<'line', number[], number>['data'];

    weight_factor = 1; // if max sweights are large (>2000kg or >4000lbs) the weight data in sets is divided by weight_factor to make ticks more readable
    // weight_factor is 1 if data is in this.mainUnit, 1000 if this.mainUnit is 'kg' and data values are in 't' or
    // weight_factor is 2000 if this.mainUnit is 'lbs' and data values are in 't'
    tickUnit = '';     // the label used to indicate the weight unit of axis ticks (the string 'kg', 'lbs', or 't')

    options: ChartConfiguration<'line'>['options'] & { plugins: { roughness?: any } } = {
        plugins: {
            title: {
                display: true,
                text: this.tr.anslate('Stock'),
                position: 'top' as const,
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            roughness: {
                disabled: true,
            },
            tooltip: {
                mode: 'index' as const,
                intersect: false,
                // position: 'nearest',
                backgroundColor: 'rgba(219,87,133,0.8)', // '#db5785'
                caretPadding: 10,
                displayColors: false,
                // // remove total and all that are 0
                // filter: (tooltipItem, data) =>
                //     tooltipItem.datasetIndex !== 0 && data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] !== 0,
                // // show stores with large stocks first
                // itemSort: (a, b, data) =>
                //     data.datasets[b.datasetIndex].data[b.index] - data.datasets[a.datasetIndex].data[a.index],
                callbacks: {
                    title: (tooltipItems: TooltipItem<'line'>[]) => {
                        if (tooltipItems[0]) {
                            const index = tooltipItems[0].dataIndex;
                            let titleStr = this.getDateStr(this.data, index, '');
                            const moreThanOneStore = this.additionalData.length > 1 && this.additionalData.reduce((prev: number, cur: { data: number[] }) => prev + (cur.data[index] ? 1 : 0), 0) > 1;
                            if (moreThanOneStore) {
                                let total = Math.round((this.data.datasets[0].data[index] as number) * 10 * this.weight_factor) / 10;
                                if (total && this.mainUnit === 'lbs') {
                                    total /= this.utils.getUnitFactor(this.mainUnit); // to convert values back before formatting
                                }
                                const cost = this.totalCosts && this.totalCosts[index];
                                if (total) {
                                    titleStr += ' ' + this.utils.formatAmount(total, undefined, this.mainUnit, 1);
                                    if (cost) {
                                        titleStr += '$$$$';
                                    }
                                }
                                if (cost) {
                                    titleStr += this.formatCost(cost, false);
                                }
                            }
                            return titleStr;
                        }
                        return this.tr.anslate('Total');
                    },
                    label: (tooltipItem: TooltipItem<'line'>) => {
                        const str = [];
                        this.additionalData.sort((a: { data: number[]; }, b: { data: number[]; }) => b.data[tooltipItem.dataIndex] - a.data[tooltipItem.dataIndex]);
                        const MAX_ELEMS = 8;
                        const haveAnyCost = this.additionalData.reduce((prev: boolean, cur: { cost: number[]; }) => prev || (cur?.cost?.[tooltipItem.dataIndex]), false);
                        for (let i = 0; i < Math.min(this.additionalData.length, MAX_ELEMS); i++) {
                            // (additionalData are not potentially converted to lbs)
                            const total = this.additionalData[i].data[tooltipItem.dataIndex];
                            if (!total) {
                                continue;
                            }
                            const cost: number = this.additionalData[i]?.cost?.[tooltipItem.dataIndex];
                            let lblStr = this.additionalData[i].label + ': ';
                            if (total) {
                                lblStr += this.utils.formatAmount(total, undefined, this.mainUnit, 1);
                                if (cost || haveAnyCost) {
                                    lblStr += '$$$$';
                                }
                            }
                            if (cost) {
                                lblStr += this.formatCost(cost, false);
                            } else if (haveAnyCost) {
                                lblStr += '&emsp;';
                            }
                            str.push(lblStr);
                        }
                        if (this.additionalData.length > MAX_ELEMS) {
                            str.push('...');
                        }
                        return str;
                    }
                },

                // Disable the on-canvas tooltip
                enabled: false,

                external: function (context) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        tooltipEl.style.zIndex = '5';
                        tooltipEl.style.background = 'rgba(66,66,66,0.8)'; // '#424242'
                        tooltipEl.style.border = '0px solid rgba(204, 15, 80, 0.6)';
                        tooltipEl.style.borderRadius = '6px';
                        tooltipEl.style.color = 'white';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem: { lines: any; }) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = '<thead>';

                        titleLines.forEach(function (title: string) {
                            if (title.indexOf('$$$$') >= 0) {
                                const blSplit = title.split('$$$$');
                                innerHtml += '<tr><th style="padding-bottom: 5px; display: flex; justify-content: space-between;">' + blSplit[0] + '<i>&emsp;</i>' + blSplit[1] + '</td></tr>';
                            } else {
                                innerHtml += '<tr><th style=\'padding-bottom: 5px;\'>' + title + '</th></tr>';
                            }
                        });
                        innerHtml += '</thead><tbody>';

                        bodyLines[0].forEach(function (bodyLine: string) {
                            if (bodyLine.indexOf('$$$$') >= 0) {
                                const blSplit = bodyLine.split('$$$$');
                                innerHtml += '<tr><td style="display: flex; justify-content: space-between;">' + blSplit[0] + '<i>&emsp;</i>' + blSplit[1] + '</td></tr>';
                            } else {
                                innerHtml += '<tr><td>' + bodyLine + '</td></tr>';
                            }
                        });
                        innerHtml += '</tbody>';

                        const tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;
                    }

                    const position = context.chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.position = 'absolute';

                    // tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    const pl = position.left + window.scrollX;
                    if (tooltipModel.caretX - (tooltipModel.width / 2.0) < 0) {
                        tooltipEl.style.left = (pl + 3) + 'px';
                    } else if (tooltipModel.caretX + (tooltipModel.width / 2.0) > position.width) {
                        tooltipEl.style.left = (pl + position.width - tooltipModel.width - 30) + 'px';
                    } else {
                        tooltipEl.style.left = (pl + tooltipModel.caretX - (tooltipModel.width / 2.0)) + 'px';
                    }
                    tooltipEl.style.top = position.top + tooltipModel.caretY + 10 + 'px';

                    // tooltipEl.style.fontFamily = tooltipModel.bodyFontFamily;
                    // tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    // tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    // tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                    tooltipEl.style.pointerEvents = 'none';

                    tooltipEl.querySelector('table').innerHTML = tooltipEl.querySelector('table').innerHTML.replace(/\$\$\$\$/g, '&nbsp;&nbsp;•&nbsp;&nbsp;'); //   »  
                }
            },
        },
        hover: {
            mode: 'index',
            intersect: false,
            // animationDuration: 0, // duration of animations when hovering an item
        },
        layout: {
            padding: {
                left: 5,
                right: 30,
                top: 20,
                bottom: 20
            }
        },
        scales: {
            x: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.25)',
                },
                ticks: {
                    callback: (value: string, index: number) => {
                        return this.getDateStr(this.data, index, value);
                    },
                },
            },
            y: {
                display: true,
                suggestedMin: 0,
                //suggestedMax: 10,
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 6,
                    callback: value => {
                        if ((this._isRatherSmall) && (this.tickUnit !== 't')) {
                            return `${value}`;
                        } else {
                            return `${value}${this.tickUnit}`;
                        }
                    }
                },
                title: {
                    display: false
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.25)',
                },
            },
        },
        responsive: true,
        aspectRatio: 1.5,
        maintainAspectRatio: false,
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }


    ngOnInit(): void {
        this.inited = true;
        // mainUnit @Input is set after the options object is initialized
        if (this.updData) {
            this.setNewData(this.updData);
            this.updData = undefined;
        }

        this.isRatherSmall$.subscribe(val => this._isRatherSmall = val);
    }

    // adapted from https://stackoverflow.com/a/55533058/13171715
    sumArrays(propName: string, arrays: Record<string, number[]>[]) {
        const n = arrays.reduce((max, xs) => Math.max(max, xs[propName]?.length || 0), 0);
        const result = Array.from({ length: n });
        return result.map((_, i) => arrays.map(xs => xs[propName][i] || 0).reduce((sum, x) => sum + x, 0));
    }

    setNewData(nd: any) {
        this.haveData = false;
        this.data = {
            labels: [],
            datasets: [{
                label: this.tr.anslate('Total'),
                data: [],
                borderColor: '#db5785',
                pointBackgroundColor: '#db5785',
                backgroundColor: this.isDarkmode ? '#fafa00' : '#e0e0e055',
                pointHoverRadius: 6,
                pointHoverBorderColor: '#db5785',
                pointHoverBackgroundColor: '#db5785'
            }],
        };
        this.data['interval'] = 'month';
        
        this.additionalData = []; // ensure that this variable is initiallized, otherwise the tooltips fail
        if (nd?.interval) {
            this.data['interval'] = nd.interval;
        }
        if ((nd?.labels && !nd.labels.length) || (nd?.labels2 && !nd.labels2.length) || !this.inited) {
            // initial view or empty data
            let month = DateTime.now().startOf('month');
            this.data.labels = [];
            this.data.datasets[0].data = [];
            for (let m = 0; m < this.MAX_NUMBER_OF_MONTHS; m++) {
                // this.data.labels.unshift(month.toFormat(this._isRatherSmall ? 'LLL' : 'LLLL'));
                this.data.labels.unshift(month.toMillis());
                month = month.minus({ months: 1 }).startOf('month');

                this.data.datasets[0]?.data?.push(0);
            }
        }
        if (!this.inited) {
            // store new data until inited
            this.updData = nd;
        }

        if (this.inited && (nd?.labels?.length || nd.labels2?.length)) {
            this.haveData = true;
            if (nd.labels2?.length) {
                this.data.labels = nd.labels2;
            } else {
                this.data.labels = nd.labels.map((l: string) => {
                    // don't translate '17 Nov'
                    if (Number.isNaN(parseInt(l.substring(0, 1), 10))) {
                        return this.tr.anslate(l);
                    } else {
                        return l;
                    }
                });
            }
            if (nd.additionalData && this.data.datasets.length) {
                this.data.datasets[0].data = this.sumArrays('data', nd.additionalData);
                this.totalCosts = this.sumArrays('cost', nd.additionalData);
            }
            this.additionalData = nd.additionalData;

            if (this.mainUnit === 'lbs') {
                // convert the data (necessary to have the graph show the correct numbers)
                const factor = this.utils.getUnitFactor(this.mainUnit);
                const dataset = this.data.datasets?.[0]?.data;
                for (let d = 0; d < dataset.length; d++) {
                    dataset[d] = (dataset[d] as number) * factor;
                }
                // unnecessary to convert values in additionalData, these will be converted using utils.formatAmount
                // for (let ds = 0; ds < this.additionalData.length; ds++) {
                //     dataset = this.additionalData[ds].data;
                //     for (let d = 0; d < dataset.length; d++) {
                //         dataset[d] *= factor;
                //     }
                // }
                if (Math.max(...(dataset as number[])) > 4000) {
                    this.weight_factor = 2000;
                    this.tickUnit = 't';
                    for (let d = 0; d < dataset.length; d++) {
                        dataset[d] /= this.weight_factor;
                    }
                } else {
                    this.weight_factor = 1;
                    this.tickUnit = this.mainUnit;
                }
            } else {
                const dataset = this.data.datasets?.[0]?.data;
                if (Math.max(...(dataset as number[])) > 2000) {
                    this.weight_factor = 1000;
                    this.tickUnit = 't';
                    for (let d = 0; d < dataset.length; d++) {
                        dataset[d] /= this.weight_factor;
                    }
                } else {
                    this.weight_factor = 1;
                    this.tickUnit = this.mainUnit;
                }
            }

            if (this.isDarkmode) {
                this.data.datasets[0].backgroundColor = '#61616155';
                this.options.scales.x.grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales.x.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                this.options.scales.y.grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales.y.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
            } else {
                this.data.datasets[0].backgroundColor = '#e0e0e055';
                this.options.scales.x.grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales.x.grid.zeroLineColor = 'rgba(0, 0, 0, 0.25)';
                this.options.scales.y.grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales.y.grid.zeroLineColor = 'rgba(0, 0, 0, 0.25)';
            }
            this.data.datasets[0].tension = 0.4;

            this.chart?.render();
        }
    }

    formatCost(cost: number, small: boolean) {
        if (!cost) {
            return this.currencyPipe.transform(0, this.currency, 'symbol-narrow', '1.0-0');
        }
        if (Math.abs(cost) >= 1000 && small) {
            const currencySymbol = getCurrencySymbol(this.currency, 'narrow', this.locale);
            const str = this.currencyPipe.transform(cost / 1000, this.currency, 'symbol-narrow', '1.0-0');
            const idx = str.indexOf(currencySymbol);
            if (idx >= 0) {
                // TODO which symbol to add? t or k or M
                return str.replace(currencySymbol, 'k' + currencySymbol);
            }
        }
        const format = Math.abs(cost) < 10 ? '1.2-2' : '1.0-0';
        return this.currencyPipe.transform(cost, this.currency, 'symbol-narrow', format);
    }

    getDateStr(data: { labels?: number[] | string[], interval?: 'month' | 'week' | 'day' }, index: number, def: string): string {
        if (!data?.labels) {
            return def;
        }
        try {
            if (data.labels[index] === 0 || data.labels[index] === '0') {
                return this.tr.anslate('today');
            }
            if (typeof data.labels[index] === 'number') {
                const date = DateTime.fromMillis(data.labels[index] as number);
                if (!date.isValid) {
                    return data.labels[index]?.toString();
                }
                if (data.interval === 'week' || data.interval === 'day') {
                    return date.toLocaleString({ month: "short", day: "numeric" });
                    // return date.toFormat(this._isRatherSmall ? 'D. LLL' : 'D. LLLL');
                } else {
                    return date.toFormat(this._isRatherSmall ? 'LLL' : 'LLLL');
                }
            } else {
                return data.labels[index] as string;
            }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (err) {
            return def;
        }
    }
}
