 
import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, EventEmitter, Output, LOCALE_ID, Inject } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ReportService } from 'src/app/modules/report/report.service';
import { Utils } from 'src/app/util/utils';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Roast } from 'src/app/models/Roast';
import { MatTableDataSource } from '@angular/material/table';
import { UserType } from 'src/app/modules/frame/services/user.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { AlertService } from 'src/app/util/alert/alert.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateTime, Settings } from 'luxon';

@Component({
    selector: 'app-cr-germany-berlin',
    templateUrl: './cr-germany-berlin.component.html',
    styleUrls: ['./cr-germany-berlin.component.scss']
})
export class CrGermanyBerlinComponent implements OnInit, OnDestroy {

    constructor(
        private clipboardService: ClipboardService,
        private reportService: ReportService,
        public utils: Utils,
        // used in template
        public tr: TranslatorService,
        private alertService: AlertService,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        this.localeBackup = locale;
        Settings.defaultLocale = 'de';
        this.tax = this.reportService.getCoffeeTax('GER');
    }

    localeBackup: string;

    @ViewChild('roastTable') roastTableElem: ElementRef;

    @Input() currentUser: UserType;
    @Input() endDate = DateTime.now();
    @Input() readOnly = false;

    roastsHaveErrors = false;
    onlyDiscardedNotDestroyed = false;

    private _manualDiscarded = false;
    get manualDiscarded(): boolean { return this._manualDiscarded; }
    @Input() set manualDiscarded(md: boolean) {
        if (this._manualDiscarded !== md) {
            this._manualDiscarded = md;
            this.updateRoastData();
        }
    }

    private _roasts: Roast[];
    get roasts(): Roast[] { return this._roasts; }
    @Input() set roasts(r: Roast[]) {
        this._roasts = r;
        this.updateRoastData();
    }

    @Output() dataChanged = new EventEmitter();

    FIELDSEPARATOR = '\t';
    LINESEPARATOR = '\r\n';
    DECIMALSEPARATOR = '.';
    @Input() set exportSettings(es: { fieldSep: string, decSep: string, lineSep: string }) {
        this.FIELDSEPARATOR = es.fieldSep;
        this.LINESEPARATOR = es.lineSep;
        this.DECIMALSEPARATOR = es.decSep;
    }

    sum_discarded = 0;
    sum_amount = 0;
    sum_end_weight = 0;
    sum_end_weight_discarded = 0;
    nrDiscardedButTaxed = 0;
    nrDiscarded = 0;

    data_roasts: MatTableDataSource<Roast>;
    columnsToDisplay_roasts = ['Number', 'Date', 'Time', 'CoffeesOrBlend', 'Amount', 'End Weight', 'Yield', 'Loss', 'Taxable', 'Tax', 'Exclude Yield'];

    justCopied_r = false;
    copyFailed_r = false;
    creatingPdf = false;

    tax = 0;

    private ngUnsubscribe = new Subject();

    DateTime = DateTime;


    ngOnInit(): void {
        this.tax = this.reportService.getCoffeeTax('Germany');
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        if (this.localeBackup) {
            // .locale(this.localeBackup);
            Settings.defaultLocale = this.localeBackup;
        }
    }

    /**
     * (Re)calculates overall information on all roasts (sum, errors, discarded, ...)
     * Must be called if any parameter changed that affect any of those.
     */
    updateRoastData(): void {
        this.sum_discarded = 0;
        this.sum_end_weight_discarded = 0;
        this.sum_amount = 0;
        this.sum_end_weight = 0;
        this.nrDiscardedButTaxed = 0;
        this.nrDiscarded = 0;
        this.roastsHaveErrors = false;

        this.data_roasts = new MatTableDataSource(this._roasts);
        // need to set the filterPredicate funtion
        this.onlyDiscardedNotDestroyedChanged(undefined, false);

        for (let r = 0; r < this.data_roasts?.filteredData.length; r++) {
            const roast = this.data_roasts.filteredData[r];
            if (!roast) {
                this.roastsHaveErrors = true;
            } else {
                if (roast.discarded) {
                    this.nrDiscarded += 1;
                    if (!this.manualDiscarded || roast.destroyed) {
                        this.sum_discarded += roast.amount || 0;
                        this.sum_end_weight_discarded += roast.end_weight || 0;
                        roast['Bemerkung'] = 'Fehlröstung';
                    } else {
                        this.nrDiscardedButTaxed += 1;
                        if (roast['Bemerkung'] === 'Fehlröstung') {
                            roast['Bemerkung'] = undefined;
                        }
                    }
                }
                this.sum_end_weight += roast.end_weight || 0;
                this.sum_amount += roast.amount || 0;
                if (!this.roastsHaveErrors && (
                    (!roast.coffee && !roast.blend)
                    || typeof roast.amount === 'undefined'
                    || typeof roast.end_weight === 'undefined')) {
                    this.roastsHaveErrors = true;
                }
            }
        }
        if (this.nrDiscarded) {
            // add or remove the Destroyed table column
            // depending on _manualDiscarded flag / checkbox
            const tctdr = this.columnsToDisplay_roasts;
            if (this._manualDiscarded) {
                if (tctdr[tctdr.length - 1] !== 'Destroyed') {
                    tctdr.push('Destroyed');
                }
            } else if (tctdr[tctdr.length - 1] === 'Destroyed') {
                tctdr.pop();
            }
        }
    }

    onlyDiscardedNotDestroyedChanged(change?: MatCheckboxChange, callUpdate = true): void {
        if (change) {
            this.onlyDiscardedNotDestroyed = change.checked;
        }
        if (this.onlyDiscardedNotDestroyed) {
            // need to set the filter string to sth otherwise the filterPredicate
            // funtion will be ignored; need to set the predicate before!
            this.data_roasts.filterPredicate = record =>
                record.discarded && !record.destroyed;
            this.data_roasts.filter = 'home';
        } else {
            this.data_roasts.filterPredicate = () => true;
            this.data_roasts.filter = undefined;
        }
        
        if (callUpdate) {
            // only udpate if not called from updateRoastData itself
            this.updateRoastData();
        }
    }

    destroyedChanged(i?: number): void {
        this.utils.destroyedChanged(() => this.updateRoastData(), () => this.dataChanged.emit(), this.readOnly, this._roasts, i);
    }

    removeDestroyed(i: number): void {
        this.utils.removeDestroyed(i, () => this.updateRoastData(), () => this.dataChanged.emit(), this.readOnly, this._roasts);
        this.dataChanged.emit();
    }

    applyDestroyedToAll(i: number): void {
        this.utils.applyDestroyedToAll(i, () => this.updateRoastData(), () => this.dataChanged.emit(), this.readOnly, this._roasts);
        this.dataChanged.emit();
    }

    cleanStr(str: string): string {
        return str.replace('"', '\'').replace(this.FIELDSEPARATOR, ' ');
    }

    copyRoasts(): void {
        // create internal text representation for copy
        let text = '';
        for (let i = 0; i < this.data_roasts?.filteredData?.length; i++) {
            const roastdata = this.data_roasts.filteredData[i];
            // lfd. Nr.
            text += (i + 1).toString() + this.FIELDSEPARATOR;
            // Datum, Uhrzeit
            if (roastdata.date) {
                text += roastdata.date.toLocaleString(DateTime.DATE_SHORT) + this.FIELDSEPARATOR;
                text += roastdata.date.toLocaleString(DateTime.TIME_SIMPLE) + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
                text += this.FIELDSEPARATOR;
            }
            // Sorte
            if (roastdata.coffee) {
                text += '"' + this.cleanStr(this.reportService.createCoffeeLabel(roastdata.coffee, true, this.FIELDSEPARATOR === ',' ? ' ' : ',', true)) + '"' + this.FIELDSEPARATOR;
            } else if (roastdata.blend) {
                text += '"' + this.cleanStr(this.reportService.createBlendLabel(roastdata.blend, true, this.FIELDSEPARATOR === ',' ? ' ' : ',', ' / ')) + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            // Rohkaffee in kg
            if (!isNaN(roastdata.amount)) {
                let num = (Math.round(roastdata.amount * 1000) / 1000).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            // Röstkaffee in kg
            if (Number.isFinite(roastdata.end_weight)) {
                let num = (Math.round(roastdata.end_weight * 1000) / 1000).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            // Ausbeute in %
            if (Number.isFinite(roastdata.amount) && Number.isFinite(roastdata.end_weight)) {
                let num = (Math.round(roastdata.end_weight / roastdata.amount * 1000) / 1000).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            // Röstverlust in %
            if (Number.isFinite(roastdata.amount) && Number.isFinite(roastdata.end_weight)) {
                let num = (Math.round((roastdata.amount - roastdata.end_weight) / roastdata.amount * 1000) / 1000).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (Number.isFinite(roastdata.end_weight) && (!roastdata.discarded || (this.manualDiscarded && !roastdata.destroyed))) {
                // Zu versteuern sind / kg
                let num = (Math.round(roastdata.end_weight * 1000) / 1000).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                // Steuer 2,19€ / kg
                text += num + this.FIELDSEPARATOR;
                num = (Math.round(roastdata.end_weight * this.tax * 100) / 100).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += '0' + this.FIELDSEPARATOR;
                text += '0' + this.FIELDSEPARATOR;
            }
            if (roastdata.discarded && (!this.manualDiscarded || roastdata.destroyed) && Number.isFinite(roastdata.end_weight)) {
                // Fehlröstung kg
                let num = (Math.round(roastdata.end_weight * 1000) / 1000).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += '0';
            }
            if (i < this.data_roasts?.filteredData.length - 1) {
                text += this.LINESEPARATOR;
            }
        }
        if (this.clipboardService.copyFromContent(text, this.roastTableElem?.nativeElement)) {
            this.justCopied_r = true;
            setTimeout(() => {
                this.justCopied_r = false;
            }, 2000);
        } else {
            this.copyFailed_r = true;
            setTimeout(() => {
                this.copyFailed_r = false;
            }, 2000);
        }
    }

    generateDataArray(): unknown[] {
        const stockData = [];
        for (let d = 0; d < this.data_roasts?.filteredData.length; d++) {
            const row = this.data_roasts.filteredData[d];
            let coffbl = '';
            if (row.coffee) {
                coffbl = this.reportService.createCoffeeLabel(row.coffee, true, '\n', true);
            } else if (row.blend) {
                coffbl = this.reportService.createBlendLabel(row.blend, true, ' ', '\n');
            }
            stockData.push({
                ['Lfd. Nr. ']: d + 1,
                ['Datum']: row.date.toFormat('dd.MM.yyyy'),
                ['Uhrzeit']: row.date.toFormat('HH:mm'),
                ['Sorte']: coffbl,
                ['Rohkaffee (kg)']: { alignment: 'right', text: !isFinite(row.amount) ? '-' : row.amount.toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'kg' },
                ['Röstkaffee (kg)']: { alignment: 'right', text: !isFinite(row.end_weight) ? '-' : row.end_weight.toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'kg' },
                ['Ausbeute (%)']: { alignment: 'right', text: !row.amount || !isFinite(row.end_weight) ? '-' : (Math.round(row.end_weight / row.amount * 1000) / 10.0).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' },
                ['Röstverlust (%)']: { alignment: 'right', text: !row.amount || !isFinite(row.end_weight) ? '-' : (Math.round((row.amount - row.end_weight) / row.amount * 1000) / 10.0).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' },
                ['zu versteuern (kg)']: { alignment: 'right', text: (row.discarded && (!this.manualDiscarded || row.destroyed)) || !isFinite(row.end_weight) ? '-' : row.end_weight.toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'kg' },
                ['Steuer (2,19€/kg)']: { alignment: 'right', text: (row.discarded && (!this.manualDiscarded || row.destroyed)) || !isFinite(row.end_weight) ? '-' : (Math.round(row.end_weight * this.tax * 100) / 100.0).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' },
                ['Fehlröstung']: { alignment: 'right', text: (!row.discarded || (this.manualDiscarded && !row.destroyed)) || !isFinite(row.end_weight) ? '-' : row.end_weight.toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'kg' },
            });
        }
        // sum footer
        const avgYield = this.sum_amount && this.sum_end_weight ? this.sum_end_weight * 100 / this.sum_amount : 0;
        const avgLoss = this.sum_amount && this.sum_end_weight ? (this.sum_amount - this.sum_end_weight) * 100 / this.sum_amount : 0;
        stockData.push({
            ['Lfd. Nr. ']: '',
            ['Datum']: '',
            ['Uhrzeit']: '',
            ['Sorte']: { text: 'Summe', alignment: 'right', bold: true, margin: [0, 20, 0, 0] },
            ['Rohkaffee (kg)']: { text: !isFinite(this.sum_amount) ? '-' : this.sum_amount.toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'kg', bold: true, margin: [0, 20, 0, 0] },
            ['Röstkaffee (kg)']: { alignment: 'right', text: !isFinite(this.sum_end_weight) ? '-' : this.sum_end_weight.toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'kg', bold: true, margin: [0, 20, 0, 0] },
            ['Ausbeute (%)']: { alignment: 'right', text: !isFinite(avgYield) ? '-' : avgYield.toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%', bold: true, margin: [0, 20, 0, 0] },
            ['Röstverlust (%)']: { alignment: 'right', text: !isFinite(avgLoss) ? '-' : avgLoss.toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%', bold: true, margin: [0, 20, 0, 0] },
             
            ['zu versteuern (kg)']: { alignment: 'right', text: ((this.sum_end_weight - this.sum_end_weight_discarded) || 0).toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'kg', bold: true, margin: [0, 20, 0, 0] },
             
            ['Steuer (2,19€/kg)']: { alignment: 'right', text: (((this.sum_end_weight - this.sum_end_weight_discarded) || 0) * this.tax).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€', bold: true, margin: [0, 20, 0, 0] },
             
            ['Fehlröstung']: { alignment: 'right', text: !isFinite(this.sum_end_weight_discarded) ? '-' : this.sum_end_weight_discarded.toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + 'kg', bold: true, margin: [0, 20, 0, 0] },
        });
        return stockData;
    }

    generatePDF(): void {
        if (this.creatingPdf) { return; }

        this.creatingPdf = true;
        const stockData = this.generateDataArray();

        const title = 'Röstprotokoll ' + this.endDate.toFormat('yyyy-MM-dd');
        this.reportService.getPDFFromArray(stockData, title, 'de')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: blob => {
                    this.creatingPdf = false;

                    const filename = title;
                    this.utils.saveBlobToFileSystem(blob, filename + '.pdf');

                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingPdf = false;
                    this.utils.handleError('error creating PDF', error);
                }
            });
    }
}
