import { NgModule, InjectionToken } from '@angular/core';
import { RouterModule, Routes, ActivatedRouteSnapshot, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/util/ui/page-not-found.component';
import { LoginComponent } from 'src/app/modules/frame/login/login.component';
import { LogoutComponent } from 'src/app/modules/frame/login/logout.component';
import { RegisterComponent } from 'src/app/modules/frame/register/register.component';
import { ResetpasswordComponent } from 'src/app/modules/frame/login/resetpassword.component';
import { InvitedComponent } from 'src/app/modules/frame/login/invited.component';
import { EmptyComponent } from 'src/app/util/ui/empty.component';
import { WelcomeComponent } from './modules/frame/welcome.component';
import { LegalFrameComponent } from './modules/frame/legal/legal-frame.component';
import { WizardComponent } from './modules/wizard/wizard.component';
import { TestComponent } from './modules/test/test.component';
import { UserSettingsComponent } from './modules/account/user-settings.component';
import { ReportingComponent } from './modules/report/reporting.component';
import { ContactsComponent } from './modules/contact/contacts.component';
import { StoresComponent } from './modules/store/stores.component';
import { BlendsComponent } from './modules/blend/blends.component';
import { CoffeesComponent } from './modules/coffee/coffees.component';
import { RoastsComponent } from './modules/roast/roasts.component';
import { ImprintComponent } from './modules/frame/legal/imprint.component';
import { PrivacyComponent } from './modules/frame/legal/privacy.component';
import { GenerateErrorComponent } from './util/ui/generate-error.component';
import { CustomsreportsComponent } from './modules/report/customs/customsreports.component';
import { BeansreportsComponent } from './modules/report/beans/beansreports.component';
import { StocksreportsComponent } from './modules/report/stocks/stocksreports.component';
import { RoastreportsComponent } from './modules/report/roasts/roastreports.component';
import { SelectRoastreportsComponent } from './modules/report/roasts/select-roastreports.component';
import { AddExternalComponent } from './modules/external/import/add-external.component';
import { RemindersComponent } from './modules/reminders/reminders.component';
import { SchedulerComponent } from './modules/scheduler/scheduler.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
    {
        path: 'welcome',
        component: WelcomeComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'invited',
        component: InvitedComponent
    },
    {
        path: 'resetPassword',
        component: ResetpasswordComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'legal',
        // loadChildren: () => import('src/app/modules/frame/legal/legal.module').then(m => m.LegalModule)},
        component: LegalFrameComponent,
        children: [
            {
                path: '',
                component: ImprintComponent,
            },
            {
                path: 'privacy',
                component: PrivacyComponent,
            },
        ]
    },
    {
        path: 'wizard',
        component: WizardComponent
    },
    {
        path: 'test',
        component: TestComponent
    },
    {
        path: 'settings',
        component: UserSettingsComponent
    },

    {
        path: 'reports',
        component: ReportingComponent,
        children: [
            {
                path: '',
                redirectTo: 'roasts',
                pathMatch: 'full'
            },
            {
                path: 'roasts',
                component: SelectRoastreportsComponent,
                children: [
                    {
                        path: 'fixed',
                        component: RoastreportsComponent,
                    },
                    {
                        path: 'open',
                        component: RoastreportsComponent,
                    },
                ]
            },
            {
                path: 'transactions',
                component: BeansreportsComponent,
            },
            {
                path: 'stocks',
                component: StocksreportsComponent,
            },
            {
                path: 'customs',
                component: CustomsreportsComponent,
            },
        ]
    },
    {
        path: 'contacts',
        component: ContactsComponent
    },
    {
        path: 'stores',
        component: StoresComponent
    },
    {
        path: 'coffees',
        component: CoffeesComponent,
    },
    {
        path: 'blends',
        component: BlendsComponent
    },
    {
        path: 'roasts',
        component: RoastsComponent
    },
    {
        path: 'reminders',
        component: RemindersComponent
    },
    {
        path: 'add',
        component: AddExternalComponent,
    },
    {
        path: 'schedule',
        component: SchedulerComponent,
    },
    {
        path: 'generateerror',
        component: GenerateErrorComponent
    },
    {
        path: 'externalRedirect',
        resolve: {
            url: externalUrlProvider,
        },
        // We need a component here because we cannot define the route otherwise
        // https://medium.com/@adrianfaciu/using-the-angular-router-to-navigate-to-external-links-15cc585b7b88
        component: EmptyComponent,
    },
    {
        path: '',
        redirectTo: '/welcome',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    preloadingStrategy: PreloadAllModules
})
    ],
    exports: [RouterModule],
    declarations: [],
    providers: [
        {
            provide: externalUrlProvider,
            useValue: (route: ActivatedRouteSnapshot) => {
                const externalUrl = route.paramMap.get('externalUrl');
                if (externalUrl) {
                    // redirect to a given URL
                    window.open(externalUrl, '_self');
                    return;
                }
                let lang = route.paramMap.get('lang');
                if (lang === 'en-GB') {
                    lang = 'gb';
                }
                if (lang) {
                    // redirect to artisan.plus in the given language
                    let pathname = window.location.pathname.substring(3);
                    if (pathname.indexOf(';') >= 0) {
                        pathname = pathname.split(';')[0];
                    }
                    let url: string;
                    const path = route.paramMap.get('path');
                    if (path) {
                        url = window.location.origin + '/' + lang + '/' + path;
                    } else {
                        url = window.location.origin + '/' + lang + pathname;
                    }
                    const query = route.paramMap.get('query');
                    if (query) {
                        url += query;
                    }
                    window.open(url, '_self');
                }
            },
        },
    ],
})

export class AppRoutingModule { }
