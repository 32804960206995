import { Supplier } from './Supplier';
import { StockChange } from './StockChange';
import { Location } from './Location';

export class Purchase extends StockChange {
    order_number ?: string;
    tracking_number ?: string;
    product_id ?: string;
    price ?: number;
    supplier ?: Supplier;
    externalLocation ?: Location;
}

