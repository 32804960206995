import { DateTime } from "luxon";
import { InvitedUserInfo } from "../modules/frame/services/user.service";
import { Account } from "./Account";
import { User } from "./User";

export interface LastTriggered {
    user: User,
    date: DateTime,
}

export interface Condition {
    on_date?: DateTime, // trigger on this date
    interval: number, // e.g. every nth week
    interval_done?: number, // how many days or kgs etc. until the next trigger (negative if expired)
    interval_done_forsort?: number, // how many days until the next trigger (negative if expired); same as interval_sone if no statistics are available for this user (account)
    interval_unit: string, // roasted_amount, roast_hours, batches, days, weeks, months, years, day_of_week, day_of_month, gas_consumed
    start_date?: DateTime, // date from which the intervals are counted
    last_triggered?: LastTriggered[], // date when the last notification has been generated
}

// specifies who of an account's full access users should receive which type of notification
// the first entry of the can specify default: true which applies to all users not listed in subsequent entries
// each entry should specify at least one notification (otherwise don't generate an entry for that user)
interface NotificationInfo {
    default?: boolean; // can only be true for the first entry in the array
    user: InvitedUserInfo;
    not_for_web ?: boolean;
    not_for_artisan ?: boolean;
    for_email ?: boolean;
}

export class Reminder {
    _id ?: string;
    created_at ?: Date;
    updated_at ?: Date;
    internal_hr_id ?: number;
    hr_id ?: string;
    owner ?: Account;
    label: string;
    notes ?: string;
    active ?: boolean;
    deleted ?: boolean;
    expired ?: boolean;
    machine?: string;
    conditions?: Condition[];
    snooze_until ?: DateTime; // date when a notification should be generated for this reminder
    image ?: string;
    notify_users?: NotificationInfo[];
    created_by?: User;
    updated_by?: User;
    // only in web client:
    expiredForSort ?: number;
    expiredPre ?: string;
    expiredNumber ?: string;
    expiredUnit ?: string;
    expired2ForSort ?: number;
    expired2Pre ?: string;
    expired2Number ?: string;
    expired2Unit ?: string;
}

