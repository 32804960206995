import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ReportService } from 'src/app/modules/report/report.service';
import { Utils } from 'src/app/util/utils';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { NGXLogger } from 'ngx-logger';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { BeansreportTableComponent } from './beansreport-table.component';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StockChange } from 'src/app/models/StockChange';
import { Location } from 'src/app/models/Location';
import { BeansReport } from 'src/app/models/BeansReport';
import { Router } from '@angular/router';
import { StandardService } from 'src/app/util/services/standard.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-newbeansreport',
    templateUrl: './newbeansreport.component.html',
    styleUrls: ['./newbeansreport.component.scss']
})
export class NewbeansreportComponent implements OnInit, OnDestroy {

    constructor(
        private reportService: ReportService,
        // used in template
        public utils: Utils,
        private alertService: AlertService,
        public tr: TranslatorService,
        private logger: NGXLogger,
        private userService: UserService,
        private router: Router,
        private standardService: StandardService,
    ) { }

    @Input() currentUser: UserType;
    @Input() lastEndDate: DateTime;
    // format: 2019-002
    @Input() lastReportNumber: string;
    @Input() reportNames: string[];
    @Input() readOnly = false;

    @Output() newReportModeChanged = new EventEmitter<boolean>();
    @Output() newReport = new EventEmitter<BeansReport>();

    /** the "internal" name, e.g. 2019-001 */
    reportName: string;
    /** the user defined label */
    label: string;
    /** true if the user at least once changed that name */
    labelWasTouched = false;

    startDate: DateTime;
    endDate: DateTime;

    ssf: Location[];
    stores: Location[];

    transactions: StockChange[];
    invalidTransactions: StockChange[];

    // columnsToDisplay = ['Number', 'Date', 'Coffees', 'Amount', 'Price', 'Supplier', 'Order Number', 'References'];
    columnsToDisplay = ['Number', 'Date', 'Transaction-ID', 'Coffees', 'Amount', 'References'];
    dataSource: MatTableDataSource<StockChange>;
    // columnsToDisplayInvalids = ['Reason', 'Date', 'Coffees', 'Amount', 'Price', 'Supplier', 'Order Number', 'References'];
    columnsToDisplayInvalids = ['Number', 'InvalidReason', 'Date', 'Transaction-ID', 'Coffees', 'Amount', 'References'];
    dataSourceInvalids: MatTableDataSource<StockChange>;

    showOrganic: 'on' | 'off' | '' = '';
    organicButtonInactive = false;

    selectedTypes: { Purchase: boolean, Sale: boolean, Correction: boolean, Transfer: boolean } = { Purchase: true, Sale: false, Correction: false, Transfer: false };

    private ngUnsubscribe = new Subject();

    @ViewChild('table') table: BeansreportTableComponent;
    @ViewChild('invalidsTable') invalidsTable: BeansreportTableComponent;
    @ViewChild('hiddenElems') hiddenElems: ElementRef;


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }

        this.getAllStores();

        this.selectedTypes.Purchase = true;
        if (this.lastEndDate && this.lastEndDate.isValid) {
            this.startDate = this.lastEndDate.plus({ milliseconds: 1 });
            this.endDate = DateTime.now().minus({ month: 1 }).endOf('month');
            if (this.endDate < this.startDate) {
                this.endDate = DateTime.now().endOf('day');
            }
            this.reportName = this.reportService.createReportName(this.lastReportNumber, this.startDate.year);
            this.label = this.reportService.suggestLabel({ showOrganic: this.showOrganic, from: this.startDate, to: this.endDate }, this.reportName, this.reportNames);

            this.getAllWithin(undefined, ['Purchase']);

        } else {
            this.startDate = DateTime.now().minus({ month: 1 }).startOf('month');
            this.reportService.getFirstTransactionDate(['Purchase'])
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            if (response.result) {
                                this.startDate = DateTime.fromMillis(response.result).startOf('month');
                                this.endDate = this.startDate.endOf('year');
                                this.reportName = this.reportService.createReportName(this.lastReportNumber, this.startDate.year);
                                this.label = this.reportService.suggestLabel({ showOrganic: this.showOrganic, from: this.startDate, to: this.endDate }, this.reportName, this.reportNames);
                                this.getAllWithin(undefined, ['Purchase']);
                            }

                        } else {
                            this.logger.warn('error getting first transaction date');
                            // this.utils.handleError('error retrieving reports', response.error);
                        }
                    },
                    error: error => {
                        this.logger.warn('error getting first transaction date', error);
                        // this.utils.handleError('error retrieving reports', error);
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores = response.result;
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                        if (!this.stores?.length) {
                            this.stores = [];
                        }
                    }
                    this.stores.sort((s1, s2) => s1.hr_id > s2.hr_id ? -1 : s1.hr_id > s2.hr_id ? 1 : 0);
                    const ssforall = this.utils.readShowStockFrom(this.stores, this.currentUser);
                    this.ssf = ssforall === 'all' ? undefined : ssforall;
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                    if (!this.stores?.length) {
                        this.stores = [];
                    }
                }
            });
    }

    // called from the template when the dropdown value changed
    showstockfromChanged($event: { value: Location[] }): void {
        if (!$event.value?.length || $event.value.length === this.stores.length) {
            $event.value = null;
        }
        this.ssf = $event.value;

        if (!this.labelWasTouched) {
            // suggest label based on dates - if user has not changed it before
            this.label = this.reportService.suggestLabel({ showOrganic: this.showOrganic, from: this.startDate, to: this.endDate }, this.reportName, this.reportNames, this.ssf?.length === 1 ? this.ssf[0]?.['label'] : undefined);
        }
        this.getAllWithin();
    }

    showstockfromOpened(opened: boolean): void {
        if (!opened && !this.ssf?.length) {
            // nothing selected, change to null for dropdown and save
            this.ssf = null;
        }
    }

    // potentially suggests a new label and retrieves all items;
    // called from template when start or end date is changed
    datesChanged(): void {
        if (!this.startDate || !this.endDate) {
            this.alertService.error(this.tr.anslate('Invalid date'));
            return;
        }
        this.startDate = this.startDate.startOf('day');
        if (this.endDate < this.startDate) {
            this.endDate = this.startDate.endOf('month');
        }
        this.endDate = this.endDate.endOf('day');
        if (!this.labelWasTouched) {
            // suggest label based on dates - if user has not changed it before
            this.label = this.reportService.suggestLabel({ showOrganic: this.showOrganic, from: this.startDate, to: this.endDate }, this.reportName, this.reportNames, this.ssf?.length === 1 ? this.ssf[0]?.['label'] : undefined);
        }
        this.getAllWithin();
    }

    // retrieves all items for the new state; called from template when the types
    // of items to include (Purchases, ...) has changed
    typesChanged(): void {
        this.getAllWithin();
    }

    // filter alls invalid transactions and recalculates sums
    updateTables(transactionList: StockChange[]): void {
        this.transactions = [];
        this.invalidTransactions = [];
        for (const transaction of transactionList) {
            const reason = this.reportService.isInvalidTransaction(transaction);
            if (!reason) {
                this.transactions.push(transaction);
            } else {
                transaction['invalidReason'] = reason;
                this.invalidTransactions.push(transaction);
            }
        }
        this.columnsToDisplay = this.reportService.getBeansReportColumns(this.transactions);
        this.dataSource = new MatTableDataSource(this.transactions);
        this.dataSourceInvalids = new MatTableDataSource(this.invalidTransactions);
        if (this.table) {
            setTimeout(() => { if (this.table) { this.table.recalc(); } });
        }
        if (this.invalidsTable) {
            setTimeout(() => { if (this.invalidsTable) { this.invalidsTable.recalc(); } });
        }
    }

    // main method to retrieve all items for the current state
    getAllWithin(showOrganic = this.showOrganic, types = this.reportService.getTypes(this.selectedTypes), startDate = this.startDate, endDate = this.endDate, ssf = this.ssf): void {
        if (!this.startDate || !this.endDate) {
            this.alertService.error(this.tr.anslate('Invalid date'));
            return;
        }
        this.reportService.getAllNonRoastsWithin(types, { from: startDate, to: endDate, showOrganic, ssf: ssf?.length ? ssf : undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.updateTables(this.utils.dateifyStockChanges(response.result));
                    } else {
                        this.utils.handleError('error retrieving reports', response.error);
                    }
                    this.showOrganic = showOrganic;
                    this.organicButtonInactive = false;
                },
                error: error => {
                    this.utils.handleError('error retrieving reports', error);
                    this.showOrganic = showOrganic;
                    this.organicButtonInactive = false;
                }
            });
    }

    // /**
    //  * Returns the number of not reconciled transactions.
    //  */
    // getNrOfOpenTransactions(): number {
    //     if (!this.transactions) {
    //         return 0;
    //     }
    //     return this.transactions.reduce((cnt, transaction) => cnt += transaction.reconciled ? 0 : 1, 0);
    // }

    // creates and saves the report
    createReport(): void {
        if (!this.transactions) {
            return;
        }

        // generate a report object with links to the selected transactions
        const newReport = new BeansReport();
        newReport.created = DateTime.now();
        newReport.startDate = this.startDate;
        newReport.endDate = this.endDate;
        newReport.label = this.label;
        newReport.transactions = this.transactions.map(r => r._id) as unknown as StockChange[];
        newReport.number = this.reportName;
        newReport.types = this.reportService.getTypesEnum(this.selectedTypes);
        newReport.certInfoOnCreation = this.reportService.getCertInfo(this.showOrganic);
        newReport.ssf = this.ssf?.length ? this.ssf.map(loc => ({ _id: loc._id })) : undefined;

        this.reportService.addBeansReport(newReport)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success) {
                        this.alertService.success(this.tr.anslate('Successfully added'));
                        this.newReport.emit(this.utils.dateifyBeansReports([response.result])?.[0]);
                        this.newReportModeChanged.emit(false);
                    } else {
                        this.utils.handleError('error adding report', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error adding report', error);
                }
            });
    }

    // cancels report creation; called from template
    cancel(): void {
        this.newReportModeChanged.emit(false);
    }

    // retrieves items after organic switch changed; called from template
    showOrganicChanged(): void {
        if (this.organicButtonInactive) {
            return;
        }
        this.organicButtonInactive = true;
        const nextShowOrganic = this.utils.getNextShowOrganicState(this.showOrganic);
        if (!this.labelWasTouched) {
            this.label = this.reportService.suggestLabel({ showOrganic: nextShowOrganic, from: this.startDate, to: this.endDate }, this.reportName, this.reportNames, this.ssf?.length === 1 ? this.ssf[0]?.['label'] : undefined);
        }
        this.getAllWithin(nextShowOrganic);
    }

    scrollToMissing(): void {
        this.hiddenElems?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
}
