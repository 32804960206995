<div class="scheduler-planner-year-weekdays">
    <span class="scheduler-planner-year-weekday"><!-- placeholder --></span>
    @for (weekday of WEEKDAYS; track weekday) {
    <span class="scheduler-planner-year-weekday">{{weekday}}</span>
    }
</div>

<div class="scheduler-planner-year-month">
    @for (yearData of yearDatas; let colIdx = $index; track colIdx) {
    <div class="scheduler-planner-year-column">
        <mat-grid-list cols="8" gutterSize="1px" class="scheduler-planner-year-grid">
            @for (item of yearData ?? []; let dayIdx = $index; track item?.date) {
            <!-- month label -->
            <!-- replace with @let in Angular 18 -->
            @if (item.date.plus({ days: 10 }); as dateplus) {
            @if (dayIdx === 0) {
            <!-- item.date={{item.date}}, yearData[9].date.startOf('month')={{yearData[9].date.startOf('month')}} -->
            <mat-grid-tile [rowspan]="rowsForMonth(dateplus.startOf('month'), true)">
                @if (DateTime.now(); as curdate) {
                <div class="scheduler-planner-year-month-label"
                    [ngClass]="{'scheduler-planner-year-month-label-thismonth': dateplus.month === curdate.month && dateplus.year === curdate.year}">
                    <div class="scheduler-planner-year-grid-border"
                        (click)="openDate(dateplus, 'm')" (keydown.enter)="openDate(dateplus, 'm'); $event.preventDefault()" role="button" [tabindex]="0">
                    </div>
                    <div>{{utils.getMonthStr(dateplus.month - 1, locale, 'short')}}</div>
                    <div>'{{dateplus.year.toString().substring(2, 4)}}</div>
                </div>
                }
            </mat-grid-tile>
            } @else if (dayIdx > 10 && item.date.localWeekday === 1 && 7 >= item.date.day) {
            <mat-grid-tile [rowspan]="rowsForMonth(item.date.startOf('month'), false)">
                @if (DateTime.now(); as curdate) {
                <div class="scheduler-planner-year-month-label" [ngClass]="{'scheduler-planner-year-month-label-thismonth': dateplus.month === curdate.month && dateplus.year === curdate.year}">
                    <div class="scheduler-planner-year-grid-border"
                        (click)="openDate(dateplus, 'm')" (keydown.enter)="openDate(dateplus, 'm'); $event.preventDefault()" role="button" [tabindex]="0">
                    </div>
                    <div>{{utils.getMonthStr(dateplus.month - 1, locale, 'short')}}</div>
                    <div>'{{dateplus.year.toString().substring(2, 4)}}</div>
                </div>
                }
            </mat-grid-tile>
            }
            }

            <!-- TODO take STARTOFWEEK into account -->
            <mat-grid-tile
                class="scheduler-planner-year-gridtile"
                [matTooltip]="item.amountStr !== '-' ? item.amountStr : ''" matTooltipShowDelay="50"
                [ngStyle]="{
                    'background-color': item.color,
                    'border-top-style': 7 >= item.date.day ? 'dotted' : 'none',
                    'border-left-style': item.date.day === 1 || (item.date.localWeekday === 1 && dayIdx !== 0) ? 'dotted' : 'none',
                    'border-right-style': item.amountStr !== '-' && item.date.localWeekday === 7 ? 'dotted' : 'none',
                    'border-bottom-style': item.amountStr !== '-' && dayIdx >= $count - 7 ? 'dotted' : 'none',
                    'box-shadow': item.amountStr !== '-' && item.date.toISODate() === today ? ('inset 0 0 0px 3px ' + accentColor) : 'none',
                }">
                @if (item.amountStr !== '-') {
                <div class="scheduler-planner-year-grid-border"
                    (click)="openDate(item.date)" (keydown.enter)="openDate(item.date); $event.preventDefault()" role="button" [tabindex]="0">
                </div>
                <span class="scheduler-planner-year-date">
                    {{item.date?.day}}
                </span>
                }
                <!-- {{item.amountStr}} -->
            </mat-grid-tile>
            }
        </mat-grid-list>
    </div>
    }
</div>

<!-- just to get the color -->
<button class="action-button add-button" type="button" mat-raised-button #primarybutton style="display: none;"><mat-icon>done</mat-icon></button>
<button class="action-button edit-button" type="button" mat-raised-button #accentbutton style="display: none;"><mat-icon>done</mat-icon></button>