import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Coffee } from 'src/app/models/Coffee';
import { Location } from 'src/app/models/Location';

export interface ObjectChangedInfo {
    model: string;
    reload?: boolean;
    info?: string | {
        editMode?: number,
        object?: unknown,
        action?: string,
        type?: string,
        coffee?: Partial<Coffee>,
        store?: Location
        amount?: number,
        storeIdx?: number,
        coffeeId?: string,
    };
}

@Injectable({
    providedIn: 'root'
})
export class ObjectChangedService {
    private changedSource = new Subject<ObjectChangedInfo>();
    changed$ = this.changedSource.asObservable();

    objectChanged(obj: ObjectChangedInfo): void {
        setTimeout(() => {
            this.changedSource.next(obj);
        });
    }
}
