import { Account } from './Account';
import { Producer } from './Producer';
import { Certification } from './Certification';
import { Enumerations } from './Enumerations';
import { Tag } from './Tag';
import { User } from './User';
import { Location } from './Location';
import { DateTime } from 'luxon';

export class Stock {
    amount: number;
    location: Location;
    value ?: number;
    fifo_cost ?: number;
    low_limit ?: number;
    // only for Artisan API
    location_id?: string;
    location_label?: string;
    // only for web API
    location_hr_id?: string;
}

export class Coffee {
    _id: string;
    created_at ?: Date;
    updated_at ?: Date;
    created_by ?: User;
    updated_by ?: User;
    deleted ?: boolean;
    hidden ?: boolean;
    internal_hr_id ?: number;
    hr_id ?: string;
    owner ?: Account;
    stock ?: Stock[];
    low_limit ? = -1;
    label: string;
    location ?: Location;
    ref_id ?: string;
    reference ?: string;
    default_unit ?: { name: Enumerations.CoffeeUnits, size: number };
    producer ?: Producer;
    origin_region ?: string;
    origin ?: string;
    regions ?: string[];
    certifications ?: Certification[];
    organic?: boolean; // true iff all certifications are organic - this is not always set!
    tags ?: Tag[];
    refs ?: { count: number, objs?: string[] };
    ICO ?: { origin?: number, exporter?: number, lot?: number };
    crop_date ?: { landed?: number[], picked?: number[] };
    grade ?: string;
    altitude ?: { max?: number, min?: number };
    screen_size ?: { max?: number, min?: number };
    score ?: number;
    species ?: string;
    varietals ?: string[];
    cultivation ?: string;
    harvesting ?: string;
    processing ?: string;
    fermentation ?: string;
    drying ?: string;
    decaffeination ?: string;
    ageing ?: string;
    packaging ?: string;
    density ?: number;
    moisture ?: number;
    water_activity ?: number;
    defects ?: number;
    defects_unit ? = 350;
    flavors ?: string[];
    notes ?: string;
    crop_yield ?: number;
    selection ?: string;
    picture ?: string;
    files ?: string[];
    cupping_notes ?: string;
    average_cost ?: number;
    average_fifo_cost ?: number;
    imported?: { source?: string, id?: string, link?: string, sourceLabel: string, date?: DateTime, useSVG?: boolean };
    // manually added, only for web client
    yearLabel ?: string;
    curStock?: number;
    totalStock?: number;
    totalStockStr?: string;
}

