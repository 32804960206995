<div style="position: relative;">
    <h2 i18n="reminders$$header, list of actions in the reminder section@@Completed Tasks" class="main-heading">Completed Tasks</h2>
    <button [disabled]="editMode >= 0" (click)="addLog()"
        type="button" class="right-centered-button add-button action-button" [ngClass]="{'centered-button': (isLarge$ | async)}" mat-raised-button>
        <mat-icon>add</mat-icon>
    </button>
</div>

@if (readOnly) {
<div class="readonly-spacer"></div>
}

@if (reminderLog?.length === 0 && !loadingLog && !loadingLogInternal && !filter && (!filterStartDate || +filterStartDate === +initialFilterStartDate) && (!filterEndDate || +filterEndDate === +initialFilterEndDate)) {
<mat-hint data-test="hnt_reminderlog" class="hint-left">
    <div i18n="reminders$$hint explaining the concept of the log of completed actions@@Here you can see, and manually document, completed actions such as the routine cleaning of machines." style="margin-bottom: 10px;">
        Here you can see, and manually document, completed actions such as the routine cleaning of machines.
    </div>
</mat-hint>
}

@if (readOnly) {
<div class="readonly-spacer"></div>
}

<!-- <div *ngIf="machines?.length > 1" class="filter-row"> -->
@if ((reminderLog?.length > 4) || filter || filterStartDate || filterEndDate || loadingLog || loadingLogInternal) {
<div class="filter-row">
    <mat-form-field class="filter-text-input">
        <input [(ngModel)]="filter"
            [disabled]="editNewEntry >= 0 || editMode >= 0" matInput
            (input)="filterInput()"
            (blur)="filterEntries({ filter: $event.target['value'] }, false, false, true)"
            [matAutocomplete]="autoCompleteFilter"
            placeholder="Filter" i18n-placeholder="generic$$filter to limit the display of a list, placeholder@@Filter"
            class="filter-input" type="text" name="filter">
        <mat-autocomplete #autoCompleteFilter="matAutocomplete" panelWidth="300px"
            (optionSelected)="filterEntries({ filter: $event?.option?.value })">
            @for (machine of filteredMachines; track machine?.label) {
            <mat-option [value]="machine.label">{{machine.label}}</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
    <div class="nobreak">
        <mat-form-field class="date-input-ff1">
            <mat-label i18n="reports$$start date@@start date">From</mat-label>
            <input matInput data-test="npt_startDate" name="startDate"
                [(ngModel)]="filterStartDate" (dateChange)="filterEntries({ filterStartDate: $event?.value })"
                [disabled]="editNewEntry >= 0 || editMode >= 0"
                placeholder="From" i18n-placeholder="reports$$start date@@start date"
                [matDatepicker]="myDatepicker">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="date-input-ff2">
            <mat-label i18n="reports$$end date@@end date">To</mat-label>
            <input [min]="filterStartDate" matInput data-test="npt_endDate" name="endDate"
                [(ngModel)]="filterEndDate" (dateChange)="filterEntries({ filterEndDate: $event?.value })"
                [disabled]="editNewEntry >= 0 || editMode >= 0"
                placeholder="To" i18n-placeholder="reports$$end date@@end date"
                [matDatepicker]="myDatepicker2">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker2></mat-datepicker>
        </mat-form-field>
        @if (filterStartDate || filterEndDate) {
        <button mat-icon-button class="primary-element" (click)="cancelFilterDates()">
            <mat-icon class="filterdate-close">close</mat-icon>
        </button>
        }
    </div>
    <mat-form-field data-test="slct_export" style="width: 100px; margin: 0 10px 0 30px">
        <mat-select [disabled]="loadingLog || isNew >= 0 || !reminderLog?.length"
            placeholder="Export" matInput name="export" panelWidth="null"
            i18n-placeholder="generic$$button, export@@Export"
            [(value)]="exportFormat"
            (selectionChange)="export($event.value)">
            <!-- <mat-option></mat-option> -->
            <mat-option value="csv">CSV</mat-option>
            <mat-option value="clipboardCSV" i18n="generic$$Clipboard@@Clipboard">Clipboard</mat-option>
            <mat-option value="pdf">PDF</mat-option>
        </mat-select>
    </mat-form-field>
    @if (justCopied) {
    <mat-icon class="copied copy-icon done-icon">done</mat-icon>
    }
    @if (copyFailed) {
    <mat-icon class="copyFailed copy-icon">error_outline</mat-icon>
    }
</div>
}

@if (!readOnly && (reminderLog?.length === 0) && !(filter || filterStartDate || filterEndDate || loadingLog || loadingLogInternal)) {
<div>
    <p class="main-note" i18n="generic$$no items found, hint at how to add one@@nothing to show plus hint, add with button">Currently, there is nothing to show. Add using the button at the top!</p>
</div>
}
@if ((reminderLog?.length === 0 && !loadingLog && !loadingLogInternal && (filter || filterStartDate || filterEndDate))) {
<div>
    <p class="main-note" i18n="generic$$no matches found using the given filter@@no match for filter">No matches found</p>
</div>
}
@if (loadingLog && !dontShowSpinner) {
<mat-spinner class="main-note" diameter="20"></mat-spinner>
}


<div class="main-table-wrapper">
    @if (datasource.data.length) {
    <table mat-table [dataSource]="datasource"
        matSort (matSortChange)="sortData()" matSortActive="done_date" matSortDirection="desc"
        class="wide-table mat-elevation-z8" style="width: 100%;">
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr [@rowsAnimation]="deleted[r]" mat-row *matRowDef="let r=index; let entries; columns: columnsToDisplay" class="report-stock-row"></tr>
        <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="generic$$Title@@Title">Title</th>
            <td mat-cell *matCellDef="let entry; let i=index">
                @if (editMode !== i) {
                {{entry.label}}
                } @else {
                <mat-form-field>
                    <input required matInput autofocus data-test="npt_label"
                        placeholder="Title" i18n-placeholder="generic$sort order label@@Title"
                        [(ngModel)]="entry.label" type="text" name="tasklabel">
                </mat-form-field>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="generic$$arbitrary notes@@Notes">Notes</th>
            <td mat-cell *matCellDef="let entry; let i=index">
                @if (editMode !== i) {
                {{entry.notes}}
                } @else {
                <mat-form-field>
                    <input matInput autofocus data-test="npt_label"
                        i18n-placeholder="generic$arbitrary notes@@Notes" placeholder="Notes"
                        [(ngModel)]="entry.notes" type="text" name="notes">
                </mat-form-field>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="machine">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="roasts$$machine@@Machine">Machine</th>
            <td mat-cell *matCellDef="let entry; let i=index">
                @if (editMode !== i) {
                {{entry.machine}}
                } @else {
                <mat-form-field>
                    <input [(ngModel)]="entry.machine"
                        i18n-placeholder="roasts$machine@@Machine" placeholder="Machine"
                        (input)="changeMachineFilter($event.target['value'])" (focus)="changeMachineFilter($event.target['value'])"
                        matInput type="text" id="machine" name="machine"
                        autocomplete="off" [matAutocomplete]="autoCompleteMachine">
                    <mat-autocomplete #autoCompleteMachine="matAutocomplete">
                        @for (machine of filteredMachinesForEdit; track machine?.label) {
                        <mat-option [value]="machine.label">{{machine.label}}</mat-option>
                        }
                    </mat-autocomplete>
                    <!-- <mat-select name="machine" [(ngModel)]="entry.machine">
              <mat-option></mat-option>
              <mat-option *ngFor="let machine of machines" [value]=machine.label>{{machine.label}}</mat-option>
            </mat-select> -->
                </mat-form-field>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="reminders$$Reminder@@Reminder">Reminder</th>
            <td mat-cell *matCellDef="let entry; let i=index">
                @if (entry.source?.hr_id) {
                <a [routerLink]="['/reminders', {id: entry.source.hr_id}]" class="cursor-pointer">{{entry.source.label}}</a>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="generic$$created by user nickname@@created by">by {{cNickname}}</th>
            <td mat-cell *matCellDef="let entry; let i=index">
                {{entry.done_user?.nickname ?? ''}}
            </td>
        </ng-container>
        <ng-container matColumnDef="done_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="generic$$date placeholder or header@@Date">Date</th>
            <td mat-cell *matCellDef="let entry; let i=index">
                @if (editMode !== i) {
                {{entry.done_date.toLocaleString(DateTime.DATE_MED)}}
                } @else {
                <mat-form-field class="date-input">
                    <input required matInput data-test="npt_done_date" name="done_date"
                        [(ngModel)]="entry.done_date" [matDatepicker]="myDatepicker">
                    <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker></mat-datepicker>
                </mat-form-field>
                }
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let entry; let i=index">
                @if (editMode !== i) {
                <div style="white-space: nowrap;">
                    @if (!readOnly) {
                    <button mat-icon-button type="button" class="tertiary-element" (click)="deleteEntry(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button type="button" class="primary-element"
                        (click)="editEntry(i)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    }
                </div>
                } @else {
                <button mat-icon-button type="button" class="primary-element"
                    (click)="cancelEdit(i)">
                    <mat-icon>close</mat-icon>
                </button>
                <button mat-icon-button type="submit" class="primary-element"
                    (click)="saveEntry(i)">
                    <mat-icon>done</mat-icon>
                </button>
                }
            </td>
        </ng-container>
    </table>
    }
</div>