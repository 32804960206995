import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AcaiaConstants } from './AcaiaConstants';
import { Queue } from './Queue';
import { BleQueue } from './bleQueue';

@Injectable({
    providedIn: 'root'
})
export class BleAcaia {

    // collects names of scales using the same characteristic for read and write
    oldVersionNames = [];

    // the browser's Bluetooth discovery dialog will list those devices if found
    nameFilters = [
        { namePrefix: AcaiaConstants.DEVICE_NAME_PEARL2021 },
        { namePrefix: AcaiaConstants.DEVICE_NAME_LUNAR },
        { namePrefix: AcaiaConstants.DEVICE_NAME_LUNAR2021 },
        { namePrefix: AcaiaConstants.DEVICE_NAME_PEARL },
        { namePrefix: AcaiaConstants.DEVICE_NAME_PEARLS },
        { namePrefix: AcaiaConstants.DEVICE_NAME_PYXIS },
    ];

    // used to parse messages from the scale
    private protocolParseStep = AcaiaConstants.E_PRS_CHECKHEADER1;
    private protocolParseBuf = [];
    private protocolParseCMD = 0;
    private protocolParseDataIndex = 0;
    private protocolParseDataLen = 0;
    private protocolParseCRC = [];

    // BLE device
    private device: BluetoothDevice;
    private service: BluetoothRemoteGATTService;
    private writeCharacteristic: BluetoothRemoteGATTCharacteristic;

    // flags
    private connected = false;
    private connectedCalled = false;
    private notificationConfSent = false;
    private idSent = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private callDoConnect: any[];
    private receiving = false;

    // stores last weight
    private weight: number;

    // collects messages from the scale
    private queue: Queue<ArrayBuffer>;
    private packet: number[];

    // holds msgType on messages split in header && payload
    private msgType: number;

    // store status of scale
    private firmware: string;
    private unit = 2; // 1: kg, 2: g, 5: ounce

    // callbacks specified by the user of this service
    private dataCallback: (weight: number) => void;
    private disconnectedCallback: () => void;
    private connectingCallback: () => void;
    private connectedCallback: () => void;
    private errCallback: (err: string) => void;

    // periodically send heartbeat to the scale
    private heartbeat: NodeJS.Timeout;

    // timers used to resend messages if not received or sth goes wrong
    private sendIdTimer: NodeJS.Timeout;
    private disconnecttimer: NodeJS.Timeout;
    private confNotificationTimer: NodeJS.Timeout;

    // queue to sequentially send messages to the scale
    bleQueue = new BleQueue();
    bleOps: {
        writeValue: (arg0: DataView) => Promise<void | Error>;
        startNotifications: () => Promise<void | BluetoothRemoteGATTCharacteristic>;
        stopNotifications: () => Promise<void | BluetoothRemoteGATTCharacteristic>;
    };


    constructor(private logger: NGXLogger) {
        // these use the _LEGACY service IDs and write=read characteristic
        this.oldVersionNames.push(AcaiaConstants.DEVICE_NAME_PEARL);
        this.oldVersionNames.push(AcaiaConstants.DEVICE_NAME_LUNAR);
        this.oldVersionNames.push(AcaiaConstants.DEVICE_NAME_PYXIS);
    }

    /**
     * Main method of this service. Asks browser+user to select a device and egisters callbacks.
     * @param reconnect true if it should reconnect to an already connected device
     * @param dataChanged called if weight changes
     * @param connecting called if user chose a device
     * @param connected called if connection is done
     * @param disconnected called if device is disconnected
     * @param error called on any error
     */
    async connectAcaia(
        reconnect = false,
        dataChanged: (weight: number) => void,
        connecting: () => void,
        connected: () => void,
        disconnected: () => void,
        error: (err: string) => void,
    ): Promise<void> {
        // first disconnect any existing connection
        this.callDoConnect = undefined;
        if (this.heartbeat) {
            clearInterval(this.heartbeat)
        }
        if (!this.device || !this.device.gatt.connected) {
            // no current connection, directly call doConnect
            await this.doConnect(reconnect, dataChanged, connecting, connected, disconnected, error);
            // the call to doConnect->requestDevice does not (a)wait
            // if (typeof this.connectedCallback === 'function') {
            //     this.connectedCallback();
            // }
        } else {
            // have a connection
            if (this.receiving) {
                // already receive data, ok
                // speed up notifications
                await this.confNotifications(false /* first */, false /* sleep */);
                if (!this.connectedCalled && typeof connected === 'function') {
                    this.dataCallback = dataChanged;
                    this.disconnectedCallback = disconnected;
                    this.connectedCallback = connected;
                    this.connectingCallback = connecting;
                    this.errCallback = error;
                    this.connectedCallback();
                }
            } else {
                // no data yet, disconnect and try to reconnect
                if (typeof this.disconnectedCallback === 'function') {
                    // add the doConnect to the existing callback which is calld after onDisconnect
                    const origDisconnectedCallback = this.disconnectedCallback;
                    this.disconnectedCallback = async () => {
                        origDisconnectedCallback();
                        await this.doConnect(reconnect, dataChanged, connecting, connected, disconnected, error);
                    }
                } else {
                    // let onDisconnect call the doConnect
                    this.callDoConnect = [reconnect, dataChanged, connecting, connected, disconnected, error];
                }
                await this.disconnect();
            }
        }
    }

    private errCallbackInter(err: string): void {
        if (this.device && this.device?.gatt?.connected) {
            this.errCallback(err);
        } else {
            this.logger.debug(`not showing error: ${err}`);
        }
    }

    // helper that actually calls startDiscovery (or connect if reconnect is true)
    private async doConnect(
        reconnect = false,
        dataChanged: (weight: number) => void,
        connecting: () => void,
        connected: () => void,
        disconnected: () => void,
        error: (err: string) => void,
    ): Promise<void> {
        this.dataCallback = dataChanged;
        this.disconnectedCallback = disconnected;
        this.connectedCallback = connected;
        this.connectingCallback = connecting;
        this.errCallback = error;

        if (reconnect) {
            await this.connect();
        } else {
            this.startDiscovery();
        }
    }

    // shows the browser's dialog which lists all available matching devices
    private async startDiscovery(): Promise<void> {
        // does not list any devices:
        // bluetooth.requestDevice({filters: [{services: [SERVICE_UUID]}]})

        try {
            const device = await navigator.bluetooth.requestDevice({
                filters: this.nameFilters,
                optionalServices: [AcaiaConstants.SERVICE_UUID, AcaiaConstants.SERVICE_UUID_LEGACY],
            });
            await this.deviceAdded(device);
        } catch (err) {
            this.logger.debug('error searching for devices: ' + err);
            if (!err.toString().includes('User cancelled') && typeof this.errCallback === 'function') {
                this.errCallback('error searching for devices - please reload')
            }
        }
    }

    // called when the user wants to connect to a device
    private async deviceAdded(device: BluetoothDevice): Promise<void> {
        this.device = device;
        device.addEventListener('gattserverdisconnected', this.onDisconnected.bind(this));

        if (typeof this.connectingCallback === 'function') {
            this.connectingCallback();
        }

        await this.connect();
    }

    // connects to this.device, retrieves the service and characteristic(s)
    // and listenes to incoming data
    private async connect(): Promise<void> {
        if (this.connected && this.device?.gatt?.connected) {
            // should not happen; maybe user wants to connect to a new device, disconnect first
            this.device.removeEventListener('gattserverdisconnected', this.onDisconnected.bind(this));
            await this.disconnect();
            return;
        } else if (!this.device) {
            this.logger.debug('error connecting - no device selected');
            if (typeof this.errCallback === 'function') {
                this.errCallback('error connecting: no device selected - please reload')
            }
            return;
        }

        // create buffer for incoming data
        this.queue = new Queue<ArrayBuffer>(async (payload: ArrayBuffer) => {
            this.addBuffer(payload);
            // for some scles / versions, the packet header is split from the content
            // need to read in two times and recompose the message
            if (this.packet.length <= 3) {
                return;
            }

            const weight = await this.processData(this.packet);
            this.packet = undefined;

            if (typeof this.dataCallback === 'function' && weight != null) {
                // make sure that the user of this service knows we are connected
                if (!this.connectedCalled) {
                    if (typeof this.connectedCallback === 'function') {
                        this.connectedCallback();
                    }
                    this.connectedCalled = true;
                }
                this.dataCallback(Math.round(weight * 10) / 10);
            }
        });

        let serviceUUID = AcaiaConstants.SERVICE_UUID;
        let char = AcaiaConstants.CHAR_UUID;
        let oldVersion = false;
        for (const name of this.oldVersionNames) {
            if (this.device.name.toUpperCase().indexOf(name.toUpperCase()) >= 0) {
                oldVersion = true;
                serviceUUID = AcaiaConstants.SERVICE_UUID_LEGACY;
                char = AcaiaConstants.CHAR_UUID_LEGACY;
                break;
            }
        }

        try {
            // connect device
            await this.device.gatt.connect();
            this.logger.trace('connected to GATT');
            try {
                // get service
                const service = await this.device.gatt.getPrimaryService(serviceUUID);
                this.service = service;
                this.logger.debug('got primary service');
                if (!oldVersion) {
                    try {
                        // get characteristic for writing
                        const writecharacteristic = await this.service.getCharacteristic(AcaiaConstants.CHAR_UUID_WRITE);
                        this.writeCharacteristic = writecharacteristic;
                        this.logger.debug('Got write characteristic ...');
                    } catch (err) {
                        this.logger.debug(`error getting write characteristic: ${err}`);
                        if (typeof this.errCallback === 'function') {
                            this.errCallback('error getting write characteristic - please reload if it does not work')
                        }
                        // lets try anyway ...
                        // this.disconnect();
                        return null;
                    }
                }
                try {
                    // get characteristic for reading
                    const characteristic = await this.service.getCharacteristic(char);
                    if (oldVersion) {
                        // read and write charcateristic are the same
                        this.writeCharacteristic = characteristic;
                    }
                    this.logger.debug('Got characteristic ... starting notifications...');
                    // use a queue for sequential reading and writing
                    this.bleOps = this.bleQueue.useBLECharacteristic(characteristic, this.writeCharacteristic, this.characteristicValueChanged.bind(this), this.errCallbackInter.bind(this));
                    try {
                        // registers listener and starts notifications
                        await this.bleOps.startNotifications();
                        // start heartbeat
                        this.heartbeat = setInterval(this.sendHeartbeat.bind(this), 5000);
                    } catch (err) {
                        this.logger.debug(`error starting notifications: ${err}`);
                        if (typeof this.errCallback === 'function') {
                            this.errCallback('error starting notifications - please reload')
                        }
                        await this.disconnect();
                        return null;
                    }
                } catch (err) {
                    this.logger.debug(`err getting characteristic: ${err}`);
                    if (typeof this.errCallback === 'function') {
                        this.errCallback('error getting characteristic - please reload')
                    }
                    await this.disconnect();
                    return null;
                }
            } catch (err) {
                this.logger.debug(`error getting primary service: ${err}`);
                if (typeof this.errCallback === 'function') {
                    this.errCallback('error getting primary service - please reload')
                }
                await this.disconnect();
                return null;
            }
        } catch (err) {
            this.logger.debug(`error connecting: ${err}`);
            if (typeof this.errCallback === 'function') {
                this.errCallback('error connecting - please reload')
            }
            return null;
        }
    }

    // returns weight data
    private async processData(data: number[]): Promise<number> {
        await this.acaiaProtocolParser(data);
        return this.weight;
    }

    // clears all parser related variables
    private resetProtocolParser(): void {
        this.protocolParseStep = AcaiaConstants.E_PRS_CHECKHEADER1;
        this.protocolParseBuf = [];
        this.protocolParseCRC = [];
        this.protocolParseCMD = 0;
        this.protocolParseDataLen = 0;
        this.protocolParseDataIndex = 0;
    }

    // interprets the incoming message from the scale; calls parseScaleData
    private async acaiaProtocolParser(dataIn: number[]): Promise<void> {
        for (const c_in of dataIn) {
            if (this.protocolParseStep === AcaiaConstants.E_PRS_CHECKHEADER1) {
                if (c_in === AcaiaConstants.HEADER1)
                    this.protocolParseStep = AcaiaConstants.E_PRS_CHECKHEADER2;
            } else if (this.protocolParseStep === AcaiaConstants.E_PRS_CHECKHEADER2)
                if (c_in === AcaiaConstants.HEADER2)
                    this.protocolParseStep = AcaiaConstants.E_PRS_CMDID;
                else
                    this.resetProtocolParser();
            else if (this.protocolParseStep === AcaiaConstants.E_PRS_CMDID) {
                this.protocolParseCMD = c_in;
                // In these commands the data len is determined by the next byte, so assign 255
                if (this.protocolParseCMD === AcaiaConstants.NEW_CMD_SYSTEM_SA
                    || this.protocolParseCMD === AcaiaConstants.NEW_CMD_INFO_A
                    || this.protocolParseCMD === AcaiaConstants.NEW_CMD_STATUS_A
                    || this.protocolParseCMD === AcaiaConstants.NEW_CMD_EVENT_SA) {
                    this.protocolParseDataLen = 255;
                }
                this.protocolParseStep = AcaiaConstants.E_PRS_CMDDATA
            } else if (this.protocolParseStep === AcaiaConstants.E_PRS_CMDDATA) {
                if (this.protocolParseDataIndex === 0 && this.protocolParseDataLen === 255)
                    this.protocolParseDataLen = c_in
                this.protocolParseBuf.push(c_in);
                this.protocolParseDataIndex += 1;

                if (this.protocolParseDataIndex === this.protocolParseDataLen)
                    this.protocolParseStep = AcaiaConstants.E_PRS_CHECKSUM1
                if (this.protocolParseDataIndex > 20)
                    this.resetProtocolParser();
            } else if (this.protocolParseStep === AcaiaConstants.E_PRS_CHECKSUM1) {
                this.protocolParseCRC.push(c_in);
                this.protocolParseStep = AcaiaConstants.E_PRS_CHECKSUM2
            } else if (this.protocolParseStep === AcaiaConstants.E_PRS_CHECKSUM2) {
                this.protocolParseCRC.push(c_in);
                const cal_crc = this.crc(this.protocolParseBuf);
                if (cal_crc[0] === this.protocolParseCRC[0] && cal_crc[1] === this.protocolParseCRC[1]) {
                    this.msgType = this.protocolParseCMD;
                    // copy the payload before resetting the parser data
                    const data = this.protocolParseBuf.slice();
                    // reset here since the buffer would accumulate if sth goes wrong in parseScaleData
                    this.resetProtocolParser();
                    // When protocol parsing success, call original data parser
                    await this.parseScaleData(this.msgType, data);
                    this.msgType = undefined; // message consumed completely
                } else {
                    this.resetProtocolParser();
                }
            }
        }
    }

    // return a bytearray of len 2 containing the even && odd CRCs over the given payload
    private crc(payload: number[]): number[] {
        let cksum1 = 0;
        let cksum2 = 0;
        for (let i = 0; i < payload.length; i++) {
            if ((i % 2) === 0) {
                cksum1 = (cksum1 + payload[i]) & 0xFF;
            } else {
                cksum2 = (cksum2 + payload[i]) & 0xFF;
            }
        }
        return [cksum1, cksum2];
    }

    private async parseScaleData(msgType: number, data: number[]): Promise<void> {
        if (msgType === AcaiaConstants.MSG_INFO) {
            if (!this.idSent) {
                this.parseInfo(data);
                await this.sendId();
            }
        } else if (msgType === AcaiaConstants.MSG_STATUS) {
            if (this.sendIdTimer) {
                clearTimeout(this.sendIdTimer);
                this.sendIdTimer = undefined;
            }
            if (!this.notificationConfSent) {
                this.parseStatus(data);
                await this.confNotifications(true /* first */);
            }
        } else if (msgType === AcaiaConstants.MSG_EVENT)
            await this.parseScaleEvents(data);
    }

    // parses the AcaiaConstants.MSG_INFO data
    private parseInfo(data: number[]): void {
        this.logger.debug('parseInfo');
        if (data.length > 4) {
            this.firmware = `${data[2]}, ${data[3]}, ${data[4]}`;
            this.logger.debug(`firmware: ${this.firmware}`);
        }
    }

    // sfter receiving a MSG_INFO, sends an ID to trigger MSG_STATUS
    private async sendId(): Promise<void> {
        // resend ID every second until the MSG_STATUS has arrived
        this.sendIdTimer = setTimeout(async () => {
            await this.sendId();
        }, 1000);
        await this.sendMessage(AcaiaConstants.MSG_IDENTIFY, [0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d, 0x2d]);
        this.idSent = true;
    }

    // parses MSG_STATUS data
    private parseStatus(payload: number[]): void {
        this.logger.debug('parseStatus');
        // // battery level(7 bits of first byte) + TIMER_START(1bit)
        // if (payload && payload.length > 0) {
        //     this.battery = payload[0] & ~(1 << 7);
        //     this.logger.debug('battery: ', this.battery);
        // }
        // console.log('bat', '{}%'.format(this.battery))
        // unit(7 bits of second byte) + CD_START(1bit)
        if (payload?.length > 2) {
            this.unit = payload[2] & ~(1 << 7);
            this.logger.debug('unit: ', this.unit);
        }
        // mode(7 bits of third byte) + tare(1bit)
        // sleep(4th byte), 0: off, 1: 5sec, 2: 10sec, 3: 20sec, 4: 30sec, 5: 60sec
        // key disabled(5th byte), touch key setting 0: off, 1: On
        // sound(6th byte), beep setting 0 : off 1: on
        // resolution(7th byte), 0 : default, 1 : high
        // max weight(8th byte)
        // if (payload && payload.length > 7) {
        //     this.max_weight = (payload[7] + 1) * 1000;
        //     this.logger.debug('max_weight: ', this.max_weight);
        // }
    }

    // parses MSG_EVENT data
    private async parseScaleEvents(payload: number[]): Promise<void> {
        if (payload?.length) {
            const pos = await this.parseScaleEvent(payload);
            if (pos > -1)
                await this.parseScaleEvents(payload.slice(pos + 1));
        }
    }

    // parses MSG_EVENT data; returns length of consumed data or - 1 on error
    private async parseScaleEvent(payload: number[]): Promise<number> {
        if (payload?.length) {
            const ev = payload[1];
            payload = payload.slice(2);
            let val = -1;
            if (ev === AcaiaConstants.EVENT_WEIGHT) {
                if (this.confNotificationTimer) {
                    clearTimeout(this.confNotificationTimer);
                    this.confNotificationTimer = undefined;
                    // slow down; only send once
                    await this.confNotifications(false /* first */);
                }
                if (!this.connectedCalled && typeof this.connectedCallback === 'function') {
                    this.connectedCallback();
                    this.connectedCalled = true;
                }
                val = this.parseWeightEvent(payload);
            }
            // else if (ev === AcaiaConstants.EVENT_BATTERY)
            //     val = this.parseBatteryEvent(payload);
            // else if (ev === AcaiaConstants.EVENT_TIMER)
            //     val = this.parseTimerEvent(payload);
            // else if (ev === AcaiaConstants.EVENT_ACK)
            //     val = this.parseAckEvent(payload);
            // else if (ev === AcaiaConstants.EVENT_KEY)
            //     val = this.parseKeyEvent(payload);
            else
                return -1;
            if (val < 0)
                return -1;
            return val + 1;
        }
        return -1;
    }

    // parses EVENT_WEIGHT data; returns length of consumed data or - 1 on error
    private parseWeightEvent(payload: number[]): number {
        if (payload.length < AcaiaConstants.EVENT_WEIGHT_LEN)
            return -1;
        // first 4 bytes encode the weight as unsigned long
        let value = ((payload[3] & 0xff) << 24) + ((payload[2] & 0xff) << 16) + ((payload[1] & 0xff) << 8) + (payload[0] & 0xff);

        const unit = payload[4];

        if (unit === 1) {
            value /= 10;
        } else if (unit === 2) {
            value /= 100;
        } else if (unit === 3) {
            value /= 1000;
        } else if (unit === 4) {
            value /= 10000;
        }

        // convert received weight data to g
        if (this.unit === 1) // kg
            value = value * 1000;
        else if (this.unit === 5) // oz
            value = value * 28.3495;

        // stable = (payload[5] & 0x01) !== 0x01

        // if (2nd bit of payload[5] is set, the reading is negative)
        if ((payload[5] & 0x02) === 0x02)
            value *= -1;

        // if (value is fresh && reading is stable)
        if (value !== this.weight) // && stable)
            this.weight = value;
        //            logger.debug('new weight: %s', this.weight)

        return AcaiaConstants.EVENT_WEIGHT_LEN;
    }

    // sends a message to the this.writeCharacteristic
    private async sendMessage(tp: number, payload: number[], log = true): Promise<void> {
        if (log) this.logger.debug(`... sending ${tp} - ${payload.join(', ')}`);
        const msg = this.encode(tp, payload);
        if (this.writeCharacteristic && this.bleOps.writeValue 
            && msg && this.device && this.device.gatt.connected) {
            try {
                await this.bleOps.writeValue(new DataView(msg));
                if (log) this.logger.debug(`successfully wrote ${new Uint8Array(msg).join(', ')} `);
            } catch (err) {
                this.logger.debug(`error writing - ${err}`);
                if (typeof this.errCallback === 'function') {
                    this.errCallback('error writing message - please reload')
                }
            }
        } else {
            this.logger.trace('could not send message', this.writeCharacteristic, this.device, msg);
            if (typeof this.errCallback === 'function') {
                this.errCallback('error writing message - please reload')
            }
        }
    }

    private encode(msgType: number, payload: number[]): ArrayBuffer {
        const buf = new ArrayBuffer(5 + payload.length);
        const bytes = new Uint8Array(buf);
        bytes[0] = AcaiaConstants.HEADER1;
        bytes[1] = AcaiaConstants.HEADER2;
        bytes[2] = msgType;
        let cksum1 = 0;
        let cksum2 = 0;

        for (let i = 0; i < payload.length; i++) {
            const val = payload[i] & 0xff;
            bytes[3 + i] = val;
            if (i % 2 === 0) {
                cksum1 += val;
            } else {
                cksum2 += val;
            }
        }

        bytes[payload.length + 3] = (cksum1 & 0xFF);
        bytes[payload.length + 4] = (cksum2 & 0xFF);

        return buf;
    }

    // should be sent every 3 - 5sec for certain models
    private async sendHeartbeat(): Promise<void> {
        await this.sendMessage(AcaiaConstants.MSG_SYSTEM, [0x02, 0x00], false);
    }

    private async sendStop(): Promise<void> {
        await this.sendMessage(AcaiaConstants.MSG_SYSTEM, [0x00, 0x00]);
    }

    // async sendTare(): Promise<void> {
    //     await this.doSendMessage(AcaiaConstants.MSG_TARE, [0x00]);
    // }

    // async sendTimerCommand(cmd): Promise<void> {
    //     await this.doSendMessage(AcaiaConstants.MSG_TIMER, [0x00] + cmd);
    // }

    // configure notifications
    private async confNotifications(first = false, sleep = false): Promise<void> {
        this.logger.debug('confNotifications');
        if (first) {
            // resend ID every second or so until the weight events have arrived
            // only for the first call, the others are not so important
            this.confNotificationTimer = setTimeout(async () => {
                await this.confNotifications(first, sleep);
            }, 750);
        }
        await this.sendEvent(
            [ // pairs of key / setting
                0,  // weight id
                first ? 0 : (sleep ? 10 : 5),  // 0, 1, 3, 5, 7, 15, 31, 63, 127  // weight argument(speed of notifications in 1 / 10 sec)
                // 5 or 7 seems to be good values for this app in Artisan
                //                    1,   // battery id
                //                    255, #2,  // battery argument(if (0 : fast, 1 : slow))
                //                    2,  // timer id
                //                    255, #5,  // timer argument
                //                    3,  // key(not used)
                //                    255, #4   // setting(not used)
            ]
        );
        this.notificationConfSent = true;
    }

    private async sendEvent(payload: number[]): Promise<void> {
        await this.sendMessage(AcaiaConstants.MSG_EVENT, [payload.length + 1, ...payload]);
    }

    // private parseBatteryEvent(payload: number[]): number {
    //     //        logger.debug('parseBatteryEvent(_)')
    //     if ((payload.length < AcaiaConstants.EVENT_BATTERY_LEN))
    //         return -1;
    //     const b = payload[0]
    //     if (0 <= b && b <= 100) {
    //         this.battery = payload[0];
    //         // print('bat', '{}%'.format(this.battery))
    //         this.logger.debug(`battery: ${this.battery}`);
    //     }
    //     return AcaiaConstants.EVENT_BATTERY_LEN;
    // }

    // private parseTimerEvent(payload) {
    //     if ((payload.length < AcaiaConstants.EVENT_TIMER_LEN))
    //         return -1;
    //     //            print('minutes', payload[0])
    //     //            print('seconds', payload[1])
    //     //            print('mseconds', payload[2])
    //     const value = ((payload[0] & 0xff) * 60) + payload[1] + payload[2] / 10.0;
    //     logger.debug('parseTimerEvent(_): %sm%s%sms, %s', payload[0], payload[1], payload[2], value);
    //     return AcaiaConstants.EVENT_TIMER_LEN;
    // }

    // private parseAckEvent(payload) {
    //     if ((payload.length < AcaiaConstants.EVENT_ACK_LEN))
    //         return -1;
    //     return AcaiaConstants.EVENT_ACK_LEN;
    // }

    // private parseKeyEvent(payload) {
    //     logger.debug('parseKeyEvent(_)');
    //     if ((payload.length < AcaiaConstants.EVENT_KEY_LEN))
    //         return -1;
    //     return AcaiaConstants.EVENT_KEY_LEN;
    // }

    // merges this.packet and the given buffer
    private addBuffer(buffer: ArrayBuffer): void {
        const tmp = new Uint8Array(buffer);
        let len = 0;

        if (this.packet != null) {
            len = this.packet.length;
        }

        const result = [];
        result.length = len + buffer.byteLength;

        for (let i = 0; i < len; i++) {
            result[i] = this.packet[i];
        }

        for (let i = 0; i < buffer.byteLength; i++) {
            result[i + len] = tmp[i];
        }

        this.packet = result;
    }

    // called when the scale sends data
    private characteristicValueChanged(event: { target: { value: { buffer: ArrayBuffer } } }): void {
        if (!event?.target?.value?.buffer) {
            return;
        }
        const data = event.target.value.buffer;
        const udata = new Uint8Array(data);
        if (!udata[2] || !udata[4] || udata[2] !== AcaiaConstants.MSG_EVENT || udata[4] !== AcaiaConstants.EVENT_WEIGHT) {
            // do not log weight messages
            this.logger.debug('received ' + new Uint8Array(event.target.value.buffer));
        } else {
            this.logger.trace('received weight data ' + new Uint8Array(event.target.value.buffer));
        }
        
        // keep track of whether the scale still sends data
        this.receiving = true;
        if (this.disconnecttimer) {
            clearTimeout(this.disconnecttimer);
            this.disconnecttimer = undefined;
        }
        this.disconnecttimer = setTimeout(() => {
            this.receiving = false;
        }, 1000);

        this.queue.add(data);
    }

    // called when the callbacks are invalid
    // (e.g. user leaves an input field)
    leave(): void {
        setTimeout(async () => {
            await this.confNotifications(false /* first */, true /* sleep */);
        }, 0);
        this.connectedCalled = false;
        this.dataCallback = undefined;
        this.disconnectedCallback = undefined;
        this.connectedCallback = undefined;
        this.connectingCallback = undefined;
        this.errCallback = undefined;
    }

    async disconnect(): Promise<void> {
        if (this.heartbeat) {
            clearInterval(this.heartbeat)
        }
        if (this.sendIdTimer) {
            clearTimeout(this.sendIdTimer);
            this.sendIdTimer = undefined;
        }
        if (this.device && this.device.gatt.connected) {
            // send stop comand to scale
            await this.sendStop();
            try {
                if (this.device && this.device.gatt.connected) {
                    try {
                        // remove listener and call stop notifications
                        await this.bleOps.stopNotifications();
                    } catch (err) {
                        this.logger.debug(`error stopping notifications ${err}`);
                        if (typeof this.errCallback === 'function') {
                            this.errCallback('error starting notifications - please reload')
                        }
                    }
                    // finally disconnect from GATT
                    this.device.gatt.disconnect();
                    this.connected = false
                }
            } catch (err) {
                this.logger.debug('error disconnecting: ', err)
                if (typeof this.errCallback === 'function') {
                    this.errCallback('error disconnecting - please reload')
                }
            }
        } else {
            this.connected = false
        }
    }

    // listener called when that GATT disconnects
    private async onDisconnected(event: Event): Promise<void> {
        // Object event.target is the Bluetooth device getting disconnected
        this.connectedCalled = false
        const btd = event.target as BluetoothDevice;
        btd.removeEventListener('gattserverdisconnected', this.onDisconnected.bind(this));
        // don't remove, we might want to reconnect
        // this.device = undefined;
        this.logger.debug(`Bluetooth Device ${event.target ? btd.name : ''} disconnected`);
        if (typeof this.disconnectedCallback === 'function') {
            this.disconnectedCallback();
        }
        this.notificationConfSent = false;
        this.idSent = false;
        if (this.callDoConnect) {
            await this.doConnect(this.callDoConnect[0], this.callDoConnect[1], this.callDoConnect[2], this.callDoConnect[3], this.callDoConnect[4], this.callDoConnect[5]);
            this.callDoConnect = undefined;
        }
    }
}
