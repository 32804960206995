import { Utils } from 'src/app/util/utils';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Component, AfterViewInit, ViewChild, AfterContentInit, OnDestroy, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { NGXLogger } from 'ngx-logger';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/User';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

    constructor(
        private logger: NGXLogger,
        private alertService: AlertService,
        private router: Router,
        private userService: UserService,
        private tr: TranslatorService,
        private utils: Utils,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    user: User & { password?: string } = new User();
    loading = false;
    doblur = false;
    isDarkmode = false;

    private ngUnsubscribe = new Subject();

    @ViewChild('firstinput', { static: true }) firstInput: MatInput;

    ngOnInit(): void {
        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.doblur = true;
        }, 100);
    }

    ngAfterContentInit(): void {
        if (this.firstInput) {
            this.firstInput.focus();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    register(): void {
        this.user.locale = this.locale || 'en';
        this.logger.debug('new user', this.user);
        this.loading = true;
        this.userService.create(this.user)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (response.result?.user) {
                            this.userService.sendVerificationEmail({ email: this.user.email })
                                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                .subscribe({
                                    next: response2 => {
                                        if (response2.success === true) {
                                            this.alertService.success(this.tr.anslate('Please click on the link in the email we sent to {{email}}', { email: response2.result }), undefined, false, false);
                                            this.logger.debug('successfully created user', response2.result);
                                            this.router.navigate(['/login']);
                                        } else {
                                            this.utils.handleError('Could not send verification email', response2.error);
                                            this.loading = false;
                                        }
                                    },
                                    error: error => {
                                        this.utils.handleError('Could not send verification email', error);
                                        this.loading = false;
                                    }
                                });
                        } else {
                            this.utils.handleError('could not add user', { message: 'server error' });
                            this.logger.debug('error creating user, user in response undefined');
                            this.loading = false;
                        }
                    } else {
                        this.utils.handleError('could not add user', response.error);
                        this.loading = false;
                    }
                },
                error: error => {
                    this.utils.handleError('could not add user', error);
                    this.loading = false;
                }
            });
    }
}
