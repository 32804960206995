import { Injectable } from "@angular/core";

// copied from moment.js collection of Intl data

@Injectable({
    providedIn: 'root'
})
export class Ordinals {

    private ordEN(number: number): string {
        const b = number % 10,
            output =
                ~~((number % 100) / 10) === 1
                    ? 'th'
                    : b === 1
                        ? 'st'
                        : b === 2
                            ? 'nd'
                            : b === 3
                                ? 'rd'
                                : 'th';
        return `${number}${output}`;
    }

    private ordFR(number: number, period: string): string {
        switch (period) {
            case 'D':
                return `${number}${(number === 1 ? 'er' : '')}`;

            // Words with masculine grammatical gender: mois, trimestre, jour
            default:
            case 'M':
            case 'Q':
            case 'DDD':
            case 'd':
                return `${number}${(number === 1 ? 'er' : 'e')}`;

            // Words with feminine grammatical gender: semaine
            case 'w':
            case 'W':
                return `${number}${(number === 1 ? 're' : 'e')}`;
        }
    }

    private ordZH(number: number, period: string) {
        switch (period) {
            case 'd':
            case 'D':
            case 'DDD':
                return `${number}日`;
            case 'M':
                return `${number}月`;
            case 'w':
            case 'W':
                return `${number}周`;
            default:
                return `${number}`;
        }
    }

    getOrdinal(num: number, locale = 'en'): string {
        const loc = (locale || 'en').substring(0, 2);
        switch (loc) {
            case 'de':
                return `${num.toString()}.`;
            case 'en':
                return this.ordEN(num);
            case 'es':
                return `${num.toString()}º`;
            case 'fr':
                return this.ordFR(num, 'D');
            case 'it':
                return `${num.toString()}º`;
            case 'pt':
                return `${num.toString()}º`;
            case 'zh':
                return this.ordZH(num, 'D');
        
            default:
                return num.toString();
        }
    }
}
