{
	"Reason": "Raison",
	"References": "Références",
	"Beans used in blends, header": "Grains dans les mélanges",
	"Total": "Total",
	"deleted": "supprimé",
	"Target store": "Magasin cible",
	"over limit": "AU-DESSUS DE LIMITE",
	"Reminders menu label": "Rappels",
	"active": "actif",
	"Rename machines": "Renommer les machines",
	"{{mainUnit}} roasted": "{$INTERPOLATION} torréfaction",
	"hours of roasting": "heures de torréfaction",
	"minutes of roasting": "minutes de torréfaction",
	"min": "min",
	"time": "temps",
	"h": "h",
	"hour of roasting": "heure de torréfaction",
	"weeks": "semaines",
	"years": "années",
	"day of the week": "jour de la semaine",
	"day of the month": "jour du mois",
	"propane consumed": " gaz consommé",
	"On": "Au",
	"After": "Après",
	"Repetition": "Répétition",
	"Repetitions": "Répétitions",
	"Maximum number of repetitions restricted by": "Nombre maximal de répétitions limité par",
	"repetitions possible with the current stock": "répétitions possibles avec le stock actuel",
	"error retrieving all reminders": "erreur lors de la récupération de tous les rappels",
	"daily": "quotidien",
	"yearly": "annuel",
	"Condition": "Régner",
	"starting from a date": "de",
	"Day": "Jour",
	"Week": "La semaine",
	"Completed Tasks": "Tâches terminées",
	"Here you can see, and manually document, completed actions such as the routine cleaning of machines.": "Ici, vous pouvez voir et documenter manuellement les actions terminées telles que le nettoyage continu des machines.",
	"Reminder": "Rappel",
	"Here you can let the system remind you of recurring tasks such as 'Clean thermocouple every week'.": "Ici, vous pouvez laisser le système vous rappeler des tâches récurrentes telles que « Nettoyer le thermocouple chaque semaine ».",
	"Every /n/ {{PLACEHOLDER}}": "Tous les /n/ {$INTERPOLATION}",
	"Every /WEEKDAY/": "Chaque /WEEKDAY/",
	"Every /nthDAYOFMONTH/ day of the month": "Chaque /nthDAYOFMONTH/ jour du mois",
	"(every n>=2) days": "jours",
	"(every n>=2) weeks": "semaines",
	"(every n>=2) months": "mois",
	"(every n>=2) years": "ans",
	"Propane bottle": "Bouteille propane",
	"Once {{kg}} have been roasted": "Une fois que {$INTERPOLATION} ont été torréfiés",
	"Clean fans": "Nettoyer les ventilateurs",
	"Clean cooling tray": "Nettoyer le plateau de refroidissement",
	"Submit tax report": "Soumettre le rapport fiscal",
	"Pay tax bill": "Payer la facture fiscale",
	"After each roast hour": "Après chaque heure de torréfaction",
	"expired since {{kg}}": "expiré depuis {$INTERPOLATION}",
	"expired {{time}}": "expiré {$INTERPOLATION}",
	"next {{time}}": "{$INTERPOLATION} suivant",
	"Every {{interval}} {{intervalUnit}}": "Chaque {$INTERPOLATION}{$INTERPOLATION_1}",
	"Every {{weekday}}": "Chaque {$INTERPOLATION}",
	"Every {{dayOfMonth}}": "Chaque {$INTERPOLATION}",
	"from {{date}}": "depuis {$INTERPOLATION}",
	"After {{minutes}} roast minutes": "Après {$INTERPOLATION} minutes de torréfaction",
	"After {{hours}} roast hours": "Après {$INTERPOLATION} heures de torréfaction",
	"After {{n}} roasts": "Après {$INTERPOLATION} torréfactions",
	"{{interval}} to go": "encore {$INTERPOLATION}",
	"Lubricate drum bearings": "Lubrifier les roulements du tambour",
	"Clean exhaust ducting": "Nettoyer les conduits d'évacuation",
	"Lubricate drum drive chain": "Lubrifier la chaîne d'entraînement du tambour",
	"Replace air filter": "Remplacer le filtre à air",
	"Generate monthly roast report": "Générer un rapport de torréfaction mensuel",
	"Replace gas bottle": "Remplacer la bouteille de gaz",
	"Prepare organic audit": "Préparer l'audit organique",
	"Empty chaff container": "Vider conteneur à paillettes",
	"Once {{kg}} {{gasUnit}} propane consumed ({{btu}} {{btuUnit}})": "Une fois {$INTERPOLATION}{$INTERPOLATION_1} propane consommé ({$INTERPOLATION_2}{$INTERPOLATION_3})",
	"Notify all": "Avertir tous",
	"Browser": "Navigateur",
	"Notification": "Notification",
	"Best add this email to your address book": "Veuillez ajouter cet email à votre carnet d'adress",
	"OR": "OU",
	"After each roast": "Après chaque torréfaction",
	"average FIFO cost comes from": "La moyenne de {$INTERPOLATION_1}/{$INTERPOLATION_2} provient du coût actuel de {$INTERPOLATION_4} pour le stock actuel de {$INTERPOLATION_5}{$INTERPOLATION_6} grains",
	"Corrections are not listed": "Les corrections ne sont pas énumérée",
	"Maximum for display reached": "Maximum pour l'affichage atteint",
	"plural_discarded": "détruits",
	"from": "depuis",
	"Attribute": "Attribut",
	"Load / Save ...": "Charger / Enregistrer ...",
	"Click here to add more filters": "Ajouter plus de filtres",
	"No amount set": "Aucun montant défini",
	"No yield set": "Aucun rendement défini",
	"No beans set": "Aucun grains défini",
	"No blends": "Pas de mélanges",
	"Specific bean in blends": "Grain spécifique dans les mélanges",
	"missing value": "valeur manquante",
	"any value": "toutes valeurs",
	"any": "tous",
	"or": "ou",
	"and": "et",
	"not": "pas",
	"Summary": "Résumé",
	"List of roasts": "Liste des torréfactions",
	"BT": "TG",
	"FC": "1erC",
	"Only one value found": "Seulement une valeur trouvée",
	"Share beans info": "Partager des informations sur les haricots",
	"Copy to clipboard": "Copier dans le presse-papier",
	"See what will be shared": "Voir ce qui sera partagé",
	"Scheduler menu label": "Planificateur",
	"5848348023107769657": "Poids",
	"Title": "Titre",
	"This is a system wide change. All existing roasts, templates, scheduled items etc. will be affected. Cannot be undone.": "Il s’agit d’un changement à l’échelle du système. Tous les torréfactions, modèles, éléments programmés, etc. existants seront affectés. Ne peut pas être défait.",
	"Caution! Chosen machine name already exists, you will not be able to separate roasts by machine after the change.": "Prudence! Le nom de la machine choisie existe déjà, vous ne pourrez plus séparer les torréfactions par machine après le changement.",
	"Cupping": "Cupping",
	"Always available as template": " Toujours disponible comme modèle",
	"Roasting Notes": "Notes de torréfaction",
	"Cupping Notes": "Notes de dégustation",
	"Search beans": "Rechercher des haricots",
	"Add as post blend": "Ajouter comme post-mélange",
	"Save": "Sauvegarder",
	"Missing": "Manquant",
	"From history": "De l'histoire",
	"Search templates": "Modèles de recherche",
	"Add machines": "Ajouter des machines",
	"Favorites": "Favoris",
	"Reload - check for changes": "Recharger - vérifier les modifications",
	"not specified": "non spécifié",
	"Reduce displayed stock by planned amounts": "Réduire le stock affiché selon les quantités prévues",
	"Search roast": "Rechercher un torréfaction",
	"Batch": "Lot",
	"Hauptzollamt Aachen": "Hauptzollamt Aix-la-Chapelle",
	"Hauptzollamt Augsburg": "Hauptzollamt Augsbourg",
	"Hauptzollamt Berlin": "Hauptzollamt Berlin",
	"Hauptzollamt Bielefeld": "Hauptzollamt Bielefeld",
	"Hauptzollamt Braunschweig": "Hauptzollamt Brunswick",
	"Hauptzollamt Bremen": "Hauptzollamt Brême",
	"Hauptzollamt Darmstadt": "Hauptzollamt Darmstadt",
	"Hauptzollamt Dortmund": "Hauptzollamt Dortmund",
	"Hauptzollamt Dresden": "Hauptzollamt Dresde",
	"Hauptzollamt Duisburg": "Hauptzollamt Duisbourg",
	"Hauptzollamt Düsseldorf": "Hauptzollamt Düsseldorf",
	"Hauptzollamt Erfurt": "Hauptzollamt d'Erfurt",
	"Hauptzollamt Frankfurt (Oder)": "Hauptzollamt Francfort (Oder)",
	"Hauptzollamt Frankfurt am Main": "Hauptzollamt Francfort-sur-le-Main",
	"Hauptzollamt Gießen": "Hauptzollamt Giessen",
	"Hauptzollamt Hamburg": "Hauptzollamt Hambourg",
	"Hauptzollamt Hannover": "Hauptzollamt Hanovre",
	"Hauptzollamt Heilbronn": "Hauptzollamt Heilbronn",
	"Hauptzollamt Itzehoe": "Hauptzollamt Itzehoe",
	"Hauptzollamt Karlsruhe": "Hauptzollamt Karlsruhe",
	"Hauptzollamt Kiel": "Hauptzollamt Kiel",
	"Hauptzollamt Koblenz": "Hauptzollamt Coblence",
	"Hauptzollamt Köln": "Hauptzollamt Cologne",
	"Hauptzollamt Krefeld": "Hauptzollamt Krefeld",
	"Hauptzollamt Landshut": "Hauptzollamt Landshut",
	"Hauptzollamt Lörrach": "Hauptzollamt Lörrach",
	"Hauptzollamt Magdeburg": "Hauptzollamt Magdebourg",
	"Hauptzollamt München": "Hauptzollamt Munich",
	"Hauptzollamt Münster": "Hauptzollamt Münster",
	"Hauptzollamt Nürnberg": "Hauptzollamt Nuremberg",
	"Hauptzollamt Oldenburg": "Hauptzollamt d'Oldenbourg",
	"Hauptzollamt Osnabrück": "Hauptzollamt Osnabrück",
	"Hauptzollamt Potsdam": "Hauptzollamt Potsdam",
	"Hauptzollamt Regensburg": "Hauptzollamt Ratisbonne",
	"Hauptzollamt Rosenheim": "Hauptzollamt Rosenheim",
	"Hauptzollamt Saarbrücken": "Hauptzollamt Sarrebruck",
	"Hauptzollamt Schweinfurt": "Hauptzollamt Schweinfurt",
	"Hauptzollamt Singen": "Hauptzollamt Singen",
	"Hauptzollamt Stralsund": "Hauptzollamt Stralsund",
	"Hauptzollamt Stuttgart": "Hauptzollamt Stuttgart",
	"Hauptzollamt Ulm": "Hauptzollamt Ulm",
	"of": "de",
	"Item cannot be updated, probably already synchronized with Artisan": "L'article ne peut pas être mis à jour, probablement déjà synchronisé avec Artisan",
	"Item cannot be updated, roasting has already started": "L'élément ne peut pas être mis à jour, la torréfaction a déjà commencé",
	"Please enter a value for {{missingField}}": "Veuillez saisir une valeur pour {$INTERPOLATION}",
	"Some items could not be deleted": "Certains éléments n'ont pas pu être supprimés",
	"error updating the schedule information": "erreur lors de la mise à jour des informations de planification",
	"error updating the schedule": "erreur lors de la mise à jour du planification",
	"error retrieving the schedule": "erreur lors de la récupération du planification",
	"Beans used in blends": "Haricots utilisés dans les mélanges",
	"Batches": "Lots",
	"Batch size": "Taille du lot",
	"Loss": "Perte",
	"Yield": "Rendement",
	"Weight": "Volume",
	"Stocks": "Stocks",
	"Customs": "Douane",
	"Roasts": "Torréfactions",
	"Sequential": "Complet",
	"Open": "Libre",
	"You can COPY or MOVE all your roast reports from the 'Sequential' section here:": "Vous pouvez COPIER ou DÉPLACER tous vos rapports de torréfaction de la section 'Complet' ici:",
	"Per store": "Par magasin",
	"Per bean": "Par grains",
	"per": "pro",
	"Show zero amounts": "Afficher montants zéro",
	"loading reports": "Chargement des rapports ...",
	"Sum": "Somme",
	"Store": "Magasin",
	"Cost": "Coût",
	"invalid transactions not included": "Les transactions suivantes ne seront pas incluses dans le rapport car des informations importantes manquent:",
	"no amount": "pas de montant",
	"Quarter {{quarter number}}, {{year}}": "Trimestre {$INTERPOLATION}, {$INTERPOLATION_1}",
	"Week {{week number}}, {{year}}": "Semaine {$INTERPOLATION}, {$INTERPOLATION_1}",
	"error adding report": "erreur lors de l'ajout du rapport",
	"report dates are overlapping with report {{reportLabel}}": "les dates de rapport se chevauchent avec le rapport {$INTERPOLATION}",
	"report with label {{label}} already exists": "un rapport portant le nom {$INTERPOLATION} existe déjà",
	"report cannot be deleted: it is reconciled": "le rapport ne peut pas être supprimé: il est finalisé",
	"no beans or blend": "pas de grains ou de mélange",
	"no roast date": "pas de date de torréfaction",
	"weight or yield not positive": "Volume ou rendement non positives",
	"Yield must be smaller than weight": "Le rendement doit être inférieur à le volume",
	"no roast location": "pas de lieu de torréfaction",
	"before selected period": "avant la période sélectionnée",
	"unknown reason": "raison inconnue",
	"Roast Report": "Rapport de torréfaction",
	"Created by artisan.plus": "Créé par artisan.plus",
	"Check Online Status": "Vérifier l'état d'artisan.plus",
	"amount": "montant",
	"Months": "Mois",
	"Regions": "Régions",
	"Accumulated Production": "Production accumulée",
	"Yearly Production": "Production annuelle",
	"Ambient": "Ambiant",
	"Machines": "Machines",
	"Users": "Utilisateurs",
	"User": "Utilisateur",
	"Value": "Valeur",
	"Certification": "Certification",
	"Water Activity": "Activité d'eau",
	"Origins": "Origines",
	"estimated yield": "rendement estimé",
	"estimated amount": "quantité estimée",
	"month": "mois",
	"batches": "lots",
	"batch": "lot",
	"one batch": "un lot",
	"days": "journées",
	"{{count}} on one day": "{$INTERPOLATION} par jour",
	"{{count}} on {{days}} days": "{$INTERPOLATION} en {$INTERPOLATION_1} jours",
	"still ~{{month_left}} months": "encore ~{$INTERPOLATION} mois",
	"still ~{{weeks_left}} weeks": "encore ~{$INTERPOLATION} semaines",
	"still about one week": "encore environ une semaine",
	"still about one month": "encore environ un mois",
	"more than": "plus que",
	"weekly": "hebdomadaire",
	"monthly": "mensuel",
	"Monthly Production": "Production mensuelle",
	"This year": "Cette année",
	"Last year": "L'année dernière",
	"{{nrCounts}} roasts": "{$INTERPOLATION} torréfactions",
	"one roast": "une torréfaction",
	"one lot": "un lot",
	"{{nrCoffees}} lots": "{$INTERPOLATION} lots",
	"1 month": "1 mois",
	"{{nrMonth}} months": "{$INTERPOLATION} mois",
	"1 year": "1 an",
	"{{nrYears}} years": "{$INTERPOLATION} ans",
	"today": "auhourd'hui",
	"stored": "stocké",
	"with replacements": "avec remplacements",
	"roasted": "torréfié",
	"estimated age": "âge estimé",
	"Age": "Age",
	"age": "âge",
	"expected total age": "âge total prévu",
	"expected reach": "durée attendue",
	"min reach": "durée min",
	"max reach": "durée maximale",
	"harvested": "récolté",
	"yield": "rendement",
	"Color system": "Système couleur",
	"Corrections": "Corrections",
	"Transfers": "Transferts",
	"Sales": "Ventes",
	"Purchases": "Achats",
	"Correction": "Correction",
	"Purchase": "Achat",
	"Sale": "Vente",
	"Transfer": "Transfert",
	"Last correction": "Dernière correction",
	"Last purchase": "Dernier achat",
	"Last sale": "Dernière vente",
	"Last transfer": "Dernier transfert",
	"error updating the additional report information": "erreur de mise à jour des informations de rapport supplémentaires",
	"no data": "pas de données",
	"missing or invalid date": "date manquante ou invalide",
	"user data could not be downloaded": "les données utilisateur n'ont pas pu être téléchargées",
	"Update available, please do a browser refresh (CMD-SHIFT-R or CTRL-SHIFT-F5)": "Mise à jour disponible, veuillez actualiser votre navigateur (CMD-SHIFT-R ou CTRL-SHIFT-F5)",
	"location not found": "lieu non trouvé",
	"could not find beans": "n'a pas pu trouver de grains",
	"roast not found": "torréfaction non trouvé",
	"store not found": "magasin non trouvé",
	"purchase not found": "achat non trouvé",
	"transfer not found": "transfert non trouvé",
	"sale not found": "vente non trouvée",
	"correction not found": "correction non trouvée",
	"erroneous altitude range": "altitude invalide",
	"erroneous screen size range": "taille invalide",
	"must specify target of transfer": "doit spécifier la cible du transfert",
	"must specify location of change": "doit spécifier le lieu du changement",
	"must specify the beans to be changed": "doit spécifier les grains à changer",
	"roast must not have both beans and blend": "un torréfaction ne doit pas avoir grains et mélange",
	"invalid ID given for Location": "ID non valide donnée pour l'emplacement",
	"error retrieving a store": "erreur lors de la récupération d'un magasin",
	"trying to get location": "en essayant d'obtenir le lieu",
	"error retrieving stock of a store": "erreur lors de la récupération du stock d'un magasin",
	"error retrieving all store stocks": "erreur lors de la récupération du stocks de tous les magasins",
	"error retrieving all purchases for the beans": "erreur lors de la récupération de tous les achats pour ces grains",
	"error adding the custom selection property": "erreur lors de l'ajout de la propriété de sélection unique",
	"error adding the region": "erreur lors de l'ajout de la région",
	"could not identify the user": "n'a pas pu identifier l'utilisateur",
	"could not find the user": "n'a pas pu trouver l'utilisateur",
	"could not update the user account": "Impossible de mettre à jour le compte d'utilisateur",
	"could not update the user": "Impossible de mettre à jour l'utilisateur",
	"user is not associated with an account. Please send an email to admin@artisan.plus": "l'utilisateur n'est pas associé à un compte. S'il vous plaît envoyez un e-mail à admin@artisan.plus",
	"Your account is not activated. Please send an email to admin@artisan.plus": "Votre compte n'est pas activé. S'il vous plaît envoyez un e-mail à admin@artisan.plus",
	"missing or invalid start or end date": "date de début ou de fin manquante ou invalide",
	"no user information given": "aucune information d'utilisateur donnée",
	"no email given": "aucun e-mail donné",
	"no password given": "pas de mot de passe",
	"no nickname given": "pas de surnom",
	"could not send email": "l'e-mail n'a pas pu être envoyé",
	"wrong password reset link": "lien de réinitialisation de mot de passe incorrect",
	"not allowed to change someone else\\'s password": "pas autorisé à changer le mot de passe de quelqu'un d'autre",
	"could not create account with customer_code {{ccode}}": "Impossible de créer un compte avec code_client {$INTERPOLATION}",
	"could not add user": "Impossible d'ajouter un utilisateur",
	"successfully uploaded data but could not set password": "Données téléchargées avec succès mais impossible de changer le mot de passe",
	"could not retrieve user from id {{userId}}": "Impossible de récupérer l'utilisateur de l'id {$INTERPOLATION}",
	"object with id {{id}} not found": "objet avec l'ID {$INTERPOLATION} introuvable",
	"{{model}} with id {{_id}} not found": "{$INTERPOLATION} avec l'identifiant {$INTERPOLATION_1} introuvable",
	"trying to get {{model}} by name {{label}}": "en essayant d'obtenir {$INTERPOLATION} par nom {$INTERPOLATION_1}",
	"could not add producer location {{label}}": "Impossible d'ajouter le lieu du producteur {$INTERPOLATION}",
	"location with {{label and type info}} already exists": "un lieu avec {$INTERPOLATION} existe déjà",
	"could not add location {{label}}": "Impossible d'ajouter le lieu {$INTERPOLATION}",
	"could not update location {{label}}": "Impossible de mettre à jour le lieu {$INTERPOLATION}",
	"could not update location of {{producerLabel}}": "Impossible de mettre à jour le lieu de {$INTERPOLATION}",
	"could not add supplier {{label}}": "impossible d'ajouter le fournisseur {$INTERPOLATION}",
	"could not add customer {{label}}": "Impossible d'ajouter le client {$INTERPOLATION}",
	"could not find blend with ID {{blend}}": "Impossible de trouver le mélange avec l'ID {$INTERPOLATION}",
	"trying to get {{model}}": "en essayant d'obtenir {$INTERPOLATION}",
	"invalid ID given for {{property}}": "ID non valide donné pour {$INTERPOLATION}",
	"could not make related updates": "les mises à jour liées n'ont pas pu être faites",
	"stock of beans at location {{location}} could not be updated": "le stock de grains au lieu {$INTERPOLATION} n'a pas pu être mis à jour",
	"value of beans at location {{location}} could not be calculated": "La valeur des beans au lieu {$INTERPOLATION} n'a pas pu être calculée",
	"could not update property {{error}}": "Impossible de mettre à jour la propriété {$INTERPOLATION}",
	"roast with roast_id {{roast_id}} not found": "torréfaction avec roast_id {$INTERPOLATION} non trouvée",
	"rejected deleting all {{model}}": "impossible de tout supprimer {$INTERPOLATION}",
	"could not check if {{model}} exists in DB": "n'a pas pu vérifier si {$INTERPOLATION} existe dans la DB",
	"logged in user not the same as user to be deleted": "utilisateur connecté différent de l'utilisateur à supprimer",
	"logged in user not the same as user to be updated": "utilisateur connecté différent de l'utilisateur à mettre à jour",
	"could not delete location": "impossible de supprimer le lieu",
	"could not un-reconcile a transaction in the report": "ne pouvait pas annuler la finalisation d'une transaction dans le rapport",
	"undefined": "indéfini",
	"error retrieving information": "erreur lors de la récupération des informations",
	"Failed updating blend templates": "Échec de la mise à jour des modèles de mélange",
	"UNKNOWN": "INCONNU",
	"NEW": "NOUVEAU",
	"Attention": "Attention",
	"Please add at least one type of beans first.": "S'il vous plaît ajouter d'abord au moins un type de grains.",
	"A blend needs at least one type of beans.": "Un mélange nécessite au moins un type de grain.",
	"Ratios must be positive and add up to 100%.": "Les ratios doivent être positifs et totaliser 100%.",
	"0% ratios are not allowed.": "Les ratios 0% ne sont pas possibles.",
	"Please select beans or a blend.": "Veuillez sélectionner des grains ou un mélange.",
	"Please enter an amount larger than 0.": "Veuillez entrer un montant supérieur à 0.",
	"Do you really want to delete {{name}}?": "Voulez-vous vraiment supprimer {$INTERPOLATION}?",
	"Do you really want to delete all your data?!": "Voulez-vous vraiment supprimer toutes vos données?!",
	"Do you really want to delete your account and all your data?!": "Voulez-vous vraiment supprimer votre compte et toutes vos données?!",
	"Do you really want to delete this {{transaction_type}}?": "Voulez-vous vraiment supprimer ce {$INTERPOLATION}?",
	"Do you really want to finalize this report and all contained roasts?": "Voulez-vous vraiment finaliser ce rapport et toutes les torréfactions contenues?",
	"Do you really want to cancel the access to the account of {{nickname}}?": "Souhaitez-vous vraiment annuler l'accès au compte de {$INTERPOLATION}?",
	"Set the stock of this coffee to 0 (consumed)?": "Mettre le volume de ce café à 0 (consommé)?",
	"consumed": "consommé",
	"Email sent. You can also forward the following link yourself": "Email envoyé. Vous pouvez également transmettre vous-même le lien suivant",
	"You can now switch accounts here": "Vous pouvez maintenant changer le compte ici",
	"Sun-grown": "Cultivé au soleil",
	"Plantation": "Plantation",
	"Garden": "Jardin",
	"Shade-grown": "Cultivé à l'ombre",
	"Forest": "Forêt",
	"Wild Forest": "Forêt sauvage",
	"Organic Solvent": "Solvant organique",
	"Natural Decaffeination Process EA": "Décaféination naturelle (acétate d'éthyle)",
	"Methylene Chloride Process": "Procédé au chlorure de méthylène",
	"Triglyceride Process": "Procédé triglycéride",
	"Water Filtered": "Eau filtrée",
	"Swiss Water Process": "Procédé \"Swiss Water\"",
	"Mountain Water Process": "Procédé à l'eau de montagne",
	"Supercritical Fluid": "Fluide supercritique",
	"Carbon Dioxide Process": "Procédé au dioxyde de carbone",
	"Others": "Autres",
	"decaffeinated": "décaféiné",
	"European Preparation": "Préparation européenne",
	"American Preparation": "Préparation américaine",
	"Abu Dhabi": "Abou Dabi",
	"Ajman": "Ajman",
	"Azores and Madeira": "Açores et Madère",
	"Bonaire": "Bonaire",
	"Caroline Islands": "Îles Carolines",
	"Ceuta": "Ceuta",
	"Cocos Islands": "Îles Cocos",
	"Dubai": "Dubai",
	"E.C. unspecified": "CE non spécifié",
	"Faeroe Islands": "Îles Féroé",
	"Falkland Islands": "les îles Falkland",
	"Fujairah": "Fujaïrah",
	"Gaza Strip": "bande de Gaza",
	"Holy See": "Saint-Siège",
	"Leeward Islands": "Îles sous le Vent",
	"Libyan Arab Jamahiriya": "Jamahiriya arabe libyenne",
	"Macau": "Macao",
	"Melilla": "Melilla",
	"Netherlands Antilles": "Antilles néerlandaises",
	"Northern Marianas": "Mariannes du Nord",
	"Ras al Khaimah": "Ras el Khaïmah",
	"Reunion": "Réunion",
	"Sabah": "Sabah",
	"Sao Tome and Principe": "Sao Tomé et Principe",
	"Sarawak": "Sarawak",
	"Sharjah": "Charjah",
	"Svalbard and Jan Mayen Islands": "Îles Svalbard et Jan Mayen",
	"Swaziland": "Swaziland",
	"Tahiti": "Tahiti",
	"Umm al Qaiwain": "Umm al Qaiwain",
	"Windward Islands": "Îles du Vent",
	"Galápagos": "Galápagos",
	"Africa": "Afrique",
	"Caribbean": "Caraïbes",
	"Central America": "Amérique centrale",
	"Europe": "Europe",
	"Canary Islands": "Les îles Canaries",
	"South America": "Amérique du sud",
	"Asia": "Asie",
	"ANI": "ANI",
	"Bali": "Bali",
	"Borneo": "Bornéo",
	"Flores": "Flores",
	"Java": "Java",
	"Papua": "Papouasie",
	"St. Lucia": "Sainte Lucie",
	"Sulawesi": "Sulawesi",
	"Sumatra": "Sumatra",
	"Sumbawa": "Sumbawa",
	"Oceania": "Océanie",
	"Hawaii": "Hawaii",
	"Metric": "Métrique",
	"Imperial": "Impérial",
	"t": "t",
	"none": "aucun",
	"Weight of a bag in kg?": "Poids d'un sac en kg?",
	"Weight of a barrel in kg?": "Poids d'un tonneau en kg?",
	"Weight of a box in kg?": "Poids d'une boîte en kg?",
	"Weight of a bag in lbs?": "Poids d'un sac en livres?",
	"Weight of a barrel in lbs?": "Poids d'un tonneau en livres?",
	"Weight of a box in lbs?": "Poids d'une boîte en livres?",
	"yesterday": "hier",
	"{{x}} days ago": "Il y a {$INTERPOLATION} jours",
	"Fahrenheit": "Fahrenheit",
	"Celsius": "Celsius",
	"bag": "sac",
	"plural_bag": "sacs",
	"barrel": "tonneau",
	"plural_barrel": "tonneaux",
	"box": "boîte",
	"plural_box": "boîtes",
	"Stores": "Magasins",
	"Producers": "Producteurs",
	"Suppliers": "Fournisseurs",
	"Customers": "Clients",
	"Fields": "Lieu",
	"External Stores": "Magasins externes",
	"social": "social",
	"organic": "biologique",
	"fair": "équitable",
	"sustainable": "durable",
	"bird friendly": "favorable aux oiseaux",
	"shade grown": "cultivé à l'ombre",
	"high quality": "haute qualité",
	"Afghanistan": "Afghanistan",
	"Aland Islands": "Iles Aland",
	"Albania": "Albanie",
	"Algeria": "Algérie",
	"American Samoa": "Samoa américaines",
	"Andorra": "Andorre",
	"Angola": "Angola",
	"Anguilla": "Anguilla",
	"Antarctica": "Antarctique",
	"Antigua and Barbuda": "Antigua-et-Barbuda",
	"Argentina": "Argentine",
	"Armenia": "Arménie",
	"Aruba": "Aruba",
	"Australia": "Australie",
	"Austria": "Autriche",
	"Azerbaijan": "Azerbaïdjan",
	"Bahamas": "Bahamas",
	"Bahrain": "Bahrein",
	"Bangladesh": "Bangladesh",
	"Barbados": "Barbade",
	"Belarus": "Biélorussie",
	"Belgium": "Belgique",
	"Belize": "Belize",
	"Benin": "Bénin",
	"Bermuda": "Bermudes",
	"Bhutan": "Bhoutan",
	"Bolivia": "Bolivie",
	"Bonaire, Sint Eustatius and Saba": "Bonaire, Sint Eustatius et Saba",
	"Bosnia and Herzegovina": "Bosnie Herzégovine",
	"Botswana": "Botswana",
	"Bouvet Island": "Île Bouvet",
	"Brazil": "Brésil",
	"British Indian Ocean Territory": "Territoire britannique de l'océan Indien",
	"Brunei Darussalam": "Brunei Darussalam",
	"Bulgaria": "Bulgarie",
	"Burkina Faso": "Burkina Faso",
	"Burundi": "Burundi",
	"Cape Verde": "Cap-Vert",
	"Cambodia": "Cambodge",
	"Cameroon": "Cameroun",
	"Canada": "Canada",
	"Cayman Islands": "Îles Caïmans",
	"Central African Republic": "République Centrafricaine",
	"Chad": "Tchad",
	"Chile": "Chili",
	"China": "Chine",
	"Christmas Island": "L'île de Noël",
	"Cocos (Keeling) Islands": "Îles Cocos (Keeling)",
	"Colombia": "Colombie",
	"Comoros": "Les Comores",
	"Congo, DR": "Congo, DR",
	"Congo, Republic": "Congo, République",
	"Cook Islands": "Les Îles Cook",
	"Costa Rica": "Costa Rica",
	"Croatia": "Croatie",
	"Cuba": "Cuba",
	"Curaçao": "Curacao",
	"Cyprus": "Chypre",
	"Czech Republic": "Tchéquie",
	"Denmark": "Danemark",
	"Djibouti": "Djibouti",
	"Dominica": "Dominique",
	"Dominican Republic": "République Dominicaine",
	"Ecuador": "Équateur",
	"Egypt": "Egypte",
	"El Salvador": "Salvador",
	"Equatorial Guinea": "Guinée Équatoriale",
	"Eritrea": "Erythrée",
	"Estonia": "Estonie",
	"Eswatini": "Eswatini",
	"Ethiopia": "Ethiopie",
	"Falkland Islands [Malvinas]": "Îles Falkland [Malvinas]",
	"Faroe Islands": "Îles Féroé",
	"Fiji": "Fidji",
	"Finland": "Finlande",
	"France": "France",
	"French Guiana": "Guyane Française",
	"French Polynesia": "Polynésie française",
	"French Southern Territories": "Terres Australes Françaises",
	"Gabon": "Gabon",
	"Gambia": "Gambie",
	"Georgia": "Géorgie",
	"Germany": "Allemagne",
	"Ghana": "Ghana",
	"Gibraltar": "Gibraltar",
	"Greece": "Grèce",
	"Greenland": "Groenland",
	"Grenada": "Grenade",
	"Guadeloupe": "Guadeloupe",
	"Guam": "Guam",
	"Guatemala": "Guatemala",
	"Guernsey": "Guernesey",
	"Guinea": "Guinée",
	"Guinea-Bissau": "Guinée Bissau",
	"Guyana": "Guyane",
	"Haiti": "Haïti",
	"Heard Island and McDonald Islands": "Îles Heard et McDonald",
	"Honduras": "Honduras",
	"Hong Kong": "Hong Kong",
	"Hungary": "Hongrie",
	"Iceland": "Islande",
	"India": "Inde",
	"Indonesia": "Indonésie",
	"Iran": "Iran",
	"Iraq": "Irak",
	"Ireland": "Irlande",
	"Isle of Man": "Île de man",
	"Israel": "Israël",
	"Italy": "Italie",
	"Ivory Coast": "Côte d'Ivoire",
	"Jamaica": "Jamaïque",
	"Japan": "Japon",
	"Jersey": "Jersey",
	"Jordan": "Jordan",
	"Kazakhstan": "Kazakhstan",
	"Kenya": "Kenya",
	"Kiribati": "Kiribati",
	"North Korea": "Corée du Nord",
	"South Korea": "Corée du Sud",
	"Kuwait": "Koweit",
	"Kyrgyzstan": "Kirghizistan",
	"Laos": "Laos",
	"Latvia": "Lettonie",
	"Lebanon": "Liban",
	"Lesotho": "Lesotho",
	"Liberia": "Libéria",
	"Libya": "Libye",
	"Liechtenstein": "Liechtenstein",
	"Lithuania": "Lituanie",
	"Luxembourg": "Luxembourg",
	"Macao": "Macao",
	"North Macedonia": "Macédoine du Nord",
	"Madagascar": "Madagascar",
	"Malawi": "Malawi",
	"Malaysia": "Malaisie",
	"Maldives": "Maldives",
	"Mali": "Mali",
	"Malta": "Malte",
	"Marshall Islands": "Iles Marshall",
	"Martinique": "Martinique",
	"Mauritania": "Mauritanie",
	"Mauritius": "Maurice",
	"Mayotte": "Mayotte",
	"Mexico": "Mexique",
	"Micronesia": "Micronésie",
	"Moldova": "Moldova",
	"Monaco": "Monaco",
	"Mongolia": "Mongolie",
	"Montenegro": "Monténégro",
	"Montserrat": "Montserrat",
	"Morocco": "Maroc",
	"Mozambique": "Mozambique",
	"Myanmar": "Myanmar",
	"Namibia": "Namibie",
	"Nauru": "Nauru",
	"Nepal": "Népal",
	"Netherlands": "Pays-Bas",
	"New Caledonia": "Nouvelle Calédonie",
	"New Zealand": "Nouvelle-Zélande",
	"Nicaragua": "Nicaragua",
	"Niger": "Niger",
	"Nigeria": "Nigeria",
	"Niue": "Niue",
	"Norfolk Island": "l'ile de Norfolk",
	"Northern Mariana Islands": "Îles Mariannes du Nord",
	"Norway": "Norvège",
	"Oman": "Oman",
	"Pakistan": "Pakistan",
	"Palau": "Palau",
	"Palestine": "Palestine",
	"Panama": "Panama",
	"PNG": "PNG",
	"Paraguay": "Paraguay",
	"Peru": "Pérou",
	"Philippines": "Philippines",
	"Pitcairn": "Pitcairn",
	"Poland": "Pologne",
	"Portugal": "Portugal",
	"Puerto Rico": "Porto Rico",
	"Qatar": "Qatar",
	"Réunion": "Réunion",
	"Romania": "Roumanie",
	"Russian Federation": "Fédération Russe",
	"Rwanda": "Rwanda",
	"Saint Barthélemy": "Saint Barthélemy",
	"St. Helena": "Sainte-Hélène",
	"Saint Kitts and Nevis": "Saint-Christophe-et-Niévès",
	"Saint Lucia": "Sainte-Lucie",
	"Saint Martin (French part)": "Saint Martin (partie française)",
	"Saint Pierre and Miquelon": "Saint-Pierre-et-Miquelon",
	"St. Vincent": "Saint Vincent",
	"Samoa": "Samoa",
	"San Marino": "Saint Marin",
	"São Tomé": "São Tomé",
	"Saudi Arabia": "Arabie Saoudite",
	"Senegal": "Sénégal",
	"Serbia": "Serbie",
	"Seychelles": "Seychelles",
	"Sierra Leone": "Sierra Leone",
	"Singapore": "Singapour",
	"Sint Maarten (Dutch part)": "Sint Maarten (partie néerlandaise)",
	"Slovakia": "Slovaquie",
	"Slovenia": "Slovénie",
	"Solomon Islands": "îles Salomon",
	"Somalia": "Somalie",
	"South Africa": "Afrique du Sud",
	"South Georgia and the South Sandwich Islands": "Géorgie du Sud et les îles Sandwich du Sud",
	"South Sudan": "Soudan du sud",
	"Spain": "Espagne",
	"Sri Lanka": "Sri Lanka",
	"Sudan": "Soudan",
	"Suriname": "Suriname",
	"Svalbard and Jan Mayen": "Svalbard et Jan Mayen",
	"Sweden": "Suède",
	"Switzerland": "Suisse",
	"Syrian Arab Republic": "République arabe syrienne",
	"Taiwan": "Taiwan",
	"Tajikistan": "Tadjikistan",
	"Tanzania": "Tanzanie",
	"Thailand": "Thaïlande",
	"Timor, East": "Timor oriental",
	"Timor": "Timor",
	"Togo": "Togo",
	"Tokelau": "Tokélaou",
	"Tonga": "Tonga",
	"Trinidad and Tobago": "Trinité-et-Tobago",
	"Tunisia": "Tunisie",
	"Turkey": "Turquie",
	"Turkmenistan": "Turkménistan",
	"Turks and Caicos Islands": "îles Turques-et-Caïques",
	"Tuvalu": "Tuvalu",
	"Uganda": "Ouganda",
	"Ukraine": "Ukraine",
	"United Arab Emirates": "Emirats Arabes Unis",
	"UK": "Royaume-Uni",
	"United States Minor Outlying Islands": "États-Unis Îles mineures éloignées des ",
	"USA": "Etats-Unis",
	"Uruguay": "Uruguay",
	"Uzbekistan": "Ouzbekistan",
	"Vanuatu": "Vanuatu",
	"Venezuela": "Venezuela",
	"Vietnam": "Vietnam",
	"Virgin Islands (British)": "Îles Vierges britanniques",
	"Virgin Islands (U.S.)": "Îles Vierges américaines",
	"Wallis and Futuna": "Wallis et Futuna",
	"Western Sahara": "Sahara occidental",
	"Yemen": "Yémen",
	"Zambia": "Zambie",
	"Zimbabwe": "Zimbabwe",
	"Single farm": "Ferme unique",
	"Cooperation": "Coopérative",
	"could not get report: {{error}}": "Impossible d'obtenir le rapport: {$INTERPOLATION}",
	"could not save PDF file: {{error}}": "Impossible d'enregistrer le fichier PDF: {$INTERPOLATION}",
	"could not save the report: {{error}}": "Impossible d'enregistrer le rapport: {$INTERPOLATION}",
	"could not delete the report: {{error}}": "Impossible de supprimer le rapport: {$INTERPOLATION}",
	"could not update the report: {{error}}": "Impossible de mettre à jour le rapport: {$INTERPOLATION}",
	"report not found": "rapport non trouvé",
	"report already reconciled": "rapport déjà finalisé",
	"could not reconcile the report: {{error}}": "Impossible de finaliser le rapport: {$INTERPOLATION}",
	"could not find the report with id {{report_id}}": "Impossible de trouver le rapport avec l'ID {$INTERPOLATION}",
	"error creating PDF": "erreur lors de la création du fichier PDF",
	"error creating CSV": "erreur lors de la création du fichier CSV",
	"error creating Sheet": "erreur lors de la création de la feuille",
	"error creating XML": "erreur lors de la création du fichier XML",
	"Type": "Sorte",
	"Items per page": "Objets par page",
	"Next page": "Page suivante",
	"Previous page": "Page précédente",
	"0 of {{length}}": "0 sur {$INTERPOLATION}",
	"{{startIndex}} - {{endIndex}} of {{length}}": "{$INTERPOLATION} - {$INTERPOLATION_1} de {$INTERPOLATION_2}",
	"First page": "Première page",
	"Last page": "Dernière page",
	"Please click on the link in the email we sent to {{email}}": "Veuillez cliquer sur le lien dans l'e-mail que nous avons envoyé à {$INTERPOLATION}",
	"Your login expired, please login again.": "Votre connexion a expiré, veuillez vous reconnecter.",
	"Successfully removed": "Supprimé avec succès",
	"Successfully added": "Ajouté avec succès",
	"Successfully updated": "Mise à jour avec succès",
	"Successfully finalized": "Finalisé avec succès",
	"Finalized": "Finalisé",
	"Successfully sent email to {{target_email}}": "Envoi d'un e-mail à {$INTERPOLATION} avec succès",
	"Nothing to change": "Rien à changer",
	"Ignored since amount was zero": "Ignoré parce que le montant était nul",
	"Are you connected to the Internet?": "Êtes-vous connecté à Internet?",
	"sum of ratios must be 100%, not {{sum}}": "la somme des ratios doit être 100% et pas {$INTERPOLATION}",
	"Your browser sent too many requests too quickly. Please slow down.": "Votre navigateur a envoyé trop de demandes trop rapidement. Ralentissez, s'il vous plaît.",
	"beans with label {{label}} already exist": "les grains avec le label {$INTERPOLATION} existent déjà",
	"Http failure response for https": "Erreur pendant la connection https",
	"Not a valid file": "Fichier non valide",
	"Could not set new image": "Impossible de utiliser la nouvelle image",
	"Error loading image. Please choose a new one!": "Erreur lors du chargement de l'image. Veuillez en choisir un nouveau!",
	"Could not update documents": "Impossible de mettre à jour les documents",
	"Invalid date": "Date invalide",
	"{{imageName}} is not a valid image": "{$INTERPOLATION} n'est pas une image valide",
	"password must not be empty": "mot de passe ne doit pas être vide",
	"repeated password does not match": "mot de passe répété ne correspond pas au mot de passe",
	"could not update the password": "Impossible de mettre à jour le mot de passe",
	"could not delete the data": "impossible de supprimer les données",
	"Invitation failed. Did you use the invitation link?": "L'invitation a échoué. Avez-vous utilisé le lien d'invitation?",
	"You will LOSE ACCESS to all of your OWN existing data - you have {{nrExistingObjects}} objects": "Vous PERDREZ L'ACCÈS à toutes vos PROPRES données existantes - vous avez {$INTERPOLATION} objets",
	"If you do not want this, cancel and ask the person who invited you to use another of your email addresses": "Si vous ne le souhaitez pas, annulez et demandez à la personne qui vous a invité d'utiliser une autre de vos adresses e-mail",
	"error updating the beans information": "erreur lors de la mise à jour des informations sur les grains",
	"error updating the roast information": "erreur lors de la mise à jour des informations sur les torréfactions",
	"error updating the blend information": "erreur lors de la mise à jour des informations sur les mélanges",
	"error updating the store information": "erreur lors de la mise à jour des informations sur le magasin",
	"error updating the stock information": "erreur lors de la mise à jour des informations sur le stock",
	"error updating the information": "erreur de mise à jour des informations",
	"error updating user information": "erreur de mise à jour des informations sur l'utilisateur",
	"error retrieving all stores": "erreur lors de la récupération des magasins",
	"error retrieving all locations": "erreur lors de la récupération des lieux",
	"error retrieving all beans": "erreur lors de la récupération des grains",
	"error retrieving all blends": "erreur lors de la récupération des mélanges",
	"error retrieving all customers": "erreur lors de la récupération des clients",
	"error retrieving reports": "erreur lors de la récupération des rapports",
	"error retrieving graph data": "erreur lors de la récupération des données graphiques",
	"error retrieving all roasts": "erreur lors de la récupération des torréfactions",
	"error adding store": "erreur lors de l'ajout d'un magasin",
	"error adding purchase": "erreur lors de l'ajout d'un achat",
	"error adding correction": "erreur lors de l'ajout d'une correction",
	"error adding transfer": "erreur lors de l'ajout d'un transfert",
	"error adding sale": "erreur lors de l'ajout d'une vente",
	"error editing store": "erreur lors de la modification du magasin",
	"error editing purchase": "erreur lors de la modification du achat",
	"error editing correction": "erreur lors de la modification de la correction",
	"error editing transfer": "erreur lors de la modification du transfert",
	"error editing sale": "erreur lors de la modification de la vente",
	"error deleting purchase": "erreur lors de la suppression du achat",
	"error deleting correction": "erreur lors de la suppression de la correction",
	"error deleting transfer": "erreur lors de la suppression du transfert",
	"error deleting sale": "erreur lors de la suppression de la vente",
	"no documents were uploaded": "aucun document n'a été téléchargé",
	"server error": "erreur du serveur",
	"error": "Erreur",
	"User with email {{email}} not found": "Utilisateur avec e-mail {$INTERPOLATION} introuvable",
	"Email not verified. Please check the email sent by admin@artisan.plus": "E-mail non vérifié. Veuillez vérifier le courrier électronique envoyé par admin@artisan.plus",
	"Your account does not have enough credit. Please recharge at {{link}}": "Votre compte n'a pas assez de crédit. Merci de recharger à {$INTERPOLATION}",
	"wrong email or password": "e-mail ou mot de passe incorrect",
	"wrong password given": "mot de passe incorrect",
	"User with email {{email}} already exists": "L'utilisateur avec l'e-mail {$INTERPOLATION} existe déjà",
	"you are not allowed to access this data": "vous n'êtes pas autorisé à accéder à ces données",
	"user not found": "utilisateur non trouvé",
	"not found": "pas trouvé",
	"producer with label {{label}} already exists": "le producteur avec le label {$INTERPOLATION} existe déjà",
	"could not add producer {{label}}": "impossible d'ajouter le producteur {$INTERPOLATION}",
	"could not update producer {{label}}": "Impossible de mettre à jour le producteur {$INTERPOLATION}",
	"could not update supplier {{label}}": "Impossible de mettre à jour le fournisseur {$INTERPOLATION}",
	"could not update customer {{label}}": "Impossible de mettre à jour le client {$INTERPOLATION}",
	"could not update roast {{label}}": "Impossible de mettre à jour le torréfaction {$INTERPOLATION}",
	"could not update coffee {{label}}": "Impossible de mettre à jour les grains {$INTERPOLATION}",
	"location with label {{label}} already exists": "le lieu avec le label {$INTERPOLATION} existe déjà",
	"could not add beans {{label}}": "Impossible d'ajouter les grains {$INTERPOLATION}",
	"supplier with label {{label}} already exists": "le fournisseur avec le label {$INTERPOLATION} existe déjà",
	"blend with label {{label}} already exists": "le mélange avec l'étiquette {$INTERPOLATION} existe déjà",
	"customer with label {{label}} already exists": "le client avec le label {$INTERPOLATION} existe déjà",
	"stock update failed": "mise à jour du stock a échoué",
	"could not save image file {{error}}": "Impossible d'enregistrer l'image {$INTERPOLATION}",
	"wrong email verification link": "mauvais lien de vérification d'e-mail",
	"Email verify failed": "La vérification de l'e-mail a échoué",
	"Beans with this name + picked year + origin already exist, {{info}}": "Grains averc ce nom + année récoltée + origine existes déjà: {$INTERPOLATION}",
	"Hidden beans with this name + picked year + origin already exist, {{info}}": "Grains (chachés) averc ce nom + année récoltée + origine existes déjà: {$INTERPOLATION}",
	"this does not belong to current user": "cela n'appartient pas à l'utilisateur actuel",
	"cannot update since a related item is already finalized": "ne peut pas mettre à jour car un élément lié est déjà finalisé",
	"Could not reach server.": "Impossible d'atteindre le serveur.",
	"Please repeat the password reset process": "Veuillez répéter le processus de réinitialisation du mot de passe",
	"Could not send verification email": "Impossible d'envoyer l'e-mail de vérification",
	"Company name": "Nom de la compagnie",
	"Company number": "Numéro de la compagnie",
	"not included explanation": "Torréfactions avec des dates qui appartiennent à ce rapport mais ne sont pas incluses:",
	"added or modified after the report has been created": "ajouté ou modifié après la création du rapport",
	"finalize": "Finaliser",
	"roasts": "torréfactions",
	"creating PDF": "Création PDF en cours ...",
	"Do not show this again": "Ne pas montrer de nouveau",
	"Can be edited in": "Peut être édité dans",
	"update PDF": "Mettre à jour le PDF généré",
	"download PDF": "PDF",
	"start date": "De",
	"end date": "A",
	"allow empty weights": "Inclure les poids vides",
	"invalid roasts not included": "Les torréfactions suivantes ne seront pas incluse dans le rapport car il manque des informations importantes:",
	"Missing items": "Fehlende Einträge",
	"roasts not in range are excluded": "Les torréfactions suivantes ne seront pas incluses dans le rapport car leur date de torréfaction est avant la période sélectionnée:",
	"no reports": "Aucun rapport disponible pour le moment.",
	"You can freely choose your report intervals here.": "Vous pouvez choisir librement vos intervalles de rapport ici.",
	"Reports created here must be consecutive (no overlaps or gaps). They can be finalized for official purposes.": "Les rapports ici doivent être consécutifs (pas de chevauchements ni de lacunes). Ils peuvent être rendus immuables à des fins officielles.",
	"Soon you will see an overview of your beans here; and more, stay tuned!": "Vous pourrez bientôt avoir un aperçu de vos grains ici; et plus, restez à l'écoute!",
	"Date": "Date",
	"Blends": "Mélanges",
	"Change in all blend templates?": "Changer tous les modèles de mélange?",
	"all": "tous",
	"Last modified": "Dernière modification",
	"Loading beans": "Chargement des grains ...",
	"nothing to show plus hint, add with button": "En ce moment, il n'y a rien à montrer. Ajoutez en utilisant le bouton en haut!",
	"You have no hidden items.": "Vou n'avez pas d'objets cachés.",
	"Click here to find all hidden entries": "Cliquez ici pour trouver cos objets cachés",
	"Show only items with organic certification": "Cliquez ici pour ne voir que les articles certifiés biologiques",
	"Show only beans that are not hidden": "Cliquez ici pour ne voir que les grains qui ne sont pas cachés",
	"edit": "Modifier",
	"Name": "Nom",
	"Supplier": "Fournisseur",
	"producer": "Producteur",
	"code": "Code",
	"organizational_form": "Type d'organisation",
	"farmowner": "Propriétaire de la ferme",
	"Web": "Site internet",
	"loading fields": "Chargement des lieux ...",
	"Country": "Pays",
	"street": "Rue",
	"Postal Code": "Code postal",
	"city": "Ville",
	"region": "Région",
	"subregion": "Sous-région",
	"Address": "Adresse",
	"Phone": "Téléphone",
	"Coupon": "Coupon",
	"Request code": "Obtenez le code",
	"Details": "Détails",
	"GET a COUPON": "Obtenir un COUPON",
	"Supplier Partners": "Partenaires fournisseurs",
	"Do you want to add the following beans?": "Voulez-vous ajouter les haricots suivants ?",
	"last seen at supplier on date": "Vu pour la dernière fois chez {$INTERPOLATION} le {$INTERPOLATION_1}",
	"beans already exist will merge attributes": "Ces grains existent déjà dans votre compte. Les nouveaux attributs seront fusionnés.",
	"add a new place": "Ajouter un nouveau lieu",
	"place": "Lieu",
	"close edit place mode": "Fermer",
	"Customer": "Client",
	"Stock": "Stock",
	"incl. replacement ingredients": "y compris les ingrédients de remplacement",
	"Notes": "Remarques",
	"Add/Change replacement ingredients": "Ajouter / modifier des ingrédients de remplacement",
	"Undo replace": "Annuler le remplacement",
	"Replace by": "Remplacer par",
	"ID": "ID",
	"Yield in {{mainUnit}}": "Rendement en {$INTERPOLATION}",
	"order_number": "Numéro de commande",
	"sales_number": "Numéro de vente",
	"dot seperator": "Format: 12.34kg (point)",
	"comma seperator": "Format: 12,34 kg (virgule)",
	"Light mode": "Mode claire",
	"Auto": "Auto",
	"Dark mode": "Mode sombre",
	"Sharing options": "Options de partage",
	"Users with full access to this account:": "Utilisateurs ayant un accès complet à ce compte:",
	"admin user": "(Admin)",
	"pending user": "(en attente)",
	"resend invitation": "Renvoyer l'invitation",
	"Add user to account": "Ajouter un utilisateur à votre compte",
	"Viewers with read-only access to this account:": "Les spectateurs ayant un accès en lecture seule à ce compte:",
	"You can see the accounts of:": "Vous pouvez voir les comptes de:",
	"unit system": "Système d'unité",
	"temperature system": "Système de température",
	"currency": "Devise",
	"Variant": "Variante",
	"Decimal Separator": "Séparateur décimal",
	"Line separator": "Séparateur de ligne",
	"Unix / macOS": "Unix / macOS",
	"Windows": "Windows",
	"Separated by": "Séparé par",
	"Space": "Espace",
	"Semicolon": "Point-virgule",
	"Comma": "Virgule",
	"Tab": "Tab",
	"Data Export Settings (CSV, Excel)": "Paramètres d'exportation (CSV, Excel)",
	"Change your password": "Changez votre mot de passe",
	"download data heading": "Téléchargez vos données",
	"download all data": "Exportez toutes vos données dans un fichier.",
	"delete data heading": "Supprimer vos données",
	"reset": "Réinitialiser votre compte: conserve votre compte mais supprime toutes vos données pour toujours. Ne peut pas être annulé.",
	"delete account": "Supprimer votre compte: supprimez toutes vos données, y compris votre compte. Ne peut pas être annulé.",
	"Reports Settings": "Paramètres des rapports",
	"paid until": "Payé jusqu'au {$INTERPOLATION}",
	"your config": "Votre configuration",
	"Roast": "Torréfaction",
	"roast": "torréfaction",
	"Report": "Rapport",
	"Blend": "Mélange",
	"Beans": "Grains",
	"for price": "pour",
	"from source": "de",
	"on {{date}}": "le {$INTERPOLATION}",
	"cannot delete": "Impossible de supprimer!",
	"cannot delete because of references": "{$INTERPOLATION} ne peut pas être supprimé car il est en cours d'utilisation.",
	"Amount": "Volume",
	"total cost": "Coût total",
	"Cost per": "Coût par",
	"done": "Terminer",
	"Add new beans": "Ajouter des nouveaux grains",
	"unit_specification": "Unité de l'emballage",
	"unit_specification_2": "1 {$INTERPOLATION} a combien de {$INTERPOLATION_1}?",
	"wizard heading": "Commençons!",
	"your locations": "Vos magasins",
	"location explanation": "Comment appelez-vous l'endroit où vous stockez vos grains verts: par exemple Torr. Principal, Magasin 1, Ma cuisine, ...",
	"new location": "Nouvel magasin",
	"select location": "Sélectionner un magasin existant",
	"new location label": "Ajouter un nouvel magasin",
	"next step": "Continuer",
	"Your Beans": "Vos grains",
	"coffee explanation": "Quel type de grains avez-vous à {$INTERPOLATION}?",
	"New beans": "Nouveaux grains",
	"select existing beans": "Sélectionnez les grains existants",
	"coffee amount explanation": "Combien avez-vous {$INTERPOLATION} à {$INTERPOLATION_1}?",
	"new supplier": "Nouveau fournisseur",
	"select supplier": "Sélectionner un fournisseur existant",
	"question for supplier": "De qui l'avez-vous obtenu (fournisseur)?",
	"wizard finished": "Génial! Et ensuite?",
	"Clipboard": "En mémoire",
	"Export": "Exporter",
	"button add more": "En ajouter plus",
	"close edit mode": "Fermer",
	"Year": "Année",
	"clone": "Créer une copie",
	"Import Beans Data": "Importer des données de haricots",
	"reference": "Référence",
	"loading certifications": "Chargement des certifications ...",
	"Origin": "Origine",
	"loading producers": "Chargement des producteurs ...",
	"loading suppliers": "Chargement des fournisseurs ...",
	"loading origins": "Chargement des origines ...",
	"Origin differs from ICO origin": "Différent de l'origine ICO!",
	"lot": "Lot",
	"certifications": "Certifications",
	"add a new producer": "Ajouter un nouveau producteur",
	"ICO": "ICO",
	"ICO details": "ICO Origin - Exportateur - Lot",
	"crop_date_picked": "Récolté",
	"crop_date_landed": "Arrivé",
	"grade": "Classe",
	"altitude": "Altitude",
	"altitude_minmax": "Altitude min / max",
	"screen_size": "Taille",
	"screen_size_minmax": "Taille min / max",
	"score": "Note",
	"Species": "Espèce",
	"Varietals": "Variété",
	"cultivation": "Cultivation",
	"harvesting": "Récolte",
	"Processing": "Traitement",
	"fermentation": "Fermentation",
	"drying": "Séchage",
	"decaffeination": "Décaféination",
	"ageing": "Vieillissement",
	"packaging": "Emballage",
	"Selection": "Sélection",
	"water_activity": "Activité de l'eau",
	"defects": "Défauts",
	"flavors": "Saveurs",
	"crop_yield": "Rendement de récolte",
	"add a new field": "Ajouter un nouveau lieu",
	"field": "Lieu",
	"add initial stock": "Ajouter un stock initial:",
	"report": "rapport",
	"Density": "Densité",
	"roast yield": "rendement",
	"new blend name": "Nouveau nom de mélange",
	"use blend template": "Utiliser {$INTERPOLATION}",
	"Batch Prefix / Number / Position": "Préfixe / numéro / position",
	"Prefix": "Préfixe",
	"Number": "Numéro",
	"Position": "Position",
	"Energy": "Energie",
	"Ground color": "Couleur moulue",
	"Whole color": "Couleur grains",
	"Open in Artisan as background template (Artisan >2.4.4)": "Ouvrir comme modèle d'arrière-plan (Artisan >2.4.4)",
	"Open in Artisan": "Ouvrir dans Artisan",
	"CHARGE": "CHARGE",
	"DROP": "VIDAGE",
	"DEV": "DEV",
	"Temperature": "Température",
	"Pressure": "Pression",
	"Humidity": "Humidité ambiante",
	"CM ET/BT": "CM TE/TG",
	"AUC": "SSC",
	"Template": "Modèle",
	"loss": "perte",
	"reconciled roast info": "Cet élément a été placé dans un rapport finalisé et la plupart des données ne peuvent plus être modifié.",
	"Weight in {{mainUnit}}": "Poids en {$INTERPOLATION}",
	"no stores": "S'il vous plaît d'abord ajoutez un magasin!",
	"no beans": "S'il vous plaît ajouter d'abort des grains!",
	"grams per liter": "g/l",
	"Moisture": "Humidité vert",
	"discarded": "détruit",
	"page not found text": "Nous travaillons dur pour ajouter le contenu que vous voulez ici!",
	"Update available": "Mise à jour disponible",
	"Label changelog page": "Quoi de neuf?",
	"UPDATE": "MISE À JOUR",
	"last changed": "Dernier changement {$INTERPOLATION}",
	"created by": "par {$INTERPOLATION}",
	"updated by": "par {$INTERPOLATION}",
	"Added {{date}}": "Ajouté {$INTERPOLATION}",
	"Loading roasts": "Chargement des torréfactions ...",
	"sort order user": "Utilisateur",
	"Repeat every": "Répéter chaque",
	"Repeat on": "Répéter le",
	"Ends": "Finit",
	"Filter": "Filtre",
	"Machine": "Machine",
	"roast date": "Date de torréfaction",
	"batch number": "Numéro de lot",
	"Show roasts from": "Afficher torréfactions de",
	"no match for filter": "Aucun résultat",
	"Filter ...": "Filtrer ...",
	"Find / Add ...": "Filtrer / Ajouter ...",
	"Amount of beans available": "Disponible:",
	"Ratio": "Ratio",
	"Check out your {{newPartners}} new LINKSTART_supplier partners_LINKEND!": "Découvrez vos {$INTERPOLATION} nouveaux LINKSTART_fournisseurs partenaires_LINKEND!",
	"Check out your new LINKSTART_supplier partner_LINKEND!": "Découvrez votre nouveau LINKSTART_fournisseurs partenaires_LINKEND!",
	"No coupon available, please email info@artisan.plus": "Aucun coupon disponible, veuillez envoyer un courriel à info@artisan.plus",
	"Graphs of your data are shown here as soon as there is enough data": "Des graphiques de vos données sont affichés ici dès qu'il y a suffisamment de données.",
	"to customer": "à",
	"list header": "Nombre de transactions: {$INTERPOLATION}",
	"x% of a blend": "({$INTERPOLATION} d'un mélange)",
	"of a blend": "d'un mélange",
	"between dates": "entre {$INTERPOLATION} et {$INTERPOLATION_1}",
	"no purchases made": "Aucun achat effectué. Les coûts sont calculés en utilisant uniquement les achats. Pensez à ajouter un achat ou à remplacer une correction par un achat.",
	"to target": "à",
	"amount header": "Volume",
	"limit header": "Limite pour Alerte de Stock",
	"add purchase": "Ajouter un achat",
	"edit purchase": "Modifier l'achat",
	"pick beans from list": "Choisissez des grains",
	"pick supplier from list": "Choisissez le fournisseur",
	"last purchase/correction price info": "{$INTERPOLATION}{$INTERPOLATION_1} pour {$INTERPOLATION_2} le {$INTERPOLATION_3}",
	"no last purchase": "Aucun prix d'achat précédent disponible.",
	"Loading last purchase price": "Chargement du dernier achat ...",
	"add a new supplier": "Ajouter un nouveau fournisseur",
	"rename": "Renommer",
	"select coffee": "S'il vous plaît sélectionnez d'abord les grains!",
	"tracking_number": "Numéro de suivi",
	"product_id": "Produit ID",
	"add sale": "Ajouter une vente",
	"edit sale": "Modifier la vente",
	"pick customer from list": "Choisir le client",
	"total price": "Prix total",
	"Price per": "Prix par",
	"from source store": "De",
	"no stores with beans": "Vous n'avez pas de magasins avec des grains.",
	"add a new customer": "Ajouter un nouveau client",
	"correction title": "Corrigez votre stock",
	"edit correction title": "Modifier la correction",
	"bought beans": "Acheté des grains",
	"add a purchase": "ajouter un achat",
	"sold beans": "Vendu des grains",
	"add a sale": "ajouter une vente",
	"moved beans": "Transféré des grains",
	"add a transfer": "ajouter un transfert",
	"other reason": "Autre raison",
	"new amount": "Nouveau montant",
	"new weight in": "Nouveau poids en {$INTERPOLATION}",
	"delta amount": "Différence de montant",
	"delta weight in": "Différence de poids en {$INTERPOLATION}",
	"show delta amounts": "Entrez la différence de poids à la place",
	"purchase": "Acheter",
	"sell": "Vendre",
	"transfer": "Transferer",
	"correct": "Corriger",
	"store with xkg of beans": "avec {$INTERPOLATION} des grains",
	"add transfer": "Ajouter un transfert",
	"edit transfer": "Modifier le transfert",
	"Please select a source store first!": "Veuillez d'abord sélectionner un magasin source!",
	"are you sure": "Êtes-vous sûr?",
	"Upload documents": "Charger des documents",
	"you have been invited": "Vous avez été invité à vous connecter au compte de {$INTERPOLATION}",
	"you have been invited, readonly": "Vous avez été invité à voir les données du compte de {$INTERPOLATION}",
	"add account": "AJOUTER UN COMPTE",
	"register_new_password": "Nouveau mot de passe",
	"Success! You can now log in with your new password.": "Succès! Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.",
	"add user to account": "Ajouter un utilisateur à votre compte",
	"invite for read-only access": "Inviter pour un accès en lecture seule",
	"User with email already invited": "Utilisateur avec e-mail {$INTERPOLATION} déjà invité",
	"invalid email": "e-mail invalide",
	"get full access": "La personne aura un accès complet à vos données.",
	"get read-only access": "La personne peut lire toutes vos données.",
	"Set stock to 0": "Mettre le volume à 0",
	"Fill with": "Remplir avec",
	"not paid": "EXPIRÉ",
	"visit our store": "S'il vous plaît visitez notre boutique pour prolonger votre abonnement",
	"0 paid days left": "0 jours restants",
	"1 paid days left": "1 jour restant",
	"2 paid days left": "2 jours restant",
	"3 paid days left": "3 jours restant",
	"No changes possible": "Aucun changement possible",
	"greeting": "Salut",
	"Your account": "Mon Compte",
	"logout": "SE DÉCONNECTER",
	"for": "pour",
	"register link": "S'INSCRIRE",
	"You can always visit our shop to extend your subscription": "Vous pouvez toujours visiter notre boutique pour prolonger votre abonnement",
	"login link": "S'IDENTIFIER",
	"paid until, extend here": "Payé jusqu'au {$INTERPOLATION} (prolonger ici)",
	"Beans menu label": "Grains",
	"Blends menu label": "Mélanges",
	"Roasts menu label": "Torréfactions",
	"Contacts menu label": "Contacts",
	"Reports menu label": "Rapports",
	"legal notice": "Mention légale",
	"privacy statement": "Politique de confidentialité",
	"Shop": "Boutique Internet",
	"resend verification email": "Renvoyer l'e-mail de vérification",
	"email": "Email",
	"password": "Mot de passe",
	"reset_password": "RÉINITIALISER LE MOT DE PASSE",
	"login button": "S'IDENTIFIER",
	"nickname": "Surnom",
	"register button": "S'INSCRIRE",
	"Partner Coupons": "Coupons Partenaires",
	"accept privacy": "J'accepte la politique de confidentialité:",
	"read here": "lire",
	"Month": "Mois",
	"register_password_repeat": "Répéter le mot de passe",
	"update password": "METTRE À JOUR LE MOT DE PASSE",
	"inventory management": "gestion d'inventaire",
	"How can artisan.plus support you?": "Comment artisan.plus peut vous aider?",
	"every roast automatically reduces your stock": "chaque torréfaction réduit automatiquement votre stock",
	"simplifies planning": "simplifie la planification et l'exécution de vos torréfactions",
	"roast even without an internet connection": "fonctionne avec et sans connexion Internet",
	"keeps your roast profiles private": "garde vos profils de torréfaction privés",
	"includes unlimited users and machines": "des utilisateurs et des machines illimités inclus",
	"blends and certifications": "maintient les mélanges et guarde une vue des les certifications",
	"charts and tax reports": "propose des graphiques, des rapports fiscaux et des prévisions",
	"imports coffee descriptions": "prend en charge l'importation de données Bean à partir d' importateurs",
	"3 months free testing": "3 mois gratuits - obtenez toutes les fonctionnalités à partir de 42€/mois",
	"includes unlimited warehouses and bean quantities": "des magasins et des quantités de haricots illimités inclus",
	"description 1": "Combien de temps mes grains dureront-ils?",
	"description 2": "Combien vaut mon stock?",
	"description 3": "Quels types de grains ai-je en stock?",
	"description 4": "Comment capturer efficacement mes torréfactions?",
	"description 5": "Comment vais-je?",
	"description 6": "Comment créer des rapports?"
}