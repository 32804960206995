import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FileShowComponent } from './file-upload/file-show.component';

@NgModule({
    declarations: [
        FileUploadComponent,
        FileShowComponent,
    ],
    imports: [
        CommonModule,
        MyMaterialModule,
        MatProgressBarModule,
        FormsModule,
    ],
    providers: [
    ],
    exports: [
        FileShowComponent,
    ],
})
export class FileUploadModule { }
