<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>


<div class="date-range-flex-div">
    <span class="flex-end" style="flex-wrap: wrap; margin-bottom: 10px;">
        <mat-form-field class="date-input-ff1" subscriptSizing="dynamic">
            <mat-label i18n="reports$$start date@@start date">From</mat-label>
            <input matInput required name="startDate"
                [matDatepicker]="myDatepicker"
                placeholder="From" i18n-placeholder="reports$$start date@@start date"
                [(ngModel)]="startDate"
                (dateChange)="datesChanged()">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="date-input-ff1" style="margin-right: 10px;" subscriptSizing="dynamic">
            <mat-label i18n="reports$$end date@@end date">To</mat-label>
            <input matInput required name="endDate"
                [matDatepicker]="myDatepicker2"
                [min]="startDate"
                placeholder="To" i18n-placeholder="reports$$end date@@end date"
                [(ngModel)]="endDate"
                (dateChange)="datesChanged()">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker2></mat-datepicker>
        </mat-form-field>
        <!-- showstockfrom filter -->
        @if (stores?.length > 1) {
        <mat-form-field class="narrow-select" subscriptSizing="dynamic">
            <mat-label i18n="locationtypes$$LocationTypes@@Stores">Stores</mat-label>
            <mat-select multiple matInput name="showstockfrom" panelWidth="null"
                placeholder="Stores" i18n-placeholder="locationtypes$$LocationTypes@@Stores"
                [value]="!ssf ? stores : ssf"
                [compareWith]="utils.compareObjectsFn"
                (openedChange)="showstockfromOpened($event)"
                (selectionChange)="showstockfromChanged($event)">
                <mat-select-trigger class="twoLineOption ellipsed">
                    @if (ssf?.length === 1) {
                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: ssf[0]}"></ng-container>
                    } @else if (!ssf?.length || ssf?.length === stores.length) {
                    <span i18n="generic$$indicating everything is selected@@all">all</span>
                    } @else if (ssf?.length > 1 && stores.length > ssf.length) {
                    {{ssf.length}} <span i18n="locationtypes$$LocationTypes@@Stores">Stores</span>
                    }
                </mat-select-trigger>
                @for (store of stores; track store?._id) {
                <mat-option [value]="store"><ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container></mat-option>
                }
            </mat-select>
        </mat-form-field>
        }
        <!-- label -->
        <mat-form-field class="label-input" subscriptSizing="dynamic">
            <mat-label i18n="generic$$Title@@Title">Title</mat-label>
            <input matInput required name="label" type="text"
                placeholder="Title" i18n-placeholder="generic$$Title@@Title"
                [(ngModel)]="label"
                (input)="labelWasTouched = true">
        </mat-form-field>
    </span>

    <div style="margin-right: 4px; white-space: nowrap;">
        <button mat-button type="button" class="cancel-button" (click)="cancel()">
            <mat-icon>close</mat-icon>
        </button>
        @if (transactions) {
        <button [disabled]="!startDate || !endDate || !label" mat-raised-button type="submit" class="action-button" (click)="createReport()">
            <mat-icon>done</mat-icon>
        </button>
        }
        @if (invalidTransactions?.length && dataSourceInvalids) {
        <div style="white-space: nowrap; margin-left: 5px; margin-bottom: 5px;">
            <a (click)="scrollToMissing()" (keydown.enter)="scrollToMissing(); $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_missingRoasts" class="error-reason cursor-pointer">
                <span i18n="reports$$link to a list of items (such as roasts or purchases) that are not included in the curent report@@Missing items">Missing items</span>
                <mat-icon style="vertical-align: bottom;">arrow_drop_down</mat-icon>
            </a>
        </div>
        }
    </div>
</div>

@if (!transactions) {
<mat-spinner diameter="20"></mat-spinner>
<!-- <span class="spinner-with-comment">
    <mat-spinner diameter="20"></mat-spinner> <span class="spinner-comment" i18n="generic$$currently loading items@@Loading">Loading ...</span>
</span> -->
}

<div class="transaction-types">
    <mat-checkbox class="primary-element transaction-type-checkbox"
        (change)="typesChanged()"
        [(ngModel)]="selectedTypes.Purchase"><span i18n="transaction$$transaction title@@Purchase">Purchase</span>
    </mat-checkbox>
    <mat-checkbox class="primary-element transaction-type-checkbox"
        (change)="typesChanged()"
        [(ngModel)]="selectedTypes.Sale"><span i18n="transaction$$transaction title@@Sale">Sale</span>
    </mat-checkbox>
    <mat-checkbox class="primary-element transaction-type-checkbox"
        (change)="typesChanged()"
        [(ngModel)]="selectedTypes.Correction"><span i18n="transaction$$transaction title@@Correction">Correction</span>
    </mat-checkbox>
    <mat-checkbox class="primary-element transaction-type-checkbox"
        (change)="typesChanged()"
        [(ngModel)]="selectedTypes.Transfer"><span i18n="transaction$$transaction title@@Transfer">Transfer</span>
    </mat-checkbox>

    <app-organicicon class="cursor-pointer" [leftMargin]="10"
        (click)="showOrganicChanged()"
        [isOrganic]="true"
        [inactive]="!showOrganic"
        [strikethrough]="showOrganic === 'off'"
        [matTooltip]="!showOrganic ? tr.anslate('Show only items with organic certification') : ''"
        i18n-matTooltip="generic$$tooltip, filter for organic certifications@@Show only items with organic certification">
    </app-organicicon>
</div>

@if (dataSource) {
<app-beansreport-table #table
    [currentUser]="currentUser"
    [dataSource]="dataSource"
    [columnsToDisplay]="columnsToDisplay"
    [additionalIsEditable]="true"
    [editMode]="true"
    [readOnly]="readOnly"
    [reportName]="reportName">
</app-beansreport-table>
}


<span name="hiddenElems" id="hiddenElems" #hiddenElems></span>
<br />
@if (invalidTransactions?.length && dataSourceInvalids) {
<div class="other-table-heading" i18n="reports$$invalid transactions not included, header@@invalid transactions not included">The following transactions will not be included in the report since important information is missing:</div>

<app-beansreport-table #invalidsTable
    [currentUser]="currentUser"
    [dataSource]="dataSourceInvalids"
    [columnsToDisplay]="columnsToDisplayInvalids"
    [additionalIsEditable]="true"
    [readOnly]="readOnly"
    [editMode]="true"
    [reportName]="reportName">
</app-beansreport-table>
}


<div class="enlargepage"></div>