<mat-dialog-content style="max-width: 450px;">
    @if (!purchases) {
    <mat-spinner diameter="30" strokeWidth="2" class="center-spinner"></mat-spinner>
    }

    @if (purchases && !purchases.length) {
    <div i18n="transaction$$explain that cost of beans can only be calculated if there are purchases@@no purchases made">No purchases made. Costs are calculated using purchases only. Consider adding a purchase or replacing a correction with a purchase.</div>
    }

    @if (purchases?.length) {
    <div>
        <ng-container i18n="transaction$$explains how the average cost is calculated for FIFO@@average FIFO cost comes from">The average of <b>{{averageCost / unitFactor | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}}</b> comes from the current cost of {{totalCost | currency:currency:'symbol-narrow':'1.2-2'}} for your current stock of {{data.totalAmount * unitFactor | number:'1.0-1'}}&nbsp;{{data.totalAmount === 1 ? mainUnitSingular : mainUnit}} beans</ng-container>@if (showCorrectionHint) {
        <span>&nbsp;*</span>
        }:
    </div>
    <ul style="margin-left: -20px; margin-top: 20px;">
        @for (purchase of purchases; track purchase?._id) {
        <li>
            <div>
                <span>{{tr.anslate(purchase.type)}}:</span>
                {{purchase.amount | number:'1.0-1'}}{{mainUnit}}
                @if (purchase.price) {
                <span class="littlespaceright"> <span i18n="transaction$$indicates the price of a transaction, e.g. 3kg _for_ 100€@@for price">for</span> {{purchase.price | currency:currency:'symbol-narrow':'1.2-2'}}</span>
                }
                (<b>{{fifoData[$index].amount | number:'1.0-1'}}{{mainUnit}} => {{fifoData[$index].cost | currency:currency:'symbol-narrow':'1.2-2'}}</b>)
            </div>
            @if (purchase.type === 'Purchase' && purchase.supplier?.label) {
            <div class="secondLineIndent">
                <span class="clipped-string"><span i18n="transaction$$transaction, e.g. a transfer, _from_ a source location@@from source" class="littlespaceright">from</span> <a [routerLink]="['/contacts', {id: purchase.supplier?._id}]" (click)="closeDialog()" tabindex="-1">{{purchase.supplier.label}}</a></span>
            </div>
            }
            @if (purchase.location?.label) {
            <div class="secondLineIndent">
                <span class="clipped-string"><span i18n="transaction$$transaction, e.g. a transfer, _to_ a target location@@to target" class="littlespaceright">to</span> <a [routerLink]="['/stores', {id: purchase.location.hr_id ? purchase.location.hr_id : 'L' + purchase.location.internal_hr_id}]" (click)="closeDialog()" tabindex="-1">{{purchase.location.label}}</a></span>
            </div>
            }
            <div class="secondLineIndent">
                @if (purchase.date) {
                <span i18n="transaction$$e.g. on 09.12.1978@@on {{date}}">on {{purchase.date.toLocaleString(DateTime.DATE_MED)}}</span>
                }
                @if (!Number.isNaN(Number(purchase.price / purchase.amount))) {
                <span class="littlespaceleft littlespaceright">({{purchase.price / purchase.amount | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}})</span>
                }
                @if (!data.readOnly && !purchase.reconciled) {
                <a (click)="editTransaction(purchase)" (keydown.enter)="editTransaction(purchase); $event.preventDefault()" role="button" [tabindex]="0" class="littlespaceleft" tabindex="-1">
                    <mat-icon class="smallicon">edit</mat-icon>
                </a>
                }
                <!-- <a *ngIf="!purchase.reconciled" (click)="deleteTransaction(purchase)" tabindex="-1"><mat-icon class="smallicon smallicon-second">delete</mat-icon></a> -->
            </div>
        </li>
        }
    </ul>
    @if (showCorrectionHint) {
    <div style="margin-top: 10px">
        (*)&nbsp;
        <ng-container i18n="transactions$$Corrections are not listed@@Corrections are not listed">Corrections are not listed</ng-container>
    </div>
    }
    }
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button ng-submit autofocus class="action-button" mat-dialog-close type="submit">
        <mat-icon>done</mat-icon>
    </button>
</mat-dialog-actions>