<h1 mat-dialog-title>{{data?.error ? data.error : data?.title?.text}} @if (data?.title?.wait) {
    <mat-spinner diameter="15" style="display: inline; vertical-align: text-top; margin-left: 10px;"></mat-spinner>
    }
</h1>
<mat-dialog-content>
    @if (data?.error) {
    {{data.errorMsg}}
    }
    @if (data && !data.error && data.info) {
    <ul>
        @for (item of data?.info; track item?.label) {
        <li><b>{{item.label}}</b>:
            @if (item.isLink) {
            <a href="{{item.value}}" target="_blank" rel="noopener">{{item.value}}</a>
            } @else {
            {{item.value}}
            }
        </li>
        }
    </ul>
    }
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-button" autofocus cdkFocusInitial mat-button type="submit" mat-dialog-close tabindex="0">
        <mat-icon>close</mat-icon>
    </button>
    @if (data?.info && !data?.error) {
    <button class="action-button" mat-raised-button type="button" tabindex="1" (click)="applyInfo()">
        <mat-icon>archive</mat-icon>
        <!-- <mat-icon>file_download</mat-icon> -->
        <!-- <mat-icon>approval</mat-icon> -->
    </button>
    }
</mat-dialog-actions>