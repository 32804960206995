<div class="action-button-container">
    <!-- label -->
    <div class="report-container">
        <mat-form-field class="label-input">
            <mat-label i18n="generic$$Title@@Title">Title</mat-label>
            <input required type="text" matInput name="label" data-test="npt_label"
                placeholder="Title" i18n-placeholder="generic$$Title@@Title"
                [(ngModel)]="label" (input)="labelWasTouched = true">
        </mat-form-field>
    </div>

    @if ((invalidRoasts?.length && invalidsReport) || (!this.lastEndDate && notInRangeReport?.roasts?.length)) {
    <div style="white-space: nowrap; margin-left: 5px; margin-bottom: 5px;">
        <a (click)="scrollToMissing()" (keydown.enter)="scrollToMissing(); $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_missingRoasts" class="error-reason"
            style="cursor: pointer;">
            <span i18n="reports$$link to a list of items (such as roasts or purchases) that are not included in the curent report@@Missing items">Missing items</span>
            <mat-icon style="vertical-align: bottom;">arrow_drop_down</mat-icon>
        </a>
    </div>
    }

    <span class="newroastreport-button-group">
        <button mat-button type="button" data-test="bttn_cancel" class="cancel-button"
            (click)="cancel()">
            <mat-icon>close</mat-icon>
        </button>

        @if (!saving && report?.roasts) {
        <button [disabled]="!currentFilter.from || !currentFilter.to || !label"
            (click)="createReport()" data-test="bttn_done" mat-raised-button type="submit" class="action-button">
            <mat-icon>done</mat-icon>
        </button>
        } @else if (report?.roasts) {
        <button disabled mat-raised-button class="action-button spinner-button">
            <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
        </button>
        }
    </span>
</div>

<div class="date-range-flex-div">
    <span class="flex-end" style="flex-wrap: wrap;">
        <!-- dates filter, only for fixed reports, otherwise roasts-filter is used -->
        @if (!isOpenReport) {
        <span class="nobreak" style="margin-right: 25px;">
            <mat-form-field class="date-input-ff1" subscriptSizing="dynamic">
                <mat-label i18n="reports$$start date@@start date">From</mat-label>
                <input [disabled]="!!lastEndDate" required matInput
                    data-test="npt_startDate"
                    placeholder="From" i18n-placeholder="reports$$start date@@start date"
                    [matDatepicker]="myDatepicker" name="startDate"
                    [(ngModel)]="currentFilter.from" (dateChange)="datesChanged(true)">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="date-input-ff2" subscriptSizing="dynamic">
                <mat-label i18n="reports$$end date@@end date">To</mat-label>
                <input required data-test="npt_endDate" matInput
                    placeholder="To" i18n-placeholder="reports$$end date@@end date"
                    [matDatepicker]="myDatepicker2" name="endDate"
                    [(ngModel)]="currentFilter.to" (dateChange)="datesChanged()"
                    [min]="currentFilter.from">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker2></mat-datepicker>
            </mat-form-field>
        </span>
        @if (currentFilter.anpa || (invalidRoasts?.length && invalidsReport) || (!this.lastEndDate && notInRangeReport?.roasts?.length)) {
        <mat-checkbox style="margin-left: -11px;"
            data-test="tggl_nonPositiveAmounts"
            [(ngModel)]="currentFilter.anpa"
            (change)="allowablesChanged()"
            i18n="reports$$toggle label to allow roasts with empty amounts/weights to be included in a report@@allow empty weights">Allow empty weights</mat-checkbox>
        }
        } @else { <!-- isOpenReport -->
        <app-roasts-filter style="margin-right: 10px; margin-left: -10px;"
            [currentUser]="currentUser"
            [showAlways]="true"
            [showCancelDates]="false"
            [hideSearchDetails]="!report?.roasts?.length"
            [stores]="stores"
            [readOnly]="readOnly"
            [isDarkmode]="isDarkmode"
            [energyUnit]="energyUnit"
            [filters]="currentFilter"
            (filterChanged)="filterChanged($event)">
        </app-roasts-filter>
        }

        @if (waiting || waiting2 || waiting3 || waiting4) {
        <mat-spinner diameter="20" class="inline-spinner"></mat-spinner>
        }
    </span>

    @if (isOpenReport && (currentFilter.anpa || (invalidRoasts?.length && invalidsReport) || (!this.lastEndDate && notInRangeReport?.roasts?.length))) {
    <mat-checkbox style="margin-left: -11px;"
        data-test="tggl_nonPositiveAmounts"
        [(ngModel)]="currentFilter.anpa"
        (change)="allowablesChanged()"
        i18n="reports$$toggle label to allow roasts with empty amounts/weights to be included in a report@@allow empty weights">Allow empty weights</mat-checkbox>
    }
</div>

<div class="newroastreport-main-content">
    @if ((reportOverview?.blends?.length ?? 0) + (reportOverview?.coffees?.length ?? 0) > 2) {
    <div class="listofroasts-link">
        <a (click)="scrollToListOfRoasts()" (keydown.enter)="scrollToListOfRoasts(); $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_listofroasts" class="listofroasts-items-link cursor-pointer">
            <span i18n="roasts$$link or heading to a list of roasts@@List of roasts">List of roasts</span>
            <mat-icon style="vertical-align: bottom;">arrow_drop_down</mat-icon>
        </a>
    </div>
    }

    @if (reportOverview) {
    <app-roastreport-overview
        [currentUser]="currentUser"
        [reportOverview]="reportOverview"
        [showExportUI]="false"
        [isOpenReport]="isOpenReport">
    </app-roastreport-overview>
    }

    <span name="reportTableElem" id="reportTableElem" #reportTableElem></span>
    @if (report?.roasts) {
    <app-roastreport-table #table data-test="table"
        [currentUser]="currentUser"
        [(report)]="report"
        [isOpenReport]="isOpenReport"
        [columnsToDisplay]="columnsToDisplay"
        [additionalIsEditable]="true"
        [showTotals]="true"
        [showExportUI]="false"
        [reportName]="reportName"
        (loadMoreClick)="loadAllRoastsForReport('normal', $event)">
    </app-roastreport-table>
    }


    <span name="hiddenElems" id="hiddenElems" #hiddenElems></span>
    <br />
    @if ((invalidRoasts?.length && invalidsReport) || (!this.lastEndDate && notInRangeReport?.roasts?.length)) {
    <mat-checkbox class="mat-left-padding other-table-heading"
        [(ngModel)]="currentFilter.anpa"
        (change)="allowablesChanged()"
        i18n="reports$$toggle label to allow roasts with empty amounts/weights to be included in a report@@allow empty weights">Allow empty weights</mat-checkbox>
    }

    @if (invalidRoasts?.length && invalidsReport) {
    <div data-test="dv_invalidHeader" class="mat-padding" style="margin-left: 15px;"
        i18n="reports$$invalid roasts not included, header@@invalid roasts not included">The following roasts will not be included in the report since important information is missing:</div>
    }
    @if (invalidsReport?.roasts?.length) {
    <app-roastreport-table #invalidsTable data-test="invalidsTable"
        [currentUser]="currentUser"
        [invalids]="true"
        [report]="invalidsReport"
        [isOpenReport]="isOpenReport"
        [columnsToDisplay]="columnsToDisplayInvalids"
        [showTotals]="false"
        [showExportUI]="false"
        [reportName]="reportName"
        (loadMoreClick)="loadAllRoastsForReport('invalids', $event)">
    </app-roastreport-table>
    }

    <br />
    @if (!this.lastEndDate && notInRangeReport?.roasts?.length) {
    <div data-test="dv_beforeHeader"
        class="mat-padding other-table-heading" style="margin-left: 15px;"
        i18n="reports$$roasts with a date before the start date not included, header@@roasts not in range are excluded">
        The following roasts will not be included in the report since their roast date is before the selected period:</div>

    <app-roastreport-table
        #notinrangeTable data-test="tbl_notInRange"
        [currentUser]="currentUser"
        [invalids]="true"
        [report]="notInRangeReport"
        [isOpenReport]="isOpenReport"
        [columnsToDisplay]="columnsToDisplayInvalids"
        [showTotals]="false"
        [showExportUI]="false"
        [reportName]="reportName">
    </app-roastreport-table>
    }
</div>

<div class="enlargepage"></div>