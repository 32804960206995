!function (t, n) {
  "object" == typeof exports && "object" == typeof module ? module.exports = n() : "function" == typeof define && define.amd ? define([], n) : "object" == typeof exports ? exports.ChartjsPluginStacked100 = n() : t.ChartjsPluginStacked100 = n();
}(self, () => (() => {
  "use strict";

  var t = {
      d: (n, a) => {
        for (var r in a) t.o(a, r) && !t.o(n, r) && Object.defineProperty(n, r, {
          enumerable: !0,
          get: a[r]
        });
      },
      o: (t, n) => Object.prototype.hasOwnProperty.call(t, n),
      r: t => {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
          value: "Module"
        }), Object.defineProperty(t, "__esModule", {
          value: !0
        });
      }
    },
    n = {};
  t.r(n), t.d(n, {
    default: () => g
  });
  var a = function (t, n, a) {
      if (a || 2 === arguments.length) for (var r, e = 0, o = n.length; e < o; e++) !r && e in n || (r || (r = Array.prototype.slice.call(n, 0, e)), r[e] = n[e]);
      return t.concat(r || Array.prototype.slice.call(n));
    },
    r = function (t) {
      return "object" === typeof t && !!t;
    },
    e = function (t, n, a) {
      if (r(t)) {
        var e = function (t, n) {
          return t && t["".concat(n, "AxisKey")] || n;
        }(a, n ? "x" : "y");
        return t[e];
      }
      return t;
    },
    o = function (t) {
      t.originalData = t.datasets.map(function (t) {
        return n = t.data, a([], n, !0);
        var n;
      });
    },
    i = function (t, n) {
      var a = Math.pow(10, n);
      return Math.round(100 * t * a) / a;
    },
    c = function (t, n) {
      var a = Math.pow(10, n);
      return Math.floor(100 * t * a) / a;
    },
    u = function (t, n) {
      var a = Math.pow(10, n);
      return Math.ceil(100 * t * a) / a;
    },
    f = function () {
      return f = Object.assign || function (t) {
        for (var n, a = 1, r = arguments.length; a < r; a++) for (var e in n = arguments[a]) Object.prototype.hasOwnProperty.call(n, e) && (t[e] = n[e]);
        return t;
      }, f.apply(this, arguments);
    },
    l = function (t, n, a) {
      if (a || 2 === arguments.length) for (var r, e = 0, o = n.length; e < o; e++) !r && e in n || (r || (r = Array.prototype.slice.call(n, 0, e)), r[e] = n[e]);
      return t.concat(r || Array.prototype.slice.call(n));
    },
    s = Symbol(),
    d = function (t, n, a, e, o) {
      if (!r(n)) return o;
      var i = a && a["".concat(e ? "y" : "x", "AxisKey")];
      if (!i) return o;
      var c = n[i];
      if (!c) return o;
      var u = t.findIndex(function (t) {
        return t === c;
      });
      return u < 0 ? o : u;
    },
    p = function (t, n) {
      return !n || (t.xAxisID || t.yAxisID) === n;
    },
    v = function (t, n, a, r, o, f, v, y) {
      void 0 === f && (f = "off");
      var b = function (t, n, a, r, o, i) {
          var c,
            u = t.labels,
            f = t.datasets,
            v = u.length,
            y = null === (c = null == f ? void 0 : f[0]) || void 0 === c ? void 0 : c.stack,
            b = l([], new Array(v), !0).map(function (t, c) {
              return f.filter(function (t) {
                return p(t, o);
              }).reduce(function (t, o, f) {
                var l = o.parsing || i,
                  p = o.stack || s,
                  v = o.data.find(function (t, n) {
                    return d(u, t, l, a, n) == c;
                  });
                t[p] || (t[p] = 0);
                var b = Math.abs(e(v, a, l) || 0) * n[f];
                return r && !y ? t[p] < b && (t[p] = b) : t[p] += b, t;
              }, {});
            });
          return y && r ? b.map(function (t) {
            var n = Math.max.apply(Math, Object.values(t));
            return Object.keys(t).forEach(function (a) {
              return t[a] = n;
            }), t;
          }) : b;
        }(t, n, a, o, v, y),
        h = "off" === f ? i : "down" === f ? c : u;
      return t.datasets.map(function (n) {
        var o = p(n, v),
          i = new Array(t.labels.length);
        return n.data.forEach(function (c, u) {
          var f = n.parsing || y,
            l = e(c, a, f),
            p = d(t.labels, c, f, a, u);
          if (o) {
            var v = n.stack || s,
              g = b[p][v];
            i[p] = l && g ? h(l / g, r) : 0;
          } else i[p] = l;
        }), i;
      });
    },
    y = function (t, n) {
      t && t.forEach(function (t, a) {
        n[a].data = t;
      });
    },
    b = function (t) {
      return "y" === t.options.indexAxis;
    },
    h = function (t, n, a, r) {
      var e = f(f({
        stacked: a
      }, r), t.options.scales[n]);
      t.options.scales[n] = e;
    };
  const g = {
    id: "stacked100",
    beforeInit: function (t, n, a) {
      if (a.enable) {
        var r = a.replaceTooltipLabel,
          o = void 0 === r || r,
          i = a.fixNegativeScale,
          c = void 0 === i || i,
          u = a.individual,
          l = void 0 !== u && u,
          s = b(t),
          v = s ? "x" : "y",
          y = function (t, n) {
            var a = {
              max: 100
            };
            return t ? n ? f({
              min: -100
            }, a) : a : f({
              min: 0
            }, a);
          }(t.data.datasets.some(function (n) {
            return n.data.some(function (a) {
              return (e(a, s, n.parsing || t.options.parsing) || 0) < 0;
            });
          }), c);
        a.axisId ? h(t, a.axisId, !l, y) : ["x", "y"].forEach(function (n) {
          h(t, n, !l, n === v ? y : {});
        }), o && (t.options.plugins.tooltip.callbacks.label = function (t, n) {
          return function (a) {
            var r = a.chart.data,
              o = a.datasetIndex,
              i = a.dataIndex,
              c = r.datasets[o].label || "",
              u = r.datasets[o].parsing || a.chart.options.parsing,
              f = r.originalData[o].find(function (n, a) {
                return d(r.labels, n, u, t, a) == i;
              }),
              l = r.calculatedData[o][i],
              s = e(f, t, u);
            return p(r.datasets[o], n) ? "".concat(c, ": ").concat(l, "% (").concat(s, ")") : "".concat(c, ": ").concat(l);
          };
        }(s, a.axisId));
      }
    },
    beforeUpdate: function (t, n, a) {
      if (a.enable) {
        var r = t.data;
        o(r);
        var e = r.datasets.map(function (n, a) {
            var r, e;
            return (null !== (e = null === (r = t.getDatasetMeta(a)) || void 0 === r ? void 0 : r.hidden) && void 0 !== e ? e : n.hidden) ? 0 : 1;
          }),
          i = function (t) {
            if (!("precision" in t)) return 1;
            if ("number" != typeof t.precision) return 1;
            var n = Math.floor(t.precision);
            return n < 0 || n > 16 ? 1 : n;
          }(a);
        r.calculatedData = v(r, e, b(t), i, a.individual, a.roundOption, a.axisId, t.options.parsing), y(r.calculatedData, r.datasets);
      }
    },
    afterUpdate: function (t, n, a) {
      if (a.enable) {
        var r = t.data;
        y(r.originalData, r.datasets);
      }
    }
  };
  return n;
})());