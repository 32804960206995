<!-- Roast Graphs -->

<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>


@if (waitingForGraphLongRunning) {
<div class="graph-spinner">
    <mat-spinner diameter="20"></mat-spinner>
</div>
}

@if (haveSomeData || filters.ssf !== 'all' || filters.showOrganic || filters.origins?.vals?.length || (filters.from?.isValid && filters.to?.isValid)) {
<div class="filter-bar">
    <!-- date filter -->
    <div class="nobreak" style="display: inline-block" [ngStyle]="{'margin-right': filters.from?.isValid || filters.to?.isValid ? '6px' : '32px'}">
        <mat-form-field class="date-input-narrow left-margin" subscriptSizing="dynamic">
            <mat-label i18n="reports$$start date@@start date">From</mat-label>
            <input matInput name="startDate" data-test="npt_startDate"
                placeholder="From" i18n-placeholder="reports$$start date@@start date"
                [ngModel]="filters.from?.isValid ? filters.from : undefined"
                (dateChange)="filterDatesChanged($event, true)"
                [matDatepicker]="myDatepicker">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepicker">
                <mat-icon matDatepickerToggleIcon>today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="date-input-narrow left-margin" subscriptSizing="dynamic">
            <mat-label i18n="reports$$end date@@end date">To</mat-label>
            <input matInput [min]="filters.from" name="endDate" data-test="npt_endDate"
                placeholder="To" i18n-placeholder="reports$$end date@@end date"
                [ngModel]="filters.to?.isValid ? filters.to : undefined"
                (dateChange)="filterDatesChanged($event, false)"
                [matDatepicker]="myDatepickerEnd">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepickerEnd">
                <mat-icon matDatepickerToggleIcon>event</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #myDatepickerEnd></mat-datepicker>
        </mat-form-field>
        @if (filters.from?.isValid || filters.to?.isValid) {
        <button mat-icon-button class="primary-element" (click)="cancelFilterDates()">
            <mat-icon class="overview-close-icon" style="vertical-align: middle; margin-right: 2px;">close</mat-icon>
        </button>
        }
    </div>
    <!-- origin filter -->
    @if (allOrigins?.length) {
    <mat-form-field class="origin-select" subscriptSizing="dynamic">
        <mat-label i18n="graphs$$graph label@@Origins">Origins</mat-label>
        <mat-select multiple panelWidth="null"
            [compareWith]="utils.compareObjectsFn" name="origins"
            placeholder="Origins" i18n-placeholder="graphs$$graph label@@Origins"
            [ngModel]="filters.origins?.vals"
            (selectionChange)="originFilterChanged($event)">
            @if (filters.origins?.vals?.length) {
            <mat-select-trigger>
                {{translatedOrigins.join(', ')}}
            </mat-select-trigger>
            }
            <mat-option>
                <ngx-mat-select-search ngModel (ngModelChange)="filteredOrigins = filterOrigins($event)" name="originSelect"
                    placeholderLabel="Selection" i18n-placeholderLabel="beans$$beans property selection@@Selection"
                    noEntriesFoundLabel="" i18n-noEntriesFoundLabel="generic$$no matches found using the given filter@@no match for filter">
                </ngx-mat-select-search>
            </mat-option>
            @for (origin of filteredOrigins; track origin?.english) {
            <mat-option [value]="origin.english">
                {{origin.translated}}
            </mat-option>
            }
        </mat-select>
    </mat-form-field>
    }
    <!-- organic filter -->
    <div class="origin-select organic-filter-icon">
        <app-organicicon class="cursor-pointer"
            (click)="showOrganicChanged()"
            [isOrganic]="true"
            [inactive]="!filters.showOrganic"
            [strikethrough]="filters.showOrganic === 'off'"
            [matTooltip]="!filters.showOrganic ? tr.anslate('Show only items with organic certification') : ''"
            i18n-matTooltip="generic$$tooltip, filter for organic certifications@@Show only items with organic certification">
        </app-organicicon>
        @if (!helptipOrganicShown && showHelptipOrganic) {
        <div class="helptip helptip-organic" [ngClass]="{'showme': showHelptipOrganic}">
            <span i18n="generic$$tooltip, filter for organic certifications@@Show only items with organic certification">Show only items with organic certification</span>&nbsp;<mat-icon class="small-close-icon" (click)="removeHelptip()">close</mat-icon>
        </div>
        }
    </div>
    <!-- filters.ssf filter -->
    @if (stores?.length > 1) {
    <mat-form-field class="origin-select" subscriptSizing="dynamic">
        <mat-label i18n="stores$$placeholder: select the store(s) for which to show roasts@@Show roasts from">Show roasts from</mat-label>
        <mat-select multiple [disabled]="disableSSF"
            name="filters.ssf" panelWidth="null"
            placeholder="Show roasts from" i18n-placeholder="stores$$placeholder: select the store(s) for which to show roasts@@Show roasts from"
            [value]="filters.ssf === 'all' ? stores : filters.ssf"
            [compareWith]="utils.compareObjectsFn"
            (selectionChange)="showstockfromChanged($event)">
            <mat-select-trigger class="twoLineOption ellipsed">
                @if (filters.ssf?.length === 1) {
                <ng-container *ngTemplateOutlet="labelEntry; context: {obj: filters.ssf[0]}"></ng-container>
                } @else if (!filters.ssf?.length || filters.ssf === 'all' || filters.ssf?.length === stores.length) {
                <span i18n="generic$$indicating everything is selected@@all">all</span>
                } @else if (filters.ssf?.length > 1 && stores.length > filters.ssf.length) {
                {{filters.ssf.length}} <span i18n="locationtypes$$LocationTypes@@Stores">Stores</span>
                }
            </mat-select-trigger>
            @for (store of stores; track store?._id) {
            <mat-option [disabled]="disableSSF" [value]="store"><ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container></mat-option>
            }
        </mat-select>
    </mat-form-field>
    }
</div>
}

@if (!waitingForGraph && (!stores?.length || (!filters.origins && !filters.showOrganic && !haveSomeData))) {
<div>
    <div i18n="graphs$$Placeholder when no (data for) graphs are available@@Graphs of your data are shown here as soon as there is enough data" class="main-note" style="width: initial;">Graphs of your data are shown here as soon as there is enough data</div>
</div>
}

@if (data) {
@if (data[0]) {
<app-roastamountaccumulated-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [currentUser]="currentUser"
    [isSmall]="(isSmall$ | async)"
    [newData]="data[0]">
</app-roastamountaccumulated-graph>

<app-roastamountpertime-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [currentUser]="currentUser"
    [isSmall]="(isSmall$ | async)"
    [newData]="data[0]">
</app-roastamountpertime-graph>
}

@if (data[1]) {
<app-roastamounttotal-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [currentUser]="currentUser"
    [isSmall]="(isSmall$ | async)"
    [newData]="data[1]">
</app-roastamounttotal-graph>

<app-energytotal-widget
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [currentUser]="currentUser"
    [newData]="data[1]">
</app-energytotal-widget>
}

<!-- TOBEFINALIZED
<div>
  @if (data[3]) {
  <app-roastgroundcolorhisto-graph
    [isDarkmode]="isDarkmode"
    [style.width]="((isSmall$ | async) || !data[4]) ? '100%' : '50%'"
    [style.float]="((isSmall$ | async) || !data[4]) ? 'none' : 'left'"
    [currentUser]="currentUser"
    [newData]="data[3]">
  </app-roastgroundcolorhisto-graph>
  }
  @if (data[4]) {
  <app-roastwholecolorhisto-graph
    [isDarkmode]="isDarkmode"
    [style.width]="((isSmall$ | async) || !data[3]) ? '100%' : '50%'"
    [style.float]="((isSmall$ | async) || !data[3]) ? 'none' : 'right'"
    [currentUser]="currentUser"
    [newData]="data[4]">
  </app-roastwholecolorhisto-graph>
  }
</div>
-->

@if (data[5]) {
<app-roastconsumption-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [startDate]="filters.from"
    [endDate]="filters.to"
    [currentUser]="currentUser"
    [showstockfrom]="filters.ssf"
    [isSmall]="(isSmall$ | async)"
    [newData]="data[5]">
</app-roastconsumption-graph>
}

@if (data[6]) {
<app-roastblend-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [startDate]="filters.from"
    [endDate]="filters.to"
    [currentUser]="currentUser"
    [showstockfrom]="filters.ssf"
    [isSmall]="(isSmall$ | async)"
    [newData]="data[6]">
</app-roastblend-graph>
}

@if (data[0]) {
<app-roastambient-graph
    [isDarkmode]="isDarkmode"
    [temperatureUnit]="temperatureUnit"
    [currentUser]="currentUser"
    [isSmall]="(isSmall$ | async)"
    [newData]="data[0]">
</app-roastambient-graph>
}

<div>
    @if (data[7]) {
    <app-roastmachine-graph
        [isDarkmode]="isDarkmode"
        [style.width]="((isSmall$ | async) || !data[8]) ? '100%' : '50%'"
        [style.float]="((isSmall$ | async) || !data[8]) ? 'none' : 'left'"
        [currentUser]="currentUser"
        [latestYear]="filters.to?.year"
        [newData]="data[7]">
    </app-roastmachine-graph>
    }
    @if (data[8]) {
    <app-roastuser-graph
        [isDarkmode]="isDarkmode"
        [style.width]="((isSmall$ | async) || !data[7]) ? '100%' : '50%'"
        [style.float]="((isSmall$ | async) || !data[7]) ? 'none' : 'right'"
        [currentUser]="currentUser"
        [latestYear]="filters.to?.year"
        [newData]="data[8]">
    </app-roastuser-graph>
    }
</div>
}