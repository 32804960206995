<div class="spacer"></div>

<div class="date-range-flex-div">
    <span>
        <!-- label -->
        <mat-form-field class="label-input">
            <mat-label i18n="generic$$Title@@Title">Title</mat-label>
            <input required placeholder="Title" data-test="npt_label" matInput name="label"
                type="text" i18n-placeholder="generic$$Title@@Title"
                [(ngModel)]="reportCopy.label">
        </mat-form-field>

        @if (currentFilter.anpa || (invalidRoasts?.length && invalidsReport) || (isOnly && notInRangeReport?.roasts?.length)) {
        <mat-checkbox data-test="tggl_nonPositiveAmounts"
            i18n="reports$$toggle label to allow roasts with empty amounts/weights to be included in a report@@allow empty weights"
            class="primary-element mat-left-padding"
            [(ngModel)]="currentFilter.anpa"
            (change)="allowablesChanged()">Allow empty weights</mat-checkbox>
        }

        <!-- dates filter for fixed reports -->
        @if (!isOpenReport) {
        <span class="nobreak">
            <mat-form-field class="date-input-ff1">
                <mat-label i18n="reports$$start date@@start date">From</mat-label>
                <input [disabled]="!isOnly && !isOpenReport" required
                    data-test="npt_startDate" matInput
                    placeholder="From" i18n-placeholder="reports$$start date@@start date"
                    [matDatepicker]="myDatepicker" name="startDate"
                    [(ngModel)]="currentFilter.from" (dateChange)="datesChanged()">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="date-input-ff2">
                <mat-label i18n="reports$$end date@@end date">To</mat-label>
                <input [disabled]="!isNewest && !isOpenReport" required
                    data-test="npt_endDate" matInput
                    [matDatepicker]="myDatepicker2" i18n-placeholder="reports$$end date@@end date"
                    placeholder="To" [(ngModel)]="currentFilter.to" name="endDate" (dateChange)="datesChanged()"
                    [min]="currentFilter.from">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker2></mat-datepicker>
            </mat-form-field>
        </span>
        }

        @if (isOpenReport) {
        <app-roasts-filter
            [currentUser]="currentUser"
            [showAlways]="true"
            [stores]="stores"
            [energyUnit]="energyUnit"
            [readOnly]="readOnly"
            [isDarkmode]="isDarkmode"
            [filters]="currentFilter"
            (filterChanged)="filterChanged($event)">
        </app-roasts-filter>
        }
    </span>

    @if ((invalidRoasts?.length && invalidsReport) || (isOnly && notInRangeReport?.roasts?.length)) {
    <div class="missing-items-div">
        <a (click)="scrollToMissing()" (keydown.enter)="scrollToMissing(); $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_missingRoasts" class="missing-items-link cursor-pointer">
            <span i18n="reports$$link to a list of items (such as roasts or purchases) that are not included in the curent report@@Missing items">Missing items</span>
            <mat-icon style="vertical-align: bottom;">arrow_drop_down</mat-icon>
        </a>
    </div>
    }
</div>

@if (reportOverview) {
<app-roastreport-overview
    [currentUser]="currentUser"
    [reportOverview]="reportOverview"
    [showExportUI]="false"
    [isOpenReport]="isOpenReport">
</app-roastreport-overview>
}

@if (reportCopy?.roasts) {
<app-roastreport-table #table data-test="table"
    [currentUser]="currentUser"
    [report]="reportCopy"
    [isOpenReport]="isOpenReport"
    [columnsToDisplay]="columnsToDisplay"
    [additionalIsEditable]="true"
    [showTotals]="true"
    [showExportUI]="false"
    [reportName]="reportCopy?.number || report?.number"
    (loadMoreClick)="loadAllRoastsForReport('normal', $event)">
</app-roastreport-table>
}

<span name="hiddenElems" id="hiddenElems" #hiddenElems></span>
<br />
@if ((invalidRoasts?.length && invalidsReport) || (isOnly && notInRangeReport?.roasts?.length)) {
<mat-checkbox data-test="tggl_nonPositiveAmounts2"
    class="primary-element mat-left-padding"
    i18n="reports$$toggle label to allow roasts with empty amounts/weights to be included in a report@@allow empty weights"
    [(ngModel)]="currentFilter.anpa"
    (change)="allowablesChanged()">Allow empty weights</mat-checkbox>
}

@if (invalidRoasts?.length) {
<div data-test="dv_missingHeader" class="other-table-heading"
    i18n="reports$$invalid roasts not included, header@@invalid roasts not included">The following roasts will not be included in the report since important information is missing:</div>
}
@if (invalidsReport?.roasts?.length) {
<app-roastreport-table #invalidsTable data-test="invalidsTable"
    [currentUser]="currentUser"
    [invalids]="true"
    [report]="invalidsReport"
    [isOpenReport]="isOpenReport"
    [columnsToDisplay]="columnsToDisplayInvalids"
    [showTotals]="false"
    [showExportUI]="false"
    [reportName]="reportCopy?.number || report?.number"
    (loadMoreClick)="loadAllRoastsForReport('invalids', $event)">
</app-roastreport-table>
}

<br />
@if (isOnly && notInRangeReport?.roasts?.length) {
<div data-test="dv_notinrangeHeader" class="other-table-heading"
    i18n="reports$$roasts with a date before the start date not included, header@@roasts not in range are excluded">
    The following roasts will not be included in the report since their roast date is before the selected period:</div>

<app-roastreport-table
    #notinrangeTable data-test="tbl_notInRange"
    [currentUser]="currentUser"
    [invalids]="true"
    [report]="notInRangeReport"
    [isOpenReport]="isOpenReport"
    [columnsToDisplay]="columnsToDisplayInvalids"
    [showTotals]="false"
    [showExportUI]="false"
    [reportName]="reportCopy?.number || report?.number">
    <!-- (loadMoreClick)="loadAllRoastsForReport()"> -->
</app-roastreport-table>
}