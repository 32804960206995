// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Enumerations {

    // possible account.settings
    export enum SETTINGS {
        pref_roastreport_type = 'pref_roastreport_type',
        pref_stockinclplanned = 'pref_stockinclplanned',
        additional_machines = 'additional_machines',
        schedule_machine = 'schedule_machine',
        schedule_store = 'schedule_store',
        schedule_unit = 'schedule_unit',
    }

    export const SUPPLIER_COUPONS = 1;

    // used for identifying help tips
    export enum HELPTIP {
        HIDDENBEANS = 1, // where to list hiddden beans
        ORGANICBEANS = 2, // how to filter for organic beans
        EMAILNOTIF = 4, // advise to add notifications email to your address book
        ADDFILTER = 8, // advise to add a filter for another property
    }

    // used for the dsa (dont show again) setting
    export enum DIALOG {
        COMPANYINFO = 1, // report PDF creation, aks for company name / number
        REPORTSDESCRIPTION = 2, // show description of sequential / free roast reports
    }

    export enum UNIT_SYSTEM {
        METRIC = 'Metric',
        IMPERIAL = 'Imperial'
    }

    export enum TEMPERATURE_SYSTEM {
        FAHRENHEIT = 'Fahrenheit',
        CELSIUS = 'Celsius'
    }

    // keep same order as in protobuf specification!
    // @@plural (needs special treatment in translator.service.ts for English)
    export enum CoffeeUnits {
        _NONE = '',
        BAG = 'bag',
        BARREL = 'barrel',
        BOX = 'box'
    }

    export enum StockchangeTypes {
        PURCHASE = 1,
        SALE = 2,
        CORRECTION = 4,
        TRANSFER = 8
    }
    
    export enum LocationTypes {
        // the user's warehouse
        STORE = 'Stores',
        // a supplier's legal address
        SUPPLIER = 'Suppliers',
        // a producer's legal address
        PRODUCER = 'Producers',
        // a customer's legal address
        CUSTOMER = 'Customers',
        // the originating location of a coffee
        FIELD = 'Fields',
        // a customer's storage / shipping address; a supplier's storage / shipping address
        EXTERNAL = 'External Stores'
    }

    export enum CoffeeUnitSizesInKg {
        _NONE = 1,
        BAG = 60,
        BARREL = 69.85,
        BOX = 24
    }

    // this is here only to have the translation scripts find them
    export enum CertificationProperties {
        SOCIAL = 'social',
        ORGANIC = 'organic',
        FAIR = 'fair',
        SUSTAINABLE = 'sustainable',
        BIRD_FRIENDLY = 'bird friendly',
        SHADE_GROWN = 'shade grown',
        HIGH_QUALITY = 'high quality'
    }

    export enum CertificationTypes {
        CT_NONE = 0,
        ORGANIC = 1,
        NOT_ORGANIC = 2,
        FAIR = 4,
        NOT_FAIR = 8,
        SOCIAL = 16,
        NOT_SOCIAL = 32,
        SUSTAINABLE = 64,
        NOT_SUSTAINABLE = 128,
        BIRD_FRIENDLY = 256,
        NOT_BIRD_FRIENDLY = 512,
        SHADE_GROWN = 1024,
        NOT_SHADE_GROWN = 2048,
        HIGH_QUALITY = 5096,
        NOT_HIGH_QUALITY = 10192,
    }

    export enum Hauptzollaemter {
        HZA_7050 = 'Hauptzollamt Aachen',
        HZA_7400 = 'Hauptzollamt Augsburg',
        HZA_2150 = 'Hauptzollamt Berlin',
        HZA_8000 = 'Hauptzollamt Bielefeld',
        HZA_4900 = 'Hauptzollamt Braunschweig',
        HZA_2300 = 'Hauptzollamt Bremen',
        HZA_3200 = 'Hauptzollamt Darmstadt',
        HZA_8100 = 'Hauptzollamt Dortmund',
        HZA_5550 = 'Hauptzollamt Dresden',
        HZA_2650 = 'Hauptzollamt Duisburg',
        HZA_2600 = 'Hauptzollamt Düsseldorf',
        HZA_3000 = 'Hauptzollamt Erfurt',
        HZA_3650 = 'Hauptzollamt Frankfurt (Oder)',
        HZA_3300 = 'Hauptzollamt Frankfurt am Main',
        HZA_3450 = 'Hauptzollamt Gießen',
        HZA_4600 = 'Hauptzollamt Hamburg',
        HZA_5100 = 'Hauptzollamt Hannover',
        HZA_9450 = 'Hauptzollamt Heilbronn',
        HZA_6150 = 'Hauptzollamt Itzehoe',
        HZA_5850 = 'Hauptzollamt Karlsruhe',
        HZA_6200 = 'Hauptzollamt Kiel',
        HZA_6550 = 'Hauptzollamt Koblenz',
        HZA_7150 = 'Hauptzollamt Köln',
        HZA_2900 = 'Hauptzollamt Krefeld',
        HZA_7500 = 'Hauptzollamt Landshut',
        HZA_4050 = 'Hauptzollamt Lörrach',
        HZA_7350 = 'Hauptzollamt Magdeburg',
        HZA_7600 = 'Hauptzollamt München',
        HZA_8300 = 'Hauptzollamt Münster',
        HZA_8750 = 'Hauptzollamt Nürnberg',
        HZA_5300 = 'Hauptzollamt Oldenburg',
        HZA_5350 = 'Hauptzollamt Osnabrück',
        HZA_3700 = 'Hauptzollamt Potsdam',
        HZA_8800 = 'Hauptzollamt Regensburg',
        HZA_7750 = 'Hauptzollamt Rosenheim',
        HZA_9300 = 'Hauptzollamt Saarbrücken',
        HZA_8850 = 'Hauptzollamt Schweinfurt',
        HZA_4100 = 'Hauptzollamt Singen',
        HZA_9150 = 'Hauptzollamt Stralsund',
        HZA_9550 = 'Hauptzollamt Stuttgart',
        HZA_9650 = 'Hauptzollamt Ulm',
    }

    // https://www.iso.org/obp/ui/#search
    export enum Country {
        'Afghanistan' = 'AF',
        'Aland Islands' = 'AX',
        'Albania' = 'AL',
        'Algeria' = 'DZ',
        'American Samoa' = 'AS',
        'Andorra' = 'AD',
        'Angola' = 'AO',
        'Anguilla' = 'AI',
        'Antarctica' = 'AQ',
        'Antigua and Barbuda' = 'AG',
        'Argentina' = 'AR',
        'Armenia' = 'AM',
        'Aruba' = 'AW',
        'Australia' = 'AU',
        'Austria' = 'AT',
        'Azerbaijan' = 'AZ',
        'Bahamas' = 'BS',
        'Bahrain' = 'BH',
        'Bangladesh' = 'BD',
        'Barbados' = 'BB',
        'Belarus' = 'BY',
        'Belgium' = 'BE',
        'Belize' = 'BZ',
        'Benin' = 'BJ',
        'Bermuda' = 'BM',
        'Bhutan' = 'BT',
        'Bolivia' = 'BO',
        'Bonaire, Sint Eustatius and Saba' = 'BQ',
        'Bosnia and Herzegovina' = 'BA',
        'Botswana' = 'BW',
        'Bouvet Island' = 'BV',
        'Brazil' = 'BR',
        'British Indian Ocean Territory' = 'IO',
        'Brunei Darussalam' = 'BN',
        'Bulgaria' = 'BG',
        'Burkina Faso' = 'BF',
        'Burundi' = 'BI',
        'Cape Verde' = 'CV',
        'Cambodia' = 'KH',
        'Cameroon' = 'CM',
        'Canada' = 'CA',
        'Cayman Islands' = 'KY',
        'Central African Republic' = 'CF',
        'Chad' = 'TD',
        'Chile' = 'CL',
        'China' = 'CN',
        'Christmas Island' = 'CX',
        'Cocos (Keeling) Islands' = 'CC',
        'Colombia' = 'CO',
        'Comoros' = 'KM',
        'Congo, DR' = 'CD',
        'Congo, Republic' = 'CG',
        'Cook Islands' = 'CK',
        'Costa Rica' = 'CR',
        'Ivory Coast' = 'CI',
        'Croatia' = 'HR',
        'Cuba' = 'CU',
        'Curaçao' = 'CW',
        'Cyprus' = 'CY',
        'Czech Republic' = 'CZ',
        'Denmark' = 'DK',
        'Djibouti' = 'DJ',
        'Dominica' = 'DM',
        'Dominican Republic' = 'DO',
        'Ecuador' = 'EC',
        'Egypt' = 'EG',
        'El Salvador' = 'SV',
        'Equatorial Guinea' = 'GQ',
        'Eritrea' = 'ER',
        'Estonia' = 'EE',
        'Eswatini' = 'SZ',
        'Ethiopia' = 'ET',
        'Falkland Islands [Malvinas]' = 'FK',
        'Faroe Islands' = 'FO',
        'Fiji' = 'FJ',
        'Finland' = 'FI',
        'France' = 'FR',
        'French Guiana' = 'GF',
        'French Polynesia' = 'PF',
        'French Southern Territories' = 'TF',
        'Gabon' = 'GA',
        'Gambia' = 'GM',
        'Georgia' = 'GE',
        'Germany' = 'DE',
        'Ghana' = 'GH',
        'Gibraltar' = 'GI',
        'Greece' = 'GR',
        'Greenland' = 'GL',
        'Grenada' = 'GD',
        'Guadeloupe' = 'GP',
        'Guam' = 'GU',
        'Guatemala' = 'GT',
        'Guernsey' = 'GG',
        'Guinea' = 'GN',
        'Guinea-Bissau' = 'GW',
        'Guyana' = 'GY',
        'Haiti' = 'HT',
        'Heard Island and McDonald Islands' = 'HM',
        'Honduras' = 'HN',
        'Hong Kong' = 'HK',
        'Hungary' = 'HU',
        'Iceland' = 'IS',
        'India' = 'IN',
        'Indonesia' = 'ID',
        'Iran' = 'IR',
        'Iraq' = 'IQ',
        'Ireland' = 'IE',
        'Isle of Man' = 'IM',
        'Israel' = 'IL',
        'Italy' = 'IT',
        'Jamaica' = 'JM',
        'Japan' = 'JP',
        'Jersey' = 'JE',
        'Jordan' = 'JO',
        'Kazakhstan' = 'KZ',
        'Kenya' = 'KE',
        'Kiribati' = 'KI',
        'North Korea' = 'KP',
        'South Korea' = 'KR',
        'Kuwait' = 'KW',
        'Kyrgyzstan' = 'KG',
        'Laos' = 'LA',
        'Latvia' = 'LV',
        'Lebanon' = 'LB',
        'Lesotho' = 'LS',
        'Liberia' = 'LR',
        'Libya' = 'LY',
        'Liechtenstein' = 'LI',
        'Lithuania' = 'LT',
        'Luxembourg' = 'LU',
        'Macao' = 'MO',
        'North Macedonia' = 'MK',
        'Madagascar' = 'MG',
        'Malawi' = 'MW',
        'Malaysia' = 'MY',
        'Maldives' = 'MV',
        'Mali' = 'ML',
        'Malta' = 'MT',
        'Marshall Islands' = 'MH',
        'Martinique' = 'MQ',
        'Mauritania' = 'MR',
        'Mauritius' = 'MU',
        'Mayotte' = 'YT',
        'Mexico' = 'MX',
        'Micronesia' = 'FM',
        'Moldova' = 'MD',
        'Monaco' = 'MC',
        'Mongolia' = 'MN',
        'Montenegro' = 'ME',
        'Montserrat' = 'MS',
        'Morocco' = 'MA',
        'Mozambique' = 'MZ',
        'Myanmar' = 'MM',
        'Namibia' = 'NA',
        'Nauru' = 'NR',
        'Nepal' = 'NP',
        'Netherlands' = 'NL',
        'New Caledonia' = 'NC',
        'New Zealand' = 'NZ',
        'Nicaragua' = 'NI',
        'Niger' = 'NE',
        'Nigeria' = 'NG',
        'Niue' = 'NU',
        'Norfolk Island' = 'NF',
        'Northern Mariana Islands' = 'MP',
        'Norway' = 'NO',
        'Oman' = 'OM',
        'Pakistan' = 'PK',
        'Palau' = 'PW',
        'Palestine' = 'PS',
        'Panama' = 'PA',
        'PNG' = 'PG',
        'Paraguay' = 'PY',
        'Peru' = 'PE',
        'Philippines' = 'PH',
        'Pitcairn' = 'PN',
        'Poland' = 'PL',
        'Portugal' = 'PT',
        'Puerto Rico' = 'PR',
        'Qatar' = 'QA',
        'Réunion' = 'RE',
        'Romania' = 'RO',
        'Russian Federation' = 'RU',
        'Rwanda' = 'RW',
        'Saint Barthélemy' = 'BL',
        'St. Helena' = 'SH',
        'Saint Kitts and Nevis' = 'KN',
        'Saint Lucia' = 'LC',
        'Saint Martin (French part)' = 'MF',
        'Saint Pierre and Miquelon' = 'PM',
        'St. Vincent' = 'VC',
        'Samoa' = 'WS',
        'San Marino' = 'SM',
        'São Tomé' = 'ST',
        'Saudi Arabia' = 'SA',
        'Senegal' = 'SN',
        'Serbia' = 'RS',
        'Seychelles' = 'SC',
        'Sierra Leone' = 'SL',
        'Singapore' = 'SG',
        'Sint Maarten (Dutch part)' = 'SX',
        'Slovakia' = 'SK',
        'Slovenia' = 'SI',
        'Solomon Islands' = 'SB',
        'Somalia' = 'SO',
        'South Africa' = 'ZA',
        'South Georgia and the South Sandwich Islands' = 'GS',
        'South Sudan' = 'SS',
        'Spain' = 'ES',
        'Sri Lanka' = 'LK',
        'Sudan' = 'SD',
        'Suriname' = 'SR',
        'Svalbard and Jan Mayen' = 'SJ',
        'Sweden' = 'SE',
        'Switzerland' = 'CH',
        'Syrian Arab Republic' = 'SY',
        'Taiwan' = 'TW',
        'Tajikistan' = 'TJ',
        'Tanzania' = 'TZ',
        'Thailand' = 'TH',
        'Timor, East' = 'TL',
        'Togo' = 'TG',
        'Tokelau' = 'TK',
        'Tonga' = 'TO',
        'Trinidad and Tobago' = 'TT',
        'Tunisia' = 'TN',
        'Turkey' = 'TR',
        'Turkmenistan' = 'TM',
        'Turks and Caicos Islands' = 'TC',
        'Tuvalu' = 'TV',
        'Uganda' = 'UG',
        'Ukraine' = 'UA',
        'United Arab Emirates' = 'AE',
        'UK' = 'GB',
        'United States Minor Outlying Islands' = 'UM',
        'USA' = 'US',
        'Uruguay' = 'UY',
        'Uzbekistan' = 'UZ',
        'Vanuatu' = 'VU',
        'Venezuela' = 'VE',
        'Vietnam' = 'VN',
        'Virgin Islands (British)' = 'VG',
        'Virgin Islands (U.S.)' = 'VI',
        'Wallis and Futuna' = 'WF',
        'Western Sahara' = 'EH',
        'Yemen' = 'YE',
        'Zambia' = 'ZM',
        'Zimbabwe' = 'ZW'
    }

    // ATTENTION: this is used by updateTranslationSource.py!
    // https://unstats.un.org/unsd/tradekb/knowledgebase/country-code
    export enum Country3 {
        AFG = 'Afghanistan',
        ALA = 'Aland Islands',
        ALB = 'Albania',
        DZA = 'Algeria',
        ASM = 'American Samoa',
        AND = 'Andorra',
        AGO = 'Angola',
        AIA = 'Anguilla',
        ATA = 'Antarctica',
        ATG = 'Antigua and Barbuda',
        ARG = 'Argentina',
        ARM = 'Armenia',
        ABW = 'Aruba',
        AUS = 'Australia',
        AUT = 'Austria',
        AZE = 'Azerbaijan',
        BHS = 'Bahamas',
        BHR = 'Bahrain',
        BGD = 'Bangladesh',
        BRB = 'Barbados',
        BLR = 'Belarus',
        BEL = 'Belgium',
        BLZ = 'Belize',
        BEN = 'Benin',
        BMU = 'Bermuda',
        BTN = 'Bhutan',
        BOL = 'Bolivia',
        BES = 'Bonaire, Sint Eustatius and Saba',
        BIH = 'Bosnia and Herzegovina',
        BWA = 'Botswana',
        BVT = 'Bouvet Island',
        BRA = 'Brazil',
        IOT = 'British Indian Ocean Territory',
        BRN = 'Brunei Darussalam',
        BGR = 'Bulgaria',
        BFA = 'Burkina Faso',
        BDI = 'Burundi',
        CPV = 'Cape Verde',
        KHM = 'Cambodia',
        CMR = 'Cameroon',
        CAN = 'Canada',
        CYM = 'Cayman Islands',
        CAF = 'Central African Republic',
        TCD = 'Chad',
        CHL = 'Chile',
        CHN = 'China',
        CXR = 'Christmas Island',
        CCK = 'Cocos (Keeling) Islands',
        COL = 'Colombia',
        COM = 'Comoros',
        COD = 'Congo, DR',
        COG = 'Congo, Republic',
        COK = 'Cook Islands',
        CRI = 'Costa Rica',
        HRV = 'Croatia',
        CUB = 'Cuba',
        CUW = 'Curaçao',
        CYP = 'Cyprus',
        CZE = 'Czech Republic',
        DNK = 'Denmark',
        DJI = 'Djibouti',
        DMA = 'Dominica',
        DOM = 'Dominican Republic',
        ECU = 'Ecuador',
        EGY = 'Egypt',
        SLV = 'El Salvador',
        GNQ = 'Equatorial Guinea',
        ERI = 'Eritrea',
        EST = 'Estonia',
        SWZ = 'Eswatini',
        ETH = 'Ethiopia',
        FLK = 'Falkland Islands [Malvinas]',
        FRO = 'Faroe Islands',
        FJI = 'Fiji',
        FIN = 'Finland',
        FRA = 'France',
        GUF = 'French Guiana',
        PYF = 'French Polynesia',
        ATF = 'French Southern Territories',
        GAB = 'Gabon',
        GMB = 'Gambia',
        GEO = 'Georgia',
        DEU = 'Germany',
        GHA = 'Ghana',
        GIB = 'Gibraltar',
        GRC = 'Greece',
        GRL = 'Greenland',
        GRD = 'Grenada',
        GLP = 'Guadeloupe',
        GUM = 'Guam',
        GTM = 'Guatemala',
        GGY = 'Guernsey',
        GIN = 'Guinea',
        GNB = 'Guinea-Bissau',
        GUY = 'Guyana',
        HTI = 'Haiti',
        HMD = 'Heard Island and McDonald Islands',
        HND = 'Honduras',
        HKG = 'Hong Kong',
        HUN = 'Hungary',
        ISL = 'Iceland',
        IND = 'India',
        IDN = 'Indonesia',
        IRN = 'Iran',
        IRQ = 'Iraq',
        IRL = 'Ireland',
        IMN = 'Isle of Man',
        ISR = 'Israel',
        ITA = 'Italy',
        CIV = 'Ivory Coast',
        JAM = 'Jamaica',
        JPN = 'Japan',
        JEY = 'Jersey',
        JOR = 'Jordan',
        KAZ = 'Kazakhstan',
        KEN = 'Kenya',
        KIR = 'Kiribati',
        PRK = 'North Korea',
        KOR = 'South Korea',
        KWT = 'Kuwait',
        KGZ = 'Kyrgyzstan',
        LAO = 'Laos',
        LVA = 'Latvia',
        LBN = 'Lebanon',
        LSO = 'Lesotho',
        LBR = 'Liberia',
        LBY = 'Libya',
        LIE = 'Liechtenstein',
        LTU = 'Lithuania',
        LUX = 'Luxembourg',
        MAC = 'Macao',
        MKD = 'North Macedonia',
        MDG = 'Madagascar',
        MWI = 'Malawi',
        MYS = 'Malaysia',
        MDV = 'Maldives',
        MLI = 'Mali',
        MLT = 'Malta',
        MHL = 'Marshall Islands',
        MTQ = 'Martinique',
        MRT = 'Mauritania',
        MUS = 'Mauritius',
        MYT = 'Mayotte',
        MEX = 'Mexico',
        FSM = 'Micronesia',
        MDA = 'Moldova',
        MCO = 'Monaco',
        MNG = 'Mongolia',
        MNE = 'Montenegro',
        MSR = 'Montserrat',
        MAR = 'Morocco',
        MOZ = 'Mozambique',
        MMR = 'Myanmar',
        NAM = 'Namibia',
        NRU = 'Nauru',
        NPL = 'Nepal',
        NLD = 'Netherlands',
        NCL = 'New Caledonia',
        NZL = 'New Zealand',
        NIC = 'Nicaragua',
        NER = 'Niger',
        NGA = 'Nigeria',
        NIU = 'Niue',
        NFK = 'Norfolk Island',
        MNP = 'Northern Mariana Islands',
        NOR = 'Norway',
        OMN = 'Oman',
        PAK = 'Pakistan',
        PLW = 'Palau',
        PSE = 'Palestine',
        PAN = 'Panama',
        PNG = 'PNG',
        PRY = 'Paraguay',
        PER = 'Peru',
        PHL = 'Philippines',
        PCN = 'Pitcairn',
        POL = 'Poland',
        PRT = 'Portugal',
        PRI = 'Puerto Rico',
        QAT = 'Qatar',
        REU = 'Réunion',
        ROU = 'Romania',
        RUS = 'Russian Federation',
        RWA = 'Rwanda',
        BLM = 'Saint Barthélemy',
        SHN = 'St. Helena',
        KNA = 'Saint Kitts and Nevis',
        LCA = 'Saint Lucia',
        MAF = 'Saint Martin (French part)',
        SPM = 'Saint Pierre and Miquelon',
        VCT = 'St. Vincent',
        WSM = 'Samoa',
        SMR = 'San Marino',
        STP = 'São Tomé',
        SAU = 'Saudi Arabia',
        SEN = 'Senegal',
        SRB = 'Serbia',
        SYC = 'Seychelles',
        SLE = 'Sierra Leone',
        SGP = 'Singapore',
        SXM = 'Sint Maarten (Dutch part)',
        SVK = 'Slovakia',
        SVN = 'Slovenia',
        SLB = 'Solomon Islands',
        SOM = 'Somalia',
        ZAF = 'South Africa',
        SGS = 'South Georgia and the South Sandwich Islands',
        SSD = 'South Sudan',
        ESP = 'Spain',
        LKA = 'Sri Lanka',
        SDN = 'Sudan',
        SUR = 'Suriname',
        SJM = 'Svalbard and Jan Mayen',
        SWE = 'Sweden',
        CHE = 'Switzerland',
        SYR = 'Syrian Arab Republic',
        TWN = 'Taiwan',
        TJK = 'Tajikistan',
        TZA = 'Tanzania',
        THA = 'Thailand',
        TLS = 'Timor, East',
        TGO = 'Togo',
        TKL = 'Tokelau',
        TON = 'Tonga',
        TTO = 'Trinidad and Tobago',
        TUN = 'Tunisia',
        TUR = 'Turkey',
        TKM = 'Turkmenistan',
        TCA = 'Turks and Caicos Islands',
        TUV = 'Tuvalu',
        UGA = 'Uganda',
        UKR = 'Ukraine',
        ARE = 'United Arab Emirates',
        GBR = 'UK',
        UMI = 'United States Minor Outlying Islands',
        USA = 'USA',
        URY = 'Uruguay',
        UZB = 'Uzbekistan',
        VUT = 'Vanuatu',
        VEN = 'Venezuela',
        VNM = 'Vietnam',
        VGB = 'Virgin Islands (British)',
        VIR = 'Virgin Islands (U.S.)',
        WLF = 'Wallis and Futuna',
        ESH = 'Western Sahara',
        YEM = 'Yemen',
        ZMB = 'Zambia',
        ZWE = 'Zimbabwe'
    }

    export function getEnum(enumName: string): string[] {
        return Object.values<string>(Enumerations[enumName]);
    }
}
