<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title i18n="wizard$$motivating start for the first-time-user wizard@@wizard heading">Let's get you started!</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        @if (currentUser) {
        <form name="settings" #myUserForm class="settings-form">
            <mat-form-field data-test="slct_country" style="width: 47.5%; margin-right: 5%;">
                <mat-label i18n="contacts$$country@@Country">Country</mat-label>
                <input required matInput type="text" name="country" id="country"
                    placeholder="Country" i18n-placeholder="contacts$$country@@Country"
                    [matAutocomplete]="autoCompleteCountry"
                    (input)="changeCountryFilter()"
                    (focus)="changeCountryFilter()"
                    (blur)="countryChanged($event)"
                    [(ngModel)]="userCountry">
                <mat-autocomplete #autoCompleteCountry="matAutocomplete" panelWidth="300px"
                    [displayWith]="displayCountryFn" (optionSelected)="countryChanged()">
                    @for (cntr of countries; track cntr?.tr) {
                    <mat-option [value]="cntr">{{cntr.tr}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field data-test="slct_currency" style="width: 47.5%">
                <mat-label i18n="unit_system$$user can choose between any currency@@currency">Currency</mat-label>
                <mat-select [disabled]="!userCountry" name="currency"
                    placeholder="Currency" [hideSingleSelectionIndicator]="true"
                    i18n-placeholder="unit_system$$user can choose between any currency@@currency"
                    [(ngModel)]="currentUser.account.currency"
                    (selectionChange)="settingChanged()">
                    <mat-option value="EUR">EUR</mat-option>
                    <mat-option value="USD">USD</mat-option>
                    @for (cur of currenciesWithoutEURUSD; track cur) {
                    <mat-option [value]="cur">{{cur}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <mat-form-field data-test="slct_unitSystem" style="width: 47.5%; margin-right: 5%;">
                <mat-label i18n="unit_system$$user can choose between, e.g. metric or imperial@@unit system">Unit system</mat-label>
                <mat-select name="unit_system" [hideSingleSelectionIndicator]="true"
                    placeholder="Unit system"
                    i18n-placeholder="unit_system$$user can choose between, e.g. metric or imperial@@unit system"
                    (selectionChange)="settingChanged(true)"
                    [(ngModel)]="currentUser.unit_system">
                    <mat-option [value]="Enumerations.UNIT_SYSTEM.METRIC">kg</mat-option>
                    <mat-option [value]="Enumerations.UNIT_SYSTEM.IMPERIAL">lbs</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field data-test="slct_temperatureSystem" style="width: 47.5%">
                <mat-label i18n="unit_system$$user can choose between, e.g. Celsius or Fahrenheit@@temperature system">Temperature system</mat-label>
                <mat-select name="temperature_system" [hideSingleSelectionIndicator]="true"
                    placeholder="Temperature system"
                    i18n-placeholder="unit_system$$user can choose between, e.g. Celsius or Fahrenheit@@temperature system"
                    (selectionChange)="settingChanged()"
                    [(ngModel)]="currentUser.temperature_system">
                    <mat-option [value]="Enumerations.TEMPERATURE_SYSTEM.CELSIUS">°C</mat-option>
                    <mat-option [value]="Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT">°F</mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <form>
            <mat-stepper orientation="vertical" linear="true" #wizardStepper class="initwizard">
                <mat-step [completed]="!!(location?.label || newLocationLabel)" state="location">
                    <!-- Location input -->
                    <ng-template matStepLabel i18n="wizard$$form entry, first step header@@your locations">Your Stores</ng-template>
                    <img src="assets/images/speicherstadt.jpg" class="wizard-image" alt="a picture of the Speicherstadt in Hamburg as an example for a storage location">
                    <div class="explanation" i18n="wizard$$explanation what a store is@@location explanation">What do you call the place where you store your green beans: e.g. Main Roastery, Store 1, Kitchen, ...</div>
                    @if (!newLocationLabel && locations?.length > 0) {
                    <mat-form-field data-test="slct_location" class="newselect">
                        <mat-label i18n="wizard$$select existing location@@select location">Select existing location</mat-label>
                        <mat-select required name="select_location" [hideSingleSelectionIndicator]="true"
                            placeholder="Select existing location"
                            i18n-placeholder="wizard$$select existing location@@select location"
                            [(ngModel)]="location" [compareWith]="compareLabelFn">
                            <mat-option i18n="wizard$$select new location@@new location">New location</mat-option>
                            @for (loc of locations; track loc?.label) {
                            <mat-option [value]="loc">
                                {{loc.label}}
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    }
                    @if (!location?.label) {
                    <mat-form-field>
                        <mat-label i18n="wizard$$placeholder for a new location label@@new location label">Add new location</mat-label>
                        <input required matInput autofocus
                            type="text" name="newLocationLabel" data-test="npt_newLocationLabel"
                            placeholder="Add new location"
                            i18n-placeholder="wizard$$placeholder for a new location label@@new location label"
                            [(ngModel)]="newLocationLabel">
                    </mat-form-field>
                    }
                    <div>
                        <button matStepperNext [disabled]="!location?.label && !newLocationLabel"
                            (click)="getExistingSuppliers()"
                            data-test="bttn_next" mat-raised-button class="action-button"
                            type="button" i18n="wizard$$go to next step, button@@next step">Next</button>
                    </div>
                </mat-step>

                <mat-step [completed]="!!((unitIdx === 0 || unit.size) && (coffee.label || newCoffeeLabel) && (supplier || newSupplierLabel))" state="coffee">
                    <!-- Coffee input -->
                    <ng-template matStepLabel i18n="wizard$$form entry, second step header@@Your Beans">Your Beans</ng-template>
                    <img src="assets/images/coffeebags.jpg" class="wizard-image" alt="a picture of a coffee bean bag as an example for beans">
                    <div class="explanation" i18n="wizard$$ask user for their beans@@coffee explanation">What type of beans do you have at {{newLocationLabel || !location?.label ? newLocationLabel : location?.label}}?</div>
                    @if (!newCoffeeLabel && coffees?.length > 0) {
                    <mat-form-field data-test="slct_coffee-label">
                        <mat-label i18n="wizard$$select existing beans@@select existing beans">Select existing beans</mat-label>
                        <mat-select required name="select_coffee"
                            class="newselect" [hideSingleSelectionIndicator]="true"
                            placeholder="Select existing beans"
                            i18n-placeholder="wizard$$select existing beans@@select existing beans"
                            (selectionChange)="coffeeSelected($event)"
                            [(ngModel)]="coffee.label">
                            <mat-option i18n="wizard$$let user add new beans@@New beans">New beans</mat-option>
                            @for (cof of coffees; track cof?.label) {
                            <mat-option [value]="cof.label">
                                {{cof.label}}
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    }
                    @if (!coffee.label) {
                    <mat-form-field>
                        <mat-label i18n="transaction$$let user add new beans (by name)@@Add new beans">Add new beans</mat-label>
                        <input required matInput autofocus type="text" name="coffeelabel"
                            data-test="npt_newCoffeeLabel"
                            placeholder="Add new beans"
                            i18n-placeholder="transaction$$let user add new beans (by name)@@Add new beans"
                            [(ngModel)]="newCoffeeLabel">
                    </mat-form-field>
                    }
                    @if (newCoffeeLabel || coffee.label) {
                    <div>
                        <div class="explanation" i18n="wizard$$let user enter an initial amount of beans@@coffee amount explanation">How much {{newCoffeeLabel ? newCoffeeLabel : coffee.label}} do you have at {{newLocationLabel || !location ? newLocationLabel : location.label}}?</div>
                        <div class="flexContainer">
                            <mat-form-field class="amountInput">
                                <mat-label i18n="generic$$Amount (of beans, e.g. in kg or bags)@@Amount">Amount</mat-label>
                                <input matInput required type="text" inputmode="numeric" name="amount"
                                    data-test="npt_amount" class="amount-input"
                                    placeholder="Amount"
                                    i18n-placeholder="generic$$Amount (of beans, e.g. in kg or bags)@@Amount"
                                    (change)="utils.checkChanges(this, 'amount', amount, $event.target['value'], changePrice)"
                                    [value]="amount | number:'1.0-3'">
                            </mat-form-field>
                            <mat-form-field class="unitInput" data-test="slct_unitIdx">
                                <mat-label i18n="unit_system$$descriptive unit specification@@unit_specification">Packaging Unit</mat-label>
                                <mat-select name="unitchooser" [hideSingleSelectionIndicator]="true"
                                    placeholder="Packaging Unit"
                                    i18n-placeholder="unit_system$$descriptive unit specification@@unit_specification"
                                    [(ngModel)]="unitIdx">
                                    <mat-option [value]=0>{{mainUnit}}</mat-option>
                                    @for (aunit of getUnits(); track aunit) {
                                    <mat-option [value]="$index+1">{{aunit}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        @if (unitIdx !== 0) {
                        <mat-form-field class="wide amountInput">
                            <!-- not the standard kg/lb -->
                            <mat-label><span i18n="unit_system$$descriptive unit specification@@unit_specification">Packaging Unit</span> ({{mainUnit}})</mat-label>
                            <input matInput required type="text" inputmode="numeric"
                                data-test="npt_unit-size" name="default_unit_size"
                                placeholder="1 {{getCurrentUnit()}} has how many {{mainUnit}}?"
                                i18n-placeholder="unit_system$$describes what a bag etc. actually means@@unit_specification_2"
                                [value]="unit.size | number:'1.0-1'"
                                (change)="utils.checkChanges(this, 'size', unit.size, $event.target['value'], changePrice, 1, unit)">
                        </mat-form-field>
                        }<br />
                        <mat-form-field class="amount-input-ff1">
                            <input matInput name="price"
                                inputmode="numeric" pattern="[\-0-9,.]*" type="text"
                                data-test="npt_price" class="amount-input"
                                placeholder="Total cost"
                                i18n-placeholder="generic$$price spent buying the beans@@total cost"
                                (change)="utils.checkChanges(this, 'price', price, $event.target['value'], changePrice)"
                                [value]="price | currency:currentUser.account.currency:'symbol-narrow':'1.0-2'">
                            /{{amount * (unitIdx !== 0 && unit?.size || 1) | number:'1.0-3'}}{{mainUnit}}
                        </mat-form-field>
                        <mat-form-field class="amount-input-ff2">
                            <input matInput name="pricePerUnit"
                                inputmode="numeric" pattern="[\-0-9,.]*"
                                data-test="npt_pricePerUnit" class="amount-input" type="text"
                                placeholder="Cost per" i18n-placeholder="generic$$price spent buying the beans per unit@@Cost per"
                                (change)="utils.checkChanges(this, 'pricePerUnit', pricePerUnit, $event.target['value'], changePrice)"
                                [value]="pricePerUnit | currency:currentUser.account.currency:'symbol-narrow':'1.2-2'">
                            /{{mainUnitSingular}}<br />
                        </mat-form-field>
                        <br />
                        @if (retrievingSuppliers) {
                        <mat-spinner diameter="15"></mat-spinner>
                        }
                        <!-- <span *ngIf="supplier?.image">
                  <img class="dropdown-image" width="55px" height="35px" [src]="'assets/images/suppliers/' + supplier.image + (isDarkmode ? '_dark' : '') + '_70x110.png'"
                    onerror="this.style.display='none'" (error)="imageLoadError(supplier)">
                </span> -->
                        @if (!newSupplierLabel && suppliers?.length > 0) {
                        <mat-form-field class="newselect select-with-logo">
                            <mat-select required [hideSingleSelectionIndicator]="true"
                                data-test="slct_supplier"
                                placeholder="Select existing supplier"
                                i18n-placeholder="wizard$$select an existing supplier@@select supplier"
                                [(ngModel)]="supplier" [compareWith]="compareLabelFn" name="select_supplier">
                                @if (supplier?.image) {
                                <mat-select-trigger style="display: flex; justify-content: space-between; align-items: center;">
                                    {{supplier.label}}
                                    <img class="dropdown-image" width="55px" height="35px" [src]="'assets/images/suppliers/' + supplier.image + (isDarkmode ? '_dark' : '') + '_70x110.png'"
                                        onerror="this.style.display='none'" (error)="imageLoadError(supplier)" alt="a picture of a supplier">
                                </mat-select-trigger>
                                }
                                @if (supplier && !supplier?.image) {
                                <mat-select-trigger>
                                    {{supplier.label}}
                                </mat-select-trigger>
                                }
                                <mat-option i18n="wizard$$let the user enter a new supplier@@new supplier">New supplier</mat-option>
                                @for (sup of suppliers; track sup?.label) {
                                <mat-option [value]="sup">
                                    <!-- {{sup.label}} -->
                                    @if (sup.image) {
                                    <div class="dropdown-text-image" style="display: flex; justify-content: space-between; align-items: center;">
                                        {{sup.label}}
                                        <img class="dropdown-image" width="55px" height="35px" [src]="'assets/images/suppliers/' + sup.image + (isDarkmode ? '_dark' : '') + '_70x110.png'"
                                            onerror="this.style.display='none'" alt="a picture of a supplier">
                                    </div>
                                    }
                                    @if (!sup.image) {
                                    <div>{{sup.label}}</div>
                                    }
                                </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        }
                        @if (!supplier?.label) {
                        <mat-form-field>
                            <input required matInput autofocus data-test="npt_newSupplierLabel"
                                placeholder="From whom did you get it (supplier)?" i18n-placeholder="wizard$$let user enter a new supplier by name@@question for supplier"
                                [(ngModel)]="newSupplierLabel" type="text" name="newSupplierLabel">
                        </mat-form-field>
                        }
                        <br />
                    </div>
                    }
                    <div>
                        <button matStepperNext [disabled]="!(unitIdx === 0 || unit.size) || !(coffee.label || newCoffeeLabel) || !(supplier || newSupplierLabel)"
                            data-test="bttn_next2" mat-raised-button class="action-button"
                            type="button" i18n="wizard$$go to next step, button@@next step">Next</button>
                    </div>
                </mat-step>
                <mat-step editable="true" state="done">
                    @if (!saved && !error) {
                    <button [disabled]="!userCountry" style="margin-top: 10px;"
                        mat-raised-button matStepperNext data-test="bttn_save"
                        (click)="save()" type="button" class="action-button">
                        <mat-icon>done</mat-icon>
                    </button>
                    }
                    @if (!userCountry) {
                    <span class="has-error" style="margin-left: 20px; vertical-align: middle;">
                        <mat-icon style="vertical-align: middle; margin-right: 5px;">error_circle_rounded</mat-icon> <span i18n="contacts$$country@@Country" style="vertical-align: middle">Country</span>
                    </span>
                    }
                    @if (saved && !error) {
                    <div>
                        <div class="explanation" i18n="wizard$$finished the initial entry wizard@@wizard finished">Great! What next?</div>
                        <button i18n="generic$$button, done@@done" data-test="bttn_done_final" mat-raised-button
                            (click)="forward()" class="action-button" type="button">Done</button>
                        <button i18n="wizard$$button, run the wizard again to add more stores / beans@@button add more"
                            data-test="bttn_reset" mat-raised-button class="action-button" type="button"
                            (click)="wizardStepper.reset(); reset()">Add more</button>
                    </div>
                    }
                </mat-step>

                <ng-template matStepperIcon="location">
                    <mat-icon>place</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="coffee">
                    <mat-icon>local_cafe</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="done">
                    <mat-icon>done</mat-icon>
                </ng-template>
            </mat-stepper>
        </form>
        }
    </mat-card-content>
</mat-card>

<div class="enlargepage"></div>