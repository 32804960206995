import { DialogModule } from 'src/app/modules/ui/dialog/dialog.module';
import { ContactsComponent } from './contacts.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactRoutingModule } from './contact-routing.module';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'src/app/modules//ui/file-upload.module';
import { ModificationDateModule } from 'src/app/modules/ui/modification-date.module';
import { ContactInfoDialogComponent } from './contact-info-dialog.component';
import { SelectFloidDialogComponent } from './select-floid-dialog.component';
import { SupplierCouponComponent } from './supplier-coupon.component';
import { SupplierPartnersComponent } from './supplierpartners.component';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';

@NgModule({
    declarations: [
        ContactsComponent,
        ContactInfoDialogComponent,
        SelectFloidDialogComponent,
        SupplierCouponComponent,
        SupplierPartnersComponent,
    ],
    imports: [
        CommonModule,
        ContactRoutingModule,
        MyMaterialModule,
        FormsModule,
        DialogModule,
        FileUploadModule,
        ModificationDateModule,
        DragScrollComponent,
        DragScrollItemDirective
    ]
})
export class ContactModule { }
