import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from 'src/app/util/utils';

@Component({
    selector: 'app-numberinput-dialog',
    templateUrl: './numberinput-dialog.component.html',
})
export class NumberinputDialogComponent implements AfterViewInit {

    constructor(
        private utils: Utils,
        public dialogRef: MatDialogRef<NumberinputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            placeholder: string,
            min: number,
            max: number,
            initial: number,
            locale: string,
        }
    ) {
        if (data?.locale) {
            this.locale = data.locale;
        }
        if (typeof this.data?.initial !== 'undefined') {
            if (typeof this.data.min !== 'undefined' && this.data?.initial < this.data.min) {
                this.data.initial = this.data.min;
            }
            if (typeof this.data.max !== 'undefined' && this.data?.initial > this.data.max) {
                this.data.initial = this.data.max;
            }
            this.value = this.data?.initial?.toLocaleString(this.locale, { minimumFractionDigits: 3, maximumFractionDigits: 3 });
        }
    }

    value: string;
    okDisabled = false;
    locale = 'en';

    @ViewChild('inputField') inputField: ElementRef;

    ngAfterViewInit(): void {
        setTimeout(() => this.inputField?.nativeElement?.select(), 0);
    }

    validateInput(): number {
        this.okDisabled = true;
        if (this.value?.trim() !== '') {
            const parsedVal = this.utils.parseLocaleFloat(this.value, this.locale);
            if (Number.isFinite(Number(parsedVal))) {
                if (typeof this.data.min !== 'undefined' && parsedVal < this.data.min) {
                    this.value = this.data.min.toLocaleString(this.locale, { minimumFractionDigits: 3, maximumFractionDigits: 3 })
                    setTimeout(() => this.okDisabled = false, 500);
                    return undefined;
                }
                if (typeof this.data.max !== 'undefined' && parsedVal > this.data.max) {
                    this.value = this.data.max.toLocaleString(this.locale, { minimumFractionDigits: 3, maximumFractionDigits: 3 })
                    setTimeout(() => this.okDisabled = false, 500);
                    return undefined;
                }
                this.okDisabled = false;
                this.value = parsedVal.toLocaleString(this.locale, { minimumFractionDigits: 3, maximumFractionDigits: 3 })
                return parsedVal;
            } else {
                this.value = '';
                return undefined;
            }
        }
        return undefined;
    }

    validateInputLive(): void {
        this.okDisabled = true;
        if (this.value?.trim() !== '') {
            const parsedVal = this.utils.parseLocaleFloat(this.value, this.locale);
            if (Number.isFinite(Number(parsedVal))
                && (typeof this.data.min === 'undefined' || parsedVal >= this.data.min)
                && (typeof this.data.max === 'undefined' || parsedVal < this.data.max)) {
                this.okDisabled = false;
            }
        }
    }

    onYesClick(): void {
        const val = this.validateInput();
        if (typeof val !== 'undefined') {
            this.dialogRef.close(val);
        }
    }
}
