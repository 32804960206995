<input type="file" #file style="display: none" (change)="onFilesAdded()" multiple />
<div>
    <h1 mat-dialog-title i18n="documents$$file upload@@Upload documents">Upload documents</h1>

    <mat-dialog-actions class="actions">
        <button [disabled]="uploading || uploadSuccessful" mat-raised-button
            class="action-button" style="margin-right: auto;"
            (click)="addFiles()"><mat-icon>add</mat-icon>
        </button>
        @if (showCancelButton) {
        <button mat-button class="cancel-button" mat-dialog-close><mat-icon>close</mat-icon></button>
        }
        <button mat-raised-button class="action-button" [disabled]="!canBeClosed || files.size === 0" (click)="closeDialog()">
            <ng-container><mat-icon>done</mat-icon></ng-container>
        </button>
    </mat-dialog-actions>

    <mat-dialog-content>
        @for (file of files; track file) {
        <div>
            @if (!uploading) {
            <button mat-icon-button class="tertiary-element"
                (click)="removeFile(file)">
                <mat-icon>delete</mat-icon>
            </button>
            }&nbsp;
            <span class="filename">{{file.name}}</span>
            @if (uploadInfo) {
            <mat-progress-bar mode="determinate" [value]="uploadInfo[file.name].progress | async">
            </mat-progress-bar>
            }
        </div>
        }
        <!-- <mat-list>
      <mat-list-item *ngFor="let file of files">
        <h4 mat-line>{{file.name}}</h4>
        <mat-progress-bar *ngIf="uploadInfo" mode="determinate" [value]="uploadInfo[file.name].progress | async">
        </mat-progress-bar>
        <button *ngIf="!progress" mat-icon-button (click)="removeFile(file)">
          <mat-icon>delete_alt</mat-icon>
        </button>
      </mat-list-item>
    </mat-list> -->
    </mat-dialog-content>
</div>