import { AuthGuard } from 'src/app/modules/frame/services/auth-guard.service';
import { UserSettingsComponent } from 'src/app/modules/account/user-settings.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
      path: '',
      component: UserSettingsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
