import { Component, OnInit } from '@angular/core';
import { TestService } from 'src/app/util/services/test.service';
import { Utils } from 'src/app/util/utils';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
// import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

    constructor(
        public utils: Utils,
        private testService: TestService,
    ) { }

    private ngUnsubscribe = new Subject();

    roastedCoffee: number;
    sometest: unknown;

    ngOnInit(): void {
        this.testService.getRoastedCoffee()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.roastedCoffee = response.result;
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                        this.roastedCoffee = undefined;
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                    this.roastedCoffee = undefined;
                }
            });

        this.testService.getSomeTest()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.sometest = response.result;
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                        this.sometest = undefined;
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                    this.sometest = undefined;
                }
            });
    }
}
