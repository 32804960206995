<h1 mat-dialog-title i18n="dialogs$$dialog question title@@are you sure">Are you sure?</h1>
<mat-dialog-content>
    {{data.text}}
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-button" (click)="handleExit(undefined, undefined, true)" mat-button type="submit">
        <mat-icon>close</mat-icon>
    </button>
    <button [disabled]="waiting" mat-raised-button type="button"
        class="action-button spinner-button" [ngClass]="{'edit-button': data.okIcon === 'delete'}"
        (click)="finishDialog(true)">
        @if (showWaiting) {
        <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
        } @else {
        <mat-icon>{{data.okIcon}}</mat-icon>
        }
    </button>
</mat-dialog-actions>