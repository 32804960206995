/* eslint-disable @typescript-eslint/no-explicit-any */
 
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { CurrencyPipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, TooltipItem } from 'chart.js';

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-origins-graph',
    templateUrl: './origins-graph.component.html',
    providers: [CurrencyPipe, DecimalPipe],
})
export class OriginsGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private userService: UserService,
        private currencyPipe: CurrencyPipe,
        private decimalPipe: DecimalPipe,
        private utils: Utils,
        private router: Router,
    ) { }

    MAX_ENTRIES = 6; // note that on extending this beyond 6 you need to add additional backgroundColors!

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    // needed to have the correct order of ngOnInit and newData setter
    inited = false;
    updData: any;

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currentUser: UserType;
    @Input() currency = 'EUR';
    @Input() isDarkmode = false;

    haveData = false;

    data = {
        labels: [],
        units: [],
        datasets: [],
    };

    options: ChartConfiguration<'bar'>['options'] = {
        indexAxis: 'y' as const,
        plugins: {
            title: {
                display: true,
                text: this.tr.anslate('Origins'),
                position: 'top' as const,
            },
            legend: {
                display: false,
                position: 'bottom' as const,
            },
            stacked100: {
                enable: true,
                replaceTooltipLabel: false,
            },
            datalabels: {
                color: '#FFFFFF',
                display: true,
                align: 'center' as const,
                anchor: 'center' as const,
                font: function (context) {
                    const width = context.chart.width;
                    const size = Math.round(width * 0.007 + 8);
                    return {
                        size: size,
                        weight: 400
                    };
                },
                formatter: function (_value: any, context: any) {
                    const data = context.chart.data;
                    const { datasetIndex, dataIndex } = context;
                    const rateValue = data.calculatedData[datasetIndex][dataIndex];
                    const datasetLabel = data.datasets[datasetIndex].label;
                    const space = (context.chart.width / 100) * rateValue;
                    if (datasetLabel.length * 8.7 < space) {
                        return datasetLabel;
                    } else {
                        return '';
                    }
                }
            },
            // rough: false,
            tooltip: {
                // enabled: 'false',
                position: 'average' as const,
                // titleSpacing: 5,
                // titleMarginBottom: 8,
                bodySpacing: 4,
                footerSpacing: 4,
                backgroundColor: 'rgba(66,66,66,0.8)', // '#424242',
                titleColor: '#fff',
                bodyColor: 'rgba(0, 0, 0, 0)',
                callbacks: {
                    title: () => {
                        // we do not need a title here
                        return '';
                    },
                    label: (tooltipItem: TooltipItem<'bar'>) => {
                        try {
                            const datasetIndex = tooltipItem.datasetIndex;
                            const datasetLabel = this.data.datasets[datasetIndex].label;
                            // You can use two type values.
                            // `data['originalData']` is raw values,
                            // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)

                            if (tooltipItem.dataIndex === 1) {
                                const originalValue = this.data['originalData'][datasetIndex][tooltipItem.dataIndex];
                                if (originalValue === 0) {
                                    return null;
                                } else {
                                    return datasetLabel + ': ' + this.currencyPipe.transform(originalValue, this.currency, 'symbol-narrow', '1.0-0');
                                }
                            } else if (tooltipItem.dataIndex === 2) {
                                const originalValue = this.data['originalData'][datasetIndex][0]; // weight
                                if (originalValue === 0) {
                                    return null;
                                } else {
                                    const value = this.data['originalData'][datasetIndex][tooltipItem.dataIndex];
                                    if (value === 0) {
                                        return null;
                                    } else {
                                        return datasetLabel + ': ' + this.currencyPipe.transform(value, this.currency, 'symbol-narrow', '1.2-2') + '/' + this.mainUnit;
                                    }
                                }
                            } else {
                                const originalValue = this.data['originalData'][datasetIndex][tooltipItem.dataIndex];
                                if (originalValue === 0) {
                                    return null;
                                } else {
                                    // originalValue is in kg (no need to convert it for the graph)
                                    const value_formated = this.utils.formatAmountForPipe(originalValue, undefined, this.currentUser.unit_system);
                                    const value_formated_full = this.decimalPipe.transform(value_formated.value, '1.0-1') + value_formated.post;
                                    return `${datasetLabel}: ${value_formated_full}`;
                                }
                            }
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        } catch (err) {
                            return '';
                        }
                    }
                }
            },
        },
        layout: {
            padding: {
                left: 5,
                right: 20,
                top: 20,
                bottom: 20,
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    display: false
                },
                beginAtZero: true,
                stacked: true,
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: true,
                },
                beginAtZero: true,
                stacked: true,
            },
        },
        aspectRatio: 1.5,
        maintainAspectRatio: false,
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }

        this.inited = true;
        if (this.data?.units && this.data.units.length > 0) {
            this.data.units[0] = this.mainUnit;
        }
        if (this.updData) {
            this.setNewData(this.updData);
            this.updData = undefined;
        }
    }

    setNewData(nd: any) {
        // nd is assumed to be sorted nd.data thus the total weight, not the value (which is transported in nd.amount)
        // only the top 5 elements are displayed
        this.haveData = false;
        if (nd?.data) {
            if (!this.inited) {
                // store new data until inited
                this.updData = nd;
                return;
            }

            this.haveData = nd.amount && nd.amount.length > 1
                && nd.data?.slice(0, this.MAX_ENTRIES).reduce((prev: number, curr: number) => prev + curr, 0) > 0;

            if (this.haveData) {
                if (nd.amount?.slice(0, this.MAX_ENTRIES)?.reduce((prev: number, curr: number) => prev + curr, 0) > 0) {
                    this.data.labels = [this.mainUnit, this.currency, this.currency + '/' + this.mainUnit];
                    this.options.layout.padding = { left: 5, right: 20, top: 20, bottom: 20 };
                } else {
                    this.data.labels = [this.mainUnit];
                    this.options.layout.padding = { left: 5, right: 20, top: 20, bottom: 60 };
                }

                let backgroundColors: string[];
                let hoverBackgroundColors: string[];
                if (this.isDarkmode) {
                    backgroundColors = [
                        '#43a7cfDD', // primary-400/DD
                        '#64b7d8DD', // primary-300/DD
                        '#757575', // grey-600
                        '#9e9e9e', // grey-500
                        '#bdbdbd', // grey-400
                        '#e0e0e0'  // grey-300
                    ];
                    hoverBackgroundColors = [
                        '#64b7d8', // primary-300
                        '#91cce3', // primary-200
                        '#9e9e9e', // grey-500
                        '#bdbdbd', // grey-400
                        '#e0e0e0', // grey-300
                        '#eeeeee'  // grey-200
                    ];
                    this.options.plugins.datalabels.color = '#eeeeee';
                    this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
                    this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
                    this.options.plugins.tooltip.backgroundColor = '#eeeeeeEE'; // G200/DD => '#eeeeee'
                } else {
                    backgroundColors = [
                        '#64b7d8', // primary-300
                        '#91cce3', // primary-200
                        '#9e9e9e', // grey-500
                        '#bdbdbd', // grey-400
                        '#e0e0e0', // grey-300
                        '#eeeeee'  // grey-200
                    ];
                    hoverBackgroundColors = [
                        '#43a7cf', // primary-400/DD
                        '#64b7d8', // primary-300/DD
                        '#757575', // grey-600
                        '#9e9e9e', // grey-500
                        '#bdbdbd', // grey-400
                        '#e0e0e0'  // grey-300
                    ];
                    this.options.plugins.datalabels.color = '#FFFFFF';
                    this.options.plugins.tooltip.titleColor = '#fff';
                    this.options.plugins.tooltip.bodyColor = '#fff';
                    this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
                }

                const dsets = [];
                if (this.inited && nd.labels?.length &&
                    nd.labels.length === nd.data?.length &&
                    nd.data.length === nd.amount?.length &&
                    nd.amount.length === nd.coffees?.length) {

                    // only the top MAX_ENTRIES elements are displayed
                    for (let i = 0; i < Math.min(this.MAX_ENTRIES, nd.labels.length); i++) {
                        const ds: any = {};
                        ds.label = this.tr.anslate(nd.labels[i]);
                        ds.borderWidth = 1;
                        ds.borderColor = this.isDarkmode ? '#303030' : '#fafafa';
                        if (this.isDarkmode) {
                            ds.hoverBorderColor = '#616161EE';
                        }
                        ds.categoryPercentage = 1;
                        ds.barPercentage = 0.9;
                        ds.hoverBackgroundColor = hoverBackgroundColors[i];
                        ds.backgroundColor = backgroundColors[i];
                        if (this.data.labels.length > 1) {
                            const weight = this.utils.convertToUserUnit(nd.data[i], this.currentUser.unit_system);
                            const value = (weight > 0 ? nd.amount[i] / weight : 0);
                            if (this.data.labels.length > 2) {
                                ds.data = [nd.data[i], nd.amount[i], value];
                            } else {
                                ds.data = [nd.data[i], nd.amount[i]];
                            }
                        } else {
                            ds.data = [nd.data[i]];
                        }
                        dsets.push(ds);
                    }
                }
                this.data.datasets = dsets;
            }
            if (this.chart?.chart) {
                this.chart.chart.update();
            }
        }
    }
}
