 
import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Inject, LOCALE_ID } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ReportService } from 'src/app/modules/report/report.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Roast } from 'src/app/models/Roast';
import { MatTableDataSource } from '@angular/material/table';
import { UserType } from 'src/app/modules/frame/services/user.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Purchase } from 'src/app/models/Purchase';
import { Coffee } from 'src/app/models/Coffee';
import { TransService } from 'src/app/modules/transaction/trans.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateTime, Settings } from 'luxon';

interface SteuermeldungEintrag {
    Monat: string;
    Registrierkennzeichen: string;
    'Menge in kg': number;
    'Steuersatz/kg': number;
    'Steuerbetrag €': number;
    Bemerkung: string;
}


@Component({
    selector: 'app-cr-germany-koeln',
    templateUrl: './cr-germany-koeln.component.html',
    styleUrls: ['./cr-germany-koeln.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrGermanyKoelnComponent implements OnInit, OnDestroy {

    constructor(
        private clipboardService: ClipboardService,
        private reportService: ReportService,
        public utils: Utils,
        // used in template
        public tr: TranslatorService,
        private alertService: AlertService,
        private transService: TransService,
        private changeDetector: ChangeDetectorRef,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        Settings.defaultLocale = 'de';
        this.tax = this.reportService.getCoffeeTax('GER');
    }

    localeBackup: string;

    @ViewChild('transTable') transTableElem: ElementRef;
    @ViewChild('roastTable') roastTableElem: ElementRef;
    @ViewChild('taxTable') taxTableElem: ElementRef;

    @Input() currentUser: UserType;
    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() endDate = DateTime.now();
    @Input() readOnly = false;

    roastsHaveErrors = false;
    transactionsHaveErrors = false;
    onlyDiscardedNotDestroyed = false;

    showPurchaseDate = false;
    showPurchasePrice = false;

    private _manualDiscarded = false;
    get manualDiscarded(): boolean { return this._manualDiscarded; }
    @Input() set manualDiscarded(md: boolean) {
        if (this._manualDiscarded !== md) {
            this._manualDiscarded = md;
            this.updateRoastData();
        }
    }

    private _roasts: Roast[];
    get roasts(): Roast[] { return this._roasts; }
    @Input() set roasts(r: Roast[]) {
        this._roasts = r;
        if (this._lastPurchases?.size) {
            this.setLastPurchaseInfo();
        }
        this.updateRoastData();
    }

    private _purchases: Purchase[];
    get purchases(): Purchase[] { return this._purchases }
    @Input() set purchases(p: Purchase[]) {
        this._purchases = p ?? [];
        this.trans = this._purchases;
        this.data_trans = new MatTableDataSource(this.trans);
        this.transactionsHaveErrors = this.checkTransactionErrors();
        if (this._purchases) {
            this.sum_purch = this._purchases.reduce((sum, val) => sum += val.amount || 0, 0);
        }
    }

    private _lastPurchases: Map<string, { date: DateTime, amount: number, price: number }>;
    @Input() set lastPurchases(lsp: { coffee: Coffee, date: DateTime, amount: number, price: number }[]) {
        this._lastPurchases = new Map();
        for (const lpurch of lsp) {
            this._lastPurchases.set(lpurch.coffee as unknown as string, { date: lpurch.date, amount: lpurch.amount, price: lpurch.price });
        }
        if (this._roasts?.length) {
            this.setLastPurchaseInfo();
        }
    }

    @Output() dataChanged = new EventEmitter();

    FIELDSEPARATOR = '\t';
    LINESEPARATOR = '\r\n';
    DECIMALSEPARATOR = '.';
    @Input() set exportSettings(es: { fieldSep: string, decSep: string, lineSep: string }) {
        this.FIELDSEPARATOR = es.fieldSep;
        this.LINESEPARATOR = es.lineSep;
        this.DECIMALSEPARATOR = es.decSep;
    }

    trans: Purchase[];

    sum_purch = 0;
    sum_discarded = 0;
    sum_end_weight_discarded = 0;
    sum_amount = 0;
    sum_end_weight = 0;
    nrDiscardedButTaxed = 0;
    nrDiscarded = 0;

    data_trans: MatTableDataSource<Purchase>;
    columnsToDisplay_trans = ['Number', 'Date', 'Order Number', 'Supplier', 'Coffees', 'Purchase Amount'];

    data_roasts: MatTableDataSource<Roast>;
    columnsToDisplay_roasts = ['Number', 'Date', 'CoffeesOrBlend', 'Purchase', 'PurchaseCost', 'Amount', 'RoastTime', 'End Weight', 'Loss', 'LossPercent', 'Note'];

    data_tax: MatTableDataSource<SteuermeldungEintrag>;
    columnsToDisplay_tax = ['Monat', 'Registrierkennzeichen', 'Menge in kg', 'Steuersatz/kg', 'Steuerbetrag €', 'Bemerkung'];

    justCopied = false;
    copyFailed = false;
    justCopied_r = false;
    copyFailed_r = false;
    justCopied_t = false;
    copyFailed_t = false;
    creatingPdf = false;

    tax = 0;

    private ngUnsubscribe = new Subject();
    floor = Math.floor;
    round = Math.round;
    DateTime = DateTime;


    ngOnInit(): void {
        this.tax = this.reportService.getCoffeeTax('Germany');
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        if (this.localeBackup) {
            Settings.defaultLocale = this.localeBackup;
        }
    }

    setLastPurchaseInfo(): void {
        for (const roast of this._roasts) {
            if (roast.coffee) {
                const rcoffeeStr = roast.coffee._id.toString();
                const lpurch = this._lastPurchases.get(rcoffeeStr);
                if (lpurch) {
                    roast.lastPurchaseDate = lpurch.date;
                    if (lpurch.price && lpurch.amount && roast.amount) {
                        roast.lastPurchasePrice = lpurch.price / lpurch.amount * roast.amount;
                    }
                }
            } else if (roast.blend?.ingredients?.length) {
                roast.lastPurchaseDates = [];
                roast.lastPurchasePrices = [];
                for (const ing of roast.blend.ingredients) {
                    const rcoffeeStr = ing.coffee._id.toString();
                    const lpurch = this._lastPurchases.get(rcoffeeStr);
                    if (lpurch) {
                        roast.lastPurchaseDates.push(lpurch.date);
                        if (lpurch.price && lpurch.amount && roast.amount) {
                            roast.lastPurchasePrices.push((lpurch.price / lpurch.amount) * roast.amount * (ing.ratio || 0));
                        }
                    }
                }
            }
        }
    }

    /**
     * (Re)calculates overall information on all roasts (sum, errors, discarded, ...)
     * Must be called if any parameter changed that affect any of those.
     */
    updateRoastData(): void {
        this.sum_discarded = 0;
        this.sum_end_weight_discarded = 0;
        this.sum_amount = 0;
        this.sum_end_weight = 0;
        this.nrDiscardedButTaxed = 0;
        this.nrDiscarded = 0;
        this.roastsHaveErrors = false;

        this.data_roasts = new MatTableDataSource(this._roasts);
        // need to set the filterPredicate funtion
        this.onlyDiscardedNotDestroyedChanged(undefined, false);

        for (let r = 0; r < this.data_roasts?.filteredData.length; r++) {
            const roast = this.data_roasts.filteredData[r];
            if (!roast) {
                this.roastsHaveErrors = true;
            } else {
                if (roast.discarded) {
                    this.nrDiscarded += 1;
                    if (!this.manualDiscarded || roast.destroyed) {
                        this.sum_discarded += roast.amount || 0;
                        this.sum_end_weight_discarded += roast.end_weight || 0;
                        roast['Bemerkung'] = 'Fehlröstung';
                    } else {
                        this.nrDiscardedButTaxed += 1;
                        if (roast['Bemerkung'] === 'Fehlröstung') {
                            roast['Bemerkung'] = undefined;
                        }
                    }
                }
                this.sum_end_weight += roast.end_weight || 0;
                this.sum_amount += roast.amount || 0;
                if (!this.roastsHaveErrors && (
                    (!roast.coffee && !roast.blend)
                    || typeof roast.amount === 'undefined'
                    || typeof roast.end_weight === 'undefined')) {
                    this.roastsHaveErrors = true;
                }
            }
        }
        if (this.nrDiscarded) {
            // add or remove the Destroyed table column
            // depending on _manualDiscarded flag / checkbox
            const tctdr = this.columnsToDisplay_roasts;
            if (this._manualDiscarded) {
                if (tctdr[tctdr.length - 1] !== 'Destroyed') {
                    tctdr.push('Destroyed');
                }
            } else if (tctdr[tctdr.length - 1] === 'Destroyed') {
                tctdr.pop();
            }
        }

        this.fillTaxInfo();
    }

    onlyDiscardedNotDestroyedChanged(change?: MatCheckboxChange, callUpdate = true): void {
        if (change) {
            this.onlyDiscardedNotDestroyed = change.checked;
        }
        if (this.onlyDiscardedNotDestroyed) {
            // need to set the filter string to sth otherwise the filterPredicate
            // funtion will be ignored; need to set the predicate before!
            this.data_roasts.filterPredicate = record =>
                record.discarded && !record.destroyed;
            this.data_roasts.filter = 'home';
        } else {
            this.data_roasts.filterPredicate = () => true;
            this.data_roasts.filter = undefined;
        }

        if (callUpdate) {
            // only udpate if not called from updateRoastData itself
            this.updateRoastData();
        }
    }

    destroyedChanged(i?: number): void {
        this.utils.destroyedChanged(() => this.updateRoastData(), () => this.dataChanged.emit(), this.readOnly, this._roasts, i);
    }

    removeDestroyed(i: number): void {
        this.utils.removeDestroyed(i, () => this.updateRoastData(), () => this.dataChanged.emit(), this.readOnly, this._roasts);
        this.dataChanged.emit();
    }

    applyDestroyedToAll(i: number): void {
        this.utils.applyDestroyedToAll(i, () => this.updateRoastData(), () => this.dataChanged.emit(), this.readOnly, this._roasts);
        this.dataChanged.emit();
    }

    editTransaction(purchase: Purchase): void {
        if (!purchase) {
            return;
        }
        purchase.type = purchase.__t;
        if (typeof purchase.location === 'string') {
            purchase.location = {
                _id: purchase.location as unknown as string, type: [Enumerations.LocationTypes.STORE], label: '' };
        }
        this.transService.editTransaction(purchase, this.ngUnsubscribe, trans => {
            const idx = this.data_trans.data.findIndex(trans => purchase._id === trans._id);
            if (idx >= 0) {
                this.data_trans.data[idx] = trans;
                this.data_trans = new MatTableDataSource<Purchase>(this.data_trans.data);
                this.changeDetector.markForCheck();
            }
        });
    }

    /**
     * 
     * @param {number} cnt number
     * @param {number} curMonth 1-12
     * @param {number} curYear year
     * @returns 
     */
    getKennzeichen(cnt: number, curMonth: number, curYear: number) {
        return `TKA-${cnt.toString().padStart(4, '0')}-${(this.currentUser?.account?.company?.number || '000000').padStart(6, '0')}-${(curMonth).toString().padStart(2, '0')}-${curYear.toString()}`;
    }

    fillTaxInfo(): void {
        this.data_tax = undefined;
        if (!this._roasts?.length) {
            return;
        }
        const taxentries: SteuermeldungEintrag[] = [];
        let sum_end_weight = 0;
        let curMonth = this._roasts[0].date.month;
        let curYear = this._roasts[0].date.year;
        for (const roast of this._roasts) {
            const rdate = roast.date;
            const rmonth = rdate.month;
            const ryear = rdate.year;
            if (rmonth !== curMonth || ryear !== curYear) {
                taxentries.push({
                    Monat: this.utils.getMonthStr(curMonth - 1, 'de'),
                    Registrierkennzeichen: this.getKennzeichen(taxentries.length, curMonth, curYear),
                    'Menge in kg': sum_end_weight || 0,
                    'Steuersatz/kg': this.tax,
                    'Steuerbetrag €': (sum_end_weight || 0) * this.tax,
                    Bemerkung: '',
                });
                sum_end_weight = 0;
                if (!roast.discarded || (this._manualDiscarded && !roast.destroyed)) {
                    sum_end_weight = roast.end_weight || 0;
                }
                curMonth = rmonth;
                curYear = ryear;
            } else {
                if (!roast.discarded || (this._manualDiscarded && !roast.destroyed)) {
                    sum_end_weight += (roast.end_weight || 0);
                }
            }
        }
        taxentries.push({
            Monat: this.utils.getMonthStr(curMonth - 1, 'de'),
            Registrierkennzeichen: this.getKennzeichen(taxentries.length, curMonth, curYear),
            'Menge in kg': sum_end_weight || 0,
            'Steuersatz/kg': this.tax,
            'Steuerbetrag €': (sum_end_weight || 0) * this.tax,
            Bemerkung: '',    
        });        
        this.data_tax = new MatTableDataSource(taxentries);
        this.changeDetector.markForCheck();
    }

    cleanStr(str: string): string {
        return str.replace('"', '\'').replace(this.FIELDSEPARATOR, ' ');
    }

    checkTransactionErrors(): boolean {
        for (let t = 0; t < this.trans?.length; t++) {
            const transaction = this.trans[t];
            if (!transaction
                || !transaction.coffee
                || typeof transaction.amount === 'undefined') {
                return true;
            }
        }
        return false;
    }

    copyTrans(): void {
        // create internal text representation for copy
        let text = '';
        for (let i = 0; i < this.trans?.length; i++) {
            const purchase = this.trans[i];
            text += (i + 1) + this.FIELDSEPARATOR;
            if (purchase.date) {
                text += purchase.date.toFormat('dd.MM.yyyy') + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (purchase['order_number']) {
                text += '"' + this.cleanStr(purchase['order_number']) + '"' + this.FIELDSEPARATOR;
            } else if (purchase.reference) {
                text += '"' + this.cleanStr(purchase.reference) + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (purchase['supplier']) {
                text += '"' + this.cleanStr(purchase.supplier.label) + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (!Number.isNaN(purchase.amount)) {
                let num = (Math.round(this.utils.convertToUserUnit(purchase.amount, this.currentUser?.unit_system) * 1000) / 1000).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            }
            if (purchase.coffee) {
                text += '"' + this.reportService.createCoffeeLabel(purchase.coffee, true, this.FIELDSEPARATOR === ',' ? ' ' : ',', true, this.FIELDSEPARATOR).replace('"', '\'') + '"';
            }
            if (i < this.trans.length - 1) {
                text += this.LINESEPARATOR;
            }
        }
        if (this.clipboardService.copyFromContent(text, this.transTableElem?.nativeElement)) {
            this.justCopied = true;
            setTimeout(() => {
                this.changeDetector.markForCheck();
                this.justCopied = false;
            }, 2000);
        } else {
            this.copyFailed = true;
            setTimeout(() => {
                this.changeDetector.markForCheck();
                this.copyFailed = false;
            }, 2000);
        }
    }

    copyTax(): void {
        // create internal text representation for copy
        let text = '';
        for (let i = 0; i < this.data_tax.data.length; i++) {
            const entry = this.data_tax.data[i];
            text += entry.Monat + this.FIELDSEPARATOR;
            text += entry.Registrierkennzeichen + this.FIELDSEPARATOR;

            let num = (Math.round((entry['Menge in kg'] || 0) * 100) / 100).toString();
            if (this.DECIMALSEPARATOR !== '.') {
                num = num.replace('.', this.DECIMALSEPARATOR);
            }
            text += num + this.FIELDSEPARATOR;

            num = (Math.round((entry['Steuersatz/kg'] || 0) * 100) / 100).toString();
            if (this.DECIMALSEPARATOR !== '.') {
                num = num.replace('.', this.DECIMALSEPARATOR);
            }
            text += num + this.FIELDSEPARATOR;

            num = (Math.round((entry['Steuerbetrag €'] || 0) * 100) / 100).toString();
            if (this.DECIMALSEPARATOR !== '.') {
                num = num.replace('.', this.DECIMALSEPARATOR);
            }
            text += num + this.FIELDSEPARATOR;

            text += (entry.Bemerkung || '') + this.FIELDSEPARATOR;

            if (i < this.data_tax.data.length - 1) {
                text += this.LINESEPARATOR;
            }
        }
        if (this.clipboardService.copyFromContent(text, this.taxTableElem?.nativeElement)) {
            this.justCopied_t = true;
            setTimeout(() => {
                this.changeDetector.markForCheck();
                this.justCopied_t = false;
            }, 2000);
        } else {
            this.copyFailed_t = true;
            setTimeout(() => {
                this.changeDetector.markForCheck();
                this.copyFailed_t = false;
            }, 2000);
        }
    }

    copyRoasts(): void {
        // create internal text representation for copy
        let text = '';
        for (let i = 0; i < this.data_roasts?.filteredData.length; i++) {
            const roastdata = this.data_roasts.filteredData[i];
            // if (roastdata.discarded) {
            //     roastdata.end_weight = 0;
            // }
            // lfd. Nr.
            text += (i + 1) + this.FIELDSEPARATOR;
            // Datum
            if (roastdata.date) {
                text += roastdata.date.toLocaleString(DateTime.DATE_SHORT) + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            // Sorte
            if (roastdata.coffee) {
                text += '"' + this.cleanStr(this.reportService.createCoffeeLabel(roastdata.coffee, true, this.FIELDSEPARATOR === ',' ? ' ' : ',', true)) + '"' + this.FIELDSEPARATOR;
            } else if (roastdata.blend) {
                text += '"' + this.cleanStr(this.reportService.createBlendLabel(roastdata.blend, true, this.FIELDSEPARATOR === ',' ? ' ' : ',', ' / ')) + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            // Rohkaffee Lieferung vom
            if (this.showPurchaseDate && roastdata['lastPurchaseDate']) {
                text += roastdata['lastPurchaseDate'].toLocaleString(DateTime.DATE_SHORT) + this.FIELDSEPARATOR;
            } else if (this.showPurchaseDate && roastdata['lastPurchaseDates']) {
                text += roastdata['lastPurchaseDates'].map(date => date.toLocaleString(DateTime.DATE_SHORT)).join(' ') + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            // € (Rohkaffee Lieferung)
            if (this.showPurchasePrice && roastdata['lastPurchasePrice']) {
                let num = (Math.round(roastdata['lastPurchasePrice'] * 100) / 100).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else if (this.showPurchasePrice && roastdata['lastPurchasePrices']) {
                text += roastdata['lastPurchasePrices'].map((price: number) => (Math.round(price * 100) / 100).toString()).join(' ') + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }

            // Rohkaffee in kg
            if (!isNaN(roastdata.amount)) {
                let num = (Math.round((roastdata.amount || 0) * 100) / 100).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }

            // Röstzeit
            if (roastdata.drop_time) {
                text += (Math.round((roastdata.drop_time || 0) * 100 / 60.0) / 100).toString() + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }

            // Röstkaffee in kg
            if (Number.isFinite(roastdata.end_weight)) {
                let num = (Math.round((roastdata.end_weight || 0) * 100) / 100).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }

            // Schwund in kg
            if (Number.isFinite(roastdata.amount - roastdata.end_weight)) {
                let num = (Math.round(((roastdata.amount - roastdata.end_weight) || 0) * 100) / 100).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            // Schwund in %
            if (roastdata.amount && Number.isFinite(roastdata.amount - roastdata.end_weight)) {
                let num = (Math.round((roastdata.amount - roastdata.end_weight) / roastdata.amount * 1000) / 10).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }

            text += roastdata['Bemerkung'] || '';

            // // Ausbeute in %
            // if (Number.isFinite(roastdata.amount) && Number.isFinite(roastdata.end_weight)) {
            //     let num = (Math.round(roastdata.end_weight / roastdata.amount * 1000) / 1000).toString();
            //     if (this.DECIMALSEPARATOR !== '.') {
            //         num = num.replace('.', this.DECIMALSEPARATOR);
            //     }
            //     text += num + this.FIELDSEPARATOR;
            // } else {
            //     text += this.FIELDSEPARATOR;
            // }

            // if (Number.isFinite(roastdata.end_weight)) {
            //     // Zu versteuern sind / kg
            //     let num = (Math.round(roastdata.end_weight * 1000) / 1000).toString();
            //     if (this.DECIMALSEPARATOR !== '.') {
            //         num = num.replace('.', this.DECIMALSEPARATOR);
            //     }
            //     // Steuer 2,19€ / kg
            //     text += num + this.FIELDSEPARATOR;
            //     num = (Math.round(roastdata.end_weight * this.tax * 100) / 100).toString();
            //     if (this.DECIMALSEPARATOR !== '.') {
            //         num = num.replace('.', this.DECIMALSEPARATOR);
            //     }
            //     text += num + this.FIELDSEPARATOR;
            // } else {
            //     text += '0' + this.FIELDSEPARATOR;
            //     text += '0' + this.FIELDSEPARATOR;
            // }

            // if (roastdata.discarded && Number.isFinite(roastdata.amount)) {
            //     // Fehlröstung kg
            //     let num = (Math.round(roastdata.amount * 1000) / 1000).toString();
            //     if (this.DECIMALSEPARATOR !== '.') {
            //         num = num.replace('.', this.DECIMALSEPARATOR);
            //     }
            //     text += num + this.FIELDSEPARATOR;
            // } else {
            //     text += '0';
            // }

            if (i < this.data_roasts.filteredData.length - 1) {
                text += this.LINESEPARATOR;
            }
        }
        if (this.clipboardService.copyFromContent(text, this.roastTableElem?.nativeElement)) {
            this.justCopied_r = true;
            setTimeout(() => {
                this.justCopied_r = false;
                this.changeDetector.markForCheck();
            }, 2000);
        } else {
            this.copyFailed_r = true;
            setTimeout(() => {
                this.copyFailed_r = false;
                this.changeDetector.markForCheck();
            }, 2000);
        }
    }

    generateDataArray(): unknown[] {
        const stockData = [];
        for (let d = 0; d < this.data_roasts?.filteredData.length; d++) {
            const row = this.data_roasts.filteredData[d];
            let coffbl = '';
            if (row.coffee) {
                coffbl = this.reportService.createCoffeeLabel(row.coffee, true, '\n', true);
            } else if (row.blend) {
                coffbl = this.reportService.createBlendLabel(row.blend, true, ' ', '\n');
            }
            stockData.push({
                ['Lfd Nr.']: d + 1,
                ['Datum']: row.date.toFormat('dd.MM.yyyy'),
                // ['Uhrzeit']: row.date.toFormat('HH:mm'),
                ['Kaffeesorte']: coffbl,
                ['Rohkaffee Lieferung vom']: this.showPurchaseDate && row['lastPurchaseDate'] ? row['lastPurchaseDate'].toFormat('dd.MM.yyyy') : (this.showPurchaseDate && row['lastPurchaseDates'] ? row['lastPurchaseDates'].map(date => date.toFormat('dd.MM.yyyy')).join(' ') : ''),
                ['€']: { alignment: 'right', text: this.showPurchasePrice && row['lastPurchasePrice'] ? row['lastPurchasePrice'].toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : (this.showPurchasePrice && row['lastPurchasePrices'] ? row['lastPurchasePrices'].map((price: number) => price.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€').join(' ') : '') },
                ['Einfüllmenge Rohkaffee kg']: { alignment: 'right', text: isNaN(row.amount) ? '-' : row.amount.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'kg' },
                ['Röstzeit']: { alignment: 'right', text: row.drop_time ? (row.drop_time / 60.0).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'min' : '-' },
                ['Menge Röstkaffee kg']: { alignment: 'right', text: isNaN(row.end_weight) ? '-' : row.end_weight.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'kg' },
                ['Schwund kg']: { alignment: 'right', text: isNaN(row.amount - row.end_weight) ? '-' : (row.amount - row.end_weight).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'kg' },
                ['Schwund %']: { alignment: 'right', text: !row.amount || isNaN(row.amount - row.end_weight) ? '-' : ((row.amount - row.end_weight) / row.amount * 100).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%' },
                // ['Bemerkung']: row.discarded ? 'Fehlröstung' : '',
                ['Bemerkung']: row['Bemerkung'] || '',
                
                // ['zu versteuern' + ' (' + this.mainUnit + ')']: this.utils.formatNumber(row.end_weight, 3).toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }),
                // ['Steuer (2,19€/kg)']: !row.end_weight ? '-' : (Math.round(row.end_weight * this.tax * 100) / 100.0).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                // ['Fehlröstung']: !row.discarded || isNaN(row.amount) ? '-' : this.utils.formatNumber(row.end_weight, 3).toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }),
            });
        }
        // sum footer
        // const avgYield = this.sum_amount && this.sum_end_weight ? this.sum_end_weight / this.sum_amount : 0;
        const avgLoss = this.sum_amount && this.sum_end_weight ? (this.sum_amount - this.sum_end_weight) * 100 / this.sum_amount : 0;
        const avgLossWithoutDiscarded = this.sum_amount && this.sum_end_weight ? ((this.sum_amount - this.sum_discarded - this.sum_end_weight + this.sum_end_weight_discarded) * 100 / (this.sum_amount - this.sum_discarded)) : 0;
        stockData.push({
            // need space ' ' here otherwise border is ignored
            ['Lfd Nr.']: { text: ' ', border: [false, false, false, false] },
            ['Datum']: { text: ' ', border: [false, false, false, false] },
            // ['Uhrzeit']: { text: ' ', border: [false, false, false, false] },
            ['Kaffeesorte']: { text: ' ', border: [false, false, false, false] },
            ['Rohkaffee Lieferung vom']: { text: ' ', border: [false, false, false, false] },
            ['€']: { text: ' ', border: [false, false, false, false] },
            ['Einfüllmenge Rohkaffee kg']: { alignment: 'right', text: isNaN(this.sum_amount) ? '-' : this.sum_amount.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'kg', bold: true, border: [false, false, false, false], margin: [0, 10, 0, 0] },
            ['Röstzeit']: { text: ' ', border: [false, false, false, false] },
            ['Menge Röstkaffee kg']: { alignment: 'right', text: isNaN(this.sum_end_weight) ? '-' : this.sum_end_weight.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'kg', bold: true, border: [false, false, false, false], margin: [0, 10, 0, 0] },
            ['Schwund kg']: { alignment: 'right', text: isNaN(this.sum_amount - this.sum_end_weight) ? '-' : (this.sum_amount - this.sum_end_weight).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'kg', bold: true, border: [false, false, false, false], margin: [0, 10, 0, 0] },
            ['Schwund %']: { alignment: 'right', text: !avgLoss || isNaN(avgLoss) ? '-' : avgLoss.toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%', bold: true, border: [false, false, false, false], margin: [0, 10, 0, 0] },
            ['Bemerkung']: { text: ' ', border: [false, false, false, false] },

            // // eslint-disable-next-line max-len
            // ['zu versteuern' + ' (' + this.mainUnit + ')']: { text: this.utils.formatNumber(this.sum_end_weight - this.sum_discarded, 3).toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + this.mainUnit, bold: true, margin: [0, 10, 0, 0] },
            // // eslint-disable-next-line max-len
            // ['Steuer (2,19€/kg)']: { text: !this.sum_end_weight ? '-' : (Math.round(this.sum_end_weight * this.tax * 100) / 100.0).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€', bold: true, margin: [0, 10, 0, 0] },
            // // eslint-disable-next-line max-len
            // ['Fehlröstung']: { text: !this.sum_discarded || isNaN(this.sum_amount) ? '-' : this.utils.formatNumber(this.sum_discarded, 3).toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 }) + this.mainUnit, bold: true, margin: [0, 10, 0, 0] },
        });
        // ohne Fehlröstungen:
        if (this.sum_discarded) {
            stockData.push({
                ['Lfd Nr.']: { text: ' ', border: [false, false, false, false] },
                ['Datum']: { text: ' ', border: [false, false, false, false] },
                // ['Uhrzeit']: { text: ' ', border: [false, false, false, false] },
                ['Kaffeesorte']: { text: 'ohne Fehlröstungen:', alignment: 'right', bold: false, colSpan: 3, border: [false, false, false, false] },
                ['Rohkaffee Lieferung vom']: { text: ' ', border: [false, false, false, false] },
                ['€']: { text: ' ', border: [false, false, false, false] },
                ['Einfüllmenge Rohkaffee kg']: { alignment: 'right', text: (isNaN(this.sum_amount - this.sum_discarded) ? '-' : (this.sum_amount - this.sum_discarded).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'kg'), bold: false, border: [false, false, false, false] },
                ['Röstzeit']: { text: ' ', border: [false, false, false, false] },
                ['Menge Röstkaffee kg']: { alignment: 'right', text: (isNaN(this.sum_end_weight - this.sum_end_weight_discarded) ? '-' : (this.sum_end_weight - this.sum_end_weight_discarded).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'kg'), bold: false, border: [false, false, false, false] },
                ['Schwund kg']: { alignment: 'right', text: (isNaN(this.sum_amount - this.sum_discarded - this.sum_end_weight + this.sum_end_weight_discarded) ? '-' : (this.sum_amount - this.sum_discarded - this.sum_end_weight + this.sum_end_weight_discarded).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'kg'), bold: false, border: [false, false, false, false] },
                ['Schwund %']: { alignment: 'right', text: (!avgLossWithoutDiscarded ? '-' : avgLossWithoutDiscarded.toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%'), bold: false, border: [false, false, false, false] },
                ['Bemerkung']: { text: ' ', border: [false, false, false, false] },
            });
        }
        if (!this.showPurchaseDate || !this.showPurchasePrice) {
            for (const sd of stockData) {
                if (!this.showPurchaseDate) {
                    delete sd['Rohkaffee Lieferung vom'];
                }
                if (!this.showPurchasePrice) {
                    delete sd['€'];
                }
            }
        }

        return stockData;
    }

    generatePDF(): void {
        if (this.creatingPdf) { return; }

        this.creatingPdf = true;
        const stockData = this.generateDataArray();

        const title = 'Röstprotokoll ' + this.endDate.toFormat('yyyy-MM-dd');
        this.reportService.getPDFFromArray(stockData, title, 'de', true)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: blob => {
                    this.creatingPdf = false;
                    this.changeDetector.markForCheck();

                    const filename = title;
                    this.utils.saveBlobToFileSystem(blob, filename + '.pdf');

                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingPdf = false;
                    this.changeDetector.markForCheck();
                    this.utils.handleError('error creating PDF', error);
                }
            });
    }
}
