<!-- Store Graphs -->

@if (stores?.length > 1) {
<div class="select-bar">
    <span>&nbsp;</span>
    @if (!data?.length || retrievingData) {
    <span>
        <mat-spinner diameter="20"></mat-spinner>
    </span>
    }
    <mat-form-field>
        <mat-select [disabled]="disableSSF" matInput
            placeholder="Stores" i18n-placeholder="locationtypes$$LocationTypes@@Stores"
            name="showstockfrom" panelWidth="null"
            [value]="showstockfrom === 'all' ? stores : showstockfrom" multiple
            (selectionChange)="showstockfromChanged($event)"
            [compareWith]="utils.compareObjectsFn">
            <mat-select-trigger>
                @if (showstockfrom?.length === 1) {
                {{showstockfrom[0]['hr_id']}} {{showstockfrom[0]['label']}}
                } @else if (showstockfrom === 'all' || showstockfrom?.length === stores.length) {
                <span
                    i18n="generic$$indicating everything is selected@@all">all</span>
                } @else if (showstockfrom?.length > 1 && showstockfrom.length < stores.length) {
                    {{showstockfrom.length}} <span i18n="locationtypes$$LocationTypes@@Stores">Stores</span>
                    }
            </mat-select-trigger>
            @for (store of stores; track store?._id) {
            <mat-option [disabled]="disableSSF" [value]="store">{{store.hr_id}} {{store.label}}</mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>
}

@if (!stores?.length) {
<div>
    <div i18n="graphs$$Placeholder when no (data for) graphs are available@@Graphs of your data are shown here as soon as there is enough data" class="main-note" style="width: initial;">Graphs of your data are shown here as soon as there is enough data</div>
</div>
}

@if (data) {
@if (data[0]) {
<app-amountpertime-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [currency]="currency"
    [newData]="data[0]">
</app-amountpertime-graph>
}

@if (data[9] && haveAgeGraphData) {
<app-age-graph
    [isDarkmode]="isDarkmode"
    [newData]="data[9]"
    [isSmall]="(isSmall$ | async)"
    (haveData)="hasAgeGraphDataChanged($event)">
</app-age-graph>
}

@if (data[3]) {
<app-regions-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [currency]="currency"
    [currentUser]="currentUser"
    [newData]="data[3]">
</app-regions-graph>
}

@if (data[10]) {
<app-origins-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [currency]="currency"
    [currentUser]="currentUser"
    [newData]="data[10]">
</app-origins-graph>
}

<div>
    @if (data?.[4]) {
    <app-species-graph
        [isDarkmode]="isDarkmode"
        [mainUnit]="mainUnit"
        [style.width]="((isSmall$ | async) || !data[5]) ? '100%' : '50%'"
        [style.float]="((isSmall$ | async) || !data[5]) ? 'none' : 'left'"
        [currentUser]="currentUser"
        [newData]="data[4]">
    </app-species-graph>
    }
    @if (data[5]) {
    <app-varietals-graph
        [isDarkmode]="isDarkmode"
        [mainUnit]="mainUnit"
        [style.width]="((isSmall$ | async) || !data[4]) ? '100%' : '50%'"
        [style.float]="((isSmall$ | async) || !data[4]) ? 'none' : 'right'"
        [currentUser]="currentUser"
        [newData]="data[5]">
    </app-varietals-graph>
    }
</div>

<div style="clear:both;"></div>

@if (data[1]) {
<app-processing-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [currentUser]="currentUser"
    [newData]="data[1]">
</app-processing-graph>
}

@if (data[2]) {
<app-certifications-graph
    [isDarkmode]="isDarkmode"
    [mainUnit]="mainUnit"
    [currentUser]="currentUser"
    [newData]="data[2]">
</app-certifications-graph>
}

@if (data[6]) {
<app-moisture-graph
    [isDarkmode]="isDarkmode"
    [newData]="data[6]"
    [isSmall]="(isSmall$ | async)">
</app-moisture-graph>
}

@if (data[7]) {
<app-activity-graph
    [isDarkmode]="isDarkmode"
    [newData]="data[7]"
    [isSmall]="(isSmall$ | async)">
</app-activity-graph>
}

@if (data[8]) {
<app-density-graph
    [isDarkmode]="isDarkmode"
    [newData]="data[8]"
    [isSmall]="(isSmall$ | async)">
</app-density-graph>
}
}