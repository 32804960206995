import { TransService } from 'src/app/modules/transaction/trans.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { StockType } from './store-stock.component';
import { CorrectDialogComponent, CorrectDialogResultType } from 'src/app/modules/transaction/dialogs/correct-dialog.component';
import { Subject } from 'rxjs';
import { ShowPurchasesDialogComponent } from 'src/app/modules/store/show-purchases-dialog.component';
import { Enumerations } from 'src/app/models/Enumerations';
import { StockChange } from 'src/app/models/StockChange';
import { ObjectChangedService } from 'src/app/util/services/objectchanged.service';
import { Router } from '@angular/router';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { StandardService } from 'src/app/util/services/standard.service';
import { Correction } from 'src/app/models/Correction';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Coffee } from 'src/app/models/Coffee';
import { YesNoWaitDialogComponent } from '../ui/dialog/yesno-wait-dialog.component';
import { DateTime } from 'luxon';
import { Constants } from 'src/app/util/constants';

@Component({
    selector: 'app-stock',
    templateUrl: './stock.component.html',
    styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit, OnDestroy {

    constructor(
        private logger: NGXLogger,
        private userService: UserService,
        private alertService: AlertService,
        public utils: Utils,
        private tr: TranslatorService,
        private dialog: MatDialog,
        private transService: TransService,
        private objectChangedService: ObjectChangedService,
        private router: Router,
        private standardService: StandardService,
    ) { }

    @Input() currentUser: UserType;
    @Input() readOnly = false;
    currency = 'EUR';
    mainUnit: UnitSystemType = 'kg';
    mainUnitSingular = 'kg';

    totalAmount = 0;
    totalCost = 0;
    avgCost = 0;

    private _stocks: StockType[]
    get stocks(): StockType[] { return this._stocks }
    @Input() set stocks(s: StockType[]) {
        this._stocks = s.filter(stock => stock.amount && Number.isFinite(stock.amount) && Math.abs(stock.amount) > Constants.EPSILON);
        this.totalAmount = 0;
        this.totalCost = 0;
        let lavgCost = 0;
        let cnt = 0;
        for (const stock of this._stocks) {
            if (!isNaN(stock.amount)) {
                this.totalAmount += stock.amount;
                const cost = stock.fifo_cost;
                if (cost) {
                    this.totalCost += cost;
                    lavgCost += cost / stock.amount;
                    cnt += 1;
                }
            }
        }
        // initially, we don't have the correct mainUnit yet
        // this.avgCost = lavgCost / this.utils.getUnitFactor(this.mainUnit) / cnt;
        this.avgCost = lavgCost / cnt;
    }
    // columnsToDisplay = ['amount', 'low_limit', 'location'];
    columnsToDisplay = ['location', 'amount', 'cost', 'actions'];

    private ngUnsubscribe = new Subject();

    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
            this.mainUnitSingular = 'lb';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    formatAmount(amount: number): { pre: string, value: number, post: string } {
        return this.utils.formatAmountForPipe(amount, this.stocks && this.stocks.length > 0 && this.stocks[0] ? this.stocks[0].default_unit : undefined, this.currentUser.unit_system);
    }

    changeAmount(sIdx: number): void {
        if (this.readOnly) { return; }

        this.logger.debug('changeAmount', this.stocks[sIdx]);
        const dialogRef = this.dialog.open(CorrectDialogComponent, {
            closeOnNavigation: true,
            data: {
                stores: [this.stocks[sIdx].location], stocks: [[this.stocks[sIdx]]], preSelected: 0,
                coffees: [{
                    label: this.stocks[sIdx].coffeeLabel, internal_hr_id: this.stocks[sIdx].coffee_internal_hr_id,
                    origin: this.stocks[sIdx].origin, _id: this.stocks[sIdx].coffeeId,
                    default_unit: this.stocks[sIdx].default_unit
                }]
            }
        });

        dialogRef.componentInstance.finished.subscribe(dialogResult => {
            if (dialogResult instanceof CorrectDialogResultType) {
                this.transService.handleCorrectionDialogClosed(dialogResult, dialogRef, this.stocks[sIdx], this.stocks, sIdx,
                    undefined, this.stocks[sIdx].coffeeId as unknown as Coffee, undefined, this.ngUnsubscribe);
            }
        });
    }

    changeCost(stock?: StockType): void {
        if (!this.stocks || this.stocks.length === 0) {
            this.logger.error('changeCost called but we have no stocks info: ' + this.stocks);
            return;
        }
        if (!stock) {
            stock = this.stocks[0];
        }
        const totalAmount = this.stocks.filter(s => s.coffeeId?.toString() === stock.coffeeId?.toString()).reduce((prev, stock) => prev + stock.amount, 0);
        const dialogRef = this.dialog.open(ShowPurchasesDialogComponent, {
            closeOnNavigation: true,
            data: {
                readOnly: this.readOnly,
                coffee: stock.coffeeId,
                totalAmount,
            }
        });

        dialogRef.afterClosed().subscribe(trans => {
            if (!trans || this.readOnly) {
                return;
            }
            this.editTransaction(trans);
        });
    }

    setAmountToZero(sIdx: number): void {
        if (this.readOnly) { return; }

        this.logger.debug('setAmountToZero', this.stocks[sIdx]);
        const dialogRef = this.dialog.open(YesNoWaitDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Set the stock of this coffee to 0 (consumed)?'), okIcon: 'done' }
        });

        dialogRef.componentInstance.finished.subscribe(result => {
            if (result === true) {
                const correction = new Correction();
                delete correction.reconciled;
                correction.coffee = this.stocks[sIdx].coffeeId as unknown as Coffee;
                correction.location = this.stocks[sIdx]['locationId'] || this.stocks[sIdx].location?._id as unknown as Location;
                correction.amount = 0;
                correction.date = DateTime.now();
                correction.reason = this.tr.anslate('consumed');
                correction['relative'] = false;

                dialogRef.componentInstance.waiting = true;
                this.standardService.add<Correction>('corrections', correction)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe), takeUntil(dialogRef.componentInstance.cancelled))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully updated'));
                                // make the change locally
                                this.stocks[sIdx].amount = response.result.amount;
                                // notify of the change (why is the local change not enough to update the stock?
                                // because the coffees component will re-render everything and will use its (old) value)
                                this.objectChangedService.objectChanged({ model: 'stocks', info: { storeIdx: sIdx, coffeeId: this.stocks[sIdx].coffeeId, amount: this.stocks[sIdx].amount }, reload: false });
                                this.objectChangedService.objectChanged({ model: 'coffees', info: { coffee: correction.coffee, type: 'transaction' }, reload: true });
                                this.objectChangedService.objectChanged({ model: 'stores', info: { store: correction.location, type: 'transaction' }, reload: false });
                            } else {
                                this.utils.handleError('error adding correction', response.error);
                            }
                            dialogRef.close();
                        },
                        error: error => {
                            this.utils.handleError('error adding correction', error);
                            dialogRef.close();
                        }
                    });
            } else {
                dialogRef.close();
            }
        });
    }

    editTransaction(transaction: StockChange): void {
        if (this.readOnly) { return; }

        this.transService.editTransaction(transaction, this.ngUnsubscribe, trans => {
            // notify parent and siblings
            this.objectChangedService.objectChanged({ model: 'stores', info: { store: transaction.location, type: 'transaction' }, reload: false });
            this.objectChangedService.objectChanged({ model: 'coffees', info: { coffee: transaction.coffee, type: 'transaction' }, reload: true });

            // if the (source) store has been changed, need to update this as well
            if (!this.utils.compareObjectsFn(trans.location, transaction.location)) {
                this.objectChangedService.objectChanged({ model: 'stores', info: { store: trans.location, type: 'transaction' }, reload: false });
            }
            transaction = trans;
        });
    }
}
