<a [routerLink]="['/welcome']">
    <div class="backdrop" [ngClass]="{ 'animated': doblur }">
        <div class="background-mask"></div>
    </div>
</a>
<div class="ap-popuplike">
    @if (!setPassword) {
    <div>
        <form name="form" (ngSubmit)="f.form.valid && resetPassword()" #f="ngForm">
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
                <mat-form-field class="form-group">
                    <mat-label i18n="users$$email address@@email">Email</mat-label>
                    <input matInput #email="ngModel" required email name="email"
                        autofocus autocomplete="username email"
                        id="npt_email" type="email" data-test="npt_email"
                        placeholder="Email" i18n-placeholder="users$$email address@@email"
                        [(ngModel)]="myemail">
                </mat-form-field>
            </div>
            <div class="form-group">
                <button [disabled]="!f.form.valid || loading" data-test="bttn_reset"
                    mat-raised-button class="primary-element mainbutton" type="submit"
                    i18n="register$$reset password, button@@reset_password">RESET PASSWORD</button>
                @if (loading) {
                <mat-spinner></mat-spinner>
                }
            </div>
        </form>
    </div>
    } @else {
    <form name="form" (ngSubmit)="f.form.valid && updatePassword()" #f="ngForm">
        <div style="display: none" class="form-group">
            <mat-form-field class="form-group">
                <mat-label i18n="users$email address@@email">Email</mat-label>
                <input matInput email name="email2"
                    autocomplete="username email"
                    type="email" data-test="npt_email2"
                    placeholder="Email" i18n-placeholder="users$email address@@email"
                    [(ngModel)]="myemail">
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="form-group">
                <mat-label i18n="users$new password@@register_new_password">New password</mat-label>
                <input matInput #password="ngModel" type="password" name="password"
                    id="npt_newpassword" data-test="npt_newpassword"
                    required pattern=".{4,}" autocomplete="new-password"
                    placeholder="New password" i18n-placeholder="users$new password@@register_new_password"
                    [(ngModel)]="mypassword">
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="form-group">
                <mat-label i18n="register$repeat password@@register_password_repeat">Repeat password</mat-label>
                <input matInput required type="password" name="repeat-password"
                    id="npt_repeatpassword" data-test="npt_repeatpassword"
                    pattern=".{4,}" autocomplete="new-password"
                    placeholder="Repeat password" i18n-placeholder="register$repeat password@@register_password_repeat"
                    [(ngModel)]="repeat_password">
            </mat-form-field>
        </div>
        <div class="form-group">
            <button [disabled]="!f.form.valid || loading" type="submit"
                data-test="bttn_update" mat-raised-button
                class="primary-element mainbutton action-button"
                style="margin: 0;"
                i18n="register$$update password, button@@update password">UPDATE PASSWORD</button>
            @if (loading) {
            <mat-spinner></mat-spinner>
            }
        </div>
    </form>
    }
</div>