<a [routerLink]="['/welcome']">
    <div class="backdrop" [ngClass]="{ 'animated': doblur }">
        <div class="background-mask"></div>
    </div>
</a>
<div class="ap-popuplike">
    <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm">
        @if (showResendVerificationEmail) {
        <a data-test="lnk_resend" [routerLink]="['/login']" (click)="resendVerificationEmail()"
            i18n="users$$resend verification email@@resend verification email" class="reset-password">Resend Verification Email</a>
        }

        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email.valid }">
            <mat-form-field class="form-group" hideRequiredMarker>
                <mat-label i18n="users$$email address@@email">Email</mat-label>
                <input autofocus id="npt_email" data-test="npt_email"
                    placeholder="Email" i18n-placeholder="users$$email address@@email"
                    [(ngModel)]="user.email" #email="ngModel" autocomplete="username email" type="email" name="email"
                    matInput required email #firstinput="matInput">
            </mat-form-field>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }" style="margin-bottom:30px">
            <mat-form-field class="form-group" hideRequiredMarker>
                <mat-label i18n="users$$password@@password">Password</mat-label>
                <input id="npt_password" data-test="npt_password" #password="ngModel"
                    placeholder="Password" i18n-placeholder="users$$password@@password"
                    matInput autocomplete="current-password" name="password" type="password"
                    [(ngModel)]="user.password" required pattern=".{4,}">
            </mat-form-field>
            <a data-test="lnk_resetpassword" class="reset-password" [routerLink]="['/resetPassword']" i18n="users$$reset password, button@@reset_password">RESET PASSWORD</a>
        </div>

        <div class="form-group mainbutton">
            <button [disabled]="loading || !user.email || !user.password || !f.form.valid" autofocus type="submit"
                data-test="bttn_login" mat-raised-button
                class="primary-element mainbutton action-button"
                style="margin: 0;"
                i18n="users$$login button@@login button">LOGIN</button>
        </div>

        <div class="register-doc">
            <a class="login toolbartext" data-test="lnk_register"
                routerLink="/register" routerLinkActive="active"
                i18n="toolbar$$link to registration page@@register link">REGISTER</a>
            <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/docs'" target="_blank" rel="noopener" class="login" style="padding-right: 0">
                <mat-icon>{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
            </a>
        </div>

        @if (loading) {
        <mat-spinner></mat-spinner>
        }
    </form>
</div>