import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { User } from 'src/app/models/User';
import { Account } from 'src/app/models/Account';
import { environment } from 'src/environments/environment';
import { LoginChangedService } from './loginchanged.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { Observable, of, Subject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { throttleTime } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';
import { CustomErrorType } from 'src/app/util/CustomErrorType';
import { DateTime } from 'luxon';
import { UnitSystemType } from 'src/app/util/utils';

export interface UserType {
    user_id: string;
    country: string;
    nickname: string;
    account: {
        paidUntil?: DateTime,
        paid?: boolean,
        active?: boolean,
        currency?: string,
        temperature?: string,
        _id?: string,
        subscription?: string,
        limit?: {
            rlimit?: number, // 0 or undefined for PRO; 500 for HOME, 1500 for HOME+
            rused?: number, // kg roasted in the year before HOME paidUntil (from 2022)
        },
        customs?: {
            tax_warehouse?: { address?: string, number?: string, number2?: string, purpose?: string },
            customs_office?: { name?: string, address?: string },
            report_type?: { country?: string, variant?: string },
            settings?: { manualDiscarded?: boolean },
        };
        company?: {
            name?: string,
            number?: string,
            tax_number?: string,
            beteiligtennr?: string,
            legalform?: string,
            foundation?: DateTime,
            street?: string,
            housenr?: string,
            addinfo?: string,
            zip?: string,
            town?: string,
            partoftown?: string,
            contact?: { phone?: string, name?: string, email?: string },
        };
        settings?: {
            pref_roastreport_type?: string,
            pref_stockinclplanned?: boolean,
            useAvgCost?: boolean,
            schedule_machine?: string,
            schedule_store?: string,
            schedule_unit?: UnitSystemType,
        };
        coupons?: number;
        reminders?: number;
        notifications?: number;
    };
    token: string;
    unit_system: Enumerations.UNIT_SYSTEM;
    temperature_system: Enumerations.TEMPERATURE_SYSTEM;
    admin: boolean;
    enabled_modules: string[];
    language: string;
    // whether the user can make any changes or not
    readonly: boolean;
    readonly_account_idx: number;
    other_accounts: { _id: string, nickname: string, email: string }[];
    export: { linesep?: string, sep?: string, decsep?: string };
    energy_unit: string;
    gas_unit: string;
    // helptips shown (which help indicators have already been shown)
    hts: number;
    // dialogs that should not be shown any more
    dsa: number;
}

export interface InvitedUserInfo {
    _id?: string;
    nickname?: string;
    email?: string;
    admin?: boolean;
}

export enum Role {
    NotSet = 0,
    None = 1,
    Admin = 2,
}


@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
        private loginChangedService: LoginChangedService,
        public mediaMatcher: MediaMatcher,
        private router: Router,
    ) { }

    readonly SUPPLIERPARTNERSSTATE = 'spstate';

    role: Role = Role.NotSet;
    private isRoleAsked = false;

    readonly darkmodeLocalStorageName = 'theme';
    private darkmodeMode = new Subject<'auto' | 'lightmode' | 'darkmode'>();
    darkmodeMode$ = this.darkmodeMode.asObservable();
    private currentDarkmodeMode: 'auto' | 'lightmode' | 'darkmode';

    matcher: MediaQueryList;


    create(user: User): Observable<{ success: boolean, result: { user: { user_id: string, token: string, locale: string } }, error: CustomErrorType }> {
        this.logger.debug('sending new user', user);
        return this.http.post<{
            success: boolean,
            result: { user: { user_id: string, token: string, locale: string } },
            error: CustomErrorType
        }>(environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/', user);
    }

    setEmailVerified(user_id: string, token: string): Observable<{ success: boolean, result: User, error: CustomErrorType }> {
        this.logger.debug('setting user email verified', user_id, token);
        return this.http.put<{ success: boolean, result: User, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/email_verified/' + user_id, { token: token });
    }

    sendVerificationEmail(userInfo: { email: string }): Observable<{ success: boolean, result: string, error: CustomErrorType }> {
        this.logger.debug('sendVerificationEmail for', userInfo);
        return this.http.post<{ success: boolean, result: string, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/verifyemail', userInfo);
    }

    addUserToAccount(email: string, readonly: boolean): Observable<{ success: boolean, result: string, error: CustomErrorType }> {
        this.logger.debug('addUserToAccount for', email);
        return this.http.post<{ success: boolean, result: string, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/addtoaccount', { email, readonly_user: readonly });
    }

    removeUserFromAccount(userId: string, email: string, readonly: boolean, pending: boolean): Observable<{ success: boolean, result: string, error: CustomErrorType }> {
        this.logger.debug('removeUserFromAccount for', email);
        return this.http.post<{ success: boolean, result: string, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/removefromaccount', { userId, email, readonly, pending });
    }

    removeViewerFromMyAccount(userId: string): Observable<{ success: boolean, result: string, error: CustomErrorType }> {
        this.logger.debug('removeViewerFromMyAccount for', userId);
        return this.http.post<{ success: boolean, result: string, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/removefrommyaccount', { userId });
    }

    removeAccountFromAccount(account: string): Observable<{ success: boolean, result: { other_accounts: string[] }, error: CustomErrorType }> {
        this.logger.debug('removeAccountFromAccount for', account);
        return this.http.post<{ success: boolean, result: { other_accounts: string[] }, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/removeaccountfromaccount', { account });
    }

    getInvitationInfo(token: string): Observable<{ success: boolean, result: { email: string, by: string, readonly_user: boolean, existingObjects: number }, error: CustomErrorType }> {
        this.logger.debug('getInvitationInfo for', token);
        return this.http.get<{ success: boolean, result: { email: string, by: string, readonly_user: boolean, existingObjects: number }, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/inviteinfo/' + token);
    }

    getAdditionalInfo(onlySpecificType?: string): Observable<{
        success: boolean, result: {
            admin: boolean, email: string,
            otherUsers: InvitedUserInfo[], pendingUsers: InvitedUserInfo[], otherViewers: InvitedUserInfo[], pendingViewers: InvitedUserInfo[]
        }, error: CustomErrorType
    }> {
        this.logger.debug('getAdditionalInfo');
        return this.http.get<{
            success: boolean, result: {
                admin: boolean, email: string, otherUsers: InvitedUserInfo[], pendingUsers: InvitedUserInfo[], otherViewers: InvitedUserInfo[], pendingViewers: InvitedUserInfo[],
            }, error: CustomErrorType
        }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/addinfo', onlySpecificType ? { params: { type: onlySpecificType } } : undefined);
    }

    switchAccountOfUser(token: string): Observable<{
        success: boolean,
        result: {
            readonly: boolean, readonly_account_idx: number, other_accounts: { _id: string, nickname: string, email: string }[]
        }, error: CustomErrorType
    }> {
        this.logger.debug('switchAccountOfUser for', token);
        return this.http.post<{ success: boolean, result: { readonly: boolean, readonly_account_idx: number, other_accounts: { _id: string, nickname: string, email: string }[] }, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/switchaccount', { token });
    }

    // sendEmail(content: string, email: string, subject: string) {
    //     this.logger.debug('sendEmail to', email);
    //     return this.http.post<{success: boolean, result: string, error: CustomErrorType}>
    //     (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/email', {content: content, email: email, subject: subject});
    // }

    sendPasswordResetEmail(email: string): Observable<{ success: boolean, result: string, error: CustomErrorType }> {
        this.logger.debug('sendPasswordResetEmail for', email);
        return this.http.post<{ success: boolean, result: string, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/resetpasswordemail', { email });
    }

    updatePassword(userInfo: { _id: string, password: string, token: string }, loggedIn = false): Observable<{ success: boolean, result: string, error: CustomErrorType }> {
        this.logger.debug('updatePassword for', userInfo._id, loggedIn ? 'with password' : 'with token' + userInfo.token);
        return this.http.put<{ success: boolean, result: string, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/password' + (loggedIn ? '2' : ''), userInfo);
    }

    updateUser(user: Partial<User>): Observable<{ success: boolean, result: User, error: CustomErrorType }> {
        this.logger.debug('update user with', user);
        return this.http.put<{ success: boolean, result: User, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users', user);
    }

    resendInvitationEmail(invited_email: string): Observable<{ success: boolean, result: '', error: CustomErrorType }> {
        return this.http.post<{ success: boolean, result: '', error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/resend', { invited_email });
    }

    /**
     * Retrieves the current user object from local storage.
     * If a routeSnapshop is given, the params are checked for parameters that affect the logged in state
     * (e.g. _account:1 will call changeLoginStatus if not already looking at readonly account #1).
     * @param {ActivatedRouteSnapshot} routeSnapshot current .snapshot from ActivatedRoute; contains params such as _account:1
     * @returns {UserType} currently stored user info if logged in
     */
    getCurrentUser(routeSnapshot?: ActivatedRouteSnapshot): UserType {
        const luser = localStorage.getItem('currentUser');
        this.logger.trace('found logged in user', luser);
        if (luser) {
            const user = JSON.parse(luser) as UserType;
            if (typeof user.account?.paidUntil === 'string') {
                user.account.paidUntil = DateTime.fromISO(user.account.paidUntil);
            }
            if (typeof user.account?.company?.foundation === 'string') {
                user.account.company.foundation = DateTime.fromISO(user.account.company.foundation);
            }

            if (routeSnapshot?.params?._account) {
                if (user?.other_accounts
                    && user.readonly_account_idx !== parseInt(routeSnapshot.params._account, 10)
                    && user.other_accounts.length > routeSnapshot.params._account) {
                    user.readonly_account_idx = parseInt(routeSnapshot.params._account, 10);
                    user.readonly = true;
                    this.storeCurrentUser(user);
                    this.loginChangedService.changeLoginStatus(true);
                }
            }

            user.language = user.language ?? 'en';
            return user;
        }
        return undefined;
    }

    storeCurrentUser(user: UserType): void {
        if (!user) {
            return;
        }
        user.language = user.language ?? 'en';
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    storeSetting(name: 'spstate', value: string): void {
        const settings = localStorage.getItem('settings');
        let settingsObj: { spstate?: string };
        if (!settings) {
            settingsObj = {};
        } else {
            try {
                settingsObj = JSON.parse(settings);
            } catch {
                settingsObj = {};
            }
        }
        if (settingsObj[name] !== value) {
            settingsObj[name] = value;
            try {
                localStorage.setItem('settings', JSON.stringify(settingsObj));
            } catch {
                // ignore
            }
        }
    }
    getSetting(name: string, defaultvalue: string): string {
        const settings = localStorage.getItem('settings');
        if (!settings) {
            return defaultvalue;
        }
        try {
            return JSON.parse(settings)[name];
        } catch {
            return defaultvalue;
        }
    }

    storeDarkmode(dm: 'lightmode' | 'darkmode' | 'auto'): void {
        if (this.currentDarkmodeMode !== dm) {
            this.currentDarkmodeMode = dm;
            localStorage.setItem(this.darkmodeLocalStorageName, dm);
        }
        // call independent of whether the mode changed or not: could be that the media query changed
        this.darkmodeMode.next(dm);
    }

    getDarkmode(): 'lightmode' | 'darkmode' | 'auto' {
        if (this.currentDarkmodeMode) {
            return this.currentDarkmodeMode;
        }
        const dm = localStorage.getItem(this.darkmodeLocalStorageName);
        if (dm && (dm === 'lightmode' || dm === 'darkmode')) {
            return dm;
        }
        return 'auto';
    }
    isDarkModeEnabled(dm?: 'auto' | 'lightmode' | 'darkmode'): boolean {
        if (!dm) {
            dm = this.getDarkmode();
        }
        if (dm === 'darkmode') {
            return true;
        }
        if (dm === 'lightmode') {
            return false;
        }
        // darkmode mode is 'auto'; whether darkmode or not depends on @media
        return this.mediaMatcher.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    isReadOnly(): boolean {
        const user = this.getCurrentUser();
        return !user || user.readonly === true;
    }
    isReadOnlyAccount(): boolean {
        const user = this.getCurrentUser();
        return !user || (typeof user.readonly_account_idx !== 'undefined');
    }

    getPageSize(dataName: string, defaultPageSize: number): number {
        const ps = localStorage.getItem('pageSize_' + dataName);
        if (ps && !Number.isNaN(Number(+ps)) && +ps > 0 && +ps < 100) {
            return +ps;
        }
        return defaultPageSize;
    }

    setPageSize(dataName: string, pageSize: number): void {
        localStorage.setItem('pageSize_' + dataName, pageSize.toString());
    }

    /**
     * Returns the given item from local storage.
     * Don't use this if there is a specific function, e.g. readShowStockFrom (in utils) for dataName 'stockfrom'
     * @param {{string}} dataName string identifying the item in local storage
     * @returns {{string}} content in local storage or undefined if not found, see localStorage.getItem()
     */
    getFromLocal(dataName: string): string {
        return localStorage.getItem(dataName);
    }

    /**
     * Stores the given item into local storage.
     * Don't use this if there is a specific function, e.g. storeShowStockFrom (in utils) for dataName 'stockfrom'
     * @param {{string}} dataName string identifying the item in local storage
     * @param {{string}} data content for local storage, see localStorage.setItem()
     */
    storeToLocal(dataName: string, data: string): void {
        localStorage.setItem(dataName, data);
    }

    login(email: string, password: string, token?: string): Observable<{ success: boolean, result: { user: UserType }, error: CustomErrorType }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/authenticate';
        const params = { email, password, token };
        this.logger.debug('calling api/authenticate');
        return this.http.post<{ success: boolean, result: { user: UserType }, error: CustomErrorType }>(url, params);
    }

    logout(): void {
        // remove user from local storage to log user out
        this.setRole(Role.NotSet);
        localStorage.removeItem('currentUser');
        this.loginChangedService.changeLoginStatus(false);
    }

    navigateToLogin(returnUrl: string): void {
        // not logged in so redirect to login page with the return url
        this.logout();
        this.logger.debug('user not logged in, go to login page with returnUrl ', returnUrl);
        this.router.navigate(['/login'], { queryParams: { returnUrl } });
    }

    setRole(r: Role): void {
        this.role = r;
    }

    getUserRole(): Observable<Role> {
        if (this.role !== Role.NotSet) {
            return of(this.role);
        }
        if (typeof this.role === 'undefined') {
            return of(Role.None);
        }
        const obs = this.http.get<{ success: boolean, result: Role, error: CustomErrorType }>(environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/role');
        if (!this.isRoleAsked) {
            obs.pipe(distinctUntilChanged(), throttleTime(environment.RELOADTHROTTLE)).subscribe({
                next: val => this.role = val.result,
                error: () => this.role = undefined,
            });
            this.isRoleAsked = true;
        }
        return obs.pipe(map(
            val => val ? val.result : Role.None
        ));
    }

    deleteAllData(userId: string): Observable<{ success: boolean, result: string, error: CustomErrorType }> {
        this.logger.debug('deleteAllData', userId);
        return this.http.delete<{
            success: boolean,
            result: string,
            error: CustomErrorType
        }>(environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/alldata/' + userId);
    }

    deleteAccount(userId: string): Observable<{ success: boolean, result: string, error: CustomErrorType }> {
        this.logger.debug('deleteAccount', userId);
        return this.http.delete<{
            success: boolean,
            result: string,
            error: CustomErrorType
        }>(environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/alldata/account/' + userId);
    }

    getAllData(userId: string): Observable<{ success: boolean, result: User, error: CustomErrorType }> {
        this.logger.debug('getAllData', userId);
        return this.http.get<{ success: boolean, result: User, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/alldata/' + userId);
    }

    getCustomsInfo(): Observable<{ success: boolean, result: { customs: Account['customs'], company: Account['company'] }, error: CustomErrorType }> {
        this.logger.debug('getCustomsInfo');
        return this.http.get<{ success: boolean, result: { customs: Account['customs'], company: Account['company'] }, error: CustomErrorType }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/accounts/users/customsinfo');
    }
}
