import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Subject } from 'rxjs';
import { Enumerations } from 'src/app/models/Enumerations';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NavigationEnd, ActivatedRoute, Router } from '@angular/router';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { ReportService } from 'src/app/modules/report/report.service';
import { BeansReport } from 'src/app/models/BeansReport';

@Component({
    selector: 'app-beansreports',
    templateUrl: './beansreports.component.html',
})
export class BeansreportsComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private utils: Utils,
        private reportService: ReportService,
        private userService: UserService,
        private route: ActivatedRoute,
    ) { }

    beansreports: BeansReport[];
    newReportMode = false;
    reportNames: string[] = [];
    @Input() currentUser: UserType;
    readOnly = false;

    loadSubscription = new Subject<string>();
    private ngUnsubscribe = new Subject();

    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';
    FIELDSEPARATOR = '\t';
    LINESEPARATOR = '\r\n';
    DECIMALSEPARATOR = '.';

    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }

        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }
        if (this.currentUser.export) {
            this.FIELDSEPARATOR = this.currentUser.export.sep;
            this.LINESEPARATOR = this.currentUser.export.linesep;
            this.DECIMALSEPARATOR = this.currentUser.export.decsep;
        }

        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.readOnly = this.userService.isReadOnly();
                this.getExistingReports();
            });

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && e.url.indexOf('reports') >= 0) {
                    // only pass and debounce interesting events
                    this.loadSubscription.next('reload');
                }
            });

        this.loadSubscription.next('init');
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    getExistingReports(): void {
        this.reportService.getBeansReports()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.beansreports = this.utils.dateifyBeansReports(response.result);

                    } else {
                        this.utils.handleError('error retrieving reports', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving reports', error);
                }
            });
    }

    add(): void {
        this.newReportMode = true;
        this.reportNames = (this.beansreports || []).map(rep => rep?.label);
    }

    onNewReportModeChanged(mode: boolean): void {
        this.newReportMode = mode;
    }

    onReportDeleted(deletedReport: BeansReport): void {
        if (deletedReport?._id) {
            this.beansreports = this.beansreports.filter(r => (r._id || r).toString() !== (deletedReport._id || deletedReport).toString());
        }
    }

    // the object is directly updated
    // onReportChanged(changedReport: BeansReport): void {
    //     if (changedReport?._id) {
    //         for (let r = 0; r < this.beansreports.length; r++) {
    //             if (changedReport._id.toString() === this.beansreports[r]?._id?.toString()) {
    //                 this.beansreports[r] = changedReport;
    //                 return;
    //             }
    //         }
    //     }
    // }

    onNewReport(newReport: BeansReport): void {
        if (newReport) {
            this.beansreports.unshift(newReport);
        }
    }
}
