<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>

<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>



<ng-template #smallSpinner>
    <mat-spinner diameter="15" strokeWidth="1" class="small-spinner"></mat-spinner>
</ng-template>



@if (!data.transaction) {
<h1 mat-dialog-title i18n="transaction$$add transfer header@@add transfer">Add Transfer</h1>
} @else {
<h1 mat-dialog-title i18n="transaction$$edit transfer header@@edit transfer">Edit Transfer</h1>
}
<mat-dialog-content>

    <form class="narrow-form" id="form" name="form" (ngSubmit)="f.form.valid && add()" #f="ngForm">
        <div class="right-align">
            <div class="flex-wrap">
                @if (!doneLoadingStocks || (from_stores && from_stores.length > 0)) {
                <mat-form-field>
                    <mat-label i18n="transaction$$source store placeholder@@from source store">From</mat-label>
                    <mat-select required [disabled]="waiting" name="from_store"
                        placeholder="From"
                        i18n-placeholder="transaction$$source store placeholder@@from source store"
                        panelClass="twoLineOption" panelWidth="null" [hideSingleSelectionIndicator]="true"
                        [(ngModel)]="from_store" [compareWith]="utils.compareObjectsFn"
                        (selectionChange)="sourceStoreChanged()">
                        <mat-select-trigger class="twoLineOption ellipsed">
                            <ng-container *ngTemplateOutlet="labelEntry; context: {obj: from_store}"></ng-container>
                        </mat-select-trigger>
                        @if (from_store && (!from_stores || from_stores.length === 0)) {
                        <mat-option [value]="from_store">
                            <ng-container *ngTemplateOutlet="labelEntry; context: {obj: from_store}"></ng-container>
                        </mat-option>
                        }
                        @if (from_stores) {
                        @for (fstore of from_stores; track fstore?._id) {
                        <mat-option [value]="fstore">
                            <ng-container *ngTemplateOutlet="labelEntry; context: {obj: fstore}"></ng-container>
                        </mat-option>
                        }
                        }
                    </mat-select>
                </mat-form-field>
                } @else {
                <div i18n="transaction$$no stores with beans message@@no stores with beans">You do not have any stores with beans.</div>
                }
                @if (!from_stores) {
                <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
                }
            </div>
        </div>

        @if (from_store) {
        <div>
            <div class="right-align">
                <div class="flex-wrap">
                    <mat-form-field>
                        <mat-label i18n="generic$$Beans@@Beans">Beans</mat-label>
                        <mat-select required [disabled]="waiting" name="coffee"
                            panelClass="twoLineOption" panelWidth="null" [hideSingleSelectionIndicator]="true"
                            placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                            [(ngModel)]="coffee"
                            [compareWith]="utils.compareObjectsFn"
                            (selectionChange)="coffeeChanged()">
                            <mat-select-trigger>
                                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coffee}"></ng-container>
                            </mat-select-trigger>
                            @if (coffees?.length >= 5) {
                            <mat-option>
                                <ngx-mat-select-search ngModel (ngModelChange)="filteredCoffees = utils.filterObjects($event, coffees)" name="coffeeSelect"
                                    placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                    noEntriesFoundLabel="">
                                </ngx-mat-select-search>
                            </mat-option>
                            }
                            @if (filteredCoffees) {
                            @for (coff of filteredCoffees; track coff?._id) {
                            <mat-option [value]="coff">
                                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coff}"></ng-container>
                            </mat-option>
                            }
                            }
                        </mat-select>
                    </mat-form-field><br />
                    @if (!filteredCoffees) {
                    <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
                    }
                </div>
            </div>
            @if (coffee) {
            <div>
                @if (coffee.default_unit?.name) {
                <mat-form-field class="amount-input-ff1" floatLabel="always">
                    <mat-label i18n="generic$$Amount (of beans, e.g. in kg or bags)@@Amount">Amount</mat-label>
                    <input matInput required [disabled]="waiting" name="amount" class="amount-input"
                        type="text" inputmode="numeric" pattern="[\-0-9,.]*"
                        (change)="checkChangesUnits(this, 'amount', amount, $event.target['value'], true)"
                        [value]="amount | number:'1.0-3'">
                    @if (amount === 1) {
                    <span>{{tr.anslate(coffee.default_unit.name)}}</span>
                    } @else {
                    {{tr.anslate('plural_' + coffee.default_unit.name)}}
                    }
                </mat-form-field>
                }
                <mat-form-field class="amount-input-ff2" floatLabel="always">
                    <mat-label i18n="transaction$$weight of beans in, e.g. kg@@Weight in {{mainUnit}}">Weight in {{mainUnit}}</mat-label>
                    <input matInput [required]="!coffee.default_unit?.name" [disabled]="waiting" name="unitamount"
                        class="amount-input" type="text" min="0" inputmode="numeric" pattern="[\-0-9,.]*"
                        (change)="checkChangesUnits(this, 'unitamount', unitamount, $event.target['value'], false)"
                        [value]="unitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'">{{mainUnit}}
                </mat-form-field><br />
                @if (getAmountStr(); as available) {
                <div class="comment">
                    <span [ngClass]="{'warn-amount': round(this.unitamount * utils.getUnitFactor(mainUnit) * 1000) > round(available.value * 1000)}" i18n="transaction$$label before showing how much of those beans are available in this store@@Amount of beans available">Available:</span>
                    @if (doneLoadingStocks) {
                    <a class="spaceleft cursor-pointer"
                        (click)="useAllStock()" (keydown.enter)="useAllStock(); $event.preventDefault()" role="button" [tabindex]="0"
                        [ngClass]="{'warn-amount': round(this.unitamount * utils.getUnitFactor(mainUnit) * 1000) > round(available.value * 1000)}">{{available.pre}}{{available.value | number:'1.0-2'}}{{available.post}}</a>
                    } @else {
                    <mat-spinner diameter="15" strokeWidth="1" class="small-spinner spaceleft"></mat-spinner>
                    }
                </div>
                }
                <div class="right-align">
                    <div class="flex-wrap">
                        <mat-form-field>
                            <mat-label i18n="transaction$$Target store placeholder@@Target store">Target store</mat-label>
                            <mat-select required [disabled]="waiting" name="to_store"
                                panelClass="twoLineOption" panelWidth="null" [hideSingleSelectionIndicator]="true"
                                placeholder="Target store" i18n-placeholder="transaction$$Target store placeholder@@Target store"
                                [(ngModel)]="to_store"
                                [compareWith]="utils.compareObjectsFn">
                                <mat-select-trigger class="twoLineOption ellipsed">
                                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: to_store}"></ng-container>
                                </mat-select-trigger>
                                @if (to_store && (!to_stores || to_stores.length === 0)) {
                                <mat-option [value]="to_store">
                                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: to_store}"></ng-container>
                                </mat-option>
                                }
                                @if (to_stores) {
                                @for (tostore of to_stores; track tostore?._id; let c = $index) {
                                <mat-option [value]="tostore">
                                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: tostore}"></ng-container>
                                </mat-option>
                                }
                                }
                            </mat-select>
                        </mat-form-field>
                        @if (!to_stores) {
                        <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
                        }
                    </div>
                </div>
                <mat-form-field>
                    <mat-label i18n="transaction$$placeholder, an arbitrary reference string@@reference">Reference</mat-label>
                    <input matInput type="string" [disabled]="waiting" name="reference"
                        placeholder="Reference" i18n-placeholder="transaction$$placeholder, an arbitrary reference string@@reference"
                        [(ngModel)]="reference">
                </mat-form-field>
                <app-ap-datetime [value]="date" [dateRequired]="true" [disabled]="waiting" (valueChange)="date=$event"></app-ap-datetime>
            </div>
            } @else {
            @if (coffees?.length === 0) {
            <div>
                <span i18n="transaction$$no beans to transfer / sell etc.@@no beans">Please add beans first!</span>
            </div>
            }
            @if (coffees?.length > 0) {
            <div i18n="transaction$$need to select some beans before continuing@@select coffee">Please select beans first!</div>
            }
            }
        </div>
        } @else {
        @if (stores?.length === 0) {
        <div i18n="transaction$$no stores defined yet@@no stores">Please add a store first!</div>
        }
        @if (stores?.length > 0) {
        <div i18n="transaction$$select a source store first@@Please select a source store first!">Please select a source store first!</div>
        }
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="handleExit(undefined, undefined, true)" class="cancel-button" type="button">
        <mat-icon>close</mat-icon>
    </button>
    <button mat-raised-button [disabled]="waiting || !f.form.valid || notLoaded > 0 || (doneLoadingStocks && (!from_stores || from_stores.length === 0))" form="form" ng-submit type="submit" class="action-button spinner-button">
        @if (showWaiting) {
        <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
        } @else {
        <mat-icon>done</mat-icon>
        }
    </button>
</mat-dialog-actions>