<div>
    @if (readOnly) {
    <div class="readonly-spacer"></div>
    }

    <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="select-roasts-nav">
        <a mat-tab-link class="small-mat-tab"
            (click)="activeLink = 'fixed'"
            [routerLink]="'fixed'"
            [active]="activeLink === 'fixed'">
            <span i18n="reports$$menu item, reports that have to be sequential and without gaps@@Sequential">Sequential</span>
        </a>
        <a mat-tab-link class="small-mat-tab"
            (click)="activeLink = 'open'"
            [routerLink]="'open'"
            [active]="activeLink === 'open'">
            <span i18n="reports$$menu item, reports that can overlap and can have gaps@@Open">Open</span>
        </a>
    </nav>

    <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
    </mat-tab-nav-panel>
</div>