import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserType, UserService } from 'src/app/modules/frame/services/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-select-roastreports',
    templateUrl: './select-roastreports.component.html',
    styleUrls: ['./select-roastreports.component.scss'],
})
export class SelectRoastreportsComponent implements OnInit, OnDestroy {


    constructor(
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    currentUser: UserType;
    readOnly = false;
    private ngUnsubscribe = new Subject();

    selectedTab: number;

    activeLink = 'fixed';

    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        this.readOnly = this.userService.isReadOnly();

        // url: /reports/roasts or /reports/roasts/fixed or /reports/roasts/open
        if (this.router.url === '/reports/roasts') {
            if (this.currentUser?.account?.settings?.pref_roastreport_type === 'open') {
                this.activeLink = 'open';
            } else {
                this.activeLink = 'fixed';
            }
        } else {
            let lastSeg = this.router.url?.substring(this.router.url.lastIndexOf('/') + 1) || 'fixed';
            const idx = Math.max(lastSeg.indexOf(';'), lastSeg.indexOf('?'));
            if (idx >= 0) {
                lastSeg = lastSeg.substring(0, idx);
            }
            this.activeLink = lastSeg;
        }
        this.router.navigate(['/reports/roasts/' + this.activeLink], { replaceUrl: true });

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (e: unknown) => {
                    if (e instanceof NavigationEnd) {
                        // url: /reports/roasts or /reports/roasts/fixed or /reports/roasts/open
                        if (this.router.url === '/reports/roasts') {
                            if (this.currentUser?.account?.settings?.pref_roastreport_type === 'open') {
                                this.activeLink = 'open';
                            } else {
                                this.activeLink = 'fixed';
                            }
                            this.router.navigate(['/reports/roasts/' + this.activeLink], { replaceUrl: true });
                        } else {
                            let lastSeg = this.router.url?.substring(this.router.url.lastIndexOf('/') + 1) || 'fixed';
                            // check whether there is a direkt id (label); TODO how to keep it in the URL?
                            const idx = Math.max(lastSeg.indexOf(';'), lastSeg.indexOf('?'));
                            if (idx >= 0) {
                                lastSeg = lastSeg.substring(0, idx);
                            }
                            this.activeLink = lastSeg;
                        }
                        // this.router.navigate(['/reports/roasts/' + this.activeLink]);
                    }
                }
            );

        if (this.router.url === '/reports/roasts') {
            this.activeLink = 'fixed';
        } else {
            this.activeLink = this.router.url?.substring(this.router.url.lastIndexOf('/') + 1) || 'fixed';
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }
}
