<ng-template #unitTemplate let-unit="unit" let-warn="warn">
    <span class="scheduler-unit cursor-pointer" [ngClass]="{'warn-amount': warn}" (click)="changeSubMainUnit()" (keydown.enter)="changeSubMainUnit(); $event.preventDefault()" role="button" [tabindex]="0">{{unit}}
        <!-- @if (unit !== '%') {
        <mat-icon class="unit-change-indicator">arrow_drop_down</mat-icon>
        } -->
    </span>
</ng-template>

<ng-template #coffeeEntry let-coffee="coffee" let-isTrigger="isTrigger">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon [isOrganic]="coffee?.organic" [size]="7" [leftMargin]="3"></app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <!-- @if (isTrigger) {
    <div>
        {{coffee.label}}@if (coffee.totalStockStr) {: <span [ngClass]="{'warn-amount': !coffee.totalStock || unitamount > coffee.totalStock}">{{coffee.totalStockStr}}</span>}
    </div>
    } @else { -->
    <div class="scheduler-label-with-stock">
        <span style="margin-right: 15px; overflow: hidden; text-overflow: ellipsis;">{{coffee.label}}</span>
        @if (coffee.totalStockStr) {
        @if (!coffee.totalStock || unitamount > coffee.totalStock) {
        <span class="scheduler-dropdown-amount warn-amount">{{coffee.totalStockStr}}</span>
        } @else {
        <span class="scheduler-dropdown-amount">{{coffee.totalStockStr}}</span>
        }
        <!-- <span class="scheduler-dropdown-amount" [ngClass]="{'warn-amount': !coffee.totalStock || unitamount > coffee.totalStock}">{{coffee.totalStockStr}}</span> -->
        }
    </div>
    }
    <!-- } -->
</ng-template>

<ng-template #templateEntry let-template="template" let-isTrigger="isTrigger">
    <div class="smallerfont">
        @if (template?.batch_prefix) {
        <span>{{template.batch_prefix}}</span>
        }
        @if (Number.isFinite(template?.batch_number)) {
        <span>{{Math.round(template.batch_number)}}</span>
        }
        @if (template?.batch_prefix || Number.isFinite(template?.batch_number)) {, }
        @if (template?.start_weight || template?.amount) {
        <span>{{utils.formatAmount(template.start_weight ?? template.amount, undefined, mainUnit)}}</span>
        }
        @if (template?.end_weight) {
        <span> -> {{utils.formatAmount(template.end_weight, undefined, mainUnit)}}</span>
        }
        @if (template?.machine) {
        @if (template.start_weight || template.amount || template.end_weight) {, }
        <span>{{template.machine}}</span>
        }
        @if (template?.drop_time) {
        @if (template.drop_time || template.start_weight || template.amount || template.end_weight) {, }
        <span>{{Math.floor(template.drop_time / 60.0) + ':' + utils.padNumber(template.drop_time % 60, 2)}}</span>&nbsp;<span i18n="unit_system$$short for minutes@@min">min</span>
        }
    </div>
    @if (template?.label) {
    <!-- @if (isTrigger) {
    <div>
        {{coffee.label}}@if (coffee.totalStockStr) {: <span [ngClass]="{'warn-amount': !coffee.totalStock || unitamount > coffee.totalStock}">{{coffee.totalStockStr}}</span>}
    </div>
    } @else { -->
    <div style="display: flex;">{{template.label}}</div>
    }
    <!-- } -->
</ng-template>

<ng-template #storeEntry let-store="store" let-isTrigger="isTrigger">
    <div class="smallerfont">
        @if (store?.hr_id) {
        <span>{{store.hr_id}}</span>
        }
    </div>
    @if (store?.label) {
    <!-- @if (isTrigger) {
    <div>
        {{coffee.label}}@if (coffee.totalStockStr) {: <span [ngClass]="{'warn-amount': !coffee.totalStock || unitamount > coffee.totalStock}">{{coffee.totalStockStr}}</span>}
    </div>
    } @else { -->
    <div class="scheduler-label-with-stock">
        <span style="margin-right: 15px;">{{store.label}}</span>
        @if (store.coffeeStockStr) {
        <span class="scheduler-dropdown-amount" [ngClass]="{'warn-amount': !store.coffeeStock || unitamount > store.coffeeStock}">{{store.coffeeStockStr}}</span>
        }
    </div>
    }
    <!-- } -->
</ng-template>

<ng-template #blendEntry let-blend="blend" let-isTrigger="isTrigger">
    <div class="smallerfont scheduler-inside-select">
        @if (blend?.hr_id) {
        <span class="littlespaceright">{{blend.hr_id}}</span>
        <app-organicicon [isOrganic]="blend.organic" [size]="10" [leftMargin]="3" [rightMargin]="5"></app-organicicon>
        }
        @if (blend?.blendinfo) {
        <span>{{blend.blendinfo}}</span>
        }
    </div>
    <div class="scheduler-label-with-stock">
        <span style="margin-right: 15px;">{{blend?.label}}</span>
        @if (blend?.totalStockStr) {
        <span class="scheduler-dropdown-amount" [ngClass]="{'warn-amount': !blend.totalStock || unitamount > blend.totalStock}">{{blend.totalStockStr}}</span>
        }
    </div>
</ng-template>


<ng-template #storeInputGroup let-broadWidget="broadWidget">
    <!-- Store -->
    @if (stores?.length >= 2) {
    <div class="scheduler-input-group" style="position: relative;"
        [ngStyle]="{'margin-top': 4 >= stores?.length ? '20px' : '5px'}">
        @if (4 >= stores?.length) { <!-- does not include null -->
        <span class="scheduler-chip-input-header">
            <ng-container i18n="generic$$storage location@@Store">Store</ng-container>
        </span>
        }
        <span>
            @if (4 >= stores?.length) { <!-- does not include null -->
            <app-chips-filter #storeFilter class="left-margin-only"
                [margin_top]="-19"
                [mainWidth]="broadWidget ? '760px' : '355px'"
                chipWidth="170px"
                [multiple]="false"
                [atLeastOne]="true"
                [warnNonSelected]="false"
                [additionalValues]="coffeeStockStrs"
                [additionalValueWarns]="coffeeStockWarns"
                [allOptions]="storesLabels ?? []"
                placeholder="Store"
                (selectChanged)="storeChanged(true, $event?.[0])">
            </app-chips-filter>
            } @else {
            <mat-form-field required class="form-group scheduler-input-formfield-normal" style="min-height: 54px;"
                subscriptSizing="dynamic" hideRequiredMarker="true">
                <mat-label i18n="generic$$storage location@@Store">Store</mat-label>
                <mat-select required hideSingleSelectionIndicator="true"
                    name="stores" panelWidth="null"
                    [(ngModel)]="store"
                    (selectionChange)="storeChanged()"
                    [compareWith]="utils.compareObjectsFn">
                    <mat-select-trigger class="twoLineOption ellipsed">
                        <ng-container *ngTemplateOutlet="storeEntry; context: {store, isTrigger: true}"></ng-container>
                    </mat-select-trigger>
                    @if (stores?.length >= 5) {
                    <mat-option>
                        <ngx-mat-select-search ngModel (ngModelChange)="filteredStores = filterLabelObjects($event, stores)" name="storeSelect"
                            placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                            noEntriesFoundLabel="">
                        </ngx-mat-select-search>
                    </mat-option>
                    }
                    @for (store of (filteredStores ?? []); track store?._id) {
                    <mat-option [value]="store" class="scheduler-select-option-with-stock">
                        <ng-container *ngTemplateOutlet="storeEntry; context: {store}"></ng-container>
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>
            }
        </span>
    </div>
    }
</ng-template>




<!--       1st group        -->



<div class="scheduler-input-div">

    <div class="scheduler-input-buttons scheduler-input-buttons-centered" style="grid-column: 1; grid-row: 1;">
        <button mat-icon-button class="primary-element" (click)="openBeansSearchDialog()" matTooltip="Search beans" i18n-matTooltip="generic$$link to dialog to find beans or blends that are not directly dislayed@@Search beans">
            @if (loading) {
            <mat-spinner class="spinner-in-input" diameter="15"></mat-spinner>
            } @else {
            <mat-icon class="scheduler-narrow-icon">search</mat-icon>
            }
        </button>
    </div>

    <div class="scheduler-input-group-group-main" style="grid-column: 2; grid-row: 1;">
        <div class="scheduler-input-group-box">
            <div class="scheduler-input-group">
                <!-- Beans, coffee -->
                <span>
                    <mat-form-field class="form-group twoline-select scheduler-input-formfield-normal" subscriptSizing="dynamic">
                        <mat-label i18n="generic$$Beans@@Beans">Beans</mat-label>
                        <mat-select [disabled]="!coffees?.length" hideSingleSelectionIndicator="true"
                            placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                            name="coffee" panelClass="twoLineOption" panelWidth="null"
                            [(ngModel)]="coffee"
                            (selectionChange)="beansChanged($event)"
                            [compareWith]="utils.compareObjectsFn">
                            @if (coffee) {
                            <mat-select-trigger class="twoLineOption ellipsed">
                                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee, isTrigger: true}"></ng-container>
                            </mat-select-trigger>
                            }
                            @if (coffees?.length >= 5 || haveFilteredCoffee) {
                            <mat-option>
                                <ngx-mat-select-search ngModel (ngModelChange)="filteredCoffees = utils.filterObjects($event, coffees)" name="coffeeSelect"
                                    placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                    noEntriesFoundLabel="">
                                </ngx-mat-select-search>
                            </mat-option>
                            }
                            <mat-option [value]="null"></mat-option>
                            <!-- <mat-option *ngIf="isNew !== -1 && isNew === index" [value]="null"></mat-option> -->
                            @for (coffee of filteredCoffees; track coffee?._id) {
                            <mat-option [value]="coffee" class="scheduler-select-option-with-stock">
                                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee}"></ng-container>
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    @if (!coffees) {
                    <mat-spinner class="spinner-in-input" diameter="15"></mat-spinner>
                    }
                </span>
            </div>
        </div>

        <div class="scheduler-input-group-box">
            @if (blends?.length) {
            <div class="scheduler-input-group">
                <!-- Blend -->
                <span>
                    <mat-form-field class="form-group twoline-select scheduler-input-formfield-normal" subscriptSizing="dynamic">
                        <mat-label i18n="generic$$Blend@@Blend">Blend</mat-label>
                        <mat-select name="blend"
                            panelClass="twoLineOption" hideSingleSelectionIndicator="true" panelWidth="null"
                            placeholder="Blend" i18n-placeholder="generic$$Blend@@Blend"
                            [compareWith]="utils.compareObjectsFn"
                            [(ngModel)]="blend"
                            (selectionChange)="beansChanged($event, true)">
                            @if (blend) {
                            <mat-select-trigger class="twoLineOption ellipsed">
                                <ng-container *ngTemplateOutlet="blendEntry; context: {blend}"></ng-container>
                            </mat-select-trigger>
                            }
                            @if (blends?.length >= 5 || haveFilteredBlends) {
                            <mat-option>
                                <ngx-mat-select-search ngModel (ngModelChange)="filteredBlends = utils.filterObjects($event, blends)"
                                    placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                    noEntriesFoundLabel="" name="blendSelect">
                                </ngx-mat-select-search>
                            </mat-option>
                            }
                            <mat-option [value]="null"></mat-option>
                            @for (blend of filteredBlends; track blend?._id) {
                            <mat-option [value]="blend">
                                <ng-container *ngTemplateOutlet="blendEntry; context: {blend}"></ng-container>
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </span>
            </div>
            <!-- hints for batch size -->
            @if (blend?.ingredients?.length >= 2) {
            <div class="scheduler-input-group scheduler-input-group-nowrap scheduler-postblend-switch" style="margin-top: -3px;">
                <span class="scheduler-input-formfield-normal-hint scheduler-input-postblend-switch">
                    <mat-checkbox name="postblendToggle" class="primary-element"
                        [(ngModel)]="isPostBlend"
                        i18n="roast$$label, treat as post blend@@Add as post blend">Add as post blend</mat-checkbox>
                </span>
            </div>
            }
            } @else if (stores?.length === 2) { <!-- !blends?.length -->
            <!-- Store -->
            <ng-container *ngTemplateOutlet="storeInputGroup"></ng-container>
            }
        </div>
    </div>



    <!--       2nd group        -->



    <div class="scheduler-input-buttons" style="grid-column: 1; grid-row: 2;">
        @if (!readOnly && editModeItem && !editModeItem.synced && !itemReadOnly) {
        <button [disabled]="isUpdating || !editModeItem || editModeItem.synced || itemReadOnly || (!coffee && !blend) || !batchsize || !batches || !store || !title"
            type="button" mat-raised-button class="add-button action-button schedule-input-add-button"
            (click)="save()">
            <mat-icon>done</mat-icon>
        </button>
        <button [disabled]="isUpdating || !editModeItem || editModeItem?.synced || itemReadOnly"
            type="button" mat-button
            class="cancel-button schedule-input-add-button"
            (click)="cancelEditMode()">
            <mat-icon>close</mat-icon>
        </button>
        }
    </div>

    <div class="scheduler-input-group-group-main" style="grid-column: 2; grid-row: 2;">
        <div class="scheduler-input-group-box">
            <div class="scheduler-input-group scheduler-input-group-nowrap">
                <!-- # batches -->
                <span class="scheduler-input-formfield">
                    <mat-form-field required class="scheduler-input-nrbatches" subscriptSizing="dynamic"
                        [ngClass]="{'warn-amount': !batches}"
                        floatLabel="always" hideRequiredMarker="true">
                        <mat-label i18n="roast$$input, number of batches@@Batches">Batches</mat-label>
                        <!-- [ngClass]="{'warn-amount': !batches || 0 > remains}"> -->
                        <input required type="number" min="1" pattern="[0-9]+"
                            matInput name="batches" data-test="npt_batches"
                            class="scheduler-input-number-input"
                            [(ngModel)]="batches"
                            (ngModelChange)="updateBatches(true)">
                    </mat-form-field>
                </span>

                <!-- Title -->
                <span class="scheduler-input-formfield scheduler-input-flex-middle">
                    <mat-form-field required #titleFormField hideRequiredMarker="true"
                        [matTooltip]="title" subscriptSizing="dynamic"
                        class="form-group" style="width: 150px">
                        <mat-label i18n="generic$$Title@@Title">Title</mat-label>
                        <input required matInput name="label"
                            type="text" data-test="npt_label"
                            class="scheduler-input-title-input"
                            placeholder="Title" i18n-placeholder="generic$$Title@@Title"
                            [matAutocomplete]="autoCompleteTitle"
                            #titleTrigger="matAutocompleteTrigger"
                            (input)="changeTitleFilter()"
                            (focus)="$event.stopPropagation(); titleTrigger.openPanel();"
                            [(ngModel)]="title"
                            (change)="titleChanged()">
                        <mat-autocomplete #autoCompleteTitle="matAutocomplete"
                            panelWidth="300px" hideSingleSelectionIndicator="true"
                            (optionSelected)="titleChanged(true)">
                            @for (rec of recentRoasts; track rec?.title) {
                            <mat-option [value]="rec.title">{{rec.title}}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-icon (click)="openTitleDialog()" class="smallesticon cursor-pointer" style="margin-left: 5px;">edit</mat-icon>
                </span>

                <!-- Batch size -->
                <span class="scheduler-input-batchsize-plusunit"
                    [ngClass]="{'warn-amount': !batchsize}">
                    <mat-form-field required class="scheduler-input-batchsize"
                        subscriptSizing="dynamic" floatLabel="always" hideRequiredMarker="true">
                        <mat-label i18n="roasts$$Batch size@@Batch size">Batch size</mat-label>
                        <!-- [value]="batchsize ? (batchsize * utils.getUnitFactor(mainUnit) | number:weightFormat) : 0"> -->
                        <input required matInput type="text" name="loss"
                            data-test="npt_batchsize" class="scheduler-input-number-input"
                            (change)="checkChangesUnits(this, 'batchsize', batchsize, $event.target['value'], false)"
                            [value]="utils.formatAmount(batchsize, undefined, mainUnit, undefined, false, false, 0, 3)">
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="unitTemplate; context: {unit: mainUnit, warn: !batchsize}"></ng-container>
                </span>
            </div>

            <!-- hints for batch size -->
            @if (!editModeItem?.date || editModeItem.date >= today) {
            <div class="scheduler-input-group scheduler-input-group-nowrap scheduler-filter-hints" style="margin-top: -3px;">
                <!-- <span class="scheduler-input-nrbatches" style="margin-right: 8px;"></span>
                        <span class="scheduler-input-flex-middle" style="margin-right: 8px;"></span> -->
                <span class="scheduler-input-formfield-normal-hint"
                    style="text-align: right"
                    [ngClass]="{'warn-amount': !batchsize || (batchsize && suggestedBatchsize && isOffFromSuggestion(batchsize, suggestedBatchsize)) || Math.round((batches * batchsize - unitamount) * 100) / 100}">
                    @if (Math.round((batches * batchsize - unitamount) * 100) / 100; as remains) {
                    @if (remains > 0) {
                    <ng-container>
                        <a (click)="noLeftOver()" (keydown.enter)="noLeftOver(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">
                            <span i18n="generic$$short label, click to adjust batch size to avoid leftover amount@@Save">Save</span>
                            {{utils.fixDigits(remains * utils.getUnitFactor(mainUnit))}}{{mainUnit}}
                        </a>
                    </ng-container>
                    }
                    @if (0 > remains) {
                    <ng-container>
                        <a (click)="noMissingAmount()" (keydown.enter)="noMissingAmount(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">
                            <span i18n="generic$$label, missing amount@@Missing">Missing</span>: {{utils.fixDigits(-remains * utils.getUnitFactor(mainUnit))}}{{mainUnit}}
                        </a>
                    </ng-container>
                    }
                    } @else if (suggestedBatchsize && isOffFromSuggestion(batchsize, suggestedBatchsize)) {
                    <a (click)="useBatchsizeSuggestion()" (keydown.enter)="useBatchsizeSuggestion(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">
                        <ng-container i18n="scheduler$$label, derived from recent roast statistics@@From history">From history</ng-container>: {{utils.fixDigits(suggestedBatchsize * utils.getUnitFactor(mainUnit))}}{{mainUnit}}
                    </a>
                    }
                </span>
            </div>
            }
        </div>

        <div class="scheduler-input-group-box scheduler-input-totals">
            <div class="scheduler-input-group scheduler-input-group-nowrap">
                <mat-icon class="scheduler-input-summary-icon">arrow_and_edge</mat-icon>

                <!-- Amount -->
                <span class="scheduler-input-formfield scheduler-input-amount-plusunit"
                    [ngClass]="{'warn-amount': !unitamount || (Math.abs(batches * batchsize - unitamount) > EPSILON)}">
                    <mat-form-field class="scheduler-input-amount"
                        subscriptSizing="dynamic" floatLabel="always">
                        <mat-label i18n="stores$$amount, table header@@amount header">Amount</mat-label>
                        <!-- [value]="unitamount * utils.getUnitFactor(mainUnit) | number:weightFormat"> -->
                        <input matInput type="text" name="unitamount"
                            data-test="npt_unitamount" class="scheduler-input-number-input"
                            (change)="checkChangesUnits(this, 'unitamount', unitamount, $event.target['value'], false)"
                            [value]="utils.formatAmount(unitamount, undefined, mainUnit, undefined, false, false, 0, 3)">
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="unitTemplate; context: {unit: mainUnit, warn: !unitamount || (Math.abs(batches * batchsize - unitamount) > EPSILON)}"></ng-container>
                </span>

                <!-- Loss -->
                <span class="scheduler-input-formfield scheduler-input-loss-plusunit"
                    [ngClass]="{'warn-amount': !loss || 0 > loss || loss > 100}">
                    <mat-form-field class="scheduler-input-loss"
                        style="margin-top: -7px"
                        subscriptSizing="dynamic" floatLabel="always">
                        <mat-label i18n="reports$$report heading roast loss@@Loss">Loss</mat-label>
                        <input matInput type="text" name="loss"
                            data-test="npt_loss" class="scheduler-input-number-input"
                            (change)="checkChanges('loss', loss, $event.target['value'])"
                            [value]="!loss ? '' : (loss | number:'1.1-1')">
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="unitTemplate; context: {unit: '%', warn: !loss || 0 > loss || loss > 100}"></ng-container>
                </span>

                <!-- End-Weight -->
                <span class="scheduler-input-formfield scheduler-input-endweight-plusunit"
                    style="margin-right: 0;">
                    <mat-form-field class="scheduler-input-endweight"
                        subscriptSizing="dynamic" floatLabel="always">
                        <mat-label i18n="reports$$report heading volume out@@Yield">Yield</mat-label>
                        <!-- [value]="unitweight * utils.getUnitFactor(mainUnit) | number:weightFormat"> -->
                        <input matInput type="text" name="unitweight" class="scheduler-input-number-input"
                            data-test="npt_unitweight"
                            (change)="checkChangesUnits(this, 'unitweight', unitweight, $event.target['value'], false)"
                            [value]="utils.formatAmount(unitweight, undefined, mainUnit, undefined, false, false, 0, 3)">
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="unitTemplate; context: {unit: mainUnit}"></ng-container>
                </span>
            </div>

            <!-- hints for amount, loss, end_weight -->
            @if (!editModeItem?.date || editModeItem.date >= today) {
            @if (!unitamount || (batches && batchsize && Math.round((batches * batchsize - unitamount) * 100) / 100) || (suggestedLoss && Math.round((loss - suggestedLoss) * 10) / 10)) {
            <div class="scheduler-input-group scheduler-input-group-nowrap scheduler-filter-hints"
                style="display: inline-block;">
                <!-- amount hint -->
                @if (batchsize && (!unitamount || (batches && batchsize && Math.round((batches * batchsize - unitamount) * 100) / 100))) {
                <span class="scheduler-input-amount-plusunit-hint">
                    <span [ngClass]="{'warn-amount': !unitamount || isOffFromSuggestion(unitamount, batches * batchsize)}"
                        class="scheduler-filter-hints"
                        style="padding-left: 36px; text-align: right;">
                        <a (click)="useAmountSuggestion()" (keydown.enter)="useAmountSuggestion(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">
                            @if (batches && batchsize) {
                            {{batches}}x {{batchsize * utils.getUnitFactor(mainUnit) | number:weightFormat}}}<ng-container *ngTemplateOutlet="unitTemplate; context: {unit: mainUnit, warn: !unitamount || isOffFromSuggestion(unitamount, batches * batchsize)}"></ng-container>
                        </a>
                    </span>
                </span>
                }

                <!-- loss hint -->
                @if (suggestedLoss && Math.round((loss - suggestedLoss) * 10) / 10) {
                <span class="scheduler-input-loss-plusunit"
                    [ngStyle]="{'padding-left': !unitamount || (batches && batchsize && Math.round((batches * batchsize - unitamount) * 100) / 100) ? 0 : '125px'}"
                    style="display: inline-block; text-align: right; width: 130px;">
                    <span [ngClass]="{'warn-amount': !loss || (suggestedLoss && isOffFromSuggestion(loss, suggestedLoss))}">
                        <a (click)="useLossSuggestion()" (keydown.enter)="useLossSuggestion(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">
                            <ng-container i18n="scheduler$$label, derived from recent roast statistics@@From history">From history</ng-container>: {{suggestedLoss | number:'1.1-1'}}
                            <ng-container *ngTemplateOutlet="unitTemplate; context: {unit: '%', warn: !loss || (suggestedLoss && isOffFromSuggestion(loss, suggestedLoss))}"></ng-container>
                        </a>
                    </span>
                </span>
                }

                <!-- end_weight hint -->
                <!-- <span class="scheduler-input-endweight-plusunit scheduler-filter-hints"></span> -->
            </div>
            }
            }
        </div>

        <div class="scheduler-input-group-box">
            @if (stores?.length > 2 || blends?.length) {
            <!-- Store -->
            <ng-container *ngTemplateOutlet="storeInputGroup; context:{broadWidget: true}"></ng-container>
            }
        </div>
    </div>




    <!--            3rd Group            -->





    <div class="scheduler-input-buttons scheduler-input-buttons-centered" style="grid-column: 1; grid-row: 3;">
        <button mat-icon-button class="primary-element" (click)="openTemplateSearchDialog()" matTooltip="Search templates" i18n-matTooltip="roasts$$link to dialog to find roast templates@@Search templates">
            <mat-icon class="scheduler-narrow-icon">search</mat-icon>
        </button>
    </div>

    <div class="scheduler-input-group-group" style="grid-column: 2; grid-row: 3;">
        <div class="scheduler-input-group-box">
            <div class="scheduler-input-group">
                <!-- Template -->
                <span>
                    <mat-form-field class="form-group twoline-select scheduler-input-formfield-normal" subscriptSizing="dynamic">
                        <mat-label i18n="roasts$$roast property Template@@Template">Template</mat-label>
                        <mat-select name="template" #templateSelect
                            hideSingleSelectionIndicator="true" panelWidth="null"
                            [(ngModel)]="template"
                            (selectionChange)="templateChanged()"
                            [compareWith]="utils.compareObjectsIdFn">
                            <mat-select-trigger class="twoLineOption ellipsed">
                                <ng-container *ngTemplateOutlet="templateEntry; context: {template, isTrigger: true}"></ng-container>
                            </mat-select-trigger>
                            @if (templates.length >= 5) {
                            <mat-option>
                                <ngx-mat-select-search ngModel (ngModelChange)="filteredTemplates = filterLabelObjects($event, templates)" name="templateSelect"
                                    placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                    noEntriesFoundLabel="">
                                </ngx-mat-select-search>
                            </mat-option>
                            }
                            <mat-option [value]="null"></mat-option>
                            @for (template of filteredTemplates; track $index) {
                            <mat-option [value]="template" class="scheduler-select-option-template">
                                <ng-container *ngTemplateOutlet="templateEntry; context: {template}"></ng-container>
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </span>
            </div>
        </div>

        <div class="scheduler-input-group">
            <!-- Notes -->
            <mat-form-field class="form-group no-height-change scheduler-input-formfield-normal"
                subscriptSizing="dynamic" style="min-height: 55px;">
                <mat-label i18n="generic$$arbitrary notes@@Notes">Notes</mat-label>
                <textarea matInput name="notes" data-test="npt_notes"
                    style="min-height: auto; height: auto;"
                    [ngStyle]="{'margin-top': notes?.includes('\n') ? 0 : '14px'}"
                    placeholder="Notes" i18n-placeholder="generic$$arbitrary notes@@Notes"
                    [(ngModel)]="notes"
                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3">
                    </textarea>
            </mat-form-field>
        </div>
    </div>




    <!--            4th Group            -->



    <div class="scheduler-input-buttons scheduler-input-buttons-centered"
        style="grid-column: 1; grid-row: 4; align-self: flex-start; margin-top: 20px; flex-direction: row-reverse; justify-content: space-between;">
        @if (!readOnly) {
        @if (machinesToRenameCount) {
        <button mat-icon-button class="scheduler-input-narrow-button primary-element" (click)="openRenameMachineDialog()" matTooltip="Rename machines" i18n-matTooltip="generic$$link to dialog to quickly fix typos in machine names@@Rename machines"><mat-icon class="scheduler-narrow-icon">redo</mat-icon>
        </button>
        }
        <button mat-icon-button class="scheduler-input-narrow-button primary-element" (click)="openAddMachineDialog()" matTooltip="Add machines" i18n-matTooltip="generic$$link to dialog to quickly add a machine@@Add machines"><mat-icon class="scheduler-narrow-icon">add</mat-icon>
        </button>
        }
    </div>

    <div class="scheduler-input-group-group" style="grid-column: 2; grid-row: 4;">
        <div class="scheduler-input-group-box">
            <div class="scheduler-input-group" style="position: relative;"
                [ngStyle]="{'margin-top': 4 >= machines?.length ? '14px' : '5px'}">
                <!-- Machine -->
                @if (!machines?.length || 4 >= machines?.length) { <!-- does not include null -->
                <span class="scheduler-chip-input-header">
                    <ng-container i18n="roasts$$machine@@Machine">Machine</ng-container>
                </span>
                }
                <span class="scheduler-input-with-buttons">
                    @if (!machines?.length) { <!-- does not include null -->
                    <span style="display: block; height: 40px"></span>
                    } @else if (4 >= machines?.length) {
                    <app-chips-filter #machineFilter class="left-margin-only"
                        [margin_top]="-19"
                        mainWidth="760px"
                        chipWidth="170px"
                        [multiple]="false"
                        [atLeastOne]="false"
                        [warnNonSelected]="false"
                        [allOptions]="machines ?? []"
                        placeholder="Machine"
                        (selectChanged)="machineChanged(true, $event)">
                    </app-chips-filter>
                    } @else {
                    <mat-form-field class="form-group scheduler-input-formfield-normal" subscriptSizing="dynamic">
                        <mat-label i18n="roasts$$machine@@Machine">Machine</mat-label>
                        <mat-select hideSingleSelectionIndicator="true"
                            name="machine" panelWidth="null"
                            [(ngModel)]="machine"
                            (selectionChange)="machineChanged()"
                            [compareWith]="utils.compareObjectsFn">
                            @if (machines.length >= 5) {
                            <mat-option>
                                <ngx-mat-select-search ngModel (ngModelChange)="filteredMachines = filterLabelObjects($event, machines)" name="machineSelect"
                                    placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                    noEntriesFoundLabel="">
                                </ngx-mat-select-search>
                            </mat-option>
                            }
                            <mat-option [value]="null"></mat-option>
                            @for (machine of filteredMachines; track machine) {
                            <mat-option [value]="machine">{{machine}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    }
                </span>
            </div>
        </div>

        <!-- Users -->
        <div class="scheduler-input-group-box">
            @if (users?.length >= 2) { <!-- does not include null -->
            <div class="scheduler-input-group" style="position: relative;"
                [ngStyle]="{'margin-top': 4 >= users?.length ? '24px' : '5px'}">
                <div class="scheduler-input-group">
                    @if (4 >= users?.length) { <!-- does not include null -->
                    <span class="scheduler-chip-input-header">
                        <ng-container i18n="generic$$User@@User">User</ng-container>
                    </span>
                    }
                    <span>
                        @if (4 >= users?.length) { <!-- does not include null -->
                        <app-chips-filter #userFilter class="left-margin-only"
                            [margin_top]="-19"
                            mainWidth="760px"
                            chipWidth="170px"
                            [multiple]="false"
                            [atLeastOne]="false"
                            [warnNonSelected]="false"
                            [allOptions]="userNicknames ?? []"
                            placeholder="Users"
                            (selectChanged)="selectUser($event?.[0])">
                        </app-chips-filter>
                        } @else {
                        <mat-form-field class="form-group scheduler-input-formfield-normal" subscriptSizing="dynamic">
                            <mat-label i18n="generic$$User@@User">User</mat-label>
                            <mat-select hideSingleSelectionIndicator="true"
                                name="users" panelWidth="null"
                                [(ngModel)]="user"
                                [compareWith]="utils.compareObjectsFn">
                                @if (users.length >= 5) {
                                <mat-option>
                                    <ngx-mat-select-search ngModel (ngModelChange)="filteredUsers = filterUsers($event, users)" name="userSelect"
                                        placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                        noEntriesFoundLabel="">
                                    </ngx-mat-select-search>
                                </mat-option>
                                }
                                <mat-option [value]="null"></mat-option>
                                @for (user of filteredUsers; track user?.nickname) {
                                <mat-option [value]="user">{{user?.nickname}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        }
                    </span>
                </div>
            </div>
            }
        </div>
    </div>
</div>

<div class="enlargepage-smaller"></div>