<ng-template #settodone>
    <input matInput [matDatepicker]="picker" style="width: 0; visibility: hidden;"
        [value]="today" (dateInput)="done($event)">
    <mat-datepicker #picker>
        <mat-datepicker-actions>
            <button mat-button matDatepickerCancel class="cancel-button">
                <mat-icon>close</mat-icon>
            </button>
            <button mat-raised-button matDatepickerApply class="action-button">
                <mat-icon>done</mat-icon>
            </button>
        </mat-datepicker-actions>
    </mat-datepicker>
    <button (click)="picker.open(); $event.stopPropagation()" type="button" class="action-button" mat-raised-button>
        <mat-icon>done</mat-icon>
    </button>
</ng-template>

<ng-template #snoozereminder>
    @if (reminder.active && reminder.expired) {
    <input matInput [matDatepicker]="picker2" style="width: 0; visibility: hidden;"
        [value]="today" [min]="today" (dateInput)="snooze($event)">
    <mat-datepicker #picker2>
        <mat-datepicker-actions>
            <button mat-button matDatepickerCancel class="cancel-button">
                <mat-icon>close</mat-icon>
            </button>
            <button mat-raised-button matDatepickerApply class="action-button">
                <mat-icon>done</mat-icon>
            </button>
        </mat-datepicker-actions>
    </mat-datepicker>
    <button (click)="reminder.snooze_until ? snooze() : picker2.open(); $event.stopPropagation()" type="button" class="action-button" mat-raised-button>
        @if (!reminder.snooze_until) {
        <mat-icon>snooze</mat-icon>
        } @else {
        <mat-icon>notifications_off</mat-icon>
        }
    </button>
    }
</ng-template>


<span #scrolltarget></span>
@if (reminder) {
<span style="position: relative;">
    <!-- animation -->
    @if (showAnimatedPanel) {
    <div [@createLog]="animatePanel ? 'end' : 'start'" class="animated-item">
        <div class="mat-expansion-panel-header">
            <mat-panel-title class="paneltitle" [ngClass]="{'inactive-reminder': !reminder.active}">
                <span style="display: flex; align-items: center; width: calc(100% - 110px);">
                    @if (expiredStrs.expiredUnit) {
                    <span class="reminder-interval" [ngClass]="{'reminder-condition-expired': reminder.expired}">
                        {{expiredStrs.expiredPre}}{{expiredStrs.expiredNumber}} {{expiredStrs.expiredUnit}}
                        @if (expiredStrs.expired2Unit && reminder.expired) {
                        <br />{{expiredStrs.expired2Unit}}
                        }
                        @if (expiredStrs.expired2Unit && !reminder.expired) {
                        <br /><span class="paneltitle-forecast">~ {{expiredStrs.expired2Unit}}</span>
                        }
                    </span>
                    } @else {
                    <span class="reminder-interval">&nbsp;</span>
                    }
                    <span style="width: 100%;">
                        @if (remindercopy?.machine || reminder.machine) {
                        <span class="head-hrid">{{remindercopy?.machine || reminder.machine}}</span>
                        }
                        <div class="head-label">{{remindercopy?.label || reminder.label}}</div>
                    </span>
                </span>
            </mat-panel-title>
        </div>
    </div>
    }
    <!-- animation end -->
    <mat-expansion-panel [@deleteEntry]="isdeleted" hideToggle="true" [expanded]="(isNew === index) || isExpanded"
        (closed)="panel(false)" (opened)="panel(true)" [disabled]="editMode === index"
        [ngClass]="{'expired-reminder': reminder.expired && !reminder.snooze_until, 'inactive-reminder': !reminder.active}">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto"
            [ngClass]="{'inactive-reminder': !reminder.active}">
            <mat-panel-title class="paneltitle">
                <span class="paneltitle-inner">
                    @if (expiredStrs.expiredUnit) {
                    <span class="reminder-interval" [ngClass]="{'reminder-condition-expired': reminder.expired, 'inactive-reminder': !reminder.active}">
                        {{expiredStrs.expiredPre}}{{expiredStrs.expiredNumber}} {{expiredStrs.expiredUnit}}
                        @if (expiredStrs.expired2Unit && reminder.expired) {
                        <br />{{expiredStrs.expired2Unit}}
                        }
                        @if (expiredStrs.expired2Unit && !reminder.expired) {
                        <br /><span class="paneltitle-forecast">~ {{expiredStrs.expired2Unit}}</span>
                        }
                    </span>
                    } @else {
                    <span class="reminder-interval">&nbsp;</span>
                    }
                    <span [ngStyle]="{'width': !isExpanded && (remindercopy?.image || reminder.image) && (isQuiteLarge$ | async) ? 'calc(100% - 185px)' : 'calc(100% - 110px)'}">
                        @if (remindercopy?.machine || reminder.machine) {
                        <span class="head-hrid head-machine" [ngClass]="{'inactive-reminder': !reminder.active}">{{remindercopy?.machine || reminder.machine}}</span>
                        }
                        <!-- target user(s) -->
                        @if ((remindercopy && hasNotification(remindercopy.notify_users)) || (!remindercopy && hasNotification(reminder?.notify_users))) {
                        @if ((remindercopy?.notify_users?.length || reminder.notify_users?.length) && remindercopy?.notify_users?.length !== targetUsers?.length && reminder.notify_users?.length !== targetUsers?.length && (remindercopy?.notify_users?.length > 1 || (remindercopy?.notify_users?.[0] && !remindercopy.notify_users[0].default) || reminder?.notify_users?.length > 1 || (reminder?.notify_users?.[0] && !reminder.notify_users[0].default))) {
                        <span
                            class="head-hrid" [ngClass]="{'inactive-reminder': !reminder.active}">
                            @if (remindercopy?.notify_users?.[0]?.user?.nickname && !remindercopy.notify_users[0].default || (reminder?.notify_users?.[0]?.user?.nickname && !reminder.notify_users[0].default)) {
                            <mat-icon class="small">notifications</mat-icon>
                            }
                            @for (notifInfo of (remindercopy?.notify_users?.length ? remindercopy.notify_users : reminder.notify_users); track $index) {
                            <span>
                                {{(notifInfo?.default || !notifInfo?.user?.nickname) ? '' : (notifInfo?.user?.nickname + '&nbsp;')}}
                            </span>
                            }
                        </span>
                        }
                        } @else {
                        <mat-icon class="small">notifications_off</mat-icon>
                        }
                        <div class="head-label" [ngClass]="{'inactive-reminder': !reminder.active}">{{remindercopy?.label || reminder.label}}</div>
                    </span>
                    @if (!isExpanded && reminder.image && (isQuiteLarge$ | async)) {
                    <span class="header-image-container">
                        <img [src]="reminder.image" class="header-image" alt="a picture related to the reminder">
                    </span>
                    }
                </span>
            </mat-panel-title>
            @if ((isMiddleOrLarge$ | async)) {
            <mat-panel-description style="align-self: center; justify-content: inherit;" [ngStyle]="{'flex-grow': isExpanded ? 0 : ((isVeryLarge$ | async) ? 1.5 : ((isLarge$ | async) ? 2 : 0))}">
                @if (!readOnly && editMode !== index && !isExpanded && (isMiddleOrLarge$ | async)) {
                <div class="reminder-buttons flex-center">
                    @if (reminder.active) {
                    <ng-container *ngTemplateOutlet="settodone"></ng-container>
                    }
                    <ng-container *ngTemplateOutlet="snoozereminder"></ng-container>
                    <!-- deactivate -->
                    <!-- <button *ngIf="reminder.active && !reminder.expired" (click)="activate(false); $event.stopPropagation()" type="button" class="action-button" mat-raised-button>
                <mat-icon>notifications_off</mat-icon>
              </button> -->
                    <!-- activate -->
                    <!-- active -->
                    @if (!reminder.active && !reminder.conditions?.length) {
                    <button mat-icon-button class="tertiary-element reminder-header-delete-button" type="button"
                        (click)="delete(); $event.stopPropagation()">
                        <mat-icon>delete</mat-icon>
                    </button>
                    }
                    @if (!reminder.active) {
                    <mat-checkbox name="active_checkbox"
                        [ngModel]="reminder.active"
                        (click)="activate(!reminder.active); $event.stopPropagation()"
                        class="primary-element active-checkbox-header"
                        [ngClass]="{'active-checkbox-header-noconditions': !reminder.conditions?.length}"
                        i18n="reminders$$should the reminder be active@@active">active</mat-checkbox>
                    }
                </div>
                }
                @if (!isExpanded && (isLarge$ | async)) {
                <div class="mat-panel-description-inner">
                    <!-- any type of for loop had the problem that a click on the icon did not open the panel -->
                    @if (conditionsToString(reminder); as conditionStrs) {
                    <div>
                        @if (conditionStrs.snooze) {
                        <span class="snooze-label">
                            <mat-icon class="event_icon">snooze</mat-icon>{{conditionStrs.snooze}}
                        </span>
                        }
                        @if (conditionStrs.strings[0]; as conditionStr) {
                        <div style="display: flex;">
                            @if (conditionStr.interval_unit !== 'date') {
                            <mat-icon class="event_icon">event_repeat</mat-icon>
                            } @else {
                            <mat-icon class="event_icon">event</mat-icon>
                            }
                            {{conditionStr.text}}
                        </div>
                        }
                        @if (conditionStrs.strings[1]; as conditionStr) {
                        <div style="display: flex;">
                            @if (conditionStr.interval_unit !== 'date') {
                            <mat-icon class="event_icon">event_repeat</mat-icon>
                            } @else {
                            <mat-icon class="event_icon">event</mat-icon>
                            }
                            {{conditionStr.text}} @if (conditionStrs.strings.length > 2) {
                            / ...
                            }
                        </div>
                        }
                    </div>
                    }
                    <!-- <ng-template ngFor let-conditionStr [ngForOf]="conditionsToString(reminder)">
          <div style="display: flex;">
            <mat-icon *ngIf="conditionStr?.interval_unit !== 'date'" class="event_icon">event_repeat</mat-icon>
            <mat-icon *ngIf="conditionStr?.interval_unit === 'date'" class="event_icon">event</mat-icon>
            {{conditionStr?.text}}
          </div>
        </ng-template> -->
                </div>
                }
            </mat-panel-description>
            }
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            @if (!readOnly && editMode === index && remindercopy) {
            <form id="form" name="form" (ngSubmit)="editForm.form.valid && save()" #editForm="ngForm">
                <!-- edit mode -->
                <div class="outerdiv">
                    <div class="nottable width-for-edit">
                        <div class="side-by-side">
                            <!-- label -->
                            <mat-form-field>
                                <mat-label i18n="generic$$Title@@Title">Title</mat-label>
                                <input required matInput autofocus data-test="npt_label"
                                    placeholder="Title" i18n-placeholder="generic$$Title@@Title"
                                    [(ngModel)]="remindercopy.label" type="text" name="tasklabel"
                                    [matAutocomplete]="autoCompleteLabel"
                                    (input)="changeLabelFilter()"
                                    (blur)="labelChanged($event)">
                            </mat-form-field>
                            <mat-autocomplete #autoCompleteLabel="matAutocomplete" panelWidth="300px"
                                (optionSelected)="labelChanged(undefined, $event)">
                                @for (task of filteredTasks; track task?.label) {
                                <mat-option [value]="task.label">{{task.label}}</mat-option>
                                }
                            </mat-autocomplete>
                            <!-- active -->
                            <mat-checkbox name="active_checkbox"
                                [(ngModel)]="remindercopy.active"
                                class="primary-element active-checkbox"
                                i18n="reminders$$should the reminder be active@@active">active</mat-checkbox>
                        </div>
                        <!-- notes -->
                        <mat-form-field class="form-group">
                            <input [(ngModel)]="remindercopy.notes" matInput type="text" name="notes"
                                i18n-placeholder="generic$$arbitrary notes@@Notes" placeholder="Notes">
                        </mat-form-field><br />
                        <!-- machine -->
                        <!-- <div *ngIf="machines?.length" class="side-by-side"> -->
                        <div class="side-by-side">
                            <mat-form-field class="form-group">
                                <input [(ngModel)]="remindercopy.machine" matInput type="text" id="machine" name="id"
                                    placeholder="Machine" i18n-placeholder="roasts$$machine@@Machine"
                                    (input)="changeMachineFilter($event.target['value'])"
                                    (focus)="changeMachineFilter($event.target['value'])"
                                    autocomplete="off"
                                    [matAutocomplete]="autoCompleteMachine">
                                <mat-autocomplete #autoCompleteMachine="matAutocomplete">
                                    @for (machine of filteredMachines; track machine?.label) {
                                    <mat-option [value]="machine.label">{{machine.label}}</mat-option>
                                    }
                                </mat-autocomplete>
                            </mat-form-field>
                            @if (machinesToRenameCount > 1) {
                            <a (click)="openRenameMachineDialog()" (keydown.enter)="openRenameMachineDialog(); $event.preventDefault()" role="button" [tabindex]="0" class="side-string"
                                i18n="generic$$link to dialog to quickly fix typos in machine names@@Rename machines">Rename machines</a>
                            }
                        </div>
                        <!-- conditions -->
                        @for (condition of remindercopy.conditions; track $index) {
                        <div class="condition-box" [ngStyle]="{'padding-top': $index > 0 ? '5px' : 0}">
                            <!-- <div class="condition-line"> -->
                            <!-- type of condition, weekly, nr of batches, etc. -->
                            <!-- <div class="condition-header2"> -->
                            @if ($index > 0) {
                            <span i18n="generic$$OR@@OR" style="margin-top: 5px; font-weight: bold;">OR</span>
                            }
                            <span class="condition-header-part1">
                                <span>
                                    <span>
                                        <!-- <mat-icon *ngIf="condition.is_snooze" class="event_icon" style="margin-right: 0;">snooze</mat-icon> -->
                                        <ng-container i18n="reminders$$Condition@@Condition">Condition</ng-container>
                                        @if (remindercopy.conditions.length > 1) { {{$index + 1}}}:
                                    </span>
                                    <mat-form-field class="form-group condition-interval_unit2" subscriptSizing="dynamic">
                                        <mat-select required name="interval_unit_{{$index}}"
                                            panelWidth="null" hideSingleSelectionIndicator
                                            [(ngModel)]="condition.interval_unit"
                                            (selectionChange)="conditionIntervalUnitChanged(condition)">
                                            @for (interval of intervals; track interval?.label) {
                                            <mat-option [value]='interval.value'>
                                                <!-- {{condition.interval === 1 ? interval.onelabel ?? interval.label : interval.label}} -->
                                                <!-- {{interval.onelabelcondition ?? interval.onelabel ?? interval.label}} -->
                                                {{interval.label[0].toUpperCase() + interval.label.slice(1)}}
                                            </mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </span>
                                <span>
                                    @if (condition.interval_unit !== 'date') {
                                    <span class="condition-header-part2">
                                        <ng-container i18n="reminders$$starting from a specific date@@starting from a date">from</ng-container>
                                        <mat-form-field class="condition-startdate2 notopmargin" subscriptSizing="dynamic">
                                            <mat-label i18n="generic$$date placeholder or header@@Date">Date</mat-label>
                                            <input required matInput data-test="npt_start_date" name="start_date_{{$index}}"
                                                [(ngModel)]="condition.start_date" [matDatepicker]="myDatepicker">
                                            <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                                            <mat-datepicker #myDatepicker></mat-datepicker>
                                        </mat-form-field>
                                    </span>
                                    }
                                    @if (!readOnly && remindercopy.conditions.length > 1) {
                                    <button mat-icon-button type="button" class="tertiary-element"
                                        (click)="deleteCondition($index)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    }
                                </span>
                            </span>
                            <!-- </div> -->
                            @if (condition.interval_unit !== 'date') {
                            <div class="condition-details-input">
                                @if (translate(condition.interval_unit === 'day_of_week' || condition.interval_unit === 'day_of_month' ? 'every' : 'alwaysafter', condition.interval, condition.interval_unit); as translation) {
                                {{translation[0]}}
                                <mat-form-field class="form-group condition-interval2 notopmargin" subscriptSizing="dynamic">
                                    @if (intervalChooserMap.get(condition.interval_unit); as intervals) {
                                    @if (intervals.type === 'dropdown') {
                                    <mat-select required panelWidth="null"
                                        name="condition_interval_{{$index}}" hideSingleSelectionIndicator
                                        [(ngModel)]="condition.interval"
                                        (selectionChange)="conditionChanged()">
                                        @for (interval of intervals.values; track interval; let i = $index) {
                                        <mat-option [value]=interval>
                                            {{intervals.strings[i]}}
                                        </mat-option>
                                        }
                                    </mat-select>
                                    }
                                    } @else if (condition.interval_unit === 'batches') {
                                    <input required matInput
                                        [(ngModel)]="condition.interval"
                                        (blur)="checkIntervalAfterNumberInput(condition)"
                                        autocomplete="off" name="condition_interval_{{$index}}"
                                        type="number" min="1" step="1">
                                    } @else if (condition.interval_unit !== 'roasted_amount' && condition.interval_unit !== 'gas_consumed') {
                                    <input required matInput
                                        [(ngModel)]="condition.interval"
                                        (blur)="checkIntervalAfterNumberInput(condition)"
                                        autocomplete="off" name="condition_interval_{{$index}}"
                                        type="number" min="0.00001" step="any">
                                    } @else if (condition.interval_unit === 'gas_consumed') {
                                    <input required matInput
                                        [value]="condition.interval != null ? utils.convertGasSize(condition.interval, 'kg', this.gasUnit) : 0"
                                        (change)="checkChangesUnits(condition, 'interval', condition.interval, $event.target['value'], true)"
                                        (blur)="checkIntervalAfterNumberInput(condition)"
                                        autocomplete="off" name="condition_interval_{{$index}}"
                                        type="number" min="0.00001" step="any">
                                    } @else if (condition.interval_unit === 'roasted_amount') {
                                    <input required matInput
                                        type="text" name="condition_interval_{{$index}}"
                                        (change)="checkChangesUnits(condition, 'interval', condition.interval, $event.target['value'], false)"
                                        [value]="condition.interval != null ? (condition.interval * utils.getUnitFactor(mainUnit) | number:'1.0-1') : 0"
                                        (blur)="checkIntervalAfterNumberInput(condition)">
                                    }
                                </mat-form-field>
                                @if (condition.interval_unit === 'gas_consumed') {
                                <mat-form-field class="form-group condition-interval2 notopmargin"
                                    style="width: 58px;" subscriptSizing="dynamic">
                                    <mat-select required panelWidth="null"
                                        name="condition_gasunit_{{$index}}" hideSingleSelectionIndicator
                                        [(ngModel)]="gasUnit"
                                        (selectionChange)="gasUnitChanged()">
                                        <mat-option value="kg">kg</mat-option>
                                        <mat-option value="lbs">lbs</mat-option>
                                        <mat-option value="gal">US gal</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                }
                                {{translation[1]}}
                                }
                            </div>
                            }
                            <!-- on a specific date -->
                            @if (condition.interval_unit === 'date') {
                            <div>
                                {{tr.anslate('on {{date}}', { date: '' })}}
                                <mat-form-field class="condition-ondate2 notopmargin" subscriptSizing="dynamic">
                                    <input required matInput data-test="npt_ondate"
                                        [(ngModel)]="condition.on_date" [matDatepicker]="myDatepicker2" name="on_date_{{$index}}">
                                    <mat-datepicker-toggle matIconSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                                    <mat-datepicker #myDatepicker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                            }
                        </div>
                        }
                        @if (!readOnly) {
                        <button (click)="addCondition()"
                            mat-icon-button class="primary-element" type="button">
                            <mat-icon>add</mat-icon>
                        </button>
                        }
                        <div i18n="reminders$$header for notification related settings@@Notification" class="notification-header">Notification</div>
                        <!-- target user -->
                        @if (targetUsers?.length > 1) {
                        <div style="margin-top: -5px;">
                            <mat-form-field data-test="slct_targetusers" subscriptSizing="dynamic">
                                <mat-select multiple panelWidth="null"
                                    name="targetUsers" hideSingleSelectionIndicator placeholder="Notify all"
                                    i18n-placeholder="reminders$$select users who will receive notifications for a task or 'Notify all'@@Notify all"
                                    [(ngModel)]="remindercopy.notify_users" [compareWith]="compareNotifUsersFn">
                                    <mat-select-trigger>
                                        @if (remindercopy.notify_users?.length === 1) {
                                        {{remindercopy.notify_users[0]?.user?.nickname}}
                                        }
                                        @if (remindercopy.notify_users?.length > 1 && 3 > remindercopy.notify_users.length) {
                                        @for (notifInfo of remindercopy.notify_users; track $index) {
                                        <span>{{notifInfo?.user?.nickname}}@if (remindercopy.notify_users.length - 1 > $index) {, }</span>
                                        }
                                        }
                                        @if (remindercopy.notify_users?.length > 3) {
                                        {{remindercopy.notify_users.length}} <span i18n="graphs$$graph title@@Users">Users</span>
                                        }
                                    </mat-select-trigger>
                                    @for (user of targetUsers; track user?._id) {
                                    <mat-option [value]="getCurrentNotifInfo($index)">{{user.nickname}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        }
                        <!-- notification modality -->
                        @if (!remindercopy.notify_users?.length) {
                        <div class="notification-target">
                            <!-- only user -->
                            <!-- <mat-icon class="smallish">notifications</mat-icon> -->
                            <mat-checkbox data-test="chkbx_web"
                                [checked]="!defaultNotifInfo.not_for_web"
                                (change)="defaultNotifInfo.not_for_web = !$event.checked"
                                class="primary-elementnotification-checkboxes" name="web_checkbox"
                                i18n="generic$$notifications shown in the browser@@Browser">Browser</mat-checkbox>
                            <mat-checkbox data-test="chkbx_artisan"
                                [checked]="!defaultNotifInfo.not_for_artisan"
                                (change)="defaultNotifInfo.not_for_artisan = !$event.checked"
                                class="primary-element notification-checkboxes" name="artisan_checkbox">Artisan</mat-checkbox>
                            <mat-checkbox data-test="chkbx_email"
                                [(ngModel)]="defaultNotifInfo.for_email"
                                class="primary-element notification-checkboxes" name="email_checkbox"
                                i18n="users$$email address@@email">Email</mat-checkbox>
                            @if (defaultNotifInfo.for_email && !helptipEmailShown) {
                            <div class="helptip helptip-email" [ngClass]="{'showme': defaultNotifInfo.for_email}"><span><span i18n="generic$$tooltip, avoid notification emails to end up in spam@@Best add this email to your address book">Best add this email to your address book</span>: <a href="mailto:artisan@artisan.plus" class="helptip-email-email">artisan&#64;artisan.plus</a></span>
                                <mat-icon class="small-close-icon" (click)="removeHelptip()">close</mat-icon>
                            </div>
                            }
                        </div>
                        } @else {
                        <div style="display: table;">
                            @for (notifInfo of remindercopy.notify_users; track $index) {
                            <div class="notification-info-row">
                                <!-- several users selected -->
                                <!-- <mat-icon class="smallish">notifications</mat-icon> -->
                                @if (remindercopy.notify_users.length > 1) {
                                <div class="notification-nickname">{{notifInfo.user?.nickname}}: &MediumSpace;</div>
                                }
                                <mat-checkbox data-test="chkbx_web"
                                    [checked]="!notifInfo.not_for_web"
                                    (change)="notifInfo.not_for_web = !$event.checked"
                                    class="primary-element notification-checkboxes" name="web_checkbox_{{$index}}"
                                    i18n="generic$$notifications shown in the browser@@Browser">Browser</mat-checkbox>
                                <mat-checkbox data-test="chkbx_artisan"
                                    [checked]="!notifInfo.not_for_artisan"
                                    (change)="notifInfo.not_for_artisan = !$event.checked"
                                    class="primary-element notification-checkboxes" name="artisan_checkbox_{{$index}}">Artisan</mat-checkbox>
                                <mat-checkbox data-test="chkbx_email"
                                    [(ngModel)]="notifInfo.for_email"
                                    class="primary-element notification-checkboxes" name="email_checkbox_{{$index}}"
                                    i18n="users$$email address@@email">Email</mat-checkbox>
                                @if (notifInfo.for_email && !helptipEmailShown) {
                                <div class="helptip helptip-email" [ngClass]="{'showme': notifInfo.for_email}">
                                    <span><span i18n="generic$$tooltip, avoid notification emails to end up in spam@@Best add this email to your address book">Best add this email to your address book</span>: <a href="mailto:artisan@artisan.plus" class="helptip-email-email">artisan&#64;artisan.plus</a></span>
                                    <mat-icon class="small-close-icon" (click)="removeHelptip()">close</mat-icon>
                                </div>
                                }
                            </div>
                            }
                        </div>
                        }
                    </div>
                    <div style="float: right;">
                        @if (waitForLocalServer) {
                        <div>... waiting 7s for local server to reload after the image update ...</div>
                        }
                        @if (remindercopy.image) {
                        <img [src]="remindercopy.image" class="reminder-image" (click)="addImage(true)" (keydown.enter)="addImage(true); $event.preventDefault()" role="button" [tabindex]="0" alt="a picture related to the reminder">
                        }
                    </div>
                </div>
            </form>
            } @else { <!-- not editMode -->
            <div class="outerdiv" style="float: left;">
                <div class="nottable">
                    <app-modification-date [element]="reminder" [user]="currentUser"></app-modification-date>
                    <p>
                        @if (reminder.machine) {
                        {{reminder.machine}}:
                        }{{reminder.label}}
                    </p>
                    @if (reminder.notes) {
                    <p><i>{{reminder.notes}}</i></p>
                    }
                    <p style="margin-bottom: 10px;"></p>
                    <!-- <ul *ngIf="reminder.conditions?.length > 1; else onlyOneCondition"> -->
                    @if (readableString) {
                    <ul>
                        @if (readableString.snooze) {
                        <li class="snooze-label">
                            <mat-icon class="event_icon" style="margin-right: 0; width: 18px;">snooze</mat-icon>
                            {{readableString.snooze}}
                        </li>
                        }
                        @for (conditionStr of readableString.strings; track conditionStr) {
                        <li>
                            {{conditionStr}}
                        </li>
                        }
                    </ul>
                    }
                    <div>
                        @if (reminder?.notify_users?.length) {
                        @if (hasNotification(reminder.notify_users)) {
                        @for (notifInfo of reminder.notify_users; track $index) {
                        <span>
                            @if (notifInfo.for_email || !notifInfo.not_for_artisan || !notifInfo.not_for_web) {
                            <mat-icon class="small">notifications</mat-icon>
                            {{(notifInfo?.default || !notifInfo?.user?.nickname) ? '' : (notifInfo?.user?.nickname + ':')}}
                            @if (!notifInfo?.not_for_web) {
                            <span i18n="generic$$notifications shown in the browser@@Browser">Browser</span>
                            @if (!notifInfo?.not_for_artisan || notifInfo?.for_email) {
                            ,
                            }
                            }
                            @if (!notifInfo?.not_for_artisan) {
                            Artisan@if (notifInfo?.for_email) {
                            ,
                            }
                            }
                            @if (notifInfo?.for_email) {
                            <span i18n="users$$email address@@email">Email</span>
                            }
                            <br />
                            }
                        </span>
                        }
                        } @else {
                        <mat-icon class="small">notifications_off</mat-icon>
                        }
                        } @else {
                        <mat-icon class="small">notifications</mat-icon>
                        <span i18n="generic$$notifications shown in the browser@@Browser">Browser</span>,
                        Artisan
                        }
                    </div>
                </div>
            </div>
            <div style="float: right;">
                @if (waitForLocalServer) {
                <div>... waiting 7s for local server to reload after the image update ...</div>
                }
                @if (reminder.image) {
                <img [src]="reminder.image" class="reminder-image" (click)="addImage()" (keydown.enter)="addImage(); $event.preventDefault()" role="button" [tabindex]="0" alt="a picture related to the reminder">
                }
            </div>
            }
            <!-- display mode (i.e. NOT edit mode) -->
        </ng-template>
        @if (0 > editMode || editMode !==index) {
        <mat-action-row>
            <!-- <div *ngIf="!(isMiddleOrLarge$ | async)" class="reminder-buttons" style="margin-right: auto;"> -->
            <div class="reminder-buttons" style="margin-right: auto;">
                <ng-container *ngTemplateOutlet="settodone"></ng-container>
                <ng-container *ngTemplateOutlet="snoozereminder"></ng-container>
                <!-- <button *ngIf="!reminder.active && reminder.conditions?.length" (click)="activate(true); $event.stopPropagation()" type="button" class="action-button" mat-raised-button>
                    <mat-icon>notifications</mat-icon>
                </button> -->
            </div>
            @if (!readOnly) {
            <button mat-raised-button type="button" [disabled]="editMode >= 0" (click)="delete()" class="edit-button">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-raised-button type="button" [disabled]="editMode >= 0" (click)="edit()" class="action-button">
                <mat-icon>edit</mat-icon>
            </button>
            }
        </mat-action-row>
        }
        @if (!readOnly && editMode === index) {
        <mat-action-row [ngClass]="{'separate-buttons': remindercopy?.internal_hr_id || remindercopy?._id}">
            @if (remindercopy?.internal_hr_id || remindercopy?._id) {
            <button mat-raised-button type="button" class="action-button otherside-button" (click)="addImage(true)">
                <mat-icon>{{remindercopy?.image ? 'insert_photo' : 'add_photo_alternate'}}</mat-icon>
            </button>
            }
            <div>
                <button mat-button type="button" class="cancel-button"
                    (click)="cancel()">
                    <mat-icon>close</mat-icon>
                </button>
                @if (!isSaving && !waitForLocalServer) {
                <button [disabled]="isSaving || !isValid" mat-raised-button type="submit" class="action-button" form="form" data-test="bttn_done">
                    <mat-icon>done</mat-icon>
                </button>
                } @else {
                <button disabled mat-raised-button class="action-button spinner-button">
                    <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                </button>
                }
            </div>
        </mat-action-row>
        }
    </mat-expansion-panel>
</span>
}