import { Utils } from 'src/app/util/utils';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { AfterViewInit } from '@angular/core';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-resetpassword',
    templateUrl: './resetpassword.component.html',
    styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit, OnDestroy, AfterViewInit {

    loading = false;
    myemail: string;
    setPassword = false;
    userId: string;
    token: string;
    mypassword: string;
    repeat_password: string;

    doblur = false;

    private ngUnsubscribe = new Subject();

    constructor(
        private userService: UserService,
        private logger: NGXLogger,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private tr: TranslatorService,
        private utils: Utils,
    ) { }

    ngOnInit(): void {
        this.userId = this.route.snapshot.params.id;
        this.token = this.route.snapshot.params.token;
        if (this.userId && this.token) {
            // allow setting new password
            this.setPassword = true;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.doblur = true;
        }, 100);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    resetPassword(): void {
        this.loading = true;
        if (this.userId && this.token) {
            // allow setting new password
            this.setPassword = true;
            this.loading = false;

        } else if (this.myemail) {
            this.userService.sendPasswordResetEmail(this.myemail)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.logger.debug('sendPasswordResetEmail:', response.result);
                            this.alertService.success(this.tr.anslate('Successfully sent email to {{target_email}}', { target_email: response.result }));
                        } else {
                            this.utils.handleError('could not send email', response.error);
                        }
                        this.loading = false;
                    },
                    error: error => {
                        this.utils.handleError('could not send email', error);
                        this.loading = false;
                    }
                });
        }
    }

    updatePassword(): void {
        if (!this.userId || !this.token) {
            this.setPassword = false;
            this.logger.fatal('cannot update password, no userId or token', this.userId, this.token);
            this.alertService.error(this.tr.anslate('Please repeat the password reset process'));

        } else {
            if (!this.mypassword) {
                this.alertService.error(this.tr.anslate('password must not be empty'));
                return;
            }
            if (this.mypassword !== this.repeat_password) {
                this.alertService.error(this.tr.anslate('repeated password does not match'));
                return;
            }

            this.loading = true;
            this.userService.updatePassword({ _id: this.userId, password: this.mypassword, token: this.token })
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.logger.debug('successfully updated password:', response.result);
                            this.alertService.success(this.tr.anslate('Success! You can now log in with your new password.'));
                            this.router.navigate(['/login']);

                        } else {
                            this.utils.handleError('could not update the password', response.error);
                        }
                        this.loading = false;
                    },
                    error: error => {
                        this.utils.handleError('could not update the password', error);
                        this.loading = false;
                    }
                });
        }
    }
}
