import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Utils } from 'src/app/util/utils';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { EMPTYTAG } from './filter-utils';
import { MatFormField, MatLabel, MatOption, MatSelect, MatSelectChange, MatSelectTrigger } from '@angular/material/select';
import { FilterComponent, IdAndLabel, IdAndLabelArrayOrNotnull } from './filter.component';
import cloneDeep from 'lodash-es/cloneDeep';
import { FilterService } from './filter.service';
import { OrganicIconComponent } from 'src/app/modules/ui/organicicon.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule } from '@angular/forms';

/**
 * Allows selecting one or more out of a list of alternatives.
 * Shows a searchable dropdown box.
 * Shows x / n where x is the number of selected and n the number of all objects.
 * 
 * Uses the @Output() selectChanged to send the array of selected items.
 * Offers a setValues method to set the selected items from outside.
 * 
 * The trigger string is generated by getObjectTrigger which works ok
 * when the objects have hr_id, pre/num, or label.
 * The list of options is shown using hr_id or label (unless there is a 
 * special case created in the template using objectType).
 * 
 * If the user manually selects all items, the selectedOptions are set to []
 * on close of the dropdown box.
 */

@Component({
    selector: 'app-select-filter',
    templateUrl: './select-filter.component.html',
    styleUrls: ['./select-filter.component.scss'],
    standalone: true,
    imports: [OrganicIconComponent, NgClass, MatFormField, MatSelect, MatSelectTrigger, MatIcon, MatOption, NgxMatSelectSearchModule, FormsModule, NgTemplateOutlet, MatLabel],
    providers: []
})
export class SelectFilterComponent<T extends IdAndLabel> implements FilterComponent, OnInit {

    constructor(
        public utils: Utils,
        public tr: TranslatorService,
        private filterService: FilterService,
    ) { }

    @Input() disabled = false;
    @Input() initialValue: IdAndLabelArrayOrNotnull<T> = undefined;

    private _allOptions: IdAndLabel[];
    get allOptions(): IdAndLabel[] { return this._allOptions; }
    @Input() set allOptions(op: IdAndLabel[]) {
        if (!op) {
            op = [];
        }
        // if (op[0] === null) {
        //     op.splice(0, 1, EMPTYTAG);
        // }
        this._allOptions = op.map(opt => (opt != null
            ? (typeof opt === 'string' ? { _id: undefined, label: opt } : opt)
            : { _id: EMPTYTAG, label: undefined }));
        // this.selectedOptions = [];
        // if the _allOptions changed, select only those that are still included
        const curLen = this.selectedOptions?.length ?? 0;
        this.selectedOptions = (this.selectedOptions ?? []).filter(so => this._allOptions?.includes(so));
        if (!this.selectedOptions.length) {
            this.selectedOptions = undefined;
        }
        if (curLen !== (this.selectedOptions?.length ?? 0)) {
            this.emitChanges();
        }
        this.filteredOptions = this._allOptions?.slice();
    }
    @Input() objectType: string;
    // @Input() compareWith: (o1: unknown, o2: unknown) => boolean;

    i18nPlaceholder: string;
    @Input() set placeholder(ph: string) {
        this.i18nPlaceholder = this.filterService.translateAttr(ph);
    }

    @Output() selectChanged = new EventEmitter<IdAndLabelArrayOrNotnull<T>>();


    allChecked = false;
    indetChecked = false;

    selectedOptions: IdAndLabel[];
    filteredOptions: IdAndLabel[];

    EMPTYTAG = EMPTYTAG;

    ngOnInit(): void {
        if (this.initialValue) {
            this.setValues(this.initialValue);
        } else {
            setTimeout(() => this.selectChanged.emit(this.initialValue ?? { vals: [] }), 0);
        }
    }

    reset(): void {
        this.selectedOptions = [];
        this.filterHasChanged();
    }

    setValues(options: IdAndLabelArrayOrNotnull<T>): void {
        this.reset();
        if (options.notnull) {
            for (const allOption of this.allOptions) {
                if (allOption && allOption._id !== EMPTYTAG) {
                    if (!this.selectedOptions) {
                        this.selectedOptions = [];
                    }
                    this.selectedOptions.push(allOption);
                }
            }
        } else {
            for (let s = 0; s < options?.vals?.length; s++) {
                const option = options.vals[s];
                if (option == null) {
                    for (let o = 0; o < this.allOptions?.length; o++) {
                        const allOption = this.allOptions[o];
                        if (allOption?._id === EMPTYTAG) {
                            if (!this.selectedOptions) {
                                this.selectedOptions = [];
                            }
                            this.selectedOptions.push({ _id: EMPTYTAG, label: undefined });
                            break;
                        }
                    }
                    continue;
                }
                for (let o = 0; o < this.allOptions?.length; o++) {
                    const allOption = this.allOptions[o];
                    if (!allOption) {
                        continue;
                    }
                    const optStr = option.toString();
                    if (!this.selectedOptions) {
                        this.selectedOptions = [];
                    }
                    if (allOption._id) {
                        if (allOption._id?.toString() === (option?._id?.toString() ?? optStr)) {
                            this.selectedOptions.push(allOption);
                            break;
                        }
                    } else if (allOption.label) {
                        if (allOption.label?.toString() === (option?.label?.toString() ?? optStr)) {
                            this.selectedOptions.push(allOption);
                            break;
                        }
                    } else if (allOption.toString() === optStr) {
                        this.selectedOptions.push(allOption);
                        break;
                    }
                }
                if (!this.selectedOptions.length) {
                    this.selectedOptions = undefined;
                }
            }
        }
        this.filterHasChanged();
    }

    /**
     * If the user manually selects all items, set the selectedOptions to []
     * as it is semantically the same and uses less resources.
     * @param nowOpen false if just closed
     */
    filterClosed(nowClosed: boolean) {
        if (nowClosed && this.selectedOptions?.length === this.allOptions.length) {
            this.selectedOptions = undefined;
            this.allChecked = false;
            this.selectChanged.emit({ vals: [] });
        }
    }

    filterHasChanged($event?: MatSelectChange): void {
        if ($event?.value) {
            this.selectedOptions = $event.value;
        }

        if (!this.selectedOptions?.length) {
            this.allChecked = false;
            this.indetChecked = false;
        } else {
            if (this.selectedOptions?.length === this.allOptions.length || (this.allOptions?.[0]?._id === EMPTYTAG && this.selectedOptions?.[0]?._id !== EMPTYTAG && this.selectedOptions?.length === this.allOptions.length - 1)) {
                // have all (or all but --) selected
                // don't set selectedOption=[] here as it would de-select all
                // while the user is still looking at the list and has just
                // selected an element (the last one)
                // see filterClosed and emitChanges
                // this.selectedOptions = [];
                this.allChecked = true;
                this.indetChecked = false;
            } else {
                this.allChecked = false;
                this.indetChecked = true;
            }
        }

        this.emitChanges();
    }

    emitChanges(): void {
        let soCopy: IdAndLabel[];
        if (this.selectedOptions?.[0]?._id === EMPTYTAG) {
            soCopy = cloneDeep(this.selectedOptions);
            soCopy[0] = null;
        } else if (this.allOptions?.[0]?._id === EMPTYTAG && this.selectedOptions?.[0]?._id !== EMPTYTAG && this.selectedOptions?.length === this.allOptions.length - 1) {
            // null is an option and all but null are selected
            this.selectChanged.emit({ notnull: true });
            return;
        } else {
            soCopy = cloneDeep(this.selectedOptions);
        }
        this.selectChanged.emit({ vals: soCopy as T[] });
    }

    toggleAll(allToggle: boolean): void {
        if (allToggle) {
            if (this.allOptions?.[0]?._id === EMPTYTAG) {
                this.selectedOptions = this.allOptions.slice(1);
            } else {
                this.selectedOptions = this.allOptions.slice();
            }
        } else {
            this.selectedOptions = undefined;
        }
        this.filterHasChanged();
    }

    /**
     * Creates a string from the selected objects. Works well if the objects have
     * hr_id, label, pre/num, or nickname
     * @param objects selected objects to render
     * @param showEmpty if true and the first object is EMPTYTAG, EMPTYTAG will be prepended
     * @returns string listing the selected objects
     */
    getObjectTrigger(objects: IdAndLabel[], showEmpty = true): string {
        // we know that objects.length > 1
        let str = '';

        if (objects.length === this.allOptions.length - 1 && this.allOptions[0]._id !== EMPTYTAG && (objects[0]?._id === EMPTYTAG || objects[0]?.toString() === EMPTYTAG)) {
            // have all but --
            return this.tr.anslate('any value');
        }

        if (typeof objects[0] === 'string') {
            str = objects[0] || '';
        } else if (objects[0]?._id === EMPTYTAG || objects[0]?.toString() === EMPTYTAG) {
            if (showEmpty) {
                str = EMPTYTAG;
            }
        } else {
            str = objects[0]?.hr_id || objects[0]?.['nickname'] || `${objects[0]?.pre ?? ''}${objects[0]?.num ?? ''} ${objects[0]?.label}`?.trim() || '';
        }
        let i = 1;
        while (i < objects.length && str.length < 25) {
            const obji = objects[i];
            if (typeof obji === 'string') {
                str += `, ${objects[i] || ''}`;
            } else if (obji?._id === EMPTYTAG || obji?.toString() === EMPTYTAG) {
                if (showEmpty) {
                    str += `, ${EMPTYTAG}`;
                }
            } else {
                str += `, ${obji?.hr_id || obji?.['nickname'] || `${obji.pre ?? ''}${obji.num ?? ''} ${obji.label}`?.trim() || ''}`;
            }
            i += 1;
        }
        if (i < objects.length) {
            str = str.substring(0, 21);
            str += ' ...';
        }
        return str;
    }
}
