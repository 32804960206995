import { UpdateSnackComponent } from 'src/app/modules/ui/snacks/update-snack.component';
import { TransService } from 'src/app/modules/transaction/trans.service';
import { ShareMostRecentTransactionsService } from 'src/app/util/services/share-mostrecenttransactions.service';
// (ElementRef only used as type):
 
import { Component, OnInit, QueryList, ViewChildren, ElementRef, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { StandardService } from 'src/app/util/services/standard.service';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Enumerations } from 'src/app/models/Enumerations';
import { StockService } from 'src/app/modules/stock/stock.service';
import { Location } from 'src/app/models/Location';
import { StockType, StoreStockComponent } from 'src/app/modules/stock/store-stock.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { PurchaseDialogComponent } from 'src/app/modules/transaction/dialogs/purchase-dialog.component';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Subject } from 'rxjs';
import { ObjectChangedInfo, ObjectChangedService } from 'src/app/util/services/objectchanged.service';
import { Purchase } from 'src/app/models/Purchase';
import { TransactionsComponent } from 'src/app/modules/transaction/transactions.component';
import { CorrectDialogComponent, CorrectDialogResultType } from 'src/app/modules/transaction/dialogs/correct-dialog.component';
import { TransferDialogComponent } from 'src/app/modules/transaction/dialogs/transfer-dialog.component';
import { Transfer } from 'src/app/models/Transfer';
import { Sale } from 'src/app/models/Sale';
import { SellDialogComponent } from 'src/app/modules/transaction/dialogs/sell-dialog.component';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { throttleTime, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
// import { StoreGraphsComponent } from 'src/app/modules/graph/store/store-graphs.component';
import { ImageUpload2Component } from 'src/app/modules/ui/image-upload/image-upload2.component';
import { FileService } from 'src/app/util/services/file.service';
import { SizeTooLargeError } from 'src/app/util/exceptions/SizeTooLargeException';
import { CantDeleteDialogComponent } from 'src/app/modules/ui/dialog/cant-delete-dialog.component';
import { Correction } from 'src/app/models/Correction';
import { SplitGutterInteractionEvent } from 'angular-split';
import { Constants } from 'src/app/util/constants';
import cloneDeep from 'lodash-es/cloneDeep';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-stores',
    templateUrl: './stores.component.html',
    styleUrls: ['./stores.component.scss'],
    providers: []
})
export class StoresComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private standardService: StandardService,
        private userService: UserService,
        private dialog: MatDialog,
        private utils: Utils,
        private objectChangedService: ObjectChangedService,
        public tr: TranslatorService,
        private alertService: AlertService,
        private logger: NGXLogger,
        private stockService: StockService,
        private fileService: FileService,
        private transService: TransService,
        private shareMostRecentTransactionsService: ShareMostRecentTransactionsService,
        private snackBar: MatSnackBar,
        @Inject(LOCALE_ID) public locale: string
    ) { }

    defaultSplitSizes = [70, 30];
    splitSizes = this.defaultSplitSizes;
    readonly splitSizesLocalStorageName = 'store-graph-split-size';

    currentUser: UserType;
    stores: Location[];
    isOrganic: boolean[] = [];
    stocks: StockType[][];
    stockSums: number[];
    stockValues: number[];
    storecopy: Location;
    filteredCountries: string[] = [];

    ignore: string[] = [
        '_id', 'internal_hr_id', 'owner', '__v', '__t', 'type', // internals
        'updated_by', 'updated_at', 'created_by', 'created_at', 'label', 'hr_id', // already in header
        'coordinates' // would need a map interface
    ];

    editMode = -1;
    isNew = -1;
    saveDisabled = false;
    isSaving = false;

    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';
    isDarkmode = false;

    loadSubscription = new Subject<string>();
    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();

    notes: string[] = [];
    notesChanged: Subject<string>[] = [];

    private needReloadGraphData = false;
    readOnly = false;
    checkingIfDeletable = false;

    nrTransactions: number[] = [];

    Enumerations = Enumerations;

    @ViewChildren('hridElem') hridElems: QueryList<ElementRef>;
    @ViewChildren(TransactionsComponent) transactionElems: QueryList<TransactionsComponent>;

    allTransactionsLoaded = false;
    _storeStockComps: QueryList<StoreStockComponent>;
    @ViewChildren(StoreStockComponent) set storeStockComps(sscs: QueryList<StoreStockComponent>) {
        if (sscs?.length) {
            this._storeStockComps = sscs;
            if (this.allTransactionsLoaded) {
                this.transactionsLoaded(-1, { storeId: 'all' });
                this.allTransactionsLoaded = false;
            }
        }
    }

    // // need to use a setter, otherwise, due to the *ngIf, it will always be undefined
    // // https://stackoverflow.com/questions/39366981/angular-2-viewchild-in-ngif
    // _graphsComponent: StoreGraphsComponent;
    // @ViewChild(StoreGraphsComponent) set graphsComponent(gc: StoreGraphsComponent) {
    //     this._graphsComponent = gc;
    // }

    ngOnInit(): void {
        this.locale = this.locale ?? 'en';

        const ss = this.userService.getFromLocal(this.splitSizesLocalStorageName);
        if (ss) {
            this.splitSizes = JSON.parse(ss);
        } else {
            this.splitSizes = this.defaultSplitSizes;
        }

        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        this.shareMostRecentTransactionsService.resetTransactions();

        this.objectChangedService.changed$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(obj => this.objectChanged(obj));

        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.getAllStores());

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((event: unknown) => {
                if (event instanceof NavigationEnd && event.url.indexOf('stores') >= 0) {
                    // only pass and debounce interesting events
                    this.loadSubscription.next('reload');
                }
            });

        // show hint to account switcher after accepting an invitation
        // note the typo which was fixed April 2024
        if (this.route.snapshot.params.showAccountHint === '1' || this.route.snapshot.params.showAccountHint === '1') {
            setTimeout(() =>
                this.snackBar.open(this.tr.anslate('You can now switch accounts here'), '',
                    { duration: 8000, horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snack-right' })
                , 2000);
        }

        if (this.currentUser) {
            this.loadSubscription.next('init');
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    onDragEnd(e: SplitGutterInteractionEvent): void {
        this.splitSizes = e.sizes as number[];
        this.userService.storeToLocal(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
    }

    onGutterClick(e: SplitGutterInteractionEvent): void {
        if (e.sizes[0] === '*' || e.sizes[0] < 50) {
            this.splitSizes = [100, 0];
        } else {
            this.splitSizes = [0, 100];
        }
        this.userService.storeToLocal(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
    }

    /**
     * As soon as the transactions component has finished loading all transactions,
     * notify the StoreStockComponent
     */
    transactionsLoaded(storeIdx: number, transInfo: { storeId: string, nrTransations?: number }): void {
        if (this._storeStockComps?.length) {
            const sscs = this._storeStockComps.toArray();
            for (const ssc of sscs) {
                if (transInfo.storeId === 'all' || ssc?.store?.hr_id === transInfo.storeId) {
                    ssc?.allTransactionsLoaded();
                }
            }
        } else {
            this.allTransactionsLoaded = true;
        }
        if (storeIdx >= 0) {
            this.nrTransactions[storeIdx] = transInfo.nrTransations;
        }
    }

    /**
     * Retrieves all storage locations and stocks.
     * Also checks whether the web app can / needs to eb updated.
     */
    getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.readOnly = this.userService.isReadOnly();
                    if (response.success === true) {
                        this.stores = response.result;
                        if (response['forceReload']) {
                            this.utils.forceReload(false);
                        }
                        if (response.v && typeof response.v === 'string') {
                            const snackBarRef = this.snackBar.openFromComponent(UpdateSnackComponent);
                            // const snackBarRef = this.snackBar.open(this.tr.anslate('Update available'), this.tr.anslate('UPDATE'));
                            snackBarRef.onAction().subscribe(() => {
                                this.utils.forceReload();
                            });
                        }
                        if (!this.stores || this.stores.length === 0) {
                            if (!this.readOnly) {
                                this.router.navigate(['/wizard']);
                            }
                        } else {
                            this.getAllStocks();
                            for (let s = 0; s < this.stores.length; s++) {
                                this.notes[s] = this.stores[s].notes;
                                this.notesChanged[s] = new Subject<string>();
                                this.notesChanged[s].pipe(
                                    debounceTime(4000),
                                    distinctUntilChanged(),
                                    throttleTime(environment.RELOADTHROTTLE),
                                    takeUntil(this.ngUnsubscribe))
                                    .subscribe(() => this.saveNotes(s));
                            }
                        }
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                        if (!this.stores?.length) {
                            this.stores = [];
                        }
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                    if (!this.stores?.length) {
                        this.stores = [];
                    }
                }
            });

        this.reloadGraphData();
    }

    reloadStore(sIdx: number, store: Location, recalc = false): void {
        if (!this.stores || sIdx < 0 || sIdx >= this.stores.length) {
            this.logger.fatal('stores, updateStore, sIdx out of range:', sIdx, store, this.stores ? this.stores.length : 'undefined');
            return;
        }
        const storeId = store._id
            ? store._id
            : (store.hr_id
                ? store.hr_id
                : store.internal_hr_id
                    ? ('L' + store.internal_hr_id)
                    : store as unknown as string);
        this.logger.debug('updating store', storeId);
        // TODO getOne<Location> probably unnecessary since the store does not contain any relevant information
        this.standardService.getOne<Location>('stores', storeId)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores[sIdx] = response.result;

                        this.transactionElems.toArray()[sIdx]?.reload();

                        this.stockService.getCoffeeStock(storeId, recalc)
                            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                            .subscribe({
                                next: response2 => {
                                    this.stocks[sIdx] = response2.result.filter(s => s.amount);
                                    for (const stock of this.stocks) {
                                        for (let s2 = 0; s2 < stock?.length; s2++) {
                                            const stk = stock[s2];
                                            if (stk) {
                                                stk.yearLabel = this.utils.createBeansYearLabel(stk as { crop_date?: { landed?: number[], picked?: number[] } });
                                            }
                                        }
                                    }

                                    this.updateSums(sIdx);
                                },
                                error: error => {
                                    this.utils.handleError('error retrieving stock of a store', error);
                                }
                            });
                    } else {
                        this.utils.handleError('error retrieving a store', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving a store', error);
                }
            });
    }

    getAllStocks(): void {
        if (!this.stores) {
            return;
        }
        const storeIds = this.stores.map(s => s._id);
        this.stockService.getCoffeeStocks(storeIds)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stocks = [];
                        this.stockSums = [];
                        this.stockValues = [];
                        for (let sIdx = 0; sIdx < response.result.length; sIdx++) {
                            this.stocks[sIdx] = response.result[sIdx].filter(s => s.amount);
                            // init with true if there is at least one coffee in stock
                            this.isOrganic[sIdx] = !!this.stocks[sIdx].length;
                            for (const cof of this.stocks[sIdx]) {
                                if (cof) {
                                    cof.yearLabel = this.utils.createBeansYearLabel(cof as { crop_date?: { landed?: number[], picked?: number[] } });
                                    if (!this.utils.isOrganic(cof.certInfo) && cof.amount > Constants.EPSILON) {
                                        this.isOrganic[sIdx] = false;
                                    }
                                }
                            }
                            this.updateSums(sIdx);
                        }

                        const storeId = this.route.snapshot.params.id;
                        if (storeId) {
                            const storesarray = this.hridElems.toArray();
                            for (let i = 0; i < storesarray.length; i++) {
                                if (storesarray[i].nativeElement.innerText === storeId) {
                                    if (i !== 0) {
                                        // no need to scroll to first element (probably more but we cannot know)
                                        setTimeout(() => {
                                            storesarray[i].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                                        }, 750);
                                    }
                                    break;
                                }
                            }
                        }
                    } else {
                        this.utils.handleError('error retrieving all store stocks', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all store stocks', error);
                }
            });
    }

    // getAllStocksFor(store: Location): Observable<{success: boolean, result: StockType[], error: string}> {
    //     return this.storeService.getCoffeeStock(store._id ? store._id : store as any as string);
    // }

    objectChanged(obj: ObjectChangedInfo): void {
        if (!obj || (obj.model !== 'stocks' && obj.model !== 'stores')) {
            return;
        }

        if (!this.stores || !this.stocks) {
            return;
        }

        this.isNew = -1;
        this.editMode = -1;

        // a transaction has changed
        if (typeof obj.info !== 'string' && obj.info && obj.info.store && obj.info.type === 'transaction') {
            for (let s = 0; s < this.stores.length; s++) {
                if ((obj.info.store._id || obj.info.store).toString() === (this.stores[s]?._id || this.stores[s]).toString() ||
                    obj.info.store.internal_hr_id === this.stores[s]?.internal_hr_id) {
                    this.reloadStore(s, obj.info.store);
                    break;
                }
            }
            this.reloadGraphData();
            return;
        }

        if (obj.reload) {
            this.getAllStores();
        }
    }

    saveNotes(sIdx: number): void {
        if (!this.stores || sIdx < 0 || sIdx > this.stores.length || !this.stores[sIdx]) {
            this.logger.warn('stores, saveNotes, sIdx out of range', sIdx, this.stores ? this.stores.length : 'undefined');
            return;
        }
        if (this.stores[sIdx].notes === this.notes[sIdx]) {
            return;
        }

        this.standardService.update<Location>('stores', { _id: this.stores[sIdx]._id, notes: this.notes[sIdx], type: undefined, label: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.stores[sIdx].notes = this.notes[sIdx];
                    } else {
                        this.utils.handleError('error updating the store information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the store information', error);
                }
            });
    }

    changeCountryFilter(value = ''): void {
        const filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const countries = this.utils.getCountries(true);
        // this.storecopy.country = value;
        this.filteredCountries = countries.filter(country =>
            (country.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0));
        const validValue = this.utils.getCountryValue(value);
        if (validValue) {
            this.storecopy.country = validValue;
        }
        this.saveDisabled = !validValue && (value !== '');
    }

    checkCountry(value: string): void {
        if (!value || value === 'UNKNOWN') {
            this.storecopy.country = null;
            this.saveDisabled = false;
            return;
        }
        const cValue = this.utils.getCountryValue(value);
        if (!cValue) {
            this.storecopy.country = 'UNKNOWN';
            this.saveDisabled = true;
            setTimeout(() => {
                if (this.utils.getCountries(false).indexOf(this.storecopy.country) < 0) {
                    this.storecopy.country = null;
                    this.saveDisabled = false;
                }
            }, 1000);
        } else {
            this.storecopy.country = cValue;
            this.saveDisabled = false;
        }
    }

    cancel(): void {
        this.editMode = -1;
        this.storecopy = undefined;
        if (this.isNew >= 0) {
            this.isNew = -1;
            // this.objectChangedService.objectChanged({model: 'stores', object: this.stores[0], reload: true})
            this.stores.shift();
        }
        this.isNew = -1;
    }

    save(): void {
        if (this.readOnly) { return; }

        this.isSaving = true;
        if (this.isNew >= 0) {
            this.standardService.add('stores', this.storecopy)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (!response) {
                            this.alertService.success(this.tr.anslate('Nothing to change'));
                            this.isNew = -1;
                            this.editMode = -1;

                        } else if (response.success === true) {
                            this.alertService.success(this.tr.anslate('Successfully added'));
                            this.stores[0] = response && response.result ? response.result : cloneDeep(this.storecopy);
                            this.stocks.unshift([]);
                            this.stockSums.unshift(0);
                            this.stockValues.unshift(0);
                            this.isNew = -1;
                            this.editMode = -1;
                            for (let s = 0; s < this.stores.length; s++) {
                                this.notes[s] = this.stores[s].notes;
                                this.notesChanged[s] = new Subject<string>();
                                this.notesChanged[s].pipe(
                                    debounceTime(4000),
                                    distinctUntilChanged(),
                                    throttleTime(environment.RELOADTHROTTLE),
                                    takeUntil(this.ngUnsubscribe))
                                    .subscribe(() => this.saveNotes(s));
                            }

                        } else {
                            this.utils.handleError('error adding store', response.error);
                        }
                        this.isSaving = false;
                    },
                    error: error => {
                        this.utils.handleError('error adding store', error);
                        this.isSaving = false;
                    }
                });

        } else {
            if (this.storecopy.hr_id && this.storecopy._id) {
                // make sure only one of _id and hr_id is present in the update object
                delete this.storecopy.hr_id;
            }
            this.standardService.update<Location>('stores', this.storecopy)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (!response) {
                            this.alertService.success(this.tr.anslate('Nothing to change'));
                            this.isNew = -1;
                            this.editMode = -1;

                        } else if (response.success === true) {
                            this.alertService.success(this.tr.anslate('Successfully updated'));
                            this.stores[this.editMode] = response && response.result ? response.result : cloneDeep(this.storecopy);
                            this.isNew = -1;
                            this.editMode = -1;

                            // currently only necessary if label changed ...
                            this.reloadGraphData();

                        } else {
                            this.utils.handleError('error adding store', response.error);
                        }
                        this.isSaving = false;
                    },
                    error: error => {
                        this.utils.handleError('error updating the information', error);
                        this.isSaving = false;
                    }
                });
        }
    }

    private labelExists(label: string): boolean {
        for (const store of this.stores) {
            if (store.label === label) {
                return true;
            }
        }
        return false;
    }

    add(): void {
        if (this.readOnly) { return; }

        if (!this.stores) {
            this.stores = [];
        }
        let cnt = 0;
        let label = this.tr.anslate('NEW');
        while (this.labelExists(label)) {
            cnt++;
            label = this.tr.anslate('NEW') + cnt;
        }
        const store = new Location();
        store.label = label;
        store.type = [Enumerations.LocationTypes.STORE];

        this.stores.unshift(store);
        this.edit(this.stores[0], 0);
        this.isNew = 0;
    }

    reloadGraphData(): void {
        // if (this._graphsComponent) {
        //     this._graphsComponent.reloadData();
        // }
    }

    delete(store: Location, sIdx: number): void {
        if (this.readOnly) { return; }

        if (!this.stores || !this.stocks || !this.stocks[sIdx]) {
            this.logger.fatal('delete called on wrong state', this.stores, this.stocks);
            return;
        }
        this.checkingIfDeletable = true;
        this.standardService.getRefs('locations', store._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.checkingIfDeletable = false;
                    if (response.success === true) {
                        if (response.result.count > 0) {
                            const dialogRef = this.dialog.open(CantDeleteDialogComponent, {
                                closeOnNavigation: true,
                                data: {
                                    label: store.hr_id + ' ' + store.label,
                                    count: response.result.count,
                                    refs: this.utils.dateifyObjects(response.result.refs, ['date']),
                                    mainUnit: this.mainUnit,
                                    currency: this.currency
                                }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete(store, sIdx);
                                }
                            });

                        } else {
                            const dialogRef = this.dialog.open(YesNoDialogComponent, {
                                closeOnNavigation: true,
                                data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: store.hr_id + ' ' + store.label }) }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete(store, sIdx);
                                }
                            });
                        }
                    } else {
                        this.utils.handleError('could not delete the data', response.error);
                    }
                },
                error: error => {
                    this.checkingIfDeletable = false;
                    this.utils.handleError('could not delete the data', error);
                }
            });
    }

    private doDelete(store: Location, sIdx: number): void {
        if (this.needReloadGraphData) {
            this.reloadGraphData();
            this.needReloadGraphData = false;
        }

        this.standardService.remove('stores', store._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.isNew = -1;
                        this.editMode = -1;
                        this.stores.splice(sIdx, 1);
                        this.stocks.splice(sIdx, 1);
                        this.alertService.success(this.tr.anslate('Successfully removed'));
                    } else {
                        this.utils.handleError('Error updating the store information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Error updating the store information', error);
                }
            });
    }

    edit(store: Location, sIdx: number): void {
        if (this.readOnly) { return; }

        this.storecopy = cloneDeep(store);
        if (typeof this.storecopy.coordinates === 'undefined') {
            this.storecopy.coordinates = { type: '', coordinates: [] };
        }
        if (typeof this.storecopy.tags === 'undefined') {
            this.storecopy.tags = [];
        }
        this.editMode = sIdx;
    }

    getAmount(amount: number): string {
        return this.utils.formatAmount(
            amount,
            { name: Enumerations.CoffeeUnits._NONE, size: Enumerations.CoffeeUnitSizesInKg._NONE },
            this.currentUser.unit_system,
        );
    }

    // getTotalAmount(storeIdx: number): string {
    //     if (!this.stocks) {
    //         return '';
    //     }
    //     const stocks = this.stocks[storeIdx];
    //     if (!stocks) {
    //         return '';
    //     }
    //     let res = '';
    // don't use "of"
    //     for (const stock of stocks) {
    //         res += stock.coffeeLabel + ': ' + this.utils.formatAmount(stock.amount, stock.default_unit, this.currentUser.unit_system) + '; ';
    //     }
    //     res = res.substring(0, res.length - 2);
    //     return res;
    // }

    updateSums(storeIdx: number): void {
        // calculate sum
        this.stockSums[storeIdx] = 0;
        this.stockValues[storeIdx] = 0;
        for (const stock of this.stocks[storeIdx]) {
            this.stockSums[storeIdx] += stock.amount;
            this.stockValues[storeIdx] += stock.fifo_cost;
        }
    }

    purchase(): void {
        if (this.readOnly) { return; }
        if (!this.stores) { this.stores = []; }
        if (!this.stocks) { this.stocks = []; }

        const dialogRef = this.dialog.open(PurchaseDialogComponent, {
            closeOnNavigation: true,
            data: { stores: this.stores }
        });

        dialogRef.componentInstance.finished.subscribe(p => {
            const purchase = p as Purchase;
            if (!purchase) {
                dialogRef.close();
                return;
            }
            if (!purchase.amount) {
                this.alertService.success(this.tr.anslate('Ignored since amount was zero'));
                dialogRef.close();
                return;
            }
            // long running op; set waiting true and takeUntil(cancelled)
            dialogRef.componentInstance.waiting = true;
            this.standardService.add<Purchase>('purchases', purchase)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe), takeUntil(dialogRef.componentInstance.cancelled))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.alertService.success(this.tr.anslate('Successfully added'));

                            // make change locally
                            for (let storeIdx = 0; storeIdx < this.stores.length; storeIdx++) {
                                if (this.stores[storeIdx]._id?.toString() === (purchase.location._id || purchase.location).toString()) {
                                    // update transactions
                                    response.result.type = response.result.__t;
                                    response.result.translated_type = this.tr.anslate(response.result.type);
                                    this.transactionElems.toArray()[storeIdx]?.insertTransactionToList(this.utils.dateifyStockChanges([response.result])?.[0]);
                                    // this.transactionElems.toArray()[storeIdx].getAllTransactions();

                                    // update stock
                                    let found = false;
                                    for (const stock of this.stocks[storeIdx]) {
                                        if (stock?.coffeeId === (purchase.coffee._id || purchase.coffee).toString() && stock.location_id === (purchase.location._id || purchase.location).toString()) {
                                            stock.amount += response.result.amount;
                                            stock.cost += response.result.price;
                                            stock.fifo_cost += response.result.price;
                                            this.updateSums(storeIdx);
                                            found = true;
                                            break;
                                        }
                                    }
                                    if (!found) {
                                        // coffee not stored yet, have a new stock, update
                                        this.stockService.getCoffeeStock(this.stores[storeIdx]._id)
                                            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                            .subscribe({
                                                next: response2 => {
                                                    if (response2.success === true) {
                                                        this.stocks[storeIdx] = response2.result.filter(s => s.amount);
                                                        this.updateSums(storeIdx);
                                                    } else {
                                                        this.utils.handleError('error retrieving stock of a store', response2.error);
                                                    }
                                                },
                                                error: error => {
                                                    this.utils.handleError('error retrieving stock of a store', error);
                                                }
                                            });
                                    }
                                    break;
                                }
                            }
                            this.reloadGraphData();

                        } else {
                            this.utils.handleError('error adding purchase', response.error);
                        }
                        dialogRef.close();
                    },
                    error: error => {
                        this.utils.handleError('error adding purchase', error);
                        dialogRef.close();
                    }
                });
        });
    }

    updateAfterCorrection(correction: Correction): void {
        // get affected store / stock
        let stock: StockType;
        let foundStoreIdx: number;
        for (let storeIdx = 0; storeIdx < this.stores.length && !stock; storeIdx++) {
            if (this.stores[storeIdx]._id.toString() === (correction.location._id || correction.location).toString()) {
                foundStoreIdx = storeIdx;
                correction.location = this.stores[foundStoreIdx];
                for (let stockIdx = 0; stockIdx < this.stocks[storeIdx].length; stockIdx++) {
                    const tempstock = this.stocks[storeIdx][stockIdx];
                    if (tempstock?.coffeeId === (correction.coffee._id || correction.coffee).toString()
                        && tempstock.location_id === (correction.location._id || correction.location).toString()) {

                        if (Math.abs(correction.amount) < 0.01) {
                            // remove "empty stock"
                            this.stocks[storeIdx].splice(stockIdx, 1);
                            break;
                        }
                        correction.coffee = {
                            _id: tempstock.coffeeId,
                            label: tempstock.coffeeLabel,
                            internal_hr_id: tempstock.coffee_internal_hr_id,
                            origin: tempstock.origin,
                            yearLabel: tempstock.yearLabel,
                        };
                        stock = tempstock;
                        break;
                    }
                }
                break;
            }
        }
        if (typeof foundStoreIdx === 'undefined') {
            this.logger.fatal('store for correction not found ' + JSON.stringify(correction));
            return;
        }

        // update transactions
        correction.type = 'Correction';
        correction.translated_type = this.tr.anslate('Correction');
        if (correction.coffee) {
            correction.coffee.hr_id = correction.coffee.hr_id || 'C' + correction.coffee.internal_hr_id;
            correction.coffee.yearLabel = correction.coffee.yearLabel || this.utils.createBeansYearLabel(correction.coffee);
        }
        const lastTrans = this.shareMostRecentTransactionsService.getTransaction(correction.coffee?.hr_id, this.stores[foundStoreIdx]?.hr_id);
        const elem = this.transactionElems.toArray()[foundStoreIdx];
        if (elem) {
            elem.insertTransactionToList(correction);
        }
        // this.transactionElems.toArray()[foundStoreIdx].getAllTransactions();

        if (stock && lastTrans && correction.date > (lastTrans.date ?? DateTime.now())) {
            // update stock locally if this correction is the newest of all transaction
            stock.amount = correction.amount;
            if (correction.coffee && typeof correction.coffee.average_cost !== 'undefined') {
                stock.cost = correction.amount * correction.coffee.average_cost;
                stock.fifo_cost = correction.amount * correction.coffee.average_fifo_cost;
            }
            this.updateSums(foundStoreIdx);

        } else if (Math.abs(correction.amount) >= 0.01) {
            // have a new stock, update
            this.stockService.getCoffeeStock(this.stores[foundStoreIdx]._id)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.stocks[foundStoreIdx] = response.result.filter(s => s.amount !== 0);
                            this.updateSums(foundStoreIdx);

                        } else {
                            this.utils.handleError('error retrieving stock of a store', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving stock of a store', error);
                    }
                });
        }
        this.reloadGraphData();
    }

    correct(): void {
        if (this.readOnly) { return; }
        if (!this.stores) { this.stores = []; }
        if (!this.stocks) { this.stocks = []; }

        const dialogRef = this.dialog.open(CorrectDialogComponent, {
            closeOnNavigation: true,
            data: { stores: this.stores, stocks: this.stocks }
        });

        dialogRef.componentInstance.finished.subscribe(dialogResult => {
            if (dialogResult instanceof CorrectDialogResultType) {
                this.transService.handleCorrectionDialogClosed(dialogResult, dialogRef, undefined, undefined, undefined, this.stores, undefined,
                    dialogResult.openDialog === 'correction' ? (trans: Correction) => { this.updateAfterCorrection(trans) } : undefined, this.ngUnsubscribe);
            }
        });
    }

    transfer(): void {
        if (this.readOnly) { return; }
        if (!this.stores) { this.stores = []; }
        if (!this.stocks) { this.stocks = []; }

        const dialogRef = this.dialog.open(TransferDialogComponent, {
            closeOnNavigation: true,
            data: { stores: this.stores, stocks: this.stocks }
        });

        dialogRef.componentInstance.finished.subscribe(t => {
            const transfer = t as Transfer;
            if (!transfer) {
                dialogRef.close();
                return;
            }
            if (!transfer.amount) {
                this.alertService.success(this.tr.anslate('Ignored since amount was zero'));
                dialogRef.close();
                return;
            }
            // long running op; set waiting true and takeUntil(cancelled)
            dialogRef.componentInstance.waiting = true;
            this.standardService.add<Transfer>('transfers', transfer)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe), takeUntil(dialogRef.componentInstance.cancelled))
                .subscribe({
                    next: response => {
                        if (response.success === true && response.result) {
                            this.alertService.success(this.tr.anslate('Successfully added'));

                            let from_stock: StockType;
                            let from_storeIdx: number;
                            let stock: StockType;
                            let storeIdx: number;

                            let found = 0;
                            for (let s = 0; s < this.stores.length && found < 2; s++) {
                                if (this.stores[s]._id.toString() === (transfer.location._id || transfer.location).toString()) {
                                    // found source
                                    from_storeIdx = s;
                                    for (const stock of this.stocks[s]) {
                                        if (stock.coffeeId === (transfer.coffee._id || transfer.coffee).toString() && stock.location_id === (transfer.location._id || transfer.location).toString()) {
                                            from_stock = stock;
                                            found++;
                                            break;
                                        }
                                    }
                                }
                                if (this.stores[s]._id === (transfer.target._id || transfer.target).toString()) {
                                    // found target
                                    storeIdx = s;
                                    for (let s2 = 0; s2 < this.stocks[s].length && !stock; s2++) {
                                        if (this.stocks[s][s2].coffeeId === (transfer.coffee._id || transfer.coffee).toString() && this.stocks[s][s2].location_id === (transfer.target._id || transfer.target).toString()) {
                                            stock = this.stocks[s][s2];
                                            found++;
                                            break;
                                        }
                                    }
                                }
                            }

                            // update transactions
                            response.result = this.utils.dateifyStockChanges([response.result])?.[0] as Transfer;
                            response.result.type = response.result.__t;
                            response.result.translated_type = this.tr.anslate(response.result.type);
                            this.transactionElems.toArray()[from_storeIdx]?.insertTransactionToList(response.result);
                            this.transactionElems.toArray()[storeIdx]?.insertTransactionToList(response.result);
                            // this.transactionElems.toArray()[from_storeIdx].getAllTransactions();
                            // this.transactionElems.toArray()[storeIdx].getAllTransactions();

                            this.stockValues[from_storeIdx] = Number.MAX_VALUE;
                            this.stockValues[storeIdx] = Number.MAX_VALUE;

                            // update source stock
                            if (from_stock) {
                                from_stock.amount -= response.result.amount;
                                if (from_stock.amount === 0) {
                                    this.stocks[from_storeIdx] = this.stocks[from_storeIdx].filter(s => s.amount !== 0);
                                }
                            }

                            // update target stock
                            if (stock) {
                                stock.amount += response.result.amount;
                            } else {
                                // // have a new stock, update
                                // this.getAllStocksFor(this.stores[storeIdx]).subscribe(
                                //     response => {
                                //         if (response.success === true) {
                                //             this.stocks[storeIdx] = response.result.filter(s => s.amount !== 0);
                                //
                                //         } else {
                                //             this.utils.handleError('error retrieving stock of a store', response.error);
                                //         }
                                //     },
                                //     error => {
                                //         this.utils.handleError('error retrieving stock of a store', error);
                                //     }
                                // );
                            }
                            this.reloadGraphData();

                            this.reloadStore(from_storeIdx, this.stores[from_storeIdx]);
                            this.reloadStore(storeIdx, this.stores[storeIdx]);
                        } else {
                            this.utils.handleError('error adding transfer', response.error);
                        }
                        dialogRef.close();
                    },
                    error: error => {
                        this.utils.handleError('error adding transfer', error);
                        dialogRef.close();
                    }
                });
        });
    }

    sell(): void {
        if (this.readOnly) { return; }
        if (!this.stores) { this.stores = []; }
        if (!this.stocks) { this.stocks = []; }

        const dialogRef = this.dialog.open(SellDialogComponent, {
            closeOnNavigation: true,
            data: { stores: this.stores, stocks: this.stocks }
        });

        dialogRef.componentInstance.finished.subscribe(s => {
            const sale = s as Sale;
            if (!sale) {
                dialogRef.close();
                return;
            }
            if (!sale.amount) {
                this.alertService.success(this.tr.anslate('Ignored since amount was zero'));
                dialogRef.close();
                return;
            }
            // long running op; set waiting true and takeUntil(cancelled)
            dialogRef.componentInstance.waiting = true;
            this.standardService.add<Sale>('sales', sale)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe), takeUntil(dialogRef.componentInstance.cancelled))
                .subscribe({
                    next: response => {
                        if (!response) {
                            // nothing changed
                            this.alertService.success(this.tr.anslate('Nothing to change'));

                        } else if (response.success === true) {
                            this.alertService.success(this.tr.anslate('Successfully added'));

                            // make change locally
                            for (let storeIdx = 0; storeIdx < this.stores.length; storeIdx++) {
                                if (this.stores[storeIdx]._id === (sale.location._id || sale.location).toString()) {
                                    // update transactions
                                    response.result.type = response.result.__t;
                                    response.result.translated_type = this.tr.anslate(response.result.type);
                                    this.transactionElems.toArray()[storeIdx].insertTransactionToList(this.utils.dateifyStockChanges([response.result])?.[0]);
                                    // this.transactionElems.toArray()[storeIdx].getAllTransactions();

                                    // update stock
                                    for (const stock of this.stocks[storeIdx]) {
                                        if (stock?.coffeeId === (sale.coffee._id || sale.coffee).toString() && stock.location_id === (sale.location._id || sale.location).toString()) {
                                            stock.amount -= response.result.amount;
                                            this.updateSums(storeIdx);
                                            this.stockValues[storeIdx] = Number.MAX_VALUE;
                                            break;
                                        }
                                    }
                                    // // have a new stock, update
                                    // this.getAllStocksFor(this.stores[storeIdx]).subscribe(
                                    //     response => {
                                    //         if (response.success === true) {
                                    //             this.stocks[storeIdx] = response.result.filter(s => s.amount !== 0);
                                    //             this.updateSums(storeIdx);
                                    //
                                    //         } else {
                                    //             this.utils.handleError('error retrieving stock of a store', response.error);
                                    //         }
                                    //     },
                                    //     error => {
                                    //         this.utils.handleError('error retrieving stock of a store', error);
                                    //     }
                                    // );
                                    this.reloadStore(storeIdx, this.stores[storeIdx]);
                                    break;
                                }
                            }
                            this.reloadGraphData();

                        } else {
                            this.utils.handleError('error adding sale', response.error);
                        }
                        dialogRef.close();
                    },
                    error: error => {
                        this.utils.handleError('error adding sale', error);
                        dialogRef.close();
                    }
                });
        });
    }

    private updateImage(sIdx: number, avatar = false) {
        const imageOrLogo = avatar ? 'logo' : 'image';
        this.standardService.update<Location>('stores', { _id: this.stores[sIdx]._id, [imageOrLogo]: this.stores[sIdx][imageOrLogo], type: undefined, label: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response) {
                        this.alertService.success(this.tr.anslate('Nothing to change'));
                    } else if (response && response.success !== true) {
                        this.utils.handleError('error updating the store information', response?.error);
                    } else {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the store information', error);
                }
            });
    }

    changeImage(sIdx: number, avatar = false): void {
        if (this.readOnly || (!this.stores[sIdx]._id && !this.stores[sIdx].internal_hr_id)) { return; }

        const imageOrLogo = avatar ? 'logo' : 'image';
        const defaultImage = avatar ? 'assets/icons/main/store' + (this.isDarkmode ? '-dark' : '') + '.png' : 'assets/images/default_store.jpg';
        const dialogRef = this.dialog.open(ImageUpload2Component, {
            closeOnNavigation: true, minWidth: '300px', autoFocus: false,
            data: { url: this.stores[sIdx][imageOrLogo], avatar, default: defaultImage }
        });

        dialogRef.afterClosed().subscribe(fileString => {
            if (typeof fileString === 'undefined') {
                return;
            }
            if (fileString === null) {
                // remove image
                this.stores[sIdx][imageOrLogo] = null;
                this.updateImage(sIdx, avatar);
                return;
            }
            try {
                const file: Blob = this.utils.dataURItoBlob(fileString);
                this.fileService.uploadFile(file, 'IMAGE', 'STORE', this.stores[sIdx].internal_hr_id ? this.stores[sIdx].internal_hr_id.toString() : this.stores[sIdx]._id.toString())
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                if (environment.BASE_API_URL.indexOf('localhost') >= 0) {
                                    this.stores[sIdx][imageOrLogo] = '/' + (this.locale === 'en-GB' ? 'gb' : this.locale) + response.result;
                                } else {
                                    this.stores[sIdx][imageOrLogo] = response.result;
                                }
                                this.logger.debug('using image at ' + this.stores[sIdx][imageOrLogo]);
                                this.updateImage(sIdx, avatar);

                            } else {
                                this.utils.handleError('Could not set new image', response.error);
                            }
                        },
                        error: error => {
                            this.utils.handleError('Could not set new image', error);
                        }
                    });
            } catch (err) {
                if (err instanceof SizeTooLargeError) {
                    this.utils.handleError('File size too large (must be <2MB)', undefined);
                } else {
                    throw err;
                }
            }
        });
    }

    filesChanged(sIdx: number, newFiles: string[]): void {
        if (this.readOnly) { return; }

        this.stores[sIdx].files = newFiles;
        this.standardService.update<Location>('stores', { _id: this.stores[sIdx]._id, files: this.stores[sIdx].files, type: undefined, label: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.logger.debug('update successful');
                    } else {
                        this.utils.handleError('Could not update documents', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Could not update documents', error);
                }
            });
    }
}
