import { Observable, forkJoin } from 'rxjs';
import { Component, OnDestroy, Input, Output, EventEmitter, OnInit, ViewChild, Inject, LOCALE_ID, ViewChildren, QueryList } from '@angular/core';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Subject } from 'rxjs';
import { StandardService, RoastFilterInfo, FilterOptions, GetPageOptions, NumberFilterType } from 'src/app/util/services/standard.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { Coffee } from 'src/app/models/Coffee';
import { environment } from 'src/environments/environment';
import { TranslatorService } from 'src/app/util/services/translator.service';
// import { Options } from '@angular-slider/ngx-slider';
import { NGXLogger } from 'ngx-logger';
import { UserService, UserType } from '../frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { EMPTYTAG } from './filters/filter-utils';
import { Constants } from 'src/app/util/constants';
import { Blend } from 'src/app/models/Blend';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import { RangeFilterComponent } from './filters/range-filter.component';
import { SelectFilterComponent } from './filters/select-filter.component';
import { CheckboxFilterComponent } from './filters/checkbox-filter.component';
import { IdAndLabel, IdAndLabelArrayOrNotnull } from './filters/filter.component';
import { FilterService } from './filters/filter.service';
import { Utils2 } from 'src/app/util/utils2';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
    selector: 'app-roasts-filter',
    templateUrl: './roasts-filter.component.html',
    styleUrls: ['./roasts-filter.component.scss'],
    providers: [],
    animations: [
        trigger('openClose', [
            state('open', style({
                // height: '200px',
                // height: 'initial',
            })),
            state('closed', style({
                // display: 'none',
                visibility: 'hidden',
                height: 0,
                // width: 0,
                opacity: 0,
            })),
            transition('open <=> closed', [
                animate('0.5s ease')
            ]),
        ]),
    ],
})
export class RoastsFilterComponent implements OnInit, OnDestroy {

    constructor(
        public utils: Utils,
        private utils2: Utils2,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private standardService: StandardService,
        public tr: TranslatorService,
        public filterService: FilterService,
        private logger: NGXLogger,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    public anyAdditionalFilterActive = false;

    // total number of items
    @Input() count: number;
    @Input() showAlways = false;
    @Input() showCancelDates = true;
    private _hideSearchDetails = false;
    @Input() get hideSearchDetails() { return this._hideSearchDetails; }
    set hideSearchDetails(hsd: boolean) {
        this._hideSearchDetails = hsd;
        if (this.hideSearchDetails && this.detailFilterOpen && !this.anyAdditionalFilterActive) {
            this.detailFilterOpen = false;
        }
    }
    @Input() isDarkmode: boolean;
    @Input() readOnly: boolean;
    @Input() editNewRoast: number;
    @Input() currentUser: UserType;
    private _stores: { _id: string, hr_id?: string, label?: string }[]
    get stores() { return this._stores; }
    @Input() set stores(newStores: { _id: string, hr_id?: string, label?: string }[]) {
        this._stores = newStores;
        const ssf = this.utils.readShowStockFrom(this._stores, this.currentUser);
        this.filters.ssf = ssf;
        this.lastFilter = this.utils2.cleanResult(cloneDeep(this.filters));
    }

    @Output() filterChanged = new EventEmitter<{ options: GetPageOptions, now?: boolean, reloadFilterData?: boolean }>();
    @Output() moreThanOneUser = new EventEmitter<boolean>();

    setValueToFilterFunction: () => void;

    detailFilterOpen = false;
    callSetValuesToFilters = false;

    filterInfo: RoastFilterInfo;

    selected: { coffees?: Coffee[], blends?: Blend[], discarded?: boolean, reconciled?: boolean } = {};

    allChecked = false;
    indetChecked = false;

    private _filters: GetPageOptions = {};
    get filters(): GetPageOptions { return this._filters; }
    @Input() set filters(fo: GetPageOptions) {
        if (this.justSentFilter) {
            return;
        }
        if (!fo) {
            this._filters = { ssf: 'all' };
        } else {
            this.removeDefaults(fo);
            this._filters = fo;
            if (fo.origins?.notnull) {
                this.translatedOrigins = this.allOrigins.map(entry => entry.translated);
                fo.origins = { vals: this.allOrigins.map(entry => entry.english) };
            } else {
                this.translatedOrigins = fo.origins?.vals?.map((entry: string) => entry ? this.tr.anslate(entry) : '--') ?? [];
            }
        }
        this.lastFilter = this.utils2.cleanResult(cloneDeep(this.filters));
        if (this.hideSearchDetails && this.detailFilterOpen && !this.anyAdditionalFilterActive) {
            this.detailFilterOpen = false;
        }
        
        this.updateAdditionalFilterActive(this.filters);

        // cannot directly call setValuesToFilters since it needs this.filterInfo
        this.callSetValuesToFilters = true;
    }

    private _energyUnit: string;
    get energyUnit() { return this._energyUnit; }
    @Input() set energyUnit(eu: string) {
        const oldUnit = this._energyUnit;
        this._energyUnit = eu;
        if (oldUnit == null) {
            return;
        }
        for (const fop of this.allFilterOptions) {
            if (fop.label === 'Energy') {
                fop.unit = eu;
                break;
            }
        }
        this.currentUser.energy_unit = eu;
        if (this.filterInfo?.BTU_batch) {
            this.filterInfo.BTU_batch.unit = eu;
            if (oldUnit === 'BTU') {
                this.filterInfo.BTU_batch.min = this.utils.convertEnergy(this.filterInfo.BTU_batch.min, eu);
                this.filterInfo.BTU_batch.max = this.utils.convertEnergy(this.filterInfo.BTU_batch.max, eu);
            } else {
                this.filterInfo.BTU_batch.min = this.utils.convertEnergy(this.utils.convertEnergy(this.filterInfo.BTU_batch.min, oldUnit, true), eu);
                this.filterInfo.BTU_batch.max = this.utils.convertEnergy(this.utils.convertEnergy(this.filterInfo.BTU_batch.max, oldUnit, true), eu);
            }
            const uis = this.filterUIs?.toArray();
            for (let f = 0; f < uis?.length; f++) {
                const attrLabel = this.filterOptions[f].label;
                if (attrLabel === 'Energy') {
                    const filterUI = uis?.[f] as RangeFilterComponent;
                    const vals = filterUI.getValues();
                    setTimeout(() => {
                        const newMin = oldUnit === 'BTU' ? this.utils.convertEnergy(vals.min, eu) : this.utils.convertEnergy(this.utils.convertEnergy(vals.min, oldUnit, true), eu);
                        const newMax = oldUnit === 'BTU' ? this.utils.convertEnergy(vals.max, eu) : this.utils.convertEnergy(this.utils.convertEnergy(vals.max, oldUnit, true), eu);
                        filterUI.setValues({ min: newMin, max: newMax, inverse: vals.inverse, allowNull: vals.allowNull });
                    }, 50);
                    break;
                }
            }
        }
    }

    lastFilter: GetPageOptions;
    readableQuery = new Map<string, string>();
    justSentFilter = false;

    mainUnit: UnitSystemType = 'kg';
    tempUnit = '°C';

    // all available origins (ever roasted by this account)
    allOrigins: { translated: string, english: string }[] = [];
    // those origins that match the text typed by the user in the dropdown
    filteredOrigins: { translated: string, english: string }[] = [];
    // translated version of origins; for display as trigger in the dropdown
    translatedOrigins: string[] = [];

    helptipOrganicShown = false;
    showHelptipOrganic = true;
    helptipAddFilterShown = false;
    showHelptipAddFilter = true;

    existingFilters: FilterOptions[] = [];
    filteredLabels: string[] = [];
    filterName: string;
    newFilterLabel: string;
    isNewFilterLabel = false;

    allFilterOptionsSetForUniqueness = new Set<string>();
    allFilterOptions: { label: string, translatedLabel: string, unit?: string }[] = [];
    filterOptions: { label: string, type?: 'range' | 'checkbox' | 'select' | 'checkboxandtext', unit?: string }[] = [];
    filteredFilterOptions: { label: string, translatedLabel: string, unit?: string }[];
    dbNameToVisibleName = new Map<string, string>();
    visibleNameToDBname = new Map<string, string>();

    someFilterHasChanged = false;
    isLoading = false;
    isLoadingTimer: ReturnType<typeof setTimeout>;
    isLoadingTemplates = false;
    isLoadingTemplatesTimer: ReturnType<typeof setTimeout>;

    show: boolean[] = [];
    readonly nonAdditionalSearchParams = ['from', 'to', 'origins', 'showOrganic', 'ssf', 'pageSize', 'pageIndex', 'sortOrder', 'inverse', 'filtername', '_id', 'anpa', 'filtertype', 'owner', 'created_by', 'updated_by', 'created_at', 'updated_at'];
    readonly separatelyTreated = this.nonAdditionalSearchParams.concat(['amount', 'end_weight', 'coffees', 'blends', 'date']);

    // @ViewChild('amountRangeFilter') amountRangeFilter: RangeFilterComponent;
    _amountRangeFilter: RangeFilterComponent;
    @ViewChild(RangeFilterComponent) set amountRangeFilter(arf: RangeFilterComponent) {
        this._amountRangeFilter = arf;
        if (this.setValueToFilterFunction && arf) {
            setTimeout(() => {
                if (typeof this.setValueToFilterFunction === 'function') {
                    this.setValueToFilterFunction();
                    this.setValueToFilterFunction = undefined;
                }
            }, 50);
        }
    }
    get amountRangeFilter() { return this._amountRangeFilter; }
    
    @ViewChild('myDatepickerStart') datePickerStartDate: MatDatepicker<DateTime>;
    @ViewChild('myDatepickerEnd') datePickerEndDate: MatDatepicker<DateTime>;
    @ViewChild('endweightRangeFilter') endweightRangeFilter: RangeFilterComponent;
    @ViewChild('beansSelectFilter') beansSelectFilter: SelectFilterComponent<Coffee>;
    @ViewChild('blendsSelectFilter') blendsSelectFilter: SelectFilterComponent<Blend>;
    @ViewChildren('otherAttrSelect') otherAttrSelect: QueryList<MatSelect>;
    private _filterUIs: QueryList<RangeFilterComponent | SelectFilterComponent<never> | CheckboxFilterComponent < never >>;
    get filterUIs() { return this._filterUIs; }
    @ViewChildren('filterui') set filterUIs(fuis: QueryList<RangeFilterComponent | SelectFilterComponent<never> | CheckboxFilterComponent<never>>) {
        this._filterUIs = fuis;
    }

    EMPTYTAG = EMPTYTAG;
    DateTime = DateTime;
    round = Math.round;
    private ngUnsubscribe = new Subject();

    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.temperature_system === Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT) {
            this.tempUnit = '°F';
        }

        this.helptipOrganicShown = !!(this.currentUser.hts & Enumerations.HELPTIP.ORGANICBEANS);
        this.helptipAddFilterShown = !!(this.currentUser.hts & Enumerations.HELPTIP.ADDFILTER);

        if (!this.filters.ssf) {
            // directly read ssf; cannot use this.utils.readShowStockFrom bc we don't have this.stores yet
            const ssf = localStorage.getItem('stockfrom_' + this.currentUser.user_id);
            if (ssf && ssf !== 'all') {
                this.filters.ssf = ssf.split(Constants.SSF_SEPARATOR).map(s => ({ _id: s }));
            }
            // if (ssf === 'all') {
            //     this.filter.ssf = 'all';
            // } else if (ssf) {
            //     this.filter.ssf = ssf.split(Constants.SSF_SEPARATOR).map(s => ({ _id: s }));
            // }
        }

        // get all origins/origin_regions that have been used in roasts for the filter
        this.updateOriginSet();

        // preload filter data so that animation is smooth
        this.getRoastFilterData();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.isLoadingTemplatesTimer);
    }

    public reloadRoastFilterData(): void {
        this.getRoastFilterData(true);
    }

    public setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    private removeDefaults(filter: GetPageOptions): void {
        if (filter.pageIndex === 0) {
            delete filter.pageIndex
        }
        // if (filter.ssf === 'all') {
        //     delete filter.ssf;
        // }
    }

    private async addDefaultFilters() {
        const defaultFilters: FilterOptions[] = [
            {
                filtertype: 'Roast',
                amount: { allowNull: 'only' },
                filtername: this.tr.anslate('No amount set'),
            },
            {
                filtertype: 'Roast',
                end_weight: { allowNull: 'only' },
                filtername: this.tr.anslate('No yield set'),
            },
            {
                filtertype: 'Roast',
                coffees: { vals: [null] },
                blends: { vals: [null] },
                filtername: this.tr.anslate('No beans set'),
            },
        ];
        if (this.filterInfo.blends?.vals?.length) {
            defaultFilters.push(
                {
                    filtertype: 'Roast',
                    blends: { vals: [null] },
                    filtername: this.tr.anslate('No blends'),
                },
            )
        }
        // if (this.filterInfo.blends?.vals?.length) {
        //     defaultFilters.push(
        //         {
        //             filtertype: 'Roast',
        //             blends: this.filterInfo.blends.vals.slice(1),
        //             label: this.tr.anslate('Only Blends'),
        //         },
        //     )
        // }
        if (this.filterInfo.coffees?.vals?.[1]?._id && this.filterInfo.blends?.vals?.length > 1) {
            defaultFilters.push(
                {
                    filtertype: 'Roast',
                    coffees: { vals: [this.filterInfo.coffees.vals[1]] },
                    blends: { notnull: true },
                    filtername: this.tr.anslate('Specific bean in blends'),
                },
            )
        }
        const obsvbls = [];
        for (const deffilter of defaultFilters) {
            obsvbls.push(this.standardService.add<FilterOptions>('customfilters', deffilter));
        }
        forkJoin(obsvbls as Observable<{ success: boolean; result: FilterOptions; error: string; }>[])
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: responses => {
                    for (const response of responses) {
                        if (response?.success && response.result) {
                            this.existingFilters.push(this.utils.dateifyObject(response.result, ['from', 'to']));
                        }
                    }
                    this.filteredLabels = this.existingFilters?.map(ef => ef.filtername) ?? [];
                },
                // ignore error
            });
    }

    getPropNameForTranslation(modAttr: string): string {
        switch (modAttr) {
            case 'BTU batch':
                return 'Energy';
            case 'Density roasted':
                return 'Density';
            case 'CO2 batch':
                return 'CO2';
            case 'Charge temp':
                return 'CHARGE BT';
            case 'Drop temp':
                return 'DROP BT';
            case 'Drop time':
                return 'DROP';
            case 'FCs temp':
                return 'FC BT';
            case 'FCs RoR':
                return 'FC RoR';
            case 'FCs time':
                return 'FC';
            // case 'date':
            //     return 'Date';
            default:
                return modAttr;
        }
    }

    // load data used for specific filtering / filter info
    getRoastFilterData(reload = false): void {
        this.standardService.getRoastFilterData(this.filters.from, this.filters.to)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.filterInfo = this.utils.dateifyRoastFilterInfo(response.result);
                        if (!this.filterInfo) {
                            return;
                        }

                        if (!reload) {
                            this.getExistingFilters();
                        }

                        this.moreThanOneUser.emit(this.filterInfo.users?.vals?.length > 1);

                        if (this.filterInfo.coffees) {
                            for (let c = 0; c < this.filterInfo.coffees.vals?.length; c++) {
                                const cof = this.filterInfo.coffees[c];
                                if (cof && !cof.yearLabel) {
                                    cof.yearLabel = this.utils.createBeansYearLabel(cof) || undefined;
                                }
                            }
                            // this.filters.coffees = [];
                        }

                        // check existing filter options
                        // set input method to use, remove filters with only one option (e.g. users)
                        for (let f = 0; f < this.filterOptions.length; f++) {
                            const fo = this.filterOptions[f];
                            const attr = this.getAttrFromName(fo.label);
                            if (attr !== 'discarded' && attr !== 'reconciled' && attr !== 'notes' && attr !== 'cupping_notes' && this.filterInfo[attr]?.vals?.length === 1) {
                                this.filterOptions.splice(f, 1);
                                f -= 1;
                                continue;
                            }
                            fo.type = this.getBestInputMethod(attr);
                        }
                        // always add template
                        this.filterInfo.template = { vals: [] };

                        this.allFilterOptions.length = 0;
                        this.allFilterOptionsSetForUniqueness.clear();
                        const attrs = Object.getOwnPropertyNames(this.filterInfo);
                        for (const attr of attrs) {
                            if (this.separatelyTreated.indexOf(attr) >= 0) {
                                continue;
                            }
                            if (typeof this.filterInfo[attr] !== 'undefined') {
                                // remove filters with only one option
                                if (attr !== 'discarded' && attr !== 'reconciled' && attr !== 'notes' && attr !== 'cupping_notes' && this.filterInfo[attr]?.vals?.length === 1) {
                                    delete this.filterInfo[attr];
                                    continue;
                                }

                                let modAttr: string;
                                // do not modify CHARGE
                                if (new RegExp(/^[A-Z]*$/).test(attr)) {
                                    modAttr = attr;
                                } else {
                                    // modify from "ground_color" to "Ground color"
                                    // modify from "color_system" to "Color system"
                                    // modify from "machine" to "Machine"

                                    modAttr = attr.replace('_', ' ');
                                    modAttr = modAttr[0].toUpperCase() + modAttr.slice(1);
                                }
                                if (!this.allFilterOptionsSetForUniqueness.has(modAttr)) {
                                    this.allFilterOptionsSetForUniqueness.add(modAttr);
                                    modAttr = this.getPropNameForTranslation(modAttr);
                                    const translatedLabel = this.filterService.translateAttr(modAttr);
                                    this.allFilterOptions.push({ label: modAttr, translatedLabel, unit: this.filterInfo[attr].unit });
                                    this.visibleNameToDBname.set(modAttr, attr);
                                    this.dbNameToVisibleName.set(attr, modAttr);
                                }
                            }
                        }
                        // these are in separatelyTreated and must be modified separately
                        this.visibleNameToDBname.set('Amount', 'amount');
                        this.dbNameToVisibleName.set('amount', 'Amount');
                        this.visibleNameToDBname.set('Yield', 'end_weight');
                        this.dbNameToVisibleName.set('end_weight', 'Yield');
                        this.visibleNameToDBname.set('organic', 'showOrganic');
                        this.dbNameToVisibleName.set('showOrganic', 'organic');
                        this.visibleNameToDBname.set('Ground color', 'ground_color');
                        this.dbNameToVisibleName.set('ground_color', 'Ground color');
                        this.visibleNameToDBname.set('Cupping Notes', 'cupping_notes');
                        this.dbNameToVisibleName.set('cupping_notes', 'Cupping Notes');
                        this.visibleNameToDBname.set('Cupping', 'cupping_score');
                        this.dbNameToVisibleName.set('cupping_score', 'Cupping');
                        this.visibleNameToDBname.set('Color system', 'color_system');
                        this.dbNameToVisibleName.set('color_system', 'Color system');

                        // these are mostly for the getFilterDescription
                        this.dbNameToVisibleName.set('ssf', 'Stores');
                        this.dbNameToVisibleName.set('from', 'Date');
                        this.dbNameToVisibleName.set('origins', 'Origins');
                        this.dbNameToVisibleName.set('coffees', 'Beans');
                        this.dbNameToVisibleName.set('blends', 'Blends');

                        this.allFilterOptions.sort((a, b) => a.translatedLabel.localeCompare(b.translatedLabel, this.locale));
                        this.filteredFilterOptions = this.allFilterOptions.slice();

                        // setTimeout(() => {
                        //     this.setValuesToFilters(this.lastFilter);                            
                        // }, 1);
                    } else {
                        this.utils.handleError('error retrieving all roasts', response.error);
                    }

                    if (this.callSetValuesToFilters) {
                        this.callSetValuesToFilters = false;
                        this.setValuesToFilters(this.filters);
                        this.updateAdditionalFilterActive(this.filters);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                }
            });
    }

    clearLoadSaveSelect(): void {
        this.filterName = undefined;
    }

    // valsToString(vals: unknown[], attr: string): string[] {
    //     if (attr === 'template') {
    //         return vals.map((v: {hr_id?: string, pre?: string, num?: number, label?: string}) => !v ? '' : `${v.hr_id || `${v.pre ?? ''}${v.num ?? ''} ${v.label}`?.trim() || ''}`);
    //     }
    //     return vals.map(v => v.toString());
    // }

    loadTemplates(index: number, cb?: (onlyUpdateAttr: string) => void) {
        this.filterOptions[index].type = undefined;

        // request templates from server
        this.isLoadingTemplatesTimer = setTimeout(() => {
            this.isLoadingTemplates = true;
        }, 600);
        this.standardService.getRoastTemplatesForFilter(this.filters.from, this.filters.to)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: async response => {
                    clearTimeout(this.isLoadingTemplatesTimer);
                    this.isLoadingTemplates = false;
                    if (response.success === true) {
                        this.filterInfo.template = { vals: response.result };
                        this.filterOptions[index].type = this.getBestInputMethod('template');
                        if (typeof cb === 'function') {
                            setTimeout(cb, 1, 'Template');
                        }
                    }
                },
                error: error => {
                    clearTimeout(this.isLoadingTemplatesTimer);
                    this.isLoadingTemplates = false;
                    this.utils.handleError('error retrieving information', error);
                }
            });
    }

    /**
     * Called when an optional filter attribute has been selected
     */
    filterOptionChanged(index: number, newOption: MatSelectChange): void {
        // remove filter for previously selected option
        const oldAttr = this.getAttrFromName(this.filterOptions[index].label);
        if (oldAttr) {
            delete this.selected[oldAttr];
            delete this.filters[oldAttr];
        }

        if (this.filterOptions[index]) {
            // set new selected option
            this.filterOptions[index].label = newOption.value;

            const attr = this.getAttrFromName(this.filterOptions[index].label);
            
            if (attr === 'template') {
                this.loadTemplates(index);
            } else {
                this.filterOptions[index].type = this.getBestInputMethod(attr);
            }
            // this.filterOptions = this.filterOptions.slice();
        }
        // reload component; had some update refresh issues using other methods
        // (set rangeOptions to new object, using manualRefresh)
        this.show[index] = false;
        setTimeout(() => this.show[index] = true, 10);
    }

    /**
     * Decide which UI should be used for this particular input
     * @param {string} attr property
     * @returns {"range" | "checkbox" | "select"} string
     */
    getBestInputMethod(attr: string): 'range' | 'checkbox' | 'select' | 'checkboxandtext' {
        if (!attr || !this.filterInfo) {
            return undefined;
        }
        if (attr === 'notes' || attr === 'cupping_notes') {
            return 'checkboxandtext';
        }
        if (this.filterInfo[attr]?.vals?.length) {
            // this case should be avoided beforehand; better to display a
            // single checkbox than nothing, though:
            // if (attr !== 'discarded' && attr !== 'reconciled' && attr !== 'notes' && attr !== 'cupping_notes' && this.filterInfo[attr]?.vals?.length === 1) {
            //     return undefined;
            // }
            // template values are too long for checkboxes
            if (attr !== 'template' && this.filterInfo[attr]?.vals?.length <= 3) {
                return 'checkbox';
            } else {
                return 'select';
            }
        } else {
            if (this.filterInfo[attr]?.min != null && this.filterInfo[attr].min === this.filterInfo[attr].max) {
                this.filterInfo[attr].vals = [null, `${this.filterInfo[attr].min}`];
                return 'checkbox';
            } else if (!this.filterInfo[attr] || this.filterInfo[attr]?.vals?.length === 0) {
                // checkbox handles no options best
                return 'checkbox';
            } else {
                return 'range';
            }
        }
    }

    filterFilters(label: string): void {
        const labels = this.existingFilters.map(ef => ef.filtername);
        this.filteredLabels = this.utils.filterArray(label, labels);
        const upperlabels = this.existingFilters.map(ef => (ef.filtername ?? '').toUpperCase());
        this.isNewFilterLabel = label && upperlabels.indexOf(label.toUpperCase()) < 0;
        if (this.isNewFilterLabel) {
            this.newFilterLabel = label;
        }
    }

    resetAllFilters(): void {
        this.amountRangeFilter?.reset();
        this.endweightRangeFilter?.reset();
        this.beansSelectFilter?.reset();
        this.blendsSelectFilter?.reset();
        this.filterOptions = [];
    }

    getExistingFilters(): void {
        this.standardService.getAll<FilterOptions>('customfilters', { filtertype: 'Roast' })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (!this.readOnly && !response.result?.length) {
                            // no filters, check whether to add default filters
                            this.standardService.getDeleted<FilterOptions>('customfilters')
                                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                .subscribe({
                                    next: async response => {
                                        if (response.success === true && response.count === 0) {
                                            // no deleted filters, i.e. new user, add default filters
                                            await this.addDefaultFilters();
                                        }
                                    },
                                    // ignore error
                                });
                        }
                        this.existingFilters = this.utils.dateifyObjects(response.result, ['from', 'to']) ?? [];
                        // this.existingFilters = response.result?.map(cf => {
                        //     cf.filter._id = cf._id;
                        //     return cf.filter;
                        // }) ?? [];
                        this.existingFilters.forEach(ef => {
                            ef.from = typeof ef.from === 'string' ? DateTime.fromISO(ef.from) : undefined;
                            ef.to = typeof ef.to === 'string' ? DateTime.fromISO(ef.to) : undefined;
                        });
                        this.filteredLabels = this.existingFilters.map(ef => ef.filtername) ?? [];
                    } else {
                        this.utils.handleError('error updating the information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the information', error);
                }
            });
    }

    saveFilter(filter: FilterOptions): void {
        filter.filtertype = 'Roast';
        this.standardService.add<FilterOptions>('customfilters', filter)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.existingFilters.unshift(this.utils.dateifyObject(response.result, ['from', 'to']));
                        this.filteredLabels = this.existingFilters?.map(ef => ef.filtername) ?? [];
                    } else {
                        this.utils.handleError('error updating the information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the information', error);
                }
            });
    }

    setValuesToFilters(loadedFilter: FilterOptions, filterNameCopy?: string, onlyOptional = false, loading = false): void {
        // don't use setter as this would update the lastFilter
        this._filters = cloneDeep(loadedFilter);
        this.utils2.cleanLoadedFilter(this._filters);

        this.setValueToFilterFunction = () => {
            if (!onlyOptional) {
                // specifically set amount and end_weight range filters
                const propsArr = ['amount', 'end_weight'];
                const rangeFilters = [this.amountRangeFilter, this.endweightRangeFilter];
                for (let i = 0; i < 2; i++) {
                    const prop = propsArr[i];
                    if (loadedFilter[prop]) {
                        rangeFilters[i]?.setValues({ min: loadedFilter[prop].min, max: loadedFilter[prop].max, inverse: !!loadedFilter[prop].inverse, allowNull: loadedFilter[prop].allowNull });
                    }
                    // need to update in any case, might just be reset
                    this.readableQuery.set(prop, this.getReadableQuery(this.filterInfo[prop], loadedFilter[prop], this.locale, prop));
                }
                // specifically set coffee select filter
                if (loadedFilter.coffees) {
                    this.beansSelectFilter?.setValues(loadedFilter.coffees);
                }
                // specifically set blend select filter
                if (loadedFilter.blends) {
                    this.blendsSelectFilter?.setValues(loadedFilter.blends);
                }
            }
    
            // add all optional filters
            let filterAdded = false;
            // let updateUiFuncCalledSeparately = false;
            const updateUiFunc = (onlyUpdateAttr: string) => {
                const uis = this.filterUIs?.toArray();
                for (let f = 0; f < uis?.length; f++) {
                    const filterUI = uis?.[f];
                    const attrLabel = this.visibleNameToDBname.get(this.filterOptions[f].label) ?? this.filterOptions[f].label;
                    if (!onlyUpdateAttr || this.filterOptions[f].label === onlyUpdateAttr) {
                        if (this.filterOptions[f].label === 'BTU_batch' && this.energyUnit !== 'BTU') {
                            // need to convert values from BTU to current unit
                            loadedFilter[attrLabel].min = this.utils.convertEnergy(loadedFilter[attrLabel].min, this.energyUnit);
                            loadedFilter[attrLabel].max = this.utils.convertEnergy(loadedFilter[attrLabel].max, this.energyUnit);
                        }
                        filterUI.setValues(loadedFilter[attrLabel]);
                    }
                }
                setTimeout(() => {
                    if (filterNameCopy) {
                        this.filterName = filterNameCopy;
                    }
                    this.lastFilter = this.utils2.cleanResult(cloneDeep(this.filters));
                }, 10);
            };
            this.show.length = 0;
            const props = Object.getOwnPropertyNames(loadedFilter);
            this.filterOptions.length = 0;
            for (const prop of props) {
                if (this.separatelyTreated.includes(prop)) {
                    continue;
                }
                if (prop === 'template') {
                    this.filterOptions.push({ label: this.dbNameToVisibleName.get(prop) ?? prop });
                    this.loadTemplates(this.filterOptions.length - 1, updateUiFunc);
                    // updateUiFuncCalledSeparately = true;
                } else {
                    this.filterOptions.push({ label: this.dbNameToVisibleName.get(prop) ?? prop, type: this.getBestInputMethod(prop) });
                }
                this.show.push(true);
                filterAdded = true;
            }

            this.checkIfFilterHasChanged();
            this.detailFilterOpen = this.detailFilterOpen || filterAdded || !!loadedFilter.amount || !!loadedFilter.end_weight || loading;
            // set values for optional filters
            if (filterAdded) {
                // if (!updateUiFuncCalledSeparately) {
                    setTimeout(updateUiFunc, 10);
                // }
            } else {
                setTimeout(() => {
                    if (filterNameCopy) {
                        this.filterName = filterNameCopy;
                    }
                }, 50);
            }
        }
        this.detailFilterOpen = this.detailFilterOpen || !!loadedFilter.amount || !!loadedFilter.end_weight || loading;
        if (this.amountRangeFilter) {
            this.setValueToFilterFunction();
            this.setValueToFilterFunction = undefined;
        } else {
            // schedule for later when the filter UI has loaded
        }
    }

    filterNameSelected(): void {
        if (this.isNewFilterLabel) {
            // save
            this.logger.debug('save ' + this.filterName);
            const filterCopy = this.utils2.cleanResult(cloneDeep(this.filters)) as GetPageOptions;
            delete filterCopy.pageSize;
            delete filterCopy.pageIndex;
            delete filterCopy.sortOrder;
            delete filterCopy.inverse;

            if (this.energyUnit !== 'BTU' && (filterCopy.BTU_batch?.['min'] || filterCopy.BTU_batch?.['max'])) {
                // need to convert values; always store energy filter values in BTU
                if (this.energyUnit !== 'BTU') {
                    filterCopy.BTU_batch['min'] = this.utils.convertEnergy(filterCopy.BTU_batch['min'], this.energyUnit, true);
                    filterCopy.BTU_batch['max'] = this.utils.convertEnergy(filterCopy.BTU_batch['max'], this.energyUnit, true);
                }
            }

            filterCopy.filtername = this.filterName;
            this.saveFilter(filterCopy);
        } else {
            // load
            const filterNameCopy = this.filterName;
            this.logger.debug('load ' + this.filterName);
            for (const loadedFilter of this.existingFilters) {
                if (!loadedFilter) {
                    continue;
                }
                if (loadedFilter.filtername === this.filterName) {
                    this.resetAllFilters();
                    if (!loadedFilter.ssf) {
                        loadedFilter.ssf = 'all';
                    }
                    this.setValuesToFilters(loadedFilter, filterNameCopy, false, true);
                    break;
                }
            }
        }
    }

    deleteExistingFilter(idx: number): void {
        if (!this.existingFilters?.[idx]) {
            return;
        }
        this.logger.debug('remove ' + this.existingFilters[idx].filtername);
        this.standardService.remove('customfilters', this.existingFilters[idx]._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.existingFilters.splice(idx, 1);
                        this.filteredLabels = this.existingFilters?.map(ef => ef.filtername) ?? [];
                    } else {
                        this.utils.handleError('cannot delete', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('cannot delete', error);
                }
            });
    }

    // TODO generate once and store in variable
    /**
     * Returns a description of the contents of a filter
     * @param {number} idx the index within this.existingFilters
     * @returns {string} concatenation of all translated properties used in this filter
     */
    getFilterDescription(idx: number): string {
        if (!this.existingFilters?.[idx]) {
            return '';
        }
        let str = '';
        const props = Object.getOwnPropertyNames(this.existingFilters[idx]);
        for (const prop of props) {
            if (prop === 'ssf' && this.existingFilters[idx].ssf === 'all') {
                continue;
            }
            const rprop = prop === 'showOrganic' ? 'organic' : this.dbNameToVisibleName.get(prop);
            if (rprop && typeof this.existingFilters[idx][prop] !== 'undefined') {
                str += `${this.filterService.translateAttr(rprop)}, `;
            }
            // str += (rprop ? this.translate(rprop) : prop) + ', ';
        }

        return str?.substring(0, str.length - 2);
    }

    selectChanged<T extends IdAndLabel>(attr: string, newSelection: IdAndLabelArrayOrNotnull<T>) {
        this.selected[attr] = newSelection;
        if (newSelection.notnull) {
            this.filters[attr] = { notnull: true };
        } else if (attr === 'template') {
            this.filters[attr] = { vals: newSelection?.vals?.slice() };
        } else {
            this.filters[attr] = { vals: newSelection?.vals?.map((obj: T) => obj?._id ?? obj?.label ?? obj?.toString()) };
        }
        this.checkIfFilterHasChanged();
        this.clearLoadSaveSelect();
    }

    labelChanged() {
        if (!this.filters.label) {
            delete this.filters.label;
        }
        this.checkIfFilterHasChanged();
        this.clearLoadSaveSelect();
    }

    checkboxChanged(attr: string, newSelection: string[]) {
        if (attr === 'discarded') {
            this.selected.discarded = !!newSelection[0];
            this.filters.discarded = !!newSelection[0];
        } else if (attr === 'reconciled') {
            this.selected.reconciled = !!newSelection[0];
            this.filters.reconciled = !!newSelection[0];
        // } else if (attr === 'discarded') {
        //     this.selected.discarded = !!newSelection[0];
        //     this.filters.discarded = !!newSelection[0];
        } else {
            this.selected[attr] = newSelection.slice();
            this.filters[attr] = { vals: newSelection.slice() };
        }
        this.checkIfFilterHasChanged();
        this.clearLoadSaveSelect();
    }

    checkboxAndTextChanged(attr: string, checkbox: boolean, text: string) {
        if (attr === 'notes') {
            if (!checkbox) {
                this.filters.notes = { allowNull: 'only' };
            } else {
                this.filters.notes = { allowNull: 'no', vals: (text ? [text] : undefined) };
            }
        } else if (attr === 'cupping_notes') {
            if (!checkbox) {
                this.filters.cupping_notes = { allowNull: 'only' };
            } else {
                this.filters.cupping_notes = { allowNull: 'no', vals: (text ? [text] : undefined) };
            }
        } else {
            // this is actually not supported ...
            this.selected[attr] = checkbox;
            this.filters[attr] = { vals: text };
        }
        this.checkIfFilterHasChanged();
        this.clearLoadSaveSelect();
    }

    datesChanged($event: MatDatepickerInputEvent<DateTime>, isStartDate: boolean): void {
        let startDate: DateTime = DateTime.invalid('no date given');
        let endDate: DateTime = DateTime.invalid('no date given');
        if (isStartDate) {
            startDate = $event.value;
            endDate = this.filters.to;
            if (endDate?.isValid && endDate < startDate) {
                endDate = startDate;
            }
        } else {
            startDate = this.filters.from;
            endDate = $event.value;
            if (startDate?.isValid && startDate > endDate) {
                startDate = endDate;
            }
        }
        if (startDate?.isValid && endDate?.isValid) {
            startDate = startDate.startOf('day');
            endDate = endDate.endOf('day');
            this.filters.from = startDate;
            this.filters.to = endDate;
            this.reloadRoastFilterData();
            this.doFilter();
        } else if (!startDate?.isValid && !endDate?.isValid) {
            this.cancelFilterDates();
        } else {
            this.filters.from = startDate;
            this.filters.to = endDate;
            if (isStartDate) {
                if (startDate?.isValid) {
                    this.datePickerEndDate?.open();
                } else {
                    // startDate changed but is not valid
                    startDate = this.filterInfo.date?.min ?? DateTime.fromMillis(0);
                    this.filters.from = startDate;
                    if (endDate?.isValid) {
                        this.reloadRoastFilterData();
                        this.doFilter();
                    }
                }
            // } else {
            //     // endDate changed but is not valid
            }
        }
    }

    cancelFilterDates(): void {
        // cannot use undefined for dates as addDefaultsToFilterOptions would reset them
        this.filters.from = DateTime.invalid('no date given');
        this.filters.to = DateTime.invalid('no date given');
        this.reloadRoastFilterData();
        this.doFilter();
    }

    // toggleAll(item: string, allToggle: boolean): void {
    //     if (allToggle) {
    //         this.filters[item] = this.filteredItems[item];
    //         this.selected[item] = this.filteredItems[item];
    //     } else {
    //         this.filters[item] = [];
    //         this.selected[item] = [];
    //     }
    //     if (!this.filters[item]?.length) {
    //         // none selected is same as all selected
    //         this.allChecked[item] = true;
    //         this.indetChecked[item] = false;
    //     } else if (this.filters[item]?.length === this.filterInfo[item].length) {
    //         this.allChecked[item] = true;
    //         this.indetChecked[item] = false;
    //     } else {
    //         this.allChecked[item] = false;
    //         this.indetChecked[item] = true;
    //     }
    // }

    // getObjectTrigger(objects: { hr_id?: string, label?: string }[], showEmpty = true): string {
    //     // we know that objects.length > 1
    //     let str = objects[0]?.hr_id || objects[0]?.label || '';
    //     if (!showEmpty && str === EMPTYTAG) {
    //         str = '';
    //     }
    //     let o = 1;
    //     while (o < objects.length && str.length < 25) {
    //         str += ', ' + objects[o]?.hr_id || objects[o]?.label || '';
    //         o += 1;
    //     }
    //     if (o < objects.length) {
    //         str = str.substring(0, 21);
    //         str += ' ...';
    //     }
    //     return str;
    // }

    // getTemplateTrigger(templates: { pre?: string, num?: number }[], showEmpty = true): string {
    //     // we know that .length > 1
    //     let str = (templates[0].pre || '') + (templates[0].num === 0 ? '0' : (templates[0].num || ''));
    //     if (!showEmpty && str === EMPTYTAG) {
    //         str = '';
    //     }
    //     let c = 1;
    //     while (c < templates.length && str.length < 25) {
    //         str += ', ' + (templates[c].pre || '') + (templates[c].num === 0 ? '0' : (templates[c].num || ''));
    //         c += 1;
    //     }
    //     if (c < templates.length) {
    //         str = str.substring(0, 21);
    //         str += ' ...';
    //     }
    //     return str;
    // }

    // getStringTrigger(strs: string[]): string {
    //     // we know that strs.length > 1
    //     let str = strs[0];
    //     if (str == null) {
    //         str = '[ ]';
    //     }
    //     let c = 1;
    //     while (c < strs.length && str.length < 25) {
    //         str += ', ' + strs[c];
    //         c += 1;
    //     }
    //     if (c < strs.length) {
    //         str = str.substring(0, 21);
    //         str += ' ...';
    //     }
    //     return str;
    // }

    rangeChanged(prop: string, $event: NumberFilterType): void {
        if (!this.filters[prop]) {
            this.filters[prop] = {};
        }
        this.filters[prop].min = $event.min;
        this.filters[prop].max = $event.max;
        this.filters[prop].inverse = $event.inverse;
        this.filters[prop].allowNull = $event.allowNull;
        // submit filter on click!
        // this.doFilter();

        this.checkIfFilterHasChanged();
        this.clearLoadSaveSelect();
        // if ($event.firstInit) {
        //     this.lastFilter = this.utils2.cleanResult(cloneDeep(this.filter));
        // }
        setTimeout(() => {
            this.readableQuery.set(prop, this.getReadableQuery(this.filterInfo[prop], this.filters[prop], this.locale, prop));            
        }, 0);
    }

    // /**
    //  * Checks whether an option changed with respect to this.lastFilter that
    //  * should trigger filter data reload. Currently only the from/to dates.
    //  * @param filter current filter
    //  * @returns true if an option changed that should trigger filter data reload
    //  */
    // private hasPrimaryFilterDataChanged(filter: GetPageOptions): boolean {
    //     if ((this.lastFilter.from || filter.from) && +filter.from !== +this.lastFilter.from
    //         || (this.lastFilter.to || filter.to) && +filter.to !== +this.lastFilter.to) {
    //         // || this.lastFilter.showOrganic !== filter.showOrganic
    //         // || !isEqual(this.lastFilter.ssf, filter.ssf)
    //         // || !isEqual(this.lastFilter.origins, filter.origins)) {
    //         return true;
    //     }
    //     return false;
    // }

    checkIfOriginsFilterNotNull(filterCopy: GetPageOptions): boolean {
        const filterItem = filterCopy.origins.vals;
        if (filterItem?.length) {
            const allCount = (this.allOrigins?.length ?? 0) + 1;
            if (allCount && filterItem.length === allCount - 1) {
                // one item is missing - check that it is the null item
                if (filterItem.every(val => val != null && val !== EMPTYTAG)) {
                    return true;
                }
            }
        }
        return false;
    }

    // update anyAdditionalFilterActive state
    private updateAdditionalFilterActive(filter: GetPageOptions): void {
        this.anyAdditionalFilterActive = false;
        const attrs = Object.getOwnPropertyNames(filter);
        for (const prop of attrs) {
            if (!this.nonAdditionalSearchParams.includes(prop) && typeof filter[prop] !== 'undefined') {
                this.anyAdditionalFilterActive = true;
                break;
            }
        }
    }

    doFilter(now = false): void {
        this.anyAdditionalFilterActive = false;

        // const filterCopy: GetPageOptions = cloneDeep(this.filters ?? {});
        const filterCopy: GetPageOptions = this.utils2.cleanResult(cloneDeep(this.filters ?? {}));
        // const reloadFilterData = this.hasPrimaryFilterDataChanged(filterCopy);
        // if (reloadFilterData) {
        //     this.reloadRoastFilterData();
        // }

        if (!filterCopy || !Object.keys(filterCopy).length) {
            this.logger.debug('using no filter ... ');
            this.filterChanged.emit({ options: undefined, now });
        } else {
            this.updateAdditionalFilterActive(filterCopy);

            // check special case that can be translated into $ne: null
            const attrs = Object.getOwnPropertyNames(filterCopy);
            for (const attr of attrs) {
                if (attr === 'origins') {
                    if (filterCopy.origins?.vals?.length === this.allOrigins.length + 1) {
                        filterCopy.origins = undefined;
                    } else if (this.checkIfOriginsFilterNotNull(filterCopy)) {
                        filterCopy.origins = { notnull: true };
                    }
                    continue;
                }
                const filterItem = filterCopy[attr];
                if (filterItem?.length) {
                    const allCount = this.filterInfo?.[attr]?.vals?.length;
                    if (allCount && filterItem.length === allCount - 1) {
                        // one item is missing - check that it is the null item
                        if (filterItem.every((val: unknown) => val != null)) {
                            filterCopy[attr] = { notnull: true };
                        }
                    }
                }
            }

            delete filterCopy.pageIndex;
            this.logger.debug('going to filter ... ' + JSON.stringify(filterCopy));
            this.justSentFilter = true;
            this.filterChanged.emit({ options: filterCopy, now });
            this.justSentFilter = false;
        }
        this.someFilterHasChanged = false;
        this.lastFilter = cloneDeep(filterCopy);
        // this.clearLoadSaveSelect();
    }

    checkIfFilterHasChanged(): void {
        const filterCopy: GetPageOptions = this.utils2.cleanResult(cloneDeep(this.filters));
        if (filterCopy.ssf === 'all' && !this.lastFilter.ssf) {
            // treat undefined and 'all' equal for ssf
            this.lastFilter.ssf = 'all';
        }
        // ignore pageIndex
        delete filterCopy.pageIndex;
        delete this.lastFilter.pageIndex;
        const ch = !isEqual(this.lastFilter, filterCopy);
        // if (ch) {
            setTimeout(() => {
                this.someFilterHasChanged = ch;    
            }, 1);
        // } else {
        //     this.someFilterHasChanged = ch;    
        // }
    }

    addFilter(): void {
        this.filterOptions.push({ label: '' });
        this.removeHelptip('addfilter');
        setTimeout(() => {
            this.openFilterDropdown();
        }, 50);
    }

    openFilterDropdown(): void {
        if (this.otherAttrSelect?.length) {
            this.otherAttrSelect.last.open();
        }
    }

    toggleAll(allToggle: boolean): void {
        if (allToggle) {
            this.filters.origins = { vals: this.allOrigins.slice().map(orig => orig.english) };
        } else {
            this.filters.origins = undefined;
        }
        this.originsChanged({ value: this.filters.origins?.vals });
    }

    deleteFilter(optionNr: number): void {
        const attr = this.visibleNameToDBname.get(this.filterOptions[optionNr]?.label);
        if (attr) {
            delete this.selected[attr];
            delete this.filters[attr];
        }
        this.filterOptions.splice(optionNr, 1);
        this.checkIfFilterHasChanged();
    }

    // from "Color system" to "color_system"
    getAttrFromName(attrName: string): string {
        return this.visibleNameToDBname.get(attrName) ?? attrName;
    }

    updateOriginSet() {
        this.allOrigins = [];
        this.standardService.getOriginsOfRoasts()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                         
                        const oregions = (response.result.origin_regions ?? []).map(oreg => ({ english: oreg, translated: this.tr.anslate(oreg).toLocaleUpperCase(this.locale) })).sort((o1, o2) => o1?.translated?.localeCompare(o2?.translated, this.locale));
                        this.allOrigins = oregions.concat((response.result.origins || []).map(origin => ({ english: origin, translated: this.tr.anslate(origin) })).sort((o1, o2) => o1?.translated?.localeCompare(o2?.translated, this.locale)));
                        this.filteredOrigins = this.allOrigins;
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                }
            });
    }

    originsChanged(changeEvent: { value?: string[] }): void {
        this.translatedOrigins = changeEvent.value?.map((entry: string) => entry ? this.tr.anslate(entry) : '') ?? [];
        if (!changeEvent.value?.length) {
            this.filters.origins = undefined;
        } else {
            this.filters.origins = { vals: changeEvent.value as string[] };
        }

        if (!this.filters.origins?.vals?.length) {
            this.allChecked = false;
            this.indetChecked = false;
        } else {
            if (this.filters.origins?.vals?.length === this.allOrigins.length + 1 || (this.filters.origins?.vals?.[0] !== EMPTYTAG && this.filters.origins?.vals?.length === this.allOrigins.length)) {
                // have all (or all but --) selected
                // don't set selectedOption=[] here as it would de-select all
                // while the user is still looking at the list and has just
                // selected an element (the last one)
                // see filterClosed and emitChanges
                // this.selectedOptions = [];
                this.allChecked = true;
                this.indetChecked = false;
            } else {
                this.allChecked = false;
                this.indetChecked = true;
            }
        }

        this.doFilter();
    }

    filterOrigins(value: string): { translated: string, english: string }[] {
        if (!value) {
            // return [];
            return this.allOrigins;
            // return this.allOrigins.map(entry => entry.english);
        }
        const filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return this.allOrigins.filter(originOrRegion => originOrRegion.translated.toLocaleLowerCase(this.locale).normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0);
    }

    // called from the template when the dropdown value changed
    showstockfromChanged($event: { value: { _id: string, hr_id?: string, label?: string }[] | 'all' }): void {
        // if ($event.value === 'all' || $event.value.length === this.stores.length) {
        //     // $event.value = 'all';
        //     $event.value = undefined;
        // }
        if (!$event.value?.length || $event.value.length === this.stores.length) {
            $event.value = 'all';
        }
        this.filters.ssf = $event.value;
        this.utils.storeShowStockFrom($event.value, this.currentUser);
        this.doFilter();
    }

    showstockfromOpened(opened: boolean): void {
        if (!opened && !this.filters.ssf?.length) {
            // nothing selected, change to 'all' for dropdown
            // delete this.filters.ssf;
            this.filters.ssf = 'all';
        }
    }

    // called from template without arg or from outside with arg newState
    organicChanged(newState?: 'on' | 'off' | ''): void {
        if (this.editNewRoast >= 0) {
            // edit mode on
            return;
        }
        if (typeof newState !== 'undefined') {
            this.filters.showOrganic = newState;
        } else {
            this.filters.showOrganic = this.utils.getNextShowOrganicState(this.filters.showOrganic);
        }
        this.doFilter();
        this.removeHelptip('organic');
    }

    removeHelptip(type: 'addfilter' | 'organic'): void {
        if (type === 'organic') {
            if (this.showHelptipOrganic && !this.helptipOrganicShown) {
                this.showHelptipOrganic = false;
                this.helptipOrganicShown = true;
                this.utils.storeHelptipShown(Enumerations.HELPTIP.ORGANICBEANS);
            }
        } else {
            if (this.showHelptipAddFilter && !this.helptipAddFilterShown) {
                this.showHelptipAddFilter = false;
                this.helptipAddFilterShown = true;
                this.utils.storeHelptipShown(Enumerations.HELPTIP.ADDFILTER);
            }
        }
    }

    getReadableQuery(filterInfo: { min: number, max: number, step: number }, filter: NumberFilterType, locale: string, attribute: string): string {
        // avoid crash
        let str = '';
        const { min: floor, max: ceil, step = 0.1 } = filterInfo ?? {};
        if (floor == null || ceil == null) {
            return undefined;
        }
        const nrDigits = Math.round(Math.log10(1 / step));
        const onlyOneOption = floor === ceil;
        const { min = floor, max = ceil, inverse = false, allowNull = 'yes' } = filter ?? {};
        const idstr = attribute === 'ID' ? 'R' : '';
        const myEmptyTag = (attribute === 'amount' || attribute === 'end_weight') ? '0' : EMPTYTAG;
        if (allowNull === 'only') {
            return attribute === 'amount' || attribute === 'end_weight' ? '0' : this.tr.anslate('missing value');
        } else {
            if (!onlyOneOption) {
                if (!inverse) {
                    if (min === floor && max === ceil) {
                        if (allowNull === 'no') {
                            return this.tr.anslate('any value');
                        }
                        if (allowNull === 'yes') {
                            return this.tr.anslate('any');
                        }
                    } else { // min !== floor || max !== ceil
                        if (allowNull === 'yes' && attribute !== 'ID') {
                            str = `${myEmptyTag} ${this.tr.anslate('or')} `;
                        }
                        if (min === max) {
                            if (idstr) {
                                return str.concat(`${idstr}${min.toFixed(0)}`);
                            } else {
                                return str.concat(`${idstr}${min.toLocaleString(locale, { maximumFractionDigits: this.fixNrDigits(nrDigits, min) })}`);
                            }
                        }
                        if (min !== floor) {
                            if (idstr) {
                                str = str.concat(`\u2265${idstr}${min.toFixed(0)}`);
                            } else {
                                str = str.concat(`\u2265${idstr}${min.toLocaleString(locale, { maximumFractionDigits: this.fixNrDigits(nrDigits, min) })}`);
                            }
                        }
                        if (min !== floor && max !== ceil) {
                            str = `${str} ${this.tr.anslate('and')} `;
                        }
                        if (max !== ceil) {
                            if (idstr) {
                                str = str.concat(`\u2264${idstr}${max.toFixed(0)}`);
                            } else {
                                str = str.concat(`\u2264${idstr}${max.toLocaleString(locale, { maximumFractionDigits: this.fixNrDigits(nrDigits, max) })}`);
                            }
                        }
                    }
                } else {
                    if (allowNull === 'yes' && attribute !== 'ID') {
                        str = myEmptyTag;
                        if (min !== floor || max !== ceil) {
                            str = `${str} ${this.tr.anslate('or')} `;
                        }
                    }
                    if (min === max) {
                        if (idstr) {
                            return str.concat(`${this.tr.anslate('not')} ${idstr}${min.toFixed(0)}`);
                        } else {
                            return str.concat(`${this.tr.anslate('not')} ${idstr}${min.toLocaleString(locale, { maximumFractionDigits: this.fixNrDigits(nrDigits, min) })}`);
                        }
                    }
                    if (min !== floor) {
                        if (idstr) {
                            str = str.concat(`<${idstr}${min.toLocaleString(locale, { maximumFractionDigits: this.fixNrDigits(nrDigits, min) })}`);
                        } else {
                            str = str.concat(`<${idstr}${min.toFixed(0)}`);
                        }
                    }
                    if (min !== floor && max !== ceil) {
                        str = `${str} ${this.tr.anslate('or')} `;
                    }
                    if (max !== ceil) {
                        if (idstr) {
                            str = str.concat(`>${idstr}${max.toFixed(0)}`);
                        } else {
                            str = str.concat(`>${idstr}${max.toLocaleString(locale, { maximumFractionDigits: this.fixNrDigits(nrDigits, max) })}`);
                        }
                    }
                }
            } else {
                if (allowNull === 'no') {
                    return `${idstr}${floor.toLocaleString(locale, { maximumFractionDigits: this.fixNrDigits(nrDigits, floor) })}`;
                }
                // allowNull === 'yes'
                return `0 ${this.tr.anslate('or')} ${floor.toLocaleString(locale, { maximumFractionDigits: this.fixNrDigits(nrDigits, floor) })}`;
            }
        }
        return str;
    }

    /**
     * Checks whether the value would be rounded to 0 with the given 
     * number of digits and returns a better fitting number of digits
     * (<= 3) if possible; returns the given number of digits otherwise
     * @param {number} nrDigits suggested number of digits
     * @param {number} val number to check
     * @returns {number} new suggested umber of digits
     */
    fixNrDigits(nrDigits: number, val: number): number {
        if (!val) {
            return nrDigits;
        }
        let newNrDigits = nrDigits;
        if (val < Math.pow(10, -newNrDigits) / 2) {
            // would be rounded to 0, check if increasing number of digits helps
            do {
                newNrDigits += 1;
            } while (newNrDigits < 4 && val < Math.pow(10, -newNrDigits) / 2);
        }
        if (newNrDigits >= 4) {
            return nrDigits;
        }
        return newNrDigits;
    }
}
