import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-renamemachinenames-dialog',
    templateUrl: './renamemachinenames-dialog.component.html',
    styleUrls: ['./renamemachinenames-dialog.component-themed.scss'],
})
export class RenameMachineNamesDialogComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<RenameMachineNamesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { 
            machines: { label: string, cnt: number }[],
            useCnt: true,
        }
    ) { }

    replacements: { val: string, replaceWith: string, cnt?: number }[];

    haveChanges = false;
    willMerge = false;
    willMergeIdxs = [];

    ngOnInit(): void {
        this.replacements = this.data.machines.filter(m => m.label && (!this.data.useCnt || m?.cnt)).map(m => {
            return { val: m.label, replaceWith: m.label, cnt: m.cnt };
        });
    }

    inputChanged(): void {
        this.haveChanges = false;
        const names = new Set<string>();
        for (const replacement of this.replacements) {
            if (replacement.val !== replacement.replaceWith) {
                this.haveChanges = true;
            }
            if (names.has(replacement.replaceWith)) {
                this.willMerge = true;
                this.willMergeIdxs = this.replacements.map((r, idx) => r.replaceWith === replacement.replaceWith ? idx : -1).filter(i => i >= 0);
                // must have changes
                return;
            }
            names.add(replacement.replaceWith);
        }
    }

    closeDialog(): void {
        this.dialogRef.close(this.replacements.map(r => ({ val: r.val, replaceWith: r.replaceWith })));
    }
}
