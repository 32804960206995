import { Component, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { Roast } from 'src/app/models/Roast';
import { Sale } from 'src/app/models/Sale';
import { Purchase } from 'src/app/models/Purchase';
import { MatTableDataSource } from '@angular/material/table';
import { ClipboardService } from 'ngx-clipboard';
import { ReportService } from 'src/app/modules/report/report.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UserType } from 'src/app/modules/frame/services/user.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-cr-germany-rosenheim',
    templateUrl: './cr-germany-rosenheim.component.html',
    styleUrls: ['./cr-germany-rosenheim.component.scss']
})
export class CrGermanyRosenheimComponent {

    constructor(
        private clipboardService: ClipboardService,
        private reportService: ReportService,
        public utils: Utils,
        public tr: TranslatorService,
    ) { }

    @ViewChild('transTable') transTableElem: ElementRef;
    @ViewChild('roastTable') roastTableElem: ElementRef;

    @Input() currentUser: UserType;
    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() readOnly = false;

    roastsHaveErrors = false;
    transactionsHaveErrors = false;
    onlyDiscardedNotDestroyed = false;

    private _manualDiscarded = false;
    get manualDiscarded(): boolean { return this._manualDiscarded; }
    @Input() set manualDiscarded(md: boolean) {
        if (this._manualDiscarded !== md) {
            this._manualDiscarded = md;
            this.updateRoastData();
        }
    }

    private _roasts: Roast[];
    get roasts(): Roast[] { return this._roasts; }
    @Input() set roasts(r: Roast[]) {
        this._roasts = r;
        this.updateRoastData();
    }
    private _sales: Sale[];
    get sales(): Sale[] { return this._sales }
    @Input() set sales(s: Sale[]) {
        this._sales = s;
        if (this._purchases) {
            this.trans = this._sales.concat(this._purchases);
            this.trans.sort((a, b) => +a.date - +b.date);
            this.data_trans = new MatTableDataSource(this.trans);
            this.transactionsHaveErrors = this.checkTransactionErrors();
        } else {
            this.trans = this._sales;
        }
        if (this._sales) {
            this.sum_sale = this._sales.reduce((sum, val) => sum += val.amount || 0, 0);
        }
    }
    private _purchases: Purchase[];
    get purchases(): Purchase[] { return this._purchases }
    @Input() set purchases(p: Purchase[]) {
        this._purchases = p ?? [];
        if (this._sales) {
            this.trans = this._purchases.concat(this._sales);
            this.trans.sort((a, b) => +a.date - +b.date);
            this.data_trans = new MatTableDataSource(this.trans);
            this.transactionsHaveErrors = this.checkTransactionErrors();
        } else {
            this.trans = this._purchases;
        }
        if (this._purchases) {
            this.sum_purch = this._purchases.reduce((sum, val) => sum += val.amount || 0, 0);
        }
    }

    @Output() dataChanged = new EventEmitter();

    FIELDSEPARATOR = '\t';
    LINESEPARATOR = '\r\n';
    DECIMALSEPARATOR = '.';
    @Input() set exportSettings(es: { fieldSep: string, decSep: string, lineSep: string }) {
        this.FIELDSEPARATOR = es.fieldSep;
        this.LINESEPARATOR = es.lineSep;
        this.DECIMALSEPARATOR = es.decSep;
    }

    trans: (Sale | Purchase)[];

    sum_purch = 0;
    sum_sale = 0;
    sum_amount = 0;
    sum_end_weight = 0;
    sum_discarded = 0;
    sum_end_weight_discarded = 0;
    nrDiscardedButTaxed = 0;
    nrDiscarded = 0;

    data_trans: MatTableDataSource<Purchase | Sale>;
    columnsToDisplay_trans = ['Date', 'Supplier/Customer', 'Order/Sales Number', 'Coffees', 'Purchase Amount', 'Sale Amount'];

    data_roasts: MatTableDataSource<Roast>;
    columnsToDisplay_roasts = ['Date', 'Coffees', 'ID', 'Blends', 'Amount', 'End Weight'];

    justCopied = false;
    copyFailed = false;
    justCopied_r = false;
    copyFailed_r = false;

    DateTime = DateTime;

    cleanStr(str: string): string {
        return str.replace('"', '\'').replace(this.FIELDSEPARATOR, ' ');
    }

    /**
     * (Re)calculates overall information on all roasts (sum, errors, discarded, ...)
     * Must be called if any parameter changed that affect any of those.
     * TODO: this is called twice on init or date change since both, set _manualDiscarded
     * and set roasts calls it. Throttle?
    */
    updateRoastData(): void {
        this.sum_discarded = 0;
        this.sum_end_weight_discarded = 0;
        this.sum_amount = 0;
        this.sum_end_weight = 0;
        this.nrDiscardedButTaxed = 0;
        this.nrDiscarded = 0;
        this.roastsHaveErrors = false;

        this.data_roasts = new MatTableDataSource(this._roasts);
        // need to set the filterPredicate funtion
        this.onlyDiscardedNotDestroyedChanged(undefined, false);
        for (let r = 0; r < this.data_roasts?.filteredData.length; r++) {
            const roast = this.data_roasts.filteredData[r];
            if (!roast) {
                this.roastsHaveErrors = true;
            } else {
                if (roast.discarded) {
                    this.nrDiscarded += 1;
                    if (!this.manualDiscarded || roast.destroyed) {
                        this.sum_discarded += roast.amount || 0;
                        this.sum_end_weight_discarded += roast.end_weight || 0;
                        roast['Bemerkung'] = 'Fehlröstung';
                    } else {
                        this.nrDiscardedButTaxed += 1;
                        if (roast['Bemerkung'] === 'Fehlröstung') {
                            roast['Bemerkung'] = undefined;
                        }
                    }
                }
                this.sum_end_weight += roast.end_weight || 0;
                this.sum_amount += roast.amount || 0;
                if (!this.roastsHaveErrors && (
                    (!roast.coffee && !roast.blend)
                    || typeof roast.amount === 'undefined'
                    || typeof roast.end_weight === 'undefined')) {
                    this.roastsHaveErrors = true;
                }
            }
        }
        if (this.nrDiscarded) {
            // add or remove the Destroyed table column
            // depending on _manualDiscarded flag / checkbox
            const tctdr = this.columnsToDisplay_roasts;
            if (this._manualDiscarded) {
                if (tctdr[tctdr.length - 1] !== 'Destroyed') {
                    tctdr.push('Destroyed');
                }
            } else if (tctdr[tctdr.length - 1] === 'Destroyed') {
                tctdr.pop();
            }
        }
    }

    onlyDiscardedNotDestroyedChanged(change?: MatCheckboxChange, callUpdate = true): void {
        if (change) {
            this.onlyDiscardedNotDestroyed = change.checked;
        }
        if (this.onlyDiscardedNotDestroyed) {
            // need to set the filter string to sth otherwise the filterPredicate
            // funtion will be ignored; need to set the predicate before!
            this.data_roasts.filterPredicate = record =>
                record.discarded && !record.destroyed;
            this.data_roasts.filter = 'home';
        } else {
            this.data_roasts.filterPredicate = () => true;
            this.data_roasts.filter = undefined;
        }

        if (callUpdate) {
            // only udpate if not called from updateRoastData itself
            this.updateRoastData();
        }
    }

    destroyedChanged(i?: number): void {
        this.utils.destroyedChanged(() => this.updateRoastData(), () => this.dataChanged.emit(), this.readOnly, this._roasts, i);
    }

    removeDestroyed(i: number): void {
        this.utils.removeDestroyed(i, () => this.updateRoastData(), () => this.dataChanged.emit(), this.readOnly, this._roasts);
    }

    applyDestroyedToAll(i: number): void {
        this.utils.applyDestroyedToAll(i, () => this.updateRoastData(), () => this.dataChanged.emit(), this.readOnly, this._roasts);
    }

    checkRoastErrors(): boolean {
        for (const roast of this.roasts) {
            if (!roast
                || (!roast.coffee && !roast.blend)
                || typeof roast.amount === 'undefined'
                || typeof roast.end_weight === 'undefined') {
                return true;
            }
        }
        return false;
    }

    checkTransactionErrors(): boolean {
        for (let t = 0; t < this.trans?.length; t++) {
            const transaction = this.trans[t];
            if (!transaction
                || !transaction.coffee
                || typeof transaction.amount === 'undefined') {
                return true;
            }
        }
        return false;
    }

    copyTrans(): void {
        // create internal text representation for copy
        let text = '';
        for (let i = 0; i < this.trans?.length; i++) {
            const purchaseOrSale = this.trans[i];
            if (purchaseOrSale.date) {
                text += purchaseOrSale.date.toFormat('dd.MM.yyyy') + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (purchaseOrSale['supplier']) {
                text += '"' + this.cleanStr(purchaseOrSale['supplier']) + '"' + this.FIELDSEPARATOR;
            } else if (purchaseOrSale['customer']) {
                text += '"' + this.cleanStr(purchaseOrSale['customer']) + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (purchaseOrSale['order_number']) {
                text += '"' + this.cleanStr(purchaseOrSale['order_number']) + '"' + this.FIELDSEPARATOR;
            } else if (purchaseOrSale['sales_number']) {
                text += '"' + this.cleanStr(purchaseOrSale['sales_number']) + '"' + this.FIELDSEPARATOR;
            } else if (purchaseOrSale.reference) {
                text += '"' + this.cleanStr(purchaseOrSale.reference) + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (purchaseOrSale.coffee) {
                text += '"' + this.reportService.createCoffeeLabel(purchaseOrSale.coffee, true, this.FIELDSEPARATOR === ',' ? ' ' : ',', true, this.FIELDSEPARATOR).replace('"', '\'') + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (purchaseOrSale.__t === 'Sale') {
                // sale amounts go one column futher to the right
                text += this.FIELDSEPARATOR;
            }
            if (!Number.isNaN(purchaseOrSale.amount)) {
                let num = (Math.round(this.utils.convertToUserUnit(purchaseOrSale.amount, this.currentUser?.unit_system) * 1000) / 1000).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num;
            }
            if (i < this.trans.length - 1) {
                text += this.LINESEPARATOR;
            }
        }
        if (this.clipboardService.copyFromContent(text, this.transTableElem?.nativeElement)) {
            this.justCopied = true;
            setTimeout(() => {
                this.justCopied = false;
            }, 2000);
        } else {
            this.copyFailed = true;
            setTimeout(() => {
                this.copyFailed = false;
            }, 2000);
        }
    }

    copyRoasts(): void {
        // create internal text representation for copy
        let text = '';
        for (let i = 0; i < this.data_roasts?.filteredData.length; i++) {
            const roastdata = this.data_roasts.filteredData[i];
            if (roastdata.date) {
                // the form only wants to have the DAY, not the full date
                text += roastdata.date.toFormat('d') + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (roastdata.coffee) {
                text += '"' + this.cleanStr(this.reportService.createCoffeeLabel(roastdata.coffee, true, this.FIELDSEPARATOR === ',' ? ' ' : ',', true)) + '"' + this.FIELDSEPARATOR;
            } else if (roastdata.blend) {
                text += '"' + this.cleanStr(roastdata.blend.label) + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (roastdata.hr_id) {
                text += '"' + roastdata.hr_id + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (roastdata.blend) {
                text += '"' + this.cleanStr(this.reportService.createBlendLabel(roastdata.blend, true, ' ', ' / ')) + '"' + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (!isNaN(roastdata.amount)) {
                let num = (Math.round(this.utils.convertToUserUnit(roastdata.amount, this.currentUser?.unit_system) * 1000) / 1000).toString();
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                text += num + this.FIELDSEPARATOR;
            } else {
                text += this.FIELDSEPARATOR;
            }
            if (!isNaN(roastdata.end_weight)) {
                let num = '0';
                if (!roastdata.discarded || (this.manualDiscarded && !roastdata.destroyed)) {
                    num = (Math.round(this.utils.convertToUserUnit(roastdata.end_weight, this.currentUser?.unit_system) * 1000) / 1000).toString();
                    if (this.DECIMALSEPARATOR !== '.') {
                        num = num.replace('.', this.DECIMALSEPARATOR);
                    }
                }
                text += num;
            }
            if (i < this.data_roasts?.filteredData.length - 1) {
                text += this.LINESEPARATOR;
            }
        }
        if (this.clipboardService.copyFromContent(text, this.roastTableElem?.nativeElement)) {
            this.justCopied_r = true;
            setTimeout(() => {
                this.justCopied_r = false;
            }, 2000);
        } else {
            this.copyFailed_r = true;
            setTimeout(() => {
                this.copyFailed_r = false;
            }, 2000);
        }
    }
}
