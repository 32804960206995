import { HttpRequest, HttpEvent, HttpHandlerFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserType } from 'src/app/modules/frame/services/user.service';

export function readonlyaccountInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    if (req.url.indexOf('.php') === -1) {
        // add parameter 'account' if looking at a different account than the user's own account
        const currentUser = JSON.parse(localStorage.getItem('currentUser')) as UserType;
        if (currentUser?.token) {
            // logged in
            if (currentUser.readonly_account_idx >= 0 && currentUser.other_accounts[currentUser.readonly_account_idx]) {
                if (req.method === 'GET' || req.method === 'DELETE') {
                    req = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${currentUser.token}`
                        },
                        setParams: {
                            _account: currentUser.other_accounts[currentUser.readonly_account_idx]._id,
                        }
                    });
                } else {
                    const newBody = Object.assign({}, req.body) as { _account: string };
                    newBody._account = currentUser.other_accounts[currentUser.readonly_account_idx]._id;
                    req = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${currentUser.token}`
                        },
                        body: newBody,
                    });
                }
            }
        }
    }

    return next(req);
}
