import { Account } from './Account';
import { User } from './User';
import { Roast } from './Roast';
import { FilterOptions } from '../util/services/standard.service';
import { DateTime } from 'luxon';

export class RoastReportOpen {
    _id: string;
    created_at ?: Date;
    updated_at ?: Date;
    deleted ?: boolean;
    owner ?: Account;
    created ?: DateTime;
    startDate ?: DateTime;
    endDate ?: DateTime;
    label ?: string;
    number ?: string;
    roasts ?: Roast[];
    pdfLink ?: string;
    pdfLinkDate?: DateTime;
    pdfOvLink ?: string;
    pdfOvLinkDate ?: DateTime;
    files ?: string[];
    created_by ?: User;
    updated_by ?: User;
    certInfoOnCreation ?: number;
    discardedCount ?: number;
    discardedAmount ?: number;
    // removed after adding the generic FilterOptions field
    // anpa ?: boolean; // allowNonPositiveRoasts (amount or end_weight)
    filter?: FilterOptions;
    // only used in client
    certInfo ?: number;
    roastsCount ?: number;
    roastsTotal ?: number;
    roastsTotalOut ?: number;
    fullyLoaded ?: boolean;
}

