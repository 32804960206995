import { User } from './User';
import { Account } from './Account';
import { Tag } from './Tag';
import { Coffee } from './Coffee';
import { Location } from './Location';
import { DateTime } from 'luxon';

export class StockChange {
    _id: string;
    created_at ?: Date;
    updated_at ?: Date;
    deleted ?: boolean;
    owner ?: Account;
    coffee ?: Coffee;
    location ?: Location;
    amount: number;
    reconciled ? = false;
    tags ?: Tag[];
    reference ?: string;
    refs ?: { count: number, objs?: string[] };
    date: DateTime = DateTime.now();
    report ?: string;
    reportNote ?: string;
    created_by ?: User;
    updated_by ?: User;
    // only in client
    price ?: number;
    __t ?: string;
    type ?: string;
    translated_type ?: string;
}

