<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>


@if (blend?.ingredients?.length > 0) {
<div>
    @for (ing of blend.ingredients; track $index) {
    <div class="flex-end" style="margin-bottom: 15px;">
        <div style="white-space: nowrap;">
            <mat-form-field class="form-group ratioinput no-bottom-margin" subscriptSizing="dynamic">
                <input matInput required [disabled]="readOnly"
                    type="text" inputmode="decimal" class="ratioinput"
                    placeholder="Ratio" i18n-placeholder="blends$$Ratio@@Ratio"
                    name="ratio-{{$index}}"
                    [ngModel]="(!ing.ratio ? '' : (ing.ratio * 100 | number:'1.0-4')) + '%'"
                    (change)="checkChangesRatio('ratio', ing.ratio, $event.target['value'], 4, $index)"
                    [ngClass]="{'warn-amount': ing.ratio === 0 || !utils.percentCorrect(blend)}">
            </mat-form-field>
            (<mat-form-field class="form-group fixed-ratioinput-num no-bottom-margin" subscriptSizing="dynamic">
                <input matInput [disabled]="readOnly" name="ratio-{{$index}}-num"
                    type="text" inputmode="numeric" class="ratioinput"
                    [ngModel]="num[$index]"
                    (change)="fractionChanged($index, true, $event.target['value'])">
            </mat-form-field>
            <span style="padding-bottom: 6px">/</span>
            <mat-form-field class="form-group fixed-ratioinput-denom no-bottom-margin" subscriptSizing="dynamic">
                <input matInput [disabled]="readOnly" name="ratio-{{$index}}-denom"
                    type="text" inputmode="numeric" class="ratioinput"
                    [ngModel]="denom[$index]"
                    (change)="fractionChanged($index, false, $event.target['value'])">
            </mat-form-field><span style="margin-right: 10px;">)</span>
        </div>
        <div class="wide-beans-input" style="position: relative;">
            <mat-form-field class="coffeeinput" subscriptSizing="dynamic">
                <mat-select required [disabled]="readOnly" panelWidth="null"
                    name="coffee-{{$index}}" panelClass="twoLineOption"
                    placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                    [(ngModel)]="ing.coffee" [compareWith]="utils.compareObjectsFn"
                    (selectionChange)="ingredientChanged($index)">
                    @if (ing.coffee) {
                    <mat-select-trigger class="twoLineOption ellipsed">
                        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: ing.coffee}"></ng-container>
                    </mat-select-trigger>
                    }
                    @if (!coffees) {
                    <mat-spinner></mat-spinner>
                    } @else {
                    @if (getCoffees($index); as mycoffees) {
                    @if (mycoffees.length >= 5) {
                    <mat-option>
                        <ngx-mat-select-search ngModel name="coffeeSelect"
                            (ngModelChange)="filteredCoffees[$index] = utils.filterObjects($event, mycoffees)"
                            placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                            noEntriesFoundLabel="" i18n-noEntriesFoundLabel="generic$no matches found using the given filter@@no match for filter">
                        </ngx-mat-select-search>
                    </mat-option>
                    }
                    @for (coffee of filteredCoffees[$index]; track coffee?._id) {
                    <mat-option [value]="coffee">
                        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coffee}"></ng-container>
                    </mat-option>
                    }
                    }
                    }
                </mat-select>
            </mat-form-field>
            @if (getAmountStr($index); as available) {
            <div class="comment" style="position: absolute; bottom: -35px; left: 0;">
                <span i18n="transaction$$label before showing how much of those beans are available in this store@@Amount of beans available">Available:</span>
                <span class="spaceleft">{{available.pre}}{{available.value | number:'1.0-3'}}{{available.post}}</span>
            </div>
            }
        </div>
        @if (!coffeesLoaded) {
        <mat-spinner diameter="20" style="margin-left: 8px;"></mat-spinner>
        }
        @if (coffeesLoaded && !readOnly && blend.ingredients.length > 1) {
        <button mat-icon-button type="button" class="tertiary-element" style="vertical-align: middle;"
            (click)="deleteIngredient($index)">
            <mat-icon class="smallish">delete</mat-icon>
        </button>
        }
    </div>
    }
</div>
}
@if (!readOnly) {
<button mat-icon-button type="button" class="primary-element" (click)="addIngredient()">
    <mat-icon>add</mat-icon>
</button>
}