import { HttpRequest, HttpEvent, HttpHandlerFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { version } from 'src/environments/version';
import { nanoid } from 'nanoid'
import { inject, LOCALE_ID } from '@angular/core';

export function webclientInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const locale = inject(LOCALE_ID);
    if (req.url.indexOf('.php') === -1) {
        const webstr = `web_${version.latestWebVersion}`;
        if (req.method === 'GET' || req.method === 'DELETE') {
            req = req.clone({
                setParams: {
                    _cl: webstr,
                }
            });
        } else {
            if (req.method === 'POST') {
                req = req.clone({
                    setHeaders: {
                        'Idempotency-Key': nanoid(),
                    }
                });
            }
            if (!(req.body instanceof FormData)) {
                const newBody = Object.assign({}, req.body) as { _cl: string };
                newBody._cl = webstr;
                req = req.clone({
                    body: newBody,
                });
            } else {
                (req.body as FormData).append('_cl', webstr);
                // newBody = new FormData();
                // (request.body as FormData).forEach((value, key) => newBody.append(key, value));
            }
        }
        req = req.clone({
            setHeaders: {
                'Content-Language': locale,
            }
        });
    }

    return next(req);
}
