<div class="split-canvas">
    <as-split direction="vertical" [gutterSize]="15" (dragEnd)="onDragEnd($event)" (gutterClick)="onGutterClick($event)" [gutterDblClickDuration]="0" [useTransition]="true">
        <as-split-area [size]="splitSizes[0]">
            <div>
                @if (!readOnly) {
                <div class="actionbar">
                    <div class="actionbar-inner">
                        @if (stores) {
                        <button [disabled]="!stocks || editMode >= 0" data-test="bttn_add"
                            (click)="add()" mat-raised-button type="button" class="add-button action-button">
                            <mat-icon>add</mat-icon>
                        </button>
                        }
                        <span style="white-space: nowrap">
                            @if (stores?.length >= 1) {
                            <button [disabled]="!stocks || editMode >= 0"
                                (click)="purchase()" data-test="bttn_purchase" mat-raised-button type="button" class="action-button"
                                i18n="transaction$$purchase button@@purchase">Purchase
                            </button>
                            <button [disabled]="!stocks || editMode >= 0"
                                (click)="sell()" data-test="bttn_sell" mat-raised-button type="button" class="action-button"
                                i18n="transaction$$sell button@@sell">Sell
                            </button>
                            @if (stores?.length > 1) {
                            <button [disabled]="!stocks || editMode >= 0"
                                (click)="transfer()" data-test="bttn_transfer" mat-raised-button type="button" class="action-button"
                                i18n="transaction$$transfer button@@transfer">Transfer
                            </button>
                            }
                            <button [disabled]="!stocks || editMode >= 0"
                                (click)="correct()" data-test="bttn_correct" mat-raised-button type="button" class="action-button"
                                i18n="transaction$$correct button@@correct">Correct
                            </button>
                            }
                        </span>
                    </div>
                </div>
                }

                @if (!stores) {
                <div class="wait-outer">
                    <mat-spinner data-test="spnnr_main" class="spinner"></mat-spinner>
                </div>
                }

                @if (!readOnly && !stores?.length) {
                <div>
                    <p class="main-note" i18n="generic$$no items found, hint at how to add one@@nothing to show plus hint, add with button">Currently, there is nothing to show. Add using the button at the top!</p>
                </div>
                }

                @if (readOnly) {
                <div class="readonly-spacer"></div>
                }
                @if (stores) {
                <div class="flexContainer">
                    @for (store of stores; track store?._id; let storeIdx = $index) {
                    <mat-card appearance="outlined" class="store-card">
                        <mat-card-header>
                            @if (store._id || store.internal_hr_id) {
                            <div mat-card-avatar>
                                @if (store.logo) {
                                <img class="avatar-icon" src="{{store.logo}}" (click)="changeImage($index, true)" (keydown.enter)="changeImage($index, true); $event.preventDefault()" role="button" [tabindex]="0" alt="a picture of the storage location">
                                }
                                @if (!store.logo) {
                                <mat-icon class="avatar-icon-generic" [svgIcon]="'store' + (isDarkmode ? '-dark' : '')" (click)="changeImage($index, true)"></mat-icon>
                                }
                            </div>
                            }
                            <mat-card-title>
                                @if (editMode !== $index) {
                                <span>
                                    <app-organicicon [isOrganic]="isOrganic[$index]" [size]="15"></app-organicicon>
                                    {{store.label}}
                                </span>
                                } @else {
                                <span>{{storecopy.label}}</span>
                                }
                            </mat-card-title>
                            @if (store._id || store.internal_hr_id) {
                            <mat-card-subtitle>
                                <span class="hr_id" #hridElem>{{store.hr_id}}</span>
                                @if (stockSums && stockSums[$index]) {
                                <span>&nbsp;<span i18n="stores$$L1000 _with 10kg of beans_, subtitle@@store with xkg of beans">with {{getAmount(stockSums[$index])}} of beans</span></span>
                                }
                                @if (stockValues?.[$index]) {
                                @if (stockValues[$index] >= 1) {
                                <span>,&nbsp;{{stockValues[$index] | currency:currency:'symbol-narrow':'1.0-0'}}</span>
                                }
                                }
                                @if (!readOnly) {
                                <a data-test="lnk_reload" (click)="reloadStore($index, stores[$index], true)" (keydown.enter)="reloadStore($index, stores[$index], true); $event.preventDefault()" role="button" [tabindex]="0" class="littlespaceleft">
                                    <mat-icon class="smallesticon greyicon">update</mat-icon>
                                </a>
                                }
                            </mat-card-subtitle>
                            }
                        </mat-card-header>
                        @if (store.image !== null && (store._id || store.internal_hr_id)) {
                        <div class="lowheight">
                            <img data-test="mg_main" mat-card-image class="store-image" [src]="store.image ? store.image : 'assets/images/default_store.jpg'" (click)="changeImage($index)" (keydown.enter)="changeImage($index); $event.preventDefault()" role="button" [tabindex]="0"
                                onerror="if (this.src !== 'assets/images/default_store.jpg') this.src = 'assets/images/default_store.jpg';" alt="a picture of the storage location">
                        </div>
                        }
                        <mat-card-content>
                            @if (!readOnly && editMode === $index) {
                            <div>
                                <form id="form" name="form" (ngSubmit)="f.form.valid && save()" #f="ngForm">
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="generic$$Name@@Name">Name</mat-label>
                                        <input matInput required autofocus type="text" name="label"
                                            placeholder="Name" i18n-placeholder="generic$$Name@@Name"
                                            [(ngModel)]="storecopy.label">
                                    </mat-form-field>
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$address@@Address">Address</mat-label>
                                        <input matInput type="text" name="address"
                                            placeholder="Address" i18n-placeholder="contacts$$address@@Address"
                                            [(ngModel)]="storecopy.address">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$street@@street">Street</mat-label>
                                        <input matInput type="text" name="street"
                                            placeholder="Street" i18n-placeholder="contacts$$street@@street"
                                            [(ngModel)]="storecopy.street">
                                    </mat-form-field>
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$Postal Code@@Postal Code">Postal Code</mat-label>
                                        <input matInput type="text" name="Postal Code"
                                            placeholder="Postal Code" i18n-placeholder="contacts$$Postal Code@@Postal Code"
                                            [(ngModel)]="storecopy.zip_code">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$City@@city">City</mat-label>
                                        <input matInput type="text" name="city"
                                            placeholder="City" i18n-placeholder="contacts$$City@@city"
                                            [(ngModel)]="storecopy.city">
                                    </mat-form-field>
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$region@@region">Region</mat-label>
                                        <input matInput type="text" name="region"
                                            placeholder="Region" i18n-placeholder="contacts$$region@@region"
                                            [(ngModel)]="storecopy.region">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$subregion@@subregion">Subregion</mat-label>
                                        <input matInput type="text" name="subregion"
                                            placeholder="Subregion" i18n-placeholder="contacts$$subregion@@subregion"
                                            [(ngModel)]="storecopy.subregion">
                                    </mat-form-field>
                                    <mat-form-field class="form-group">
                                        <mat-label i18n="contacts$$country@@Country">Country</mat-label>
                                        <input matInput name="country" id="country" type="text"
                                            data-test="npt_country" autocomplete="new-password"
                                            placeholder="Country" i18n-placeholder="contacts$$country@@Country"
                                            (input)="changeCountryFilter($event?.target?.['value'])"
                                            (focus)="changeCountryFilter($event?.target?.['value'])"
                                            [ngModel]="tr.anslate(storecopy.country || '')"
                                            [matAutocomplete]="autoCompleteCountry"
                                            (blur)="checkCountry($event?.target?.['value'])"
                                            [ngClass]="{'has-error': storecopy.country === 'UNKNOWN'}">
                                        <mat-autocomplete #autoCompleteCountry="matAutocomplete" (optionSelected)="checkCountry($event?.option?.value)">
                                            @for (country of filteredCountries; track country) {
                                            <mat-option [value]="country">{{country}}</mat-option>
                                            }
                                        </mat-autocomplete>
                                    </mat-form-field><br />
                                    <!-- coordinates -->
                                    <div class="inner-action-row" [ngClass]="{'separate-buttons': storecopy._id || storecopy.internal_hr_id}">
                                        @if (storecopy._id || storecopy.internal_hr_id) {
                                        <button data-test="bttn_photo"
                                            (click)="changeImage($index)" mat-raised-button type="button" class="action-button otherside-button">
                                            <mat-icon>{{storecopy?.image ? 'insert_photo' : 'add_photo_alternate'}}</mat-icon>
                                        </button>
                                        }
                                        <div>
                                            <button (click)="cancel()" data-test="bttn_cancel" mat-button type="button" class="cancel-button">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                            @if (!isSaving) {
                                            <button [disabled]="saveDisabled || isSaving" mat-raised-button type="submit" class="action-button" form="form" data-test="bttn_done">
                                                <mat-icon>done</mat-icon>
                                            </button>
                                            } @else {
                                            <button disabled mat-raised-button class="action-button spinner-button">
                                                <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                            } @else {
                            @if (stocks?.[$index]?.length) {
                            <app-store-stock
                                [currentUser]="currentUser"
                                [readOnly]="readOnly"
                                [store]="store"
                                [stocks]="stocks[$index]"
                                [allStocks]="stocks">
                            </app-store-stock>
                            }
                            <app-modification-date [element]="store" [user]="currentUser"></app-modification-date>
                            <div>
                                @if (store.street) {
                                <span>{{store.street}}</span>
                                }
                                @if (store.address) {
                                <span>@if (store.street) {
                                    <span>, </span>
                                    }{{store.address}}</span>
                                }
                                @if (store.zip_code) {
                                <span>@if (store.street || store.address) {
                                    <span>, </span>
                                    }{{store.zip_code}} </span>
                                }
                                @if (store.city) {
                                <span>@if (!store.zip_code && (store.street || store.address)) {
                                    <span>, </span>
                                    }{{store.city}}</span>
                                }
                            </div>
                            <div>
                                @if (store.subregion) {
                                <span>{{store.subregion}}</span>
                                }
                                @if (store.region) {
                                <span>@if (store.subregion) {
                                    <span>, </span>
                                    }{{store.region}}</span>
                                }
                                @if (store.country) {
                                <span>@if (store.subregion || store.region) {
                                    <span>, </span>
                                    }{{tr.anslate(store.country || '')}}</span>
                                }
                            </div>
                            <div style="width: 100%">
                                <app-ap-file
                                    [files]="store.files"
                                    [belongsTo]="store.internal_hr_id"
                                    belongsToType="STORES"
                                    (filesChanged)="filesChanged($index, $event)"
                                    [readOnly]="readOnly">
                                </app-ap-file>
                            </div>
                            <mat-form-field class="store-notesdiv no-height-change">
                                <mat-label i18n="generic$$arbitrary notes@@Notes">Notes</mat-label>
                                <textarea [disabled]="readOnly" matInput
                                    data-test="npt_notes" name="notes-{{$index}}"
                                    [(ngModel)]="notes[$index]"
                                    (blur)="saveNotes($index)"
                                    (ngModelChange)='notesChanged[$index]?.next($event)'
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5">
                                </textarea>
                            </mat-form-field>
                            @if (!nrTransactions[storeIdx] || 1000 > nrTransactions[storeIdx]) {
                            <app-store-transactions
                                [currentUser]="currentUser"
                                [readOnly]="readOnly"
                                [store]="store"
                                (allLoaded)="transactionsLoaded(storeIdx, $event)">
                            </app-store-transactions>
                            }
                            }
                        </mat-card-content>
                        @if (!readOnly && (0 > editMode || editMode !== $index)) {
                        <mat-card-actions align="end">
                            @if (!checkingIfDeletable) {
                            <button [disabled]="editMode >= 0" mat-raised-button type="button"
                                (click)="delete(store, $index)"
                                data-test="bttn_delete" class="edit-button">
                                <mat-icon>delete</mat-icon>
                            </button>
                            } @else {
                            <button disabled mat-raised-button class="action-button spinner-button">
                                <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                            </button>
                            }
                            <button [disabled]="editMode >= 0" mat-raised-button type="button"
                                (click)="edit(store, $index)"
                                data-test="bttn_edit" class="action-button">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-card-actions>
                        }
                    </mat-card>
                    }
                </div>
                }
            </div>
            <div class="enlargepage"></div>
        </as-split-area>

        <as-split-area [size]="splitSizes[1]">
            @if (splitSizes[1] > 5) {
            <app-store-graphs [stores]="stores"></app-store-graphs>
            }
        </as-split-area>
    </as-split>
</div>