import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Notification } from 'src/app/models/Notification';
import { Reminder } from 'src/app/models/Reminder';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
    ) {
    }

    getNotifications(max: number): Observable<{ success: boolean, result: Notification[], error: string }> {
        this.logger.debug(`getNotifications(${max})`);
        return this.http.get<{ success: boolean, result: Notification[], error: string }>
            (environment.BASE_API_URL + environment.SUB_API_URL + '/notifications', { params: { html: 1, limit: max } } );
    }

    setNotificationSeen(notif: Notification): Observable<{ success: boolean, result: string, error: string }> {
        this.logger.debug(`setNotificationSeen ${notif?.hr_id}`);
        return this.http.put<{ success: boolean, result: string, error: string }>
            (environment.BASE_API_URL + environment.SUB_API_URL + `/notifications/seen/${notif?.hr_id}`, { date: DateTime.now() });
    }

    snoozeNotification(notif: Notification, date: DateTime): Observable<{ success: boolean, result: string, error: string }> {
        this.logger.debug(`snoozeNotification ${notif?.hr_id}`);
        return this.http.put<{ success: boolean, result: string, error: string }>
            (environment.BASE_API_URL + environment.SUB_API_URL + `/notifications/snooze/${notif?.hr_id}`, { date: date.valueOf().toString() });
    }

    setReminderDone(rem: Reminder, doneDate: DateTime): Observable<{ success: boolean, result: Reminder, error: string }> {
        this.logger.debug(`setReminderDone ${rem?.hr_id} ${doneDate}`);
        const prms = new HttpParams().set('doneDate', doneDate.valueOf().toString());
        return this.http.put<{ success: boolean, result: Reminder, error: string }>
            (environment.BASE_API_URL + environment.SUB_API_URL + `/notifications/reminder/done/${rem?.hr_id}`, { }, { params: prms });
    }
}
