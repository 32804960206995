import { NgClass, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Enumerations } from 'src/app/models/Enumerations';

@Component({
    selector: 'app-organicicon',
    templateUrl: './organicicon.component.html',
    imports: [NgStyle, NgClass],
    standalone: true,
    providers: []
})
export class OrganicIconComponent {
    
    // either use isOrganic directly or give certInfo
    @Input() isOrganic = false;

    // either use isOrganic directly or give certInfo
    private _certInfo = 0;
    get certInfo(): number { return this._certInfo; }
    @Input() set certInfo(ci: number) {
        this._certInfo = ci;
        this.isOrganic = ci % (2 * Enumerations.CertificationTypes.ORGANIC) >= Enumerations.CertificationTypes.ORGANIC;
    }
    @Input() strikethrough = false;
    // if true, the icon will be grey and no pointer cursor is shown
    @Input() inactive = false;
    // the size of the icon
    @Input() size = 13;
    // additional margin to the right
    @Input() rightMargin = 0;
    // additional margin to the left
    @Input() leftMargin = 0;
    // additional bottom margin
    @Input() bottomMargin = 0;
    // additional top margin
    @Input() topMargin = 0;
    // should be 'middle' or 'bottom'
    @Input() verticalAlign = 'middle';
    // if true, uses "size" space if no icon is shown
    @Input() spacer = false;
}
