<mat-form-field class="form-group datesize" data-test="npt_date">
    <mat-label i18n="generic$$date placeholder or header@@Date">Date</mat-label>
    <input matInput [required]="dateRequired" [disabled]="disabled" name="date"
        [matDatepicker]="myDatepicker"
        placeholder="Date" i18n-placeholder="generic$$date placeholder or header@@Date"
        [value]="value"
        (dateChange)="dateInput($event.value)">
    <mat-datepicker-toggle matIconPrefix [for]="myDatepicker"></mat-datepicker-toggle>
    <mat-datepicker #myDatepicker></mat-datepicker>
</mat-form-field>
<mat-form-field class="form-group timesize" data-test="npt_timeHour">
    <!-- <mat-label i18n="generic$$time placeholder or header@@time">Time</mat-label> -->
    <input matInput [disabled]="disabled" name="dateHour"
        type="text" inputmode="numeric" min="0" max="23" step="1"
        class="noarrows" style="text-align: right"
        placeholder="h" i18n-placeholder="generic$$short for hours@@h"
        [value]="hour | number:'2.0-0'" (change)="hourChanged($event.target['value'])">
</mat-form-field>:<mat-form-field class="form-group timesize" data-test="npt_timeMinute">
    <input matInput [disabled]="disabled" name="dateMinute"
        type="text" inputmode="numeric" min="0" max="59" step="1" class="noarrows"
        placeholder="h" i18n-placeholder="generic$$short for hours@@h"
        [value]="minute | number:'2.0-0'" (change)="minuteChanged($event.target['value'])">
</mat-form-field><br />