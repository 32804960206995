import { User } from './User';
import { Coffee } from './Coffee';
import { Location } from './Location';
import { Roast, RoastTemplate } from './Roast';
import { Blend } from './Blend';

export class RoastScheduledItem {
    _id?: string;
    created_at?: Date;
    updated_at?: Date;
    created_by?: User;
    updated_by?: User;
    date?: string;
    amount?: number;
    loss?: number; // in %
    machine?: string;
    user?: string; // _id
    nickname?: string;
    count?: number;
    title?: string;
    coffee?: Coffee;
    blend?: Blend;
    location?: Location;
    template?: (RoastTemplate | Roast) & { roast_id?: string };
    note?: string;
    synced?: boolean;
    roasts?: Roast[];
    // only in web client
    itemAmount?: string;
    marginRightForDelete?: string;
    tooltip?: string;
    info1?: string;
    info2?: string;
    blendInfo?: string;
    selected?: boolean;
    roasted?: number;
    isPostBlend?: boolean;
}