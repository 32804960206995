import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TestService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getRoastedCoffee(): Observable<{ success: boolean, result: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/roastedcoffee';
        return this.http.get<{ success: boolean, result: number, error: string }>(url);
    }

    getSomeTest(): Observable<{ success: boolean, result: unknown, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/test/sometest';
        return this.http.get<{ success: boolean, result: unknown, error: string }>(url);
    }
}
