import { RegisterComponent } from 'src/app/modules/frame/register/register.component';
import { ResetpasswordComponent } from 'src/app/modules/frame/login/resetpassword.component';
import { ApToolbarModule } from 'src/app/modules/ui/ap-toolbar.module';
import { WelcomeComponent } from './welcome.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrameRoutingModule } from './frame-routing.module';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { InvitedComponent } from './login/invited.component';

@NgModule({
    declarations: [
        LoginComponent,
        InvitedComponent,
        LogoutComponent,
        ResetpasswordComponent,
        RegisterComponent,
        WelcomeComponent,
    ],
    imports: [
        CommonModule,
        FrameRoutingModule,
        MyMaterialModule,
        FormsModule,
        ApToolbarModule,
    ],
    exports: [
    ]
})
export class FrameModule { }
