@if (currentBlend) {
<ng-template #coffeeString let-coffee="coffee">
    @if (coffee?.hr_id) {
    <span>{{coffee.hr_id}}</span>
    }
    <app-organicicon [isOrganic]="utils.isOrganicCoffee(coffee)" [size]="7" [leftMargin]="3"></app-organicicon>
    @if (coffee?.origin) {
    <span> {{tr.anslate(coffee.origin)}}</span>
    }
    @if (coffee?.yearLabel) {
    <span> {{coffee.yearLabel}}</span>
    }
    @if (coffee?.label && (coffee.origin || coffee.yearLabel)) {
    <span>,</span>
    }
    @if (coffee?.label) {
    <span> {{coffee.label}}</span>
    }
</ng-template>
<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>

<div class="blends-div">
    @for (ing of currentBlend.ingredients; track $index) {
    <div>
        <!-- <div style="display: flex; margin-bottom: 10px;" [ngStyle]="{'align-items': store && showAmounts ? 'center' : 'flex-end'}"> -->
        <div style="display: flex; flex-wrap: wrap; margin-bottom: 10px; align-items: start" class="roast-blend-edit-div">
            <div class="flex-baseline" style="margin-top: 12px">
                <mat-form-field class="form-group ratioinput ratioinput-small" subscriptSizing="dynamic">
                    <mat-label i18n="blends$$Ratio@@Ratio">Ratio</mat-label>
                    <input matInput required [disabled]="readOnly || reconciled"
                        class="ratioinput ratioinput-small" type="text"
                        placeholder="Ratio" i18n-placeholder="blends$$Ratio@@Ratio"
                        name="ratio-{{$index}}" [ngModel]="(!ing.ratio ? '' : (ing.ratio * 100 | number:'1.0-3')) + '%'"
                        (change)="checkChangesRatio(ing, 'ratio', ing.ratio, $event.target['value'], 4, $index)"
                        [ngClass]="{'warn-amount': ing.ratio === 0 || !utils.percentCorrect(currentBlend)}">
                </mat-form-field>
                (<mat-form-field class="form-group fixed-ratioinput-num" subscriptSizing="dynamic">
                    <input matInput [disabled]="readOnly || reconciled" type="number" class="ratioinput"
                        name="ratio-{{$index}}-num" [ngModel]="num[$index]"
                        (change)="fractionChanged($index, true, $event.target['value'])">
                </mat-form-field>
                <span style="padding-bottom: 6px">/</span>
                <mat-form-field class="form-group fixed-ratioinput-denom" subscriptSizing="dynamic">
                    <input matInput [disabled]="readOnly || reconciled" type="number" class="ratioinput"
                        name="ratio-{{$index}}-denom" [ngModel]="denom[$index]"
                        (change)="fractionChanged($index, false, $event.target['value'])">
                </mat-form-field><span style="margin-right: 10px;">)</span>
                @if (amount) {
                <mat-form-field class="form-group ratioinput" style="width: 70px;" subscriptSizing="dynamic">
                    <mat-label i18n="graphs$$graph label@@">Weight</mat-label>
                    <input matInput [disabled]="readOnly || reconciled" name="amount-{{$index}}"
                        class="ratioinput" type="text"
                        [ngModel]="(!ing.ratio || !amount ? '' : (ing.ratio * amount | number:'1.0-3')) + mainUnit"
                        (change)="checkChangesRatio(ing, 'ratio', ing.ratio, $event.target['value'], 3, $index, true, false, round(amount * ing.ratio * 1000) > round((getAmountStr($index)?.value ?? 0) * 1000))">
                </mat-form-field>
                }
            </div>
            <div>
                <mat-form-field class="coffeeinput" style="margin-bottom: 5px;" subscriptSizing="dynamic">
                    <mat-label i18n="generic$$Beans@@Beans">Beans</mat-label>
                    <mat-select required [disabled]="readOnly || reconciled"
                        name="coffee-{{$index}}" panelClass="twoLineOption"
                        hideSingleSelectionIndicator="true" panelWidth="null"
                        [compareWith]="utils.compareObjectsFn"
                        placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                        [(ngModel)]="currentBlend.ingredients[$index].coffee"
                        (selectionChange)="ingredientChanged($index)">
                        @if (currentBlend?.ingredients?.[$index]) {
                        <mat-select-trigger class="twoLineOption ellipsed">
                            <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: currentBlend.ingredients[$index].coffee}"></ng-container>
                        </mat-select-trigger>
                        }
                        @if (!coffees) {
                        <mat-spinner></mat-spinner>
                        } @else {
                        @if (getCoffees($index); as mycoffees) {
                        @if (mycoffees.length >= 5) {
                        <mat-option>
                            <ngx-mat-select-search ngModel (ngModelChange)="filteredCoffees[$index] = utils.filterObjects($event, mycoffees)" name="coffeeSelect"
                                placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                noEntriesFoundLabel="" i18n-noEntriesFoundLabel="generic$no matches found using the given filter@@no match for filter">
                            </ngx-mat-select-search>
                        </mat-option>
                        }
                        @for (coffee of filteredCoffees[$index]; track coffee?._id) {
                        <mat-option [value]="coffee">
                            <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coffee}"></ng-container>
                        </mat-option>
                        }
                        }
                        }
                    </mat-select>
                </mat-form-field>
                @if (store && showAmounts && amountStr[$index]) {
                <div class="av-comment">
                    <span [ngClass]="{'warn-amount': !amountStr[$index].enough}" i18n="transaction$$label before showing how much of those beans are available in this store@@Amount of beans available">Available:</span>
                    <span class="spaceleft" [ngClass]="{'warn-amount': !amountStr[$index].enough}">{{amountStr[$index].pre}}{{amountStr[$index].value | number:'1.0-3'}}{{amountStr[$index].post}}</span>
                </div>
                }
            </div>
            @if (!readOnly && !reconciled && currentBlend.ingredients.length > 1) {
            <button mat-icon-button
                type="button" class="tertiary-element" (click)="deleteIngredient($index)" style="align-self: center; padding: 0">
                <mat-icon class="smallish">delete</mat-icon>
            </button>
            }
        </div>
        @if (!readOnly && store && showAmounts && !amountStr[$index]?.enough && ing.replace_coffee) {
        <div class="replace-line">
            <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/updates/replace-blend-ingredients/'" rel="noopener" style="padding-right: 0;margin: 0 5px 0 0;" target="_blank">
                <mat-icon class="helpicon">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
            </a>
            @if (amountStr[$index]?.value > Constants.EPSILON) {
            <span><span i18n="blends$$Fill with: beans X; allows selecting replacement beans if roasting a blend and one ingredient is nearly out of stock@@Fill with">Fill with</span>:
                &nbsp;<ng-container *ngTemplateOutlet="coffeeString; context:{coffee: ing.replace_coffee}"></ng-container>
                <mat-icon (click)="useReplaceCoffee($index)" class="primary-element" class="smallish replace-icon">swap_horiz</mat-icon><br />
            </span>
            }
            <span i18n="blends$$Replace by: beans X; allows selecting replacement beans if roasting a blend and one ingredient is out of stock@@Replace by">Replace by</span>:
            &nbsp;<ng-container *ngTemplateOutlet="coffeeString; context:{coffee: ing.replace_coffee}"></ng-container>
            <mat-icon (click)="useReplaceCoffee($index, true)" class="primary-element" class="smallish replace-icon">swap_horiz</mat-icon>
        </div>
        }
    </div>
    }
</div>
}
@if (!readOnly && !reconciled) {
<button mat-icon-button class="primary-element" type="button" (click)="addIngredient()">
    <mat-icon>add</mat-icon>
</button>
}