/* eslint-disable @typescript-eslint/no-explicit-any */
 
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Router } from '@angular/router';
import { DecimalPipe, NgStyle } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';

import { DateTime } from 'luxon';
import 'chartjs-adapter-luxon';
import 'chartjs-plugin-stacked100';
import { Chart, LineController, LineElement, LinearScale, PointElement, Title, Legend, Tooltip, TooltipItem, ChartConfiguration, ChartEvent } from 'chart.js';
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, Legend, Tooltip);

@Component({
    standalone: true,
    imports: [BaseChartDirective, NgStyle],
    selector: 'app-roastblend-graph',
    templateUrl: './roastblend-graph.component.html',
    providers: [DecimalPipe]
})

export class RoastblendGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private userService: UserService,
        private decimalPipe: DecimalPipe,
        private utils: Utils,
        private router: Router
    ) { }

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    // needed to have the correct order of ngOnInit and newData setter
    inited = false;
    updData: any;

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currentUser: UserType;
    @Input() isDarkmode = false;
    @Input() showstockfrom: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';
    @Input() startDate: DateTime = undefined;
    @Input() endDate: DateTime = undefined;
    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }

    _small = false;
    @Input() set isSmall(is: boolean) {
        this._small = is;
        if (this.chart) {
            this.options.plugins.legend.display = !is;
            this.options.scales.y.display = !is;
            this.chart.render();
        }
    }

    canvasHeight = '350px';

    haveData = false;

    data: ChartConfiguration<'line'>['data'];

    weight_factor = 1; // if max sweights are large (>2000kg or >4000lbs) the weight data in sets is divided by weight_factor to make ticks more readable
    // weight_factor is 1 if data is in this.mainUnit, 1000 if this.mainUnit is 'kg' and data values are in 't' or
    // weight_factor is 2000 if this.mainUnit is 'lbs' and data values are in 't'
    tickUnit: UnitSystemType | 't' = this.mainUnit; // the label used to indicate the weight unit of axis ticks (the string 'kg', 'lbs', or 't')

    // plugins = [ChartRough];

    showStock = !this.endDate || this.endDate.hasSame(DateTime.now(), 'day');

    options: ChartConfiguration<'bar'>['options'] & { plugins: { roughness?: any } } = {
        indexAxis: 'y' as const,
        plugins: {
            roughness: {
                disabled: true,
            },
            title: {
                display: true,
                text: 'Blends', // translated below
                position: 'top' as const,
            },
            legend: {
                display: true,
                position: 'bottom' as const,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                mode: 'index' as const,
                position: 'nearest' as const,
                displayColors: false,
                backgroundColor: 'rgba(116,52,66,0.85)',
                titleColor: 'rgba(255, 255, 255, 0)',
                bodyColor: 'rgba(0, 0, 0, 0)',
                callbacks: {
                    title: (tooltipItems: TooltipItem<'bar'>[]) => {
                        const index = tooltipItems[0].dataIndex;
                        const title = this.data.labels[index];
                        if (this.showStock && this.showstockfrom === 'all') {
                            // we show the total amount only if chart is showing data over all stores, as
                            // the total if filtered to a subsect of the stores can be confusing showing amounts higher than ever available in the selected stores
                            // as the roasted amount (unfiltered over all stores) is added.
                            const total = (this.data.datasets[0]['data_raw'][index] + this.data.datasets[1]?.['data_raw']?.[index]) || 0;
                            const total_formated = this.utils.formatAmountForPipe(total, undefined, this.currentUser.unit_system);
                            const total_formated_full = this.decimalPipe.transform(total_formated.value, '1.0-1') + total_formated.post;
                            return `${title}, ${total_formated_full}`;
                        } else {
                            return `${title}`;
                        }
                    },
                    beforeBody: (tooltipItems: TooltipItem<'bar'>[]) => {
                        if (tooltipItems.length) {
                            const tooltipItem = tooltipItems[0];
                            const index = tooltipItem.dataIndex;
                            const roasted = this.data.datasets[0].data[index] as number;
                            const in_stock = this.data.datasets[1]?.data?.[index] as number;
                            if (roasted > 0 || (this.showStock && in_stock > 0)) {
                                const str = [];
                                // same as 'roasted' but not potentially converted to lbs:
                                const roasted_raw = this.data.datasets[0]['data_raw'][index];
                                const roasted_percent = this.showStock ? Math.round(roasted / ((roasted + in_stock) / 100)) : undefined;
                                const count = this.data.datasets[0]['counts'][index];
                                const roastedPerDay = this.data.datasets[0]['roastedPerDay'][index];
                                const roasted_formated = this.utils.formatAmountForPipe(roasted_raw, undefined, this.currentUser.unit_system);
                                const roasted_formated_full = this.decimalPipe.transform(roasted_formated.value, '1.0-1') + roasted_formated.post;
                                let batches = '';
                                if (count > 1) {
                                    batches = `${count} ${this.tr.anslate('batches')}`;
                                } else if (count === 1) {
                                    batches = `${this.tr.anslate('one batch')}`;
                                }
                                str.push(`${this.tr.anslate('roasted')}: ${roasted_formated_full} (${roasted_percent ? `${roasted_percent}%, ` : ''}${batches})`);
                                if (this.showStock) {
                                    let todo = '';
                                    if (count > 0) {
                                        const batch_size = roasted / count;
                                        if (batch_size > 0) {
                                            const estimate = Math.round(in_stock / batch_size);
                                            if (estimate === 1) {
                                                todo = `, ${this.tr.anslate('one batch')}`;
                                            } else if (estimate > 1) {
                                                todo = `, ~${estimate} ${this.tr.anslate('batches')}`;
                                            }
                                        }
                                    }
                                    const in_stock_percent = Math.round(100 - roasted_percent);
                                    // same as 'in_stock' but not potentially converted to lbs:
                                    const in_stock_raw = this.data.datasets[1]?.['data_raw']?.[index];
                                    const in_stock_formated = this.utils.formatAmountForPipe(in_stock_raw, undefined, this.currentUser.unit_system);
                                    const in_stock_formated_full = this.decimalPipe.transform(in_stock_formated.value, '1.0-1') + in_stock_formated.post;
                                    str.push(`${this.tr.anslate('stored')}: ${in_stock_formated_full} (${in_stock_percent}%${todo})`);

                                    const replacements = this.data.datasets[2]?.data?.[index] as number;
                                    if (replacements > 0) {
                                        // same as 'replacements' but not potentially converted to lbs:
                                        const replacements_raw = this.data.datasets[2]?.['data_raw']?.[index];
                                        const replacements_formated = this.utils.formatAmountForPipe(replacements_raw, undefined, this.currentUser.unit_system);
                                        const replacements_formated_full = this.decimalPipe.transform(replacements_formated.value, '1.0-1') + replacements_formated.post;
                                        str.push(`  +${replacements_formated_full} ${this.tr.anslate('with replacements')}`);
                                    }
                                }

                                const per_week = roastedPerDay * 7;
                                if (per_week > 0) {
                                    const per_week_formated = this.utils.formatAmountForPipe(per_week, undefined, this.currentUser.unit_system);
                                    const per_week_formated_full = this.decimalPipe.transform(per_week_formated.value, '1.0-1') + per_week_formated.post;
                                    if (per_week_formated.value >= 1) { // we don't render amounts smaller than 1
                                        const weeks_left = Math.round(in_stock / per_week);
                                        if (weeks_left < 53) { // we don't render more than 52 weeks (a year)
                                            let weeks_left_str = '';
                                            if (this.showStock) {
                                                if (weeks_left > 1) {
                                                    weeks_left_str = ` (${this.tr.anslate('still ~{{weeks_left}} weeks', { weeks_left: weeks_left }).trim()})`;
                                                } else if (weeks_left === 1) {
                                                    weeks_left_str = ` (${this.tr.anslate('still about one week')})`;
                                                }
                                            }
                                            str.push(`${this.tr.anslate('weekly')}: ${per_week_formated_full}${weeks_left_str}`);
                                        }
                                    }
                                }

                                const per_month = roastedPerDay * 30;
                                if (per_month > 0) {
                                    const per_month_formated = this.utils.formatAmountForPipe(per_month, undefined, this.currentUser.unit_system);
                                    if (per_month_formated.value >= 1) { // we don't render amounts smaller than 1
                                        const per_month_formated_full = this.decimalPipe.transform(per_month_formated.value, '1.0-1') + per_month_formated.post;
                                        const month_left = Math.round(in_stock / per_month);
                                        let month_left_str = '';
                                        if (this.showStock) {
                                            if (month_left > 1) {
                                                month_left_str = ` (${this.tr.anslate('still ~{{month_left}} months', { month_left: month_left }).trim()})`;
                                            } else if (month_left === 1) {
                                                month_left_str = ` (${this.tr.anslate('still about one month')})`;
                                            }
                                        }
                                        str.push(`${this.tr.anslate('monthly')}: ${per_month_formated_full}${month_left_str}`);
                                    }
                                }
                                return str;
                            } else {
                                return null;
                            }
                        } else {
                            return null;
                        }
                    },
                    label: () => {
                        return '';
                    },
                }
            },
        },
        hover: {
            mode: 'index' as const,
            axis: 'y' as const,
            // animationDuration: 0, // duration of animations when hovering an item
        },
        layout: {
            padding: {
                left: 20,
                right: 30,
                top: 20,
                bottom: 20
            }
        },
        aspectRatio: 0.9,
        maintainAspectRatio: false,
        // plugins: {
        //     datalabels: {
        //         display: false,
        //     },
        //     rough: {
        //         roughness: 1,
        //         bowing: 1,
        //         fillStyle: 'zigzag-line',
        //         fillWeight: 0,
        //         hachureAngle: -35,
        //         hachureGap: 4.5,
        //         curveStepCount: 1,
        //         simplification: 0
        //     }
        // },
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    major: {
                        enabled: true
                    },
                    callback: value => {
                        if ((this._small) && (this.tickUnit !== 't')) {
                            return `${value}`;
                        } else {
                            return `${value}${this.tickUnit}`;
                        }
                    }
                },
                title: {
                    display: false
                },
                border: {
                    display: true,
                },
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineWidth: 1,
                    // zeroLineColor: '#999'
                },
                stacked: true
            },
            y: {
                //categoryPercentage: 1, // deprecated in chartjs v2.8, moved to dataset:categoryPercentage
                //barPercentage: 0.6,    // deprecated in chartjs v2.8, moved to dataset:barPercentage
                border: {
                    display: true,
                },
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.25)',
                },
                ticks: {
                    display: true
                },
                stacked: true
            },
        }
    };


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }

        this.options.plugins.title.text = this.tr.anslate('Blends');

        this.inited = true;
        // mainUnit @Input is set after the options object is initialized
        if (this.updData) {
            this.setNewData(this.updData);
            this.updData = undefined;
        }
    }

    adaptGraphSize() {
        try {
            if (this.chart) {
                this.canvasHeight = (this.data.labels.length * 15 + 250) + 'px';
                this.chart.render();
            } else {
                setTimeout(() => {
                    this.canvasHeight = (this.data.labels.length * 15 + 250) + 'px';
                    this.chart?.render();
                });
            }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (err) {
            // ignore
        }
    }

    chartClicked(event: { event?: ChartEvent, active?: { datasetIndex?: number, index?: number }[] }) {
        if (event.active?.length > 0) {
            const elem = event.active[0];
            const internal_hr_id = this.data.datasets[elem.datasetIndex]?.['coffees']?.[elem.index];
            if (internal_hr_id) {
                this.router.navigate(['/blends', { id: 'B' + internal_hr_id }]);
            }
        }
    }

    setNewData(nd: any) {
        this.data = { labels: [], datasets: [] };
        this.haveData = false;

        this.showStock = !this.endDate || this.endDate.hasSame(DateTime.now(), 'day');

        if (nd?.data?.length && nd.labels && nd.coffees && nd.additionalData?.length > 3 &&
            nd.labels.length === nd.data.length && nd.coffees.length === nd.data.length &&
            nd.additionalData[0]?.data?.length === nd.data.length &&
            nd.additionalData[1]?.data?.length === nd.data.length &&
            nd.additionalData[2]?.data?.length === nd.data.length &&
            nd.additionalData[3]?.data?.length === nd.data.length) {

            this.haveData = true;

            // convert the weight data if needed
            let roasted_converted = nd.data;
            let in_stock_converted = nd.additionalData[0].data;
            let replacements_converted = nd.additionalData[3].data;
            if (this.mainUnit === 'lbs') {
                const convFactor = this.utils.getUnitFactor(this.mainUnit);
                roasted_converted = roasted_converted.map(x => x * convFactor);
                in_stock_converted = in_stock_converted.map(x => x * convFactor);
                replacements_converted = replacements_converted.map(x => x * convFactor);
                if (Math.max(...in_stock_converted) > 4000) {
                    this.weight_factor = 2000;
                    this.tickUnit = 't';
                    roasted_converted = roasted_converted.map(x => x / this.weight_factor);
                    in_stock_converted = in_stock_converted.map(x => x / this.weight_factor);
                    replacements_converted = replacements_converted.map(x => x / this.weight_factor);
                } else {
                    this.weight_factor = 1;
                    this.tickUnit = this.mainUnit;
                }
            } else {
                if (Math.max(...in_stock_converted) > 2000) {
                    this.weight_factor = 1000;
                    this.tickUnit = 't';
                    roasted_converted = roasted_converted.map(x => x / this.weight_factor);
                    in_stock_converted = in_stock_converted.map(x => x / this.weight_factor);
                    replacements_converted = replacements_converted.map(x => x / this.weight_factor);
                } else {
                    this.weight_factor = 1;
                    this.tickUnit = this.mainUnit;
                }
            }

            // add default units and counts
            const counts = [];
            const roastedPerDay = [];
            for (let i = 0; i < nd.coffees.length; i++) {
                counts.push(nd.additionalData[1].data[i]);
                roastedPerDay.push(nd.additionalData[2].data[i]);
            }

            let timeframe = '';
            const startDateMoreThanOneYear = !this.startDate || ((this.endDate ?? DateTime.now()).diff(this.startDate, 'year').years > 1);
            if (this.showStock && startDateMoreThanOneYear) {
                // interval from more than 1 year ago till today
                timeframe = `(1 ${this.tr.anslate('Year')})`;
            } else if (this.showStock) {
                // interval till today but less than 1 year
                timeframe = `(${this.tr.anslate('from {{date}}', { date: this.startDate.toLocaleString(DateTime.DATE_SHORT) })})`;
            } else if (startDateMoreThanOneYear) {
                // interval not till today and more than 1 year
                timeframe = `(${this.endDate.minus({ year: 1 }).toLocaleString(DateTime.DATE_SHORT)} - ${this.endDate.toLocaleString(DateTime.DATE_SHORT)})`;
            } else {
                // interval not till today and less than 1 year
                timeframe = `(${this.startDate.toLocaleString(DateTime.DATE_SHORT)} - ${this.endDate.toLocaleString(DateTime.DATE_SHORT)})`;
            }
            const label = `${this.tr.anslate('roasted')} ${timeframe}`

            const dsets = [];

            // add projection min dataset
            dsets.push({
                barPercentage: 0.6,
                categoryPercentage: 1,
                label,
                coffees: nd.coffees,
                data: roasted_converted.map((n: number) => Math.max(n, 0)),
                data_raw: nd.data.map((n: number) => Math.max(n, 0)),
                counts: counts,                  // only in the first data set!
                roastedPerDay: roastedPerDay,    // only in the first data set!
                backgroundColor: this.isDarkmode ? '#c00b40A2' : '#ad042792',
                hoverBackgroundColor: this.isDarkmode ? '#c00b40DD' : '#ad0427BB',
                borderWidth: 0,
                rough: {
                    roughness: 0,
                    fillStyle: 'solid',
                    bowing: 0
                }
            });
            if (this.showStock) {
                // add projection max dataset
                dsets.push({
                    barPercentage: 0.6,
                    categoryPercentage: 1,
                    label: this.tr.anslate('stored'),
                    coffees: nd.coffees,
                    data: in_stock_converted.map((n: number) => Math.max(n, 0)),
                    data_raw: nd.additionalData[0].data.map((n: number) => Math.max(n, 0)),
                    backgroundColor: this.isDarkmode ? '#9e9e9eA0' : '#bdbdbdA0',
                    hoverBackgroundColor: this.isDarkmode ? '#9e9e9eFF' : '#bdbdbdFF',
                    borderWidth: 0,
                    rough: {
                        roughness: 0,
                        fillStyle: 'solid',
                        bowing: 0
                    }
                });

                // add projection max dataset incl. replacements
                dsets.push({
                    barPercentage: 0.6,
                    categoryPercentage: 1,
                    label: this.tr.anslate('with replacements'),
                    data: replacements_converted.map((n: number) => Math.max(n, 0)),
                    data_raw: nd.additionalData[3].data.map((n: number) => Math.max(n, 0)),
                    // this worked with rough:
                    // backgroundColor: this.isDarkmode ? '#e0e0e0DD' : '#bdbdbdA0',  // dark: G300/DD, light: G400/A0
                    backgroundColor: this.isDarkmode ? '#fafafa66' : '#e0e0e077', // dark: G50/, light: G300/77
                    hoverBackgroundColor: this.isDarkmode ? '#eeeeee' : '#9e9e9eFF', // dark: G200/EE, light: G500/A0
                    borderColor: this.isDarkmode ? '#9e9e9eBB' : '#bdbdbd', // dark: G500/BB, light: G400
                    hoverBorderColor: this.isDarkmode ? '#bdbdbdEE' : '#9e9e9e', // dark: G400/EEom, light: G500
                    borderWidth: 0,
                });
            }

            this.data.labels = nd.labels;
            this.data.datasets = dsets;
        }

        if (this.isDarkmode) {
            this.options.scales.x.grid.color = 'rgba(255, 255, 255, 0.2)';
            // this.options.scales.x.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
            this.options.scales.y.grid.color = 'rgba(255, 255, 255, 0.2)';
            // this.options.scales.y.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
            this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
            this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
            this.options.plugins.tooltip.backgroundColor = '#eeeeeeDD'; // G200/DD => '#eeeeee'
        } else {
            this.options.scales.x.grid.color = 'rgba(0, 0, 0, 0.1)';
            // this.options.scales.x.grid.zeroLineColor = '#999';
            this.options.scales.y.grid.color = 'rgba(0, 0, 0, 0.1)';
            // this.options.scales.y.grid.zeroLineColor = 'rgba(0, 0, 0, 0.1)';
            this.options.plugins.tooltip.titleColor = '#fff';
            this.options.plugins.tooltip.bodyColor = '#fff';
            this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
        }

        this.adaptGraphSize();
    }
}
