<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>


<h1 mat-dialog-title i18n="blends$$dialog, make beans change in all blend templates@@Change in all blend templates?">Change in all blend templates?</h1>
<mat-dialog-content>
    <div>
        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: data.from}"></ng-container>
    </div>
    <div _ngcontent-dsd-c286="" style="margin-left: 30px;">&#8681;</div>
    <div>
        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: data.to}"></ng-container>
    </div>

    <div style="margin-top: 20px;"><span i18n="generic$$Blends@@Blends">Blends</span>:</div>
    @if (!showAffectedBlends && !loadingClicked) {
    <a (click)="loadingClicked = true; loadAllBlends()" (keydown.enter)="loadingClicked = true; loadAllBlends(); $event.preventDefault()" role="button" [tabindex]="0" class="littlespaceleft">
        <mat-icon class="cursor-pointer">update</mat-icon>
    </a>
    }
    @if (!showAffectedBlends && loadingClicked && loading) {
    <mat-spinner class="in-text-spinner" diameter="15"></mat-spinner>
    }

    @if (showAffectedBlends) {
    <ul>
        @for (blend of data.affectedBlends; track blend?._id) {
        @if (blend.label && $index < 10) {
            <li>{{blend.label}}</li>
            }
            }
            @if (data.affectedBlends.length > 10) {
            <div>...</div>
            }
    </ul>
    }
    @if (showAffectedBlends && !data.affectedBlends.length) {
    <div i18n="generic$$no matches found using the given filter@@no match for filter">No matches found</div>
    }

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-button" mat-button type="submit" mat-dialog-close tabindex="0">
        <mat-icon>close</mat-icon>
    </button>
    <button class="action-button" mat-raised-button type="button" (click)="onYesClick()">
        <mat-icon>done</mat-icon>
    </button>
</mat-dialog-actions>