import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModificationDateComponent } from './ap-modification-date/modification-date.component';

@NgModule({
    declarations: [
        ModificationDateComponent,
    ],
    imports: [
        CommonModule,
    ],
    providers: [
    ],
    exports: [
        ModificationDateComponent,
    ],
})
export class ModificationDateModule { }
