import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Constants } from 'src/app/util/constants';

@Component({
    selector: 'app-wait-dialog',
    template: '',
})
export class WaitDialogComponent implements OnInit {

    constructor(
        protected dialogRef: MatDialogRef<WaitDialogComponent>,
    ) {
        dialogRef.disableClose = true;
    }

    @Output() finished: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() cancelled = new EventEmitter<void>();

    showWaitingTimer: ReturnType<typeof setTimeout>;
    showWaiting = false;
    private _waiting = false;
    public get waiting() {
        return this._waiting;
    }
    public set waiting(w: boolean) {
        if (w) {
            this.showWaitingTimer = setTimeout(() => {
                this.showWaiting = true;
            }, Constants.SHOW_SPINNER_DELAY_TIME);
        } else {
            clearTimeout(this.showWaitingTimer);
            this.showWaiting = false;
        }
        this._waiting = w;
    }


    ngOnInit(): void {
        this.dialogRef.backdropClick().subscribe(() => this.handleExit());
        this.dialogRef.keydownEvents().subscribe(ev => ev?.code === 'Escape' && this.handleExit(ev));
        this.dialogRef.afterClosed().subscribe(res => this.handleExit(undefined, res));
    }

    protected handleExit(ev?: KeyboardEvent, yes?: boolean, force = false) {
        if (force || (!this.waiting && (!ev || ev.code === 'Escape'))) {
            this.cancelled.emit();
            this.dialogRef.close(yes);
        }
    }

    protected finishDialog(ok: boolean) {
        this.finished.emit(ok);
    }
}
