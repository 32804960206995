@if (btuTotal > 0 || co2Total > 0) {
<div class="energy-widg">
    @if (btuTotal > 0) {
    <span class="widg-frame">
        <div><span class="widg-title" i18n="roasts$$energy (e.g. used by one or more roasts)@@Energy">Energy</span> {{titleYear}}</div>
        <span class="widg-box cursor-pointer" (click)="switchEnergyUnit()" (keydown.enter)="switchEnergyUnit(); $event.preventDefault()" role="button" [tabindex]="0" [ngClass]="{'widg-box-small': isSmall$ | async}">
            <div class="widg-main" [ngClass]="{'widg-main-small': isSmall$ | async}">{{btuTotalStr}} {{useEnergyUnit1000 ? energyUnit1000 : energyUnit}}</div>
            @if (btuPerUnit) {
            <div class="widg-secondary" [ngClass]="{'widg-secondary-small': isSmall$ | async}">{{btuPerUnit}} {{useEnergyUnitDividedBy1000 ? energyUnitDividedBy1000 : energyUnit}} <ng-container i18n="generic$$e.g. 120g CO2 _per_ kg@@per">per</ng-container> {{mainUnit}} <ng-container i18n="generic$$Beans@@Beans">Beans</ng-container><!-- (of {{totalRoastedBTU | number:'1.0-1'}} {{mainUnit}} beans) -->
            </div>
            }
        </span>
    </span>
    }
    @if (co2Total > 0) {
    <span class="widg-frame">
        <div><span class="widg-title">CO<sub>2</sub></span> {{titleYear}}</div>
        <span class="widg-box" [ngClass]="{'widg-box-small': isSmall$ | async}">
            <div class="widg-main" [ngClass]="{'widg-main-small': isSmall$ | async}">{{co2TotalStr}} {{co2Unit}}</div>
            @if (co2PerUnit) {
            <div class="widg-secondary" [ngClass]="{'widg-secondary-small': isSmall$ | async}">{{co2PerUnit.pre}} {{co2PerUnit.value | number:'1.0-0'}} {{co2PerUnit.post}} <ng-container i18n="generic$$e.g. 120g CO2 _per_ kg@@per">per</ng-container>&nbsp;{{mainUnit}} <ng-container i18n="generic$$Beans@@Beans">Beans</ng-container><!-- (of {{totalRoastedCO2 | number:'1.0-1'}} {{mainUnit}} beans -->
            </div>
            }
        </span>
    </span>
    }
</div>
}