<div class="checkbox-filter-main-div" [ngStyle]="{'margin-top': margin_top + 'px'}">
    @if (!noValueFound) {
    @if (showAllowNull) {
    <span class="allowNull-container" [ngStyle]="{'width': width ? width : 'initial'}" [ngClass]="{'flex-display': inlineCheckbox}">
        <span class="allowNull-label" [ngClass]="{'error-reason': showError}" [ngStyle]="{'margin-right': inlineCheckbox ? '10px' : 0}">--</span>
        <div class="checkbox-filter-with-value">
            @if (additionalValues?.[0]) {
            <span class="checkbox-filter-filter-hints" [ngClass]="{'warn-amount': additionalValueWarns?.[0]}">
                {{additionalValues?.[0]}}
            </span>
            }
            <mat-checkbox [disabled]="disabled"
                [ngModel]="selected?.[0]"
                (change)="optionChanged(0, $event)"
                [ngClass]="{'tertiary-element': showError, 'primary-element': !showError}">
            </mat-checkbox>
        </div>
    </span>
    <!-- } @else {
    <span style="display: inline-block; min-width: 26px;"></span> -->
    }
    @for (option of allOptionsButFirst; track option) {
    <span class="allowNull-container" [ngStyle]="{'width': width ? width : 'initial'}" [ngClass]="{'flex-display': inlineCheckbox}">
        <span class="allowNull-label" [ngClass]="{'error-reason': showError}" [ngStyle]="{'margin-right': inlineCheckbox ? '10px' : 0}">{{option}} {{unit}}</span>
        <div class="checkbox-filter-with-value">
            @if (additionalValues?.[$index]) {
            <span class="checkbox-filter-filter-hints" [ngClass]="{'warn-amount': additionalValueWarns?.[$index]}">
                {{additionalValues?.[$index]}}
            </span>
            }
            <mat-checkbox [disabled]="disabled"
                [ngModel]="selected?.[$index + 1]"
                (change)="optionChanged($index + 1, $event)"
                [ngClass]="{'tertiary-element': showError, 'primary-element': !showError}">
            </mat-checkbox>
        </div>
    </span>
    }
    } @else {
    <span style="display: inline-block"></span>{{EMPTYTAG}}
    }
</div>