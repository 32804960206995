<ng-template #noInfo>---</ng-template>
<ng-template #noInfoError><span class="no-amount">---</span></ng-template>
<ng-template #roastsErrors><span class="no-amount" style="margin-top: 8px">Achtung:</span> Manche Einträge im Röstprotokoll sind unvollständig!</ng-template>
<ng-template #purchaseErrors><span class="no-amount" style="margin-top: 8px">Achtung:</span> Manche Einträge in der Tabelle "Zugang Rohkaffee" sind unvollständig!</ng-template>


<div style="margin-right: 15px;">Diese Daten können direkt in das <a href="https://artisan.plus/assets/documents/customs/GER/koeln.xls" rel="noopener" target="_blank">Excel Dokument</a> kopiert werden. Bitte immer beim entsprechenden Hauptzollamt nachfragen, ob dieses Format auch akzeptiert wird.</div>

@if (data_trans?.data?.length > 2 || data_tax?.data?.length > 2) {
<div class="copy-labels">
    @if (data_tax?.data?.length) {
    <span class="copy-label">
        Steueranmeldung:
        @if (!justCopied_t && !copyFailed_t) {
        <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyTax()">
            <mat-icon matTooltip="Copy to clipboard"
                i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
        </button>
        }
        @if (justCopied_r) {
        <button mat-icon-button type="button" disabled class="copy-button-button">
            <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
        </button>
        }
        @if (copyFailed_r) {
        <button mat-icon-button type="button" disabled class="copy-button-button">
            <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
        </button>
        }
    </span>
    }
    <span class="copy-label">
        Rohkaffee:
        @if (!justCopied && !copyFailed) {
        <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyTrans()">
            <mat-icon matTooltip="Copy to clipboard"
                i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
        </button>
        }
        @if (justCopied) {
        <button mat-icon-button type="button" disabled class="copy-button-button">
            <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
        </button>
        }
        @if (copyFailed) {
        <button mat-icon-button type="button" disabled class="copy-button-button">
            <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
        </button>
        }
    </span>
    @if (data_roasts?.filteredData?.length) {
    <span class="copy-label">
        Röstprotokoll:
        @if (!justCopied_r && !copyFailed_r) {
        <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyRoasts()">
            <mat-icon matTooltip="Copy to clipboard"
                i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
        </button>
        }
        @if (justCopied_r) {
        <button mat-icon-button type="button" disabled class="copy-button-button">
            <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
        </button>
        }
        @if (copyFailed_r) {
        <button mat-icon-button type="button" disabled class="copy-button-button">
            <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
        </button>
        }
    </span>
    }
</div>
}

@if (roastsHaveErrors || transactionsHaveErrors) {
<div>
    @if (roastsHaveErrors) {
    <div>
        <ng-container *ngTemplateOutlet="roastsErrors"></ng-container>
    </div>
    }
    @if (transactionsHaveErrors) {
    <div>
        <ng-container *ngTemplateOutlet="purchaseErrors"></ng-container>
    </div>
    }
</div>
}

@if (data_trans) {
<h2>Steueranmeldung
    @if (data_tax?.data?.length && !justCopied_t && !copyFailed_t) {
    <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyTax()">
        <mat-icon matTooltip="Copy to clipboard"
            i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
    </button>
    }
    @if (justCopied_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
    </button>
    }
    @if (copyFailed_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
    </button>
    }
</h2>
}

@if (data_tax?.data?.length) {
<table mat-table [dataSource]="data_tax" class="wide-table mat-elevation-z8" #taxTable>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay_tax"></tr>
    <tr mat-row *matRowDef="let transs; columns: columnsToDisplay_tax" class="report-trans-row"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplay_tax" class="sum-cell"></tr>
    <ng-container matColumnDef="Monat">
        <th mat-header-cell *matHeaderCellDef>Monat</th>
        <td mat-cell *matCellDef="let entry">{{entry.Monat}}</td>
        <td mat-footer-cell *matFooterCellDef colSpan="3" style="text-align: right;">
            @if (!nrDiscarded) {
            <span>* keine Fehlröstungen vorhanden</span>
            }
            @if (nrDiscarded && (!manualDiscarded || !nrDiscardedButTaxed)) {
            <span>* ohne Fehlröstungen</span>
            }
            @if (nrDiscarded && nrDiscarded === nrDiscardedButTaxed) {
            <span>* inkl. allen Fehlröstungen</span>
            }
            @if (nrDiscarded && nrDiscardedButTaxed && nrDiscarded !== nrDiscardedButTaxed) {
            <span>* inkl. {{nrDiscardedButTaxed}} von {{nrDiscarded}} Fehlröstungen (siehe Röstprotokoll)</span>
            }
            <!-- nrDiscarded: {{nrDiscarded}}, nrDiscardedButTaxed: {{nrDiscardedButTaxed}} -->
        </td>
    </ng-container>
    <ng-container matColumnDef="Registrierkennzeichen">
        <th mat-header-cell *matHeaderCellDef>Registrierkennzeichen</th>
        <td mat-cell *matCellDef="let entry" style="width: 182px">
            <!-- <mat-form-field class="table-inputfield"> -->
            <input [(ngModel)]="entry.Registrierkennzeichen" matInput
                type="text" style="text-decoration: underline"
                data-test="npt_Registrierkennzeichen">
            <!-- </mat-form-field> -->
            <!-- {{entry.Registrierkennzeichen}} -->
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Menge in kg">
        <th mat-header-cell *matHeaderCellDef class="right-align">Menge in kg*</th>
        <td mat-cell *matCellDef="let entry" class="right-align">
            {{(entry['Menge in kg'] || 0) | number:'1.2-2'}}kg
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Steuersatz/kg">
        <th mat-header-cell *matHeaderCellDef class="right-align">Steuersatz/kg</th>
        <td mat-cell *matCellDef="let entry" class="right-align">
            {{(entry['Steuersatz/kg'] || 0) | number:'1.2-2'}}€
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Steuerbetrag €">
        <th mat-header-cell *matHeaderCellDef class="right-align">Steuerbetrag €</th>
        <td mat-cell *matCellDef="let entry" class="right-align">
            {{(entry['Steuerbetrag €'] || 0) | number:'1.2-2'}}€
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Bemerkung">
        <th mat-header-cell *matHeaderCellDef>Bemerkung</th>
        <td mat-cell *matCellDef="let entry">
            <input [(ngModel)]="entry.Bemerkung" matInput
                type="text" style="text-decoration: underline"
                data-test="npt_Bemerkung">
            <!-- {{entry.Bemerkung}} -->
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
</table>
} @else {
Keine Einträge für "Steueranmeldung"
}


@if (data_trans) {
<h2>Zugang Rohkaffee
    @if (data_trans.data?.length && !justCopied && !copyFailed) {
    <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyTrans()">
        <mat-icon matTooltip="Copy to clipboard"
            i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
    </button>
    }
    @if (justCopied_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
    </button>
    }
    @if (copyFailed_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
    </button>
    }
</h2>
}

@if (data_trans.data?.length) {
<table mat-table [dataSource]="data_trans" class="wide-table mat-elevation-z8" #transTable>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay_trans"></tr>
    <tr mat-row *matRowDef="let transs; columns: columnsToDisplay_trans" class="report-trans-row"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplay_trans" class="sum-cell"></tr>
    <ng-container matColumnDef="Number">
        <th mat-header-cell *matHeaderCellDef>Lfd<br />Nr.</th>
        <td mat-cell *matCellDef="let trans; let i=index">
            {{i+1}}
            @if (!readOnly) {
            <a (click)="editTransaction(trans)" (keydown.enter)="editTransaction(trans); $event.preventDefault()" role="button" [tabindex]="0">
                <mat-icon class="smallicon">edit</mat-icon>
            </a>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef i18n="generic$$date placeholder or header@@Date">Date</th>
        <td mat-cell *matCellDef="let trans">
            @if (trans.date) {
            <span>{{trans.date.toLocaleString(DateTime.DATE_SHORT)}}</span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Order Number">
        <th mat-header-cell *matHeaderCellDef>Rech.<br />Nr.</th>
        <td mat-cell *matCellDef="let trans">
            @if (trans.order_number || trans.reference) {
            <span>{{trans.order_number || trans.reference}}</span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Supplier">
        <th mat-header-cell *matHeaderCellDef><span i18n="contacts$$supplier@@Supplier">Supplier</span></th>
        <td mat-cell *matCellDef="let trans">
            @if (trans.supplier) {
            <span>{{trans.supplier.label}}</span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Purchase Amount">
        <th mat-header-cell *matHeaderCellDef class="right-align">Menge netto<br />in kg</th>
        <td mat-cell *matCellDef="let purchase" class="right-align">
            @if (purchase.__t === 'Purchase') {
            @if (purchase.amount != null) {
            <span><span>{{purchase.amount | number:'1.2-2'}}{{mainUnit}}</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{sum_purch | number:'1.0-2'}}{{mainUnit}}</td>
    </ng-container>
    <ng-container matColumnDef="Coffees">
        <th mat-header-cell *matHeaderCellDef>Marke</th>
        <td mat-cell *matCellDef="let trans">
            @if (trans?.coffee) {
            <span>
                @if (trans.coffee.hr_id) {
                <a [routerLink]="['/coffees', {id: trans.coffee.hr_id}]">{{trans.coffee.hr_id}}</a>
                }
                @if (trans.coffee.origin) {
                <span> {{tr.anslate(trans.coffee.origin)}}</span>
                }
                @if (trans.coffee.yearLabel) {
                <span> {{trans.coffee.yearLabel}}</span>
                }
                @if (trans.coffee.label && (trans.coffee.hr_id || trans.coffee.origin || trans.coffee.yearLabel)) {
                <br />
                }
                @if (trans.coffee.label) {
                <span> {{trans.coffee.label}}</span>
                }
            </span>
            }
            @if (trans?.blend; as blend) {
            <span>
                @if (blend.label) {
                <span>{{blend.label}}:</span>
                }
                <ul class="ingredients-list">
                    @for (ing of blend.ingredients; track $index) {
                    <li>
                        @if (ing.ratio != null) {
                        <span>{{ing.ratio * 100 | number:'1.1-1'}}%
                            @if (ing.coffee?.hr_id) {
                            <a [routerLink]="['/coffees', {id: ing.coffee.hr_id}]"> {{ing.coffee.hr_id}}</a>
                            }
                            @if (ing.coffee?.origin) {
                            <span> {{tr.anslate(ing.coffee.origin)}}</span>
                            }
                            @if (ing.coffee?.yearLabel) {
                            <span> {{ing.coffee.yearLabel}}</span>
                            }
                            @if (ing.coffee?.label && (ing.coffee.origin || ing.coffee.yearLabel)) {
                            <span>,</span>
                            }
                            @if (ing.coffee?.label) {
                            <span> {{ing.coffee.label}}</span>
                            }
                        </span>
                        }
                    </li>
                    }
                </ul>
            </span>
            }
            @if (!trans.coffee && !trans.blend) {
            <ng-container *ngTemplateOutlet="noInfoError"></ng-container>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
</table>
} @else {
Keine Käufe in diesem Zeitraum vorhanden
}


@if (data_roasts) {
<h2>Röstprotokoll
    @if (!justCopied_r && !copyFailed_r && data_roasts?.filteredData?.length) {
    <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyRoasts()">
        <mat-icon matTooltip="Copy to clipboard"
            i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
    </button>
    }
    @if (justCopied_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
    </button>
    }
    @if (copyFailed_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
    </button>
    }
    @if (data_roasts?.filteredData?.length) {
    <a (click)="generatePDF()" (keydown.enter)="generatePDF(); $event.preventDefault()" role="button" [tabindex]="0" [ngClass]="{'isDisabled': creatingPdf}" class="report-link">PDF</a>
    }
</h2>
}

@if (manualDiscarded && nrDiscarded) {
<mat-checkbox [disabled]="readOnly" name="manualDiscarded_checkbox"
    [ngModel]="onlyDiscardedNotDestroyed"
    (change)="onlyDiscardedNotDestroyedChanged($event)"
    class="manualDiscarded-checkbox wrap-checkbox-label"
    [ngStyle]="{'margin-bottom': roastsHaveErrors ? '10px' : 0}">
    Zeige nur alle Fehlröstungen, die noch nicht als vernichtet markiert sind.
</mat-checkbox>
}

@if (roastsHaveErrors) {
<div><ng-container *ngTemplateOutlet="roastsErrors"></ng-container></div>
}

@if (data_roasts?.filteredData?.length) {
<div style="margin-top: 10px">
    <mat-checkbox [(ngModel)]="showPurchaseDate" name="showPurchaseDate_checkbox">Daten in "Rohkaffee Lieferung vom" anzeigen</mat-checkbox>
</div>
}
@if (data_roasts?.filteredData?.length) {
<div>
    <mat-checkbox [(ngModel)]="showPurchasePrice" name="showPurchasePrice_checkbox">Preise von "Rohkaffee Käufen" anzeigen</mat-checkbox>
</div>
}

@if (data_roasts?.filteredData?.length && (showPurchaseDate || showPurchasePrice)) {
<div><span class="error-reason">Achtung</span>: Die Daten in den Spalten <span class="error-reason">"Rohkaffee Lieferung vom / €"</span> sind das Datum und der Preis des letzten Kaufs dieses Kaffees, nicht unbedingt der Kauf der verwendeten Bohnen. Bitte evtl. manuell anpassen!</div>
}

@if (data_roasts?.filteredData?.length) {
<table mat-table [dataSource]="data_roasts" class="wide-table mat-elevation-z8" #roastTable>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay_roasts"></tr>
    <tr mat-row *matRowDef="let roasts; columns: columnsToDisplay_roasts" class="report-roasts-row"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplay_roasts" class="sum-cell"></tr>
    <!-- <ng-container matColumnDef="ID">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let roast">
      <span *ngIf="roast.batch_prefix || roast.batch_number || roast.hr_id; else noInfo">
        {{roast.batch_prefix}}{{roast.batch_number}}
        <br *ngIf="roast.batch_prefix || roast.batch_number"/>
        <a *ngIf="roast.hr_id; else noInfo" [routerLink]="['/roasts', {id: roast.hr_id}]">{{roast.hr_id}}</a>
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container> -->
    <ng-container matColumnDef="Destroyed">
        <th mat-header-cell *matHeaderCellDef>Fehlröstung<br />vernichtet</th>
        <td mat-cell *matCellDef="let roast; let i=index">
            @if (roast.discarded) {
            <mat-form-field class="amount-input-ff1 narrow-date-field">
                <input matInput [placeholder]="roast.destroyed ? '' : 'Leer / Datum'"
                    [(ngModel)]="roast.destroyed" (dateChange)="destroyedChanged(i)"
                    [disabled]="readOnly" data-test="npt_destroyedDate"
                    [matDatepicker]="myDatepicker" name="destroyedDate">
                <mat-datepicker-toggle matIconPrefix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
            }
            @if (roast.destroyed && !readOnly) {
            <button mat-icon-button class="tertiary-element" (click)="removeDestroyed(i)">
                <mat-icon class="tertiary-element removeDestroyed-icon">close</mat-icon>
            </button>
            }
            <!-- <ng-container *ngIf="i === 0"> -->
            @if (roast.discarded && nrDiscarded > 1) {
            <br />
            @if (!readOnly) {
            <a (click)="applyDestroyedToAll(i)" (keydown.enter)="applyDestroyedToAll(i); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">
                Auf alle anwenden
            </a>
            }
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Number">
        <th mat-header-cell *matHeaderCellDef>Lfd<br />Nr.</th>
        <td mat-cell *matCellDef="let roast; let i=index">
            @if (roast.hr_id) {
            <a [routerLink]="['/roasts', {id: roast.hr_id}]">{{i+1}}</a>
            } @else {
            {{i+1}}
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let roast">
            @if (roast.date) {
            <span>{{roast.date.toLocaleString(DateTime.DATE_SHORT)}}</span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <!-- <ng-container matColumnDef="Time">
  <th mat-header-cell *matHeaderCellDef>Uhrzeit</th>
  <td mat-cell *matCellDef="let roast">
    <span *ngIf="roast.date; else noInfoError">{{roast.date.toLocaleString(DateTime.TIME_SIMPLE)}}</span>
  </td>
  <td mat-footer-cell *matFooterCellDef></td>
</ng-container> -->
    <ng-container matColumnDef="CoffeesOrBlend">
        <th mat-header-cell *matHeaderCellDef>Kaffeesorte</th>
        <td mat-cell *matCellDef="let roast">
            @if (roast?.coffee) {
            <span>
                @if (roast.coffee.hr_id) {
                <a [routerLink]="['/coffees', {id: roast.coffee.hr_id}]">{{roast.coffee.hr_id}}</a>
                }
                @if (roast.coffee.origin) {
                <span> {{tr.anslate(roast.coffee.origin)}}</span>
                }
                @if (roast.coffee.yearLabel) {
                <span> {{roast.coffee.yearLabel}}</span>
                }
                @if (roast.coffee.label && (roast.coffee.hr_id || roast.coffee.origin || roast.coffee.yearLabel)) {
                <br />
                }
                @if (roast.coffee.label) {
                <span> {{roast.coffee.label}}</span>
                }
            </span>
            }
            @if (roast?.blend; as blend) {
            <span>
                <ul class="ingredients-list">
                    @for (ing of blend.ingredients; track $index) {
                    <li>
                        @if (ing.ratio != null) {
                        <span>{{ing.ratio * 100 | number:'1.1-1'}}%
                            @if (ing.coffee?.hr_id) {
                            <a [routerLink]="['/coffees', {id: ing.coffee.hr_id}]"> {{ing.coffee.hr_id}}</a>
                            }
                            @if (ing.coffee?.origin) {
                            <span> {{tr.anslate(ing.coffee.origin)}}</span>
                            }
                            @if (ing.coffee?.yearLabel) {
                            <span> {{ing.coffee.yearLabel}}</span>
                            }
                            @if (ing.coffee?.label && (ing.coffee.origin || ing.coffee.yearLabel)) {
                            <span>,</span>
                            }
                            @if (ing.coffee?.label) {
                            <span> {{ing.coffee.label}}</span>
                            }
                        </span>
                        }
                    </li>
                    }
                </ul>
            </span>
            }
            @if (!roast?.coffee && !roast?.blend) {
            <ng-container *ngTemplateOutlet="noInfoError"></ng-container>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef colSpan="3" class="normalweight-footer right-align">
            @if (sum_discarded) {
            <br />ohne Fehlröstungen:
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="Purchase">
        <th mat-header-cell *matHeaderCellDef class="error-reason">Rohkaffee<br />Lieferung<br />vom</th>
        <td mat-cell *matCellDef="let roast">
            @if (showPurchaseDate) {
            @if (roast.coffee) {
            {{roast.lastPurchaseDate.toLocaleString(DateTime.DATE_SHORT)}}
            }
            @if (roast.blend) {
            @for (date of roast.lastPurchaseDates; track date?.valueOf()) {
            <span>
                {{date.toLocaleString(DateTime.DATE_SHORT)}}<br />
            </span>
            }
            }
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="PurchaseCost">
        <th mat-header-cell *matHeaderCellDef class="error-reason">€</th>
        <td mat-cell *matCellDef="let roast">
            @if (showPurchasePrice) {
            @if (roast.coffee) {
            @if (roast.lastPurchasePrice) {
            <span>{{roast.lastPurchasePrice | number:'1.2-2'}}€</span>
            }
            }
            @if (roast.blend) {
            @for (price of roast.lastPurchasePrices; track price) {
            @if (price) {
            <span>{{price | number:'1.2-2'}}€<br /></span>
            }
            }
            }
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef class="right-align">Einfüllmenge<br />Rohkaffee<br />kg</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.amount != null) {
            <span><span>{{roast.amount | number:'1.2-2'}}kg</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{sum_amount | number:'1.2-2'}}kg@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_amount - sum_discarded) | number:'1.2-2'}}kg</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="RoastTime">
        <th mat-header-cell *matHeaderCellDef class="right-align">Röstzeit<br />min</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.drop_time) {
            <span><span>{{floor(roast.drop_time / 60.0) + ':' + utils.padNumber(roast.drop_time % 60, 2)}}</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="End Weight">
        <th mat-header-cell *matHeaderCellDef class="right-align">Menge<br />Röstkaffee<br />kg</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.end_weight != null) {
            <span><span>{{roast.end_weight | number:'1.2-2'}}kg</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{sum_end_weight | number:'1.2-2'}}kg@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_end_weight - sum_end_weight_discarded) | number:'1.2-2'}}kg</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="Loss">
        <th mat-header-cell *matHeaderCellDef class="right-align">Schwund<br />kg</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.amount && roast.end_weight != null) {
            <span><span>{{roast.amount - roast.end_weight | number:'1.2-2'}}kg</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{sum_amount - sum_end_weight | number:'1.2-2'}}kg@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_amount - sum_discarded - sum_end_weight + sum_end_weight_discarded) | number:'1.2-2'}}kg</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="LossPercent">
        <th mat-header-cell *matHeaderCellDef class="right-align">Schwund<br />%</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.amount && roast.end_weight != null) {
            <span><span>{{(roast.amount - roast.end_weight) * 100 / roast.amount | number:'1.1-1'}}%</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{(sum_amount - sum_end_weight) * 100 / sum_amount | number:'1.1-1'}}%@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_amount - sum_discarded - sum_end_weight + sum_end_weight_discarded) / (sum_amount - sum_discarded) * 100 | number:'1.1-1'}}%</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="Note">
        <th mat-header-cell *matHeaderCellDef style="min-width: 110px;">Bemerkung</th>
        <td mat-cell *matCellDef="let roast">
            <input [(ngModel)]="roast.Bemerkung" matInput
                type="text" style="text-decoration: underline"
                data-test="npt_Bemerkung2">
            <!-- <span *ngIf="roast.discarded"><span>Fehlröstung</span></span> -->
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <!-- <ng-container matColumnDef="Taxable">
<th mat-header-cell *matHeaderCellDef class="right-align">Zu versteuern</th>
<td mat-cell *matCellDef="let roast" class="right-align">
  <span *ngIf="!roast.discarded && roast.end_weight != null; else noInfo"><span>{{roast.end_weight | number:'1.3-3'}}kg</span></span>
</td>
<td mat-footer-cell *matFooterCellDef class="right-align">{{(sum_end_weight - sum_discarded) | number:'1.0-3'}}kg</td>
</ng-container>
<ng-container matColumnDef="Tax">
  <th mat-header-cell *matHeaderCellDef class="right-align">Steuer 2,19€/kg</th>
  <td mat-cell *matCellDef="let roast" class="right-align">
    <span *ngIf="!roast.discarded && roast.end_weight != null; else noInfo"><span>{{roast.end_weight * tax | number:'1.2-2'}}€</span></span>
  </td>
  <td mat-footer-cell *matFooterCellDef class="right-align">{{(sum_end_weight - sum_discarded) * tax | number:'1.2-2'}}€</td>
</ng-container>
<ng-container matColumnDef="Exclude Yield">
  <th mat-header-cell *matHeaderCellDef class="right-align">Fehlröstung</th>
  <td mat-cell *matCellDef="let roast" class="right-align">
    <span *ngIf="roast.discarded && roast.amount != null; else noInfo"><span>{{roast.amount | number:'1.3-3'}}kg</span></span>
  </td>
  <td mat-footer-cell *matFooterCellDef class="right-align">{{sum_discarded | number:'1.0-3'}}kg</td>
</ng-container> -->
</table>
} @else {
Keine Röstungen in diesem Zeitraum vorhanden
}