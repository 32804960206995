import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Coffee } from 'src/app/models/Coffee';

@Injectable({
    providedIn: 'root'
})
export class ExternalService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getSupplierPartners(): Observable<{ success: boolean, result: { label: string, partner: string, useSVG?: boolean }[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/external/data/partners';
        return this.http.get<{ success: boolean, result: { label: string, partner: string, useSVG?: boolean }[], error: string }>(url);
    }

    retrieveData(source: string, id: string): Observable<{ success: boolean, result: { id: string, source: string, current: boolean, updated_at: string, existingId: string, coffee: Coffee }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/external/data';
        return this.http.get<{ success: boolean, result: { id: string, source: string, current: boolean, updated_at: string, existingId: string, coffee: Coffee }, error: string }>(url, { params: { source, id } });
    }

    retrieveIDs(source: string): Observable<{ success: boolean, result: string[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/external/data/ids';
        return this.http.get<{ success: boolean, result: string[], error: string }>(url, { params: { source } });
    }
}
