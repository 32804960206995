import { Component, OnDestroy, Inject, LOCALE_ID, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { finalize, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { Enumerations } from 'src/app/models/Enumerations';
import { InvitedUserInfo, UserType } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { SchedulerService } from './scheduler.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { DateTime, Info } from 'luxon';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { NgClass, NgStyle } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'app-scheduler-planner-year',
    templateUrl: './scheduler-planner-year.component.html',
    styleUrls: ['./scheduler-planner-year.component.scss'],
    standalone: true,
    imports: [MatGridList, MatGridTile, NgStyle, MatButton, MatIcon, MatTooltip, NgClass],
})
export class SchedulerPlannerYearComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        protected schedulerService: SchedulerService,
        protected alertService: AlertService,
        protected utils: Utils,
        protected tr: TranslatorService,
        protected windowSize: BreakpointObserver,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    // TODO setting
    readonly STARTWEEKDAY = 1;

    readonly today = DateTime.now().toISODate();

    readonly WEEKDAYS = Info.weekdays('short', { locale: this.locale });

    // always show at least 3 months
    readonly MINDAYS = 70;

    readonly COLSIZE = 430;
    readonly ADDSIZE = 170;
    lastSize = 1;
    breakPoints = [];

    _currentUser: UserType;
    @Input() set currentUser(cu: UserType) {
        this._currentUser = cu;
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lb';
        }
    }
    get currentUser(): UserType {
        return this._currentUser;
    }

    _startDate: DateTime = DateTime.now().startOf('month');
    @Input() set startDate(sd: DateTime) {
        if (!sd) {
            // this._startDate = DateTime.now().startOf('year');
            this._startDate = DateTime.now().startOf('month').minus({ month: 1 });
        } else {
            // this._startDate = sd.startOf('month');
            this._startDate = sd.startOf('month').minus({ month: 1 });
        }
        // this.getRoastScheduleYear();
        if (this.getRoastScheduleYear$) {
            this.getRoastScheduleYear$.next();
        }
    }
    get startDate(): DateTime {
        return this._startDate;
    }

    @Input() machineFilter: string[];
    @Input() userFilter: InvitedUserInfo[];

    @Output() jumpToDate = new EventEmitter<{ date: DateTime, view: 's' | 'm' }>();
    @Output() startLoading = new EventEmitter<void>();
    @Output() doneLoading = new EventEmitter<void>();

    lastResult: { date: string, amount: number }[];
    yearDatas: { date: DateTime, amount: number, amountStr: string, color: string }[][] = [];

    openItemDate: string;

    mainUnit: UnitSystemType = 'kg';

    primaryColor = 'rgb(20, 123, 179)';
    accentColor = 'rgb(204, 15, 80)';

    private getRoastScheduleYear$: Subject<void>;
    protected ngUnsubscribe = new Subject()

    @ViewChild('primarybutton') _primarybutton: MatButton;
    @ViewChild('accentbutton') _accentbutton: MatButton;

    DateTime = DateTime;

    ngOnInit(): void {
        for (let i = 2; i < 8; i++) {
            if (i === 6) {
                // don't support (i - 1) = 5 columns since cannot evenly spread 12 months
                continue;
            }
            const bpMin = this.ADDSIZE + (i - 1) * this.COLSIZE;
            const bp = this.ADDSIZE + i * this.COLSIZE;
            this.breakPoints.push(`(max-width: ${bp}px) and (min-width: ${bpMin}px)`);
            // this.breakPoints.push(`(max-width: ${bp}px) and (min-width: ${bpMin})`);
        }

        const layoutChanges = this.windowSize.observe(this.breakPoints);
        layoutChanges.subscribe(sizeInfo => {
            const sizes = Object.getOwnPropertyNames(sizeInfo.breakpoints);
            for (let s = 0; s < sizes.length; s++) {
                const size = sizes[s];
                if (sizeInfo.breakpoints[size]) {
                    this.updateViewForSize(s + 1);
                    break;
                }
            }
        });
        for (let s = 0; s < this.breakPoints.length; s++) {
            const bp = this.breakPoints[s];
            if (this.windowSize.isMatched(bp)) {
                this.updateViewForSize(s + 1);
                break;
            }
        }

        this.setFilter(this.machineFilter, this.userFilter);

        this.getRoastScheduleYear$ = new Subject<void>();
        this.getRoastScheduleYear$.pipe(
            switchMap(() => this.callGetRoastScheduleYear().pipe(finalize(() => {
                this.doneLoading.emit();
            }))),
            takeUntil(this.ngUnsubscribe),
        ).subscribe({
            next: response => {
                if (response.success === true) {
                    this.lastResult = response.result;
                    this.convertDataForView();
                } else {
                    this.utils.handleError('error retrieving the schedule', response.error);
                }
            },
            error: error => {
                this.utils.handleError('error retrieving the schedule', error);
            }
        });
        this.getRoastScheduleYear$.next();
    }

    ngAfterViewInit() {
        const primarybuttonEl = this._primarybutton?._elementRef?.nativeElement;
        if (primarybuttonEl) {
            // "rgb(20, 123, 179)"
            this.primaryColor = getComputedStyle(primarybuttonEl)?.backgroundColor;
        }
        const accentbuttonEl = this._accentbutton?._elementRef?.nativeElement;
        if (accentbuttonEl) {
            // "rgb(204, 15, 80)"
            this.accentColor = getComputedStyle(accentbuttonEl)?.backgroundColor;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    // TODO optimize!
    private calcColor(num: number, min: number, max: number): string {
        const minCol = 0.2;
        const relNum = ((num - min) / (max - min)) * (1 - minCol) + minCol;
        // from "rgb(20, 123, 179)" to "rgba(20, 123, 179, 0.5)"
        return `rgba(${this.primaryColor.substring(4, this.primaryColor.length - 1)}, ${relNum})`;
    }

    private convertDataForView(): void {
        if (!this.lastResult) {
            this.lastResult = [];
        }

        // calculate min and max amount values and nr of months with data
        const min = 0;
        let max = 0;
        let nrMonthsWithData = 0;
        let lastMonth = -1;
        let firstDate = DateTime.fromISO(this.lastResult[0]?.date);
        if (firstDate.isValid) {
            lastMonth = firstDate.month;
            nrMonthsWithData = 1;
        } else {
            firstDate = this.startDate;
        }
        for (const data of this.lastResult) {
            if (data.amount > max) {
                max = data.amount;
            }
            const date = DateTime.fromISO(data.date);
            if (date.isValid && date.month !== lastMonth) {
                const newNrMonths = date.month - lastMonth;
                nrMonthsWithData += newNrMonths < 0 ? newNrMonths + 12 : newNrMonths;
                lastMonth = date.month;
            }
        }
        // number of empty months before the first month with data
        const addMonths = firstDate.month - this.startDate.month;
        // total number of displayed months
        const nrMonths = nrMonthsWithData + (addMonths < 0 ? addMonths + 12 : addMonths);

        const endDate = this.startDate.plus({ year: 1 }).minus({ weeks: 2 }).endOf('month');
        this.yearDatas = [];
        let d = 0;
        let month = 0;
        // max number of months per column; min 2 months vertically
        const maxMonths = Math.max(Math.ceil(nrMonths / this.lastSize), 2);

        // skip those from before the start date (should not be any)
        while (d < this.lastResult.length && (!this.lastResult[d]?.date || this.lastResult[d].date < this.startDate.toISODate())) {
            d += 1;
        }

        let days = 0;
        let date = this.startDate;
        for (let colIdx = 0; colIdx < this.lastSize; colIdx++) {
            if (days > this.MINDAYS && d >= this.lastResult.length && date.day === 1) {
                // stop if no more data is available and the month finished
                break;
            }

            this.yearDatas[colIdx] = [];

            // fill first week with empty days from previous month
            for (let i = date.localWeekday - 1; i > 0; i--) {
                const dayData = {
                    date: date.minus({ days: i }),
                    amount: undefined,
                    amountStr: '-',
                    color: 'none',
                };
                this.yearDatas[colIdx].push(dayData);
            }

            // fill year
            while (date <= endDate) {
                if (days > this.MINDAYS && d >= this.lastResult.length && date.day === 1) {
                    // stop if no more data is available and the month finished
                    break;
                }
                let dayData: { date: DateTime, amount: number, amountStr: string, color: string };
                if (date.toISODate() === this.lastResult[d]?.date) {
                    dayData = {
                        date,
                        amount: this.lastResult[d].amount,
                        amountStr: this.utils.formatAmount(this.lastResult[d].amount, undefined, this.mainUnit),
                        // TODO optimize!
                        color: this.calcColor(this.lastResult[d].amount, min, max),
                    };
                    d += 1;
                } else {
                    // placeholder data
                    dayData = {
                        date,
                        amount: undefined,
                        amountStr: undefined,
                        color: 'none',
                    };
                }
                this.yearDatas[colIdx].push(dayData);
                date = date.plus({ day: 1 });
                days += 1;
                if (date.day === 1) {
                    // new month
                    month += 1;
                    // if reached max nr of months (except are in last column and MINDAYS not reached)
                    if (month >= maxMonths && !(colIdx === this.lastSize - 1 && days < this.MINDAYS)) {
                        // switch to next column
                        month = 0;
                        break;
                    }
                }
            }

            // fill days from next month until week is complete
            for (let i = 0; i < 7 - date.minus({ days: 1 }).localWeekday; i++) {
                const dayData = {
                    date: date.plus({ days: i }),
                    amount: undefined,
                    amountStr: '-',
                    color: 'none',
                };
                this.yearDatas[colIdx].push(dayData);
            }
        }
    }

    // private getRoastScheduleYear(): void {
    //     const startDateStr = this.startDate.toISODate();

    //     // TODO optimize: esp. when browsing months, we already have 11/12 of the data
    //     // TODO when scrolling fast, previous requests are not cancelled
    //     this.startLoading.emit();
    //     this.schedulerService.getScheduleYear(startDateStr, this.machineFilter, this.userFilter)
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
    //         .pipe(finalize(() => {
    //             this.doneLoading.emit();
    //         }))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     this.lastResult = response.result;
    //                     this.convertDataForView();
    //                 } else {
    //                     this.utils.handleError('error retrieving the schedule', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 this.utils.handleError('error retrieving the schedule', error);
    //             }
    //         });
    // }

    private callGetRoastScheduleYear(): Observable<{ success: boolean, result: { date: string; amount: number; }[], error: string }> {
        const startDateStr = this.startDate.toISODate();
        // TODO optimize: esp. when browsing months, we already have 11/12 of the data
        // but need to check whether machineFilter or userFilter changed!
        this.startLoading.emit();
        return this.schedulerService.getScheduleYear(startDateStr, this.machineFilter, this.userFilter);
    }

    public setFilter(machineFilter: string[], userFilter: InvitedUserInfo[]) {
        this.machineFilter = machineFilter;
        this.userFilter = userFilter;

        // don't try to call during onInit
        if (this.getRoastScheduleYear$) {
            this.getRoastScheduleYear$.next();
        }
    }

    protected openDate(date: DateTime, view: 's' | 'm' = 's'): void {
        if (date?.isValid) {
            if (view === 'm') {
                date = date.startOf('month');
            }
            this.jumpToDate.emit({ date, view });
        }
    }

    protected rowsForMonth(monthDate: DateTime, isTopMonth: boolean): number {
        if (!monthDate) {
            return 1;
        }
        // including indent at the beginning of the month
        const rows = Math.ceil((monthDate.daysInMonth + (monthDate.localWeekday - 1)) / 7);
        if (!isTopMonth && monthDate.localWeekday !== this.STARTWEEKDAY) {
            // overlap with previous month
            return rows - 1;
        }
        return rows;
    }

    private updateViewForSize(size: number): void {
        if (size !== this.lastSize) {
            this.lastSize = size;
            this.convertDataForView();
        }
    }
}
