/* eslint-disable @typescript-eslint/no-explicit-any */
 
import { Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';

import { DateTime } from 'luxon';
import 'chartjs-adapter-luxon';
import 'chartjs-plugin-stacked100';
import { Chart, LineController, LineElement, LinearScale, PointElement, Title, Legend, Tooltip, TooltipItem, ChartConfiguration } from 'chart.js';
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, Legend, Tooltip);

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-roastambient-graph',
    templateUrl: './roastambient-graph.component.html',
})

export class RoastambientGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private userService: UserService,
        private breakpointObserver: BreakpointObserver,
        private utils: Utils,
        private router: Router,
        @Inject(LOCALE_ID) public locale: string,
    ) {
    }

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    // needed to have the correct order of ngOnInit and newData setter
    inited = false;
    updData: unknown;

    @Input() temperatureUnit = '°C';
    @Input() currentUser: UserType;
    @Input() isDarkmode = false;

    _small = false;
    @Input() set isSmall(is: boolean) {
        this._small = is;
        if (this.chart) {
            this.options.plugins.legend.display = !is;
            this.options.scales['left-y-axis'].title.display = !is;
            this.options.scales['right-y-axis'].title.display = !is;
            this.options.scales['right-yy-axis'].title.display = !is;
            this.chart.render();
        }
    }
    _isRatherSmall = false;
    isRatherSmall$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 999px)')
        .pipe(map(result => result.matches));

    haveData = false;

    data: ChartConfiguration<'line'>['data'];

    options: ChartConfiguration<'line'>['options'] & { plugins: { roughness?: any } } = {
        plugins: {
            roughness: {
                disabled: true,
            },
            // rough: false,
            title: {
                display: true,
                text: this.tr.anslate('Ambient'),
                position: 'top' as const,
            },
            legend: {
                display: true,
                position: 'bottom' as const,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                mode: 'index' as const, // 'nearest',
                intersect: false,
                backgroundColor: 'rgba(66,66,66,0.8)', // '#424242',
                titleColor: '#fff',
                bodyColor: 'rgba(0, 0, 0, 0)',
                // titleColor: '#fff',
                // bodyColor: 'rgba(0, 0, 0, 0)',
                callbacks: {
                    title: (tooltipItems: TooltipItem<'line'>[]) => {
                        if (tooltipItems[0]) {
                            const index = tooltipItems[0].dataIndex;
                            const d: string = this.data.datasets[0].data[index]['x'];
                            return DateTime.fromISO(d).toLocaleString(DateTime.DATE_MED);
                        }
                    },
                    label: (tooltipItem: TooltipItem<'line'>) => {
                        if (tooltipItem.datasetIndex === 0) {
                            return this.tr.anslate('Temperature') + ': ' + tooltipItem.formattedValue + this.temperatureUnit;
                        } else if (tooltipItem.datasetIndex === 1) {
                            return this.tr.anslate('Humidity') + ': ' + tooltipItem.formattedValue + '%';
                        } else {
                            return this.tr.anslate('Pressure') + ': ' + tooltipItem.formattedValue + 'hPa';
                        }
                    },
                }
            },
        },
        layout: {
            padding: {
                left: 0,
                right: 20,
                top: 20,
                bottom: 20
            }
        },
        hover: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
            x: {
                type: 'time' as const,
                display: true,
                time: {
                    unit: 'month' as const,
                    // distribution: 'series' as const,
                },
                title: {
                    display: false,
                },
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.1)'
                },
                ticks: {
                    autoSkip: true,
                    // maxRotation: 0,
                    callback: (value: string | number, index: number, values: unknown[]) => {
                        try {
                            return DateTime.fromJSDate(new Date(values[index]['value'])).toFormat(this._isRatherSmall ? 'LLL' : 'LLLL');
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        } catch (err) {
                            return value;
                        }
                    },
                },
            },
            'left-y-axis': {
                display: true,
                type: 'linear' as const,
                position: 'left' as const,
                ticks: {
                    color: '#ad0427',
                    autoSkip: true,
                    maxTicksLimit: 6
                },
                title: {
                    display: true,
                    color: '#ad0427',
                    text: this.temperatureUnit
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.1)'
                },
            },
            'right-y-axis': {
                display: true,
                type: 'linear' as const,
                position: 'right' as const,
                grid: {
                    display: false
                },
                suggestedMin: 40,
                suggestedMax: 50,
                ticks: {
                    color: '#1985ba',
                    autoSkip: true,
                    maxTicksLimit: 6
                },
                title: {
                    display: true,
                    color: '#1985ba',
                    text: '%'
                }
            },
            'right-yy-axis': {
                display: true,
                type: 'linear' as const,
                position: 'right' as const,
                grid: {
                    display: false
                },
                suggestedMin: 990,
                suggestedMax: 1000,
                ticks: {
                    color: '#616161',
                    // fontColor: '#616161',
                    autoSkip: true,
                    maxTicksLimit: 6
                },
                title: {
                    display: true,
                    color: '#616161',
                    text: 'hPa'
                }
            },
        },
        aspectRatio: 0.9,
        maintainAspectRatio: false,
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }

        this.locale = this.locale ? this.locale.substring(0, 2) : 'en';

        this.inited = true;
        // temperatureUnit @Input is set after the options object is initialized
        this.options.scales['left-y-axis'].title.text = this.temperatureUnit;
        if (this.updData) {
            this.setNewData(this.updData);
            this.updData = undefined;
        }

        this.isRatherSmall$.subscribe(val => this._isRatherSmall = val);
    }

    setNewData(nd: any): void {
        this.haveData = false;
        if (nd?.data?.length > 1 ||
            (nd?.additionalData?.length && nd.additionalData[0]?.dates?.length > 1)) {
            if (!this.inited) {
                // store new data until inited
                this.updData = nd;
                return;
            }

            this.data = { datasets: [] };

            // we only take data from the last 12 month
            const data_period = new Date();
            data_period.setMonth(data_period.getMonth() - 12);

            // 1. collect data

            const tempEntries = [];
            const humidityEntries = [];
            const pressureEntries = [];

            const timestamps = nd.additionalData[0]?.dates;

            // collect last year's temperatures
            if (timestamps?.length &&
                nd.additionalData?.length > 7 &&
                nd.additionalData[7]?.data?.length === timestamps.length) {
                for (let i = 0; i < nd.additionalData[7].data.length; i++) {
                    let val = nd.additionalData[7].data[i];
                    if (val) {
                        val = Math.round(this.utils.convertTemp(val, this.currentUser.temperature_system) * 10) / 10;
                        const d = new Date(timestamps[i]);
                        if (d > data_period) {
                            tempEntries.push({ y: val, x: d.toISOString() });
                        }
                    }
                }
            }
            // collect this year's temperatures
            if (nd.labels?.length &&
                nd.additionalData?.length > 4 &&
                nd.additionalData[4]?.data.length === nd.labels.length) {
                for (let i = 0; i < nd.additionalData[4].data.length; i++) {
                    let val = nd.additionalData[4].data[i];
                    if (val) {
                        val = Math.round(this.utils.convertTemp(val, this.currentUser.temperature_system) * 10) / 10;
                        const d = new Date(nd.labels[i]);
                        if (d > data_period) {
                            tempEntries.push({ y: val, x: d.toISOString() });
                        }
                    }
                }
            }
            // collect last year's humidity
            if (timestamps?.length > 0 &&
                nd.additionalData?.length > 9 &&
                nd.additionalData[9]?.data?.length === timestamps.length) {
                for (let i = 0; i < nd.additionalData[9].data.length; i++) {
                    const val = nd.additionalData[9].data[i];
                    if (val) {
                        const d = new Date(timestamps[i]);
                        if (d > data_period) {
                            humidityEntries.push({ y: val, x: d.toISOString() });
                        }
                    }
                }
            }
            // collect this year's humidity
            if (nd.labels?.length &&
                nd.additionalData?.length > 6 &&
                nd.additionalData[6]?.data.length === nd.labels.length) {
                for (let i = 0; i < nd.data.length; i++) {
                    const val = nd.additionalData[6].data[i];
                    if (val) {
                        const d = new Date(nd.labels[i]);
                        if (d > data_period) {
                            humidityEntries.push({ y: val, x: d.toISOString() });
                        }
                    }
                }
            }
            // collect last year's pressure
            if (timestamps?.length &&
                nd.additionalData?.length > 8 &&
                nd.additionalData[8]?.data?.length === timestamps.length) {
                for (let i = 0; i < nd.additionalData[8].data.length; i++) {
                    const val = nd.additionalData[8].data[i];
                    if (val) {
                        const d = new Date(timestamps[i]);
                        if (d > data_period) {
                            pressureEntries.push({ y: val, x: d.toISOString() });
                        }
                    }
                }
            }
            // collect this year's pressure
            if (nd.labels?.length &&
                nd.additionalData?.length > 5 &&
                nd.additionalData[5]?.data.length === nd.labels.length) {
                for (let i = 0; i < nd.data.length; i++) {
                    const val = nd.additionalData[5].data[i];
                    if (val) {
                        const d = new Date(nd.labels[i]);
                        if (d > data_period) {
                            pressureEntries.push({ y: val, x: d.toISOString() });
                        }
                    }
                }
            }

            // 2. generate data sets

            if (tempEntries.length > 1) {
                this.haveData = true;
                this.data.datasets.push({
                    label: this.tr.anslate('Temperature'),
                    data: tempEntries,
                    yAxisID: 'left-y-axis',
                    fill: false,
                    borderColor: this.isDarkmode ? '#c00b40' : 'rgba(173, 4, 39, 0.6)', // '#ad0427',  'rgba(204, 5, 45, 1)'
                    backgroundColor: this.isDarkmode ? '#c00b40' : 'rgba(173, 4, 39, 0.5)', // '#ad0427',
                    pointBackgroundColor: this.isDarkmode ? '#c00b40' : 'rgba(173, 4, 39, 0.7)', // '#ad0427',
                    pointBorderColor: this.isDarkmode ? '#c00b40' : 'rgba(173, 4, 39, 0.7)', // '#ad0427',
                    borderWidth: 2,
                    pointRadius: 1,
                    pointHoverRadius: 4,
                    tension: 0.4,
                });
                this.options.scales['left-y-axis'].display = true;
            } else {
                this.options.scales['left-y-axis'].display = false;
            }
            if (humidityEntries.length > 1) {
                this.haveData = true;
                this.data.datasets.push({
                    label: this.tr.anslate('Humidity'),
                    data: humidityEntries,
                    yAxisID: 'right-y-axis',
                    fill: false,
                    borderColor: this.isDarkmode ? 'rgba(25, 133, 186, 0.9)' : 'rgba(25, 133, 186, 0.6)', // '#1985ba',
                    backgroundColor: this.isDarkmode ? 'rgba(25, 133, 186, 0.9)' : 'rgba(25, 133, 186, 0.5)', // '#1985ba',
                    pointBackgroundColor: this.isDarkmode ? 'rgba(25, 133, 186, 0.9)' : 'rgba(25, 133, 186, 0.7)', // '#1985ba',
                    pointBorderColor: this.isDarkmode ? 'rgba(25, 133, 186, 0.9)' : 'rgba(25, 133, 186, 0.7)', // '#1985ba',
                    borderWidth: 2,
                    pointRadius: 1,
                    pointHoverRadius: 4,
                    tension: 0.4,
                });
                this.options.scales['right-y-axis'].display = true;
            } else {
                this.options.scales['right-y-axis'].display = false;
            }
            if (pressureEntries.length > 1) {
                this.haveData = true;
                this.data.datasets.push({
                    label: this.tr.anslate('Pressure'),
                    data: pressureEntries,
                    yAxisID: 'right-yy-axis',
                    fill: false,
                    borderColor: this.isDarkmode ? '#9e9e9eDD' : 'rgba(97, 97, 97, 0.6)', // dark: 9e9e9e, light: '#616161',
                    backgroundColor: this.isDarkmode ? '#9e9e9eDD' : 'rgba(97, 97, 97, 0.5)', // '#616161',
                    pointBackgroundColor: this.isDarkmode ? '#9e9e9eDD' : 'rgba(97, 97, 97, 0.7)', // '#616161',
                    pointBorderColor: this.isDarkmode ? '#9e9e9eDD' : 'rgba(97, 97, 97, 0.7)', // '#616161',
                    borderWidth: 2,
                    pointRadius: 1,
                    pointHoverRadius: 4,
                    tension: 0.4,
                });
                this.options.scales['right-yy-axis'].display = true;
            } else {
                this.options.scales['right-yy-axis'].display = false;
            }

            if (this.isDarkmode) {
                this.options.scales.x.grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales.x.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                this.options.scales['left-y-axis'].grid.color = 'rgba(255, 255, 255, 0.2)';
                // this.options.scales['left-y-axis'].grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
                this.options.scales['left-y-axis'].ticks.color = '#DD0D4A';
                this.options.scales['left-y-axis'].title.color = '#DD0D4A';
                this.options.scales['right-y-axis'].ticks.color = 'rgba(28, 146, 205, 1)';
                this.options.scales['right-y-axis'].title.color = 'rgba(28, 146, 205, 1)';
                this.options.scales['right-yy-axis'].ticks.color = '#9f9f9fEE';
                this.options.scales['right-yy-axis'].title.color = '#9f9f9fEE';
                this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
                this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
                // this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
                // this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
                this.options.plugins.tooltip.backgroundColor = '#eeeeeeDD'; // G200/DD => '#eeeeee'
            } else {
                this.options.scales.x.grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales.x.grid.zeroLineColor = 'rgba(0, 0, 0, 0.1)';
                this.options.scales['left-y-axis'].grid.color = 'rgba(0, 0, 0, 0.1)';
                // this.options.scales['left-y-axis'].grid.zeroLineColor = 'rgba(0, 0, 0, 0.1)';
                this.options.scales['left-y-axis'].ticks.color = '#ad0427';
                this.options.scales['left-y-axis'].title.color = '#ad0427';
                this.options.scales['right-y-axis'].ticks.color = '#1985ba';
                this.options.scales['right-y-axis'].title.color = '#1985ba';
                this.options.scales['right-yy-axis'].ticks.color = '#616161';
                this.options.scales['right-yy-axis'].title.color = '#616161';
                this.options.plugins.tooltip.titleColor = '#fff';
                this.options.plugins.tooltip.bodyColor = '#fff';
                // this.options.plugins.tooltip.titleColor = '#fff';
                // this.options.plugins.tooltip.bodyColor = '#fff';
                this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
            }

            this.chart?.render();
        }
    }
}
