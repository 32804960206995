import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class PriceService {

    constructor(
        private http: HttpClient,
    ) { }

    getLastPurchasePrices(): Observable<{ success: boolean, result: { coffee_internal_hr_id: number, price: number, amount: number, date: DateTime, type: string }[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/lastpurchaseprices';
        return this.http.get<{ success: boolean, result: { coffee_internal_hr_id: number, price: number, amount: number, date: DateTime, type: string }[], error: string }>(url);
    }

}
