import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { FilterComponent, IdAndLabelArrayOrNotnull } from './filter.component';
import { EMPTYTAG } from './filter-utils';
import { FilterService } from './filter.service';
import { NgClass, NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-checkbox-filter',
    templateUrl: './checkbox-filter.component.html',
    styleUrls: ['./checkbox-filter.component.scss'],
    standalone: true,
    imports: [MatCheckbox, NgClass, NgStyle, FormsModule],
})
export class CheckboxFilterComponent<T> implements FilterComponent {

    constructor(
        private filterService: FilterService,
    ) { }

    @Input() disabled = false;
    
    @Input() multiple = true;
    @Input() atLeastOne = false;
    @Input() warnNonSelected = true;
    @Input() inlineCheckbox = false;

    allOptionsButFirst: string[];
    private _allOptions: string[];
    get allOptions(): string[] {
        return this._allOptions;
    }
    @Input() set allOptions(op: string[]) {
        this.showError = false;
        if (!op) {
            op = [];
        }
        this._allOptions = op;
        this.noValueFound = false;
        if (!op.length) {
            this.noValueFound = true;
            this.selected = [];
        } else {
            if (this.showAllowNull) {
                this.selected[0] = false;
                if (this.multiple) {
                    // select all but null by default
                    this.selected.length = this._allOptions.length;
                    for (let i = 1; i < this._allOptions.length; i++) {
                        this.selected[i] = true;
                    }
                } else if (this._allOptions.length) {
                    // select first (non null)
                    this.selected.length = 2;
                    this.selected[1] = true;
                }
                this.allOptionsButFirst = this._allOptions.slice(1);
            } else if (this._allOptions.length === 1) {
                // entry at index 0 is not used; reserved for allowNull
                this.selected = [undefined, true];
                // use all entries here, i.e. the only one
                this.allOptionsButFirst = this._allOptions.slice();
            } else if (this._allOptions.length) {
                if (this.multiple) {
                    // select all by default
                    this.selected.length = this._allOptions.length;
                    for (let i = 0; i < this._allOptions.length; i++) {
                        this.selected[i] = true;
                    }
                } else {
                    // select first (non null)
                    this.selected = [undefined, true];
                }
                this.allOptionsButFirst = this._allOptions.slice();
            }
        }
        setTimeout(() => { this.emitChanges(); }, 0);

    }

    @Input() nrDigits: number;
    @Input() unit: string;
    @Input() additionalValues: string[];
    @Input() additionalValueWarns: boolean[] = [];

    @Input() dontAllowNull = false;
    @Input() showAllowNull = true;

    i18nPlaceholder: string;
    @Input() set placeholder(ph: string) {
        this.i18nPlaceholder = this.filterService.translateAttr(ph);
    }

    @Input() margin_top = -7;
    @Input() width: string;

    @Output() selectChanged = new EventEmitter<string[]>();

    selectedOptions: string[] = [];
    selected: boolean[] = [];
    noValueFound = false;
    showError = false;

    EMPTYTAG = EMPTYTAG;


    optionChanged(idx: number, event: MatCheckboxChange) {
        this.showError = false;
        if (this.multiple) {
            if (!event.checked && this.atLeastOne) {
                const cntSelected = this.selected.reduce((prev, cur) => prev + (cur ? 1 : 0), 0);
                if (cntSelected === 1) {
                    // don't allow deselecting the only selected item
                    this.selected[idx] = false;
                    setTimeout(() => {
                        this.selected[idx] = true;
                    }, 0);
                    return;
                }
            }
            this.selected[idx] = event.checked;
        } else {
            if (event.checked) {
                // deselect others if any
                for (let i = 0; i < this._allOptions.length; i++) {
                    this.selected[i] = i === idx;
                }
            } else {
                if (this.atLeastOne) {
                    // don't allow deselecting
                    this.selected[idx] = false;
                    setTimeout(() => {
                        this.selected[idx] = true;
                    }, 0);
                    return;
                }
                this.selected[idx] = false;
            }
        }
        this.emitChanges();
    }

    emitChanges(): void {
        if (!this.showAllowNull && this.allOptions.length === 1) {
            // use value at selected[1] not at index 0
            this.selectedOptions = this.selected[1] ? this.allOptions.slice() : [];
            this.selectChanged.emit(this.selectedOptions);
        } else {
            this.selectedOptions = this.allOptions.filter((_option, idx) => this.selected[idx]);
            if (!this.selectedOptions.length) {
                this.showError = this.warnNonSelected;
                this.selectChanged.emit([]);
            } else if (this.selectedOptions.length === this.allOptions.length) {
                // if the user manually selects all items, send the selectedOptions as []
                // as it is semantically the same and uses less resources
                this.selectChanged.emit([]);
            } else {
                this.selectChanged.emit(this.selectedOptions);
            }
        }
    }

    reset(): void {
        // this is the case for 'discarded' / 'reconciled'
        this.selected.length = this._allOptions.length + (this.showAllowNull ? 0 : 1);
        for (let i = 0; i < this._allOptions.length; i++) {
            this.selected[i] = false;
        }
    }

    setValues(options: IdAndLabelArrayOrNotnull<T> | boolean): void {
        this.reset();
        if (typeof options === 'boolean') {
            // this is the case for 'discarded' / 'reconciled'
            this.selected[1] = options;
        } else if (options.notnull) {
            for (let i = 0; i < this._allOptions.length; i++) {
                const allOption = this._allOptions[i];
                if (allOption) {
                    this.selected[i] = true;
                    if (!this.multiple) {
                        break;
                    }
                }
            }
        } else {
            let found = false;
            for (let o1 = 0; o1 < options?.vals?.length && !found; o1++) {
                const option = options.vals[o1];
                for (let o2 = 0; o2 < this._allOptions.length; o2++) {
                    const allOption = this._allOptions[o2];
                    if (option?.toString() === allOption?.toString()) {
                        this.selected[o2] = true;
                        if (!this.multiple) {
                            found = true;
                            break;
                        }
                    }
                }
            }
        }
        this.emitChanges();
    }
}
