import { ApDatetimeModule } from 'src/app/modules/ui/ap-datetime.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { TransactionsComponent } from './transactions.component';
import { RouterModule } from '@angular/router';
import { PurchaseDialogComponent } from './dialogs/purchase-dialog.component';
import { CorrectDialogComponent } from './dialogs/correct-dialog.component';
import { TransferDialogComponent } from './dialogs/transfer-dialog.component';
import { SellDialogComponent } from './dialogs/sell-dialog.component';
import { FormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UiModule } from '../ui/ui.module';

@NgModule({
    declarations: [
        TransactionsComponent,
        PurchaseDialogComponent,
        CorrectDialogComponent,
        TransferDialogComponent,
        SellDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        UiModule,
        MyMaterialModule,
        ApDatetimeModule,
        NgxMatSelectSearchModule,
    ],
    exports: [
        TransactionsComponent,
    ],
    providers: [],
})
export class TransactionsModule { }
