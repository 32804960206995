// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference types="@angular/localize" />

// /**
//  * Load`$localize` onto the global scope - used if i18n tags appear in Angular templates.
//  */
// import '@angular/localize/init';

// copied from node_modules/@angular/localize/fesm2022/init.mjs
// since the import of localize above was ignored by webpack
import { ɵ$localize } from '@angular/localize';
export { ɵ$localize as $localize } from '@angular/localize';
// Attach $localize to the global context, as a side-effect of this module.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(globalThis as any).$localize = ɵ$localize;

import { enableProdMode/*, TRANSLATIONS, TRANSLATIONS_FORMAT, MissingTranslationStrategy*/ } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule,
    // {
    //     missingTranslation: MissingTranslationStrategy.Ignore
    // }
).catch(err => console.log(err));
