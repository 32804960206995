import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { UserService } from 'src/app/modules/frame/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {

    constructor(
        private router: Router,
        private logger: NGXLogger,
        private userService: UserService
    ) { }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUser = this.userService.getCurrentUser();
        if (currentUser) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.userService.logout();

        // avoid potential endless redirect loop
        if (state.url !== '/welcome') {
            this.logger.debug('user not logged in, go to login page with returnUrl ', state.url);
            this.router.navigate(['/welcome'], { queryParams: { returnUrl: state.url } });
        }
        return false;
    }
}
