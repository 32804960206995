import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FilterComponent } from './filter.component';
import { FilterService } from './filter.service';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
    selector: 'app-checkboxandtext-filter',
    templateUrl: './checkboxandtext-filter.component.html',
    styleUrls: ['./checkboxandtext-filter.component.scss'],
})
export class CheckboxandtextFilterComponent implements OnInit, OnDestroy, FilterComponent {

    constructor(
        private filterService: FilterService,
    ) { }

    @Input() disabled = false;

    i18nPlaceholder: string;
    @Input() set placeholder(ph: string) {
        this.i18nPlaceholder = this.filterService.translateAttr(ph);
    }

    @Output() filterChanged = new EventEmitter<{ checkbox: boolean, text: string }>();

    selected = true;
    text: string;
    $textChanged: Subject<string> = new Subject<string>();
    textDisabled = false;

    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        this.$textChanged.pipe(
            debounceTime(800),
            distinctUntilChanged(),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.textChanged());

        this.emitChanges();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    textChanged() {
        this.selected = true;
        this.emitChanges();
    }

    selectChanged() {
        if (!this.selected) {
            this.text = undefined;
        //     this.textDisabled = true;
        // } else {
        //     this.textDisabled = false;
        }
        this.emitChanges();
    }

    emitChanges(): void {
        this.filterChanged.emit({ checkbox: this.selected, text: this.text });
    }

    reset(): void {
        this.selected = true;
        this.text = undefined;
    }

    setValues(value: { allowNull?: 'yes' | 'only' | 'no', vals?: string[] }): void {
        this.reset();
        this.selected = value.allowNull == null || value.allowNull === 'no';
        this.text = value.vals?.[0];
        this.emitChanges();
    }
}
