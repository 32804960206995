import { version } from './version';

export const environment = {
    production: true,
    SUB_API_URL: '/api/v1',
    RELOADTHROTTLE: 1000,
    BASE_API_URL: 'https://artisan.plus',
    latestWebVersion: version.latestWebVersion,
    buildNr: version.buildNr,
    MAX_UPLOAD_SIZE_MB: 2,
};
