<div class="split-canvas">
    <as-split direction="vertical" [gutterSize]="15" (dragEnd)="onDragEnd($event)" (gutterClick)="onGutterClick($event)" [gutterDblClickDuration]="0" [useTransition]="true">
        <as-split-area [size]="splitSizes[0]">
            @if (splitSizes[0] > 5) {
            <div>
                <div style="position: relative;">
                    <h2 i18n="menu$$menu entry Reminders@@Reminders menu label" class="main-heading vertcenter-heading">Reminders</h2>
                    <button [disabled]="editMode >= 0" (click)="add()"
                        type="button" class="right-centered-button add-button action-button" [ngClass]="{'centered-button': (isLarge$ | async)}" mat-raised-button>
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                @if (!maxObjectCount && !idToHighlight && !loading) {
                <mat-hint data-test="hnt_reminders" class="hint-left">
                    <span i18n="reminders$$hint explaining the concept of reminders@@Here you can let the system remind you of recurring tasks such as 'Clean thermocouple every week'.">
                        Here you can let the system remind you of recurring tasks such as 'Clean thermocouple every week'.
                    </span>
                </mat-hint>
                }
                @if (readOnly) {
                <div class="readonly-spacer"></div>
                }
                @if (maxObjectCount > 5) {
                <mat-paginator [disabled]="editMode >= 0"
                    [length]="maxObjectCount" [pageIndex]="pageIndex"
                    [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 50]"
                    (page)="pagingChanged($event)">
                </mat-paginator>
                }
                @if (filteredObjects?.length) {
                <mat-accordion displayMode="default" multi="true">
                    @for (reminder of filteredObjects; track reminder?._id) {
                    <app-reminder #reminderElem
                        [readOnly]="readOnly"
                        [currentUser]="currentUser"
                        [targetUsers]="targetUsers"
                        [index]="$index"
                        [idToHighlight]="idToHighlight"
                        [(editMode)]="editMode"
                        [(isNew)]="isNew"
                        (addCancelled)="addCancelled()"
                        (deleted)="deleted($event, $index)"
                        (updated)="updated($event, $index)"
                        (logChanged)="logChanged($event)"
                        (machinesChanged)="machinesChanged($index)"
                        [machines]="machines"
                        [reminder]="reminder"
                        [(gasUnit)]="gasUnit"
                        [(isExpanded)]="expandeds[$index]">
                    </app-reminder>
                    }
                </mat-accordion>
                }
                <!-- <mat-paginator *ngIf="maxObjectCount >= 5 && filteredObjects?.length > 0 && pageSize >= 10" [length]="filteredObjectCount" [pageSize]="pageSize" [disabled]="editMode >= 0 || isNew >= 0"
          [pageSizeOptions]="[5, 10, 25, 50]" [pageIndex]="pageIndex" (page)="pagingChanged($event)">
        </mat-paginator> -->
                @if (loading) {
                <mat-spinner class="main-note" diameter="20"></mat-spinner>
                }
                <!-- <div *ngIf="!readOnly && filteredObjects?.length === 0 && !idToHighlight">
        <p class="main-note" i18n="generic$$no items found, hint at how to add one@@nothing to show plus hint, add with button">Currently, there is nothing to show. Add using the button at the top!</p>
      </div> -->
                @if ((idToHighlight && !maxObjectCount)) {
                <div>
                    <p class="main-note" i18n="generic$$no matches found using the given filter@@no match for filter">No matches found</p>
                </div>
                }
                <!-- <div *ngIf="!readOnly && maxObjectCount === 0 && filteredObjects && !idToHighlight">
      <p class="main-note" i18n="generic$$no items found, hint at how to add one@@nothing to show plus hint, add with button">Currently, there is nothing to show. Add using the button at the top!</p>
    </div>
    <div *ngIf="(idToHighlight && maxObjectCount === 0) || (maxObjectCount > 0 && filteredObjects?.length === 0)">
      <p class="main-note" i18n="generic$$no matches found using the given filter@@no match for filter">No matches found</p>
    </div> -->
                <!-- <ng-container *ngIf="reminderLog?.length">
    <ul>
      <li *ngFor="let logEntry of reminderLog">
        {{logEntry | json}}
      </li>
    </ul>
  </ng-container> -->
                <!-- <div class="readonly-spacer"></div> -->
            </div>
            }
            <div class="enlargepage-smaller"></div>
        </as-split-area>

        <as-split-area [size]="splitSizes[1]">
            @if (splitSizes[1] > 5) {
            <app-reminderlogtable #logTable
                [currentUser]="currentUser"
                [readOnly]="readOnly"
                [machines]="machines">
            </app-reminderlogtable>
            }

            <!-- <mat-accordion *ngIf="reminderLog?.length" displayMode="default" multi="true">
            <app-reminderlog *ngFor="let logEntry of reminderLog; let m=index"
                [readOnly]="readOnly"
                [currentUser]="currentUser"
                [index]="m"
                [idToHighlight]="idToHighlight"
                [(editMode)]="editMode"
                [isNew]="isNew"
                (addCancelled)="addCancelledLog($event)"
                (deleted)="deletedLog($event, m)"
                (updated)="updatedLog($event, m)"
                [machines]="machines"
                [reminder]="logEntry">
            </app-reminderlog>
            </mat-accordion> -->

            <div class="enlargepage-smaller"></div>
        </as-split-area>

    </as-split>
</div>