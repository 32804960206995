import { DialogModule } from 'src/app/modules/ui/dialog/dialog.module';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { TransactionsModule } from 'src/app/modules/transaction/transactions.module';
import { StockComponent } from './stock.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreStockComponent } from './store-stock.component';
import { RouterModule } from '@angular/router';
import { UiModule } from 'src/app/modules/ui/ui.module';

@NgModule({
    declarations: [
        StockComponent,
        StoreStockComponent,
    ],
    imports: [
        CommonModule,
        TransactionsModule,
        MyMaterialModule,
        RouterModule,
        DialogModule,
        UiModule,
    ],
    exports: [
        StockComponent,
        StoreStockComponent,
    ],
    providers: [
    ]
})
export class StockModule { }
