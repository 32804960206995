import { PathLocationStrategy } from '@angular/common';
import { Injectable, ErrorHandler } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { throttleTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServerLogService } from './services/server-log.service';
import * as StackTrace from 'stacktrace-js';
import { Error as MyError } from 'src/app/models/Error';
import { UserService } from 'src/app/modules/frame/services/user.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private location: PathLocationStrategy,
        private serverLogService: ServerLogService,
        private logger: NGXLogger,
        private userService: UserService,
    ) { }

    handleError(error: Error): void {
        this.logger.error(error);
        const url = this.location instanceof PathLocationStrategy ? this.location.path() : '';
        // conv to JSON (or string) is empty for Error: https://stackoverflow.com/q/18391212/13171715
        const errCopy: MyError = new MyError();
        Object.getOwnPropertyNames(error).forEach(function (key) {
            errCopy[key] = error[key];
        });

        StackTrace.fromError(error)
            .then(stackframes => {
                try {
                    const stackString = stackframes
                        .splice(0, 4)
                        .map(function (sf) {
                            return sf.toString();
                        }).join('\n');
                    // errCopy.stack2 = stackString;
                    errCopy.stack = stackString;
                    errCopy.details = url;
                    // if (stackframes && stackframes[0]) {
                    //     errCopy.line = stackframes[0].lineNumber;
                    //     errCopy.column = stackframes[0].columnNumber;
                    //     errCopy.file = stackframes[0].fileName;
                    // }
                } catch (err) {
                    // ignore
                    this.logger.debug('could not get stack frame: ' + err);
                }
            })
            .catch(reason => {
                this.logger.debug('could not get stack frame: ' + reason);
            })
            .finally(() => {
                const currentUser = this.userService.getCurrentUser();
                if (currentUser?.user_id && currentUser.nickname) {
                    errCopy.user = currentUser?.user_id + ' ' + currentUser.nickname;
                }

                this.serverLogService.errorOccurred(errCopy, 'GlobalErrorHandler.finally')
                    .pipe(throttleTime(environment.RELOADTHROTTLE))
                    .subscribe({
                        next: response => {
                            if (response.success !== true) {
                                this.logger.debug('could not send error, error: ' + response.error);
                            }
                        },
                        error: error => {
                            this.logger.debug('could not send error, error: ' + error);
                        }
                    }); 
            });
    }
}