<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>

<ng-template #blendEntry let-blend="blend" let-isTrigger="isTrigger">
    <div class="smallerfont scheduler-inside-select">
        @if (blend?.hr_id) {
        <span class="littlespaceright">{{blend.hr_id}}</span>
        <app-organicicon [isOrganic]="utils.isOrganicBlend(blend)" [size]="10" [leftMargin]="3"></app-organicicon>
        }
        @if (blend?.blendinfo) {
        <span>{{blend.blendinfo}}</span>
        }
    </div>
    <span style="margin-right: 15px;">{{blend?.label}}</span>
</ng-template>

<ng-template #templateEntry let-template="template" let-isTrigger="isTrigger">
    <div class="smallerfont">
        @if (template?.batch_prefix) {
        <span [ngClass]="{'spaceright': !template.batch_number}">{{template.batch_prefix}}</span>
        }@if (Number.isFinite(template?.batch_number)) {
        <span>{{Math.round(template.batch_number)}}</span>
        }@if (template?.batch_prefix || Number.isFinite(template?.batch_number)) {, }
        @if (template?.amount) {
        <span>{{utils.formatAmount(template.amount, undefined, mainUnit)}}</span>
        }
        @if (template?.end_weight) {
        <span> -> {{utils.formatAmount(template.end_weight, undefined, mainUnit)}}</span>
        }
        @if (template?.machine) {
        @if (template.batch_prefix || template.batch_number || template.amount || template.end_weight) {, }
        <span>{{template.machine}}</span>
        }
        @if (template?.drop_time) {
        @if (template.batch_prefix || template.batch_number || template?.machine || template.amount || template.end_weight) {, }
        <span>{{Math.floor(template.drop_time / 60.0) + ':' + utils.padNumber(template.drop_time % 60, 2)}}</span>&nbsp;<span i18n="unit_system$$short for minutes@@min">min</span>
        }
    </div>
    @if (template?.label) {
    <!-- @if (isTrigger) {
    <div>
        {{coffee.label}}@if (coffee.totalStockStr) {: <span [ngClass]="{'scheduler-warn-amount': !coffee.totalStock || unitamount > coffee.totalStock}">{{coffee.totalStockStr}}</span>}
    </div>
    } @else { -->
    <div style="display: flex;">{{template.label}}</div>
    }
    <!-- } -->
</ng-template>



<h1 mat-dialog-title i18n="generic$$link to dialog to find a roast (template)@@Search roast">Search roast</h1>
<mat-dialog-content style="text-align: start;">

    <div class="dialog-group" style="margin-bottom: 5px;">
        <mat-form-field class="beans-search-formfields" subscriptSizing="dynamic">
            <mat-label i18n="generic$$Title@@Title">Title</mat-label>
            <input matInput data-test="npt_txt" type="text"
                [(ngModel)]="label" cdkFocusInitial>
        </mat-form-field>

        @if (machines?.length) {
        <mat-form-field class="form-group scheduler-narrower-input" subscriptSizing="dynamic">
            <mat-label i18n="roasts$$machine@@Machine">Machine</mat-label>
            <mat-select hideSingleSelectionIndicator="true"
                name="machine" panelWidth="null"
                [(ngModel)]="machine"
                [compareWith]="utils.compareObjectsFn">
                @if (machines.length >= 5) {
                <mat-option>
                    <ngx-mat-select-search ngModel (ngModelChange)="filteredMachines = filterLabelObjects($event, machines)" name="machineSelect"
                        placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                        noEntriesFoundLabel="">
                    </ngx-mat-select-search>
                </mat-option>
                }
                @if (filteredMachines?.length) {
                <mat-option [value]="null"></mat-option>
                }
                @for (machine of filteredMachines; track machine) {
                <mat-option [value]="machine">{{machine}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        }
    </div>

    <div class="dialog-group" style="margin-bottom: 5px; display: flex; align-items: center; width: 355px;">
        <span i18n="roasts$$Batch@@Batch"
            style="margin-right: 10px; font-size: 14px;">Batch</span>
        <div style="display: flex; flex-grow: 5;">
            <mat-form-field class="beans-search-formfields beans-search-prefix" subscriptSizing="dynamic"
                style="flex-grow: 2; text-align: right; margin-right: 5px;">
                <mat-label i18n="roasts$$Batch Prefix (short placeholder)@@Prefix">Prefix</mat-label>
                <input matInput data-test="npt_batchprefix" type="text"
                    [(ngModel)]="batchPrefix">
            </mat-form-field>
            <mat-form-field class="beans-search-formfields" subscriptSizing="dynamic"
                style="width: 100px;">
                <mat-label i18n="reports$$report heading sequential number@@Number">Number</mat-label>
                <input matInput data-test="npt_batchnumber" type="number"
                    [(ngModel)]="batchNumber">
            </mat-form-field>
        </div>
    </div>

    <div class="dialog-group" style="margin-bottom: 5px;">
        <mat-form-field class="form-group beans-search-beans" subscriptSizing="dynamic">
            <mat-label i18n="generic$$Beans@@Beans">Beans</mat-label>
            <mat-select [disabled]="!coffees?.length" hideSingleSelectionIndicator="true"
                placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                name="coffee" panelClass="twoLineOption" panelWidth="null"
                (selectionChange)="beansChanged(false)"
                [(ngModel)]="coffee"
                [compareWith]="utils.compareObjectsFn">
                @if (coffee) {
                <mat-select-trigger class="twoLineOption ellipsed">
                    <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee, isTrigger: true}"></ng-container>
                </mat-select-trigger>
                }
                @if (coffees?.length >= 5) {
                <mat-option>
                    <ngx-mat-select-search ngModel (ngModelChange)="filteredCoffees = utils.filterObjects($event, coffees)" name="coffeeSelect"
                        placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                        noEntriesFoundLabel="">
                    </ngx-mat-select-search>
                </mat-option>
                }
                @if (filteredCoffees?.length) {
                <mat-option [value]="null"></mat-option>
                }
                @for (coffee of filteredCoffees; track coffee?._id) {
                <mat-option [value]="coffee">
                    <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee}"></ng-container>
                </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <button mat-icon-button
            class="template-search-opendialog-icon primary-element"
            (click)="openBeansSearchDialog(true, false)"
            matTooltip="Search beans"
            i18n-matTooltip="generic$$link to dialog to find beans or blends that are not directly dislayed@@Search beans">
            <mat-icon class="scheduler-narrow-icon">search</mat-icon>
        </button>

        <mat-form-field class="form-group beans-search-blends" subscriptSizing="dynamic">
            <mat-label i18n="generic$$Blend@@Blend">Blend</mat-label>
            <mat-select name="blend"
                panelClass="twoLineOption" hideSingleSelectionIndicator="true" panelWidth="null"
                placeholder="Blend" i18n-placeholder="generic$$Blend@@Blend"
                (selectionChange)="beansChanged(true)"
                [compareWith]="utils.compareObjectsFn"
                [(ngModel)]="blend">
                @if (blend) {
                <mat-select-trigger class="twoLineOption ellipsed">
                    <ng-container *ngTemplateOutlet="blendEntry; context: {blend}"></ng-container>
                </mat-select-trigger>
                }
                @if (blends?.length >= 5) {
                <mat-option>
                    <ngx-mat-select-search ngModel (ngModelChange)="filteredBlends = utils.filterObjects($event, blends)"
                        placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                        noEntriesFoundLabel="" name="blendSelect">
                    </ngx-mat-select-search>
                </mat-option>
                }
                @if (filteredBlends?.length) {
                <mat-option [value]="null"></mat-option>
                }
                @for (blend of filteredBlends; track blend?.hr_id) {
                <mat-option [value]="blend">
                    <ng-container *ngTemplateOutlet="blendEntry; context: {blend}"></ng-container>
                </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <button mat-icon-button
            class="template-search-opendialog-icon primary-element"
            (click)="openBeansSearchDialog(false, true)"
            matTooltip="Search beans"
            i18n-matTooltip="generic$$link to dialog to find beans or blends that are not directly dislayed@@Search beans">
            <mat-icon class="scheduler-narrow-icon">search</mat-icon>
        </button>
    </div>

    <div class="roast-template-switch">
        <mat-slide-toggle labelPosition="after" class="primary-element" name="is_template_switch"
            [(ngModel)]="is_template"
            i18n="roasts$$should this roast be available as a template@@Always available as template">
            Always available as template</mat-slide-toggle>
    </div>

    <div style="text-align: center;">
        <button [disabled]="isLoading || (label?.length < 3 && !coffee && !blend && !batchNumber &&!batchPrefix && !machine)"
            (click)="doSearch()" type="button" mat-raised-button
            class="action-button dofilter-button">
            @if (isLoading) {
            <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
            } @else {
            <mat-icon>search</mat-icon>
            }
        </button>
    </div>

    <mat-form-field class="form-group beans-search-beans" subscriptSizing="dynamic">
        <mat-label i18n="reports$$Roasts@@Roasts">Roasts</mat-label>
        <mat-select [disabled]="!templates?.length" hideSingleSelectionIndicator="true"
            placeholder="Roasts" i18n-placeholder="reports$$Roasts@@Roasts"
            name="roast" panelClass="twoLineOption" panelWidth="null"
            [(ngModel)]="template"
            [compareWith]="utils.compareObjectsFn">
            @if (template) {
            <mat-select-trigger class="twoLineOption ellipsed">
                <ng-container *ngTemplateOutlet="templateEntry; context: {template, isTrigger: true}"></ng-container>
            </mat-select-trigger>
            }
            <!-- @if (filteredTemplates?.length) {
            <mat-option [value]="null"></mat-option>
            } -->
            @for (template of filteredTemplates; track template?._id) {
            <mat-option [value]="template">
                <ng-container *ngTemplateOutlet="templateEntry; context: {template}"></ng-container>
            </mat-option>
            }
        </mat-select>
    </mat-form-field>


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="cancel-button" autofocus cdkFocusInitial mat-button type="submit" mat-dialog-close tabindex="0">
        <mat-icon>close</mat-icon>
    </button>
    <button [disabled]="!template" class="action-button" mat-raised-button type="button" (click)="selectTemplate()">
        <mat-icon>done</mat-icon>
    </button>
</mat-dialog-actions>