import { HttpRequest, HttpEvent, HttpHandlerFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserType } from 'src/app/modules/frame/services/user.service';

export function jwtInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    if (req.url.indexOf('.php') === -1) {
        // add authorization header with jwt token if available
        const currentUser = JSON.parse(localStorage.getItem('currentUser')) as UserType;
        if (currentUser?.token) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }
    }

    return next(req);
}
