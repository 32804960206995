<mat-dialog-content style="max-width: 450px;">
    <div>
        @if (data.item) {
        <section style="margin-top: 0;">
            <i>{{data.item.count}}x {{utils.formatAmount(data.item.amount, undefined, data.mainUnit, -1, true, true)}} {{data.item.title}}</i>
        </section>
        } @else if (data.items?.length === 1) {
        <section style="margin-top: 0;">
            <i>{{data.items[0].count}}x {{utils.formatAmount(data.items[0].amount, undefined, data.mainUnit, -1, true, true)}} {{data.items[0].title}}</i>
        </section>
        } @else if (data.items?.length > 1) {
        <section style="margin-top: 0;">
            <i>{{totalRoastsCount}} <span i18n="menu$$menu entry Roasts@@Roasts menu label">Roasts</span></i>
        </section>
        }
        <section style="margin-top: 0;">
            <div class="recurring-interval-container">
                <span id="recurring-interval-label" style="white-space: nowrap;"
                    i18n="scheduler$$label, repeat every (2 weeks)@@Repeat every">Repeat every</span>
                <mat-form-field required class="recurring-interval"
                    subscriptSizing="dynamic" aria-labelledby="recurring-interval-label"
                    [ngClass]="{'warn-amount': !options.interval}"
                    floatLabel="always" hideRequiredMarker="true">
                    <input required type="number" min="1" pattern="[0-9]+"
                        matInput name="interval" data-test="npt_interval"
                        class="recurring-interval-input"
                        [(ngModel)]="options.interval"
                        (ngModelChange)="updateDates()">
                </mat-form-field>

                <mat-form-field class="recurring-freq" subscriptSizing="dynamic"
                    floatLabel="always" hideRequiredMarker="true">
                    <mat-select required panelWidth="null" name="freq"
                        [(ngModel)]="options.freq"
                        (ngModelChange)="updateFreq()">
                        @for (freq of FREQUENCIES; track freq; let i = $index) {
                        <mat-option [value]="freq">
                            {{options.interval === 1 ? FREQUENCIES_STR[freq] : FREQUENCIES_STR_PLURAL[freq]}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </section>

        <section>
            @if (options.freq === RRule.WEEKLY) {
            <div class="recurring-repeat-weekday-label"
                i18n="scheduler$$label, repeat on (some weekdays)@@Repeat on">Repeat on</div>
            <div class="recurring-weekday-container">
                <mat-button-toggle-group multiple name="weekdays"
                    [hideMultipleSelectionIndicator]="true"
                    [(ngModel)]="options.byweekday"
                    (ngModelChange)="updateDates()">
                    @for (weekday of WEEKDAYS; track weekday) {
                    <mat-button-toggle class="recurring-repeat-weekday-button"
                        [value]="$index">
                        {{weekday}}
                    </mat-button-toggle>
                    }
                </mat-button-toggle-group>
            </div>
            }
        </section>

        <section>
            <span id="end-radio-group-label"
                i18n="scheduler$$label, recurrence ends (on date or after n repetitions)@@Ends">Ends</span>
            <div class="recurring-end-radio-container">
                <mat-radio-group aria-labelledby="end-radio-group-label"
                    [(ngModel)]="end"
                    (ngModelChange)="endTypeChanged()"
                    class="recurring-end-radio-group primary-element">
                    <mat-radio-button value="date" class="recurring-end-radio-buton"
                        i18n="reminders$$On a specific date@@On">On</mat-radio-button>
                    <mat-radio-button value="count" class="recurring-end-radio-buton"
                        i18n="scheduler$$After a specific number of repetitions@@After">After</mat-radio-button>
                </mat-radio-group>
                <span class="recurring-end-details">
                    <span class="recurring-end-datepicker">
                        <mat-form-field subscriptSizing="dynamic">
                            <input matInput [matDatepicker]="dapicker"
                                [(ngModel)]="until"
                                (ngModelChange)="updateEndDate($event)"
                                [min]="tomorrow">
                            <mat-datepicker-toggle matIconSuffix [for]="dapicker">
                                <mat-icon matDatepickerToggleIcon
                                    (click)="end='date'">event</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #dapicker></mat-datepicker>
                        </mat-form-field>
                    </span>
                    <span>
                        <mat-form-field class="recurring-count"
                            subscriptSizing="dynamic"
                            [ngClass]="{'warn-amount': end === 'count' && !options.count}"
                            floatLabel="always" hideRequiredMarker="true">
                            <input type="number" min="1" pattern="[0-9]+"
                                (click)="end='count'"
                                [(ngModel)]="options.count"
                                (ngModelChange)="updateCount($event)"
                                matInput name="interval" data-test="npt_count"
                                class="recurring-count-input">
                        </mat-form-field>
                        @if (options.count === 1) {
                        <span i18n="scheduler$$label, repetition@@Repetition" class="recurring-count-label">Repetition</span>
                        } @else {
                        <span i18n="scheduler$$label, repetitions@@Repetitions" class="recurring-count-label">Repetitions</span>
                        }
                    </span>
                </span>
            </div>

            @if (reps >= 0) {
            <div>
                <i i18n="scheduler$$Maximum number of repetitions restricted by@@Maximum number of repetitions restricted by">Maximum number of repetitions restricted by</i><br />
                @if (data.minRepsIdx >= 0 && data.items?.[data.minRepsIdx]) {
                <i>{{data.items?.[data.minRepsIdx].count}}x {{utils.formatAmount(data.items?.[data.minRepsIdx].amount, undefined, data.mainUnit, -1, true, true)}} {{data.items?.[data.minRepsIdx].title}}</i>:
                } @else if (data.minRepsCoffeeInfo) {
                <i>{{utils.formatAmount(data.minRepsCoffeeInfo.amount, undefined, data.mainUnit, -1, true, true)}} {{data.minRepsCoffeeInfo.coffee?.label}}</i>:
                }
            </div>
            <div [ngClass]="{'warn-amount': (dates?.length || options.count || 0) > reps}"><ng-container i18n="generic$$Stock@@Stock">Stock</ng-container>: {{utils.formatAmount(data.stock, undefined, data.mainUnit, -1, true, true)}}; {{dates?.length || options.count || 0}}*{{utils.formatAmount(data.batchSize, undefined, data.mainUnit, -1, true, true)}} = {{utils.formatAmount((dates?.length || options.count || 0) * data.batchSize, undefined, data.mainUnit, -1, true, true)}}</div>
            <div><a (click)="useCountSuggestion(reps)" (keydown.enter)="useCountSuggestion(reps); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">{{reps}}</a>&nbsp;<span i18n="scheduler$$explanation, current stock can be used for n scheduled roast sessions@@repetitions possible with the current stock">repetitions possible with the current stock</span></div>
            } @else {
            <ng-container i18n="generic$$Stock@@Stock">Stock</ng-container>: {{utils.formatAmount(data.stock, undefined, data.mainUnit, -1, true, true) || ('0' + data?.mainUnit)}}
            }
        </section>

        <section>
            @if (dates?.length) {
            <div class="recurring-dates">
                <div class="recurring-dates-label">
                    {{dates.length}}
                    @if (dates.length === 1) {
                    <span i18n="scheduler$$label, repetition@@Repetition">Repetition</span>
                    } @else {
                    <span i18n="scheduler$$label, repetitions@@Repetitions">Repetitions</span>
                    }:
                    @if (dates.length > 1) {
                    {{dates[0] | date:'shortDate'}} - {{dates[dates.length - 1] | date:'shortDate'}}
                    }
                </div>
                @for (date of dates; track date?.valueOf()) {
                <div>
                    @if (isRemovedDate(date)) {
                    <mat-icon class="recurring-dates-icon primary-element" (click)="addDate(date)">add</mat-icon>
                    <del><span class="recurring-date">{{date | date:'fullDate'}}</span></del>
                    } @else {
                    <mat-icon class="recurring-dates-icon tertiary-element" (click)="removeDate(date)">delete</mat-icon>
                    <span class="recurring-date">{{date | date:'fullDate'}}</span>
                    }
                </div>
                }
            </div>
            }
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: space-between;">
    <span>
        <!-- @if (data?.item) { -->
        <button class="edit-button" mat-raised-button (click)="deleteClones()">
            <mat-icon>delete</mat-icon>
        </button>
        <!-- } -->
    </span>
    <div>
        <button class="cancel-button" autofocus cdkFocusInitial mat-button type="submit" mat-dialog-close tabindex="0">
            <mat-icon>close</mat-icon>
        </button>
        <button class="action-button" mat-raised-button type="button" (click)="addClones()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</mat-dialog-actions>