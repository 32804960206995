{
	"not paid": "ABGELAUFEN",
	"visit our store": "Bitte besuche unseren Shop, um Dein Abonnement zu verlängern",
	"0 paid days left": "Noch 0 Tage",
	"1 paid days left": "Noch 1 Tag",
	"2 paid days left": "Noch 2 Tage",
	"3 paid days left": "Noch 3 Tage",
	"No changes possible": "Keine Änderungen möglich",
	"for": "für",
	"greeting": "Hallo",
	"Your account": "Dein Konto",
	"logout": "AUSLOGGEN",
	"register link": "REGISTRIEREN",
	"login link": "EINLOGGEN",
	"You can always visit our shop to extend your subscription": "Du kannst jederzeit unseren Shop besuchen, um Dein Abonnement zu verlängern",
	"over limit": "ÜBER DEM LIMIT",
	"Reminders menu label": "Erinnerungen",
	"active": "aktiv",
	"Rename machines": "Maschinen umbenennen",
	"{{mainUnit}} roasted": "{$INTERPOLATION} geröstet",
	"Every /n/ {{PLACEHOLDER}}": "Immer nach /n/ {$INTERPOLATION}",
	"Every /WEEKDAY/": "Jeden /WEEKDAY/",
	"Every /nthDAYOFMONTH/ day of the month": "Jeden /nthDAYOFMONTH/ Tag des Monats",
	"(every n>=2) days": "Tagen",
	"(every n>=2) weeks": "Wochen",
	"(every n>=2) months": "Monate",
	"(every n>=2) years": "Jahre",
	"hours of roasting": "Röststunden",
	"minutes of roasting": "Röstminuten",
	"min": "min",
	"time": "Zeit",
	"h": "h",
	"hour of roasting": "Röststunde",
	"weeks": "Wochen",
	"years": "Jahre",
	"day of the week": "Wochentag",
	"day of the month": "Tag des Monats",
	"propane consumed": " Gas verbraucht",
	"On": "Am",
	"After": "Nach",
	"Repetition": "Wiederholung",
	"Repetitions": "Wiederholungen",
	"Maximum number of repetitions restricted by": "Maximale Wiederholungen begrenzt durch",
	"repetitions possible with the current stock": "Wiederholungen mit dem aktuellen Bestand möglich",
	"error retrieving all reminders": "Fehler beim Abrufen aller Erinnerungen",
	"daily": "täglich",
	"yearly": "jährlich",
	"Condition": "Bedingung",
	"starting from a date": "ab",
	"Completed Tasks": "Erledigte Aufgaben",
	"Here you can see, and manually document, completed actions such as the routine cleaning of machines.": "Hier kannst Du erledigte Aktionen einsehen und manuell dokumentieren. Also zum Beispiel die kontinuierliche Reinigung der Maschinen sicherstellen.",
	"Reminder": "Erinnerung",
	"Here you can let the system remind you of recurring tasks such as 'Clean thermocouple every week'.": "Hier kannst Du Dich vom System an wiederkehrende Aufgaben wie „Thermoelement jede Woche reinigen“ erinnern lassen.",
	"Day": "Tag",
	"Week": "Woche",
	"Propane bottle": "Propangasflasche",
	"Once {{kg}} have been roasted": "Sobald {$INTERPOLATION} geröstet wurden",
	"Clean fans": "Lüfter reinigen",
	"Clean cooling tray": "Kühlsieb reinigen",
	"Submit tax report": "Steuerbericht einreichen",
	"Pay tax bill": "Steuerrechnung bezahlen",
	"After each roast hour": "Nach jeder Röststunde",
	"expired since {{kg}}": "abgelaufen seit {$INTERPOLATION}",
	"expired {{time}}": "abgelaufen {$INTERPOLATION}",
	"next {{time}}": "als nächstes {$INTERPOLATION}",
	"Every {{interval}} {{intervalUnit}}": "Alle {$INTERPOLATION}{$INTERPOLATION_1}",
	"Every {{weekday}}": "Jeden {$INTERPOLATION}",
	"Every {{dayOfMonth}}": "Jeden {$INTERPOLATION}",
	"from {{date}}": "ab {$INTERPOLATION}",
	"After {{minutes}} roast minutes": "Nach {$INTERPOLATION} Röstminuten",
	"After {{hours}} roast hours": "Nach {$INTERPOLATION} Röststunden",
	"After {{n}} roasts": "Nach {$INTERPOLATION} Röstungen",
	"{{interval}} to go": "noch {$INTERPOLATION}",
	"Lubricate drum bearings": "Trommellager schmieren",
	"Clean exhaust ducting": "Abluftführung reinigen",
	"Lubricate drum drive chain": "Trommelantriebskette schmieren",
	"Replace air filter": "Luftfilter ersetzen",
	"Generate monthly roast report": "Monatlichen Röstbericht erstellen",
	"Replace gas bottle": "Gasflasche ersetzen",
	"Prepare organic audit": "Bio-Audit vorbereiten",
	"Empty chaff container": "Kaffeehäutchensammelbehälter leeren",
	"Once {{kg}} {{gasUnit}} propane consumed ({{btu}} {{btuUnit}})": "Sobald {$INTERPOLATION}{$INTERPOLATION_1} Propangas verbraucht wurden ({$INTERPOLATION_2}{$INTERPOLATION_3})",
	"Notify all": "Alle benachrichtigen",
	"Browser": "Browser",
	"Notification": "Benachrichtigung",
	"Best add this email to your address book": "Diese Adresse am besten dem Adressbuch hinzufügen",
	"OR": "ODER",
	"After each roast": "Nach jeder Röstung",
	"average FIFO cost comes from": "Der Durchschnittspreis von {$INTERPOLATION_1}/{$INTERPOLATION_2} ergibt sich aus den aktuellen Kosten von {$INTERPOLATION_4} für den aktuellen Bestand von {$INTERPOLATION_5}{$INTERPOLATION_6} Bohnen",
	"Corrections are not listed": "Korrekturen sind nicht aufgeführt",
	"Maximum for display reached": "Maximum für Anzeige erreicht",
	"plural_discarded": "Fehlröstungen",
	"from": "aus",
	"Attribute": "Attribut",
	"Load / Save ...": "Laden / Speichern ...",
	"Click here to add more filters": "Weitere Filter hinzufügen",
	"No amount set": "Keine Menge eingegeben",
	"No yield set": "Kein Ertrag eingegeben",
	"No beans set": "Keine Rohbohnen gesetzt",
	"No blends": "Keine Mischungen",
	"Specific bean in blends": "Bestimmte Rohbohne in Mischungen",
	"missing value": "fehlender Wert",
	"any value": "irgendein Wert",
	"any": "beliebig",
	"or": "oder",
	"and": "und",
	"not": "nicht",
	"Summary": "Zusammenfassung",
	"List of roasts": "Liste aller Röstungen",
	"BT": "BT",
	"FC": "FC",
	"Only one value found": "Nur ein Wert gefunden",
	"Share beans info": "Bohneninformationen teilen",
	"Copy to clipboard": "In die Zwischenablage kopieren",
	"See what will be shared": "Ansehen, welche Informationen weitergegeben werden",
	"Scheduler menu label": "Röstplan",
	"5848348023107769657": "Gewicht",
	"Title": "Titel",
	"This is a system wide change. All existing roasts, templates, scheduled items etc. will be affected. Cannot be undone.": "Dies ist eine systemweite Änderung. Alle vorhandenen Roasts, Vorlagen, geplante Elemente usw. sind davon betroffen. Kann nicht rückgängig gemacht werden.",
	"Caution! Chosen machine name already exists, you will not be able to separate roasts by machine after the change.": "Achtung! Der gewählte Maschinenname existiert bereits. Nach der Änderung kannst Du die Röstungen nicht mehr nach Maschinen trennen.",
	"Cupping": "Cupping",
	"Always available as template": " Immer als Vorlage verfügbar ",
	"Roasting Notes": "Röstnotizen",
	"Cupping Notes": "Notizen zum Cupping",
	"Search beans": "Bohnen suchen",
	"Add as post blend": "Als Post-Blend hinzufügen",
	"Save": "Spare",
	"Missing": "Fehlen",
	"From history": "Früher",
	"Search templates": "Vorlagen suchen",
	"Add machines": "Machinen hinzufügen",
	"Favorites": "Favoriten",
	"Reload - check for changes": "Neu laden - auf Änderungen prüfen",
	"not specified": "nicht angegeben",
	"Reduce displayed stock by planned amounts": "Angezeigten Bestand um geplante Mengen reduzieren",
	"Search roast": "Röstungen suchen",
	"Batch": "Charge",
	"Hauptzollamt Aachen": "Hauptzollamt Aachen",
	"Hauptzollamt Augsburg": "Hauptzollamt Augsburg",
	"Hauptzollamt Berlin": "Hauptzollamt Berlin",
	"Hauptzollamt Bielefeld": "Hauptzollamt Bielefeld",
	"Hauptzollamt Braunschweig": "Hauptzollamt Braunschweig",
	"Hauptzollamt Bremen": "Hauptzollamt Bremen",
	"Hauptzollamt Darmstadt": "Hauptzollamt Darmstadt",
	"Hauptzollamt Dortmund": "Hauptzollamt Dortmund",
	"Hauptzollamt Dresden": "Hauptzollamt Dresden",
	"Hauptzollamt Duisburg": "Hauptzollamt Duisburg",
	"Hauptzollamt Düsseldorf": "Hauptzollamt Düsseldorf",
	"Hauptzollamt Erfurt": "Hauptzollamt Erfurt",
	"Hauptzollamt Frankfurt (Oder)": "Hauptzollamt Frankfurt (Oder)",
	"Hauptzollamt Frankfurt am Main": "Hauptzollamt Frankfurt am Main",
	"Hauptzollamt Gießen": "Hauptzollamt Gießen",
	"Hauptzollamt Hamburg": "Hauptzollamt Hamburg",
	"Hauptzollamt Hannover": "Hauptzollamt Hannover",
	"Hauptzollamt Heilbronn": "Hauptzollamt Heilbronn",
	"Hauptzollamt Itzehoe": "Hauptzollamt Itzehoe",
	"Hauptzollamt Karlsruhe": "Hauptzollamt Karlsruhe",
	"Hauptzollamt Kiel": "Hauptzollamt Kiel",
	"Hauptzollamt Koblenz": "Hauptzollamt Koblenz",
	"Hauptzollamt Köln": "Hauptzollamt Köln",
	"Hauptzollamt Krefeld": "Hauptzollamt Krefeld",
	"Hauptzollamt Landshut": "Hauptzollamt Landshut",
	"Hauptzollamt Lörrach": "Hauptzollamt Lörrach",
	"Hauptzollamt Magdeburg": "Hauptzollamt Magdeburg",
	"Hauptzollamt München": "Hauptzollamt München",
	"Hauptzollamt Münster": "Hauptzollamt Münster",
	"Hauptzollamt Nürnberg": "Hauptzollamt Nürnberg",
	"Hauptzollamt Oldenburg": "Hauptzollamt Oldenburg",
	"Hauptzollamt Osnabrück": "Hauptzollamt Osnabrück",
	"Hauptzollamt Potsdam": "Hauptzollamt Potsdam",
	"Hauptzollamt Regensburg": "Hauptzollamt Regensburg",
	"Hauptzollamt Rosenheim": "Hauptzollamt Rosenheim",
	"Hauptzollamt Saarbrücken": "Hauptzollamt Saarbrücken",
	"Hauptzollamt Schweinfurt": "Hauptzollamt Schweinfurt",
	"Hauptzollamt Singen": "Hauptzollamt Singen",
	"Hauptzollamt Stralsund": "Hauptzollamt Stralsund",
	"Hauptzollamt Stuttgart": "Hauptzollamt Stuttgart",
	"Hauptzollamt Ulm": "Hauptzollamt Ulm",
	"of": "von",
	"Item cannot be updated, probably already synchronized with Artisan": "Eintrag kann nicht aktualisiert werden, wahrscheinlich bereits mit Artisan synchronisiert",
	"Item cannot be updated, roasting has already started": "Eintrag kann nicht aktualisiert werden, Röstung hat bereits begonnen",
	"Please enter a value for {{missingField}}": "Bitte gib einen Wert für {$INTERPOLATION} ein",
	"Some items could not be deleted": "Einige Elemente konnten nicht gelöscht werden",
	"error updating the schedule information": "Fehler beim Aktualisieren des Röstplans",
	"error updating the schedule": "Fehler beim Aktualisieren des Röstplans",
	"error retrieving the schedule": "Fehler beim Abrufen des Röstplans",
	"Beans used in blends": "In Mischungen verwendete Bohnen",
	"Batches": "Chargen",
	"Batch size": "Batchgröße",
	"paid until, extend here": "Bezahlt bis {$INTERPOLATION} (hier verlängern)",
	"Blends menu label": "Mischungen",
	"Roasts menu label": "Röstungen",
	"Beans menu label": "Rohbohnen",
	"Contacts menu label": "Kontakte",
	"Reports menu label": "Berichte",
	"legal notice": "Impressum",
	"privacy statement": "Datenschutzerklärung",
	"Shop": "Preise",
	"paid until": "Bezahlt bis {$INTERPOLATION}",
	"your config": "Deine Konfiguration",
	"Light mode": "Heller Modus",
	"Auto": "Auto",
	"Dark mode": "Dunkler Modus",
	"dot seperator": "Format: 12.34 kg (Punkt)",
	"comma seperator": "Format: 12,34 kg (Komma)",
	"Data Export Settings (CSV, Excel)": "Exporteinstellungen (CSV, Excel)",
	"Tab": "Tab",
	"Comma": "Komma",
	"Semicolon": "Semikolon",
	"Space": "Leerzeichen",
	"Separated by": "Getrennt durch",
	"Windows": "Windows",
	"Unix / macOS": "Unix / macOS",
	"Line separator": "Zeilentrennzeichen",
	"Change your password": "Passwort ändern",
	"download data heading": "Daten herunterladen",
	"download all data": "Exportiere Deine Daten in eine Datei.",
	"delete account": "Löschen Deines Kontos inklusive aller Daten. Dies kann nicht rückgängig gemacht werden.",
	"reset": "Konto zurücksetzen: behält Dein Konto bei, löscht jedoch alle Deine Daten für immer. Dies kann nicht rückgängig gemacht werden.",
	"delete data heading": "Daten löschen",
	"Decimal Separator": "Dezimaltrennzeichen",
	"Variant": "Variante",
	"Reports Settings": "Einstellungen für Berichte",
	"Sharing options": "Freigabeoptionen",
	"Users with full access to this account:": "Benutzer mit vollem Zugriff auf dieses Konto:",
	"admin user": "(Administrator)",
	"pending user": "(noch nicht angenommen)",
	"resend invitation": "Einladung erneut versenden",
	"Viewers with read-only access to this account:": "Benutzer mit Lesezugriff auf dieses Konto:",
	"You can see the accounts of:": "Du kannst die Daten von diesen Leuten sehen:",
	"Add user to account": "Füge Deinem Konto einen Benutzer hinzu",
	"unit system": "Maßeinheit",
	"currency": "Währung",
	"temperature system": "Temperatur",
	"register button": "REGISTRIEREN",
	"Partner Coupons": "Partner-Gutscheine",
	"nickname": "Spitzname",
	"accept privacy": "Ich akzeptiere die Datenschutzerklärung:",
	"read here": "lesen",
	"login button": "EINLOGGEN",
	"email": "Email",
	"password": "Passwort",
	"resend verification email": "Bestätigungsmail erneut senden",
	"reset_password": "Passwort zurücksetzen",
	"register_new_password": "Neues Passwort",
	"Success! You can now log in with your new password.": "Geschafft. Du kannst dich nun mit dem neuen Passwort einloggen.",
	"you have been invited": "Du wurdest eingeladen, dem Konto von {$INTERPOLATION} beizutreten",
	"you have been invited, readonly": "Du wurdest eingeladen, die Daten von {$INTERPOLATION} lesen zu können",
	"add account": "KONTO ZUFÜGEN",
	"Total": "Gesamt",
	"deleted": "gelöscht",
	"Reason": "Grund",
	"References": "Referenzen",
	"Beans used in blends, header": "Bohnen in Blends",
	"Target store": "Ziellager",
	"creating PDF": "PDF wird erstellt ...",
	"Do not show this again": "Nicht mehr anzeigen",
	"Can be edited in": "Kann hier geändert werden",
	"update PDF": "PDF aktualisieren",
	"download PDF": "PDF",
	"roasts": "Röstungen",
	"finalize": "Finalisieren",
	"Store": "Lager",
	"Sum": "Summe",
	"loading reports": "Berichte werden geladen ...",
	"Show zero amounts": "Zeige Nulleinträge",
	"Per bean": "Pro Rohbohnen",
	"per": "pro",
	"Per store": "Pro Lager",
	"Cost": "Kosten",
	"report": "Bericht",
	"grams per liter": "g/l",
	"no stores": "Keine Lager vorhanden!",
	"Weight in {{mainUnit}}": "Gewicht in {$INTERPOLATION}",
	"no beans": "Bitte erst Rohbohnen hinzufügen!",
	"Open in Artisan as background template (Artisan >2.4.4)": "Als Hintergrundvorlage laden (Artisan >2.4.4)",
	"Open in Artisan": "In Artisan laden",
	"CHARGE": "FÜLLEN",
	"DROP": "LEEREN",
	"DEV": "Entwicklung",
	"Temperature": "Temperatur",
	"Pressure": "Luftdruck",
	"Humidity": "Luftfeuchte",
	"Template": "Vorlage",
	"CM ET/BT": "CM ET/BT",
	"AUC": "AUC",
	"reconciled roast info": "Dies wurde in einen Abschlussbericht aufgenommen und die meisten Inhalte können nicht mehr geändert werden.",
	"new blend name": "Neuer Mischungsname",
	"use blend template": "Benutze {$INTERPOLATION}",
	"Batch Prefix / Number / Position": "Chargenpräfix / Nummer / Position",
	"Prefix": "Präfix",
	"Number": "Nummer",
	"Position": "Position",
	"Energy": "Energie",
	"Ground color": "Mahlgutfarbe",
	"Whole color": "Bohnenfarbe",
	"roast yield": "Ertrag",
	"loss": "Verlust",
	"Density": "Dichte",
	"discarded": "Fehlröstung",
	"Moisture": "Bohnenfeuchte",
	"invalid transactions not included": "Die folgenden Transaktionen werden nicht in den Bericht aufgenommen, da wichtige Informationen fehlen:",
	"clone": "Kopie erstellen",
	"Import Beans Data": "Beans-Daten importieren",
	"close edit mode": "Schließen",
	"Year": "Jahr",
	"reference": "Deine Referenz",
	"add a new field": "Neues Feld hinzufügen",
	"loading certifications": "Zertifizierungen werden geladen ...",
	"Species": "Spezies",
	"Varietals": "Sorten",
	"Processing": "Verarbeitung",
	"loading origins": "Herkunftländer werden geladen ...",
	"lot": "Los",
	"certifications": "Zertifizierungen",
	"loading producers": "Produzenten werden geladen ...",
	"loading suppliers": "Lieferanten werden geladen ...",
	"add a new producer": "Neuen Produzenten hinzufügen",
	"ICO": "ICO",
	"ICO details": "ICO Herkunft - Exporteur - Los",
	"crop_date_picked": "Ernte",
	"crop_date_landed": "Ankunft",
	"grade": "Güteklasse",
	"altitude": "Höhe",
	"altitude_minmax": "Höhe min / max",
	"screen_size": "Bohnengröße",
	"screen_size_minmax": "Bohnengröße min / max",
	"score": "Bewertung",
	"cultivation": "Anbau",
	"harvesting": "Erntemethode",
	"drying": "Trocknung",
	"decaffeination": "Entkoffeinierung",
	"ageing": "Alterung",
	"packaging": "Verpackung",
	"Selection": "Selektion",
	"water_activity": "Wasseraktivität",
	"defects": "Fehler",
	"flavors": "Aroma",
	"crop_yield": "Ernteertrag",
	"fermentation": "Fermentation",
	"Origin": "Herkunft",
	"Origin differs from ICO origin": "Anders als bei ICO!",
	"field": "Feld",
	"add initial stock": "Anfangsbestand hinzufügen:",
	"edit": "Bearbeiten",
	"Name": "Name",
	"nothing to show plus hint, add with button": "Derzeit gibt es nichts zu zeigen. Mit dem Button oben hinzufügen!",
	"You have no hidden items.": "Du hast keine versteckten Einträge.",
	"Click here to find all hidden entries": "Hier klicken um alle versteckten Einträge zu sehen",
	"Show only items with organic certification": "Hier klicken um nur Einträge mit Bio-Zertifizierung zu sehen",
	"Show only beans that are not hidden": "Hier klicken um nur Rohbohnen zu sehen, die nicht versteckt sind",
	"Country": "Land",
	"producer": "Produzent",
	"code": "Kennnummer",
	"organizational_form": "Organisationsform",
	"farmowner": "Inhaber",
	"Web": "Internet",
	"loading fields": "Felder werden geladen ...",
	"street": "Straße",
	"Address": "Adresse",
	"Phone": "Telefon",
	"Coupon": "Gutschein",
	"Request code": "Code anfordern",
	"Details": "Details",
	"GET a COUPON": "GUTSCHEIN holen",
	"Supplier Partners": "Lieferantenpartner",
	"Do you want to add the following beans?": "Die folgenden Bohnen hinzufügen?",
	"last seen at supplier on date": "Zuletzt gesehen bei {$INTERPOLATION} am {$INTERPOLATION_1}",
	"beans already exist will merge attributes": "Diese Bohnen sind bereits in Deinem Konto vorhanden. Neue Attribute werden zusammengeführt.",
	"Postal Code": "Postleitzahl",
	"city": "Stadt",
	"region": "Region",
	"subregion": "Unterregion",
	"Supplier": "Lieferant",
	"add a new place": "Neuen Ort hinzufügen",
	"place": "Ort",
	"close edit place mode": "Schließen",
	"Customer": "Kunde",
	"Notes": "Anmerkungen",
	"Stock": "Lagerbestand",
	"incl. replacement ingredients": "inkl. Ersatzzutaten",
	"Add/Change replacement ingredients": "Ersatzzutaten hinzufügen / ändern",
	"Undo replace": "Ersetzen rückgängig machen",
	"Replace by": "Ersetzen durch",
	"done": "Erledigt",
	"Amount": "Menge",
	"Cost per": "Kosten pro",
	"total cost": "Gesamtkosten",
	"unit_specification": "Packungseinheit",
	"unit_specification_2": "1 {$INTERPOLATION} hat wie viele {$INTERPOLATION_1}?",
	"Add new beans": "Neue Rohbohnen hinzufügen",
	"wizard heading": "Lass uns anfangen!",
	"your locations": "Deine Standorte",
	"location explanation": "Wie nennst Du den Ort, an dem Du Deine Rohbohnen lagerst: zB Rösterei, Heiligtum, ...",
	"new location": "Neuer Standort",
	"select location": "Bestehenden Standort auswählen",
	"new location label": "Neuen Standort hinzufügen",
	"next step": "Weiter",
	"Your Beans": "Deine Rohbohnen",
	"coffee explanation": "Welche Rohbohnen hast Du im Lager {$INTERPOLATION}?",
	"New beans": "Neue Rohbohnen",
	"select existing beans": "Kaffee auswählen",
	"coffee amount explanation": "Wie viel {$INTERPOLATION} hast Du im Lager {$INTERPOLATION_1}?",
	"new supplier": "Neuer Lieferant",
	"select supplier": "Bestehenden Lieferanten auswählen",
	"question for supplier": "Woher (Lieferant)?",
	"wizard finished": "Großartig! Und nun?",
	"Clipboard": "Zwischenablage",
	"Export": "Export",
	"button add more": "Weiter zufügen",
	"Roast": "Röstung",
	"roast": "Röstung",
	"Beans": "Rohbohnen",
	"Blend": "Mischung",
	"Report": "Bericht",
	"for price": "für",
	"from source": "von",
	"on {{date}}": "am {$INTERPOLATION}",
	"cannot delete": "Kann nicht gelöscht werden!",
	"cannot delete because of references": "{$INTERPOLATION} kann nicht gelöscht werden. Verwendet von:",
	"all": "überall",
	"Last modified": "Zuletzt geändert",
	"Loading beans": "Rohbohnen werden geladen ...",
	"ID": "ID",
	"order_number": "Bestellnummer",
	"sales_number": "Auftragsnummer",
	"Yield in {{mainUnit}}": "Ertrag in {$INTERPOLATION}",
	"Blends": "Mischungen",
	"Change in all blend templates?": "Alle Mischvorlagen entsprechend ändern?",
	"Customs": "Zoll",
	"Stocks": "Bestände",
	"Roasts": "Röstungen",
	"Sequential": "Lückenlos",
	"Open": "Frei",
	"You can COPY or MOVE all your roast reports from the 'Sequential' section here:": "Du kannst alle Deine bereits angelegten 'lückenlosen' Röstberichte hierher KOPIEREN oder VERSCHIEBEN:",
	"Date": "Datum",
	"last changed": "Letzte Änderung {$INTERPOLATION}",
	"updated by": "von {$INTERPOLATION}",
	"Added {{date}}": "Am {$INTERPOLATION} zugefügt",
	"created by": "von {$INTERPOLATION}",
	"Loading roasts": "Röstungen werden geladen ...",
	"sort order user": "Benutzer",
	"Repeat every": "Wiederholen alle",
	"Repeat on": "Wiederholen am",
	"Ends": "Endet",
	"Filter": "Filter",
	"Machine": "Maschine",
	"roast date": "Röstdatum",
	"batch number": "Chargennummer",
	"Show roasts from": "Nur Röstungen in",
	"no match for filter": "Keine Treffer gefunden",
	"Filter ...": "Filtern ...",
	"Find / Add ...": "Filtern / Zufügen ...",
	"Amount of beans available": "Verfügbar:",
	"Ratio": "Verhältnis",
	"Check out your {{newPartners}} new LINKSTART_supplier partners_LINKEND!": "Sieh Dir Deine {$INTERPOLATION} neuen LINKSTART_Lieferantenpartner_LINKEND an!",
	"Check out your new LINKSTART_supplier partner_LINKEND!": "Sieh Dir Deinen neuen LINKSTART_Lieferantenpartner_LINKEND an!",
	"No coupon available, please email info@artisan.plus": "Kein Gutschein verfügbar, bitte sende eine E-Mail an info@artisan.plus",
	"Update available": "Update verfügbar",
	"Label changelog page": "Was gibt's Neues?",
	"UPDATE": "AKTUALISIEREN",
	"page not found text": "Wir arbeiten hart daran, den gewünschten Inhalt hier hinzuzufügen!",
	"Check Online Status": "Ist artisan.plus online?",
	"You can now switch accounts here": "Hier kannst Du jetzt die Konten wechseln",
	"no beans or blend": "Keine Rohbohnen oder Mischung",
	"no amount": "keine Mengenangabe",
	"no roast date": "kein Röstdatum",
	"weight or yield not positive": "Chargengröße oder Ertrag nicht positiv",
	"Yield must be smaller than weight": "Ertrag muss kleiner als Chargengröße sein",
	"no roast location": "kein Röststandort",
	"before selected period": "vor dem ausgewählten Zeitraum",
	"unknown reason": "unbekannter Grund",
	"Week {{week number}}, {{year}}": "Woche {$INTERPOLATION}, {$INTERPOLATION_1}",
	"Quarter {{quarter number}}, {{year}}": "Quartal {$INTERPOLATION}, {$INTERPOLATION_1}",
	"error adding report": "Fehler beim Hinzufügen des Berichts",
	"report dates are overlapping with report {{reportLabel}}": "Datum überlappt mit Bericht {$INTERPOLATION}",
	"report with label {{label}} already exists": "Der Bericht mit der Bezeichnung {$INTERPOLATION} ist bereits vorhanden",
	"report cannot be deleted: it is reconciled": "Der Bericht kann nicht gelöscht werden: Er ist bereits finalisiert",
	"Roast Report": "Röstprotokoll",
	"Created by artisan.plus": "Erstellt durch artisan.plus",
	"Metric": "Metrisch",
	"Imperial": "Britisch",
	"t": "t",
	"none": "keine",
	"Weight of a bag in kg?": "Gewicht eines Sacks in kg?",
	"Weight of a barrel in kg?": "Gewicht eines Fasses in kg?",
	"Weight of a box in kg?": "Gewicht einer Kiste in kg?",
	"Weight of a bag in lbs?": "Gewicht eines Sacks in Pfund?",
	"Weight of a barrel in lbs?": "Gewicht eines Fasses in Pfund?",
	"Weight of a box in lbs?": "Gewicht einer Kiste in Pfund?",
	"yesterday": "Gestern",
	"{{x}} days ago": "vor {$INTERPOLATION} Tagen",
	"Correction": "Korrektur",
	"Purchase": "Kauf",
	"Sale": "Verkauf",
	"Transfer": "Transfer",
	"Last correction": "Letzte Korrektur",
	"Last purchase": "Letzter Kauf",
	"Last sale": "Letzter Verkauf",
	"Last transfer": "Letzter Transfer",
	"Purchases": "Einkäufe",
	"Sales": "Verkäufe",
	"Transfers": "Transfers",
	"Corrections": "Korrekturen",
	"Color system": "Farbsystem",
	"Sun-grown": "Sonne",
	"Plantation": "Plantage",
	"Garden": "Garten",
	"Shade-grown": "Schatten",
	"Forest": "Wald",
	"Wild Forest": "Wild gewachsen",
	"Organic Solvent": "Organische Lösung",
	"Natural Decaffeination Process EA": "Natürlicher Entkoffeinierungsprozess EA",
	"Methylene Chloride Process": "Methylenchlorid-Verfahren",
	"Triglyceride Process": "Triglycerid-Prozess",
	"Water Filtered": "Wasser gefiltert",
	"Swiss Water Process": "Schweizer Wasserprozess (SWP)",
	"Mountain Water Process": "Bergwasser-Prozess",
	"Supercritical Fluid": "Überkritische Flüssigkeit",
	"Carbon Dioxide Process": "Kohlendioxid-Verfahren",
	"Others": "Andere",
	"decaffeinated": "entkoffeiniert",
	"European Preparation": "Europäische Verarbeitung",
	"American Preparation": "Amerikanische Verarbeitung",
	"Abu Dhabi": "Abu Dhabi",
	"Ajman": "Ajman",
	"Azores and Madeira": "Azoren und Madeira",
	"Bonaire": "Bonaire",
	"Caroline Islands": "Karolinen",
	"Ceuta": "Ceuta",
	"Cocos Islands": "Kokosinseln",
	"Dubai": "Dubai",
	"E.C. unspecified": "EG nicht spezifiziert",
	"Faeroe Islands": "Färöer-Inseln",
	"Falkland Islands": "Falkland Inseln",
	"Fujairah": "Fujairah",
	"Gaza Strip": "Gazastreifen",
	"Holy See": "Heiliger Stuhl",
	"Leeward Islands": "Inseln unter dem Winde",
	"Libyan Arab Jamahiriya": "Libysche Araber Jamahiriya",
	"Macau": "Macao",
	"Melilla": "Melilla",
	"Netherlands Antilles": "Niederländische Antillen",
	"Northern Marianas": "Nördliche Marianen",
	"Ras al Khaimah": "Ras al Khaimah",
	"Reunion": "Wiedervereinigung",
	"Sabah": "Sabah",
	"Sao Tome and Principe": "Sao Tome und Principe",
	"Sarawak": "Sarawak",
	"Sharjah": "Schardscha",
	"Svalbard and Jan Mayen Islands": "Spitzbergen und Jan Mayen Inseln",
	"Swaziland": "Swasiland",
	"Tahiti": "Tahiti",
	"Umm al Qaiwain": "Umm al Qaiwain",
	"Windward Islands": "Inseln über dem Winde",
	"Galápagos": "Galapagos",
	"Africa": "Afrika",
	"Caribbean": "Karibik",
	"Central America": "Zentralamerika",
	"Europe": "Europa",
	"Canary Islands": "Kanarische Inseln",
	"South America": "Südamerika",
	"Asia": "Asien",
	"ANI": "ANI",
	"Bali": "Bali",
	"Borneo": "Borneo",
	"Flores": "Flores",
	"Java": "Java",
	"Papua": "Papua",
	"St. Lucia": "St. Lucia",
	"Sulawesi": "Sulawesi",
	"Sumatra": "Sumatra",
	"Sumbawa": "Sumbawa",
	"Oceania": "Ozeanien",
	"Hawaii": "Hawaii",
	"Company name": "Firma",
	"Company number": "Unternehmensnummer",
	"Do you really want to finalize this report and all contained roasts?": "Möchtest Du diesen Bericht und alle enthaltenen Röstungen wirklich finalisieren?",
	"Do you really want to cancel the access to the account of {{nickname}}?": "Willst Du wirklich den Zugriff auf das Konto von {$INTERPOLATION} beenden?",
	"Set the stock of this coffee to 0 (consumed)?": "Den Bestand dieses Kaffees auf 0 setzen (verbraucht)?",
	"consumed": "verbraucht",
	"Email sent. You can also forward the following link yourself": "Email gesendet. Du kannst diesen Link auch selbst weiterleiten",
	"Do you really want to delete all your data?!": "Möchtest Du wirklich alle Daten löschen?!",
	"Do you really want to delete your account and all your data?!": "Möchtest Du Dein Konto und alle Deine Daten wirklich löschen?!",
	"Do you really want to delete this {{transaction_type}}?": "Möchtest Du diese/n {$INTERPOLATION} wirklich löschen?",
	"Do you really want to delete {{name}}?": "Möchtest Du {$INTERPOLATION} wirklich löschen?",
	"Attention": "Achtung",
	"Please add at least one type of beans first.": "Bitte füge zuerst mindestens eine Rohbohne hinzu.",
	"A blend needs at least one type of beans.": "Eine Mischung benötigt mindestens eine Rohbohne.",
	"Ratios must be positive and add up to 100%.": "Verhältnisse müssen positiv sein und sich auf 100% addieren.",
	"0% ratios are not allowed.": "0% Verhältnisse sind nicht zulässig.",
	"Please select beans or a blend.": "Bitte wähle Rohbohnen oder eine Mischung aus.",
	"Please enter an amount larger than 0.": "Bitte gebe einen Betrag größer als 0 ein.",
	"UNKNOWN": "UNBEKANNT",
	"NEW": "NEU",
	"Please click on the link in the email we sent to {{email}}": "Bitte klicke auf den Link in der E-Mail, die wir an {$INTERPOLATION} gesendet haben",
	"Your login expired, please login again.": "Login abgelaufen, bitte erneut anmelden.",
	"Successfully finalized": "Erfolgreich finalisiert",
	"Finalized": "Finalisiert",
	"Successfully removed": "Erfolgreich entfernt",
	"Successfully added": "Erfolgreich hinzugefügt",
	"Successfully updated": "Erfolgreich aktualisiert",
	"Are you connected to the Internet?": "Mit dem Internet verbunden?",
	"Successfully sent email to {{target_email}}": "E-Mail erfolgreich an {$INTERPOLATION} gesendet",
	"sum of ratios must be 100%, not {{sum}}": "Summe der Verhältnisse muss 100% sein, nicht {$INTERPOLATION}",
	"Nothing to change": "Keine Änderungen nötig",
	"Ignored since amount was zero": "Ignoriert, da der Betrag 0 war",
	"Http failure response for https": "HTTP-Fehlerantwort für https",
	"Not a valid file": "Keine gültige Datei",
	"Could not set new image": "Neues Bild konnte nicht eingestellt werden",
	"Error loading image. Please choose a new one!": "Fehler beim Laden des Bildes. Bitte wähle ein neues aus!",
	"Could not update documents": "Dokumente konnten nicht aktualisiert werden",
	"could not update the password": "Das Passwort konnte nicht aktualisiert werden",
	"could not delete the data": "Daten konnten nicht gelöscht werden",
	"Invitation failed. Did you use the invitation link?": "Einladung fehlgeschlagen. Hast du den Einladungslink benutzt?",
	"You will LOSE ACCESS to all of your OWN existing data - you have {{nrExistingObjects}} objects": "DU VERLIERST den Zugriff auf alle Deine eigenen Daten - Du hast {$INTERPOLATION} Objekte.",
	"If you do not want this, cancel and ask the person who invited you to use another of your email addresses": "Wenn Du dies nicht möchtest, brich ab und bitte die Person, die Dich eingeladen hat, eine andere Deiner E-Mail-Adressen zu verwenden",
	"beans with label {{label}} already exist": "Rohbohne mit Name {$INTERPOLATION} ist bereits vorhanden",
	"Your browser sent too many requests too quickly. Please slow down.": "Dein Browser hat zu viele Anfragen zu schnell gesendet. Bitte mache etwas langsamer.",
	"Invalid date": "Ungültiges Datum",
	"{{imageName}} is not a valid image": "{$INTERPOLATION} ist kein gültiges Bild",
	"password must not be empty": "Passwort darf nicht leer sein",
	"repeated password does not match": "wiederholtes Passwort stimmt nicht überein",
	"Fahrenheit": "Fahrenheit",
	"Celsius": "Celsius",
	"bag": "Sack",
	"plural_bag": "Säcke",
	"barrel": "Fass",
	"plural_barrel": "Fässer",
	"box": "Box",
	"plural_box": "Boxen",
	"Stores": "Lager",
	"Producers": "Produzenten",
	"Suppliers": "Lieferanten",
	"Customers": "Kunden",
	"Fields": "Anbauflächen",
	"External Stores": "Externe Lager",
	"social": "sozial",
	"organic": "Bio",
	"fair": "fair",
	"sustainable": "nachhaltig",
	"bird friendly": "bird friendly",
	"shade grown": "shade grown",
	"high quality": "high quality",
	"Afghanistan": "Afghanistan",
	"Aland Islands": "Aland Islands",
	"Albania": "Albanien",
	"Algeria": "Algerien",
	"American Samoa": "Amerikanische Samoa-Inseln",
	"Andorra": "Andorra",
	"Angola": "Angola",
	"Anguilla": "Anguilla",
	"Antarctica": "Antarktis",
	"Antigua and Barbuda": "Antigua und Barbuda",
	"Argentina": "Argentinien",
	"Armenia": "Armenien",
	"Aruba": "Aruba",
	"Australia": "Australien",
	"Austria": "Österreich",
	"Azerbaijan": "Aserbaidschan",
	"Bahamas": "Bahamas",
	"Bahrain": "Bahrain",
	"Bangladesh": "Bangladesch",
	"Barbados": "Barbados",
	"Belarus": "Weißrussland",
	"Belgium": "Belgien",
	"Belize": "Belize",
	"Benin": "Benin",
	"Bermuda": "Bermuda",
	"Bhutan": "Bhutan",
	"Bolivia": "Bolivien",
	"Bonaire, Sint Eustatius and Saba": "Bonaire, Sint Eustatius und Saba",
	"Bosnia and Herzegovina": "Bosnien und Herzegowina",
	"Botswana": "Botswana",
	"Bouvet Island": "Bouvet Island",
	"Brazil": "Brasilien",
	"British Indian Ocean Territory": "Britisches Territorium des Indischen Ozeans",
	"Brunei Darussalam": "Brunei Darussalam",
	"Bulgaria": "Bulgarien",
	"Burkina Faso": "Burkina Faso",
	"Burundi": "Burundi",
	"Cape Verde": "Kap Verde",
	"Cambodia": "Kambodscha",
	"Cameroon": "Kamerun",
	"Canada": "Kanada",
	"Cayman Islands": "Cayman Inseln",
	"Central African Republic": "Zentralafrikanische Republik",
	"Chad": "Tschad",
	"China": "China",
	"Christmas Island": "Weihnachtsinsel",
	"Cocos (Keeling) Islands": "Kokosinseln (Keelinginseln)",
	"Colombia": "Kolumbien",
	"Comoros": "Komoren",
	"Congo, DR": "Kongo, DR",
	"Congo, Republic": "Republik Kongo",
	"Cook Islands": "Cookinseln",
	"Costa Rica": "Costa Rica",
	"Croatia": "Kroatien",
	"Cuba": "Kuba",
	"Curaçao": "Curacao",
	"Cyprus": "Zypern",
	"Czech Republic": "Tschechien",
	"Denmark": "Dänemark",
	"Djibouti": "Dschibuti",
	"Dominica": "Dominica",
	"Dominican Republic": "Dominikanische Republik",
	"Ecuador": "Ecuador",
	"Egypt": "Ägypten",
	"El Salvador": "El Salvador",
	"Equatorial Guinea": "Äquatorialguinea",
	"Eritrea": "Eritrea",
	"Estonia": "Estland",
	"Eswatini": "Eswatini",
	"Ethiopia": "Äthiopien",
	"Falkland Islands [Malvinas]": "Falklandinseln [Malvinas]",
	"Faroe Islands": "Färöer Inseln",
	"Fiji": "Fidschi",
	"Finland": "Finnland",
	"France": "Frankreich",
	"French Guiana": "Französisch-Guayana",
	"French Polynesia": "Französisch Polynesien",
	"French Southern Territories": "Südfranzösische Territorien",
	"Gabon": "Gabun",
	"Gambia": "Gambia",
	"Georgia": "Georgia",
	"Germany": "Deutschland",
	"Ghana": "Ghana",
	"Gibraltar": "Gibraltar",
	"Greece": "Griechenland",
	"Greenland": "Grönland",
	"Grenada": "Grenada",
	"Guadeloupe": "Guadeloupe",
	"Guam": "Guam",
	"Guatemala": "Guatemala",
	"Guernsey": "Guernsey",
	"Guinea": "Guinea",
	"Guinea-Bissau": "Guinea-Bissau",
	"Guyana": "Guyana",
	"Haiti": "Haiti",
	"Heard Island and McDonald Islands": "Heard und McDonaldinseln",
	"Honduras": "Honduras",
	"Hong Kong": "Hongkong",
	"Hungary": "Ungarn",
	"Iceland": "Island",
	"India": "Indien",
	"Indonesia": "Indonesien",
	"Iran": "Iran",
	"Iraq": "Irak",
	"Ireland": "Irland",
	"Isle of Man": "Isle of Man",
	"Israel": "Israel",
	"Italy": "Italien",
	"Ivory Coast": "Elfenbeinküste",
	"Jamaica": "Jamaika",
	"Japan": "Japan",
	"Jersey": "Jersey",
	"Jordan": "Jordan",
	"Kazakhstan": "Kasachstan",
	"Kenya": "Kenia",
	"Kiribati": "Kiribati",
	"North Korea": "Nord Korea",
	"South Korea": "Südkorea",
	"Kuwait": "Kuwait",
	"Kyrgyzstan": "Kirgisistan",
	"Laos": "Laos",
	"Latvia": "Lettland",
	"Lebanon": "Libanon",
	"Lesotho": "Lesotho",
	"Liberia": "Liberia",
	"Libya": "Libyen",
	"Liechtenstein": "Liechtenstein",
	"Lithuania": "Litauen",
	"Luxembourg": "Luxemburg",
	"Macao": "Macao",
	"North Macedonia": "Nordmazedonien",
	"Madagascar": "Madagaskar",
	"Malawi": "Malawi",
	"Malaysia": "Malaysia",
	"Maldives": "Malediven",
	"Mali": "Mali",
	"Malta": "Malta",
	"Marshall Islands": "Marshallinseln",
	"Martinique": "Martinique",
	"Mauritania": "Mauretanien",
	"Mauritius": "Mauritius",
	"Mayotte": "Mayotte",
	"Mexico": "Mexiko",
	"Micronesia": "Mikronesien",
	"Moldova": "Moldau",
	"Monaco": "Monaco",
	"Mongolia": "Mongolei",
	"Montenegro": "Montenegro",
	"Montserrat": "Montserrat",
	"Morocco": "Marokko",
	"Mozambique": "Mosambik",
	"Myanmar": "Myanmar",
	"Namibia": "Namibia",
	"Nauru": "Nauru",
	"Nepal": "Nepal",
	"Netherlands": "Niederlande",
	"New Caledonia": "Neu-Kaledonien",
	"New Zealand": "Neuseeland",
	"Nicaragua": "Nicaragua",
	"Niger": "Niger",
	"Nigeria": "Nigeria",
	"Niue": "Niue",
	"Norfolk Island": "Norfolkinsel",
	"Northern Mariana Islands": "Nördliche Marianneninseln",
	"Norway": "Norwegen",
	"Oman": "Oman",
	"Pakistan": "Pakistan",
	"Palau": "Palau",
	"Palestine": "Palästina",
	"Panama": "Panama",
	"PNG": "PNG",
	"Paraguay": "Paraguay",
	"Peru": "Peru",
	"Philippines": "Philippinen",
	"Pitcairn": "Pitcairn",
	"Poland": "Polen",
	"Portugal": "Portugal",
	"Puerto Rico": "Puerto Rico",
	"Qatar": "Katar",
	"Réunion": "Réunion",
	"Romania": "Rumänien",
	"Russian Federation": "Russische Föderation",
	"Rwanda": "Ruanda",
	"Saint Barthélemy": "Saint Barthélemy",
	"St. Helena": "St. Helena",
	"Saint Kitts and Nevis": "St. Kitts und Nevis",
	"Saint Lucia": "St. Lucia",
	"Saint Martin (French part)": "Saint Martin (französischer Teil)",
	"Saint Pierre and Miquelon": "Saint Pierre und Miquelon",
	"St. Vincent": "St. Vincent",
	"Samoa": "Samoa",
	"San Marino": "San Marino",
	"São Tomé": "São Tomé",
	"Saudi Arabia": "Saudi Arabien",
	"Senegal": "Senegal",
	"Serbia": "Serbien",
	"Seychelles": "Seychellen",
	"Sierra Leone": "Sierra Leone",
	"Singapore": "Singapur",
	"Sint Maarten (Dutch part)": "Sint Maarten (niederländischer Teil)",
	"Slovakia": "Slowakei",
	"Slovenia": "Slowenien",
	"Solomon Islands": "Salomon-Inseln",
	"Somalia": "Somalia",
	"South Africa": "Südafrika",
	"South Georgia and the South Sandwich Islands": "Süd-Georgien und die südlichen Sandwich-Inseln",
	"South Sudan": "Südsudan",
	"Spain": "Spanien",
	"Sri Lanka": "Sri Lanka",
	"Sudan": "Sudan",
	"Svalbard and Jan Mayen": "Svalbard und Jan Mayen",
	"Sweden": "Schweden",
	"Switzerland": "Schweiz",
	"Syrian Arab Republic": "Syrische Arabische Republik",
	"Taiwan": "Taiwan",
	"Tajikistan": "Tadschikistan",
	"Tanzania": "Tansania",
	"Thailand": "Thailand",
	"Timor, East": "Timor, Osten",
	"Timor": "Timor",
	"Togo": "Togo",
	"Tokelau": "Tokelau",
	"Tonga": "Tonga",
	"Trinidad and Tobago": "Trinidad und Tobago",
	"Tunisia": "Tunesien",
	"Turkey": "Türkei",
	"Turkmenistan": "Turkmenistan",
	"Turks and Caicos Islands": "Turks- und Caicosinseln",
	"Tuvalu": "Tuvalu",
	"Uganda": "Uganda",
	"Ukraine": "Ukraine",
	"United Arab Emirates": "Vereinigte Arabische Emirate",
	"UK": "Großbritannien und Nordirland",
	"United States Minor Outlying Islands": "Kleinere abgelegene Inseln der Vereinigten Staaten",
	"Uruguay": "Uruguay",
	"Uzbekistan": "Usbekistan",
	"Vanuatu": "Vanuatu",
	"Venezuela": "Venezuela",
	"Vietnam": "Vietnam",
	"Virgin Islands (British)": "Jungferninseln, Britisch",
	"Virgin Islands (U.S.)": "Jungferninseln (US)",
	"Wallis and Futuna": "Wallis und Futuna",
	"Western Sahara": "Westsahara",
	"Yemen": "Jemen",
	"Zambia": "Sambia",
	"Zimbabwe": "Zimbabwe",
	"Chile": "Chile",
	"Suriname": "Suriname",
	"USA": "USA",
	"Cooperation": "Kooperation",
	"Single farm": "Einzelbetrieb",
	"could not get report: {{error}}": "Bericht konnte nicht abgerufen werden: {$INTERPOLATION}",
	"could not save PDF file: {{error}}": "PDF-Datei konnte nicht gespeichert werden: {$INTERPOLATION}",
	"could not save the report: {{error}}": "Der Bericht konnte nicht gespeichert werden: {$INTERPOLATION}",
	"could not delete the report: {{error}}": "Der Bericht konnte nicht gelöscht werden: {$INTERPOLATION}",
	"could not update the report: {{error}}": "Der Bericht konnte nicht aktualisiert werden: {$INTERPOLATION}",
	"report not found": "Bericht nicht gefunden",
	"report already reconciled": "Bericht bereits finalisiert",
	"could not reconcile the report: {{error}}": "Der Bericht konnte nicht finalisiert werden: {$INTERPOLATION}",
	"could not find the report with id {{report_id}}": "Der Bericht mit der ID {$INTERPOLATION} konnte nicht gefunden werden",
	"error creating PDF": "Fehler beim PDF Erstellen",
	"error creating CSV": "Fehler beim Erstellen (CSV)",
	"error creating Sheet": "Fehler beim Erstellen der Tabelle",
	"error creating XML": "Fehler beim Erstellen (XML)",
	"Type": "Art",
	"Items per page": "Einträge pro Seite",
	"Next page": "Nächste Seite",
	"Previous page": "Vorherige Seite",
	"0 of {{length}}": "0 von {$INTERPOLATION}",
	"{{startIndex}} - {{endIndex}} of {{length}}": "{$INTERPOLATION} - {$INTERPOLATION_1} von {$INTERPOLATION_2}",
	"First page": "Erste Seite",
	"Last page": "Letzte Seite",
	"Months": "Monate",
	"Value": "Wert",
	"Certification": "Zertifizierung",
	"Water Activity": "Wasseraktivität",
	"Origins": "Herkunft",
	"estimated yield": "voraussichtlicher Ertrag",
	"estimated amount": "voraussichtliche Menge",
	"month": "Monat",
	"batches": "Röstungen",
	"batch": "Röstung",
	"one batch": "eine Röstung",
	"days": "Tage",
	"{{count}} on one day": "{$INTERPOLATION} an einem Tag",
	"{{count}} on {{days}} days": "{$INTERPOLATION} an {$INTERPOLATION_1} Tagen",
	"still ~{{month_left}} months": "noch ~{$INTERPOLATION} Monate",
	"still ~{{weeks_left}} weeks": "noch ~{$INTERPOLATION} Wochen",
	"still about one week": "noch ungefähr für eine Woche",
	"still about one month": "noch ungefähr für einen Monat",
	"more than": "mehr als",
	"weekly": "wöchentlich",
	"monthly": "monatlich",
	"Monthly Production": "Monatliche Produktion",
	"This year": "Dieses Jahr",
	"Last year": "Letztes Jahr",
	"{{nrCounts}} roasts": "{$INTERPOLATION} Röstungen",
	"one roast": "eine Röstung",
	"one lot": "ein Los",
	"{{nrCoffees}} lots": "{$INTERPOLATION} Lose",
	"1 month": "1 Monat",
	"{{nrMonth}} months": "{$INTERPOLATION} Monate",
	"1 year": "1 Jahr",
	"{{nrYears}} years": "{$INTERPOLATION} Jahre",
	"Age": "Alter",
	"today": "Heute",
	"stored": "gelagert",
	"with replacements": "mit Ersatzzutaten",
	"roasted": "geröstet",
	"estimated age": "vermutliches Alter",
	"age": "Alter",
	"yield": "Ertrag",
	"expected total age": "voraussichtliches Gesamtalter",
	"expected reach": "erwartete Reichweite",
	"min reach": "min. Reichweite",
	"max reach": "max. Reichweite",
	"harvested": "geerntet",
	"Regions": "Regionen",
	"Accumulated Production": "Kumulierte Betriebsleistung",
	"Yearly Production": "Jahresproduktion",
	"Ambient": "Umgebungsluft",
	"Machines": "Maschinen",
	"Users": "Benutzer",
	"User": "Benutzer",
	"amount": "Menge",
	"must specify target of transfer": "muss das Transferziel angeben",
	"must specify location of change": "muss den Ort der Änderung angeben",
	"must specify the beans to be changed": "die zu ändernden Rohbohnen müssen angegeben werden",
	"roast must not have both beans and blend": "eine Röstung darf nicht Rohbohnen und Mischung gleichzeitig haben",
	"invalid ID given for Location": "ungültige ID für Standort angegeben",
	"trying to get location": "Anfragen der Position",
	"error retrieving stock of a store": "Fehler beim Abrufen des Bestands eines Lagers",
	"error adding the custom selection property": "Fehler beim Hinzufügen der benutzerdefinierten Auswahleigenschaft",
	"error adding the region": "Fehler beim Hinzufügen der Region",
	"could not identify the user": "Benutzer konnte nicht identifiziert werden",
	"could not update the user account": "Benutzerkonto konnte nicht aktualisiert werden",
	"could not update the user": "Benutzer konnte nicht aktualisiert werden",
	"could not find the user": "Benutzer konnte nicht gefunden werden",
	"user is not associated with an account. Please send an email to admin@artisan.plus": "Benutzer ist keinem Konto zugeordnet. Bitte sende eine E-Mail an admin@artisan.plus",
	"Your account is not activated. Please send an email to admin@artisan.plus": "Dein Konto ist nicht aktiviert. Bitte sende eine E-Mail an admin@artisan.plus",
	"missing or invalid start or end date": "fehlendes oder ungültiges Start- oder Enddatum",
	"missing or invalid date": "fehlendes oder ungültiges Datum",
	"no user information given": "keine Benutzerinformationen angegeben",
	"no email given": "keine E-Mail angegeben",
	"no password given": "kein Passwort angegeben",
	"no nickname given": "kein Spitzname angegeben",
	"user data could not be downloaded": "Benutzerdaten konnten nicht heruntergeladen werden",
	"could not send email": "E-Mail konnte nicht gesendet werden",
	"wrong password reset link": "falscher Link zum Zurücksetzen des Passworts",
	"not allowed to change someone else\\'s password": "Das Passwort eines anderen Benutzers darf nicht geändert werden",
	"could not create account with customer_code {{ccode}}": "Konto konnte nicht mit Kundencode {$INTERPOLATION} erstellt werden",
	"could not add user": "Benutzer konnte nicht hinzugefügt werden",
	"successfully uploaded data but could not set password": "Daten wurden erfolgreich hochgeladen, das Passwort konnte jedoch nicht festgelegt werden",
	"could not retrieve user from id {{userId}}": "Benutzer konnte nicht von ID {$INTERPOLATION} abgerufen werden",
	"object with id {{id}} not found": "Objekt mit der ID {$INTERPOLATION} wurde nicht gefunden",
	"{{model}} with id {{_id}} not found": "{$INTERPOLATION} mit der ID {$INTERPOLATION_1} wurde nicht gefunden",
	"trying to get {{model}} by name {{label}}": "versuchen, {$INTERPOLATION} anhand des Namens {$INTERPOLATION_1} abzurufen",
	"could not add producer location {{label}}": "Standort des Produzenten konnte nicht hinzugefügt werden {$INTERPOLATION}",
	"location with {{label and type info}} already exists": "Standort mit {$INTERPOLATION} ist bereits vorhanden",
	"could not add location {{label}}": "Standort {$INTERPOLATION} konnte nicht hinzugefügt werden",
	"could not update location {{label}}": "Standort {$INTERPOLATION} konnte nicht aktualisiert werden",
	"could not update location of {{producerLabel}}": "Standort vom Produzenten {$INTERPOLATION} konnte nicht aktualisiert werden",
	"could not find beans": "Rohbohne nicht gefunden",
	"roast not found": "Röstung nicht gefunden",
	"store not found": "Lager nicht gefunden",
	"purchase not found": "Kauf nicht gefunden",
	"transfer not found": "Transfer nicht gefunden",
	"sale not found": "Verkauf nicht gefunden",
	"correction not found": "Korrektur nicht gefunden",
	"could not add supplier {{label}}": "Anbieter {$INTERPOLATION} konnte nicht hinzugefügt werden",
	"could not add customer {{label}}": "Kunden {$INTERPOLATION} konnte nicht hinzugefügt werden",
	"could not find blend with ID {{blend}}": "Mischung mit ID {$INTERPOLATION} konnte nicht gefunden werden",
	"trying to get {{model}}": "versuchen, {$INTERPOLATION} zu bekommen",
	"invalid ID given for {{property}}": "ungültige ID für {$INTERPOLATION} angegeben",
	"could not make related updates": "konnte keine entsprechenden Updates vornehmen",
	"stock of beans at location {{location}} could not be updated": "Rohbohnenvorrat an Standort {$INTERPOLATION} konnte nicht aktualisiert werden",
	"could not update property {{error}}": "Eigenschaft {$INTERPOLATION} konnte nicht aktualisiert werden",
	"roast with roast_id {{roast_id}} not found": "Röstung mit Nummer {$INTERPOLATION} nicht gefunden",
	"rejected deleting all {{model}}": "das Löschen aller {$INTERPOLATION} wurde abgelehnt",
	"could not check if {{model}} exists in DB": "Es konnte nicht geprüft werden, ob {$INTERPOLATION} in der DB vorhanden ist",
	"logged in user not the same as user to be deleted": "angemeldeter Benutzer, der nicht mit dem zu löschenden Benutzer identisch ist",
	"logged in user not the same as user to be updated": "Angemeldeter Benutzer, der nicht mit dem zu aktualisierenden Benutzer identisch ist",
	"no data": "keine Daten",
	"could not delete location": "Standort konnte nicht gelöscht werden",
	"could not un-reconcile a transaction in the report": "Eine Transaktion im Bericht konnte nicht rückgängig gemacht werden",
	"undefined": "nicht definiert",
	"error retrieving information": "Fehler beim Abrufen von Informationen",
	"error retrieving all store stocks": "Fehler beim Abrufen aller Lagerbestände",
	"error retrieving all purchases for the beans": "Fehler beim Abrufen aller Einkäufe für die Rohbohnen",
	"error retrieving a store": "Fehler beim Abrufen eines Lagers",
	"value of beans at location {{location}} could not be calculated": "Der Wert der Rohbohnen am Standort {$INTERPOLATION} konnte nicht berechnet werden",
	"Update available, please do a browser refresh (CMD-SHIFT-R or CTRL-SHIFT-F5)": "App Update: Bitte aktualisiere die Seite im Browser (CMD-SHIFT-R oder CTRL-SHIFT-F5)",
	"location not found": "Standort nicht gefunden",
	"erroneous altitude range": "falscher Höhenbereich",
	"erroneous screen size range": "falscher Rohbohnengrößenbereich",
	"error updating the additional report information": "Fehler beim Aktualisieren der Berichtsinformationen",
	"Failed updating blend templates": "Fehler beim Aktualisieren der Mischungsvorlagen",
	"error updating the beans information": "Fehler beim Aktualisieren der Rohbohneninformationen",
	"error updating the roast information": "Fehler beim Aktualisieren der Röstung",
	"error updating the blend information": "Fehler beim Aktualisieren der Mischung",
	"error updating the store information": "Fehler beim Aktualisieren des Lagers",
	"error updating the stock information": "Fehler beim Aktualisieren der Bestandsinformationen",
	"error retrieving all locations": "Fehler beim Abrufen aller Standorte",
	"error retrieving all beans": "Fehler beim Abrufen aller Rohbohnen",
	"error retrieving all blends": "Fehler beim Abrufen aller Mischungen",
	"error retrieving all customers": "Fehler beim Abrufen aller Kunden",
	"error retrieving reports": "Fehler beim Abrufen der Berichte",
	"error retrieving graph data": "Fehler beim Abrufen von Diagrammdaten",
	"error adding store": "Fehler beim Hinzufügen des Lagers",
	"error adding purchase": "Fehler beim Hinzufügen des Kaufs",
	"error adding correction": "Fehler beim Hinzufügen der Korrektur",
	"error adding transfer": "Fehler beim Transferieren",
	"error adding sale": "Fehler beim Hinzufügen des Verkaufs",
	"error editing store": "Fehler beim Bearbeiten des Lagers",
	"error editing purchase": "Fehler beim Bearbeiten des Kaufs",
	"error editing correction": "Fehler beim Bearbeiten der Korrektur",
	"error editing transfer": "Fehler beim Bearbeiten des Transfers",
	"error editing sale": "Fehler beim Bearbeiten des Verkaufs",
	"error deleting purchase": "Fehler beim Löschen des Kaufs",
	"error deleting correction": "Fehler beim Löschen der Korrektur",
	"error deleting transfer": "Fehler beim Löschen des Transfers",
	"error deleting sale": "Fehler beim Löschen des Verkaufs",
	"no documents were uploaded": "Es wurden keine Dateien hochgeladen",
	"server error": "Serverfehler",
	"error": "Fehler",
	"User with email {{email}} not found": "Benutzer mit E-Mail {$INTERPOLATION} wurde nicht gefunden",
	"Email not verified. Please check the email sent by admin@artisan.plus": "E-Mail wurde nicht bestätigt. Bitte lese die E-Mail von admin@artisan.plus",
	"Your account does not have enough credit. Please recharge at {{link}}": "Dein Konto verfügt nicht über ausreichend Guthaben. Bitte lade es über unseren Shop auf {$INTERPOLATION}",
	"wrong email or password": "falsche Email oder Passwort",
	"wrong password given": "falsches Passwort angegeben",
	"User with email {{email}} already exists": "Benutzer mit E-Mail {$INTERPOLATION} ist bereits vorhanden",
	"you are not allowed to access this data": "Du darfst nicht auf diese Daten zugreifen",
	"user not found": "Benutzer nicht gefunden",
	"not found": "nicht gefunden",
	"producer with label {{label}} already exists": "Produzent mit Label {$INTERPOLATION} ist bereits vorhanden",
	"could not add producer {{label}}": "Produzent {$INTERPOLATION} konnte nicht hinzugefügt werden",
	"could not update producer {{label}}": "Produzent {$INTERPOLATION} konnte nicht aktualisiert werden",
	"could not update supplier {{label}}": "Anbieter {$INTERPOLATION} konnte nicht aktualisiert werden",
	"could not update customer {{label}}": "Kunde {$INTERPOLATION} konnte nicht aktualisiert werden",
	"could not update roast {{label}}": "Röstung konnte nicht aktualisiert werden {$INTERPOLATION}",
	"could not update coffee {{label}}": "Rohbohnen {$INTERPOLATION} konnten nicht aktualisiert werden",
	"location with label {{label}} already exists": "Die Position mit der Bezeichnung {$INTERPOLATION} ist bereits vorhanden",
	"could not add beans {{label}}": "Rohbohne konnte nicht hinzugefügt werden {$INTERPOLATION}",
	"supplier with label {{label}} already exists": "Lieferant mit Label {$INTERPOLATION} ist bereits vorhanden",
	"blend with label {{label}} already exists": "Mischung mit Label {$INTERPOLATION} existiert bereits",
	"customer with label {{label}} already exists": "Kunde mit Label {$INTERPOLATION} ist bereits vorhanden",
	"stock update failed": "Lageraktualisierung fehlgeschlagen",
	"could not save image file {{error}}": "Bilddatei {$INTERPOLATION} konnte nicht gespeichert werden",
	"wrong email verification link": "falscher Link zur E-Mail-Bestätigung",
	"Email verify failed": "E-Mail-Bestätigung fehlgeschlagen",
	"Hidden beans with this name + picked year + origin already exist, {{info}}": "Bohne (versteckt) mit Name + Erntejahr + Herkunft existiert bereits: {$INTERPOLATION}",
	"Beans with this name + picked year + origin already exist, {{info}}": "Bohne mit Name + Erntejahr + Herkunft existiert bereits: {$INTERPOLATION}",
	"this does not belong to current user": "Dies gehört nicht zum aktuellen Benutzer",
	"cannot update since a related item is already finalized": "kann nicht aktualisiert werden, da ein referenziertes Element bereits finalisiert ist",
	"Could not reach server.": "Server konnte nicht erreicht werden.",
	"Please repeat the password reset process": "Bitte versuche noch einmal das Passwort zurückzusetzen",
	"Could not send verification email": "Bestätigungs-E-Mail konnte nicht gesendet werden",
	"error updating the information": "Fehler beim Aktualisieren der Informationen",
	"error retrieving all roasts": "Fehler beim Abrufen aller Röstungen",
	"error updating user information": "Fehler beim Aktualisieren der Benutzerinformationen",
	"error retrieving all stores": "Fehler beim Abrufen aller Lager",
	"invalid email": "Keine gültige Email-Adresse",
	"User with email already invited": "Benutzer mit E-Mail {$INTERPOLATION} ist bereits eingeladen worden",
	"invite for read-only access": "Gib einem Benutzer Leserechte",
	"get full access": "Die Person erhält vollen Zugriff auf Deine Daten.",
	"get read-only access": "Die Person kann all Deine Daten lesen.",
	"add user to account": "Füge Deinem Konto einen Benutzer hinzu",
	"update password": "PASSWORT ERNEUERN",
	"register_password_repeat": "Wiederhole das Passwort",
	"Weight": "Chargengröße",
	"Yield": "Ertrag",
	"Loss": "Verlust",
	"added or modified after the report has been created": "hinzugefügt oder geändert, nachdem der Bericht bereits erstellt war",
	"not included explanation": "Einträge mit Datumsangaben, die zu diesem Bericht gehören, jedoch nicht enthalten sind:",
	"allow empty weights": "Auch Röstungen ohne vollständige Gewichtsangaben zufügen",
	"invalid roasts not included": "Die folgenden Röstungen werden nicht in den Bericht aufgenommen, da wichtige Informationen fehlen:",
	"Missing items": "Fehlende Einträge",
	"roasts not in range are excluded": "Die folgenden Röstungen werden nicht in den Bericht aufgenommen, da das Röstdatum vor dem ausgewählten Zeitraum liegt:",
	"start date": "Von",
	"end date": "Bis",
	"no reports": "Noch keine Berichte verfügbar.",
	"You can freely choose your report intervals here.": "Hier kannst Du Röstberichte über beliebige Zeitpunkte erstellen.",
	"Reports created here must be consecutive (no overlaps or gaps). They can be finalized for official purposes.": "Röstberichte hier müssen zeitlich lückenlos aufeinanderfolgen. Für Nachweiszwecke können sie unveränderbar gemacht werden.",
	"Soon you will see an overview of your beans here; and more, stay tuned!": "Hier gibt es in Kürze eine Übersicht Deiner Rohbohnen. und mehr, bleib dran!",
	"to customer": "an",
	"x% of a blend": "({$INTERPOLATION} einer Mischung)",
	"of a blend": "einer Mischung",
	"between dates": "zwischen {$INTERPOLATION} und {$INTERPOLATION_1}",
	"list header": "Anzahl der Transaktionen: {$INTERPOLATION}",
	"no purchases made": "Keine Einkäufe gemacht. Die Kosten werden nur durch den Preis bei Einkäufen berechnet. Du kannst einen Kauf hinzuzufügen oder eine eventuell bestehende Korrektur durch einen Kauf ersetzen.",
	"to target": "nach",
	"amount header": "Menge",
	"limit header": "Limit für Lageralarm",
	"last purchase/correction price info": "{$INTERPOLATION}{$INTERPOLATION_1} für {$INTERPOLATION_2} am {$INTERPOLATION_3}",
	"no last purchase": "Keine vorherigen Einkaufspreise verfügbar.",
	"Loading last purchase price": "Letzter Kauf wird geladen ...",
	"edit purchase": "Kauf bearbeiten",
	"add purchase": "Kauf hinzufügen",
	"pick beans from list": "Wähle Rohbohnen",
	"pick supplier from list": "Wähle den Lieferanten",
	"add a new supplier": "Neuen Lieferanten hinzufügen",
	"select coffee": "Zuerst Rohbohnen auswählen!",
	"rename": "Umbenennen",
	"tracking_number": "Sendungsnummer",
	"product_id": "Produkt ID",
	"Price per": "Preis pro",
	"total price": "Gesamtpreis",
	"edit sale": "Verkauf bearbeiten",
	"add sale": "Verkauf hinzufügen",
	"pick customer from list": "Kunde wählen",
	"no stores with beans": "Du hast keine Lagerstätten mit Rohbohnen.",
	"from source store": "Von",
	"add a new customer": "Neuen Kunden hinzufügen",
	"edit correction title": "Korrektur bearbeiten",
	"bought beans": "Bohnen gekauft?",
	"add a purchase": "Kauf hinzufügen!",
	"sold beans": "Bohnen verkauft?",
	"add a sale": "Verkauf hinzufügen!",
	"moved beans": "Bohnen woanders?",
	"add a transfer": "Transfer hinzufügen!",
	"other reason": "Anderer Grund",
	"new amount": "Neue Menge",
	"new weight in": "Neues Gewicht in {$INTERPOLATION}",
	"delta amount": "Betragsdifferenz",
	"delta weight in": "Gewichtsunterschied in {$INTERPOLATION}",
	"show delta amounts": "Lieber die Gewichtsdifferenz eingeben",
	"correction title": "Korrigiere Deinen Bestand",
	"purchase": "Kaufen",
	"sell": "Verkaufen",
	"transfer": "Transferieren",
	"correct": "Korrigieren",
	"store with xkg of beans": "mit {$INTERPOLATION} Rohbohnen",
	"edit transfer": "Transfer bearbeiten",
	"add transfer": "Transfer hinzufügen",
	"Please select a source store first!": "Bitte wähle zuerst ein Quellenlager aus!",
	"Month": "Monat",
	"Set stock to 0": "Lagerbestand auf 0 setzen",
	"Fill with": "Auffüllen mit",
	"are you sure": "Bist Du sicher?",
	"Upload documents": "Dokumente hochladen",
	"Graphs of your data are shown here as soon as there is enough data": "Diagramme Deiner Daten werden hier angezeigt, sobald genügend Daten vorhanden sind.",
	"inventory management": "Lagerverwaltung",
	"How can artisan.plus support you?": "Wie kann artisan.plus Dich unterstützen?",
	"every roast automatically reduces your stock": "Jeder Röstvorgang aktualisiert automatisch den Bestand",
	"simplifies planning": "Vereinfacht die Planung und Durchführung Deiner Röstungen",
	"roast even without an internet connection": "Rösten auch ohne Internetverbindung",
	"keeps your roast profiles private": "Hält Deine Röstprofile privat",
	"includes unlimited users and machines": "Inklusive beliebig vieler Benutzer und Maschinen",
	"blends and certifications": "Unterstützt Mischungen und Zertifizierungen",
	"charts and tax reports": "Diagramme, Steuerberichte und Prognosen",
	"imports coffee descriptions": "Importiert Kaffeebeschreibungen von Händlern",
	"3 months free testing": "3 Monate gratis testen – 48€ / Monat (noch günstiger bei längeren Abos)",
	"includes unlimited warehouses and bean quantities": "Inklusive beliebig viele Lager und Bohnenmengen",
	"description 1": "Wie lange reichen meine Rohbohnen noch?",
	"description 2": "Was ist mein Lagerbestand wert?",
	"description 3": "Was für Rohbohnen hab ich?",
	"description 4": "Wie dokumentiere ich meine Röstungen am effizientesten?",
	"description 5": "Wie viel produziere ich?",
	"description 6": "Wie kann ich meine Steuermeldungen generieren?"
}