<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>

<ng-template #blendEntry let-blend="blend" let-isTrigger="isTrigger">
    <div class="smallerfont scheduler-inside-select">
        @if (blend?.hr_id) {
        <span class="littlespaceright">{{blend.hr_id}}</span>
        <app-organicicon [isOrganic]="utils.isOrganicBlend(blend)" [size]="10" [leftMargin]="3"></app-organicicon>
        }
        @if (blend?.blendinfo) {
        <span>{{blend.blendinfo}}</span>
        }
    </div>
    <span style="margin-right: 15px;">{{blend?.label}}</span>
</ng-template>


<h1 mat-dialog-title i18n="generic$$link to dialog to find beans or blends that are not directly dislayed@@Search beans">Search beans</h1>
<mat-dialog-content>

    <div class="dialog-group" style="margin-bottom: 40px;">
        <mat-form-field class="beans-search-formfields" subscriptSizing="dynamic">
            <mat-label i18n="generic$$Title@@Title">Title</mat-label>
            <input required matInput data-test="npt_txt" type="text"
                [(ngModel)]="searchText" cdkFocusInitial>
        </mat-form-field>

        <button [disabled]="searchText?.length < 3 || isLoading || isLoadingB"
            (click)="doSearch()" type="submit" mat-raised-button
            class="action-button dofilter-button">
            @if (isLoading || isLoadingB) {
            <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
            } @else {
            <mat-icon>search</mat-icon>
            }
        </button>
    </div>

    @if (data?.searchCoffees) {
    <div class="dialog-group">
        <mat-form-field class="form-group beans-search-beans" subscriptSizing="dynamic">
            <mat-label i18n="generic$$Beans@@Beans">Beans</mat-label>
            <mat-select [disabled]="!coffees?.length" hideSingleSelectionIndicator="true"
                placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                name="coffee" panelClass="twoLineOption" panelWidth="null"
                [(ngModel)]="coffee"
                [compareWith]="utils.compareObjectsFn">
                @if (coffee) {
                <mat-select-trigger class="twoLineOption ellipsed">
                    <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee, isTrigger: true}"></ng-container>
                </mat-select-trigger>
                }
                @if (filteredCoffees?.length) {
                <mat-option [value]="null"></mat-option>
                }
                @for (coffee of filteredCoffees; track coffee?._id) {
                <mat-option [value]="coffee">
                    <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee}"></ng-container>
                </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <button [disabled]="!coffee" class="action-button" mat-raised-button type="button" (click)="selectBean()">
            <mat-icon>done</mat-icon>
        </button>
    </div>
    }

    @if (this.data?.searchBlends) {
    <div class="dialog-group">
        <mat-form-field class="form-group beans-search-blends" subscriptSizing="dynamic">
            <mat-label i18n="generic$$Blend@@Blend">Blend</mat-label>
            <mat-select name="blend"
                [disabled]="!blends?.length"
                panelClass="twoLineOption" hideSingleSelectionIndicator="true" panelWidth="null"
                placeholder="Blend" i18n-placeholder="generic$$Blend@@Blend"
                [compareWith]="utils.compareObjectsFn"
                [(ngModel)]="blend">
                @if (blend) {
                <mat-select-trigger class="twoLineOption ellipsed">
                    <ng-container *ngTemplateOutlet="blendEntry; context: {blend}"></ng-container>
                </mat-select-trigger>
                }
                @if (filteredBlends?.length) {
                <mat-option [value]="null"></mat-option>
                }
                @for (blend of filteredBlends; track blend?.hr_id) {
                <mat-option [value]="blend">
                    <ng-container *ngTemplateOutlet="blendEntry; context: {blend}"></ng-container>
                </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <button [disabled]="!blend" class="action-button" mat-raised-button type="button" (click)="selectBlend()">
            <mat-icon>done</mat-icon>
        </button>
    </div>
    }
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="cancel-button" autofocus cdkFocusInitial mat-button type="submit" mat-dialog-close tabindex="0">
        <mat-icon>close</mat-icon>
    </button>
</mat-dialog-actions>