import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoresComponent } from './stores.component';
import { AuthGuard } from 'src/app/modules/frame/services/auth-guard.service';

const routes: Routes = [
    {
        path: '',
        component: StoresComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class StoreRoutingModule { }
