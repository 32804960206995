import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from './info-dialog.component';
import { TranslatorService } from 'src/app/util/services/translator.service';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        private dialog: MatDialog,
        private tr: TranslatorService,
    ) { }

    /**
     * Shows a standard dialog. Title and text will be translated.
     * If there is an additionalText, this will not be translated.
     *
     * @param {string} title dialog title, translated
     * @param {string} text content, translated
     * @param {string} additionalText also in content, not translated
     */
    showDialog(title: string, text: string, additionalText?: string): void {
        const dialogRef = this.dialog.open(InfoDialogComponent, {
            closeOnNavigation: true,
            data: {
                title: this.tr.anslate(title),
                text: this.tr.anslate(text),
                additionalText,
            }
        });

        dialogRef.afterClosed().subscribe();
    }
}
