<div class="image-upload-dialog">
    <div class="actions">
        <button mat-raised-button class="action-button" (click)="file.click()">
            <mat-icon>photo_library</mat-icon>
        </button>
        @if (croppedImage || errorLoading) {
        <button mat-raised-button class="edit-button"
            (click)="removeImage()">
            <mat-icon>delete_outline</mat-icon>
        </button>
        }
        <span class="flexspan"></span>
        <!-- Hidden input -->
        <input #file type="file" (change)="fileChangeEvent($event)" accept="image/*" class="hidden">
        <button mat-button type="button" class="cancel-button" (click)="cancel()">
            <mat-icon>close</mat-icon>
        </button>
        <button mat-raised-button [disabled]="!isReady && !errorLoading"
            (click)="saveImage()" class="action-button">
            <mat-icon>done</mat-icon>
        </button>
    </div>

    @if (loading) {
    <div>
        <mat-spinner style="margin: auto" diameter="20"></mat-spinner>
    </div>
    }

    <!-- outputType="both" -->
    <image-cropper class="cropping"
        output="base64"
        [imageChangedEvent]="imageChangedEvent"
        [imageFile]="imageFileChanged"
        [maintainAspectRatio]="data.avatar"
        [aspectRatio]="data.avatar ? 1/1 : 2/1"
        [resizeToWidth]="data.avatar ? 64 : 500"
        [onlyScaleDown]="true"
        [cropperMinWidth]="32"
        [cropperMinHeight]="32"
        [roundCropper]="data.avatar"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()">
    </image-cropper>

    @if (croppedImage) {
    <img [src]="croppedImage" class="cropped-image" [ngClass]="{'rounded': data.avatar}" alt="an arbitrary uploaded image" />
    }
</div>