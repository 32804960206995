import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, Inject, LOCALE_ID, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, throttleTime } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, DecimalPipe, formatNumber, NgClass } from '@angular/common';
import { GraphService } from 'src/app/modules/graph/graph.service';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';

@Component({
    standalone: true,
    selector: 'app-energytotal-widget',
    templateUrl: './energytotal-widget.component.html',
    styleUrls: ['./energytotal-widget-themed.component.scss'],
    imports: [NgClass, FormsModule, AsyncPipe, DecimalPipe],
})
export class EnergytotalWidgetComponent implements OnInit, OnDestroy {

    constructor(
        private breakpointObserver: BreakpointObserver,
        private userService: UserService,
        private utils: Utils,
        private router: Router,
        private graphService: GraphService,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        graphService.energyUnitChanged$.subscribe(
            energyUnit => {
                if (this._energyUnit !== energyUnit) {
                    this._energyUnit = energyUnit;
                    this.dataChanged();
                }
            });
    }

    readonly BTU_INDEX = 2;
    readonly CO2_INDEX = 3;
    readonly TOTALS_BTU_INDEX = 8;
    readonly TOTALS_CO2_INDEX = 9;

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currentUser: UserType;
    @Input() isDarkmode = false;

    _energyUnit: string;
    get energyUnit(): string { return this._energyUnit; }
    @Input() set energyUnit(eu: string) {
        if (this._energyUnit !== eu) {
            this._energyUnit = eu;
            this.dataChanged();
        }
    }
    // for notification of roast components
    @Output() energyUnitChange = new EventEmitter<string>();

    isSmall$: Observable<boolean>;

    data: { data?: number[], labels?: string[], additionalData?: { data: number[] }[] };

    btuTotal: number;
    co2Total: number;
    btuTotalStr: string;
    co2TotalStr: string;
    btuPerUnit: string;
    co2PerUnit: { pre: string, value: number, post: string };
    readonly co2Unit = 'kg';
    energyUnit1000: string;
    energyUnitDividedBy1000: string;
    useEnergyUnit1000 = false;
    useEnergyUnitDividedBy1000 = false;
    totalRoastedBTU: number;
    totalRoastedCO2: number;

    titleYear: string;

    inited = false;
    private ngUnsubscribe = new Subject();


    @Input() set newData(nd: { data?: number[], additionalData?: { data: number[] }[] }) {
        this.setNewData(nd);
    }

    ngOnInit(): void {
        this.isSmall$ = this.breakpointObserver.observe('(max-width: 750px)')
            .pipe(map(result => result.matches));

        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        this.energyUnit = this.currentUser.energy_unit || 'BTU';
        this.energyUnit1000 = this.utils.getUnit1000(this.energyUnit);
        // avoid setNewData being called (through Input()) before energyUnit loaded from user
        this.inited = true;
        this.dataChanged();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    // recalculates the shown values
    dataChanged(): void {
        if (!this.inited || !this.data?.data || !this.data.additionalData?.length) {
            return;
        }

        // this.titleYear = undefined;
        // if (this.data.labels[0] && this.data.labels[0] !== new Date().getFullYear().toString()) {
        this.titleYear = this.data.labels[0];
        // }

        // const totalRoasted = this.data.data[0] * this.utils.getUnitFactor(this.mainUnit);
        if (this.data.additionalData[this.BTU_INDEX]?.data && this.data.additionalData[this.BTU_INDEX].data[0] != null) {
            const val = this.data.additionalData[this.BTU_INDEX].data[0];
            this.btuTotal = this.utils.convertEnergy(val, this.energyUnit);
            if (this.btuTotal >= 100000) {
                this.btuTotalStr = formatNumber(this.btuTotal / 1000, this.locale, '1.0-0');
                this.useEnergyUnit1000 = true;
            } else {
                this.btuTotalStr = formatNumber(this.btuTotal, this.locale, '1.0-0');
                this.useEnergyUnit1000 = false;
            }

            this.totalRoastedBTU = this.data.additionalData[this.TOTALS_BTU_INDEX]?.data?.[0];
            if (this.totalRoastedBTU) {
                this.totalRoastedBTU *= this.utils.getUnitFactor(this.mainUnit);
                let btuper = this.btuTotal / this.totalRoastedBTU;
                // this.btuPerUnit = Number.isFinite(btuper) ? btuper.toFixed(0) : undefined;
                if (Number.isFinite(btuper) && btuper > 0) {
                    this.useEnergyUnitDividedBy1000 = false;
                    if (btuper < 1) {
                        this.energyUnitDividedBy1000 = this.utils.getUnitDividedBy1000(this.energyUnit);
                        if (this.energyUnitDividedBy1000) {
                            btuper *= 1000.0;
                            this.useEnergyUnitDividedBy1000 = true;
                        }
                    }
                    let digits = 0;
                    if (btuper < 10) {
                        digits = 2;
                    } else if (btuper < 100) {
                        digits = 1;
                    }
                    this.btuPerUnit = formatNumber(btuper, this.locale, `1.0-${digits}`);
                }
            }
        }

        if (this.data.additionalData[this.CO2_INDEX]?.data && this.data.additionalData[this.CO2_INDEX].data[0] != null) {
            this.co2Total = this.data.additionalData[this.CO2_INDEX].data[0];
            this.co2TotalStr = formatNumber(this.co2Total, this.locale, '1.0-0');

            this.totalRoastedCO2 = this.data.additionalData[this.TOTALS_CO2_INDEX]?.data?.[0];
            this.co2PerUnit = undefined;
            if (this.totalRoastedCO2) {
                this.totalRoastedCO2 *= this.utils.getUnitFactor(this.mainUnit);
                const co2per = this.co2Total / this.totalRoastedCO2;
                this.co2PerUnit = this.utils.formatAmountForPipe(co2per);
                // if (Number.isFinite(co2per) && co2per > 0) {
                //     let digits = 0;
                //     if (co2per < 10) {
                //         digits = 2;
                //     } else if (co2per < 100) {
                //         digits = 1;
                //     }
                //     this.co2PerUnit = formatNumber(co2per, this.locale, `1.0-${digits}`);
                // }
            }
        }
    }

    /**
     * Switches to another energyUnit.
     * Called from template, click on BTU widget.
     * Since it jumps over a unit if the result would be "0", this method should
     * only be called if the btuTotal is >0 in at least one energyUnit to avoid an endless loop.
     */
    switchEnergyUnit(): void {
        switch (this.energyUnit) {
            case 'BTU':
                this.energyUnit = 'kJ';
                break;
            case 'kJ':
                this.energyUnit = 'kCal';
                break;
            case 'kCal':
                this.energyUnit = 'kWh';
                break;
            case 'kWh':
                this.energyUnit = 'hph';
                break;
            case 'hph':
                this.energyUnit = 'BTU';
                break;
            default:
                this.energyUnit = 'kJ';
                break;
        }
        this.energyUnit1000 = this.utils.getUnit1000(this.energyUnit);
        // done via the this.energyUnit setter:
        // this.dataChanged();
        if (Math.round(this.btuTotal) === 0) {
            this.switchEnergyUnit();
            this.energyUnit1000 = this.utils.getUnit1000(this.energyUnit);
            // this.dataChanged();
            return;
        }
        this.saveEnergyUnit(this.energyUnit);
        this.graphService.energyUnitSource.next(this.energyUnit);
    }

    // stores the given energyUnit in the currentUser and on the server
    saveEnergyUnit(value: string): void {
        this.currentUser.energy_unit = value;

        if (!this.userService.isReadOnly()) {
            this.userService.updateUser({ _id: this.currentUser.user_id, energy_unit: value })
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true && response.result) {
                            this.userService.storeCurrentUser(this.currentUser);
                        } else {
                            this.utils.handleError('error updating user information', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error updating user information', error);
                    }
                });
        }
    }

    setNewData(nd: { data?: number[], additionalData?: { data: number[] }[] }): void {
        // nd.data: total amount per year, array
        // nd.coffees: [], // yields per year
        // nd.additionalData[0].data: number of roasts per year
        // nd.additionalData[1].data: number of roast days per year
        // nd.additionalData[2].data:  BTU per year
        // nd.additionalData[3].data:  CO2 in kg per year
        // nd.additionalData[4]: { totalWithBTU: number, totalWithCO2: number }, // totalAmountWithBTU and totalAmountWithCO2
        // nd.additionalData[5].data:  LPG (in BTU) per year
        // nd.additionalData[6].data:  NG (in BTU) per year
        // nd.additionalData[7].data:  ELEC (in BTU) per year

        // large number test:
        // nd.additionalData[3].data[0] = 123456;
        // small number test:
        // nd.additionalData[3].data[0] = 9;
        // only one / no valid values test:
        // nd.additionalData[3].data[0] = 0;
        // nd.additionalData[4].data[0] = 0;
        // test "0" for some energyUnits:
        // nd.additionalData[3].data[0] = 10;

        this.data = nd;
        this.dataChanged();
    }
}
