import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { Enumerations } from 'src/app/models/Enumerations';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { BleAcaia } from './ble-acaia';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-bleinput',
    templateUrl: './bleinput.component.html',
    styleUrls: ['./bleinput.component.scss']
})
export class BleinputComponent implements OnInit, OnDestroy {

    // TODO only show Acaia icon if BLE supported
    // TODO send already translated placeholder since i18n-placeholder cannot be used dynamically in the HTML

    bleenabled = false;

    @Input() required = false;
    @Input() disabled = false;
    @Input() placeholder: string;
    // used in template to reset value if cancelled
    @Input() origValue: number;
    @Input() value: string;

    @Output() valueChanged = new EventEmitter<number>();
    @Output() checkedValueChanged = new EventEmitter<number>();
    @Output() inputChanged = new EventEmitter<number>();

    connecting = false;
    connected = false;
    disconnecting = false;
    curValue: number;

    disconnecttimer: NodeJS.Timeout;
    needReconnect = false;

    currentUser: UserType;
    mainUnit: UnitSystemType = 'kg';
    isDarkmode = false;
    private ngUnsubscribe = new Subject();

    constructor(
        private logger: NGXLogger,
        private userService: UserService,
        private utils: Utils,
        private router: Router,
        private bleAcaia: BleAcaia,
    ) {
        this.currentUser = this.userService.getCurrentUser();
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }

        this.bleenabled = !!(navigator && navigator['bluetooth'] && navigator['bluetooth'].requestDevice);
    }

    ngOnInit(): void {
        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm)
            );
    }

    ngOnDestroy(): void {
        this.bleAcaia.leave();
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.disconnecttimer);
    }

    // async ngOnDestroy(): Promise<void> {
    //     await this.disconnect(false);
    // }

    async reconnect(): Promise<void> {
        this.connecting = false;
        this.needReconnect = true;
        await this.disconnect(false);
    }

    async connect(reconnect = false): Promise<void> {
        if (this.connecting) {
            await this.reconnect();
            return;
        }
        this.disconnecting = false;
        if (this.connected) {
            this.logger.debug('already connected');
            this.utils.handleError('error', 'already connected');
            return;
        }
        await this.bleAcaia.connectAcaia(
            reconnect,
            /* new data */
            (weight: number) => {
                if (this.connected && !this.disconnecting) {
                    // g => kg, no conversion to lbs
                    this.curValue = weight / 1000.0;
                    this.checkedValueChanged.emit(this.curValue);
                }
            },
            /* connecting */
            () => {
                this.logger.debug(' -- received connecting callback');
                this.connecting = true;
            },
            
            /* connected */
            () => {
                this.logger.debug(' -- received connected callback');
                if (this.disconnecttimer) {
                    clearTimeout(this.disconnecttimer);
                    this.disconnecttimer = undefined;
                }
                this.connected = true;
                this.connecting = false;
            },
            
            /* disconnected */
            async () => {
                this.logger.debug(' -- received disconnected callback');
                if (this.disconnecttimer) {
                    clearTimeout(this.disconnecttimer);
                    this.disconnecttimer = undefined;
                }
                if (!this.disconnecting) {
                    // the connection was cancelled, restore the original value
                    this.checkedValueChanged.emit(this.origValue);
                }
                this.connected = false;
                this.connecting = false;
                this.bleAcaia.leave();
                if (this.needReconnect) {
                    this.needReconnect = false;
                    await this.connect(true /*reconnect*/);
                }
            },

            /* error */
            (err: string) => {
                this.utils.handleError('error', err);
                this.connecting = false;
            },
        )
    }

    async close(confirm: boolean): Promise<void> {
        if (confirm) {
            // user accepts the current value; set the new origValue
            // in kg, no conversion to lbs
            this.origValue = this.curValue;
        } else {
            // user discards the current value, send the old origValue
            // in kg, no conversion to lbs
            this.checkedValueChanged.emit(this.origValue);
        }
        this.connected = false;
        this.connecting = false;
        this.bleAcaia.leave();
    }

    async disconnect(confirm: boolean): Promise<void> {
        this.disconnecting = true;
        // if (!this.connected) {
        //     this.logger.debug('already disconnected');
        //     return;
        // }
        // if (!this.bleAcaia) {
        //     this.logger.debug('not connected');
        //     return;
        // }
        if (confirm) {
            // user accepts the current value; set the new origValue
            // in kg, no conversion to lbs
            this.origValue = this.curValue;
        } else {
            // user discards the current value, send the old origValue
            // in kg, no conversion to lbs
            this.checkedValueChanged.emit(this.origValue);
        }
        await this.bleAcaia.disconnect();
        // if anything goes wrong, assure that the visual state is reset
        this.disconnecttimer = setTimeout(() => {
            this.connected = false;
            this.connecting = false;
        }, 5000);
    }
}
