import { FileUploadModule } from 'src/app/modules/ui/file-upload.module';
import { DialogModule } from 'src/app/modules/ui/dialog/dialog.module';
import { BlendsComponent } from 'src/app/modules/blend/blends.component';
import { BlendComponent } from 'src/app/modules/blend/blend.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlendRoutingModule } from 'src/app/modules/blend/blend-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { StockModule } from 'src/app/modules/stock/stock.module';
import { ModificationDateModule } from 'src/app/modules/ui/modification-date.module';
import { ReplaceAllInBlendsComponent } from './replace-all-in-blends.component';
import { BlendEditComponent } from './blend-edit.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UiModule } from 'src/app/modules/ui/ui.module';

@NgModule({
    declarations: [
        BlendComponent,
        BlendsComponent,
        ReplaceAllInBlendsComponent,
        BlendEditComponent,
    ],
    imports: [
        CommonModule,
        BlendRoutingModule,
        StockModule,
        RouterModule,
        FormsModule,
        MyMaterialModule,
        DialogModule,
        FileUploadModule,
        ModificationDateModule,
        NgxMatSelectSearchModule,
        UiModule,
    ],
})
export class BlendModule { }
