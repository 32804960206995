@if (!isOrganic && spacer) {
<div [ngStyle]="{'min-width': +size + +rightMargin + +leftMargin + 'px'}" style="display: inline-block;"></div>
} @else if (isOrganic) {
<span [ngStyle]="{'margin-right': rightMargin + 'px', 'margin-left': leftMargin + 'px', 'line-height': (+bottomMargin + +size) + 'px', 'margin-top': topMargin + 'px', 'display': 'inline-block' }">
    @if (!strikethrough) {
    <span [ngClass]="{'organic-icon': !inactive, 'inactive': inactive}" style="display: inline-block;" [ngStyle]="{'height': size+'px', 'width': size+'px', 'vertical-align': verticalAlign}">
        <svg height="100%" width="100%" x="0px" y="0px" viewBox="0 0 4.5 4.5" xml:space="preserve">
            <path
                d="M 0.20320236,4.4824097 C 0.12456706,4.458337 0.03962284,4.3779279 0.01648809,4.3056436 -0.02377138,4.1798414 0.0056886,4.0684035 0.14948623,3.8016529 0.44265954,3.2580239 0.93388784,2.689777 1.5716902,2.1564646 1.7852236,1.9779146 2.0932563,1.7454133 2.2870417,1.6165222 c 0.0845,-0.056208 0.08578,-0.0577 0.066228,-0.077764 C 2.3007037,1.4848219 2.1993771,1.4648507 2.1248732,1.4937355 2.0876652,1.5081604 1.8387623,1.6625841 1.6876725,1.7649837 1.1980277,2.0968365 0.96466471,2.2808511 0.58717758,2.6327613 0.44021294,2.769769 0.31785982,2.8785549 0.31528552,2.8745071 0.30536552,2.858917 0.23148912,2.6378857 0.21014125,2.5599258 0.15034225,2.3415046 0.13777215,2.243789 0.13768425,1.9966787 0.13760262,1.7418514 0.14986505,1.6588684 0.21474985,1.473734 0.46927525,0.74803153 1.3303594,0.26384075 2.6975577,0.07763907 3.122727,0.01972959 3.4343059,4.3420987e-4 3.9502462,3.8048483e-5 4.3474826,-2.7888063e-4 4.3779484,9.8767064e-4 4.4178575,0.01938004 4.5001845,0.05729502 4.5047805,0.07386388 4.4976605,0.30694311 4.4878705,0.62621074 4.4461365,0.94941318 4.3743706,1.2610291 4.160424,2.190002 3.6791463,2.8348339 2.9428187,3.1790725 2.6970401,3.2939709 2.4637687,3.3635672 2.1691714,3.4098726 1.8509973,3.4598822 1.4783687,3.4637156 1.1246844,3.4205923 1.0562204,3.412203 0.99642293,3.4075889 0.99179422,3.4102222 0.97961342,3.4172132 0.88838289,3.6211129 0.85446749,3.7171237 0.81268859,3.8354152 0.78503689,3.9456121 0.76152919,4.087601 0.73630109,4.2399566 0.70486959,4.3079029 0.62590652,4.3808024 0.51126875,4.4866358 0.3464925,4.5262449 0.20322606,4.4823945 Z"
                id="path951" />
        </svg>
    </span>
    } @else {
    <span class="inverted-icon" style="display: inline-block;" [ngStyle]="{'height': size+'px', 'width': size+'px'}">
        <svg height="100%" width="100%" x="0px" y="0px" viewBox="0 0 4.5 4.5" xml:space="preserve">
            <path
                d="M 0.2842672,0 0,0.2642211 0.718562,0.9321111 C 0.5231311,1.1071338 0.3827745,1.3070453 0.3041077,1.5317289 0.2405307,1.713316 0.2286337,1.7946877 0.2287137,2.0446333 c 9.07e-5,0.2423752 0.01237,0.3382194 0.070919,0.5524551 0.020898,0.076467 0.093186,0.2932694 0.1029171,0.3085587 0.00252,0.00397 0.1223839,-0.1027654 0.2662842,-0.2371476 C 1.0265921,2.3344034 1.2542084,2.1534704 1.7030702,1.8471149 L 1.8769063,2.0086922 C 1.7912273,2.0734502 1.7073066,2.1388887 1.6327421,2.2013453 1.0082411,2.7244403 0.5273385,3.2817871 0.2402802,3.8150007 0.099425,4.0766411 0.0705853,4.1859938 0.1100087,4.3093855 c 0.022652,0.070898 0.1057856,0.1497393 0.1827854,0.1733483 0.1402786,0.043011 0.3016974,0.00415 0.4139478,-0.099662 0.077316,-0.071502 0.10801305,-0.1381698 0.13271995,-0.2876057 0.023026,-0.1392694 0.050103,-0.2473088 0.091013,-0.3633335 0.033205,-0.094172 0.12257375,-0.2941767 0.13449285,-0.3010255 0.00453,-0.0026 0.063069,0.00193 0.1301027,0.010123 0.3463077,0.042293 0.7112151,0.038539 1.0227542,-0.010515 0.2884528,-0.045419 0.5168312,-0.1136175 0.757483,-0.2263184 0.041506,-0.019438 0.082049,-0.040029 0.1217444,-0.061602 L 4.0959133,4.0712175 4.3800961,3.8069965 3.430034,2.9239316 2.1951137,1.7760961 2.0418779,1.6336662 1.035587,0.6983378 Z M 3.7148077,2.6601814 C 4.0280563,2.317166 4.2508527,1.8716375 4.3769721,1.3230673 4.4472417,1.0174215 4.4881472,0.7004186 4.4977035,0.3872679 4.5046793,0.158659 4.5002192,0.1424239 4.419608,0.1052333 4.3805306,0.0871983 4.35071,0.0859319 3.9617579,0.0862426 3.4565781,0.0866283 3.1514981,0.1055663 2.7351961,0.1623622 2.2115379,0.2338039 1.7665115,0.3514073 1.4003981,0.5089807 Z"
                style="stroke-width:0.0416749;stroke-miterlimit:4;stroke-dasharray:none"
                id="path1627" />
            <!-- <path
          id="path958"
          d="M 13.244047,10.416667 86.458333,91.071425"
          style="fill:none;stroke:#cc0e50;stroke-width:20px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" /> -->
        </svg>
    </span>
    }
</span>
}

<!-- other version of strikethrough icon all in red -->
<!-- <span *ngIf="isOrganic && strikethrough" class="warn-icon">
<svg height='12px' width='12px' x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
  <path
    transform="scale(8.3333333)"
    d="M 10.390625 0.31835938 C 10.290125 0.29435937 10.175516 0.32392188 10.103516 0.41992188 C 8.8675156 2.0039219 6.6964219 2.2201406 6.7324219 3.7441406 C 6.7444219 4.0681406 6.80325 4.2717188 7.03125 4.5117188 C 7.94325 3.3477188 9.0845312 2.9634531 9.1445312 2.9394531 C 9.1565312 2.9394531 9.1679688 2.9277344 9.1679688 2.9277344 C 9.0239687 3.0237344 8.5070781 3.468625 7.9550781 4.140625 C 7.6764071 4.4342248 7.4092199 4.7746069 7.1523438 5.1582031 L 7.5917969 5.6425781 C 7.6985277 5.4751999 7.8080982 5.3181059 7.9199219 5.171875 C 8.6159219 5.435875 9.5035312 5.4244687 10.019531 4.9804688 C 10.907531 4.2124688 10.823703 1.8830625 10.595703 0.5390625 C 10.577703 0.4190625 10.491125 0.34235938 10.390625 0.31835938 z M 2.9726562 1.6835938 C 2.8856562 1.7030937 2.8072031 1.7690938 2.7832031 1.8710938 C 2.5552031 3.0110937 2.41225 5.0035 3.15625 5.6875 C 3.55225 6.0475 4.2111094 6.0951719 4.7871094 5.9511719 C 5.1231094 6.4191719 5.424625 6.9842656 5.640625 7.5722656 L 6.6953125 7.65625 C 6.853482 7.1231603 7.038965 6.6667579 7.2402344 6.265625 L 6.7890625 5.7695312 C 6.5682866 6.1800382 6.3586933 6.6295841 6.1796875 7.1757812 C 5.9756875 6.6717813 5.7235469 6.1914844 5.4355469 5.7714844 C 4.8595469 4.7634844 4.1032656 4.0792656 3.9472656 3.9472656 C 3.9592656 3.9472656 3.9606562 3.9489375 3.9726562 3.9609375 C 4.0206563 3.9849375 4.9803281 4.3432813 5.7363281 5.3632812 C 5.8935575 5.2060519 5.9503672 5.057341 5.9804688 4.8769531 L 3.0976562 1.6914062 C 3.057411 1.6788857 3.0136063 1.6744153 2.9726562 1.6835938 z "
    style="stroke-width:0.12"
    id="path946" />
  <path
    transform="scale(8.3333333)"
    d="M 5.3417969 7.9433594 C 5.0822969 7.9358594 4.8425312 7.9370781 4.6445312 7.9550781 C 4.2005313 8.0030781 3.288375 8.31575 2.484375 8.84375 C 2.088375 9.10775 1.6448594 9.4076094 1.3808594 9.5996094 C 1.2488594 9.6956094 1.2123125 9.8875313 1.3203125 10.019531 L 2.4472656 11.544922 C 2.5432656 11.676922 2.7234688 11.699516 2.8554688 11.603516 L 3.2871094 11.279297 C 3.3951094 11.207297 3.5284375 11.184703 3.6484375 11.220703 C 4.4284375 11.484703 5.256875 11.663219 5.796875 11.699219 C 6.984875 11.783219 9.2889375 10.416656 9.9609375 9.9726562 C 10.117814 9.8651667 10.206878 9.727978 10.246094 9.5878906 L 9.5703125 8.8417969 C 9.5205754 8.8490934 9.4728591 8.8480954 9.4199219 8.8671875 C 9.1679219 8.9511875 8.7489688 9.06025 8.2929688 9.15625 C 8.1249687 9.42025 7.8231875 9.6350313 7.3671875 9.7070312 C 7.2591875 9.7310313 7.1398125 9.7324219 7.0078125 9.7324219 C 6.6838125 9.7324219 6.3125781 9.6964375 5.8925781 9.6484375 C 5.8685781 9.6484375 5.8325938 9.6487188 5.8085938 9.6367188 C 5.7125937 9.6247188 5.6152344 9.5513594 5.6152344 9.4433594 C 5.6032344 9.3113594 5.71175 9.2165156 5.84375 9.2285156 C 5.87975 9.2285156 5.9034531 9.2402344 5.9394531 9.2402344 C 6.4794531 9.3002344 6.9845938 9.3487813 7.3085938 9.3007812 C 7.9085937 9.2047813 8.0767344 8.84325 8.0527344 8.53125 C 8.0287344 8.27925 7.8488281 8.0407344 7.5488281 8.0527344 C 7.1288281 8.0767344 6.63625 8.0281875 6.15625 7.9921875 C 5.88025 7.9681875 5.6012969 7.9508594 5.3417969 7.9433594 z "
    style="stroke-width:0.12"
    id="path948" />
  <path
    inkscape:connector-curvature="0"
    style="fill:none;stroke-width:16.6667;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    d="M 12.823065,11.04814 86.037351,91.702893"
    id="path958" />
</svg>
</span> -->