<div class="slider-pos" [ngStyle]="{'margin-top': margin_top + 'px'}">
    @if (showAllowNull) {
    <span class="allowNull-container">
        <span class="allowNull-label">{{emptyIsZero ? '0' : EMPTYTAG}}</span>
        <mat-checkbox
            [ngModel]="allowNull"
            (change)="allowNullClicked()"
            [disabled]="disabled"
            [indeterminate]="dontAllowNull"
            [ngClass]="{'tertiary-element': dontAllowNull, 'primary-element': !dontAllowNull}">
        </mat-checkbox>
    </span>
    } @else {
    <span style="display: inline-block; min-width: 26px;"></span>
    }
    @if (!onlyOneOption && show) {
    <ngx-slider appClickValues #slider
        (userChange)="doFilter($event)"
        (valueChange)="doFilter({ value: $event, pointerType: 0 })"
        (highValueChange)="doFilter({ highValue: $event, pointerType: 1, value: undefined })"
        [value]="rangemin"
        [highValue]="rangemax"
        [options]="rangeOptions"
        class="ngxslider" [ngClass]="{'inverted-slider': isNot}">
        <!-- [manualRefresh]="manualRefresh"> -->
    </ngx-slider>
    } @else if (showSwitch) {
    <button [disabled]="disabled"
        (click)="isNot = !isNot; doFilter(undefined); updateShowSwitch()"
        mat-icon-button type="button" class="primary-element">
        @if (isNot) {
        <mat-icon class="switch-icon">compare_arrows</mat-icon>
        } @else {
        <mat-icon class="switch-icon">swap_horiz</mat-icon>
        }
    </button>
    }
    @if (onlyOneOption) {
    <span style="margin-top: 5px; margin-left: 10px; display: inline-block;"><span i18n="filters$$indicates that there is only one possibility@@Only one value found">Only one value found</span>: {{floor | number:'1.0-0'}}</span>
    }
</div>