import { Component, OnInit, AfterViewInit, ViewChild, AfterContentInit, OnDestroy } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/util/alert/alert.service';
import { NGXLogger } from 'ngx-logger';
import { LoginChangedService } from 'src/app/modules/frame/services/loginchanged.service';
import { UserService, Role } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Utils } from 'src/app/util/utils';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/User';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loginChangedService: LoginChangedService,
        private alertService: AlertService,
        private logger: NGXLogger,
        private userService: UserService,
        private utils: Utils,
        private tr: TranslatorService,
    ) { }

    user: User & { password?: string } = new User();
    loading = false;
    returnUrl: string;
    returnParam: unknown;
    returnQuery: unknown;

    doblur = false;
    showResendVerificationEmail = false;
    isDarkmode = false;

    private ngUnsubscribe = new Subject();

    @ViewChild('firstinput', { static: true }) firstInput: MatInput;


    ngOnInit(): void {
        // reset login status
        this.userService.logout();

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        // get return url from route parameters or default to '/stores'
        this.returnUrl = '/stores';
        if (this.route.snapshot.queryParams['returnUrl']) {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
            if (this.returnUrl.indexOf('?') !== -1) {
                // e.g. "/add;d=5?data=ChBF"
                const spl = this.returnUrl.split('?');
                this.logger.debug('1st split', spl);
                this.returnUrl = spl[0];
                this.returnQuery = {};
                const spl2 = spl[1].split('=');
                this.logger.debug('2st split', spl2);
                this.returnQuery[spl2[0]] = decodeURIComponent(spl2[1]);
            }
            if (this.returnUrl.indexOf(';') !== -1) {
                // e.g. "/...;id=5acc9b9421a1231adc9ac349"
                // e.g. "/add;source=genuineorigin;id=GEN21BRD"
                const spl = this.returnUrl.split(';');
                this.returnUrl = spl[0];
                this.returnParam = {};
                let spl2 = spl[1].split('=');
                this.returnParam[spl2[0]] = decodeURIComponent(spl2[1]);
                // TODO generalize
                if (spl[2]) {
                    spl2 = spl[2].split('=');
                    this.returnParam[spl2[0]] = decodeURIComponent(spl2[1]);
                }
                if (spl[3]) {
                    spl2 = spl[3].split('=');
                    this.returnParam[spl2[0]] = decodeURIComponent(spl2[1]);
                }
            }
        }
        this.logger.debug('init in LoginComponent ' + this.route.snapshot.queryParams['returnUrl']);

        const userId = this.route.snapshot.params.id;
        if (userId) {
            // this is called from the link in the verification email for the given user
            const token = this.route.snapshot.params.token;
            this.userService.setEmailVerified(userId, token)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.logger.debug('verified email');
                            this.alertService.success(this.tr.anslate('Email verified! You can now login.'));
                            this.router.navigate(['/login']);

                        } else {
                            this.utils.handleError('Email verify failed', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Email verify failed', error);
                    }
                });
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.doblur = true;
        }, 100);
    }

    ngAfterContentInit(): void {
        if (this.firstInput) {
            this.firstInput.focus();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    login(): void {
        this.logger.debug('login() called in login.component');

        this.loading = true;
        this.userService.login(this.user.email, this.user.password)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: resp => {
                    this.logger.trace('received login', resp);
                    // login successful if there's a jwt token in the response
                    // {"success": true, "result": {user: {user_id: string,nickname: string,
                    //      account: string,admin: boolean,token: string}}, "error": ""}
                    if (resp?.success === true && resp.result?.user?.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        let role: Role = 0;
                        if (resp.result.user.admin === true) {
                            role += Role.Admin;
                        }
                        delete resp.result.user.admin;
                        this.userService.setRole(role);

                        if (!resp.result.user.nickname) {
                            resp.result.user.nickname = '';
                        }

                        // readonly mode if subscription expired or over limit or user is readonly_user anyway (read-only invitation to another account)
                        resp.result.user.readonly ||= (typeof resp.result.user.readonly_account_idx !== 'undefined') || this.utils.paidDaysLeft(resp.result.user.account.paidUntil) < 0;

                        this.userService.storeCurrentUser(resp.result.user);

                        this.loginChangedService.changeLoginStatus(true);
                        this.logger.debug('login successful for', this.user.email,
                            'forwarding to', this.returnUrl, 'and param', this.returnParam, 'with query', this.returnQuery);
                        this.router.navigate([this.returnUrl, this.returnParam || {}], { queryParams: this.returnQuery });
                        if (resp.result.user.account.notifications) {
                            // fetch and show notification
                            this.utils.getNotifications();
                        } else {
                            this.utils.removeActiveNotificationSnackBar();
                        }

                    } else {
                        this.utils.handleError('server error', resp && resp.error ? resp.error : '');
                        this.loading = false;
                    }
                    this.showResendVerificationEmail = false;
                },
                error: error => {
                    if (error.status === 401 && error.error && error.error.error &&
                        error.error.error.indexOf('Email not verified') >= 0) {
                        this.showResendVerificationEmail = true;
                    } else {
                        this.showResendVerificationEmail = false;
                    }
                    this.utils.handleError('', error);
                    this.loading = false;
                }
            });
    }

    resendVerificationEmail(): void {
        this.userService.sendVerificationEmail({ email: this.user.email })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.alertService.success(this.tr.anslate('Please click on the link in the email we sent to {{email}}', { email: response.result }), undefined, false, false);
                        this.logger.debug('successfully re-sent verification email', response.result);
                        this.loading = false;
                        this.router.navigate(['/login']);
                    } else {
                        this.utils.handleError('could not send email', response.error);
                        this.loading = false;
                    }
                },
                error: error => {
                    this.utils.handleError('could not send email', error);
                    this.loading = false;
                }
            });
    }

}
