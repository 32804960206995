import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[appExistingEmail]',
     
    providers: [{ provide: NG_VALIDATORS, useExisting: ExistingEmailDirective, multi: true }]
})
export class ExistingEmailDirective implements Validator {

    @Input('appExistingEmail') existingEmails: string[];

    validate(control: AbstractControl): Record<string, unknown> | null {
        return this.existingEmails.indexOf(control.value) >= 0 ? { existingEmail: { value: control.value } } : null;
    }
}
