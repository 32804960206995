import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { BeansReport } from 'src/app/models/BeansReport';
import { UserType, UserService } from 'src/app/modules/frame/services/user.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { ReportService } from 'src/app/modules/report/report.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { Enumerations } from 'src/app/models/Enumerations';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StockChange } from 'src/app/models/StockChange';
import { EditbeansreportComponent } from './editbeansreport.component';
import { ClipboardService } from 'ngx-clipboard';
import { CurrencyPipe } from '@angular/common';
import { TransService } from 'src/app/modules/transaction/trans.service';
import { ExportToCsv } from 'src/app/util/export-to-csv';
import { CantDeleteDialogComponent } from '../../ui/dialog/cant-delete-dialog.component';
import { StandardService } from 'src/app/util/services/standard.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-beansreport',
    templateUrl: './beansreport.component.html',
    styleUrls: ['./beansreport.component.scss']
})
export class BeansreportComponent implements OnInit, OnDestroy {

    constructor(
        public utils: Utils,
        public reportService: ReportService,
        public tr: TranslatorService,
        private alertService: AlertService,
        private standardService: StandardService,
        private dialog: MatDialog,
        private userService: UserService,
        private route: ActivatedRoute,
        private clipboardService: ClipboardService,
        private transService: TransService,
        private currencyPipe: CurrencyPipe,
        private router: Router,
    ) { }

    @Input() currentUser: UserType;
    @Input() readOnly = false;

    nrTransactions: Map<string, number>;
    uNickname: string;
    private _report: BeansReport;
    get report(): BeansReport { return this._report; }
    @Input() set report(rep: BeansReport) {
        this._report = rep;
        this.update();
    }

    @Output() reportDeleted = new EventEmitter<BeansReport>();
    // @Output() reportChanged = new EventEmitter<BeansReport>();
    anyDeleted = false;

    editMode = false;

    dataSource: MatTableDataSource<StockChange>;
    // some will be added by reportService.getBeansReportColumns depending on which transactions are displayed
    columnsToDisplay: string[];

    FIELDSEPARATOR = '\t';
    LINESEPARATOR = '\r\n';
    DECIMALSEPARATOR = '.';
    @Input() set exportSettings(es: { fieldSep: string, decSep: string, lineSep: string }) {
        this.FIELDSEPARATOR = es.fieldSep;
        this.LINESEPARATOR = es.lineSep;
        this.DECIMALSEPARATOR = es.decSep;
    }

    exportFormat: string;
    creatingPdf = false;
    creatingCsv = false;
    creatingSheet = false;
    csvOptions = {
        fieldSeparator: ';',
        filename: 'Purchase_Sale Report',
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Purchase/Sale Report',
        useTextFile: false,
        useBom: false,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        // need to use the PR from https://github.com/cyrilselasi/export-to-csv
        useBlanksForUndefined: true,
    };
    justCopied = false;
    copyFailed = false;
    checkingIfDeletable: number = undefined;

    totals: { showTotals: boolean, numberOf: number, sum: number, sumPrice: number };

    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';

    @ViewChild(EditbeansreportComponent) editComponent: EditbeansreportComponent;
    @ViewChild('hiddenElems') hiddenElems: ElementRef;

    Number = Number;
    DateTime = DateTime;

    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser?.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }
        if (this.currentUser.export) {
            this.csvOptions.fieldSeparator = this.currentUser.export.sep;
            // this.csvOptions.lineSeparator = this.currentUser.export.linesep;
            this.csvOptions.decimalSeparator = this.currentUser.export.decsep;
        }

        if (this.report) {
            this.dataSource = new MatTableDataSource(this.report.transactions);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    update(): void {
        this.nrTransactions = new Map<string, number>();
        if (this.report) {
            // remove @... if inside a nickname
            if (this.report.updated_by?.nickname) {
                if (this.report.updated_by.nickname.indexOf('@') > 0) {
                    this.uNickname = this.report.updated_by.nickname.split('@')[0];
                } else {
                    this.uNickname = this.report.updated_by.nickname;
                }
            }
            const transTypes = new Set<string>();
            this.anyDeleted = false;
            if (!this.report?.transactions?.length) {
                this.report.certInfo = 0;
            } else {
                for (const trans of this.report.transactions) {
                    this.nrTransactions.set(trans.__t, (this.nrTransactions.get(trans.__t) || 0) + 1);
                    transTypes.add(trans.__t);
                    if (trans.deleted === true) {
                        this.anyDeleted = true;
                    }
                }
                // // calculate actual certInfo of the whole report
                // const first = this.calcCertsInfo(this.report.transactions[0]?.coffee?.certifications);
                // this.report.certInfo = this.report.transactions.map(t => t.coffee?.certifications).reduce((prev, cur) => !prev ? 0 : prev & this.calcCertsInfo(cur), first);
                // calculate actual certInfo of the whole report

                // set certInfo to 1 if all contained beans are organic, 0 otherwise
                this.report.certInfo = this.report.transactions.reduce((prev, cur) => !prev ? false : prev && this.utils.isOrganicCoffee(cur.coffee), true) ? 1 : 0;
            }
            this.columnsToDisplay = this.reportService.getBeansReportColumns(undefined, transTypes);
        }
    }

    // calcCertsInfo(certs: Certification[]): number {
    //     if (!certs.length) {
    //         return 0;
    //     }
    //     let certInfo = this.getCertInfo(certs[0]);
    //     for (let c = 1; c < certs.length; c++) {
    //         const cert = certs[c];
    //         const ci = this.getCertInfo(cert);
    //         certInfo &= ci;
    //     }
    //     return certInfo;
    // }

    // getCertInfo(cert: Certification): number {
    //     let certInfo = 0;
    //     if (cert.organic) { certInfo += Enumerations.CertificationTypes.ORGANIC; }
    //     if (cert.fair) { certInfo += Enumerations.CertificationTypes.FAIR; }
    //     if (cert.sustainable) { certInfo += Enumerations.CertificationTypes.SUSTAINABLE; }
    //     if (cert.bird_friendly) { certInfo += Enumerations.CertificationTypes.BIRD_FRIENDLY; }
    //     if (cert.shade_grown) { certInfo += Enumerations.CertificationTypes.SHADE_GROWN; }
    //     if (cert.high_quality) { certInfo += Enumerations.CertificationTypes.HIGH_QUALITY; }
    //     if (cert.social) { certInfo += Enumerations.CertificationTypes.SOCIAL; }
    //     return certInfo;
    // }

    deleteReport(): void {
        if (this.readOnly) { return; }

        const dialogRef = this.dialog.open(YesNoDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: this.report.label }) }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.reportService.deleteBeansReport(this.report._id)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully removed'));
                                this.reportDeleted.emit(this.report);

                            } else {
                                this.utils.handleError('error deleting report', response.error);
                            }
                        },
                        error: error => {
                            this.utils.handleError('error deleting report', error);
                        }
                    });
            }
        });
    }

    /**
     * Allows to edit a report.
     */
    editReport(): void {
        if (this.readOnly) {
            return;
        }
        this.editMode = true;
    }

    cancelEdit(): void {
        this.editMode = false;
    }

    saveEditedReport(): void {
        if (this.readOnly) { return; }

        if (this.editComponent) {
            this.editComponent.saveReport();
        } else {
            this.alertService.error(this.tr.anslate('Could not save report. Please try again.'));
        }
    }

    onEditModeChanged(mode: boolean): void {
        this.editMode = mode;
    }

    onReportChanged(changedReport: BeansReport): void {
        this.utils.updateObject(this.report, changedReport);
        if (!changedReport.ssf) {
            this.report.ssf = undefined;
        }
        if (!changedReport.missingTransactions?.length) {
            this.report.missingTransactions = undefined;
        }
        this.dataSource = new MatTableDataSource(this.report.transactions);
        this.update();
        // this.reportChanged.emit(this.report);
    }

    totalsChanged(newTotals: { showTotals: boolean, numberOf: number, sum: number, sumPrice: number }): void {
        this.totals = newTotals;
    }

    export(format: 'csv' | 'sheet' | 'clipboardCSV'): void {
        switch (format) {
            case 'csv':
                this.generateCSV();
                break;
            case 'sheet':
                this.generateSheet();
                break;
            case 'clipboardCSV':
                this.copy();
                break;
            default:
                break;
        }
        setTimeout(() => this.exportFormat = null, 1000);
    }

    generateDataArray(forPDF = false, mustNotContainChar?: string): unknown[] {
        const numberStr = this.tr.anslate('Number');
        const typeStr = this.tr.anslate('Type');
        const dateStr = this.tr.anslate('Date');
        const idStr = this.tr.anslate('ID');
        const beansIdStr = this.tr.anslate('Beans') + ' ' + this.tr.anslate('ID');
        const beansStr = this.tr.anslate('Beans');
        const amountStr = this.tr.anslate('Amount') + ' (' + this.mainUnit + ')';
        const costStr = this.tr.anslate('Cost') + ' (' + this.currency + ')';
        const customerStr = this.tr.anslate('Customer');
        const supplierStr = this.tr.anslate('Supplier');
        const storeIdStr = this.tr.anslate('Store') + ' ' + this.tr.anslate('ID');
        const storeStr = this.tr.anslate('Store');
        const targetStoreIdStr = this.tr.anslate('Target store') + ' ' + this.tr.anslate('ID');
        const targetStoreStr = this.tr.anslate('Target store');
        const reasonStr = this.tr.anslate('Reason');
        const refStr = this.tr.anslate('References');

        const data = [];
        this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);

        for (let r = 0; r < this.dataSource.data.length; r++) {
            const row = this.dataSource.data[r];
            const newdata = {};
            // Number and Type
            newdata[numberStr] = (this.dataSource.data.length - r).toString().padStart(3, '0');
            newdata[typeStr] = row['name'];
            if (row.deleted) {
                newdata[typeStr] += ` (${this.tr.anslate('deleted')})`;
            }

            // Transaction-ID
            if (row['order_number']) {
                newdata[idStr] = row['order_number'];
            } else if (row['sales_number']) {
                newdata[idStr] = row['sales_number'];
            // } else {
            //     newdata[idStr] = '';
            }
            // Date
            newdata[dateStr] = row.date.toLocaleString(DateTime.DATE_SHORT);
            // Coffee Id and Coffee
            if (!forPDF) {
                newdata[beansIdStr] = row.coffee ? row.coffee.hr_id : '';
            }
            newdata[beansStr] = this.reportService.createCoffeeLabel(row.coffee, true, forPDF ? '\n' : (this.FIELDSEPARATOR === ',' ? ' ' : ','), forPDF, mustNotContainChar);
            // Amount
            let num = (Math.round(this.utils.convertToUserUnit(row.__t === 'Sale' ? -row.amount : row.amount, this.currentUser?.unit_system) * 1000) / 1000).toFixed(3);
            if (this.DECIMALSEPARATOR !== '.') {
                num = num.replace('.', this.DECIMALSEPARATOR);
            }
            newdata[amountStr] = forPDF ? { text: num, alignment: 'right' } : num;
            // Cost
            if (row.price) {
                num = (Math.round((row.__t === 'Sale' ? -row.price : row.price) * 100) / 100).toFixed(2);
                if (this.DECIMALSEPARATOR !== '.') {
                    num = num.replace('.', this.DECIMALSEPARATOR);
                }
                newdata[costStr] = forPDF ? { text: num, alignment: 'right' } : num;
            } else {
                newdata[costStr] = '';
            }
            // Customer
            if (this.columnsToDisplay.indexOf('Customer') >= 0) {
                newdata[customerStr] = row['customer']?.label || '';
            }
            // Supplier
            if (this.columnsToDisplay.indexOf('Supplier') >= 0) {
                newdata[supplierStr] = row['supplier']?.label || '';
            }
            // Store ID and Store
            if (!forPDF) {
                newdata[storeIdStr] = row.location?.hr_id || '';
                newdata[storeStr] = row.location?.label || '-';
            } else {
                newdata[storeStr] = (row.location ? row.location.hr_id + ' ' : '') + (row.location && row.location.label ? row.location.label : '-');
            }
            // Target Store ID and target store
            if (this.columnsToDisplay.indexOf('Transfer') >= 0) {
                if (!forPDF) {
                    newdata[targetStoreIdStr] = row['target']?.hr_id || '';
                    newdata[targetStoreStr] = row['target']?.label || '';
                } else {
                    newdata[targetStoreStr] = (row['target']?.hr_id ? row['target'].hr_id + ' ' : '') + (row['target']?.label || '');
                }
            }
            // Reason
            if (this.columnsToDisplay.indexOf('Correction') >= 0) {
                newdata[reasonStr] = row['reason'] || '';
            }
            // References
            newdata[refStr] = row.reportNote || '';

            if (mustNotContainChar) {
                if (newdata[customerStr]) newdata[customerStr] = newdata[customerStr]?.replace(mustNotContainChar, ' ');
                if (newdata[supplierStr]) newdata[supplierStr] = newdata[supplierStr]?.replace(mustNotContainChar, ' ');
                if (newdata[storeStr]) newdata[storeStr] = newdata[storeStr].replace(mustNotContainChar, ' ');
                if (newdata[targetStoreStr]) newdata[targetStoreStr] = newdata[targetStoreStr]?.replace(mustNotContainChar, ' ');
                if (newdata[reasonStr]) newdata[reasonStr] = newdata[reasonStr]?.replace(mustNotContainChar, ' ');
            }

            data.push(newdata);
        }
        // sum footer
        if (this.totals?.showTotals) {
            const tamount = this.utils.fixDigits(this.utils.formatNumber(this.totals.sum, 3, this.currentUser?.unit_system), this.mainUnit, 3);
            const tcost = this.currencyPipe.transform(this.totals.sumPrice, this.currency, 'symbol-narrow', '1.0-0');
            const row = {
                [numberStr]: this.tr.anslate('Total') + ': ' + this.totals.numberOf,
                [amountStr]: forPDF ? { text: tamount, alignment: 'right' } : tamount,
                [costStr]: forPDF ? { text: tcost, alignment: 'right' } : tcost,

                // [typeStr]: '', [dateStr]: '', [idStr]: '', [beansIdStr]: '', [beansStr]: '',
                // [targetStoreIdStr]: '', [storeIdStr]: '', [storeStr]: '', [refStr]: '',
            };
            if (forPDF) {
                delete row[beansIdStr];
                delete row[storeIdStr];
                delete row[targetStoreIdStr];
            }
            if (this.columnsToDisplay.indexOf('Customer') >= 0) {
                row[customerStr] = '';
            }
            if (this.columnsToDisplay.indexOf('Supplier') >= 0) {
                row[supplierStr] = '';
            }
            if (this.columnsToDisplay.indexOf('Transfer') >= 0) {
                row[targetStoreIdStr] = '';
                row[targetStoreStr] = '';
            }
            if (this.columnsToDisplay.indexOf('Correction') >= 0) {
                row[reasonStr] = '';
            }
            data.push(row);
        }
        return data;
    }

    copy(): void {
        if (!this.dataSource?.data) {
            return;
        }

        try {
            const data = this.generateDataArray(false, this.FIELDSEPARATOR);

            if (data.length > 0) {
                this.csvOptions.showTitle = false;
                const csvExporter = new ExportToCsv(this.csvOptions);
                const text = csvExporter.generateCsv(data, true);
                if (this.clipboardService.copyFromContent(text)) {
                    this.justCopied = true;
                    setTimeout(() => {
                        this.justCopied = false;
                    }, 2000);
                } else {
                    this.copyFailed = true;
                    setTimeout(() => {
                        this.copyFailed = false;
                    }, 2000);
                }
            } else {
                this.alertService.success(this.tr.anslate('nothing to show'));
            }
        } catch (error) {
            this.utils.handleError('error creating CSV', error);
            this.creatingCsv = false;
        }
    }

    generateCSV(): void {
        if (this.creatingCsv) { return; }

        this.creatingCsv = true;
        // the downloading is async and we can't get a handle to when it finished; just guess here
        setTimeout(() => {
            this.creatingCsv = false;
        }, 3000);

        try {
            const data = this.generateDataArray(false, this.FIELDSEPARATOR);

            // not needed after rework to always include all fields in generateDataArray:
            // // collect all headers
            // const hds = new Set<string>();
            // for (let d = 0; d < data.length; d++) {
            //     Object.getOwnPropertyNames(data[d]).forEach((val) => hds.add(val));
            // }
            // // need to be set on data[0] for the lib to work
            // const headers = Array.from(hds.values());
            // headers.forEach((hd) => {
            //     if (!data[0][hd]) data[0][hd] = '';
            // });

            if (data.length > 0) {
                this.csvOptions.title = this.tr.anslate('Purchases') + '/' + this.tr.anslate('Sales') + ' ' + this.report.label;
                this.csvOptions.filename = this.report.label + '_' + this.tr.anslate('Purchases') + '_' + this.tr.anslate('Sales');
                const csvExporter = new ExportToCsv(this.csvOptions);
                csvExporter.generateCsv(data);
                this.alertService.success(this.tr.anslate('Successfully added'));
            } else {
                this.alertService.success(this.tr.anslate('nothing to show'));
            }
        } catch (error) {
            this.utils.handleError('error creating CSV', error);
            this.creatingCsv = false;
        }
    }

    generateSheet(): void {
        if (this.creatingSheet) { return; }

        this.creatingSheet = true;
        const data = this.generateDataArray();

        this.reportService.getSheetFromArray(data)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: blob => {
                    this.creatingSheet = false;

                    const filename = this.report.label + '_' + this.tr.anslate('Purchases') + '_' + this.tr.anslate('Sales');
                    this.utils.saveBlobToFileSystem(blob, filename + '.xlsx');

                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingSheet = false;
                    this.utils.handleError('error creating Sheet', error);
                }
            });
    }

    generatePDF(): void {
        if (this.creatingPdf) { return; }

        this.creatingPdf = true;
        const data = this.generateDataArray(true);

        const title = this.report.label;
        this.reportService.getPDFFromArray(data, title)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: blob => {
                    this.creatingPdf = false;

                    const filename = this.report.label + '_' + this.tr.anslate('Purchases') + '_' + this.tr.anslate('Sales');
                    this.utils.saveBlobToFileSystem(blob, filename + '.pdf');

                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingPdf = false;
                    this.utils.handleError('error creating PDF', error);
                }
            });
    }

    editTransaction(transIdx: number): void {
        if (!this.report || !this.report.missingTransactions || !this.report.missingTransactions[transIdx]) {
            return;
        }
        const transaction = this.report.missingTransactions[transIdx];
        if (!transaction.type) {
            transaction.type = transaction.__t;
        }
        this.transService.editTransaction(transaction, this.ngUnsubscribe, trans => {
            this.report.missingTransactions[transIdx] = trans;
        });
    }

    deleteTransaction(transIdx: number): void {
        if (!this.report || !this.report.missingTransactions || !this.report.missingTransactions[transIdx]) {
            return;
        }
        const transaction = this.report.missingTransactions[transIdx];
        if (!transaction.type) {
            transaction.type = transaction.__t;
        }
        this.checkingIfDeletable = transIdx;
        this.standardService.getRefs('stockchanges', transaction._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.checkingIfDeletable = undefined;
                    if (response.success === true) {
                        if (response.result.count > 0) {
                            const ondate = this.tr.anslate('on {{date}}', { date: transaction.date.toLocaleString(DateTime.DATE_MED) });
                            const dialogRef = this.dialog.open(CantDeleteDialogComponent, {
                                closeOnNavigation: true,
                                data: {
                                    label: `${this.tr.anslate(transaction.type)}: ${(transaction.amount * this.utils.getUnitFactor(this.mainUnit)).toFixed(2)}${this.mainUnit} ${ondate}`,
                                    count: response.result.count,
                                    refs: this.utils.dateifyObjects(response.result.refs, ['date']),
                                    mainUnit: this.mainUnit,
                                    currency: this.currency
                                }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete(transaction, transIdx);
                                }
                            });

                        } else {
                            this.doDelete(transaction, transIdx);
                        }
                    } else {
                        this.utils.handleError('could not delete the data', response.error);
                    }
                },
                error: error => {
                    this.checkingIfDeletable = undefined;
                    this.utils.handleError('could not delete the data', error);
                }
            });
    }

    private doDelete(transaction: StockChange, transIdx): void {
        this.transService.deleteTransaction(transaction, this.ngUnsubscribe, () => {
            this.report.missingTransactions.splice(transIdx, 1);
        });
    }

    scrollToMissing(): void {
        this.hiddenElems?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
}
