import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NGXLogger } from 'ngx-logger';
import { map, distinctUntilChanged, throttleTime, debounce, switchMap, takeUntil } from 'rxjs/operators';
import { ReminderLog } from 'src/app/models/ReminderLog';
import { StandardService } from 'src/app/util/services/standard.service';
import { Utils } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';
import { UserType } from '../frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, Subject, race, timer, of } from 'rxjs';
import { YesNoDialogComponent } from '../ui/dialog/yesno-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/util/alert/alert.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { User } from 'src/app/models/User';
import { ReportService } from 'src/app/modules/report/report.service';
import { ExportToCsv } from 'src/app/util/export-to-csv';
import { ClipboardService } from 'ngx-clipboard';
import { DateTime } from 'luxon';

const ANIMATIONDIRATION = '0.6s';
const ANIMATIONDIRATIONINMS = 600;

interface FilterParams {
    filter?: string;
    filterStartDate?: DateTime;
    filterEndDate?: DateTime;
}

@Component({
    selector: 'app-reminderlogtable',
    templateUrl: './reminderlogtable.component.html',
    styleUrls: ['./reminderlogtable.component.scss'],
    animations: [
        trigger('rowsAnimation', [
            // cannot use :leave or * => void since this would animate all rows everytime the datasource is updated
            state('deleted',
                style({ opacity: 0 })),
            transition('* => deleted', [
                animate(ANIMATIONDIRATION)
            ]),
        ]),
    ],
})
export class ReminderlogtableComponent implements OnInit, OnDestroy {

    constructor(
        private logger: NGXLogger,
        private standardService: StandardService,
        private utils: Utils,
        private tr: TranslatorService,
        private dialog: MatDialog,
        private alertService: AlertService,
        private clipboardService: ClipboardService,
        private reportService: ReportService,
        private breakpointObserver: BreakpointObserver,
    ) {
        //
    }

    csvOptions = {
        fieldSeparator: ';',
        filename: 'Completed Tasks', // translated below
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Completed Tasks', // translated below
        useTextFile: false,
        useBom: false,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        // need to use the PR from https://github.com/cyrilselasi/export-to-csv
        useBlanksForUndefined: true,
    };

    filteredMachinesForEdit: { label: string, cnt: number }[] = [];
    private machines: { label: string, cnt: number }[] = [];
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('machines') set _machines(ms: { label: string, cnt: number }[]) {
        this.machines = ms;
        this.filteredMachines = this.machines?.slice() ?? [];
    }
    @Input() currentUser: UserType;
    @Input() readOnly: boolean;

    filteredMachines: { label: string, cnt: number }[] = [];
    datasource = new MatTableDataSource<ReminderLog>();
    deleted: string[] = [];
    allColumns = ['done_date', 'label', 'notes', 'machine', 'user', 'source'];
    columnsToDisplay = ['done_date', 'label', 'notes', 'machine', 'user', 'source'];
    allEditColumns = ['done_date', 'label', 'notes', 'machine'];

    editNewEntry = -1;
    filter = '';
    filterChanged = new Subject<FilterParams>();
    // used to cancel any debounced input
    cancelFilterChange = new Subject<void>();
    cancelledFilterChange = false;

    exportFormat: string;
    creating = false;
    justCopied = false;
    copyFailed = false;

    readonly initialFilterStartDate = DateTime.now().minus({ year: 1 }).startOf('day');
    // standard is too also look 2 weeks into the future
    readonly initialFilterEndDate = DateTime.now().plus({ weeks: 2 }).endOf('day');
    filterStartDate = this.initialFilterStartDate;
    filterEndDate = this.initialFilterEndDate;
    lastFilterParams: FilterParams = { filter: '', filterStartDate: this.filterStartDate };

    editMode = -1;
    isNew = -1;
    reminderLog: ReminderLog[] = [];

    // loadingTimer: ReturnType<typeof setTimeout>;
    loadingTimer;
    loadingLog = false;
    loadingLogInternal = false;
    // avoid showing spinner while the create-log-entry animation is running
    dontShowSpinner = false;

    isLarge$: Observable<boolean>;

    @ViewChild(MatSort, { static: false }) sort: MatSort;

    DateTime = DateTime;

    private ngUnsubscribe = new Subject();
    
    // placeholder for the xliffmerge script (keep equiv-text="{{cNickname}}, not equiv-text="{{''}})
    cNickname = '';


    ngOnInit(): void {
        this.csvOptions.filename = this.tr.anslate('Completed Tasks');
        this.csvOptions.title = this.csvOptions.filename;

        this.filter = '';
        this.filterChanged.pipe(
            // debounceTime(1250),
            debounce(() => race(timer(1250), this.cancelFilterChange)),
            distinctUntilChanged(),
            takeUntil(this.ngUnsubscribe),
            switchMap(val => {
                if (!this.cancelledFilterChange) {
                    this.logger.trace(`filterChanged ${JSON.stringify(val)}`);
                    return this.asyncFilterEntries(val, true)
                }
                this.cancelledFilterChange = false;
                return of();
            })).subscribe({
                next: response => {
                    this.handleNewData(response);
                },
                error: error => {
                    this.handleError(error);
                }
            });

        this.isLarge$ = this.breakpointObserver.observe('(min-width: 900px)')
            .pipe(map(result => result.matches));

        this.getReminderLog();

        if (!this.readOnly) {
            this.columnsToDisplay.push('actions');
        }
        if (this.currentUser?.export) {
            this.csvOptions.fieldSeparator = this.currentUser.export.sep;
            // this.csvOptions.lineSeparator = this.currentUser.export.linesep;
            this.csvOptions.decimalSeparator = this.currentUser.export.decsep;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.loadingTimer);
    }

    reload(): void {
        this.resetFilter();
        this.getReminderLog();
    }

    resetFilter(): void {
        this.filter = '';
        this.filterStartDate = this.initialFilterStartDate;
        this.filterEndDate = this.initialFilterEndDate;
    }

    // check for which columns there is at least one entry in the current data
    checkColumns(): void {
        const cols = [];
        for (const prop of this.allColumns) {
            if (prop in ['done_date', 'label']) {
                // must always be shown
                continue;
            }
            if (prop === 'user') {
                for (const entry of this.reminderLog) {
                    if (entry.done_user?._id.toString() !== this.currentUser.user_id.toString()) {
                        cols.push(prop);
                        break;
                    }
                }
            } else {
                for (const entry of this.reminderLog) {
                    if (entry[prop]) {
                        cols.push(prop);
                        break;
                    }
                }
            }
        }
        this.columnsToDisplay = cols;
        if (!this.readOnly) {
            this.columnsToDisplay.push('actions');
        }
    }

    handleNewData(response: { success: boolean, result: ReminderLog[], error: string; }): void {
        if (response?.success === true) {
            this.reminderLog = this.utils.dateifyReminderLogs(response.result);
            this.datasource.data = this.reminderLog;
            this.checkColumns();
        } else {
            this.utils.handleError('error updating the information', response?.error);
        }
        clearTimeout(this.loadingTimer);
        this.loadingLog = false;
        this.loadingLogInternal = false;
    }

    handleError(error: unknown): void {
        this.utils.handleError('error updating the information', error);
        clearTimeout(this.loadingTimer);
        this.loadingLog = false;
        this.loadingLogInternal = false;
    }

    /**
     * Retieves all reminder log entries for the given filter.
     * @param filterParams filter
     * @param waitTime ms to wait before handleNewData is called; most useful to wait for an animation to finish
     */
    getReminderLog(filterParams?: FilterParams, waitTime?: number): void {
        this.getReminderLogAsync(filterParams)
            .subscribe({
                next: response => {
                    if (waitTime) {
                        this.dontShowSpinner = true;
                        setTimeout(() => {
                            this.handleNewData(response);
                            this.dontShowSpinner = false;
                        }, waitTime);
                    } else {
                        this.handleNewData(response);
                    }
                },
                error: error => {
                    this.handleError(error);
                }
            });
    }

    private getReminderLogAsync(filterParams?: FilterParams) {
        this.loadingLogInternal = true;
        this.loadingTimer = setTimeout(() => {
            this.loadingLog = true;
        }, 300);
        return this.standardService.getAll<ReminderLog>('reminderlogs',
            { from: filterParams?.filterStartDate ?? this.initialFilterStartDate, to: filterParams?.filterEndDate ?? this.initialFilterEndDate })
            .pipe(throttleTime(environment.RELOADTHROTTLE));
    }

    addLog(): void {
        if (this.readOnly) { return; }

        if (!this.reminderLog) {
            this.reminderLog = [];
        }
        const label = this.tr.anslate('NEW');

        this.editMode = 0;
        this.isNew = 0;
        const reminder = new ReminderLog();
        reminder.label = label;
        reminder.done_date = DateTime.now();
        reminder.done_user = this.currentUser.user_id as unknown as User;
        this.reminderLog.unshift(reminder);
        this.datasource.data = this.reminderLog;
        this.editEntry(0);
    }

    editEntry(idx: number): void {
        if (this.readOnly || idx < 0 || idx >= this.reminderLog?.length) {
            return;
        }

        this.columnsToDisplay = this.allEditColumns.slice();
        // if (this.machines?.length || this.reminderLog?.[idx]?.machine) {
        //     this.columnsToDisplay.push('machine');
        //     if (this.reminderLog?.[idx]?.machine) {
        //         // make sure it is contained in the options
        //         if (this.machines?.map(entry => entry.label).indexOf(this.reminderLog[idx].machine) < 0) {
        //             this.machines.push({ label: this.reminderLog[idx].machine, cnt: 1 });
        //         }
        //     }
        // }
        if (!this.readOnly) {
            this.columnsToDisplay.push('actions');
        }
        this.editMode = idx;
    }

    cancelEdit(idx: number): void {
        if (this.isNew >= 0) {
            this.reminderLog.splice(idx, 1);
            this.datasource.data = this.reminderLog;
            this.isNew = -1;
        }
        this.editMode = -1;
        this.checkColumns();
    }

    saveEntry(idx: number): void {
        this.editMode = -1;

        // TODO implement isEquals to avoid saving with no changes

        let obs: Observable<{ success: boolean; result: ReminderLog; error: string }>;
        if (this.isNew === idx) {
            obs = this.standardService.add('reminderlogs', this.reminderLog[idx]);
        } else {
            obs = this.standardService.update('reminderlogs', this.reminderLog[idx]);
        }
        obs.pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response) {
                        this.alertService.success(this.tr.anslate('Nothing to change'));
                    } else if (response && response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.reminderLog.splice(idx, 1, this.utils.dateifyReminderLogs([response.result])?.[0] || this.reminderLog[idx]);
                        this.datasource.data = this.reminderLog;
                    } else {
                        this.utils.handleError('Error updating the information', response.error);
                    }
                    this.checkColumns();
                },
                error: error => {
                    this.utils.handleError('Error updating the information', error);
                    this.checkColumns();
                }
            });

        this.isNew = -1;
    }

    deleteEntry(idx: number): void {
        if (this.readOnly || idx < 0 || idx >= this.reminderLog?.length) {
            return;
        }

        const dialogRef = this.dialog.open(YesNoDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: `"${this.reminderLog[idx].label}"` }) },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.standardService.remove('reminderlogs', this.reminderLog[idx]._id)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                // this.alertService.success(this.tr.anslate('Successfully removed'));
                                this.deleted[idx] = 'deleted';
                                setTimeout(() => {
                                    this.deleted[idx] = undefined;
                                    this.reminderLog.splice(idx, 1);
                                    this.datasource.data = this.reminderLog;
                                }, ANIMATIONDIRATIONINMS);
                            } else {
                                this.utils.handleError('Error updating the information', response.error);
                            }
                        },
                        error: error => {
                            this.utils.handleError('Error updating the information', error);
                        }
                    });
            }
        });
    }

    sortData(): void {
        if (!this.datasource.sort) {
            this.datasource.sort = this.sort;
        }
    }

    // clears the date filter inputs
    cancelFilterDates(): void {
        this.filterStartDate = this.initialFilterStartDate;
        this.filterEndDate = this.initialFilterEndDate;
        // this.pageIndex = 0;
        this.filterEntries({ filter: this.filter, filterStartDate: this.filterStartDate });
    }

    asyncFilterEntries(val?: FilterParams, fromInputDebounce = false): Observable<{ success: boolean, result: ReminderLog[], error: string }> {
        const calledServer = this.filterEntries(val, fromInputDebounce, true);
        if (calledServer) {
            return this.getReminderLogAsync(val);
        }
        return of({ success: true, result: this.reminderLog, error: '' });
    }

    /**
     * Filters currently displayed objects and calls getReminderLog
     * (if the filter is different from the last filter).
     * @param filter the filter string, matches label, notes, machine
     * @returns true if a call to the server is made (async=true) or should be made (async=false)
     */
    filterEntries(val?: FilterParams, fromInputDebounce = false, async = false, fromBlur = false): boolean {
        this.logger.trace(`filterEntries ${JSON.stringify(val)}`);

        const filterParams = {
            filter: (val?.filter ?? this.filter)?.trim(),
            filterStartDate: (val?.filterStartDate ?? this.filterStartDate)?.startOf('day'),
            filterEndDate: (val?.filterEndDate ?? this.filterEndDate)?.endOf('day'),
        };

        // show all that are currently in the cache and call getReminderLog
        if (!fromInputDebounce && !fromBlur) {
            this.logger.trace('send stop debounce');
            // reset last value for distinct to work correctly
            this.filterChanged.next({ filter: '' });
            // stop any debounced filter input events
            // (delete all directly triggers filterEntries; we do not want a previously debounced request to come in after 1s)
            this.cancelledFilterChange = true;
            this.cancelFilterChange.next();
        }

        if (this.isDifferentFilter(filterParams, this.lastFilterParams)) {
            this.logger.trace(`doFilter ${JSON.stringify(filterParams)}`);

            this.lastFilterParams = Object.assign({}, filterParams);

            this.reminderLog = this.reminderLog.filter(entry =>
                (!filterParams.filter
                    || entry.machine?.toUpperCase().indexOf(filterParams.filter.toUpperCase()) >= 0
                    || entry.label?.toUpperCase().indexOf(filterParams.filter.toUpperCase()) >= 0
                    || entry.notes?.toUpperCase().indexOf(filterParams.filter.toUpperCase()) >= 0
                    || entry.done_user?.nickname?.toUpperCase().indexOf(filterParams.filter.toUpperCase()) >= 0)
                && (!filterParams.filterStartDate || entry.done_date >= filterParams.filterStartDate)
                && (!filterParams.filterEndDate || entry.done_date < filterParams.filterEndDate));
            this.datasource.data = this.reminderLog;

            if (!async) {
                this.getReminderLog(filterParams);
            }
            return true;
        }
        return false;
    }

    // called when user types into filter
    filterInput(): void {
        this.logger.trace(`filterInput ${this.filter}`);
        if (!this.filter) {
            this.filteredMachines = this.machines?.slice() || [];
            // setTimeout(() => {
            this.filterEntries();
            // }, 250);
            return;
        }
        if (!this.machines || !this.machines.length) {
            this.filteredMachines = [];
            this.filterChanged.next({ filter: this.filter });
            return;
        }
        const foundMachine = this.machines.find(machineCnt => machineCnt.label === this.filter);
        if (foundMachine) {
            // clicked on a predefined one (or entered it exactly)
            this.filteredMachines = [foundMachine];
            // setTimeout(() => {
            this.filterEntries();
            // }, 250);
            return;
        }

        const filterValue = this.filter.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        this.filteredMachines = this.machines.filter(machineCnt => machineCnt?.label?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0);
        this.filterChanged.next({ filter: this.filter });
    }

    // avoids asking the server twice with the same filter
    isDifferentFilter(filterParams: FilterParams, lastFilterParams: FilterParams): boolean {
        const isdiff = filterParams.filter !== lastFilterParams.filter
            || (typeof filterParams.filterStartDate === 'undefined' && typeof lastFilterParams.filterStartDate !== 'undefined')
            || (typeof filterParams.filterStartDate !== 'undefined' && +filterParams.filterStartDate !== +lastFilterParams.filterStartDate)
            || (typeof filterParams.filterEndDate === 'undefined' && typeof lastFilterParams.filterEndDate !== 'undefined')
            || (typeof filterParams.filterEndDate !== 'undefined' && filterParams.filterEndDate !== lastFilterParams.filterEndDate);
        this.logger.trace(`isdiff: ${isdiff}`);
        return isdiff;
    }

    changeMachineFilter(machine: string): void {
        if (!machine) {
            this.filteredMachinesForEdit = this.machines.slice();
            // this.machine = '';
            return;
        }
        if (this.machines) {
            const upId = machine.toUpperCase();
            this.filteredMachinesForEdit = this.machines.filter(m => m && m.label.toUpperCase().indexOf(upId) >= 0);
        }
        // this.id = machine.trim();
    }

    export(format: 'csv' | 'pdf' | 'clipboardCSV'): void {
        this.generateData(format);
        // this.exportFormat = null;
        setTimeout(() => this.exportFormat = null, 1500);
    }

    generateDataArray(data: ReminderLog[], csv = false): ReminderLog[] {
        const newData = [];
        const hasUserEntry = data.some(entry => entry.done_user?._id.toString() !== this.currentUser.user_id.toString())
        for (const entry of data) {
            const newEntry = {
                [this.tr.anslate('Date')]: entry.done_date.toLocaleString(csv ? DateTime.DATE_SHORT : DateTime.DATE_MED),
                [this.tr.anslate('Title')]: entry.label,
                [this.tr.anslate('Notes')]: entry.notes,
                [this.tr.anslate('Machine')]: entry.machine,
            }
            if (hasUserEntry) {
                newEntry[this.tr.anslate('User')] = entry.done_user?.nickname ?? '';
            }
            newData.push(newEntry);
        }
        return newData;
    }

    generateData(format: 'csv' | 'pdf' | 'clipboardCSV'): void {
        if (this.creating) { return; }

        if (format === 'pdf') {
            this.creating = true;
            const data = this.generateDataArray(this.reminderLog);

            const title = `${this.tr.anslate('Completed Tasks')} ${this.filterStartDate.toLocaleString(DateTime.DATE_MED)} - ${this.filterEndDate.toLocaleString(DateTime.DATE_MED)}`;
            this.reportService.getPDFFromArray(data, title)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: blob => {
                        this.creating = false;

                        const filename = title;
                        this.utils.saveBlobToFileSystem(blob, filename + '.pdf');

                        this.alertService.success(this.tr.anslate('Successfully added'));
                    },
                    error: error => {
                        this.creating = false;
                        this.utils.handleError('error creating PDF', error);
                    }
                });

        } else if (format === 'csv') {
            this.creating = true;
            const data = this.generateDataArray(this.reminderLog);
            this.csvOptions.title = this.tr.anslate('Completed Tasks');
            this.csvOptions.filename = `${this.tr.anslate('Completed Tasks')}_${this.filterStartDate.toFormat('yyyy-MM-dd')}-${this.filterEndDate.toFormat('yyyy-MM-dd')}`;
            const csvExporter = new ExportToCsv(this.csvOptions);
            csvExporter.generateCsv(data);
            this.alertService.success(this.tr.anslate('Successfully added'));
            this.creating = false;

        } else if (format === 'clipboardCSV') {
            this.creating = true;
            const data = this.generateDataArray(this.reminderLog, true);
            try {
                if (data.length > 0) {
                    this.csvOptions.showTitle = false;
                    const csvExporter = new ExportToCsv(this.csvOptions);
                    const text = csvExporter.generateCsv(data, true);
                    if (this.clipboardService.copyFromContent(text)) {
                        this.justCopied = true;
                        setTimeout(() => {
                            this.justCopied = false;
                        }, 1500);
                    } else {
                        this.copyFailed = true;
                        setTimeout(() => {
                            this.copyFailed = false;
                        }, 1500);
                    }
                } else {
                    this.alertService.success(this.tr.anslate('nothing to show'));
                }
                this.creating = false;
            } catch (error) {
                this.utils.handleError('error creating CSV', error);
                this.creating = false;
            }
        }
    }
}
