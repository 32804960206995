<ng-template #noReports>
    <p>
        <span i18n="reports$$beans report, placeholder@@Soon you will see an overview of your beans here; and more, stay tuned!">Soon you will see an overview of your beans here; and more, stay tuned!</span>
    </p>
</ng-template>

<ng-container>
    @if (!readOnly) {
    <div class="actionbar">
        <div class="actionbar-inner">
            <button [disabled]="newReportMode" type="button"
                class="add-button action-button" mat-raised-button (click)="add()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
    }

    @if (!newReportMode) {
    <div class="top-space">
        @if (!beansreports) {
        <div class="main-note">
            <span i18n="reports$$currently loading reports@@loading reports">Loading reports ...</span>
            <mat-spinner class="main-note" diameter="20"></mat-spinner>
        </div>
        }
        @if (beansreports?.length === 0) {
        <div class="main-note" i18n="reports$$no reports@@no reports">No reports available yet.</div>
        }
        @else if (beansreports?.length > 0) {
        <mat-accordion displayMode="default" multi="true">
            @for (beansreport of beansreports; track beansreport?.label) {
            <app-beansreport
                [currentUser]="currentUser"
                [report]="beansreport"
                (reportDeleted)="onReportDeleted($event)"
                [readOnly]="readOnly"
                [exportSettings]="{ fieldSep: FIELDSEPARATOR, lineSep: LINESEPARATOR, decSep: DECIMALSEPARATOR }">
            </app-beansreport>
            }
            <!-- (reportChanged)="onReportChanged($event)" -->
        </mat-accordion>
        }
    </div>
    }

    @if (!readOnly && newReportMode && beansreports) {
    <div class="top-space">
        <app-newbeansreport
            [currentUser]="currentUser"
            [readOnly]="readOnly"
            [lastEndDate]="beansreports[0] ? beansreports[0].endDate : undefined"
            [lastReportNumber]="beansreports.length > 0 ? beansreports[0].number : undefined"
            [reportNames]="reportNames"
            (newReportModeChanged)="onNewReportModeChanged($event)"
            (newReport)="onNewReport($event)">
        </app-newbeansreport>
    </div>
    }
</ng-container>