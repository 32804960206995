<ng-template #oneTemplate let-template="template">
    {{template.pre}}{{template.num}} {{template.label}}
</ng-template>
<ng-template #triggerOneCoffee let-coffee="coffee">
    <span>{{coffee.hr_id}}</span>
    <app-organicicon [isOrganic]="utils.isOrganicCoffee(coffee)" [size]="10" [leftMargin]="5" [rightMargin]="5"></app-organicicon>
    @if (coffee.origin) {
    <span> {{tr.anslate(coffee.origin)}}</span>
    }
    @if (coffee.yearLabel) {
    <span> {{coffee.yearLabel}}</span>
    }
    @if (coffee.label) {
    <span> {{coffee.label}}</span>
    }
</ng-template>
<ng-template #listOneCoffee let-coffee="coffee">
    <span>{{coffee.hr_id}}</span>
    <app-organicicon [isOrganic]="utils.isOrganicCoffee(coffee)" [size]="10" [leftMargin]="5" [rightMargin]="5"></app-organicicon>
    @if (coffee.origin) {
    <span> {{tr.anslate(coffee.origin)}}</span>
    }
    @if (coffee.yearLabel) {
    <span> {{coffee.yearLabel}}</span>
    }
    <br />
    <div [ngClass]="{'hidden-label': coffee.hidden}"> {{coffee.label}}</div>
</ng-template>

@if (allOptions?.length) {
<div>
    <mat-form-field class="filter-field" subscriptSizing="dynamic">
        <mat-label>{{i18nPlaceholder}}</mat-label>
        <mat-select [disabled]="disabled" multiple name="attr"
            [ngModel]="selectedOptions" [compareWith]="utils.compareObjectsFn"
            (selectionChange)="filterHasChanged($event)"
            (openedChange)="filterClosed(!$event)"
            data-test="slct-attr" panelClass="twoLineOption" panelWidth="null"
            [placeholder]="i18nPlaceholder">
            <mat-select-trigger>
                @if (selectedOptions?.length > 1) {
                <span>{{getObjectTrigger(selectedOptions)}}</span>
                } @else {
                @if (selectedOptions?.[0] != null && selectedOptions?.[0]?._id !== EMPTYTAG) {
                @switch (objectType) {
                @case ('coffees') {
                <ng-container *ngTemplateOutlet="triggerOneCoffee; context: {coffee: selectedOptions[0]}"></ng-container>
                }
                @case ('template') {
                <ng-container *ngTemplateOutlet="oneTemplate; context: {template: selectedOptions[0]}"></ng-container>
                }
                @case ('users') {
                {{selectedOptions[0]?.['nickname']}}
                }
                @default {
                {{selectedOptions[0].hr_id ?? selectedOptions[0].label ?? selectedOptions[0]}}
                }
                }
                } @else {
                <mat-icon class="smallish" style="vertical-align: middle;">unknown_med</mat-icon>
                }
                }
            </mat-select-trigger>
            @if (allOptions?.length >= 3) {
            <mat-option>
                <ngx-mat-select-search ngModel name="coffeeSelect"
                    (ngModelChange)="filteredOptions = utils.filterObjects($event.toString(), allOptions)"
                    [showToggleAllCheckbox]="true"
                    [toggleAllCheckboxChecked]="allChecked"
                    [toggleAllCheckboxIndeterminate]="indetChecked"
                    (toggleAll)="toggleAll($event)"
                    placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                    noEntriesFoundLabel="">
                </ngx-mat-select-search>
            </mat-option>
            }
            @for (obj of filteredOptions; track $index) {
            <mat-option [value]="obj == null ? { _id: EMPTYTAG } : obj">
                @if (obj?._id !== EMPTYTAG) {
                @switch (objectType) {
                @case ('template') {
                <ng-container *ngTemplateOutlet="oneTemplate; context: {template: obj}"></ng-container>
                }
                @case ('coffees') {
                <ng-container *ngTemplateOutlet="listOneCoffee; context: {coffee: obj}"></ng-container>
                }
                @case ('users') {
                {{obj['nickname']}}
                }
                @default {
                {{obj.hr_id ?? obj.label ?? obj}}
                }
                }
                } @else {
                <mat-icon>unknown_med</mat-icon>
                }
            </mat-option>
            }
        </mat-select>
    </mat-form-field>
    @if (allOptions) {
    <div class="filter-hints">{{!selectedOptions?.length ? allOptions.length : selectedOptions?.length}} / {{allOptions.length}}</div>
    }
</div>
}

<!--
<div [ngStyle]="{'margin-top': margin_top + 'px'}">
  <mat-form-field class="filter-field">
    <mat-select [(ngModel)]="selectedOptions" (selectionChange)="filterHasChanged()"
      data-test="slct-filter" multiple matInput name="selectOptions">
      <mat-select-trigger>
        <span *ngIf="selectedOptions?.length > 1; else maxOneOption">
          {{selectedOptions ? getStringTrigger(selectedOptions) : ''}}
        </span>
        <ng-template #maxOneOption>
          <span *ngIf="selectedOptions?.length">{{selectedOptions[0]}}</span>
        </ng-template>
      </mat-select-trigger>
      <mat-option *ngIf="options?.length >= 3">
        <ngx-mat-select-search ngModel name="optionSelect"
          (ngModelChange)="filteredOptions = utils.filterArray($event, filteredOptions)"
          [showToggleAllCheckbox]="true"
          [toggleAllCheckboxChecked]="allChecked"
          [toggleAllCheckboxIndeterminate]="indetChecked"
          (toggleAll)="toggleAll($event)"
          placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
          noEntriesFoundLabel="">
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{option}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="options" class="filter-hints">{{!selectedOptions ? options.length - (options[0] === EMPTYTAG ? 1 : 0) : selectedOptions.length - (selectedOptions[0] || selectedOptions[0] === EMPTYTAG ? 1 : 0)}} / {{options.length - 1}}</div>
</div> -->