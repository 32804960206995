import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Coffee } from 'src/app/models/Coffee';
import { StandardService } from 'src/app/util/services/standard.service';
import { Utils } from 'src/app/util/utils';
import { Enumerations } from 'src/app/models/Enumerations';
import { Location } from 'src/app/models/Location';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Transfer } from 'src/app/models/Transfer';
import { StockType } from 'src/app/modules/stock/store-stock.component';
import { environment } from 'src/environments/environment';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/util/alert/alert.service';
import { StockService } from 'src/app/modules/stock/stock.service';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { TransactionDialogComponent } from './transaction-dialog.component';

@Component({
    selector: 'app-transfer-dialog',
    templateUrl: './transfer-dialog.component.html',
    styleUrls: ['./transfer-dialog.component.scss'],
})
export class TransferDialogComponent extends TransactionDialogComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private standardService: StandardService,
        // used in template
        public tr: TranslatorService,
        protected userService: UserService,
        private stockService: StockService,
        public utils: Utils,
        // used by "that"
        public dialogRef: MatDialogRef<TransferDialogComponent>,
        // used by "that"
        public alertService: AlertService,
        protected router: Router,
        // private changeDetectorRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: {
            stores: Location[],
            stocks: StockType[][],
            transaction: Transfer,
            selectedSourceStore: Location,
            selectedCoffee: Coffee,
        }
    ) {
        super(dialogRef, utils, userService, router);
        dialogRef.disableClose = true;
    }

    transfer: Transfer;
    reference: string;

    // the target warehouse
    from_store: Location;
    to_store: Location;
    from_stores: Location[];
    to_stores: Location[];
    stocks: StockType[][];

    doneLoadingStocks = false;
    notLoaded = 3;

    round = Math.round;
    abs = Math.abs;

    @ViewChild('coffeeSelect', { static: true }) coffeeSelect: MatSelect;

    // Transfer: move amount of coffee from from_store on to_store on date.

    ngOnInit(): void {
        super.ngOnInit();

        this.getAllCoffees();

        if (this.data.selectedSourceStore) {
            this.getAllStores();
        } else if (this.data.stores && this.data.stocks) {
            this.stocks = this.data.stocks;
            this.stores = this.data.stores;
            this.doneLoadingStocks = true;
            this.notLoaded -= 2;

            this.to_stores = this.data.stores;
            this.from_stores = this.data.stores;
            if (!this.data.transaction) {
                // remove all from 'from' that have no coffees
                // but only if not editing an existing transaction
                this.from_stores = this.data.stores.filter((_store, sIdx) => this.stocks[sIdx].length > 0);
            }

            if (this.stores?.length > 0 && this.from_stores?.length > 0 && this.from_stores[0]) {
                this.from_store = this.from_stores[0];
                this.to_stores = this.stores.filter(s => s._id !== this.from_store._id);
                if (this.to_stores.length === 1) {
                    this.to_store = this.to_stores[0];
                }
            }
        } else {
            this.getAllStores();
        }

        if (this.data.transaction) {
            // have a transaction that needs to be edited i.e. loaded
            this.coffee = this.data.transaction.coffee;
            if (this.coffee && typeof this.coffee !== 'string') {
                this.coffee.yearLabel = this.coffee.yearLabel || this.utils.createBeansYearLabel(this.coffee);
            }
            this.date = this.data.transaction.date;
            this.reference = this.data.transaction.reference;
            this.from_store = this.data.transaction.location;
            this.to_store = this.data.transaction.target;
            if (this.stores) {
                this.from_stores = this.stores;
                this.to_stores = this.stores.filter(s => s._id !== this.from_store._id);
            }
            this.unitamount = this.data.transaction.amount;
            this.calcAmount(false);
            this.reconciled = this.data.transaction.reconciled;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    ngAfterViewInit(): void {
        if (this.coffeeSelect) {
            this.coffeeSelect.compareWith = this.utils.compareByLabelFn;
        }
    }

    protected getAllCoffees(): void {
        this.standardService.getAll<Coffee>('coffees')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.coffees = response.result;
                        if (this.coffee?.hidden) {
                            // need to add this current coffee since hidden coffees are not retrieved by getAll
                            this.coffees.unshift(this.coffee);
                        }
                        for (const cof of this.coffees) {
                            if (cof) {
                                if (!cof.default_unit?.name) {
                                    cof.default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 }
                                }
                                cof.yearLabel = cof.yearLabel || this.utils.createBeansYearLabel(cof);
                            }
                        }
                        this.filteredCoffees = this.coffees;
                        this.notLoaded -= 1;
                        let doUpdate = false;

                        if (!this.data.transaction && this.stocks && this.stocks.length > 0) {
                            // use only those coffees that have a stock in one or more of the user's stores
                            // but only if not editing an existing transaction
                            let availableCoffeeIds = [];
                            for (let s = 0; this.stocks && s < this.stocks.length; s++) {
                                const stocks = this.stocks[s];
                                availableCoffeeIds = availableCoffeeIds.concat(stocks.filter(stock => stock).map(stock => stock.coffeeId));
                            }
                            this.coffees = this.coffees.filter(c => availableCoffeeIds.indexOf(c._id) >= 0);
                            this.filteredCoffees = this.coffees;

                            if (!this.coffee && this.coffees && this.coffees.length > 0) {
                                this.coffee = this.coffees[0];
                                // this.coffeeChanged();
                            }
                            doUpdate = true;
                        }
                        if (this.data.selectedCoffee) {
                            for (const coff of this.coffees) {
                                if ((coff._id || coff).toString() === (this.data.selectedCoffee._id || this.data.selectedCoffee).toString()) {
                                    this.coffee = coff;
                                    doUpdate = true;
                                    break;
                                }
                            }
                        }
                        if (typeof this.coffee === 'string') {
                            // lookup coffee ID
                            let found = false;
                            for (let c = 0; c < this.coffees.length && !found; c++) {
                                const coff = this.coffees[c];
                                if ((coff._id || coff).toString() === this.coffee.toString()) {
                                    this.coffee = coff;
                                    doUpdate = true;
                                    found = true;
                                }
                            }
                        }
                        if (!this.data.transaction && !this.coffee && this.coffees && this.coffees.length > 0) {
                            this.coffee = this.coffees[0];
                        }
                        if (doUpdate) {
                            this.sourceStoreChanged();
                        }
                        // this.changeDetectorRef.detectChanges();
                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                }
        });
    }

    protected getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (this.data.selectedSourceStore) {
                            this.from_store = this.data.selectedSourceStore;
                        }
                        this.from_stores = response.result;
                        this.stores = response.result;
                        this.to_stores = this.stores;
                        this.notLoaded -= 1;
                        // this.changeDetectorRef.detectChanges();
                        this.getAllStocks();
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                }
        });
    }

    protected getAllStocks(): void {
        if (!this.stores) {
            return;
        }
        const storeIds = this.stores.map(s => s._id);
        this.stockService.getCoffeeStocks(storeIds)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stocks = [];
                        this.notLoaded -= 1;
                        if (!this.data.transaction) {
                            for (let sIdx = 0; sIdx < response.result.length; sIdx++) {
                                this.stocks[sIdx] = response.result[sIdx].filter(s => s.amount);
                            }
                        } else {
                            this.stocks = response.result;
                        }
                        this.doneLoadingStocks = true;

                        this.from_stores = this.stores;
                        if (!this.data.transaction && this.stocks && this.stocks.length > 0) {
                            // use only those coffees that have a stock in one or more of the user's stores
                            // but only if this is not an edit of an existing transaction
                            let availableCoffeeIds = [];
                            for (const stocks of this.stocks) {
                                if (stocks) {
                                    availableCoffeeIds = availableCoffeeIds.concat(stocks.map(s => s.coffeeId));
                                }
                            }
                            if (this.coffees) {
                                this.coffees = this.coffees.filter(c => availableCoffeeIds.indexOf(c._id) >= 0);
                                this.filteredCoffees = this.coffees;
                            }

                            if (!this.coffee && this.coffees && this.coffees.length > 0) {
                                this.coffee = this.coffees[0];
                                // this.coffeeChanged();
                            }
                            if (this.from_store) {
                                this.sourceStoreChanged();
                            }

                            // remove all from 'from' that have no coffees
                            this.from_stores = this.stores.filter((_store, sIdx) => this.stocks[sIdx].length);
                            if (this.stores?.length && (!this.from_store || !this.stores.map(s => s._id?.toString().includes(this.from_store?._id?.toString())))) {
                                this.from_store = this.from_stores[0];
                            }
                            this.to_stores = this.stores.filter(s => s._id !== this.from_store._id);
                            if (!this.to_store && this.to_stores.length === 1) {
                                this.to_store = this.to_stores[0];
                            }
                            // this.changeDetectorRef.detectChanges();
                        } else {
                            this.to_stores = this.stores.filter(s => s._id !== this.from_store._id);
                        }
                    } else {
                        this.utils.handleError('error retrieving all store stocks', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all store stocks', error);
                }
        });
    }

    protected calcAmount(toUnit: boolean): void {
        if (this.coffee?.default_unit?.size) {
            if (toUnit) {
                // amount to unitamount
                this.unitamount = this.amount * this.coffee.default_unit.size;
            } else {
                // unitamount to amount
                this.amount = this.unitamount / this.coffee.default_unit.size;
            }
        }
    }

    // called when a different coffee has been selected; need to check unitamount
    protected coffeeChanged(): void {
        this.calcAmount(false);
    }

    protected useAllStock(): void {
        const amount = this.getAmount();
        if (amount) {
            this.unitamount = Math.round(amount * 1000) / 1000;
            this.calcAmount(false);
        }
    }

    protected sourceStoreChanged(): void {
        if (!this.from_store) {
            return;
        }
        if (!this.data.transaction) {
            // use only those coffees that have a stock in one or more of the user's stores
            this.filteredCoffees = (this.coffees || []).filter(c => {
                for (let s = 0; c.stock?.length && s < c.stock.length; s++) {
                    const stock = c.stock[s];
                    if (stock && this.utils.compareObjectsFn(stock.location, this.from_store)) {
                        return true;
                    }
                }
                return false;
            });
            if (this.filteredCoffees.length === 1) {
                this.coffee = this.filteredCoffees[0];
            } else if (this.coffee?.stock) {
                // reset coffee unless the selected one also exists in the new source store
                let exists = false;
                for (let s = 0; this.coffee.stock?.length && s < this.coffee.stock.length; s++) {
                    const stock = this.coffee.stock[s];
                    if (stock && this.utils.compareObjectsFn(stock.location, this.from_store)) {
                        exists = true;
                    }
                }
                if (!exists) {
                    if (this.filteredCoffees.length > 0) {
                        this.coffee = this.filteredCoffees[0];
                    } else {
                        this.coffee = undefined;
                    }
                }
            }
        }

        this.to_stores = this.stores.filter(s => !this.utils.compareObjectsFn(s, this.from_store));
        if (this.to_stores.length === 1) {
            this.to_store = this.to_stores[0];
        }
    }

    private getAmount(): number {
        if (this.data.transaction) {
            // editing an existing transaction
            return undefined;
        }

        // new transaction
        if (!this.stocks) {
            return 0;
        }
        for (const stockPerLoc of this.stocks) {
            for (const stock of stockPerLoc) {
                if (!(stock.location_internal_hr_id === this.from_store.internal_hr_id)) {
                    // not the correct store
                    break;
                }
                if (stock.coffee_internal_hr_id === this.coffee.internal_hr_id) {
                    return stock.amount;
                }
            }
        }
        // could not find beans in current store => amount is 0
        return 0;
    }

    protected getAmountStr(): { pre: string, value: number, post: string } {
        const val = this.getAmount();
        if (val == null) {
            return undefined;
        }
        if (!val) {
            return { pre: '', value: 0, post: '' };
        }
        return this.utils.formatAmountForPipe(val, undefined, this.currentUser.unit_system);
    }

    // checks if anything (not including the amount) has changed
    protected hasChanged(): boolean {
        const t = this.data.transaction;
        if (t) {
            if (!this.utils.compareObjectsFn(this.to_store, t.target)) { return true; }
            if (!this.utils.compareObjectsFn(this.coffee, t.coffee)) { return true; }
            if (!this.utils.compareObjectsFn(this.from_store, t.location)) { return true; }
            if (this.reference !== t.reference) { return true; }
            if (this.reconciled !== t.reconciled) { return true; }
            // if (JSON.stringify(this.tags) !== JSON.stringify(t.tags)) return true;
            if (!this.date.hasSame(t.date, 'minute')) { return true; }
        } else {
            // only depends on amount which is checked outside this function
            return true;
        }
        return false;
    }

    protected doAdd(that?: TransferDialogComponent): void {
        if (!that) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            that = this;
        }
        if (!that.submitPressed) {
            return;
        }
        that.submitPressed = false;

        const old_amount = that.data && that.data.transaction && that.data.transaction.amount ? that.data.transaction.amount : 0;
        if (that.utils.isWithinRounding(that.unitamount, old_amount, 3) && !that.hasChanged()) {
            that.alertService.success(that.tr.anslate('Nothing to change'));
            // that.dialogRef.close();
            that.finishDialog(undefined);
            return;
        }

        that.transfer = new Transfer();
        delete that.transfer.reconciled;
        that.transfer.amount = that.unitamount;
        that.transfer.reference = that.reference;
        that.transfer.coffee = that.coffee?._id as unknown as Coffee || that.coffee;
        that.transfer.date = that.date;
        that.transfer.location = that.from_store._id as unknown as Location || that.from_store;
        that.transfer.target = that.to_store._id as unknown as Location || that.to_store;
        that.transfer.type = 'Transfer';
        that.transfer.translated_type = that.tr.anslate('Transfer');

        // that.dialogRef.close(that.transfer);
        that.finishDialog(that.transfer);
    }
}
