import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StockType } from './store-stock.component';
import { Constants } from 'src/app/util/constants';

@Injectable({
    providedIn: 'root'
})
export class StockService {

    constructor(
        private http: HttpClient,
    ) { }

    getCoffeeStock(storeId: string, recalc = false): Observable<{ success: boolean, result: StockType[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/coffeestock/' + storeId;
        let options = {};
        if (recalc) {
            const httpParams = new HttpParams().set('recalc', '1');
            options = { params: httpParams };
        }
        return this.http.get<{ success: boolean, result: StockType[], error: string }>(url, options);
    }

    getCoffeeStocks(storeIds: string[], recalc = false): Observable<{ success: boolean, result: StockType[][], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/coffeestocks/' + (storeIds?.length ? storeIds.join(Constants.STORES_SEPARATOR) : '');
        let options = {};
        if (recalc) {
            const httpParams = new HttpParams().set('recalc', '1');
            options = { params: httpParams };
        }
        return this.http.get<{ success: boolean, result: StockType[][], error: string }>(url, options);
    }

}
