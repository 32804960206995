<ng-template #coffeeString let-coffee="coffee">
    @if (coffee?.label && coffee.hr_id) {
    <span>&nbsp;<a [routerLink]="['/coffees', {id: coffee.hr_id}]" (click)="closeDialog()">{{coffee.hr_id}}</a>
        @if (coffee.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
        @if (coffee.origin || coffee.yearLabel) {
        <span>,</span>
        }
        @if (coffee.label) {
        <span> {{coffee.label}}</span>
        }
    </span>
    }
</ng-template>


<h1 mat-dialog-title i18n="delete dialogs$$delete dialog, cannot delete header@@cannot delete">Unable to delete!</h1>
<mat-dialog-content>
    <div i18n="delete dialogs$$info that an item cannot be deleted since it is referenced from another item@@cannot delete because of references">{{data.label}} cannot be deleted since it is in use.</div>
    <br />
    <ul>
        @for (ref of data.refs; track $index) {
        <li>
            @if (ref.model === 'RoastReport') {
            <span i18n="generic$$Report@@Report">Report</span> {{ref.ref?.label}}
            }
            @if (ref.model === 'RoastReportOpen') {
            <span i18n="generic$$Report@@Report">Report</span> (<span i18n="generic$$Open@@Open">Open</span>) {{ref.ref?.label}}
            }
            @if (ref.model === 'Coffee') {
            <span i18n="generic$$Beans@@Beans">Beans</span>
            <ng-container *ngTemplateOutlet="coffeeString; context: {coffee: ref.ref}"></ng-container>
            }
            @if (ref.model === 'Blend') {
            <span i18n="generic$$Blend@@Blend">Blend</span>&nbsp;@if (ref.ref?.hr_id) {
            <a [routerLink]="['/blends', {id: ref.ref.hr_id}]" (click)="closeDialog()">{{ref.ref.hr_id}} {{ref.ref.label}}</a>
            }
            }
            @if (ref.model === 'Roast') {
            <span i18n="generic$$Roast@@Roast">Roast</span>&nbsp;@if (ref.ref?.hr_id) {
            <a [routerLink]="['/roasts', {id: ref.ref.hr_id}]" (click)="closeDialog()">{{ref.ref.hr_id}} {{ref.ref.label}}</a>
            }
            }
            @if (ref.model === 'Purchase' || ref.model === 'Sale' || ref.model === 'Correction' || ref.model === 'Transfer') {
            {{tr.anslate(ref.model)}}: @if (utils.getRoute(ref.model); as link) {
            <span>
                <a [routerLink]="[link, {id: ref.ref?.hr_id}]" (click)="closeDialog()">{{ref.ref.hr_id}} {{ref.ref.label}}</a>
            </span>
            } @else {
            @if (ref.ref) {
            <span>
                {{ref.ref.amount * utils.getUnitFactor(data.mainUnit) | number:'1.0-0'}}{{data.mainUnit}}
                @if (ref.ref.coffee) {
                <span class="littlespaceright">
                    <ng-container *ngTemplateOutlet="coffeeString; context: {coffee: ref.ref.coffee}"></ng-container>
                </span>
                }
                @if (ref.ref.price) {
                <span> <span i18n="transaction$$indicates the price of a transaction, e.g. 3kg _for_ 100€@@for price">for</span> {{ref.ref.price | currency:data.currency:'symbol-narrow':'1.0-0'}}</span>
                }
                @if (ref.model === 'Purchase' && ref.ref.supplier?.label) {
                <span> <span i18n="transaction$$transaction, e.g. a transfer, _from_ a source location@@from source" class="littlespaceleft littlespaceright">from</span> <a [routerLink]="['/contacts', {id: ref.ref.supplier?._id}]">{{ref.ref.supplier?.label}}</a></span>
                }
                @if (ref.model === 'Sale' && ref.ref.customer?.label) {
                <span> <span i18n="transaction$$transaction, e.g. a transfer, _to_ a target location@@to target" class="littlespaceleft littlespaceright">to</span> @if (ref.ref.customer?.label) {
                    <a [routerLink]="['/contacts', {id: ref.ref.customer?._id}]">{{ref.ref.customer?.label}}</a>
                    }</span>
                }
                @if (ref.model === 'Correction' && ref.ref.reason) {
                <span> "{{ref.ref.reason}}"</span>
                }
                @if (ref.model === 'Transfer') {
                <span>
                    @if (ref.ref.location?.label) {
                    <span><span i18n="transaction$$transaction, e.g. a transfer, _from_ a source location@@from source" class="littlespaceleft">from</span> @if (ref.ref.location.hr_id) {
                        <a [routerLink]="['/stores', {id: ref.ref.location.hr_id}]">{{ref.ref.location.label}}</a>
                        } @else {
                        {{ref.ref.location.label}}
                        }</span>
                    }
                    @if (ref.ref.target?.label) {
                    <span><span i18n="transaction$$transaction, e.g. a transfer, _to_ a target location@@to target" class="littlespaceleft">to</span> @if (ref.ref.target.hr_id) {
                        <a [routerLink]="['/stores', {id: ref.ref.target.hr_id}]">{{ref.ref.target.label}}</a>
                        } @else {
                        {{ref.ref.target.label}}
                        }
                    </span>
                    }
                </span>
                }
                @if (ref.ref.date) {
                <span i18n="transaction$$e.g. on 09.12.1978@@on {{date}}" class="littlespaceleft">on {{DateTime.fromISO(ref.ref.date).toLocaleString(DateTime.DATE_MED)}}</span>
                }
            </span>
            }
            }
            }
        </li>
        }
    </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="cancel-button" autofocus cdkFocusInitial mat-button type="submit" mat-dialog-close tabindex="0">
        <mat-icon>close</mat-icon>
    </button>
</mat-dialog-actions>