import { Account } from './Account';
import { Tag } from './Tag';
import { Location } from './Location';
import { User } from './User';

export class Customer {
    _id: string;
    created_at ?: Date;
    updated_at ?: Date;
    deleted ?: boolean;
    owner ?: Account;
    label: string;
    code ?: string;
    web ?: string;
    email ?: string;
    phone ?: string;
    notes ?: string;
    logo ?: string;
    image ?: string;
    files ?: string[];
    location ?: Location;
    tags ?: Tag[];
    refs ?: { count: number, objs?: string[] };
    created_by ?: User;
    updated_by ?: User;
}

