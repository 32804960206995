<ng-template #noInfoError><span class="no-amount">---</span></ng-template>
<ng-template #roastsErrors><span class="no-amount" style="margin-top: 8px">Achtung:</span> Manche Einträge im Röstprotokoll ("Röstkaffee Ertrag") sind unvollständig!</ng-template>
<ng-template #purchaseErrors><span class="no-amount">Achtung:</span> Manche Einträge in "Rohkaffee Zugang" sind unvollständig!</ng-template>


<h2 style="margin-right: 15px;">Daten für das Hauptzollamt Hamburg
    @if (!justCopied_r && !copyFailed_r && data_roasts?.filteredData?.length) {
    <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyRoasts()">
        <mat-icon matTooltip="Copy to clipboard"
            i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
    </button>
    }
    @if (justCopied_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
    </button>
    }
    @if (copyFailed_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
    </button>
    }
</h2>

@if (transactionsHaveErrors) {
<div><ng-container *ngTemplateOutlet="purchaseErrors"></ng-container></div>
}

@if (roastsHaveErrors) {
<div><ng-container *ngTemplateOutlet="roastsErrors"></ng-container></div>
}

<table mat-table [dataSource]="data_summary" class="wide-table mat-elevation-z8" #summaryTable>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay_summary"></tr>
    <tr mat-row *matRowDef="let summary; columns: columnsToDisplay_summary" class="report-trans-row"></tr>

    <ng-container matColumnDef="sold">
        <th mat-header-cell *matHeaderCellDef>Rohkaffee Verkauf (kg)</th>
        <td mat-cell *matCellDef="let summary">
            @if (summary.sum_sale) {
            <span>{{summary.sum_sale | number:'1.0-3'}}kg</span>
            } @else {
            ---
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="purchased">
        <th mat-header-cell *matHeaderCellDef>Rohkaffee Zugang (kg)</th>
        <td mat-cell *matCellDef="let summary">
            @if (summary.sum_purch) {
            <span>{{summary.sum_purch | number:'1.0-3'}}kg</span>
            } @else {
            ---
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="roasted">
        <th mat-header-cell *matHeaderCellDef>Rohkaffee Abgang (Kg)</th>
        <td mat-cell *matCellDef="let summary">
            @if (summary.sum_roasts) {
            <span>{{summary.sum_roasts | number:'1.0-3'}}kg</span>
            } @else {
            ---
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="yield">
        <th mat-header-cell *matHeaderCellDef>Röstkaffee Ertrag (kg)</th>
        <td mat-cell *matCellDef="let summary">
            @if (summary.yield) {
            <span>{{summary.yield | number:'1.0-3'}}kg</span>
            } @else {
            ---
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="rateofyield">
        <th mat-header-cell *matHeaderCellDef>Einbrand (%)</th>
        <td mat-cell *matCellDef="let summary">
            @if (summary.rateofyield) {
            <span>{{summary.rateofyield | number:'1.1-1'}}%</span>
            } @else {
            ---
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="tax">
        <th mat-header-cell *matHeaderCellDef>Kaffeesteuer ({{tax | number:'1.2-2'}}€/kg)</th>
        <td mat-cell *matCellDef="let summary">
            @if (summary.tax) {
            <span>{{summary.tax | number:'1.2-2'}}€</span>
            } @else {
            ---
            }
        </td>
    </ng-container>
</table>


@if (data_trans) {
<h2>Rohkaffee Ein-/Verkauf
    @if (data_trans.data && data_trans.data.length > 0) {
    @if (!justCopied && !copyFailed) {
    <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyTrans()">
        <mat-icon matTooltip="Copy to clipboard"
            i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
    </button>
    }
    @if (justCopied) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
    </button>
    }
    @if (copyFailed) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
    </button>
    }
    }
</h2>
}

@if (data_trans.data?.length) {
<table mat-table [dataSource]="data_trans" class="wide-table mat-elevation-z8" #transTable>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay_trans"></tr>
    <tr mat-row *matRowDef="let transs; columns: columnsToDisplay_trans" class="report-trans-row"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplay_trans" class="sum-cell"></tr>
    <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef i18n="generic$$date placeholder or header@@Date">Date</th>
        <td mat-cell *matCellDef="let trans">
            @if (trans.date) {
            <span>{{trans.date.toLocaleString(DateTime.DATE_SHORT)}}</span>
            } @else {
            ---
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Supplier/Customer">
        <th mat-header-cell *matHeaderCellDef><span i18n="contacts$$customer@@Customer">Customer</span>/<span i18n="contacts$$supplier@@Supplier">Supplier</span></th>
        <td mat-cell *matCellDef="let trans">
            @if (trans.__t === 'Purchase') {
            @if (trans.supplier) {
            <span>{{trans.supplier}}</span>
            } @else {
            ---
            }
            }
            @if (trans.__t === 'Sale') {
            @if (trans.customer) {
            <span>{{trans.customer}}</span>
            } @else {
            ---
            }
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Order/Sales Number">
        <th mat-header-cell *matHeaderCellDef><span i18n="transaction$$add an order number@@order_number">Order Number</span>/<span i18n="transaction$$add a sales number@@sales_number">Sales Number</span></th>
        <td mat-cell *matCellDef="let trans">
            @if (trans.__t === 'Purchase') {
            @if (trans.order_number || trans.reference) {
            <span>{{trans.order_number || trans.reference}}</span>
            } @else {
            ---
            }
            }
            @if (trans.__t === 'Sale') {
            @if (trans.sales_number || trans.reference) {
            <span>{{trans.sales_number || trans.reference}}</span>
            } @else {
            ---
            }
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Coffees">
        <th mat-header-cell *matHeaderCellDef i18n="generic$$Beans@@Beans">Beans</th>
        <td mat-cell *matCellDef="let trans">
            @if (trans?.coffee) {
            <span>
                @if (trans.coffee.hr_id) {
                <a [routerLink]="['/coffees', {id: trans.coffee.hr_id}]">{{trans.coffee.hr_id}}</a>
                }
                @if (trans.coffee.origin) {
                <span> {{tr.anslate(trans.coffee.origin)}}</span>
                }
                @if (trans.coffee.yearLabel) {
                <span> {{trans.coffee.yearLabel}}</span>
                }
                @if (trans.coffee.label && (trans.coffee.hr_id || trans.coffee.origin || trans.coffee.yearLabel)) {
                <br />
                }
                @if (trans.coffee.label) {
                <span> {{trans.coffee.label}}</span>
                }
            </span>
            }
            @if (trans?.blend; as blend) {
            <span>
                @if (blend.label) {
                <span>{{blend.label}}:</span>
                }
                <ul class="ingredients-list">
                    @for (ing of blend.ingredients; track $index) {
                    <li>
                        @if (ing.ratio != null) {
                        <span>{{ing.ratio * 100 | number:'1.1-1'}}%
                            @if (ing.coffee?.hr_id) {
                            <a [routerLink]="['/coffees', {id: ing.coffee.hr_id}]"> {{ing.coffee.hr_id}}</a>
                            }
                            @if (ing.coffee?.origin) {
                            <span> {{tr.anslate(ing.coffee.origin)}}</span>
                            }
                            @if (ing.coffee?.yearLabel) {
                            <span> {{ing.coffee.yearLabel}}</span>
                            }
                            @if (ing.coffee?.label && (ing.coffee.origin || ing.coffee.yearLabel)) {
                            <span>,</span>
                            }
                            @if (ing.coffee?.label) {
                            <span> {{ing.coffee.label}}</span>
                            }
                        </span>
                        }
                    </li>
                    }
                </ul>
            </span>
            }
            @if (!trans.coffee && !trans.blend) {
            <ng-container *ngTemplateOutlet="noInfoError"></ng-container>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align" colspan="4"><span i18n="generic$$indicating the sum of some numbers@@Sum">Sum</span>:</td>
    </ng-container>
    <ng-container matColumnDef="Purchase Amount">
        <th mat-header-cell *matHeaderCellDef class="right-align" i18n="transaction$$transaction title@@Purchase">Purchase</th>
        <td mat-cell *matCellDef="let purchase" class="right-align">
            @if (purchase.__t === 'Purchase') {
            @if (purchase.amount != null) {
            <span><span>{{utils.convertToUserUnit(purchase.amount, currentUser?.unit_system) | number:'1.3-3'}}{{mainUnit}}</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{utils.convertToUserUnit(sum_purch, currentUser?.unit_system) | number:'1.0-3'}}{{mainUnit}}</td>
    </ng-container>
    <ng-container matColumnDef="Sale Amount">
        <th mat-header-cell *matHeaderCellDef class="right-align" i18n="transaction$$transaction title@@Sale">Sale</th>
        <td mat-cell *matCellDef="let sale" class="right-align">
            @if (sale.__t === 'Sale') {
            @if (sale.amount != null) {
            <span><span>{{utils.convertToUserUnit(sale.amount, currentUser?.unit_system) | number:'1.3-3'}}{{mainUnit}}</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">{{utils.convertToUserUnit(sum_sale, currentUser?.unit_system) | number:'1.0-3'}}{{mainUnit}}</td>
    </ng-container>
</table>
} @else {
Keine Käufe in diesem Zeitraum vorhanden
}


@if (data_roasts) {
<h2>Röstprotokoll
    @if (!justCopied_r && !copyFailed_r && data_roasts?.filteredData?.length) {
    <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyRoasts()">
        <mat-icon matTooltip="Copy to clipboard"
            i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
    </button>
    }
    @if (justCopied_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
    </button>
    }
    @if (copyFailed_r) {
    <button mat-icon-button type="button" disabled class="copy-button-button">
        <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
    </button>
    }
</h2>
}

@if (manualDiscarded && nrDiscarded) {
<mat-checkbox [disabled]="readOnly" name="manualDiscarded_checkbox"
    [ngModel]="onlyDiscardedNotDestroyed"
    (change)="onlyDiscardedNotDestroyedChanged($event)"
    class="manualDiscarded-checkbox wrap-checkbox-label"
    [ngStyle]="{'margin-bottom': roastsHaveErrors ? '10px' : 0}">
    Zeige nur alle Fehlröstungen, die noch nicht als vernichtet markiert sind.
</mat-checkbox>
}

@if (roastsHaveErrors) {
<div><ng-container *ngTemplateOutlet="roastsErrors"></ng-container></div>
}

@if (data_roasts?.filteredData?.length) {
<table mat-table [dataSource]="data_roasts" class="wide-table mat-elevation-z8" #roastTable>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay_roasts"></tr>
    <tr mat-row *matRowDef="let roasts; columns: columnsToDisplay_roasts" class="report-roasts-row"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplay_roasts" class="sum-cell"></tr>
    <ng-container matColumnDef="Destroyed">
        <th mat-header-cell *matHeaderCellDef>Fehlröstung<br />vernichtet</th>
        <td mat-cell *matCellDef="let roast; let i=index">
            @if (roast.discarded) {
            <mat-form-field class="amount-input-ff1 narrow-date-field">
                <input matInput [placeholder]="roast.destroyed ? '' : 'Leer / Datum'"
                    [(ngModel)]="roast.destroyed" (dateChange)="destroyedChanged(i)"
                    [disabled]="readOnly" data-test="npt_destroyedDate"
                    [matDatepicker]="myDatepicker" name="destroyedDate">
                <mat-datepicker-toggle matIconPrefix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
            }
            @if (roast.destroyed && !readOnly) {
            <button mat-icon-button class="tertiary-element" (click)="removeDestroyed(i)">
                <mat-icon class="tertiary-element removeDestroyed-icon">close</mat-icon>
            </button>
            }
            <!-- <ng-container *ngIf="i === 0"> -->
            @if (roast.discarded && nrDiscarded > 1) {
            <br />
            @if (!readOnly) {
            <a (click)="applyDestroyedToAll(i)" (keydown.enter)="applyDestroyedToAll(i); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">
                Auf alle anwenden
            </a>
            }
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef i18n="generic$$date placeholder or header@@Date">Date</th>
        <td mat-cell *matCellDef="let roast">
            @if (roast.date) {
            <span>{{roast.date.toLocaleString(DateTime.DATE_SHORT)}}</span>
            } @else {
            ---
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Coffees">
        <th mat-header-cell *matHeaderCellDef i18n="generic$$Beans@@Beans">Beans</th>
        <td mat-cell *matCellDef="let roast">
            @if (roast?.coffee) {
            <span>
                @if (roast.coffee.hr_id) {
                <a [routerLink]="['/coffees', {id: roast.coffee.hr_id}]">{{roast.coffee.hr_id}}</a>
                }
                @if (roast.coffee.origin) {
                <span> {{tr.anslate(roast.coffee.origin)}}</span>
                }
                @if (roast.coffee.yearLabel) {
                <span> {{roast.coffee.yearLabel}}</span>
                }
                @if (roast.coffee.label && (roast.coffee.hr_id || roast.coffee.origin || roast.coffee.yearLabel)) {
                <br />
                }
                @if (roast.coffee.label) {
                <span> {{roast.coffee.label}}</span>
                }
            </span>
            }
            @if (roast?.blend; as blend) {
            <span>
                @if (blend.label) {
                <span>{{blend.label}}</span>
                }
            </span>
            }
            @if (!roast.coffee && !roast.blend) {
            <ng-container *ngTemplateOutlet="noInfoError"></ng-container>
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="ID">
        <th mat-header-cell *matHeaderCellDef i18n="generic$$ID@@ID">ID</th>
        <td mat-cell *matCellDef="let roast">
            @if (roast.batch_prefix || roast.batch_number || roast.hr_id) {
            <span>
                {{roast.batch_prefix}}{{roast.batch_number}}
                @if (roast.batch_prefix || roast.batch_number) {
                <br />
                }
                @if (roast.hr_id) {
                <a [routerLink]="['/roasts', {id: roast.hr_id}]">{{roast.hr_id}}</a>
                } @else {
                ---
                }
            </span>
            } @else {
            ---
            }
        </td>
        <ng-container mat-footer-cell *matFooterCellDef></ng-container>
    </ng-container>
    <ng-container matColumnDef="Blends">
        <th mat-header-cell *matHeaderCellDef i18n="generic$$Blend@@Blend">Blend</th>
        <td mat-cell *matCellDef="let roast">
            @if (roast?.blend; as blend) {
            <span>
                <ul class="ingredients-list">
                    @for (ing of blend.ingredients; track $index) {
                    <li>
                        @if (ing.ratio != null) {
                        <span>{{ing.ratio * 100 | number:'1.1-1'}}%
                            @if (ing.coffee?.hr_id) {
                            <a [routerLink]="['/coffees', {id: ing.coffee.hr_id}]"> {{ing.coffee.hr_id}}</a>
                            }
                            @if (ing.coffee?.origin) {
                            <span> {{tr.anslate(ing.coffee.origin)}}</span>
                            }
                            @if (ing.coffee?.yearLabel) {
                            <span> {{ing.coffee.yearLabel}}</span>
                            }
                            @if (ing.coffee?.label && (ing.coffee.origin || ing.coffee.yearLabel)) {
                            <span>,</span>
                            }
                            @if (ing.coffee?.label) {
                            <span> {{ing.coffee.label}}</span>
                            }
                        </span>
                        }
                    </li>
                    }
                </ul>
            </span>
            }
            @if (!roast.coffee && !roast.blend) {
            <ng-container *ngTemplateOutlet="noInfoError"></ng-container>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align" colspan="4">
            <span i18n="generic$$indicating the sum of some numbers@@Sum">Sum</span>:
            @if (sum_discarded) {
            <br /><span class="normalweight-footer">ohne Fehlröstungen:</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef class="right-align" i18n="generic$$Amount (of beans, e.g. in kg or bags)@@Amount">Amount</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.amount != null) {
            <span><span>{{utils.convertToUserUnit(roast.amount, currentUser?.unit_system) | number:'1.3-3'}}{{mainUnit}}</span></span>
            } @else {
            <span class="no-amount">---</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">
            {{utils.convertToUserUnit(sum_amount, currentUser?.unit_system) | number:'1.3-3'}}{{mainUnit}}@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_amount - sum_discarded) | number:'1.3-3'}}kg</span>
            }
        </td>
    </ng-container>
    <ng-container matColumnDef="End Weight">
        <th mat-header-cell *matHeaderCellDef class="right-align" i18n="roasts$$end weight in e.g. kg@@Yield in {{mainUnit}}">Yield in {{mainUnit}}</th>
        <td mat-cell *matCellDef="let roast" class="right-align">
            @if (roast.end_weight != null && (!roast.discarded || (manualDiscarded && !roast.destroyed))) {
            <span><span>{{utils.convertToUserUnit(roast.end_weight, currentUser?.unit_system) | number:'1.3-3'}}{{mainUnit}}</span>
            </span>
            } @else {
            ---
            }
            @if (roast.discarded && (!manualDiscarded || roast.destroyed)) {
            <span class="small-font discarded"><br />Fehlröstung</span>
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align">
            {{utils.convertToUserUnit(sum_end_weight, currentUser?.unit_system) | number:'1.3-3'}}{{mainUnit}}@if (sum_discarded) {
            <br /><span class="normalweight-footer">{{(sum_end_weight - sum_end_weight_discarded) | number:'1.3-3'}}kg</span>
            }
        </td>
    </ng-container>
</table>
} @else {
Keine Röstungen in diesem Zeitraum vorhanden
}