import { DateTime } from 'luxon';
import { Account } from './Account';
import { Location } from './Location';
import { StockChange } from './StockChange';
import { User } from './User';

export class BeansReport {
    _id: string;
    created_at ?: Date;
    updated_at ?: Date;
    deleted ?: boolean;
    owner ?: Account;
    created?: DateTime;
    startDate ?: DateTime;
    endDate?: DateTime;
    label ?: string;
    number ?: string;
    types ?: number;
    transactions ?: StockChange[];
    missingTransactions ?: StockChange[];
    pdfLink ?: string;
    pdfLinkDate?: DateTime;
    certInfoOnCreation ?: number;
    ssf ?: Location[];
    files ?: string[];
    created_by ?: User;
    updated_by ?: User;
    // only used in client
    certInfo?: number;
}

