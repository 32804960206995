import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WaitDialogComponent } from './wait-dialog.component';

@Component({
    selector: 'app-yesno-wait-dialog',
    templateUrl: './yesno-wait-dialog.component.html',
})
export class YesNoWaitDialogComponent extends WaitDialogComponent implements OnInit {

    constructor(
        protected dialogRef: MatDialogRef<YesNoWaitDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { text: string, okIcon?: string }
    ) {
        super(dialogRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!this.data.okIcon) {
            this.data.okIcon = 'delete';
        }
    }
}
