import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslatorService } from 'src/app/util/services/translator.service';

@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.scss'],
    providers: []
})
export class ReportingComponent implements OnInit, OnDestroy {

    constructor(
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private tr: TranslatorService,
    ) { }

    currentUser: UserType;
    readOnly = false;
    private ngUnsubscribe = new Subject();

    purchaseSaleLabel = 'Purchases / Sales'; // translated below

    selectedTab: number;

    activeLink = 'roasts';

    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        this.readOnly = this.userService.isReadOnly();

        this.purchaseSaleLabel = this.tr.anslate('Purchases') + '/' + this.tr.anslate('Sales');

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (/*e: unknown*/) => {
                    // url: /reports/customs
                    if (this.router.url === '/reports') {
                        this.activeLink = 'roasts';
                    } else {
                        this.activeLink = this.router.url?.substring(this.router.url.lastIndexOf('/') + 1) || 'roasts';
                    }

                    // if a reload should be triggered
                    // const newActive = this.router.url?.substring(this.router.url.lastIndexOf('/') + 1) || 'roasts';
                    // if (e instanceof NavigationEnd) {
                    //     if (this.activeLink === newActive) {
                    //         // this.loadSubscription.next('reload');
                    //     } else {
                    //         this.activeLink = newActive;
                    //     }
                    // }
                }
            );

        if (this.router.url === '/reports') {
            this.activeLink = 'roasts';
        } else {
            this.activeLink = this.router.url?.substring(this.router.url.lastIndexOf('/') + 1) || 'roasts';
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }
}
