import { MyMaterialModule } from './material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApToolbarComponent } from './ap-toolbar/ap-toolbar.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ApToolbarComponent
    ],
    imports: [
        CommonModule,
        MyMaterialModule,
        FormsModule,
        RouterModule,
    ],
    exports: [
        ApToolbarComponent,
    ]
})
export class ApToolbarModule { }
