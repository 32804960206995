<ng-template #coffeeEntry let-coffee="coffee" let-editingCoffee="editingCoffee">
    <div class="smallerfont">
        @if (coffee?.toString() === '######' && editingCoffee) {
        <span>{{editingCoffee.label}}</span>
        }
        @if (coffee && coffee.toString() !== '######') {
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
        }
    </div>
    @if (coffee?.label) {
    <div>{{coffee.label}}</div>
    }
</ng-template>

<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>

<ng-template #smallSpinner>
    <mat-spinner diameter="15" strokeWidth="1" class="small-spinner"></mat-spinner>
</ng-template>





@if (!data.transaction) {
<h1 mat-dialog-title i18n="transaction$$add purchase header@@add purchase">Add Purchase</h1>
} @else {
<h1 mat-dialog-title i18n="transaction$$edit purchase header@@edit purchase">Edit Purchase</h1>
}
<mat-dialog-content>
    <!-- <p>Purchase: buy amount of coffee from supplier on date.</p>
  <p>The supplier sends it from externalLocation to the location (a warehouse of the user)</p>
  <p>Inputs: amount, coffee, supplier, date, externalLocation, location</p> -->

    <form class="narrow-form" id="form" name="form" (ngSubmit)="f.form.valid && next()" #f="ngForm">
        @if (!secondPage) {
        <div>
            <div class="right-align">
                <div class="flex-wrap">
                    @if (!addNewCoffeeMode && !editNewCoffeeMode) {
                    <mat-form-field data-test="slct_coffee" subscriptSizing="dynamic"
                        class="form-group left-large">
                        <mat-label i18n="generic$$Beans@@Beans">Beans</mat-label>
                        <mat-select required name="coffee" [hideSingleSelectionIndicator]="true"
                            panelClass="twoLineOption" panelWidth="null"
                            placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                            [(ngModel)]="coffee" (selectionChange)="coffeeChanged()"
                            [compareWith]="utils.compareObjectsFn">
                            <mat-select-trigger class="twoLineOption ellipsed">
                                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coffee, editingCoffee: editingCoffee}"></ng-container>
                            </mat-select-trigger>
                            @if (coffees?.length >= 5) {
                            <mat-option>
                                <ngx-mat-select-search ngModel name="coffeeSelect"
                                    (ngModelChange)="filteredCoffees = utils.filterObjects($event, coffees)"
                                    placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                    noEntriesFoundLabel="">
                                </ngx-mat-select-search>
                            </mat-option>
                            }
                            @if (!coffees) {
                            @if (coffee && coffee.toString() !== '######') {
                            <mat-option [value]="coffee">
                                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coffee, editingCoffee: editingCoffee}"></ng-container>
                            </mat-option>
                            }
                            @if (!coffee) {
                            <mat-option></mat-option>
                            }
                            <mat-option value="######" class="highlight-add-option">
                                @if (editingCoffee?.label && coffee?.toString() === '######') {
                                <span>{{editingCoffee.label}}</span>
                                } @else {
                                <ng-container i18n="transaction$$let user add new beans (by name)@@Add new beans">Add new beans</ng-container>
                                }
                            </mat-option>
                            }
                            <!-- <mat-spinner *ngIf="!coffees; else haveCoffees"></mat-spinner> -->
                            @if (coffees) {
                            <mat-option value="######" class="highlight-add-option">
                                @if (editingCoffee?.label && coffee?.toString() === '######') {
                                <span>{{editingCoffee.label}}</span>
                                } @else {
                                <ng-container i18n="transaction$$let user add new beans (by name)@@Add new beans">Add new beans</ng-container>
                                }
                            </mat-option>
                            @for (coff of filteredCoffees; track coff?._id) {
                            <mat-option [value]="coff">
                                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coff, editingCoffee: editingCoffee}"></ng-container>
                            </mat-option>
                            }
                            }
                        </mat-select>
                    </mat-form-field>
                    }
                    @if (!addNewCoffeeMode && !editNewCoffeeMode) {
                    <button style="margin-top: auto"
                        data-test="bttn_addnewcoffee" matButton class="smallButton" type="button"
                        (click)="addNewCoffee()">
                        <mat-icon style="vertical-align: middle">add</mat-icon>
                    </button>
                    }
                    @if (!coffees && (!coffee || (!addNewCoffeeMode && !editNewCoffeeMode))) {
                    <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
                    }
                </div>
                @if (!addNewCoffeeMode && !editNewCoffeeMode && coffee?.toString() === '######') {
                <button matButton type="button" class="smallButton" i18n="transaction$$button to rename entity@@rename" (click)="editCoffee()">Rename</button>
                }
            </div>
            @if (addNewCoffeeMode || editNewCoffeeMode) {
            <div class="no-space-below flex-wrap">
                <mat-form-field class="form-group">
                    <mat-label i18n="transaction$$let user add new beans (by name)@@Add new beans">Add new beans</mat-label>
                    <input matInput data-test="npt_newcoffee" type="text" name="clabel"
                        placeholder="Add new beans" i18n-placeholder="transaction$$let user add new beans (by name)@@Add new beans"
                        [(ngModel)]="editingCoffee.label">
                </mat-form-field>
                <button data-test="bttn_choosecoffee" matButton class="smallButton"
                    i18n="transaction$$pick beans from list@@pick beans from list" type="button"
                    (click)="closeCoffee()">Choose beans</button>
            </div>
            }
            @if (coffee) {
            <div [ngStyle]="{'margin-top': addNewCoffeeMode || editNewCoffeeMode ? 0 : '20px'}">
                @if (!addNewCoffeeMode && !editNewCoffeeMode && coffee.default_unit?.name) {
                <mat-form-field class="amount-input-ff1">
                    <mat-label i18n="generic$$Amount (of beans, e.g. in kg or bags)@@Amount">Amount</mat-label>
                    <input matInput required data-test="npt_amount" class="amount-input" type="text" name="amount"
                        (change)="checkChangesUnits(this, 'amount', amount, $event.target['value'], true)"
                        [value]="amount | number:'1.0-3'">
                    @if (amount === 1) {
                    <span>{{tr.anslate(coffee.default_unit.name)}}</span>
                    } @else {
                    {{tr.anslate('plural_' + coffee.default_unit.name)}}
                    }
                </mat-form-field>
                }
                <mat-form-field class="amount-input-ff2">
                    <mat-label i18n="transaction$$weight of beans in, e.g. kg@@Weight in {{mainUnit}}">Weight in {{mainUnit}}</mat-label>
                    <input matInput [required]="!coffee.default_unit?.name"
                        data-test="npt_unitamount" class="amount-input" type="text" name="unitamount"
                        (change)="checkChangesUnits(this, 'unitamount', unitamount, $event.target['value'], false)"
                        [value]="unitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'">{{mainUnit}}
                </mat-form-field><br />
                <div class="right-align">
                    <div class="flex-wrap">
                        @if (!addNewSupplierMode && !editNewSupplierMode) {
                        <mat-form-field data-test="slct_supplier" class="form-group left-large select-with-logo">
                            <mat-label i18n="contacts$$supplier@@Supplier">Supplier</mat-label>
                            <mat-select required name="supplier"
                                panelWidth="null" [hideSingleSelectionIndicator]="true"
                                placeholder="Supplier" i18n-placeholder="contacts$$supplier@@Supplier"
                                [(ngModel)]="supplier"
                                (selectionChange)="supplierChanged()"
                                [compareWith]="utils.compareObjectsFn">
                                @if (supplier?.image) {
                                <mat-select-trigger style="display: flex; justify-content: space-between; align-items: center;">
                                    {{supplier.label}}
                                    <img class="dropdown-image" width="55px" height="35px" [src]="'assets/images/suppliers/' + supplier.image + (isDarkmode ? '_dark' : '') + '_70x110.png'"
                                        onerror="this.style.display='none'" (error)="imageLoadError(supplier)" alt="a picture of the supplier">
                                </mat-select-trigger>
                                }
                                @if (!supplier?.image) {
                                <mat-select-trigger>
                                    {{supplier?.label}}
                                </mat-select-trigger>
                                }
                                @if (!suppliers) {
                                @if (supplier && supplier.toString() !== '######') {
                                <mat-option [value]="supplier">
                                    @if (supplier.label) {
                                    <span>{{supplier.label}}</span>
                                    }
                                </mat-option>
                                }
                                @if (!supplier) {
                                <mat-option></mat-option>
                                }
                                <mat-option value="######" class="highlight-add-option">
                                    @if (editingSupplier?.label && supplier?.toString() === '######') {
                                    <span>{{editingSupplier.label}}</span>
                                    } @else {
                                    <ng-container i18n="contacts$$add new supplier option@@add a new supplier">Add new Supplier</ng-container>
                                    }
                                </mat-option>
                                } @else {
                                <mat-option value="######" class="highlight-add-option">
                                    @if (editingSupplier?.label && supplier?.toString() === '######') {
                                    <span>{{editingSupplier.label}}</span>
                                    } @else {
                                    <ng-container i18n="contacts$$add new supplier option@@add a new supplier">Add new Supplier</ng-container>
                                    }
                                </mat-option>
                                @for (supplier of suppliers; track supplier?._id) {
                                <mat-option [value]="supplier">
                                    <!-- <span *ngIf="supplier.label">{{supplier.label}}</span> -->
                                    @if (supplier?.image) {
                                    <div class="dropdown-text-image">
                                        @if (supplier.label) {
                                        <span class="dropdown-text-image-label">{{supplier.label}}</span>
                                        }
                                        <img class="dropdown-image" width="55px" height="35px" [src]="'assets/images/suppliers/' + supplier.image + (isDarkmode ? '_dark' : '') + '_70x110.png'"
                                            onerror="this.style.display='none'" alt="a picture of the supplier">
                                    </div>
                                    }
                                    @if (!supplier?.image && supplier?.label) {
                                    <span>{{supplier.label}}</span>
                                    }
                                </mat-option>
                                }
                                }
                            </mat-select>
                        </mat-form-field>
                        }
                        @if (!suppliers && (!supplier || (!addNewSupplierMode && !editNewSupplierMode))) {
                        <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
                        }
                    </div>
                    @if (!addNewSupplierMode && !editNewSupplierMode && supplier?.toString() === '######') {
                    <button (click)="editSupplier()"
                        i18n="transaction$$button to rename entity@@rename" data-test="bttn_renameSupplier" matButton class="smallButton" type="button">Rename</button>
                    }
                </div>
                @if (addNewSupplierMode || editNewSupplierMode) {
                <div class="no-space-below text-align-right">
                    <mat-form-field class="form-group no-bottom-margin">
                        <mat-label i18n="contacts$$add new supplier option@@add a new supplier">Add new Supplier</mat-label>
                        <input matInput required data-test="npt_supplierLabel" type="text" name="clabel"
                            placeholder="Add new Supplier" i18n-placeholder="contacts$$add new supplier option@@add a new supplier"
                            [(ngModel)]="editingSupplier.label">
                    </mat-form-field>
                    <button (click)="closeSupplier()" data-test="bttn_chooseSupplier"
                        matButton class="smallButton positionright" type="button" i18n="transaction$$pick supplier from list@@pick supplier from list">Choose supplier</button>
                </div>
                }
                <app-ap-datetime [value]="date" [dateRequired]="true" (valueChange)="date=$event"></app-ap-datetime>
                <div class="flex-wrap">
                    <mat-form-field data-test="slct_store" class="left-large">
                        <mat-label i18n="generic$$storage location@@Store">Store</mat-label>
                        <mat-select required name="store"
                            panelClass="twoLineOption" panelWidth="null" [hideSingleSelectionIndicator]="true"
                            placeholder="Target store" i18n-placeholder="transaction$$Target store placeholder@@Target store"
                            [(ngModel)]="store" [compareWith]="utils.compareObjectsFn">
                            @if (store) {
                            <mat-select-trigger class="twoLineOption ellipsed">
                                <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                            </mat-select-trigger>
                            }
                            @if (stores) {
                            @for (store of stores; track store?._id) {
                            <mat-option [value]="store">
                                <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                            </mat-option>
                            }
                            } @else if (store) {
                            <mat-option [value]="store">
                                <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    @if (!stores) {
                    <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
                    }
                </div>
            </div>
            } @else {
            <div>
                @if (coffees && coffees.length === 0) {
                <span i18n="transaction$$no beans to transfer / sell@@no beans">Please add beans first!</span>
                }
            </div>
            @if (coffees?.length) {
            <div i18n="transaction$$need to select some beans before continuing@@select coffee">Please select beans first!</div>
            }
            }
        </div>
        } @else {
        <!-- price and optional details -->
        <mat-form-field>
            <mat-label i18n="transaction$$add an order number@@order_number">Order Number</mat-label>
            <input matInput [disabled]="waiting" type="string" name="order_number"
                placeholder="Order Number" i18n-placeholder="transaction$$add an order number@@order_number"
                [(ngModel)]="order_number" data-test="npt_orderNumber">
        </mat-form-field><br />
        <mat-form-field class="amount-input-ff1" subscriptSizing="dynamic">
            <mat-label i18n="generic$$price spent buying the beans@@total cost">Total cost</mat-label>
            <input matInput required [disabled]="waiting" type="text" value="0"
                data-test="npt_price" name="price" class="amount-input"
                (change)="utils.checkChanges(this, 'price', price, $event.target['value'], doAdd)"
                [value]="price | currency:currency:'symbol-narrow':'1.0-2'">
            /{{unitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'}}{{mainUnit}}
        </mat-form-field>
        <mat-form-field class="amount-input-ff2" subscriptSizing="dynamic">
            <mat-label i18n="generic$$price spent buying the beans per unit@@Cost per">Cost per</mat-label>
            <input matInput required [disabled]="waiting"
                type="text" value="0" pattern="[\-0-9,.]*" inputmode="numeric"
                data-test="npt_pricePerUnit" class="amount-input" name="pricePerUnit"
                (change)="utils.checkChanges(this, 'pricePerUnit', pricePerUnit, $event.target['value'], doAdd)"
                [value]="pricePerUnit | currency:currency:'symbol-narrow':'1.2-2'">
            /{{mainUnitSingular}}<br />
        </mat-form-field>
        @if (lastPurchasePrices) {
        <div data-test="dv_lastPurchasePrice" class="comment" style="margin-top: 0;">
            @if (getLastPurchasePrice(); as lastPrice) {
            {{tr.anslate('Last ' + lastPrice.type.toLowerCase())}}: <span i18n="transaction$$display info on the last transaction@@last purchase/correction price info">{{lastPrice.amount * utils.getUnitFactor(mainUnit) | number:'1.0-2'}}{{mainUnit}} for {{lastPrice.price | currency:currency:'symbol-narrow':'1.0-2'}} on {{lastPrice.date.toLocaleString(DateTime.DATE_MED)}}</span>
            } @else {
            <ng-container i18n="transaction$$no last purchase for those beans@@no last purchase">No previous purchase prices available.</ng-container>
            }
        </div>
        } @else {
        <div class="comment"><span i18n="transaction$$currently loading the last purchase of those beans@@Loading last purchase price">Loading last purchase ...</span></div>
        }
        <mat-form-field>
            <mat-label i18n="transaction$$add a tracking number@@tracking_number">Tracking Number</mat-label>
            <input matInput [disabled]="waiting" type="text" name="tracking_number" data-test="npt_tracking"
                placeholder="Tracking Number" i18n-placeholder="transaction$$add a tracking number@@tracking_number"
                [(ngModel)]="tracking_number">
        </mat-form-field><br />
        <mat-form-field>
            <mat-label i18n="transaction$$add a product id@@product_id">Product ID</mat-label>
            <input matInput [disabled]="waiting" type="text" name="product_id" data-test="npt_productId"
                placeholder="Product ID" i18n-placeholder="transaction$$add a product id@@product_id"
                [(ngModel)]="product_id">
        </mat-form-field><br />
        <mat-form-field>
            <mat-label i18n="transaction$$placeholder, an arbitrary reference string@@reference">Reference</mat-label>
            <input matInput [disabled]="waiting" type="string" name="reference" data-test="npt_reference"
                placeholder="Reference" i18n-placeholder="transaction$$placeholder, an arbitrary reference string@@reference"
                [(ngModel)]="reference">
        </mat-form-field><br />
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if (secondPage) {
    <button (click)="prev()" [disabled]="waiting" data-test="bttn_prev" mat-raised-button class="action-button">
        <mat-icon>navigate_before</mat-icon>
    </button>
    }
    <button mat-button (click)="handleExit(undefined, undefined, true)" data-test="bttn_close" type="button" class="cancel-button">
        <mat-icon>close</mat-icon>
    </button>
    @if (!secondPage) {
    <button (click)="next()" [disabled]="!f.form.valid || waiting || !unitamount" data-test="bttn_next" mat-raised-button class="action-button">
        <mat-icon>navigate_next</mat-icon>
    </button>
    }
    <!-- waiting: {{waiting}} -->
    @if (secondPage) {
    <button [disabled]="waiting || !f.form.valid" ng-submit data-test="bttn_done"
        (click)="add()" mat-raised-button form="form" type="submit" class="action-button spinner-button">
        @if (showWaiting) {
        <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
        } @else {
        <mat-icon>done</mat-icon>
        }
    </button>
    }
</mat-dialog-actions>