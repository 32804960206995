import { User } from './User';
import { RoastReportOpen } from './RoastReportOpen';
import { DateTime } from 'luxon';

export class RoastReport extends RoastReportOpen {
    reconciled ?: boolean;
    reconciledDate ?: DateTime;
    reconciled_by ?: User;
}

