import { AdduserDialogComponent } from './adduser-dialog.component';
import { FormsModule } from '@angular/forms';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { UserSettingsComponent } from 'src/app/modules/account/user-settings.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { ExistingEmailDirective } from './existing-email.directive';
import { DialogModule } from 'src/app/modules/ui/dialog/dialog.module';

@NgModule({
    declarations: [
        UserSettingsComponent,
        AdduserDialogComponent,
        ExistingEmailDirective,
    ],
    imports: [
        CommonModule,
        AccountRoutingModule,
        MyMaterialModule,
        FormsModule,
        DialogModule,
    ],
})
export class AccountModule { }
