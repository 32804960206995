{
    "finalize": "Finalise",
    "Ground color": "Ground colour",
    "Whole color": "Whole colour",
    "flavors": "Flavours",
    "plural_bag": "bags",
    "plural_barrel": "barrels",
    "plural_box": "boxes",
    "(every n>=2) days": "days",
    "(every n>=2) weeks": "weeks",
    "(every n>=2) months": "moenths",
    "(every n>=2) years": "years",
    "Color system": "Colour system",
    "Do you really want to finalize this report and all contained roasts?": "Do you really want to finalise this report and all contained roasts?",
    "Successfully finalized": "Successfully finalised",
    "Finalized": "Finalised",
    "report already reconciled": "report already finalised",
    "could not reconcile the report: {{error}}": "could not finalise the report: {$INTERPOLATION}",
    "cannot update since a related item is already finalized": "cannot update since a related item is already finalised",
    "Reports created here must be consecutive (no overlaps or gaps). They can be finalized for official purposes.": "Reports created here must be consecutive (no overlaps or gaps). They can be finalised for official purposes."
}