import { Customer } from './Customer';
import { StockChange } from './StockChange';
import { Location } from './Location';

export class Sale extends StockChange {
    sales_number ?: string;
    tracking_number ?: string;
    product_id ?: string;
    price ?: number;
    customer ?: Customer;
    externalLocation ?: Location;
}

