import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { HiddenType, StandardService } from 'src/app/util/services/standard.service';
import { Coffee } from 'src/app/models/Coffee';
import { PropertiesType, Utils } from 'src/app/util/utils';
import { ObjectChangedService, ObjectChangedInfo } from 'src/app/util/services/objectchanged.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { throttleTime, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Certification } from 'src/app/models/Certification';
import { Location } from 'src/app/models/Location';
import { Producer } from 'src/app/models/Producer';
import { Variety } from 'src/app/models/Variety';
import { TranslatorService } from 'src/app/util/services/translator.service';
import unionwith from 'lodash-es/unionWith';
import { Supplier } from 'src/app/models/Supplier';
import { PageEvent } from '@angular/material/paginator';
import { Constants } from 'src/app/util/constants';
import cloneDeep from 'lodash-es/cloneDeep';
import { PropertiesService } from 'src/app/util/services/properties.service';

@Component({
    selector: 'app-coffees',
    templateUrl: './coffees.component.html',
    styleUrls: ['./coffees.component.scss'],
    providers: []
})
export class CoffeesComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private standardService: StandardService,
        public tr: TranslatorService,
        private objectChangedService: ObjectChangedService,
        public utils: Utils,
        private propertiesService: PropertiesService,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    // list of objects that are shown (filtered, sorted, sliced for page)
    coffees: Coffee[];
    // list of objects received and held locally; always sorted and fitered but not sliced
    displayCache: Coffee[] = [];
    // list of all objects ever retrieved (in the lifetime of the component)
    cacheBucket: Coffee[] = [];
    // total number of objects in the DB (indendent of current filter); used to check whether everything is cached locally
    objectCount = 0;
    // needed to display (or not) the paginator when switching to show only hidden objects
    maxObjectCount = 0;
    // total number of objects in the DB for the current filter
    filteredObjectCount = 0;

    pageSize = 10;
    pageIndex = 0;

    editNewCoffee = -1;
    isNew = -1;
    idToHighlight: string;

    sortValue: string;
    lastSortValue: string;
    firstSort = true;
    inverse = false;

    currentUser: UserType;
    readOnly = false;
    loadSubscription = new Subject<string>();
    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();

    allCertifications: Certification[];
    fields: Location[];
    stores: Location[];
    showstockfrom: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';
    producers: Producer[];
    suppliers: Supplier[];
    allVarietals: Variety[][];
    allVarietalCategories: string[] = []; // ok to init with []; template uses allVarietals
    // label is translated cat ('Schatten'), value is actual value ('Forest')
    properties: PropertiesType;

    filter = '';
    filterChanged: Subject<string> = new Subject<string>();
    gettingForFilter = false;
    gettingForFilterTimer: ReturnType<typeof setTimeout>;
    showHidden: HiddenType = 'false';
    hiddenButtonInactive = false;
    helptipHiddenShown = false;
    showHelptipHidden = true;

    showOrganic: 'on' | 'off' | '';
    organicButtonInactive = false;
    helptipOrganicShown = false;
    showHelptipOrganic = true;
    isDarkmode = false;

    anyCoffeeHasStock = true;
    firstCallToAnyCoffeeHasStock = true;

    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        this.pageSize = this.userService.getPageSize('coffees', this.pageSize);
        this.helptipHiddenShown = !!(this.currentUser.hts & Enumerations.HELPTIP.HIDDENBEANS);
        this.helptipOrganicShown = !!(this.currentUser.hts & Enumerations.HELPTIP.ORGANICBEANS);
        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        // directly read ssf; cannot use this.utils.readShowStockFrom bc we don't have this.stores yet
        const ssf = localStorage.getItem('stockfrom_' + this.currentUser.user_id);
        if (ssf === 'all') {
            this.showstockfrom = 'all';
        } else if (ssf) {
            this.showstockfrom = ssf.split(Constants.SSF_SEPARATOR).map(s => { return { _id: s, label: '' }; });
        }

        this.objectChangedService.changed$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(obj => this.objectChanged(obj));

        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.sortValue = 'lastmodified';
                this.lastSortValue = 'lastmodified';

                this.displayCache.length = 0;
                this.cacheBucket.length = 0;

                // make sure those are re(pre)loaded as well:
                this.allCertifications = undefined;
                this.fields = undefined;
                this.stores = undefined;
                this.producers = undefined;
                this.suppliers = undefined;
                this.allVarietals = undefined;
                this.allVarietalCategories = undefined;
                this.properties = undefined;
                this.editNewCoffee = -1;
                this.isNew = -1;
                this.pageIndex = 0;

                this.idToHighlight = this.route.snapshot.params.id;
                if (this.idToHighlight) {
                    // have a direct link; need to get the corresponding page
                    this.getPageForId(this.idToHighlight);
                } else {
                    this.filter = '';
                    this.getAllPaged(this.pageSize, 0);
                }
            }
            );

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && e.url.indexOf('coffees') >= 0) {
                    // only pass and debounce interesting events
                    this.loadSubscription.next('reload');
                }
            });

        this.filterChanged.pipe(
            debounceTime(1250),
            distinctUntilChanged())
            .subscribe(val => this.filterCoffees(val));

        this.sortValue = 'lastmodified';
        this.lastSortValue = 'lastmodified';

        if (this.currentUser) {
            this.loadSubscription.next('init');
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.gettingForFilterTimer);
    }

    /**
     * Calculates total stock of all objects in cacheBucket and stores it in .curStock
     * @param overwrite recalculate and save even if .curStock already exists
     * @param ssf current showstockfrom setting
     */
    private calcStockForSortAndFilter(overwrite = false, ssf: { _id: string, hr_id?: string, label?: string }[] | 'all' = this.showstockfrom) {
        // set .curStock to the sum of stock (taking showstockfrom into account)
        // used for sorting and filtering
        // usually set by each coffee.component; missing when just received all coffees
        if (ssf === 'all') {
            for (const coff of this.cacheBucket) {
                if (coff && (overwrite || typeof coff.curStock === 'undefined')) {
                    coff.curStock = coff.stock ? coff.stock.reduce((prev, curr) => prev + (curr.amount || 0), 0) || 0 : 0;
                }
            }
        } else {
            const filteredIds = (ssf || []).map(s => s._id);
            for (const coff of this.cacheBucket) {
                if (coff && (overwrite || typeof coff.curStock === 'undefined')) {
                    coff.curStock = coff.stock ? coff.stock.reduce((prev, curr) => {
                        if (filteredIds.indexOf((curr.location._id || curr.location).toString()) >= 0) {
                            return prev + (curr.amount || 0);
                        } else {
                            return prev;
                        }
                    }, 0) || 0 : 0;
                }
            }
        }
    }

    private updateAnyCoffeeHasStock() {
        if (!this.coffees?.length) {
            this.anyCoffeeHasStock = false;
            return;
        }

        // necessary to have the coffee components calc .curStock
        // could also always call calcStockForSortAndFilter
        setTimeout(() => {
            if (this.coffees.some(cof => typeof cof.curStock === 'undefined')) {
                this.calcStockForSortAndFilter();
            }

            this.anyCoffeeHasStock = true;
            for (const coffee of this.coffees) {
                if (coffee.curStock) {
                    return;
                }
            }
            this.anyCoffeeHasStock = false;
        }, 10);
    }

    /**
     * Called when the filter input is changed. Calls doFilter and, if necessary, getAllPaged.
     * Resets paging to 0 (first page).
     * @param filter 
     */
    private filterCoffees(filter: string) {
        this.pageIndex = 0;
        if (typeof filter !== 'string') {
            return;
        }
        filter = filter.trim();
        const countCached = this.countCacheBucket();

        // could maybe fall back to displayCache if !filter and sort order is still "lastchanged"
        // but if the last filter request added objects to the displayCache, we cannot know whether
        // the objects had been there before or not; => only optimise for countCached === this.objectCount (all cached)

        if (!filter && countCached === this.objectCount) {
            // cleared filter and have all in cache; cacheBucket should be correctly sorted
            this.filteredObjectCount = this.objectCount;
            this.displayCache = this.cacheBucket.slice();
            this.coffees = this.cacheBucket.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));
            this.updateAnyCoffeeHasStock();
        } else {
            if (countCached === this.objectCount) {
                this.doFilter(filter);
                this.displayCache = this.coffees.slice();
                this.coffees = this.coffees.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));
                this.updateAnyCoffeeHasStock();
            } else {
                // show all that are currently in the cache
                this.doFilter(filter);
                // this.coffees now holds all (cached) objects that match the filter; save for paging
                this.displayCache = this.coffees.slice();

                // this will be done in getAllPaged as well; here to avoid flickering since all objects will be briefly displayed
                this.coffees = this.coffees.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));
                this.updateAnyCoffeeHasStock();
                // retrieve server-side filtered objects
                this.filteredObjectCount = -1;
                this.getAllPaged(this.pageSize, this.pageIndex, this.sortValue, this.inverse, this.showstockfrom, filter);
            }
        }
        this.filter = filter;
    }

    /**
     * Filters _cached_ objects from cacheBucket (into this.coffees). Does _not_ slice for paging.
     * @param filter the filter string
     * @param recalcStock if true calculate .curStock (does not overwrite existing .curStock entries)
     */
    private doFilter(filter: string, recalcStock = true) {
        // this.logger.debug('filtering with ' + this.filter);

        if (recalcStock) {
            this.calcStockForSortAndFilter(false, this.showstockfrom);
        }

        this.coffees = this.cacheBucket.filter(c => {
            let inverse = false;
            let capFilter = filter.toUpperCase();
            if (filter.substring(0, 1) === '-') {
                inverse = true;
                capFilter = filter.substring(1).toUpperCase();
            }
            if (capFilter.substring(0, 1) === 'C' && !isNaN(Number.parseInt(capFilter.substring(1), 10))) {
                return c.internal_hr_id === Number.parseInt(capFilter.substring(1), 10) ? !inverse : inverse;
            }
            if (capFilter.substring(0, 1) === '>' || capFilter.substring(0, 1) === '<') {
                const numStr = capFilter.substring(1);
                // this relies on each coffee.component to calculate the curStock
                return (this.filterByPropertyAndNumber(Number.parseFloat(numStr), capFilter, c.curStock) ? !inverse : inverse);
            }
            if (capFilter.startsWith('ORIGIN:')) {
                const cfilter = capFilter.substring('ORIGIN:'.length).trim();
                if (!c.origin) {
                    return false;
                }
                const origin = typeof c.origin['origin'] !== 'undefined' ? c.origin['origin'] : c.origin;
                return (origin && this.tr.anslate(origin).toUpperCase().indexOf(cfilter) >= 0
                    || c.origin_region && this.tr.anslate(c.origin_region).toUpperCase().indexOf(cfilter) >= 0) ? !inverse : inverse;

            } else if (capFilter.startsWith('STOCK:')) {
                const cfilter = capFilter.substring('STOCK:'.length).trim();
                const numStr = cfilter.substring(cfilter.search(/[<>]/) + 1);
                return this.filterByPropertyAndNumber(Number.parseFloat(numStr), cfilter, c.curStock) ? !inverse : inverse;

            } else if (capFilter.startsWith('FLAVOR:')) {
                const cfilter = capFilter.substring('FLAVOR:'.length).trim();
                if (!c.flavors?.length) {
                    return false;
                }
                for (const flavor of c.flavors) {
                    const flavorStr = flavor?.trim()?.toUpperCase();
                    if (flavorStr === cfilter) {
                        return !inverse;
                    }
                }
                return inverse;

            } else if (capFilter.startsWith('USER:')) {
                const cfilter = capFilter.substring('USER:'.length).trim();
                return (c.created_by && c.created_by.nickname && c.created_by.nickname.toUpperCase() === cfilter) ? !inverse : inverse;
            }

            const numdot = capFilter.replace(/,/g, '.');
            const num = parseFloat(numdot);
            if (!Number.isNaN(num)) {
                return this.filterByNumber(c, num, capFilter) ? !inverse : inverse;
            }

            // filter is not a number
            const origin = c.origin && typeof c.origin['origin'] !== 'undefined' ? c.origin['origin'] : c.origin;
            return ((c.label && c.label.toUpperCase().indexOf(capFilter) >= 0) || (c.hr_id && c.hr_id.toUpperCase() === capFilter))
                || (origin && this.tr.anslate(origin).toUpperCase().indexOf(capFilter) >= 0)
                || (c.origin_region && this.tr.anslate(c.origin_region).toUpperCase().indexOf(capFilter) >= 0)
                || (c.notes && c.notes.indexOf(capFilter) >= 0)
                ? !inverse : inverse;
        });

        this.filteredObjectCount = this.coffees.length;
        // this.coffees = this.coffees.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));

        // // reset paging to avoid being on a page that doesn't exist after filtering
        // this.pageIndex = 0;
    }

    private filterByPropertyAndNumber(filterNum: number, capFilter: string, toCheck: number): boolean {
        const origFilterNum = filterNum;
        filterNum = this.utils.convertToKg(filterNum, this.currentUser?.unit_system);
        if (capFilter.substring(0, 1) === '>') {
            return toCheck > filterNum;

        } else if (capFilter.substring(0, 1) === '<') {
            return toCheck < filterNum;

        } else if (filterNum === 0 && (capFilter.indexOf('.') >= 0 || capFilter.indexOf(',') >= 0)) {
            return !toCheck;

        } else if (Math.round(origFilterNum) === origFilterNum && capFilter.indexOf('.') < 0 && capFilter.indexOf(',') < 0) {
            // integer number: get all with equal _rounded_ amount
            return Math.round(this.utils.convertToUserUnit(toCheck, this.currentUser?.unit_system)) === origFilterNum;

        } else {
            // float number: get all with equal amount truncated to same digits
            const endNum = capFilter.search(/[^-,.\d]/);
            let svall = capFilter.replace(/,/g, '.');
            svall = svall.replace(/[^-\d.]/g, '');
            const digits = svall.length - svall.indexOf('.') - 1;
            const numstr = svall.substring(0, endNum < 0 ? capFilter.length : endNum);
            const cToCheck = this.utils.convertToUserUnit(toCheck, this.currentUser?.unit_system);
            return Math.trunc(cToCheck) === Math.trunc(origFilterNum) &&
                cToCheck && cToCheck.toFixed(digits).substring(0, numstr.length) === numstr;
        }
    }

    private filterByNumber(coffee: Coffee, filterNum: number, capFilter: string): boolean {
        // need to do this before converting the number according to the user's unit_system
        if (coffee.internal_hr_id === filterNum) {
            return true;
        }
        if (coffee.crop_date?.landed && coffee.crop_date.landed[0] === filterNum || coffee.crop_date?.picked && coffee.crop_date.picked[0] === filterNum) {
            return true;
        }
        const origFilterNum = filterNum;
        filterNum = this.utils.convertToKg(filterNum, this.currentUser?.unit_system);
        if (Math.round(origFilterNum) === origFilterNum && capFilter.indexOf('.') < 0 && capFilter.indexOf(',') < 0) {
            // integer number: get all with equal _rounded_ stock (or hr_id, see above)
            return Math.round(this.utils.convertToUserUnit(coffee.curStock, this.currentUser?.unit_system)) === origFilterNum;

        } else {
            // float number: get all with equal stock truncated to same digits
            const endNum = capFilter.search(/[^-,.\d]/);
            let svall = capFilter.replace(/,/g, '.');
            svall = svall.replace(/[^-\d.]/g, '');
            const digits = svall.length - svall.indexOf('.') - 1;
            const numstr = svall.substring(0, endNum < 0 ? capFilter.length : endNum);
            const cStock = this.utils.convertToUserUnit(coffee.curStock, this.currentUser?.unit_system);
            return Math.trunc(cStock) === Math.trunc(origFilterNum) && cStock && cStock.toFixed(digits).substring(0, numstr.length) === numstr;
        }
    }

    /**
     * Appends the given objects that are not already in the cache to the local cache.
     * @param coffees objects to merge into the local cache
     */
    private mergeWithCache(coffees: Coffee[], alsoCurrentDisplayCache = false) {
        this.cacheBucket = unionwith(this.cacheBucket, coffees, this.utils.compareObjectsFn) as Coffee[];
        if (this.sortValue === 'stock') {
            this.calcStockForSortAndFilter(false, this.showstockfrom);
        }
        this.sort(this.cacheBucket, this.sortValue, this.inverse);
        if (alsoCurrentDisplayCache) {
            this.displayCache = unionwith(this.displayCache, coffees, this.utils.compareObjectsFn) as Coffee[];
            this.sort(this.displayCache, this.sortValue, this.inverse);
        }
    }

    private preLoadPropertiesForEditing(): void {
        // pre-load all the other infos necessary for editing
        if (!this.producers) {
            this.standardService.getAll<Producer>('producers')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.producers = response.result;
                        } else {
                            this.utils.handleError('Are you connected to the Internet?', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Are you connected to the Internet?', error);
                    }
                });
        }
        if (!this.suppliers) {
            this.standardService.getAll<Supplier>('suppliers')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.suppliers = response.result;
                        } else {
                            this.utils.handleError('Are you connected to the Internet?', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Are you connected to the Internet?', error);
                    }
                });
        }
        if (!this.fields || !this.stores) {
            this.standardService.getAll<Location>('locations')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            // TODO optimize: only retrieve places of types FIELD or STORE
                            const places = response.result as Location[];
                            this.fields = places.filter(p => (p.type || p['__t'] || '').indexOf(Enumerations.LocationTypes.FIELD) >= 0);
                            this.fields.sort((p1, p2) => p1.internal_hr_id - p2.internal_hr_id);
                            this.stores = places.filter(p => (p.type || p['__t'] || '').indexOf(Enumerations.LocationTypes.STORE) >= 0);
                            this.stores.sort((p1, p2) => p2.internal_hr_id - p1.internal_hr_id);
                            if (!this.stores?.length) {
                                this.stores = [];
                            }
                            this.showstockfrom = this.utils.readShowStockFrom(this.stores, this.currentUser);
                        } else {
                            this.utils.handleError('Are you connected to the Internet?', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Are you connected to the Internet?', error);
                    }
                });
        }

        if (!this.allCertifications) {
            this.propertiesService.getCertifications()
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            response.result.sort((c1, c2) => {
                                if (c1.label < c2.label) { return -1; }
                                if (c1.label > c2.label) { return 1; }
                                return 0;
                            });
                            this.allCertifications = response.result;
                        } else {
                            this.utils.handleError('Are you connected to the Internet?', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Are you connected to the Internet?', error);
                    }
                });
        }

        if (!this.allVarietals || !this.allVarietalCategories) {
            this.propertiesService.getVerietals()
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.allVarietalCategories = [];
                            this.allVarietals = [];
                            const vars = response.result;
                            for (const varietal of vars) {
                                let idx = this.allVarietalCategories.indexOf(varietal.category);
                                if (idx < 0) {
                                    idx = this.allVarietalCategories.length;
                                    this.allVarietalCategories.push(varietal.category);
                                }
                                if (typeof this.allVarietals[idx] === 'undefined') {
                                    this.allVarietals[idx] = [];
                                }
                                this.allVarietals[idx].push(varietal);
                            }
                        } else {
                            this.utils.handleError('Are you connected to the Internet?', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Are you connected to the Internet?', error);
                    }
                });
        };

        if (!this.properties) {
            this.propertiesService.getProperties(['coffee', 'producer'], undefined)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.properties = this.utils.convertLoadedProps(response.result);
                        } else {
                            this.utils.handleError('Are you connected to the Internet?', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Are you connected to the Internet?', error);
                    }
                });
        }
    }

    /**
     * Main method to retrieve the list of objects to show based on filter, showstockfrom, sort, page.
     * First tries to use displayCache / bucketCache and clientSideSort if possible.
     * Preloads previous / next page using preLoadObjects.
     * Takes care of paging.
     * @param pageSize current page size
     * @param pageIndex current page index
     * @param sortValue current sort order (could be different to lastSortValue)
     * @param inverse current sort inverse (could be different to last this.inverse)
     * @param ssf current showstockfrom (could be different to last this.showstockfrom)
     * @param filter current filter (could be different to last this.filter)
     * @param showHidden whether only hidden should be displayed ('only'), hidden should also be displayed ('true') or no hidden should be dispayed ('false') (could be different to last this.filter)
     */
    private getAllPaged(pageSize: number = this.pageSize, pageIndex: number = this.pageIndex,
        sortValue: string = this.sortValue, inverse: boolean = this.inverse,
        ssf: { _id: string, hr_id?: string, label?: string }[] | 'all' = this.showstockfrom,
        filter: string = this.filter,
        showHidden = this.showHidden,
        showOrganic: 'on' | 'off' | '' = this.showOrganic) {

        if (ssf !== 'all' && ssf.length === this.stores?.length) {
            ssf = 'all';
        }

        if (showHidden !== this.showHidden || showOrganic !== this.showOrganic) {
            // need to reload
            this.gettingForFilterTimer = setTimeout(() => {
                this.gettingForFilter = true;
            }, 600);
            this.standardService.getPage<Coffee>('coffees', pageSize, pageIndex, sortValue, inverse, ssf, filter, showHidden, showOrganic)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        this.readOnly = this.userService.isReadOnly();
                        clearTimeout(this.gettingForFilterTimer);
                        this.gettingForFilter = false;
                        if (response.success === true) {
                            this.coffees = response.result;
                            this.objectCount = response.count;
                            this.filteredObjectCount = response.count;
                            this.maxObjectCount = Math.max(this.maxObjectCount, this.objectCount);

                            // don't mix hidden and non-hidden objects in the cacheBucket, clear it
                            this.cacheBucket.length = 0;
                            this.mergeWithCache(response.result);

                            // clear displayed cache and insert at current page
                            // this.displayCache = this.coffees.slice();
                            this.displayCache.length = 0;
                            for (let i = 0; i < this.coffees.length; i++) {
                                this.displayCache[pageSize * pageIndex + i] = this.coffees[i];
                            }

                            if (this.filteredObjectCount > pageSize) {
                                this.preLoadObjects(pageSize, pageIndex, sortValue, inverse, ssf, filter, showHidden, showOrganic, true);
                            }
                            this.updateAnyCoffeeHasStock();

                        } else {
                            this.utils.handleError('error retrieving all beans', response.error);
                        }
                        this.showHidden = showHidden;
                        this.showOrganic = showOrganic;
                        this.organicButtonInactive = false;
                        this.hiddenButtonInactive = false;
                    },
                    error: error => {
                        this.utils.handleError('error retrieving all beans', error);
                        clearTimeout(this.gettingForFilterTimer);
                        this.gettingForFilter = false;
                        this.showHidden = showHidden;
                        this.showOrganic = showOrganic;
                        this.organicButtonInactive = false;
                        this.hiddenButtonInactive = false;
                    }
                });
            return;
        }
        this.showOrganic = showOrganic;

        // 1) check cache first

        const totalCached = this.countCacheBucket();
        const currentCached = this.countCurrentCached();
        // check: have all with current filter in cache
        if (this.filteredObjectCount > 0 && this.filter === filter
            && ((filter && currentCached === this.filteredObjectCount) || (!filter && totalCached === this.objectCount))
            && (this.lastSortValue !== this.sortValue || this.inverse !== inverse
                || (this.sortValue === 'stock' && ((this.showstockfrom === 'all' && ssf !== 'all') || (this.showstockfrom !== 'all' && ssf === 'all')
                    || this.showstockfrom.length !== ssf.length)))) {
            // _same_ filter but different sort order and have all in cache => sort (and filter) on cached objects
            this.clientSideFilterAndSort(sortValue, inverse, ssf, pageSize, pageIndex, filter);
            return;
        }

        // check: different filter but have all in cache
        if (totalCached === this.objectCount
            && (this.filter !== filter || this.lastSortValue !== this.sortValue || this.inverse !== inverse
                || (this.sortValue === 'stock' && ((this.showstockfrom === 'all' && ssf !== 'all') || (this.showstockfrom !== 'all' && ssf === 'all')
                    || this.showstockfrom.length !== ssf.length)))) {
            // new filter or new sort order but have all in cache => sort and filter on cached objects
            this.clientSideFilterAndSort(sortValue, inverse, ssf, pageSize, pageIndex, filter);
            return;
        }

        // check: have all in cache and same filter and sort order
        if (totalCached > 0 && totalCached === this.objectCount) {
            // slice from cache
            if (filter) {
                this.doFilter(filter, false);
                this.coffees = this.coffees.slice(pageSize * pageIndex, pageSize * (pageIndex + 1));
            } else {
                this.coffees = this.cacheBucket.slice(pageSize * pageIndex, pageSize * (pageIndex + 1));
            }
            this.updateAnyCoffeeHasStock();
            return;
        }

        const firstIndex = pageSize * pageIndex;
        const lastIndex = Math.min(pageSize * (pageIndex + 1) - 1, this.filteredObjectCount - 1);

        // check: switched to new page which is in cache
        if (this.filteredObjectCount > 0
            && this.filter === filter
            && (this.lastSortValue === this.sortValue || (this.firstSort && this.lastSortValue === 'lastmodified' && !this.sortValue)) && this.inverse === inverse
            && (this.sortValue !== 'stock' || (this.sortValue === 'stock' && ((this.showstockfrom === 'all' && ssf === 'all') || (Array.isArray(this.showstockfrom) && Array.isArray(ssf) && this.showstockfrom.length === ssf.length))))
            && this.displayCache[firstIndex] && this.displayCache[lastIndex]) {
            // have objects in cache and sort order and filter didn't change
            // also showstockfrom has no impact (sort not "stock") or has not changed
            // basically (if at all) paging changed => use cached objects

            // displayCache hold currently filtered and sorted objects
            this.coffees = this.displayCache.slice(pageSize * pageIndex, pageSize * (pageIndex + 1));
            this.updateAnyCoffeeHasStock();

            // // cannot just slice since the filter must be applied
            // if (!filter) {
            //     this.coffees = this.displayCache.slice(pageSize * pageIndex, pageSize * (pageIndex + 1));
            // this.updateAnyCoffeeHasStock();
            // } else {
            //     this.clientSideFilterAndSort(sortValue, inverse, ssf, pageSize, pageIndex, filter);
            // }
            this.preLoadObjects(pageSize, pageIndex, sortValue, inverse, ssf, filter, showHidden);
            return;
        }

        // 2) cache is not sufficient, need to retrieve from server

        this.gettingForFilterTimer = setTimeout(() => {
            this.gettingForFilter = true;
        }, 600);
        this.standardService.getPage<Coffee>('coffees', pageSize, pageIndex, sortValue, inverse, ssf, filter, showHidden, showOrganic)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.readOnly = this.userService.isReadOnly();
                    clearTimeout(this.gettingForFilterTimer);
                    this.gettingForFilter = false;
                    if (response.success === true) {
                        if (!response.result?.length && this.objectCount === 0 && !this.filter && !this.readOnly && this.showHidden !== 'only' && this.showHidden !== 'true' && !showOrganic) {
                            // have no coffees and not only a readonly viewer
                            this.gettingForFilter = false;
                            this.router.navigate(['/wizard']);
                            return;
                        }

                        this.preLoadPropertiesForEditing();

                        this.coffees = response.result;
                        if (!filter) {
                            this.objectCount = response.count;
                            this.maxObjectCount = Math.max(this.maxObjectCount, this.objectCount);
                        } else if (this.objectCount < 0) {
                            // happens if a filter is active and new beans are added with initial stock
                            // the objectCount is set to -1 to avoid using (or emptying) the cache
                            this.objectCount = response.count;
                        }
                        this.filteredObjectCount = response.count;

                        this.mergeWithCache(response.result);
                        this.updateAnyCoffeeHasStock();

                        // clear displayed cache and insert at current page
                        // this.displayCache = this.coffees.slice();
                        this.displayCache.length = 0;
                        for (let i = 0; i < this.coffees.length; i++) {
                            this.displayCache[pageSize * pageIndex + i] = this.coffees[i];
                        }

                        // clear displayed cache and insert at current page
                        // this.displayCache.length = 0;
                        // this.displayCache.splice(pageSize * pageIndex, 0, ...response.result);

                        // // insert or replace retrieved objects in the local cache
                        // if (this.displayCache.length < pageSize * pageIndex) {
                        //     let cnt = 0;
                        //     for (let i = pageSize * pageIndex; i < pageSize * pageIndex + this.coffees.length; i++) {
                        //         this.displayCache[i] = this.coffees[cnt++];
                        //     }
                        // } else {
                        //     this.displayCache.splice(pageSize * pageIndex, this.coffees.length, ...this.coffees);
                        // }

                        if (this.filteredObjectCount > pageSize) {
                            this.preLoadObjects(pageSize, pageIndex, sortValue, inverse, ssf, filter, showHidden, showOrganic, true);
                        }

                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                    clearTimeout(this.gettingForFilterTimer);
                    this.gettingForFilter = false;
                }
            });
    }

    private doStuffAfterLoading(response: { success: boolean, result: { objects: Coffee[], pageIndex: number }, count: number, error: string }) {
        this.preLoadPropertiesForEditing();

        this.coffees = response.result.objects;
        this.pageIndex = response.result.pageIndex;

        this.mergeWithCache(response.result.objects);
        this.updateAnyCoffeeHasStock();

        // clear displayed cache and insert at current page
        // this.displayCache = this.coffees.slice();
        this.displayCache.length = 0;
        for (let i = 0; i < this.coffees.length; i++) {
            this.displayCache[this.pageSize * this.pageIndex + i] = this.coffees[i];
        }

        if (!this.filter) {
            this.objectCount = response.count;
            this.maxObjectCount = Math.max(this.maxObjectCount, this.objectCount);
        }
        this.filteredObjectCount = response.count;

        // if (this.objectCount !== response.count) {
        //     // something changed, clear the cache
        //     this.displayCache.length = 0;
        // }
        // // insert or replace retrieved objects in the local cache
        // if (this.displayCache.length < this.pageSize * this.pageIndex) {
        //     let cnt = 0;
        //     for (let i = this.pageSize * this.pageIndex; i < this.pageSize * this.pageIndex + this.coffees.length; i++) {
        //         this.displayCache[i] = this.coffees[cnt++];
        //     }
        // } else {
        //     this.displayCache.splice(this.pageSize * this.pageIndex, this.coffees.length, ...this.coffees);
        // }

        this.preLoadObjects(this.pageSize, this.pageIndex, this.sortValue, this.inverse, this.showstockfrom, this.filter, this.showHidden);
    }

    /**
     * Look for the given id and call getAllPaged if found in the cache or getPageForId
     * to retrieve the page from the server.
     * @param id "C1234" or _id
     */
    private getPageForId(id: string) {
        // don't check cache since it will be empty (linked only from other pages)
        this.standardService.getPageForId<Coffee>('coffees', this.pageSize, id, this.sortValue, this.inverse, this.showstockfrom, this.filter, this.showHidden)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.readOnly = this.userService.isReadOnly();
                    if (response.success === true) {
                        if (!response.result?.objects?.length) {
                            // don't go to wizard if none found here
                            // try the inverse "this.showHidden !== 'false'" (should use true in getPageForId)
                            const myShowHidden = (this.showHidden === 'only' || this.showHidden === 'true') ? 'false' : 'only';
                            this.standardService.getPageForId<Coffee>('coffees', this.pageSize, id, this.sortValue, this.inverse, this.showstockfrom, this.filter, myShowHidden)
                                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                .subscribe({
                                    next: response2 => {
                                        this.readOnly = this.userService.isReadOnly();
                                        if (response2.success === true) {
                                            if (!response2.result?.objects?.length) {
                                                // specified coffee not found, show empty list and corresponding message
                                                this.coffees = [];
                                                this.pageIndex = 0;
                                                this.displayCache.length = 0;
                                                this.objectCount = 0;
                                                this.filteredObjectCount = 0;

                                                return;
                                            }
                                            this.showHidden = (this.showHidden === 'only' || this.showHidden === 'true') ? 'false' : 'only';
                                            this.doStuffAfterLoading(response2);
                                        } else {
                                            this.utils.handleError('error retrieving all beans', response2.error);
                                        }
                                    },
                                    error: error => {
                                        this.utils.handleError('error retrieving all beans', error);
                                    }
                                });
                        } else {
                            this.doStuffAfterLoading(response);
                        }
                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                }
            });
    }

    private countCacheBucket(): number {
        return this.cacheBucket.reduce((prev, cur) => prev + (cur ? 1 : 0), 0);
    }

    private countCurrentCached(): number {
        return this.displayCache.reduce((prev, cur) => prev + (cur ? 1 : 0), 0);
    }

    /**
     * If total number of objects is max Constants.DOWNLOADALL_THRESHOLD, loads all from server.
     * Otherwise preloads the previous / next pages (if possible and not already in the cache)
     * @param pageSize 
     * @param pageIndex 
     * @param sortValue 
     * @param inverse 
     * @param ssf 
     */
    private preLoadObjects(
        pageSize: number, pageIndex: number,
        sortValue: string, inverse: boolean,
        ssf: { _id: string, hr_id?: string, label?: string }[] | 'all',
        filter: string,
        showHidden: HiddenType = this.showHidden,
        showOrganic: 'on' | 'off' | '' = this.showOrganic,
        forcePreLoad = false) {
        const countCached = this.countCacheBucket();
        if (!forcePreLoad
            && (this.filteredObjectCount === 0 || (!filter && countCached === this.objectCount) || (filter && countCached >= this.filteredObjectCount))) {
            // have all in cache, nothing to do
            return;
        }

        if (this.filteredObjectCount <= Constants.DOWNLOADALL_THRESHOLD) {
            // get all at once in the background
            this.standardService.getPage<Coffee>('coffees', Constants.DOWNLOADALL_THRESHOLD, 0, sortValue, inverse, ssf, filter, showHidden, showOrganic)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        this.readOnly = this.userService.isReadOnly();
                        if (response.success === true) {
                            this.mergeWithCache(response.result, true);
                            if (this.filteredObjectCount !== response.count) {
                                // something changed in the meantime
                                this.clientSideFilterAndSort(sortValue, inverse, ssf, pageSize, pageIndex, filter);
                                if (!filter) {
                                    this.objectCount = response.count;
                                    this.maxObjectCount = Math.max(this.maxObjectCount, this.objectCount);
                                }
                                this.filteredObjectCount = response.count;
                            }
                        }
                    }
                });

        } else {
            // get at least the next / previous page; make sure it can be cancelled
            let firstIndex = pageSize * (pageIndex + 1);
            let lastIndex = Math.min(pageSize * (pageIndex + 2) - 1, this.objectCount - 1);

            // check displayCache (not cacheBucket) to check if the age is cached
            if (firstIndex < this.filteredObjectCount - 1 &&
                (!this.displayCache[firstIndex] || !this.displayCache[lastIndex])) {

                // there is a next page and it is not in the cache
                this.standardService.getPage<Coffee>('coffees', pageSize, pageIndex + 1, sortValue, inverse, ssf, filter, showHidden, showOrganic)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.displayCache.splice(pageSize * (pageIndex + 1), response.result.length, ...response.result);
                                this.mergeWithCache(response.result, false);

                                if (this.filteredObjectCount !== response.count) {
                                    // something changed in the meantime, reload the current page
                                    this.standardService.getPage<Coffee>('coffees', pageSize, pageIndex, sortValue, inverse, ssf, filter, showHidden, showOrganic)
                                        .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                        .subscribe({
                                            next: response2 => {
                                                if (response2.success === true) {
                                                    this.displayCache.splice(pageSize * pageIndex, response2.result.length, ...response2.result);
                                                    this.mergeWithCache(response2.result, false);
                                                    if (!filter) {
                                                        this.objectCount = response2.count;
                                                        this.maxObjectCount = Math.max(this.maxObjectCount, this.objectCount);
                                                    }
                                                    this.filteredObjectCount = response2.count;
                                                }
                                            }
                                        });
                                }
                            }
                        }
                    });
            }

            firstIndex = pageSize * (pageIndex - 1);
            lastIndex = Math.min(pageSize * pageIndex, this.filteredObjectCount - 1);

            if (pageIndex > 0 &&
                (!this.displayCache[firstIndex] || !this.displayCache[lastIndex])) {
                // there is a previous page and it is not in the cache
                this.standardService.getPage<Coffee>('coffees', pageSize, pageIndex - 1, sortValue, inverse, ssf, filter, showHidden, showOrganic)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.displayCache.splice(pageSize * (pageIndex - 1), response.result.length, ...response.result);
                                this.mergeWithCache(response.result, false);

                                if (this.filteredObjectCount !== response.count) {
                                    // something changed in the meantime, reload the current page
                                    this.standardService.getPage<Coffee>('coffees', pageSize, pageIndex, sortValue, inverse, ssf, filter, showHidden, showOrganic)
                                        .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                        .subscribe({
                                            next: response2 => {
                                                if (response2.success === true) {
                                                    this.displayCache.splice(pageSize * pageIndex, response2.result.length, ...response2.result);
                                                    this.mergeWithCache(response2.result, false);
                                                    if (!filter) {
                                                        this.objectCount = response2.count;
                                                        this.maxObjectCount = Math.max(this.maxObjectCount, this.objectCount);
                                                    }
                                                    this.filteredObjectCount = response2.count;
                                                }
                                            }
                                        });
                                }
                            }
                        }
                    });
            }
        }
    }


    private getCountryOfOrigin(origin: string): string {
        if (!origin || !this.properties) {
            return '';
        } else {
            const procs = this.properties['Origin'];
            for (const proc of procs) {
                if (proc.value === origin) {
                    if (proc.label?.indexOf('(') >= 0) {
                        const originPlusCountry = proc.label as string; // Asia#Bali (Indonesia)
                        const c = originPlusCountry.match(/.*\((.*)\)/);
                        return c && c[1];
                    }
                    return '';
                }
            }
            return '';
        }
    }

    /**
     * Sorts the given list of objects
     * @param objects the objects to sort (in place)
     * @param sortValue 
     * @param inverse 
     */
    private sort(objects: Coffee[], sortValue: string, inverse: boolean) {
        const inversenum = inverse ? 2 : 0;
        objects.sort((c1, c2) => {
            if (!c1) return c2 ? 1 : 0;
            if (!c2) return -1;
            let diff = 0;
            let diff2 = 0;
            let or1: string, or2: string, comp: number;
            let pro1: string, pro2: string, or1country: string, or2country: string;
            switch (sortValue) {
                case undefined:
                case 'lastmodified':
                    if (!c1.updated_at && !c2.updated_at) { return 0; }
                    if (!c2.updated_at) { return 1 - inversenum; }
                    if (!c1.updated_at) { return -1 + inversenum; }
                    diff = new Date(c1.updated_at).getTime() - new Date(c2.updated_at).getTime();
                    if (diff < 0) { return 1 - inversenum; }
                    if (diff > 0) { return -1 + inversenum; }
                    diff = c1.internal_hr_id - c2.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                case 'hr_id':
                    diff = c2.internal_hr_id - c1.internal_hr_id;
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);

                case 'label':
                    if (!c1.label) { c1.label = ''; }
                    if (!c2.label) { c2.label = ''; }
                    comp = c1.label.localeCompare(c2.label, this.locale);
                    if (comp === 0) {
                        diff = c2.internal_hr_id - c1.internal_hr_id;
                        return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);
                    }
                    return comp < 0 ? (-1 + inversenum) : (1 - inversenum);

                case 'origin':
                    // needs translation
                    or1 = this.tr.anslate(c1.origin ? (typeof c1.origin['origin'] !== 'undefined' ? c1.origin['origin'] : c1.origin) : '');
                    or2 = this.tr.anslate(c2.origin ? (typeof c2.origin['origin'] !== 'undefined' ? c2.origin['origin'] : c2.origin) : '');
                    // first need country (e.g. Indonesia) in case of, e.g. Bali: Asia#Bali (Indonesia)
                    or1country = c1.origin ? (typeof c1.origin['country'] !== 'undefined' ? c1.origin['country'] : this.getCountryOfOrigin(c1.origin)) : '';
                    if (or1country && or1country !== or1) {
                        // country is already translated
                        or1 = or1country + or1;
                    }
                    or2country = c2.origin ? (typeof c2.origin['country'] !== 'undefined' ? c2.origin['country'] : this.getCountryOfOrigin(c2.origin)) : '';
                    if (or2country && or2country !== or2) {
                        or2 = or2country + or2;
                    }
                    comp = or1.localeCompare(or2, this.locale);
                    // if (or1.toUpperCase() < or2.toUpperCase()) { return -1 + inversenum; }
                    // if (or1.toUpperCase() > or2.toUpperCase()) { return 1 - inversenum; }
                    if (comp === 0) {
                        diff = c1.internal_hr_id - c2.internal_hr_id;
                        return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);
                    }
                    return comp < 0 ? (-1 + inversenum) : (1 - inversenum);

                case 'processing':
                    // no need to translate before sort since these are not translated
                    // the category is prepended, i.e. sort can be done on the original string
                    pro1 = c1.processing || '';
                    pro2 = c2.processing || '';
                    comp = pro1.localeCompare(pro2, this.locale);
                    if (comp === 0) {
                        diff = c1.internal_hr_id - c2.internal_hr_id;
                        return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);
                    }
                    return comp < 0 ? (-1 + inversenum) : (1 - inversenum);

                case 'stock':
                    if (!c1.curStock && !c2.curStock) {
                        diff = c1.internal_hr_id - c2.internal_hr_id;
                        return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);
                    }
                    if (typeof c1.curStock === 'undefined') { c1.curStock = 0 }
                    if (typeof c2.curStock === 'undefined') { c2.curStock = 0 }
                    diff = c1.curStock - c2.curStock;
                    diff2 = c2.internal_hr_id - c1.internal_hr_id;
                    return diff > 0 ? -1 + inversenum : (diff < 0 ? 1 - inversenum : (diff2 > 0 ? -1 + inversenum : (diff2 < 0 ? 1 - inversenum : 0)));
            }
        });
    }

    /**
     * Called when the user goes to the next/previous page or changed the page size.
     * Stores potentially new page size and call getAllPaged
     * @param $event new paging data
     */
    pagingChanged($event: PageEvent): void {
        if (this.pageSize !== $event.pageSize) {
            this.pageSize = $event.pageSize;
            this.userService.setPageSize('coffees', this.pageSize);
        }
        this.pageIndex = $event.pageIndex;
        this.getAllPaged();
    }

    /**
     * Filters cacheBucket objects (into this.coffees) and sorts (this.coffees in place).
     * Slices (this.coffees) for current page.
     * @param sortValue 
     * @param inverse 
     * @param ssf 
     * @param pageSize 
     * @param pageIndex 
     * @param filter 
     */
    private clientSideFilterAndSort(sortValue: string, inverse: boolean,
        ssf: { _id: string, hr_id?: string, label?: string }[] | 'all',
        pageSize: number, pageIndex: number, filter: string) {

        let recalcStock = true;
        if (sortValue === 'stock') {
            this.calcStockForSortAndFilter(ssf !== this.showstockfrom, ssf);
            recalcStock = false;
        }

        // would be more efficient to first filter then sort but
        // want to keep chachedObjects sorted anyway (e.g. for paging)
        // need to sort if sort or inverse changed or showstockfrom changed for sortvalue "stock"
        if (sortValue !== this.lastSortValue || inverse !== this.inverse
            || (this.sortValue === 'stock' && ((this.showstockfrom === 'all' && ssf !== 'all') || (this.showstockfrom !== 'all' && ssf === 'all')
                || this.showstockfrom.length !== ssf.length))) {
            this.sort(this.cacheBucket, sortValue, inverse);
        }
        this.doFilter(filter, recalcStock);
        this.displayCache = this.coffees.slice();

        // if (sortValue !== this.lastSortValue || inverse !== this.inverse) {
        //     // this.coffees has been re-set in doFilter
        //     this.sort(this.coffees, sortValue, inverse);
        // }

        // slice for current page
        this.coffees = this.coffees.slice(pageSize * pageIndex, pageSize * (pageIndex + 1));
        this.updateAnyCoffeeHasStock();
    }

    // sortCoffees(inverse: boolean, ssf: { _id: string, hr_id?: string, label?: string }[] | 'all') {
    //     // if (this.countCached() !== this.objectCount) {
    //     //     // clear cache since sort order changed and not all are cached
    //     //     this.displayCache.length = 0;
    //     // }
    //     this.getAllPaged(this.pageSize, this.pageIndex, this.sortValue, inverse, ssf);
    // }

    /**
     * Called when the user specifies a new value for ssf via the dropdown.
     * Stores the new value, calls calcStockForSortAndFilter and, if necessary, uses
     * getAllPaged to get the correctly sorted objects
     * @param $event new ssf value
     */
    showstockfromChanged($event: { value: Location[] | 'all' }): void {
        if (!$event.value?.length || $event.value === 'all' || $event.value.length === this.stores.length) {
            this.utils.storeShowStockFrom('all', this.currentUser);
            $event.value = 'all';
        } else {
            this.utils.storeShowStockFrom($event.value, this.currentUser);
        }

        // now done in updateAnyCoffeeHasStock
        // // always recalculate for display
        // this.calcStockForSortAndFilter(true, $event.value);

        // check if necessary to initiate a new sort or get new data (if sort or filter are related to "curStock")
        const searchIdx = this.filter.substring(0, 1) === '-' ? 1 : 0;
        if (!Number.isNaN(this.filter.substring(searchIdx, searchIdx + 1))
            || this.filter.substring(searchIdx, searchIdx + 1) === '>' || this.filter.substring(searchIdx, searchIdx + 1) === '<'
            || this.filter.substring(searchIdx, searchIdx + 'STOCK:'.length) === 'STOCK:'
            || (this.sortValue === 'stock' && this.filteredObjectCount > 0)) {
            this.getAllPaged(this.pageSize, this.pageIndex, this.sortValue, this.inverse, $event.value, this.filter);
        }

        // set after sorting such that sorting still knows the previous sort values
        this.showstockfrom = $event.value;
    }

    /**
     * Called when the user chooses a new sort order (or wants to invert it).
     */
    sortOrderChanged(): void {
        if (this.editNewCoffee >= 0) {
            return;
        }
        if (this.firstSort && this.sortValue === 'lastmodified') {
            this.lastSortValue = undefined;
            this.getAllPaged(this.pageSize, this.pageIndex, this.sortValue, true, this.showstockfrom, this.filter);
            // this.sortCoffees(true, this.showstockfrom);
            this.inverse = true;
        } else {
            if (this.lastSortValue === this.sortValue) {
                this.getAllPaged(this.pageSize, this.pageIndex, this.sortValue, !this.inverse, this.showstockfrom, this.filter);
                // this.sortCoffees(!this.inverse, this.showstockfrom);
                this.inverse = !this.inverse;
            } else {
                this.getAllPaged(this.pageSize, this.pageIndex, this.sortValue, false, this.showstockfrom, this.filter);
                // this.sortCoffees(false, this.showstockfrom);
                this.inverse = false;
                this.lastSortValue = this.sortValue;
            }
        }
        this.firstSort = false;
    }

    producerAdded(prod: Producer): void {
        if (prod) {
            this.producers.unshift(prod);
        }
    }

    fieldAdded(field: Location): void {
        if (field) {
            this.fields.unshift(field);
        }
    }

    private hideObject(obj: Coffee) {
        if (!obj?._id) {
            return;
        }
        let found = false;
        if (this.coffees) {
            this.coffees = this.coffees.filter(cof => {
                const nomatch = cof?._id !== obj._id;
                if (!nomatch) {
                    found = true;
                }
                return nomatch;
            });
            this.updateAnyCoffeeHasStock();
        }
        if (this.displayCache) {
            this.displayCache = this.displayCache.filter(cof => cof?._id !== obj._id);
        }
        if (this.cacheBucket) {
            this.cacheBucket = this.cacheBucket.filter(cof => cof?._id !== obj._id);
        }
        if (found) {
            this.objectCount -= 1;
            if (this.filteredObjectCount > 0) {
                this.filteredObjectCount -= 1;
            }
            // check whether the current page is now empty
            if (this.coffees.length === 0) {
                if (this.pageIndex > 0) {
                    this.pageIndex -= 1;
                }
                this.getAllPaged();
            }
        }
    }

    private objectChanged(obj: ObjectChangedInfo) {
        if (!obj || !this.coffees || (obj.model !== 'coffees' && obj.model !== 'stocks')) {
            return;
        }
        if (obj.info && typeof obj.info !== 'string' && typeof obj.info.editMode !== 'undefined') {
            this.editNewCoffee = obj.info.editMode;
            this.hiddenButtonInactive = obj.info.editMode !== -1;
            this.organicButtonInactive = obj.info.editMode !== -1;
        } else {
            this.editNewCoffee = -1;
            this.hiddenButtonInactive = false;
            this.organicButtonInactive = false;
        }
        this.isNew = -1;

        if (obj.model === 'coffees' && obj.info && typeof obj.info !== 'string' && obj.info.object && obj.info.action === 'clone') {
            this.clone(obj.info.object as Coffee);
            return;
        }
        if (obj.model === 'coffees' && obj.info === 'loadPlaces') {
            this.standardService.getAll<Location>('locations')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            // TODO optimize: only retrieve places of types FIELD or STORE
                            const places = response.result as Location[];
                            this.fields = places.filter(p => (p.type || p['__t'] || '').indexOf(Enumerations.LocationTypes.FIELD) >= 0);
                            this.fields.sort((p1, p2) => p1.internal_hr_id - p2.internal_hr_id);
                            this.stores = places.filter(p => (p.type || p['__t'] || '').indexOf(Enumerations.LocationTypes.STORE) >= 0);
                            this.stores.sort((p1, p2) => p2.internal_hr_id - p1.internal_hr_id);
                            if (!this.stores?.length) {
                                this.stores = [];
                            }
                            this.showstockfrom = this.utils.readShowStockFrom(this.stores, this.currentUser);
                        } else {
                            this.utils.handleError('Are you connected to the Internet?', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Are you connected to the Internet?', error);
                    }
                });
            return;
        }
        if (obj.model === 'coffees' && obj.info === 'loadProducers') {
            this.standardService.getAll<Producer>('producers')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.producers = response.result;
                        } else {
                            this.utils.handleError('Are you connected to the Internet?', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Are you connected to the Internet?', error);
                    }
                });
            return;
        }
        if (obj.model === 'coffees' && obj.info === 'loadSuppliers') {
            this.standardService.getAll<Supplier>('suppliers')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.suppliers = response.result;
                        } else {
                            this.utils.handleError('Are you connected to the Internet?', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('Are you connected to the Internet?', error);
                    }
                });
            return;
        }
        if (obj.model === 'coffees' && obj.info && typeof obj.info !== 'string' && obj.info.type === 'transaction') {
            if (obj.info.coffee) {
                // reload this coffee
                this.reloadCoffee(obj.info.coffee);
                return;
            } else {
                obj.reload = true;
            }
        }

        if (obj.model === 'coffees' && obj.info && typeof obj.info !== 'string' && (obj.info.type === 'hidden' || obj.info.type === 'unhidden')) {
            if (obj.info.coffee) {
                // remove this coffee from display and cache
                this.hiddenButtonInactive = true;
                setTimeout(() => {
                    this.hideObject(obj.info['coffee']);
                    this.hiddenButtonInactive = false;
                }, 2000);
                if (!this.helptipHiddenShown) {
                    setTimeout(() => {
                        this.showHelptipHidden = true;
                        this.utils.storeHelptipShown(Enumerations.HELPTIP.HIDDENBEANS);
                    }, 2000);
                }
                return;
            } else {
                obj.reload = true;
            }
        }

        if (obj.reload) {
            // currently the only case is that new beans are added with initial stock
            this.displayCache.length = 0;
            // avoid that the cache is used; but dont invalidate the cacheBucket
            this.objectCount = -1;
            this.idToHighlight = obj.info && typeof obj.info !== 'string' ? obj.info.coffeeId : undefined;
            // reset the filter such that the new beans are definitely shown
            this.filter = '';
            this.sortValue = 'lastmodified';
            this.lastSortValue = 'lastmodified';
            this.getAllPaged();
            return;
        }

        if (obj.info) {
            // info: {storeIdx: sIdx, coffeeId: stock.coffeeId, amount: this.stocks[sIdx].amount}
            if (obj.model === 'stocks' && typeof obj.info !== 'string' && obj.info.amount != null &&
                obj.info.coffeeId != null && this.coffees?.length && obj.info.storeIdx >= 0) {
                // stock amount change
                for (const coff of this.coffees) {
                    if ((coff._id || coff).toString() === obj.info.coffeeId) {
                        if (obj.info.storeIdx < coff.stock?.length) {
                            coff.stock[obj.info.storeIdx].amount = obj.info.amount;
                        }
                        break;
                    }
                }
                return;
            }

            if (typeof obj.info !== 'string' && typeof obj.info.object !== 'undefined') {
                try {
                    const coffee = obj.info.object as Coffee;
                    if (this.coffees.length > 0 && typeof this.coffees[0]._id === 'undefined') {
                        // the first in the list is a new one
                        if (coffee.deleted === true) {
                            // ... and it has been deleted (or the add has been cancelled)
                            this.coffees.splice(0, 1);
                            // no need to look at the caches, has only been added temporarily
                        } else {
                            // ... and it has been saved
                            // don't simply replace object as this will create a new subcomponent which is not expanded
                            this.utils.updateObject(this.coffees[0], coffee);
                            // add the new element to the beginning of the cache
                            this.displayCache.unshift(this.coffees[0]);
                            this.cacheBucket.unshift(this.coffees[0]);
                            this.objectCount++;
                            this.maxObjectCount++;
                            this.filteredObjectCount++;
                            if (this.coffees.length > this.pageSize) {
                                // remove the last shown if longer than page size
                                this.coffees.pop();
                            }
                            this.sortValue = 'lastmodified';
                            this.lastSortValue = 'lastmodified';
                            if (!this.helptipOrganicShown) {
                                setTimeout(() => {
                                    this.showHelptipOrganic = true;
                                    this.utils.storeHelptipShown(Enumerations.HELPTIP.ORGANICBEANS);
                                }, 2000);
                            }
                        }
                    } else if (coffee._id) {
                        for (let c = 0; c < this.coffees.length; c++) {
                            if ((this.coffees[c]._id || this.coffees[c]).toString() === (coffee._id || coffee).toString()) {
                                if (coffee.deleted === true) {
                                    this.coffees.splice(c, 1);
                                    // remove from caches
                                    this.displayCache.splice(this.pageSize * this.pageIndex + c, 1);
                                    for (let b = 0; b < this.cacheBucket.length; b++) {
                                        const ccof = this.cacheBucket[b];
                                        if ((ccof._id || ccof).toString() === (coffee._id || coffee).toString()) {
                                            this.cacheBucket.splice(b, 1);
                                            break;
                                        }
                                    }

                                    this.objectCount--;
                                    this.maxObjectCount--;
                                    this.filteredObjectCount--;
                                    if (this.coffees.length === 0) {
                                        if (this.pageIndex > 0) {
                                            this.pageIndex -= 1;
                                        }
                                        this.getAllPaged();
                                    }
                                } else {
                                    // don't simply replace object as this will create a new subcomponent which is not expanded
                                    this.utils.updateObject(this.coffees[c], coffee);
                                    this.utils.updateObject(this.displayCache[this.pageSize * this.pageIndex + c], coffee);
                                    for (const ccof of this.cacheBucket) {
                                        if ((ccof._id || ccof).toString() === (coffee._id || coffee).toString()) {
                                            this.utils.updateObject(ccof, coffee);
                                            break;
                                        }
                                    }
                                }
                                break;
                            }
                        }
                    } else {
                        // cannot associate changed object
                        this.displayCache.length = 0;
                        this.getAllPaged();
                    }
                    this.idToHighlight = undefined;
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                } catch (err) {
                    // ignore but refresh to be on the save side
                    this.displayCache.length = 0;
                    this.getAllPaged();
                }
            }
        }
    }

    private reloadCoffee(coffee: Partial<Coffee>) {
        // find coffee in current page or in chache
        let idx = -1;
        let cidx = -1;
        for (let i = 0; i < this.coffees.length; i++) {
            const coff = this.coffees[i];
            if (coff?.internal_hr_id === coffee.internal_hr_id || (coff?._id === (coffee._id || coffee).toString())) {
                idx = i;
                break;
            }
        }
        for (let i = 0; i < this.displayCache.length; i++) {
            const coff = this.displayCache[i];
            if (coff?.internal_hr_id === coffee.internal_hr_id || (coff?._id === (coffee._id || coffee).toString())) {
                cidx = i;
                break;
            }
        }
        if (idx >= 0 || cidx >= 0) {
            this.standardService.getOne<Coffee>('coffees', coffee.internal_hr_id ? 'C' + coffee.internal_hr_id : (coffee._id || coffee).toString())
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            if (idx >= 0 && idx < this.coffees.length) {
                                this.coffees[idx] = response.result;
                            }
                            if (cidx >= 0 && cidx < this.displayCache.length) {
                                this.displayCache[cidx] = response.result;
                            }
                            if (idx >= 0) {
                                this.idToHighlight = 'C' + coffee.internal_hr_id;
                            }
                        } else {
                            this.utils.handleError('error updating the beans information', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error updating the beans information', error);
                    }
                });
        }
    }

    private clone(coffee: Coffee) {
        if (this.readOnly) { return; }

        this.hiddenButtonInactive = true;
        this.organicButtonInactive = true;

        if (!this.coffees) {
            this.coffees = [];
        }
        let cnt = 0;
        let label = coffee.label + ' - Copy';
        while (this.labelExists(label)) {
            cnt++;
            label = coffee.label + ' - Copy' + cnt;
        }

        this.editNewCoffee = 0;
        this.isNew = 0;
        const newCoffee = cloneDeep(coffee);
        newCoffee.label = label;
        newCoffee.stock = [];
        // delete IDs since new ones will be assigned
        delete newCoffee._id;
        delete newCoffee.hr_id;
        // don't delete internal_hr_id since we use it to scroll to the newly opened object
        this.idToHighlight = newCoffee.internal_hr_id.toString();

        // need to know it is a cloned one, otherwise isEquals removes everything on save
        newCoffee['cloned'] = true;

        this.coffees.unshift(newCoffee);
    }

    /**
     * Heuristic: check whether the given label is currently known.
     * Does not talk to the server. Therefore, it does not know or query all coffees of 
     * the user (esp. no hidden ones), only checks the currently cached ones.
     * @param label label to check
     */
    private labelExists(label: string): boolean {
        for (const coffee of this.cacheBucket) {
            if (coffee.label === label) {
                return true;
            }
        }
        return false;
    }

    add(): void {
        if (this.readOnly) { return; }

        this.hiddenButtonInactive = true;
        this.organicButtonInactive = true;

        this.preLoadPropertiesForEditing();

        if (!this.coffees) {
            this.coffees = [];
        }
        let cnt = 0;
        let label = this.tr.anslate('NEW');
        while (this.labelExists(label)) {
            cnt++;
            label = this.tr.anslate('NEW') + cnt;
        }

        this.editNewCoffee = 0;
        this.isNew = 0;
        const coffee = new Coffee();
        coffee.default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 };
        coffee.crop_date = { landed: [], picked: [] };
        coffee.altitude = {};
        coffee.screen_size = {};
        coffee.stock = [];
        coffee.ICO = {};
        coffee.screen_size = {};
        coffee.tags = [];
        coffee.flavors = [];
        coffee.varietals = [];
        coffee.certifications = [];

        coffee.label = label;
        this.coffees.unshift(coffee);
    }

    showHiddenChanged(showHidden?: HiddenType): void {
        if (this.hiddenButtonInactive) {
            return;
        }
        this.hiddenButtonInactive = true;
        this.showHelptipHidden = false;
        this.utils.storeHelptipShown(Enumerations.HELPTIP.HIDDENBEANS);

        this.pageIndex = 0;
        this.getAllPaged(this.pageSize, this.pageIndex, this.sortValue, this.inverse, this.showstockfrom, this.filter, showHidden);
        // this is set in getAllPaged to avoid flickering if only 1 item is hidden
        // this.showHidden = showHidden;
    }

    showOrganicChanged(): void {
        if (this.organicButtonInactive) {
            return;
        }
        this.organicButtonInactive = true;
        this.showHelptipOrganic = false;
        this.utils.storeHelptipShown(Enumerations.HELPTIP.ORGANICBEANS);

        const nextShowOrganic = this.utils.getNextShowOrganicState(this.showOrganic);
        this.pageIndex = 0;
        this.getAllPaged(this.pageSize, this.pageIndex, this.sortValue, this.inverse, this.showstockfrom, this.filter, this.showHidden, nextShowOrganic);
        // this is set in getAllPaged to avoid flickering if only 1 item is hidden
        // this.showHidden = showHidden;
    }

    removeHelptip(type: 'hidden' | 'organic'): void {
        if (type === 'hidden') {
            if (this.showHelptipHidden && !this.helptipHiddenShown) {
                this.showHelptipHidden = false;
                this.helptipHiddenShown = true;
                this.utils.storeHelptipShown(Enumerations.HELPTIP.HIDDENBEANS);
            }
        } else {
            if (this.showHelptipOrganic && !this.helptipOrganicShown) {
                this.showHelptipOrganic = false;
                this.helptipOrganicShown = true;
                this.utils.storeHelptipShown(Enumerations.HELPTIP.ORGANICBEANS);
            }
        }
    }

    import(): void {
        this.router.navigate(['/add']);
    }
}
