<ng-template #coffeeEntry let-coffee="coffee">
    <span>
        @if (coffee?.hr_id) {
        <span><a [routerLink]="['/coffees', {id: coffee.hr_id}]">{{coffee.hr_id}}</a></span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="12"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </span>
    @if (coffee?.label) {
    <span> {{coffee.label}}</span>
    }
</ng-template>

<ng-template #roastEntry let-roasts="roasts">
    <table>
        @for (roast of roasts; track roast?._id) {
        <tr>
            <td class="right-align">
                @if (roast.hr_id) {
                <a [routerLink]="['/roasts', {id: roast.hr_id}]" class="littlespaceright">{{roast.hr_id}}</a>
                }
            </td>
            <td class="right-align">
                <span>{{roast.batch_prefix}}{{roast.batch_number}}</span>
            </td>
            <td class="right-align">
                <span>
                    {{roast.amount * utils.getUnitFactor(this.data.mainUnit) | number:'1.1-1'}}{{this.data.mainUnit}}
                </span>
            </td>
            <td>
                @if (roast.coffee) {
                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: roast.coffee}"></ng-container>
                } @else if (roast.blend?.label) {
                <a [routerLink]="['/blends', {id: roast.blend.label}]">{{roast.blend.label}}</a>
                }
            </td>
            <td>
                @if (roast.date) {
                <span i18n="transaction$$e.g. on 09.12.1978@@on {{date}}">on {{roast.date.toLocaleString(DateTime.DATE_SHORT)}}</span>
                }
            </td>
            <td>
                @if (roast.machine) {
                {{roast.machine}}
                }
            </td>
        </tr>
        }
        @if (roasts?.length > 1) {
        <tr>
            <td><span style="font-weight: bold;" i18n="generic$$indicating the sum of some numbers@@Sum">Sum</span>:</td>
            <td></td>
            <td class="right-align">
                <span style="font-weight: bold;">
                    {{amountSum * utils.getUnitFactor(this.data.mainUnit) | number:'1.1-1'}}{{this.data.mainUnit}}
                </span>
            </td>
        </tr>
        }
    </table>
</ng-template>

<h1 mat-dialog-title>
    {{discardedsTitle}}
    @if (isLoading) {
    <mat-spinner diameter="15" style="display: inline-block; margin-left: 15px;"></mat-spinner>
    }
</h1>
<mat-dialog-content>
    <ng-container *ngTemplateOutlet="roastEntry; context: {roasts}"></ng-container>

    @if (notIncludedRoasts?.length) {
    <div data-test="dv_missingHeader" class="other-table-heading"
        i18n="reports$$invalid roasts not included, header@@invalid roasts not included">The following roasts will not be included in the report since important information is missing:</div>
    <ng-container *ngTemplateOutlet="roastEntry; context: {roasts: notIncludedRoasts}"></ng-container>
    }
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="cancel-button" autofocus cdkFocusInitial mat-button type="submit" mat-dialog-close tabindex="0">
        <mat-icon>close</mat-icon>
    </button>
</mat-dialog-actions>