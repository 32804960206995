<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>


<div class="spacer"></div>

<span class="flex-end">
    @if (!isLoading && invalidTransactions?.length && dataSourceInvalids) {
    <div class="missing-items-div">
        <a (click)="scrollToMissing()" (keydown.enter)="scrollToMissing(); $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_missingRoasts" class="missing-items-link cursor-pointer">
            <span i18n="reports$$link to a list of items (such as roasts or purchases) that are not included in the curent report@@Missing items">Missing items</span>
            <mat-icon style="vertical-align: bottom;">arrow_drop_down</mat-icon>
        </a>
    </div>
    }

    <mat-form-field class="amount-input-ff1">
        <mat-label i18n="reports$$start date@@start date">From</mat-label>
        <input matInput required [matDatepicker]="myDatepicker" i18n-placeholder="reports$$start date@@start date" placeholder="From"
            [(ngModel)]="reportCopy.startDate" name="startDate" (dateChange)="datesChanged()">
        <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #myDatepicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="amount-input-ff2">
        <mat-label i18n="reports$$end date@@end date">To</mat-label>
        <input matInput required [matDatepicker]="myDatepicker2" i18n-placeholder="reports$$end date@@end date" placeholder="To"
            [(ngModel)]="reportCopy.endDate" name="endDate" (dateChange)="datesChanged()"
            [min]="reportCopy.startDate">
        <mat-datepicker-toggle matIconSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
        <mat-datepicker #myDatepicker2></mat-datepicker>
    </mat-form-field>
    <!-- showstockfrom filter -->
    @if (stores?.length > 1) {
    <mat-form-field class="origin-select">
        <mat-select multiple matInput name="showstockfrom" panelWidth="null"
            placeholder="Stores" i18n-placeholder="locationtypes$$LocationTypes@@Stores"
            [value]="(!reportCopy.ssf) ? stores : reportCopy.ssf"
            [compareWith]="utils.compareObjectsFn"
            (openedChange)="showstockfromOpened($event)"
            (selectionChange)="showstockfromChanged($event)">
            <mat-select-trigger class="twoLineOption ellipsed">
                @if (reportCopy.ssf?.length === 1) {
                <ng-container *ngTemplateOutlet="labelEntry; context: {obj: reportCopy.ssf[0]}"></ng-container>
                } @else if (!reportCopy.ssf?.length || reportCopy.ssf?.length === stores.length) {
                <span i18n="generic$$indicating everything is selected@@all">all</span>
                } @else if (reportCopy.ssf?.length > 1 && stores.length > reportCopy.ssf.length) {
                {{reportCopy.ssf.length}} <span i18n="locationtypes$$LocationTypes@@Stores">Stores</span>
                }
            </mat-select-trigger>
            @for (store of stores; track store?._id) {
            <mat-option [value]="store"><ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container></mat-option>
            }
        </mat-select>
    </mat-form-field>
    }
    <!-- label -->
    <mat-form-field class="label-input">
        <mat-label i18n="generic$$Title@@Title">Title</mat-label>
        <input matInput required type="text" i18n-placeholder="generic$$Title@@Title" placeholder="Title" [(ngModel)]="reportCopy.label" name="label">
    </mat-form-field>
</span>

<div class="transaction-types">
    <mat-checkbox class="primary-element transaction-type-checkbox"
        (change)="typesChanged()"
        [(ngModel)]="selectedTypes.Purchase"><span i18n="transaction$$transaction title@@Purchase">Purchase</span>
    </mat-checkbox>
    <mat-checkbox class="primary-element transaction-type-checkbox"
        (change)="typesChanged()"
        [(ngModel)]="selectedTypes.Sale"><span i18n="transaction$$transaction title@@Sale">Sale</span>
    </mat-checkbox>
    <mat-checkbox class="primary-element transaction-type-checkbox"
        (change)="typesChanged()"
        [(ngModel)]="selectedTypes.Correction"><span i18n="transaction$$transaction title@@Correction">Correction</span>
    </mat-checkbox>
    <mat-checkbox class="primary-element transaction-type-checkbox"
        (change)="typesChanged()"
        [(ngModel)]="selectedTypes.Transfer"><span i18n="transaction$$transaction title@@Transfer">Transfer</span>
    </mat-checkbox>

    <app-organicicon class="cursor-pointer" [leftMargin]="10"
        (click)="showOrganicChanged()"
        [isOrganic]="true"
        [inactive]="!showOrganic"
        [strikethrough]="showOrganic === 'off'"
        [matTooltip]="!showOrganic ? tr.anslate('Show only items with organic certification') : ''"
        i18n-matTooltip="generic$$tooltip, filter for organic certifications@@Show only items with organic certification">
    </app-organicicon>
</div>


@if (isLoading) {
<mat-spinner diameter="20"></mat-spinner>
<!-- <span class="spinner-with-comment">
    <mat-spinner diameter="20"></mat-spinner> <span class="spinner-comment" i18n="generic$$currently loading items@@Loading">Loading ...</span>
</span> -->
}
<br />

@if (!isLoading && dataSource) {
<app-beansreport-table #table
    [currentUser]="currentUser"
    [dataSource]="dataSource"
    [columnsToDisplay]="columnsToDisplay"
    [additionalIsEditable]="true"
    [readOnly]="readOnly"
    [editMode]="true"
    [reportName]="reportCopy.number"
    (reportChanged)="onReportChanged($event)">
</app-beansreport-table>
}


<span name="hiddenElems" id="hiddenElems" #hiddenElems></span>
<br />
@if (!isLoading && invalidTransactions?.length && dataSourceInvalids) {
<div class="other-table-heading" i18n="reports$$invalid transactions not included, header@@invalid transactions not included">The following transactions will not be included in the report since important information is missing:</div>

<app-beansreport-table #invalidsTable
    [currentUser]="currentUser"
    [dataSource]="dataSourceInvalids"
    [columnsToDisplay]="columnsToDisplayInvalids"
    [additionalIsEditable]="true"
    [readOnly]="readOnly"
    [editMode]="true"
    [reportName]="reportCopy?.number || report?.number">
</app-beansreport-table>
}