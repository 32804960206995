import { FileUploadModule } from 'src/app/modules/ui/file-upload.module';
import { StoresComponent } from './stores.component';
import { AngularSplitModule } from 'angular-split';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreRoutingModule } from './store-routing.module';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { TransactionsModule } from 'src/app/modules/transaction/transactions.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { StockModule } from 'src/app/modules/stock/stock.module';
import { StoreGraphModule } from 'src/app/modules/graph/store-graph.module';
import { ApDatetimeModule } from 'src/app/modules/ui/ap-datetime.module';
import { ShowPurchasesDialogComponent } from './show-purchases-dialog.component';
import { ModificationDateModule } from 'src/app/modules/ui/modification-date.module';
import { UiModule } from 'src/app/modules/ui/ui.module';

@NgModule({
    declarations: [
        StoresComponent,
        ShowPurchasesDialogComponent,
    ],
    imports: [
        CommonModule,
        StoreRoutingModule,
        RouterModule,
        TransactionsModule,
        AngularSplitModule,
        MyMaterialModule,
        FormsModule,
        StockModule,
        StoreGraphModule,
        ApDatetimeModule,
        FileUploadModule,
        ModificationDateModule,
        UiModule,
    ],
    providers: [
    ],
})
export class StoreModule { }
