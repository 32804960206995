<ng-template #noInfo>---</ng-template>

@if (waiting) {
<div class="spinner-with-comment" data-test="spnnr">
    <mat-spinner diameter="20"></mat-spinner> <span class="spinner-comment" i18n="roasts$$currently loading roasts@@Loading roasts">Loading roasts ...</span>
</div>
}

@if (showExportUI) {
<app-report-export-ui #reportExport
    [currentUser]="currentUser"
    [report]="report"
    [overview]="false"
    [readOnly]="readOnly"
    [isOpenReport]="isOpenReport"
    [currentSort]="currentSort"
    [show1808]="false">
</app-report-export-ui>
}

<div style="position: relative;">
    <table mat-table [dataSource]="dataSource" class="wide-table mat-elevation-z8"
        matSort matSortActive="Date" matSortDirection="asc" matSortDisableClear="true"
        (matSortChange)="sortChanged($event)">
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let roast; columns: columnsToDisplay"></tr>
        <tr mat-footer-row *matFooterRowDef="!waiting && (report.fullyLoaded && report.roastsCount > 10 && dataSource.data?.length < MAX_LIST_ROASTS) ? [] : ['loadMore']" class="sum-cell" [ngClass]="{hiderow: !waiting && (report.fullyLoaded && dataSource.data?.length < MAX_LIST_ROASTS)}"></tr>
        <tr mat-footer-row *matFooterRowDef="!report.fullyLoaded || !showTotals ? [] : columnsToDisplay" class="sum-cell"></tr>

        <!-- <ng-container *ngIf="!fullyLoaded">
    <tr mat-footer-row *matFooterRowDef="['loadMore']" class="sum-cell"></tr>
  </ng-container> -->

        <ng-container matColumnDef="InvalidReason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" i18n="reports$$Reason@@Reason">Reason</th>
            <td mat-cell *matCellDef="let roast">
                <span class='error-reason'>{{roast.invalidReason}}</span>
            </td>
            <td mat-footer-cell *matFooterCellDef colspan="3"></td>
        </ng-container>

        <ng-container matColumnDef="Number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" class="left-align">#</th>
            <td mat-cell *matCellDef="let roast; let row=index">
                <span>{{report?.number || reportName}}-</span>
                <br />
                <b>{{roast.tempnumber}}</b>
            </td>
            <td mat-footer-cell *matFooterCellDef colspan="3">
                <span i18n="generic$$total number of some items@@Total">Total</span>: {{totals?.nr || (dataSource?.data || []).length}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" i18n="generic$$date placeholder or header@@Date">Date</th>
            <td mat-cell *matCellDef="let roast">
                @if (roast.date) {
                <span [ngClass]="{'error-reason': roast.dateOutside}">{{roast.date.toLocaleString(DateTime.DATE_SHORT)}}</span>
                } @else {
                ---
                }
                <br />
                @if (roast.date) {
                <span [ngClass]="{'error-reason': roast.dateOutside}">{{roast.date.toLocaleString(DateTime.TIME_SIMPLE)}}</span>
                }
            </td>
            <ng-container mat-footer-cell *matFooterCellDef></ng-container>
        </ng-container>

        <ng-container matColumnDef="Roast-ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" i18n="generic$$Roast, header@@Roast">Roast</th>
            <td mat-cell *matCellDef="let roast">
                @if (roast.batch_prefix || roast.batch_number || roast.hr_id) {
                <span>
                    {{roast.batch_prefix}}{{roast.batch_number}}
                    @if (roast.batch_prefix || roast.batch_number) {
                    <br />
                    }
                    @if (roast.hr_id) {
                    <a [routerLink]="['/roasts', {id: roast.hr_id}]">{{roast.hr_id}}</a>
                    }
                    <app-organicicon [certInfo]="roast.certInfo" [size]="11" [leftMargin]="5"></app-organicicon>
                    @if (roast.deleted === true) {
                    <br />
                    <span class="deleted-transaction" i18n="generic$$tag used to indicate that an item has been deleted@@deleted">deleted</span>
                    }
                </span>
                } @else {
                ---
                }
            </td>
            <ng-container mat-footer-cell *matFooterCellDef></ng-container>
        </ng-container>

        <ng-container matColumnDef="Coffees">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" i18n="generic$$Beans@@Beans" style="min-width: 200px;">Beans</th>
            <td mat-cell *matCellDef="let roast">
                @if (roast?.coffee) {
                <span>
                    @if (roast.coffee.hr_id) {
                    <a [routerLink]="['/coffees', {id: roast.coffee.hr_id}]">{{roast.coffee.hr_id}}</a>
                    }
                    <app-organicicon [isOrganic]="utils.isOrganicCoffee(roast.coffee)" [size]="11" [leftMargin]="5" [rightMargin]="3"></app-organicicon>
                    @if (roast.coffee.origin) {
                    <span> {{tr.anslate(roast.coffee.origin)}}</span>
                    }
                    @if (roast.coffee.yearLabel) {
                    <span> {{roast.coffee.yearLabel}}</span>
                    }
                    @if (roast.coffee.label && (roast.coffee.hr_id || roast.coffee.origin || roast.coffee.yearLabel)) {
                    <br />
                    }
                    @if (roast.coffee.label) {
                    <span> {{roast.coffee.label}}</span>
                    }
                </span>
                }
                @if (roast?.blend; as blend) {
                <span>
                    @if (blend.label) {
                    {{blend.label}}
                    <app-organicicon [isOrganic]="utils.isOrganicBlend(blend)" [size]="11" [leftMargin]="5"></app-organicicon>
                    }
                    <ul class="ingredients-list">
                        @for (ing of blend.ingredients; track $index) {
                        <li>
                            @if (ing.ratio != null) {
                            <span>{{ing.ratio * 100 | number:'1.1-1'}}%
                                @if (ing.coffee?.hr_id) {
                                <a [routerLink]="['/coffees', {id: ing.coffee.hr_id}]">{{ing.coffee.hr_id}}</a>
                                }
                                @if (ing.coffee?.certifications) {
                                <app-organicicon [isOrganic]="utils.isOrganicCoffee(ing.coffee)" [size]="11" [leftMargin]="5" [rightMargin]="3"></app-organicicon>
                                }
                                @if (ing.coffee?.origin) {
                                <span> {{tr.anslate(ing.coffee.origin)}}</span>
                                }
                                @if (ing.coffee?.yearLabel) {
                                <span> {{ing.coffee.yearLabel}}</span>
                                }
                                @if (ing.coffee?.label && (ing.coffee.origin || ing.coffee.yearLabel)) {
                                <span>,</span>
                                }
                                @if (ing.coffee?.label) {
                                <span> {{ing.coffee.label}}</span>
                                }
                            </span>
                            }
                        </li>
                        }
                    </ul>
                </span>
                }
                @if (!roast.coffee && !roast.blend) {
                <ng-container *ngTemplateOutlet="noInfo"></ng-container>
                }
            </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">
                <span i18n="generic$$indicating the sum of some numbers@@Sum">Sum</span>:
            </td>
        </ng-container>

        <ng-container matColumnDef="In">
            <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" start="desc" i18n="reports$$report heading volume in@@Weight" class="right-align">Weight</th>
            <td mat-cell *matCellDef="let roast" class="right-align">
                @if (utils.convertToUserUnit(roast.amount, currentUser?.unit_system); as weight) {
                <span>{{weight | number:'1.0-3'}}{{mainUnit}}</span>
                } @else {
                ---
                }
            </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">
                @if (utils.getWeight(sumIn, currentUser?.unit_system); as weight) {
                <span>@if (sumInMissing) {
                    (
                    }{{weight.pre}}{{weight.value | number:'1.0-3'}}{{weight.post}}@if (sumInMissing) {
                    )
                    }</span>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="Out">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" arrowPosition="before" i18n="reports$$report heading volume out@@Yield" class="right-align">Yield</th>
            <td mat-cell *matCellDef="let roast" class="right-align">
                @if (utils.convertToUserUnit(roast.end_weight, currentUser?.unit_system); as weight) {
                <span>{{weight | number:'1.0-3'}}{{mainUnit}}</span>
                }
            </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">
                @if (utils.getWeight(sumOut, currentUser?.unit_system); as weight) {
                <span>@if (weight.value) {
                    @if (sumOutMissing) {(}{{weight.pre}}{{weight.value | number:'1.0-3'}}{{weight.post}}@if (sumOutMissing) {)}
                    }</span>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="Loss">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" arrowPosition="before" i18n="reports$$report heading roast loss@@Loss" class="right-align">Loss</th>
            <td mat-cell *matCellDef="let roast" class="right-align">
                @if (roast.amount) {
                @if (roast.discarded) {
                <span class="discarded">
                    <!-- <span *ngIf="utils.convertToUserUnit(roast.amount, currentUser?.unit_system) as weight">{{weight | number:'1.0-3'}}{{mainUnit}}</span>
                    <br /> -->
                    <span class="small-font spaceright" i18n="roast$$discarded@@discarded">discarded</span>
                </span>
                }
                @if (!roast.discarded && roast.end_weight != null) {
                @if (((roast.amount - roast.end_weight) / roast.amount); as loss) {
                <span [ngClass]="{'discarded': loss <= 0}">
                    @if (utils.convertToUserUnit(roast.amount - roast.end_weight, currentUser?.unit_system); as weight) {
                    <span>{{weight | number:'1.0-3'}}{{mainUnit}}</span>
                    }
                    <br />
                    {{loss * 100 | number:'1.1-1'}}%
                </span>
                } @else {
                ---
                }
                }
                } @else {
                ---
                }
            </td>
            <td mat-footer-cell *matFooterCellDef class="right-align">
                @if (utils.convertToUserUnit(sumInForAvg - sumOutForAvg, currentUser?.unit_system); as weight) {
                <span>@if (sumInMissing || sumOutMissing) {(}{{weight | number:'1.0-3'}}{{mainUnit}}@if (sumInMissing || sumOutMissing) {)}</span>
                }
                @if (avgLoss) {
                <br />
                @if (sumInMissing || sumOutMissing) {(}{{avgLoss * 100 | number:'1.1-1'}}%@if (sumInMissing || sumOutMissing) {)}
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="References">
            <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" class="centered" class="text-centered" i18n="reports$$References@@References">References</th>
            <td mat-cell *matCellDef="let roast; let r=index" class="text-centered">
                @if (!additionalIsEditable) {
                <span class="additional-field">{{roast.reportNote}}</span>
                }
                @if (additionalIsEditable) {
                <mat-form-field class="additional-field form-group">
                    <input matInput type="text" name="additional" [ngModel]="roast.reportNote" [@animateField]="animateFieldState"
                        (blur)="saveAdditionalNote(r, $event.target['value'])"
                        (ngModelChange)='additionalNoteChanged[r].next($event)'
                        [disabled]="readOnly">
                </mat-form-field>
                }
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="loadMore">
            <td mat-footer-cell *matFooterCellDef colspan="8">
                @if (waiting) {
                <mat-spinner diameter="20" style="margin-left: auto; margin-right: auto;"></mat-spinner>
                }
                @if (!waiting && MAX_LIST_ROASTS > report?.roasts?.length) {
                <div class="load-moreindicator cursor-pointer" (click)="loadMore()" (keydown.enter)="loadMore(); $event.preventDefault()" role="button" [tabindex]="0">
                    <mat-icon class="animated"
                        [ngClass]="{'primary-element': !invalids, 'tertiary-element': invalids}">expand_more
                    </mat-icon>
                </div>
                }
                @if (!waiting && report?.roasts?.length >= MAX_LIST_ROASTS) {
                <div class="load-moreindicator">
                    <mat-icon
                        [ngClass]="{'primary-element': !invalids, 'tertiary-element': invalids}">warning
                    </mat-icon>
                    <span style="margin-left: 10px;" i18n="reports$$Maximum for display reached@@Maximum for display reached">Maximum for display reached</span>
                </div>
                }
            </td>
        </ng-container>
    </table>

    @if (!invalids) {
    <span class="table-badge" i18n="reports$$Roasts@@Roasts">Roasts</span>
    }
</div>