import { DateTime } from "luxon";

export class Error {
    _id ?: string;
    user ?: string;
    name ?: string;
    message ?: string;
    msg ?: string;
    stack ?: string;
    stack2 ?: string;
    details ?: string;
    time ?: DateTime;
    line ?: number;
    column?: number;
    loc ?: string;
    file ?: string;
}
