<div style="padding-right: 5px;">
    <div class="stocksreports-filter-div">
        @if (loading) {
        <span class="report-container">
            <mat-spinner diameter="20"></mat-spinner>
        </span>
        }

        <mat-form-field class="amount-input-ff1 report-container">
            <input matInput required name="date"
                placeholder="Date" i18n-placeholder="generic$$date placeholder or header@@Date"
                [(ngModel)]="date"
                (dateChange)="dateChanged()"
                [matDatepicker]="myDatepicker">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="month-input">
            <mat-select name="month"
                placeholder="Month" i18n-placeholder="dates$$Month@@Month"
                [(ngModel)]="month"
                (selectionChange)="monthChanged()">
                <mat-option [value]="null"></mat-option>
                @for (month of months; track month) {
                <mat-option [value]="$index + 1">
                    {{month}}
                </mat-option>
                }
            </mat-select>
        </mat-form-field>

        @if (!loading) {
        <div class="icon-group report-container" style="margin-right: 10px;"
            [ngStyle]="{'margin-bottom': loading || !haveZeroes ? '12.5px' : 0}">
            <app-organicicon class="cursor-pointer"
                [size]="13" [topMargin]="5"
                (click)="showOrganicChanged()"
                [isOrganic]="true"
                [inactive]="!showOrganic"
                [strikethrough]="showOrganic === 'off'"
                [matTooltip]="!showOrganic ? tr.anslate('Show only items with organic certification') : ''">
            </app-organicicon>

            <span>
                @if (!showHidden) {
                <span matTooltip="Show only beans that are not hidden"
                    i18n-matTooltip="reports$$toggle label, should hidden beans be included in a report@@Show only beans that are not hidden">
                    <mat-icon class="cursor-pointer grey-icon hide-icon" (click)="hiddenChanged()">visibility</mat-icon>
                </span>
                } @else if (showHidden === 'off') {
                <mat-icon class="primary-element cursor-pointer hide-icon" (click)="hiddenChanged()">visibility</mat-icon>
                } @else {
                <mat-icon class="tertiary-element cursor-pointer hide-icon" (click)="hiddenChanged()">visibility_off</mat-icon>
                }
            </span>
        </div>
        }

        @if (haveZeroes) {
        <mat-checkbox class="primary-element"
            style="margin-left: 12px;"
            [(ngModel)]="showZeroAmounts"
            (change)="settingsChanged()"
            i18n="reports$$toggle label, should items with empty amounts be included in a report@@Show zero amounts">Show zero amounts</mat-checkbox>
        }
    </div>

    <div class="report-container">
        @if (!showZeroAmounts && haveMultipleLocations || showZeroAmounts && haveMultipleLocationsWithZeroAmounts) {
        <mat-checkbox class="primary-element toggle-alignment"
            [(ngModel)]="combineLocations"
            (change)="settingsChanged()"
            i18n="reports$$toggle label, should there be only one line per coffee@@Per bean">Per bean</mat-checkbox>
        }
        @if (!showZeroAmounts && haveMultipleCoffees && haveMultipleLocations || showZeroAmounts && haveMultipleCoffeesWithZeroAmounts && haveMultipleLocationsWithZeroAmounts) {
        <mat-checkbox class="primary-element"
            style="margin-right: 30px;"
            [(ngModel)]="combineCoffees"
            (change)="settingsChanged('combineCoffees')"
            i18n="reports$$toggle label, should there be only one line per store@@Per store">Per store</mat-checkbox>
        }

        <!-- export -->
        <div class="report-link-space-flex">
            <span data-test="spn_reportPDF" class="report-pdf-space-flex">
                <a (click)="generatePDF()" (keydown.enter)="generatePDF(); $event.preventDefault()" role="button" [tabindex]="0" [ngClass]="{'isDisabled': creatingPdf}" class="report-link">PDF</a>
            </span>

            @if (this.data_stock?.data) {
            <mat-form-field data-test="slct_export"
                style="width: 100px; margin-right: 10px; margin-top: 5px;">
                <mat-select [disabled]="creatingSheet || creatingCsv" matInput
                    name="export" panelWidth="null"
                    placeholder="Export" i18n-placeholder="generic$$button, export@@Export"
                    [(value)]="exportFormat"
                    (selectionChange)="export($event.value)">
                    <!-- <mat-option></mat-option> -->
                    <mat-option value="csv">CSV</mat-option>
                    <mat-option value="clipboardCSV" i18n="generic$$Clipboard@@Clipboard">Clipboard</mat-option>
                    <mat-option value="sheet">Excel</mat-option>
                </mat-select>
            </mat-form-field>
            }
        </div>
    </div>
</div>

<!-- matSortDisableClear="true"  -->
<!-- <table *ngIf="!loading && data_stock" mat-table [dataSource]="data_stock"  -->
<table mat-table [dataSource]="data_stock"
    matSort (matSortChange)="sortData()" matSortActive="Amount" matSortDirection="desc"
    class="wide-table stock-table mat-elevation-z8" style="width: auto; margin-top: 5px;" #stockTable>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let stocks; columns: columnsToDisplay" class="report-stock-row"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplay" class="sum-cell"></tr>

    <ng-container matColumnDef="Coffee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="generic$$Beans@@Beans">Beans</th>
        <td mat-cell *matCellDef="let stock; let s=index">
            @if (stock?.cs?.length > 1) {
            @for (cof of stock.cs; track cof?.label) {
            <span>
                @if (cof.hr_id) {
                <a [routerLink]="['/coffees', {id: cof.hr_id}]" [matTooltip]="cof.label">{{cof.hr_id}}</a>
                }
                <app-organicicon [certInfo]="cof.certInfo" [size]="9" [rightMargin]="4" [leftMargin]="4" [spacer]="10 >= stock.cs.length"></app-organicicon>
                @if (cof.origin) {
                <span [ngClass]="{'littlespaceleft': stock.cs.length > 10}">{{tr.anslate(cof.origin)}}</span>
                }
                @if (cof.yearLabel) {
                <span [ngClass]="{'littlespaceleft': cof.origin}">{{cof.yearLabel}}</span>
                }
                @if (cof.label) {
                <span [ngClass]="{'littlespaceleft': cof.origin || cof.yearLabel}"> {{cof.label}}</span>
                }:
                <span [ngClass]="{'negative-amount': 0 > cof.a}">
                    <a (click)="changeAmount(s, cof, stock.l, cof.a)" (keydown.enter)="changeAmount(s, cof, stock.l, cof.a); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">{{utils.convertToUserUnit(cof.a, currentUser?.unit_system) | number:'1.0-3'}}{{mainUnit}}</a>
                </span>
                @if (stock.cs.length - 1 > $index) {
                @if (stock.cs.length > 10) {, } @else {<br>}
                }
            </span>
            }
            }
            @if (!stock?.cs?.length) {
            @if (stock?.c?.hr_id) {
            <a [routerLink]="['/coffees', {id: stock.c.hr_id}]">{{stock.c.hr_id}}</a>
            }
            @if (stock?.c) {
            <app-organicicon [certInfo]="stock.c.certInfo" [size]="9" [rightMargin]="4" [leftMargin]="4" [spacer]="true"></app-organicicon>
            }
            @if (stock?.c?.origin) {
            <span>{{tr.anslate(stock.c.origin)}}</span>
            }
            @if (stock?.c?.yearLabel) {
            <span> {{stock.c.yearLabel}}</span>
            }
            @if (stock?.c?.label && (stock.c.hr_id || stock.c.origin || stock.c.yearLabel)) {
            <br />
            }
            @if (stock?.c?.label) {
            <span> {{stock.c.label}}</span>
            }
            }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="Store">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="asc" arrowPosition="after" i18n="generic$$storage location@@Store">Store</th>
        <td mat-cell *matCellDef="let stock; let s=index">
            @if (stock?.ls?.length > 1) {
            @for (loc of stock.ls; track loc?.label) {
            <span>
                @if (loc.hr_id) {
                <a [routerLink]="['/stores', {id: loc.hr_id}]" [matTooltip]="stock.ls.length > 3 ? loc.label : undefined">{{loc.hr_id}}</a>
                }
                @if (3 >= stock.ls.length) {
                {{loc.label}}
                }:
                <span [ngClass]="{'negative-amount': 0 > loc.a}">
                    <a (click)="changeAmount(s, stock.c, loc, loc.a)" (keydown.enter)="changeAmount(s, stock.c, loc, loc.a); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">{{loc.a | number:'1.0-3'}}{{mainUnit}}</a>
                </span>
                @if (stock.ls.length - 1 > $index) {
                @if (stock.ls.length> 3) {
                ,
                } @else {
                <br>
                }
                }
            </span>
            }
            }
            @if (!stock?.ls || 1>= stock.ls.length) {
            @if (stock?.l?.hr_id) {
            <span>@if (stock.l.hr_id) {
                <a [routerLink]="['/stores', {id: stock.l.hr_id}]">{{stock.l.hr_id}}</a>
                } {{stock.l.label}}</span>
            } @else {
            ---
            }
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align"><span i18n="generic$$indicating the sum of some numbers@@Sum">Sum</span>:</td>
    </ng-container>

    <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" arrowPosition="before" class="right-align" i18n="generic$$Amount (of beans, e.g. in kg or bags)@@Amount">Amount</th>
        <td mat-cell *matCellDef="let stock; let s=index" class="right-align">
            @if (stock?.a != null) {
            <span [ngClass]="{'negative-amount': 0 > stock.a}">
                @if (stock.l && stock.c) {
                <a (click)="changeAmount(s, stock.c, stock.l, stock.a)" (keydown.enter)="changeAmount(s, stock.c, stock.l, stock.a); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">@if (stock.a != null) {
                    <span>{{utils.convertToUserUnit(stock.a, currentUser?.unit_system) | number:'1.3-3'}}{{mainUnit}}</span>
                    } @else {
                    ---
                    }</a>
                } @else {
                <a (click)="changeAmount2(stock, s, stock.cs, stock.ls)" (keydown.enter)="changeAmount2(stock, s, stock.cs, stock.ls); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">{{utils.convertToUserUnit(stock.a, currentUser?.unit_system) | number:'1.3-3'}}{{mainUnit}}</a>
                }
            </span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align" [ngClass]="{'negative-amount': 0 > total}">{{(data ? utils.convertToUserUnit(total, currentUser?.unit_system) : 0) | number:'1.0-0'}}{{mainUnit}}</td>
    </ng-container>

    <ng-container matColumnDef="Cost">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" arrowPosition="before" class="right-align" i18n="stores$$cost, table header@@Cost">Cost</th>
        <td mat-cell *matCellDef="let stock; let s=index" class="right-align">
            @if (stock?.p) {
            <span [ngClass]="{'negative-amount': 0 > stock.p}">
                {{stock.p | currency:currency:'symbol-narrow':'1.2-2'}}
            </span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align" [ngClass]="{'negative-amount': 0 > totalCost}">{{totalCost || 0 | currency:currency:'symbol-narrow':'1.2-2'}}</td>
    </ng-container>

    <ng-container matColumnDef="CostPer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc" arrowPosition="before" class="right-align" i18n="generic$$price spent buying the beans per unit@@Cost per">Cost per</th>
        <td mat-cell *matCellDef="let stock; let s=index" class="right-align">
            @if (stock?.p && stock.a) {
            <span [ngClass]="{'negative-amount': stock.p / 0 > stock.a}">
                @if (stock?.cs?.length > 1) {
                {{stock.p / stock.a / utils.getUnitFactor(mainUnit) | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}}
                } @else {
                <a (click)="changeCost(stock)" (keydown.enter)="changeCost(stock); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">{{stock.p / stock.a / utils.getUnitFactor(mainUnit) | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}}</a>
                }
            </span>
            } @else {
            ---
            }
        </td>
        <td mat-footer-cell *matFooterCellDef class="right-align" [ngClass]="{'negative-amount': 0 > totalAverageCost}">{{(totalAverageCost || 0) / utils.getUnitFactor(mainUnit) | currency:currency:'symbol-narrow':'1.2-2'}}/{{mainUnitSingular}}</td>
    </ng-container>
</table>