import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserType } from 'src/app/modules/frame/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AccountChangedService {

    private changedSource = new Subject<UserType['account']>();
    changed$ = this.changedSource.asObservable();

    accountChanged(accountInfo: UserType['account']): void {
        setTimeout(() => {
            this.changedSource.next(accountInfo);
        });
    }
}
