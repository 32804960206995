import PQueue from 'p-queue';

// inspired by https://github.com/nico-martin/scroll-hat-snake-ui/blob/main/src/app/hooks/useBLECharacteristic.tsx
export class BleQueue {

    private bleQueue = new PQueue({ concurrency: 1 });

    useBLECharacteristic = (
        characteristic: BluetoothRemoteGATTCharacteristic,
        writeCharacteristic: BluetoothRemoteGATTCharacteristic,
        listener: (this: BluetoothRemoteGATTCharacteristic, ev: Event) => void,
        errCallback: (err: string) => void,
    ): {
        writeValue: (arg0: DataView) => Promise<void | Error>;
        startNotifications: () => Promise<void | BluetoothRemoteGATTCharacteristic>;
        stopNotifications: () => Promise<void | BluetoothRemoteGATTCharacteristic>;
    } => {
        const writeValue = async (data: DataView) => {
            return await this.bleQueue.add(
                () => {
                    try {
                        // writeCharacteristic.writeValueWithoutResponse(data);
                        // writeCharacteristic.writeValueWithResponse(data);
                        writeCharacteristic.writeValue(data);
                    } catch (err) {
                        errCallback(err.toString());
                    }
                }
            );
        };

        // const readValue = async () => {
        //     const val= await this.bleQueue.add(
        //         () => characteristic.readValue()
        //     );
        //     value = val;
        //     return val;
        // };

        const startNotifications = async () => {
            await this.bleQueue.add(
                async () => {
                    try {
                        characteristic.addEventListener(
                            'characteristicvaluechanged',
                            listener
                        );
                        // return await characteristic.startNotifications();
                    } catch (err) {
                        errCallback(err.toString());
                    }
                }
            );
            return await this.bleQueue.add(() => characteristic.startNotifications());
        }

        const stopNotifications = async () => {
            await this.bleQueue.add(
                async () => {
                    try {
                        characteristic.removeEventListener(
                            'characteristicvaluechanged',
                            listener
                        );
                        // return await characteristic.stopNotifications();
                    } catch (err) {
                        errCallback(err.toString());
                    }
                }
            );
            return await this.bleQueue.add(() => characteristic.stopNotifications());
        }

        return { writeValue, startNotifications, stopNotifications };
    };
}
