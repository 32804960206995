import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Coffee } from 'src/app/models/Coffee';
import { Observable } from 'rxjs';
import { Blend } from 'src/app/models/Blend';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BlendService {

    constructor(
        private http: HttpClient,
    ) { }

    sendCoffeeReplacement(from: Coffee, to: Coffee): Observable<{ success: boolean, result: Blend[], count: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/blends/replacebeans';
        return this.http.post<{ success: boolean, result: Blend[], count: number, error: string }>(url, { from: from._id || from, to: to._id || to });
    }
}
