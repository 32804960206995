<ng-template #alertMessage let-alert="alert">
    <span>{{alert.message}} @if (alert.link && alert.linkText) {
        <a [href]="alert.link" target="_blank" rel="noopener" style="margin-left: 20px;">{{alert.linkText}}</a>
        }</span>
</ng-template>


@for (alert of alerts; track alert?.message) {
<div class="{{ cssClass(alert) }} alert-dismissible fadeInDownAlert">
    <ng-container *ngTemplateOutlet="alertMessage; context: {alert}"></ng-container>
    <button mat-icon-button (click)="removeAlert(alert)" class="close-button">
        <mat-icon>close</mat-icon>
    </button>
</div>
}

@for (alert of removingAlerts; track alert?.message) {
<div class="{{ cssClass(alert) }} alert-dismissible fadeOutUp">
    <ng-container *ngTemplateOutlet="alertMessage; context: {alert}"></ng-container>
</div>
}