import { Enumerations } from './Enumerations';
import { Account } from './Account';
import { Tag } from './Tag';
import { User } from './User';

export class Location {
    _id: string;
    created_at ?: Date;
    updated_at ?: Date;
    deleted ?: boolean;
    internal_hr_id ?: number;
    hr_id ?: string;
    type ?: Enumerations.LocationTypes[];
    owner ?: Account;
    label ? = '';
    address ?: string;
    street ?: string;
    zip_code ?: string;
    city ?: string;
    region ?: string;
    subregion ?: string;
    country ?: string;
    coordinates ?: { type?: string, coordinates: number[] };
    logo ?: string;
    image ?: string;
    files ?: string[];
    tags ?: Tag[];
    refs ?: { count: number, objs?: string[] };
    notes ?: string;
    created_by ?: User;
    updated_by ?: User;
    // only in web client
    coffeeStock?: number;
    coffeeStockStr?: string;
}

