import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-company-info-dialog',
    template: `
    <h1 mat-dialog-title i18n="company$$Company name@@Company name">Company name</h1>
    <mat-dialog-content>
        <div class="report-container">
            <mat-form-field class="company-name">
                <input matInput data-test="npt_companyName" type="text"
                    [(ngModel)]="company.name" name="companyName"
                    placeholder="Company name" i18n-placeholder="company$$Company name@@Company name">
            </mat-form-field>
            <mat-form-field class="company-number">
                <input matInput data-test="npt_companyNumber" type="text"
                    [(ngModel)]="company.number" name="companyNumber"
                    placeholder="Company number" i18n-placeholder="company$$Company number@@Company number">
            </mat-form-field>
        </div>
        @if (dontShowAgain) {
        <div style="margin-bottom: 10px;">
            <span i18n="general$$links to the place where information can be changed@@Can be edited in">Can be edited in</span>: <span i18n="settings$$Settings for reports, header@@Reports Settings">Reports Settings</span>
        </div>
        }
        <div>
            <mat-slide-toggle [(ngModel)]="dontShowAgain"
                data-test="tggl_dontShowAgain" matInput class="primary-element"
                i18n="general$$dialog checkbox label@@Do not show this again">
                Do not show this again</mat-slide-toggle>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button data-test="bttn_cancel" class="cancel-button" mat-button type="submit" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
        <button (click)="onYesClick()" data-test="bttn_done" class="action-button" mat-raised-button type="button">
            <mat-icon>done</mat-icon>
        </button>
    </mat-dialog-actions>
    `
})
export class CompanyInfoDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<CompanyInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { company: { name: string, number: string } },
    ) { }

    company = { name: '', number: '' };
    dontShowAgain = false;

    ngOnInit(): void {
        if (this.data.company) {
            this.company = { name: this.data.company.name, number: this.data.company.number };
        }
    }

    onYesClick(): void {
        this.dialogRef.close({ company: this.company, dontShowAgain: this.dontShowAgain });
    }
}
