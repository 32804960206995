<div class="split-canvas">
    <as-split direction="vertical" [gutterSize]="15" (dragEnd)="onDragEnd($event)" (gutterClick)="onGutterClick($event)" [gutterDblClickDuration]="0" [useTransition]="true">
        <as-split-area [size]="splitSizes[0]">
            @if (splitSizes[0] > 5) {
            <div>
                @if (!filteredObjects) {
                <div class="wait-outer">
                    <mat-spinner class="spinner"></mat-spinner>
                </div>
                }
                @if (!readOnly) {
                <div class="actionbar">
                    <div class="actionbar-inner">
                        @if (filteredObjects) {
                        <button [disabled]="editNewRoast >= 0" type="button"
                            class="add-button action-button" mat-raised-button (click)="add()">
                            <mat-icon>add</mat-icon>
                        </button>
                        }
                    </div>
                </div>
                } @else {
                <div class="readonly-spacer"></div>
                }
                @if (!this.idToHighlight) {
                <app-roasts-filter
                    [currentUser]="currentUser"
                    [count]="maxObjectCount"
                    [editNewRoast]="editNewRoast"
                    [stores]="stores"
                    [energyUnit]="energyUnit"
                    [isDarkmode]="isDarkmode"
                    [readOnly]="readOnly"
                    [filters]="currentFilter"
                    [showAlways]="ssf && ssf !== 'all'"
                    (moreThanOneUser)="moreThanOneUser = $event"
                    (filterChanged)="filtering$.next({ options: $event.options, now: $event.now })">
                </app-roasts-filter>
                }
                <!-- (filterChanged)="filterObjects($event.options, undefined, $event.done)"> -->
                <div class="sortbar appbackground">
                    @if (maxObjectCount > 1) {
                    <div class="sortbar-inner" style="margin-left: 0;">
                        <mat-button-toggle-group [disabled]="editNewRoast >= 0"
                            [ngClass]="{'inverse': inverse}"
                            class="sortButtonGroup" [hideSingleSelectionIndicator]="true"
                            [ngModel]="sortValue">
                            <mat-button-toggle (click)="sortOrderChanged('roastdate')" class="sortButton" i18n="roasts$$sort order, roast data button@@roast date" value="roastdate">Roast date</mat-button-toggle>
                            <!-- <mat-button-toggle (click)="sortOrderChanged('hr_id')" class="sortButton" i18n="generic$$sort order ID@@ID" value="hr_id">ID</mat-button-toggle> -->
                            <mat-button-toggle (click)="sortOrderChanged('label')" class="sortButton" i18n="generic$$Title@@Title" value="label">Title</mat-button-toggle>
                            <mat-button-toggle (click)="sortOrderChanged('batch')" class="sortButton" i18n="roasts$$batch number@@batch number" value="batch">Batch number</mat-button-toggle>
                            <mat-button-toggle (click)="sortOrderChanged('machine')" class="sortButton" i18n="roasts$$machine@@Machine" value="machine">Machine</mat-button-toggle>
                            @if (moreThanOneUser) {
                            <mat-button-toggle (click)="sortOrderChanged('user')" class="sortButton" i18n="generic$$user, sort order button@@sort order user" value="user">User</mat-button-toggle>
                            }
                        </mat-button-toggle-group>
                    </div>
                    } @else {
                    <span></span>
                    }
                </div>
                @if (maxObjectCount >= 5) {
                <div class="appbackground" style="position: relative;">
                    <mat-paginator [disabled]="editNewRoast >= 0 || isNew >= 0"
                        class="appbackground"
                        [length]="filteredObjectCount"
                        [pageSize]="pageSize"
                        [pageSizeOptions]="[5, 10, 25, 50]"
                        [pageIndex]="pageIndex"
                        (page)="paging$.next($event)">
                    </mat-paginator>
                    <!-- <mat-paginator [length]="filteredObjectCount" [pageSize]="pageSize" [disabled]="editNewRoast >= 0 || isNew >= 0"
                        [pageSizeOptions]="[5, 10, 25, 50]" [pageIndex]="pageIndex" (page)="pagingChanged($event)">
                    </mat-paginator> -->
                    @if (gettingForFilterLongRunning) {
                    <mat-spinner class="filter-spinner-absolute" diameter="20"></mat-spinner>
                    }
                </div>
                }
                @if (gettingForFilterLongRunning && !(maxObjectCount >= 5)) {
                <mat-spinner class="filter-spinner" diameter="20"></mat-spinner>
                }
                @if (filteredObjects?.length > 0) {
                <mat-accordion displayMode="default" multi="true">
                    @for (roast of filteredObjects; track roast?._id) {
                    <app-roast
                        [currentUser]="currentUser"
                        [(energyUnit)]="energyUnit"
                        [roast]="roast"
                        [index]="$index"
                        [idToHighlight]="idToHighlight"
                        [editMode]="editNewRoast"
                        [isNew]="isNew"
                        [readOnly]="readOnly"
                        [readOnlyAccount]="readOnlyAccount"
                        [machines]="machines"
                        [stores]="stores">
                    </app-roast>
                    }
                </mat-accordion>
                }
                @if (maxObjectCount >= 5 && filteredObjects?.length && pageSize >= 10) {
                <mat-paginator [disabled]="editNewRoast >= 0 || isNew >= 0"
                    [length]="filteredObjectCount"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[5, 10, 25, 50]"
                    [pageIndex]="pageIndex"
                    (page)="paging$.next($event)">
                </mat-paginator>
                }
                @if (!readOnly && maxObjectCount === 0 && filteredObjects && !idToHighlight && !gettingForFilter) {
                <div>
                    <p class="main-note" i18n="generic$$no items found, hint at how to add one@@nothing to show plus hint, add with button">Currently, there is nothing to show. Add using the button at the top!</p>
                </div>
                }
                @if (!gettingForFilter && ((idToHighlight && maxObjectCount === 0) || (maxObjectCount > 0 && filteredObjects?.length === 0))) {
                <div>
                    <p class="main-note" i18n="generic$$no matches found using the given filter@@no match for filter">No matches found</p>
                </div>
                }
                <div class="enlargepage"></div>
            </div>
            }
        </as-split-area>

        <as-split-area [size]="splitSizes[1]">
            @if (splitSizes[1] > 20) {
            <app-roast-graphs [filters]="currentFilter" (filterChanged)="tellFilterChanged($event)"></app-roast-graphs>
            }
        </as-split-area>

    </as-split>
</div>