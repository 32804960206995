<div style="height: 100%;" id="mainappdiv" [ngClass]="{'force-dark-mode': darkmodeMode === 'darkmode', 'force-light-mode': darkmodeMode === 'lightmode', 'auto-mode': darkmodeMode === 'auto'}">
    <app-alert></app-alert>

    <div class="wrapper">
        @if (loggedInUser?.nickname) {
        <app-ap-toolbar
            [loggedInUser]="loggedInUser"
            [drawer]="drawer"
            [relative]=true
            [opaque]=true
            [paidUntil]="paidUntil">
        </app-ap-toolbar>
        }

        <mat-sidenav-container class="sidenav-container" autosize>
            <!-- fixedInViewport="true" -->
            <mat-sidenav
                #drawer
                class="sidenav"
                [fixedInViewport]="isSmall$ | async"
                [attr.role]="'navigation'"
                [mode]="(isSmall$ | async) ? 'over' : 'side'"
                [opened]="((isSmall$ | async) !== (false || null || undefined)) && loggedInUser?.nickname"
                fixedTopGap="37">
                <!-- fixedTopGap="56"> -->
                <mat-nav-list>
                    @if ((isSmallDelayed$ | async) || (isLarge$ | async)) {
                    <a mat-list-item [routerLink]="['/stores']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'store' + (isDarkmode ? '-dark' : '')"></mat-icon><span class="menu-label" i18n="locationtypes$$LocationTypes@@Stores">Stores</span>
                    </a>
                    <a mat-list-item [routerLink]="['/coffees']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'coffee' + (isDarkmode ? '-dark' : '')"></mat-icon><span class="menu-label" i18n="menu$$menu entry Beans@@Beans menu label">Beans</span>
                    </a>
                    <a mat-list-item [routerLink]="['/blends']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'blend' + (isDarkmode ? '-dark' : '')"></mat-icon><span class="menu-label" i18n="menu$$menu entry Blends@@Blends menu label">Blends</span>
                    </a>
                    <a mat-list-item [routerLink]="['/schedule']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" svgIcon="schedule"></mat-icon><span><span class="menu-label" i18n="menu$$menu entry Schedule@@Scheduler menu label">Schedule</span>
                            <!-- @if (DateTime.now().toISODate() < '2024-08-30' ) {
                            <span _ngcontent-ng-c435486427="" class="menu-label" style="font-size: 11px; color: #cc0e50; position: absolute; left: 14px; top: -4px;">NEW</span>
                        } -->
                        </span>
                    </a>
                    <a mat-list-item [routerLink]="['/roasts']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'roast' + (isDarkmode ? '-dark' : '')"></mat-icon><span class="menu-label" i18n="menu$$menu entry Roasts@@Roasts menu label">Roasts</span>
                    </a>
                    <a mat-list-item [routerLink]="['/contacts']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'contact' + (isDarkmode ? '-dark' : '')"></mat-icon><span class="menu-label" i18n="menu$$menu entry Contacts@@Contacts menu label">Contacts</span>
                    </a>
                    <a mat-list-item [routerLink]="['/reports']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" svgIcon="report"></mat-icon><span class="menu-label" i18n="menu$$menu entry Reports@@Reports menu label">Reports</span>
                    </a>
                    <a mat-list-item [routerLink]="['/reminders']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="primary-badge menu-icon" [svgIcon]="'reminder' + (isDarkmode ? '-dark' : '')" [matBadgeHidden]="!(nrExpiredReminders > 0)" [matBadge]="nrExpiredReminders" matBadgeSize="medium" matBadgeOverlap="true" aria-hidden="false"></mat-icon><span class="menu-label" i18n="menu$$menu entry Reminders@@Reminders menu label">Reminders</span>
                    </a>
                    } @else {
                    <a mat-list-item [routerLink]="['/stores']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'store' + (isDarkmode ? '-dark' : '')" matTooltip="Stores" i18n-matTooltip="locationtypes$$LocationTypes@@Stores"></mat-icon>
                    </a>
                    <a mat-list-item [routerLink]="['/coffees']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'coffee' + (isDarkmode ? '-dark' : '')" matTooltip="Beans" i18n-matTooltip="menu$$menu entry Beans@@Beans menu label"></mat-icon>
                    </a>
                    <a mat-list-item [routerLink]="['/blends']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'blend' + (isDarkmode ? '-dark' : '')" matTooltip="Blends" i18n-matTooltip="menu$$menu entry Blends@@Blends menu label"></mat-icon>
                    </a>
                    <a mat-list-item [routerLink]="['/schedule']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" svgIcon="schedule"></mat-icon>
                        <!-- @if (DateTime.now().toISODate() < '2024-06-30' ) {
                        <span _ngcontent-ng-c435486427="" class="menu-label" style="font-size: 11px; color: #cc0e50; position: absolute; left: 14px; top: -1px;">NEW</span>
                        } -->
                    </a>
                    <a mat-list-item [routerLink]="['/roasts']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'roast' + (isDarkmode ? '-dark' : '')" matTooltip="Roasts" i18n-matTooltip="menu$$menu entry Roasts@@Roasts menu label"></mat-icon>
                    </a>
                    <a mat-list-item [routerLink]="['/contacts']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" [svgIcon]="'contact' + (isDarkmode ? '-dark' : '')" matTooltip="Contacts" i18n-matTooltip="menu$$menu entry Contacts@@Contacts menu label"></mat-icon>
                    </a>
                    <a mat-list-item [routerLink]="['/reports']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="menu-icon" svgIcon="report" matTooltip="Reports" i18n-matTooltip="menu$$menu entry Reports@@Reports menu label"></mat-icon>
                    </a>
                    <a mat-list-item [routerLink]="['/reminders']" routerLinkActive="activeMenu">
                        <mat-icon matListItemIcon class="primary-badge menu-icon" [svgIcon]="'reminder' + (isDarkmode ? '-dark' : '')"
                            matTooltip="Reminders" i18n-matTooltip="menu$$menu entry Reminders@@Reminders menu label"
                            [matBadgeHidden]="!(nrExpiredReminders > 0)" [matBadge]="nrExpiredReminders" matBadgeSize="medium" matBadgeOverlap="true" aria-hidden="false">
                        </mat-icon>
                    </a>
                    }
                </mat-nav-list>
            </mat-sidenav>

            <mat-sidenav-content [ngClass]="{'padding-left': loggedInUser?.nickname}">
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>

        <div class="spacer"></div>

        <div class="footer">
            <div>
                <a routerLink="/legal"><span i18n="footer$$legal notice@@legal notice">Legal Notice</span></a>
                <span> - </span>
                <a routerLink="/legal/privacy"><span i18n="footer$$privacy policy@@privacy statement">Privacy Policy</span></a>
            </div>
            <span>
                @if (!paidUntil || loggedInUser?.readonly) {
                <a [href]="'https://buy.artisan.plus/' + (locale2 === 'de' ? locale2 : '')" target="_blank" rel="noopener" i18n="footer$$shop@@Shop">Shop</a>
                }
                @if (paidUntil && (loggedInUser && !loggedInUser.readonly)) {
                <a [href]="'https://buy.artisan.plus/' + (locale2 === 'de' ? locale2 : '')" target="_blank" rel="noopener" i18n="toolbar$$link showing until when user has paid and how to extend@@paid until, extend here">Paid until {{paidUntil.toLocaleString(DateTime.DATE_SHORT)}} (extend here)</a>
                }
            </span>
        </div>
    </div>
</div>