import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-contact-info-dialog',
    templateUrl: './contact-info-dialog.component.html',
})
export class ContactInfoDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<ContactInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            error: string,
            errorMsg: string,
            title: { text: string, wait: boolean },
            info: { label: string, value: string, isLink: boolean }[],
        }
    ) { }

    applyInfo(): void {
        this.dialogRef.close(true);
    }
}
