export class Constants {
    static readonly EPSILON = 0.0001;
    static readonly LABEL_SEPARATOR = '#';
    static readonly REPORTNUMBER_SEPARATOR = '-';
    static readonly DOWNLOADALL_THRESHOLD = 30;
    // origins are transferred using JSON.stringify()
    // static readonly ORIGINS_SEPARATOR = ';';
    static readonly FILTERVALUE_SEPARATOR = ';';
    static readonly SSF_SEPARATOR = ',';
    static readonly STORES_SEPARATOR = ',';
    static readonly TYPES_SEPARATOR = ',';
    static readonly PROPERTIES_SEPARATOR = ',';
    static readonly SHOW_SPINNER_DELAY_TIME = 600;
}
