<!-- <ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template> -->


<div class="split-canvas" #splitCanvas>
    <as-split direction="vertical" gutterSize="15"
        (dragEnd)="onDragEnd($event)" (gutterClick)="onGutterClick($event)"
        [useTransition]="true">

        <as-split-area [size]="splitSizes[0]">
            <div cdkDropListGroup>
                <div class="scheduler-planner-titlebar">
                    <div class="scheduler-planner-titlebar-icon-wrapper">
                        @if (loading > 0) {
                        <mat-spinner diameter="15" class="scheduler-planner-spinner"></mat-spinner>
                        } @else {
                        <mat-icon class="scheduler-reload-icon"
                            matTooltip="Reload - check for changes"
                            i18n-matTooltip="generic$$Reload - check for changes@@Reload - check for changes"
                            (click)="reloadSchedule()" (keydown.enter)="reloadSchedule(); $event.preventDefault()" role="button" [tabindex]="0">update
                        </mat-icon>
                        }
                    </div>

                    <div class="scheduler-planner-paging">
                        <mat-icon class="cursor-pointer" (click)="scrollDay(-1)" (keydown.enter)="scrollDay(-1); $event.preventDefault()" role="button" [tabindex]="0">chevron_left</mat-icon>
                        <span (click)="scrollDay(0)" (keydown.enter)="scrollDay(0); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer todaylabel" i18n="generic$$today@@today">today</span>
                        <mat-icon class="cursor-pointer" (click)="scrollDay(1)" (keydown.enter)="scrollDay(1); $event.preventDefault()" role="button" [tabindex]="0">chevron_right</mat-icon>
                        <input matInput [matDatepicker]="picker" class="hidden-datepicker-input"
                            [value]="lastDate"
                            (dateChange)="scrollDay($event)">
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-icon class="cursor-pointer calender-icon"
                            (click)="picker.open()" (keydown.enter)="picker.open(); $event.preventDefault()" role="button" [tabindex]="0">today</mat-icon>
                    </div>

                    <div class="scheduler-planner-filter">
                        @if (machines?.length > 1) {
                        <mat-form-field class="form-group scheduler-planner-narrow-formfield"
                            subscriptSizing="dynamic" floatLabel="always">
                            <mat-label i18n="roasts$$machine@@Machine">Machine</mat-label>
                            <mat-select multiple matInput panelWidth="null"
                                name="machinefilter" data-test="slct_machinefilter"
                                [compareWith]="utils.compareObjectsFn"
                                placeholder="not specified" i18n-placeholder="generic$$indicating a value is not set@@not specified"
                                [(ngModel)]="machineFilter"
                                (selectionChange)="filterChanged()">
                                @if (machineFilter?.length) {
                                <mat-select-trigger>
                                    @if (machineFilter.length === machines.length) {
                                    <ng-container i18n="generic$$indicating everything is selected@@all">all</ng-container>
                                    <!-- } @else if (!machineFilter.length) {
                                    NONE!
                                    <ng-container i18n="generic$$indicating a value is not set@@not specified">not specified</ng-container> -->
                                    } @else {
                                    {{createTrigger(machineFilter, users?.length > 1)}}
                                    }
                                </mat-select-trigger>
                                }
                                @if (machines.length >= 5) {
                                <mat-option>
                                    <ngx-mat-select-search ngModel (ngModelChange)="machineFilter = filterLabelObjects($event, machines)" name="machineSelect"
                                        placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                        noEntriesFoundLabel="">
                                    </ngx-mat-select-search>
                                </mat-option>
                                }
                                <!-- <mat-option [value]="null"></mat-option> -->
                                <!-- <mat-option [value]="EMPTYTAG"><mat-icon>unknown_med</mat-icon></mat-option> -->
                                @for (machine of machines; track machine) {
                                <mat-option [value]="machine">{{machine}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        }

                        @if (users?.length > 1) {
                        <mat-form-field class="form-group scheduler-planner-narrow-formfield"
                            subscriptSizing="dynamic" floatLabel="always">
                            <mat-label i18n="graphs$$graph title@@Users">Users</mat-label>
                            <mat-select multiple matInput panelWidth="null"
                                [compareWith]="utils.compareObjectsFn" name="userfilter" data-test="slct_userfilter"
                                placeholder="not specified" i18n-placeholder="generic$$indicating a value is not set@@not specified"
                                [(ngModel)]="userFilter"
                                (selectionChange)="filterChanged()">
                                @if (userFilter?.length) {
                                <mat-select-trigger>
                                    @if (userFilter.length === users.length) {
                                    <ng-container i18n="generic$$indicating everything is selected@@all">all</ng-container>
                                    <!-- } @else if (!userFilter.length) {
                                    NONE!
                                    <ng-container i18n="generic$$indicating a value is not set@@not specified">not specified</ng-container> -->
                                    } @else {
                                    {{createNarrowUserTrigger(userFilter)}}
                                    }
                                </mat-select-trigger>
                                }
                                @if (users.length >= 5) {
                                <mat-option>
                                    <ngx-mat-select-search ngModel (ngModelChange)="userFilter = filterUserObjects($event, users)" name="userSelect"
                                        placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                        noEntriesFoundLabel="">
                                    </ngx-mat-select-search>
                                </mat-option>
                                }
                                <!-- <mat-option [value]="null"></mat-option> -->
                                <!-- <mat-option [value]="EMPTYTAG"><mat-icon>unknown_med</mat-icon></mat-option> -->
                                @for (user of users; track user?.nickname) {
                                <mat-option [value]="user">{{user?.nickname}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        }

                        <!-- zoom -->
                        <span class="scheduler-zoom-container"
                            [ngClass]="{'scheduler-zoom-container-shiftdown': !machines?.length && !(users?.length > 1)}">
                            <mat-icon class="scheduler-zoom-icon" (click)="zoom(true)" (keydown.enter)="zoom(true); $event.preventDefault()" role="button" [tabindex]="0">zoom_out</mat-icon>
                            <mat-icon class="scheduler-zoom-icon scheduler-zoom-icon-month" [ngClass]="{'scheduler-zoom-icon-selected': zoomState === 'l'}" (click)="zoom('l')" (keydown.enter)="zoom('l'); $event.preventDefault()" role="button" [tabindex]="0">square</mat-icon>
                            <mat-icon class="scheduler-zoom-icon scheduler-zoom-icon-week" [ngClass]="{'scheduler-zoom-icon-selected': zoomState === 'm'}" (click)="zoom('m')" (keydown.enter)="zoom('m'); $event.preventDefault()" role="button" [tabindex]="0">square</mat-icon>
                            <mat-icon class="scheduler-zoom-icon scheduler-zoom-icon-days" [ngClass]="{'scheduler-zoom-icon-selected': zoomState === 's'}" (click)="zoom('s')" (keydown.enter)="zoom('s'); $event.preventDefault()" role="button" [tabindex]="0">square</mat-icon>
                            <mat-icon class="scheduler-zoom-icon" (click)="zoom(false)" (keydown.enter)="zoom(false); $event.preventDefault()" role="button" [tabindex]="0">zoom_in</mat-icon>
                        </span>

                        @if (zoomState === 's') {
                        @if (isSmall$ | async) {
                        <mat-slide-toggle
                            matTooltip="Reduce displayed stock by planned amounts"
                            i18n-matTooltip="scheduler$$toggle label, should planned items be included in current stock display@@Reduce displayed stock by planned amounts"
                            class="primary-element scheduler-stock-toggle-without-label"
                            [disabled]="!!loading"
                            [(ngModel)]="includePlannedInStock"
                            (change)="includePlannedInStockChanged()"></mat-slide-toggle>
                        } @else {
                        <mat-slide-toggle
                            class="primary-element scheduler-stock-toggle"
                            [disabled]="!!loading"
                            [(ngModel)]="includePlannedInStock"
                            (change)="includePlannedInStockChanged()">
                            <span i18n="scheduler$$toggle label, should planned items be included in current stock display@@Reduce displayed stock by planned amounts">Reduce displayed stock by planned amounts</span>
                            <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/docs/schedule/#see-your-stock/'" rel="noopener" style="padding-right: 0; margin: 0 0 0 5px;" target="_blank">
                                <mat-icon class="helpicon" style="vertical-align: middle;">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
                            </a>
                        </mat-slide-toggle>
                        }
                        }

                        <!-- showstockfrom filter -->
                        <!-- @if (stores?.length > 1) {
                        <mat-form-field class="origin-select left-margin-only twoline-select" subscriptSizing="dynamic">
                            <mat-label i18n="generic$$storage location@@Store">Store</mat-label>
                            <mat-select multiple
                                name="showstockfrom" panelWidth="null"
                                [value]="(!ssf || ssf === 'all') ? stores : ssf"
                                [compareWith]="utils.compareObjectsFn"
                                (openedChange)="showstockfromOpened($event)"
                                (selectionChange)="showstockfromChanged($event)">
                                <mat-select-trigger class="twoLineOption ellipsed">
                                    @if (ssf?.length === 1) {
                                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: ssf[0]}"></ng-container>
                                    } @else if (!ssf?.length || ssf === 'all' || ssf?.length === stores.length) {
                                    <span i18n="generic$$indicating everything is selected@@all">all</span>
                                    } @else if (ssf?.length > 1 && stores.length > ssf.length) {
                                    {{ssf.length}} <span i18n="locationtypes$$LocationTypes@@Stores">Stores</span>
                                    }
                                </mat-select-trigger>
                                @for (store of stores; track store?.hr_id) {
                                <mat-option [value]="store"><ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container></mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        } -->
                    </div>
                </div>

                @if (zoomState === 'm') {
                <div class="scheduler-planner-title-month">{{month}}</div>
                }

                @if (zoomState === 'l') {
                <app-scheduler-planner-year #schedulerPlanner
                    [currentUser]="currentUser"
                    [startDate]="lastDate"
                    [machineFilter]="machineFilter?.length === machines?.length ? undefined : machineFilter"
                    [userFilter]="userFilter?.length === users?.length ? undefined : userFilter"
                    (jumpToDate)="zoomState = $event.view; scrollTo($event.date)"
                    (startLoading)="setLoading(1)"
                    (doneLoading)="setLoading(-1)">
                </app-scheduler-planner-year>
                } @else {
                <div class="scheduler-planner-scroll-container" style="display: flex;">
                    <div style="flex-grow: 1;">
                        @for (row of scheduleRowsIndices; track row) {
                        <div class="scheduler-planner-container">
                            @for (schedule of schedules | slice:row*7:(row+1)*7; track schedule?.date) {
                            <!-- [machineFilter]="machineFilter"
                                [userFilter]="userFilter" -->
                            <div class="scheduler-planner-inner-container"
                                [ngClass]="{'scheduler-planner-div-micro': zoomState === 's' && (row*7 + $index === 0)}">
                                <app-scheduler-planner #schedulerPlanner
                                    [currentUser]="currentUser"
                                    [schedule]="schedule"
                                    [previousDay]="row*7 + $index === 0"
                                    [readOnly]="readOnly"
                                    [isMainDay]="row*7 + $index === 1"
                                    [zoomState]="zoomState"
                                    [index]="row*7 + $index"
                                    [users]="users"
                                    [startMonthIndex]="lastDate.startOf('month').localWeekday - 1"
                                    (addCurrentItem)="addCurrentItem($event)"
                                    (editItem)="editItem($event, row*7 + $index)"
                                    (itemAdded)="itemAdded($event)"
                                    (itemDeleted)="itemDeleted($event)"
                                    (movedAcrossDays)="movedAcrossDays($event)"
                                    (initiateReload)="reloadSchedule()"
                                    (jumpToDate)="zoomState = 's'; scrollTo($event)"
                                    (updateFinished)="updateFinished()">
                                </app-scheduler-planner>
                            </div>
                            }
                            @if (zoomState === 'm') {
                            <!-- calendar week -->
                            <div class="scheduler-planner-calendarweek">
                                <span class="scheduler-planner-calendarweek-num">{{getWeekNr(schedules[row * 7].date)}}</span>
                            </div>
                            }
                        </div>
                        }
                    </div>

                    <!-- @if (zoomState === 'm') {
                    <div class="scheduler-planner-calendarweek">
                        @for (weekNr of calendarWeeks; track weekNr) {
                        <span class="scheduler-planner-calendarweek-num">{{weekNr}}</span>
                        }
                    </div>
                    } -->
                </div>
                }

                @if (zoomState === 's') {
                <div class="scheduler-favorites">
                    <app-scheduler-planner-fav #schedulerPlannerFavorites
                        [currentUser]="currentUser"
                        [users]="users"
                        (editItem)="editItem($event, -1)"
                        [line]="0">
                    </app-scheduler-planner-fav>
                    <app-scheduler-planner-fav #schedulerPlannerFavorites
                        [currentUser]="currentUser"
                        [users]="users"
                        (editItem)="editItem($event, -1)"
                        [line]="1">
                    </app-scheduler-planner-fav>
                    <app-scheduler-planner-fav #schedulerPlannerFavorites
                        [currentUser]="currentUser"
                        [users]="users"
                        (editItem)="editItem($event, -1)"
                        [line]="2">
                    </app-scheduler-planner-fav>
                </div>
                }

                <div class="enlargepage-smaller"></div>
            </div>
        </as-split-area>

        <as-split-area [size]="splitSizes[1]">
            <!-- [ssf]="ssf" -->
            <!-- (storesLoaded)="storesLoaded($event)" -->
            <app-scheduler-input #schedulerInput
                [currentUser]="currentUser"
                [readOnly]="readOnly"
                [includePlannedInStock]="includePlannedInStock"
                (renderFinished)="updateSplitSize()"
                (inputFinished)="dataLoaded()"
                (cancelEdit)="onCancelEdit()"
                (saveEdit)="onSaveEdit($event)"
                (reloadSchedule)="reloadSchedule()"
                (machinesLoaded)="machinesLoaded($event)"
                (usersLoaded)="usersLoaded($event)"
                (newItem)="onNewItem($event)"
                (coffeeStockChanged)="coffeeStockChanged($event)">
            </app-scheduler-input>
        </as-split-area>
    </as-split>
</div>