{
	"Update available": "有可用更新",
	"Label changelog page": "有什么更新？",
	"UPDATE": "更新",
	"over limit": "超限",
	"Reminders menu label": "提醒事项",
	"active": "积极的",
	"Rename machines": "重命名机器",
	"{{mainUnit}} roasted": "{$INTERPOLATION} 烤",
	"hours of roasting": "烘烤数小时",
	"minutes of roasting": "烤分钟",
	"min": "分钟",
	"time": "时间",
	"h": "小时",
	"hour of roasting": "烘烤数小时",
	"weeks": "周",
	"years": "年",
	"day of the week": "一周中的天",
	"day of the month": "一个月中的哪一天",
	"propane consumed": " 消耗的气体",
	"On": "在",
	"After": "后",
	"Repetition": "重复",
	"Repetitions": "重复",
	"Maximum number of repetitions restricted by": "最大重复次数受",
	"repetitions possible with the current stock": "现有库存可能出现重复",
	"error retrieving all reminders": "检索所有提醒时出错",
	"daily": "日常的",
	"yearly": "每年",
	"on {{date}}": "[object Object]",
	"Condition": "状况",
	"starting from a date": "从",
	"Day": "日",
	"Week": "星期",
	"Completed Tasks": "已完成的任务",
	"Here you can see, and manually document, completed actions such as the routine cleaning of machines.": "在这里，您可以查看并手动记录已完成的操作，例如连续清洁机器。",
	"Reminder": "提醒",
	"Here you can let the system remind you of recurring tasks such as 'Clean thermocouple every week'.": "在这里，您可以让系统提醒您重复执行的任务，例如“每周清洁热电偶”。",
	"Every /n/ {{PLACEHOLDER}}": "每 /n/ {$INTERPOLATION}",
	"Every /WEEKDAY/": "每 /WEEKDAY/",
	"Every /nthDAYOFMONTH/ day of the month": "每月 /nthDAYOFMONTH/ 天",
	"(every n>=2) days": "天",
	"(every n>=2) weeks": "周",
	"(every n>=2) months": "个月",
	"(every n>=2) years": "年",
	"Propane bottle": "丙烷瓶",
	"Once {{kg}} have been roasted": "{$INTERPOLATION} 烤好后",
	"Clean fans": "清洁风扇",
	"Clean cooling tray": "清洁冷却盘",
	"Submit tax report": "提交税务报告",
	"Pay tax bill": "缴纳税单",
	"After each roast hour": "每烤一小时后",
	"expired since {{kg}}": "自 {$INTERPOLATION} 起已过期",
	"expired {{time}}": "过期{$INTERPOLATION}",
	"next {{time}}": "下一个{$INTERPOLATION}",
	"Every {{interval}} {{intervalUnit}}": "每 {$INTERPOLATION}{$INTERPOLATION_1}",
	"Every {{weekday}}": "每{$INTERPOLATION}",
	"Every {{dayOfMonth}}": "每{$INTERPOLATION}",
	"from {{date}}": "自 {$INTERPOLATION}",
	"After {{minutes}} roast minutes": "{$INTERPOLATION} 分钟烘烤后",
	"After {{hours}} roast hours": "{$INTERPOLATION} 小时烘烤后",
	"After {{n}} roasts": "{$INTERPOLATION} 次烘烤后",
	"{{interval}} to go": "还是 {$INTERPOLATION}",
	"Lubricate drum bearings": "润滑鼓轴承",
	"Clean exhaust ducting": "清洁排气管道",
	"Lubricate drum drive chain": "润滑鼓传动链",
	"Replace air filter": "更换空气过滤器",
	"Generate monthly roast report": "生成月度烘焙报告",
	"Replace gas bottle": "更换气瓶",
	"Prepare organic audit": "准备有机审计",
	"Empty chaff container": "清洁糠箱",
	"Once {{kg}} {{gasUnit}} propane consumed ({{btu}} {{btuUnit}})": "一旦消耗 {$INTERPOLATION}{$INTERPOLATION_1} 丙烷 ({$INTERPOLATION_2}{$INTERPOLATION_3})",
	"Notify all": "通知所有人",
	"Browser": "浏览器",
	"Notification": "通知",
	"Best add this email to your address book": "最好将此添加到您的地址簿",
	"OR": "或者",
	"After each roast": "每次烤完之后",
	"average FIFO cost comes from": "{$INTERPOLATION_1}/{$INTERPOLATION_2}的平均值来自您当前 {$INTERPOLATION_5}{$INTERPOLATION_6} 豆库存的 {$INTERPOLATION_4} 的当前成本",
	"Corrections are not listed": "未列出更正",
	"Maximum for display reached": "已达到显示最大值",
	"plural_discarded": "不计入",
	"from": "从",
	"Attribute": "属性",
	"Load / Save ...": "加载/保存...",
	"Click here to add more filters": "添加更多过滤器",
	"No amount set": "没有设定金额",
	"No yield set": "没有设定产量",
	"No beans set": "没有设置豆类",
	"No blends": "没有混合",
	"Specific bean in blends": "混合物中的特定豆",
	"missing value": "缺失值",
	"any value": "任意值",
	"any": "任何",
	"or": "或者",
	"and": "和",
	"not": "不是",
	"Summary": "概括",
	"List of roasts": "烘焙熟豆",
	"BT": "BT",
	"FC": "一爆",
	"Only one value found": "只找到一个值",
	"Share beans info": "分享豆子信息",
	"Copy to clipboard": "复制到剪贴板",
	"See what will be shared": "查看将要分享的内容",
	"Scheduler menu label": "日程",
	"5848348023107769657": "重量",
	"Title": "标题",
	"This is a system wide change. All existing roasts, templates, scheduled items etc. will be affected. Cannot be undone.": "这是系统范围内的变更。所有现有烘焙、模板、预定项目等都将受到影响。无法撤消。",
	"Caution! Chosen machine name already exists, you will not be able to separate roasts by machine after the change.": "注意！所选的机器名称已存在，更改后您将无法按机器区分烘焙。",
	"Cupping": "Cupping",
	"Always available as template": " 始终可用作模板 ",
	"Roasting Notes": "烘焙笔记",
	"Cupping Notes": "杯测笔记",
	"Search beans": "搜索豆",
	"Add as post blend": "添加为后混合",
	"Save": "节省",
	"Missing": "丢失的",
	"From history": "从历史来看",
	"Search templates": "搜索模板",
	"Add machines": "添加机器",
	"Favorites": "收藏夹",
	"Reload - check for changes": "重新加载-检查更改",
	"not specified": "未指定",
	"Reduce displayed stock by planned amounts": "按计划数量减少显示库存",
	"Search roast": "搜索烤肉",
	"Batch": "批",
	"Hauptzollamt Aachen": "亚琛市政厅",
	"Hauptzollamt Augsburg": "奥格斯堡总督察局",
	"Hauptzollamt Berlin": "柏林中央情报局",
	"Hauptzollamt Bielefeld": "比勒费尔德中央车站",
	"Hauptzollamt Braunschweig": "不伦瑞克中央情报局",
	"Hauptzollamt Bremen": "不来梅中央情报局",
	"Hauptzollamt Darmstadt": "达姆施塔特中央统计局",
	"Hauptzollamt Dortmund": "多特蒙德中央情报局",
	"Hauptzollamt Dresden": "德累斯顿中央统计局",
	"Hauptzollamt Duisburg": "杜伊斯堡中央车站",
	"Hauptzollamt Düsseldorf": "杜塞尔多夫中央停车场",
	"Hauptzollamt Erfurt": "埃尔福特中央车站",
	"Hauptzollamt Frankfurt (Oder)": "法兰克福 (奥得河畔) 中央车站",
	"Hauptzollamt Frankfurt am Main": "美因河畔法兰克福 Hauptzollamt",
	"Hauptzollamt Gießen": "吉森中央统计局",
	"Hauptzollamt Hamburg": "汉堡中央情报局",
	"Hauptzollamt Hannover": "汉诺威中央统计局",
	"Hauptzollamt Heilbronn": "海尔布隆中央统计局",
	"Hauptzollamt Itzehoe": "伊策霍埃总督府",
	"Hauptzollamt Karlsruhe": "卡尔斯鲁厄中央统计局",
	"Hauptzollamt Kiel": "基尔中央管制站",
	"Hauptzollamt Koblenz": "科布伦茨中央车站",
	"Hauptzollamt Köln": "科隆中央管制局",
	"Hauptzollamt Krefeld": "克雷菲尔德中央调度中心",
	"Hauptzollamt Landshut": "兰茨胡特中央情报局",
	"Hauptzollamt Lörrach": "罗拉赫中央服务局",
	"Hauptzollamt Magdeburg": "马格德堡中央情报局",
	"Hauptzollamt München": "慕尼黑中央情报局",
	"Hauptzollamt Münster": "明斯特中央车站",
	"Hauptzollamt Nürnberg": "纽伦堡中央调度办公室",
	"Hauptzollamt Oldenburg": "奥尔登堡总督察局",
	"Hauptzollamt Osnabrück": "奥斯纳布吕克中央情报局",
	"Hauptzollamt Potsdam": "波茨坦中央情报局",
	"Hauptzollamt Regensburg": "雷根斯堡中央停车场",
	"Hauptzollamt Rosenheim": "罗森海姆中央管制局",
	"Hauptzollamt Saarbrücken": "萨尔布吕肯中央服务局",
	"Hauptzollamt Schweinfurt": "施韦因富特中央车站",
	"Hauptzollamt Singen": "中央管制办公室",
	"Hauptzollamt Stralsund": "施特拉尔松德中央服务局",
	"Hauptzollamt Stuttgart": "斯图加特中央统计局",
	"Hauptzollamt Ulm": "乌尔姆市政厅",
	"of": "的",
	"Item cannot be updated, probably already synchronized with Artisan": "项目无法更新，可能已与 Artisan 同步",
	"Item cannot be updated, roasting has already started": "商品无法更新，烘焙已经开始",
	"Please enter a value for {{missingField}}": "请输入 {$INTERPOLATION} 的值",
	"Some items could not be deleted": "有些项目无法删除",
	"error updating the schedule information": "更新时间表信息时出错",
	"error updating the schedule": "更新时间表时出错",
	"error retrieving the schedule": "检索时间表时出错",
	"Beans used in blends": "混合咖啡中使用的咖啡豆",
	"Batches": "批次",
	"Batch size": "批次大小",
	"page not found text": "我们正在努力在这里添加您想要的内容！",
	"nothing to show plus hint, add with button": "当前没有任何可显示的内容。使用顶部的按钮添加！",
	"edit": "编辑",
	"producer": "生产者",
	"Supplier": "供应商",
	"Customer": "客户",
	"code": "代码",
	"Web": "网站",
	"loading fields": "正在加载地点...",
	"place": "地区",
	"add a new place": "新增地点",
	"street": "街道",
	"Address": "地址",
	"Phone": "电话",
	"Coupon": "优惠券",
	"Details": "细节",
	"Request code": "请求代码",
	"GET a COUPON": "获取优惠券",
	"Supplier Partners": "供应商合作伙伴",
	"Do you want to add the following beans?": "您要添加以下豆子吗？",
	"last seen at supplier on date": "最后一次出现于 {$INTERPOLATION_1} 的 {$INTERPOLATION}",
	"beans already exist will merge attributes": "这些豆子已存在于您的帐户中。新属性将被合并。",
	"Postal Code": "邮政编码",
	"city": "城市",
	"region": "地区",
	"subregion": "次区域",
	"Country": "国家",
	"close edit place mode": "关闭",
	"organizational_form": "组织形式",
	"farmowner": "农场主",
	"You have no hidden items.": "您没有隐藏的条目。",
	"Click here to find all hidden entries": "单击此处查找所有隐藏项",
	"Show only items with organic certification": "仅显示具有有机认证的条目",
	"Name": "名称",
	"Date": "日期",
	"Report": "报告",
	"Beans": "豆子",
	"Blend": "拼配",
	"Roast": "烘焙",
	"roast": "烘焙",
	"Sum": "小计",
	"ID": "ID",
	"Total": "总计",
	"Notes": "备注",
	"Check Online Status": "检查在线状态",
	"References": "备注",
	"Beans used in blends, header": "用于混合的豆子",
	"Yield": "产出",
	"Loss": "失重率",
	"Number": "编号",
	"Week {{week number}}, {{year}}": "第 {$INTERPOLATION} 周， {$INTERPOLATION_1}",
	"Quarter {{quarter number}}, {{year}}": "季度 {$INTERPOLATION} ， {$INTERPOLATION_1}",
	"error adding report": "添加报告时出错",
	"report dates are overlapping with report {{reportLabel}}": "报告日期与报告 {$INTERPOLATION} 重叠",
	"report with label {{label}} already exists": "名称为 {$INTERPOLATION} 的报告已存在",
	"report cannot be deleted: it is reconciled": "报告无法删除：已对帐",
	"no beans or blend": "没有豆子或拼配",
	"no amount": "没有数量",
	"no roast date": "没有烘焙日期",
	"weight or yield not positive": "重量或产出不为正",
	"no roast location": "缺失烘焙位置",
	"before selected period": "在选定时段之前",
	"unknown reason": "原因不明",
	"Roast Report": "烘焙报告",
	"Created by artisan.plus": "由artisan.plus创建",
	"no reports": "尚无报告。",
	"You can now switch accounts here": "您现在可以在这里切换帐户",
	"Users with full access to this account:": "拥有此帐户的完全访问权限的用户：",
	"Viewers with read-only access to this account:": "对此帐户具有只读访问权限的查看者：",
	"You can see the accounts of:": "您可以看到以下帐户：",
	"Metric": "公制",
	"Imperial": "英制",
	"t": "千",
	"none": "无",
	"Weight of a bag in kg?": "一袋的重量（公斤）？",
	"Weight of a barrel in kg?": "一桶的重量（公斤）？",
	"Weight of a box in kg?": "一箱的重量（公斤）？",
	"Weight of a bag in lbs?": "一袋的重量（磅）？",
	"Weight of a barrel in lbs?": "一桶的重量（磅）？",
	"Weight of a box in lbs?": "一箱的重量（磅）？",
	"yesterday": "昨天",
	"{{x}} days ago": "{$INTERPOLATION} 天前",
	"Purchase": "采购",
	"Sale": "销售",
	"Correction": "更正",
	"Transfer": "转移",
	"Purchases": "采购",
	"Sales": "销售",
	"Transfers": "转移",
	"Corrections": "更正",
	"Last correction": "最近更正",
	"Last purchase": "最近采购",
	"Last sale": "最近销售",
	"Last transfer": "最近转移",
	"UNKNOWN": "未知",
	"NEW": "新增",
	"Attention": "注意",
	"Please add at least one type of beans first.": "请先添加至少一种类型的豆子。",
	"A blend needs at least one type of beans.": "拼配至少需要一种豆子。",
	"Ratios must be positive and add up to 100%.": "比率必须为正，且总计为100％。",
	"0% ratios are not allowed.": "0％的比率无法使用。",
	"Please select beans or a blend.": "请选择豆子或拼配。",
	"Please enter an amount larger than 0.": "请输入数量大于0。",
	"Do you really want to delete {{name}}?": "您确认要删除 {$INTERPOLATION} 吗？",
	"Do you really want to delete all your data?!": "您真的要删除所有数据吗？",
	"Do you really want to delete your account and all your data?!": "您真的要删除您的帐户和所有数据吗？",
	"Do you really want to delete this {{transaction_type}}?": "您是否真的要删除此 {$INTERPOLATION} ？",
	"Do you really want to finalize this report and all contained roasts?": "您是否真的要定稿此报告以及所有相关熟豆？",
	"Do you really want to cancel the access to the account of {{nickname}}?": "您是否真的要取消对 {$INTERPOLATION} 帐户的访问？",
	"Set the stock of this coffee to 0 (consumed)?": "将此咖啡的库存设置为0（已用完）？",
	"Email sent. You can also forward the following link yourself": "邮件已发送。您也可以自己转发以下链接",
	"consumed": "已用完",
	"Color system": "色值系统",
	"Yield must be smaller than weight": "产量必须小于重量",
	"Ratio": "拼配比率",
	"Check out your new LINKSTART_supplier partner_LINKEND!": "查看您的新LINKSTART_供应商合作伙伴_LINKEND！",
	"Check out your {{newPartners}} new LINKSTART_supplier partners_LINKEND!": "查看您的 {$INTERPOLATION} 个新LINKSTART_供应商合作伙伴_LINKEND！",
	"No coupon available, please email info@artisan.plus": "没有可用的优惠券，请发送电子邮件至 info@artisan.plus",
	"Company name": "公司名称",
	"Company number": "公司编号",
	"Fahrenheit": "华氏度",
	"Celsius": "摄氏度",
	"bag": "袋",
	"plural_bag": "袋",
	"barrel": "桶",
	"plural_barrel": "桶",
	"box": "箱",
	"plural_box": "箱",
	"Stores": "仓库",
	"Producers": "生产者",
	"Suppliers": "供应商",
	"Customers": "客户",
	"Fields": "区域",
	"External Stores": "外部仓库",
	"Sun-grown": "日照种植",
	"Plantation": "种植园",
	"Garden": "花园",
	"Shade-grown": "遮阴种植",
	"Forest": "森林",
	"Wild Forest": "野生森林",
	"Organic Solvent": "有机溶剂",
	"Natural Decaffeination Process EA": "天然脱咖啡因过程 EA",
	"Methylene Chloride Process": "二氯甲烷法",
	"Triglyceride Process": "甘油三酸酯法",
	"Water Filtered": "水洗过滤",
	"Swiss Water Process": "瑞士水处理",
	"Mountain Water Process": "山区水洗处理",
	"Supercritical Fluid": "超临界流体",
	"Carbon Dioxide Process": "二氧化碳处理法",
	"Others": "其他",
	"decaffeinated": "低因",
	"European Preparation": "欧洲标准",
	"American Preparation": "美国准备",
	"Abu Dhabi": "阿布扎比",
	"Ajman": "阿治曼",
	"Azores and Madeira": "亚速尔群岛和马德拉",
	"Bonaire": "博内尔岛",
	"Caroline Islands": "卡罗琳群岛",
	"Ceuta": "休达",
	"Cocos Islands": "科科斯群岛",
	"Dubai": "迪拜",
	"E.C. unspecified": "EC 未指定",
	"Faeroe Islands": "法罗群岛",
	"Falkland Islands": "福克兰群岛",
	"Fujairah": "富查伊拉",
	"Gaza Strip": "加沙地带",
	"Holy See": "教廷",
	"Leeward Islands": "背风群岛",
	"Libyan Arab Jamahiriya": "阿拉伯利比亚民众国",
	"Macau": "澳门",
	"Melilla": "梅利利亚",
	"Netherlands Antilles": "荷属安的列斯",
	"Northern Marianas": "北马里亚纳群岛",
	"Ras al Khaimah": "哈伊马角",
	"Reunion": "团圆",
	"Sabah": "沙巴",
	"Sao Tome and Principe": "圣多美和普林西比",
	"Sarawak": "砂拉越",
	"Sharjah": "沙迦",
	"Svalbard and Jan Mayen Islands": "斯瓦尔巴群岛和扬马延群岛",
	"Swaziland": "斯威士兰",
	"Tahiti": "大溪地",
	"Umm al Qaiwain": "乌姆盖万",
	"Windward Islands": "向风群岛",
	"Galápagos": "加拉帕戈斯群岛",
	"Africa": "非洲",
	"Caribbean": "加勒比海",
	"Central America": "中美洲",
	"Europe": "欧洲",
	"Canary Islands": "加那利群岛",
	"South America": "南美洲",
	"Asia": "亚洲",
	"ANI": "ANI",
	"Bali": "巴里岛",
	"Borneo": "婆罗洲",
	"Flores": "弗洛雷斯",
	"Java": "爪哇",
	"Papua": "巴布亚",
	"St. Lucia": "圣露西亚",
	"Sulawesi": "苏拉威西岛",
	"Sumatra": "苏门答腊",
	"Sumbawa": "松巴哇岛",
	"Timor": "帝汶",
	"Oceania": "大洋洲",
	"Hawaii": "夏威夷",
	"Months": "月份",
	"Regions": "地区",
	"Find / Add ...": "添加/筛选...",
	"Accumulated Production": "累计产量",
	"Yearly Production": "年产量",
	"Ambient": "环境",
	"Machines": "机器",
	"Users": "用户",
	"User": "用户",
	"Weight": "重量",
	"Value": "价值",
	"Certification": "认证",
	"Water Activity": "水活性",
	"Origins": "产区",
	"with replacements": "有替代品",
	"estimated yield": "预期产量",
	"estimated amount": "预期数量",
	"month": "月",
	"batches": "分批",
	"batch": "批",
	"one batch": "一批",
	"days": "天",
	"{{count}} on one day": "{$INTERPOLATION} 在一天之内",
	"{{count}} on {{days}} days": "{$INTERPOLATION_1} 天的 {$INTERPOLATION}",
	"still ~{{month_left}} months": "仍余 {$INTERPOLATION} 个月",
	"still ~{{weeks_left}} weeks": "仍余 {$INTERPOLATION} 周",
	"still about one week": "还有大约一个星期",
	"still about one month": "还有大约一个月",
	"more than": "多于",
	"weekly": "每周",
	"monthly": "每月",
	"Monthly Production": "月产量",
	"This year": "今年",
	"Last year": "去年",
	"{{nrCounts}} roasts": "{$INTERPOLATION} 熟豆",
	"one roast": "一个熟豆",
	"one lot": "一柜",
	"{{nrCoffees}} lots": "{$INTERPOLATION} 批次",
	"1 month": "1个月",
	"{{nrMonth}} months": "{$INTERPOLATION} 个月",
	"1 year": "1年",
	"{{nrYears}} years": "{$INTERPOLATION} 年",
	"today": "现在",
	"stored": "库存",
	"roasted": "熟豆",
	"estimated age": "预期年数",
	"Age": "年数",
	"age": "年数",
	"yield": "产出",
	"amount": "数量",
	"expected total age": "预期总年数",
	"expected reach": "预期范围",
	"min reach": "最低范围",
	"max reach": "最高范围",
	"harvested": "产季",
	"social": "社会性",
	"organic": "有机",
	"fair": "公平贸易",
	"sustainable": "可持续",
	"bird friendly": "鸟类友好",
	"shade grown": "遮阴种植",
	"high quality": "高品质",
	"Afghanistan": "阿富汗",
	"Aland Islands": "奥兰群岛",
	"Albania": "阿尔巴尼亚",
	"Algeria": "阿尔及利亚",
	"American Samoa": "美属萨摩亚",
	"Andorra": "安道尔",
	"Angola": "安哥拉",
	"Anguilla": "安圭拉岛",
	"Antarctica": "南极洲",
	"Antigua and Barbuda": "安提瓜和巴布达",
	"Argentina": "阿根廷",
	"Armenia": "亚美尼亚",
	"Aruba": "阿鲁巴岛",
	"Australia": "澳大利亚",
	"Austria": "奥地利",
	"Azerbaijan": "阿塞拜疆",
	"Bahamas": "巴哈马",
	"Bahrain": "巴林",
	"Bangladesh": "孟加拉国",
	"Barbados": "巴巴多斯",
	"Belarus": "白俄罗斯",
	"Belgium": "比利时",
	"Belize": "伯利兹",
	"Benin": "贝宁",
	"Bermuda": "百慕大",
	"Bhutan": "不丹",
	"Bolivia": "玻利维亚",
	"Bonaire, Sint Eustatius and Saba": "博内尔，圣尤斯特歇斯和萨巴",
	"Bosnia and Herzegovina": "波斯尼亚和黑塞哥维那",
	"Botswana": "博茨瓦纳",
	"Bouvet Island": "布维岛",
	"Brazil": "巴西",
	"British Indian Ocean Territory": "英属印度洋领地",
	"Brunei Darussalam": "文莱达鲁萨兰国",
	"Bulgaria": "保加利亚",
	"Burkina Faso": "布基纳法索",
	"Burundi": "布隆迪",
	"Cape Verde": "佛得角",
	"Cambodia": "柬埔寨",
	"Cameroon": "喀麦隆",
	"Canada": "加拿大",
	"Cayman Islands": "开曼群岛",
	"Central African Republic": "中非共和国",
	"Chad": "乍得",
	"Chile": "智利",
	"China": "中国",
	"Christmas Island": "圣诞岛",
	"Cocos (Keeling) Islands": "科科斯（基林）群岛",
	"Colombia": "哥伦比亚",
	"Comoros": "科摩罗",
	"Congo, DR": "刚果民主共和国",
	"Congo, Republic": "刚果共和国",
	"Cook Islands": "库克群岛",
	"Costa Rica": "哥斯达黎加",
	"Croatia": "克罗地亚",
	"Cuba": "古巴",
	"Curaçao": "库拉索",
	"Cyprus": "塞浦路斯",
	"Czech Republic": "捷克共和国",
	"Denmark": "丹麦",
	"Djibouti": "吉布地",
	"Dominica": "多米尼加",
	"Dominican Republic": "多明尼加共和国",
	"Ecuador": "厄瓜多尔",
	"Egypt": "埃及",
	"El Salvador": "萨尔瓦多",
	"Equatorial Guinea": "赤道几内亚",
	"Eritrea": "厄立特里亚",
	"Estonia": "爱沙尼亚",
	"Eswatini": "埃斯瓦蒂尼",
	"Ethiopia": "埃塞俄比亚",
	"Falkland Islands [Malvinas]": "福克兰群岛[马尔维纳斯]",
	"Faroe Islands": "法罗群岛",
	"Fiji": "斐济",
	"Finland": "芬兰",
	"France": "法国",
	"French Guiana": "法属圭亚那",
	"French Polynesia": "法属波利尼西亚",
	"French Southern Territories": "法属南部领地",
	"Gabon": "加蓬",
	"Gambia": "冈比亚",
	"Georgia": "乔治亚州",
	"Germany": "德国",
	"Ghana": "加纳",
	"Gibraltar": "直布罗陀",
	"Greece": "希腊",
	"Greenland": "格陵兰",
	"Grenada": "格林纳达",
	"Guadeloupe": "瓜德罗普岛",
	"Guam": "关岛",
	"Guatemala": "危地马拉",
	"Guernsey": "根西岛",
	"Guinea": "几内亚",
	"Guinea-Bissau": "几内亚比绍",
	"Guyana": "圭亚那",
	"Haiti": "海地",
	"Heard Island and McDonald Islands": "希尔德岛和麦当劳群岛",
	"Honduras": "洪都拉斯",
	"Hong Kong": "香港",
	"Hungary": "匈牙利",
	"Iceland": "冰岛",
	"India": "印度",
	"Indonesia": "印度尼西亚",
	"Iran": "伊朗",
	"Iraq": "伊拉克",
	"Ireland": "爱尔兰",
	"Isle of Man": "马恩岛",
	"Israel": "以色列",
	"Italy": "意大利",
	"Ivory Coast": "象牙海岸",
	"Jamaica": "的牙买加",
	"Japan": "日本",
	"Jersey": "球衣",
	"Jordan": "约旦",
	"Kazakhstan": "哈萨克斯坦",
	"Kenya": "肯尼亚",
	"Kiribati": "基里巴斯",
	"North Korea": "北朝鲜",
	"South Korea": "南韩",
	"Kuwait": "科威特",
	"Kyrgyzstan": "吉尔吉斯斯坦",
	"Laos": "老挝",
	"Latvia": "拉脱维亚",
	"Lebanon": "黎巴嫩的",
	"Lesotho": "莱索托",
	"Liberia": "利比里亚",
	"Libya": "利比亚",
	"Liechtenstein": "列支敦士登",
	"Lithuania": "立陶宛",
	"Luxembourg": "卢森堡",
	"Macao": "澳门",
	"North Macedonia": "北马其顿",
	"Madagascar": "马达加斯加",
	"Malawi": "马拉维",
	"Malaysia": "马来西亚",
	"Maldives": "马尔代夫",
	"Mali": "马里",
	"Malta": "马耳他",
	"Marshall Islands": "马绍尔群岛",
	"Martinique": "马提尼克岛",
	"Mauritania": "毛里塔尼亚",
	"Mauritius": "毛里求斯",
	"Mayotte": "马约特岛",
	"Mexico": "墨西哥",
	"Micronesia": "密克罗尼西亚",
	"Moldova": "摩尔多瓦",
	"Monaco": "摩纳哥",
	"Mongolia": "蒙古",
	"Montenegro": "黑山共和国",
	"Montserrat": "蒙特塞拉特",
	"Morocco": "摩洛哥",
	"Mozambique": "莫桑比克",
	"Myanmar": "缅甸",
	"Namibia": "纳米比亚",
	"Nauru": "瑙鲁",
	"Nepal": "尼尼泊尔",
	"Netherlands": "荷兰",
	"New Caledonia": "新喀里多尼亚",
	"New Zealand": "新西兰",
	"Nicaragua": "尼加拉瓜",
	"Niger": "尼日尔",
	"Nigeria": "奈及利亚",
	"Niue": "纽埃",
	"Norfolk Island": "诺福克岛",
	"Northern Mariana Islands": "北马里亚纳群岛",
	"Norway": "挪威",
	"Oman": "阿曼",
	"Pakistan": "巴基斯坦",
	"Palau": "u琉",
	"Palestine": "巴勒斯坦",
	"Panama": "巴拿马",
	"PNG": "PNG",
	"Paraguay": "巴拉圭",
	"Peru": "秘鲁",
	"Philippines": "菲律宾",
	"Pitcairn": "皮特凯恩",
	"Poland": "波兰",
	"Portugal": "葡萄牙",
	"Puerto Rico": "波多黎各",
	"Qatar": "卡塔尔",
	"Réunion": "团圆",
	"Romania": "罗马尼亚",
	"Russian Federation": "俄罗斯联邦",
	"Rwanda": "卢旺达",
	"Saint Barthélemy": "圣巴泰勒米岛",
	"St. Helena": "圣海伦娜",
	"Saint Kitts and Nevis": "圣基茨和尼维斯",
	"Saint Lucia": "圣卢西亚",
	"Saint Martin (French part)": "圣马丁（法语部分）",
	"Saint Pierre and Miquelon": "圣皮埃尔和密克隆群岛",
	"St. Vincent": "圣文森特",
	"Samoa": "萨摩亚",
	"San Marino": "圣马力诺",
	"São Tomé": "圣多美",
	"Saudi Arabia": "沙特阿拉伯",
	"Senegal": "塞内加尔",
	"Serbia": "塞尔维亚",
	"Seychelles": "塞舌尔",
	"Sierra Leone": "塞拉利昂",
	"Singapore": "新加坡",
	"Sint Maarten (Dutch part)": "圣马丁（荷兰语部分）",
	"Slovakia": "斯洛伐克",
	"Slovenia": "斯洛文尼亚",
	"Solomon Islands": "所罗门群岛",
	"Somalia": "索马里",
	"South Africa": "南非",
	"South Georgia and the South Sandwich Islands": "南乔治亚岛和南桑威奇群岛",
	"South Sudan": "南苏丹",
	"Spain": "西班牙",
	"Sri Lanka": "斯里兰卡",
	"Sudan": "苏丹",
	"Suriname": "苏里南",
	"Svalbard and Jan Mayen": "斯瓦尔巴和扬·马延",
	"Sweden": "瑞典",
	"Switzerland": "瑞士",
	"Syrian Arab Republic": "阿拉伯叙利亚共和国",
	"Taiwan": "台湾",
	"Tajikistan": "塔吉克斯坦",
	"Tanzania": "坦桑尼亚",
	"Thailand": "泰国",
	"Timor, East": "东帝汶",
	"Togo": "多哥",
	"Tokelau": "托克劳",
	"Tonga": "汤加",
	"Trinidad and Tobago": "特立尼达和多巴哥",
	"Tunisia": "突尼斯",
	"Turkey": "火鸡",
	"Turkmenistan": "土库曼斯坦",
	"Turks and Caicos Islands": "特克斯和凯科斯群岛",
	"Tuvalu": "图瓦卢",
	"Uganda": "乌干达",
	"Ukraine": "乌克兰",
	"United Arab Emirates": "阿拉伯联合酋长国",
	"UK": "英国",
	"United States Minor Outlying Islands": "美国本土外小岛屿",
	"USA": "美国",
	"Uruguay": "乌拉圭",
	"Uzbekistan": "乌兹别克斯坦",
	"Vanuatu": "瓦努阿图",
	"Venezuela": "委内瑞拉",
	"Vietnam": "越南",
	"Virgin Islands (British)": "维尔京群岛（不列颠）",
	"Virgin Islands (U.S.)": "维尔京群岛（美国）",
	"Wallis and Futuna": "瓦利斯和富图纳群岛",
	"Western Sahara": "西撒哈拉",
	"Yemen": "也门",
	"Zambia": "赞比亚",
	"Zimbabwe": "津巴布韦",
	"Single farm": "单农场",
	"Cooperation": "合作社",
	"could not get report: {{error}}": "无法获得报告： {$INTERPOLATION}",
	"could not save PDF file: {{error}}": "无法保存PDF文件： {$INTERPOLATION}",
	"could not save the report: {{error}}": "无法保存报告： {$INTERPOLATION}",
	"could not delete the report: {{error}}": "无法删除报告： {$INTERPOLATION}",
	"could not update the report: {{error}}": "无法更新报告： {$INTERPOLATION}",
	"report not found": "找不到报告",
	"report already reconciled": "报告已对帐",
	"could not reconcile the report: {{error}}": "无法协调报告： {$INTERPOLATION}",
	"could not find the report with id {{report_id}}": "找不到ID为 {$INTERPOLATION} 的报告",
	"error creating PDF": "创建PDF时出错",
	"error creating CSV": "创建CSV时出错",
	"error creating Sheet": "创建工作表时出错",
	"error creating XML": "创建XML时出错",
	"Type": "类型",
	"Items per page": "每页条数",
	"Next page": "下一页",
	"Previous page": "上一页",
	"0 of {{length}}": "{$INTERPOLATION} 中的0",
	"{{startIndex}} - {{endIndex}} of {{length}}": "{$INTERPOLATION_2} 中的 {$INTERPOLATION} - {$INTERPOLATION_1}",
	"First page": "第一页",
	"Last page": "最后一页",
	"Please click on the link in the email we sent to {{email}}": "请点击我们发送给 {$INTERPOLATION} 的电子邮件中的链接",
	"Your login expired, please login again.": "您的登录已过期，请重新登录。",
	"Successfully removed": "成功移除",
	"Successfully added": "成功添加",
	"Successfully updated": "成功更新",
	"Successfully finalized": "成功完成",
	"Finalized": "最终确定的",
	"Successfully sent email to {{target_email}}": "已成功发送电子邮件至 {$INTERPOLATION}",
	"Nothing to change": "无改变",
	"Ignored since amount was zero": "由于数量为零而被忽略",
	"Are you connected to the Internet?": "您已连接到网络了吗？",
	"sum of ratios must be 100%, not {{sum}}": "比率总和必须是100％，而不是 {$INTERPOLATION}",
	"Your browser sent too many requests too quickly. Please slow down.": "您的浏览器过快地发送了太多请求。请减速。",
	"beans with label {{label}} already exist": "标签为 {$INTERPOLATION} 的豆子已存在",
	"Http failure response for https": "https的HTTP响应失败",
	"Not a valid file": "非有效文件",
	"Could not set new image": "无法设置新图片",
	"Error loading image. Please choose a new one!": "加载图片时出错。请选择一个新的！",
	"Could not update documents": "无法更新文件",
	"Invalid date": "失效日期",
	"{{imageName}} is not a valid image": "{$INTERPOLATION} 不是有效的图片",
	"password must not be empty": "密码不能为空",
	"repeated password does not match": "重复的密码不匹配",
	"could not update the password": "无法更新密码",
	"could not delete the data": "无法删除数据",
	"Invitation failed. Did you use the invitation link?": "邀请失败。您是否使用了邀请链接？",
	"You will LOSE ACCESS to all of your OWN existing data - you have {{nrExistingObjects}} objects": "您将丢失对所有自己的现有数据的访问权限-您有 {$INTERPOLATION} 个对象",
	"If you do not want this, cancel and ask the person who invited you to use another of your email addresses": "如果您不想这样，请取消并要求您的邀请人使用您的其他邮件地址",
	"Could not reach server.": "无法访问服务器。",
	"Please repeat the password reset process": "请重复密码重置过程",
	"Could not send verification email": "无法发送验证邮件",
	"this does not belong to current user": "这不属于当前用户",
	"cannot update since a related item is already finalized": "由于相关项目已经完结，因此无法更新",
	"error updating the beans information": "更新豆子信息时出错",
	"error updating the roast information": "更新熟豆信息时出错",
	"error updating the blend information": "更新拼配信息时出错",
	"error updating the store information": "更新商店信息时出错",
	"error updating the stock information": "更新库存信息时出错",
	"error updating the information": "更新信息时出错",
	"error updating user information": "更新用户信息时出错",
	"error retrieving all stores": "检索所有商店时出错",
	"error retrieving all locations": "检索所有位置时出错",
	"error retrieving all beans": "检索所有豆子时出错",
	"error retrieving all blends": "检索所有拼配时出错",
	"error retrieving all customers": "检索所有客户时出错",
	"error retrieving reports": "错误检索报告",
	"error retrieving graph data": "错误检索图形数据",
	"error retrieving all roasts": "检索所有烘焙时出错",
	"error adding store": "添加仓库时出错",
	"error adding purchase": "添加采购时出错",
	"error adding correction": "增加更正时出错",
	"error adding transfer": "添加转移时出错",
	"error adding sale": "添加销售时出错",
	"error editing store": "编辑仓库时出粗",
	"error editing purchase": "编辑采购时出错",
	"error editing correction": "编辑更正时出错",
	"error editing transfer": "编辑转移时出错",
	"error editing sale": "编辑销售时出错",
	"error deleting purchase": "删除采购时出错",
	"error deleting correction": "删除更正时出错",
	"error deleting transfer": "删除转移时出错",
	"error deleting sale": "删除销售时出错",
	"no documents were uploaded": "没有文件上传",
	"server error": "服务器错误",
	"error": "错误",
	"User with email {{email}} not found": "找不到电子邮件为 {$INTERPOLATION} 的用户",
	"Email not verified. Please check the email sent by admin@artisan.plus": "电子邮件未验证。请检查admin@artisan.plus发送的电子邮件",
	"Your account does not have enough credit. Please recharge at {{link}}": "您的帐户额度不足。请前往 {$INTERPOLATION} 充值",
	"wrong email or password": "错误的邮箱或者密码",
	"wrong password given": "输入的密码错误",
	"User with email {{email}} already exists": "电子邮件为 {$INTERPOLATION} 的用户已存在",
	"you are not allowed to access this data": "您无权访问此数据",
	"user not found": "找不到用户",
	"not found": "未找到",
	"producer with label {{label}} already exists": "标签为 {$INTERPOLATION} 的生产者已经存在",
	"could not add producer {{label}}": "无法添加生产者 {$INTERPOLATION}",
	"could not update producer {{label}}": "无法更新生产者 {$INTERPOLATION}",
	"could not update supplier {{label}}": "无法更新供应商 {$INTERPOLATION}",
	"could not update customer {{label}}": "无法更新客户 {$INTERPOLATION}",
	"could not update roast {{label}}": "无法更新烘焙 {$INTERPOLATION}",
	"could not update coffee {{label}}": "无法更新咖啡 {$INTERPOLATION}",
	"location with label {{label}} already exists": "标签为 {$INTERPOLATION} 的位置已存在",
	"could not add beans {{label}}": "无法添加豆子 {$INTERPOLATION}",
	"supplier with label {{label}} already exists": "标签为 {$INTERPOLATION} 的供应商已存在",
	"blend with label {{label}} already exists": "标签为 {$INTERPOLATION} 的拼配已经存在",
	"customer with label {{label}} already exists": "标签为 {$INTERPOLATION} 的客户已经存在",
	"stock update failed": "库存更新失败",
	"could not save image file {{error}}": "无法保存图像文件 {$INTERPOLATION}",
	"wrong email verification link": "错误的电子邮件验证链接",
	"Email verify failed": "电子邮件验证失败",
	"Hidden beans with this name + picked year + origin already exist, {{info}}": "具有该名称+采摘年份+产地的隐藏豆已经存在， {$INTERPOLATION}",
	"Beans with this name + picked year + origin already exist, {{info}}": "具有该名称+采摘年份+产地的豆子已经存在， {$INTERPOLATION}",
	"Failed updating blend templates": "无法更新拼配模板",
	"user data could not be downloaded": "用户数据无法下载",
	"Update available, please do a browser refresh (CMD-SHIFT-R or CTRL-SHIFT-F5)": "有可用的更新，请刷新浏览器（CMD-SHIFT-R或CTRL-SHIFT-F5）",
	"location not found": "找不到位置",
	"could not find beans": "找不到豆子",
	"roast not found": "找不到烘焙",
	"store not found": "找不到仓库",
	"purchase not found": "找不到采购",
	"transfer not found": "找不到转移",
	"sale not found": "找不到销售",
	"correction not found": "找不到更正",
	"erroneous altitude range": "错误的海拔范围",
	"erroneous screen size range": "屏幕尺寸错误范围",
	"must specify target of transfer": "必须指定转移目标",
	"must specify location of change": "必须指定变更地点",
	"must specify the beans to be changed": "必须指定要更改的豆子",
	"roast must not have both beans and blend": "烘焙不能同时有豆子和拼配",
	"invalid ID given for Location": "指定的位置ID无效",
	"error retrieving a store": "检索仓库时出错",
	"trying to get location": "试图找到位置",
	"error retrieving stock of a store": "检索商店库存时出错",
	"error retrieving all store stocks": "检索所有仓库库存时出错",
	"error retrieving all purchases for the beans": "检索豆子的所有购买时出错",
	"error adding the custom selection property": "添加自定义选择属性时出错",
	"error adding the region": "添加区域时出错",
	"error updating the additional report information": "更新其他报告信息时出错",
	"could not identify the user": "无法识别用户",
	"could not find the user": "找不到用户",
	"could not update the user account": "无法更新用户帐户",
	"could not update the user": "无法更新用户",
	"user is not associated with an account. Please send an email to admin@artisan.plus": "用户未与帐户关联。请发送电子邮件至admin@artisan.plus",
	"Your account is not activated. Please send an email to admin@artisan.plus": "您的帐户尚未激活。请发送电子邮件至admin@artisan.plus",
	"missing or invalid start or end date": "缺少或无效的开始或结束日期",
	"missing or invalid date": "缺少日期或日期无效",
	"no user information given": "没有提供用户信息",
	"no email given": "没有提供邮箱",
	"no password given": "没有提供密码",
	"no nickname given": "没有提供昵称",
	"could not send email": "无法发送电子邮件",
	"wrong password reset link": "错误的密码重置链接",
	"not allowed to change someone else\\'s password": "不允许更改他人的密码",
	"could not create account with customer_code {{ccode}}": "无法使用customer_code {$INTERPOLATION} 创建帐户",
	"could not add user": "无法添加用户",
	"successfully uploaded data but could not set password": "成功上传数据，但无法设置密码",
	"could not retrieve user from id {{userId}}": "无法从ID {$INTERPOLATION} 检索用户",
	"object with id {{id}} not found": "找不到ID为 {$INTERPOLATION} 的对象",
	"{{model}} with id {{_id}} not found": "找不到ID为 {$INTERPOLATION_1} 的 {$INTERPOLATION}",
	"trying to get {{model}} by name {{label}}": "尝试获取名称为 {$INTERPOLATION_1} 的 {$INTERPOLATION}",
	"could not add producer location {{label}}": "无法添加生产者位置 {$INTERPOLATION}",
	"location with {{label and type info}} already exists": "{$INTERPOLATION} 的位置已存在",
	"could not add location {{label}}": "无法添加位置 {$INTERPOLATION}",
	"could not update location {{label}}": "无法更新位置 {$INTERPOLATION}",
	"could not update location of {{producerLabel}}": "无法更新 {$INTERPOLATION} 的位置",
	"could not add supplier {{label}}": "无法添加供应商 {$INTERPOLATION}",
	"could not add customer {{label}}": "无法添加客户 {$INTERPOLATION}",
	"could not find blend with ID {{blend}}": "找不到ID为 {$INTERPOLATION} 的拼配",
	"trying to get {{model}}": "试图获得 {$INTERPOLATION}",
	"invalid ID given for {{property}}": "为 {$INTERPOLATION} 给出了无效的ID",
	"could not make related updates": "无法进行相关更新",
	"stock of beans at location {{location}} could not be updated": "无法更新位置 {$INTERPOLATION} 处的豆子库存",
	"value of beans at location {{location}} could not be calculated": "无法计算位置 {$INTERPOLATION} 上的豆子价值",
	"could not update property {{error}}": "无法更新属性 {$INTERPOLATION}",
	"roast with roast_id {{roast_id}} not found": "找不到带有roast_id {$INTERPOLATION} 的烘焙",
	"rejected deleting all {{model}}": "拒绝删除所有 {$INTERPOLATION}",
	"could not check if {{model}} exists in DB": "无法检查数据库中是否存在 {$INTERPOLATION}",
	"logged in user not the same as user to be deleted": "登录的用户与要删除的用户不同",
	"logged in user not the same as user to be updated": "登录的用户与要更新的用户不同",
	"no data": "没有数据",
	"could not delete location": "无法删除位置",
	"could not un-reconcile a transaction in the report": "无法取消对报表中的交易进行核对",
	"undefined": "不明确",
	"error retrieving information": "错误检索信息",
	"dot seperator": "格式：12.34kg（点）",
	"comma seperator": "格式：12,34kg（逗号）",
	"Light mode": "明亮模式",
	"Auto": "自动",
	"Dark mode": "深夜模式",
	"Sharing options": "共享选项",
	"admin user": "（管理员）",
	"pending user": "（进行中）",
	"resend invitation": "-重新发送邀请",
	"Add user to account": "将用户添加到您的帐户",
	"unit system": "单位制",
	"temperature system": "温度单位",
	"currency": "货币单位",
	"paid until": "有效至 {$INTERPOLATION}",
	"your config": "您的配置",
	"Data Export Settings (CSV, Excel)": "数据导出设置（CSV，Excel）",
	"Separated by": "分类为",
	"Tab": "标签",
	"Comma": "逗号",
	"Semicolon": "分号",
	"Space": "空间",
	"Line separator": "行分隔符",
	"Windows": "视窗",
	"Unix / macOS": "Unix / macOS",
	"Decimal Separator": "小数点分隔符",
	"Reports Settings": "报告设定",
	"Variant": "变量",
	"Change your password": "更改您的密码",
	"download data heading": "下载资料",
	"download all data": "将所有数据导出为文件。",
	"delete data heading": "删除资料",
	"reset": "重设帐户：保留您的帐户，但永久删除所有数据。无法撤消。",
	"delete account": "删除您的帐户：删除所有数据，包括您的帐户。无法撤消。",
	"Loading roasts": "载入烘焙中...",
	"done": "完成",
	"Amount": "数量",
	"total cost": "总成本",
	"Cost per": "单位成本",
	"unit_specification": "包装单位",
	"unit_specification_2": "1 {$INTERPOLATION} 有多少公斤/磅？",
	"Add new beans": "添加新豆",
	"wizard heading": "让我们开始吧！",
	"location explanation": "您怎么称呼您存放生豆的地方：例如中央工厂、门店1、厨房……“",
	"coffee explanation": "您在 {$INTERPOLATION} 处拥有哪种类型的豆子？",
	"button add more": "新增更多",
	"your locations": "您的门店",
	"select location": "选择现有位置",
	"new location": "新地点",
	"new location label": "新增位置",
	"next step": "下一步",
	"Your Beans": "您的豆子",
	"select existing beans": "选择现有的豆",
	"New beans": "新豆子",
	"coffee amount explanation": "您在 {$INTERPOLATION_1} 处有多少 {$INTERPOLATION} ？",
	"select supplier": "选择现有供应商",
	"new supplier": "新供应商",
	"question for supplier": "找谁拿货（供应商）？",
	"wizard finished": "太棒了！下一步？",
	"Clipboard": "剪贴板",
	"Export": "输出",
	"Last modified": "最近修改",
	"all": "全部",
	"Loading beans": "载入豆子...",
	"last changed": "最近修改 {$INTERPOLATION}",
	"updated by": "由 {$INTERPOLATION}",
	"Added {{date}}": "添加于 {$INTERPOLATION}",
	"created by": "由 {$INTERPOLATION}",
	"Store": "仓库",
	"Show only beans that are not hidden": "仅显示未隐藏的豆子",
	"loading reports": "正在加载报告...",
	"Show zero amounts": "显示零数量",
	"Per bean": "每个豆子",
	"Per store": "每个仓库",
	"Cost": "成本",
	"per": "每",
	"deleted": "已删除",
	"Reason": "原因",
	"Target store": "目标仓库",
	"roasts": "熟豆",
	"creating PDF": "创建PDF ...",
	"download PDF": "下载PDF",
	"update PDF": "更新生成的PDF",
	"finalize": "完成",
	"Customs": "海关",
	"Stocks": "库存",
	"Roasts": "熟豆",
	"no match for filter": "找不到匹配项",
	"Filter ...": "添加 ...",
	"Amount of beans available": "可用：",
	"Blends": "拼配",
	"Change in all blend templates?": "应用到所有拼配模板？",
	"Stock": "库存",
	"incl. replacement ingredients": "包括替代成分",
	"Replace by": "替换为",
	"Add/Change replacement ingredients": "添加/更改 替代成分",
	"Undo replace": "撤消替换",
	"sort order user": "用户",
	"Repeat every": "重复每一个",
	"Repeat on": "重复",
	"Ends": "结束于",
	"Filter": "筛选器",
	"roast date": "烘焙日期",
	"batch number": "批次编号",
	"Machine": "机器",
	"Show roasts from": "显示熟豆来自",
	"report": "报告",
	"Open in Artisan": "在工匠中打开",
	"Open in Artisan as background template (Artisan >2.4.4)": "在 Artisan 中打开作为背景模板（Artisan >2.4.4）",
	"grams per liter": "克/升",
	"Weight in {{mainUnit}}": "重量r=\"投豆量“",
	"no beans": "请先添加豆子！",
	"no stores": "请先添加仓库！",
	"Batch Prefix / Number / Position": "批次前缀/编号/位置",
	"Prefix": "前缀",
	"Position": "位置",
	"Energy": "火力",
	"Ground color": "粉值",
	"Whole color": "豆值",
	"Density": "密度",
	"new blend name": "新拼配名称",
	"use blend template": "使用 {$INTERPOLATION}",
	"CHARGE": "投豆",
	"DROP": "排豆",
	"DEV": "发展",
	"Temperature": "温度",
	"Pressure": "压力",
	"Humidity": "环境湿度",
	"CM ET/BT": "CM ET/BT",
	"AUC": "AUC",
	"Template": "模板",
	"roast yield": "产出量",
	"loss": "失重率",
	"reconciled roast info": "此项目已被添加到最终报告中，大多数内容无法再更改。",
	"Moisture": "生豆含水量",
	"discarded": "不计入",
	"Year": "年",
	"close edit mode": "关闭",
	"clone": "建立副本",
	"Import Beans Data": "导入 Beans 数据",
	"add a new field": "新增栏位",
	"reference": "参考",
	"loading certifications": "正在加载认证...",
	"add initial stock": "添加初始库存：",
	"crop_date_picked": "采摘",
	"crop_date_landed": "到港",
	"lot": "批次",
	"ICO details": "ICO产区 - 出口商 - 批次",
	"ICO": "ICO",
	"grade": "等级",
	"altitude_minmax": "最低/最高海拔",
	"altitude": "高度",
	"screen_size_minmax": "豆目最小/最大",
	"screen_size": "豆目",
	"score": "分数",
	"Species": "树种",
	"Varietals": "品种",
	"cultivation": "培育品种",
	"harvesting": "产季",
	"Processing": "处理法",
	"fermentation": "发酵",
	"drying": "干燥",
	"decaffeination": "低因咖啡",
	"ageing": "年数",
	"packaging": "包装",
	"Selection": "筛选",
	"water_activity": "水活性",
	"defects": "瑕疵",
	"flavors": "风味",
	"crop_yield": "年产量",
	"loading origins": "正在加载产区...",
	"Origin": "产区",
	"Origin differs from ICO origin": "与ICO产区不同！",
	"certifications": "认证",
	"loading producers": "正在加载生产者...",
	"loading suppliers": "正在加载供应商...",
	"add a new producer": "添加新的生产者",
	"field": "场地",
	"add a new customer": "添加新客户",
	"add sale": "添加销售",
	"edit sale": "编辑销售",
	"from source store": "从",
	"no stores with beans": "您没有任何有豆子的仓库。",
	"rename": "重命名",
	"pick customer from list": "选择客户",
	"select coffee": "请先选择豆子！",
	"total price": "总价",
	"Price per": "单价",
	"tracking_number": "物流单号",
	"product_id": "产品编号",
	"add a new supplier": "添加新供应商",
	"add purchase": "添加采购",
	"edit purchase": "编辑采购",
	"pick beans from list": "选择豆子",
	"pick supplier from list": "选择供应商",
	"last purchase/correction price info": "{$INTERPOLATION}{$INTERPOLATION_1} 的 {$INTERPOLATION_2} ·于 {$INTERPOLATION_3}",
	"no last purchase": "无有效的历史采购价格。",
	"Loading last purchase price": "正在加载最近的采购...",
	"start date": "从",
	"end date": "至",
	"invalid transactions not included": "由于缺少重要信息，因此以下交易将不包含在报告中：",
	"not included explanation": "日期在本报告范围内但并未包括在内的条目：",
	"added or modified after the report has been created": "在创建报告后添加或修改",
	"You can freely choose your report intervals here.": "您可以在此处自由选择报告间隔。",
	"Reports created here must be consecutive (no overlaps or gaps). They can be finalized for official purposes.": "此处创建的报告必须是连续的（没有重叠或空白）。它们最终可用于官方目的。",
	"Soon you will see an overview of your beans here; and more, stay tuned!": "很快您将在这里看到您的豆子的相关概述以及更多，敬请期待！",
	"allow empty weights": "允许空缺重量",
	"invalid roasts not included": "由于重要信息缺失，以下烘焙将不包含在报告中：",
	"Missing items": "缺失对象",
	"roasts not in range are excluded": "由于以下烘焙时间在所选时间段之前，因此不会包含在报告中：",
	"Sequential": "排序",
	"Open": "打开",
	"add account": "新增帐户",
	"you have been invited": "您已受邀加入 {$INTERPOLATION} 的帐户",
	"you have been invited, readonly": "邀请您查看 {$INTERPOLATION} 帐户的数据",
	"register_new_password": "新密码",
	"add user to account": "将用户添加到您的帐户",
	"invite for read-only access": "邀请进行只读访问",
	"User with email already invited": "电子邮件为 {$INTERPOLATION} 的用户已被邀请",
	"invalid email": "无效邮箱",
	"get full access": "此人将获得对您数据的完全访问权限。",
	"get read-only access": "此人可以读取您的所有数据。",
	"for price": "为",
	"from source": "从",
	"cannot delete": "无法删除！",
	"cannot delete because of references": "{$INTERPOLATION} 由于正在使用中而无法删除。",
	"order_number": "订单号",
	"sales_number": "销售编号",
	"Yield in {{mainUnit}}": "{$INTERPOLATION} 的产出",
	"to target": "至",
	"amount header": "数量",
	"limit header": "库存警报限制",
	"no purchases made": "没有产生采购。成本仅依据采购来计算。请考虑添加采购或用采购来替换更正。",
	"purchase": "采购",
	"sell": "出售",
	"transfer": "转移",
	"correct": "更正",
	"store with xkg of beans": "有 {$INTERPOLATION} 的豆子",
	"bought beans": "您买了豆子",
	"add a purchase": "添加一个采购",
	"sold beans": "您卖了豆子",
	"add a sale": "添加一个销售",
	"moved beans": "您转仓了豆子",
	"add a transfer": "添加一个转移",
	"other reason": "其他原因",
	"correction title": "更正您的库存",
	"edit correction title": "编辑更正",
	"new weight in": "新增重量 ( {$INTERPOLATION} )",
	"new amount": "新增数量",
	"delta weight in": "重量差异 ( {$INTERPOLATION} )",
	"delta amount": "数量差异",
	"show delta amounts": "手动输入重量差异",
	"add transfer": "添加转移",
	"edit transfer": "编辑转移",
	"Please select a source store first!": "请先选择货源仓库！",
	"to customer": "至",
	"list header": "交易次数： {$INTERPOLATION}",
	"x% of a blend": "（ {$INTERPOLATION} 的拼配 ）",
	"of a blend": "拼配的",
	"between dates": "在 {$INTERPOLATION} 和 {$INTERPOLATION_1} 之间",
	"are you sure": "您确定吗？",
	"Upload documents": "上传文件",
	"Fill with": "填充",
	"Graphs of your data are shown here as soon as there is enough data": "数据充分时，此处会立刻显示出您的数据图表。",
	"paid until, extend here": "有效至 {$INTERPOLATION} （在此处扩展）",
	"legal notice": "法律声明",
	"privacy statement": "隐私政策",
	"Shop": "商店",
	"Beans menu label": "豆子",
	"Blends menu label": "拼配",
	"Roasts menu label": "烘焙",
	"Contacts menu label": "联系人",
	"Reports menu label": "报告",
	"visit our store": "请访问我们的商店以延长您的订购",
	"not paid": "已到期",
	"0 paid days left": "还剩0天",
	"1 paid days left": "仅剩1天",
	"2 paid days left": "剩余2天",
	"3 paid days left": "剩余3天",
	"No changes possible": "无法更改",
	"greeting": "你好",
	"Your account": "您的帐户",
	"logout": "登出",
	"for": "前往",
	"register link": "注册",
	"You can always visit our shop to extend your subscription": "您可以随时访问我们的商店延长您的订购",
	"login link": "登录",
	"email": "邮箱",
	"password": "密码",
	"reset_password": "重设密码",
	"login button": "登录",
	"resend verification email": "重新发送验证电子邮件",
	"Success! You can now log in with your new password.": "成功！现在，您可以使用新密码登录。",
	"register_password_repeat": "重复输入密码",
	"update password": "更新密码",
	"nickname": "昵称",
	"register button": "注册",
	"Partner Coupons": "合作伙伴优惠券",
	"accept privacy": "我接受隐私政策：",
	"read here": "读取",
	"Set stock to 0": "将库存设为0",
	"inventory management": "库存管理",
	"How can artisan.plus support you?": "artisan.plus 如何帮助您？",
	"every roast automatically reduces your stock": "每次烘焙都会自动更新您的库存",
	"simplifies planning": "简化烘焙计划和执行",
	"roast even without an internet connection": "无论有无互联网连接均可使用",
	"keeps your roast profiles private": "保持您的烘焙资料私密",
	"includes unlimited users and machines": "包括无限的用户和机器",
	"blends and certifications": "维护混合和跟踪认证",
	"charts and tax reports": "提供图表、税务报告和预测",
	"imports coffee descriptions": "支持从导入器导入 bean 数据",
	"3 months free testing": "3 个月免费-享受所有功能，每月 42 欧元起",
	"includes unlimited warehouses and bean quantities": "包括无限的仓库和豆子数量",
	"description 1": "我的豆子还能用多久？",
	"description 2": "我的库存值多少钱？",
	"description 3": "我有什么类型的豆子？",
	"description 4": "如何高效管理我的烘焙？",
	"description 5": "我的进展如何？",
	"description 6": "如何生成报告？",
	"Month": "月",
	"Do not show this again": "不再显示",
	"Can be edited in": "前往编辑",
	"You can COPY or MOVE all your roast reports from the 'Sequential' section here:": "您可以在此处“后续”板块中，[复制]或[移动]所有您的烘焙报告："
}