import { ApToolbarModule } from 'src/app/modules/ui/ap-toolbar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LegalRoutingModule } from './legal-routing.module';
import { ImprintComponent } from './imprint.component';
import { PrivacyComponent } from './privacy.component';
import { LegalFrameComponent } from './legal-frame.component';

@NgModule({
    declarations: [
        ImprintComponent,
        PrivacyComponent,
        LegalFrameComponent,
    ],
    imports: [
        CommonModule,
        LegalRoutingModule,
        ApToolbarModule,
    ]
})
export class LegalModule { }
