import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/util/alert/alert.service';
import { StandardService } from 'src/app/util/services/standard.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Utils } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-ap-hide',
    templateUrl: './ap-hide.component.html',
    styleUrls: ['./ap-hide.component.scss']
})
export class ApHideComponent implements OnDestroy {

    constructor(
        private standardService: StandardService,
        private utils: Utils,
        private alertService: AlertService,
        private tr: TranslatorService,
    ) { }

    private _objToHide: { _id: string, hidden?: boolean };
    get objToHide(): { _id: string, hidden?: boolean } { return this._objToHide; }
    @Input() set objToHide(obj: { _id: string, hidden?: boolean }) {
        this._objToHide = obj;
        this.hidden = this._objToHide?.hidden;
    }
    @Input() objModelPath: string;

    @Output() objHideChanged = new EventEmitter<{ _id: string, hidden: boolean }>();

    hidden = false;
    loading = false;

    private ngUnsubscribe = new Subject();

    // ngOnInit(): void {
    // }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    hiddenChanged(): void {
        this.loading = true;
        const updObj = { _id: this.objToHide._id, hidden: !this.hidden };
        this.standardService.update(this.objModelPath, updObj)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response) {
                        // nothing changed
                        this.alertService.success(this.tr.anslate('Nothing to change'));
                        this.loading = false;
                        return;
                    }

                    if (response.success === true) {
                        if (response?.result) {
                            this.objToHide = response.result;
                            this.hidden = !this.hidden;
                        }
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                    } else {
                        this.utils.handleError('error updating the information', response.error);
                    }
                    this.objHideChanged.emit(updObj);
                },
                error: error => {
                    this.loading = false;
                    this.utils.handleError('error updating the information', error);
                }
            });
    }
}
