import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { StockChange } from 'src/app/models/StockChange';
import { MatTableDataSource } from '@angular/material/table';
import { UserType, UserService } from 'src/app/modules/frame/services/user.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { NGXLogger } from 'ngx-logger';
import { StandardService } from 'src/app/util/services/standard.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { trigger, state, style, transition, sequence, animate } from '@angular/animations';
import { Enumerations } from 'src/app/models/Enumerations';
import { Subject } from 'rxjs';
import { debounceTime, startWith, distinctUntilChanged, skip, throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TransService } from 'src/app/modules/transaction/trans.service';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { CantDeleteDialogComponent } from '../../ui/dialog/cant-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { Roast } from 'src/app/models/Roast';

@Component({
    selector: 'app-beansreport-table',
    templateUrl: './beansreport-table.component.html',
    styleUrls: ['./beansreport-table.component.scss'],
    animations: [
        trigger('animateField', [
            state('idle', style({
                backgroundColor: '#00000000'
            })),
            state('saved', style({
                backgroundColor: '#00000000'
            })),
            transition('idle => saved', sequence([
                style({ color: 'black' }),
                animate(400, style({ backgroundColor: '#00FF0030' })),
                animate(400, style({ backgroundColor: '#00000000' })),
            ]))
        ])
    ],
})
export class BeansreportTableComponent implements OnInit, OnDestroy {

    constructor(
        public utils: Utils,
        private userService: UserService,
        private logger: NGXLogger,
        private standardService: StandardService,
        private transService: TransService,
        private dialog: MatDialog,
        public tr: TranslatorService,
        private router: Router,
    ) { }

    @Input() currentUser: UserType;
    @Input() reportName: string;
    @Input() columnsToDisplay: string[];
    @Input() additionalIsEditable = false;
    @Input() readOnly = false;
    @Input() editMode = false;

    private _dataSource: MatTableDataSource<StockChange>;
    get dataSource(): MatTableDataSource<StockChange> { return this._dataSource; }
    @Input() set dataSource(ds: MatTableDataSource<StockChange>) {
        this._dataSource = ds;
        this.updateDataSource();
    }
    @Output() reportChanged = new EventEmitter<StockChange[]>();
    @Output() totalsChanged = new EventEmitter<{ showTotals: boolean, numberOf: number, sum: number, sumPrice: number }>();

    showTotals = true;
    animateFieldState = 'idle';
    additionalNoteChanged: Subject<StockChange>[] = [];
    checkingIfDeletable: number = undefined;

    sum = 0;
    sumPrice = 0;

    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';

    private ngUnsubscribe = new Subject();

    // @ViewChild('table') table: MatTable<any>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    DateTime = DateTime;


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser?.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    updateDataSource(): void {
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'Date': return item.date;
                case 'Cost': return item.price;
                case 'Transaction-ID': return item['order_number'] || item['sales_number'];
                case 'Supplier': return item['supplier'] ? item['supplier']['label'] : undefined;
                case 'Customer': return item['customer'] ? item['customer']['label'] : undefined;
                case 'Target Store': return item['target'] ? item['target']['label'] : undefined;
                case 'Coffees': return item.coffee ? item.coffee.hr_id : undefined;
                case 'Store': return item.location ? item.location.hr_id + item.location.label : undefined;
                case 'Amount': return item.amount;
                case 'Reason': return item['reason'];
                case 'InvalidReason': return item['invalidReason'];
                case 'References': return item.reference;
                default: return item[property];
            }
        };
        if (this.sort) {
            this.dataSource.sort = this.sort;
        }
        this.recalc();
    }

    recalc(): void {
        const transactions = this.dataSource.data;
        let thesum = 0;
        let thePricesum = 0;
        this.showTotals = true;
        this.additionalNoteChanged.length = 0;
        // let lastType = transactions.length ? transactions[0].__t : undefined;
        for (const trans of transactions) {
            // if (this.showTotals && trans.__t !== lastType) {
            if (this.showTotals && (trans.__t === 'Correction' || trans.__t === 'Transfer')) {
                // don't show sum if several transaction types present
                this.showTotals = false;
            }
            if (!this.readOnly) {
                // every field gets its own listener to catch only value changes
                const id = trans._id.toString();
                this.additionalNoteChanged[id] = new Subject<StockChange>();
                this.additionalNoteChanged[id].pipe(
                    debounceTime(2000),
                    startWith(trans.reportNote),
                    distinctUntilChanged(),
                    skip(1))
                    .subscribe(() => {
                        this.saveAdditionalNote(trans);
                        // this.saveAdditionalNote(id);
                    }
                    );
            }

            thesum += isNaN(Number(trans.amount)) ? 0 : (trans.__t === 'Sale' ? -trans.amount : trans.amount);
            thePricesum += isNaN(Number(trans.price)) ? 0 : (trans.__t === 'Sale' ? -trans.price : trans.price);
            trans['name'] = this.tr.anslate(trans.__t);
            if (trans.coffee && !trans.coffee.yearLabel) {
                trans.coffee.yearLabel = this.utils.createBeansYearLabel(trans.coffee);
            }
            if ((trans as Roast).blend?.ingredients) {
                for (const ing of (trans as Roast).blend.ingredients) {
                    const cof = ing?.coffee;
                    if (cof && !cof.yearLabel) {
                        cof.yearLabel = this.utils.createBeansYearLabel(cof);
                    }
                }
            }
        }
        this.sumPrice = thePricesum;
        this.sum = thesum;
        this.totalsChanged.emit({ showTotals: this.showTotals, numberOf: transactions.length, sum: this.sum, sumPrice: this.sumPrice });
    }

    // saveAdditionalNote(transId: string) {
    //     if (this.readOnly) { return; }

    //     let myTrans;
    //     for (let t = 0; t < this.dataSource.data.length; t++) {
    //         const trans = this.dataSource.data[t];
    //         if (trans._id === transId) {
    //             myTrans = trans;
    //             break;
    //         }
    //     }
    //     if (!myTrans) {
    //         this.logger.error('could not find stockchange for which to save reference (beans report)');
    //         return;
    //     }

    //     this.logger.debug('save notes for ', transId, ': ', myTrans.reportNote);
    //     this.standardService.update<StockChange>('stockchanges', {_id: transId, reportNote: myTrans.reportNote, modified_at: DateTime.now()})
    //         .pipe(throttleTime(environment.RELOADTHROTTLE))
    //         .subscribe(response => {
    //             if (!response || response.success === true) {
    //                 this.animateFieldState = 'saved';
    //                 setTimeout(() => this.animateFieldState = 'idle', 3000);
    //             } else {
    //                 this.utils.handleError('error updating the additional report information', response.error);
    //             }
    //         },
    //         error => {
    //             this.utils.handleError('error updating the additional report information', error);
    //         }
    //     );
    // }

    saveAdditionalNote(transaction: StockChange): void {
        if (this.readOnly) { return; }

        this.logger.debug('save notes:', transaction.reportNote);
        this.standardService.update<StockChange>('stockchanges', { _id: transaction._id, reportNote: transaction.reportNote, amount: undefined, date: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        if (response?.result) {
                            transaction.reportNote = response.result.reportNote;
                        }
                        this.animateFieldState = 'saved';
                        setTimeout(() => this.animateFieldState = 'idle', 3000);
                    } else {
                        this.utils.handleError('error updating the additional report information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the additional report information', error);
                }
            });
    }

    deleteTransaction(transaction: StockChange, idx: number): void {
        if (!transaction) {
            return;
        }
        transaction.type = transaction.__t;
        this.checkingIfDeletable = idx;
        this.standardService.getRefs('stockchanges', transaction._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.checkingIfDeletable = undefined;
                    if (response.success === true) {
                        if (response.result.count > 0) {
                            const ondate = this.tr.anslate('on {{date}}', { date: transaction.date.toLocaleString(DateTime.DATE_MED) });
                            const dialogRef = this.dialog.open(CantDeleteDialogComponent, {
                                closeOnNavigation: true,
                                data: {
                                    label: `${this.tr.anslate(transaction.type) }: ${(transaction.amount * this.utils.getUnitFactor(this.mainUnit)).toFixed(2)}${this.mainUnit} ${ondate}`,
                                    count: response.result.count,
                                    refs: this.utils.dateifyObjects(response.result.refs, ['date']),
                                    mainUnit: this.mainUnit,
                                    currency: this.currency
                                }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete(transaction);
                                }
                            });

                        } else {
                            this.doDelete(transaction);
                        }
                    } else {
                        this.utils.handleError('could not delete the data', response.error);
                    }
                },
                error: error => {
                    this.checkingIfDeletable = undefined;
                    this.utils.handleError('could not delete the data', error);
                }
            });
    }

    private doDelete(transaction: StockChange): void {
        this.transService.deleteTransaction(transaction, this.ngUnsubscribe, () => {
            const idx = this.dataSource.data.findIndex(trans => transaction._id === trans._id);
            if (idx >= 0) {
                this.dataSource.data.splice(idx, 1);
                this.dataSource = new MatTableDataSource<StockChange>(this.dataSource.data);
                this.updateDataSource();
                // this didn't work as the changes in the datasource didn't get through
                // this.recalc();
                // if (this.table) {
                //     this.table.renderRows();
                // }
            }
            this.reportChanged.emit(this.dataSource.data);
        });
    }

    editTransaction(transaction: StockChange): void {
        if (!transaction) {
            return;
        }
        transaction.type = transaction.__t;
        this.transService.editTransaction(transaction, this.ngUnsubscribe, trans => {
            const idx = this.dataSource.data.findIndex(trans => transaction._id === trans._id);
            if (idx >= 0) {
                // this.dataSource.data.splice(idx, 1, trans);
                this.dataSource.data[idx] = trans;
                this.dataSource = new MatTableDataSource<StockChange>(this.dataSource.data);
                this.updateDataSource();
                // this didn't work as the changes in the datasource didn't get through
                // this.recalc();
                // if (this.table) {
                //     this.table.renderRows();
                // }
            }
            this.reportChanged.emit(this.dataSource.data);
        });
    }
}
