import { DateTime } from "luxon";
import { Account } from "./Account";
import { Reminder } from "./Reminder";
import { User } from "./User";

export class ReminderLog {
    _id ?: string;
    created_at ?: Date;
    updated_at ?: Date;
    // internal_hr_id ?: number;
    // hr_id ?: string;
    owner ?: Account;
    label: string;
    notes ?: string;
    // active ?: boolean;
    deleted ?: boolean;
    done_date ?: DateTime;
    done_user ?: User;
    // expired ?: boolean;
    machine?: string;
    source?: Reminder;
    created_by?: User;
    updated_by?: User;
}

