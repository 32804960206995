@if (previousDay) {
@if (zoomState === 's') {
<div class="scheduler-planner-div-micro" [ngClass]="{'scheduler-planner-div-micro-higher': schedule.date >= yesterday}">
    <div class="scheduler-list" style="width: unset;">
        <div class="scheduler-list-header">
            <span class="scheduler-list-header-weekday">{{schedule.weekdayShort}}</span><br />
            <span class="scheduler-list-header-subtitle">{{schedule.summary?.split(' • ')?.[1]}}</span>
        </div>
    </div>
</div>
}
} @else { <!-- i.e. !previousDay -->
<div class="scheduler-planner-div" [ngClass]="{'scheduler-planner-unset-width': zoomState === 'm'}">
    <!-- <div class="scheduler-planner-div" [@shrinkItem]="{value: zoomState === 's' ? 'start' : 'end'}"> -->
    <!-- <div class="scheduler-list" [@shrinkItem]="{value: zoomState === 's' ? 'start' : 'end'}" -->
    <div class="scheduler-list"
        cdkDropList
        [cdkDropListData]="{items: filteredItems, date: schedule.date, allItems: schedule.items}"
        (cdkDropListDropped)="drop($event)"
        (cdkDropListEntered)="dragEntered($event)"
        [ngClass]="{'scheduler-planner-unset-width': zoomState === 'm'}">
        <div class="scheduler-list-header">
            @if (zoomState === 's') {
            <span class="scheduler-list-header-date">{{schedule.date | date:'shortDate'}}</span>
            }
            @if (zoomState === 'm') {
            @if (index >= startMonthIndex && startMonthIndex + 7 > index) {
            <span class="scheduler-list-header-weekday-small">{{schedule.weekdayShort}} </span>
            }
            <span class="scheduler-list-header-date-small" [ngClass]="{'scheduler-list-header-date-smaller': index < startMonthIndex || index > 20 && schedule.date.substring(8, 9) === '0'}">{{schedule.date.substring(8, 10)}}</span>
            }
            @if (zoomState === 's') {
            <span class="scheduler-list-header-weekday">{{schedule.weekday}}</span><br />
            <!-- <span class="scheduler-list-header-subtitle">{{schedule.summary}}</span> -->
            }
        </div>
        <div class="scheduler-list-drop-placeholder"></div>
    </div>

    @if (zoomState === 's') {
    <div class="scheduler-list-buttons">
        @if (!readOnly && schedule.date >= today) {
        <button [disabled]="isAddingNow" type="button" mat-raised-button
            class="add-button action-button" style="margin-bottom: 15px;"
            (click)="addCurrentItem.emit(schedule.date)">
            @if (!isAdding) {
            <mat-icon>add</mat-icon>
            } @else {
            <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
            }
        </button>
        }
    </div>
    }
</div>
}