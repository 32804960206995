import { Component, OnInit } from '@angular/core';
import { SchedulerPlannerComponent } from './scheduler-planner.component';

@Component({
    selector: 'app-scheduler-planner-placeholder',
    templateUrl: './scheduler-planner-placeholder.component.html',
    styleUrls: ['./scheduler-planner-placeholder.component.scss'],
})
export class SchedulerPlannerPlaceholderComponent extends SchedulerPlannerComponent implements OnInit {

    yesterday = this.DateTime.fromISO(this.today).minus({ day: 1 }).toISODate();

    ngOnInit(): void {
        this.isPlaceholder = true;
    }
}
