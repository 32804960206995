// adapted from https://blog.joefallon.net/2018/09/typescript-try-catch-finally-and-custom-errors/
export class SizeTooLargeError extends Error {
    constructor(message?: string) {
        super(message);
        // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
        // Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        Object.setPrototypeOf(this, SizeTooLargeError.prototype);
        this.name = SizeTooLargeError.name; // stack traces display correctly now
    }
}
