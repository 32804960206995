import { EventEmitter, Host } from '@angular/core';
import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NumberinputDialogComponent } from '../../ui/dialog/numberinput-dialog.component';
import { Options, SliderComponent } from '@angular-slider/ngx-slider';
import { convertFromIndexToValue, convertFromValueToIndex } from './filter-utils';

@Directive({
    selector: '[appClickValues]',
})
export class ClickValuesDirective implements AfterViewInit, OnDestroy {

    constructor(
        private renderer2: Renderer2,
        private el: ElementRef,
        private dialog: MatDialog,
        @Host() public range: SliderComponent,
    ) {
        this.range.manualRefresh = this.manualRefresh;
    }

    unlisten: (() => void)[] = [];
    lowElem: HTMLElement;
    lowElem2: HTMLElement;
    highElem: HTMLElement;
    highElem2: HTMLElement;
    combinedElem: HTMLElement;

    manualRefresh: EventEmitter<void> = new EventEmitter<void>();

    ngAfterViewInit(): void {
        for (const element of this.el.nativeElement.childNodes) {
            const classes: DOMTokenList = element.classList;
            if (classes.contains('ngx-slider-model-value')) {
                this.lowElem = element;
            }
            if (classes.contains('ngx-slider-pointer-min')) {
                this.lowElem2 = element;
            }
            if (classes.contains('ngx-slider-model-high')) {
                this.highElem = element;
            }
            if (classes.contains('ngx-slider-pointer-max')) {
                this.highElem2 = element;
            }
            if (classes.contains('ngx-slider-combined')) {
                this.combinedElem = element;
            }
            if (this.lowElem && this.highElem && this.lowElem2 && this.highElem2 && this.combinedElem) {
                break;
            }
        }
        if (this.lowElem) {
            this.lowElem.style['z-index'] = '5';
            this.lowElem.style['cursor'] = 'text';
            this.unlisten.push(this.renderer2.listen(this.lowElem, 'mousedown', () => {
                this.handleClick(true);
            }));
        }
        if (this.highElem) {
            this.highElem.style['z-index'] = '5';
            this.highElem.style['cursor'] = 'text';
            this.unlisten.push(this.renderer2.listen(this.highElem, 'mousedown', () => {
                this.handleClick(false);
            }));
        }
        if (this.combinedElem) {
            this.combinedElem.style['z-index'] = '5';
            this.combinedElem.style['cursor'] = 'text';
            this.unlisten.push(this.renderer2.listen(this.combinedElem, 'mousedown', event => {
                this.handleClick(undefined, event);
            }));
        }
        if (this.lowElem2) {
            this.unlisten.push(this.renderer2.listen(this.lowElem2, 'dblclick', () => {
                this.handleClick(true);
            }));
        }
        if (this.highElem2) {
            this.unlisten.push(this.renderer2.listen(this.highElem2, 'dblclick', () => {
                this.handleClick(false);
            }));
        }
    }

    ngOnDestroy(): void {
        for (const unlisten of this.unlisten) {
            if (typeof unlisten === 'function') {
                unlisten();
            }
        }
    }

     
    handleClick(isLow: boolean, event?: MouseEvent): void {
        if (typeof isLow === 'undefined') {
            // clicked on combined label, need to calculate isLow
            const label = (event.target as HTMLElement)?.textContent;
            // label: 1 - 3, 123.456 - 500, 1 - 3.456
            if (label) {
                const labelSplit = label.split(' - ');
                const width = (event.target as HTMLElement)?.offsetWidth;
                if (width && labelSplit?.length === 2) {
                    const middleEstimate = width * labelSplit[0].length / (label.length - 3);
                    isLow = event.offsetX <= middleEstimate;
                    // console.log(`estimated click on ${isLow ? 'low' : 'high'}`);
                }
            }
        }
        const stepsArray = (this.range['viewOptions'] as Options).stepsArray;
        const convValue = convertFromIndexToValue(this.range.value, stepsArray);
        const convHighValue = convertFromIndexToValue(this.range.highValue, stepsArray);
        const dialogRef = this.dialog.open(NumberinputDialogComponent, {
            closeOnNavigation: true,
            data: {
                // title: 'Number input',
                title: '',
                placeholder: '',
                initial: isLow ? convValue : convHighValue,
                min: isLow ? this.range.options?.floor : convValue,
                max: isLow ? convHighValue : this.range.options?.ceil,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result || result === 0) {
                let previousValue: number;
                // convert result into stepArray "index"
                const stepsArray = (this.range['viewOptions'] as Options).stepsArray;
                const convResult = convertFromValueToIndex(result, stepsArray);
                // const { preIdx, postIdx } = findStepIndeces(result, stepsArray);
                // const stepSize = stepsArray[postIdx].value - stepsArray[preIdx].value;
                // const convResult = (result - stepsArray[preIdx].value) / stepSize * stepSize + preIdx;
                if (isLow) {
                    previousValue = this.range.value;
                    this.range.value = convResult;
                    this.range['viewLowValue'] = convResult;
                } else {
                    previousValue = this.range.highValue;
                    this.range.highValue = convResult;
                    this.range['viewHighValue'] = convResult;                    
                }
                // TODO still necessary?
                // this.manualRefresh.emit();
                const isFirstChange = () => false;
                const changes = { value: { previousValue, currentValue: this.range.value, firstChange: false, isFirstChange }, highValue: { previousValue, currentValue: this.range.highValue, firstChange: false, isFirstChange } };
                this.range.ngOnChanges(changes);
            }
        });
    }
}