import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
    ) { }

    uploadFile(file: Blob, fileType: string, purpose: string, belongsTo: string): Observable<{ success: boolean, result: string, fileName: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/file/';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileType', fileType);
        formData.append('purpose', purpose);
        formData.append('belongsTo', belongsTo);
        return this.http.post<{ success: boolean, result: string, fileName: string, error: string }>(url, formData);
    }

    /**
     * Upload documents to server returning a progress status
     * Adapted from https://malcoded.com/posts/angular-file-upload-component-with-express/
     * @param files set of files to upload
     * @returns progress for each file
     */
    public uploadFiles(files: Set<File>, fileType: string, purpose: string, belongsTo: string): Record<string, { progress: Observable<number>, fileName: Observable<{ fileName: string, path: string }> }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/file/';

        // this will be the our resulting map
        const status: Record<string, { progress: Observable<number>, fileName: Observable<{ fileName: string, path: string }> }> = {};

        files.forEach(file => {
            // create a new multipart-form for every file
            const formData: FormData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            formData.append('fileType', fileType);
            formData.append('purpose', purpose);
            formData.append('belongsTo', belongsTo);

            // create a http-post request and pass the form
            // tell it to report the upload progress
            const req = new HttpRequest('POST', url, formData, {
                reportProgress: true
            });

            // create a new progress-subject for every file
            const progress = new Subject<number>();
            const fileInfo = new Subject<{ fileName: string, path: string }>();

            // send the http-request and subscribe for progress-updates
            this.http.request(req)
                .subscribe({
                    next: event => {
                        if (event.type === HttpEventType.UploadProgress) {
                            // calculate the progress percentage
                            const percentDone = Math.round(100 * event.loaded / event.total);
                            // pass the percentage into the progress-stream
                            progress.next(percentDone);

                        } else if (event instanceof HttpResponse) {
                            // Close the progress-stream if we get an answer form the API
                            // The upload is complete
                            progress.complete();
                            if (event.body && event.body['success'] === true) {
                                fileInfo.next({ fileName: file.name, path: event.body['result'] });
                            } else {
                                fileInfo.error('could not get filename: ' + event.body ? event.body['error'] : 'null');
                            }
                            fileInfo.complete();
                        }
                    },
                    error: error => {
                        progress.error(error);
                        this.logger.debug(error);
                    }
                });

            // Save every progress-observable in a map of all observables
            status[file.name] = {
                progress: progress.asObservable(),
                fileName: fileInfo.asObservable(),
            };
        });

        // return the map of progress.observables
        return status;
    }
}
