import { Utils } from 'src/app/util/utils';
import { Component, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/util/alert/alert.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';

@Component({
    selector: 'app-adduser-dialog',
    templateUrl: './adduser-dialog.component.html',
    styleUrls: ['./adduser-dialog.component.scss'],
    providers: []
})
export class AdduserDialogComponent implements OnDestroy {

    constructor(
        private alertService: AlertService,
        private utils: Utils,
        private userService: UserService,
        private tr: TranslatorService,
        private dialogRef: MatDialogRef<AdduserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { readonly: boolean, existingEmails: string[] },
    ) { }

    email: string;
    adding = false;

    private ngUnsubscribe = new Subject();

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    add(): void {
        this.adding = true;
        this.userService.addUserToAccount(this.email, this.data.readonly)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.dialogRef.close(true);
                    } else {
                        this.utils.handleError('error', response.error);
                    }
                    this.adding = false;
                },
                error: error => {
                    this.utils.handleError('error', error);
                    this.adding = false;
                }
            });
    }
}
