import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-info-dialog',
    template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
    <mat-dialog-content>
        <div>{{data.text}}</div>
        @if (data.additionalText) {
            <div>{{data.additionalText}}</div>
        }
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button class="action-button" mat-raised-button type="submit" mat-dialog-close><mat-icon>check_box</mat-icon></button>
    </mat-dialog-actions>
    `
})
export class InfoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<InfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, text: string, additionalText: string }
    ) { }

}
