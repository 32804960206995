<mat-checkbox [disabled]="disabled" class="primary-element"
    [(ngModel)]="selected"
    (change)="selectChanged()"
    style="margin-right: 10px;">
</mat-checkbox>

<mat-form-field class="inputfield-besides-checkbox">
    <input [(ngModel)]="text" (blur)="textChanged()" (ngModelChange)="$textChanged.next($event)"
        [disabled]="disabled || textDisabled" matInput class="filter-input"
        type="text" name="label" [placeholder]="i18nPlaceholder">
</mat-form-field>