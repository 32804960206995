export enum AlertType {
    Success,
    Error,
    Info,
    Warning,
    Clear,
    ClearAlsoErrors,
}
export class Alert {
    type: AlertType;
    message: string;
    link?: string;
    linkText?: string;
}

export interface AlertMessageInfo {
    error?: string | ProgressEvent | { error?: string, message?: string };
    message?: string;
    link?: string;
    linkText?: string;
}
