@if (haveData) {
<div id="divChart" style="position: relative; width: 100%"
    [ngStyle]="{'height': canvasHeight}">
    <canvas baseChart
        type="bar"
        [options]="options"
        [data]="data"
        (chartClick)="chartClicked($event)">
    </canvas>
</div>
}