// based on https://whiteduck.de/en/how-to-approach-angular-internationalization-i18n-in-2022/
// does not work anymore with the new Angular 17+ esbuild system
// dynamic imports not supported
// TODO have a script change the code here to include only the
// locale data of the language for which it is compiled

import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { loadTranslations, MessageId, TargetMessage } from '@angular/localize';
import enGB from '@angular/common/locales/en-GB';
import fr from '@angular/common/locales/fr';
import de from '@angular/common/locales/de';
import it from '@angular/common/locales/it';
import pt from '@angular/common/locales/pt';
import zh from '@angular/common/locales/zh';
import engbjson from 'src/app/locale/en-GB.json';
import frjson from 'src/app/locale/fr.json';
import dejson from 'src/app/locale/de.json';
import itjson from 'src/app/locale/it.json';
import ptjson from 'src/app/locale/pt.json';
import zhjson from 'src/app/locale/zh.json';

@Injectable({
    providedIn: 'root',
})
export class I18n {
    locale = 'en';

    constructor(
        @Inject(APP_BASE_HREF) private baseHref: string
    ) {
        // const userLocale = localStorage.getItem('locale'); 
        const url = this.baseHref;
        const loc = /\/(..)\//.exec(url)?.[1];
        this.locale = loc || 'en';
        if (this.locale === 'gb') {
            this.locale = 'en-GB';
        }
        if (this.locale === 'zz' || this.locale === 'xy') {
            this.locale = 'en';
        }
    }

    getLocaleModule(locale: string): unknown[] {
        switch (locale) {
            case 'en-GB':
                return enGB;
            case 'de':
                return de;
            case 'fr':
                return fr;
            case 'it':
                return it;
            case 'pt':
                return pt;
            case 'zh':
                return zh;
            default:
                return undefined;
        };
    }

    getLocaleTranslations(locale: string): Record<MessageId, TargetMessage> {
        switch (locale) {
            case 'en-GB':
                return engbjson;
            case 'de':
                return dejson;
            case 'fr':
                return frjson;
            case 'it':
                return itjson;
            case 'pt':
                return ptjson;
            case 'zh':
                return zhjson;
            default:
                return undefined;
        };
    }

    async setLocale() {
        if (this.locale !== 'en' && this.locale !== 'zz' && this.locale !== 'xy') {
            // Set locale for built in pipes, etc.
            const localeModule = this.getLocaleModule(this.locale);
            registerLocaleData(localeModule);

            // Load translation file
            // const localeTranslationsModule = await import(
            //     `src/app/locale/${this.locale}.json`
            // );

            // Load translations for the current locale at run-time
            const localeTranslations = this.getLocaleTranslations(this.locale);
            loadTranslations(localeTranslations);

            // // Use web pack magic string to only include required locale data
            // const localeModule = await import(
            //     /* webpackInclude: /(de|en|es|zh|fr|it|pt|en-GB)\.mjs$/ */
            //     `/node_modules/@angular/common/locales/${this.locale}.mjs`
            // );

            // // Set locale for built in pipes, etc.
            // registerLocaleData(localeModule.default);

            // // Load translation file
            // const localeTranslationsModule = await import(
            //     `src/app/locale/${this.locale}.json`
            // );

            // // Load translations for the current locale at run-time
            // loadTranslations(localeTranslationsModule.default);
        }
    }
}
