<ng-template #coffeeEntry let-coffee="coffee">
    @if (coffee?.hr_id) {
    <a [routerLink]="['/coffees', {id: coffee.hr_id}]">{{coffee.hr_id}}</a>
    }
    @if (coffee?.translated_origin) {
    <span> {{coffee.translated_origin}}</span>
    }
    @if (coffee?.yearLabel) {
    <span> {{coffee.yearLabel}}</span>
    }
    <!-- <span *ngIf="coffee?.label && (coffee.hr_id || coffee.translated_origin || coffee.yearLabel)">,</span> -->
    @if (coffee?.label) {
    <span class="clipped-string littlespaceleft littlespaceright"> {{coffee.label}}</span>
    }
</ng-template>

<ng-template #locationLabel let-transaction="transaction">
    @if (!store && transaction?.location?.label) {
    <span>
        <span i18n="transaction$$transaction, e.g. a transfer, _to_ a target location@@to target" class="littlespaceleft littlespaceright">to</span>
        @if (transaction.location.hr_id || transaction.location.internal_hr_id) {
        <a [routerLink]="['/stores', {id: transaction.location.hr_id ? transaction.location.hr_id : 'L' + transaction.location.internal_hr_id}]" class="clipped-string">{{transaction.location.label}}</a>
        } @else {
        <span class="clipped-string">{{transaction.location.label}}</span>
        }
    </span>
    }
</ng-template>



@if (transactions?.length) {
<mat-expansion-panel>
    <mat-expansion-panel-header class="notcolored">
        <mat-panel-title><span i18n="transaction$$transaction list header@@list header">Number of Transactions: {{transactions.length}}</span>
            @if (loading) {
            <mat-spinner style="margin-left: 15px" diameter="15" strokeWidth="1" class="primary-element"></mat-spinner>
            }
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        @for (transaction of getNoRoasts(); track transaction?._id) {
        <div>
            <div class="smallfont">
                {{transaction.translated_type}}: {{transaction.amount * utils.getUnitFactor(mainUnit) | number:'1.0-1'}}{{mainUnit}}
                @if (!coffee && transaction.coffee) {
                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: transaction.coffee}"></ng-container>
                }
                <!-- <a *ngIf="!coffee && transaction.coffee?.label && (transaction.coffee.internal_hr_id || transaction.coffee.hr_id)" class="clipped-string littlespaceright" [routerLink]="['/coffees', {id: transaction.coffee.hr_id ? transaction.coffee.hr_id : 'C' + transaction.coffee.internal_hr_id}]">{{transaction.coffee.label}}</a> -->
                @if (transaction['blend']?.label) {
                <span class="clipped-string">{{transaction['blend'].label}}</span>
                }
                <!-- <a *ngIf="transaction['blend']?.label" [routerLink]="['/blends', {id: transaction['blend']?._id}]">{{transaction['blend']?.label}}</a> -->
                @if (transaction.price) {
                <span> <span i18n="transaction$$indicates the price of a transaction, e.g. 3kg _for_ 100€@@for price">for</span> {{transaction.price | currency:currency:'symbol-narrow':'1.0-0'}} </span>
                }
                @if (transaction.type === 'Purchase' && transaction['supplier']?.label) {
                <span> <span i18n="transaction$$transaction, e.g. a transfer, _from_ a source location@@from source" class="littlespaceright">from</span> <a [routerLink]="['/contacts', {id: transaction['supplier']?._id}]" class="clipped-string">{{transaction['supplier'].label}}</a>
                    <ng-container *ngTemplateOutlet="locationLabel; context: {transaction: transaction}"></ng-container>
                </span>
                }
                @if (transaction.type === 'Sale' && transaction['customer']?.label) {
                <span> <span i18n="transaction$$sale _to_ a customer@@to customer" class="littlespaceright">to</span> @if (transaction['customer']?.label) {
                    <a [routerLink]="['/contacts', {id: transaction['customer']?._id}]" class="clipped-string">{{transaction['customer'].label}}</a>
                    }
                    <ng-container *ngTemplateOutlet="locationLabel; context: {transaction: transaction}"></ng-container>
                </span>
                }
                @if (transaction.type === 'Correction' && transaction['reason']) {
                <span [ngClass]="{'littlespaceleft': !transaction?.coffee.label}">"{{transaction['reason']}}"<ng-container *ngTemplateOutlet="locationLabel; context: {transaction: transaction}"></ng-container></span>
                }
                @if (transaction.type === 'Transfer' && transaction['target']) {
                <span>
                    @if (!store) {
                    <span>
                        @if (transaction.location?.label) {
                        <span><span i18n="transaction$$transaction, e.g. a transfer, _from_ a source location@@from source" class="littlespaceright">from</span> <a [routerLink]="['/stores', {id: transaction.location.hr_id ? transaction.location.hr_id : 'L' + transaction.location.internal_hr_id}]" class="clipped-string">{{transaction.location.label}}</a></span>
                        }
                        @if (transaction['target']?.label) {
                        <span><span i18n="transaction$$transaction, e.g. a transfer, _to_ a target location@@to target" class="littlespaceleft littlespaceright">to</span> <a [routerLink]="['/stores', {id: transaction['target'].hr_id ? transaction['target'].hr_id : 'L' + transaction['target'].internal_hr_id}]" class="clipped-string">{{transaction['target'].label}}</a></span>
                        }
                    </span>
                    } @else {
                    <span>
                        @if (transaction['target']._id === store._id) {
                        <span>
                            @if (transaction.location?.label) {
                            <span><span i18n="transaction$$transaction, e.g. a transfer, _from_ a source location@@from source" class="littlespaceright">from</span> <a [routerLink]="['/stores', {id: transaction.location.hr_id ? transaction.location.hr_id : 'L' + transaction.location.internal_hr_id}]" class="clipped-string">{{transaction.location.label}}</a></span>
                            }
                        </span>
                        } @else {
                        @if (transaction['target']?.label) {
                        <span><span i18n="transaction$$transaction, e.g. a transfer, _to_ a target location@@to target" class="littlespaceright">to</span> <a [routerLink]="['/stores', {id: transaction.location.hr_id ? transaction.location.hr_id : 'L' + transaction.location.internal_hr_id}]" class="clipped-string">{{transaction['target'].label}}</a></span>
                        }
                        }
                    </span>
                    }
                </span>
                }
                <div class="secondLineIndent">
                    @if (transaction.date) {
                    <span i18n="transaction$$e.g. on 09.12.1978@@on {{date}}" class="littlespaceleft littlespaceright">on {{transaction.date.toLocaleString(DateTime.DATE_MED)}}</span>
                    }
                    @if (!readOnly && !transaction.reconciled) {
                    <a (click)="editTransaction(transaction)" (keydown.enter)="editTransaction(transaction); $event.preventDefault()" role="button" [tabindex]="0">
                        <mat-icon class="smallicon">edit</mat-icon>
                    </a>
                    @if (checkingIfDeletable !== transaction._id) {
                    <a (click)="deleteTransaction(transaction)" (keydown.enter)="deleteTransaction(transaction); $event.preventDefault()" role="button" [tabindex]="0">
                        <mat-icon class="smallicon smallicon-second">delete</mat-icon>
                    </a>
                    } @else {
                    <a>
                        <mat-spinner class="button-spinner" diameter="13" style="display: inline;"></mat-spinner>
                    </a>
                    }
                    }
                </div>
            </div>
        </div>
        }
        <hr>
        @for (transaction of sumByCoffeeRoasts; track $index) {
        <div>
            <div class="smallfont">
                @if (transaction['count'] === 1) {
                <span i18n="generic$$Roast, header@@Roast">Roast</span>:
                } @else {
                <span i18n="reports$$Roasts@@Roasts">Roasts</span> ({{transaction['count']}}):
                }
                <!-- <span i18n="reports$$Roasts@@Roasts">Roasts</span>: {{transaction.amount * utils.getUnitFactor(mainUnit) | number:'1.0-1'}}{{mainUnit}} <a *ngIf="!coffee && transaction.coffee?.label && (transaction.coffee.internal_hr_id || transaction.coffee.hr_id)" [routerLink]="['/coffees', {id: transaction.coffee.hr_id ? transaction.coffee.hr_id : 'C' + transaction.coffee.internal_hr_id}]">{{transaction.coffee?.label}}</a><span *ngIf="transaction['blend']?.label" [ngClass]="{'littlespaceright': coffee}">{{transaction['blend']?.label}}</span> -->
                @if (transaction.coffee) {
                {{transaction.amount * utils.getUnitFactor(mainUnit) | number:'1.0-1'}}{{mainUnit}}
                @if (!coffee) {
                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: transaction.coffee}"></ng-container>
                }
                }
                @if (!transaction.coffee && !coffee && transaction['blend']) {
                {{transaction.amount * utils.getUnitFactor(mainUnit) | number:'1.0-1'}}{{mainUnit}} @if (transaction['blend'].label) {
                <span class='littlespaceright'>@if (transaction['blend'].label) {
                    <a [routerLink]="['/blends', {id: transaction['blend'].label}]">{{transaction['blend'].label}}</a>
                    } @else {
                    {{transaction['blend']?.label}}
                    }@if (!transaction['blend'].label) {
                    <span>{{transaction['blend'].label}}</span>
                    }</span>
                } @else {
                <span i18n="reports$$roast overview@@of a blend">of a blend</span>
                }
                }
                @if (!transaction.coffee && coffee && transaction['blend']) {
                {{getBlendRatio(coffee, transaction['blend']) * transaction.amount * utils.getUnitFactor(mainUnit) | number:'1.0-1'}}{{mainUnit}} @if (transaction['blend'].label) {
                <span class='littlespaceright'>({{transaction.amount * utils.getUnitFactor(mainUnit) | number:'1.0-1'}}{{mainUnit}} <a [routerLink]="['/blends', {id: transaction['blend'].label}]">{{transaction['blend']?.label}}</a>)</span>
                } @else {
                <span class='littlespaceright' i18n="reports$$roast overview: n kg (x% of a blend)@@x% of a blend">({{(getBlendRatio(coffee, transaction['blend']) * 100 | number:'1.1-1') + '%'}} of a blend)</span>
                }
                }
                @if (!transaction.coffee && !transaction['blend']) {
                {{transaction.amount * utils.getUnitFactor(mainUnit) | number:'1.0-1'}}{{mainUnit}}
                }
                <!-- <a *ngIf="transaction['blend']?.label" [routerLink]="['/blends', {id: transaction['blend']?._id}]">{{transaction['blend']?.label}}</a> -->
                @if (transaction.fromDate && transaction.toDate) {
                <span>
                    @if (transaction.fromDate.substring(0, 10) === transaction.toDate.substring(0, 10)) {
                    <span i18n="transaction$$e.g. on 09.12.1978@@on {{date}}">on {{DateTime.fromISO(transaction.fromDate).toLocaleString(DateTime.DATE_SHORT)}}</span>
                    } @else {
                    <span i18n="transaction$$between date 1 and date 2@@between dates"> between {{DateTime.fromISO(transaction.fromDate).toLocaleString(DateTime.DATE_SHORT)}} and {{DateTime.fromISO(transaction.toDate).toLocaleString(DateTime.DATE_SHORT)}}</span>
                    }
                </span>
                }
            </div>
        </div>
        }
    </ng-template>
</mat-expansion-panel>
}