<div class="report-link-space-flex" #maindiv>
    <span data-test="spn_reportPDF" class="report-pdf-space-flex">
        @if (!(overview ? report?.pdfOvLink : report?.pdfLink) && !creatingPdf) {
        <a [ngClass]="{'isDisabled': readOnly}"
            (click)="generatePDF()" (keydown.enter)="generatePDF(); $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_createPDF" class="report-link">
            PDF
        </a>
        }
        @if (creatingPdf) {
        <span>
            <mat-spinner class="primary-element floatleft-spinner" diameter="15" strokeWidth="1"></mat-spinner>
            <span class="littlespaceright" i18n="generic$$currently creating a PDF@@creating PDF">Creating PDF ...</span>
        </span>
        }
        @if (overview ? report?.pdfOvLink : report?.pdfLink) {
        @if (!creatingPdf) {
        <button mat-icon-button (click)="generatePDF()"
            class="report-link updatepdf-link primary-element" [ngClass]="{'isDisabled': readOnly}"
            data-test="lnk_updatePDF"
            matTooltip="Update generated PDF" i18n-matTooltip="generic$$update PDF@@update PDF">
            <mat-icon class="smallicon" style="vertical-align: middle;">update</mat-icon>
        </button>

        <a [href]="overview ? report?.pdfOvLink : report?.pdfLink" data-test="lnk_pdf" class="report-link" target="_blank" rel="noopener">
            <span i18n="generic$$download PDF@@download PDF">PDF</span>
            <mat-icon class="small" style="margin-right: 4px;">launch</mat-icon>
        </a>
        <br />
        @if (overview ? report?.pdfOvLinkDate : report?.pdfLinkDate) {
        <span data-test="spn_pdfLinkDate" class="small-info">{{(overview ? report?.pdfOvLinkDate : report?.pdfLinkDate).toLocaleString(DateTime.DATETIME_SHORT)}}</span>
        }
        }
        }
    </span>

    @if (report?.roasts?.length) {
    <div>
        <mat-form-field data-test="slct_export" style="width: 100px; margin-right: 10px; margin-top: 5px;">
            <mat-select [disabled]="creatingSheet || creatingCsv || creatingXML"
                placeholder="Export" matInput name="export" panelWidth="null"
                i18n-placeholder="generic$$button, export@@Export"
                [(value)]="exportFormat"
                (selectionChange)="export($event.value)">
                <!-- <mat-option></mat-option> -->
                <mat-option value="csv">CSV</mat-option>
                <mat-option value="clipboardCSV" i18n="generic$$Clipboard@@Clipboard">Clipboard</mat-option>
                <mat-option value="sheet">Excel</mat-option>
                <mat-option value="xml">XML</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    }

    <!-- <ng-container *ngIf="report?.roasts?.length">
  <div style="text-align: center;">
    <span style="display: block">
      <a (click)="generateSheet()" data-test="lnk_sheet" [ngClass]="{'isDisabled': creatingSheet}" class="report-link">
        <span i18n="generic$$link to download data as Excel@@download Excel">Excel data</span>
      </a>,
      <a (click)="generateCSV()" data-test="lnk_csv" [ngClass]="{'isDisabled': creatingCsv}" class="report-link">
        <ng-container i18n="generic$$link to download data as CSV@@download CSV">CSV data</ng-container>
      </a>
    </span>
    <a *ngIf="!justCopied && !copyFailed" (click)="copy()" data-test="lnk_copy" class="overview-closelink">
      <mat-icon class="overview-close-icon" style="font-size: 18px;">content_copy</mat-icon>
    </a>
    <mat-icon *ngIf="justCopied" class="overview-copied overview-close-icon" style="font-size: 18px;">done</mat-icon>
    <mat-icon *ngIf="copyFailed" class="overview-copyFailed overview-close-icon" style="font-size: 18px;">error_outline</mat-icon>
  </div>

  <span class="right-align">
    <a (click)="generateXML()" data-test="lnk_xml" [ngClass]="{'isDisabled': creatingXML}" class="report-link"><span i18n="generic$$link to download data as XML@@download XML">XML data</span></a>
  </span>
</ng-container> -->
</div>