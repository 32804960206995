import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreGraphsComponent } from './store/store-graphs.component';
import { AmountpertimeGraphComponent } from './store/amountpertime-graph.component';
import { ProcessingGraphComponent } from './store/processing-graph.component';
import { CertificationsGraphComponent } from './store/certifications-graph.component';
import { RegionsGraphComponent } from './store/regions-graph.component';
import { OriginsGraphComponent } from './store/origins-graph.component';
import { AgeGraphComponent } from './store/age-graph.component';
import { SpeciesGraphComponent } from './store/species-graph.component';
import { VarietalsGraphComponent } from './store/varietals-graph.component';
import { MoistureGraphComponent } from './store/moisture-graph.component';
import { DensityGraphComponent } from './store/density-graph.component';
import { ActivityGraphComponent } from './store/activity-graph.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        StoreGraphsComponent,
    ],
    imports: [
        MatSelectModule,
        MatProgressSpinnerModule,
        AmountpertimeGraphComponent,
        ProcessingGraphComponent,
        CertificationsGraphComponent,
        RegionsGraphComponent,
        OriginsGraphComponent,
        AgeGraphComponent,
        SpeciesGraphComponent,
        VarietalsGraphComponent,
        MoistureGraphComponent,
        DensityGraphComponent,
        ActivityGraphComponent,
        FormsModule,
        CommonModule,
    ],
    exports: [
        StoreGraphsComponent,
    ],
    providers: [
        BreakpointObserver,
    ],
})
export class StoreGraphModule { }
