<ng-template #companyData>
    @if (companyDataOpened) {
    <div class="overview-flex-div">
        <mat-form-field class="form-group formfield-wide">
            <input [disabled]="readOnly || !!loadingCustomsData" [(ngModel)]="company.name" placeholder="Firma"
                data-test="npt_companyName" matInput type="text">
        </mat-form-field>
        <mat-form-field class="form-group formfield-small">
            <input data-test="npt_companyNumber" matInput type="text" pattern="\d*" maxlength="5"
                [disabled]="readOnly || !!loadingCustomsData" [(ngModel)]="company.number" placeholder="Nummer (bis 5 Ziffern)">
        </mat-form-field>
        <mat-form-field class="form-group formfield">
            <input data-test="npt_companyTaxNumber" matInput
                type="text" pattern="\d*" minlength="10" maxlength="10"
                [disabled]="readOnly || !!loadingCustomsData" [(ngModel)]="company.tax_number" placeholder="Verbrauchsteuernummer (10 Ziffern)">
        </mat-form-field><br />
        <mat-form-field class="form-group formfield-wide">
            <input data-test="npt_Gründungsdatum" matInput
                [disabled]="readOnly || !!loadingCustomsData"
                [matDatepicker]="myDatepickerG" placeholder="Gründungsdatum"
                [(ngModel)]="company.foundation">
            <mat-datepicker-toggle matIconSuffix [for]="myDatepickerG"></mat-datepicker-toggle>
            <mat-datepicker #myDatepickerG></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="form-group formfield-small">
            <mat-select [(ngModel)]="company.legalform" data-test="npt_legalform"
                [disabled]="readOnly || !!loadingCustomsData" placeholder="Rechtsform">
                <mat-option value="AG">AG</mat-option>
                <mat-option value="ausl. Rechtsform">ausl. Rechtsform</mat-option>
                <mat-option value="BgA">BgA</mat-option>
                <mat-option value="e.K.">e.K.</mat-option>
                <mat-option value="e.V.">e.V.</mat-option>
                <mat-option value="eG">eG</mat-option>
                <mat-option value="Einzelunternehmen">Einzelunternehmen</mat-option>
                <mat-option value="EWIV">EWIV</mat-option>
                <mat-option value="Freiberufler">Freiberufler</mat-option>
                <mat-option value="GbR">GbR</mat-option>
                <mat-option value="GmbH">GmbH</mat-option>
                <mat-option value="KdöR">KdöR</mat-option>
                <mat-option value="KG">KG</mat-option>
                <mat-option value="KGaA">KGaA</mat-option>
                <mat-option value="OHG">OHG</mat-option>
                <mat-option value="PartG">PartG</mat-option>
                <mat-option value="Privat">Privat</mat-option>
                <mat-option value="SCE">SCE</mat-option>
                <mat-option value="SE">SE</mat-option>
                <mat-option value="Sonstige">Sonstige</mat-option>
                <mat-option value="Stiftung">Stiftung</mat-option>
                <mat-option value="VVaG">VVaG</mat-option>
                <mat-option value=""></mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form-group formfield">
            <input data-test="npt_beteilgtennr" matInput type="text"
                pattern="\d*" minlength="12" maxlength="12"
                [disabled]="readOnly || !!loadingCustomsData" [(ngModel)]="company.beteiligtennr" placeholder="Beteiligten-Nr. (VVSt, 12 Ziffern)">
        </mat-form-field><br />
        <mat-form-field class="form-group formfield-wide">
            <input data-test="npt_companyStreet" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.street" placeholder="Straße">
        </mat-form-field>
        <mat-form-field class="form-group formfield-small">
            <input data-test="npt_housenr" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.housenr" placeholder="Hausnummer">
        </mat-form-field>
        <mat-form-field class="form-group formfield">
            <input data-test="npt_adzusatz" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.addinfo" placeholder="Adresszusatz">
        </mat-form-field><br />
        <mat-form-field class="form-group formfield-wide">
            <input data-test="npt_companyTown" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.town" placeholder="Ort">
        </mat-form-field>
        <mat-form-field class="form-group formfield-small">
            <input data-test="npt_companyZip" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.zip" placeholder="Postleitzahl">
        </mat-form-field>
        <mat-form-field class="form-group formfield">
            <input data-test="npt_partoftown" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.partoftown" placeholder="Ortsteil">
        </mat-form-field><br />
        <mat-form-field class="form-group formfield-wide">
            <input data-test="npt_companyPerson" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.contact.name" placeholder="Ansprechpartner/in">
        </mat-form-field>
        <mat-form-field class="form-group formfield-small">
            <input data-test="npt_companyPhone" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.contact.phone" placeholder="Telefon">
        </mat-form-field>
        <mat-form-field class="form-group formfield">
            <input data-test="npt_companyEmail" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.contact.email" placeholder="Email">
        </mat-form-field><br />
        <mat-form-field class="form-group formfield-wide">
            <!-- <input data-test="npt_companyHauptzollamt" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
          [(ngModel)]="customs.customs_office.name" placeholder="Hauptzollamt"> -->
            <mat-select [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="customs.customs_office.name" placeholder="Hauptzollamt">
                @for (hza of hauptzollaemter; track hza) {
                <mat-option [value]="hza">{{hza}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form-group formfield-rest">
            <textarea data-test="npt_companyHauptzollamtAdresse" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="customs.customs_office.address" placeholder="Adresse des Hauptzollamts">
          </textarea>
        </mat-form-field><br />
        <mat-form-field class="form-group formfield-wide-butone">
            <input data-test="npt_taxNumber" matInput type="text"
                [(ngModel)]="customs.tax_warehouse.number" placeholder="Verbrauchsteuer-Nr Steuerlager (10+1 Ziffern)"
                pattern="\d*" minlength="10" maxlength="10"
                [disabled]="readOnly || !!loadingCustomsData">
        </mat-form-field>
        <mat-form-field class="form-group formfield-extrasmall">
            <input data-test="npt_taxNumber2" matInput type="text"
                [(ngModel)]="customs.tax_warehouse.number2"
                pattern="\d*" minlength="1" maxlength="1"
                [disabled]="readOnly || !!loadingCustomsData">
        </mat-form-field>
        <mat-form-field data-test="slct_taxPurpose" class="form-group formfield-small">
            <mat-select [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="customs.tax_warehouse.purpose" placeholder="Steuerlager zur">
                <mat-option value="1">Herstellung und Lagerung von Kaffee</mat-option>
                <mat-option value="0">Lagerung von Kaffee</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form-group formfield">
            <textarea data-test="npt_taxAddress" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="customs.tax_warehouse.address" placeholder="Anschrift"></textarea>
        </mat-form-field><br />
        <mat-form-field class="form-group formfield-wide">
            <input data-test="npt_bank_institute" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.bank.institute" placeholder="Kreditinstitut">
        </mat-form-field>
        <mat-form-field class="form-group formfield-small">
            <input data-test="npt_companyPhone" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.bank.iban" placeholder="BIC">
        </mat-form-field>
        <mat-form-field class="form-group formfield">
            <input data-test="npt_companyEmail" matInput type="text" [disabled]="readOnly || !!loadingCustomsData"
                [(ngModel)]="company.bank.bic" placeholder="IBAN">
        </mat-form-field><br />
        <mat-action-row>
            <button [disabled]="readOnly || !!loadingCustomsData" (click)="storeUserData()"
                data-test="bttn_done" mat-raised-button type="button" class="action-button">
                @if (!loadingCustomsData) {
                <mat-icon>done</mat-icon>
                } @else {
                <mat-spinner diameter="24" style="width: 36px; height: 32px; margin-top:5px;"></mat-spinner>
                }
            </button>
            <!-- <button mat-raised-button type="button" (click)="download1830()" class="action-button">XML</button> -->
        </mat-action-row>
        <!-- <mat-divider style="margin-bottom: 10px;"></mat-divider> -->
    </div>
    }
</ng-template>


@if (customs?.report_type?.country !== 'Germany' || customs?.report_type?.variant === '-') {
<div style="margin-top: 10px">
    <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/updates/exporting-data/#customs--tax-report-settings'"
        data-test="lnk_customsHelp" style="padding-right: 0; margin: 0 0 0 25px;"
        rel="noopener" target="_blank">
        <mat-icon class="helpicon" [ngClass]="{'bigicon': !customs?.report_type?.country || customs?.report_type?.country === '-' || !customs?.report_type?.variant || customs?.report_type?.variant === '-'}">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
    </a>
    @if (!customs?.report_type?.country || customs?.report_type?.country === '-' || !customs?.report_type?.variant || customs?.report_type?.variant === '-') {
    <a
        [routerLink]="['/settings', {section: 'customs'}]" data-test="lnk_customsSettings">
        <span class="littlespaceleft" i18n="settings$$Settings for reports, header@@Reports Settings">Reports Settings</span>
    </a>
    }
</div>
}

@if (customs?.report_type?.country === 'Germany' && customs.report_type.variant !== '-') {
<a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/updates/tax-reports'"
    data-test="lnk_customsReportsHelp" class="customsreports-helpicon"
    rel="noopener" target="_blank">
    <mat-icon class="helpicon bigicon">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
</a>

<mat-checkbox [disabled]="readOnly || adChanging"
    class="manualDiscarded-checkbox wrap-checkbox-label"
    [ngModel]="!manualDiscarded"
    (change)="manualDiscardedChanged($event)"
    matTooltip="Wenn das nicht angeklickt ist, muss man für jede Fehlröstung entscheiden, ob sie voll für die Steuer angerechnet werden soll oder nicht (im Röstprotokoll)"
    name="manualDiscarded_checkbox">
    Fehlröstungen werden in Röstprotokollen automatisch mit einer Bemerkung versehen bzw. als Fehlröstung gelistet und ihr Ertrag <b>nicht</b> in der zu versteuernden Summe berücksichtigt
</mat-checkbox>
<mat-accordion displayMode="default" multi="true" class="customsreports-accordion">
    @if (customs?.report_type?.country === 'Germany') {
    <mat-expansion-panel hideToggle="true" style="margin-bottom: 15px;">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto">
            @if (customs?.report_type?.variant === 'Rosenheim' || customs?.report_type?.variant === 'Hamburg') {
            <mat-panel-title data-test="hdr_roastProtocol" class="paneltitle">Rohkaffee Ein-/Verkauf, Röstprotokoll</mat-panel-title>
            } @else {
            <mat-panel-title data-test="hdr_roastProtocol" class="paneltitle">Röstprotokoll</mat-panel-title>
            }
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="vertical-spacer"></div>
            <div>
                <mat-form-field class="amount-input-ff1">
                    <mat-label i18n="reports$$start date@@start date">From</mat-label>
                    <input matInput required placeholder="From" data-test="npt_startDate"
                        [(ngModel)]="startDate" [matDatepicker]="myDatepicker" i18n-placeholder="reports$$start date@@start date"
                        (dateChange)="datesChanged()" name="startDate">
                    <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="amount-input-ff2">
                    <mat-label i18n="reports$$end date@@end date">To</mat-label>
                    <input matInput required placeholder="To" data-test="npt_toDate"
                        [(ngModel)]="endDate" [matDatepicker]="myDatepicker2" i18n-placeholder="reports$$end date@@end date"
                        (dateChange)="datesChanged()" [min]="startDate" name="endDate">
                    <mat-datepicker-toggle matIconSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                    <mat-datepicker #myDatepicker2></mat-datepicker>
                </mat-form-field>
                <mat-form-field data-test="npt_month" class="month-input">
                    <mat-select placeholder="Month" i18n-placeholder="dates$$Month@@Month" name="month"
                        [(ngModel)]="month" (selectionChange)="monthChanged()">
                        <mat-option [value]="null"></mat-option>
                        @for (month of months; track month) {
                        <mat-option [value]="$index + 1">{{month}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/updates/exporting-data/#customs--tax-report-settings'"
                    data-test="lnk_customsHelp2" rel="noopener" style="padding-right: 0;margin: 0 0 0 5px;" target="_blank">
                    <mat-icon class="helpicon" [ngClass]="{'bigicon': !customs?.report_type?.country || customs?.report_type?.country === '-' || !customs?.report_type?.variant || customs?.report_type?.variant === '-'}">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
                </a>
                @if (!customs?.report_type?.country || customs?.report_type?.country === '-' || !customs?.report_type?.variant || customs?.report_type?.variant === '-') {
                <a
                    [routerLink]="['/settings', {section: 'customs'}]" data-test="lnk_customsSettings2">
                    <span class="littlespaceleft" i18n="settings$$Settings for reports, header@@Reports Settings">Reports Settings</span>
                </a>
                }
                @if (loadingReports) {
                <div class="main-note">
                    <span i18n="reports$$currently loading reports@@loading reports">Loading reports ...</span> <mat-spinner class="main-note" diameter="20"></mat-spinner>
                </div>
                } @else if (customs?.report_type?.country === 'Germany') {
                @if (customs?.report_type?.variant === 'Rosenheim') {
                <app-cr-germany-rosenheim
                    [currentUser]="currentUser" [readOnly]="readOnly"
                    (dataChanged)="updateForms()"
                    [manualDiscarded]="manualDiscarded"
                    [roasts]="roasts" [purchases]="purchases" [sales]="sales"
                    [exportSettings]="{ fieldSep: FIELDSEPARATOR, lineSep: LINESEPARATOR, decSep: DECIMALSEPARATOR }">
                </app-cr-germany-rosenheim>
                } @else if (customs?.report_type?.variant === 'Hamburg') {
                <app-cr-germany-hamburg
                    [currentUser]="currentUser" [readOnly]="readOnly"
                    (dataChanged)="updateForms()"
                    [manualDiscarded]="manualDiscarded"
                    [roasts]="roasts" [purchases]="purchases" [sales]="sales"
                    [exportSettings]="{ fieldSep: FIELDSEPARATOR, lineSep: LINESEPARATOR, decSep: DECIMALSEPARATOR }">
                </app-cr-germany-hamburg>
                } @else if (customs?.report_type?.variant === 'Köln') {
                <app-cr-germany-koeln
                    [currentUser]="currentUser" [readOnly]="readOnly"
                    (dataChanged)="updateForms()"
                    [manualDiscarded]="manualDiscarded"
                    [roasts]="roasts" [purchases]="purchases"
                    [lastPurchases]="lastPurchases" [endDate]="endDate"
                    [exportSettings]="{ fieldSep: FIELDSEPARATOR, lineSep: LINESEPARATOR, decSep: DECIMALSEPARATOR }">
                </app-cr-germany-koeln>
                } @else { <!-- if (customs?.report_type?.variant === 'Berlin' || customs?.report_type?.variant === 'Dresden') {-->
                <app-cr-germany-berlin
                    [currentUser]="currentUser" [readOnly]="readOnly"
                    (dataChanged)="updateForms()"
                    [manualDiscarded]="manualDiscarded"
                    [roasts]="roasts" [endDate]="endDate"
                    [exportSettings]="{ fieldSep: FIELDSEPARATOR, lineSep: LINESEPARATOR, decSep: DECIMALSEPARATOR }">
                </app-cr-germany-berlin>
                }
                }
            </div>
        </ng-template>
    </mat-expansion-panel>
    }
    @if (customs.report_type.country === 'Germany') {
    <mat-expansion-panel hideToggle="true" style="margin-bottom: 15px;">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto">
            <mat-panel-title data-test="hdr_form1830" class="paneltitle" style="margin-top: 13px;">Formular 1807 - Monatliche Steueranmeldung für Kaffee</mat-panel-title>
            <mat-panel-description>
                <!-- <a href="https://www.formulare-bfinv.de/ffw/action/invoke.do?id=1807" target="_blank" rel="noopener">Gespeichertes XML in 1807 Formular laden</a> -->
                <span class="center-align" style="margin-right: 5px">
                    <a (click)="download1807(); $event.stopPropagation()" (keydown.enter)="download1807(); $event.preventDefault(); $event.stopPropagation()" role="button" [tabindex]="0" [ngClass]="{'isDisabled': creatingXML}" class="report-link">XML<mat-icon class="small">download</mat-icon></a><br />
                    für <a href="https://www.formulare-bfinv.de/ffw/action/invoke.do?id=1807" target="_blank" rel="noopener" style="white-space: nowrap;">FMS<mat-icon class="small">launch</mat-icon></a>
                </span>
                <span class="center-align">
                    <a (click)="download1807ZP(); $event.stopPropagation()" (keydown.enter)="download1807ZP(); $event.preventDefault(); $event.stopPropagation()" role="button" [tabindex]="0" [ngClass]="{'isDisabled': creatingXML}" class="report-link">XML<mat-icon class="small">download</mat-icon></a><br />
                    für <a href="https://www.ozg-zoll.zoll-portal.de/ozg-zoll-tiger/catalog/openForm.do?path=catalog://Formulare/TIGER/1807" target="_blank" rel="noopener" style="white-space: nowrap;">Zoll-Portal<mat-icon class="small">launch</mat-icon></a>
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="vertical-spacer"></div>
            <div class="stammdaten-openlink">
                <a (click)="companyDataOpened = !companyDataOpened" (keydown.enter)="companyDataOpened = !companyDataOpened; $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_stammdaten" class="cursor-pointer">{{companyDataOpened ? 'Verstecke Stammdaten' : 'Zeige Stammdaten'}}</a>
            </div>
            <ng-container *ngTemplateOutlet="companyData"></ng-container>
            <mat-form-field data-test="npt_month" class="formfield-small">
                <mat-select required matInput name="month"
                    placeholder="Monat"
                    [(ngModel)]="month1807" (selectionChange)="monthYearChanged()">
                    <mat-option [value]="null"></mat-option>
                    @for (month of months; track month) {
                    <mat-option [value]="$index + 1">{{month}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            @if (!loadingFormData['1807']) {
            <mat-form-field class="formfield-small">
                <input required matInput name="year"
                    placeholder="Jahr"
                    [(ngModel)]="year1807" (change)="monthYearChanged()"
                    type="number" pattern="\d*" minlength="4" maxlength="4">
            </mat-form-field>
            }
            <br />
            @if (loadingFormData['1807']) {
            <mat-spinner diameter="16"></mat-spinner>
            }
            <mat-form-field class="form-group formfield-twosmall">
                <input required matInput type="text" pattern="\d*(,\d+)?"
                    placeholder="Menge zu versteuernder Röstkaffee in Kilogramm" [matTooltip]="menge1Hint"
                    [value]="(form1807.menge1 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('menge1', $event, '1807'); menge1Changed = true">
            </mat-form-field>
            <br />
            <mat-form-field class="form-group formfield-twosmall">
                <input matInput type="text" pattern="\d*" minlength="10" maxlength="10"
                    placeholder="Mandat SEPA Firmenlastschrift, 10 Ziffern"
                    [(ngModel)]="form1807['1807_nr3']">
            </mat-form-field>
            <br />
        </ng-template>
        <!-- <mat-action-row>
          <button mat-raised-button type="button" (click)="download1830()" class="action-button">XML</button>
        </mat-action-row> -->
    </mat-expansion-panel>
    }
    @if (customs.report_type.country === 'Germany') {
    <mat-expansion-panel hideToggle="true" style="margin-bottom: 15px;">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto">
            <mat-panel-title data-test="hdr_form1830" class="paneltitle">Formular 1830 - Bestandsanmeldung Kaffee</mat-panel-title>
            <mat-panel-description>
                <!-- <a href="https://www.formulare-bfinv.de/ffw/action/invoke.do?id=1830" target="_blank" rel="noopener">Gespeichertes XML in 1830 Formular laden</a> -->
                <span class="right-align">
                    <a (click)="download1830(); $event.stopPropagation()" (keydown.enter)="download1830(); $event.preventDefault()" role="button" [tabindex]="0" [ngClass]="{'isDisabled': creatingXML}" class="report-link">XML<mat-icon class="small">download</mat-icon></a> für <a href="https://www.formulare-bfinv.de/ffw/action/invoke.do?id=1830" target="_blank" rel="noopener">FMS<mat-icon class="small">launch</mat-icon></a>
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="vertical-spacer"></div>
            <div class="stammdaten-openlink">
                <a (click)="companyDataOpened = !companyDataOpened" (keydown.enter)="companyDataOpened = !companyDataOpened; $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_stammdaten" class="cursor-pointer">{{companyDataOpened ? 'Verstecke Stammdaten' : 'Zeige Stammdaten'}}</a>
            </div>
            <ng-container *ngTemplateOutlet="companyData"></ng-container>
            <mat-form-field class="form-group formfield-wide">
                <input matInput [matDatepicker]="myDatepicker" placeholder="Jetzige Bestandsaufnahme"
                    [value]="form1830.this_date" (dateChange)="dateInput('this', $event.value, '1830')" name="this_date">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field><br />
            @if (!loadingFormData['1830']) {
            <mat-form-field class="form-group formfield-wide">
                <input matInput [matDatepicker]="myDatepicker2" placeholder="Letzte Bestandsaufnahme"
                    [value]="form1830.last_date" (dateChange)="dateInput('last', $event.value, '1830')" name="last_date">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker2></mat-datepicker>
            </mat-form-field>
            }
            <mat-form-field class="form-group formfield-rest">
                <mat-select [(ngModel)]="form1830.last_inventory_type" placeholder="Letzte Bestandsaufnahme">
                    <mat-option value="1">betrieblich</mat-option>
                    <mat-option value="0">amtlich</mat-option>
                </mat-select>
            </mat-form-field><br />
            @if (loadingFormData['1830']) {
            <mat-spinner diameter="16"></mat-spinner>
            }
            <mat-form-field class="form-group formfield-only">
                <textarea matInput type="text" [(ngModel)]="form1830.stellungnahme_1830"
                    placeholder="Stellungnahme zu Fehl-/Mehrmengen">
            </textarea>
            </mat-form-field><br />
            <mat-form-field class="form-group formfield-wide">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1830.roest1 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('roest1', $event, '1830')" placeholder="Istbestände bei der letzten Bestandsaufnahme">
            </mat-form-field>
            <mat-form-field class="form-group formfield-rest">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="form1830.roest2 | number:'1.3-3':'de'"
                    (change)="setValue('roest2', $event, '1830')"
                    placeholder="Noch nicht als hergestellter Kaffee erfasst"
                    matTooltip="Bestände (der Istbestände bei der letzten Bestandsaufnahme), die noch nicht als hergestellter Kaffee erfasst sind. Als Bestände, die noch nicht als hergestellter Kaffee gelten, sind die noch nicht in den steuerlichen Aufzeichnungen erfassten Kaffeemengen zu verstehen">
            </mat-form-field><br />
            @if (!loadingFormData['1830']) {
            <mat-form-field class="form-group formfield-wide">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1830.roest3 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('roest3', $event, '1830')" (blur)="roest3Changed = true"
                    placeholder="Zugänge aus der Herstellung (Abt. I A Sp. 5 - 7)" [matTooltip]="roest3Hint">
            </mat-form-field>
            }
            @if (loadingFormData['1830']) {
            <mat-spinner diameter="16"></mat-spinner>
            }
            <br />
            <mat-form-field class="form-group formfield-wide" style="margin-right: 2%;">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1830.roest4 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('roest4', $event, '1830')" placeholder="Unversteuerte Aufnahme (Abt. I A Sp. 5 - 7)">
            </mat-form-field>
            <mat-form-field class="form-group formfield-rest" style="margin-right: 2%;">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1830.roest5 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('roest5', $event, '1830')" placeholder="Versteuerte Aufnahme (Abt. I B Sp. 5 - 7)">
            </mat-form-field><br />
            <mat-checkbox class="primary-element non-bold"
                [(ngModel)]="form1830.allOutTaxed"
                (change)=allOutTaxedChanged()>
                Alle Zugänge (gerösteter Kaffee, Zeile 4) sind versteuerte Abgänge (Zeile 8)
            </mat-checkbox><br />
            @if (!form1830.allOutTaxed) {
            <mat-form-field class="form-group formfield">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1830.roest6 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('roest6', $event, '1830')" placeholder="Versteuerte Abgänge (Abt. II Sp. 4 - 6)">
            </mat-form-field>
            }<br>
            @if (!form1830.allOutTaxed) {
            <mat-form-field class="form-group formfield" style="margin-right: 2%;">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1830.roest7 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('roest7', $event, '1830')" placeholder="Unversteurte Abgänge (Abt. III A und III B Sp. 4 - 6)">
            </mat-form-field>
            }
            @if (!form1830.allOutTaxed) {
            <mat-form-field class="form-group formfield" style="margin-right: 2%;">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1830.roest8 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('roest8', $event, '1830')" placeholder="Abgänge in die Produktion (Abt. III C Sp. 4 - 6)">
            </mat-form-field>
            }<br />
            @if (!loadingFormData['1830']) {
            <mat-form-field class="form-group formfield-wide">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1830.roest9 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('roest9', $event, '1830')" (blur)="roest9Changed = true"
                    placeholder="Istbestände der jetzigen Bestandsaufnahme" [matTooltip]="roest9Hint">
            </mat-form-field>
            }
            <mat-form-field class="form-group formfield-rest">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1830.roest10 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('roest10', $event, '1830')"
                    placeholder="Noch nicht als hergestellter Kaffee erfasst"
                    matTooltip="Bestände (der Istbestände bei der letzten Bestandsaufnahme), die noch nicht als hergestellter Kaffee erfasst sind. Als Bestände, die noch nicht als hergestellter Kaffee gelten, sind die noch nicht in den steuerlichen Aufzeichnungen erfassten Kaffeemengen zu verstehen">
            </mat-form-field>
            @if (loadingFormData['1830']) {
            <mat-spinner diameter="16"></mat-spinner>
            }
        </ng-template>
        <!-- <mat-action-row>
            <button mat-raised-button type="button" (click)="download1830()" class="action-button">XML</button>
          </mat-action-row> -->
    </mat-expansion-panel>
    }
    @if (customs.report_type.country === 'Germany') {
    <mat-expansion-panel hideToggle="true" style="margin-bottom: 15px;">
        <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto">
            <mat-panel-title data-test="hdr_form1816" class="paneltitle">Formular 1816 - Steueranmeldung für Kaffee im Einzelfall</mat-panel-title>
            <mat-panel-description>
                <!-- <a href="https://www.formulare-bfinv.de/ffw/action/invoke.do?id=1816" target="_blank" rel="noopener">Gespeichertes XML in 1816 Formular laden</a> -->
                <span class="right-align">
                    <a (click)="download1816(); $event.stopPropagation()" (keydown.enter)="download1816(); $event.preventDefault()" role="button" [tabindex]="0" [ngClass]="{'isDisabled': creatingXML}" class="report-link">XML<mat-icon class="small">download</mat-icon></a> für <a href="https://www.formulare-bfinv.de/ffw/action/invoke.do?id=1816" target="_blank" rel="noopener">FMS<mat-icon class="small">launch</mat-icon></a>
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="vertical-spacer"></div>
            <div class="stammdaten-openlink">
                <a (click)="companyDataOpened = !companyDataOpened" (keydown.enter)="companyDataOpened = !companyDataOpened; $event.preventDefault()" role="button" [tabindex]="0" data-test="lnk_stammdaten" style="cursor: pointer;">{{companyDataOpened ? 'Verstecke Stammdaten' : 'Zeige Stammdaten'}}</a>
            </div>
            <ng-container *ngTemplateOutlet="companyData"></ng-container>
            @if (!loadingFormData['1816']) {
            <mat-form-field class="form-group formfield-wide">
                <input matInput [matDatepicker]="myDatepicker2" placeholder="Von"
                    [value]="form1816.startDate" (dateChange)="dateInput('startDate', $event.value, '1816')" name="startDate">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepicker2"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker2></mat-datepicker>
            </mat-form-field>
            }
            @if (!loadingFormData['1816']) {
            <mat-form-field class="form-group formfield-wide">
                <input matInput [matDatepicker]="myDatepicker3" placeholder="Bis"
                    [value]="form1816.endDate" [min]="form1816.startDate" (dateChange)="dateInput('endDate', $event.value, '1816')" name="endDate">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepicker3"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker3></mat-datepicker>
            </mat-form-field>
            }<br />
            <mat-checkbox class="primary-element non-bold"
                [(ngModel)]="form1816.promonat"><span style="white-space:normal;">
                    Das HZA hat mir die Abgabe einer für einen Kalendermonat zusammengefassten Steueranmeldung für Kaffee zugelassen</span>
            </mat-checkbox><br />
            @if (form1816.promonat) {
            <!-- Mit Zulassung des Hauptzollamts vom: -->
            <!-- <element id="zulassungsdatum_hza_1816">16.03.2023 00:00:00</element> -->
            <mat-form-field class="form-group formfield-wide">
                <input matInput [matDatepicker]="myDatepickerPM" placeholder="Mit Zulassung des Hauptzollamts vom"
                    [value]="form1816.zulassungsdatum_hza_1816" (dateChange)="dateInput('zulassungsdatum_hza_1816', $event.value, '1816')" name="zulassungsdatum_hza_1816">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepickerPM"></mat-datepicker-toggle>
                <mat-datepicker #myDatepickerPM></mat-datepicker>
            </mat-form-field>
            <!-- Steueranmeldung für den Monat: -->
            <!-- <element id="steueranmeldung_monat_1816">01.03.2023 00:00:00</element> -->
            <mat-form-field appOnlyMonthFormat class="form-group formfield-wide">
                <input matInput [matDatepicker]="myDatepickerPMM" placeholder="Steueranmeldung für den Monat"
                    [value]="form1816.steueranmeldung_monat_1816" (dateChange)="dateInput('steueranmeldung_monat_1816', $event.value, '1816')" name="steueranmeldung_monat_1816">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepickerPMM"></mat-datepicker-toggle>
                <mat-datepicker #myDatepickerPMM></mat-datepicker>
            </mat-form-field>
            <br />
            }
            <br />
            <mat-label>Ich melde die in Feld 5 angeführten Mengen als Steuerschuldner für Kaffee und/oder kaffeehaltigen Waren an: Aufgrund/Als (KafdeeStG)</mat-label><br />
            <mat-form-field class="form-group formfield-only">
                <mat-select [(ngModel)]="form1816.reason">
                    <mat-option [value]="1" matTooltip="von unrechtmäßiger Entnahme aus dem Steuerlager">§11 Abs. 5 Nummer 1, 2. Alt.; unrechtmäßige Entnahme</mat-option>
                    <mat-option [value]="2" matTooltip="der Herstellung ohne Erlaubnis">§11 Abs. 5 Nummer 2; Herstellung ohne Erlaubnis</mat-option>
                    <mat-option [value]="3" matTooltip="von Unregelmäßigkeiten während der Beförderung unter Steueraussetzung">§11 Abs. 5 Nummer 3, Unregelmäßigkeiten, Steueraussetzung</mat-option>
                    <mat-option [value]="4" matTooltip="des Bezuges und Besitzes zu gewerblichen Zwecken aus dem zollrechtlich freien Verkehr Verkehr anderer Mitgliedstaaten">§17 Abs. 1 oder 2; aus anderen EU Staaten</mat-option>
                    <mat-option [value]="5" matTooltip="Versandhändler im Einzelfall">§18 Abs. 5 Satz 1, Versandhändler im Einzelfall</mat-option>
                    <mat-option [value]="6" matTooltip="Steuervertreter eines Versandhändlers im Einzelfall">§18 Abs. 5 Satz 1, Steuervertreter im Einzelfall</mat-option>
                    <mat-option [value]="7" matTooltip="von Unregelmäßigkeiten während der Beförderung im Rahmen des Bezugs und des Besitzes zu gewerblichen Zwecken">§19 Abs. 3 Satz 1; Unregelmäßigkeiten, gewerbliche Zwecke</mat-option>
                    <mat-option [value]="8" matTooltip="von Unregelmäßigkeiten während der Beförderung im Rahmen des Versandhandels">§19 Abs. 3 Satz 1; Unregelmäßigkeiten, Versandhandels</mat-option>
                    <mat-option [value]="9" matTooltip="von nicht rechtzeitiger Ausfuhr bzw. Lieferung der Ausgleichsware">§20 Abs. 2 KaffeeStG + §30 Abs. 6 Satz 2 KaffeeStV</mat-option>
                </mat-select>
            </mat-form-field><br />
            @if (loadingFormData['1816']) {
            <mat-spinner diameter="16"></mat-spinner>
            }
            <mat-checkbox class="primary-element non-bold"
                [(ngModel)]="form1816['1816_k20']"><span style="white-space:normal;">
                    Der Bezug bzw. die Lieferung des Versandhändlers von Waren aus dem zollrechtlich freien Verkehr anderer Mitgliedstaaten wurde angezeigt</span>
            </mat-checkbox><br />
            @if (form1816['1816_k20']) {
            <mat-form-field class="form-group formfield-date">
                <input matInput [matDatepicker]="myDatepicker" placeholder="am"
                    [value]="form1816['1816_datum1']" (dateChange)="dateInput('1816_datum1', $event.value, '1816')" name="1816_datum1">
                <mat-datepicker-toggle matIconSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
            }
            @if (form1816['1816_k20']) {
            <mat-form-field class="form-group formfield">
                <input matInput type="text" [(ngModel)]="form1816['1816_freitext']" placeholder="Geschäftszeichen">
            </mat-form-field>
            }<br>
            <mat-form-field class="form-group formfield-wide">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1816.menge1 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('menge1', $event, '1816'); menge1Changed = true" placeholder="Feld 5, Menge Röstkaffee in Kilogramm" [matTooltip]="menge1Hint">
            </mat-form-field>
            <mat-form-field class="form-group formfield-wide">
                <input matInput type="text" pattern="\d*(,\d+)?" [value]="(form1816.menge2 | number:'1.3-3':'de')?.replace('.', '')"
                    (change)="setValue('menge2', $event, '1816')" placeholder="Menge löslicher Kaffee in Kilogramm">
            </mat-form-field><br />
            <mat-checkbox class="primary-element non-bold formfield-small"
                [(ngModel)]="form1816['1816_k22']">Anlagen
            </mat-checkbox>
            @if (form1816['1816_k22']) {
            <mat-form-field class="form-group formfield-rest">
                <input matInput type="text" [(ngModel)]="form1816['1816_anlage1']" placeholder="Anlagen">
            </mat-form-field>
            }<br>
            @if (loadingFormData['1816']) {
            <mat-spinner diameter="16"></mat-spinner>
            }
        </ng-template>
        <!-- <mat-action-row>
            <button mat-raised-button type="button" (click)="download1816()" class="action-button">XML</button>
          </mat-action-row> -->
    </mat-expansion-panel>
    }
</mat-accordion>
}