<ng-template #partnerButton>
    <a data-test="lnk_partners" type="button" class="tertiary-element small-button"
        [href]="'https://' + isDarkmode + 'doc.artisan.plus/partners'"
        target="_blank" rel="noopener"
        mat-raised-button i18n="partners$$Partner Coupons@@Partner Coupons">Partner Coupons</a>
</ng-template>

<app-ap-toolbar [opaque]="opaque" [relative]=false [paidUntil]="paidUntil" [returnUrl]="returnUrl"></app-ap-toolbar>

<div class="backdrop" #pic0>
    <div class="background-mask"></div>

    <div class="page-scroll">
        <span class="btn-circle">
            <mat-icon class="animated" (click)="scrollToPic(1)">expand_more</mat-icon>
        </span>
    </div>
</div>

<div class="main-center">
    <div class="partner-button-landscape">
        <ng-container *ngTemplateOutlet="partnerButton"></ng-container>
    </div>
    <img class="main-svg fadeInDown" src="assets/images/main/artisan-plus-main.svg" alt="artisan plus logo">
    <hr class="fadeInDown">
    <div class="main-text fadeInDown"><small i18n="title$$inventory management@@inventory management">inventory management</small></div>
    <!-- <div class="partner-button fadeInDown">
        <ng-container *ngTemplateOutlet="partnerButton"></ng-container>
    </div> -->
</div>

<div class="main-row" #pic1>
    <div class="main-explanation" i18n="title$$How can artisan.plus support you?@@How can artisan.plus support you?">How can artisan.plus support you?</div>
    <div class="main-features">
        <div>
            <div class="welcome-feature-container">
                <ul style="margin-bottom: 0;">
                    <li i18n="title$$simplifies planning@@simplifies planning">simplifies <a [href]="'https://' + isDarkmode + 'doc.artisan.plus/docs/schedule/'" target="_blank" rel="noopener">planning</a> and running your roasts</li>

                    <li i18n="title$$every roast automatically reduces your stock@@every roast automatically reduces your stock">every roast automatically reduces your stock</li>

                    <li i18n="title$$roast even without an internet connection@@roast even without an internet connection">roast even <a [href]="'https://' + isDarkmode + 'doc.artisan.plus/docs/artisan/'" target="_blank" rel="noopener">without an internet connection</a></li>

                    <li i18n="title$$keeps your roast profiles private@@keeps your roast profiles private">keeps your roast profiles private</li>

                    <li i18n="title$$includes unlimited users and machines@@includes unlimited users and machines">includes unlimited users and machines</li>
                </ul>
                <span style="margin-left: 15px; margin-right: 5px; margin-top: 1em;"><a href="https://artisan-scope.org" target="_blank" rel="noopener"><img src="assets/icons/artisan-blue.svg" width="80px" alt="artisan plus logo"></a></span>
            </div>
            <div class="welcome-feature-container">
                <ul>
                    <li i18n="title$$blends and certifications@@blends and certifications">manages <a [href]="'https://' + isDarkmode + 'doc.artisan.plus/docs/blends/'" target="_blank" rel="noopener">blends</a> &amp; tracks <a [href]="'https://' + isDarkmode + 'doc.artisan.plus/docs/beans/'" target="_blank" rel="noopener">certifications</a></li>

                    <li i18n="title$$charts and tax reports@@charts and tax reports">offers <a [href]="'https://' + isDarkmode + 'doc.artisan.plus/docs/charts/#store_charts'" target="_blank" rel="noopener">charts</a>, <a [href]="'https://' + isDarkmode + 'doc.artisan.plus/docs/reports/'" target="_blank" rel="noopener">tax reports</a> &amp; <a [href]="'https://' + isDarkmode + 'doc.artisan.plus/docs/charts/#roast_charts'" target="_blank" rel="noopener">predictions</a></li>

                    <li i18n="title$$imports coffee descriptions@@imports coffee descriptions">imports coffee descriptions from <a [href]="'https://' + isDarkmode + 'doc.artisan.plus/partners/'" target="_blank" rel="noopener">traders</a></li>

                    <!-- <li>define maintenance <a [href]="'https://' + isDarkmode + 'doc.artisan.plus/docs/reminders/'" target="_blank" rel="noopener">reminders</a></li> -->
                    <!-- <li><span>multilingual</span>&nbsp;(EN, DE, IT, FR, PT, CN)</li> -->

                    <li i18n="title$$3 months free testing@@3 months free testing"><a href="https://buy.artisan.plus/PRO-Trial" target="_blank" rel="noopener">3 months free testing</a> - <a href="https://buy.artisan.plus/PRO" target="_blank" rel="noopener">48€ / month (discounts for longer subscriptions)</a></li>

                    <li i18n="title$$includes unlimited warehouses and bean quantities@@includes unlimited warehouses and bean quantities">includes unlimited warehouses and bean quantities</li>
                </ul>
                <span style="margin-left: 15px; margin-right: 5px;"><img src="assets/icons/artisan-plus-only-inverted.svg" width="80px" alt="artisan plus logo"></span>
            </div>
        </div>
    </div>
    <div class="page-scroll">
        <span class="btn-circle">
            <mat-icon (click)="scrollToPic(2)">expand_more</mat-icon>
        </span>
    </div>
</div>

<div class="main-row" #pic2>
    <div class="main-explanation" i18n="title$$graph feature@@description 1">How long will my beans last?</div>
    <img class="main-image" [src]="'assets/images/main/stocknage' + picSuffix + '.png'" alt="a picture showing artisan plus graphs of your beans stock over time">
    <div class="page-scroll">
        <span class="btn-circle">
            <mat-icon (click)="scrollToPic(3)">expand_more</mat-icon>
        </span>
    </div>
</div>

<div class="main-row back" #pic3>
    <div class="main-explanation" i18n="title$$graph feature@@description 2">How much is my stock worth?</div>
    <img class="main-image" [src]="'assets/images/main/store' + picSuffix + '.png'" alt="a picture of two stores showing the current stock of beans and more">
    <div class="page-scroll">
        <span class="btn-circle">
            <mat-icon (click)="scrollToPic(4)">expand_more</mat-icon>
        </span>
    </div>
</div>

<div class="main-row" #pic4>
    <div class="main-explanation" i18n="title$$graph feature@@description 3">What types of beans do I have?</div>
    <img class="main-image" [src]="'assets/images/main/properties' + picSuffix + '.png'" alt="a picture showing graphs representing properties of your beans such as species, varieties, and certifications">
    <div class="page-scroll">
        <span class="btn-circle">
            <mat-icon (click)="scrollToPic(5)">expand_more</mat-icon>
        </span>
    </div>
</div>

<div class="main-row back" #pic5>
    <div class="main-explanation" i18n="title$$graph feature@@description 4">How to capture my roasts efficiently?</div>
    <img class="main-image" [src]="'assets/images/main/roast' + picSuffix + '.png'" alt="a picture of artisan scope showing roast properties">
    <div class="page-scroll">
        <span class="btn-circle">
            <mat-icon (click)="scrollToPic(6)">expand_more</mat-icon>
        </span>
    </div>
</div>

<div class="main-row" #pic6>
    <div class="main-explanation" i18n="title$$graph feature@@description 5">How am I doing?</div>
    <img class="main-image" [src]="'assets/images/main/production' + picSuffix + '.png'" alt="a picture showing graphs with the accumulated and yearly production">
    <div class="page-scroll">
        <span class="btn-circle">
            <mat-icon (click)="scrollToPic(7)">expand_more</mat-icon>
        </span>
    </div>
</div>

<div class="main-row back" #pic7>
    <div class="main-explanation" i18n="title$$graph feature@@description 6">How to report?</div>
    <img class="main-image" [src]="'assets/images/main/report' + picSuffix + '.png'" alt="a picture showing a sample report">
    <div class="page-scroll">
        <span class="btn-circle">
            <mat-icon (click)="scrollToPic(0)">keyboard_arrow_up</mat-icon>
        </span>
    </div>
</div>