<div class="scheduler-planner-favorites-container">
    @if (line === 0) {
    <div class="scheduler-planner-favorites-header" i18n="generic$$Favorites@@Favorites">Favorites</div>
    @if (isAdding) {
    <mat-spinner style="display: inline-block; margin-left: 10px;" diameter="15"></mat-spinner>
    }
    }
    <div class="scheduler-planner-div-container2">
        <div class="scheduler-planner-div-container2 scheduler-planner-div-container-inner scheduler-planner-main"
            cdkDropList [cdkDropListDisabled]="readOnly"
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{fromFavorites: true, items: favItems, line}"
            (cdkDropListDropped)="drop($event)">
            <!-- @if (showClone && favItems[dragIndex]; as item) {
        <div class="scheduler-list-item cursor-move"
            [ngClass]="{'selected': item.selected}">
            <div class="scheduler-list-item-border">
            </div>
            <div class="scheduler-list-item-inner">
                <div class="scheduler-list-item-left"
                    [ngClass]="{'scheduler-list-item-roasted': item.roasts?.length}">
                    <div class="scheduler-list-item-count">
                        {{item.count}}x
                    </div>
                    <div class="scheduler-list-item-title-container">
                        <app-organicicon [isOrganic]="item.blend?.organic || item.coffee?.organic" [size]="12"></app-organicicon>
                        <div class="scheduler-list-item-title">{{item.title}}</div>
                    </div>
                </div>
                <div class="scheduler-list-item-amount">
                    {{item.itemAmount}}
                </div>
            </div>
        </div>
        } -->
            @for (item of favItems; track item?._id) {
            <div class="scheduler-list-item cursor-move"
                [ngClass]="{'selected': item.selected}"
                [matTooltip]="item.selected ? (tr.anslate('Added {{date}}', { date: (item.created_at ?? DateTime.now().toJSDate()) | date:'mediumDate' })) : item.tooltip"
                [matTooltipShowDelay]="item.selected ? 1500 : 750" matTooltipPosition="after"
                (click)="selectItem($index)" (keydown.enter)="selectItem($index); $event.preventDefault()" role="button" [tabindex]="0"
                cdkDrag [cdkDragData]="{item, fromFavorites: true, idx: $index}"
                (cdkDragStarted)="dragStarted($event)"
                (cdkDragReleased)="dragReleased()"
                cdkDragPreviewContainer="parent">
                <div class="scheduler-list-custom-placeholder2" *cdkDragPlaceholder></div>
                <div class="scheduler-list-item-border">
                </div>
                <div class="scheduler-list-item-inner">
                    <div class="scheduler-list-item-left"
                        [ngClass]="{'scheduler-list-item-roasted': (item.synced || item.roasts?.length) && item.count > (item.roasts?.length ?? 0)}"
                        [ngStyle]="{'margin-right': animateItem[$index] && item.marginRightForDelete ? item.marginRightForDelete : '5px'}">
                        <div class="scheduler-list-item-count">
                            {{item.count}}x
                        </div>
                        <div class="scheduler-list-item-title-container">
                            <app-organicicon [isOrganic]="item.blend?.organic || item.coffee?.organic" [size]="12"></app-organicicon>
                            <div class="scheduler-list-item-title">{{item.title}}</div>
                        </div>
                    </div>
                    <div class="scheduler-list-item-amount">
                        {{item.itemAmount}}
                    </div>
                    @if (!readOnly) {
                    <div class="scheduler-list-item-delete-overlay"
                        [ngStyle]="{'width': animateItem[$index] ? deleteWidth : '0'}"
                        (click)="deleteItem($index)" (keydown.enter)="deleteItem($index); $event.preventDefault()" role="button" [tabindex]="0">
                    </div>
                    <div [@showDelete]="{value: animateItem[$index] ? 'end' : 'start', params: {deleteWidth: deleteWidth}}" class="scheduler-list-item-delete">
                        <mat-icon class="scheduler-list-item-delete-icon">delete</mat-icon>
                    </div>
                    }
                </div>

                @if (item.selected) {
                @if (item.blendInfo) {
                <div class="scheduler-list-item-blendinfo">{{item.blendInfo}}</div>
                }
                @if (item.info1) {
                <div class="scheduler-list-item-info">{{item.info1}}</div>
                }
                @if (item.info2) {
                <div class="scheduler-list-item-info">{{item.info2}}</div>
                }
                @if (item.note) {
                <div class="scheduler-list-item-info"><i>{{item.note}}</i></div>
                }
                }
            </div>
            }
        </div>
        @if (favItems?.length) {
        <div class="scheduler-list-item" style="background: none; box-shadow: none;">
            <div class="scheduler-list-item-inner">
            </div>
        </div>
        }
    </div>
</div>