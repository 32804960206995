import { LegalFrameComponent } from './legal-frame.component';
import { ImprintComponent } from './imprint.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './privacy.component';

const routes: Routes = [
    {
        path: '',
        component: LegalFrameComponent,
        children: [
            {
                path: '',
                component: ImprintComponent,
            },
            {
                path: 'privacy',
                component: PrivacyComponent,
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LegalRoutingModule { }
