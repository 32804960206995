<ng-template #link>
    @if (isLocalLink) {
    <a [routerLink]="localRouterLink" class="notification-link">
        <mat-icon class="notification-icon">launch</mat-icon>
    </a>
    } @else {
    <a [href]="textdata.link" target="_blank"
        rel="noopener" class="notification-link">
        <mat-icon class="notification-icon">launch</mat-icon>
    </a>
    }
</ng-template>

<div class="custom-snack">
    <div>
        @if (textdata) {
        @if (textdata.date) {
        <div class="notification-date">
            {{textdata.date.toLocaleString(DateTime.DATE_SHORT)}}
            @if (textdata.link) {
            <ng-container *ngTemplateOutlet="link"></ng-container>
            }
        </div>
        }
        <span>
            @if (textdata.title) {
            <div class="notification-title">
                @if (textdata.notification?.machine) {
                {{textdata.notification.machine}}:
                }
                {{textdata.title}}
            </div>
            }
            @if (!textdata.title && textdata.notification?.machine) {
            <span>{{textdata.notification.machine}}: </span>
            }
            @if (textdata.html) {
            <span [innerHTML]="textdata.html"></span>
            }
            @if (textdata.text && !textdata.html) {
            <span>{{textdata.text}}</span>
            }
        </span>
        }
    </div>
    <span class="mat-simple-snackbar-action">
        @if (textdata?.notification) {
        <input matInput [matDatepicker]="picker" style="width: 0; visibility: hidden;"
            [value]="today" [min]="today" (dateInput)="snooze($event)">
        <mat-datepicker #picker>
            <mat-datepicker-actions>
                <button mat-button matDatepickerCancel class="cancel-button">
                    <mat-icon>close</mat-icon>
                </button>
                <button mat-raised-button matDatepickerApply class="action-button">
                    <mat-icon>done</mat-icon>
                </button>
            </mat-datepicker-actions>
        </mat-datepicker>
        <button (click)="picker.open()" type="button" mat-button class="snack-button" style="min-width: 32px;">
            <mat-icon style="width: 24px; height: 24px; margin: 0">snooze</mat-icon>
        </button>
        }

        <button (click)="done()" mat-button class="snack-button" style="min-width: 32px; padding-right: 0; padding-left: 0;">
            <mat-icon style="width: 24px; height: 24px; margin: 0">done</mat-icon>
        </button>
    </span>
</div>