<!-- <ng-template #empty2tds>
<td></td>
<td></td>
</ng-template> -->

@if (reportOverview) {
<!-- <div *ngIf="!opened && (nrEntries > 0 || report.roastsCount || report.roasts?.length)" class="overview-openlink">
  <a (click)="openUp(true)" class="cursor-pointer" i18n="reports$$report overview@@Link to display an overview of roasts per bean/blend">Show overview per bean / blend</a>
</div> -->
@if (opened && nrEntries > 0) {
<div class="overview-table-container">
    @if (showExportUI) {
    <app-report-export-ui #reportExportOverview
        [currentUser]="currentUser"
        [report]="report"
        [overview]="true"
        [readOnly]="readOnly"
        [isOpenReport]="isOpenReport"
        [currentSort]="currentSort"
        [show1808]="false">
    </app-report-export-ui>
    }
    <div style="position: relative;">
        <table mat-table [dataSource]="dataSource"
            class="roastreport-overview-table mat-elevation-z8"
            matSort (matSortChange)="sortData($event)">
            <tr mat-header-row *matHeaderRowDef="topHeaderColumns" class="top-header-row"></tr>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="bottom-header-row"></tr>
            <tr mat-row *matRowDef="let entry; columns: columnsToDisplay; when: isBlendRow" style="height: inherit;"></tr>
            <tr mat-row *matRowDef="let entry; columns: coffeeColumnsOnly; when: !isBlendRow" style="height: inherit;"></tr>
            <tr mat-footer-row *matFooterRowDef="columnsToDisplayFooter" class="sum-cell"></tr>
            <ng-container matColumnDef="top-header-none">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="columnsUntilBlendsPart" class="top-header"></th>
            </ng-container>
            <ng-container matColumnDef="top-header-blends">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" class="top-header overview-blend-label" style="text-align: center; padding-right: 0; padding-left: 25px;" i18n="reports$$Beans used in blends, header@@Beans used in blends, header">Beans used in blends</th>
            </ng-container>
            <ng-container matColumnDef="top-header-none2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" [attr.colspan]="2" class="top-header"></th>
            </ng-container>
            <ng-container matColumnDef="NrRoasts">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" start="desc"
                    class="bottom-header report-number-roast-header">#</th>
                <td mat-cell *matCellDef="let entry; let i=index" class="right-align top-align" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}">
                    <span class="overview-value-t">{{entry.nrRoasts || ''}}</span>
                </td>
                <td mat-footer-cell *matFooterCellDef colspan="2"><span i18n="generic$$total number of some items@@Total">Total</span>: {{reportOverview.nrRoasts}}</td>
            </ng-container>
            <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="after" class="bottom-header"><span i18n="generic$$Beans@@Beans">Beans</span>/<span i18n="generic$$Blend@@Blend">Blend</span></th>
                <td mat-cell *matCellDef="let entry; let i=index" class="overview-label-t" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}" [attr.colspan]="entry.amount || entry.endWeight ? 1 : 4">
                    @if (entry?.coffee; as coffee) {
                    <span>
                        @if (coffee.hr_id) {
                        <a [routerLink]="['/coffees', {id: coffee.hr_id}]">{{coffee.hr_id}}</a>
                        }
                        <app-organicicon [isOrganic]="entry.organic" [size]="11" [leftMargin]="5" [rightMargin]="3"></app-organicicon>
                        <!-- <app-organicicon [isOrganic]="utils.isOrganicCoffee(coffee)" [size]="11" [leftMargin]="5" [rightMargin]="3"></app-organicicon> -->
                        @if (coffee.origin) {
                        <span> {{tr.anslate(coffee.origin)}}</span>
                        }
                        @if (coffee.yearLabel) {
                        <span> {{coffee.yearLabel}}</span>
                        }
                        @if (coffee.label && (coffee.hr_id || coffee.origin || coffee.yearLabel)) {
                        <br />
                        }
                        @if (coffee.label) {
                        <span> {{coffee.label}}</span>
                        }
                        @if (entry.inBlend) {
                        <br />
                        }
                        @if (entry.inBlend) {
                        <span class="overview-blend-label"> {{entry.inBlend}}</span>
                        }
                    </span>
                    }
                    @if (entry?.blend; as blend) {
                    <span>
                        <!-- <ng-container *ngIf="blend"> -->
                        @if (entry.blendId) {
                        <a [routerLink]="['/blends', {id: entry.blendId}]">{{blend}}</a>
                        }
                        @if (!entry.blendId) {
                        <span>{{blend}}</span>
                        }
                        <app-organicicon [isOrganic]="entry.organic" [size]="11" [leftMargin]="5" [rightMargin]="5"></app-organicicon>
                        <!-- <app-organicicon [isOrganic]="utils.isOrganicBlend(blend)" [size]="11" [leftMargin]="5" [rightMargin]="5"></app-organicicon> -->
                        <span class="overview-blend-label littlespaceleft">(<span i18n="generic$$Blend@@Blend">Blend</span>)</span>
                        <!-- </ng-container> -->
                        <!-- <ul class="ingredients-list">
                            <li *ngFor="let ing of blend.ingredients">
                                <span *ngIf="ing.ratio != null">{{ing.ratio * 100 | number:'1.1-1'}}%
                                <a *ngIf="ing.coffee?.hr_id" [routerLink]="['/coffees', {id: ing.coffee.hr_id}]">{{ing.coffee.hr_id}}</a>
                                <app-organicicon *ngIf="ing.coffee?.certifications" [isOrganic]="utils.isOrganicCoffee(ing.coffee)" [size]="11" [leftMargin]="5" [rightMargin]="3"></app-organicicon>
                                <span *ngIf="ing.coffee?.origin"> {{tr.anslate(ing.coffee.origin)}}</span>
                                <span *ngIf="ing.coffee?.yearLabel"> {{ing.coffee.yearLabel}}</span>
                                <span *ngIf="ing.coffee?.label && (ing.coffee.origin || ing.coffee.yearLabel)">,</span>
                                <span *ngIf="ing.coffee?.label"> {{ing.coffee.label}}</span>
                                </span>
                            </li>
                        </ul> -->
                    </span>
                    }
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- <ng-container matColumnDef="Organic">
                <th mat-header-cell *matHeaderCellDef class="bottom-header"></th>
                <td mat-cell *matCellDef="let entry" class="overview-separator">
                <ng-container *ngIf="entry?.coffee">
                    <app-organicicon [isOrganic]="utils.isOrganicCoffee(entry.coffee)" [size]="11"></app-organicicon>
                </ng-container>
                <ng-container *ngIf="entry?.blend">
                    <app-organicicon [isOrganic]="utils.isOrganicBlend(entry.blend)" [size]="11"></app-organicicon>
                </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef colspan="3"></td>
            </ng-container> -->
            <ng-container matColumnDef="Weight">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" start="desc"
                    class="bottom-header right-align" i18n="stores$$amount, table header@@Amount">Amount</th>
                <td mat-cell *matCellDef="let entry; let i=index" class="simulate-td overview-value-t" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}">
                    @if (entry.nrRoasts && entry.amountStr) {
                    <!-- <ng-container *ngIf="entry.amount || entry.endWeight"> -->
                    @if (entry.sumInMissing) {
                    <span>(</span>
                    }{{entry.amountStr}}@if (entry.sumInMissing) {
                    <span>)</span>
                    }
                    <!-- </ng-container> -->
                    } @else {
                    ---
                    }
                </td>
                <td mat-footer-cell *matFooterCellDef class="right-align">@if (totalRoastedStr) {
                    @if (sumInMissing) {
                    <span>(</span>
                    }{{totalRoastedStr}}@if (sumInMissing) {
                    <span>)</span>
                    }
                    }
                </td>
                <!-- <td mat-footer-cell *matFooterCellDef></td> -->
            </ng-container>
            <!-- <ng-container matColumnDef="Arrow">
                <th mat-header-cell *matHeaderCellDef class="bottom-header"></th>
                <td mat-cell *matCellDef="let entry; let i=index" class="overview-separator" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}">
                <ng-container *ngIf="entry.amount || entry.endWeight">-></ng-container>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container> -->
            <ng-container matColumnDef="Yield">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" start="desc"
                    class="bottom-header right-align" i18n="reports$$report heading volume out@@Yield">Yield</th>
                <td mat-cell *matCellDef="let entry; let i=index" class="right-align" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}">
                    @if (entry.nrRoasts) {
                    <span [ngClass]="{discarded: entry.discarded}">
                        @if (entry.endWeightStr) {
                        <span class="overview-value-t">@if (entry.sumOutMissing) {
                            <span>(</span>
                            }{{entry.endWeightStr}}@if (entry.sumOutMissing) {
                            <span>)</span>
                            }</span>
                        } @else {
                        ---
                        }
                    </span>
                    }
                </td>
                <td mat-footer-cell *matFooterCellDef class="right-align">@if (totalYieldStr) {
                    @if (sumOutMissing) {
                    <span>(</span>
                    }{{totalYieldStr}}@if (sumOutMissing) {
                    <span>)</span>
                    }
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="Loss">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" start="desc"
                    class="bottom-header right-align" i18n="reports$$report heading roast loss@@Loss">Loss</th>
                <td mat-cell *matCellDef="let entry; let i=index" class="right-align" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}">
                    @if (entry.nrRoasts) {
                    @if (entry.sumInForLoss && entry.sumOutForLoss) {
                    <span class="overview-value-t" [ngClass]="{discarded: entry.discarded || entry.sumInForLoss < 0 || entry.sumInForLoss < entry.sumOutForLoss}">@if (entry.sumInMissing || entry.sumOutMissing) {
                        <span>(</span>
                        }{{(entry.sumInForLoss - entry.sumOutForLoss) * 100 / entry.sumInForLoss | number:'1.1-1'}}%@if (entry.sumInMissing || entry.sumOutMissing) {
                        <span>)</span>
                        }</span>
                    }
                    }
                </td>
                <td mat-footer-cell *matFooterCellDef class="right-align">@if (sumInForLoss && sumOutForLoss) {
                    <span>@if (sumInMissing || sumOutMissing) {
                        <span>(</span>
                        }{{(sumInForLoss - sumOutForLoss) * 100 / this.sumInForLoss | number:'1.1-1'}}%@if (sumInMissing || sumOutMissing) {
                        <span>)</span>
                        }</span>
                    }
                </td>
                <!-- <td mat-footer-cell *matFooterCellDef></td> -->
            </ng-container>
            <ng-container matColumnDef="NrBlends">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" start="desc"
                    class="bottom-header overview-blend-label" i18n="reportss@@Roasts">Roasts</th>
                <td mat-cell *matCellDef="let entry; let i=index" class="right-align" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}">
                    @if (entry.coffee) {
                    <span class="overview-value-t overview-blend-label">{{entry.nrOfBlendRoasts}}</span>
                    }
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="BlendWeight">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" start="desc"
                    class="bottom-header overview-blend-label" i18n="graphs$$graph label@@Weight">Weight</th>
                <td mat-cell *matCellDef="let entry; let i=index" class="right-align" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}">
                    @if (entry.coffee) {
                    <span class="overview-value-t overview-blend-label">{{entry.amountInBlendsStr}}</span>
                    }
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="Total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" start="desc"
                    class="bottom-header right-align" i18n="generic$$total number of some items@@Total">Total</th>
                <td mat-cell *matCellDef="let entry; let i=index" class="right-align" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}">
                    @if (entry.coffee) {
                    <span class="overview-value-t">{{entry.totalAmountStr}}</span>
                    }
                </td>
                <td mat-footer-cell *matFooterCellDef class="right-align">{{totalUsedStr}}</td>
            </ng-container>
            <ng-container matColumnDef="Cost">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" start="desc"
                    class="bottom-header right-align" i18n="stores$$cost, table header@@Cost">Cost</th>
                <td mat-cell *matCellDef="let entry; let i=index" class="right-align" [ngClass]="{'last-coffee-row': i === lastCoffeeIndex}">
                    @if (entry.coffee && entry.cost?.val) {
                    <span class="overview-value-t" style="white-space: nowrap;">
                        @if (entry.cost.amountLeft > 0) {
                        ~
                        }
                        {{entry.cost.val | currency:currency:'symbol-narrow':'1.0-0'}}
                        @if (entry.cost.valPerStr) {
                        <br />{{entry.cost.valPerStr}}/{{mainUnitSingular}}
                        }
                        <!-- <br />
        <span class="incomplete-value">-{{entry.cost.amountLeftStr}}</span>
        <span *ngIf="entry.cost.lastPurchase" style="white-space: nowrap;">
          <a *ngIf="!readOnly && !entry.cost.lastPurchase.reconciled" (click)="editTransaction(entry.cost.lastPurchase)">
            <mat-icon data-test="cn_edit" class="tertiary-element smallesticon" style="margin-left: 3px; padding-top: 2px;">edit</mat-icon>
          </a>
        </span> -->
                    </span>
                    }
                    @if (entry.blend && entry.cost?.val) {
                    @if (!entry.cost.amountLeft) {
                    <span class="overview-value-t overview-blend-label">{{entry.cost.val | currency:currency:'symbol-narrow':'1.0-0'}}</span>
                    }
                    @if (entry.cost.amountLeft > 0) {
                    <span class="overview-value-t incomplete-value">
                        ~{{entry.cost.val | currency:currency:'symbol-narrow':'1.0-0'}}
                        <!-- <br />
          -{{entry.cost.amountLeft}}kg <span *ngIf="entry.cost.lastPurchase"> => <a *ngIf="!readOnly && !entry.cost.lastPurchase.reconciled" (click)="editTransaction(entry.cost.lastPurchase)">
          <mat-icon data-test="cn_edit" class="smallesticon">edit</mat-icon>
        </a></span> -->
                    </span>
                    }
                    }
                </td>
                <td mat-footer-cell *matFooterCellDef class="right-align">@if (totalCost) {
                    <span>{{totalCost | currency:currency:'symbol-narrow':'1.0-0'}}</span>
                    }
                </td>
            </ng-container>
        </table>
        <span class="table-badge" i18n="generic$$heading or badge indicating an overview@@Summary">Summary</span>
    </div>
</div>
}
}